export const UserActionTypes = {
    LOGOUT: 'USERS_LOGOUT',
   ReserRedux: 'PAGE1_RESET_PAGE'
};


export function logout() { 
    console.log('reset redux store')
    return { type: UserActionTypes.LOGOUT } 
}
export function resetReducList() { 
    console.log('reset redux store')
    return { type: UserActionTypes.ReserRedux } 
}