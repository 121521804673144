import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import {
  RegionColumns,
  StateColumnsForPopup,
  ZoneColumnsForPopup,
  DistrictColumnsForPopup,
  TalukaColumnsForPopup,
  AreaColumnsForPopup,
} from "../../../tableColumns/table-columns";
import { regionJson } from "../../../DynamicFormsJson/MastersJSON/region";
import { getRegionNew } from "../../../Slice/region.slice";

class Region extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    const { regionList, getRegionNew } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={regionJson.formPath}
          screenTitle={regionJson.screenTitle}
          fieldMeta={regionJson.fieldMeta}
          showPdfDownload={false}
          showExcelDownload={true}
          changeExcelURL={"region-data" + '?sort={"sortOrder": "ASC"}'}
          showExtension={false}
          pdfFileName={false}
          excelFileName={regionJson.excelFileName}
          showAddButton={regionJson.showAddButton}
          tableColumnsToFilter={regionJson.tableColumnsToFilter}
          tableColumns={RegionColumns}
          tableData={regionList?.region}
          getTableData={getRegionNew}
          apiBaseURL={regionJson.apiBaseURL}
          baseIdColumn={regionJson.baseIdColumn}
          isNote={true}
          isNoteValue={"Region Is The Largest Entity Of The System"}
          showDeleteIcon={false}
          showPopUpDataList={[
            {
              dataKey: "stateCount",
              columns: StateColumnsForPopup,
              title: "State List",
              header: {
                dataKey: "name",
                label: "Region",
              },
            },
            {
              dataKey: "zoneCount",
              columns: ZoneColumnsForPopup,
              title: "Zone List",
              header: {
                dataKey: "name",
                label: "Region",
              },
            },
            {
              dataKey: "districtCount",
              columns: DistrictColumnsForPopup,
              title: "District List",
              header: {
                dataKey: "name",
                label: "Region",
              },
            },
            {
              dataKey: "talukaCount",
              columns: TalukaColumnsForPopup,
              title: "Taluka List",
              header: {
                dataKey: "name",
                label: "Region",
              },
            },
            {
              dataKey: "areaCount",
              columns: AreaColumnsForPopup,
              title: "Area List",
              header: {
                dataKey: "name",
                label: "Region",
              },
            },
          ]}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  regionList: state.region,
});
const mapDispatchToProps = {
  getRegionNew,
};
export default connect(mapStateToProps, mapDispatchToProps)(Region);
