import React from "react";
import { connect } from "react-redux";

import { targetJson } from "../../DynamicFormsJson/Transaction/Targrt";
import { getActiveYear } from "../../Slice/year.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { getTargetByStakeHolderTypeIdAndYearId } from "../../Slice/target.slice";
import { serverMsg, noInternetMsg, saveWarningMsg, checkedListEmptyMsg, savemsg, saveFailedMsg } from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import CheckBoxSelectionTable from "../../components/Comman/RT/CheckBoxSelectionTable";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import AutoComplete from "../../components/Comman/AutoComplete";
import { withRouter } from "../../components/withRouter";
import {

    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { LabelCompo } from "../../components/Comman/Label";
import { ButtonCompo } from "../../components/Comman/Button";
import { compose } from "@reduxjs/toolkit";
import { titleColor, redColor } from "../../config/ColorObj";
import { apiGet } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
class TargetUnfreeze extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicMasterData: {
                stockStakeHolderTypeDetail:
                    this.props.stockStakeHolderTypeList?.stockStakeHolderType,
                yearDetail: this.props.yearList?.activeYear,
            },
            targetNewList: [],
            selection: [],
            year: "",
            stockStakeHolderType: "",
            formError: {},
            error: false,
        };
    }
    async componentDidMount() {
        const { getStockStakeHolderType, getActiveYear, showLoador, showNotification } =
            this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            await getStockStakeHolderType({ userTypeIds: "3,4" }).then(
                ({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                }
            );
            showLoador({ loador: true });
            await getActiveYear().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (
            this.props.stockStakeHolderTypeList !== nextProps.stockStakeHolderTypeList
        ) {
            if (
                this.props.stockStakeHolderTypeList?.stockStakeHolderType !==
                nextProps.stockStakeHolderTypeList?.stockStakeHolderType
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        stockStakeHolderTypeDetail:
                            nextProps.stockStakeHolderTypeList?.stockStakeHolderType,
                    },
                });
            }
        }
        if (this.props.yearList !== nextProps.yearList) {
            if (this.props.yearList?.activeYear !== nextProps.yearList?.activeYear) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        yearDetail: nextProps.yearList?.activeYear,
                    },
                });
            }
        }
    }

    checkValidation = () => {
        const { year, stockStakeHolderType } = this.state;
        let formErrors = {};
        let formIsValid = true;

        if (year === "" || year === null) {
            formIsValid = false;
            formErrors["yearError"] = "*Year required";
        }
        if (stockStakeHolderType === "" || stockStakeHolderType === null) {
            formIsValid = false;
            formErrors["stockStakeHolderError"] = "*Stock StakeHolder Type required";
        }

        this.setState({
            formError: formErrors,
        });
        return formIsValid;
    };

    onSearchData = async () => {
        if (this.checkValidation()) {
            const {
                getTargetByStakeHolderTypeIdAndYearId,
                showLoador,
                showNotification,
            } = this.props;
            const { year, stockStakeHolderType } = this.state;
            if (navigator.onLine) {
                showLoador({ loador: true });
                await getTargetByStakeHolderTypeIdAndYearId({
                    stockStakeHolderTypeId: stockStakeHolderType,
                    yearId: year,
                }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    } else {

                    }
                });
            } else {
                this.props.showNotification({ msg: noInternetMsg, severity: "error" });
            }
        }
    };
    onAddButtonClick = () => {
        this.props.navigate(targetJson.formPath);
    };
    onAutocompleteChange = (name, newValue) => {
        if (newValue != null) {
            this.setState({
                [name]: newValue,
            });
        } else {
            this.setState({
                [name]: "",
            });
        }
    };

    onSubmit = () => {
        const { selection } = this.state;
        const { targetList, showNotification } = this.props;
        let rowList = targetList.targetNew.map(
            (rowData) => {
                const filterData = selection.filter((id) => rowData.id == id);
                return { ...rowData, checked: filterData.length != 0 ? true : false };
            }
        );
        if ((rowList.filter((row) => row.checked)).length !== 0) {

            swal({
                title: "Do you want to unfreeze the record?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    this.onSave(selection, 0);
                }
            });
        } else {
            showNotification({
                msg: checkedListEmptyMsg,
                severity: "error",
            });
        }


    };

    onSave = (ids, status) => {
        const { showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            apiGet({
                url: endpoint.target + "/update-is-freez-by-ids?targetIds=" + ids + "&status=" + status,
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (success) {
                    showNotification({ msg: savemsg });
                    window.location.replace("/target-unfreeze")
                } else {
                    showNotification({ msg: saveFailedMsg, severity: "error" });
                }
            });
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };



    getSelectedList = (e) => {
        this.setState({
            selection: e,
        });

    };

    render() {
        const { targetList, yearList, stockStakeHolderTypeList } = this.props;
        const { year, stockStakeHolderType, formError, selection } = this.state;
        return (
            <>
                <Paper
                    sx={{
                        padding: 2,
                        borderRadius: 2,
                    }}
                    elevation={0}
                >
                    <LandingScreenHeader
                        screenTitle={"Target Unfreeze"}
                        showTable={false}
                        showBackButton={false}
                        showAddButton={false}
                        onAddButtonClick={this.onAddButtonClick}
                    />
                    <br />
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={1.8} md={1.8} lg={1.8}>
                            <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, color: redColor }}
                                label={"*"}
                            />
                            <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, color: titleColor }}
                                label="Stock Holder Type :"
                            />
                        </Grid>
                        <Grid item xs={12} sm={3.7} md={3.7} lg={3.7}>
                            <AutoComplete
                                keyColName={"id"}
                                name={"stockStakeHolderType"}
                                value={stockStakeHolderType}
                                options={stockStakeHolderTypeList?.stockStakeHolderType}
                                placeHolder="Select Stock Holder Type"
                                onChange={this.onAutocompleteChange}
                                isError={formError.stockStakeHolderError ? true : false}
                                errorText={
                                    formError.stockStakeHolderError
                                        ? formError.stockStakeHolderError
                                        : " "
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={1.8} md={1.8} lg={1.8}>
                            <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, color: redColor }}
                                label={"*"}
                            />
                            <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, color: titleColor }}
                                label="Year :"
                            />
                        </Grid>
                        <Grid item xs={12} sm={3.7} md={3.7} lg={3.7}>
                            <AutoComplete
                                keyColName={"id"}
                                name={"year"}
                                value={year}
                                options={yearList?.activeYear}
                                placeHolder="Select Year"
                                onChange={this.onAutocompleteChange}
                                isError={formError.yearError ? true : false}
                                errorText={formError.yearError ? formError.yearError : " "}
                            />
                        </Grid>
                        <Grid container sm={1} xs={12} md={1} lg={1}>
                            <Grid item xs={12} sm={12} md={12} sx={{ paddingLeft: 2 }}>
                                <ButtonCompo
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    name={"Search"}
                                    onClick={this.onSearchData}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <CheckBoxSelectionTable
                        selection={selection}
                        onSelectionChange={this.getSelectedList}
                        columns={[
                            {
                                title: "Sr.No.",
                                name: "index",
                                positionCenter: true,
                                showInscreen: true,
                                align: "center",
                            },
                            {
                                title: "Name",
                                name: "name",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "left",
                            },
                            {
                                title: "January",
                                name: "jan",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "right",
                            },
                            {
                                title: "February",
                                name: "feb",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "right",
                            },
                            {
                                title: "March",
                                name: "march",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "right",
                            },
                            {
                                title: "April",
                                name: "april",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "right",
                            },
                            {
                                title: "May",
                                name: "may",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "right",
                            },
                            {
                                title: "June",
                                name: "jun",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "right",
                            },
                            {
                                title: "July",
                                name: "july",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "right",
                            },
                            {
                                title: "August",
                                name: "aug",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "right",
                            },
                            {
                                title: "September",
                                name: "sep",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "right",
                            },
                            {
                                title: "October",
                                name: "oct",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "right",
                            },
                            {
                                title: "November",
                                name: "nov",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "right",
                            },
                            {
                                title: "December",
                                name: "dec",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "right",
                            },

                            {
                                title: "Total",
                                name: "totalTarget",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "right",
                            },
                        ]}
                        rows={targetList.targetNew}
                        // setList={setOutletOrderDetail}
                        isActionColActive={false}
                    />
                    <br />

                    <>
                        <br />

                        <Grid
                            item
                            sm={12}
                            xs={12}
                            md={12}
                            lg={12}
                            rowSpacing={2}
                            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                            container
                            justifyContent="right"
                        >
                            <ButtonCompo
                                size="medium"
                                type="submit"
                                variant="contained"
                                name="UNFREEZE"
                                onClick={this.onSubmit}
                            />
                        </Grid>
                    </>

                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    targetList: state.target,
    stockStakeHolderTypeList: state.stockStakeHolderType,
    yearList: state.year,
});
const mapDispatchToProps = {
    getStockStakeHolderType,
    getActiveYear,
    getTargetByStakeHolderTypeIdAndYearId,
    showLoador,
    showNotification,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(TargetUnfreeze);
