import endpoint from "../../config/endpoints";

export const AssetsChecklistJson = {
    formPath: "/parameters-checklist-form",
    apiBaseURL: endpoint.assetsChecklist,
    screenTitle: "Parameters Checklist",
    showAddButton: true,
    showPdfDownload: false,
    showExcelDownload: false,
    tableColumnsToFilter: [
        {
            columnDisplayName: "Questions",
            columnKeyName: "questions",
            isChecked: true,
        },
        {
            columnDisplayName: "Parameters",
            columnKeyName: "assetsName",
            isChecked: true,
        },
        {
            columnDisplayName: "Status",
            columnKeyName: "isActive",
            isChecked: true,
        },
    ],

    fieldMeta: [
        {
            label: "Region",
            controlType: "autocomplete",
            placeHolder: "Select Region",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "regionDetail",
            dataKey: "region",
            getListId: "region",
            isRootLevelKey: false,
            isMandatory: true,
        },
        {
            label: "Zone",
            controlType: "multiSelect",
            placeHolder: "Select Zone",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "zoneDetail",
            dataKey: "zoneIds",
            getListFrom: "region",
            getListId: "zoneIds",
            isRootLevelKey: true,
            isMandatory: true,
        },

        {
            label: "Area",
            controlType: "multiSelect",
            placeHolder: "Select Area",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "areaDetail",
            dataKey: "areaIds",
            getListFrom: "zoneIds",
            getListId: "areaIds",
            isRootLevelKey: true,
            isMandatory: true,
        },

        {
            label: "Parameters",
            controlType: "autocomplete",
            placeHolder: "Select Parameters",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "assetsDetail",
            dataKey: "assets",
            isRootLevelKey: false,
            isMandatory: true,
        },

        {
            label: "Question",
            controlType: "textfield",
            placeHolder: "Question",
            md: 12,
            lg: 12,
            sm: 12,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            autoFocus: true,
            dataKey: "questions",
            isMandatory: true,
        },

    ]
};
