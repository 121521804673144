import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { UomJson } from "../../../DynamicFormsJson/MastersJSON/Uom";

class UomForm extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <DynamicForm
          formPath={UomJson.formPath}
          screenTitle={UomJson.screenTitle}
          fieldMeta={UomJson.fieldMeta}
          apiBaseURL={UomJson.apiBaseURL}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  uomList: state.uom,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(UomForm);
