import TextField from "@mui/material/TextField";
import * as React from "react";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
export default function TimePickerCompo({
  value,
  onChange,
  error = " ",
  disabled = false,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        value={value || null}
        inputFormat="hh:mm"
        onChange={onChange}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            size="small"
            fullWidth
            {...params}
            value={value}
            error={error !== " " ? true : false}
            helperText={error !== " " ? error : " "}
            disabled={disabled}
          />
        )}
      />
    </LocalizationProvider>
  );
}
