import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { noInternetMsg, serverMsg } from "../../../config/messageconstant";
import { transporterJson } from "../../../DynamicFormsJson/MastersJSON/transporter";
import { getAreaByTalukaPostBodyUpdated } from "../../../Slice/area.slice";
import { getDistrictByZone } from "../../../Slice/district.slice";
import { getRegionNew } from "../../../Slice/region.slice";
import { getStakeHolderNameByStakeholderTypeAndAreaUpdated } from "../../../Slice/StackHolder.slice";
import { getStateByRegion } from "../../../Slice/state.slice";
import { getStockStakeHolderType } from "../../../Slice/stockStakeHolderType.slice";
import { getTalukaByDistrict } from "../../../Slice/taluka.slice";
import { getZoneByState } from "../../../Slice/zone.slice";
import { compose } from "@reduxjs/toolkit";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { withRouter } from "../../../components/withRouter";
class TransporterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        stateDetails: this.props.stateList?.stateByRegion,
        regionDetail: this.props.regionList?.region,
        zoneDetail: this.props.zoneList?.zoneByState,
        districtIdsDetail: this.props.districtList?.districtByZone,
        talukaDetail: this.props.talukaList?.talukaByDistrict,
        areaDetail: this.props.areaList?.areaByTaluka,
        stakeHolderTypeDetail:
          this.props.stakeHolderTypeList?.stockStakeHolderType,
        stakeHolderName: this.props.stakeholderList?.stackholderNameFromType,
      },
      areaIds: "",
      userTypeId: "",
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetails: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtIdsDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
    if (this.props.stakeHolderTypeList !== nextProps.stakeHolderTypeList) {
      if (
        this.props.stakeHolderTypeList?.stockStakeHolderType !==
        nextProps.stakeHolderTypeList?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeHolderTypeDetail:
              nextProps.stakeHolderTypeList?.stockStakeHolderType,
          },
        });
      }
    }
    if (this.props.stakeholderList !== nextProps.stakeholderList) {
      if (
        this.props.stakeholderList?.stackholderNameFromType !==
        nextProps.stakeholderList?.stackholderNameFromType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeHolderName: nextProps.stakeholderList?.stackholderNameFromType,
          },
        });
      }
    }
  }
  async componentDidMount() {
    const userTypeId = localStorage.getItem("userTypeId");
    this.setState({
      userTypeId: userTypeId,
    });
    const { getRegionNew, getStockStakeHolderType, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getRegionNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getStockStakeHolderType({ userTypeIds: 2 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  getListById = async (data) => {
    console.log(data);
    const { areaIds } = this.state;
    console.log(areaIds);
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTalukaPostBodyUpdated,
      getStakeHolderNameByStakeholderTypeAndAreaUpdated,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (data.area) {
        this.setState({
          areaIds: data.area.id,
        });
      }
      if (data.region && data.region.id) {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region.id }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.stateIds) {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.stateIds }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.zoneIds) {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zoneIds }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.districtIds) {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.districtIds }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.talukaIds) {
        let talukaJson = {
          talukaIds: data.talukaIds,
        };
        showLoador({ loador: true });
        await getAreaByTalukaPostBodyUpdated({ talukaIds: talukaJson }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.stockStakeHolderType) {
        showLoador({ loador: true });
        await getStakeHolderNameByStakeholderTypeAndAreaUpdated({
          stockStakeHolderTypeIds: data.stockStakeHolderType.id,
          areaIds: areaIds,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  render() {
    const { dynamicMasterData, userTypeId } = this.state;
    return (
      <>
        {userTypeId != "" && (
          <DynamicForm
            formPath={transporterJson.formPath}
            screenTitle={transporterJson.screenTitle}
            fieldMeta={
              userTypeId == 1 || userTypeId == 6
                ? transporterJson.fieldMeta
                : transporterJson.fieldMetaForDistributor
            }
            apiBaseURL={transporterJson.apiBaseURL}
            dynamicMasterData={dynamicMasterData}
            getListById={this.getListById}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  transporterList: state.transporter,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  areaList: state.area,
  stakeHolderTypeList: state.stockStakeHolderType,
  stakeholderList: state.stockholder,
});
const mapDispatchToProps = {
  getRegionNew,
  getStateByRegion,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTalukaPostBodyUpdated,
  getStockStakeHolderType,
  getStakeHolderNameByStakeholderTypeAndAreaUpdated,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TransporterForm);
