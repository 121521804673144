import endpoint from "../../config/endpoints";

export const stockistSettleBillFormJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.assignRateConfigurationToStakeholder,
  formPath: "/settle-bill",
  formPathView: "/payment-list",
  screenTitle: "Settle Bill",
  showTitle: true,
  showCancel: false,
  showBackToList: false,
  showSaveBtn: false,
  fieldMeta: [
    {
      label: "Stock Holder",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeholdersDetail",
      dataKey: "stakeholder",
      isRootLevelKey: true,
      isMandatory: true,
      getListId: "stakeholder",
    },
    {
      label: "Payment Receipt",
      controlType: "autocomplete",
      placeHolder: "Select Payment Receipt",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      masterName: "paymentRecTranDetail",
      dataKey: "paymentRecTran",
      getListFrom: "stakeholder",
      isRootLevelKey: false,
      isMandatory: true,
      getListId: "paymentRecTran",
      disableWithCondition: true,
    },
  ],
  fieldMetaForPayment: [
    {
      label: "Stock Holder",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeholdersDetail",
      dataKey: "stakeholder",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Amount",
      controlType: "textfield",
      placeHolder: "Amount",
      inputType: "number",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "amount",
      isMandatory: true,
      disableWithCondition: true,
    },
  ],
  searchList: [
    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "area",
      getListId: "area",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Beat",
      controlType: "autocomplete",
      placeHolder: "Select Beat",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "beat",
      masterName: "beatDetail",
      getListFrom: "area",
      getListId: "beat",
      isMandatory: true,
      isRootLevelKey: false,
    },
    {
      label: "Outlet",
      controlType: "autocomplete",
      placeHolder: "Select Outlet",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "outlet",
      getListFrom: "beat",
      masterName: "outletDetail",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      isMandatory: true,
    },
  ],
};
