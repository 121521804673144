import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
    productCatelogue: [],
};
let URL = endpoints.productCatelogue;
const productCatelogueSlice = createSlice({
    name: "productCatelogue",
    initialState,
    reducers: {
        productCatelogueSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                productCatelogue: row,
            };
        },

        resetState: (state = cloneDeep(initialState), action) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const {
    productCatelogueSuccess,
    resetState,
} = productCatelogueSlice.actions;

export default productCatelogueSlice.reducer;

export const getproductCatelogue = () => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL,
        }).then(({ data, success }) => {
            if (success) {
                const { content } = data.data;
                const row = content.map((areaObject, index) => {
                    let areaData = {
                        index: index + 1,
                        id: areaObject.id === null ? "" : areaObject.id,
                        productName:
                            areaObject.productName === null
                                ? ""
                                : areaObject.productName,
                        productRetailRate:
                            areaObject.productRetailRate !== null
                                ? areaObject.productRetailRate
                                : "",
                        productMrp:
                            areaObject.productMrp === null ? "" : areaObject.productMrp,

                        fromDate:
                            areaObject.fromDate === null ? "" : areaObject.fromDate,

                        toDate:
                            areaObject.toDate === null ? "" : areaObject.toDate,

                        trendingSequence:
                            areaObject.trendingSequence === null ? "" : areaObject.trendingSequence,

                        isLabelApplicable:
                            areaObject.isLabelApplicable === null ? "" : areaObject.isLabelApplicable,

                        labelName:
                            areaObject.labelName === null ? "" : areaObject.labelName,

                        launchDate:
                            areaObject.launchDate === null ? "" : areaObject.launchDate,

                        offerApplicable:
                            areaObject.offerApplicable === null ? "" : areaObject.offerApplicable,

                        product:
                            areaObject.product === null ? "" : areaObject.product,

                        productId:
                            areaObject.productId !== null ? areaObject.productId : "",
                        isActive:
                            areaObject.isActive === null ? "" : areaObject.isActive,
                        imageMandatory:
                            areaObject.imageMandatory === null ? "" : areaObject.imageMandatory,
                    };
                    return areaData;
                });
                dispatch(productCatelogueSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};










