import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
    noOfStaff: [],
};
let URL = endpoints.noOfStaff;
const noOfStaffSlice = createSlice({
    name: "noOfStaff",
    initialState,
    reducers: {
        noOfStaffSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                noOfStaff: row,

            };
        },

        resetState: (state = cloneDeep(initialState), action) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const { noOfStaffSuccess, resetState } = noOfStaffSlice.actions;

export default noOfStaffSlice.reducer;

export const getNoOfStaff = () => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL,
        }).then(({ data, success }) => {
            if (success) {
                const { content } = data.data;
                const row = content.map((noOfStaffObject, index) => {
                    let noOfStaffData = {
                        index: index + 1,
                        id: noOfStaffObject.id === null ? "" : noOfStaffObject.id,
                        name: noOfStaffObject.name === null ? "" : noOfStaffObject.name,
                        isActive: noOfStaffObject.isActive === null ? "" : noOfStaffObject.isActive,
                    };
                    return noOfStaffData;
                });
                dispatch(noOfStaffSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};
