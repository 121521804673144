import endpoint from "../../config/endpoints";
import dayjs from "dayjs";

export const BillingHistoryPopUpJson = {
  showSaveNextBtn: true,
  screenTitle: "Add Json Data",

  fieldMeta: [
    {
      label: "Acknowledgement No",
      inputType: "number",
      controlType: "textfield",
      placeHolder: "Enter Acknowledgement No",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "ackNo",
      isMandatory: true,
    },
    {
      label: "Acknowledgement Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "ackDate",
      isMandatory: true,
    },
    {
      label: "Signed QR Code",
      controlType: "textarea",
      placeHolder: "Enter Signed QR Code",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      dataKey: "signedQRCode",
      isMandatory: true,
    },
  ],
};
