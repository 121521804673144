import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { StockHolderOrderingConfigurationJson } from "../../DynamicFormsJson/Transaction/stockHolderOrderingConfigurationJson";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
class StockHolderOrderingConfigurationView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DataToSet: [],
            placeOrder: [],
        };
        // this.columns = [
        //     {
        //         title: "Sr.No.",
        //         name: "index",
        //         align: "center",
        //     },
        //     {
        //         title: "Product Name",
        //         name: "productName",
        //     },
        //     {
        //         title: "MRP Rate",
        //         name: "mrp",
        //         align: "right",
        //     },

        //     {
        //         title: "OutLet Rate",
        //         name: "outLet",
        //         align: "right",
        //     },
        //     {
        //         title: "Distributor Rate",
        //         name: "distributor",
        //         align: "right",
        //     },
        //     {
        //         title: "Stokist Rate",
        //         name: "stokist",
        //         align: "right",
        //     },
        //     {
        //         title: "Super Stokist Rate",
        //         name: "superStokist ",
        //         align: "right",
        //     },
        // ];
    }
    async componentDidMount() {
        const { showLoador, showNotification } =
            this.props;
        if (navigator.onLine) {
            if (this.props.params.id) {
                showLoador({ loador: true });
                apiGet({
                    url:
                        StockHolderOrderingConfigurationJson.apiBaseURL +
                        "/" +
                        this.props.params.id,
                }).then(({ data, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                    } else {
                        let rowData = data.data;
                        this.setState({
                            headerData: rowData,
                            DataToSet: [
                                {
                                    xs: 12,
                                    sm: 4,
                                    md: 4,
                                    lg: 4,
                                    label: "Stock Holder",
                                    value: rowData.stockStakeHolder.stockStakeHolderType.name !== null ? rowData.stockStakeHolder.stockStakeHolderType.name : "",
                                },
                                {
                                    xs: 12,
                                    sm: 4,
                                    md: 4,
                                    lg: 4,
                                    label: "From Time",
                                    value: rowData.fromTime !== null ? rowData.fromTime : "",
                                },
                                {
                                    xs: 12,
                                    sm: 4,
                                    md: 4,
                                    lg: 4,
                                    label: "To Time",
                                    value: rowData.toTime !== null ? rowData.toTime : "",
                                },

                            ],
                            // placeOrder:
                            //     response.stockStakeHolderOrderDetails.length !== 0
                            //         ? response.stockStakeHolderOrderDetails
                            //         : [],
                        });
                    }
                });
            }

        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }
    render() {
        const { dynamicMasterData, placeOrder, DataToSet } = this.state;
        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 0,
                    }}
                    elevation={0}
                >
                    <DynamicFormWithoutSave
                        showBackToList={true}
                        DataToSet={DataToSet}
                        dynamicMasterData={dynamicMasterData}
                        showSaveBtn={false}
                        showTitle={true}
                        screenTitle={"Stock Holder Ordering Configuration View"}
                        showCancel={StockHolderOrderingConfigurationJson.showCancel}
                        apiBaseURL={StockHolderOrderingConfigurationJson.apiBaseURL}
                        showSaveNextBtn={false}
                        saveBtnText={false}
                        showSaveBtnMain={false}
                        padding={true}
                        paddingTop={true}
                    />
                    {/* <RTTable
                        columns={[]}
                        tableData={[]}
                        isActionColActive={false}
                        showPegination={false}
                    /> */}
                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    showNotification,
    showLoador,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(StockHolderOrderingConfigurationView);
