import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { compose } from "@reduxjs/toolkit";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import {
  getActiveLanguage,
  getLanguageJson,
} from "../../Slice/multilingual.slice";
import {
  getActiveProduct,
  getChangeActiveProduct,
} from "../../Slice/product.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";

class ProductMultilingualForm extends React.Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const {
      showLoador,
      showNotification,
      getActiveLanguage,
      getActiveProduct,
      productList,
      getLanguageJson,
      multilingualList,
      getChangeActiveProduct,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getActiveLanguage().then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getActiveProduct().then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          showLoador({ loador: true });
          getLanguageJson({ id: "0" }).then(({ responseData, success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            } else {
              let row = [];
              if (responseData !== null && responseData.length !== 0) {
                if (
                  responseData[0].jsonList !== null &&
                  responseData[0].jsonList.length !== 0
                ) {
                  response.map((productData) => {
                    responseData[0].jsonList.map((jsonData) => {
                      if (productData.id == jsonData.id) {
                        if (
                          jsonData.language !== null &&
                          jsonData.language.id !== ""
                        ) {
                          productData = {
                            ...productData,
                            languageMarathi:
                              jsonData.language.id == "0"
                                ? jsonData.name
                                : productData.languageMarathi,
                            languageHindi:
                              jsonData.language.id == "1"
                                ? jsonData.name
                                : productData.languageHindi,
                            languageEnglish:
                              jsonData.language.id == "2"
                                ? jsonData.name
                                : productData.languageEnglish,
                            languageGujarati:
                              jsonData.language.id == "3"
                                ? jsonData.name
                                : productData.languageGujarati,
                          };
                        }
                      }
                    });
                    row.push(productData);
                  });
                  getChangeActiveProduct({ row: row });
                }
              }
            }
          });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  handleChange = (e, row) => {
    const { name, value } = e.target;
    const { productList, getChangeActiveProduct } = this.props;
    let newList = [];
    productList.activeProduct.map((productObject) => {
      if (productObject.id == row.id) {
        if (name == "Marathi") {
          productObject = {
            ...productObject,
            languageMarathi: value,
          };
        } else if (name == "Hindi") {
          productObject = {
            ...productObject,
            languageHindi: value,
          };
        } else if (name == "English") {
          productObject = {
            ...productObject,
            languageEnglish: value,
          };
        } else {
          productObject = {
            ...productObject,
            languageGujarati: value,
          };
        }

        newList.push(productObject);
      } else {
        newList.push(productObject);
      }
    });
    getChangeActiveProduct({ row: newList });
  };
  submitHandler = () => {
    const { productList, showNotification } = this.props;
    let newDataToSave = "";
    let saveJson = [];
    productList.activeProduct.map((productObject) => {
      if (productObject.languageMarathi !== "") {
        let productSaveObject = {
          id: productObject.id,
          language: {
            id: "0",
          },
          name: productObject.languageMarathi,
        };
        saveJson.push(productSaveObject);
      }
      if (productObject.languageHindi !== "") {
        let productSaveObject = {
          id: productObject.id,
          language: {
            id: "1",
          },
          name: productObject.languageHindi,
        };
        saveJson.push(productSaveObject);
      }
      if (productObject.languageEnglish !== "") {
        let productSaveObject = {
          id: productObject.id,
          language: {
            id: "2",
          },
          name: productObject.languageEnglish,
        };
        saveJson.push(productSaveObject);
      }
      if (productObject.languageGujarati !== "") {
        let productSaveObject = {
          id: productObject.id,
          language: {
            id: "3",
          },
          name: productObject.languageGujarati,
        };
        saveJson.push(productSaveObject);
      }
    }),
      (newDataToSave = {
        type: 1,
        areaBeatId: 0,
        json: saveJson,
      });
    if (saveJson.length !== 0) {
      swal({
        title: "Are you sure?",
        text: myConstClass.saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.saveHandler(newDataToSave);
        }
      });
    } else {
      showNotification({
        msg: myConstClass.enterValueMsg,
        severity: "error",
      });
    }
  };
  saveHandler = (newDataToSave) => {
    const { showNotification, showLoador, navigate } = this.props;
    showLoador({ loador: true });
    apiPost({
      url: endpoint.multilingual + "-json",
      postBody: newDataToSave,
    }).then(({ success }) => {
      showLoador({ loador: false });
      if (success) {
        showNotification({
          msg: "Data saved successfully",
        });
        window.location.replace("/product-multilingual-form");
      } else {
        showNotification({ msg: serverMsg, severity: "error" });
      }
    });
  };
  goBack = () => {
    this.props.navigate(-1);
  };
  render() {
    const { multilingualList, productList } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <Grid container justifyContent="flex-start" direction="row">
            <TitleAndBreadCrum title={"Language Json For Product"} />
          </Grid>
        </Paper>
        <br />
        <Paper
          sx={{
            p: 2,
            pt: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" width="10%">
                    Sr. No.
                  </TableCell>
                  <TableCell width="30%">Product Name</TableCell>
                  {multilingualList.language.map((languageObject) => {
                    return (
                      <>
                        <TableCell align="center" width="10%">
                          {languageObject.name}
                        </TableCell>
                      </>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {productList.activeProduct.map((row, index) => (
                  <>
                    <TableRow key={row.name}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      {multilingualList.language.map((languageObject) => {
                        return (
                          <>
                            <TableCell>
                              <TextFieldCompo
                                color="primary"
                                name={languageObject.name}
                                placeHolder={
                                  "Enter " +
                                  languageObject.name +
                                  " Transalation"
                                }
                                size="small"
                                value={
                                  languageObject.name == row.languageMarathiName
                                    ? row.languageMarathi
                                    : languageObject.name ==
                                      row.languageHindiName
                                    ? row.languageHindi
                                    : languageObject.name ==
                                      row.languageEnglishName
                                    ? row.languageEnglish
                                    : row.languageGujarati
                                }
                                onChange={(event) =>
                                  this.handleChange(event, row)
                                }
                                fullWidth
                                helperText={""}
                              />
                            </TableCell>
                          </>
                        );
                      })}
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          &nbsp;&nbsp; &nbsp;&nbsp;
          <div align="right">
            <ButtonCompo
              size="button"
              type="button"
              variant="outlined"
              name="Cancel"
              onClick={this.goBack}
            />
            &nbsp;&nbsp;
            <ButtonCompo
              size="button"
              type="button"
              variant="contained"
              name="Submit"
              onClick={this.submitHandler}
            />
          </div>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  multilingualList: state.multilingual,
  productList: state.product,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getActiveLanguage,
  getActiveProduct,
  getChangeActiveProduct,
  getLanguageJson,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ProductMultilingualForm);
