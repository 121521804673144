import endpoint from "../../config/endpoints";

export const paymentListJSON = {
  showSaveNextBtn: true,
  showBackToList: true,
  apiBaseURL: endpoint.paymentReceipt,
  screenTitle: "Payment For Stock Holder",
  formPath: "/payment-form-stockist",
  formPathView: "/payment-list-view",
  fieldMeta: [
    {
      label: "Stock Holder",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      masterName: "stakeholdersDetail",
      dataKey: "stakeholder",
      getListId: "stakeholder",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Amount ",
      controlType: "textfield",
      placeHolder: "Enter Amount",
      inputType: "number",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      dataKey: "amount",
      isRootLevelKey: false,
      isMandatory: true,
      spValid: "float",
    },
  ],
  searchList: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      onSubmit: "compare",
      onSubmitParameter: "fromDate-ge",
      isMandatory: true,
      onSubmit: "compare",
    },
    {
      label: "Stock Holder",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeholdersDetail",
      dataKey: "stakeholderId",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
};
