import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  bankDetails: [],
};
let URL = endpoints.bankDetails;
const bankDetailsSlice = createSlice({
  name: "bankDetails",
  initialState,
  reducers: {
    bankDetailsSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        bankDetails: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { bankDetailsSuccess, resetState } = bankDetailsSlice.actions;

export default bankDetailsSlice.reducer;

export const getBankDetails = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-all",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((bankDetailsObject, index) => {
          let bankData = {
            index: index + 1,
            id: bankDetailsObject.id === null ? "" : bankDetailsObject.id,
            
            firmName:
              bankDetailsObject.stockStakeHolderFirmName === null
                ? ""
                : bankDetailsObject.stockStakeHolderFirmName,
            ownerName:
              bankDetailsObject.stockStakeHolderOwnerName === null
                ? ""
                : bankDetailsObject.stockStakeHolderOwnerName,
            bankName:
              bankDetailsObject.bankName === null
                ? ""
                : bankDetailsObject.bankName,
            accType:
              bankDetailsObject.accType === null
                ? ""
                : bankDetailsObject.accType == 1
                ? "Saving"
                : "Current",
            accNo:
              bankDetailsObject.accNo === null ? "" : bankDetailsObject.accNo,
            ifscCode:
              bankDetailsObject.ifscCode === null
                ? ""
                : bankDetailsObject.ifscCode,

            state:
              bankDetailsObject.state === null ? "" : bankDetailsObject.state,
            city: bankDetailsObject.city === null ? "" : bankDetailsObject.city,
            address:
              bankDetailsObject.address === null
                ? ""
                : bankDetailsObject.address,
            extra1:
              bankDetailsObject.extra1 === null ? "" : bankDetailsObject.extra1,
            isActive:
              bankDetailsObject.isActive === null
                ? ""
                : bankDetailsObject.isActive,
          };
          return bankData;
        });
        dispatch(bankDetailsSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
