import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  paymentReceiptTransactionForStakeholderData: [],
  settleBillWithPaymentData: [],
};
let URL = endpoints.paymentReceipt;
let URL1 = endpoints.stockStakeholderBillHeader;

const stockistSettleBillSlice = createSlice({
  name: "settleBill",
  initialState,
  reducers: {
    paymentReceiptTransactionSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        paymentReceiptTransactionForStakeholderData: row,
        isFetch: true,
      };
    },

    settleBillWithPaymentSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        settleBillWithPaymentData: row,
        isFetch: true,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },

    resetSettleBillWithPaymentData: (
      state = cloneDeep(initialState),
      action
    ) => {
      return {
        ...state,
        settleBillWithPaymentData: [],
        isFetch: false,
      };
    },
  },
});

export const {
  paymentReceiptTransactionSuccess,
  settleBillWithPaymentSuccess,
  remainingAmtSuccess,
  resetState,
  resetSettleBillWithPaymentData,
} = stockistSettleBillSlice.actions;

export default stockistSettleBillSlice.reducer;

export const getPaymentReceiptTransactionForStakeholder =
  ({ stakeholderId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-from-stake-holder-and-to-stake-holder?fromStakeHolder=" +
          stakeholderId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          const row = content.map((areaObject, index) => {
            let areaData = {
              index: index + 1,
              amt: areaObject.amt == null ? "" : +areaObject.amt.toFixed(2),
              name:
                (areaObject.amt == null
                  ? ""
                  : areaObject.amt.toFixed(2) + " ₹ - ") +
                (areaObject.settleRemainingAmt == null
                  ? ""
                  : areaObject.settleRemainingAmt.toFixed(2) + " ₹"),
              date: areaObject.date == null ? "" : areaObject.date,
              id: areaObject.id == null ? "" : areaObject.id,
              insertedUserId:
                areaObject.insertedUserId == null
                  ? ""
                  : areaObject.insertedUserId,
              outLet: areaObject.outLet == null ? "" : areaObject.outLet.id,
              payBank: areaObject.payBank == null ? "" : areaObject.payBank,
              payRefNo: areaObject.payRefNo == null ? "" : areaObject.payRefNo,
              payType: areaObject.payType == null ? "" : areaObject.payType,
              remark: areaObject.remark == null ? "" : areaObject.remark,
              remark2: areaObject.remark2 == null ? "" : areaObject.remark2,
              settleRemainingAmt:
                areaObject.settleRemainingAmt == null
                  ? ""
                  : areaObject.settleRemainingAmt.toFixed(2), // removed + because toFixed was not working
              remainingAmt:
                areaObject.remainingAmt == null
                  ? ""
                  : +areaObject.remainingAmt.toFixed(2),
              stockStakeHolderId:
                areaObject.stockStakeHolderId == null
                  ? ""
                  : areaObject.stockStakeHolderId,
              transactionNo:
                areaObject.transactionNo == null
                  ? ""
                  : areaObject.transactionNo,
            };
            return areaData;
          });
          dispatch(paymentReceiptTransactionSuccess({ row: row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getSettleBillWithPaymentListForStakeholder =
  ({ stakeholderId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/by-from-stake-holder-and-to-stake-holder?fromStakeHolder=" +
          stakeholderId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          let selectedList = [];
          const row = content.map((billDataObject, index) => {
            let billData = {
              index: index + 1,
              acknoledgeNo:
                billDataObject.acknoledgeNo == null
                  ? ""
                  : billDataObject.acknoledgeNo,
              billNo: billDataObject.billNo == null ? 0 : billDataObject.billNo,
              billDate:
                billDataObject.billDate == null ? "" : billDataObject.billDate,
              cessAmt:
                billDataObject.cessAmt == null ? "" : billDataObject.cessAmt,
              cgstAmt:
                billDataObject.cgstAmt == null
                  ? ""
                  : billDataObject.cgstAmt.toFixed(2),
              discountOnBillAmt:
                billDataObject.discountOnBillAmt == null
                  ? ""
                  : billDataObject.discountOnBillAmt.toFixed(2),
              discountOnBillPer:
                billDataObject.discountOnBillPer == null
                  ? ""
                  : billDataObject.discountOnBillPer.toFixed(2),
              id: billDataObject.id == null ? "" : billDataObject.id,
              igstAmt:
                billDataObject.igstAmt == null
                  ? ""
                  : billDataObject.igstAmt.toFixed(2),
              insertedUserId:
                billDataObject.insertedUserId == null
                  ? ""
                  : billDataObject.insertedUserId,
              isSettle:
                billDataObject.isSettle == null ? "" : billDataObject.isSettle,
              itemDistcountAmt:
                billDataObject.itemDistcountAmt == null
                  ? ""
                  : billDataObject.itemDistcountAmt.toFixed(2),
              payoutAmt:
                billDataObject.payoutAmt == null
                  ? ""
                  : billDataObject.payoutAmt,
              payoutIsSettle:
                billDataObject.payoutIsSettle == null
                  ? ""
                  : billDataObject.payoutIsSettle.toFixed(2),
              totalAmt:
                billDataObject.totalAmt == null
                  ? ""
                  : billDataObject.totalAmt.toFixed(2),
              remainingAmt:
                billDataObject.remainingAmt == null
                  ? ""
                  : billDataObject.remainingAmt.toFixed(2),
              sgstAmt:
                billDataObject.sgstAmt == null
                  ? ""
                  : billDataObject.sgstAmt.toFixed(2),
              status:
                billDataObject.status == null ? "" : billDataObject.status,
              stockStakeHolderId:
                billDataObject.stockStakeHolderId == null
                  ? ""
                  : billDataObject.stockStakeHolderId,
              taxableAmt:
                billDataObject.taxableAmt == null
                  ? ""
                  : billDataObject.taxableAmt.toFixed(2),
              checked: false,
              disabled: false,
            };
            return billData;
          });
          dispatch(settleBillWithPaymentSuccess({ row }));
          return { response: row, success, selectedList };
        }
        return { response: [], success, selectedList: [] };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const setBillData =
  ({ row }) =>
  async (dispatch) => {
    dispatch(settleBillWithPaymentSuccess({ row }));
  };

export const resetData = () => async (dispatch) => {
  try {
    dispatch(resetSettleBillWithPaymentData());
  } catch (e) {
    return console.error(e.message);
  }
};
