import React from "react";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import {
  serverMsg,
  savemsg,
  saveFailedMsg,
  noInternetMsg,
  saveWarningMsg,
  dateExistMsg
} from "../../config/messageconstant";
import { assignRateConfigurationToStakeholderJson } from "../../DynamicFormsJson/Transaction/AssignRateConfigurationToStakeholder";
import { getCategory } from "../../Slice/category.slice";
import { getProductRateConfigurationByStakeHolderTypeIdsAndCategoryIds } from "../../Slice/productRateConfiguration.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import endpoint from "../../config/endpoints";
import { withRouter } from "../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import { getStakeHolderName } from "../../Slice/StackHolder.slice";
import swal from "sweetalert";
import { Paper } from "@mui/material";
class AssignRateConfigurationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        stakeHolderTypeDetail:
          this.props.stakeHolderTypeList?.stockStakeHolderType,
        categoryDetail: this.props.categoryList?.category,
        rateConfigurationDetail:
          this.props.productRateConfiguration?.productRateConfigurationNew,
        stakeHolderName: this.props.stakeholderList?.stackholderName,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.stakeHolderTypeList !== nextProps.stakeHolderTypeList) {
      if (
        this.props.stakeHolderTypeList?.stockStakeHolderType !==
        nextProps.stakeHolderTypeList?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeHolderTypeDetail:
              nextProps.stakeHolderTypeList?.stockStakeHolderType,
          },
        });
      }
    }
    if (this.props.categoryList !== nextProps.categoryList) {
      if (
        this.props.categoryList?.category !== nextProps.categoryList?.category
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            categoryDetail: nextProps.categoryList?.category,
          },
        });
      }
    }
    if (
      this.props.productRateConfiguration !== nextProps.productRateConfiguration
    ) {
      if (
        this.props.productRateConfiguration?.productRateConfigurationNew !==
        nextProps.productRateConfiguration?.productRateConfigurationNew
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            rateConfigurationDetail:
              nextProps.productRateConfiguration?.productRateConfigurationNew,
          },
        });
      }
    }
    if (this.props.stakeholderList !== nextProps.stakeholderList) {
      if (
        this.props.stakeholderList?.stackholderName !==
        nextProps.stakeholderList?.stackholderName
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeHolderName: nextProps.stakeholderList?.stackholderName,
          },
        });
      }
    }
  }
  async componentDidMount() {
    const {
      getStockStakeHolderType,
      getCategory,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getStockStakeHolderType({ userTypeIds: 2 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      await getCategory().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  getListById = async (data) => {
    const {
      showNotification,
      showLoador,
      getProductRateConfigurationByStakeHolderTypeIdsAndCategoryIds,
      getStakeHolderName,
    } = this.props;
    if (navigator.onLine) {
      if (data.stockStakeHolderType && data.stockStakeHolderType.id) {
        showLoador({ loador: true });
        await getStakeHolderName({
          StockStakeHolderTypeIds: data.stockStakeHolderType.id,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
        if (data.category && data.category.id) {
          showLoador({ loador: true });
          await getProductRateConfigurationByStakeHolderTypeIdsAndCategoryIds({
            stockStakeHolderTypeIds: data.stockStakeHolderType.id,
            categoryIds: data.category.id,
          }).then(({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          });
        }
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSearch = (searchData) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiGet({
        url:
          endpoint.assignRateConfigurationToStakeholder +
          "/by-stock-stake-holder-and-category-and-selected-date?stockStakeHolderIds=" +
          searchData.stackholder +
          "&categoryId=" +
          searchData.category.id +
          "&selectedDate=" +
          searchData.date,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          if (data.data.length == 0) {
            const stackholderList = searchData.stackholder.split(",");
            const dataToSave = stackholderList.map((stackholderListData) => {
              const rateconfigurationListObj = {
                stockStakeHolder: {
                  id: stackholderListData,
                },
                category: searchData.category,
                productRateConfiguration: searchData.publishrateconfiguration,
                publishDate: searchData.date,
                isActive: 1,
              };
              return rateconfigurationListObj;
            });
            swal({
              title: "Are you sure?",
              text: saveWarningMsg,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                this.onSave(dataToSave);
              }
            });
          } else {
            showNotification({
              msg: dateExistMsg,
              severity: "error",
            });
          }
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.assignRateConfigurationToStakeholder + "/save-list",
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          this.props.navigate(-1);
          showNotification({ msg: savemsg });
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            getListById={this.getListById}
            dynamicMasterData={dynamicMasterData}
            showTitle={assignRateConfigurationToStakeholderJson.showTitle}
            screenTitle={assignRateConfigurationToStakeholderJson.screenTitle}
            fieldMeta={assignRateConfigurationToStakeholderJson.fieldMeta}
            showCancel={assignRateConfigurationToStakeholderJson.showCancel}
            apiBaseURL={assignRateConfigurationToStakeholderJson.apiBaseURL}
            showSaveNextBtn={
              assignRateConfigurationToStakeholderJson.showSaveNextBtn
            }
            onSave={this.onSearch}
            showSaveBtnMain={false}
            showSaveBtn={true}
          />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stakeHolderTypeList: state.stockStakeHolderType,
  stakeholderList: state.stackholder,
  categoryList: state.category,
  productRateConfiguration: state.productRateConfiguration,
});
const mapDispatchToProps = {
  getStockStakeHolderType,
  getCategory,
  getProductRateConfigurationByStakeHolderTypeIdsAndCategoryIds,
   showLoador,
  showNotification,
  getStakeHolderName,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AssignRateConfigurationForm);
