import React from "react";
import { connect } from "react-redux";
import { categoryJson } from "../../../DynamicFormsJson/MastersJSON/Category";
import { getCategory } from "../../../Slice/category.slice";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import {
  CategoryColumns,
  ProductColumnsForPopUp,
  SubCategoryColumnsForPopup,
} from "../../../tableColumns/table-columns";
import endpoint from "../../../config/endpoints";

class CategoryTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { categoryList, getCategory } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={categoryJson.formPath}
          screenTitle={categoryJson.screenTitle}
          fieldMeta={categoryJson.fieldMeta}
          showPdfDownload={false}
          showExcelDownload={true}
          changeExcelURL={categoryJson.excelUrl}
          showExtension={false}
          pdfFileName={categoryJson.pdfFileName}
          excelFileName={categoryJson.excelFileName}
          showAddButton={categoryJson.showAddButton}
          tableColumnsToFilter={categoryJson.tableColumnsToFilter}
          tableColumns={CategoryColumns}
          tableData={categoryList?.category}
          getTableData={getCategory}
          apiBaseURL={categoryJson.apiBaseURL}
          baseIdColumn={categoryJson.baseIdColumn}
          isNote={true}
          isNoteValue={"Add Various Categories Of Products"}
          showDeleteIcon={false}
          showPopUpDataList={[
            {
              dataKey: "subCategoryCount",
              columns: SubCategoryColumnsForPopup,
              title: "Sub-Category List",
              header: {
                dataKey: "name",
                label: "Category",
              },
            },
            {
              dataKey: "productCount",
              columns: ProductColumnsForPopUp,
              title: "Product List",
              // filterData: {
              //   tableDataKey: "subCategory.id",
              //   label: "Sub Category",
              //   placeHolder: "Select Sub-Category",
              //   dataKey: "subCategory",
              //   passId: "id",
              //   name: "subCategoryId",
              //   url: endpoint.subCategory + "/by-category?categoryId=",
              // },
              header: {
                dataKey: "name",
                label: "Category",
              },
            },
          ]}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  categoryList: state.category,
});
const mapDispatchToProps = {
  getCategory,
};
export default connect(mapStateToProps, mapDispatchToProps)(CategoryTable);
