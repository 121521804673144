import endpoint from "../../config/endpoints";

export const ClaimTypeJson = {
    showSaveNextBtn: true,
    formPath: "/claim-type-form",
    apiBaseURL: endpoint.claimType,
    screenTitle: "Claim Type",
    showAddButton: true,
    showPdfDownload: false,
    pdfFileName: "Claim Type",
    showExcelDownload: false,
    excelFileName: "Claim Type",
    tableColumnsToFilter: [
        {
            columnDisplayName: "Name",
            columnKeyName: "name",
            isChecked: true,
        },
        {
            columnDisplayName: "Short Name",
            columnKeyName: "shortName",
            isChecked: true,
        },
    ],
    fieldMeta: [
        {
            label: "Claim Type",
            controlType: "textfield",
            placeHolder: "Enter Claim Type",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "name",
            isMandatory: true,
        },
        {
            label: "Short Name",
            controlType: "textfield",
            placeHolder: "Enter Short Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "shortName",
            isMandatory: true,
        },
        {
            label: "Type",
            controlType: "radiogroup",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "type",
            tableDataKey: "type",
            defaultValue: "0",
            radioGroupItems: [
                {
                    label: "Per-UOM",
                    value: "0",
                },
                {
                    label: "Custom",
                    value: "1",
                },
                {
                    label: "Limit",
                    value: "2",
                },
            ],
            isMandatory: false,
        },
        {
            label: "Attachment Applicable",
            controlType: "radiogroup",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "attachmentApplicable",
            tableDataKey: "attachmentApplicable",
            defaultValue: "1",
            radioGroupItems: [
                {
                    label: "Yes",
                    value: "0",
                },
                {
                    label: "No",
                    value: "1",
                },
            ],
            isMandatory: true,
        },
        {
            label: "UOM Name",
            showLabel: "UOM Name",
            controlType: "hideTextfield",
            placeHolder: "Enter UOM Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "uomName",
            tableDataKey: "type",
            open: "0",
            isMandatory: true,
            setTextHide: true,
            rootValue: true
        },
        {
            label: "Limit Value",
            controlType: "hideRadiogroup",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "limitVal",
            tableDataKey: "type",
            open: "2",
            defaultValue: "0",
            radioGroupItems: [
                {
                    label: "Not Applicable",
                    value: "0",
                },
                {
                    label: "Yes",
                    value: "1",
                },
                {
                    label: "No",
                    value: "2",
                },
            ],
            isMandatory: true,
            setTextHide: true,
            rootValue: true
        },

        {
            label: "Remark",
            controlType: "textfield",
            placeHolder: "Enter Remark",
            labelMD: 2,
            labelLG: 2,
            valueMD: 10,
            valueLG: 10,
            md: 12,
            lg: 12,
            sm: 12,
            xs: 12,
            dataKey: "remark",
        },
    ],
};
