import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { ButtonCompo } from "../../components/Comman/Button";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import { titleColor } from "../../config/ColorObj";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ProductListDialogBox = ({
  handleCloses,
  open,
  columns,
  rows,
  formData = "",
  rejectOrder,
  acceptOrder,
  comment,
  changeHandler,
}) => {
  const handleClose = () => {
    handleCloses();
  };
  return (
    <>
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="lg"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Product List
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <DynamicTable
              data={rows}
              tableHead={columns}
              showPegination={false}
              isActionColActive={false}
            />
            <br />
            <Grid container alignItems="center">
              <Grid item xs={12} md={2.5} sm={2.5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor, marginLeft: 15 }}
                  label=" Add Comment :"
                />
              </Grid>
              <Grid item xs={12} md={9.5} sm={9.5}>
                <TextFieldCompo
                  placeholder="Add Comment"
                  color="primary"
                  name="comment"
                  size="small"
                  value={comment}
                  fullWidth
                  onChange={changeHandler}
                />
              </Grid>
            </Grid>
            <br />
            {rows.length != 0 && (
              <>
                <Grid container spacing={1}>
                  <Grid
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{ justifyContent: "flex-end" }}
                  >
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name={"Accept"}
                      onClick={acceptOrder}
                    />
                    &nbsp;&nbsp;
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name={"Reject"}
                      onClick={rejectOrder}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </DialogContent>
        </BootstrapDialog>
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductListDialogBox);
