export const pendingGrnListJSON = {
  showSaveNextBtn: true,
  formPathView: "/pending-grn-list-view",
  formPathForOutlet: "/pending-grn-details",
  screenTitle: "Pending GRN",
  showTitle: false,
  showCancel: false,
  showBackToList: false,
  showSaveBtn: false,
  fieldMeta: [],
  searchList: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromdate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "todate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "todate",
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
    },
  ],

  fieldMeta: [
    {
      label: "Is calculate in stock",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "calStock",
      tableDataKey: "calStock",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: true,
    },
  ],
};
