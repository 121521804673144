import endpoint from "../../config/endpoints";

export const StockConsumptionHeaderJson = {
  apiBaseURL: endpoint.manageStockHeader,
  screenTitle: "Stock Consumption Header",
  searchList: [
    {
      label: "Type",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "qtyType",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Primary Quantity",
          value: "0",
        },
        {
          label: "Secondary Quantity",
          value: "1",
        },
      ],
      getListId: "qtyType",
      isMandatory: true,
    },
  ],
};
