import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { zoneJson } from "../../../DynamicFormsJson/MastersJSON/zone";
import { getUpdatedStateList } from "../../../Slice/state.slice";
import {
    showLoador,
    showNotification,
} from "../../Landing/Landing.slice";
import { serverMsg ,noInternetMsg,} from "../../../config/messageconstant";

class ZoneForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicMasterData: {
                stateDetail: this.props.stateList?.states,
            },
        };
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.stateList !== nextProps.stateList) {
            if (this.props.stateList?.states !== nextProps.stateList?.states) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        stateDetail: nextProps.stateList?.states,
                    },
                });
            }
        }
    }
   async componentDidMount() {
        const { getUpdatedStateList, showLoador, showNotification } = this.props;
        if (navigator.onLine) {
        showLoador({ loador: true });
        await getUpdatedStateList().then(({ success }) => {
            showLoador({ loador: false });
            if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
            }
        });
    }
    else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
    }

    render() {
        const{dynamicMasterData}=this.state;
        return (
            <>
                <DynamicForm
                formPath={zoneJson.formPath}
                    screenTitle={zoneJson.screenTitle}
                    fieldMeta={zoneJson.fieldMeta}
                    tableColumnsToFilter={zoneJson.tableColumnsToFilter}
                    apiBaseURL={zoneJson.apiBaseURL}
                    baseIdColumn={zoneJson.baseIdColumn}
                    dynamicMasterData={dynamicMasterData}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    stateList: state.states,

});
const mapDispatchToProps = {
    getUpdatedStateList,
    showLoador,
    showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(ZoneForm);
