import React from "react";
import { connect } from "react-redux";
import { ReportInfoColumns } from "../../tableColumns/table-columns";
import { ReportInfoJson } from "../../DynamicFormsJson/Transaction/reportInfo";
import { getReportInfo } from "../../Slice/report.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";

class ReportTable extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { reportList, getReportInfo } = this.props;
        return (
            <>
                <DynamicMainScreen
                    formPath={ReportInfoJson.formPath}
                    screenTitle={ReportInfoJson.screenTitle}
                    showPdfDownload={false}
                    showExcelDownload={false}
                    showAddButton={true}
                    tableColumnsToFilter={ReportInfoJson.tableColumnsToFilter}
                    tableColumns={ReportInfoColumns}
                    tableData={reportList?.report}
                    getTableData={getReportInfo}
                    apiBaseURL={ReportInfoJson.apiBaseURL}
                    baseIdColumn={ReportInfoJson.baseIdColumn}
                    showPegination={false}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    reportList: state.report,
});
const mapDispatchToProps = {
    getReportInfo,
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportTable);
