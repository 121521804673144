import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  country: [],
};
let URL = endpoints.country;
const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    countrySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        country: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { countrySuccess, resetState } = countrySlice.actions;

export default countrySlice.reducer;

export const getCountry = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((countryObject, index) => {
          let countryData = {
            index: index + 1,
            id: countryObject.id === null ? "" : countryObject.id,
            name: countryObject.name === null ? "" : countryObject.name,
            shortName:
              countryObject.shortName === null ? "" : countryObject.shortName,
            sortOrder:
              countryObject.sortOrder === null ? "" : countryObject.sortOrder,
          };
          return countryData;
        });
        dispatch(countrySuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
