import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { batchWiseGRNJSON } from "../../DynamicFormsJson/Transaction/batchWiseGRN";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg, saveFailedMsg } from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { apiGet } from "../../utils/api_service";
import { auditJson } from "../../DynamicFormsJson/MastersJSON/audit";
import { getAuditQestion, setAuditQuestion } from "../../Slice/audit.slice";
class AuditView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DataToSetSurvey: [],
            DataToSetAudit: [],
            orderHistory: [],
            fieldData: {},
            value: "1",
        };
        this.columns = [
            { name: "index", title: "Sr. No.", align: "center" },
            { name: "question", title: "Question" },
            { name: "optionTypeName", title: "Question Type" },
            { name: "points", title: "Points", align: "center" },
        ];
    }
    async componentDidMount() {
        const {
            showLoador,
            showNotification,
            setAuditQuestion
        } = this.props;
        if (navigator.onLine) {
            if (this.props.params.id) {
                setAuditQuestion({ row: [] })
                showLoador({ loador: true });
                const response = apiGet({
                    url: auditJson.apiBaseURL + "/" + this.props.params.id,
                }).then(({ data, success }) => {
                    showLoador({ loador: false });
                    if (success) {
                        if (!data.error) {
                            let rowData = data.data;
                            this.setState({
                                value: rowData.isAuditSurvey,
                                fieldData: rowData,

                                DataToSetSurvey: [
                                    {
                                        xs: 12,
                                        sm: 6,
                                        md: 6,
                                        lg: 6,
                                        label: "From Date",
                                        value:
                                            rowData.fromDate !== null
                                                ? rowData.fromDate
                                                : "",
                                    },
                                    {
                                        xs: 12,
                                        sm: 6,
                                        md: 6,
                                        lg: 6,
                                        label: "To Date",
                                        value: rowData.toDate !== null ? rowData.toDate : "",
                                    },
                                    {
                                        xs: 12,
                                        sm: 6,
                                        md: 6,
                                        lg: 6,
                                        label: "Name",
                                        value: rowData.name !== null ? rowData.name : "",
                                    },
                                    {
                                        xs: 12,
                                        sm: 6,
                                        md: 6,
                                        lg: 6,
                                        label: "Area",
                                        value: "Rajkot ",
                                    },

                                    {
                                        xs: 12,
                                        sm: 6,
                                        md: 6,
                                        lg: 6,
                                        label: "Remark",
                                        value: rowData.remark !== null && rowData.remark !== "" ? rowData.remark : "-",
                                    },
                                ],
                            });
                            this.getAuditQestionList(this.props.params.id);

                            return { data: rowData, success };
                        }
                        return { data: {}, success: false };
                    } else {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }

                    return { data: {}, success: false };
                });
                return response;

            };

        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    getAuditQestionList = (id) => {
        const { showLoador, getAuditQestion, showNotification } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            getAuditQestion({ id: id }).then(
                ({ data, success }) => {
                    showLoador({ loador: false });
                    if (success) {
                    } else {
                        showNotification({ msg: saveFailedMsg, severity: "error" });
                    }
                }
            );
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };
    render() {
        const { orderHistory, DataToSetSurvey, value } = this.state;
        const { auditList } = this.props;
        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 0,
                    }}
                    elevation={0}
                >
                    <LandingScreenHeader
                        showTitle={auditJson.showTitle}
                        screenTitle={value == 1 ? "Survey View" : "Audit View"}
                        showSaveBtnMain={false}
                        showAddButton={false}
                        showBackButton={true}
                        onCancel={() => this.props.navigate(-1)}
                    />
                    <br />
                    <DynamicDetails DataToSet={DataToSetSurvey} />
                    <RTTable
                        columns={this.columns}
                        tableData={auditList?.auditQuestion}
                        isActionColActive={false}
                        showPegination={false}
                    />
                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    auditList: state.audit,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getAuditQestion,
    setAuditQuestion
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AuditView);
