import { useEffect, useState } from "react";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Paper,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ColumnDobleChart from "./ColumnDobleChart";
import TablePopUp from "./TablePopUp";
import OrdersTable from "./OrdersTable";
import IncomeAreaChart from "./IncomeAreaChart";
import MonthlyBarChart from "./MonthlyBarChart";
import ReportAreaChart from "./ReportAreaChart";
import SalesColumnChart from "./SalesColumnChart";
import MainCard from "./MainCard";
import AnalyticEcommerce from "./AnalyticEcommerce";
import PieChart from "./PieChart";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";

// assets
import {
  GiftOutlined,
  MessageOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import avatar1 from "../../assets/users/avatar-1.png";
import avatar2 from "../../assets/users/avatar-2.png";
import avatar3 from "../../assets/users/avatar-3.png";
import avatar4 from "../../assets/users/avatar-4.png";
import { serverMsg } from "../../config/messageconstant";
import endpoint from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";
import { connect } from "react-redux";
import {
  getDashBourdMain,
  getDataForLineGraph,
} from "../../Slice/distributorDashbourd.slice";
import NoDataFound from "./NoDataFound";
// avatar style
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: "1rem",
};

// action style
const actionSX = {
  mt: 0.75,
  ml: 1,
  top: "auto",
  right: "auto",
  alignSelf: "flex-start",
  transform: "none",
};

// sales report status
const status = [
  {
    value: "today",
    label: "Today",
  },
  {
    value: "month",
    label: "This Month",
  },
  {
    value: "year",
    label: "This Year",
  },
];

// ==============================|| DASHBOARD - DEFAULT ||============================== //

const DashboardDefault = ({
  showLoador,
  getDashBourdMain,
  getDataForLineGraph,
  distributorDashbourd,
  showNotification,
}) => {
  const [openTable, setOpenTable] = useState(false);
  const [title, setTitle] = useState("");
  const [flagData, setFlagData] = useState("");
  const [rows, setRows] = useState([]);
  const theme = useTheme();
  const [coloumnChartList, setColoumnChartList] = useState([]);

  const { primary, secondary } = theme.palette.text;
  const info = theme.palette.info.light;

  const [value, setValue] = useState("today");
  const [slot, setSlot] = useState("week");
  const toggle = (name, urlData, flag) => () => {
    setFlagData(flag);
    setTitle(name);
    if (name != "") {
      if (navigator.onLine) {
        showLoador({ loador: true });
        apiGet({
          url: urlData,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            setRows(
              data.data.map((rowObj, index) => {
                if (flag == 0) {
                  return {
                    index: index + 1,
                    beatName: rowObj.beatName,
                    firmName: rowObj.firmName,
                    ownerName: rowObj.ownerName,
                  };
                }
                if (flag == 2) {
                  return {
                    index: index + 1,
                    salesTeamName:
                      rowObj.salesTeam != null ? rowObj.salesTeam.name : "-",
                    checkInTime:
                      rowObj.checkInTime == null ? "-" : rowObj.checkInTime,
                    checkOutTime:
                      rowObj.checkOutTime == null ? "-" : rowObj.checkOutTime,
                  };
                }
                if (flag == 3) {
                  return {
                    index: index + 1,
                    salesTeamName: rowObj.name != null ? rowObj.name : "-",
                    contactNo:
                      rowObj.contactNo == null ? "-" : rowObj.contactNo,
                    emailId: rowObj.emailId == null ? "-" : rowObj.emailId,
                  };
                }
                if (flag == 4) {
                  return {
                    index: index + 1,
                    ownerName:
                      rowObj.outLet != null ? rowObj.outLet.ownerName : "-",
                    firmName:
                      rowObj.outLet != null ? rowObj.outLet.firmName : "-",
                    totalAmt:
                      rowObj.totalAmt != null ? rowObj.totalAmt.toFixed(2) : 0,
                  };
                }
                if (flag == 5) {
                  return {
                    index: index + 1,
                    ownerName:
                      rowObj.toStockStakeHolder != null
                        ? rowObj.toStockStakeHolder.ownerName
                        : "-",
                    firmName:
                      rowObj.toStockStakeHolder != null
                        ? rowObj.toStockStakeHolder.firmName
                        : "-",
                    totalAmt:
                      rowObj.totalAmt != null ? rowObj.totalAmt.toFixed(2) : 0,
                  };
                }
                return {
                  index: index + 1,
                  orderDate: rowObj.orderDate,
                  firmName:
                    rowObj.outLet != null ? rowObj.outLet.firmName : "-",
                  totalAmt:
                    rowObj.totalAmt != null ? rowObj.totalAmt.toFixed(2) : 0,
                };
              })
            );

            setOpenTable(!openTable);
          }
        });
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    } else {
      setOpenTable(!openTable);
    }
  };
  useEffect(() => {
    showLoador({ loador: true });
    getDashBourdMain().then(({ success }) => {
      if (!success) {
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
      showLoador({ loador: false });
    });
    showLoador({ loador: true });
    getDataForLineGraph({ fromDate: "", toDate: "" }).then(({ success }) => {
      if (!success) {
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
      showLoador({ loador: false });
    });
  }, []);
  const getPercentage = (curretnAmt, pervAmt) => {
    if (curretnAmt != 0 && pervAmt != 0) {
      let amt = curretnAmt / pervAmt;
      console.log(amt);
      return (amt * 100).toFixed(2);
    }
    return 0;
  };
  const getProfit = (curretnAmt, pervAmt) => {
    if (curretnAmt != 0 && pervAmt != 0) {
      let amt = curretnAmt / pervAmt;
      console.log(amt);
      let per = (amt * 100).toFixed(2);
      return per > 0 ? true : false;
    }
    return false;
  };
  const rowLink = (rowData) => {
    setFlagData(4);
    setTitle("Outlet List");
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiGet({
        url:
          endpoint.stockStakeholderBillHeader +
          "/by-to-bill?toBillId=" +
          rowData.id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          setRows(
            data.data.map((rowObj, index) => {
              return {
                index: index + 1,
                name: rowObj.name,
                firmName:
                  rowObj.fromBillFirmName != null
                    ? rowObj.fromBillFirmName
                    : "-",
                ownerName:
                  rowObj.fromBillOwnerName != null
                    ? rowObj.fromBillOwnerName
                    : "-",
                totalAmt: rowObj.totalAmt.toFixed(2),
              };
            })
          );

          setOpenTable(!openTable);
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  const onCLickPie = (index) => {
    setFlagData(6);
    let catOBJ =
      distributorDashbourd.dashbourdMain.dtoOutletBillCategoryWiseTotalAmounts[
      index
      ];
    if (Object.keys(catOBJ).length != 0) {
      setTitle("Product List");
      if (navigator.onLine) {
        showLoador({ loador: true });
        apiGet({
          url:
            endpoint.product +
            "/by-date-and-product-wise?fromDate=&toDate=&categoryId=" +
            catOBJ.category.id +
            "&type=1",
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            setRows(
              data.data.map((rowObj, index) => {
                return {
                  index: index + 1,
                  name: rowObj.name,
                  uom: rowObj.outletUom != null ? rowObj.outletUom.name : "-",
                  totalAmt: rowObj.totalAmt.toFixed(2),
                };
              })
            );

            setOpenTable(!openTable);
          }
        });
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };
  const onClickLineChart = (index) => {
    setFlagData(6);
    let catOBJ =
      distributorDashbourd.lineGraphData.monthWiseOutletTotalBill[index];
    if (Object.keys(catOBJ).length != 0) {
      setTitle("Product List");
      if (navigator.onLine) {
        showLoador({ loador: true });
        apiGet({
          url:
            endpoint.monthWiseTotalAmount +
            "/date-wise?monthYear=" +
            catOBJ.monthYear +
            "&type=1",
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            setColoumnChartList(
              data.data.map((rowObj, index) => {
                return {
                  index: index + 1,
                  date: rowObj.date,
                  totalAmt: rowObj.totalAmount.toFixed(2),
                };
              })
            );
          }
        });
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };

  return (
    <>
      <Paper
        sx={{
          p: 2,
          borderRadius: 2,
        }}
        elevation={0}
      >
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
          <Grid item xs={12} sx={{ mb: -2.25 }}>
            <Typography variant="h5">Current month</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Sell amount"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0 &&
                  distributorDashbourd.dashbourdMain
                    .currentMonthStakeHolderTotalAmt != null
                  ? (+distributorDashbourd.dashbourdMain
                    .currentMonthStakeHolderTotalAmt).toFixed(2)
                  : 0
              }
              percentage={getPercentage(
                Object.keys(distributorDashbourd.dashbourdMain).length != 0 &&
                  distributorDashbourd.dashbourdMain
                    .currentMonthStakeHolderTotalAmt != null
                  ? distributorDashbourd.dashbourdMain
                    .currentMonthStakeHolderTotalAmt
                  : 0,
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain
                    .previousMonthStakeHolderTotalAmt
                  : 0
              )}
              onClickFlag={false}
              isLoss={getProfit(
                Object.keys(distributorDashbourd.dashbourdMain).length != 0 &&
                  distributorDashbourd.dashbourdMain
                    .currentMonthStakeHolderTotalAmt != null
                  ? distributorDashbourd.dashbourdMain
                    .currentMonthStakeHolderTotalAmt
                  : 0,
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain
                    .previousMonthStakeHolderTotalAmt
                  : 0
              )}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0 &&
                  distributorDashbourd.dashbourdMain
                    .currentMonthStakeHolderTotalAmt != null
                  ? (+distributorDashbourd.dashbourdMain
                    .currentMonthStakeHolderTotalAmt).toFixed(2)
                  : 0
              }
              msgRight=""
              msgLeft="Total stake holder bill amount is = "
            />
          </Grid>

          <Grid item xs={12} sx={{ mb: -2.25 }}>
            <Typography variant="h5">GRN count</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Remain to approved"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.pendingCount
                  : 0
              }
              onClickFlag={false}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.pendingCount
                  : 0
              }
              msgRight=""
              msgLeft="Approved count is = "
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Remain to credit note"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.approvedCount
                  : 0
              }
              onClickFlag={false}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.approvedCount
                  : 0
              }
              msgRight=""
              msgLeft="Remain to credit note is = "
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: -2.25 }}>
            <Typography variant="h5">Order count</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Pending"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.sentOrderCount
                  : 0
              }
              onClickFlag={false}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.sentOrderCount
                  : 0
              }
              msgRight=""
              msgLeft="Pending order count is = "
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Accepted"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.acceptOrderCount
                  : 0
              }
              onClickFlag={false}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.acceptOrderCount
                  : 0
              }
              msgRight=""
              msgLeft="Accepted order count is = "
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: -2.25 }}>
            <Typography variant="h5">Credit note amount</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Total amount ( From me )"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.holderCreditNoteTotalAmtByFromStock.toFixed(
                    2
                  )
                  : 0
              }
              // percentage={70.5}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain
                    .holderCreditNoteTotalAmtByFromStock
                  : 0
              }
              msgRight=""
              msgLeft="Total amount is = "
              onClick={toggle(
                "Outlet List",
                endpoint.creditNote +
                "/by-date-and-outlet-wise?fromDate=&toDate=",
                4
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Total amount ( To me )"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain
                    .holderCreditNoteTotalAmtByToStock
                  : 0
              }
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain
                    .holderCreditNoteTotalAmtByToStock
                  : 0
              }
              onClick={toggle(
                "Outlet List",
                endpoint.creaditNoteHeader +
                "/by-date-and-outlet-wise?fromDate=&toDate=",
                5
              )}
              msgRight=""
              msgLeft="Total amount is = "
            />
          </Grid>
          <Grid
            item
            md={8}
            sx={{ display: { sm: "none", md: "block", lg: "none" } }}
          />

          <Grid item xs={12} md={8} lg={8}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Sale bill amount list </Typography>
              </Grid>
            </Grid>
            <MainCard content={false} sx={{ mt: 1.5 }}>
              <Box sx={{ pt: 1, pr: 2 }}>
                {Object.keys(distributorDashbourd.dashbourdMain).length != 0 &&
                  distributorDashbourd.dashbourdMain
                    .dtoStockStakeHolderAndTotalAmounts &&
                  distributorDashbourd.dashbourdMain
                    .dtoStockStakeHolderAndTotalAmounts.length != 0 ? (
                  <MonthlyBarChart
                    series={[
                      {
                        data: distributorDashbourd.dashbourdMain.dtoStockStakeHolderAndTotalAmounts.map(
                          (beatObj) => {
                            return beatObj.totalBillAmt != null
                              ? +beatObj.totalBillAmt.toFixed(2)
                              : "";
                          }
                        ),
                      },
                    ]}
                    optionsObj={{
                      chart: {
                        type: "bar",
                        height: 365,
                        toolbar: {
                          show: false,
                        },
                      },
                      plotOptions: {
                        bar: {
                          columnWidth: "45%",
                          borderRadius: 4,
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      xaxis: {
                        categories:
                          distributorDashbourd.dashbourdMain.dtoStockStakeHolderAndTotalAmounts.map(
                            (beatObj) => {
                              return beatObj.stockStakeHolder != null
                                ? beatObj.stockStakeHolder.firmName
                                : "";
                            }
                          ),
                        axisBorder: {
                          show: false,
                        },
                        axisTicks: {
                          show: false,
                        },
                        labels: {
                          style: {
                            colors: [
                              secondary,
                              secondary,
                              secondary,
                              secondary,
                              secondary,
                              secondary,
                              secondary,
                            ],
                          },
                        },
                      },
                      yaxis: {
                        show: false,
                      },
                      grid: {
                        show: false,
                      },
                      colors: [info],

                      tooltip: {
                        theme: "light",
                      },
                    }}
                  />
                ) : (
                  <NoDataFound />
                )}
              </Box>
            </MainCard>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">
                  Category wise stock holder count
                </Typography>
              </Grid>
            </Grid>
            <MainCard content={false} sx={{ mt: 1.5 }}>
              <Box sx={{ pt: 1, pr: 2 }}>
                {Object.keys(distributorDashbourd.dashbourdMain).length != 0 &&
                  distributorDashbourd.dashbourdMain
                    .dtoStockStakeHolderBillCategoryWiseTotalAmounts &&
                  distributorDashbourd.dashbourdMain
                    .dtoStockStakeHolderBillCategoryWiseTotalAmounts.length !=
                  0 ? (
                  <PieChart
                    series={distributorDashbourd.dashbourdMain.dtoStockStakeHolderBillCategoryWiseTotalAmounts.map(
                      (category) => {
                        return category.category != null
                          ? category.totalAmt
                          : "";
                      }
                    )}
                    options={{
                      chart: {
                        width: 380,
                        type: "pie",
                        events: {
                          dataPointSelection: (event, chartContext, config) => {
                            onCLickPie(config.dataPointIndex);
                          },
                        },
                      },
                      labels:
                        distributorDashbourd.dashbourdMain.dtoStockStakeHolderBillCategoryWiseTotalAmounts.map(
                          (category) => {
                            return category.category != null
                              ? category.category.name
                              : "";
                          }
                        ),
                      responsive: [
                        {
                          breakpoint: 480,
                          options: {
                            chart: {
                              width: 300,
                            },
                            legend: {
                              position: "bottom",
                            },
                          },
                        },
                      ],
                    }}
                  />
                ) : (
                  <NoDataFound />
                )}
              </Box>
            </MainCard>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Sale graph</Typography>
              </Grid>
              <Grid item />
            </Grid>
            <MainCard sx={{ mt: 2 }} content={false}>
              {Object.keys(distributorDashbourd.lineGraphData).length != 0 &&
                distributorDashbourd.lineGraphData
                  .monthWiseStockStakeHolderTotalBill &&
                distributorDashbourd.lineGraphData
                  .monthWiseStockStakeHolderTotalBill.length != 0 ? (

                <MonthlyBarChart
                  series={[
                    {
                      data: distributorDashbourd.lineGraphData.monthWiseStockStakeHolderTotalBill.map(
                        (category) => {
                          return +category.totalAmount.toFixed(2);
                        }
                      ),
                    },
                  ]}
                  optionsObj={{
                    chart: {
                      type: "bar",
                      height: 365,
                      toolbar: {
                        show: false,
                      },
                      events: {
                        dataPointSelection: (event, chartContext, config) => {
                          onClickLineChart(config.dataPointIndex);
                        },
                      },
                    },

                    plotOptions: {
                      bar: {
                        borderRadius: 4,
                        horizontal: true,
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    xaxis: {
                      categories:
                        distributorDashbourd.lineGraphData.monthWiseStockStakeHolderTotalBill.map(
                          (category) => {
                            return category.monthName != null
                              ? category.monthName
                              : "";
                          }
                        ),

                      axisBorder: {
                        show: false,
                      },
                      axisTicks: {
                        show: false,
                      },
                      labels: {
                        style: {
                          colors: [
                            secondary,
                            secondary,
                            secondary,
                            secondary,
                            secondary,
                            secondary,
                            secondary,
                          ],
                        },
                      },
                    },
                    // yaxis: {
                    //   show: false,
                    // },
                    grid: {
                      show: false,
                    },
                    colors: [info],

                    tooltip: {
                      theme: "light",
                    },
                  }}
                />
              ) : (
                <NoDataFound />
              )}
            </MainCard>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Datewise  graph</Typography>
              </Grid>
              <Grid item />
            </Grid>
            <MainCard sx={{ mt: 2 }} content={false}>
              {coloumnChartList.length != 0 ? (

                <ReportAreaChart
                  options={{
                    chart: {
                      height: 350,
                      type: "line",
                      dropShadow: {
                        enabled: true,
                        color: "#000",
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2,
                      },
                      // toolbar: {
                      //   show: false
                      // }
                    },
                    colors: ["#77B6EA", "#545454"],
                    dataLabels: {
                      enabled: true,
                    },
                    stroke: {
                      curve: "smooth",
                    },

                    grid: {
                      borderColor: "#e7e7e7",
                      row: {
                        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                        opacity: 0.5,
                      },
                    },
                    markers: {
                      size: 1,
                    },
                    xaxis: {
                      categories: coloumnChartList.map((category) => {
                        return category.date != null ? category.date : "";
                      }),
                      title: {
                        text: "Dates",
                      },
                    },
                    yaxis: {
                      title: {
                        text: "Amount",
                      },
                    },
                    legend: {
                      position: "top",
                      horizontalAlign: "right",
                      floating: true,
                      offsetY: -25,
                      offsetX: -5,
                    },
                  }}
                  series={[
                    {
                      name: "Amount",
                      data: coloumnChartList.map((category) => {
                        return +category.totalAmt;
                      }),
                    },
                  ]}
                />
              ) : (
                <NoDataFound />
              )}
            </MainCard>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Pending amount list</Typography>
              </Grid>
              <Grid item />
            </Grid>
            <MainCard sx={{ mt: 2 }} content={false}>
              {Object.keys(distributorDashbourd.dashbourdMain).length != 0 &&
                distributorDashbourd.dashbourdMain
                  .dtoStockStakeHolderPendingBills != null &&
                distributorDashbourd.dashbourdMain.dtoStockStakeHolderPendingBills
                  .length != 0 ? (
                <DynamicTable
                  data={
                    Object.keys(distributorDashbourd.dashbourdMain).length !=
                      0 &&
                      distributorDashbourd.dashbourdMain
                        .dtoStockStakeHolderPendingBills != null
                      ? distributorDashbourd.dashbourdMain.dtoStockStakeHolderPendingBills.map(
                        (sales, index) => {
                          return {
                            index: index + 1,
                            id:
                              sales.stockStakeHolder != null
                                ? sales.stockStakeHolder.id
                                : "",
                            name:
                              sales.stockStakeHolder != null
                                ? sales.stockStakeHolder.firmName
                                : "",
                            remainingAmt: sales.remainingAmt.toFixed(2),
                          };
                        }
                      )
                      : []
                  }
                  tableHead={[
                    {
                      title: "Sr. No.",
                      name: "index",
                      positionCenter: false,
                      showInscreen: true,
                      alignCenter: "center",
                    },
                    {
                      title: "Outlet Name",
                      name: "name",
                      positionCenter: false,
                      showInscreen: true,
                      alignCenter: "left",
                      linkFlag: true,
                    },
                    {
                      title: "Remaining Amount",
                      name: "remainingAmt",
                      positionCenter: false,
                      showInscreen: true,
                      alignCenter: "right",
                    },
                  ]}
                  showPegination={false}
                  isActionColActive={false}
                  rowLink={rowLink}
                />
              ) : (
                <NoDataFound />
              )}
            </MainCard>
          </Grid>
        </Grid>
      </Paper>
      {openTable && (
        <TablePopUp
          columns={
            flagData == 1
              ? [
                {
                  title: "Sr. No.",
                  name: "index",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "center",
                },
                {
                  title: "Order Date",
                  name: "orderDate",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "center",
                },

                {
                  title: "Outlet Name",
                  name: "firmName",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "left",
                },
                {
                  title: "Total Amount",
                  name: "totalAmt",
                  positionCenter: false,
                  showInscreen: true,
                  alignCenter: "right",
                },
              ]
              : flagData == 2
                ? [
                  {
                    title: "Sr. No.",
                    name: "index",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Sales Person Name",
                    name: "salesTeamName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },

                  {
                    title: "Check In Time",
                    name: "checkInTime",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Check Out Time",
                    name: "checkOutTime",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                ]
                : flagData == 3
                  ? [
                    {
                      title: "Sr. No.",
                      name: "index",
                      positionCenter: false,
                      showInscreen: true,
                      alignCenter: "center",
                    },
                    {
                      title: "Sales Person Name",
                      name: "salesTeamName",
                      positionCenter: false,
                      showInscreen: true,
                      alignCenter: "center",
                    },

                    {
                      title: "Contact Number",
                      name: "contactNo",
                      positionCenter: false,
                      showInscreen: true,
                      alignCenter: "center",
                    },
                    {
                      title: "Email Id",
                      name: "emailId",
                      positionCenter: false,
                      showInscreen: true,
                      alignCenter: "center",
                    },
                  ]
                  : flagData == 4 || flagData == 5
                    ? [
                      {
                        title: "Sr. No.",
                        name: "index",
                        positionCenter: false,
                        showInscreen: true,
                        alignCenter: "center",
                      },

                      {
                        title: "Outlet Name",
                        name: "firmName",
                        positionCenter: false,
                        showInscreen: true,
                        alignCenter: "left",
                      },
                      {
                        title: "Owner Name",
                        name: "ownerName",
                        positionCenter: false,
                        showInscreen: true,
                        alignCenter: "left",
                      },
                      {
                        title: "Amount",
                        name: "totalAmt",
                        positionCenter: false,
                        showInscreen: true,
                        alignCenter: "left",
                      },
                    ]
                    : flagData == 6
                      ? [
                        {
                          title: "Sr. No.",
                          name: "index",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "center",
                        },
                        {
                          title: "Product name",
                          name: "name",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                        },
                        {
                          title: "UOM",
                          name: "uom",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                        },
                        {
                          title: "Total Amount",
                          name: "totalAmt",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "right",
                        },
                      ]
                      : [
                        {
                          title: "Sr. No.",
                          name: "index",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "center",
                        },
                        {
                          title: "Beat Name",
                          name: "beatName",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                        },

                        {
                          title: "Outlet Name",
                          name: "firmName",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                        },
                        {
                          title: "Owner Name",
                          name: "ownerName",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                        },
                      ]
          }
          open={openTable}
          handleCloses={toggle("", "", "")}
          rows={rows}
          title={title}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  distributorDashbourd: state.distributorDashbourd,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getDashBourdMain,
  getDataForLineGraph,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDefault);
