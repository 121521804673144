import endpoint from "../../config/endpoints";

export const MRPConfigrationFooterJson = {
  showSaveNextBtn: false,
  apiBaseURL: endpoint.room,
  formPath: "",
  screenTitle: "",
  showTitle: false,
  showCancel: false,
  showSaveBtn: false,
  fieldMeta: [
    {
      label: "Publish Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "date",
      isMinDate: true,
      minDate: new Date(),
      isMandatory: true,
    },
  ],
};
