import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { StockHolderOrderingConfigurationJson } from "../../DynamicFormsJson/Transaction/stockHolderOrderingConfigurationJson";
import {
  getStockHolderOrderConfiguration,
  setStockHolderOrderConfiguration,
  getStockHolderOrderConfigurationFilter,
  setStockHolderByStockIdsAndAreaIds,
} from "../../Slice/stockHolderOrderConfiguration.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  saveFailedMsg,
  saveWarningMsg,
  savemsg,
  serverMsg,
  updateMsg,
} from "../../config/messageconstant";
import { StockHolderOrderConfigurationColumns } from "../../tableColumns/table-columns";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import StockHolderOrderConfigurationEdit from "./StockHolderOrderConfigurationEdit";
import { Grid, Paper } from "@mui/material";
import { ButtonCompo } from "../../components/Comman/Button";
import UpdateTimePopUp from "./UpdateTimePopUp";

class StockHolderOrderConfigurationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        stakeHolderTypeDetail:
          this.props.stakeHolderTypeList?.stockStakeHolderType,
      },
      editFlag: false,
      editData: null,
      updateTimeFlag: false,
      fieldDataForModule: {},
    };
  }

  componentDidMount() {
    const { getStockStakeHolderType, showLoador, showNotification } =
      this.props;

    if (navigator.onLine) {
      showLoador({ loador: true });
      getStockStakeHolderType({ userTypeIds: 2 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      this.getList();
      const moduleObjData = localStorage.getItem("moduleObj");
      this.setState({
        fieldDataForModule:
          moduleObjData != null ? JSON.parse(moduleObjData) : {},
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.stakeHolderTypeList !== prevProps.stakeHolderTypeList) {
      const { stockStakeHolderType } = this.props.stakeHolderTypeList;
      this.setState((prevState) => ({
        dynamicMasterData: {
          ...prevState.dynamicMasterData,
          stakeHolderTypeDetail: stockStakeHolderType,
        },
      }));
    }
  }

  getList = async () => {
    const { getStockHolderOrderConfiguration, showLoador, showNotification } =
      this.props;

    showLoador({ loador: true });
    await getStockHolderOrderConfiguration().then(({ success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      }
    });
  };

  onSearchData = async (data) => {
    const {
      getStockHolderOrderConfigurationFilter,
      showLoador,
      showNotification,
    } = this.props;

    if (navigator.onLine) {
      showLoador({ loador: true });
      await getStockHolderOrderConfigurationFilter({
        stockStakeHolderTypeIds: data.stockStakeHolderTypeIds,
        fromTime: moment(data.fromTime).format("HH:mm:ss"),
        toTime: moment(data.toTime).format("HH:mm:ss"),
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  handleCloses = () => {
    this.setState({
      editFlag: false,
      updateTimeFlag: false,
    });
  };

  onEditHandler = (row) => {
    console.log(row);
    let rowObject = {
      id: row.id,
      stockHolderId: row.stockHolderId,
      fromTime: moment(row.fromTime, "HH:mm:ss").format("HH:mm"),
      toTime: moment(row.toTime, "HH:mm:ss").format("hh:mm"),
      stockStakeHolderOwner: row.stockStakeHolderOwner,
      stockStakeHolderType: row.stockStakeHolderType,
    };

    this.setState({
      editData: rowObject,
      editFlag: true,
    });
  };

  onUpdateHandler = (row) => {
    console.log(row);
    let rowObject = {
      id: row.id,
      stockHolderId: row.stockHolderId,
      fromTime: moment(row.fromTime, "HH:mm:ss").format("HH:mm"),
      toTime: moment(row.toTime, "HH:mm:ss").format("hh:mm"),
      stockStakeHolderOwner: row.stockStakeHolderOwner,
      stockStakeHolderType: row.stockStakeHolderType,
    };

    this.setState({
      updateTimeFlag: true,
      editData: rowObject,
    });
  };

  onChangeAll = (e, name) => {
    const { stockHolderNameList, setStockHolderOrderConfiguration } =
      this.props;
    let list = stockHolderNameList?.StockHolderOrderConfiguration.map(
      (rowData) => {
        return {
          ...rowData,
          [name]: moment(e).format("HH:mm:ss"),
        };
      }
    );
    setStockHolderOrderConfiguration({ row: list });
  };

  saveHandler = (time) => {
    const { showLoador, showNotification } = this.props;
    const { editData } = this.state;
    console.log(time);
    if (navigator.onLine) {
      let dateFromTimeObj = new Date(time.fromTime);
      let dateToTimeObj = new Date(time.toTime);

      // Format the Date object into the desired format
      let formattedFromDate = `${dateFromTimeObj.getFullYear()}-${(
        dateFromTimeObj.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${dateFromTimeObj
        .getDate()
        .toString()
        .padStart(2, "0")}`;
      let formattedToDate = `${dateToTimeObj.getFullYear()}-${(
        dateToTimeObj.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${dateToTimeObj
        .getDate()
        .toString()
        .padStart(2, "0")}`;
      let formattedFromTime = `${dateFromTimeObj
        .getHours()
        .toString()
        .padStart(2, "0")}:${dateFromTimeObj
        .getMinutes()
        .toString()
        .padStart(2, "0")}:${dateFromTimeObj
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;
      let formattedToTime = `${dateToTimeObj
        .getHours()
        .toString()
        .padStart(2, "0")}:${dateToTimeObj
        .getMinutes()
        .toString()
        .padStart(2, "0")}:${dateToTimeObj
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;

      // Combine formatted date and time
      let formattedFromDateTime = ` ${formattedFromTime}`;
      let formattedToDateTime = ` ${formattedToTime}`;

      let stockHolderEditData = {
        id: editData.id,
        stockStakeHolder: {
          id: editData.stockHolderId,
        },
        fromTime: formattedFromDateTime,
        toTime: formattedToDateTime,
      };
      let error = false;

      if (!error) {
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            showLoador({ loador: true });
            apiPost({
              url: endpoint.stockHolderOrderingConfiguration,
              postBody: stockHolderEditData,
            }).then(({ data, success }) => {
              showLoador({ loador: false });
              if (success) {
                showNotification({ msg: updateMsg });
                this.getList();
                this.setState({
                  editFlag: false,
                });
              } else {
                showNotification({
                  msg: saveFailedMsg,
                  severity: "error",
                });
              }
              return success;
            });
          }
        });
      }
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  onSubmit = () => {
    const { stockHolderNameList } = this.props;
    const { editData } = this.state;
    if (navigator.onLine) {
      let dataToSave = [];
      stockHolderNameList?.StockHolderOrderConfiguration.map(
        (stockHolderObject) => {
          console.log(stockHolderObject);
          console.log(stockHolderObject.toTime);

          if (
            stockHolderObject.fromTime &&
            stockHolderObject.fromTime !== "" &&
            stockHolderObject.toTime &&
            stockHolderObject.toTime !== ""
          ) {
            let dateFromTimeObj = new Date(stockHolderObject.fromTime);
            let dateToTimeObj = new Date(stockHolderObject.toTime);

            let formattedFromDate = `${dateFromTimeObj.getFullYear()}-${(
              dateFromTimeObj.getMonth() + 1
            )
              .toString()
              .padStart(2, "0")}-${dateFromTimeObj
              .getDate()
              .toString()
              .padStart(2, "0")}`;
            let formattedToDate = `${dateToTimeObj.getFullYear()}-${(
              dateToTimeObj.getMonth() + 1
            )
              .toString()
              .padStart(2, "0")}-${dateToTimeObj
              .getDate()
              .toString()
              .padStart(2, "0")}`;
            let formattedFromTime = `${dateFromTimeObj
              .getHours()
              .toString()
              .padStart(2, "0")}:${dateFromTimeObj
              .getMinutes()
              .toString()
              .padStart(2, "0")}:${dateFromTimeObj
              .getSeconds()
              .toString()
              .padStart(2, "0")}`;
            let formattedToTime = `${dateToTimeObj
              .getHours()
              .toString()
              .padStart(2, "0")}:${dateToTimeObj
              .getMinutes()
              .toString()
              .padStart(2, "0")}:${dateToTimeObj
              .getSeconds()
              .toString()
              .padStart(2, "0")}`;

            let formattedFromDateTime = `${formattedFromTime}`;
            let formattedToDateTime = ` ${formattedToTime}`;

            console.log(
              "Converted time:",
              formattedFromTime + "" + formattedToTime
            );
            let stockHolderData = {
              id: stockHolderObject.id,
              stockStakeHolder: {
                id: stockHolderObject.stockHolderId,
              },
              fromTime: stockHolderObject.fromTime,
              toTime: stockHolderObject.toTime,
            };
            dataToSave.push(stockHolderData);
          }
        }
      );
      console.log(dataToSave);
      if (dataToSave.length !== 0) {
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            this.onSave(dataToSave);
          }
        });
      }
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  onSave = (dataToSave) => {
    const { showLoador, showNotification, navigate } = this.props;
    console.log(dataToSave);
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.stockHolderOrderingConfiguration + "/save-list",
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg });
          this.setState({
            updateTimeFlag: false,
          });
          this.getList();
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  render() {
    const {
      StockHolderOrderConfigurationList,
      stakeHolderTypeList,
      stockHolderNameList,
    } = this.props;
    const {
      dynamicMasterData,
      editData,
      editFlag,
      updateTimeFlag,
      fieldDataForModule,
    } = this.state;

    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicMainScreen
            formPath={StockHolderOrderingConfigurationJson.formPath}
            showExcelDownload={false}
            showPdfDownload={false}
            onSearchData={this.onSearchData}
            dynamicMasterData={dynamicMasterData}
            searchList={StockHolderOrderingConfigurationJson.searchList}
            showViewButtonInTable={false}
            formPathView={false}
            screenTitle={StockHolderOrderingConfigurationJson.screenTitle}
            showAddButton={StockHolderOrderingConfigurationJson.showAddButton}
            tableColumns={StockHolderOrderConfigurationColumns}
            tableData={
              StockHolderOrderConfigurationList?.StockHolderOrderConfiguration
            }
            apiBaseURL={StockHolderOrderingConfigurationJson.apiBaseURL}
            callApi={false}
            getTableDataById={true}
            showApply={false}
            rowApply={false}
            rowDelete={this.rowDelete}
            isNote={true}
            isNoteValue={"Add Stock Holder Ordering Configuration"}
            searchButtonGrid={
              StockHolderOrderingConfigurationJson.searchButtonGrid
            }
            getTableData={this.getList}
            rowEdit={this.onEditHandler}
          />
          <>
            <br />
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              {fieldDataForModule.editReject == 1 ? (
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Update  Time"
                  onClick={this.onUpdateHandler}
                />
              ) : (
                " "
              )}
            </Grid>
          </>
          {editFlag && (
            <>
              <StockHolderOrderConfigurationEdit
                open={editFlag}
                handleCloses={this.handleCloses}
                onSave={this.saveHandler}
                editData={editData}
              />
            </>
          )}
          {updateTimeFlag && (
            <>
              <UpdateTimePopUp
                open={updateTimeFlag}
                handleCloses={this.handleCloses}
                onSave={this.onSubmit}
                editData={editData}
                onChangeAll={this.onChangeAll}
                data={stockHolderNameList?.StockHolderOrderConfiguration}
              />
            </>
          )}
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  StockHolderOrderConfigurationList: state.stockHolderOrderConfiguration,
  stakeHolderTypeList: state.stockStakeHolderType,
  stockHolderNameList: state.stockHolderOrderConfiguration,
});

const mapDispatchToProps = {
  getStockHolderOrderConfiguration,
  setStockHolderByStockIdsAndAreaIds,
  getStockStakeHolderType,
  getStockHolderOrderConfigurationFilter,
  setStockHolderOrderConfiguration,
  showLoador,
  showNotification,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockHolderOrderConfigurationList);
