export const ClaimStructureAllotmentJson = {
    screenTitle: "Claim Structure Assignation",

    fieldMeta: [
        {
            label: "Claim Structure",
            controlType: "autocomplete",
            placeHolder: "Select Claim Structure",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "claimStructureDetail",
            dataKey: "claimStructure",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Stock Holder Type",
            controlType: "autocomplete",
            placeHolder: "Select Stock Holder Type",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "stockHolderTypeDetail",
            dataKey: "stockHolderType",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Region",
            controlType: "autocomplete",
            placeHolder: "Select Region",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "regionDetail",
            dataKey: "region",
            getListId: "region",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "State",
            controlType: "autocomplete",
            placeHolder: "Select State",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "stateDetail",
            dataKey: "state",
            getListFrom: "region",
            getListId: "state",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Zone",
            controlType: "multiSelect",
            placeHolder: "Select Zone",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "zoneDetail",
            dataKey: "zone",
            getListFrom: "state",
            getListId: "zone",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "District",
            controlType: "multiSelect",
            placeHolder: "Select District",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "districtDetail",
            dataKey: "district",
            getListFrom: "zone",
            getListId: "district",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Taluka",
            controlType: "multiSelect",
            placeHolder: "Select Taluka",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "talukaDetail",
            dataKey: "taluka",
            getListFrom: "district",
            getListId: "taluka",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Area",
            controlType: "multiSelect",
            placeHolder: "Select Area",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "areaDetail",
            dataKey: "area",
            getListFrom: "taluka",
            getListId: "area",
            isRootLevelKey: true,
            isMandatory: true,
        },

    ],

};
