import { MenuItem, Select, Checkbox, ListItemText } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
export default function RTSelectMultiple({options = [],onChange}) {
    function onColumnFilterChange(selectedOption) {
        onChange(selectedOption);
    }

    return (
        <>
            <Select
                sx={{ width: '100%' }}
                multiple
                size="small"
                renderValue={(selected) => { return selected.map((item) => item.columnDisplayName).join(","); }}
                MenuProps={MenuProps}
                value={options.filter(item => item.isChecked)}
            >
                {options.map((option) => {
                    return (
                        <MenuItem onClick={() => onColumnFilterChange(option)} key={option.columnKeyName} value={option.columnKeyName}>
                            <Checkbox checked={option.isChecked} />
                            <ListItemText primary={option.columnDisplayName} />
                        </MenuItem>
                    );
                })}
            </Select>
        </>
    );
}