import { Divider, Dialog, DialogContent, Grid, Paper, styled, IconButton } from "@mui/material";
import { DialogTitle, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { getAuditTransactionData } from "../../Slice/audit.slice";
import NoDataFound from "../DashbourdChanges/NoDataFound";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { apiPost } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
import CloseIcon from "@mui/icons-material/Close";
import { blackColor } from "../../config/ColorObj";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { classes, children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


class QuestionViewPopUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DataToSet: [],
            response: [],
            questionData: {},
            openPopUp: false,
        };

    }
    async componentDidMount() {
        const { dataRow, showLoador, showNotification } = this.props;
        if (navigator.onLine) {

            let distributorInquiryIdsData = {
                distributorInquiryIds: dataRow.id.split(",")
            }

            showLoador({ loador: true });
            apiPost({
                url: endpoint.distributorInquiry + "/with-audit-head-quetion-and-questionary-by-distributor-inquiries",
                postBody: distributorInquiryIdsData,
            }).then(({ data, success }) => {
                console.log(data.data);
                this.setState({ openPopUp: true });
                showLoador({ loador: false });
                if (success) {
                    this.setState({ questionData: data.data })
                } else {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });

        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }


    handleClose = () => {
        const { handleCloses } = this.props;
        handleCloses();
    }


    render() {
        const { dataRow, open } = this.props;
        const { questionData, openPopUp } = this.state;
        return (
            <>
                <BootstrapDialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={openPopUp}
                    fullWidth
                    maxWidth="lg"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={this.handleClose}
                    >
                        Question
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Typography style={{ fontSize: 24, color: blackColor }}>{dataRow.fullName}</Typography>
                        <br />

                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '20px',
                                justifyContent: 'flex-start'
                            }}
                        >

                            <div
                                style={{
                                    background: '#ffffff',
                                    borderRadius: '10px 10px 0 0',
                                    color: '#fff',
                                    padding: '10px',
                                    textAlign: 'center',
                                }}
                            >
                                {questionData && questionData.auditHeadDTO && questionData.auditHeadDTO.questionaryTransactions && questionData.auditHeadDTO.questionaryTransactions.length !== 0 ?

                                    (questionData.auditHeadDTO.questionaryTransactions.map((tnxObj, tnxIndex) => {
                                        return tnxObj.questionaryTransactrionDetails.length !== 0 &&
                                            tnxObj.questionaryTransactrionDetails.map((detailsObj, detailsIndex) => {
                                                let answerForMultiSelect =
                                                    detailsObj.auditQuestion.auditQuestionOption === null
                                                        ? ''
                                                        : detailsObj.auditQuestion.auditQuestionOption.map((object) => {
                                                            const auditQuestionOptionValueList = detailsObj.auditQuestionOptionValue.split(',');
                                                            const matchedValue = auditQuestionOptionValueList.filter((row) => row === object.id);
                                                            return matchedValue.length !== 0 ? object.optionTitle : '';
                                                        });

                                                let answer =
                                                    detailsObj.auditQuestion && detailsObj.auditQuestion.optionType === null
                                                        ? ''
                                                        : detailsObj.auditQuestion.optionType == 0
                                                            ? detailsObj.auditQuestionOptionValue
                                                            : detailsObj.auditQuestion.auditQuestionOption.find((row) => row.id === detailsObj.auditQuestionOptionValue)?.optionTitle ||
                                                            '';

                                                return (
                                                    <React.Fragment key={{ tnxIndex } - { detailsIndex }}>
                                                        <div
                                                            style={{
                                                                background: '#ffffff',
                                                                borderRadius: '5px',
                                                                padding: '10px',
                                                                marginBottom: '10px',
                                                                marginTop: '10px',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    color: '#333',
                                                                    fontSize: '18px',
                                                                    fontFamily: 'Poppins, sans-serif',
                                                                    fontWeight: '500',
                                                                    marginBottom: '8px',
                                                                    justifyContent: 'flex-start',
                                                                    display: 'flex',
                                                                }}
                                                            >
                                                                {detailsIndex + 1 + ') ' + detailsObj.auditQuestion.question}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    color: '#555',
                                                                    fontSize: '18px',
                                                                    fontFamily: 'Poppins, sans-serif',
                                                                    fontWeight: '300',
                                                                    justifyContent: 'flex-start',
                                                                    display: 'flex',
                                                                }}
                                                            >
                                                                {'Ans - ' +
                                                                    (detailsObj.auditQuestion.optionType == 2
                                                                        ? detailsObj.auditQuestionOptionValue.split(',').length == 1
                                                                            ? answerForMultiSelect.join('  ')
                                                                            : answerForMultiSelect.join(' , ')
                                                                        : answer)}
                                                            </div>
                                                        </div>

                                                        <Divider style={{ marginTop: -10 }} />
                                                    </React.Fragment>
                                                );
                                            });
                                    }
                                    )
                                    ) : (<NoDataFound />)

                                }
                            </div>

                        </div>
                    </DialogContent>
                </BootstrapDialog >
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    auditList: state.audit,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getAuditTransactionData,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(QuestionViewPopUp);
