import {
    Grid,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import "../../CSS/app.min.css";
import "../../CSS/bootstrap.min.css";
import "../../CSS/custom.min.css";
import "../../CSS/mermaid.min.css";
import "../../CSS/slickDashboard.css";
import { onToggle } from "../../CommonActions/common.slice";
import image4 from "../../Images/sales.png";
import image5 from "../../Images/store.png";
import image6 from "../../Images/builders.png";
import image7BgClr from "../../Images/technician.png";
import target1 from "../../Images/target_new.png";
import {
    showLoador,
    showNotification,
} from "../../Pages/Landing/Landing.slice";
import {
    getMonthYearTarget,
    getMonthYearTargetForSales,
    getRegionListFromYearAndMonth,
    getSalesGraph,
    getSalesListFromYearAndMonth,
    getSalesTeamInfoByDatesNew,
    getStateRegionwiseListFromYearAndMonth,
    getZoneListFromYearAndMonth,
    getZoneStatewiseListFromYearAndMonth,
    getDistrictListFromYearAndMonth,
    setRegionListFromYearAndMonth,
    getTalukaListFromYearAndMonth,
} from "../../Slice/adminDashboard.slice";
import { getBeatFromSalesTeamId } from "../../Slice/beat.slice";
import {
    getCategorywiseBill,
    getPackagingType,
} from "../../Slice/billingHistory.slice";
import {
    getAdminDashboard,
    getTopProductOrderListFromYearAndMonthBillWise,
    getTopProductForAdminDashboard,
} from "../../Slice/dashboard.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import {
    getCategoryByPackagingType,
    getSubCategoryPackagingType,
} from "../../Slice/subCategory.slice";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { ConvertAmount } from "../../components/Comman/ConvertValueWithDenominator";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import MonthCalender from "../../components/MonthCalender";
import SearchBar from "../../components/Search";
import { cyanBlue, redColor, titleColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import BeatPopUp from "../DashbourdChanges/BeatPopUp";
import MonthlyBarChart from "../DashbourdChanges/MonthlyBarChart";
import NoDataFound from "../DashbourdChanges/NoDataFound";
import PieChart from "../DashbourdChanges/PieChart";
import { downloadF } from "../PDF/PDFDownload";
import CategoryPopup from "./CategoryPopup";
import HiearchyDropDown from "./HiearchyDropDown";
import SubCategoryPackagingTypePopup from "./SubCategoryPackagingTypePopup";
import ReactApexChart from "react-apexcharts";

class AnalyticalAdminDashboard extends Component {
    toggleDrawer = () => {
        this.props.onToggle();
    };
    state = {
        openFlag: false,
        showFeedback: false,
        showPreview: false,
        date1: new Date(),
        newDate: "",
        currentDate: new Date(),
        curDate: new Date(),
        dob: "",
        dobDay: "",
        dobMonth: "",
        currenDate: "",
        currentMonth: "",
        timeTableList: [],
        subject: "",
        batch: "",
        yearDetail: "",
        semester: "",
        division: "",
        pieChartData: [],
        isLoading: false,
        showLoader: false,
        profile: "",
        nameFirstLetter: "",
        showZone: false,
        showArea: false,
        popUpFlag: false,
        popUpFlagCategory: false,
        beatPopupFlag: false,
        date: "",
        saleDate: "",
        productArea: "",
        productZone: "",
        outletArea: "",
        outletZone: "",
        outletDate: "",
        monthDate: "",
        yearDate: "",
        type: "",
        saleMonth: dayjs(),
        saleYear: "",
        month: "",
        year: "",
        searchValue: "",
        searchValueForZone: "",
        searchValueForSalesRegion: "",
        searchValueForZoneDetails: "",
        searchValueForAreaDetails: "",
        searchValuePersonWiseSales: "",
        searchValueStateList: "",
        searchValueForProduct: "",
        searchValueForSalesTeamList: "",
        searchValueForOutlets: "",
        regionListFromYearAndMonthData: [],
        regionListFromYearAndMonthDataForZone: [],
        regionListFromYearAndMonthDataForSalesRegion: [],
        stateListFromYearAndMonth: [],
        zoneDetailsSalesData: [],
        stateDetailsData: [],
        zoneDetailsRegionwiseData: [],
        districtDetailsZoneWiseData: [],
        districtDetailsData: [],
        talukaDetailsData: [],
        areaDetailsListData: [],
        salesGraphData: [],
        salesMonthData: [],
        areaDetailsData: [],
        productList: [],
        topPerformingOutletData: [],
        salesTeamData: [],
        formErrors: {},
        graphList: [],
        radioValue: [],
        lineGraphData: [],
        productList: [],
        salesRegionName: "",
        totalOutletCount: 0,
        zero: 0,
        productiveCall: 0,
        unvisited: 0,
        totalAmount: 0,
        salesAmt: 0,
        salesListFromYearAndMonthOnSearch: false,
        fromDate: dayjs(),
        toDate: dayjs(),
        productDataOnSearchFlag: false,
        monthList: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ],
        columns: [
            { id: "name", label: "Name", minWidth: 170 },
            { id: "code", label: "ISO\u00a0Code", minWidth: 100 },
            {
                id: "population",
                label: "Population",
                minWidth: 170,
                align: "right",
                format: (value) => value.toLocaleString("en-US"),
            },
            {
                id: "size",
                label: "Size\u00a0(km\u00b2)",
                minWidth: 170,
                align: "right",
                format: (value) => value.toLocaleString("en-US"),
            },
            {
                id: "density",
                label: "Density",
                minWidth: 170,
                align: "right",
                format: (value) => value.toFixed(2),
            },
        ],
        rows: [
            this.createData("India", "IN", 1324171354, 3287263),
            this.createData("China", "CN", 1403500365, 9596961),
            this.createData("Italy", "IT", 60483973, 301340),
            this.createData("United States", "US", 327167434, 9833520),
            this.createData("Canada", "CA", 37602103, 9984670),
            this.createData("Australia", "AU", 25475400, 7692024),
            this.createData("Germany", "DE", 83019200, 357578),
            this.createData("Ireland", "IE", 4857000, 70273),
            this.createData("Mexico", "MX", 126577691, 1972570),
            this.createData("Japan", "JP", 126317000, 377973),
            this.createData("France", "FR", 67022000, 640679),
            this.createData("United Kingdom", "GB", 67545757, 242495),
            this.createData("Russia", "RU", 146793744, 17098246),
            this.createData("Nigeria", "NG", 200962417, 923768),
            this.createData("Brazil", "BR", 210147125, 8515767),
        ],
        rowsPerPage: 10,
        page: 0,
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: +event.target.value,
            page: 0,
        });
    };
    createData(name, code, population, size) {
        const density = population / size;
        return { name, code, population, size, density };
    }

    async componentDidMount() {
        const {
            getAdminDashboard,
            showLoador,
            showNotification,
            getCategorywiseBill,
            getPackagingType,
            getStockStakeHolderType,
            getRegionListFromYearAndMonth,
            getSalesListFromYearAndMonth,
            getZoneListFromYearAndMonth,
            getZoneStatewiseListFromYearAndMonth,
            getStateRegionwiseListFromYearAndMonth,
            getDistrictListFromYearAndMonth,
            getMonthYearTarget,
            getSalesGraph,
            getMonthYearTargetForSales,
            getSalesTeamInfoByDatesNew,
            dashboardData,
            getTalukaListFromYearAndMonth,
            getTopProductForAdminDashboard,
        } = this.props;
        const { year, month, regionId } = this.state;

        if (navigator.onLine) {
            const adminDashboard = localStorage.getItem("adminDashboard");
            const adminDashboardData = JSON.parse(adminDashboard);

            const stockStakeHolderTypeListData = localStorage.getItem(
                "stockStakeHolderTypeListData"
            );
            const stockStakeHolderTypeListLocalStorage = JSON.parse(
                stockStakeHolderTypeListData
            );

            const regionListFromYearAndMonth = localStorage.getItem(
                "regionListFromYearAndMonth"
            );
            const regionListFromYearAndMonthLocalStorage = JSON.parse(
                regionListFromYearAndMonth
            );

            const categoryWiseBillData = localStorage.getItem("categoryWiseBillData");
            const categoryWiseBillDataLocalStorage = JSON.parse(categoryWiseBillData);

            const packagingTypeData = localStorage.getItem("packagingTypeData");
            const packagingTypeDataLocalStorage = JSON.parse(packagingTypeData);

            const monthYearTargetData = localStorage.getItem("monthYearTargetData");
            const monthYearTargetDataLocalStorage = JSON.parse(monthYearTargetData);

            if (!adminDashboardData) {
                showLoador({ loador: true });
                await getAdminDashboard().then(({ data, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    } else {
                        if (data !== null && Object.keys(data).length !== 0) {
                            localStorage.setItem("adminDashboard", JSON.stringify(data));
                        } else {
                            localStorage.setItem("adminDashboard", JSON.stringify([]));
                        }
                    }
                });

                await getTopProductForAdminDashboard().then(({ data, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    } else {
                    }
                });
            }

            if (!stockStakeHolderTypeListLocalStorage) {
                showLoador({ loador: true });
                getStockStakeHolderType({ userTypeIds: "4" }).then(
                    ({ response, success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        } else {
                            const stockStakeHolderTypeList =
                                response.length !== 0 ? response : [];
                            localStorage.setItem(
                                "stockStakeHolderTypeListData",
                                JSON.stringify(stockStakeHolderTypeList)
                            );
                            localStorage.setItem(
                                "stockStakeHolderTypeId",
                                response.length !== 0 ? response[0].id : ""
                            );

                            this.setState({
                                type: response.length !== 0 ? response[0].id : "",
                            });

                            showLoador({ loador: true });
                            getSalesListFromYearAndMonth({
                                month: dayjs().format("MM"),
                                year: dayjs().format("YYYY"),
                                stockStakeHolderTypeId: response[0].id,
                            }).then(({ response, success }) => {
                                showLoador({ loador: false });
                                if (!success) {
                                    showNotification({ msg: serverMsg, severity: "error" });
                                } else {
                                    const salesList = response ? response : [];
                                    localStorage.setItem(
                                        "salesListFromYearAndMonth",
                                        JSON.stringify(salesList)
                                    );
                                }
                            });
                        }
                    }
                );
            }

            if (!regionListFromYearAndMonthLocalStorage) {
                //1
                showLoador({ loador: true });
                getRegionListFromYearAndMonth({
                    month: dayjs().format("MM"),
                    year: dayjs().format("YYYY"),
                }).then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    } else {
                        this.setState({
                            regionListFromYearAndMonthData: response,
                            regionListFromYearAndMonthDataForZone: response,
                            regionListFromYearAndMonthDataForSalesRegion: response,
                            stateListFromYearAndMonth: response,
                            salesRegionName: response.length !== 0 ? response[0].name : "",
                            regionId: response[0].id,
                        });

                        getSalesGraph({
                            month: dayjs().format("MM"),
                            year: dayjs().format("YYYY"),
                            regionId: response.length !== 0 ? response[0].id : "",
                        }).then(({ response, success }) => {
                            if (!success) {
                                showNotification({ msg: serverMsg, severity: "error" });
                            } else {
                                let graphData = [];
                                let monthData = [];

                                response.length !== 0
                                    ? response.map((responseObject) => {
                                        graphData.push(responseObject.achivedAmt);
                                        monthData.push(responseObject.monthString);
                                    })
                                    : [];

                                localStorage.setItem("salesGraphData", graphData);
                                localStorage.setItem("salesMonthData", monthData);

                                this.setState({
                                    salesGraphData: graphData,
                                    salesMonthData: monthData,
                                });
                            }
                        });

                        getZoneListFromYearAndMonth({
                            month: dayjs().format("MM"),
                            year: dayjs().format("YYYY"),
                            regionId: response[0].id,
                        }).then(({ response, success }) => {
                            showLoador({ loador: false });
                            if (!success) {
                                showNotification({ msg: serverMsg, severity: "error" });
                            } else {
                                localStorage.setItem(
                                    "zoneDetailsSalesData",
                                    response.length !== 0 ? response : []
                                );
                                localStorage.setItem(
                                    "zoneListFromYearAndMonthTotal",
                                    dashboardData?.zoneListFromYearAndMonthTotal
                                );

                                this.setState({
                                    zoneDetailsSalesData: response.length !== 0 ? response : [],
                                });
                            }
                        });

                        //2

                        // getStateRegionwiseListFromYearAndMonth({
                        //   month: dayjs().format("MM"),
                        //   year: dayjs().format("YYYY"),
                        //   regionId: response[0].id,
                        // }).then(({ response, success }) => {
                        //   if (!success) {
                        //     showNotification({ msg: serverMsg, severity: "error" });
                        //   } else {
                        //     this.setState({
                        //       stateListFromYearAndMonth:
                        //         response.length !== 0 ? response : [],
                        //     });

                        //3
                        // if (response.length !== 0) {
                        //   getZoneStatewiseListFromYearAndMonth({
                        //     month: dayjs().format("MM"),
                        //     year: dayjs().format("YYYY"),
                        //     stateId: response[0].id,
                        //   }).then(({ response, success }) => {
                        //     if (!success) {
                        //       showNotification({ msg: serverMsg, severity: "error" });
                        //     } else {
                        //       this.setState({
                        //         zoneDetailsRegionwiseData:
                        //           response.length !== 0 ? response : [],
                        //       });
                        //       getDistrictListFromYearAndMonth({
                        //         month: dayjs().format("MM"),
                        //         year: dayjs().format("YYYY"),
                        //         zoneId: response[0].id,
                        //       }).then(({ response, success }) => {
                        //         if (!success) {
                        //           showNotification({ msg: serverMsg, severity: "error" });
                        //         } else {
                        //           this.setState({
                        //             districtDetailsZoneWiseData:
                        //               response.length !== 0 ? response : [],
                        //           });

                        //           getTalukaListFromYearAndMonth({
                        //             month: dayjs().format("MM"),
                        //             year: dayjs().format("YYYY"),
                        //             districtId: response[0].id,
                        //           }).then(({ response, success }) => {
                        //             if (!success) {
                        //               showNotification({ msg: serverMsg, severity: "error" });
                        //             } else {

                        //               this.setState({
                        //                 talukaDetailsDistrictWiseData:
                        //                   response.length !== 0 ? response : [],
                        //               });

                        //             }
                        //           });
                        //         }
                        //       });
                        //     }
                        //   });
                        // }

                        // }
                        // });
                    }
                });
            }

            if (!categoryWiseBillDataLocalStorage) {
                showLoador({ loador: true });
                getCategorywiseBill({
                    year: dayjs().format("YYYY"),
                    month: dayjs().format("MM"),
                    regionId: "",
                }).then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    } else {
                        const categoryWiseBillList = response.length !== 0 ? response : [];
                        localStorage.setItem(
                            "categoryWiseBillData",
                            JSON.stringify(categoryWiseBillList)
                        );

                        this.setState({
                            categoryWiseBillData: response.length !== 0 ? response : [],
                        });
                    }
                });
            }

            if (!packagingTypeDataLocalStorage) {
                getPackagingType({
                    year: dayjs().format("YYYY"),
                    month: dayjs().format("MM"),
                    regionId: "",
                }).then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    } else {
                        const packagingTypeDataList = response.length !== 0 ? response : [];
                        localStorage.setItem(
                            "packagingTypeData",
                            JSON.stringify(packagingTypeDataList)
                        );

                        this.setState({
                            packagingTypeData: response.length !== 0 ? response : [],
                        });
                    }
                });
            }

            if (!monthYearTargetDataLocalStorage) {
                showLoador({ loador: true });
                getMonthYearTarget().then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    } else {
                        if (response.length !== 0) {
                            let totalTargetNewList = [];
                            let totalAchievedNewList = [];
                            for (var i = 0; i < response.length; i++) {
                                totalAchievedNewList.push(+response[i].achivedAmt);
                                totalTargetNewList.push(+response[i].targetAmt);
                            }

                            let radioValue = [
                                {
                                    name: "Target",
                                    data: totalTargetNewList,
                                },
                                {
                                    name: "Achieved",
                                    data: totalAchievedNewList,
                                },
                            ];

                            const monthYearTargetList =
                                radioValue.length !== 0 ? radioValue : [];
                            localStorage.setItem(
                                "monthYearTargetData",
                                JSON.stringify(monthYearTargetList)
                            );

                            this.setState({
                                radioValue: [
                                    {
                                        name: "Target",
                                        data: totalTargetNewList,
                                    },
                                    {
                                        name: "Achieved",
                                        data: totalAchievedNewList,
                                    },
                                ],
                            });
                        }
                    }
                });
            }
        } else {
            showNotification({ msg: myConstClass.noInternetMsg, severity: "error" });
        }
    }

    handleClosesSalesTeam = () => {
        this.setState({
            beatPopupFlag: false,
        });
    };

    salesTeamHandler = (e, salesTeamId) => {
        const { getBeatFromSalesTeamId, showLoador, showNotification } = this.props;
        const { popUpFlag } = this.state;
        if (navigator.onLine) {
            showLoador({ loador: true });
            getBeatFromSalesTeamId({
                salesTeamIds: salesTeamId,
            }).then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    this.setState({
                        beatPopupFlag: true,
                    });
                }
            });
        } else {
            showNotification({ msg: myConstClass.noInternetMsg, severity: "error" });
        }
    };
    regionWiseSalesHandler = (e, regionId) => {
        const {
            getZoneListFromYearAndMonth,
            showLoador,
            showNotification,
            getSalesGraph,
        } = this.props;
        const { month, year, saleMonth } = this.state;
        let graphData = [];
        if (navigator.onLine) {
            showLoador({ loador: true });
            getZoneListFromYearAndMonth({
                month: month !== "" ? month : saleMonth.format("MM"),
                year: year !== "" ? year : saleMonth.format("YYYY"),
                regionId: regionId,
            }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    this.setState({
                        showZone: true,
                        showArea: false,
                        zoneDetailsSalesData: response.length !== 0 ? response : [],
                        salesRegionName:
                            response.length !== 0 ? response[0].region.name : "",
                    });
                }
            });
            showLoador({ loador: true });
            getSalesGraph({
                month: month !== "" ? month : saleMonth.format("MM"),
                year: year !== "" ? year : saleMonth.format("YYYY"),
                regionId: regionId,
            }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    let graphData = [];
                    let monthData = [];
                    response.length !== 0
                        ? response.map((responseObject) => {
                            graphData.push(responseObject.achivedAmt);
                            monthData.push(responseObject.monthString);
                        })
                        : [];

                    this.setState({
                        salesGraphData: graphData,
                        salesMonthData: monthData,
                    });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };
    viewProfile = () => {
        window.location.replace("/empPreview");
    };
    GoBack = () => {
        this.setState({
            showPreview: false,
        });
    };
    openFeedBack = (e) => {
        e.preventDefault();
        this.setState({
            showFeedback: true,
        });
    };

    ChangeHandlerSearch = (name, newValue) => {
        if (newValue !== null) {
            this.setState({ [name]: newValue });
        } else {
            this.setState({ [name]: "" });
        }
    };
    topProductHandler = (e, productId) => {
        const { saleMonth } = this.state;
        const { showLoador, showNotification } = this.props;
        showLoador({ loador: true });
        apiGet({
            url:
                endpoint.product +
                "/get-month-wise-bill-value-by-month-year-and-product?month=" +
                saleMonth.format("MM") +
                "&year=" +
                saleMonth.format("YYYY") +
                "&productId=" +
                productId,
        }).then(({ data, success }) => {
            showLoador({ loador: false });
            this.setState({
                productList: data.data,
            });
        });
    };

    salesHandler = (e, id) => {
        const { getMonthYearTargetForSales, showLoador, showNotification } =
            this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            getMonthYearTargetForSales({ salesTeamId: id }).then(
                ({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        // showNotification({ msg: serverMsg, severity: "error" });
                    } else {
                        let lineGraphDataNew = [];
                        for (var i = 0; i < response.length; i++) {
                            lineGraphDataNew.push(+response[i].achivedAmt);
                        }
                        this.setState({
                            lineGraphData: lineGraphDataNew,
                        });
                    }
                }
            );
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };
    salesTeamGraphHandler = (e, regionId) => {
        const { getZoneListFromYearAndMonth, showLoador, showNotification } =
            this.props;
        const { month, year } = this.state;
        if (navigator.onLine) {
            showLoador({ loador: true });
            getZoneListFromYearAndMonth({
                month: month,
                year: year,
                regionId: regionId,
            }).then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    // showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    this.setState({
                        showZone: true,
                        showArea: false,
                    });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };
    onChangeRadioValue = (value) => {
        console.log(value);
        if (value == "0") {
            // let zeroRadioValue = [
            //   {
            //     name: "Target",
            //     data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
            //   },
            //   {
            //     name: "Achieved",
            //     data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
            //   },
            // ];

            // localStorage.setItem("radioValue", zeroRadioValue);
            this.setState({
                radioValue: [
                    {
                        name: "Target",
                        data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
                    },
                    {
                        name: "Achieved",
                        data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
                    },
                ],
            });
        } else if (value == "1") {
            // let oneRadioValue = [
            //   {
            //     name: "Target",
            //     data: [50, 60, 57, 56, 61, 58, 63, 60, 66],
            //   },
            //   {
            //     name: "Achieved",
            //     data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
            //   },
            // ];

            // localStorage.setItem("radioValue", oneRadioValue);
            this.setState({
                radioValue: [
                    {
                        name: "Target",
                        data: [50, 60, 57, 56, 61, 58, 63, 60, 66],
                    },
                    {
                        name: "Achieved",
                        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
                    },
                ],
            });
        } else if (value == "2") {
            // let secondRadioValue = [
            //   {
            //     name: "Target",
            //     data: [80, 70, 57, 56, 61, 58, 63, 60, 66],
            //   },
            //   {
            //     name: "Achieved",
            //     data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
            //   },
            // ];

            // localStorage.setItem("radioValue", secondRadioValue);

            this.setState({
                radioValue: [
                    {
                        name: "Target",
                        data: [80, 70, 57, 56, 61, 58, 63, 60, 66],
                    },
                    {
                        name: "Achieved",
                        data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
                    },
                ],
            });
        } else if (value == "3") {
            // let thirdRadioValue =  [
            //   {
            //     name: "Target",
            //     data: [10, 20, 57, 56, 61, 58, 63, 60, 66],
            //   },
            //   {
            //     name: "Achieved",
            //     data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
            //   },
            // ];

            // localStorage.setItem("radioValue", thirdRadioValue);

            this.setState({
                radioValue: [
                    {
                        name: "Target",
                        data: [10, 20, 57, 56, 61, 58, 63, 60, 66],
                    },
                    {
                        name: "Achieved",
                        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
                    },
                ],
            });
        }
    };

    onDateChange = (date, name) => {
        const stockStakeHolderType = localStorage.getItem("stockStakeHolderTypeId");

        this.setState({
            month: date.format("MM"),
            year: date.format("YYYY"),
            saleMonth: date,
            showZone: false,
            showArea: false,
        });

        const {
            getRegionListFromYearAndMonth,
            getSalesListFromYearAndMonth,
            getTopProductOrderListFromYearAndMonthBillWise,
            showLoador,
            showNotification,
        } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            getRegionListFromYearAndMonth({
                month: date.format("MM"),
                year: date.format("YYYY"),
            }).then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });

            showLoador({ loador: true });
            getTopProductOrderListFromYearAndMonthBillWise({
                month: date.format("MM"),
                year: date.format("YYYY"),
            }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    this.setState({
                        productDataOnSearchFlag: true,
                    });
                }
            });

            getSalesListFromYearAndMonth({
                month: date.format("MM"),
                year: date.format("YYYY"),
                stockStakeHolderTypeId: stockStakeHolderType,
            }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    this.setState({
                        salesListFromYearAndMonthOnSearch: true,
                    });
                }
            });
        } else {
            showNotification({ msg: myConstClass.noInternetMsg, severity: "error" });
        }
    };

    columns = [
        {
            name: "index",
            title: "Sr.No",
            positionCenter: false,
            showInscreen: true,
            alignCenter: "center",
        },

        {
            name: "name",
            title: " Product Name",
            positionCenter: false,
            showInscreen: true,
            alignCenter: "right",
        },
        {
            name: "code",
            title: "Product Code",
            positionCenter: false,
            showInscreen: true,
            alignCenter: "left",
        },
        {
            name: "actualSale",
            title: "Acheived",
            positionCenter: false,
            showInscreen: true,
            alignCenter: "right",
        },
        {
            name: "contribution",
            title: "% Contribution",
            positionCenter: false,
            showInscreen: true,
            alignCenter: "right",
        },
    ];
    graphList = [{ x: 1, y: 64 }];
    onChangeMonthOrYear = (data, name) => {
        this.setState({
            [name]: data,
        });
    };

    onClickLineChart = (data) => { };

    onSearch = (searchData) => {
        const { dashboardData, setRegionListFromYearAndMonth } = this.props;
        const { regionListFromYearAndMonthData } = this.state;
        if (searchData != "" || searchData != undefined) {
            this.setState({
                searchValue: searchData,
            });
            const list = dashboardData.regionListFromYearAndMonth.filter(
                (currentRow) => {
                    let isValid = false;
                    if (
                        currentRow["name"] &&
                        currentRow["name"]
                            .toString()
                            .toLowerCase()
                            .includes(searchData.toLowerCase())
                    ) {
                        isValid = true;
                        return true;
                    }
                    return isValid;
                }
            );
            this.setState({
                regionListFromYearAndMonthData: list,
            });
        } else {
            this.setState({
                searchValue: "",
            });
            this.setState({
                regionListFromYearAndMonthData:
                    dashboardData.regionListFromYearAndMonth,
            });
        }
    };

    onSearchForTopPerformingProducts = (searchData) => {
        const { dashboardDetails } = this.props;
        const { productList } = this.state;
        if (searchData != "" || searchData != undefined) {
            this.setState({
                searchValueForProduct: searchData,
            });
            const list = dashboardDetails?.dashboardDataForProduct.filter(
                (currentRow) => {
                    let isValid = false;
                    if (
                        (currentRow["name"] &&
                            currentRow["name"]
                                .toString()
                                .toLowerCase()
                                .includes(searchData.toLowerCase())) ||
                        (currentRow["code"] &&
                            currentRow["code"]
                                .toString()
                                .toLowerCase()
                                .includes(searchData.toLowerCase()))
                    ) {
                        isValid = true;
                        return true;
                    }
                    return isValid;
                }
            );
            this.setState({
                dashboardDataForProduct: list,
            });
        } else {
            this.setState({
                searchValueForProduct: "",
            });
            this.setState({
                productList: dashboardDetails?.dashboardDataForProduct,
            });
        }
    };
    handleCloses = () => {
        this.setState({
            popUpFlag: false,
        });
    };
    handleClosesCategory = () => {
        this.setState({
            popUpFlagCategory: false,
        });
    };
    rigionIdHandler = (regionId, month, year) => {
        console.log(regionId);
        this.setState({
            regionId,
            month,
            year,
        });
    };
    onCLickPie = (event, categoryId) => {
        const { getSubCategoryPackagingType, showLoador, showNotification } =
            this.props;
        const { month, year, regionId } = this.state;
        if (navigator.onLine) {
            showLoador({ loador: true });
            getSubCategoryPackagingType({
                year: dayjs().format("YYYY"),
                month: dayjs().format("MM"),
                regionId: regionId,
                categoryId: categoryId,
            }).then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    this.setState({
                        popUpFlag: true,
                    });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    onCLickPieChart = (e, pacakingTypeId) => {
        const { getCategoryByPackagingType, showLoador, showNotification } =
            this.props;
        const { month, year, regionId } = this.state;
        if (navigator.onLine) {
            showLoador({ loador: true });
            getCategoryByPackagingType({
                year: dayjs().format("YYYY"),
                month: dayjs().format("MM"),
                regionId: regionId,
                pacakingTypeId: pacakingTypeId,
            }).then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    this.setState({
                        popUpFlagCategory: true,
                    });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };
    onSearchForSalesTeamList = (searchValueForSalesTeamList) => {
        this.setState({
            searchValueForSalesTeamList: searchValueForSalesTeamList,
        });
    };
    getFilteredTableDataForSalesTeamList = (searchData) => {
        const { searchValueForSalesTeamList } = this.state;
        return searchData.filter((currentRow) => {
            let isValid = false;
            if (
                (currentRow["name"] &&
                    currentRow["name"]
                        .toString()
                        .toLowerCase()
                        .includes(searchValueForSalesTeamList.trim().toLowerCase())) ||
                (currentRow["district_names"] &&
                    currentRow["district_names"]
                        .toString()
                        .toLowerCase()
                        .includes(searchValueForSalesTeamList.trim().toLowerCase())) ||
                (currentRow["area_names"] &&
                    currentRow["area_names"]
                        .toString()
                        .toLowerCase()
                        .includes(searchValueForSalesTeamList.trim().toLowerCase()))
            ) {
                isValid = true;
                return true;
            }
        });
    };
    changeDate = (name) => (date) => {
        const {
            getSalesTeamInfoByDatesNew,
            showLoador,
            showNotification,
            myConstClass,
        } = this.props;
        const { fromDate, toDate } = this.state;

        if (name === "fromDate") {
            this.setState({
                fromDate: date,
            });
        }

        if (name === "toDate") {
            this.setState({
                toDate: date,
            });
        }
    };

    generateExcel = () => {
        const { showLoador, showNotification } = this.props;
        const { fromDate, toDate } = this.state;
        if (navigator.onLine) {
            showLoador({ loador: true });
            downloadF({
                url:
                    endpoint.adminDashboard +
                    "/get-excel-sales-teams?fromDate=" +
                    dayjs(fromDate).format("DD-MM-YYYY") +
                    "&toDate=" +
                    dayjs(toDate).format("DD-MM-YYYY"),
                ext: "xlsx",
                openNewTab: true,
            }).then((response) => {
                showLoador({ loador: false });
                if (!response) {
                    this.props.showNotification({
                        msg: serverMsg,
                        severity: "error",
                    });
                } else {
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    searchSalesTeamData = () => {
        const {
            getSalesTeamInfoByDatesNew,
            showLoador,
            showNotification,
            myConstClass,
        } = this.props;
        const { fromDate, toDate } = this.state;

        if (navigator.onLine) {
            showLoador({ loador: true });
            getSalesTeamInfoByDatesNew({
                fromDate: dayjs(fromDate).format("DD-MM-YYYY"),
                toDate: dayjs(toDate).format("DD-MM-YYYY"),
            }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: response.serverMsg, severity: "error" });
                } else {
                    let totalOutletCount = 0,
                        zero = 0,
                        productiveCall = 0,
                        unvisited = 0,
                        salesAmt = 0,
                        totalAmount = 0;
                    if (response.length !== 0) {
                        response.map((salesTeam) => {
                            totalOutletCount = totalOutletCount + +salesTeam.totalOutletCount;
                            productiveCall = productiveCall + +salesTeam.productiveCall;
                            zero = zero + +salesTeam.zeroOrderCount;
                            unvisited = unvisited + +salesTeam.unvisitedOutletCount;
                            salesAmt = salesAmt + +salesTeam.totalSalesAmt;
                            totalAmount = totalAmount + +salesTeam.totalAmount;
                        });
                    }
                    this.setState({
                        totalOutletCount: totalOutletCount,
                        zero: zero,
                        productiveCall: productiveCall,
                        unvisited: unvisited,
                        salesAmt: salesAmt,
                        totalAmount: totalAmount,
                    });
                }
            });
        } else {
            showNotification({ msg: myConstClass.noInternetMsg, severity: "error" });
        }
    };

    render() {
        const {
            dashboardData,
            dashboardDetails,
            billingHistoryList,
            subCategoryList,
            stockStakeHolderTypeList,
            zoneList,
            areaList,
            showNotification,
            showLoador,
            beatList,
            dashboardDataForProduct,
        } = this.props;
        const {
            type,
            showZone,
            showArea,
            saleMonth,
            monthList,
            columns,
            rows,
            rowsPerPage,
            page,
            radioValue,
            productArea,
            productZone,
            outletArea,
            outletZone,
            regionListFromYearAndMonthData,
            regionListFromYearAndMonthDataForZone,
            regionListFromYearAndMonthDataForSalesRegion,
            stateListFromYearAndMonth,
            zoneDetailsSalesData,
            zoneDetailsRegionwiseData,
            districtDetailsZoneWiseData,
            salesGraphData,
            salesMonthData,
            areaDetailsData,
            districtDetailsData,
            talukaDetailsData,
            areaDetailsListData,
            topPerformingOutletData,
            salesTeamData,
            searchValue,
            searchValueForZone,
            searchValueForSalesRegion,
            searchValueForZoneDetails,
            searchValueForAreaDetails,
            searchValueStateList,
            searchValuePersonWiseSales,
            searchValueForProduct,
            searchValueForOutlets,
            lineGraphData,
            productList,
            salesRegionName,
            popUpFlag,
            popUpFlagCategory,
            beatPopupFlag,
            totalOutletCount,
            productiveCall,
            unvisited,
            zero,
            totalAmount,
            salesAmt,
            toDate,
            fromDate,
            formErrors,
            salesListFromYearAndMonthOnSearch,
            productDataOnSearchFlag,
        } = this.state;
        const { typeError, dateError } = this.state.formErrors;
        const adminDashboard = localStorage.getItem("adminDashboard");
        const adminDashboardData = JSON.parse(adminDashboard);

        const stockStakeHolderType = localStorage.getItem("stockStakeHolderTypeId");
        const stockStakeHolderTypeListData = localStorage.getItem(
            "stockStakeHolderTypeListData"
        );
        const stockStakeHolderTypeListLocalStorage = JSON.parse(
            stockStakeHolderTypeListData
        );

        const monthYearTargetData = localStorage.getItem("monthYearTargetData");
        const monthYearTargetDataLocalStorage = JSON.parse(monthYearTargetData);

        const salesListFromYearAndMonthData = localStorage.getItem(
            "salesListFromYearAndMonth"
        );
        const salesListFromYearAndMonthLocalStorage = JSON.parse(
            salesListFromYearAndMonthData
        );

        return (
            <>
                <div
                    id="layout-wrapper"
                    style={{
                        backgroundColor: "#fff",
                    }}
                >
                    {/* ========== App Menu ========== */}

                    {/* Left Sidebar End */}
                    {/* Vertical Overlay*/}
                    <div className="vertical-overlay" />
                    {/* ============================================================== */}
                    {/* Start right Content here 
              {/* ============================================================== */}
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                {/* Card 1 */}
                                <div
                                    style={{
                                        padding: "20px 6px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: "10px",
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: "white",
                                            // width: "22%",
                                            flex: "1",
                                            // height: "100px",
                                            borderRadius: "3%",
                                            padding: "12px",
                                            // border:"1px solid black",
                                            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                            // boxShadow:
                                            //   " rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                                            // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            {" "}
                                            {/* // image & cartCount */}
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    width: "60px",
                                                    height: "60px",
                                                    position: "relative",
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        borderRadius: "15%",
                                                        // backgroundColor: "lightgray",
                                                        backgroundImage:
                                                            "linear-gradient(to right, #004969 , #036e9d)",
                                                        position: "absolute",
                                                        top: "-25px",
                                                        left: "-4px",
                                                        objectFit: "contain",
                                                        padding: "10px",
                                                    }}
                                                    src={image4}
                                                    alt=""
                                                />
                                            </div>
                                            <span
                                                style={{
                                                    fontSize: "25px",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                {ConvertAmount({
                                                    amount: adminDashboardData
                                                        ? +adminDashboardData.currentAchievedAmt
                                                        : "",
                                                })}
                                            </span>
                                        </div>

                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                padding: "4px 0",
                                                fontWeight: "400",
                                                fontSize: "15px",
                                            }}
                                        >
                                            {" "}
                                            Total Sales
                                        </div>

                                        <div
                                            style={{
                                                backgroundColor: "lightgray",
                                                height: "1px",
                                                width: "100%",
                                            }}
                                        >
                                            {" "}
                                        </div>
                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                padding: "8px 0",
                                                fontWeight: "600",
                                                fontSize: "20px",
                                            }}
                                        >
                                            {" "}
                                            TOTAL SALES
                                        </div>
                                    </div>
                                    {/* Card 1  end*/}
                                    {/* Card 2 */}
                                    <div
                                        style={{
                                            backgroundColor: "white",
                                            // width: "22%",
                                            flex: "1",
                                            // height: "100px",
                                            borderRadius: "3%",
                                            padding: "12px",
                                            // border:"1px solid black",
                                            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                            // boxShadow:
                                            //   " rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                                            // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            {" "}
                                            {/* // image & cartCount */}
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    width: "60px",
                                                    height: "60px",
                                                    position: "relative",
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        borderRadius: "15%",
                                                        // backgroundColor: "lightgray",
                                                        backgroundImage:
                                                            "linear-gradient(to right, #004969 , #036e9d)",
                                                        position: "absolute",
                                                        top: "-25px",
                                                        left: "-4px",
                                                        objectFit: "contain",
                                                        padding: "10px",
                                                    }}
                                                    src={image5}
                                                    alt=""
                                                />
                                            </div>
                                            <span
                                                style={{
                                                    fontSize: "25px",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                {adminDashboardData
                                                    ? +adminDashboardData.totalOutletCount
                                                    : ""}
                                            </span>
                                        </div>

                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                padding: "4px 0",
                                                fontWeight: "400",
                                                fontSize: "15px",
                                            }}
                                        >
                                            {" "}
                                            Total Outlet
                                        </div>

                                        <div
                                            style={{
                                                backgroundColor: "lightgray",
                                                height: "1px",
                                                width: "100%",
                                            }}
                                        >
                                            {" "}
                                        </div>
                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                padding: "8px 0",
                                                fontWeight: "600",
                                                fontSize: "20px",
                                            }}
                                        >
                                            {" "}
                                            OUTLETS
                                        </div>
                                    </div>
                                    {/* Card 2 end */}
                                    {/* Card 3 */}
                                    <div
                                        style={{
                                            backgroundColor: "white",
                                            // width: "22%",
                                            flex: "1",
                                            // height: "100px",
                                            borderRadius: "3%",
                                            padding: "12px",
                                            // border:"1px solid black",
                                            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                            // boxShadow:
                                            //   " rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                                            // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            {" "}
                                            {/* // image & cartCount */}
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    width: "60px",
                                                    height: "60px",
                                                    position: "relative",
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        borderRadius: "15%",
                                                        // backgroundColor: "lightgray",
                                                        backgroundImage:
                                                            "linear-gradient(to right, #004969 , #036e9d)",
                                                        position: "absolute",
                                                        top: "-25px",
                                                        left: "-4px",
                                                        objectFit: "contain",
                                                        padding: "10px",
                                                    }}
                                                    src={image7BgClr}
                                                    alt=""
                                                />
                                            </div>
                                            <span
                                                style={{
                                                    fontSize: "25px",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                {adminDashboardData
                                                    ? +adminDashboardData.totalStakeHolderCount
                                                    : ""}
                                            </span>
                                        </div>

                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                padding: "4px 0",
                                                fontWeight: "400",
                                                fontSize: "15px",
                                            }}
                                        >
                                            {" "}
                                            Total Stock-Holder
                                        </div>

                                        <div
                                            style={{
                                                backgroundColor: "lightgray",
                                                height: "1px",
                                                width: "100%",
                                            }}
                                        >
                                            {" "}
                                        </div>
                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                padding: "8px 0",
                                                fontWeight: "600",
                                                fontSize: "20px",
                                            }}
                                        >
                                            {" "}
                                            STOCK-HOLDERS
                                        </div>
                                    </div>
                                    {/* Card 3 end */}
                                    {/* Card 4 */}
                                    <div
                                        style={{
                                            backgroundColor: "white",
                                            // width: "22%",
                                            // height: "100px",
                                            flex: "1",
                                            borderRadius: "3%",
                                            padding: "12px",
                                            // border:"1px solid black",
                                            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                            // boxShadow:
                                            //   " rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
                                            // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            {" "}
                                            {/* // image & cartCount */}
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    width: "60px",
                                                    height: "60px",
                                                    position: "relative",
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        borderRadius: "15%",
                                                        // backgroundColor: "lightgray",
                                                        backgroundImage:
                                                            "linear-gradient(to right, #004969 , #036e9d)",
                                                        position: "absolute",
                                                        top: "-25px",
                                                        left: "-4px",
                                                        objectFit: "contain",
                                                        padding: "10px",
                                                    }}
                                                    src={image6}
                                                    alt=""
                                                />
                                            </div>
                                            <span
                                                style={{
                                                    fontSize: "25px",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                {adminDashboardData
                                                    ? +adminDashboardData.totalSalesTeamCount
                                                    : ""}
                                            </span>
                                        </div>

                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                padding: "4px 0",
                                                fontWeight: "400",
                                                fontSize: "15px",
                                            }}
                                        >
                                            {" "}
                                            Total Manpower
                                        </div>

                                        <div
                                            style={{
                                                backgroundColor: "lightgray",
                                                height: "1px",
                                                width: "100%",
                                            }}
                                        >
                                            {" "}
                                        </div>
                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                padding: "8px 0",
                                                fontWeight: "600",
                                                fontSize: "20px",
                                            }}
                                        >
                                            {" "}
                                            MANPOWER
                                        </div>
                                    </div>
                                    {/* Card 4 end */}
                                </div>

                                <div
                                    className="row"
                                    style={{
                                        gap: 10,
                                        padding: 12,
                                        flexWrap: "nowrap",
                                    }}
                                >
                                    {/* Target Card Start */}
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            flex: "2",
                                            gap: "20px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                backgroundColor: "white",
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "20px",
                                                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                                padding: "12px",
                                                borderRadius: "5%",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    // alignItems: "center",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        flexDirection: "column",
                                                        gap: "5px",
                                                    }}
                                                >
                                                    <h1 style={{ fontWeight: "600" }}>TARGET</h1>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "flex-start",
                                                            width: "100%",
                                                        }}
                                                    >
                                                        {" "}
                                                        <h1>
                                                            {" "}
                                                            {ConvertAmount({
                                                                amount: adminDashboardData
                                                                    ? +adminDashboardData.currentTargetAmt
                                                                    : "",
                                                            })}
                                                        </h1>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        width: "60px",
                                                        height: "60px",
                                                        position: "relative",
                                                    }}
                                                >
                                                    <img
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            borderRadius: "15%",
                                                            backgroundImage:
                                                                "linear-gradient(to right, #508201 , #7ac206)",
                                                            objectFit: "contain",
                                                            padding: "10px",
                                                        }}
                                                        src={target1}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "10px",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {" "}
                                                <div
                                                    style={{
                                                        fontWeight: "600",
                                                        color: "green",
                                                        fontSize: "22px",
                                                    }}
                                                >
                                                    {ConvertAmount({
                                                        amount: adminDashboardData
                                                            ? +adminDashboardData.currentAchievedAmt
                                                            : "",
                                                    })}
                                                </div>{" "}
                                                <span>Achived</span>
                                            </div>
                                        </div>

                                        {/* ///***** */}

                                        <div
                                            style={{
                                                backgroundColor: "white",
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "20px",
                                                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                                padding: "12px",
                                                borderRadius: "5%",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    // alignItems: "center",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        flexDirection: "column",
                                                        gap: "5px",
                                                    }}
                                                >
                                                    <h1 style={{ fontWeight: "600" }}>MONTHLY</h1>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "flex-start",
                                                            width: "100%",
                                                        }}
                                                    >
                                                        {" "}
                                                        <h1>
                                                            {" "}
                                                            {ConvertAmount({
                                                                amount: adminDashboardData
                                                                    ? +adminDashboardData.targetAmt
                                                                    : "",
                                                            })}
                                                        </h1>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        width: "60px",
                                                        height: "60px",
                                                        position: "relative",
                                                    }}
                                                >
                                                    <img
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            borderRadius: "15%",
                                                            backgroundImage:
                                                                "linear-gradient(to right, #508201 , #7ac206)",
                                                            objectFit: "contain",
                                                            padding: "10px",
                                                        }}
                                                        src={target1}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "10px",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {" "}
                                                <div
                                                    style={{
                                                        fontWeight: "600",
                                                        color: "green",
                                                        fontSize: "22px",
                                                    }}
                                                >
                                                    {ConvertAmount({
                                                        amount: adminDashboardData
                                                            ? +adminDashboardData.achivedAmt
                                                            : "",
                                                    })}
                                                </div>{" "}
                                                <span>Achived</span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Target Card Ends */}
                                    {console.log(monthYearTargetDataLocalStorage)}
                                    {/* Bar Graph starts */}
                                    <div
                                        className="Group11172"
                                        style={{ flex: "6", position: "relative" }}
                                    >
                                        <div>
                                            <ReactApexChart
                                                options={{
                                                    chart: {
                                                        height: 400,
                                                        type: "area",
                                                    },
                                                    dataLabels: {
                                                        enabled: false,
                                                    },
                                                    stroke: {
                                                        curve: "smooth",
                                                    },
                                                    xaxis: {
                                                        categories: [
                                                            "Apr",
                                                            "May",
                                                            "Jun",
                                                            "Jul",
                                                            "Aug",
                                                            "Sep",
                                                        ],
                                                    },
                                                    tooltip: {
                                                        theme: "light",
                                                    },
                                                }}
                                                series={[
                                                    {
                                                        name: "Target",
                                                        data:
                                                            monthYearTargetDataLocalStorage !== null
                                                                ? monthYearTargetDataLocalStorage[0].data
                                                                : [],
                                                    },
                                                    {
                                                        name: "Achieved",
                                                        data:
                                                            monthYearTargetDataLocalStorage !== null
                                                                ? monthYearTargetDataLocalStorage[1].data
                                                                : [],
                                                    },
                                                ]}
                                                type="area"
                                                height={350}
                                            />
                                        </div>
                                    </div>

                                    {/* Bar Graph ends */}
                                </div>

                                {/* Sales Div starts */}

                                <div
                                    className="Frame427319149"
                                    style={{
                                        width: "100%",
                                        height: 36,
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        gap: 12,
                                        display: "inline-flex",
                                    }}
                                >
                                    <div
                                        className="SalesManpower"
                                        style={{
                                            color: "black",
                                            fontSize: 24.28,
                                            fontFamily: "Poppins",
                                            fontWeight: "500",
                                            textTransform: "capitalize",
                                            wordWrap: "break-word",
                                        }}
                                    >
                                        Sales
                                    </div>
                                </div>
                                {/* Sales Div end */}
                                <HiearchyDropDown rigionIdHandler={this.rigionIdHandler} />
                                {/* Top 20 Div end */}
                                <div
                                    className="row"
                                    style={{
                                        gap: 8,
                                        marginBottom: 10,
                                        width: "100%",
                                        marginLeft: 5,
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "56.4%",
                                            background: "white",
                                            padding: 5,
                                            borderRadius: 8,
                                            border: "1px #E6E6E6 solid",
                                        }}
                                    >
                                        {/* sales Overview graph starts */}
                                        <div
                                            // className="card"
                                            style={{
                                                marginBottom: -2,
                                                background: "white",
                                                borderRadius: 8,
                                                border: "0 #E6E6E6 solid",
                                            }}
                                        >
                                            <div
                                                className="card-header align-items-center d-flex"
                                                style={{
                                                    background: "white",
                                                    borderRadius: 8,
                                                    border: "0 #E6E6E6 solid",
                                                    overflow: "scroll",
                                                }}
                                            >
                                                <h4 className="text-uppercase card-title mb-0 flex-grow-1">
                                                    Top Performing Product
                                                    <div
                                                        style={{
                                                            marginLeft: -25,
                                                        }}
                                                    >
                                                        <SearchBar
                                                            placeHolder="Search..."
                                                            inputProps={{ "aria-label": "search" }}
                                                            searchValueForProduct={searchValueForProduct}
                                                            filteredRows={
                                                                this.onSearchForTopPerformingProducts
                                                            }
                                                        ></SearchBar>
                                                    </div>
                                                </h4>
                                                <h4
                                                    className="text-uppercase card-title mb-0 flex-grow-1"
                                                    style={{
                                                        textAlign: "end",
                                                        width: 180,
                                                    }}
                                                >
                                                    <MonthCalender
                                                        title=""
                                                        name="saleMonth"
                                                        value={saleMonth}
                                                        onChange={this.onDateChange}
                                                        sx={{
                                                            paddingBottom: 0,
                                                        }}
                                                    />
                                                </h4>
                                            </div>
                                        </div>
                                        <TableContainer
                                            sx={{
                                                maxHeight: 440,
                                                marginTop: 0,
                                                // width: "60%",
                                                // padding: 1.5,
                                            }}
                                        >
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead
                                                    style={{
                                                        backgroundColor: "#fff",
                                                    }}
                                                >
                                                    <TableRow>
                                                        <TableCell align="center" sx={{ width: "2%" }}>
                                                            Sr.No
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ width: "8%" }}>
                                                            Name
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ width: "5%" }}>
                                                            Product Code
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ width: "5%" }}>
                                                            Acheived
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ width: "5%" }}>
                                                            % Contribution
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {console.log(dashboardDetails?.dashboardDataForProduct)}
                                                <TableBody>
                                                    {dashboardDetails?.dashboardDataForProduct.length !==
                                                        0 &&
                                                        dashboardDetails?.dashboardDataForProduct.map(
                                                            (productObject, index) => {
                                                                let finalTotal =
                                                                    dashboardDetails?.dashboardDataForProduct.reduce(
                                                                        (total, obj) => total + +obj.orderVale,
                                                                        0
                                                                    );
                                                                let contribution = (
                                                                    (+productObject.orderVale / finalTotal) *
                                                                    100
                                                                ).toFixed();
                                                                return (
                                                                    <TableRow key={index}>
                                                                        <TableCell align="center">
                                                                            {index + 1}
                                                                        </TableCell>
                                                                        <TableCell width="2%">
                                                                            <div
                                                                                onClick={(e) =>
                                                                                    this.topProductHandler(
                                                                                        e,
                                                                                        productObject.id
                                                                                    )
                                                                                }
                                                                                style={{
                                                                                    color: cyanBlue,
                                                                                    cursor: "pointer",
                                                                                }}
                                                                            >
                                                                                {productObject.name}
                                                                            </div>
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {productObject.code}
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            {productObject.orderVale}
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            {contribution}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            }
                                                        )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        {/* Sales & Manpower Table 1 end */}
                                    </div>

                                    <div
                                        style={{
                                            width: "40%",
                                            background: "white",
                                            padding: 5,
                                            borderRadius: 8,
                                            border: "1px #E6E6E6 solid",
                                        }}
                                    >
                                        {/* Sales & Manpower Table 2 starts */}
                                        {productList !== 0 ? (
                                            <MonthlyBarChart
                                                height={550}
                                                series={[
                                                    {
                                                        name: "Product Sale",
                                                        data: productList.map((product) =>
                                                            product.totalAmount !== null
                                                                ? product.totalAmount
                                                                : 0
                                                        ),
                                                    },
                                                ]}
                                                optionsObj={{
                                                    chart: {
                                                        type: "bar",
                                                        height: 365,
                                                        events: {
                                                            dataPointSelection: (
                                                                event,
                                                                chartContext,
                                                                config
                                                            ) => {
                                                                config.dataPointIndex;
                                                            },
                                                        },
                                                    },
                                                    plotOptions: {
                                                        bar: {
                                                            columnWidth: "40%",
                                                            distributed: true,
                                                        },
                                                    },
                                                    stroke: {
                                                        show: true,
                                                        width: 2,
                                                    },
                                                    dataLabels: {
                                                        enabled: false,
                                                    },
                                                    xaxis: {
                                                        categories: productList.map((product) =>
                                                            product.monthYear !== null
                                                                ? product.monthYear
                                                                : ""
                                                        ),
                                                    },
                                                    yaxis: {
                                                        title: {
                                                            text: "Total Amount (â‚¹)",
                                                        },
                                                    },
                                                    fill: {
                                                        opacity: 1,
                                                    },
                                                    colors: [
                                                        "#FF0000",
                                                        "#00FFFF",
                                                        "#008000",
                                                        "#000FFF",
                                                        "#ffff33",
                                                        "#db4dff",
                                                    ],
                                                    tooltip: {
                                                        theme: "light",
                                                    },
                                                }}
                                            />
                                        ) : (
                                            <NoDataFound />
                                        )}

                                        {/* Sales & Manpower Table 2 end */}
                                    </div>
                                </div>
                                {/* Top 20 Div starts */}
                                {/* Top 20 Div end */}
                                <div
                                    className="row"
                                    style={{
                                        gap: 8,
                                        marginBottom: 10,
                                        width: "100%",
                                        marginLeft: 5,
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "45%",
                                            background: "white",
                                            padding: 10,
                                            borderRadius: 8,
                                            border: "1px #E6E6E6 solid",
                                        }}
                                    >
                                        {/* Distributed gragh 2 starts */}
                                        <Grid item xs={12} md={4} lg={4}>
                                            <Grid
                                                container
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                <Grid item>
                                                    <Typography variant="h5">
                                                        Category-Wise Sale
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Box sx={{ pt: 1, pr: 2 }}>
                                                {billingHistoryList.length !== 0 &&
                                                    billingHistoryList.categoryWiseBill.length !== 0 ? (
                                                    <PieChart
                                                        series={billingHistoryList.categoryWiseBill.map(
                                                            (category) =>
                                                                category.totalAmt != null
                                                                    ? category.totalAmt
                                                                    : 0
                                                        )}
                                                        options={{
                                                            chart: {
                                                                height: "100%",
                                                                width: "600%",
                                                                type: "donut",
                                                                events: {
                                                                    dataPointSelection: (
                                                                        event,
                                                                        chartContext,
                                                                        config
                                                                    ) => {
                                                                        const categoryId =
                                                                            billingHistoryList.categoryWiseBill[
                                                                                config.dataPointIndex
                                                                            ]?.id;
                                                                        this.onCLickPie(event, categoryId);
                                                                    },
                                                                },
                                                            },
                                                            animations: {
                                                                enabled: true,
                                                                easing: "easeinout",
                                                                speed: 1000,
                                                                animateGradually: {
                                                                    enabled: true,
                                                                    delay: 150,
                                                                },
                                                                dynamicAnimation: {
                                                                    enabled: true,
                                                                    speed: 100,
                                                                },
                                                            },
                                                            labels: billingHistoryList.categoryWiseBill.map(
                                                                (category) => {
                                                                    return category.category != null
                                                                        ? category.category
                                                                        : "";
                                                                }
                                                            ),
                                                            responsive: [
                                                                {
                                                                    breakpoint: 480,
                                                                    options: {
                                                                        chart: {
                                                                            width: 600,
                                                                        },
                                                                        legend: {
                                                                            position: "bottom",
                                                                        },
                                                                    },
                                                                },
                                                            ],
                                                        }}
                                                    />
                                                ) : (
                                                    <NoDataFound />
                                                )}
                                                {popUpFlag && (
                                                    <SubCategoryPackagingTypePopup
                                                        open={popUpFlag}
                                                        handleCloses={this.handleCloses}
                                                        columns={[
                                                            {
                                                                title: "Sr. No.",
                                                                name: "index",
                                                                positionCenter: false,
                                                                showInscreen: true,
                                                                alignCenter: "center",
                                                                canSearch: true,
                                                            },
                                                            {
                                                                title: "Subcategory Name",
                                                                name: "subCategory",
                                                                positionCenter: false,
                                                                showInscreen: true,
                                                                alignCenter: "left",
                                                                canSearch: true,
                                                            },
                                                            {
                                                                title: "Packaging Type",
                                                                name: "packagingType",
                                                                positionCenter: false,
                                                                showInscreen: true,
                                                                alignCenter: "left",
                                                                canSearch: true,
                                                            },
                                                            {
                                                                title: "Total Amount",
                                                                name: "totalAmt",
                                                                positionCenter: false,
                                                                showInscreen: true,
                                                                alignCenter: "right",
                                                                canSearch: true,
                                                            },
                                                        ]}
                                                        rows={subCategoryList?.packagingType}
                                                    />
                                                )}
                                            </Box>
                                        </Grid>

                                        {/* Distributed gragh 2 end */}
                                    </div>

                                    <div
                                        style={{
                                            width: "45%",
                                            background: "white",
                                            padding: 10,
                                            borderRadius: 8,
                                            border: "1px #E6E6E6 solid",
                                        }}
                                    >
                                        {/* Distributed gragh 2 starts */}

                                        <Grid item xs={12} md={4} lg={4}>
                                            <Grid
                                                container
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                <Grid item>
                                                    <Typography variant="h5">
                                                        Packaging-Type-Wise Sale
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Box sx={{ pt: 1, pr: 2 }}>
                                                {billingHistoryList.length != 0 &&
                                                    billingHistoryList.packagingType != 0 ? (
                                                    <PieChart
                                                        series={billingHistoryList.packagingType.map(
                                                            (packagingType) => {
                                                                return packagingType.packagingType != null
                                                                    ? packagingType.totalAmt
                                                                    : "";
                                                            }
                                                        )}
                                                        options={{
                                                            chart: {
                                                                height: "100%",
                                                                width: "500%",
                                                                type: "donut",
                                                                events: {
                                                                    dataPointSelection: (
                                                                        event,
                                                                        chartContext,
                                                                        config
                                                                    ) => {
                                                                        const pacakingTypeId =
                                                                            billingHistoryList.packagingType[
                                                                                config.dataPointIndex
                                                                            ]?.id;
                                                                        this.onCLickPieChart(event, pacakingTypeId);
                                                                    },
                                                                },
                                                            },
                                                            animations: {
                                                                enabled: true,
                                                                easing: "easeinout",
                                                                speed: 1000,
                                                                animateGradually: {
                                                                    enabled: true,
                                                                    delay: 150,
                                                                },
                                                                dynamicAnimation: {
                                                                    enabled: true,
                                                                    speed: 100,
                                                                },
                                                            },
                                                            labels: billingHistoryList.packagingType.map(
                                                                (packagingType) => {
                                                                    return packagingType.packagingType != null
                                                                        ? packagingType.packagingType
                                                                        : "";
                                                                }
                                                            ),
                                                            responsive: [
                                                                {
                                                                    breakpoint: 480,
                                                                    options: {
                                                                        chart: {
                                                                            width: 600,
                                                                        },
                                                                        legend: {
                                                                            position: "bottom",
                                                                        },
                                                                    },
                                                                },
                                                            ],
                                                        }}
                                                    />
                                                ) : (
                                                    <NoDataFound />
                                                )}
                                                {popUpFlagCategory && (
                                                    <CategoryPopup
                                                        open={popUpFlagCategory}
                                                        handleCloses={this.handleClosesCategory}
                                                        columns={[
                                                            {
                                                                title: "Sr. No.",
                                                                name: "index",
                                                                positionCenter: false,
                                                                showInscreen: true,
                                                                alignCenter: "center",
                                                                canSearch: true,
                                                            },
                                                            {
                                                                title: "Category Name",
                                                                name: "category",
                                                                positionCenter: false,
                                                                showInscreen: true,
                                                                alignCenter: "left",
                                                                canSearch: true,
                                                            },
                                                            {
                                                                title: "Subcategory Name",
                                                                name: "subCategory",
                                                                positionCenter: false,
                                                                showInscreen: true,
                                                                alignCenter: "left",
                                                                canSearch: true,
                                                            },
                                                            {
                                                                title: "Total Amount",
                                                                name: "totalAmt",
                                                                positionCenter: false,
                                                                showInscreen: true,
                                                                alignCenter: "right",
                                                                canSearch: true,
                                                            },
                                                        ]}
                                                        rows={subCategoryList?.categoryByPackagingType}
                                                    />
                                                )}
                                            </Box>
                                        </Grid>
                                        {/* Distributed gragh 2 end */}
                                    </div>
                                </div>
                                <div
                                    className="row"
                                    style={{
                                        gap: 8,
                                        marginBottom: 10,
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "62%",
                                            background: "white",
                                            padding: 5,
                                            borderRadius: 8,
                                            border: "1px #E6E6E6 solid",
                                        }}
                                    >
                                        {/* Sales & Manpower Table 1 starts */}
                                        <div
                                            // className="card"
                                            style={{
                                                marginBottom: -2,
                                                background: "white",
                                                borderRadius: 8,
                                                border: "0 #E6E6E6 solid",
                                            }}
                                        >
                                            <div
                                                className="card-header align-items-center d-flex"
                                                style={{
                                                    background: "white",
                                                    borderRadius: 8,
                                                    border: "0 #E6E6E6 solid",
                                                    overflow: "scroll",
                                                }}
                                            >
                                                <h4 className="text-uppercase card-title mb-0 flex-grow-1">
                                                    Person Wise Sales
                                                    <div
                                                        style={{
                                                            marginLeft: -25,
                                                        }}
                                                    >
                                                        {/* <SearchBar
                                  placeHolder="Search..."
                                  inputProps={{ "aria-label": "search" }}
                                  searchValuePersonWiseSales={searchValuePersonWiseSales}
                                  filteredRows={this.onSearchPersonWiseSales}
                                ></SearchBar> */}
                                                    </div>
                                                </h4>
                                                <h4
                                                    className="text-uppercase card-title mb-0 flex-grow-1"
                                                    style={{
                                                        marginTop: 2,
                                                    }}
                                                >
                                                    <AutoComplete
                                                        keyColName={"id"}
                                                        placeHolder="Stock Stake Holder Type"
                                                        value={
                                                            stockStakeHolderType ? stockStakeHolderType : ""
                                                        }
                                                        name={"type"}
                                                        options={
                                                            stockStakeHolderTypeListLocalStorage
                                                                ? stockStakeHolderTypeListLocalStorage
                                                                : []
                                                        }
                                                        onChange={this.ChangeHandlerSearch}
                                                        isError={typeError ? true : false}
                                                        errorText={typeError ? typeError : " "}
                                                        sx={{
                                                            marginTop: 1.2,
                                                            marginRight: 5,
                                                        }}
                                                    />
                                                </h4>

                                                <h4
                                                    className="text-uppercase card-title mb-0 flex-grow-1"
                                                    style={{
                                                        textAlign: "end",
                                                        width: 180,
                                                    }}
                                                >
                                                    <MonthCalender
                                                        title=""
                                                        name="saleMonth"
                                                        value={saleMonth}
                                                        onChange={this.onDateChange}
                                                        sx={{
                                                            paddingBottom: 0,
                                                        }}
                                                    />
                                                </h4>
                                            </div>
                                        </div>
                                        <TableContainer
                                            sx={{
                                                maxHeight: 440,
                                                marginTop: 0,
                                                // width: "60%",
                                                padding: 0,
                                            }}
                                        >
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead
                                                    style={{
                                                        backgroundColor: "#fff",
                                                    }}
                                                >
                                                    <TableRow>
                                                        <TableCell align="center" sx={{ width: "2%" }}>
                                                            Sr.No
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ width: "8%" }}>
                                                            Name
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ width: "5%" }}>
                                                            Target Amount
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ width: "5%" }}>
                                                            Acheived
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ width: "5%" }}>
                                                            % Contribution
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ width: "5%" }}>
                                                            % count (+ve, -ve)
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {salesListFromYearAndMonthOnSearch
                                                        ? dashboardData?.salesListFromYearAndMonth.map(
                                                            (regionObject, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell align="center">
                                                                        {index + 1}
                                                                    </TableCell>
                                                                    <TableCell width="2%">
                                                                        <div
                                                                            onClick={(e) =>
                                                                                this.salesHandler(e, regionObject.id)
                                                                            }
                                                                            style={{
                                                                                color: cyanBlue,
                                                                                cursor: "pointer",
                                                                            }}
                                                                        >
                                                                            {regionObject.name}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {regionObject.targetAmt}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {regionObject.actualAmt}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {regionObject.contribution}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {regionObject.divisional}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )
                                                        : salesListFromYearAndMonthLocalStorage &&
                                                            salesListFromYearAndMonthLocalStorage.length > 0
                                                            ? salesListFromYearAndMonthLocalStorage.map(
                                                                (regionObject, index) => (
                                                                    <TableRow key={index}>
                                                                        <TableCell align="center">
                                                                            {index + 1}
                                                                        </TableCell>
                                                                        <TableCell width="2%">
                                                                            <div
                                                                                onClick={(e) =>
                                                                                    this.salesHandler(e, regionObject.id)
                                                                                }
                                                                                style={{
                                                                                    color: cyanBlue,
                                                                                    cursor: "pointer",
                                                                                }}
                                                                            >
                                                                                {regionObject.name}
                                                                            </div>
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            {regionObject.targetAmt}
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            {regionObject.actualAmt}
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            {regionObject.contribution}
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            {regionObject.divisional}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            )
                                                            : null}
                                                    <TableRow>
                                                        <TableCell align="center" width="2%" colSpan={3}>
                                                            Total
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {dashboardData.salesListFromYearAndMonthTotal}
                                                        </TableCell>
                                                        <TableCell align="center">-</TableCell>
                                                        <TableCell align="center">-</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        {/* Sales & Manpower Table 1 end */}
                                    </div>

                                    <div
                                        style={{
                                            width: "500px",
                                            background: "white",
                                            padding: 5,
                                            borderRadius: 8,
                                            border: "1px #E6E6E6 solid",
                                        }}
                                    >
                                        {/* Sales & Manpower Table 2 starts */}
                                        <MonthlyBarChart
                                            // radioValue={onChangeRadioValue}
                                            height={550}
                                            type={"line"}
                                            series={[
                                                {
                                                    name: "Achived Amount",
                                                    data: lineGraphData,
                                                },
                                            ]}
                                            optionsObj={{
                                                chart: {
                                                    height: 350,
                                                    type: "line",
                                                    zoom: {
                                                        enabled: false,
                                                    },
                                                },
                                                dataLabels: {
                                                    enabled: false,
                                                },
                                                stroke: {
                                                    curve: "straight",
                                                },
                                                title: {
                                                    text: "Performance",
                                                    align: "left",
                                                },
                                                grid: {
                                                    row: {
                                                        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                                                        opacity: 0.5,
                                                    },
                                                },
                                                xaxis: {
                                                    categories: [
                                                        "Apr",
                                                        "May",
                                                        "Jun",
                                                        "Jul",
                                                        "Aug",
                                                        "Sep",
                                                        "Oct",
                                                        "Nov",
                                                        "Dec",
                                                        "Jan",
                                                        "Feb",
                                                        "Mar",
                                                    ],
                                                },
                                            }}
                                        />

                                        {/* Sales & Manpower Table 2 end */}
                                    </div>
                                </div>

                                <div
                                    className="row"
                                    style={{
                                        gap: 8,
                                        marginBottom: 10,
                                        width: "100%",
                                        marginLeft: 5,
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "97%",
                                            background: "white",
                                            padding: 5,
                                            borderRadius: 8,
                                            border: "1px #E6E6E6 solid",
                                        }}
                                    >
                                        {/* Top beat starts */}
                                        <div
                                            // className="card"
                                            style={{
                                                marginBottom: -2,
                                                background: "white",
                                                borderRadius: 8,
                                                border: "0 #E6E6E6 solid",
                                            }}
                                        >
                                            <div
                                                className="card-header align-items-center d-flex"
                                                style={{
                                                    background: "white",
                                                    borderRadius: 8,
                                                    border: "0 #E6E6E6 solid",
                                                    // marginTop: 20,
                                                }}
                                            >
                                                <h4
                                                    className="text-uppercase card-title mb-0 flex-grow-1"
                                                    style={{}}
                                                >
                                                    Sales Team List
                                                    <div
                                                        style={{
                                                            marginLeft: -20,
                                                        }}
                                                    >
                                                        <LandingScreenHeader
                                                            screenTitle={""}
                                                            showSearchBox={true}
                                                            showTable={true}
                                                            showFilter={false}
                                                            showPdfDownload={false}
                                                            showExcelDownload={false}
                                                            generateExcel={this.generateExcel}
                                                            onSearch={this.onSearchForSalesTeamList}
                                                        />
                                                    </div>
                                                </h4>
                                                <h4
                                                    className="text-uppercase card-title mb-0 flex-grow-1"
                                                    style={{
                                                        textAlign: "center",
                                                        width: "300px",
                                                    }}
                                                >
                                                    <Grid
                                                        item
                                                        sm={12}
                                                        xs={12}
                                                        md={12}
                                                        lg={12}
                                                        container
                                                        justifyContent="space-between"
                                                    >
                                                        <Grid
                                                            item
                                                            sm={4}
                                                            xs={4}
                                                            md={4}
                                                            lg={4}
                                                            container
                                                            justifyContent="space-evenly"
                                                        >
                                                            <Grid item xs={12} md={5} sm={5}>
                                                                <LabelCompo
                                                                    className="text-black"
                                                                    style={{ fontSize: 16, color: redColor }}
                                                                    label="* "
                                                                />
                                                                <LabelCompo
                                                                    className="text-black"
                                                                    style={{ fontSize: 16, color: titleColor }}
                                                                    label="From Date :"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={7} sm={7}>
                                                                <BasicDatePicker
                                                                    color="primary"
                                                                    name="fromDate"
                                                                    size="small"
                                                                    value={fromDate}
                                                                    disabled={false}
                                                                    fullWidth
                                                                    onChange={this.changeDate("fromDate")}
                                                                    errorText={
                                                                        formErrors["fromDateError"]
                                                                            ? formErrors["fromDateError"]
                                                                            : ""
                                                                    }
                                                                    isError={
                                                                        formErrors["fromDateError"]
                                                                            ? formErrors["fromDateError"]
                                                                            : ""
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            sm={4}
                                                            xs={4}
                                                            md={4}
                                                            lg={4}
                                                            container
                                                            justifyContent="space-evenly"
                                                        >
                                                            <Grid item xs={12} md={5} sm={5}>
                                                                <LabelCompo
                                                                    className="text-black"
                                                                    style={{ fontSize: 16, color: redColor }}
                                                                    label="* "
                                                                />
                                                                <LabelCompo
                                                                    className="text-black"
                                                                    style={{ fontSize: 16, color: titleColor }}
                                                                    label="To Date :"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={7} sm={7}>
                                                                <BasicDatePicker
                                                                    color="primary"
                                                                    name="toDate"
                                                                    size="small"
                                                                    value={toDate}
                                                                    minDate={fromDate}
                                                                    disabled={false}
                                                                    fullWidth
                                                                    onChange={this.changeDate("toDate")}
                                                                    errorText={
                                                                        formErrors["toDateError"]
                                                                            ? formErrors["toDateError"]
                                                                            : ""
                                                                    }
                                                                    isError={
                                                                        formErrors["toDateError"]
                                                                            ? formErrors["toDateError"]
                                                                            : ""
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            sm={2}
                                                            xs={2}
                                                            md={2}
                                                            lg={2}
                                                            container
                                                            justifyContent="space-evenly"
                                                        >
                                                            <ButtonCompo
                                                                sx={{
                                                                    bgcolor: "dodgerblue",
                                                                    color: "#fbff00",
                                                                }}
                                                                size="medium"
                                                                type="button"
                                                                variant="contained"
                                                                name={"Search"}
                                                                onClick={this.searchSalesTeamData}
                                                                fullWidth={false}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            sm={2}
                                                            xs={2}
                                                            md={2}
                                                            lg={2}
                                                            container
                                                            justifyContent="space-evenly"
                                                        >
                                                            <ButtonCompo
                                                                sx={{
                                                                    bgcolor: "dodgerblue",
                                                                    color: "#fbff00",
                                                                }}
                                                                size="medium"
                                                                type="button"
                                                                variant="contained"
                                                                name={"Excel"}
                                                                onClick={this.generateExcel}
                                                                fullWidth={false}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </h4>
                                            </div>
                                        </div>

                                        <TableContainer
                                            sx={{
                                                maxHeight: 440,
                                                // width: 610,
                                                padding: 1.5,
                                                // marginTop: 2.5,
                                            }}
                                        >
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead
                                                    style={{
                                                        backgroundColor: "#fff",
                                                    }}
                                                >
                                                    <TableRow>
                                                        <TableCell
                                                            color="#000"
                                                            align="center"
                                                            sx={{ width: "1%", color: "#000" }}
                                                            canSearch="true"
                                                        >
                                                            Sr.No
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "7%", color: "#000" }}
                                                            canSearch="true"
                                                        >
                                                            Sales Team Name
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "2%", color: "#000" }}
                                                            canSearch="true"
                                                        >
                                                            District
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "15%", color: "#000" }}
                                                            canSearch="true"
                                                        >
                                                            Area
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "3%", color: "#000" }}
                                                            canSearch="true"
                                                        >
                                                            Beat Count
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "3%", color: "#000" }}
                                                            canSearch="true"
                                                        >
                                                            Total Outlet
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "3%", color: "#000" }}
                                                            canSearch="true"
                                                        >
                                                            Productive Call
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "3%", color: "#000" }}
                                                            canSearch="true"
                                                        >
                                                            Unvisited Outlet
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "3%", color: "#000" }}
                                                            canSearch="true"
                                                        >
                                                            Zero Order
                                                        </TableCell>

                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "3%", color: "#000" }}
                                                            canSearch="true"
                                                        >
                                                            Total Order Amount
                                                        </TableCell>

                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "3%", color: "#000" }}
                                                            canSearch="true"
                                                        >
                                                            Total Sales Amount
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {dashboardData.salesInfo.length != 0 &&
                                                        this.getFilteredTableDataForSalesTeamList(
                                                            dashboardData.salesInfo
                                                        ).map((salesTeamObject, index) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell align="center" canSearch="true">
                                                                        {index + 1}
                                                                    </TableCell>
                                                                    <TableCell width="2%" canSearch="true">
                                                                        <Link
                                                                            color={cyanBlue}
                                                                            component={RouterLink}
                                                                            to={
                                                                                "/sales/" + salesTeamObject.salesTeamId
                                                                            }
                                                                        >
                                                                            {salesTeamObject.name}
                                                                        </Link>
                                                                    </TableCell>
                                                                    <TableCell align="left" canSearch="true">
                                                                        {salesTeamObject.district_names}
                                                                    </TableCell>
                                                                    <TableCell align="left" canSearch="true">
                                                                        {salesTeamObject.area_names}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        width="2%"
                                                                        align="right"
                                                                        canSearch="true"
                                                                    >
                                                                        <div
                                                                            onClick={(e) =>
                                                                                this.salesTeamHandler(
                                                                                    e,
                                                                                    salesTeamObject.salesTeamId
                                                                                )
                                                                            }
                                                                            style={{
                                                                                color: cyanBlue,
                                                                                cursor: "pointer",
                                                                            }}
                                                                        >
                                                                            {salesTeamObject.beatCount}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell align="right" canSearch="true">
                                                                        {salesTeamObject.totalOutletCount}
                                                                    </TableCell>
                                                                    <TableCell align="right" canSearch="true">
                                                                        {salesTeamObject.productiveCall}
                                                                    </TableCell>
                                                                    <TableCell align="right" canSearch="true">
                                                                        {salesTeamObject.unvisitedOutletCount}
                                                                    </TableCell>
                                                                    <TableCell align="right" canSearch="true">
                                                                        {salesTeamObject.zeroOrderCount}
                                                                    </TableCell>
                                                                    <TableCell align="right" canSearch="true">
                                                                        {salesTeamObject.totalAmount}
                                                                    </TableCell>
                                                                    <TableCell align="right" canSearch="true">
                                                                        {salesTeamObject.totalSalesAmt}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}

                                                    <TableRow>
                                                        <TableCell align="right" width="2%" colSpan={5}>
                                                            Total
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {totalOutletCount}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {productiveCall}
                                                        </TableCell>
                                                        <TableCell align="right">{unvisited}</TableCell>
                                                        <TableCell align="right">{zero}</TableCell>
                                                        <TableCell align="right">
                                                            {totalAmount.toFixed(2)}
                                                        </TableCell>
                                                        <TableCell align="right">{salesAmt}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {beatPopupFlag && (
                                            <BeatPopUp
                                                open={beatPopupFlag}
                                                handleCloses={this.handleClosesSalesTeam}
                                                columns={[
                                                    {
                                                        title: "Sr. No.",
                                                        name: "index",
                                                        positionCenter: false,
                                                        showInscreen: true,
                                                        alignCenter: "center",
                                                        canSearch: true,
                                                    },
                                                    {
                                                        title: "Beat",
                                                        name: "beatName",
                                                        positionCenter: false,
                                                        showInscreen: true,
                                                        alignCenter: "left",
                                                        canSearch: true,
                                                    },
                                                ]}
                                                rows={beatList?.beatFromSalesTeamId}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* Person-wise sales Div starts */}

                            {/* Sales & Manpower Div end */}

                            <footer className="footer">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-sm-6">2022 Â© Byte Elephants. </div>
                                        <div className="col-sm-6">
                                            <div className="text-sm-end d-none d-sm-block">
                                                {" "}
                                                Designed &amp; Developed by Byte Elephants Technologies
                                                PVT LTD{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </footer>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    common: state.common,
    dashboardData: state.adminDashboard,
    stockStakeHolderTypeList: state.stockStakeHolderType,
    zoneList: state.zone,
    areaList: state.area,
    dashboardDetails: state.dashboard,
    billingHistoryList: state.billingHistory,
    subCategoryList: state.subCategory,
    beatList: state.beat,
});

const mapDispatchToProps = {
    onToggle,
    showLoador,
    showNotification,
    getRegionListFromYearAndMonth,
    getZoneListFromYearAndMonth,
    getStockStakeHolderType,
    getSalesListFromYearAndMonth,
    getMonthYearTarget,
    getMonthYearTargetForSales,
    setRegionListFromYearAndMonth,
    getSalesGraph,
    getZoneStatewiseListFromYearAndMonth,
    getStateRegionwiseListFromYearAndMonth,
    getAdminDashboard,
    getCategorywiseBill,
    getSalesTeamInfoByDatesNew,
    getPackagingType,
    getSubCategoryPackagingType,
    getCategoryByPackagingType,
    getTopProductOrderListFromYearAndMonthBillWise,
    getBeatFromSalesTeamId,
    getDistrictListFromYearAndMonth,
    getTalukaListFromYearAndMonth,
    getTopProductForAdminDashboard,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AnalyticalAdminDashboard);
