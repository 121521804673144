import dayjs from "dayjs";

export const batchwiseStakeholderBillJson = {
  showSaveNextBtn: true,
  showAddButton: true,
  screenTitle: "Stock Holder Bill",
  formPath: "/batchwise-stakeholder-bill-form",
  showBackToList: true,
  showTitle: false,
  searchList: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromdate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "todate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "todate",
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
    },
    {
      label: "Stock Holder",
      controlType: "multiSelect",
      placeHolder: "Select Stock Holder",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "stakeholdersDetail",
      dataKey: "stackholder",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
  fieldMeta: [
    // {
    //   label: "Date",
    //   controlType: "datepicker",
    //   placeHolder: "",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "date",
    //   defaultValue: dayjs(),
    //   getListId: "stakeholder,date",
    //   isMandatory: true,
    // },

    {
      label: "Stock Holder",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeholdersDetail",
      dataKey: "stakeholder",
      isRootLevelKey: true,
      isMandatory: true,
      getListId: "stakeholder",
    },
    {
      label: "Order",
      controlType: "autocomplete",
      placeHolder: "Select Order",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "orderDetail",
      dataKey: "orderId",
      isRootLevelKey: true,
      isMandatory: true,
      getListFrom: "stakeholder",
      getListId: "orderId",
    },
    {
      label: "Discount Percentage",
      controlType: "textfield",
      placeHolder: "Enter Percentage",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "discountOnBillPer",
      inputType: "number",
      isMandatory: false,
      valid: true,
      validName: "greater-thanHundered",
    },
  ],
  fieldMetaDetails: [
    {
      label: "Product ",
      controlType: "autocomplete",
      placeHolder: "Select Product",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "productDetail",
      dataKey: "product",
      getListFrom: "orderId",
      isRootLevelKey: true,
      getListId: "product",
      isMandatory: true,
      getData: true,
    },
    {
      label: "Batch ",
      controlType: "autocomplete",
      placeHolder: "Select Batch",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "batchDetail",
      dataKey: "batch",
      getListFrom: "product",
      isRootLevelKey: true,
      isMandatory: true,
      getData: true,
    },
    {
      label: "Batch Quantity",
      controlType: "textfield",
      placeHolder: "Enter quantity",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "primaryClosingQty",
      isMandatory: false,
      spValid: "float",
      disableTrue: true,
    },
    {
      label: "Order Quantity",
      controlType: "textfield",
      placeHolder: "Enter quantity",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "orderQty",
      isMandatory: false,
      spValid: "float",
      disableTrue: true,
    },
    {
      label: "Quantity",
      controlType: "textfield",
      placeHolder: "Enter Quantity",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      spValid: "float",
      calculateMinus: true,
      dataKey: "qty",
      valid: true,
      validName: "greater-less",
      zeroValid: true,
      validCondition: "less-orderQty",
      isMandatory: true,
      validText: "Order Quantity",
    },
    {
      label: "Free Quantity",
      controlType: "textfield",
      placeHolder: "Enter Quantity",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "freeQty",
      isMandatory: false,
    },
    {
      label: "Item Discount %",
      controlType: "textfield",
      placeHolder: "Enter percentage",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "itemDiscPer",
      isMandatory: false,
      valid: true,
      validName: "greater-thanHundered",
    },
    {
      label: "Rate",
      controlType: "textfield",
      placeHolder: "Enter Rate",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "rate",
      isMandatory: false,
      spValid: "float",
      disableTrue: true,
    },
    {
      label: "MRP",
      controlType: "textfield",
      placeHolder: "Enter MRP",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "mrp",
      isMandatory: false,
      spValid: "float",
      disableTrue: true,
    },
    {
      label: "Outlet UOM",
      controlType: "textfield",
      placeHolder: "Enter UOM",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "uom",
      isMandatory: false,
      disableTrue: true,
    },
    {
      label: "Conversion Value",
      controlType: "textfield",
      placeHolder: "Enter Conversion Value",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "uomConversionValue",
      isMandatory: false,
      disableTrue: true,
    },
  ],
  fieldMetaForBilling: [
    {
      label: "Po No",
      controlType: "textfield",
      placeHolder: "Enter Po No",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "poNo",
      isMandatory: false,
    },
    {
      label: "Po Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "poDate",
      defaultValue: dayjs(),
      isMandatory: false,
    },
    {
      label: "Transporter",
      controlType: "autocomplete",
      placeHolder: "Select Transporter",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "transporterDetail",
      dataKey: "transporter",
      isRootLevelKey: true,
      isMandatory: false,
    },

    {
      label: "LR/RR/GR Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "lrRrGrDate",
      defaultValue: dayjs(),
      isMandatory: false,
    },
    {
      label: "E-Way Bill Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "eWayBillDate",
      defaultValue: dayjs(),
      isMandatory: false,
    },
    {
      label: "Vehicle No",
      controlType: "textfield",
      placeHolder: "Vehicle No",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "vehicleNo",
      isMandatory: false,
    },
    {
      label: "Same AS Bill To",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sameAsBillTo",
      tableDataKey: "sameAsBillTo",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: false,
    },
    // {
    //   label: "Firm Name",
    //   controlType: "textfield",
    //   placeHolder: "Firm Name",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "firmName",
    //   isMandatory: false,
    // },
    // {
    //   label: "Address",
    //   controlType: "textfield",
    //   placeHolder: "Address",
    //   md: 12,
    //   lg: 12,
    //   sm: 12,
    //   xs: 12,
    //   labelSM: 12,
    //   labelMD: 12,
    //   labelLG: 12,
    //   valueSM: 12,
    //   valueMD: 12,
    //   valueLG: 12,
    //   dataKey: "address",
    //   isMandatory: false,
    // },
    // {
    //   label: "State",
    //   controlType: "textfield",
    //   placeHolder: "State",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "state",
    //   isMandatory: false,
    // },
    // {
    //   label: "City",
    //   controlType: "textfield",
    //   placeHolder: "City",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "city",
    //   isMandatory: false,
    // },
    // {
    //   label: "GSTIN/UIN No",
    //   controlType: "textfield",
    //   placeHolder: "GSTIN/UIN No",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "gstin",
    //   isMandatory: false,
    // },
    {
      label: "Stock Holder",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeholdersDetail",
      dataKey: "stakeholder",
      getListId: "stakeholder",
      isRootLevelKey: true,
      isMandatory: false,
    },
    {
      label: "Remark",
      controlType: "textfield",
      placeHolder: "Remark",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 12,
      labelMD: 12,
      labelLG: 12,
      valueSM: 12,
      valueMD: 12,
      valueLG: 12,
      dataKey: "remark",
      isMandatory: false,
    },
  ],
};
