import endpoint from "../../config/endpoints";

export const SalesTeamOrderConfigFormJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.manageStockHeader,
  screenTitle: "Sales Team Order Configuration",
  formPath: "/sales-team-order-config-form",
  showBackToList: false,
  showTitle: true,
  fieldMeta: [
    {
      label: "Sales Person",
      controlType: "autocomplete",
      placeHolder: "Select Sales Person ",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      masterName: "salesPersonDetail",
      dataKey: "salesPerson",
      isRootLevelKey: true,
      isMandatory: true,
      disable: true,
    },
    {
      label: "Stock Holder Type ",
      controlType: "multiSelect",
      placeHolder: "Select Stock Holder",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      masterName: "stakeholderDetail",
      dataKey: "stakeholderIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
};
