import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { OtherTaskJson } from "../../../DynamicFormsJson/MastersJSON/otherTaskJson";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
class OtherTaskForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        return (
            <>
                <DynamicForm
                    formPath={OtherTaskJson.formPath}
                    screenTitle={OtherTaskJson.screenTitle}
                    fieldMeta={OtherTaskJson.fieldMeta}
                    apiBaseURL={OtherTaskJson.apiBaseURL}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({

});
const mapDispatchToProps = {
    showLoador,
    showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(OtherTaskForm);
