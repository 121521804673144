import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import "../../CSS/app.min.css";
import "../../CSS/bootstrap.min.css";
import "../../CSS/custom.min.css";
import "../../CSS/mermaid.min.css";
import "../../CSS/slickDashboard.css";
import { onToggle } from "../../CommonActions/common.slice";
import image10 from "../../Images/image10.png";
import image11 from "../../Images/image11.png";
import image4 from "../../Images/image4.png";
import image5 from "../../Images/image5.png";
import image7BgClr from "../../Images/image7BgClr.png";
import image8 from "../../Images/image8.png";
import image9 from "../../Images/image9.png";
import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import {
  getAreaListFromYearAndMonth,
  getMonthYearTargetForSales,
  getRegionListFromYearAndMonth,
  getSalesListFromYearAndMonth,
  getSalesTeamInfo,
  getSalesTeamInfoByDatesNew,
  getZoneListFromYearAndMonth,
} from "../../Slice/adminDashboard.slice";
import { getBeatFromSalesTeamId } from "../../Slice/beat.slice";
import { getBeatTargetByUserType } from "../../Slice/beatTarget.slice";
import {
  getBillDataByOutletId,
  getTopOutletOrderListFromYearAndMonthByBillWise,
  getTopProductOrderListFromYearAndMonthBillWise,
} from "../../Slice/billingHistory.slice";
import {
  getDashBourdMain,
  getDataForLineGraph,
} from "../../Slice/distributorDashbourd.slice";
import { getNoticeMsgByUserType } from "../../Slice/noticeMsg.slice";
import { getPendingOrderByMonthYear } from "../../Slice/outletOrderDetail.slice";
import { getSalesExecutiveWithCheckInOut } from "../../Slice/salesExecutive.slice";
import { getStockStakeHolderTypeForList } from "../../Slice/stockStakeHolderType.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import { ConvertAmount } from "../../components/Comman/ConvertValueWithDenominator";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import RTTable from "../../components/Comman/RT/RTTable";
import MonthCalender from "../../components/MonthCalender";
import { cyanBlue, redColor, titleColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import BeatPopUp from "../DashbourdChanges/BeatPopUp";
import MonthlyBarChart from "../DashbourdChanges/MonthlyBarChart";
import NoDataFound from "../DashbourdChanges/NoDataFound";
import PieChart from "../DashbourdChanges/PieChart";
import RadarChart from "../DashbourdChanges/RadarChart";
import ReportAreaChart from "../DashbourdChanges/ReportAreaChart";
import { downloadF } from "../PDF/PDFDownload";
import DistributionDashboardPopUp from "./DistributionDashboardPopUp";
import DistributorDashboardBeatTargetPopUp from "./DistributorDashboardBeatTargetPopUp";
import DistributorDashboardSalesTeamPopup from "./DistributorDashboardSalesTeamPopup";
import { withStyles } from "@material-ui/core";

const StickyTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    // color: theme.palette.common.white,
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 2,
  },
  body: {
    backgroundColor: "#fff",
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 1,
  },
}))(TableCell);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

let pendingProductList = [
  {
    id: "1",
    outletName: "Chirag",
    BillDate: "01-Sep-2023",
    remainingAmount: "12400",
  },
  {
    id: "1",
    outletName: "Nandan",
    BillDate: "17-Apr-2023",
    remainingAmount: "24000",
  },
  {
    id: "1",
    outletName: "Sai Sweets",
    BillDate: "01-Oct-2023",
    remainingAmount: "4000",
  },
  {
    id: "1",
    outletName: "Nilesh Collections",
    BillDate: "21-Sep-2023",
    remainingAmount: "7000",
  },
  {
    id: "1",
    outletName: "Rahul Traders",
    BillDate: "21-Aug-2023",
    remainingAmount: "30000",
  },
];

class DistributorDashboard extends Component {
  toggleDrawer = () => {
    this.props.onToggle();
  };
  state = {
    showFeedback: false,
    showPreview: false,
    date1: new Date(),
    newDate: "",
    currentDate: new Date(),
    curDate: new Date(),
    dob: "",
    dobDay: "",
    dobMonth: "",
    currenDate: "",
    currentMonth: "",
    timeTableList: [],
    subject: "",
    batch: "",
    yearDetail: "",
    semester: "",
    division: "",
    searchValue: "",
    pieChartData: [],
    isLoading: false,
    showLoader: false,
    profile: "",
    nameFirstLetter: "",
    showZone: false,
    showArea: false,
    date: "",
    saleDate: "",
    outletDate: "",
    monthDate: "",
    yearDate: "",
    type: "",
    saleMonth: dayjs(),
    pendingSaleMonth: dayjs(),
    topProductSaleMonth: dayjs(),
    outletPendingDate: dayjs(),
    saleYear: "",
    month: "",
    year: "",
    formErrors: {},
    graphList: [],
    coloumnChartList: [],
    totalOfOrderAmt: 0,
    tableRow: 0,
    searchValueForProduct: "",
    topPerformingProductData: [],
    searchValueForOutlets: "",
    topPerformingOutletData: [],
    searchValueForPendingBill: "",
    pendingBillData: [],
    searchValueForPending: "",
    searchValueForSalesTeamList: "",
    totalOutletCount: 0,
    zero: 0,
    productiveCall: 0,
    unvisited: 0,
    totalAmount: 0,
    salesAmt: 0,
    remoteOrder: 0,
    fromDate: dayjs(),
    toDate: dayjs(),
    pendingBillDataBottomColumn: [],
    searchValueForPendingBottomColumn: "",
    noticeMspPopUp: true,
    licensePopUp: false,
    popUpFlag: false,
    salesTeamPopupFlag: false,
    beatTargetPopUpFlag: false,
    beatPopupFlag: false,
    pendingOrderBySearch: false,
    outletBySearch: false,
    productBySearch: false,
    totalOutletPendingAmount: 0,
    tableRowForOutletPending: 0,

    columns: [
      { id: "name", label: "Name", minWidth: 170 },
      { id: "code", label: "ISO\u00a0Code", minWidth: 100 },
      {
        id: "population",
        label: "Population",
        minWidth: 170,
        align: "right",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "size",
        label: "Size\u00a0(km\u00b2)",
        minWidth: 170,
        align: "right",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "density",
        label: "Density",
        minWidth: 170,
        align: "right",
        format: (value) => value.toFixed(2),
      },
    ],
    rows: [
      this.createData("India", "IN", 1324171354, 3287263),
      this.createData("China", "CN", 1403500365, 9596961),
      this.createData("Italy", "IT", 60483973, 301340),
      this.createData("United States", "US", 327167434, 9833520),
      this.createData("Canada", "CA", 37602103, 9984670),
      this.createData("Australia", "AU", 25475400, 7692024),
      this.createData("Germany", "DE", 83019200, 357578),
      this.createData("Ireland", "IE", 4857000, 70273),
      this.createData("Mexico", "MX", 126577691, 1972570),
      this.createData("Japan", "JP", 126317000, 377973),
      this.createData("France", "FR", 67022000, 640679),
      this.createData("United Kingdom", "GB", 67545757, 242495),
      this.createData("Russia", "RU", 146793744, 17098246),
      this.createData("Nigeria", "NG", 200962417, 923768),
      this.createData("Brazil", "BR", 210147125, 8515767),
    ],
    rowsPerPage: 10,
    page: 0,
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };
  newList = pendingProductList;
  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
    let tableData = pendingProductList.filter((currentRow) => {
      if (
        currentRow["outletName"] &&
        currentRow["outletName"]
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        return currentRow;
      }
    });

    pendingProductList = tableData;
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0,
    });
  };

  createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }

  async componentDidMount() {
    const { totalOfOrderAmt, totalOfOutletPendingAmt, tableRow } = this.state;
    const {
      getPendingOrderByMonthYear,
      getNoticeMsgByUserType,
      getTopOutletOrderListFromYearAndMonthByBillWise,
      getTopProductOrderListFromYearAndMonthBillWise,
      getDashBourdMain,
      getDataForLineGraph,
      getSalesTeamInfo,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      const distributorDashData = localStorage.getItem("distributorDash");
      const distributorDashDataLocalStorage = JSON.parse(distributorDashData);

      const outletOrderPendingListData = localStorage.getItem(
        "outletOrderPendingListData"
      );
      const outletOrderPendingListLocalStorage = JSON.parse(
        outletOrderPendingListData
      );

      const lineGraphData = localStorage.getItem("lineGraphData");
      const lineGraphDataLocalStorage = JSON.parse(lineGraphData);

      const topProductOrderListData = localStorage.getItem(
        "topProductOrderListData"
      );
      const topProductOrderListDataLocalStorage = JSON.parse(
        topProductOrderListData
      );

      const topOutletOrderListData = localStorage.getItem(
        "topOutletOrderListData"
      );
      const topOutletOrderListDataLocalStorage = JSON.parse(
        topOutletOrderListData
      );

      const noticeMsgListDataList = localStorage.getItem("noticeMsgListData");
      const noticeMsgListDataLocalStorage = JSON.parse(noticeMsgListDataList);

      if (!noticeMsgListDataLocalStorage) {
        showLoador({ loador: true });
        await getNoticeMsgByUserType().then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            const noticeMsgList = response.length !== 0 ? response : [];
            localStorage.setItem(
              "noticeMsgListData",
              JSON.stringify(noticeMsgList)
            );

            this.setState({
              noticeMspPopUp: true,
            });
          }
        });
      }

      if (!topOutletOrderListDataLocalStorage) {
        showLoador({ loador: true });
        await getTopOutletOrderListFromYearAndMonthByBillWise({
          month: dayjs().format("MM"),
          year: dayjs().format("YYYY"),
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            const topOutletOrderList = response.length !== 0 ? response : [];
            localStorage.setItem(
              "topOutletOrderListData",
              JSON.stringify(topOutletOrderList)
            );
          }
        });
      }

      if (!topProductOrderListDataLocalStorage) {
        showLoador({ loador: true });
        await getTopProductOrderListFromYearAndMonthBillWise({
          month: dayjs().format("MM"),
          year: dayjs().format("YYYY"),
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            const topProductOrderList = response.length !== 0 ? response : [];
            localStorage.setItem(
              "topProductOrderListData",
              JSON.stringify(topProductOrderList)
            );
          }
        });
      }

      if (!outletOrderPendingListLocalStorage) {
        showLoador({ loador: true });
        await getPendingOrderByMonthYear({
          month: dayjs().format("MM"),
          year: dayjs().format("YYYY"),
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({
              msg: serverMsg,
              severity: "error",
            });
          } else {
            let totalOutletPendingAmount = 0;
            response.map((outletObject) => {
              totalOutletPendingAmount =
                totalOutletPendingAmount + +outletObject.totalAmount;
            });
            const tableRowForOutletPending = response.length;
            localStorage.setItem(
              "tableRowForOutletPending",
              tableRowForOutletPending
            );
            localStorage.setItem(
              "totalOutletPendingAmount",
              totalOutletPendingAmount.toFixed(2)
            );

            const outletOrderPendingList =
              response.length !== 0 ? response : [];
            localStorage.setItem(
              "outletOrderPendingListData",
              JSON.stringify(outletOrderPendingList)
            );
          }
        });
      }

      if (!distributorDashDataLocalStorage) {
        showLoador({ loador: true });
        apiGet({
          url: endpoint.stackholder + "/check-license-expiration",
        }).then((data) => {
          showLoador({ loador: false });
          if (data !== null && Object.keys(data.data.data).length !== 0) {
            if (data.data.data.error) {
              let status = data.data.data.message;
              this.setState({
                formStatus: status,
                licensePopUp: true,
              });
            }
          }
        });
      }

      if (!distributorDashDataLocalStorage) {
        showLoador({ loador: true });
        await getDashBourdMain().then(({ response, success }) => {
          if (!success) {
            showLoador({ loador: false });
            showNotification({
              msg: serverMsg,
              severity: "error",
            });
          } else {
            if (response !== null && Object.keys(response).length !== 0) {
              localStorage.setItem("distributorDash", JSON.stringify(response));

              let totalOfRemainingAmt = 0;
              response.dtoOutletPendingBill.map((dtoOutletPendingBillObj) => {
                totalOfRemainingAmt =
                  totalOfRemainingAmt + +dtoOutletPendingBillObj.remainingAmt;
              });

              localStorage.setItem(
                "totalOfRemainingAmt",
                totalOfRemainingAmt.toFixed(2)
              );
            } else {
              localStorage.setItem("distributorDash", JSON.stringify([]));
            }
          }
        });
      }

      if (!lineGraphDataLocalStorage) {
        await getDataForLineGraph({ fromDate: "", toDate: "" }).then(
          ({ response, success }) => {
            console.log(response);
            if (!success) {
              showNotification({
                msg: serverMsg,
                severity: "error",
              });
            } else {
              if (response !== null && Object.keys(response).length !== 0) {
                localStorage.setItem("lineGraphData", JSON.stringify(response));
              } else {
                localStorage.setItem("lineGraphData", JSON.stringify([]));
              }
            }
            showLoador({ loador: false });
          }
        );
      }
    }
  }

  viewProfile = () => {
    window.location.replace("/empPreview");
  };
  GoBack = () => {
    this.setState({
      showPreview: false,
    });
  };
  openFeedBack = (e) => {
    e.preventDefault();
    this.setState({
      showFeedback: true,
    });
  };

  handleSalesTeamPopup = () => {
    const { getSalesExecutiveWithCheckInOut, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getSalesExecutiveWithCheckInOut().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            salesTeamPopupFlag: true,
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  handleBeatTargetPopup = () => {
    const { getBeatTargetByUserType, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getBeatTargetByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            beatTargetPopUpFlag: true,
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  handleCloseSalesTeam = () => {
    this.setState({
      salesTeamPopupFlag: false,
    });
  };

  handleClosesBeatTarget = () => {
    this.setState({
      beatTargetPopUpFlag: false,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  regionHandler = (e, regionId) => {
    const { getZoneListFromYearAndMonth, showLoador, showNotification } =
      this.props;
    const { month, year } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getZoneListFromYearAndMonth({
        month: month,
        year: year,
        regionId: regionId,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            showZone: true,
            showArea: false,
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  handleCloses = () => {
    this.setState({
      popUpFlag: false,
    });
  };
  handleClosesSalesTeam = () => {
    this.setState({
      beatPopupFlag: false,
    });
  };
  outletHandler = (e, outletId) => {
    const { getBillDataByOutletId, showLoador, showNotification } = this.props;
    const { popUpFlag } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getBillDataByOutletId({
        outletId: outletId,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            popUpFlag: true,
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  salesTeamHandler = (e, salesTeamId) => {
    const { getBeatFromSalesTeamId, showLoador, showNotification } = this.props;
    const { popUpFlag } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getBeatFromSalesTeamId({
        salesTeamIds: salesTeamId,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            beatPopupFlag: true,
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  salesHandler = (e, id) => {
    const { getMonthYearTargetForSales, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getMonthYearTargetForSales({ salesTeamId: id }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  salesTeamGraphHandler = (e, regionId) => {
    const { getZoneListFromYearAndMonth, showLoador, showNotification } =
      this.props;
    const { month, year } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getZoneListFromYearAndMonth({
        month: month,
        year: year,
        regionId: regionId,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            showZone: true,
            showArea: false,
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  zoneHandler = (e, zoneId) => {
    const { getAreaListFromYearAndMonth, showLoador, showNotification } =
      this.props;
    const { month, year } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getAreaListFromYearAndMonth({
        month: month,
        year: year,
        zoneId: zoneId,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            showArea: true,
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onDateChange = (date, name) => {
    this.setState({
      month: date.format("MM"),
      year: date.format("YYYY"),
      saleMonth: date,
      showZone: false,
      showArea: false,
    });
    const { getRegionListFromYearAndMonth, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getRegionListFromYearAndMonth({
        month: date.format("MM"),
        year: date.format("YYYY"),
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onDateChangeSale = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }

    this.setState({
      saleDate: date,
    });
  };

  onDateChangeOutlet = async (date, name) => {
    const {
      getTopOutletOrderListFromYearAndMonthByBillWise,
      getTopProductOrderListFromYearAndMonthBillWise,
      getPendingOrderByMonthYear,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      this.setState({
        [name]: date,
      });
      if (name == "pendingSaleMonth") {
        showLoador({ loador: true });
        await getPendingOrderByMonthYear({
          month: date.format("MM"),
          year: date.format("YYYY"),
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({
              msg: serverMsg,
              severity: "error",
            });
          } else {
            let totalOutletPendingAmount = 0;
            response.map((outletObject) => {
              totalOutletPendingAmount =
                totalOutletPendingAmount + +outletObject.totalAmount;
            });
            const tableRowForOutletPending = response.length;
            this.setState({
              pendingOrderBySearch: true,
              totalOutletPendingAmount: totalOutletPendingAmount.toFixed(2),
              tableRowForOutletPending: tableRowForOutletPending,
            });
          }
        });
      } else if (name == "topProductSaleMonth") {
        showLoador({ loador: true });
        await getTopProductOrderListFromYearAndMonthBillWise({
          month: date.format("MM"),
          year: date.format("YYYY"),
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.setState({
              productBySearch: true,
            });
          }
        });
      } else {
        showLoador({ loador: true });
        await getTopOutletOrderListFromYearAndMonthByBillWise({
          month: date.format("MM"),
          year: date.format("YYYY"),
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.setState({
              outletBySearch: true,
            });
          }
        });
      }
    } else {
      showNotification({ msg: myConstClass.noInternetMsg, severity: "error" });
    }
  };

  handleFormValidation() {
    const { type, saleMonth } = this.state;
    let formErrors = {};
    let formIsValid = true;
    if (type === "" || type === null) {
      formIsValid = false;
      formErrors["typeError"] = myConstClass.stackHolderTypeMsg;
    }
    if (saleMonth === "" || saleMonth === null) {
      formIsValid = false;
      formErrors["dateError"] = myConstClass.dateMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  searchSalesList = () => {
    const { getSalesListFromYearAndMonth } = this.props;
    const { saleMonth, type } = this.state;

    if (this.handleFormValidation()) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        getSalesListFromYearAndMonth({
          month: saleMonth.format("MM"),
          year: saleMonth.format("YYYY"),
          stockStakeHolderTypeId: type,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };

  onSearchForPendingBillBottomColumn = (searchData) => {
    const { distributorDashbourd } = this.props;
    const { pendingBillDataBottomColumn } = this.state;

    if (searchData && searchData.trim() !== "") {
      const filteredList =
        distributorDashbourd.dashbourdMain.dtoOutletPendingBill.filter(
          (currentRow) => {
            if (
              (currentRow.outLet["firmName"] &&
                currentRow.outLet["firmName"]
                  .toString()
                  .toLowerCase()
                  .includes(searchData.trim().toLowerCase())) ||
              (currentRow.outLet.beat["beatName"] &&
                currentRow.outLet.beat["beatName"]
                  .toString()
                  .toLowerCase()
                  .includes(searchData.trim().toLowerCase()))
            ) {
              return true;
            }
            return false;
          }
        );
      this.setState({
        searchValueForPendingBottomColumn: searchData.trim(),
        pendingBillDataBottomColumn: filteredList,
      });
    } else {
      this.setState({
        searchValueForPendingBottomColumn: "",
        pendingBillDataBottomColumn:
          distributorDashbourd.dashbourdMain.dtoOutletPendingBill,
      });
    }
  };

  onSearchForPedning = (searchValueForPending) => {
    this.setState({
      searchValueForPending: searchValueForPending,
    });
  };

  onSearchForPerformingProducts = (searchValueForProduct) => {
    this.setState({
      searchValueForProduct: searchValueForProduct,
    });
  };

  onSearchForPerformingOutlets = (searchValueForOutlets) => {
    this.setState({
      searchValueForOutlets: searchValueForOutlets,
    });
  };

  onSearch = (searchValueForPendingBottomColumn) => {
    this.setState({
      searchValueForPendingBottomColumn: searchValueForPendingBottomColumn,
    });
  };
  onSearchForSalesTeamList = (searchValueForSalesTeamList) => {
    this.setState({
      searchValueForSalesTeamList: searchValueForSalesTeamList,
    });
  };
  getFilteredTableDataForSalesTeamList = (searchData) => {
    const { searchValueForSalesTeamList } = this.state;
    return searchData.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(searchValueForSalesTeamList.trim().toLowerCase())) ||
        (currentRow["district_names"] &&
          currentRow["district_names"]
            .toString()
            .toLowerCase()
            .includes(searchValueForSalesTeamList.trim().toLowerCase())) ||
        (currentRow["area_names"] &&
          currentRow["area_names"]
            .toString()
            .toLowerCase()
            .includes(searchValueForSalesTeamList.trim().toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
    });
  };
  getFilteredTableDataForPending = (searchData) => {
    const { searchValueForPending } = this.state;
    return searchData.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["firmName"] &&
          currentRow["firmName"]
            .toString()
            .toLowerCase()
            .includes(searchValueForPending.trim().toLowerCase())) ||
        (currentRow["orderNo"] &&
          currentRow["orderNo"]
            .toString()
            .toLowerCase()
            .includes(searchValueForPending.trim().toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
    });
  };

  getFilteredTableDataForTopPerformingProducts = (searchData) => {
    const { searchValueForProduct } = this.state;
    return searchData.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(searchValueForProduct.trim().toLowerCase())) ||
        (currentRow["code"] &&
          currentRow["code"]
            .toString()
            .toLowerCase()
            .includes(searchValueForProduct.trim().toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
    });
  };

  getFilteredTableDataForTopPerformingOutlets = (searchData) => {
    const { searchValueForOutlets } = this.state;
    return searchData.filter((currentRow) => {
      let isValid = false;
      if (
        currentRow["firmName"] &&
        currentRow["firmName"]
          .toString()
          .toLowerCase()
          .includes(searchValueForOutlets.trim().toLowerCase())
      ) {
        isValid = true;
        return true;
      }
    });
  };

  getFilteredTableData = (searchData) => {
    const { searchValueForPendingBottomColumn } = this.state;
    return searchData.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["firmName"] &&
          currentRow["firmName"]
            .toString()
            .toLowerCase()
            .includes(
              searchValueForPendingBottomColumn.trim().toLowerCase()
            )) ||
        (currentRow["beatName"] &&
          currentRow["beatName"]
            .toString()
            .toLowerCase()
            .includes(searchValueForPendingBottomColumn.trim().toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
    });
  };

  onSearchForPendingBill = (searchData) => {
    const { distributorDashbourd } = this.props;
    const { pendingBillData } = this.state;
    if (searchData != "" || searchData != undefined) {
      this.setState({
        searchValueForPending: searchData,
      });
      const list =
        distributorDashbourd.dashbourdMain.dtoOutletPendingBill.filter(
          (currentRow) => {
            let isValid = false;
            if (
              currentRow["firmName"] &&
              currentRow["firmName"]
                .toString()
                .toLowerCase()
                .includes(searchData.toLowerCase())
            ) {
              isValid = true;
              return true;
            }
            return isValid;
          }
        );
      this.setState({
        pendingBillData: list,
      });
    } else {
      this.setState({
        searchValueForPending: "",
      });
      this.setState({
        pendingBillData:
          distributorDashbourd.dashbourdMain.dtoOutletPendingBill,
      });
    }
  };

  columns = [
    {
      name: "index",
      title: "Sr.No",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "name",
      title: "Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "targetAmt",
      title: "Target Amount",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "actualAmt",
      title: "Acheived",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "contribution",
      title: "% Contribution",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "divisional",
      title: "%(-/+)",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
  ];
  graphList = [{ x: 1, y: 64 }];
  onChangeMonthOrYear = (data, name) => {
    this.setState({
      [name]: data,
    });
  };

  onClickLineChart = (index) => {
    const { distributorDashbourd } = this.props;

    const lineGraphData = localStorage.getItem("lineGraphData");
    const lineGraphDataLocalStorage = JSON.parse(lineGraphData);

    let catOBJ =
      Object.keys(lineGraphDataLocalStorage).length != 0 &&
      lineGraphDataLocalStorage.monthWiseOutletTotalBill[index];

    if (Object.keys(catOBJ).length != 0) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        apiGet({
          url:
            endpoint.monthWiseTotalAmount +
            "/date-wise?monthYear=" +
            catOBJ.monthYear +
            "&type=0",
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.setState({
              coloumnChartList: data.data.map((rowObj, index) => {
                return {
                  index: index + 1,
                  date: rowObj.date,
                  totalAmt: rowObj.totalAmount.toFixed(2),
                };
              }),
            });
          }
        });
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };

  onCLickPie = (index) => { };

  onClickBar = (index) => { };

  onCLickOrderPie = (index) => { };

  getExtraData = (dtoSalesTeamInformations) => {
    let totalOutletCount = 0,
      zero = 0,
      productiveCall = 0,
      unvisited = 0,
      totalAmount = 0,
      remoteOrder = 0;
    dtoSalesTeamInformations.map((salesTeam) => {
      totalOutletCount = totalOutletCount + +salesTeam.totalOutletCount;
      productiveCall = productiveCall + +salesTeam.productiveCall;
      zero = zero + +salesTeam.zeroOrderCount;
      unvisited = unvisited + +salesTeam.unvisitedOutletCount;
      totalAmount = totalAmount + +salesTeam.totalAmount;
      remoteOrder = remoteOrder + +salesTeam.remoteOrderCount;
    });
    this.setState({
      totalOutletCount: totalOutletCount,
      productiveCall: productiveCall,
      unvisited: unvisited,
      zero: zero,
      totalAmount: totalAmount,
      remoteOrder: remoteOrder,
    });
  };

  handleClose = () => {
    this.setState({
      noticeMspPopUp: false,
    });
  };

  handleCloseForLicense = () => {
    this.setState({
      licensePopUp: false,
    });
  };

  generateExcel = () => {
    const { showLoador, showNotification } = this.props;
    const { fromDate, toDate } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      downloadF({
        url:
          endpoint.adminDashboard +
          "/get-excel-sales-teams?fromDate=" +
          dayjs(fromDate).format("DD-MM-YYYY") +
          "&toDate=" +
          dayjs(toDate).format("DD-MM-YYYY"),
        ext: "xlsx",
        openNewTab: true,
      }).then((response) => {
        showLoador({ loador: false });
        if (!response) {
          this.props.showNotification({
            msg: serverMsg,
            severity: "error",
          });
        } else {
        }
      });
    } else {
      showNotification({ msg: myConstClass.noInternetMsg, severity: "error" });
    }
  };

  changeDate = (name) => (date) => {
    if (name === "fromDate") {
      this.setState({
        fromDate: date,
      });
    }

    if (name === "toDate") {
      this.setState({
        toDate: date,
      });
    }
  };

  searchSalesTeamData = () => {
    const {
      getSalesTeamInfoByDatesNew,
      showLoador,
      showNotification,
      myConstClass,
    } = this.props;
    const { fromDate, toDate } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getSalesTeamInfoByDatesNew({
        fromDate: dayjs(fromDate).format("DD-MM-YYYY"),
        toDate: dayjs(toDate).format("DD-MM-YYYY"),
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: response.serverMsg, severity: "error" });
        } else {
          let totalOutletCount = 0,
            zero = 0,
            productiveCall = 0,
            unvisited = 0,
            salesAmt = 0,
            totalAmount = 0,
            remoteOrder = 0;
          if (response.length !== 0) {
            response.map((salesTeam) => {
              totalOutletCount = totalOutletCount + +salesTeam.totalOutletCount;
              productiveCall = productiveCall + +salesTeam.productiveCall;
              zero = zero + +salesTeam.zeroOrderCount;
              unvisited = unvisited + +salesTeam.unvisitedOutletCount;
              salesAmt = salesAmt + +salesTeam.totalSalesAmt;
              totalAmount = totalAmount + +salesTeam.totalAmount;
              remoteOrder = remoteOrder + +salesTeam.remoteOrderCount;
            });
          }
          this.setState({
            totalOutletCount: totalOutletCount,
            zero: zero,
            productiveCall: productiveCall,
            unvisited: unvisited,
            salesAmt: salesAmt,
            totalAmount: totalAmount,
            remoteOrder: remoteOrder,
          });
        }
      });
    } else {
      showNotification({ msg: myConstClass.noInternetMsg, severity: "error" });
    }
  };

  render() {
    const {
      dashboardData2,
      stockStakeHolderTypeList,
      distributorDashbourd,
      billingHistoryList,
      noticeList,
      salesExecutiveList,
      outletOrderDetailList,
      beatList,
      showNotification,
      showLoador,
      beatTargetList,
    } = this.props;
    const {
      type,
      showZone,
      showArea,
      saleMonth,
      pendingSaleMonth,
      topProductSaleMonth,
      columns,
      rows,
      rowsPerPage,
      page,
      coloumnChartList,
      searchValue,
      totalOfOrderAmt,
      searchValueForPending,
      searchValueForProduct,
      searchValueForOutlets,
      searchValueForPendingBill,
      tableRow,
      topPerformingProductData,
      noticeMspPopUp,
      licensePopUp,
      formStatus,
      popUpFlag,
      salesTeamPopupFlag,
      beatTargetPopUpFlag,
      beatPopupFlag,
      pendingBillData,
      topPerformingOutletData,
      searchValueForPendingBottomColumn,
      pendingBillDataBottomColumn,
      totalOutletCount,
      productiveCall,
      remoteOrder,
      unvisited,
      zero,
      totalAmount,
      salesAmt,
      toDate,
      fromDate,
      formErrors,
      outletBySearch,
      pendingOrderBySearch,
      productBySearch,
      totalOutletPendingAmount,
      tableRowForOutletPending,
    } = this.state;
    const { typeError, dateError } = this.state.formErrors;

    const distributorDashData = localStorage.getItem("distributorDash");
    const distributorDashDataLocalStorage = JSON.parse(distributorDashData);

    const tableRowForOutletPendingLocalStorage = localStorage.getItem(
      "tableRowForOutletPending"
    );
    const totalOutletPendingAmountLocalStorage = localStorage.getItem(
      "totalOutletPendingAmount"
    );
    const totalOfRemainingAmtLocalStorage = localStorage.getItem(
      "totalOfRemainingAmt"
    );

    const outletOrderPendingListData = localStorage.getItem(
      "outletOrderPendingListData"
    );
    const outletOrderPendingListLocalStorage = JSON.parse(
      outletOrderPendingListData
    );

    const lineGraphData = localStorage.getItem("lineGraphData");
    const lineGraphDataLocalStorage = JSON.parse(lineGraphData);

    const topProductOrderListData = localStorage.getItem(
      "topProductOrderListData"
    );
    const topProductOrderListDataLocalStorage = JSON.parse(
      topProductOrderListData
    );

    const topOutletOrderListData = localStorage.getItem(
      "topOutletOrderListData"
    );
    const topOutletOrderListDataLocalStorage = JSON.parse(
      topOutletOrderListData
    );

    const topOutletOrderListBySearch = localStorage.getItem(
      "topOutletOrderListBySearchData"
    );
    const topOutletOrderListBySearchLocalStorage = JSON.parse(
      topOutletOrderListBySearch
    );

    const noticeMsgListDataList = localStorage.getItem("noticeMsgListData");
    const noticeMsgListDataLocalStorage = JSON.parse(noticeMsgListDataList);
    console.log(noticeMsgListDataLocalStorage);

    return (
      <>
        <div
          id="layout-wrapper"
          style={{
            backgroundColor: "#fff",
          }}
        >
          {/* ========== App Menu ========== */}

          {/* Left Sidebar End */}
          {/* Vertical Overlay*/}
          <div className="vertical-overlay" />
          {/* ============================================================== */}
          {/* Start right Content here
            {/* ============================================================== */}
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div
                  className="row"
                  style={{
                    gap: 8,
                    paddingBottom: 10,
                  }}
                >
                  {/* Card 1 */}
                  <div
                    className="Frame427319159"
                    style={{
                      width: "24.5%",
                      height: "auto",
                      paddingTop: 22,
                      paddingBottom: 22,
                      background: "#FFFDE7",
                      borderRadius: 8,
                      overflow: "hidden",
                      // border: "1px solid",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "inline-flex",
                    }}
                  >
                    <div
                      className="Frame11262"
                      style={{
                        flex: "1 1 0",
                        alignSelf: "stretch",
                        paddingLeft: 16,
                        paddingRight: 16,
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        display: "inline-flex",
                      }}
                    >
                      <div
                        className="Frame427319153"
                        style={{
                          width: "50%",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          gap: 8,
                          display: "inline-flex",
                        }}
                      >
                        <img
                          className="Image4"
                          style={{ width: 32, height: 32 }}
                          src={image4}
                        />
                        <div
                          className="TotalSales"
                          style={{
                            color: "black",
                            fontSize: 22,
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            wordWrap: "break-word",
                            top: 5,
                          }}
                        >
                          Current Month Sales
                        </div>
                      </div>
                      <div
                        className="Frame427319116"
                        style={{
                          // alignSelf: "stretch",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "center",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          className="Cr"
                          style={{
                            color: "black",
                            fontSize: 28,
                            fontFamily: "Poppins",
                            fontWeight: "500",
                            textTransform: "capitalize",
                            lineHeight: 1.8,
                            wordWrap: "break-word",
                          }}
                        >
                          {ConvertAmount({
                            amount: distributorDashDataLocalStorage
                              ? +distributorDashDataLocalStorage.currentMonthOutletTotalAmt
                              : "",
                          })}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Card 1  end*/}

                  {/* Card 2 */}

                  <div
                    className="Frame427319162"
                    style={{
                      width: "24.5%",
                      height: "auto",
                      paddingTop: 22,
                      paddingBottom: 22,
                      background: "rgba(253, 126, 20, 0.10)",
                      borderRadius: 8,
                      overflow: "hidden",
                      // border: "1px solid",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "inline-flex",
                    }}
                  >
                    <div
                      className="Frame11262"
                      style={{
                        flex: "1 1 0",
                        alignSelf: "stretch",
                        paddingLeft: 16,
                        paddingRight: 16,
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        display: "inline-flex",
                      }}
                    >
                      <div
                        className="Frame427319153"
                        style={{
                          width: "50%",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          gap: 8,
                          display: "inline-flex",
                        }}
                      >
                        <img
                          className="Image5"
                          style={{ width: 32, height: 32 }}
                          src={image5}
                        />
                        <div
                          className="TotalSales"
                          style={{
                            color: "black",
                            fontSize: 22,
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            wordWrap: "break-word",
                          }}
                        >
                          Outlet
                        </div>
                      </div>
                      <div
                        className="Frame427319116"
                        style={{
                          // alignSelf: "stretch",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "center",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            color: "black",
                            fontSize: 28,
                            fontFamily: "Poppins",
                            fontWeight: "500",
                            textTransform: "capitalize",
                            lineHeight: 1.8,
                            wordWrap: "break-word",
                          }}
                        >
                          {distributorDashDataLocalStorage
                            ? +distributorDashDataLocalStorage.totalOutletCount
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Card 2 end */}

                  {/* Card 3 */}

                  <div
                    className="Frame427319160"
                    style={{
                      width: "24.5%",
                      height: "auto",
                      paddingTop: 22,
                      paddingBottom: 22,
                      background: "rgba(39, 197, 231, 0.10)",
                      borderRadius: 8,
                      overflow: "hidden",
                      // border: "1px solid",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "inline-flex",
                    }}
                  >
                    <div
                      className="Frame11262"
                      style={{
                        flex: "1 1 0",
                        alignSelf: "stretch",
                        paddingLeft: 16,
                        paddingRight: 16,
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        display: "inline-flex",
                      }}
                    >
                      <div
                        className="Frame427319153"
                        style={{
                          width: "50%",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          gap: 8,
                          display: "inline-flex",
                        }}
                      >
                        <img
                          className="Image6"
                          style={{ width: 32, height: 32 }}
                          src={image7BgClr}
                        />
                        <div
                          className="TotalSales"
                          style={{
                            color: "black",
                            fontSize: 22,
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            wordWrap: "break-word",
                          }}
                        >
                          Sales Executive
                        </div>
                      </div>
                      <div
                        className="Frame427319116"
                        style={{
                          // alignSelf: "stretch",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "center",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            color: "black",
                            fontSize: 28,
                            fontFamily: "Poppins",
                            fontWeight: "500",
                            textTransform: "capitalize",
                            lineHeight: 1.8,
                            wordWrap: "break-word",
                          }}
                        >
                          {distributorDashDataLocalStorage
                            ? +distributorDashDataLocalStorage.countOfSalesExecutive
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Card 3 end */}
                </div>

                <div
                  className="row"
                  style={{
                    gap: 8,
                    paddingBottom: 10,
                  }}
                >
                  <div
                    className="Group11172"
                    style={{
                      width: "97.3%",
                      height: 140,
                      position: "relative",
                    }}
                  >
                    <div
                      className="Frame427319180"
                      style={{
                        width: "100%",
                        height: "auto",
                        paddingTop: 10,
                        paddingBottom: 16,
                        paddingLeft: 12,
                        paddingRight: 12,
                        background: "white",
                        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.12)",
                        borderRadius: 12,
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        gap: 12,
                        display: "inline-flex",
                      }}
                    >
                      <div
                        className="Frame427319179"
                        style={{
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          gap: 14,
                          display: "inline-flex",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <div
                          className="Frame427319174"
                          style={{
                            height: "auto",
                            padding: 12,
                            background: "#FFDFE5",
                            borderRadius: 8,
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            gap: 20,
                            display: "flex",
                            width: "25%",
                          }}
                        >
                          <div
                            className="Frame427319178"
                            style={{
                              width: 44,
                              height: 44,
                              padding: 10,
                              background: "#EC3A5A",
                              borderRadius: 23,
                              justifyContent: "center",
                              alignItems: "center",
                              gap: 10,
                              display: "flex",
                            }}
                          >
                            <img
                              className="Image13"
                              style={{ width: 32, height: 32 }}
                              src={image10}
                            />
                          </div>
                          <div
                            className="Frame427319166"
                            style={{
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              gap: 6,
                              display: "inline-flex",
                              width: "100%",
                            }}
                          >
                            <div
                              className="Frame427319175"
                              style={{
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              <div
                                className="54l"
                                style={{
                                  color: "black",
                                  fontSize: 28,
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  wordWrap: "break-word",
                                }}
                              >
                                {ConvertAmount({
                                  amount: distributorDashDataLocalStorage
                                    ? +distributorDashDataLocalStorage.creditNoteTotalAmt
                                    : "",
                                })}
                              </div>
                              <div
                                className="BillAmount"
                                style={{
                                  color: "black",
                                  fontSize: 16,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  wordWrap: "break-word",
                                }}
                              >
                                Credit Note Total Amount
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="Frame427319173"
                          style={{
                            height: "auto",
                            padding: 12,
                            background: "#F4FFB1",
                            borderRadius: 8,
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            gap: 20,
                            display: "flex",
                            width: "25%",
                          }}
                        >
                          <div
                            className="Frame427319178"
                            style={{
                              width: 44,
                              height: 44,
                              padding: 10,
                              background: "#899F00",
                              borderRadius: 23,
                              justifyContent: "center",
                              alignItems: "center",
                              gap: 10,
                              display: "flex",
                            }}
                          >
                            <img
                              className="Image11"
                              style={{ width: 32, height: 32 }}
                              src={image9}
                            />
                          </div>
                          <div
                            className="Frame427319166"
                            style={{
                              width: "100%",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              gap: 6,
                              display: "inline-flex",
                            }}
                          >
                            <div
                              className="Frame427319175"
                              style={{
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  color: "black",
                                  fontSize: 28,
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  wordWrap: "break-word",
                                }}
                              >
                                {distributorDashDataLocalStorage
                                  ? +distributorDashDataLocalStorage.vehicleCount
                                  : ""}
                              </div>
                              <div
                                className="Unisited"
                                style={{
                                  color: "black",
                                  fontSize: 16,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  wordWrap: "break-word",
                                }}
                              >
                                Vehicle Count
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="Frame427319174"
                          style={{
                            height: "auto",
                            padding: 12,
                            background: "#FFDFE5",
                            borderRadius: 8,
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            gap: 20,
                            display: "flex",
                            width: "25%",
                          }}
                        >
                          <div
                            className="Frame427319178"
                            style={{
                              width: 44,
                              height: 44,
                              padding: 10,
                              background: "#EC3A5A",
                              borderRadius: 23,
                              justifyContent: "center",
                              alignItems: "center",
                              gap: 10,
                              display: "flex",
                            }}
                          >
                            <img
                              className="Image13"
                              style={{ width: 32, height: 32 }}
                              src={image10}
                            />
                          </div>
                          <div
                            className="Frame427319166"
                            style={{
                              width: "100%",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              gap: 3,
                              display: "inline-flex",
                            }}
                            onClick={this.handleBeatTargetPopup}
                          >
                            <div
                              className="Frame427319166"
                              style={{
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: 6,
                                display: "inline-flex",
                                width: "100%",
                              }}
                            >
                              <div
                                className="Frame427319175"
                                style={{
                                  flexDirection: "column",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <div
                                  className="54l"
                                  style={{
                                    color: "black",
                                    fontSize: 28,
                                    fontFamily: "Poppins",
                                    fontWeight: "500",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {ConvertAmount({
                                    amount: distributorDashDataLocalStorage
                                      ? +distributorDashDataLocalStorage.beatTargetAmt
                                      : "",
                                  })}
                                </div>
                                <div
                                  className="BillAmount"
                                  style={{
                                    color: "black",
                                    fontSize: 16,
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  Beat Target Amount
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Bar Graph starts */}
                <div
                  className="row"
                  style={{
                    gap: 8,
                    paddingBottom: 10,
                  }}
                >
                  <div
                    className="Group11172"
                    style={{
                      width: "97.3%",
                      height: 220,
                      position: "relative",
                    }}
                  >
                    <div
                      className="Frame427319180"
                      style={{
                        width: "100%",
                        height: "auto",
                        paddingTop: 10,
                        paddingBottom: 16,
                        paddingLeft: 12,
                        paddingRight: 12,
                        background: "white",
                        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.12)",
                        borderRadius: 12,
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        gap: 12,
                        display: "inline-flex",
                      }}
                    >
                      <div
                        className="Frame1000002939"
                        style={{
                          height: 44,
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 20,
                          display: "inline-flex",
                        }}
                      >
                        <div
                          className="Frame1000002940"
                          style={{
                            paddingBottom: 6,
                            borderBottom: "2px #4450E0 solid",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: 4,
                            display: "flex",
                          }}
                        >
                          <div
                            className="RadioButtonChecked"
                            style={{
                              width: 30,
                              height: 30,
                              position: "relative",
                            }}
                          >
                            <div
                              className="Vector"
                              style={{
                                width: 30,
                                height: 30,
                                left: 0,
                                top: 0,
                                position: "absolute",
                              }}
                            ></div>
                            <div
                              className="Vector"
                              style={{
                                width: 25,
                                height: 25,
                                left: 2.5,
                                top: 2.5,
                                position: "absolute",
                                background: "#4450E0",
                              }}
                            ></div>
                            <div
                              className="Vector"
                              style={{
                                width: 12.5,
                                height: 12.5,
                                left: 8.75,
                                top: 8.75,
                                position: "absolute",
                                background: "#4450E0",
                              }}
                            ></div>
                          </div>
                          <div
                            className="TodayS"
                            style={{
                              color: "#4450E0",
                              fontSize: 18,
                              fontFamily: "Poppins",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            Todays
                          </div>
                        </div>
                      </div>
                      <div
                        className="Frame427319179"
                        style={{
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          gap: 14,
                          display: "inline-flex",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <div
                          className="Frame427319167"
                          style={{
                            height: "auto",
                            padding: 12,
                            background: "#DEFFF5",
                            borderRadius: 8,
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            gap: 20,
                            display: "flex",
                            width: "25%",
                          }}
                        >
                          <div
                            className="Frame427319178"
                            style={{
                              width: 44,
                              height: 44,
                              padding: 10,
                              background: "#12B886",
                              borderRadius: 23,
                              justifyContent: "center",
                              alignItems: "center",
                              gap: 10,
                              display: "flex",
                            }}
                          >
                            <img
                              className="Image10"
                              style={{ width: 32, height: 32 }}
                              src={image8}
                            />
                          </div>
                          <div
                            className="Frame427319166"
                            style={{
                              width: "100%",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              gap: 3,
                              display: "inline-flex",
                            }}
                            onClick={this.handleSalesTeamPopup}
                          >
                            <div
                              className="Frame427319174"
                              style={{
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  color: "black",
                                  fontSize: 28,
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  wordWrap: "break-word",
                                }}
                              >
                                {distributorDashDataLocalStorage
                                  ? +distributorDashDataLocalStorage.checkedInCount
                                  : ""}
                              </div>
                              <div
                                className="PendingForCheckIn"
                                style={{
                                  color: "black",
                                  fontSize: 16,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  wordWrap: "break-word",
                                }}
                              >
                                Check in
                              </div>
                            </div>
                            <div
                              className="Frame427319165"
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 3,
                                display: "inline-flex",
                              }}
                            >
                              <div
                                style={{
                                  width: 49,
                                  textAlign: "right",
                                  color: "#2268D1",
                                  fontSize: 18,
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  wordWrap: "break-word",
                                }}
                              >
                                {distributorDashDataLocalStorage
                                  ? +distributorDashDataLocalStorage.countOfSalesExecutive -
                                  +distributorDashDataLocalStorage.checkedInCount -
                                  +distributorDashDataLocalStorage.checkedInOutCompletedCount
                                  : ""}
                              </div>
                              <div
                                className="CheckIn"
                                style={{
                                  color: "#2268D1",
                                  fontSize: 12,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  wordWrap: "break-word",
                                }}
                              >
                                Pending For Check In
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="Frame427319173"
                          style={{
                            height: "auto",
                            padding: 12,
                            background: "#F4FFB1",
                            borderRadius: 8,
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            gap: 20,
                            display: "flex",
                            width: "25%",
                          }}
                        >
                          <div
                            className="Frame427319178"
                            style={{
                              width: 44,
                              height: 44,
                              padding: 10,
                              background: "#899F00",
                              borderRadius: 23,
                              justifyContent: "center",
                              alignItems: "center",
                              gap: 10,
                              display: "flex",
                            }}
                          >
                            <img
                              className="Image11"
                              style={{ width: 32, height: 32 }}
                              src={image9}
                            />
                          </div>
                          <div
                            className="Frame427319166"
                            style={{
                              width: "100%",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              gap: 6,
                              display: "inline-flex",
                            }}
                          >
                            <div
                              className="Frame427319175"
                              style={{
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  color: "black",
                                  fontSize: 28,
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  wordWrap: "break-word",
                                }}
                              >
                                {distributorDashDataLocalStorage
                                  ? +distributorDashDataLocalStorage.totalOutletCount -
                                  +distributorDashDataLocalStorage.todaysVistedCount
                                  : ""}
                              </div>
                              <div
                                className="Unisited"
                                style={{
                                  color: "black",
                                  fontSize: 16,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  wordWrap: "break-word",
                                }}
                              >
                                Unvisited
                              </div>
                            </div>
                            <div
                              className="Frame427319165"
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 3,
                                display: "inline-flex",
                              }}
                            >
                              <div
                                style={{
                                  width: 49,
                                  textAlign: "right",
                                  color: "#2268D1",
                                  fontSize: 18,
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  wordWrap: "break-word",
                                }}
                              >
                                {distributorDashDataLocalStorage
                                  ? +distributorDashDataLocalStorage.todaysVistedCount
                                  : ""}
                              </div>
                              <div
                                className="Visited"
                                style={{
                                  width: 66,
                                  color: "#2268D1",
                                  fontSize: 12,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  wordWrap: "break-word",
                                }}
                              >
                                Visited
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="Frame427319174"
                          style={{
                            height: "auto",
                            padding: 12,
                            background: "#FFDFE5",
                            borderRadius: 8,
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            gap: 20,
                            display: "flex",
                            width: "25%",
                          }}
                        >
                          <div
                            className="Frame427319178"
                            style={{
                              width: 44,
                              height: 44,
                              padding: 10,
                              background: "#EC3A5A",
                              borderRadius: 23,
                              justifyContent: "center",
                              alignItems: "center",
                              gap: 10,
                              display: "flex",
                            }}
                          >
                            <img
                              className="Image13"
                              style={{ width: 32, height: 32 }}
                              src={image10}
                            />
                          </div>
                          <div
                            className="Frame427319166"
                            style={{
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              gap: 6,
                              display: "inline-flex",
                              width: "100%",
                            }}
                          >
                            <div
                              className="Frame427319175"
                              style={{
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              <div
                                className="54l"
                                style={{
                                  color: "black",
                                  fontSize: 28,
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  wordWrap: "break-word",
                                }}
                              >
                                {ConvertAmount({
                                  amount: distributorDashDataLocalStorage
                                    ? +distributorDashDataLocalStorage.todaysOutletBillTotalAmt
                                    : "",
                                })}
                              </div>
                              <div
                                className="BillAmount"
                                style={{
                                  color: "black",
                                  fontSize: 16,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  wordWrap: "break-word",
                                }}
                              >
                                Bill Amount
                              </div>
                            </div>
                            <div
                              className="Frame427319165"
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 10,
                                display: "inline-flex",
                              }}
                            >
                              <div
                                className="10"
                                style={{
                                  width: 90,
                                  textAlign: "right",
                                  color: "#2268D1",
                                  fontSize: 18,
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  wordWrap: "break-word",
                                }}
                              >
                                +
                                {distributorDashDataLocalStorage
                                  ? +distributorDashDataLocalStorage.yesterdaysOutletBillTotalAmt ===
                                    0
                                    ? +distributorDashDataLocalStorage.todaysOutletBillTotalAmt
                                    : (
                                      (+distributorDashDataLocalStorage.todaysOutletBillTotalAmt /
                                        +distributorDashDataLocalStorage.yesterdaysOutletBillTotalAmt) *
                                      100
                                    ).toFixed(2)
                                  : ""}{" "}
                                %{" "}
                              </div>
                              <div
                                className="FromYesterday"
                                style={{
                                  width: "auto",
                                  color: "#2268D1",
                                  fontSize: 12,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  wordWrap: "break-word",
                                }}
                              >
                                from Yesterday
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="Frame427319175"
                          style={{
                            height: "auto",
                            padding: 12,
                            background: "#FFDFE5",
                            borderRadius: 8,
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            gap: 20,
                            display: "flex",
                            width: "25%",
                          }}
                        >
                          <div
                            className="Frame427319178"
                            style={{
                              width: 44,
                              height: 44,
                              padding: 10,
                              background: "#EC3A5A",
                              borderRadius: 23,
                              justifyContent: "center",
                              alignItems: "center",
                              gap: 10,
                              display: "flex",
                            }}
                          >
                            <img
                              className="Image14"
                              style={{ width: 32, height: 32 }}
                              src={image11}
                            />
                          </div>
                          <div
                            className="Frame427319166"
                            style={{
                              width: "100%",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              gap: 6,
                              display: "inline-flex",
                            }}
                          >
                            <div
                              className="Frame427319175"
                              style={{
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              <div
                                className="23l"
                                style={{
                                  color: "black",
                                  fontSize: 28,
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  wordWrap: "break-word",
                                }}
                              >
                                {ConvertAmount({
                                  amount: distributorDashDataLocalStorage
                                    ? +distributorDashDataLocalStorage.todaysOutletOrderTotalAmt
                                    : "",
                                })}
                              </div>
                              <div
                                className="OrderAmount"
                                style={{
                                  color: "black",
                                  fontSize: 16,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  wordWrap: "break-word",
                                }}
                              >
                                Order Amount
                              </div>
                            </div>
                            <div
                              className="Frame427319165"
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 10,
                                display: "inline-flex",
                              }}
                            >
                              <div
                                className="10"
                                style={{
                                  width: 90,
                                  textAlign: "right",
                                  color: "#2268D1",
                                  fontSize: 18,
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  wordWrap: "break-word",
                                }}
                              >
                                +
                                {distributorDashDataLocalStorage
                                  ? +distributorDashDataLocalStorage.yesterdaysOutletOrderTotalAmt ===
                                    0
                                    ? +distributorDashDataLocalStorage.todaysOutletOrderTotalAmt
                                    : (
                                      (+distributorDashDataLocalStorage.todaysOutletOrderTotalAmt /
                                        +distributorDashDataLocalStorage.yesterdaysOutletOrderTotalAmt) *
                                      100
                                    ).toFixed(2)
                                  : ""}{" "}
                                %{" "}
                              </div>
                              <div
                                className="FromYesterday"
                                style={{
                                  width: "auto",
                                  color: "#2268D1",
                                  fontSize: 12,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  wordWrap: "break-word",
                                }}
                              >
                                from Yesterday
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Bar Graph ends */}
                </div>

                {/* Sales & Manpower Div starts */}

                {/* Sales & Manpower Div end */}
                <div
                  className="row"
                  style={{
                    gap: 8,
                    marginBottom: 10,
                    width: "100%",
                    // marginTop: 15
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      background: "white",
                      padding: 5,
                      borderRadius: 8,
                      border: "1px #E6E6E6 solid",
                    }}
                  >
                    {/* Sales & Manpower Table 1 starts */}
                    <div
                      // className="card"
                      style={{
                        marginBottom: -2,
                        background: "white",
                        borderRadius: 8,
                        border: "0 #E6E6E6 solid",
                      }}
                    >
                      <div
                        className="card-header align-items-center d-flex"
                        style={{
                          background: "white",
                          borderRadius: 8,
                          border: "0 #E6E6E6 solid",
                        }}
                      >
                        <h4 className="text-uppercase card-title mb-0 flex-grow-1">
                          Outlet Order Pending for Bill(
                          {pendingOrderBySearch
                            ? tableRowForOutletPending
                            : tableRowForOutletPendingLocalStorage
                              ? tableRowForOutletPendingLocalStorage
                              : ""}
                          {" ,"}
                          {pendingOrderBySearch
                            ? totalOutletPendingAmount
                            : totalOutletPendingAmountLocalStorage
                              ? totalOutletPendingAmountLocalStorage
                              : ""}
                          )
                          <div
                            style={{
                              marginLeft: -25,
                            }}
                          >
                            <br />
                            <LandingScreenHeader
                              screenTitle={""}
                              showSearchBox={true}
                              showTable={true}
                              showFilter={false}
                              showPdfDownload={false}
                              showExcelDownload={false}
                              onSearch={this.onSearchForPedning}
                            />
                          </div>
                        </h4>
                        <h4
                          className="text-uppercase card-title mb-0 flex-grow-1"
                          style={{
                            textAlign: "end",
                            width: 0,
                          }}
                        >
                          <MonthCalender
                            title=""
                            name="pendingSaleMonth"
                            value={pendingSaleMonth}
                            onChange={this.onDateChangeOutlet}
                            sx={{
                              paddingBottom: 0,
                            }}
                          />
                        </h4>
                      </div>
                    </div>
                    <RTTable
                      columns={[
                        { name: "index", title: "Sr. No.", align: "center" },
                        { name: "orderNo", title: "Order No" },
                        { name: "orderDate", title: "Order Date" },
                        { name: "firmName", title: "Outlet" },
                        { name: "beatName", title: "Beat" },
                        {
                          name: "detailCount",
                          title: "Product Counts",
                          align: "right",
                        },
                        {
                          name: "totalAmount",
                          title: "Total",
                          align: "right",
                        },
                      ]}
                      tableData={this.getFilteredTableDataForPending(
                        pendingOrderBySearch
                          ? outletOrderDetailList?.pendingOrderByMonthYear
                          : outletOrderPendingListLocalStorage
                            ? outletOrderPendingListLocalStorage
                            : []
                      )}
                      isActionColActive={false}
                      showPegination={false}
                    />
                    <TableRow>
                      <TableCell align="center" width="2%" colSpan={4}>
                        Total
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        {pendingOrderBySearch
                          ? totalOutletPendingAmount
                          : totalOutletPendingAmountLocalStorage
                            ? totalOutletPendingAmountLocalStorage
                            : ""}
                      </TableCell>
                    </TableRow>

                    {/* <Stack spacing={2}>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={dashboardData.regionListFromYearAndMonth.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                      />
                    </Stack> */}
                    {/* Sales & Manpower Table 1 end */}
                  </div>
                </div>

                {/* Sales Div starts */}
                {/* Sales Div end */}
              </div>
              <br />
              <Typography
                variant="h5"
                style={{
                  marginLeft: 15,
                }}
              >
                BILL WISE DATA
              </Typography>
              <br />
              {/* sales Overview Div starts */}
              <div
                className="row"
                style={{
                  gap: 8,
                  marginBottom: 10,
                  width: "100%",
                  marginLeft: 5,
                }}
              >
                <div
                  style={{
                    width: "40%",
                    background: "white",
                    padding: 5,
                    borderRadius: 8,
                    border: "1px #E6E6E6 solid",
                  }}
                >
                  {/* Single bar graph starts */}
                  {console.log(lineGraphDataLocalStorage)}
                  {lineGraphDataLocalStorage &&
                    Object.keys(lineGraphDataLocalStorage).length !== 0 &&
                    lineGraphDataLocalStorage.monthWiseOutletTotalBill.length !=
                    0 ? (
                    <>
                      <MonthlyBarChart
                        series={[
                          {
                            data: lineGraphDataLocalStorage.monthWiseOutletTotalBill.map(
                              (category) => {
                                return +category.totalAmount.toFixed(2);
                              }
                            ),
                          },
                        ]}
                        optionsObj={{
                          chart: {
                            type: "bar",
                            height: 365,
                            toolbar: {
                              show: false,
                            },
                            events: {
                              dataPointSelection: (
                                event,
                                chartContext,
                                config
                              ) => {
                                this.onClickLineChart(config.dataPointIndex);
                              },
                            },
                          },

                          plotOptions: {
                            bar: {
                              borderRadius: 4,
                              horizontal: true,
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          xaxis: {
                            categories:
                              lineGraphDataLocalStorage.monthWiseOutletTotalBill.map(
                                (category) => {
                                  return category.monthName != null
                                    ? category.monthName
                                    : "";
                                }
                              ),

                            axisBorder: {
                              show: false,
                            },
                            axisTicks: {
                              show: false,
                            },
                            labels: {
                              style: {
                                // colors: [
                                //   secondary,
                                //   secondary,
                                //   secondary,
                                //   secondary,
                                //   secondary,
                                //   secondary,
                                //   secondary,
                                // ],
                              },
                            },
                          },
                          // yaxis: {
                          //   show: false,
                          // },
                          grid: {
                            show: false,
                          },
                          // colors: [info],

                          tooltip: {
                            theme: "light",
                          },
                        }}
                      />
                    </>
                  ) : (
                    <NoDataFound />
                  )}
                  {/* Single bar gragh end */}
                </div>

                <div
                  style={{
                    width: "56.4%",
                    background: "white",
                    padding: 5,
                    borderRadius: 8,
                    border: "1px #E6E6E6 solid",
                  }}
                >
                  {/* Sales & Manpower Table 2 starts */}
                  {coloumnChartList.length != 0 ? (
                    <>
                      <ReportAreaChart
                        options={{
                          chart: {
                            height: 350,
                            type: "line",
                            dropShadow: {
                              enabled: true,
                              color: "#000",
                              top: 18,
                              left: 7,
                              blur: 10,
                              opacity: 0.2,
                            },
                            // toolbar: {
                            //   show: false
                            // }
                          },
                          colors: ["#77B6EA", "#545454"],
                          dataLabels: {
                            enabled: true,
                          },
                          stroke: {
                            curve: "smooth",
                          },

                          grid: {
                            borderColor: "#e7e7e7",
                            row: {
                              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                              opacity: 0.5,
                            },
                          },
                          markers: {
                            size: 1,
                          },
                          xaxis: {
                            categories: coloumnChartList.map((category) => {
                              return category.date != null ? category.date : "";
                            }),
                            title: {
                              text: "Dates",
                            },
                          },
                          yaxis: {
                            title: {
                              text: "Amount",
                            },
                          },
                          legend: {
                            position: "top",
                            horizontalAlign: "right",
                            floating: true,
                            offsetY: -25,
                            offsetX: -5,
                          },
                        }}
                        series={[
                          {
                            name: "Amount",
                            data: coloumnChartList.map((category) => {
                              return +category.totalAmt;
                            }),
                          },
                        ]}
                      />
                    </>
                  ) : (
                    <NoDataFound />
                  )}
                  {/* Sales & Manpower Table 2 end */}
                </div>
              </div>

              {/* sales Overview Div starts */}
              <div
                className="row"
                style={{
                  gap: 8,
                  marginBottom: 10,
                  width: "100%",
                  marginLeft: 5,
                }}
              >
                <div
                  style={{
                    width: "56.4%",
                    background: "white",
                    padding: 5,
                    borderRadius: 8,
                    border: "1px #E6E6E6 solid",
                  }}
                >
                  {/* sales Overview graph starts */}
                  <div
                    // className="card"
                    style={{
                      marginBottom: -2,
                      background: "white",
                      borderRadius: 8,
                      border: "0 #E6E6E6 solid",
                    }}
                  >
                    <div
                      className="card-header align-items-center d-flex"
                      style={{
                        background: "white",
                        borderRadius: 8,
                        border: "0 #E6E6E6 solid",
                      }}
                    >
                      <h4 className="text-uppercase card-title mb-0 flex-grow-1">
                        Top Performing Product
                        {/* Replace Table with Graph */}
                        <div
                          style={{
                            marginLeft: -25,
                          }}
                        >
                          <br />
                          <LandingScreenHeader
                            screenTitle={""}
                            showSearchBox={true}
                            showTable={true}
                            showFilter={false}
                            showPdfDownload={false}
                            showExcelDownload={false}
                            onSearch={this.onSearchForPerformingProducts}
                          />
                        </div>
                      </h4>
                      <h4
                        className="text-uppercase card-title mb-0 flex-grow-1"
                        style={{
                          textAlign: "end",
                          width: 180,
                        }}
                      >
                        <MonthCalender
                          title=""
                          name="topProductSaleMonth"
                          value={topProductSaleMonth}
                          onChange={this.onDateChangeOutlet}
                          sx={{
                            paddingBottom: 0,
                          }}
                        />
                      </h4>
                    </div>
                  </div>

                  <RTTable
                    columns={[
                      { name: "index", title: "Sr. No.", align: "center" },
                      { name: "name", title: "Product Name" },
                      {
                        name: "orderValue",
                        title: "Acheived Amount",
                        align: "right",
                      },
                      {
                        name: "contribution",
                        title: "% Contribution",
                        align: "right",
                      },
                    ]}
                    tableData={this.getFilteredTableDataForTopPerformingProducts(
                      productBySearch
                        ? billingHistoryList?.topProductListFromYearAndMonth
                        : topProductOrderListDataLocalStorage
                          ? topProductOrderListDataLocalStorage
                          : []
                    )}
                    isActionColActive={false}
                    showPegination={false}
                  />
                  {/* <TableRow>
                    <TableCell align="center" width="2%" colSpan={4}>
                      Total
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      {dashboardData2.topProductListFromYearAndMonthTotal}
                    </TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow> */}
                </div>

                <div
                  style={{
                    width: "40%",
                    background: "white",
                    padding: 10,
                    borderRadius: 8,
                    border: "1px #E6E6E6 solid",
                  }}
                >
                  {/* Distributed gragh 2 starts */}

                  <Grid item xs={12} md={4} lg={4}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography variant="h5">
                          Current month category wise bill chart
                        </Typography>
                      </Grid>
                    </Grid>

                    <Box sx={{ pt: 1, pr: 2 }}>
                      {distributorDashDataLocalStorage &&
                        Object.keys(distributorDashDataLocalStorage).length !=
                        0 &&
                        distributorDashDataLocalStorage.dtoOutletBillCategoryWiseTotalAmounts &&
                        distributorDashDataLocalStorage
                          .dtoOutletBillCategoryWiseTotalAmounts.length != 0 ? (
                        <PieChart
                          series={distributorDashDataLocalStorage.dtoOutletBillCategoryWiseTotalAmounts.map(
                            (category) => {
                              return category.totalAmt != null
                                ? category.totalAmt
                                : "";
                            }
                          )}
                          options={{
                            chart: {
                              height: "100%",
                              width: "600%",
                              type: "donut",
                              events: {
                                dataPointSelection: (
                                  event,
                                  chartContext,
                                  config
                                ) => {
                                  this.onCLickPie(config.dataPointIndex);
                                },
                              },
                            },
                            animations: {
                              enabled: true,
                              easing: "easeinout",
                              speed: 1000,
                              animateGradually: {
                                enabled: true,
                                delay: 150,
                              },
                              dynamicAnimation: {
                                enabled: true,
                                speed: 100,
                              },
                            },
                            labels:
                              distributorDashDataLocalStorage.dtoOutletBillCategoryWiseTotalAmounts.map(
                                (category) => {
                                  return category.categoryName != null
                                    ? category.categoryName
                                    : "";
                                }
                              ),
                            responsive: [
                              {
                                breakpoint: 480,
                                options: {
                                  chart: {
                                    width: 600,
                                  },
                                  legend: {
                                    position: "bottom",
                                  },
                                },
                              },
                            ],
                          }}
                        />
                      ) : (
                        <NoDataFound />
                      )}
                    </Box>
                  </Grid>
                  {/* Distributed gragh 2 end */}
                </div>
              </div>

              {/* sales Overview Div starts */}
              <div
                className="row"
                style={{
                  gap: 8,
                  marginBottom: 10,
                  width: "100%",
                  marginLeft: 5,
                }}
              >
                <div
                  style={{
                    width: "56.4%",
                    background: "white",
                    padding: 5,
                    borderRadius: 8,
                    border: "1px #E6E6E6 solid",
                  }}
                >
                  {/* sales Overview graph starts */}
                  <div
                    // className="card"
                    style={{
                      marginBottom: -2,
                      background: "white",
                      borderRadius: 8,
                      border: "0 #E6E6E6 solid",
                    }}
                  >
                    <div
                      className="card-header align-items-center d-flex"
                      style={{
                        background: "white",
                        borderRadius: 8,
                        border: "0 #E6E6E6 solid",
                      }}
                    >
                      <h4 className="text-uppercase card-title mb-0 flex-grow-1">
                        Top 20 Performing Outlet
                        {/* Replace Table with Graph */}
                        <div
                          style={{
                            marginLeft: -25,
                          }}
                        >
                          <br />
                          <LandingScreenHeader
                            screenTitle={""}
                            showSearchBox={true}
                            showTable={true}
                            showFilter={false}
                            showPdfDownload={false}
                            showExcelDownload={false}
                            onSearch={this.onSearchForPerformingOutlets}
                          />
                        </div>
                      </h4>
                      <h4
                        className="text-uppercase card-title mb-0 flex-grow-1"
                        style={{
                          textAlign: "end",
                          width: 180,
                        }}
                      >
                        <MonthCalender
                          title=""
                          name="saleMonth"
                          value={saleMonth}
                          onChange={this.onDateChangeOutlet}
                          sx={{
                            paddingBottom: 0,
                          }}
                        />
                      </h4>
                    </div>
                  </div>
                  <TableContainer
                    sx={{
                      maxHeight: 440,
                      marginTop: 0,
                      // width: "60%",
                      padding: 1.5,
                    }}
                  >
                    {/* <Table stickyHeader aria-label="sticky table"> */}

                    <RTTable
                      columns={[
                        { name: "index", title: "Sr. No.", align: "center" },
                        { name: "firmName", title: " Outlet Name" },
                        { name: "beatName", title: " Beat Name" },
                        { name: "outLetType", title: " Outlet Type" },
                        {
                          name: "orderValue",
                          title: "Achieved",
                          align: "right",
                        },
                        {
                          name: "contribution",
                          title: "% Contribution",
                          align: "right",
                        },
                      ]}
                      tableData={this.getFilteredTableDataForTopPerformingOutlets(
                        outletBySearch
                          ? billingHistoryList?.topOutletListFromYearAndMonth
                          : topOutletOrderListDataLocalStorage
                            ? topOutletOrderListDataLocalStorage
                            : []
                      )}
                      isActionColActive={false}
                      showPegination={false}
                    />
                    {/* <TableRow>
                      <TableCell align="center" width="2%" colSpan={4}>
                        Total
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        {dashboardData2.topOutletListFromYearAndMonthTotal}
                      </TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow> */}
                    {/* </Table> */}
                  </TableContainer>

                  {/* <Stack spacing={2}>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={dashboardData.regionListFromYearAndMonth.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={this.handleChangePage}
                      onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                  </Stack> */}
                  {/* Sales & Manpower Table 1 end */}
                </div>
                <div
                  style={{
                    width: "40%",
                    background: "white",
                    padding: 10,
                    borderRadius: 8,
                    border: "1px #E6E6E6 solid",
                  }}
                >
                  {/* Distributed gragh starts */}

                  <Grid item xs={12} md={8} lg={8}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Typography variant="h5">
                          Current month beat list with amount
                        </Typography>
                      </Grid>
                    </Grid>
                    <Box sx={{ pt: 1, pr: 2 }}>
                      {distributorDashDataLocalStorage &&
                        Object.keys(distributorDashDataLocalStorage).length !=
                        0 &&
                        distributorDashDataLocalStorage.dtoBeatWiseTotalAmounts &&
                        distributorDashDataLocalStorage.dtoBeatWiseTotalAmounts
                          .length != 0 ? (
                        <MonthlyBarChart
                          series={[
                            {
                              data: distributorDashDataLocalStorage.dtoBeatWiseTotalAmounts.map(
                                (beatObj) => {
                                  return beatObj.totalBillAmt != null
                                    ? +beatObj.totalBillAmt.toFixed(2)
                                    : "";
                                }
                              ),
                            },
                          ]}
                          optionsObj={{
                            chart: {
                              type: "bar",
                              height: "100%",
                              toolbar: {
                                show: false,
                              },
                              events: {
                                dataPointSelection: (
                                  event,
                                  chartContext,
                                  config
                                ) => {
                                  this.onClickBar(config.dataPointIndex);
                                },
                              },
                            },
                            plotOptions: {
                              bar: {
                                columnWidth: "30%",
                                borderRadius: 4,
                              },
                            },
                            dataLabels: {
                              enabled: false,
                            },
                            xaxis: {
                              categories:
                                distributorDashDataLocalStorage.dtoBeatWiseTotalAmounts.map(
                                  (beatObj) => {
                                    return beatObj.beatName != null
                                      ? beatObj.beatName
                                      : "";
                                  }
                                ),
                              axisBorder: {
                                show: false,
                              },
                              axisTicks: {
                                show: false,
                              },
                              labels: {
                                style: {
                                  colors: [
                                    titleColor,
                                    titleColor,
                                    titleColor,
                                    titleColor,
                                    titleColor,
                                    titleColor,
                                    titleColor,
                                  ],
                                },
                              },
                            },
                            // yaxis: {
                            //   show: false,
                            // },
                            grid: {
                              show: false,
                            },
                            colors: titleColor,

                            tooltip: {
                              theme: "light",
                            },
                          }}
                        />
                      ) : (
                        <NoDataFound />
                      )}
                    </Box>
                  </Grid>

                  {/* Distributed gragh end */}
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  background: "white",
                  padding: 10,
                  borderRadius: 8,
                  border: "1px #E6E6E6 solid",
                }}
              >
                {/* Distributed gragh starts */}

                <Grid item xs={12} md={8} lg={8}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <Typography variant="h5">
                        Current month sales-person list with amount
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box sx={{ pt: 1, pr: 2 }}>
                    {distributorDashDataLocalStorage &&
                      Object.keys(distributorDashDataLocalStorage).length != 0 &&
                      distributorDashDataLocalStorage.dtoSalesTeamWithTotalAmounts &&
                      distributorDashDataLocalStorage.dtoSalesTeamWithTotalAmounts
                        .length != 0 ? (
                      <MonthlyBarChart
                        series={[
                          {
                            data: distributorDashDataLocalStorage.dtoSalesTeamWithTotalAmounts.map(
                              (salesTeamObj) => {
                                return salesTeamObj.totalBillAmt != null
                                  ? +salesTeamObj.totalBillAmt.toFixed(2)
                                  : "";
                              }
                            ),
                          },
                        ]}
                        optionsObj={{
                          chart: {
                            type: "bar",
                            height: "100%",
                            toolbar: {
                              show: false,
                            },
                            events: {
                              dataPointSelection: (
                                event,
                                chartContext,
                                config
                              ) => {
                                this.onClickBar(config.dataPointIndex);
                              },
                            },
                          },
                          plotOptions: {
                            bar: {
                              columnWidth: "30%",
                              borderRadius: 4,
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          xaxis: {
                            categories:
                              distributorDashDataLocalStorage.dtoSalesTeamWithTotalAmounts.map(
                                (salesTeamObj) => {
                                  return salesTeamObj.salesTeamName != null
                                    ? salesTeamObj.salesTeamName
                                    : "";
                                }
                              ),
                            axisBorder: {
                              show: false,
                            },
                            axisTicks: {
                              show: false,
                            },
                            labels: {
                              style: {
                                colors: [
                                  titleColor,
                                  titleColor,
                                  titleColor,
                                  titleColor,
                                  titleColor,
                                  titleColor,
                                  titleColor,
                                ],
                              },
                            },
                          },
                          // yaxis: {
                          //   show: false,
                          // },
                          grid: {
                            show: false,
                          },
                          colors: titleColor,

                          tooltip: {
                            theme: "light",
                          },
                        }}
                      />
                    ) : (
                      <NoDataFound />
                    )}
                  </Box>
                </Grid>

                {/* Distributed gragh end */}
              </div>
              <div
                className="row"
                style={{
                  gap: 8,
                  marginBottom: 10,
                  width: "100%",
                  marginLeft: 5,
                }}
              >
                <div
                  style={{
                    width: "97%",
                    background: "white",
                    padding: 5,
                    borderRadius: 8,
                    border: "1px #E6E6E6 solid",
                  }}
                >
                  {/* Top beat starts */}
                  <div
                    // className="card"
                    style={{
                      marginBottom: -2,
                      background: "white",
                      borderRadius: 8,
                      border: "0 #E6E6E6 solid",
                    }}
                  >
                    <div
                      className="card-header align-items-center d-flex"
                      style={{
                        background: "white",
                        borderRadius: 8,
                        border: "0 #E6E6E6 solid",
                        // marginTop: 20,
                      }}
                    >
                      <h4
                        className="text-uppercase card-title mb-0 flex-grow-1"
                        style={{
                          width: "48%",
                        }}
                      >
                        Pending Bill Amount
                        <div
                          style={{
                            marginLeft: -25,
                          }}
                        >
                          {/* <SearchBar
                            sx={{ width: "100%" }}
                            searchValueForPendingBottomColumn={
                              searchValueForPendingBottomColumn
                            }
                            filteredRows={this.onSearchForPendingBillBottomColumn}
                          /> */}
                          <br />
                          <LandingScreenHeader
                            screenTitle={""}
                            showSearchBox={true}
                            showTable={true}
                            showFilter={false}
                            showPdfDownload={false}
                            showExcelDownload={false}
                            onSearch={this.onSearch}
                          />
                        </div>
                      </h4>
                      <h4
                        className="text-uppercase card-title mb-0 flex-grow-1"
                        style={{
                          textAlign: "end",
                          width: 0,
                        }}
                      >
                        {/* <MonthCalender
                          title=""
                          name="saleMonth"
                          value={saleMonth}
                          onChange={this.onDateChange}
                          sx={{
                            paddingBottom: 0,
                            width: 100,
                            justifyContent: "flex-end",
                          }}
                        /> */}
                      </h4>
                    </div>
                  </div>

                  <TableContainer
                    sx={{
                      maxHeight: 440,
                      // width: 610,
                      padding: 1.5,
                      // marginTop: 2.5,
                    }}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead
                        style={{
                          backgroundColor: "#fff",
                        }}
                      >
                        <TableRow>
                          <TableCell
                            color="#000"
                            align="center"
                            sx={{ width: "2%", color: "#000" }}
                            canSearch="true"
                          >
                            Sr.No
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ width: "8%", color: "#000" }}
                            canSearch="true"
                          >
                            Beat Name
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ width: "8%", color: "#000" }}
                            canSearch="true"
                          >
                            Outlet Name
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ width: "5%", color: "#000" }}
                            canSearch="true"
                          >
                            Remaining Amount
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {distributorDashDataLocalStorage &&
                          Object.keys(distributorDashDataLocalStorage).length !=
                          0 &&
                          this.getFilteredTableData(
                            distributorDashDataLocalStorage.dtoOutletPendingBill
                          ).map((regionObject, index) => {
                            return (
                              <TableRow>
                                <TableCell align="center" canSearch="true">
                                  {index + 1}
                                </TableCell>
                                <TableCell width="2%" canSearch="true">
                                  {regionObject.beatName}
                                </TableCell>
                                <TableCell width="2%" canSearch="true">
                                  <div
                                    onClick={(e) =>
                                      this.outletHandler(
                                        e,
                                        regionObject.outletId
                                      )
                                    }
                                    style={{
                                      color: cyanBlue,
                                      cursor: "pointer",
                                    }}
                                  >
                                    {regionObject.firmName}
                                  </div>
                                </TableCell>
                                <TableCell align="right" canSearch="true">
                                  {regionObject.remainingAmt.toFixed(2)}
                                </TableCell>
                              </TableRow>
                            );
                          })}

                        <TableRow>
                          <TableCell align="center" width="2%" colSpan={2}>
                            Total
                          </TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="right">
                            {totalOfRemainingAmtLocalStorage
                              ? totalOfRemainingAmtLocalStorage
                              : ""}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* {console.log(billingHistoryList?.billData)} */}
                  {popUpFlag && (
                    <DistributionDashboardPopUp
                      open={popUpFlag}
                      handleCloses={this.handleCloses}
                      columns={[
                        {
                          title: "Sr. No.",
                          name: "index",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "center",
                          canSearch: true,
                        },
                        {
                          title: "Bill No",
                          name: "billNo",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "center",
                          canSearch: true,
                        },
                        {
                          title: "Bill Date",
                          name: "billDate",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "center",
                          canSearch: true,
                        },
                        {
                          title: "Outlet",
                          name: "firmName",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                          canSearch: true,
                        },
                        {
                          title: "Taxable Amount",
                          name: "taxableAmt",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "right",
                          canSearch: true,
                        },
                        {
                          title: "CGST",
                          name: "cgstAmt",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "right",
                          canSearch: true,
                        },
                        {
                          title: "SGST",
                          name: "sgstAmt",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "right",
                          canSearch: true,
                        },
                        {
                          title: "Total Amount",
                          name: "totalAmt",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "right",
                          canSearch: true,
                        },
                        {
                          title: "Remaining Amount",
                          name: "remainingAmt",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "right",
                          canSearch: true,
                        },
                      ]}
                      rows={billingHistoryList?.billData}
                    />
                  )}
                  {salesTeamPopupFlag && (
                    <DistributorDashboardSalesTeamPopup
                      open={salesTeamPopupFlag}
                      handleCloses={this.handleCloseSalesTeam}
                      columns={[
                        {
                          title: "Sr. No.",
                          name: "index",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "center",
                          canSearch: true,
                        },
                        {
                          title: "Sales Executive Name",
                          name: "name",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                          canSearch: true,
                        },
                        {
                          title: "Check In Time",
                          name: "checkIn",
                          positionCenter: true,
                          showInscreen: true,
                          alignCenter: "center",
                          canSearch: true,
                        },
                        {
                          title: "Check Out Time",
                          name: "checkOut",
                          positionCenter: true,
                          showInscreen: true,
                          alignCenter: "center",
                          canSearch: true,
                        },
                      ]}
                      rows={salesExecutiveList?.salesExecutiveWithCheckInOut}
                    />
                  )}

                  {beatTargetPopUpFlag && (
                    <DistributorDashboardBeatTargetPopUp
                      open={beatTargetPopUpFlag}
                      handleCloses={this.handleClosesBeatTarget}
                      columns={[
                        {
                          title: "Sr. No.",
                          name: "index",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "center",
                          canSearch: true,
                        },
                        {
                          title: "Beat",
                          name: "beat",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                          canSearch: true,
                        },
                        {
                          title: "Target",
                          name: "totalTarget",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "right",
                          canSearch: true,
                        },
                      ]}
                      rows={beatTargetList?.beatTargetByUserType}
                    />
                  )}

                  {/* <Stack spacing={2}>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={dashboardData.regionListFromYearAndMonth.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={this.handleChangePage}
                      onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                  </Stack> */}
                  {/* Top beat starts */}
                </div>
              </div>

              <div
                className="row"
                style={{
                  gap: 8,
                  marginBottom: 10,
                  width: "100%",
                  marginLeft: 5,
                }}
              >
                <div
                  style={{
                    width: "97%",
                    background: "white",
                    padding: 5,
                    borderRadius: 8,
                    border: "1px #E6E6E6 solid",
                  }}
                >
                  {/* Top beat starts */}
                  {/* 8 may changes  */}
                  <div
                    // className="card"
                    style={{
                      marginBottom: -2,
                      background: "white",
                      borderRadius: 8,
                      border: "0 #E6E6E6 solid",
                    }}
                  >
                    <div
                      className="card-header align-items-center d-flex"
                      style={{
                        background: "white",
                        borderRadius: 8,
                        border: "0 #E6E6E6 solid",
                        // marginTop: 20,
                      }}
                    >
                      <h4
                        className="text-uppercase card-title mb-0 flex-grow-1"
                        style={{}}
                      >
                        Sales Team List
                        <div
                          style={{
                            marginLeft: -20,
                          }}
                        >
                          <LandingScreenHeader
                            screenTitle={""}
                            showSearchBox={true}
                            showTable={true}
                            showFilter={false}
                            showPdfDownload={false}
                            showExcelDownload={false}
                            generateExcel={this.generateExcel}
                            onSearch={this.onSearchForSalesTeamList}
                          />
                        </div>
                      </h4>
                      <h4
                        className="text-uppercase card-title mb-0 flex-grow-1"
                        style={{
                          textAlign: "center",
                          width: "300px",
                        }}
                      >
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={12}
                          lg={12}
                          container
                          justifyContent="space-between"
                        >
                          <Grid
                            item
                            sm={4}
                            xs={4}
                            md={4}
                            lg={4}
                            container
                            justifyContent="space-evenly"
                          >
                            <Grid item xs={12} md={5} sm={5}>
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, color: redColor }}
                                label="* "
                              />
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, color: titleColor }}
                                label="From Date :"
                              />
                            </Grid>
                            <Grid item xs={12} md={7} sm={7}>
                              <BasicDatePicker
                                color="primary"
                                name="fromDate"
                                size="small"
                                value={fromDate}
                                disabled={false}
                                fullWidth
                                onChange={this.changeDate("fromDate")}
                                errorText={
                                  formErrors["fromDateError"]
                                    ? formErrors["fromDateError"]
                                    : ""
                                }
                                isError={
                                  formErrors["fromDateError"]
                                    ? formErrors["fromDateError"]
                                    : ""
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            sm={4}
                            xs={4}
                            md={4}
                            lg={4}
                            container
                            justifyContent="space-evenly"
                          >
                            <Grid item xs={12} md={5} sm={5}>
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, color: redColor }}
                                label="* "
                              />
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 16, color: titleColor }}
                                label="To Date :"
                              />
                            </Grid>
                            <Grid item xs={12} md={7} sm={7}>
                              <BasicDatePicker
                                color="primary"
                                name="toDate"
                                size="small"
                                value={toDate}
                                minDate={fromDate}
                                disabled={false}
                                fullWidth
                                onChange={this.changeDate("toDate")}
                                errorText={
                                  formErrors["toDateError"]
                                    ? formErrors["toDateError"]
                                    : ""
                                }
                                isError={
                                  formErrors["toDateError"]
                                    ? formErrors["toDateError"]
                                    : ""
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            sm={2}
                            xs={2}
                            md={2}
                            lg={2}
                            container
                            justifyContent="space-evenly"
                          >
                            <ButtonCompo
                              sx={{
                                bgcolor: "dodgerblue",
                                color: "#fbff00",
                              }}
                              size="medium"
                              type="button"
                              variant="contained"
                              name={"Search"}
                              onClick={this.searchSalesTeamData}
                              fullWidth={false}
                            />
                          </Grid>
                          <Grid
                            item
                            sm={2}
                            xs={2}
                            md={2}
                            lg={2}
                            container
                            justifyContent="space-evenly"
                          >
                            <ButtonCompo
                              sx={{
                                bgcolor: "dodgerblue",
                                color: "#fbff00",
                              }}
                              size="medium"
                              type="button"
                              variant="contained"
                              name={"Excel"}
                              onClick={this.generateExcel}
                              fullWidth={false}
                            />
                          </Grid>
                        </Grid>
                      </h4>
                    </div>
                  </div>

                  <TableContainer
                    sx={{
                      maxHeight: 440,
                      // width: 610,
                      padding: 1.5,
                      // marginTop: 2.5,
                    }}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead
                        style={{
                          backgroundColor: "#fff",
                        }}
                      >
                        <TableRow>
                          <TableCell
                            color="#000"
                            align="center"
                            sx={{ width: "1%", color: "#000" }}
                            canSearch="true"
                          >
                            Sr.No
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ width: "5%", color: "#000" }}
                            canSearch="true"
                          >
                            Sales Team Name
                          </TableCell>
                          {localStorage.getItem("showCompanyFlag") &&
                            localStorage.getItem("showCompanyFlag") != 2 && (
                              <TableCell
                                align="center"
                                sx={{ width: "2%", color: "#000" }}
                                canSearch="true"
                              >
                                District
                              </TableCell>
                            )}

                          <TableCell
                            align="center"
                            sx={{ width: "15%", color: "#000" }}
                            canSearch="true"
                          >
                            Area
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ width: "3%", color: "#000" }}
                            canSearch="true"
                          >
                            Beat Count
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ width: "3%", color: "#000" }}
                            canSearch="true"
                          >
                            Total Outlet
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ width: "3%", color: "#000" }}
                            canSearch="true"
                          >
                            Productive Call
                          </TableCell>
                          {localStorage.getItem("showCompanyFlag") &&
                            localStorage.getItem("showCompanyFlag") == 2 && (
                              <TableCell
                                align="center"
                                sx={{ width: "3%", color: "#000" }}
                                canSearch="true"
                              >
                                {" "}
                                Remote Order
                              </TableCell>
                            )}

                          <TableCell
                            align="center"
                            sx={{ width: "3%", color: "#000" }}
                            canSearch="true"
                          >
                            Unvisited Outlet
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ width: "3%", color: "#000" }}
                            canSearch="true"
                          >
                            Zero Order
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ width: "3%", color: "#000" }}
                            canSearch="true"
                          >
                            Total Order Amount
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ width: "3%", color: "#000" }}
                            canSearch="true"
                          >
                            Total Sales Amount
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dashboardData2.salesInfo.length != 0 &&
                          this.getFilteredTableDataForSalesTeamList(
                            dashboardData2.salesInfo
                          ).map((salesTeamObject, index) => {
                            return (
                              <TableRow>
                                <TableCell
                                  align="center"
                                  canSearch="true"
                                  width="5%"
                                >
                                  {index + 1}
                                </TableCell>
                                <TableCell width="15%" canSearch="true">
                                  <Link
                                    color={cyanBlue}
                                    component={RouterLink}
                                    to={"/sales/" + salesTeamObject.salesTeamId}
                                  >
                                    {salesTeamObject.name}
                                  </Link>
                                </TableCell>
                                {localStorage.getItem("showCompanyFlag") &&
                                  localStorage.getItem("showCompanyFlag") !=
                                  2 && (
                                    <TableCell align="left" canSearch="true">
                                      {salesTeamObject.district_names}
                                    </TableCell>
                                  )}
                                <TableCell align="left" canSearch="true">
                                  {salesTeamObject.area_names}
                                </TableCell>
                                <TableCell
                                  width="2%"
                                  align="right"
                                  canSearch="true"
                                >
                                  <div
                                    onClick={(e) =>
                                      this.salesTeamHandler(
                                        e,
                                        salesTeamObject.salesTeamId
                                      )
                                    }
                                    style={{
                                      color: cyanBlue,
                                      cursor: "pointer",
                                    }}
                                  >
                                    {salesTeamObject.beatCount}
                                  </div>
                                </TableCell>
                                <TableCell align="right" canSearch="true">
                                  {salesTeamObject.totalOutletCount}
                                </TableCell>
                                <TableCell align="right" canSearch="true">
                                  {salesTeamObject.productiveCall}
                                </TableCell>
                                {localStorage.getItem("showCompanyFlag") &&
                                  localStorage.getItem("showCompanyFlag") ==
                                  2 && (
                                    <TableCell align="right" canSearch="true">
                                      {salesTeamObject.remoteOrderCount}
                                    </TableCell>
                                  )}

                                <TableCell align="right" canSearch="true">
                                  {salesTeamObject.unvisitedOutletCount}
                                </TableCell>
                                <TableCell align="right" canSearch="true">
                                  {salesTeamObject.zeroOrderCount}
                                </TableCell>
                                <TableCell align="right" canSearch="true">
                                  {salesTeamObject.totalAmount}
                                </TableCell>
                                <TableCell align="right" canSearch="true">
                                  {salesTeamObject.totalSalesAmt}
                                </TableCell>
                              </TableRow>
                            );
                          })}



                        <TableRow>

                          {localStorage.getItem("showCompanyFlag") &&
                            localStorage.getItem("showCompanyFlag") == 2 ? (
                            <TableCell align="right" width="2%" colSpan={4}>
                              Total
                            </TableCell>
                          ) : (
                            <TableCell align="right" width="2%" colSpan={5}>
                              Total
                            </TableCell>
                          )}
                          <TableCell align="right">
                            {totalOutletCount}
                          </TableCell>
                          <TableCell align="right">{productiveCall}</TableCell>
                          {localStorage.getItem("showCompanyFlag") &&
                            localStorage.getItem("showCompanyFlag") == 2 && (
                              <TableCell align="right">{remoteOrder}</TableCell>
                            )}

                          <TableCell align="right">{unvisited}</TableCell>
                          <TableCell align="right">{zero}</TableCell>
                          <TableCell align="right">
                            {totalAmount.toFixed(2)}
                          </TableCell>
                          <TableCell align="right">{salesAmt}</TableCell>
                        </TableRow>



                      </TableBody>
                    </Table>
                  </TableContainer>

                  {beatPopupFlag && (
                    <BeatPopUp
                      open={beatPopupFlag}
                      handleCloses={this.handleClosesSalesTeam}
                      columns={[
                        {
                          title: "Sr. No.",
                          name: "index",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "center",
                          canSearch: true,
                        },
                        {
                          title: "Beat",
                          name: "beatName",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                          canSearch: true,
                        },
                      ]}
                      rows={beatList?.beatFromSalesTeamId}
                    />
                  )}
                </div>
              </div>
              <footer className="footer">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-6">2022 Â© Byte Elephants. </div>
                    <div className="col-sm-2"></div>
                    <div className="col-sm-4">
                      <div className="text-sm-end d-none d-sm-block">
                        {" "}
                        Designed &amp; Developed by Byte Elephants Technologies
                        PVT LTD{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
        {noticeMspPopUp &&
          (noticeMsgListDataLocalStorage
            ? noticeMsgListDataLocalStorage
            : noticeList.noticeMsg
          ).length !== 0 && (
            <BootstrapDialog
              // onClose={this.handleClose}
              aria-labelledby="customized-dialog-title"
              open={true}
              fullWidth
              maxWidth="md"
            >
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={this.handleClose}
              >
                Notification -{" "}
                {
                  (noticeMsgListDataLocalStorage
                    ? noticeMsgListDataLocalStorage
                    : noticeList.noticeMsg
                  ).length
                }
              </BootstrapDialogTitle>
              <DialogContent dividers>
                {(noticeMsgListDataLocalStorage
                  ? noticeMsgListDataLocalStorage
                  : noticeList.noticeMsg
                ).map((noticeObject, index) => (
                  <React.Fragment key={index}>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      container
                      justifyContent="center"
                    >
                      {" "}
                      <img
                        className="Image14"
                        style={{ width: 810, height: 700 }}
                        src={
                          "https://bzintel-bucket.s3.ap-south-1.amazonaws.com/distribution/document/" +
                          noticeObject.image
                        }
                        alt={noticeObject.noticeHeadline}
                      />
                    </Grid>
                    <br />
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      container
                      justifyContent="center"
                    >
                      <div style={{ fontSize: "large", fontWeight: "550" }}>
                        {noticeObject.noticeHeadline}
                      </div>
                    </Grid>
                    <br />
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      container
                      justifyContent="left"
                    >
                      <div style={{ fontSize: "small" }}>
                        {noticeObject.description}
                      </div>
                    </Grid>
                    <br />
                  </React.Fragment>
                ))}
              </DialogContent>
            </BootstrapDialog>
          )}

        {licensePopUp && formStatus && (
          <>
            <BootstrapDialog
              // onClose={this.handleClose}
              aria-labelledby="customized-dialog-title"
              open={true}
              fullWidth
              maxWidth="md"
            >
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={this.handleCloseForLicense}
              >
                License
              </BootstrapDialogTitle>
              <DialogContent dividers>
                <>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="center"
                  >
                    <div style={{ fontSize: "large" }}>{formStatus}</div>
                  </Grid>
                  <br />
                </>
              </DialogContent>
            </BootstrapDialog>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
  dashboardData2: state.adminDashboard,
  stockStakeHolderTypeList: state.stockStakeHolderType,
  distributorDashbourd: state.distributorDashbourd,
  noticeList: state.noticeMsg,
  billingHistoryList: state.billingHistory,
  salesExecutiveList: state.salesExecutive,
  outletOrderDetailList: state.outletOrderDetail,
  beatList: state.beat,
  beatTargetList: state.beatTarget,
});

const mapDispatchToProps = {
  onToggle,
  showLoador,
  showNotification,
  getBillDataByOutletId,
  getRegionListFromYearAndMonth,
  getZoneListFromYearAndMonth,
  getSalesExecutiveWithCheckInOut,
  getAreaListFromYearAndMonth,
  getStockStakeHolderTypeForList,
  getSalesListFromYearAndMonth,
  // getOrderListFromYearAndMonth,
  // getProductListFromYearAndMonth,
  getMonthYearTargetForSales,
  getDashBourdMain,
  getDataForLineGraph,
  getTopOutletOrderListFromYearAndMonthByBillWise,
  getTopProductOrderListFromYearAndMonthBillWise,
  getNoticeMsgByUserType,
  getPendingOrderByMonthYear,
  getBeatTargetByUserType,
  getBeatFromSalesTeamId,
  getSalesTeamInfo,
  getSalesTeamInfoByDatesNew,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributorDashboard);
