import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { TypeColumns } from "../../../tableColumns/table-columns";
import { TypeJson } from "../../../DynamicFormsJson/MastersJSON/type";
import { getType } from "../../../Slice/type.slice";

class TypeTable extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { typeList } = this.props;
        return (
            <>
                <DynamicMainScreen
                    formPath={TypeJson.formPath}
                    screenTitle={TypeJson.screenTitle}
                    fieldMeta={TypeJson.fieldMeta}
                    showPdfDownload={false}
                    showExcelDownload={false}
                    pdfFileName={TypeJson.pdfFileName}
                    excelFileName={TypeJson.excelFileName}
                    showAddButton={TypeJson.showAddButton}
                    tableColumnsToFilter={TypeJson.tableColumnsToFilter}
                    tableColumns={TypeColumns}
                    tableData={typeList?.type}
                    getTableData={this.props.getType}
                    apiBaseURL={TypeJson.apiBaseURL}
                    baseIdColumn={TypeJson.baseIdColumn}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    typeList: state.type,
});
const mapDispatchToProps = {
    getType,
};
export default connect(mapStateToProps, mapDispatchToProps)(TypeTable);