import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { InvestedAmountJson } from "../../../DynamicFormsJson/MastersJSON/investedAmount";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
class InvestedAmountForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
      },
    };
  }

  render() {
    return (
      <>
        <DynamicForm
          formPath={InvestedAmountJson.formPath}
          screenTitle={InvestedAmountJson.screenTitle}
          fieldMeta={InvestedAmountJson.fieldMeta}
          apiBaseURL={InvestedAmountJson.apiBaseURL}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({

});
const mapDispatchToProps = {
  showLoador,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(InvestedAmountForm);
