import endpoint from "../../config/endpoints";

export const outletRateStructureJson = {
  formPath: "/outlet-rate-structure-form",
  apiBaseURL: endpoint.outletRateStructure,
  screenTitle: "Outlet Rate Configuration",
  showAddButton: true,
  showSaveBtn:true,
  showPdfDownload: true,
  pdfFileName: "OutletRateConfiguration",
  showExcelDownload: true,
  excelFileName: "OutletRateConfiguration",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Category",
      columnKeyName: "categoryName",
      isChecked: true,
    },
    {
      columnDisplayName: "Configuration Name",
      columnKeyName: "name",
      isChecked: true,
    },
  ],

  fieldMeta: [
    {
      label: "Category",
      controlType: "autocomplete/textfield",
      placeHolder: "Select Category",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "categoryForOutletDetail",
      dataKey: "category",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Reference Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      disable: true,
      dataKey: "dateForMrpRate",
      isMandatory: true,
    },
    {
      label: "Configuration Name",
      controlType: "textfield",
      placeHolder: "Enter Name of Configuration",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "name",
      isMandatory: true,
    },
    {
      label: "Margin (%)",
      controlType: "textfield",
      inputType: "number",
      placeHolder: "Enter Margin (%)",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      disable: true,
      dataKey: "marginPercentage",
      isMandatory: true,
      validName: "zero",
      valid: true,
    },
  ],
};
