import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";

const initialState = {
  activeLoginUser: [],
};

let URL = endpoints.user;

const activeLoginUserSlice = createSlice({
  name: "activeLoginUser",
  initialState,
  reducers: {
    activeLoginUserSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        activeLoginUser: row,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { activeLoginUserSuccess, resetState } =
  activeLoginUserSlice.actions;

export default activeLoginUserSlice.reducer;

export const getActiveLoginUser =
  ({ areaIds, stockStakeHolderTypeId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-active-login-user-by-areas?areaIds=" +
          areaIds +
          "&stockStakeHolderTypeId=" +
          stockStakeHolderTypeId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((activeUserObj, index) => {
            let activeUserData = {
              index: index + 1,
              id: activeUserObj.id === null ? "" : activeUserObj.id,
              userId: activeUserObj.userId === null ? "" : activeUserObj.userId,
              userName:
                activeUserObj.userName === null ? "" : activeUserObj.userName,
              name: activeUserObj.name === null ? "" : activeUserObj.name,
              stockStakeHolderTypeId:
                activeUserObj.stockStakeHolderType === null
                  ? ""
                  : activeUserObj.stockStakeHolderType.id,
              stockStakeHolderType:
                activeUserObj.stockStakeHolderType === null
                  ? ""
                  : activeUserObj.stockStakeHolderType.name,
              contactNo:
                activeUserObj.contactNo === null ? "" : activeUserObj.contactNo,
              emailId:
                activeUserObj.emailId === null ? "" : activeUserObj.emailId,
              isActive:
                activeUserObj.flag === null
                  ? ""
                  : activeUserObj.flag == 0
                  ? "Active"
                  : activeUserObj.flag == 1
                  ? "InActive"
                  : "Not check in yet",
            };
            return activeUserData;
          });
          dispatch(activeLoginUserSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getActiveLoginUserUpdated =
  ({ activeLoginUserJson }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url:
          URL + "/get-active-login-user-by-areas-and-stock-stake-holder-type",
        postBody: activeLoginUserJson,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((activeUserObj, index) => {
            let activeUserData = {
              index: index + 1,
              id: activeUserObj.id === null ? "" : activeUserObj.id,
              userId: activeUserObj.userId === null ? "" : activeUserObj.userId,
              userName:
                activeUserObj.userName === null ? "" : activeUserObj.userName,
              name: activeUserObj.name === null ? "" : activeUserObj.name,
              stockStakeHolderTypeId:
                activeUserObj.stockStakeHolderType === null
                  ? ""
                  : activeUserObj.stockStakeHolderType.id,
              stockStakeHolderType:
                activeUserObj.stockStakeHolderType === null
                  ? ""
                  : activeUserObj.stockStakeHolderType.name,
              contactNo:
                activeUserObj.contactNo === null ? "" : activeUserObj.contactNo,
              emailId:
                activeUserObj.emailId === null ? "" : activeUserObj.emailId,
              isActive:
                activeUserObj.flag === null
                  ? ""
                  : activeUserObj.flag == 0
                  ? "Active"
                  : activeUserObj.flag == 1
                  ? "InActive"
                  : "Not check in yet",
            };
            return activeUserData;
          });
          dispatch(activeLoginUserSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const setActiveLoginUser =
  ({ row }) =>
  async (dispatch) => {
    dispatch(activeLoginUserSuccess({ row }));
  };
