import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import endpoint from "../../config/endpoints";
import { grnHistoryJson } from "../../DynamicFormsJson/Transaction/grnHistory";
import {
  getGrnearchListByDate,
  setGrnHistory,
} from "../../Slice/requestGRN.slice";
import { grnHistoryListColumns } from "../../tableColumns/table-columns";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";
import { serverMsg, noInternetMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
// import { getStockStackHolders } from "../../Slice/StackHolder.slice";

class GrnHistoryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      dynamicMasterData: {
        stakeholdersDetail: this.props.stakeholdersList.stockStakeHolders,
      },
    };
  }

  async componentDidMount() {

    const showCompanyFlag = localStorage.getItem("showCompanyFlag");
    if (showCompanyFlag == 0) {
      this.getData();
      this.setState({
        companyFlagOfc: true,
      });
    }
  }

  getData = async () => {
    const { getGrnearchListByDate, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getGrnearchListByDate({ fromdate: "", todate: "" }).then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        }
      );
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  componentWillReceiveProps(nextProps) {
    if (this.props.stakeholdersList !== nextProps.stakeholdersList) {
      if (
        this.props.stakeholdersList?.stockStakeHolders !==
        nextProps.stakeholdersList?.stockStakeHolders
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeholdersDetail: nextProps.stakeholdersList?.stockStakeHolders,
          },
        });
      }
    }
  }
  getCreditNoteListData = ({ fromdate, todate }) => {
    const { getGrnearchListByDate, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getGrnearchListByDate({
        fromdate: fromdate,
        todate: todate,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onSearchList = (data) => {
    this.setState({
      formData: data,
    });
    this.getCreditNoteListData({
      fromdate: data.fromdate,
      todate: data.todate,
    });
  };
  rowPDF = (rowData) => {
    if (navigator.onLine) {
      this.props.showLoador({ loador: true });
      downloadF({
        url:
          endpoint.grnGVNHeader +
          "/report-by-id?documentType=1" +
          "&id=" +
          rowData.id,
        ext: "pdf",
        openNewTab: true,
      }).then((response) => {
        this.props.showLoador({ loador: false });
        if (!response) {
          this.props.showNotification({
            msg: serverMsg,
            severity: "error",
          });
        } else {
        }
      });
    }
  };
  render() {
    const { grnList, setGrnHistory } = this.props;
    const { dynamicMasterData } = this.state;

    return (
      <>
        <DynamicMainScreen
          deleteMsgAuto={true}
          showViewButtonInTable={true}
          showDeleteIcon={false}
          dynamicMasterData={dynamicMasterData}
          searchList={grnHistoryJson.searchList}
          onSearchData={this.onSearchList}
          formPath={grnHistoryJson.formPath}
          screenTitle={grnHistoryJson.screenTitle}
          formPathView={grnHistoryJson.formPathView}
          showPdfDownload={false}
          showExcelDownload={false}
          isActiveURL={grnHistoryJson.isActiveURL}
          showAddButton={false}
          tableColumns={grnHistoryListColumns}
          tableData={grnList.grnHistoryByDate}
          apiBaseURL={grnHistoryJson.apiBaseURL}
          callApi={false}
          getTableDataById={true}
          showSearchBox={true}
          rowPDF={this.rowPDF}
          showPdf={false}
          showEditIcon={false}
          showPegination={false}
          getTableData={this.getData}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stakeholdersList: state.stackholder,
  grnList: state.requestGRN,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  // getStockStackHolders,
  getGrnearchListByDate,
  setGrnHistory,
};
export default connect(mapStateToProps, mapDispatchToProps)(GrnHistoryTable);
