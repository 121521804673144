import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { batchwiseStakeholderBillJson } from "../../DynamicFormsJson/Transaction/batchwiseStakeholderBill";
import {
  getOrdersFromStakeholderIdAndDate,
  getPlaceOrderHeaderByIdForStakeholderBillingNew,
} from "../../Slice/OrderHeaderList.slice";
import { getStockStackHolders } from "../../Slice/StackHolder.slice";
import { getItemwiseBatch } from "../../Slice/manageStock.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { DarkBlue } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  addAtleastProductMsg,
  deleteWarningMsg,
  noInternetMsg,
  serverMsg,
} from "../../config/messageconstant";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import BatchwiseOutletBillingHeaderForm from "../Request GRN/RequestGRNHeaderForm";
import BatchwiseStakeHolderPopUpForOffer from "./BatchwiseStakeHolderPopUpForOffer";
import BatchwiseStakeholderBillingWithOffer from "./BatchwiseStakeholderBillingWithOffer";
import dayjs from "dayjs";
class BatchwiseOutletBillingStakeholderForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowList: [],
      formErrors: {},
      open: false,
      openBill: false,
      offerBillData: "",
      offerBillDataToSave: "",
      fieldData: { type: "1" },
      batchwiseStockDetailList: [],
      dynamicMasterData: {
        stakeholdersDetail: this.props.stakeholdersList.stockStakeHolders,
        orderDetail: this.props.orderList.orderListFromStakeholderAndDate,
        batchDetail: this.props.batchList.itemwiseBatchData,
        productDetail: this.props.orderList.orderProductList,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.stakeholdersList !== nextProps.stakeholdersList) {
      if (
        this.props.stakeholdersList?.stockStakeHolders !==
        nextProps.stakeholdersList?.stockStakeHolders
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeholdersDetail: nextProps.stakeholdersList?.stockStakeHolders,
          },
        });
      }
    }
    if (this.props.orderList !== nextProps.orderList) {
      if (
        this.props.orderList.orderProductList !==
        nextProps.orderList.orderProductList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            productDetail: nextProps.orderList.orderProductList,
          },
        });
      }
    }
    if (this.props.batchList !== nextProps.batchList) {
      if (
        this.props.batchList.itemwiseBatchData !==
        nextProps.batchList.itemwiseBatchData
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            batchDetail: nextProps.batchList.itemwiseBatchData.filter((row) => row.primaryClosingQty > 0),
          },
        });
      }
    }
    if (this.props.orderList !== nextProps.orderList) {
      if (
        this.props.orderList.orderListFromStakeholderAndDate !==
        nextProps.orderList.orderListFromStakeholderAndDate
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            orderDetail: nextProps.orderList.orderListFromStakeholderAndDate,
          },
        });
      }
    }
  }

  getListById = async (data) => {
    const {
      showLoador,
      showNotification,
      getItemwiseBatch,
      getOrdersFromStakeholderIdAndDate,
      getPlaceOrderHeaderByIdForStakeholderBillingNew,
    } = this.props;
    if (navigator.onLine) {
      if (data.orderId) {
        showLoador({ loador: true });
        await getPlaceOrderHeaderByIdForStakeholderBillingNew({
          orderHeaderId: data.orderId,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        });
      }
      if (data.stakeholder) {
        showLoador({ loador: true });
        await getOrdersFromStakeholderIdAndDate({
          orderDate: dayjs().format("DD-MM-YYYY"),
          stakeholderId: data.stakeholder,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        });
      }
      if (data.product) {
        showLoador({ loador: true });
        await getItemwiseBatch({
          orderDate: dayjs().format("DD-MM-YYYY"),
          productId: data.product,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        });
      }
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  async componentDidMount() {
    this.setState({
      rowList: [],
    });
    const { showLoador, showNotification, getStockStackHolders } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getStockStackHolders().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  }

  onSearch = (data) => {
    const { rowList, dynamicMasterData, batchwiseStockDetailList } = this.state;
    const { orderList } = this.props;
    orderList.orderProductList.map((productObject) => {
      if (data.product == productObject.id) {
        if (rowList.length !== 0) {
          let qtyTotal = 0;
          rowList.map((rowObject) => {
            if (data.product == rowObject.productId) {
              qtyTotal = +rowObject.qty + qtyTotal;
            }
          });
          if (+data.qty + qtyTotal >= data.orderQty) {
            let newProductList = dynamicMasterData.productDetail.filter(
              (row) => row.id !== data.product
            );
            this.setState({
              dynamicMasterData: {
                ...dynamicMasterData,
                productDetail: newProductList,
              },
            });
          }
        } else {
          if (+data.qty >= data.orderQty) {
            let newProductList = orderList.orderProductList.filter(
              (row) => row.id !== data.product
            );
            this.setState({
              dynamicMasterData: {
                ...dynamicMasterData,
                productDetail: newProductList,
              },
            });
          }
        }
      }
    });

    let productList = dynamicMasterData["productDetail"]
      ? dynamicMasterData["productDetail"].filter(
        (row) => row.id == data.product
      )
      : [];
    let catList = dynamicMasterData["categoryDetail"]
      ? dynamicMasterData["categoryDetail"].filter(
        (row) => row.id == data.category
      )
      : [];
    this.setState({
      rowList: [
        ...rowList,
        {
          index: rowList.length + 1,
          productName: productList.length != 0 ? productList[0].name : "-",
          productId: productList.length != 0 ? productList[0].id : "-",
          mrp: productList.length != 0 ? productList[0].mrp : "-",
          uom: productList.length != 0 ? productList[0].stakeHolderUom : "-",
          uomConversionValue:
            productList.length != 0 ? productList[0].uomConversionValue : "-",
          rate: data.rate != 0 ? data.rate : "-",
          batch: data.batch != 0 ? data.batch : "-",
          cgstPer: productList.length != 0 ? productList[0].cgstPer : "-",
          sgstPer: productList.length != 0 ? productList[0].sgstPer : "-",
          igstPer: productList.length != 0 ? productList[0].igstPer : "-",
          uomId: productList.length != 0 ? productList[0].uomId : "-",
          qty: data.qty != 0 ? data.qty : "-",
          total: (+data.rate * +data.qty).toFixed(2),
          freeQty: data.freeQty,
          itemDiscPer: data.itemDiscPer,
          stockStakeHolderOrderDetail:
            productList.length != 0 ? productList[0].headerId : "-",
          ...data,
        },
      ],
    });
    let batchList = [];
    if (batchwiseStockDetailList.length !== 0) {
      batchwiseStockDetailList.map((stockData, index) => {
        if (data.batch == stockData.batch) {
          batchwiseStockDetailList.splice(index, 1);
          let stockDataNew = {
            batch: stockData.batch,
            qty: +stockData.qty + +data.qty,
            primaryClosingQty: data.primaryClosingQty,
          };
          batchwiseStockDetailList.push(stockDataNew);
          return stockDataNew;
        }
      });
    } else {
      let stockDataNew = {
        batch: data.batch,
        qty: +data.qty,
        primaryClosingQty: data.primaryClosingQty,
      };
      batchwiseStockDetailList.push(stockDataNew);
    }
    let batchData = {
      batchId: data.batch,
      stock: +data.primaryClosingQty,
    };
    batchList.push(batchData);
  };

  onSubmitData = () => {
    const { showNotification } = this.props;
    if (this.checkValidationOnSubmit()) {
      const { rowList, fieldData } = this.state;
      if (rowList.length != 0) {
        let dataToSave = [];
        rowList.map((data) => {
          let batchList = [];
          rowList.map((data1) => {
            if (data.productId == data1.productId) {
              if (batchList.length !== 0) {
                let batchData = batchList.filter(
                  (row) => row.batchCode == data1.batch
                );
                if (batchData.length !== 0) {
                } else {
                  let second = {
                    batchCode: data1.batch,
                    stock: data1.primaryClosingQty,
                  };
                  batchList.push(second);
                }
              } else {
                let second = {
                  batchCode: data1.batch,
                  stock: data1.primaryClosingQty,
                };
                batchList.push(second);
              }
            }
          });

          let third = {
            productId: data.productId,
            stock: 0,
            batchAndStocks: batchList,
          };
          if (dataToSave.length !== 0) {
            let rowData = dataToSave.filter(
              (row) => row.productId == third.productId
            );
            if (rowData.length !== 0) {
            } else {
              dataToSave.push(third);
            }
          } else {
            dataToSave.push(third);
          }
        });

        const stockStakeHolderOrderDetail = rowList.map((rowData) => {
          const rowObj = {
            product: {
              id: rowData.product,
            },
            qty: rowData.qty,
            batchCode: rowData.batch !== null ? rowData.batch : 0,
            rate: rowData.rate,
            mrp: rowData.mrp,
            cgstPer: rowData.cgstPer,
            sgstPer: rowData.sgstPer,
            igstPer: rowData.igstPer,
            freeQty: rowData.freeQty,
            itemDiscPer: rowData.itemDiscPer,
            uom: {
              id: rowData.uomId,
            },
            stockStakeHolderOrderDetail: {
              id: rowData.stockStakeHolderOrderDetail,
            },
            conversionValue: rowData.uomConversionValue,
          };

          return rowObj;
        });
        const billDataToSave = {
          discountOnBillPer: "0",
          toBill: fieldData.stakeholder,
          stockStakeHolderBillDetails: stockStakeHolderOrderDetail,
          billDate: dayjs().format("DD-MM-YYYY"),
        };
        let billHeaderDataToSave = {
          stockStakeHolderBillHeader: billDataToSave,
          productAndStocks: dataToSave,
        };
        this.onSave(billHeaderDataToSave);
      } else {
        showNotification({
          msg: addAtleastProductMsg,
          severity: "error",
        });
      }
    }
  };
  onSave = (billHeaderDataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url:
          endpoint.stockStakeholderBillHeader +
          "/generate-with-applied-offer-batch-wise",
        postBody: billHeaderDataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          if (
            Object.keys(data.data).length !== 0 &&
            Object.keys(data.data.stockStakeHolderBillsAfterApplyingOffers)
              .length !== 0
          ) {
            if (
              data.data.stockStakeHolderBillsAfterApplyingOffers
                .stockStakeHolderBillDetails.length !== 0
            ) {
              let newObjectToSave = {
                ...data.data.stockStakeHolderBillsAfterApplyingOffers,
                stockStakeHolderBillDetails:
                  data.data.stockStakeHolderBillsAfterApplyingOffers.stockStakeHolderBillDetails.map(
                    (orderObject) => {
                      let productWithoutBillObject =
                        data.data.stockStakeHolderBillHeader.stockStakeHolderBillDetails.filter(
                          (row) => row.id == orderObject.id
                        );
                      orderObject = {
                        ...orderObject,
                        taxableAmtWithoutOffer:
                          productWithoutBillObject[0].taxableAmt,
                        totalAmtWithoutOffer:
                          productWithoutBillObject[0].totalAmt,
                        isChecked: 1,

                      };
                      return orderObject;
                    }
                  ),
              };
              let newData = {
                ...data.data,
                stockStakeHolderBillsAfterApplyingOffers: newObjectToSave,
              };
              let flag = 0;
              let newList1 = [];
              if (
                Object.keys(newData).length !== 0 &&
                Object.keys(newData.stockStakeHolderBillsAfterApplyingOffers)
                  .length !== 0 &&
                newData.stockStakeHolderBillsAfterApplyingOffers.appliedOffers
                  .length !== 0
              ) {
                newList1 =
                  newData.stockStakeHolderBillsAfterApplyingOffers.appliedOffers.map(
                    (offerObject) => {
                      if (offerObject.levelWiseType == 3) {
                        flag++;
                        offerObject = {
                          ...offerObject,
                          isShow: 1,
                        };
                        return offerObject;
                      } else if (offerObject.levelWiseType == 5) {
                        flag++;
                        offerObject = {
                          ...offerObject,
                          isShow: 1,
                        };
                        return offerObject;
                      } else if (offerObject.levelWiseType == 6) {
                        flag++;
                        offerObject = {
                          ...offerObject,
                          isShow: 1,
                        };
                        return offerObject;
                      } else if (offerObject.levelWiseType == 7) {
                        flag++;
                        offerObject = {
                          ...offerObject,
                          isShow: 1,
                        };
                        return offerObject;
                      } else {
                        return offerObject;
                      }
                    }
                  );
              }
              let newList2 = {
                ...newData.stockStakeHolderBillsAfterApplyingOffers,
                appliedOffers: newList1,
              };
              let newList3 = {
                ...newData,
                stockStakeHolderBillsAfterApplyingOffers: newList2,
              };
              if (flag > 0) {
                this.setState({
                  openBill: false,
                  open: true,
                  offerBillData: newList3,
                });
              } else {
                this.setState({
                  openBill: true,
                  open: true,
                  offerBillDataToSave: newList3,
                });
              }
            }
          }
        } else {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  mandatoryCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    batchwiseStakeholderBillJson.fieldMeta.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&
              fieldData[currentField.dataKey].id == null))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  ValidationCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    batchwiseStakeholderBillJson.fieldMeta.forEach((currentField) => {
      if (currentField.valid) {
        if (
          currentField.validName === "email" &&
          !validEmail.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "zero" &&
          fieldData[currentField.dataKey] === 0
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "greater-thanHundered" &&
          fieldData[currentField.dataKey] > 100
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} should not be greater than 100 per.`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  checkValidationOnSubmit = () => {
    if (this.mandatoryCheck()) {
      return this.ValidationCheck();
    } else {
      return false;
    }
  };

  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    batchwiseStakeholderBillJson.fieldMeta.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };

  getDataForDisabled = (value) => {
    const { dynamicMasterData, rowList, setProductId } = this.state;
    let productList = dynamicMasterData["productDetail"]
      ? dynamicMasterData["productDetail"].filter((row) => row.id == value)
      : [];
    if (dynamicMasterData["productDetail"]) {
      this.setState({
        setProductId: value,
      });
    }
    let batchList = dynamicMasterData["batchDetail"]
      ? dynamicMasterData["batchDetail"].filter((row) => row.id == value)
      : [];
    if (batchList.length != 0) {
      let newBatchList = rowList.filter((row) => row.batch == batchList[0].id);
      if (newBatchList.length != 0) {
        let newBatchQty = 0;
        newBatchList.map((row) => {
          if (setProductId == row.productId) {
            newBatchQty = newBatchQty + +row.qty;
          }
        });
        return {
          primaryClosingQty: (
            +batchList[0].primaryClosingQty - newBatchQty
          ).toString(),
          batchQty: batchList.length != 0 && batchList[0].primaryClosingQty,
        };
      }
      return {
        primaryClosingQty:
          batchList.length != 0 && batchList[0].primaryClosingQty,
        batchQty: batchList.length != 0 && batchList[0].primaryClosingQty,
      };
    }
    if (productList.length != 0) {
      return {
        rate: productList.length != 0 && productList[0].rate,
        mrp: productList.length != 0 && productList[0].mrp,
        orderQty: productList.length != 0 && productList[0].orderQty,
        uom: productList.length != 0 && productList[0].stakeHolderUom,
        uomConversionValue:
          productList.length != 0 && productList[0].uomConversionValue,
      };
    }
  };

  getDataForCalculate = (value, fieldData) => {
    const { dynamicMasterData, rowList, productId } = this.state;
    let batchList = dynamicMasterData["batchDetail"]
      ? dynamicMasterData["batchDetail"].filter(
        (row) => row.id == fieldData.batch
      )
      : [];
    if (rowList.length != 0) {
      let newQty = fieldData.batchQty;
      let newFreeQty = fieldData.qty;
      rowList.map((row) => {
        if (productId == row.productId && row.batch == fieldData.batch) {
          if (batchList.length != 0 && fieldData.batch) {
            let newBatchList = batchList.filter(
              (rowData) => rowData.id == row.batch
            );
            if (newBatchList.length != 0) {
              newQty = newQty - +row.qty;
              newFreeQty = newQty - +row.freeQty;
            }
          }
        }
      });
      return {
        primaryClosingQty: (+newQty - +value).toString(),
        primaryQty: newQty,
      };
    } else if (fieldData.batch) {
      if (batchList.length != 0) {
        return {
          primaryClosingQty: (
            +batchList[0].primaryClosingQty - value
          ).toString(),
          primaryQty: +batchList[0].primaryClosingQty,
        };
      }
    }
  };

  rowDelete = (rowData) => {
    const { rowList, dynamicMasterData } = this.state;
    const { orderList } = this.props;
    swal({
      title: "Are you sure?",
      text: deleteWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (rowList.length !== 0) {
          const rowListData = this.state.rowList.filter(
            (row) => row.index != rowData.index
          );
          if (rowListData.length !== 0) {
            const row = rowListData.map((rowListDataObj, index) => {
              return { ...rowListDataObj, index: index + 1 };
            });
            let newProductList = row.filter(
              (row) => row.productId == rowData.productId
            );
            if (newProductList.length !== 0) {
              let qtyTotal = 0;
              newProductList.map((rowObject) => {
                qtyTotal = +rowObject.qty + qtyTotal;
              });
              if (qtyTotal < rowData.orderQty) {
                let productList = orderList.orderProductList.filter(
                  (row) => row.id == rowData.productId
                );
                if (productList.length !== 0) {
                  let newList = dynamicMasterData.productDetail;
                  newList.push(productList[0]);
                  this.setState({
                    dynamicMasterData: {
                      ...dynamicMasterData,
                      productDetail: newList,
                    },
                  });
                }
              }
            } else {
              let productList = orderList.orderProductList.filter(
                (row) => row.id == rowData.productId
              );
              let newList = dynamicMasterData.productDetail;
              newList.push(productList[0]);
              this.setState({
                dynamicMasterData: {
                  ...dynamicMasterData,
                  productDetail: newList,
                },
              });
            }
            this.setState({
              rowList: row,
            });
          } else {
            let newProductList = rowList.filter(
              (row) => row.productId == rowData.productId
            );
            if (newProductList.length !== 0) {
              let qtyTotal = 0;
              newProductList.map((rowObject) => {
                qtyTotal = +rowObject.qty + qtyTotal;
              });
              if (qtyTotal < rowData.orderQty) {
                let productList = orderList.orderProductList.filter(
                  (row) => row.id == rowData.productId
                );

                if (productList.length !== 0) {
                  let newList = dynamicMasterData.productDetail;
                  newList.push(productList[0]);
                  this.setState({
                    dynamicMasterData: {
                      ...dynamicMasterData,
                      productDetail: newList,
                    },
                  });
                }
              }
            } else {
              let productList = orderList.orderProductList.filter(
                (row) => row.id == rowData.productId
              );

              if (productList.length !== 0) {
                let newList = dynamicMasterData.productDetail;
                newList.push(productList[0]);

                this.setState({
                  dynamicMasterData: {
                    ...dynamicMasterData,
                    productDetail: newList,
                  },
                });
              }
            }
            this.setState({
              rowList: [],
            });
          }
        }
      }
    });
  };

  toggle = () => {
    this.setState({
      open: false,
      openBill: false,
    });
  };

  savaBillHandler = (rowData) => {
    const { offerBillData } = this.state;
    if (
      Object.keys(offerBillData).length !== 0 &&
      Object.keys(offerBillData.stockStakeHolderBillsAfterApplyingOffers)
        .length !== 0 &&
      offerBillData.stockStakeHolderBillsAfterApplyingOffers
        .stockStakeHolderBillDetails.length !== 0
    ) {
      let newList1 =
        offerBillData.stockStakeHolderBillsAfterApplyingOffers.appliedOffers.map(
          (offerObject) => {
            if (offerObject.levelWiseType == 3) {
              offerObject = {
                ...offerObject,
                isShow: 0,
              };
              return offerObject;
            } else if (offerObject.levelWiseType == 5) {
              offerObject = {
                ...offerObject,
                isShow: 0,
              };
              return offerObject;
            } else if (offerObject.levelWiseType == 6) {
              offerObject = {
                ...offerObject,
                isShow: 0,
              };
              return offerObject;
            } else if (offerObject.levelWiseType == 7) {
              offerObject = {
                ...offerObject,
                isShow: 0,
              };
              return offerObject;
            } else {
              return offerObject;
            }
          }
        );

      let dataToSet = [
        ...offerBillData.stockStakeHolderBillsAfterApplyingOffers
          .stockStakeHolderBillDetails,
        ...rowData,
      ];
      let offerBillAfterApplyingOffersData = {
        ...offerBillData.stockStakeHolderBillsAfterApplyingOffers,
        stockStakeHolderBillDetails: dataToSet,
      };
      let offerBillDataToSaveNew = {
        ...offerBillData,
        stockStakeHolderBillsAfterApplyingOffers:
          offerBillAfterApplyingOffersData,
      };
      this.setState({
        offerBillDataToSave: offerBillDataToSaveNew,
        open: true,
        openBill: true,
      });
    }
  };

  render() {
    const {
      dynamicMasterData,
      rowList,
      formErrors,
      fieldData,
      open,
      openBill,
      offerBillData,
      offerBillDataToSave,
    } = this.state;
    return (
      <>
        {!open && !openBill && (
          <>
            <Paper
              sx={{
                padding: 2,
              }}
              elevation={0}
            >
              <BatchwiseOutletBillingHeaderForm
                dynamicMasterData={dynamicMasterData}
                screenTitle={batchwiseStakeholderBillJson.screenTitle}
                fieldMeta={batchwiseStakeholderBillJson.fieldMeta}
                apiBaseURL={batchwiseStakeholderBillJson.apiBaseURL}
                formErrors={formErrors}
                showSaveBtn={batchwiseStakeholderBillJson.showSaveBtn}
                showSaveBtnMain={false}
                fieldData={fieldData}
                onDataChange={this.onDataChange}
              />
              <LabelCompo
                className="text-black"
                style={{ fontSize: "20px", color: DarkBlue }}
                label={"Add Product"}
              />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                sx={{ paddingTop: 2 }}
              >
                <DynamicFormWithoutSave
                  // fieldDataStatic={fieldData["date"] ? fieldData["date"] : ""}
                  showSaveBtn={true}
                  showSaveBtnMain={false}
                  dynamicMasterData={dynamicMasterData}
                  getListById={this.getListById}
                  searchList={batchwiseStakeholderBillJson.searchList}
                  showTitle={batchwiseStakeholderBillJson.showTitle}
                  screenTitle={batchwiseStakeholderBillJson.screenTitle}
                  fieldMeta={batchwiseStakeholderBillJson.fieldMetaDetails}
                  showCancel={batchwiseStakeholderBillJson.showCancel}
                  showBackToList={batchwiseStakeholderBillJson.showBackToList}
                  apiBaseURL={batchwiseStakeholderBillJson.apiBaseURL}
                  showSaveNextBtn={batchwiseStakeholderBillJson.showSaveNextBtn}
                  saveBtnText={"ADD"}
                  onSave={this.onSearch}
                  resetAfterSave={true}
                  getDataForCalculate={this.getDataForCalculate}
                  getDataForDisabled={this.getDataForDisabled}
                />
              </Grid>

              <br />
              <RTTable
                showPegination={false}
                columns={[
                  { name: "index", title: "Sr. No.", align: "center" },
                  { name: "productName", title: "Product" },
                  { name: "uom", title: "UOM", align: "center" },
                  { name: "rate", title: "Rate", align: "right" },
                  { name: "batch", title: "Batch", align: "left" },
                  { name: "qty", title: "Quantity", align: "right" },
                  { name: "total", title: "Total", align: "right" },
                  { name: "action", title: "Action" },
                ]}
                hiddenColumnNames={[]}
                tableData={rowList}
                isActionColActive={true}
                showEditIcon={false}
                showDeleteIcon={true}
                rowDelete={this.rowDelete}
              />
              <br />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Proceed"
                  onClick={this.onSubmitData}
                />
              </Grid>
            </Paper>
          </>
        )}
        {open && !openBill && (
          <>
            <BatchwiseStakeHolderPopUpForOffer
              offerBillData={offerBillData}
              cancelHandler={this.toggle}
              onDataSave={this.savaBillHandler}
            />
          </>
        )}
        {open && openBill && (
          <>
            <BatchwiseStakeholderBillingWithOffer
              offerBillStockStakeHolderData={offerBillDataToSave}
              onCancel={this.toggle}
            />
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  productList: state.product,
  batchList: state.manageStockByDate,
  stakeholdersList: state.stackholder,
  receivedBillList: state.billHeaderList,
  orderList: state.orderHeaderList,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getItemwiseBatch,
  getStockStackHolders,
  getOrdersFromStakeholderIdAndDate,
  getPlaceOrderHeaderByIdForStakeholderBillingNew,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BatchwiseOutletBillingStakeholderForm);
