import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import {
  BeatColumns,
  OutletCountStatusColumnsForPopup,
} from "../../../tableColumns/table-columns";
import { beatJson } from "../../../DynamicFormsJson/MastersJSON/beat";
import { getBeatByUserType, getBeatUpdated } from "../../../Slice/beat.slice";
import { getRegionNew } from "../../../Slice/region.slice";
import { getBeatsByAreasPostUpdated } from "../../../Slice/beat.slice";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { noInternetMsg, serverMsg } from "../../../config/messageconstant";
import { getStateByRegion } from "../../../Slice/state.slice";
import { getZoneByState } from "../../../Slice/zone.slice";
import { getDistrictByZone } from "../../../Slice/district.slice";
import { getTalukaByDistrict } from "../../../Slice/taluka.slice";
import { getAreaByTalukaPostBody } from "../../../Slice/area.slice";
import { apiGet } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import OutletPopupForActiveInactiveForBeat from "./OutletPopupForActiveInactiveForBeat";
class BeatTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowListData: [],
      dynamicMasterData: {
        areaDetail: this.props.areaList.area,
        regionDetail: this.props.regionList?.region,
        stateDetail: this.props.stateList?.stateByRegion,
        zoneDetail: this.props.zoneList?.zoneByState,
        districtDetail: this.props.districtList?.districtByZone,
        talukaDetail: this.props.talukaList?.talukaByDistrict,
        areaDetail: this.props.areaList?.areaByTaluka,
      },
      adminFlag: false,
      popupFlag: false,
      outletId: "",
      isActive: "",
      beatId: "",
    };
  }
  async componentDidMount() {
    const { getRegionNew, showLoador, showNotification } = this.props;
    const { adminFlag } = this.state;

    if (navigator.onLine) {
      showLoador({ loador: true });
      getRegionNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      // showLoador({ loador: true });
      // apiGet({
      //   url:
      //     endpoint.userType + "/by-session-user",
      // }).then(({ data, success }) => {
      //   if (success) {
      //     showLoador({ loador: false });
      //     if (data.data.id == 1) {
      //       this.setState({
      //         adminFlag: true,
      //       })
      //     }
      //   } else {
      //     showNotification({ msg: serverMsg, severity: "error" });
      //   }
      // });

      await this.getList();
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  getList = async () => {
    const { getBeatByUserType, getBeatUpdated, showLoador, showNotification } =
      this.props;
    const userTypeId = localStorage.getItem("userTypeId");
    if (userTypeId == 1 || userTypeId == 6 ) {
      showLoador({ loador: true });
      await getBeatUpdated().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            adminFlag: true,
          });
        }
      });
    } else {
      showLoador({ loador: true });
      await getBeatByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
  }
  onSearchData = async (data) => {
    const { getBeatsByAreasPostUpdated, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      if (data.area && data.area != "") {
        let beatData = {
          areaIds: data.area,
        };
        showLoador({ loador: true });
        await getBeatsByAreasPostUpdated({ beatDataForPost: beatData }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      } else {
        await this.getList();
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTalukaPostBody,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      console.log(data);
      if (data.region && data.region != "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state && data.state != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.zone && data.zone != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.district && data.district != "") {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka && data.taluka != "") {
        let talukaJson = {
          talukaIds: data.taluka,
        };
        showLoador({ loador: true });
        await getAreaByTalukaPostBody({ talukaIds: talukaJson }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onStatusChangePopUpStatic = (row, rowData) => {
    console.log(row);
    console.log(rowData);
    this.setState({
      popupFlag: true,
      outletId: row.id,
      isActive: row.isActive,
      beatId: rowData.id,
    });
  };
  // handleCloses = () => {
  //   this.setState({
  //     popupFlag: false,
  //   });
  // };
  handleCloses = (flag) => {
    this.setState({
      popupFlag: false,
    });
    if (flag) {
      const { showLoador, showNotification } = this.props;
      const { beatId } = this.state;
      if (navigator.onLine) {
        showLoador({ loador: true });
        apiGet({
          url: endpoint.outlet + "/list-by-beat?beatId=" + beatId,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            let rowListData1 = data.data.map((rowData, index) => {
              rowData = {
                index: index + 1,
                ...rowData,
              };
              return rowData;
            });
            this.setState({
              rowListData: rowListData1,
            });
          }
        });
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };
  render() {
    const { beatList } = this.props;
    const {
      dynamicMasterData,
      rowListData,
      adminFlag,
      popupFlag,
      outletId,
      isActive,
    } = this.state;
    return (
      <>
        <DynamicMainScreen
          rowListData={rowListData}
          dynamicMasterData={dynamicMasterData}
          onSearchData={this.onSearchData}
          getListById={this.getListById}
          searchList={adminFlag ? beatJson.searchList : []}
          searchButtonGrid={beatJson.searchButtonGrid}
          formPath={beatJson.formPath}
          screenTitle={beatJson.screenTitle}
          fieldMeta={beatJson.fieldMeta}
          showDeleteIcon={adminFlag ? true : false}
          showPdfDownload={false}
          changeExcelURL={adminFlag ? beatJson.excelUrl : false}
          showExtension={false}
          showExcelDownload={adminFlag ? true : false}
          pdfFileName={adminFlag ? beatJson.pdfFileName : false}
          excelFileName={adminFlag ? beatJson.excelFileName : false}
          showAddButton={beatJson.showAddButton}
          tableColumnsToFilter={beatJson.tableColumnsToFilter}
          tableColumns={BeatColumns}
          tableData={adminFlag ? beatList?.beat : beatList?.beatByUserType}
          getTableDataById={true}
          callApi={false}
          apiBaseURL={beatJson.apiBaseURL}
          baseIdColumn={beatJson.baseIdColumn}
          isNote={true}
          isNoteValue={"Add Various Routes"}
          showPopUpDataList={[
            {
              dataKey: "outletCount",
              columns: OutletCountStatusColumnsForPopup,
              title: "Outlet List",
              header: {
                dataKey: "name",
                label: "Beat Name",
              },
            },
          ]}
          getTableData={this.getList}
          onStatusChangePopUpStatic={this.onStatusChangePopUpStatic}
        />
        {popupFlag && (
          <>
            <OutletPopupForActiveInactiveForBeat
              open={popupFlag}
              handleCloses={this.handleCloses}
              onSave={this.saveHandler}
              outletId={outletId}
              isActive={isActive}
            />
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  beatList: state.beat,
  areaList: state.area,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getRegionNew,
  getBeatByUserType,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTalukaPostBody,
  getStateByRegion,
  getBeatsByAreasPostUpdated,
  getBeatUpdated,
};
export default connect(mapStateToProps, mapDispatchToProps)(BeatTable);
