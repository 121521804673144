import endpoint from "../../config/endpoints";

export const BatchWiseManageOutstockJson = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.manageStockHeader,
  screenTitle: "Manage Out-Stock",
  formPath: "/batchwise-manage-outstock-form",
  formPathView: "/batchwise-manage-outstock-view",
  showBackToList: true,
  showTitle: false,
  searchList: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "fromdate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "todate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "todate",
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
      isMandatory: true,
      onSubmit: "compare",
    },
  ],
  fieldMeta: [
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "date",
      isMandatory: true,
      getListId: "date,product",
    },
  ],
  fieldMetaDetails: [
    {
      label: "Category",
      controlType: "autocomplete",
      placeHolder: "Select Category ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "categoryDetail",
      dataKey: "category",
      isRootLevelKey: true,
      isMandatory: true,
      getListFrom: "outlet",
      getListId: "category",
    },
    {
      label: "Product ",
      controlType: "autocomplete",
      placeHolder: "Select Product",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "productDetail",
      dataKey: "product",
      getListFrom: "category",
      isRootLevelKey: true,
      getListId: "date,product",
      isMandatory: true,
      getData: true,
    },
    {
      label: "Batch ",
      controlType: "autocomplete",
      placeHolder: "Select Batch",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "batchDetail",
      dataKey: "batch",
      getListFrom: "product",
      isRootLevelKey: true,
      isMandatory: true,
      getData: true,
    },
    {
      label: "Batch Quantity",
      controlType: "textfield",
      placeHolder: "Enter quantity",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "primaryClosingQty",
      isMandatory: false,
      spValid: "float",
      disableTrue: true,
    },
    {
      label: "Quantity",
      controlType: "textfield",
      placeHolder: "Enter Quantity",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      calculateMinus: true,
      dataKey: "qty",
      valid: true,
      validName: "greater-less",
      zeroValid: true,
      validCondition: "less-primaryQty",
      isMandatory: true,
      validText: "Batch Quantity",
    },
    {
      label: "Rate",
      controlType: "textfield",
      placeHolder: "Enter Rate",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "rate",
      isMandatory: false,
      spValid: "float",
      disableTrue: true,
    },
    {
      label: "MRP",
      controlType: "textfield",
      placeHolder: "Enter MRP",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "mrp",
      isMandatory: false,
      spValid: "float",
      disableTrue: true,
    },
    {
      label: "UOM",
      controlType: "textfield",
      placeHolder: "Enter Rate",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "uom",
      isMandatory: false,
      disableTrue: true,
    },
    {
      label: "Conversion Value",
      controlType: "textfield",
      placeHolder: "Enter Conversion Value",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "uomConversionValue",
      isMandatory: false,
      disableTrue: true,
    },
  ],
};
