import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  zone: [],
  area: [],
  zoneNew: [],
  zoneByUserType: [],
  zoneByState: [],
  zoneByRegionId: [],
};
let URL = endpoints.zone;
const zoneSlice = createSlice({
  name: "zone",
  initialState,
  reducers: {
    zoneSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        zone: row,
      };
    },

    zoneNewSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        zoneNew: row,
      };
    },

    areaSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        area: row,
      };
    },

    zoneUserTypeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        zoneByUserType: row,
      };
    },

    zoneByStateSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        zoneByState: row,
      };
    },

    zoneByRegionIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        zoneByRegionId: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  zoneSuccess,
  zoneNewSuccess,
  areaSuccess,
  zoneUserTypeSuccess,
  zoneByStateSuccess,
  zoneByRegionIdSuccess,
  resetState,
} = zoneSlice.actions;

export default zoneSlice.reducer;

export const getZone = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"sortOrder": "ASC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((zoneObject, index) => {
          let zoneData = {
            index: index + 1,
            id: zoneObject.id === null ? "" : zoneObject.id,
            name: zoneObject.name === null ? "" : zoneObject.name,
            zoneName: zoneObject.name + " ( " + zoneObject.shortName + " )",
            shortName:
              zoneObject.shortName === null ? "" : zoneObject.shortName,
            state:
              zoneObject.state !== null && zoneObject.state.id
                ? zoneObject.state.id
                : "",
            stateName:
              zoneObject.state !== null && zoneObject.state.name
                ? zoneObject.state.name
                : "",
            districtCount:
              zoneObject.districtCount === null ? "" : zoneObject.districtCount,
            talukaCount:
              zoneObject.talukaCount === null ? "" : zoneObject.talukaCount,
            areaCount:
              zoneObject.areaCount === null ? "" : zoneObject.areaCount,
            beatCount:
              zoneObject.beatCount === null ? "" : zoneObject.beatCount,
            outletCount:
              zoneObject.outletCount === null ? "" : zoneObject.outletCount,
            sortOrder:
              zoneObject.sortOrder === null ? "" : zoneObject.sortOrder,
          };
          return zoneData;
        });
        dispatch(zoneSuccess({ row }));
        dispatch(zoneNewSuccess({ row: content }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};


export const getZoneNew = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-all",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((zoneObject, index) => {
          let zoneData = {
            index: index + 1,
            id: zoneObject.id === null ? "" : zoneObject.id,
            name: zoneObject.name === null ? "" : zoneObject.name,
            zoneName: zoneObject.name + " ( " + zoneObject.shortName + " )",
            shortName:
              zoneObject.shortName === null ? "" : zoneObject.shortName,
            state:
              zoneObject.stateId !== null
                ? zoneObject.stateId
                : "",
            stateName:
              zoneObject.stateName !== null
                ? zoneObject.stateName
                : "",
            districtCount:
              zoneObject.districtCount === null ? "" : zoneObject.districtCount,
            talukaCount:
              zoneObject.talukaCount === null ? "" : zoneObject.talukaCount,
            areaCount:
              zoneObject.areaCount === null ? "" : zoneObject.areaCount,
            beatCount:
              zoneObject.beatCount === null ? "" : zoneObject.beatCount,
            outletCount:
              zoneObject.outletCount === null ? "" : zoneObject.outletCount,
            sortOrder:
              zoneObject.sortOrder === null ? "" : zoneObject.sortOrder,
          };
          return zoneData;
        });
        dispatch(zoneSuccess({ row }));
        dispatch(zoneNewSuccess({ row: data.data }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const setZone =
  ({ row }) =>
    async (dispatch) => {
      dispatch(zoneNewSuccess({ row }));
    };

export const getAreaByZoneId =
  ({ zoneId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/" + zoneId + "/get-area",
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((areaObject, index) => {
              let areaData = {
                index: index + 1,
                id: areaObject.id === null ? "" : areaObject.id,
                name:
                  areaObject.name === null
                    ? ""
                    : areaObject.name + " (" + areaObject.shortName + ")",
                zoneName:
                  areaObject.zone !== null && areaObject.taluka.district.zone.name
                    ? areaObject.taluka.district.zone.name
                    : "",
                zone:
                  areaObject.zone !== null && areaObject.taluka.district.zone.id
                    ? areaObject.taluka.district.zone.id
                    : "",
                shortName:
                  areaObject.shortName === null ? "" : areaObject.shortName,
                sortOrder:
                  areaObject.sortOrder === null ? "" : areaObject.sortOrder,
              };
              return areaData;
            });
            dispatch(areaSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getZoneByUserType = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/by-user-type",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((zone, index) => {
          let zoneData = {
            index: index + 1,
            id: zone.id === null ? "" : zone.id,
            name: zone.name === null ? "" : zone.name,
            shortName: zone.shortName === null ? "" : zone.shortName,
            sortOrder: zone.sortOrder === null ? "" : zone.sortOrder,
          };
          return zoneData;
        });
        dispatch(zoneUserTypeSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getZoneByState =
  ({ stateIds }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/by-states?stateIds=" + stateIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((zoneByStateObject, index) => {
              let zoneByStateData = {
                index: index + 1,
                ...zoneByStateObject,
                id: zoneByStateObject.id === null ? "" : zoneByStateObject.id,
                name:
                  zoneByStateObject.name === null ? "" : zoneByStateObject.name,
                shortName:
                  zoneByStateObject.shortName === null
                    ? ""
                    : zoneByStateObject.shortName,
                sortOrder:
                  zoneByStateObject.sortOrder === null
                    ? ""
                    : zoneByStateObject.sortOrder,
                stateId:
                  zoneByStateObject.name === null
                    ? ""
                    : zoneByStateObject.state.id,
                state:
                  zoneByStateObject.name === null
                    ? ""
                    : zoneByStateObject.state.name,
              };
              return zoneByStateData;
            });
            dispatch(zoneByStateSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return { response: [], success: false };
      }
    };

export const getZoneByRegionId =
  ({ regionId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/list-by-region?regionId=" + regionId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((zoneByRegionId, index) => {
              let zoneByRegionData = {
                index: index + 1,
                ...zoneByRegionId,
                id: zoneByRegionId.id === null ? "" : zoneByRegionId.id,
                name: zoneByRegionId.name === null ? "" : zoneByRegionId.name,
                shortName:
                  zoneByRegionId.shortName === null
                    ? ""
                    : zoneByRegionId.shortName,
                sortOrder:
                  zoneByRegionId.sortOrder === null
                    ? ""
                    : zoneByRegionId.sortOrder,
                stateId:
                  zoneByRegionId.name === null ? "" : zoneByRegionId.state.id,
                state:
                  zoneByRegionId.name === null ? "" : zoneByRegionId.state.name,
              };
              return zoneByRegionData;
            });
            dispatch(zoneByRegionIdSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return { response: [], success: false };
      }
    };
