import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  paymentTerm: [],
};
let URL = endpoints.paymentTerm;
const paymentTermSlice = createSlice({
  name: "paymentTerm",
  initialState,
  reducers: {
    paymentTermSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        paymentTerm: row,
        isFetch: true,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { paymentTermSuccess, resetState } = paymentTermSlice.actions;

export default paymentTermSlice.reducer;

export const getPaymentTerm = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.content.map((paymentTermObject, index) => {
          let paymentTermData = {
            index: index + 1,
            id: paymentTermObject.id === null ? "" : paymentTermObject.id,
            name:
              paymentTermObject.paymentName === null
                ? ""
                : paymentTermObject.paymentName,
            paymentName:
              paymentTermObject.paymentName === null
                ? ""
                : paymentTermObject.paymentName,
            isActive:
              paymentTermObject.isActive === null
                ? ""
                : paymentTermObject.isActive,
            extra1:
              paymentTermObject.extra1 === null ? "" : paymentTermObject.extra1,
          };

          return paymentTermData;
        });
        dispatch(paymentTermSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
