import endpoint from "../../config/endpoints";

export const batchwiseOutletBillingJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.manageStockHeader,
  screenTitle: "Batchwise Outlet Billing",
  formPath: "/batchwise-outlet-billing-form",
  //formPathView: "/manage-stock-view",
  showBackToList: true,
  showTitle: false,
  showAddButton: true,

  searchList: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromdate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "todate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "todate",
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
      isMandatory: true,
      onSubmit: "compare",
    },
    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "regionDetail",
      dataKey: "region",
      getListId: "region",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "State",
      controlType: "autocomplete",
      placeHolder: "Select State",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stateDetail",
      dataKey: "state",
      getListFrom: "region",
      getListId: "state",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "autocomplete",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "zoneDetail",
      dataKey: "zone",
      getListFrom: "state",
      getListId: "zone",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "District",
      controlType: "autocomplete",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "districtDetail",
      dataKey: "district",
      getListFrom: "zone",
      getListId: "district",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Taluka",
      controlType: "autocomplete",
      placeHolder: "Select Taluka",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "talukaDetail",
      dataKey: "taluka",
      getListFrom: "district",
      getListId: "taluka",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "area",
      getListFrom: "taluka",
      getListId: "area",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Beat",
      controlType: "multiSelect",
      placeHolder: "Select Beat",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "beat",
      masterName: "beatDetail",
      getListFrom: "area",
      getListId: "beat",
      isMandatory: true,
      isRootLevelKey: true,
    },
    {
      label: "Outlet",
      controlType: "multiSelect",
      placeHolder: "Select Outlet",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "outletIds",
      masterName: "outletDetail",
      getListFrom: "beat",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
  fieldMeta: [
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "date",
      getListId: "outletId,date",
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "area",
      getListId: "area",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Beat",
      controlType: "autocomplete",
      placeHolder: "Select Beat",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "beat",
      masterName: "beatDetail",
      getListFrom: "area",
      getListId: "beat",
      isMandatory: true,
      isRootLevelKey: false,
    },
    {
      label: "Outlet",
      controlType: "autocomplete",
      placeHolder: "Select Outlet",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "outletId",
      getListFrom: "beat",
      masterName: "outletDetail",
      getListId: "outletId,date",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Discount %",
      controlType: "textfield",
      placeHolder: "Enter Percentage",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "discountOnBillPer",
      inputType: "number",
      isMandatory: false,
      valid: true,
      validName: "greater-thanHundered",
    },
  ],
  fieldMetaDetails: [
    {
      label: "Category",
      controlType: "autocomplete",
      placeHolder: "Select Category ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "categoryDetail",
      dataKey: "category",
      isRootLevelKey: true,
      isMandatory: true,
      getListFrom: "outlet",
      getListId: "category",
    },
    {
      label: "Product ",
      controlType: "autocomplete",
      placeHolder: "Select Product",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "productDetail",
      dataKey: "product",
      getListFrom: "category",
      isRootLevelKey: true,
      getListId: "product",
      isMandatory: true,
      getData: true,
    },
    {
      label: "Batch ",
      controlType: "autocomplete",
      placeHolder: "Select Batch",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "batchDetail",
      dataKey: "batch",
      getListFrom: "product",
      isRootLevelKey: true,
      isMandatory: true,
      getData: true,
    },
    {
      label: "Batch Quantity",
      controlType: "textfield",
      placeHolder: "Enter quantity",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "secondaryClosingQty",
      // valid: true,
      // validCondition: "greater-qty",
      // validName: "greater-less",
      isMandatory: false,
      spValid: "float",
      disableTrue: true,
    },
    {
      label: "Order Quantity",
      controlType: "textfield",
      placeHolder: "Enter quantity",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "orderQty",
      isMandatory: true,
      spValid: "float",
      disableTrue: true,
    },
    {
      label: "Quantity",
      controlType: "textfield",
      placeHolder: "Enter Quantity",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      calculateMinus: true,
      dataKey: "qty",
      valid: true,
      validName: "greater-less",
      zeroValid: true,
      validCondition: "less-secondaryQty",
      isMandatory: true,
      validText: "Batch Quantity",
    },
    {
      label: "Free Quantity",
      controlType: "textfield",
      placeHolder: "Enter Quantity",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      calculateMinus: true,
      dataKey: "freeQty",
      valid: true,
      validName: "greater-less",
      zeroValid: true,
      validCondition: "less-secondaryQty",
      isMandatory: false,
      validText: "Batch Quantity",
    },
    {
      label: "Item Discount %",
      controlType: "textfield",
      placeHolder: "Enter percentage",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "itemDiscPer",
      isMandatory: false,
      valid: true,
      validName: "greater-thanHundered",
    },
    {
      label: "Rate",
      controlType: "textfield",
      placeHolder: "Enter Rate",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "rate",
      isMandatory: false,
      spValid: "float",
      disableTrue: true,
    },
    {
      label: "MRP",
      controlType: "textfield",
      placeHolder: "Enter MRP",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "mrp",
      isMandatory: false,
      spValid: "float",
      disableTrue: true,
    },
    {
      label: "Outlet UOM",
      controlType: "textfield",
      placeHolder: "Enter UOM",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "uom",
      isMandatory: false,
      disableTrue: true,
    },
    {
      label: "Conversion Value",
      controlType: "textfield",
      placeHolder: "Enter Conversion Value",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "uomConversionValue",
      isMandatory: false,
      disableTrue: true,
    },
  ],
};
