import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
    vender: [],
};
let URL = endpoints.vender;
const venderSlice = createSlice({
    name: "vender",
    initialState,
    reducers: {

        venderSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                vender: row,
            };
        },

        resetState: (state = cloneDeep(initialState), action) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const {
    venderSuccess,
    resetState,
} = venderSlice.actions;

export default venderSlice.reducer;

export const getVender = () => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL,
        }).then(({ data, success }) => {
            if (success) {
                const { content } = data.data;
                const row = content.map((venderObj, index) => {
                    let areaData = {
                        index: index + 1,
                        id: venderObj.id === null ? "" : venderObj.id,
                        name:
                            venderObj.name === null
                                ? ""
                                : venderObj.name,
                        panNo:
                            venderObj.panNo === null ? "" : venderObj.panNo,
                        address:
                            venderObj.address !== null
                                ? venderObj.address
                                : "",

                        contactNumber: venderObj.contactNumber === null ? "" : venderObj.contactNumber,
                        emailId: venderObj.emailId === null ? "" : venderObj.emailId,
                        areaIds: venderObj.areaIds !== null ? venderObj.areaIds : "",
                        gstApplicable:
                            venderObj.gstApplicable === null ? "" : venderObj.gstApplicable,
                        gstApplicableYesNo:
                            venderObj.gstApplicable === null ? "" : venderObj.gstApplicable == 1 ? "Yes" : "No",
                        gstIn:
                            venderObj.gstIn === null ? "" : venderObj.gstIn,
                        isActive:
                            venderObj.isActive === null ? "" : venderObj.isActive,

                    };
                    return areaData;
                });
                dispatch(venderSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};




export const setVender =
    ({ row }) =>
        async (dispatch) => {
            dispatch(venderSuccess({ row }));
        };









