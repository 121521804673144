import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { NoOfStaffJson } from "../../../DynamicFormsJson/MastersJSON/noOfStaff";
import { getNoOfStaff } from "../../../Slice/noOfStaff.slice";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { withRouter } from "../../../components/withRouter";
import { noOfStaffColumns } from "../../../tableColumns/table-columns";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { apiPost } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import { statusmsg } from "../../../config/messageconstant";
class NoOfStaffList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicMasterData: {},
        };
    }

    onChangeStatus = (rowData) => {
        const { showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            let status = "";
            if (rowData.isActive == 0) {
                status = 1;
            } else {
                status = 0;
            }
            showLoador({ loador: true });
            apiPost({
                url: endpoint.noOfStaff + "/update-is-active?id=" + rowData.id +
                    "&status=" + status,
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (success) {
                    showNotification({ msg: statusmsg });
                    window.location.reload();
                }
            });
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }

    };


    render() {
        const { noOfStaffList, getNoOfStaff } = this.props;
        const { dynamicMasterData } = this.state;
        return (
            <>
                <DynamicMainScreen
                    dynamicMasterData={dynamicMasterData}
                    formPath={NoOfStaffJson.formPath}
                    fieldMeta={NoOfStaffJson.fieldMeta}
                    screenTitle={NoOfStaffJson.screenTitle}
                    showPdfDownload={false}
                    showExcelDownload={false}
                    showExtension={false}
                    pdfFileName={false}
                    excelFileName={NoOfStaffJson.excelFileName}
                    showAddButton={NoOfStaffJson.showAddButton}
                    tableColumnsToFilter={NoOfStaffJson.tableColumnsToFilter}
                    tableColumns={noOfStaffColumns}
                    tableData={noOfStaffList?.noOfStaff}
                    getTableData={getNoOfStaff}
                    apiBaseURL={NoOfStaffJson.apiBaseURL}
                    baseIdColumn={NoOfStaffJson.baseIdColumn}
                    showDeleteIcon={true}
                    isActiveURLFalse={true}
                    isActiveURLFalseMethod={this.onChangeStatus}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    noOfStaffList: state.noOfStaff,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getNoOfStaff,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(NoOfStaffList);
