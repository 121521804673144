import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  ladger: [],
};
let URL = endpoints.transactionOutlet;
const ladgerSlice = createSlice({
  name: "ladger",
  initialState,
  reducers: {
    ladgerSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        ladger: row,
      };
    },


    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  ladgerSuccess,
  ladgerByIdSuccess,
  ladgerPastDataSuccess,
  ladgerCurrentDataSuccess,
  ladgerFutureDataSuccess,
  resetState,
} = ladgerSlice.actions;

export default ladgerSlice.reducer;

export const getladger = ({ flag, fromDate, toDate, outletId }) => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/ledger-by-date-and-outlet?fromDate=" + fromDate + "&toDate=" + toDate + "&outletId=" + outletId,
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        let debit = 0
        let credit = 0
        let closingBalance = 0
        let row = content.map((ladgerObject, index) => {
          if (ladgerObject.flag != 1) {
            debit = debit + +ladgerObject.debitAmt
          }
          else {
            credit = credit + +ladgerObject.creditAmt
          }
          closingBalance = Math.abs(+ladgerObject.balanceAmt).toFixed(2)
          let ledger = {
            index: index + 1,
            date: ladgerObject.date,
            remark: ladgerObject.taransactionNo,
            type: ladgerObject.type,
            debitAmt: ladgerObject.flag != 1?(+ladgerObject.debitAmt).toFixed(2):ladgerObject.debitAmt,
            creditAmt: ladgerObject.flag != 1?ladgerObject.creditAmt:(+ladgerObject.creditAmt).toFixed(2),
            balanceAmt: ladgerObject.flag == 1 ? (Math.abs(+ladgerObject.balanceAmt)).toFixed(2) + "Cr" : ladgerObject.flag == 2 ? (Math.abs(+ladgerObject.balanceAmt)).toFixed(2) + "Db" : "-",
          }
          return ledger;
        });
        let ledger = {
          index: "",
          date: "",
          remark: "Total",
          type: "",
          debitAmt: debit.toFixed(2),
          creditAmt: credit.toFixed(2),
          balanceAmt: "",
        }
        row.push(ledger)
        ledger = {
          index: "",
          date: "",
          remark: "Closing Balance",
          type: "",
          debitAmt: closingBalance,
          creditAmt: "",
          balanceAmt: "",
        }

        row.push(ledger)
        dispatch(ladgerSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};


