import endpoint from "../../config/endpoints";

export const productJson = {
  showSaveNextBtn: true,
  showAddButtonInTable: true,
  formPath: "/product-form",
  formPathForsend: "/rate-config-by-product",
  formPathForOutlet: "/outlet-config-by-product",
  isEditURL: endpoint.product + "/update",
  openFormPath: "/single-mrp-configuration-by-itemList",
  apiBaseURL: endpoint.product,
  screenTitle: "Product",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Product",
  showExcelDownload: true,
  excelFileName: "Product",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Product Code",
      columnKeyName: "code",
      isChecked: true,
    },

    {
      columnDisplayName: "UOM Conversion Value",
      columnKeyName: "uomConversionValue",
      isChecked: true,
    },
    {
      columnDisplayName: "Product Shelf Life",
      columnKeyName: "selfLife",
      isChecked: true,
    },
    {
      columnDisplayName: "Product Weight",
      columnKeyName: "weight",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "Category",
      controlType: "autocomplete",
      placeHolder: "Select Category",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "categoryDetail",
      getListId: "category",
      dataKey: "category",
      isRootLevelKey: false,
      isMandatory: true,
      showAfterSaveAndNext:true
    },
    {
      label: "Sub-Category",
      controlType: "autocomplete",
      placeHolder: "Select Sub-Category",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "subCategoryDetail",
      getListFrom: "category",
      dataKey: "subCategory",
      isRootLevelKey: false,
      isMandatory: true,
      showAfterSaveAndNext:true
    },
    {
      label: "Stock Holder UOM",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder UOM",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeHolderUomDetail",
      dataKey: "stakeHolderUom",
      isRootLevelKey: false,
      isMandatory: true,
      showAfterSaveAndNext:true
    },
    {
      label: "Outlet UOM",
      controlType: "autocomplete",
      placeHolder: "Select Outlet UOM",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeHolderUomDetail",
      dataKey: "outletUom",
      isRootLevelKey: false,
      isMandatory: true,
      showAfterSaveAndNext:true
    },
    {
      label: "Tax",
      controlType: "autocomplete",
      placeHolder: "Select Tax",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "taxDetail",
      dataKey: "taxPercentage",
      isRootLevelKey: false,
      isMandatory: true,
      showAfterSaveAndNext:true
    },
    {
      label: "Product Code",
      controlType: "textfield",
      placeHolder: "Product Code",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "code",
      isMandatory: true,
    },
   
    {
      label: "Product Name",
      controlType: "textfield",
      placeHolder: "Product Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "name",
      isMandatory: true,
      isImageName: true,
      uniqueValidation: true,
    },
    {
      label: "Product Short Name",
      controlType: "textfield",
      placeHolder: "Enter Product Short Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "shortName",
      isMandatory: true,
      maxLength:10
    },
    {
      label: "Product Description",
      controlType: "textfield",
      placeHolder: "Product Description",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "description",
      isMandatory: true,
    },
    {
      label: "Sort Order Number",
      controlType: "textfield",
      placeHolder: "Sort Order Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sortOrder",
      isMandatory: true,
    },
    {
      label: "Product MRP",
      controlType: "textfield",
      placeHolder: "Product MRP",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      disable: true,
      dataKey: "mrp",
      isMandatory: true,
    },
   
   
    {
      label: "UOM Conversion Value",
      controlType: "textfield",
      placeHolder: "UOM Conversion Value",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "uomConversionValue",
      valid: true,
      validName: "zeroValue",
      isMandatory: true,
    },
    {
      label: "Product Shelf Life (Month)",
      controlType: "textfield",
      placeHolder: "Product Shelf Life",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "selfLife",
      isMandatory: true,
      inputType: "number",
    },
    {
      label: "Product Weight(In Gram)",
      controlType: "textfield",
      placeHolder: "Enter Product weight",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "weight",
      inputType: "number",
      isMandatory: true,
    },
    {
      label: "Select Image",
      controlType: "selectImage",
      placeHolder: "Select Image",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKeyToSave: "image",
      dataKey: "productImageShow",
      isMandatory: true,
    },
  ],
  
};
