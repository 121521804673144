import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  outletRateStructure: [],
  categoryForOutlet: [],
  rateConfigForOutlet: [],
  rateConfigForProductOutlet: [],
};
let URL = endpoints.outletRateStructure;
const outletRateStructureSlice = createSlice({
  name: "outletRateStructure",
  initialState,
  reducers: {
    outletRateStructureSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        outletRateStructure: row,
      };
    },

    categoryForOutletSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        categoryForOutlet: row,
      };
    },
    rateConfigForOutletSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        rateConfigForOutlet: row,
      };
    },
    productRateConfigurationOutletByProductIdSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        rateConfigForProductOutlet: row,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  outletRateStructureSuccess,
  categoryForOutletSuccess,
  rateConfigForOutletSuccess,
  productRateConfigurationOutletByProductIdSuccess,
  resetState,
} = outletRateStructureSlice.actions;

export default outletRateStructureSlice.reducer;

export const getOutletConfigurationList = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.content.map(
          (rateConfigForOutletListObject, index) => {
            let rateConfigForOutletData = {
              index: index + 1,
              id:
                rateConfigForOutletListObject.id === null
                  ? ""
                  : rateConfigForOutletListObject.id,
              name:
                rateConfigForOutletListObject.name === null
                  ? ""
                  : rateConfigForOutletListObject.name,
              categoryName:
                rateConfigForOutletListObject.category === null &&
                rateConfigForOutletListObject.category.name === null
                  ? ""
                  : rateConfigForOutletListObject.category.name,
            };
            return rateConfigForOutletData;
          }
        );
        dispatch(rateConfigForOutletSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getCategoryForOutlet = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-category-list",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((categoryForOutletObject, index) => {
          let categoryForOutletData = {
            index: index + 1,
            id:
              categoryForOutletObject.id === null
                ? ""
                : categoryForOutletObject.id,
            name:
              categoryForOutletObject.name === null
                ? ""
                : categoryForOutletObject.name,
            categoryName:
              categoryForOutletObject.name +
              " ( " +
              categoryForOutletObject.shortName +
              " )",
            shortName:
              categoryForOutletObject.shortName === null
                ? ""
                : categoryForOutletObject.shortName,
            sortOrder:
              categoryForOutletObject.sortOrder === null
                ? ""
                : categoryForOutletObject.sortOrder,
            isActive:
              categoryForOutletObject.isActive === null
                ? ""
                : categoryForOutletObject.isActive,
          };
          return categoryForOutletData;
        });
        dispatch(categoryForOutletSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getCategoryForRateConfig =
  ({ categoryIds }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-categories?categoryIds=" + categoryIds,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((rateConfigForOutletObject, index) => {
            let rateConfigForOutletData = {
              index: index + 1,
              id:
                rateConfigForOutletObject.id === null
                  ? ""
                  : rateConfigForOutletObject.id,
              name:
                rateConfigForOutletObject.name === null
                  ? ""
                  : rateConfigForOutletObject.name,
              categoryName:
                rateConfigForOutletObject.category === null &&
                rateConfigForOutletObject.category.name === null
                  ? ""
                  : rateConfigForOutletObject.category.name,
            };
            return rateConfigForOutletData;
          });
          dispatch(rateConfigForOutletSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getProductRateConfigurationOutletByProductId =
  ({ productId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-product?productId=" + productId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map(
            (productRateConfigurationOutletObject, index) => {
              let rateConfigurationOutletData = {
                index: index + 1,
                id:
                  productRateConfigurationOutletObject.id === null
                    ? ""
                    : productRateConfigurationOutletObject.id,
                name:
                  productRateConfigurationOutletObject.name === null
                    ? ""
                    : productRateConfigurationOutletObject.name,
                category:
                  productRateConfigurationOutletObject.category != null &&
                  productRateConfigurationOutletObject.category.id != null
                    ? productRateConfigurationOutletObject.category.id
                    : "",
                categoryName:
                  productRateConfigurationOutletObject.category != null &&
                  productRateConfigurationOutletObject.category.name != null
                    ? productRateConfigurationOutletObject.category.name
                    : "",

                marginPercentage: 0,
              };
              return rateConfigurationOutletData;
            }
          );
          dispatch(productRateConfigurationOutletByProductIdSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getRateConfigurationOutletList =
  ({ row }) =>
  async (dispatch) => {
    dispatch(productRateConfigurationOutletByProductIdSuccess({ row }));
  };
