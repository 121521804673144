import React from "react";
import { connect } from "react-redux";
import MaterialUITableWithTextField from "../../components/Comman/RT/MaterialUITableWithTextField";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { Paper,Grid } from "@mui/material";
import { ButtonCompo } from "../../components/Comman/Button";

import { setMRPConfList } from "../../Slice/mrpConfigration.slice";

import { MRPConfigrationJson } from "../../DynamicFormsJson/Transaction/MRPConfigration";

import {
  showLoador,
  showNotification,
} from "../Landing/Landing.slice";

class SelectProductDocument extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [
        {
          xs: 12,
          sm: 4,
          md: 4,
          lg: 4,
          label: "Product name",
          value: "Maggi"
        },
        {
          xs: 12,
          sm: 4,
          md: 4,
          lg: 4,
          label: "Product Value",
          value: "120000"
        },
        {
          xs: 12,
          sm: 4,
          md: 4,
          lg: 4,
          label: "Product name",
          value: "Maggi"
        },
        {
          xs: 12,
          sm: 4,
          md: 4,
          lg: 4,
         label: "Product Value",
          value: "120000"
        }
      ]
    };
  }
  

  componentDidMount() {
    const { setMRPConfList } = this.props;

    setMRPConfList({
      row: [{
        index: 1,
        id: 1,
        name: "doc",
     
        productImage: "",
        showView:true,
      }, {
        index: 2,
        id: 2,
        name: "doc2",
        productImage: "sht",
        showView:false,
      }]
    })

  }
  onSave = () => {

  }
  onSearch=(searchData)=>{

  }
  render() {
    const { DataToSet } = this.state;
    const { setMRPConfList, mrpConfigration } = this.props
    return (
      <>

        <DynamicFormWithoutSave
        showBackToList={true}
          showSaveBtn={false}
          showTitle={MRPConfigrationJson.showTitle}
          screenTitle={MRPConfigrationJson.screenTitle}
          fieldMeta={[]}
          showCancel={MRPConfigrationJson.showCancel}
          apiBaseURL={MRPConfigrationJson.apiBaseURL}
          showSaveNextBtn={MRPConfigrationJson.showSaveNextBtn}
       
          onSave={this.onSearch}
        />
        <Paper sx={{
          paddingRight: 2,
          paddingLeft: 2,
        }}
          elevation={0}>
          <MaterialUITableWithTextField tableHead={

            [
              {
                title: "Sr. No.",
                name: "index",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Document Name",
                name: "name",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
         
              {
                title: "Product Image",
                name: "productImage",
                nameToSave:'productImageToSave',
               
                selectCompo: true,
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
                placeHolder:'Enter Remark'
              },
              {
                title: "Action",
                name: "showView",
                openConditionAction: "action",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
              },
       
            ]
          } data={mrpConfigration.mrpConfigration}showView={true} showSelectCompo={true}setList={setMRPConfList} isActionColActive={false} isConditionalActions={true} />
          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
            container
            justifyContent="right"
          >
            <ButtonCompo
              size="medium"
              type="submit"
              variant="contained"
              name="Submit"
              onClick={this.onSave}
            />
          </Grid>

        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  mrpConfigration: state.mrpConfigration,
  roomList: state.room,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  setMRPConfList
};
export default connect(mapStateToProps, mapDispatchToProps)(SelectProductDocument);
