import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { publishRateConfigurationOutletJson } from "../../DynamicFormsJson/Transaction/PublishRateConfigurationOutlet";
import { getCategory } from "../../Slice/category.slice";
import {
  getpublishRateConfigOutletCurrent,
  getpublishRateConfigOutletFuture,
  getpublishRateConfigOutletSearch,
} from "../../Slice/publishRateConfigOultlet.slice";
import DynamicTabwiseTable from "../../components/dynamicscreens/DynamicTabwiseTable";
import { withRouter } from "../../components/withRouter";
import {
  serverMsg,
  deletemsg,
  noInternetMsg,
  deleteFailedMsg,
} from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { apiDelete } from "../../utils/api_service";
import endpoint from "../../config/endpoints";

class PublishRateConfigurationOutletTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryId: "",
      dynamicMasterData: {
        currentList:
          this.props.publishRateConfigOutletList
            ?.publishRateConfigOutletCurrent,
        futureList:
          this.props.publishRateConfigOutletList?.publishRateConfigOutletFuture,
        priviousList:
          this.props.publishRateConfigOutletList
            ?.publishRateConfigOutletPrevious,
      },
      dynamicMasterDataToSearch: {
        categoryDetail: this.props.categoryList?.category,
      },

      tabList: [
        {
          label: "Current Configuration",
          allyProps: 0,
          columns: [
            {
              name: "index",
              title: "Sr. No.",
              width: "10%",
              alignCenter: "center",
              showInscreen: true,
            },
            {
              title: "Rate Configuration",
              name: "name",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Publish Date",
              name: "publishDate",
              alignCenter: "center",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Day's Before",
              name: "days",
              alignCenter: "center",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Action",
              name: "action",
              alignCenter: "center",
              showInscreen: true,
            },
          ],
          isActionColActive: true,
          value: "currentList",
        },
        {
          label: "Future Configuration",
          allyProps: 1,
          columns: [
            {
              name: "index",
              title: "Sr. No.",
              width: "10%",
              alignCenter: "center",
              showInscreen: true,
            },
            {
              title: "Rate Configuration",
              name: "name",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Publish Date",
              name: "publishDate",
              alignCenter: "center",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Day's To Go",
              name: "days",
              alignCenter: "center",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Action",
              name: "action",
              alignCenter: "center",
              showInscreen: true,
            },
          ],
          isActionColActive: true,
          value: "futureList",
        },
        {
          label: "Previous Configuration",
          allyProps: 2,

          columns: [
            {
              name: "index",
              title: "Sr. No.",
              width: "10%",
              alignCenter: "center",
              showInscreen: true,
            },
            {
              title: "Rate Configuration",
              name: "name",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Published Date",
              name: "publishDate",
              alignCenter: "center",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Action",
              name: "action",
              alignCenter: "center",
              showInscreen: true,
            },
          ],
          isActionColActive: true,
          value: "priviousList",
          fieldMeta: publishRateConfigurationOutletJson.fieldMetaForSearch,
        },
      ],
    };
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.publishRateConfigOutletList !==
      nextProps.publishRateConfigOutletList
    ) {
      if (
        this.props.publishRateConfigOutletList
          ?.publishRateConfigOutletCurrent !==
        nextProps.publishRateConfigOutletList?.publishRateConfigOutletCurrent
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            currentList:
              nextProps.publishRateConfigOutletList
                ?.publishRateConfigOutletCurrent,
          },
        });
      }
    }

    if (
      this.props.publishRateConfigOutletList !==
      nextProps.publishRateConfigOutletList
    ) {
      if (
        this.props.publishRateConfigOutletList
          ?.publishRateConfigOutletFuture !==
        nextProps.publishRateConfigOutletList?.publishRateConfigOutletFuture
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            futureList:
              nextProps.publishRateConfigOutletList
                ?.publishRateConfigOutletFuture,
          },
        });
      }
    }
    if (this.props.categoryList !== nextProps.categoryList) {
      if (
        this.props.categoryList?.category !== nextProps.categoryList?.category
      ) {
        this.setState({
          dynamicMasterDataToSearch: {
            ...this.state.dynamicMasterDataToSearch,
            categoryDetail: nextProps.categoryList?.category,
          },
        });
      }
    }
    if (
      this.props.publishRateConfigOutletList !==
      nextProps.publishRateConfigOutletList
    ) {
      if (
        this.props.publishRateConfigOutletList
          ?.publishRateConfigOutletPrevious !==
        nextProps.publishRateConfigOutletList?.publishRateConfigOutletPrevious
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            priviousList:
              nextProps.publishRateConfigOutletList
                ?.publishRateConfigOutletPrevious,
          },
        });
      }
    }
  }
  getData = async () => {
    const {
      getpublishRateConfigOutletCurrent,
      getpublishRateConfigOutletFuture,
      getCategory,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getpublishRateConfigOutletCurrent().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      await getpublishRateConfigOutletFuture().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getCategory().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  async componentDidMount() {
    this.getData();
  }
  rowEdit = (rowData) => {
    this.props.navigate(publishRateConfigurationOutletJson.formPath);
  };
  onSave = (rowData) => {
    if (rowData.category) {
      this.setState({
        categoryId: rowData.category,
      });
      this.getListByCategoryId(rowData.category);
    }
  };
  getListByCategoryId = async (categoryIds) => {
    const { getpublishRateConfigOutletSearch, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getpublishRateConfigOutletSearch({
        categoryIds: categoryIds,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  rowDelete = (rowData) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiDelete({
        url: endpoint.productRateConfOutlet + "/" + rowData.id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: deletemsg });
          this.getData();
          if (this.state.categoryId != "") {
            this.getListByCategoryId(this.state.categoryId);
          }
        } else {
          showNotification({ msg: deleteFailedMsg });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onAddButtonClick = () => {
    this.props.navigate(publishRateConfigurationOutletJson.formPath);
  };
  rowViewData = (row) => {
    this.props.navigate("/publish-rate-configuration-outlet-view/" + row.id);
  };
  render() {
    const { tabList, dynamicMasterData, dynamicMasterDataToSearch } =
      this.state;

    return (
      <>
        <DynamicTabwiseTable
          dynamicMasterData={dynamicMasterDataToSearch}
          rowList={dynamicMasterData}
          rowEdit={this.rowEdit}
          showHeadEdit={false}
          formPath={publishRateConfigurationOutletJson.formPath}
          screenTitle={publishRateConfigurationOutletJson.screenTitle}
          rowDelete={this.rowDelete}
          showHeadDelete={true}
          showPdfDownload={publishRateConfigurationOutletJson.showPdfDownload}
          showExcelDownload={
            publishRateConfigurationOutletJson.showExcelDownload
          }
          pdfFileName={publishRateConfigurationOutletJson.pdfFileName}
          excelFileName={publishRateConfigurationOutletJson.excelFileName}
          showAddButton={publishRateConfigurationOutletJson.showAddButton}
          tableColumnsToFilter={
            publishRateConfigurationOutletJson.tableColumnsToFilter
          }
          onSave={this.onSave}
          apiBaseURL={publishRateConfigurationOutletJson.apiBaseURL}
          baseIdColumn={publishRateConfigurationOutletJson.baseIdColumn}
          tabList={tabList}
          onAddButtonClick={this.onAddButtonClick}
          showView={true}
          formPathView={publishRateConfigurationOutletJson.formPathView}
          rowViewData={this.rowViewData}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  categoryList: state.category,
  publishRateConfigOutletList: state.productRateConfOutlet,
});
const mapDispatchToProps = {
  getpublishRateConfigOutletCurrent,
  getpublishRateConfigOutletFuture,
  getCategory,
  getpublishRateConfigOutletSearch,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PublishRateConfigurationOutletTable);
