import React from "react";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../../components/dynamicscreens/DynamicFormWithoutSave";
import { Paper, Grid } from "@mui/material";
import MaterialUITableWithTextField from "../../../components/Comman/RT/MaterialUITableWithTextField";
import { ButtonCompo } from "../../../components/Comman/Button";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../../components/withRouter";
import endpoint from "../../../config/endpoints";
import swal from "sweetalert";

import { addOutletRateConfigurationJson } from "../../../DynamicFormsJson/Transaction/addOutletRateConfiguration";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import {
  getProductRateConfigurationOutletByProductId,
  getRateConfigurationOutletList,
} from "../../../Slice/outletRateStructure.slice";
import {
  serverMsg,
  savemsg,
  saveFailedMsg,
  noInternetMsg,
  saveWarningMsg,
} from "../../../config/messageconstant";
import { apiGet, apiPost } from "../../../utils/api_service";

class AddRateConfigurationOutletForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      DataToSet: [],
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.productRateOutletList !== nextProps.productRateOutletList) {
      if (
        this.props.productRateOutletList?.rateConfigForProductOutlet !==
        nextProps.productRateOutletList?.rateConfigForProductOutlet
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            productOutletRateDetail:
              nextProps.productRateOutletList?.rateConfigForProductOutlet,
          },
        });
      }
    }
  }
  async componentDidMount() {
    const {
      getProductRateConfigurationOutletByProductId,
      getRateConfigurationOutletList,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      await this.getByProductId();
      showLoador({ loador: true });
      await getRateConfigurationOutletList({ row: [] });

      showLoador({ loador: true });
      await getProductRateConfigurationOutletByProductId({
        productId: this.props.params.id,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getByProductId = async () => {
   const{ showLoador,
    showNotification,
  } = this.props;
    if (this.props.params.id) {
      if (navigator.onLine) {
        const { showLoador } = this.props;
        showLoador({ loador: true });
        await apiGet({
          url: endpoint.product + "/" + this.props.params.id,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            if (!data.error) {
              this.setState({
                DataToSet: [
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    label: "Product Name",
                    value:
                      data.data.name !== null && data.data.shortName !== null
                        ? data.data.name + " ( " + data.data.shortName + " ) "
                        : data.data.name !== null
                        ? data.data.name
                        : data.data.shortName !== null
                        ? data.data.shortName
                        : "",
                  },
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    label: "Product Weight",
                    value: data.data.weight !== null ? data.data.weight : "",
                  },
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    label: "Product Shelf Life",
                    value:
                      data.data.selfLife !== null ? data.data.selfLife : "",
                  },
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    label: "Stock Holder UOM",
                    value:
                      data.data.stakeHolderUom !== null &&
                      data.data.stakeHolderUom.name !== null
                        ? data.data.stakeHolderUom.name
                        : "",
                  },
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    label: "Outlet UOM",
                    value:
                      data.data.outletUom !== null &&
                      data.data.outletUom.name !== null
                        ? data.data.outletUom.name
                        : "",
                  },
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    label: "MRP",
                    value: data.data.mrp !== null ? data.data.mrp : "",
                  },
                ],
              });
            }
          } else {
            showNotification({ msg: serverMsg, severity: "error" });
          }

          return success;
        });
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };

  onSubmit = () => {
    const { productRateOutletList } = this.props;
    let filterMrpConfigList = [];
    productRateOutletList.rateConfigForProductOutlet.map(
      (productListObject) => {
        const productListObjectToSave = {
          productRateConfigurationOutletId: productListObject.id,
          product: {
            id: this.props.params.id,
          },
          marginPercentage: productListObject.marginPercentage,
        };
        filterMrpConfigList.push(productListObjectToSave);
      }
    );
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onSave(filterMrpConfigList);
      }
    });
  };
  onSave = (filterMrpConfigList) => {
    const{ showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.productRateConfigurationOutletDetails,
        postBody: filterMrpConfigList,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg });
          window.location.replace("/product");
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const { DataToSet } = this.state;
    const { getRateConfigurationOutletList, productRateOutletList } =
      this.props;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            showSaveBtnMain={false}
            formPath={addOutletRateConfigurationJson.formPath}
            DataToSet={DataToSet}
            showTitle={addOutletRateConfigurationJson.showTitle}
            screenTitle={addOutletRateConfigurationJson.screenTitle}
            fieldMeta={addOutletRateConfigurationJson.fieldMeta}
            apiBaseURL={addOutletRateConfigurationJson.apiBaseURL}
          />

          <MaterialUITableWithTextField
            tableHead={[
              {
                title: "Sr. No.",
                name: "index",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Configuration Name",
                name: "name",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },

              {
                title: "Category",
                name: "categoryName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "Margin Percentage",
                name: "marginPercentage",
                textFieldError: "changedValueErr",
                textField: true,
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
                placeHolder: "Enter Margin Percentage",
              },
            ]}
            data={productRateOutletList?.rateConfigForProductOutlet}
            setList={getRateConfigurationOutletList}
            isActionColActive={false}
          />
          {productRateOutletList?.rateConfigForProductOutlet.length != 0 && (
            <>
              <br />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Submit"
                  onClick={this.onSubmit}
                />
              </Grid>
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  productRateOutletList: state.outletRateStructure,
});
const mapDispatchToProps = {
  getProductRateConfigurationOutletByProductId,
  getRateConfigurationOutletList,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AddRateConfigurationOutletForm);
