import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg } from "../../config/messageconstant";
import { orderHistoryEditJSON } from "../../DynamicFormsJson/MastersJSON/orderHistoryEdit";
import { getBillHeaderById } from "../../Slice/billingHistory.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
class BillingHistoryView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
      billingHistory: [],
    };
    this.columns = [
      {
        title: "Sr.No.",
        name: "index",
        align: "center",
      },
      {
        title: "Product Name",
        name: "name",
      },
      {
        title: "UOM",
        name: "outletUom",
        align: "center",
      },
      {
        title: "Rate",
        name: "rate",
        align: "right",
      },
      {
        title: "Order Quantity",
        name: "orderQty",
        align: "right",
      },
      {
        title: "Bill Quantity",
        name: "billQty",
        align: "right",
      },
      {
        title: "Free Quantity",
        name: "freeQty",
        align: "right",
      },
      {
        title: "Discount % On Item",
        name: "itemDiscPer",
        align: "right",
      },
      {
        title: "Total Amount",
        name: "total",
        align: "right",
      },
    ];
  }

  async componentDidMount() {
    const { getBillHeaderById, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      if (this.props.params.id) {
        showLoador({ loador: true });
        await getBillHeaderById({
          billHeaderId: this.props.params.id,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
          } else {
            this.setState({
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Outlet Name",
                  value:
                    response.outLet !== null &&
                      response.outLet.firmName !== null
                      ? response.outLet.firmName
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Owner Name",
                  value:
                    response.outLet !== null &&
                      response.outLet.ownerName !== null
                      ? response.outLet.ownerName
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Bill Number",
                  value:
                    response.billNo !== null && response.billNo !== null
                      ? response.billNo
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Bill Date",
                  value:
                    response.billDate !== null && response.billDate !== null
                      ? response.billDate
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Taxable Amount",
                  value:
                    response.taxableAmt !== null && response.taxableAmt !== null
                      ? response.taxableAmt
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Total Amount",
                  value:
                    response.totalAmt !== null && response.totalAmt !== null
                      ? response.totalAmt
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Total Tax",
                  value:
                    response.cgstAmt !== null && response.sgstAmt !== null
                      ? (+response.cgstAmt + +response.sgstAmt).toFixed(2)
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Discount % On Bill",
                  value:
                    response.discountOnBillPer !== null &&
                      response.discountOnBillPer !== null
                      ? response.discountOnBillPer
                      : "",
                },
              ],
              billingHistory:
                response.outletBillDetails.length !== 0
                  ? response.outletBillDetails
                  : [],
            });
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  render() {
    const { dynamicMasterData, billingHistory, DataToSet } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            DataToSet={DataToSet}
            dynamicMasterData={dynamicMasterData}
            showSaveBtn={orderHistoryEditJSON.showSaveBtn}
            showTitle={true}
            screenTitle={"Billing History View"}
            fieldMeta={[]}
            showCancel={orderHistoryEditJSON.showCancel}
            apiBaseURL={orderHistoryEditJSON.apiBaseURL}
            showSaveNextBtn={orderHistoryEditJSON.showSaveNextBtn}
            saveBtnText={"Search"}
            showSaveBtnMain={false}
          />

          <RTTable
            columns={this.columns}
            tableData={billingHistory}
            isActionColActive={false}
            showPegination={false}
          />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getBillHeaderById,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BillingHistoryView);
