import { Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import endpoint from "../../config/endpoints";
import {
  checkedListEmptyMsg,
  noInternetMsg,
  outletInactiveMsg,
  savemsg,
  serverMsg,
} from "../../config/messageconstant";
import { InactiveoutletJson } from "../../DynamicFormsJson/Transaction/inactiveOutlet";
import {
  getAreaByTalukaPostBody,
  getAreaByUserType,
} from "../../Slice/area.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import {
  getRequestForInactiveOutletPost,
  setRequestedForInactive,
} from "../../Slice/outlet.slice";
import { getRegionNew } from "../../Slice/region.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import InactiveOutletTable from "./InactiveOutletTable";

class InactiveOutletForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      fieldDataForModule: {},
      dynamicMasterData: {
        regionDetail: this.props.regionList?.region,
        stateDetail: this.props.stateList?.stateByRegion,
        zoneDetail: this.props.zoneList?.zoneByState,
        districtDetail: this.props.districtList?.districtByZone,
        talukaDetail: this.props.talukaList?.talukaByDistrict,
        areaDetail: this.props.areaList?.areaByTaluka,
        areaUserTypeDetail: this.props.areaList?.area,
      },
      showTableList: false,
      selection: [],
      adminFlag: false,
      searchValue: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.area !== nextProps.areaList?.area) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaUserTypeDetail: nextProps.areaList?.area,
          },
        });
      }
    }
  }

  async componentDidMount() {
    const {
      getAreaByUserType,
      getRequestForInactiveOutletPost,
      getRegionNew,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {

      const userTypeId = localStorage.getItem("userTypeId");
      if (userTypeId == 1 || userTypeId == 6) {
        this.setState({
          adminFlag: true,
        });

        showLoador({ loador: true });
        await getRegionNew().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });

      }

      const moduleObjData = localStorage.getItem("moduleObj");

      this.setState({
        fieldDataForModule:
          moduleObjData != null ? JSON.parse(moduleObjData) : {},
      });



      if (userTypeId != 1 && userTypeId != 6) {
        showLoador({ loador: true });
        await getAreaByUserType().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }

      let inactiveOutletDataPost = {
        areaIds: [],
      };
      showLoador({ loador: true });
      await getRequestForInactiveOutletPost({
        inactiveOutletData: inactiveOutletDataPost,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  }

  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTalukaPostBody,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (data.region && data.region != "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state && data.state != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.zone && data.zone != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.district && data.district != "") {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka && data.taluka != "") {
        let talukaIdsByPost = {
          talukaIds: data.taluka,
        };
        showLoador({ loador: true });
        await getAreaByTalukaPostBody({ talukaIds: talukaIdsByPost }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSearchList = (data) => {
    const { getRequestForInactiveOutletPost, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      this.setState({
        formData: data,
      });
      const areaListPost = data.areaIds.split(",");
      let inactiveOutletDataPost = {
        areaIds: areaListPost,
      };
      showLoador({ loador: true });
      getRequestForInactiveOutletPost({
        inactiveOutletData: inactiveOutletDataPost,
      }).then(({ success }) => {
        if (!success) {
          showLoador({ loador: false });
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          showLoador({ loador: false });
          this.setState({ showTableList: true, areaIds: [data.areaIds] });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  getSelectedList = (e) => {
    this.setState({
      selection: e,
    });
    const rowList = this.props.inactiveOutletList.outletInactive.map(
      (rowData) => {
        const filterData = e.filter((id) => rowData.id == id);
        return { ...rowData, checked: filterData.length != 0 ? true : false };
      }
    );
    this.props.setRequestedForInactive({ row: rowList });
  };

  onApprove = () => {
    const {
      showNotification,
      showLoador,
      getRequestForInactiveOutletPost,
      inactiveOutletList,
    } = this.props;
    let newInactiveList = [];
    let error = false;
    let remarkField = false;

    let list = inactiveOutletList.outletInactive.map((paymentOutletObject) => {
      if (paymentOutletObject.checked) {

        let outletInactiveObj = {
          id: paymentOutletObject.id,
          remark: paymentOutletObject.remark,
        };
        if (paymentOutletObject.remark && paymentOutletObject.remark != "") {
          newInactiveList.push(outletInactiveObj);
        } else {
          remarkField = true
          error = true
        }
      }
      return paymentOutletObject
    });

    this.props.setRequestedForInactive({ row: list });

    if (
      newInactiveList.length ===
      0 && !error
    ) {
      showNotification({
        msg: checkedListEmptyMsg,
        severity: "error",
      });
    } else {
      if (!remarkField) {
        swal({
          title: "Are you sure?",
          text: outletInactiveMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            if (navigator.onLine) {
              showLoador({ loador: true });
              apiPost({
                url: endpoint.outlet + "/in-active-by-ids",
                postBody: newInactiveList,
              }).then(({ success }) => {
                if (success) {
                  showLoador({ loador: false });
                  showNotification({
                    msg: savemsg,
                  });
                  let inactiveOutletDataPost = {
                    areaIds: [],
                  };
                  showLoador({ loador: true });
                  getRequestForInactiveOutletPost({
                    inactiveOutletData: inactiveOutletDataPost,
                  }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                      showNotification({ msg: serverMsg, severity: "error" });
                    } else {
                      this.setState({
                        showTableList: true,
                      });
                    }
                  });
                } else {
                  showNotification({
                    msg: serverMsg,
                    severity: "error",
                  });
                  showLoador({ loador: false });
                }
              });
            } else {
              showNotification({ msg: noInternetMsg, severity: "error" });
            }
          }
        });
      }
    }
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };
  getFilteredTableData = (tableData) => {
    const { searchValue } = this.state;
    return tableData.filter((currentRow) => {
      let isValid = false;
      let outletName = currentRow.outlet != null ? currentRow.outlet : "-";
      let beatName = currentRow.beatName != null ? currentRow.beatName : "-";
      let areaName = currentRow.areaName != null ? currentRow.areaName : "-";
      if (
        outletName &&
        outletName.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        beatName &&
        beatName.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        areaName &&
        areaName.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  render() {
    const { dynamicMasterData, adminFlag, fieldDataForModule } = this.state;
    const { inactiveOutletList, setRequestedForInactive } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            dynamicMasterData={dynamicMasterData}
            getListById={this.getListById}
            showTitle={InactiveoutletJson.showTitle}
            showSaveBtn={true}
            showSaveNextBtn={false}
            screenTitle={InactiveoutletJson.screenTitle}
            fieldMeta={
              adminFlag
                ? InactiveoutletJson.fieldMeta
                : InactiveoutletJson.fieldMetaForStockHolder
            }
            saveBtnText={"Search"}
            onSave={this.onSearchList}
            showSaveBtnMain={false}
            padding={true}
            paddingTop={true}
          />

          <>
            <LandingScreenHeader
              screenTitle={""}
              showSearchBox={true}
              showTable={true}
              showFilter={false}
              showPdfDownload={false}
              showExcelDownload={false}
              onSearch={this.onSearch}
            />
            <InactiveOutletTable
              tableHead={[
                {
                  title: "Sr. No.",
                  name: "index",
                  positionCenter: true,
                  align: "center",
                  showInscreen: true,
                },
                {
                  title: "Outlet",
                  name: "outlet",
                  positionCenter: false,
                  canSearch: true,
                  align: "left",
                  showInscreen: true,
                },
                {
                  title: "Beat",
                  name: "beatName",
                  positionCenter: false,
                  canSearch: true,
                  align: "left",
                  showInscreen: true,
                },
                {
                  title: "Area",
                  name: "areaName",
                  positionCenter: false,
                  canSearch: true,
                  align: "left",
                  showInscreen: true,
                },
                {
                  title: "Remark",
                  name: "remark",
                  placeHolder: "Enter Remark",
                  textFieldError: "remarkErr",
                  positionCenter: false,
                  showInscreen: true,
                },
              ]}
              data={inactiveOutletList.outletInactive}
              rowList={inactiveOutletList.outletInactive}
              setList={setRequestedForInactive}
              selection={[]}
              rows={this.getFilteredTableData(
                inactiveOutletList.outletInactive
              )}
            />
            <br />
            {fieldDataForModule.editReject == 1 ? (

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name={"Approve"}
                  onClick={this.onApprove}
                />
              </Grid>) : ("")}
          </>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  areaList: state.area,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  inactiveOutletList: state.outlet,
});
const mapDispatchToProps = {
  getAreaByUserType,
  getRegionNew,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTalukaPostBody,
  getStateByRegion,
  getRequestForInactiveOutletPost,
  setRequestedForInactive,
  showLoador,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(InactiveOutletForm);
