import { Box, Paper, Tab, Tabs, Typography } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { SalesExecutiveJson } from "../../../DynamicFormsJson/MastersJSON/salesExecutive";
import { getRegionNew } from "../../../Slice/region.slice";
import { getZoneByRegionId } from "../../../Slice/zone.slice";
import { getAreaByZoneIds } from "../../../Slice/area.slice";
import { getStockStakeHolderType } from "../../../Slice/stockStakeHolderType.slice";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import SwipeableViews from 'react-swipeable-views';
import AssignBeat from "./AssignBeat";
import { withRouter } from "../../../components/withRouter";
import PropTypes from "prop-types";
import {

  setBeatBySalesTeamIdAreaId,
} from "../../../Slice/beat.slice";
import { beatAssignedMsg, beatsAssign, noInternetMsg, saveFailedMsg, serverMsg, updateData, updateMsg } from "../../../config/messageconstant";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import endpoint from "../../../config/endpoints";
import { apiGet, apiPost } from "../../../utils/api_service";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

class SalesExecutiveForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,

      dynamicMasterData: {
        regionDetail: this.props.regionList?.region,
        stateDetail: this.props.stateList?.stateByRegion,
        zoneDetail: this.props.zoneList?.zoneByRegionId,
        districtDetail: this.props.districtList?.districtByZone,
        talukaDetail: this.props.talukaList?.talukaByDistrict,
        areaDetail: this.props.areaList?.area,
        stockStakeHolderTypeDetail:
          this.props.stockStakeHolderTypeList?.stockStakeHolderType,
      },
      stockStakeHolderTypeId: "",
    };
  }
  a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  handledChange = (e, newValue) => {
    this.props.setBeatBySalesTeamIdAreaId({ row: [] });
    this.setState({
      tabValue: newValue,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }

    if (this.props.zoneList !== nextProps.zoneList) {
      if (this.props.zoneList?.zoneByRegionId !== nextProps.zoneList?.zoneByRegionId) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByRegionId,
          },
        });
      }
    }

    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.area !== nextProps.areaList?.area) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.area,
          },
        });
      }
    }
    if (
      this.props.stockStakeHolderTypeList?.stockStakeHolderType !==
      nextProps.stockStakeHolderTypeList?.stockStakeHolderType
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          stockStakeHolderTypeDetail:
            nextProps.stockStakeHolderTypeList?.stockStakeHolderType,
        },
      });
    }
  }

  async componentDidMount() {
    const { getStockStakeHolderType, getRegionNew, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getRegionNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getStockStakeHolderType({ userTypeIds: 4 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getListById = async (data) => {
    const {
      getZoneByRegionId,
      getAreaByZoneIds,
      showLoador,
      showNotification } =
      this.props;
    if (navigator.onLine) {
      if (data.stockStakeHolderType && data.stockStakeHolderType.id) {
        this.setState({
          stockStakeHolderTypeId: data.stockStakeHolderType.id,
        });
      }
      if (data.region && data.region.id) {
        showLoador({ loador: true });
        await getZoneByRegionId({ regionId: data.region.id }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.zoneIds) {
        showLoador({ loador: true });
        await getAreaByZoneIds({ zoneIds: data.zoneIds }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onCancel = () => {
    this.props.navigate(-1);
  };
  getFormData = (rowData) => {
    this.props.setBeatBySalesTeamIdAreaId({ row: [] });
    this.props.navigate(SalesExecutiveJson.formPath + "/" + rowData.data.id)
    this.setState({
      tabValue: 1,
    });

  };

  getSalsPersonData = () => {
    const { dynamicMasterData } = this.state
    let stockStakeHolderType = ""
    if (dynamicMasterData.stockStakeHolderTypeDetail.length != 0) {
      stockStakeHolderType = {
        id: dynamicMasterData.stockStakeHolderTypeDetail[0].id
      }
    }

    return { stockStakeHolderType };
  };

  setDataToForm = (data) => {
    this.setState({ formData: data })
  };



  callBeforeSaveMethod = async (dataToSave, editData) => {

    if (
      Object.keys(editData).length == 0 ||
      (Object.keys(editData).length != 0 &&
        editData.areaIds != dataToSave.areaIds)
    ) {
      const { showLoador, showNotification } = this.props;
      if (navigator.onLine) {
        showLoador({ loador: true });
        const response = await apiGet({
          url: endpoint.assignBeat + "/by-sales-team?salesTeamId=" + editData.id,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            if (data.data.length !== 0) {
              showNotification({
                msg: beatsAssign,
                severity: "error",
              });
            } else {

              showLoador({ loador: true });
              apiPost({
                url: endpoint.salesExecutive + "/update",
                postBody: dataToSave,
              }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (success) {
                  showNotification({ msg: updateMsg });
                  if (data && Object.keys(data).length !== 0) {
                    this.getFormData(data);
                  }
                } else {
                  showNotification({ msg: saveFailedMsg, severity: "error" });
                }
              });
            }
          }
          return false;
        });
        return response;
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
    return true;
  };


  render() {
    const { dynamicMasterData, tabValue } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            pt: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >

          <LandingScreenHeader
            screenTitle={SalesExecutiveJson.screenTitle}
            showTable={false}
            showBackButton={true}
            showAddButton={false}
            onCancel={this.onCancel}
          />
          <Tabs
            onChange={this.handledChange}
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example">



            <Tab label={"Form "} {...this.a11yProps(0)} />



            <Tab label={"Assign Beat"} {...this.a11yProps(1)} />


          </Tabs>
          <SwipeableViews

            index={tabValue}
            onChangeIndex={this.handleChange}
          >

            <TabPanel value={tabValue} index={0}>
              <DynamicForm
                padding={false}
                paddingTop={false}
                callBeforeSave={this.props.params.id ? true : false}
                callBeforeSaveMethod={this.callBeforeSaveMethod}
                formPath={SalesExecutiveJson.formPath}
                getListById={this.getListById}
                screenTitle={SalesExecutiveJson.screenTitle}
                fieldMeta={SalesExecutiveJson.fieldMeta}
                dynamicMasterData={dynamicMasterData}
                apiBaseURL={SalesExecutiveJson.apiBaseURL}
                showSaveNextBtn={SalesExecutiveJson.showSaveNextBtn}
                isEditURL={SalesExecutiveJson.isEditURL}
                showTitleAndBack={false}
                getExtraData={true}
                getData={this.getSalsPersonData}
                showSaveBtn={false}
                getFormData={this.getFormData}
                getFormDataStatic={true}
                getDataForm={true}
                setDataToForm={this.setDataToForm}
              />
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <AssignBeat
                padding={false}
                paddingTop={false}
                formPath={SalesExecutiveJson.formPath}
                getListById={this.getListById}
                screenTitle={SalesExecutiveJson.screenTitle}
                fieldMeta={SalesExecutiveJson.fieldMeta}
                dynamicMasterData={dynamicMasterData}
                apiBaseURL={SalesExecutiveJson.apiBaseURL}
                showSaveNextBtn={SalesExecutiveJson.showSaveNextBtn}
                isEditURL={SalesExecutiveJson.isEditURL}
                showTitleAndBack={false}
              />
            </TabPanel>


          </SwipeableViews>

        </Paper>

      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stockStakeHolderTypeList: state.stockStakeHolderType,
  regionList: state.region,
  zoneList: state.zone,
  areaList: state.area,
});
const mapDispatchToProps = {
  getStockStakeHolderType,
  getRegionNew,
  getZoneByRegionId,
  getAreaByZoneIds,
  showLoador,
  showNotification,
  setBeatBySalesTeamIdAreaId
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SalesExecutiveForm);
