import endpoint from "../../config/endpoints";

export const VenderJson = {
    formPath: "/vendor-form",
    apiBaseURL: endpoint.vender,
    screenTitle: "Vendor",
    showAddButton: true,
    showPdfDownload: false,
    showExcelDownload: false,
    tableColumnsToFilter: [
        {
            columnDisplayName: "Name",
            columnKeyName: "name",
            isChecked: true,
        },
        {
            columnDisplayName: "GST Applicable",
            columnKeyName: "gstApplicableYesNo",
            isChecked: true,
        },
        {
            columnDisplayName: "Status",
            columnKeyName: "isActive",
            isChecked: true,
        },
    ],

    fieldMeta: [
        {
            label: "Region",
            controlType: "autocomplete",
            placeHolder: "Select Region",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "regionDetail",
            dataKey: "region",
            getListId: "region",
            isRootLevelKey: false,
            isMandatory: true,
        },
        {
            label: "Zone",
            controlType: "multiSelect",
            placeHolder: "Select Zone",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "zoneDetail",
            dataKey: "zoneIds",
            getListFrom: "region",
            getListId: "zoneIds",
            isRootLevelKey: true,
            isMandatory: true,
        },

        {
            label: "Area",
            controlType: "multiSelect",
            placeHolder: "Select Area",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "areaDetail",
            dataKey: "areaIds",
            getListFrom: "zoneIds",
            getListId: "areaIds",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Name",
            controlType: "textfield",
            placeHolder: "Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            autoFocus: true,
            dataKey: "name",
            isMandatory: true,
        },
        {
            label: "Address",
            controlType: "textfield",
            placeHolder: "address",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            autoFocus: true,
            dataKey: "address",
            isMandatory: true,
        },

        {
            label: "PAN Number ",
            controlType: "textfield",
            placeHolder: "ABCTY1234D",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            maxLength: 10,
            dataKey: "panNo",
            isMandatory: true,
            valid: true,
            validName: "panNo",
        },

        {
            label: "GST Applicable",
            controlType: "radiogroup",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "gstApplicable",
            tableDataKey: "gstApplicable",
            defaultValue: "0",
            radioGroupItems: [
                {
                    label: "NO",
                    value: "0",
                },
                {
                    label: "Yes",
                    value: "1",
                },
            ],
            isMandatory: true,
        },

        {
            label: "GST Number",
            controlType: "hideTextfieldNotEqual",
            placeHolder: "22AAAAA0000A1Z5",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "gstIn",
            tableDataKey: "gstApplicable",
            openDataKey: "gstApplicable",
            open: "0",
            valid: true,
            validName: "gstIn",
            error: "GST Number",
            saveDataKey: true,
            setTextHide: true,
        },


        {
            label: "Contact Number",
            controlType: "textfield",
            placeHolder: "Contact Number",
            inputType: "number",
            maxLength: 10,
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "contactNumber",
            valid: true,
            validName: "phoneNo",
            isMandatory: true,
        },

        {
            label: "Email Id",
            controlType: "textfield",
            placeHolder: "Email Id",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "emailId",
            valid: true,
            validName: "email",
            isMandatory: true,
        },

        {
            label: "Select Firm Image",
            controlType: "selectImage",
            placeHolder: "Select Firm Image",
            inputType: "number",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKeyToSave: "firmImage",
            dataKey: "productImageShow",
            isMandatory: true,
        },
    ]
};
