import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import * as React from 'react';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function NotificationToast({ open, msg, closeSnak, severity }) {

  const handleClose = () => {
    closeSnak();
  };

  return (
    <div>
      <Snackbar
        open={open}
        onClose={() => { handleClose() }}
        TransitionComponent={undefined}
        autoHideDuration={5000}
        key={''}
      >
        <Alert onClose={() => { handleClose() }} severity={severity} sx={{ width: '100%' }}>{msg}
        </Alert></Snackbar>
    </div>
  );
}

