import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  serverMsg,
} from "../../config/messageconstant";
import { paymentListJSON } from "../../DynamicFormsJson/Transaction/paymentList";
import { getStockStackHolders } from "../../Slice/StackHolder.slice";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import PaymentDialogBox from "./PaymentDialogBox";
class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      DataToSet: [],
      dynamicMasterData: {
        stakeholdersDetail: this.props.stakeholdersList.stockStakeHolders,
      },
      openPaymentDialog: false,
      paymentFormData: {},
      stakeholderPayment: "",
      stakeholderId: "",
    };
  }
  async componentDidMount() {
    const { getStockStackHolders, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getStockStackHolders().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.stakeholdersList !== nextProps.stakeholdersList) {
      if (
        this.props.stakeholdersList?.stockStakeHolders !==
        nextProps.stakeholdersList?.stockStakeHolders
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeholdersDetail: nextProps.stakeholdersList?.stockStakeHolders,
          },
        });
      }
    }
  }

  getListById = async (data) => {
    const { getStockStackHolders, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      if (data.stakeholder) {
        showLoador({ loador: true });
        this.setState({ stakeholder: data.stakeholder });
        apiGet({
          url:
            endpoint.paymentReceipt +
            "/remaining-amount?holderId=" +
            data.stakeholder,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.setState({
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Remaining Settle Amount",
                  value:
                    data.data.settleRemainingAmt !== null
                      ? data.data.settleRemainingAmt.toFixed(2)
                      : "",
                },
              ],
            });
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSearch = (data) => {
    if (navigator.onLine) {
      this.setState({
        paymentFormData: data,
        openPaymentDialog: true,
        stakeholderId: data.stakeholder,
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  handleClosesForStakeholder = () => {
    const { openPaymentDialog } = this.state;
    this.setState({
      openPaymentDialog: !openPaymentDialog,
    });
  };

  saveData = (dialogBoxData) => {
    const { stakeholderId } = this.state;
    const { showLoador, showNotification } = this.props;
    dialogBoxData = {
      ...dialogBoxData,
      settleRemainingAmt: dialogBoxData.amt,
      fromStockStakeHolder: stakeholderId,
    };
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.paymentReceipt,
        postBody: dialogBoxData,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg });
          this.props.navigate(-1);
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  render() {
    const { dynamicMasterData, openPaymentDialog, DataToSet, paymentFormData } =
      this.state;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            dynamicMasterData={dynamicMasterData}
            getListById={this.getListById}
            searchList={paymentListJSON.searchList}
            showSaveBtn={paymentListJSON.showSaveBtn}
            showTitle={paymentListJSON.showTitle}
            screenTitle={paymentListJSON.screenTitle}
            fieldMeta={paymentListJSON.fieldMeta}
            showCancel={paymentListJSON.showCancel}
            showBackToList={paymentListJSON.showBackToList}
            apiBaseURL={paymentListJSON.apiBaseURL}
            showSaveNextBtn={paymentListJSON.showSaveNextBtn}
            saveBtnText={"Proceed"}
            onSave={this.onSearch}
          />
          <DynamicDetails DataToSet={DataToSet} />
        </Paper>
        {openPaymentDialog && (
          <PaymentDialogBox
            open={openPaymentDialog}
            handleCloses={this.handleClosesForStakeholder}
            onFormSave={this.rowAppliedList}
            saveData={this.saveData}
            rows={paymentFormData}
            showPegination={false}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stakeholdersList: state.stackholder,
});
const mapDispatchToProps = {
  getStockStackHolders,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PaymentForm);
