import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { paymentFormJSON } from "../../DynamicFormsJson/Transaction/paymentForm";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  saveFailedMsg,
  serverMsg,
  updateMsg,
} from "../../config/messageconstant";
import * as myConstClass from "../../config/messageconstant";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import swal from "sweetalert";
class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
      dynamicMasterData: {},
      paymentData: {},
    };
  }

  componentWillReceiveProps(nextProps) {}

  async componentDidMount() {
    const { getAreaByUserType, showLoador, showNotification } = this.props;
    console.log(this.props.params.id);
    if (navigator.onLine) {
      showLoador({ loador: true });
      await apiGet({
        url: endpoint.paymentReceiptOutlet + "/" + this.props.params.id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            paymentData: data.data,
            DataToSet: [
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Beat Name",
                value:
                  data.data.outLet !== null && data.data.outLet.beat !== null
                    ? data.data.outLet.beat.beatName
                    : "",
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Outlet Name",
                value:
                  data.data.outLet !== null &&
                  data.data.outLet.firmName !== null
                    ? data.data.outLet.firmName
                    : "",
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Transaction Date",
                value: data.data.date !== null ? data.data.date : "",
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Previous Amount",
                value: data.data.amt !== null ? data.data.amt : "",
              },
            ],
          });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  onSearch = (data) => {
    console.log(data);
    const { paymentData } = this.state;
    const { showNotification, showLoador } = this.props;
    if (data.amt < paymentData.amt) {
      showNotification({
        msg: myConstClass.previousAmountError,
        severity: "error",
      });
    } else {
      swal({
        title: "Are you sure?",
        text: myConstClass.updateData,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          if (navigator.onLine) {
            let paymentObject = {
              ...paymentData,
              amt: data.amt,
              payType: data.payType,
            };
            console.log(paymentObject);
            showLoador({ loador: true });
            apiPost({
              url: endpoint.paymentReceiptOutlet,
              postBody: paymentObject,
            }).then(({ data, success }) => {
              showLoador({ loador: false });
              if (success) {
                this.props.navigate(-1);
                showNotification({
                  msg: updateMsg,
                });
              } else {
                showNotification({
                  msg: saveFailedMsg,
                  severity: "error",
                });
              }
            });
          } else {
            this.props.showNotification({
              msg: noInternetMsg,
              severity: "error",
            });
          }
        }
      });
    }
  };

  onCancel = () => {
    this.props.navigate(-1);
  };

  render() {
    const { dynamicMasterData, DataToSet, paymentData } = this.state;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            tableColumnsToFilter={[]}
            screenTitle={paymentFormJSON.screenTitle}
            showPdfDownload={false}
            showExcelDownload={false}
            showAddButton={false}
            showTable={false}
            showBackButton={true}
            onCancel={this.onCancel}
          />
          <br />
          <DynamicFormWithoutSave
            dynamicMasterData={dynamicMasterData}
            getListById={this.getListById}
            searchList={paymentFormJSON.searchList}
            showSaveBtn={paymentFormJSON.showSaveBtn}
            showTitle={false}
            DataToSet={DataToSet}
            screenTitle={paymentFormJSON.screenTitle}
            fieldMeta={paymentFormJSON.fieldMeta}
            showCancel={paymentFormJSON.showCancel}
            showBackToList={paymentFormJSON.showBackToList}
            apiBaseURL={paymentFormJSON.apiBaseURL}
            showSaveNextBtn={paymentFormJSON.showSaveNextBtn}
            saveBtnText={"Proceed"}
            onSave={this.onSearch}
            setFieldData={paymentData}
          />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PaymentForm);
