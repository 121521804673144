import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg } from "../../config/messageconstant";
import { assignRateConfigurationToStakeholderJson } from "../../DynamicFormsJson/Transaction/AssignRateConfigurationToStakeholder";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";

class AssignRateConfigurationToStakeholderView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
      publishRateConfigOutletList: [],
    };
    this.columns = [
      {
        title: "Sr.No.",
        name: "index",
        align: "center",
      },
      {
        title: "Product Name",
        name: "productName",
      },
      {
        title: "Product Code",
        name: "productCode",
        align: "center",
      },
      {
        title: "UOM",
        name: "stakeHolderUom",
        align: "center",
      },
      {
        title: "Margin %",
        name: "marginPercentage",
        align: "right",
      },
    ];
  }
  async componentDidMount() {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      if (this.props.params.id) {
        showLoador({ loador: true });
        apiGet({
          url:
            assignRateConfigurationToStakeholderJson.apiBaseURL +
            "/" +
            this.props.params.id,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
          } else {
            let rowList =
              data.data.productRateConfiguration.productRateConfigurationDetails.map(
                (productObject, index) => {
                  let newList = {
                    index: index + 1,
                    productName: productObject.product.name,
                    productCode: productObject.product.code,
                    stakeHolderUom: productObject.product.stakeHolderUom.name,
                    mrp: productObject.product.mrp,
                    marginPercentage: productObject.marginPercentage.toFixed(2),
                  };
                  return newList;
                }
              );
            this.setState({
              publishRateConfigOutletList: rowList,
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Stock Holder",
                  value:
                    data.data.stockStakeHolder !== null &&
                    data.data.stockStakeHolder.ownerName !== null
                      ? data.data.stockStakeHolder.ownerName +
                        " ( " +
                        data.data.stockStakeHolder.stockStakeHolderType.name +
                        " )"
                      : "-",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Category Name",
                  value:
                    data.data.productRateConfiguration !== null &&
                    data.data.productRateConfiguration.category !== null &&
                    data.data.productRateConfiguration.category.name !== null
                      ? data.data.productRateConfiguration.category.name
                      : "-",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Configuration",
                  value:
                    data.data.productRateConfiguration !== null &&
                    data.data.productRateConfiguration.name !== null
                      ? data.data.productRateConfiguration.name
                      : "-",
                },

                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Assigned Date",
                  value:
                    data.data.publishDate !== null
                      ? data.data.publishDate
                      : "-",
                },
              ],
            });
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  render() {
    const { DataToSet, publishRateConfigOutletList } = this.state;

    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            DataToSet={DataToSet}
            showSaveBtn={assignRateConfigurationToStakeholderJson.showSaveBtn}
            showTitle={true}
            screenTitle={"Configuration To Stock Holder View"}
            fieldMeta={[]}
            showCancel={assignRateConfigurationToStakeholderJson.showCancel}
            apiBaseURL={assignRateConfigurationToStakeholderJson.apiBaseURL}
            showSaveNextBtn={
              assignRateConfigurationToStakeholderJson.showSaveNextBtn
            }
            showSaveBtnMain={false}
            padding={true}
            paddingTop={true}
          />
          <RTTable
            columns={this.columns}
            tableData={publishRateConfigOutletList}
            isActionColActive={false}
            showPegination={false}
          />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  publishRateConfigOutletList: state.assignRateConfigurationToStakeholder,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AssignRateConfigurationToStakeholderView);
