import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { batchWiseGRNJSON } from "../../DynamicFormsJson/Transaction/batchWiseGRN";
import {
    getGRNHeaderById,
    getGRNHeaderDetailsById,
} from "../../Slice/requestGRN.slice";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg } from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
class BatchWiseGRNView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DataToSet: [],
            orderHistory: [],
        };
        this.columns = [
            {
                title: "Sr.No.",
                name: "index",
                align: "center",
            },
            {
                title: "Product Name",
                name: "productName",
            },
            {
                title: "UOM",
                name: "stakeHolderUomName",
                align: "center",
            },
            {
                title: "Batch",
                name: "batchCode",
            },
            {
                title: "Rate",
                name: "rate",
                align: "right",
            },
            {
                title: "Quantity",
                name: "qty",
                align: "right",
            },
            {
                title: "Total Amount",
                name: "totalAmt",
                align: "right",
            },
        ];
    }
    async componentDidMount() {
        const {
            getGRNHeaderDetailsById,
            getGRNHeaderById,
            showLoador,
            showNotification,
        } = this.props;
        if (navigator.onLine) {
            if (this.props.params.id) {
                showLoador({ loador: true });
                await getGRNHeaderById({
                    requestGRNHeaderId: this.props.params.id,
                }).then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                    } else {
                        this.setState({
                            DataToSet: [
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "GRN Number",
                                    value: response.grnGvnNo !== null ? response.grnGvnNo : "",
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Date",
                                    value: response.date !== null ? response.date : "",
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Taxable Amount",
                                    value:
                                        response.taxableAmt !== null ? response.taxableAmt : "",
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Total Amount",
                                    value: response.totalAmt !== null ? response.totalAmt : "",
                                },

                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Total Tax",
                                    value:
                                        response.totalAmt !== null && response.taxableAmt !== null
                                            ? (+response.totalAmt - +response.taxableAmt).toFixed(2)
                                            : "",
                                },
                            ],
                        });
                    }
                });

                showLoador({ loador: true });
                await getGRNHeaderDetailsById({
                    requestGRNHeaderId: this.props.params.id,
                }).then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                    } else {
                        this.setState({
                            orderHistory:
                                response.grnGvnDetails.length !== 0
                                    ? response.grnGvnDetails
                                    : [],
                        });
                    }
                });
            }
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }
    render() {
        const { orderHistory, DataToSet } = this.state;
        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 0,
                    }}
                    elevation={0}
                >
                    <LandingScreenHeader
                        showTitle={batchWiseGRNJSON.showTitle}
                        screenTitle={"Request Batchwise GRN View"}
                        showSaveBtnMain={false}
                        showAddButton={false}
                        showBackButton={true}
                        onCancel={() => this.props.navigate(-1)}
                    />
                    <br />
                    <DynamicDetails DataToSet={DataToSet} />
                    <RTTable
                        columns={this.columns}
                        tableData={orderHistory}
                        isActionColActive={false}
                        showPegination={false}
                    />
                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getGRNHeaderDetailsById,
    getGRNHeaderById,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(BatchWiseGRNView);
