import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { purchaseReturnJson } from "../../DynamicFormsJson/Transaction/purchaseReturn";
import {
  getBillFromDateWithoutOrderCondition,
  getBillListById,
  setBillwiseList,
} from "../../Slice/orderBillList.slice";
import {
  getOutletByBeatIds,
  getOutletByUserType,
} from "../../Slice/outlet.slice";
import { getProductByCategory } from "../../Slice/product.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import {
  checkedListEmptyMsg,
  noInternetMsg,
  qtyGreaterThanBillQtyMsg,
  saveFailedMsg,
  saveWarningMsg,
  savemsg,
  serverMsg,
} from "../../config/messageconstant";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import PurchaseReturnHeaderForm from "./PurchaseReturnHeaderForm";
import PurchaseReturnTableForBillWise from "./PurchaseReturnTableForBillWise";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { LabelCompo } from "../../components/Comman/Label";
import { titleColor } from "../../config/ColorObj";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";

class PurchaseReturnByBillWise extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowList: [],
      formErrors: {},
      fieldData: { instockOutstock: "1" },
      searchValue: "",
      remark: "",
      dynamicMasterData: {
        billDetail: this.props.billByDateList.billingByDate,
        productDetail: this.props.billByDateList.billListbyId,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.billByDateList !== nextProps.billByDateList) {
      if (
        this.props.billByDateList.billingByDate !==
        nextProps.billByDateList.billingByDate
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            billDetail: nextProps.billByDateList.billingByDate,
          },
        });
      }
    }
    if (this.props.billByDateList !== nextProps.billByDateList) {
      if (
        this.props.billByDateList.billListbyId !==
        nextProps.billByDateList.billListbyId
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            productDetail: nextProps.billByDateList.billListbyId,
          },
        });
      }
    }
  }
  getBill = async (fromdate, todate) => {
    const {
      getBillFromDateWithoutOrderCondition,
      showLoador,
      showNotification,
    } = this.props;
    showLoador({ loador: true });
    await getBillFromDateWithoutOrderCondition({
      fromDate: fromdate,
      toDate: todate,
    }).then(({ success }) => {
      if (!success) {
        showLoador({ loador: false });
        showNotification({ msg: serverMsg, severity: "error" });
      } else {
        showLoador({ loador: false });
      }
    });
  };
  getListById = async (data) => {
    console.log(data);
    const { getBillListById, showLoador, showNotification } = this.props;
    const { fieldData } = this.state;
    if (navigator.onLine) {
      if (fieldData.fromdate && fieldData.todate) {
        await this.getBill(
          fieldData.fromdate.format("DD-MM-YYYY"),
          fieldData.todate.format("DD-MM-YYYY")
        );
      }
      if (data.billNo) {
        showLoador({ loador: true });
        await getBillListById({
          billHeaderId: data.billNo,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            showLoador({ loador: false });
          }
        });
      }
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  async componentDidMount() {
    this.setState({
      rowList: [],
    });
    if (this.props.params.id) {
      console.log(this.props.detailsList);
    }
  }

  onSearch = (data) => {
    const { rowList, dynamicMasterData } = this.state;

    let productList = dynamicMasterData["productDetail"]
      ? dynamicMasterData["productDetail"].filter(
          (row) => row.id == data.product
        )
      : [];
    let billByDateList = dynamicMasterData["billDetail"]
      ? dynamicMasterData["billDetail"].filter((row) => row.id == data.bill)
      : [];
    this.setState({
      rowList: [
        ...rowList,
        {
          index: rowList.length + 1,
          billName: billByDateList.length != 0 ? billByDateList[0].name : "-",
          productName: productList.length != 0 ? productList[0].name : "-",
          uom: productList.length != 0 ? productList[0].outletUom : "-",
          calStock: data.calStock,
          total: (data.rate * +data.qty).toFixed(2),
        },
      ],
    });
  };

  onSubmitData = () => {
    const { fieldData, remark } = this.state;
    const { billByDateList, showNotification } = this.props;
    if (billByDateList.length !== 0) {
      const grnGvnDetails = [];
      let flag = 0;
      let checkedFlag = 0;
      billByDateList?.billListbyId.map((rowData) => {
        if (rowData.checked) {
          checkedFlag++;
        }
      });
      if (checkedFlag > 0) {
        billByDateList?.billListbyId.map((rowData) => {
          if (+rowData.totalQty > 0 && rowData.checked) {
            flag = rowData.qtyError ? flag + 1 : flag;
            const rowObj = {
              product: rowData.product,
              mrp: rowData.mrp,
              rate: rowData.rate,
              secondaryQty: rowData.totalQty,
              batchCode: rowData.batchCode,
              billDetailId: rowData.billDetailId,
              hsnCode: rowData.product.taxPercentage.hsnCode,
              stockStakeHolderBillDetailId: rowData.id,
            };
            grnGvnDetails.push(rowObj);
          }
        });
        let dataToSave = "";
        if (flag <= 0 && grnGvnDetails.length !== 0) {
          if (this.props.params.id) {
            dataToSave = {
              id: this.props.params.id,
              remark: remark,
              instockOutstock: fieldData.instockOutstock,
              grnGvnDetails: grnGvnDetails,
              date: dayjs().format("DD-MM-YYYY"),
            };
          } else {
            dataToSave = {
              remark: remark,
              instockOutstock: fieldData.instockOutstock,
              grnGvnDetails: grnGvnDetails,
              date: dayjs().format("DD-MM-YYYY"),
            };
          }

          console.log(dataToSave);
          swal({
            title: "Are you sure?",
            text: saveWarningMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              this.onSave(dataToSave);
            }
          });
        } else {
          showNotification({
            msg: qtyGreaterThanBillQtyMsg,
            severity: "error",
          });
        }
      } else {
        showNotification({ msg: checkedListEmptyMsg, severity: "error" });
      }
    }
  };
  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.grnGVNHeader,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg });
          this.props.navigate(-1);
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  mandatoryCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    purchaseReturnJson.fieldMetaBill.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey] === "" ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&
              fieldData[currentField.dataKey].id == null) ||
            fieldData[currentField.dataKey].id == "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  ValidationCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    purchaseReturnJson.fieldMetaBill.forEach((currentField) => {
      if (currentField.valid) {
        if (
          currentField.validName === "email" &&
          !validEmail.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "zero" &&
          fieldData[currentField.dataKey] === 0
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  checkValidationOnSubmit = () => {
    if (this.mandatoryCheck()) {
      return this.ValidationCheck();
    } else {
      return false;
    }
  };
  onDataChange = async (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    if (fieldName == "fromdate" && dataToReset.todate) {
      await this.getBill(
        newValue.format("DD-MM-YYYY"),
        fieldData.todate.format("DD-MM-YYYY")
      );
    }
    if (fieldName == "todate" && dataToReset.fromdate) {
      await this.getBill(
        fieldData.fromdate.format("DD-MM-YYYY"),
        newValue.format("DD-MM-YYYY")
      );
    }
    purchaseReturnJson.fieldMetaBill.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };
  setCatList = ({ row }) => {
    const { setBillwiseList } = this.props;
    const { fieldData } = this.state;
    console.log(fieldData);
    console.log(row);
    setBillwiseList({ row });
  };

  getDataForDisabled = (value) => {
    const { dynamicMasterData } = this.state;

    let productList = dynamicMasterData["productDetail"]
      ? dynamicMasterData["productDetail"].filter((row) => row.id == value)
      : [];
    return { rate: productList[0].rate, uom: productList[0].outletUomName };
  };
  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  getFilteredTableData = (tableData) => {
    const { searchValue } = this.state;
    return tableData.filter((currentRow) => {
      let isValid = false;
      let productName =
        currentRow.product != null ? currentRow.product.name : "-";
      let outletUom =
        currentRow.product.outletUom != null
          ? currentRow.product.outletUom.name
          : "-";
      if (
        productName &&
        productName.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        outletUom &&
        outletUom.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };

  changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "remark") {
      this.setState({ remark: value });
    }
  };
  render() {
    const { dynamicMasterData, rowList, formErrors, fieldData, remark } =
      this.state;
    const { billByDateList } = this.props;

    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
        >
          <PurchaseReturnHeaderForm
            getListById={this.getListById}
            dynamicMasterData={dynamicMasterData}
            screenTitle={""}
            fieldMeta={purchaseReturnJson.fieldMetaBill}
            apiBaseURL={purchaseReturnJson.apiBaseURL}
            formErrors={formErrors}
            showSaveBtn={purchaseReturnJson.showSaveBtn}
            showSaveBtnMain={false}
            fieldData={fieldData}
            onDataChange={this.onDataChange}
            showBackToList={false}
          />
          <br />
          <LandingScreenHeader
            screenTitle={""}
            showSearchBox={true}
            showTable={true}
            showFilter={false}
            showPdfDownload={false}
            showExcelDownload={false}
            onSearch={this.onSearch}
          />
          <PurchaseReturnTableForBillWise
            tableHead={[
              {
                title: "Sr.No.",
                name: "index",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Product Name",
                name: "name",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "UOM",
                name: "name",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Rate",
                name: "name",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "Bill Quantity",
                name: "secondaryBillQty",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
              },
              {
                title: "Primary UOM",
                name: "primaryQty",
                placeHolder: "Enter Target",
                textFieldError: "qtyErr",
                positionCenter: false,
                showInscreen: true,
              },
              {
                title: "Secondary UOM",
                name: "qty",
                placeHolder: "Enter Target",
                textFieldError: "qtyErr",
                positionCenter: false,
                showInscreen: true,
              },
              {
                title: "Total Qty",
                name: "totalQty",
                placeHolder: "Enter Target",
                textFieldError: "qtyErr",
                positionCenter: false,
                showInscreen: true,
              },
              {
                title: "Total",
                name: "total",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
            ]}
            data={billByDateList?.billListbyId}
            rowList={billByDateList?.billListbyId}
            setList={this.setCatList}
            selection={[]}
            rows={this.getFilteredTableData(billByDateList?.billListbyId)}
          />
          <br />
          <Grid container alignItems="center">
            <Grid item xs={12} md={2.5} sm={2.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: titleColor, marginLeft: 15 }}
                label=" Add remark :"
              />
            </Grid>
            <Grid item xs={12} md={9.5} sm={9.5}>
              <TextFieldCompo
                placeholder="Add remark"
                color="primary"
                name="remark"
                size="small"
                value={remark}
                fullWidth
                onChange={this.changeHandler}
              />
            </Grid>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="right"
          >
            <ButtonCompo
              size="medium"
              type="Submit"
              variant="contained"
              name="Submit"
              onClick={this.onSubmitData}
            />
          </Grid>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  areaList: state.area,
  beatList: state.beat,
  outletList: state.outlet,
  productList: state.billHeaderList,
  billByDateList: state.billHeaderList,
});
const mapDispatchToProps = {
  getOutletByBeatIds,
  showLoador,
  showNotification,
  getBillFromDateWithoutOrderCondition,
  getProductByCategory,
  getOutletByUserType,
  getBillListById,
  setBillwiseList,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PurchaseReturnByBillWise); 
