import React, { useEffect, useState } from "react";
import "./billPDFCss.css";
import { langugae } from "../../config/languageJSON";
import endpoint from "../../config/endpoints";
import { apiGet, apiPost } from "../../utils/api_service";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../components/withRouter";
import { connect } from "react-redux";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { imageUrl } from "../../config/Url";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import QRCode from "qrcode.react";

const MyComponent = ({ showLoador, showNotification }) => {
  const data = "9579061161@ybl";
  const params = useParams();
  const [billList, setBill] = useState([]);
  let lang = localStorage.getItem("lang");
  let billPdfId = localStorage.getItem("billPdfId");
  useEffect(() => {
    // window.print();
    if (navigator.onLine) {
      lang = localStorage.getItem("lang");
      billPdfId = localStorage.getItem("billPdfId");

      let billPdfData = {
        outletBillHeaderIds: billPdfId.split(","),
        languageId: lang,
      };
      showLoador({ loador: true });
      apiPost({
        url: endpoint.outletBillHeader + "/report-by-ids",
        postBody: billPdfData,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
        } else {
          console.log(data.data);

          setBill(data.data);
          setTimeout(function () {
            window.print();
          }, 1200);
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }, []);

  return (
    <>
      {/* <footer className='header'>
                <p>Tax Invoice</p>

            </footer>

            <header className='header2'>
                <p>Author: Hege Refsnes</p>
            </header> */}
      {billList.map((dataToSet, index) => {
        let invoiceJson =
          dataToSet.invoiceJson != null
            ? JSON.parse(dataToSet.invoiceJson)
            : {};
        let toBill = dataToSet.outLet ? dataToSet.outLet : {};
        let taluka =
          toBill.beat != null &&
            toBill.beat.area != null &&
            toBill.beat.area.taluka != null
            ? toBill.beat.area.taluka
            : "";
        let talukaName = taluka != null ? taluka.name : "";
        let districtName =
          taluka != null && taluka.district != null ? taluka.district.name : "";
        let addr = toBill.address1
          ? toBill.address1 + " - " + talukaName + " - " + districtName
          : "";
        let addr2 =
          toBill != null && toBill.address2 != null ? toBill.address2 : "";
        let address = langugae[2].address ? langugae[2].address + " : " : "";
        var DAddrText = address + addr;
        var DAddrText1 = "Address 2 : " + addr2;
        let village = langugae[2].village ? langugae[2].village + " : " : "";
        var villageCity = toBill.villageCity
          ? village + toBill.villageCity
          : "";
        let insertDateTime = dataToSet.insertDateTime
          ? dataToSet.insertDateTime.split(" ")
          : [];
        let insertDateTimeData =
          insertDateTime.length == 2 ? insertDateTime[1] : "";
        let toBillState = toBill.state != null ? toBill.state : {};
        let indexInfo = 0;
        let totalAmt = 0;
        let totalTaxableAmt = 0;
        let qtyTot = 0;
        let freeQtyTot = 0;
        let cgstTot = 0;
        let sgstTot = 0;
        let igstTot = 0;
        let discountTot = 0;
        let dataToSetState = dataToSet.state != null ? dataToSet.state : {};
        let stockStakeHolderBillDetails =
          dataToSet.outletBillDetails != null
            ? dataToSet.outletBillDetails
            : [];

        return (
          <>
            <div style={{ marginRight: "20px", marginLeft: "20px" }}>
              {/* <table
                style={{
                  width: "100%",
                  fontSize: "12px",
                }}
              >
                <tr>
                  <td
                    style={{
                      fontSize: "12px",
                    }}
                    align={"left"}
                    className="tdsmallcell"
                  > */}
              <table style={{ width: "100%" }}>
                <tr>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td
                        align={"center"}
                        style={{ paddingBottom: "4px" }}
                        colSpan={2}
                      >
                        <div
                          style={{
                            fontWeight: 600,
                            fontSize: "18px",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "20px",
                                marginLeft: "10%",
                              }}
                            >
                              {dataToSet.stockStakeHolderGstNo == toBill.gSTNo
                                ? langugae[2].taxInvoice
                                : langugae[2].taxInvoice
                                  ? langugae[2].taxInvoice
                                  : ""}
                            </span>
                          </div>
                          <img
                            style={{
                              width: "10%",
                              height: "60px",
                              float: "right",
                            }}
                            src={imageUrl + localStorage.getItem("complogo")}
                            alt="Logo"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                          width: "50%",
                          borderLeft: "1px solid black",
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                          borderRight: "1px solid black",
                          borderCollapse: "collapse",
                          padding: "8px",
                          paddingBottom: "75px",
                        }}
                      >
                        <div style={{ fontWeight: 600, fontSize: "25px" }}>
                          {dataToSet.stockStakeHolderFirmName
                            ? dataToSet.stockStakeHolderFirmName
                            : "-"}
                        </div>
                        {langugae[2].address ? langugae[2].address + " : " : ""}{" "}
                        {dataToSet.stockStakeHolderAddress
                          ? dataToSet.stockStakeHolderAddress
                          : "-"}
                        <br />
                        {langugae[2].mobileNo
                          ? langugae[2].mobileNo + " : "
                          : ""}{" "}
                        {dataToSet.stockStakeHolderContactPersonNo
                          ? dataToSet.stockStakeHolderContactPersonNo
                          : "-"}{" "}
                        <br />
                        {"GSTIN : "}{" "}
                        {dataToSet.stockStakeHolderGstNo
                          ? dataToSet.stockStakeHolderGstNo
                          : "-"}{" "}
                      </td>

                      <td
                        style={{
                          textAlign: "left",
                          width: "50%",
                          borderLeft: "1px solid black",
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                          borderRight: "1px solid black",
                          borderCollapse: "collapse",
                          padding: "8px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {toBill.firmName ? toBill.firmName : ""},
                        </span>
                        {toBill.beat != null ? toBill.beat.beatName : "-"}
                        <br />
                        {DAddrText}
                        <br />
                        {DAddrText1}
                        <br />
                        {langugae[2].phoneNo
                          ? langugae[2].phoneNo + " : "
                          : ""}{" "}
                        {toBill.mobileNo ? toBill.mobileNo : ""} , <br />
                        {langugae[2].gSTNo
                          ? langugae[2].gSTNo + " : "
                          : ""}{" "}
                        {toBill.gstNo != null ? toBill.gstNo : "-"} , <br />
                        {"Bill No : "}{" "}
                        {dataToSet.billNo ? dataToSet.billNo : "-"} <br />
                        {"Date : "}{" "}
                        {dataToSet.billDate ? dataToSet.billDate : "-"}
                      </td>
                    </tr>
                  </table>
                </tr>
              </table>

              <div
                style={{
                  borderCollapse: "collapse",
                  marginTop: "3px",
                }}
              >
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <thead>
                    <tr>
                      <th style={{ fontSize: "12px" }} className="tdsmallcell">
                        #
                      </th>
                      <th style={{ fontSize: "12px" }} className="tdsmallcell">
                        {langugae[2].HSN ? langugae[2].HSN : ""}
                      </th>
                      <th style={{ fontSize: "12px" }} className="tdsmallcell">
                        {langugae[2].itemName ? langugae[2].itemName : ""}
                      </th>
                      <th style={{ fontSize: "12px" }} className="tdsmallcell">
                        {langugae[2].uom ? langugae[2].uom : ""}
                      </th>
                      <th style={{ fontSize: "12px" }} className="tdsmallcell">
                        {"MRP"}
                      </th>
                      <th style={{ fontSize: "12px" }} className="tdsmallcell">
                        {langugae[2].qty ? langugae[2].qty : ""}
                      </th>
                      <th style={{ fontSize: "12px" }} className="tdsmallcell">
                        {langugae[2].rate ? langugae[2].rate : ""}
                      </th>
                      <th
                        colSpan={2}
                        style={{ fontSize: "12px" }}
                        className="tdsmallcell"
                      >
                        {"Item Discount"}
                      </th>
                      <th
                        colSpan={2}
                        style={{ fontSize: "12px" }}
                        className="tdsmallcell"
                      >
                        {"Bill Discount"}
                      </th>
                      <th
                        colSpan={2}
                        style={{ fontSize: "12px" }}
                        className="tdsmallcell"
                      >
                        {"GST"}
                      </th>

                      <th style={{ fontSize: "12px" }} className="tdsmallcell">
                        {langugae[2].amount ? langugae[2].amount : ""}
                      </th>
                      <th style={{ fontSize: "12px" }} className="tdsmallcell">
                        {langugae[2].finalTotal ? langugae[2].finalTotal : ""}
                      </th>
                    </tr>
                    <tr>
                      <th className="tdsmallcell"></th>
                      <th className="tdsmallcell"></th>
                      <th className="tdsmallcell"></th>
                      <th className="tdsmallcell"></th>
                      <th className="tdsmallcell"></th>
                      <th className="tdsmallcell"></th>
                      <th className="tdsmallcell"></th>
                      <>
                        <th
                          style={{ fontSize: "12px" }}
                          className="tdsmallcell"
                        >
                          {"%"}
                        </th>
                        <th
                          style={{ fontSize: "12px" }}
                          className="tdsmallcell"
                        >
                          {"AMT"}
                        </th>
                      </>
                      <>
                        <th
                          style={{ fontSize: "12px" }}
                          className="tdsmallcell"
                        >
                          {"%"}
                        </th>
                        <th
                          style={{ fontSize: "12px" }}
                          className="tdsmallcell"
                        >
                          {"AMT"}
                        </th>
                      </>
                      <>
                        <th
                          style={{ fontSize: "12px" }}
                          className="tdsmallcell"
                        >
                          {"%"}
                        </th>
                        <th
                          style={{ fontSize: "12px" }}
                          className="tdsmallcell"
                        >
                          {"AMT"}
                        </th>
                      </>
                      <th className="tdsmallcell"></th>
                      <th className="tdsmallcell"></th>
                    </tr>
                  </thead>

                  <tbody style={{ width: "100%" }}>
                    {stockStakeHolderBillDetails.map(
                      (stockDetails, indexData) => {
                        let productData = [];
                        let product =
                          stockDetails.product != null
                            ? stockDetails.product
                            : {};
                        qtyTot = qtyTot + stockDetails.qty;
                        freeQtyTot = freeQtyTot + stockDetails.freeQty;
                        cgstTot = cgstTot + stockDetails.cgstAmt;
                        sgstTot = sgstTot + stockDetails.sgstAmt;
                        igstTot = igstTot + stockDetails.igstAmt;
                        indexInfo = indexInfo + 1;
                        totalAmt = totalAmt + stockDetails.totalAmt;
                        totalTaxableAmt =
                          totalTaxableAmt + stockDetails.taxableAmt;
                        discountTot = discountTot + stockDetails.itemDiscAmt;
                        return (
                          <>
                            <tr>
                              <td
                                style={{ fontSize: "12px" }}
                                className="tdsmallcell"
                              >
                                {indexInfo}
                              </td>
                              <td
                                style={{ fontSize: "12px" }}
                                className="tdsmallcell"
                              >
                                {stockDetails.hsnCode}
                              </td>
                              <td
                                style={{ fontSize: "12px" }}
                                align={"left"}
                                className="tdsmallcell"
                              >
                                {product.name}
                              </td>
                              <td
                                style={{ fontSize: "12px" }}
                                align={"left"}
                                className="tdsmallcell"
                              >
                                {stockDetails.uom != null
                                  ? stockDetails.uom.name
                                  : "-"}
                              </td>
                              <td
                                style={{ fontSize: "12px" }}
                                align={"right"}
                                className="tdsmallcell"
                              >
                                {stockDetails.mrp != null
                                  ? stockDetails.mrp
                                  : "-"}
                              </td>
                              <td
                                style={{ fontSize: "12px" }}
                                align={"right"}
                                className="tdsmallcell"
                              >
                                {stockDetails.qty}
                              </td>
                              <td
                                style={{ fontSize: "12px" }}
                                align={"right"}
                                className="tdsmallcell"
                              >
                                {(
                                  (stockDetails.rate * 100) /
                                  (100 +
                                    stockDetails.sgstPer +
                                    stockDetails.cgstPer +
                                    stockDetails.igstPer)
                                ).toFixed(2)}
                              </td>

                              <td
                                style={{ fontSize: "12px" }}
                                align={"right"}
                                className="tdsmallcell"
                              >
                                {stockDetails.itemDiscPer.toFixed(2)}
                              </td>
                              <td
                                style={{ fontSize: "12px" }}
                                align={"right"}
                                className="tdsmallcell"
                              >
                                {stockDetails.itemDiscAmt.toFixed(2)}
                              </td>

                              <td
                                style={{ fontSize: "12px" }}
                                align={"right"}
                                className="tdsmallcell"
                              >
                                {dataToSet.discountOnBillPer.toFixed(2)}
                              </td>
                              <td
                                style={{ fontSize: "12px" }}
                                align={"right"}
                                className="tdsmallcell"
                              >
                                {stockDetails.discountOnBillAmt.toFixed(2)}
                              </td>
                              <td
                                style={{ fontSize: "12px" }}
                                align={"right"}
                                className="tdsmallcell"
                              >
                                {(
                                  stockDetails.cgstPer +
                                  stockDetails.sgstPer +
                                  stockDetails.igstPer
                                ).toFixed(2)}
                              </td>
                              <td
                                style={{ fontSize: "12px" }}
                                align={"right"}
                                className="tdsmallcell"
                              >
                                {(
                                  stockDetails.cgstAmt +
                                  stockDetails.sgstAmt +
                                  stockDetails.igstAmt
                                ).toFixed(2)}
                              </td>
                              <td
                                style={{ fontSize: "12px" }}
                                align={"right"}
                                className="tdsmallcell"
                              >
                                {stockDetails.taxableAmt.toFixed(2)}
                              </td>
                              <td
                                style={{ fontSize: "12px" }}
                                align={"right"}
                                className="tdsmallcell"
                              >
                                {stockDetails.totalAmt.toFixed(2)}
                              </td>
                            </tr>
                          </>
                        );
                      }
                    )}

                    <tr>
                      <td
                        align={"left"}
                        className="tdsmallcell"
                        style={{ fontWeight: 600, fontSize: "12px" }}
                      >
                        {langugae[2].total ? langugae[2].total : ""}
                      </td>
                      <td className="tdsmallcell"></td>
                      <td className="tdsmallcell"></td>
                      <td className="tdsmallcell"></td>
                      <td className="tdsmallcell"></td>
                      <td
                        align={"right"}
                        style={{ fontWeight: 600, fontSize: "12px" }}
                        className="tdsmallcell"
                      >
                        {qtyTot}
                      </td>
                      <td className="tdsmallcell"></td>
                      <td className="tdsmallcell"></td>

                      <td
                        className="tdsmallcell"
                        align={"right"}
                        style={{ fontWeight: 600, fontSize: "12px" }}
                      >
                        {" "}
                        {discountTot.toFixed(2)}{" "}
                      </td>

                      <td className="tdsmallcell"></td>
                      {/* Already Align In Above code */}
                      <td
                        align={"right"}
                        style={{ fontWeight: 600, fontSize: "12px" }}
                        className="tdsmallcell"
                      >
                        {dataToSet.discountOnBillAmt.toFixed(2)}
                      </td>
                      <td className="tdsmallcell"></td>
                      <td
                        align={"right"}
                        style={{ fontWeight: 600, fontSize: "12px" }}
                        className="tdsmallcell"
                      >
                        {(cgstTot + sgstTot + igstTot).toFixed(2)}
                      </td>
                      <td
                        align={"right"}
                        style={{ fontWeight: 600, fontSize: "12px" }}
                        className="tdsmallcell"
                      >
                        {totalTaxableAmt.toFixed(2)}
                      </td>
                      <td
                        align={"right"}
                        style={{ fontWeight: 600, fontSize: "12px" }}
                        className="tdsmallcell"
                      >
                        {totalAmt.toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  style={{
                    borderCollapse: "collapse",
                    width: "100%",
                    fontSize: "12px",
                  }}
                >
                  <tr>
                    <td
                      style={{
                        fontSize: "12px",
                      }}
                      align={"left"}
                      className="tdsmallcell"
                    >
                      {langugae[2].orderBy ? langugae[2].orderBy + " : " : ""}
                      {dataToSet.salesTeamNames ? dataToSet.salesTeamNames : ""}
                      {"-"}
                      {dataToSet.salesTeamNo ? dataToSet.salesTeamNo : ""}
                    </td>
                  </tr>
                </table>
              </div>
              {/* </td>
                </tr>
              </table> */}
            </div>
            <div
              style={{
                // position: "absolute",//
                marginBottom: "0",
                width: "100%",
                backgroundColor: "fff",
                padding: "10px",
                textAlign: "right",
              }}
            >
              Number of bill {index + 1} of {billList.length}
            </div>

            {/* {index + 1 < billList.length &&
            billList[index].outletBillDetails.length +
              billList[index + 1].outletBillDetails.length <=
              18 ? (
              <>
                {console.log(index)}
                {console.log(index + 1)}
                {console.log(billList.length)}
                {console.log(billList[index].outletBillDetails.length)}
                {console.log(billList[index + 1].outletBillDetails.length)}
                {console.log(
                  billList[index].outletBillDetails.length +
                    billList[index + 1].outletBillDetails.length
                )}
              </>
            ) : index > 1 &&
              billList[index].outletBillDetails.length +
                billList[index - 1].outletBillDetails.length <
                10 ? (
              <>
                <div className="pagebreak"></div>
                {console.log("Inn else")}
                {console.log(index)}
              </>
            ) : (
              <>
                <div className="pagebreak"></div>
              </>
            )} */}
            {/* {index + 1 != billList.length && (
              <>
                <div className="pagebreak"></div>
              </>
            )} */}
            {/*  {index > 1 &&
            billList[index].outletBillDetails.length +
              billList[index - 1].outletBillDetails.length <
              10 ? (
              <>
                <div className="pagebreak"></div>
                {console.log("Inn else")}
              </>
            ) : (
              <>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>{" "}
              </>
            )} */}

            {/* New login of page break 19-08-2024 */}

            {(index + 1) % 2 == 0 &&
              billList[index].outletBillDetails.length < 5 ? (
              <></>
            ) : billList[index].outletBillDetails.length < 5 ? (
              <>
                {index + 1 < billList.length &&
                  billList[index].outletBillDetails.length +
                  billList[index + 1].outletBillDetails.length >=
                  16 &&
                  billList[index].outletBillDetails.length +
                  billList[index + 1].outletBillDetails.length <
                  18 ? (
                  <></>
                ) : index + 1 < billList.length &&
                  billList[index].outletBillDetails.length +
                  billList[index + 1].outletBillDetails.length >=
                  18 ? (
                  <>
                    <div className="pagebreak"></div>
                  </>
                ) : (
                  <>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>{" "}
                  </>
                )}
              </>
            ) : index + 1 < billList.length &&
              billList[index].outletBillDetails.length +
              billList[index + 1].outletBillDetails.length >
              18 &&
              billList[index].outletBillDetails.length +
              billList[index + 1].outletBillDetails.length <=
              20 ? (
              <>
                <div className="pagebreak"></div>
              </>
            ) : index + 1 < billList.length &&
              billList[index].outletBillDetails.length +
              billList[index + 1].outletBillDetails.length >
              16 ? (
              <>
                {" "}
                <div className="pagebreak"></div>
              </>
            ) : index + 1 < billList.length &&
              billList[index].outletBillDetails.length +
              billList[index + 1].outletBillDetails.length <=
              10 ? (
              <>
                {" "}
                <br></br>
                <br></br>
              </>
            ) : (
              <>

              </>
            )}

            <br></br>
          </>
        );
      })}
    </>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showNotification,
  showLoador,
};
export default connect(mapStateToProps, mapDispatchToProps)(MyComponent);

