import endpoint from "../../config/endpoints";

export const OrderListFooterJsons = {
  showSaveNextBtn: false,
  apiBaseURL: endpoint.room,
  formPath: "",
  screenTitle: "",
  showTitle: false,
  showCancel: false,
  showSaveBtn: false,
  fieldMeta: [],
};
