import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { imageUrl } from "../../config/Url";
import endpoint from "../../config/endpoints";
import { langugae } from "../../config/languageJSON";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";

import "./billPDFCss.css";
import moment from "moment";
const LoadsheetUpdated = ({ showLoador, showNotification }) => {
  const params = useParams();
  const [billList, setBill] = useState({});
  let lang = localStorage.getItem("lang");
  let loadsheetId = localStorage.getItem("loadSheetID");

  useEffect(() => {

    if (navigator.onLine) {
      lang = localStorage.getItem("lang");
      loadsheetId = localStorage.getItem("loadSheetID");

      let loadsheetDataUpdated = {
        outletBillHeaderIds: loadsheetId.split(","),
      }

      showLoador({ loador: true });
      apiPost({
        url: endpoint.outletBillHeader + "/dispatch-summary",
        postBody: loadsheetDataUpdated,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
        } else {
          console.log(data.data);

          setBill(data.data);
          window.print();
        }
      });


    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }, []);
  if (Object.keys(billList).length != 0) {
    let date;
    let dateForPDF = localStorage.getItem("dateForPDF");
    let dateToSet = JSON.parse(dateForPDF);
    let fromDate =
      dateToSet != null && dateToSet.fromdate
        ? dateToSet.fromdate
        : moment(new Date()).format("DD-MM-YYYY");
    let toDate =
      dateToSet != null && dateToSet.todate
        ? dateToSet.todate
        : moment(new Date()).format("DD-MM-YYYY");
    date = fromDate + " To " + toDate;
    let dataToSet = billList.dispatchSummaries;

    let finalTotalObj = {};
    let totalBox = 0;
    let totalPcs = 0;
    let totalQty = 0;
    let totalAmt = 0;

    return (
      <>
        <div style={{ marginRight: "20px", marginLeft: "20px" }}>
          <table style={{ width: "100%" }}>
            <tr>
              <table style={{ width: "100%" }}>
                <tr>
                  <td
                    style={{
                      textAlign: "left",
                      width: "70%",
                      borderLeft: "1px solid black",
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                      borderRight: "1px solid black",
                      borderCollapse: "collapse",
                      padding: "8px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: "10px",
                        fontSize: 20,
                        fontWeight: 600,
                      }}
                    >
                      {langugae[2].billDispatch ? langugae[2].billDispatch : ""}
                    </div>
                    <div className="headPadding">
                      {langugae[2].distName ? langugae[2].distName + " : " : ""}
                      {localStorage.getItem("distributorName")}
                    </div>
                    <div className="headPadding">
                      {langugae[2].beatName ? langugae[2].beatName + " : " : ""}{" "}
                      {billList.beatName ? billList.beatName : "-"}
                    </div>
                    <div className="headPadding">
                      {langugae[2].date ? langugae[2].date + " : " : ""}
                      {date}
                    </div>
                  </td>
                </tr>
              </table>
            </tr>
          </table>
          <br />
          <div style={{ fontSize: "18px" }}>
            <div style={{ display: "inline", fontWeight: 600 }}>
              {langugae[2].date ? langugae[2].date + " : " : ""}
            </div>

            {date}
          </div>
          <div style={{ borderCollapse: "collapse", marginTop: "8px" }}>
            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <thead>

                <tr style={{ borderTop: "1px solid black" }}>

                  <th
                    className="thsmallcell"
                    style={{
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                      width: "8%"
                    }}
                  >
                    {langugae[2].index ? langugae[2].index : "#"}
                  </th>

                  <th
                    className="thsmallcell"
                    style={{
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                    }}
                  >
                    {langugae[2].productName ? langugae[2].productName : ""}
                  </th>




                  <th
                    className="thsmallcell"
                    style={{
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                    }}
                  >
                    {langugae[2].box ? langugae[2].box : "BOX"}
                  </th>


                  <th
                    className="thsmallcell"
                    style={{
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                    }}
                  >
                    {langugae[2].pcs ? langugae[2].pcs : "PCS."}
                  </th>


                  <th
                    className="thsmallcell"
                    style={{
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                    }}
                  >
                    {langugae[2].mrp ? langugae[2].mrp : "MRP"}
                  </th>

                  <th
                    className="thsmallcell"
                    style={{
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                    }}
                  >
                    {langugae[2].totalQty ? langugae[2].totalQty : "Total Qty."}
                  </th>

                  <th
                    className="thsmallcell"
                    style={{
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                    }}
                  >
                    {langugae[2].rate ? langugae[2].rate : "Rate"}
                  </th>

                  <th
                    className="thsmallcell"
                    style={{
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                    }}
                  >
                    {langugae[2].totalAmt ? langugae[2].totalAmt : "Total Amount"}
                  </th>

                </tr>


              </thead>

              <tbody style={{ width: "100%" }}>
                {dataToSet && dataToSet.map((dataToSetObj, index) => {
                  totalBox = totalBox + +dataToSetObj.primaryQty;
                  totalPcs = totalPcs + +dataToSetObj.secondaryQty;
                  totalQty = totalQty + +dataToSetObj.totalQty;
                  totalAmt = totalAmt + (+dataToSetObj.rate * +dataToSetObj.totalQty);

                  let totalObj = {};
                  return (
                    <>

                      <tr>
                        <td
                          align={"center"}
                          style={{
                            borderLeft: "1px solid black",
                            fontWeight: 400,
                            borderRight: "1px solid black",
                          }}
                          className="tdsmallcell"
                        >
                          {index + 1}
                        </td>
                        <td
                          align={"left"}
                          style={{
                            borderLeft: "1px solid black",
                            fontWeight: 400,
                            borderRight: "1px solid black",
                          }}
                          className="tdsmallcell"
                        >
                          {dataToSetObj.productName}
                        </td>

                        <td
                          align={"right"}
                          style={{
                            borderLeft: "1px solid black",
                            fontWeight: 400,
                            borderRight: "1px solid black",
                          }}
                          className="tdsmallcell"
                        >
                          {Math.floor(dataToSetObj.primaryQty)}
                        </td>

                        <td
                          align={"right"}
                          style={{
                            borderLeft: "1px solid black",
                            fontWeight: 400,
                            borderRight: "1px solid black",
                          }}
                          className="tdsmallcell"
                        >
                          {dataToSetObj.secondaryQty}
                        </td>

                        <td
                          align={"right"}
                          style={{
                            borderLeft: "1px solid black",
                            fontWeight: 400,
                            borderRight: "1px solid black",
                          }}
                          className="tdsmallcell"
                        >
                          {dataToSetObj.mrp}
                        </td>

                        <td
                          align={"right"}
                          style={{
                            borderLeft: "1px solid black",
                            fontWeight: 400,
                            borderRight: "1px solid black",
                          }}
                          className="tdsmallcell"
                        >
                          {dataToSetObj.totalQty}
                        </td>

                        <td
                          align={"right"}
                          style={{
                            borderLeft: "1px solid black",
                            fontWeight: 400,
                            borderRight: "1px solid black",
                          }}
                          className="tdsmallcell"
                        >
                          {dataToSetObj.rate}
                        </td>

                        <td
                          align={"right"}
                          style={{
                            borderLeft: "1px solid black",
                            fontWeight: 400,
                            borderRight: "1px solid black",
                          }}
                          className="tdsmallcell"
                        >
                          {dataToSetObj.rate * +dataToSetObj.totalQty}
                        </td>
                      </tr>



                    </>
                  );
                })}
                <tr>
                  <th
                    style={{
                      textAlign: "center",
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {langugae[2].total ? langugae[2].total : "Total"}
                  </th>

                  <th
                    style={{
                      textAlign: "right",
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {""}
                  </th>
                  <th
                    style={{
                      textAlign: "right",
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {Math.round(totalBox)}
                  </th>

                  <th
                    style={{
                      textAlign: "right",
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {totalPcs}
                  </th>

                  <th
                    style={{
                      textAlign: "right",
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {""}
                  </th>

                  <th
                    style={{
                      textAlign: "right",
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {totalQty}
                  </th>

                  <th
                    style={{
                      textAlign: "right",
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {""}
                  </th>


                  <th
                    style={{
                      textAlign: "right",
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                      borderBottom: "1px solid black",
                    }}
                  >
                    {totalAmt}
                  </th>

                </tr>

              </tbody>
            </table>
          </div>
        </div >
      </>
    );
  }
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showNotification,
  showLoador,
};
export default connect(mapStateToProps, mapDispatchToProps)(LoadsheetUpdated);
