import endpoint from "../../config/endpoints";

export const DistributionReportsJson = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.area,
  screenTitle: "Reports",
  fieldMeta: [
    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      getListId: "area",
      dataKey: "area",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Area (Multiple)",
      controlType: "multiSelect",
      placeHolder: "Select Area (Multiple)",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      getListFrom: "zoneIds",
      dataKey: "areaIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Beat",
      controlType: "autocomplete",
      placeHolder: "Select Beat",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "beat",
      masterName: "beatDetail",
      getListFrom: "area",
      getListId: "beat",
      isMandatory: true,
      isRootLevelKey: false,
    },
    {
      label: "Outlet",
      controlType: "autocomplete",
      placeHolder: "Select Outlet",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "outletIds",
      getListFrom: "beat",
      masterName: "outletDetail",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Stock Holder Type",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder Type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stockStakeHolderTypeDetail",
      dataKey: "stockStakeHolderTypeId",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      isMandatory: true,
    },
  ],
};
