import endpoint from "../../config/endpoints";

export const TypeJson = {
    formPath: "/type-form",
    apiBaseURL: endpoint.type,
    screenTitle: "Type",
    showAddButton: true,
    showPdfDownload: true,
    pdfFileName: "Type",
    showExcelDownload: true,
    excelFileName: "Type",
    tableColumnsToFilter: [
        {
            columnDisplayName: "Name",
            columnKeyName: "name",
            isChecked: true,
        },


    ],
    fieldMeta: [
        {
            label: "Name",
            controlType: "textfield",
            placeHolder: "Enter Type Name",
            xs: 7,
            autoFocus: true,
            dataKey: "name",
            isMandatory: true,
            uniqueValidation: true,
        },
    ],
};