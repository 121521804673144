import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { compose } from "@reduxjs/toolkit";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import AutoComplete from "../../components/Comman/AutoComplete";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import {
  getActiveLanguage,
  getLanguageJson,
} from "../../Slice/multilingual.slice";
import { getAreaByUserType } from "../../Slice/area.slice";
import { getBeatByArea, getChangeActiveBeat } from "../../Slice/beat.slice";
import {
  getOutletByBeatId,
  getChangeActiveOutlet,
} from "../../Slice/outlet.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";

class OutletMultilingualForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      area: "",
      beat: "",
    };
  }

  async componentDidMount() {
    const {
      showLoador,
      showNotification,
      getActiveLanguage,
      getAreaByUserType,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getActiveLanguage().then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getAreaByUserType().then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  handleChange = (e, row) => {
    const { name, value } = e.target;
    const { outletList, getChangeActiveOutlet } = this.props;
    let newList = [];
    outletList.outlets.map((beatObject) => {
      if (beatObject.id == row.id) {
        beatObject = {
          ...beatObject,
          [name]: value,
        };
        newList.push(beatObject);
      } else {
        newList.push(beatObject);
      }
    });
    getChangeActiveOutlet({ row: newList });
  };
  submitHandler = () => {
    const { outletList, multilingualList, showNotification } = this.props;
    console.log(multilingualList.language);
    const { area, beat } = this.state;
    let newDataToSave = {
      type: 3,
      areaBeatId: beat,
    };
    let newObjectList = [];
    outletList.outlets.map((beatObject) => {
      let beatSaveObject1 = {};
      for (let i = 0; i < multilingualList.language.length; i++) {
        let beatSaveObject2 = {};
        Object.keys(multilingualList.languageJson[0].jsonList[0]).map(
          (languageObject) => {
            beatSaveObject2 = {
              ...beatSaveObject2,
              [languageObject]:
                beatObject[languageObject + multilingualList.language[i].name],
            };
          }
        );
        beatSaveObject1 = {
          ...beatSaveObject2,
          id: beatObject.id,
          language: multilingualList.language[i],
        };
        newObjectList.push(beatSaveObject1);
      }
    }),
      (newDataToSave = {
        ...newDataToSave,
        json: newObjectList,
      });
    console.log(newDataToSave);
    if (newObjectList.length !== 0) {
      swal({
        title: "Are you sure?",
        text: myConstClass.saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.saveHandler(newDataToSave);
        }
      });
    } else {
      showNotification({
        msg: myConstClass.enterValueMsg,
        severity: "error",
      });
    }
  };
  saveHandler = (newDataToSave) => {
    const { showNotification, showLoador, navigate } = this.props;
    showLoador({ loador: true });
    apiPost({
      url: endpoint.multilingual + "-json",
      postBody: newDataToSave,
    }).then(({ success }) => {
      showLoador({ loador: false });
      if (success) {
        showNotification({
          msg: "Data saved successfully",
        });
        // window.location.replace("/outlet-multilingual-form");
      } else {
        showNotification({ msg: serverMsg, severity: "error" });
      }
    });
  };
  goBack = () => {
    this.props.navigate(-1);
  };
  onAutocompleteChange = (name, newValue) => {
    const {
      getBeatByArea,
      getChangeActiveOutlet,
      getLanguageJson,
      showLoador,
      showNotification,
      getOutletByBeatId,
      multilingualList,
    } = this.props;
    if (newValue != null) {
      this.setState({
        [name]: newValue,
      });
      if (name == "area") {
        showLoador({ loador: true });
        getBeatByArea({ areaId: newValue }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        });
      } else {
        showLoador({ loador: true });
        getOutletByBeatId({ beatId: newValue }).then(
          ({ response, success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            } else {
              showLoador({ loador: true });
              getLanguageJson({ id: newValue }).then(
                ({ responseData, success }) => {
                  if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                    showLoador({ loador: false });
                  } else {
                    let row = [];
                    if (responseData !== null && responseData.length !== 0) {
                      if (
                        responseData[0].jsonList !== null &&
                        responseData[0].jsonList.length !== 0
                      ) {
                        response.map((beatData) => {
                          responseData[0].jsonList.map((jsonData) => {
                            if (beatData.id == jsonData.id) {
                              if (
                                jsonData.language !== null &&
                                jsonData.language.id !== ""
                              ) {
                                Object.keys(jsonData).map((jsonDataObject) => {
                                  if (
                                    jsonDataObject !== "id" &&
                                    jsonDataObject !== "language"
                                  ) {
                                    {
                                      multilingualList.language.map(
                                        (langObject) => {
                                          beatData = {
                                            ...beatData,
                                            [jsonDataObject + langObject.name]:
                                              jsonData[jsonDataObject],
                                          };
                                        }
                                      );
                                    }
                                  }
                                });

                                // beatData = {
                                //   ...beatData,

                                //   languageMarathi:
                                //     jsonData.language.id == "0"
                                //       ? jsonData.name
                                //       : beatData.languageMarathi,
                                //   languageHindi:
                                //     jsonData.language.id == "1"
                                //       ? jsonData.name
                                //       : beatData.languageHindi,
                                //   languageEnglish:
                                //     jsonData.language.id == "2"
                                //       ? jsonData.name
                                //       : beatData.languageEnglish,
                                //   [Object.keys(jsonData).map(
                                //     (jsonDataObject) => {
                                //       return jsonDataObject;
                                //     }
                                //   )]: Object.keys(jsonData).map(
                                //     (jsonDataObject) => {
                                //       return jsonDataObject;
                                //     }
                                //   ),
                                // };
                              }
                            }
                          });
                          row.push(beatData);
                        });
                        getChangeActiveOutlet({ row: row });
                      }
                    }
                    showLoador({ loador: false });
                  }
                }
              );
            }
          }
        );
      }
    } else {
      this.setState({
        [name]: "",
      });
    }
  };
  render() {
    const { multilingualList, beatList, areaList, outletList } = this.props;
    const { area, beat } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <Grid container justifyContent="flex-start" direction="row">
            <TitleAndBreadCrum title={"Language Json For Outlet"} />
          </Grid>
        </Paper>
        <br />
        <Paper
          sx={{
            p: 2,
            pt: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            spacing={1}
            justifyContent="space-evenly"
          >
            {" "}
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label=" *Area"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <AutoComplete
                  keyColName={"id"}
                  name={"area"}
                  value={area}
                  options={areaList.area}
                  placeHolder="Select Area"
                  onChange={this.onAutocompleteChange}
                  isError={false}
                  errorText={" "}
                />
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label=" *Beat"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <AutoComplete
                  keyColName={"id"}
                  name={"beat"}
                  value={beat}
                  options={beatList.beat}
                  placeHolder="Select beat"
                  onChange={this.onAutocompleteChange}
                  isError={false}
                  errorText={" "}
                />
              </Grid>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" width="10%">
                    Sr. No.
                  </TableCell>
                  <TableCell width="30%">Outlet Name</TableCell>
                  {multilingualList.language.map((langObject) => {
                    return (
                      <>
                        {multilingualList.languageJson.length !== 0 &&
                          multilingualList.languageJson[0].jsonList.length !==
                            0 &&
                          Object.keys(
                            multilingualList.languageJson[0].jsonList[0]
                          ).map((languageObject) => {
                            if (
                              languageObject !== "id" &&
                              languageObject !== "language"
                            ) {
                              return (
                                <>
                                  <TableCell align="center" width="10%">
                                    {[languageObject] +
                                      " in " +
                                      [langObject.name]}
                                  </TableCell>
                                </>
                              );
                            }
                          })}
                      </>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {outletList.outlets.map((row, index) => (
                  <>
                    <TableRow key={row.name}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      {multilingualList.language.map((langObject) => {
                        return (
                          <>
                            {multilingualList.languageJson.length !== 0 &&
                              multilingualList.languageJson[0].jsonList
                                .length !== 0 &&
                              Object.keys(
                                multilingualList.languageJson[0].jsonList
                              ).map((languageObject) => {
                                if (
                                  languageObject !== "id" &&
                                  languageObject !== "language"
                                ) {
                                  return (
                                    <>
                                      <TableCell>
                                        <TextFieldCompo
                                          color="primary"
                                          name={
                                            languageObject + langObject.name
                                          }
                                          placeHolder={
                                            "Enter " +
                                            langObject.name +
                                            " Transalation"
                                          }
                                          size="small"
                                          value={
                                            row[
                                              languageObject + langObject.name
                                            ]
                                          }
                                          onChange={(event) =>
                                            this.handleChange(event, row)
                                          }
                                          fullWidth
                                          helperText={""}
                                        />
                                      </TableCell>
                                    </>
                                  );
                                }
                              })}
                          </>
                        );
                      })}
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          &nbsp;&nbsp; &nbsp;&nbsp;
          <div align="right">
            <ButtonCompo
              size="button"
              type="button"
              variant="outlined"
              name="Cancel"
              onClick={this.goBack}
            />
            &nbsp;&nbsp;
            <ButtonCompo
              size="button"
              type="button"
              variant="contained"
              name="Submit"
              onClick={this.submitHandler}
            />
          </div>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  multilingualList: state.multilingual,
  productList: state.product,
  areaList: state.area,
  beatList: state.beat,
  outletList: state.outlet,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getActiveLanguage,
  getLanguageJson,
  getAreaByUserType,
  getBeatByArea,
  getChangeActiveBeat,
  getOutletByBeatId,
  getChangeActiveOutlet,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OutletMultilingualForm);

// {multilingualList.language.map((languageObject) => {
//   return (
//     <>
//       {Object.keys(outletList.outlets[0]).map(
//         (outletObject) => {
//           if (
//             outletList.outlets[0].hasOwnProperty(
//               outletObject
//             ) &&
//             outletObject !== "id"
//           )
//             return (
//               <>
//                 <TableCell align="center" width="10%">
//                   {outletList.outlets[0][outletObject]}
//                 </TableCell>
//               </>
//             );
//         }
//       )}
//     </>
//   );
// })}
