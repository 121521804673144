
import { apiGet } from "../utils/api_service";
import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoint from "../config/endpoints";

const initialState = {
    dashboardApi: {},
};
let URL = endpoint.managerDashboard;
const managerDashboardSlice = createSlice({
    name: "managerDashboard",
    initialState,
    reducers: {
        dashboardApiSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                dashboardApi: row,
                isFetch: true,
            };
        },

        resetState: (state = cloneDeep(initialState), action) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const { dashboardApiSuccess, resetState } =
    managerDashboardSlice.actions;

export default managerDashboardSlice.reducer;

export const getManagerDashboardApi =
    ({ date }) =>
        async (dispatch) => {
            try {
                const response = apiGet({
                    url: URL + "/mobile?date=" + date,
                }).then(({ data, success }) => {
                    if (success) {
                        let list = Object.values(data.data.dtoManagerLogsCount)
                        let dataList = [];
                        const dashboardData = {
                            ...data.data,
                            dataList: list.slice(1),
                        };
                        console.log(dashboardData);


                        dispatch(dashboardApiSuccess({ row: dashboardData }));
                        return { response: dashboardData, success };
                    }
                    return { response: [], success };
                });
                return response;
            } catch (e) {
                return console.error(e.message);
            }
        };