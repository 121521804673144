import React from "react";
import { connect } from "react-redux";
import { batchwiseManageStockJSON } from "../../DynamicFormsJson/Transaction/batchwiseManageInstock";
import { getManageStockList } from "../../Slice/manageStock.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import endpoint from "../../config/endpoints";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { BatchwiseMangeStockColumns } from "../../tableColumns/table-columns";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";

class BatchwiseManageStockTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSearchData = async (data) => {
    const { getManageStockList, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getManageStockList({
        fromdate: data.fromdate,
        todate: data.todate,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  rowPDF = (rowData) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      downloadF({
        url:
          endpoint.manageStockHeader +
          "/report-by-id?id=" +
          rowData.id +
          "&documentType=1",
        ext: "pdf",
        openNewTab: true,
      }).then((response) => {
        showLoador({ loador: false });
        if (!response) {
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
        } else {
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const { dynamicMasterData } = this.state;
    const { manageStockList } = this.props;
    return (
      <>
        <DynamicMainScreen
          dynamicMasterData={dynamicMasterData}
          searchList={batchwiseManageStockJSON.searchList}
          onSearchData={this.onSearchData}
          formPathView={batchwiseManageStockJSON.formPathView}
          formPath={batchwiseManageStockJSON.formPath}
          screenTitle={batchwiseManageStockJSON.screenTitle}
          fieldMeta={batchwiseManageStockJSON.fieldMeta}
          getListById={this.getListById}
          showPdfDownload={false}
          showExcelDownload={false}
          isActiveURL={batchwiseManageStockJSON.isActiveURL}
          showAddButton={batchwiseManageStockJSON.showAddButton}
          tableColumnsToFilter={batchwiseManageStockJSON.tableColumnsToFilter}
          tableColumns={BatchwiseMangeStockColumns}
          tableData={manageStockList.manageStockByDate.filter(
            (row) => row.type == "In-Stock"
          )}
          apiBaseURL={batchwiseManageStockJSON.apiBaseURL}
          callApi={false}
          getTableDataById={true}
          showSearchBox={false}
          rowPDF={this.rowPDF}
          showPdf={true}
          showViewButtonInTable={true}
          showPegination={false}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  manageStockList: state.manageStockByDate,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getManageStockList,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BatchwiseManageStockTable);
