import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { manageStockProductWise } from "../../DynamicFormsJson/Transaction/manageStockProductWise";
import {
  getCategory,
  getCategoryForStakeholderByStockHolderId,
} from "../../Slice/category.slice";
import { getItemwiseBatchByProductAndStockHolderIdNew } from "../../Slice/manageStock.slice";
import {
  getProductByCategoryIdAndStockHolderIdForStakeHolder,
  getProductBySubCategory,
} from "../../Slice/product.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { DarkBlue } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  deleteWarningMsg,
  noInternetMsg,
  saveFailedMsg,
  saveWarningMsg,
  savemsg,
  serverMsg,
} from "../../config/messageconstant";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import CreditNoteHeaderForm from "../Request Batchwise GRN/RequestBatchwiseGRNHeaderForm";
import { getSubCategoryByCategory } from "../../Slice/subCategory.slice";
import { getItemwiseBatch } from "../../Slice/manageStock.slice";
import dayjs from "dayjs";


class ManageStockProductWise extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowList: [],
      formErrors: {},
      stockHolderId: "",
      primaryClosingQtyForBatch: 0,
      rowListFlag: false,
      fieldData: { stockType: "1" },
      productStock: [],
      dynamicMasterData: {
        categoryDetail: this.props.categoryList.category,
        subCategoryDetail: this.props.subCategoryList?.subCategory,
        productDetail: this.props.productList.activeAllProduct,
        batchDetail: this.props.batchByProductList.itemwiseBatchData,
      },
    };
  }

  async componentDidMount() {
    const { showLoador, getCategory, showNotification } = this.props;
    const { fieldData } = this.state;
    if (navigator.onLine) {
      this.clearDetails();

      showLoador({ loador: true });
      await getCategory().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      apiGet({
        url: endpoint.manageStockHeader + "/get-stock-by-current-date",
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          this.setState({
            productStock: data.data.productStocks,
          });
        }
      });
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  }


  clearDetails() {
    this.setState({
      rowList: [],
    });
  }

  componentWillReceiveProps(nextProps) {

    if (this.props.categoryList !== nextProps.categoryList) {
      if (
        this.props.categoryList.category !== nextProps.categoryList.category
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            categoryDetail: nextProps.categoryList.category,
          },
        });
      }
    }
    if (this.props.subCategoryList !== nextProps.subCategoryList) {
      if (
        this.props.subCategoryList?.subCategory !==
        nextProps.subCategoryList?.subCategory
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            subCategoryDetail: nextProps.subCategoryList?.subCategory,
          },
        });
      }
    }

    if (this.props.productList !== nextProps.productList) {
      if (
        this.props.productList.activeAllProduct !==
        nextProps.productList.activeAllProduct
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            productDetail: nextProps.productList.activeAllProduct,
          },
        });
      }
    }

    if (this.props.batchByProductList !== nextProps.batchByProductList) {
      if (
        this.props.batchByProductList.itemwiseBatchData !==
        nextProps.batchByProductList.itemwiseBatchData
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            batchDetail: nextProps.batchByProductList.itemwiseBatchData.filter((row) => row.primaryClosingQty > 0),
          },
        });
      }
    }
  }

  getListById = async (data) => {
    const {
      showLoador,
      showNotification,
      getSubCategoryByCategory,
      getProductBySubCategory,
      getItemwiseBatch,
    } = this.props;
    const { rowListFlag, primaryClosingQtyForBatch } = this.state;
    if (navigator.onLine) {
      let qtyForBtch = 0;
      if (data.category) {
        showLoador({ loador: true });
        await getSubCategoryByCategory({
          categoryId: data.category,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }

      if (data.subCategory) {
        showLoador({ loador: true });
        await getProductBySubCategory({
          subCategoryId: data.subCategory,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.product) {
        showLoador({ loador: true });
        await getItemwiseBatch({
          orderDate: dayjs().format("DD-MM-YYYY"),
          productId: data.product,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        });
      }
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  onSearch = (data) => {
    const { fieldData, rowList, dynamicMasterData } = this.state;

    let catList = dynamicMasterData["categoryDetail"]
      ? dynamicMasterData["categoryDetail"].filter(
        (row) => row.id === data.category
      )
      : [];

    let subCatList = dynamicMasterData["subCategoryDetail"]
      ? dynamicMasterData["subCategoryDetail"].filter(
        (row) => row.id === data.subCategory
      )
      : [];

    let productList = dynamicMasterData["productDetail"]
      ? dynamicMasterData["productDetail"].filter(
        (row) => row.id === data.product
      )
      : [];

    let batchList = dynamicMasterData["batchDetail"]
      ? dynamicMasterData["batchDetail"].filter(
        (row) => row.id === data.batch
      )
      : [];



    this.setState({
      rowList: [
        ...rowList,
        {
          index: rowList.length + 1,
          categoryName: catList.length !== 0 ? catList[0].name : "-",
          subCategoryName: subCatList.length !== 0 ? subCatList[0].name : "-",
          productName: productList.length !== 0 ? productList[0].name : "-",
          batchCode:
            fieldData.stockType === "1"
              ? data.fromDate + "/" + data.batch + "/" + data.toDate
              : data.batch,
          batchForOutStock: data.batch,
          productObj: productList.length !== 0 ? productList[0] : "",
          hsnCode:
            productList.length !== 0
              ? productList[0].taxPercentage.hsnCode
              : "-",
          mrp: productList.length !== 0 ? productList[0].mrp : "-",
          uom: productList.length !== 0 ? productList[0].stakeHolderUomName : "-",
          stakeHolderUom: productList.length !== 0 ? productList[0].stakeHolderUom : "-",
          tax: productList.length !== 0 ? productList[0].taxPercentage.id : "-",
          uomConversionValue:
            productList.length !== 0 ? productList[0].uomConversionValue : "-",
          ...data,
        },
      ],
    });
  };


  onSubmitData = () => {
    if (this.checkValidationOnSubmit()) {
      const { rowList, fieldData, stockHolderId } = this.state;
      if (rowList.length != 0) {
        const stockStakeHolderConsumptionDetails = rowList.map((orderData) => {
          return {
            product: orderData.productObj,
            qty: orderData.qty * orderData.productObj.uomConversionValue,
            primaryQty: orderData.qty * orderData.productObj.uomConversionValue,
            rate: orderData.rate != "" ? orderData.rate : 0,
            mrp: orderData.mrp != "" ? orderData.mrp : 0,
            batchCode: fieldData.stockType == "1" ? orderData.fromDate + "/" + orderData.batch + "/" + orderData.toDate : orderData.batch,
          };
        });
        let dataToSave = {
          remark: "NA",
          type: fieldData.stockType,
          stockStakeHolderConsumptionDetails:
            stockStakeHolderConsumptionDetails,
          date: fieldData.date.format("DD-MM-YYYY"),
        };
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            this.onSave(dataToSave);
          }
        });
      } else {
        this.props.showNotification({
          msg: "Please add atleast one product",
          severity: "error",
        });
      }
    }
  };

  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.manageStockHeader,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        if (success) {
          showLoador({ loador: false });
          this.props.navigate("/manage-stock");
          showNotification({ msg: savemsg });
        } else {
          showLoador({ loador: false });
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  mandatoryCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    manageStockProductWise.fieldMeta.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&
              fieldData[currentField.dataKey].id == null))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };

  ValidationCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    manageStockProductWise.fieldMeta.forEach((currentField) => {
      if (currentField.valid) {
        if (
          currentField.validName === "email" &&
          !validEmail.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "zero" &&
          fieldData[currentField.dataKey] === 0
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };

  checkValidationOnSubmit = () => {
    if (this.mandatoryCheck()) {
      return this.ValidationCheck();
    } else {
      return false;
    }
  };

  onDataChange = (fieldName, newValue) => {

    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;

    if (fieldName == "stockType") {
      this.clearDetails();
    }

    manageStockProductWise.fieldMeta.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };

  getDataForDisabled = (value) => {
    const { productStock, rowList, dynamicMasterData, fieldData } = this.state;
    const { batchByProductList } = this.props;

    let productList = dynamicMasterData["productDetail"]
      ? dynamicMasterData["productDetail"].filter((row) => row.id == value)
      : [];


    let batchList = dynamicMasterData["batchDetail"]
      ? dynamicMasterData["batchDetail"].filter((row) => row.id == value)
      : [];

    let productStockList = productStock.filter(
      (row) => row.product.id == value
    );


    if (fieldData.stockType == "0") {
      if (batchList.length != 0) {
        let totQty = 0;
        if (rowList.length != 0) {
          rowList.map((rowbject) => {
            if (rowbject.batch == batchList[0].id) {
              return totQty = totQty + +rowbject.qty
            }
          })
          let primaryClosingQtyForBatch = batchList.length != 0 && batchList[0].primaryClosingQty - totQty
          this.setState({
            primaryClosingQtyForBatch: primaryClosingQtyForBatch,
            rowListFlag: true,
          })

          return {
            primaryClosingQty:
              batchList.length != 0 && batchList[0].primaryClosingQty - totQty,
            batchQty: batchList.length != 0 && batchList[0].primaryClosingQty,
          };

        } else {
          return {
            primaryClosingQty:
              batchList.length != 0 && batchList[0].primaryClosingQty,
            batchQty: batchList.length != 0 && batchList[0].primaryClosingQty,
          };
        }
      }
      if (productList.length != 0) {
        return {
          rate: productList.length != 0 && productList[0].rate,
          mrp: productList.length != 0 && productList[0].mrp,
          orderQty: productList.length != 0 && productList[0].orderQty,
          uom: productList.length != 0 && productList[0].stakeHolderUom,
          uomConversionValue:
            productList.length != 0 && productList[0].uomConversionValue,
        };
      }
    } else {
      if (productStockList.length != 0) {
        return {
          primaryClosingQty:
            productStockList.length != 0 && productStockList[0].primaryClosingQty,
          rate: productStockList.length != 0 && productStockList[0].product.rate,
        };
      }
    }

  };

  getDataForCalculate = (value, fieldData) => {
    const { dynamicMasterData, rowList, productId } = this.state;
    let batchList = dynamicMasterData["batchDetail"]
      ? dynamicMasterData["batchDetail"].filter(
        (row) => row.id == fieldData.batch
      )
      : [];
    // if (rowList.length != 0) {
    //   let newQty = fieldData.batchQty;
    //   let newFreeQty = fieldData.qty;
    //   rowList.map((row) => {
    //     if (productId == row.productId && row.batch == fieldData.batch) {
    //       if (batchList.length != 0 && fieldData.batch) {
    //         let newBatchList = batchList.filter(
    //           (rowData) => rowData.id == row.batch
    //         );
    //         if (newBatchList.length != 0) {
    //           newQty = newQty - +row.qty;
    //           newFreeQty = newQty - +row.freeQty;
    //         }
    //       }
    //     }
    //   });
    //   return {
    //     primaryClosingQty: (+newQty - +value).toString(),
    //     primaryQty: newQty,
    //   };
    // } else if (fieldData.batch) {
    //   if (batchList.length != 0) {
    //     return {
    //       primaryClosingQty: (
    //         +batchList[0].primaryClosingQty - value
    //       ).toString(),
    //       primaryQty: +batchList[0].primaryClosingQty,
    //     };
    //   }
    // }
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: deleteWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const rowListData = this.state.rowList.filter(
          (row) => row.index != rowData.index
        );
        const row = rowListData.map((rowListDataObj, index) => {
          return { ...rowListDataObj, index: index + 1 };
        });
        this.setState({
          rowList: row,
        });
      }
    });
  };

  render() {
    const { dynamicMasterData, rowList, formErrors, fieldData } = this.state;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <CreditNoteHeaderForm
            getListById={this.getListById}
            dynamicMasterData={dynamicMasterData}
            screenTitle={manageStockProductWise.screenTitle}
            fieldMeta={manageStockProductWise.fieldMeta}
            apiBaseURL={manageStockProductWise.apiBaseURL}
            formErrors={formErrors}
            showSaveBtn={manageStockProductWise.showSaveBtn}
            showSaveBtnMain={false}
            fieldData={fieldData}
            onDataChange={this.onDataChange}
          />
          <LabelCompo
            className="text-black"
            style={{ fontSize: "20px", color: DarkBlue }}
            label={"Add Product"}
          />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            sx={{ paddingTop: 2 }}
          >
            <DynamicFormWithoutSave
              showSaveBtn={true}
              showSaveBtnMain={false}
              dynamicMasterData={dynamicMasterData}
              getListById={this.getListById}
              searchList={manageStockProductWise.searchList}
              showTitle={manageStockProductWise.showTitle}
              screenTitle={manageStockProductWise.screenTitle}
              fieldMeta={fieldData.stockType == "1" ? manageStockProductWise.fieldMetaDetails : manageStockProductWise.fieldMetaOutStock}
              showCancel={manageStockProductWise.showCancel}
              showBackToList={manageStockProductWise.showBackToList}
              apiBaseURL={manageStockProductWise.apiBaseURL}
              showSaveNextBtn={manageStockProductWise.showSaveNextBtn}
              saveBtnText={"ADD"}
              onSave={this.onSearch}
              resetAfterSave={true}
              getDataForDisabled={this.getDataForDisabled}
              getDataForCalculate={this.getDataForCalculate}
            />
          </Grid>

          <br />
          <RTTable
            showPegination={false}
            columns={[
              { name: "index", title: "Sr. No.", align: "center" },
              { name: "categoryName", title: "Category" },
              { name: "subCategoryName", title: "Sub-Category" },
              { name: "productName", title: "Product" },
              { name: "batchCode", title: "Batch", align: "left" },
              { name: "qty", title: "Quantity", align: "right" },
              { name: "action", title: "Action" },
            ]}
            tableData={rowList}
            showEditIcon={false}
            isActionColActive={true}
            showDeleteIcon={true}
            rowDelete={this.rowDelete}
          />
          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="right"
          >
            <ButtonCompo
              size="medium"
              type="Submit"
              variant="contained"
              name="Submit"
              onClick={this.onSubmitData}
            />
          </Grid>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  categoryList: state.category,
  subCategoryList: state.subCategory,
  productList: state.product,
  batchByProductList: state.manageStockByDate,
  stakeholdersList: state.stackholder,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getCategory,
  getProductBySubCategory,
  getItemwiseBatchByProductAndStockHolderIdNew,
  getProductByCategoryIdAndStockHolderIdForStakeHolder,
  getCategoryForStakeholderByStockHolderId,
  getSubCategoryByCategory,
  getItemwiseBatch,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ManageStockProductWise);
