import endpoint from "../../config/endpoints";

export const targetJson = {
  formPath: "/target-form",
  apiBaseURL: endpoint.target,
  screenTitle: "Target Projection",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Target Projection",
  showExcelDownload: true,
  excelFileName: "Target Projection",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Jan",
      columnKeyName: "jan",
      isChecked: true,
    },
    {
      columnDisplayName: "Feb",
      columnKeyName: "feb",
      isChecked: true,
    },
    {
      columnDisplayName: "March",
      columnKeyName: "march",
      isChecked: true,
    },
    {
      columnDisplayName: "April",
      columnKeyName: "april",
      isChecked: true,
    },
    {
      columnDisplayName: "May",
      columnKeyName: "may",
      isChecked: true,
    },
    {
      columnDisplayName: "Jun",
      columnKeyName: "jun",
      isChecked: true,
    },
    {
      columnDisplayName: "July",
      columnKeyName: "july",
      isChecked: true,
    },
    {
      columnDisplayName: "Aug",
      columnKeyName: "aug",
      isChecked: true,
    },
    {
      columnDisplayName: "Sep",
      columnKeyName: "sep",
      isChecked: true,
    },
    {
      columnDisplayName: "Oct",
      columnKeyName: "oct",
      isChecked: true,
    },
    {
      columnDisplayName: "Nov",
      columnKeyName: "nov",
      isChecked: true,
    },
    {
      columnDisplayName: "Dec",
      columnKeyName: "dec",
      isChecked: true,
    },
    {
      columnDisplayName: "Total",
      columnKeyName: "total",
      isChecked: true,
    },
  ],
  searchList: [
    {
      label: "Stock Holder Type",
      controlType: "autocomplete/textfield",
      placeHolder: "Select Stock Holder Type",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      masterName: "stockStakeHolderTypeDetail",
      dataKey: "stockStakeHolderType",
      isRootLevelKey: true,
      isMandatory: true,
    },

    {
      label: "Year",
      controlType: "autocomplete/textfield",
      placeHolder: "Select Year",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      masterName: "yearDetail",
      dataKey: "name",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],

  fieldMeta: [
    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 5.5,
      lg: 5.5,
      sm: 5.5,
      xs: 12,
      masterName: "regionDetail",
      dataKey: "region",
      getListId: "region",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "State",
      controlType: "autocomplete",
      placeHolder: "Select State",
      md: 5.5,
      lg: 5.5,
      sm: 5.5,
      xs: 12,
      masterName: "stateDetail",
      dataKey: "state",
      getListFrom: "region",
      getListId: "state",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "multiSelect",
      placeHolder: "Select Zone",
      md: 5.5,
      lg: 5.5,
      sm: 5.5,
      xs: 12,
      masterName: "zoneDetail",
      dataKey: "zone",
      getListFrom: "state",
      getListId: "zone",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "District",
      controlType: "multiSelect",
      placeHolder: "Select District",
      md: 5.5,
      lg: 5.5,
      sm: 5.5,
      xs: 12,
      masterName: "districtDetail",
      dataKey: "district",
      getListFrom: "zone",
      getListId: "district",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Taluka",
      controlType: "multiSelect",
      placeHolder: "Select Taluka",
      md: 5.5,
      lg: 5.5,
      sm: 5.5,
      xs: 12,
      masterName: "talukaDetail",
      dataKey: "taluka",
      getListFrom: "district",
      getListId: "taluka",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area",
      md: 5.5,
      lg: 5.5,
      sm: 5.5,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "area",
      getListFrom: "taluka",
      getListId: "area",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Stock Holder Type",
      controlType: "autocomplete/textfield",
      placeHolder: "Select Stock Holder Type",
      md: 5.5,
      lg: 5.5,
      sm: 5.5,
      xs: 12,
      masterName: "stockStakeHolderTypelistDetail",
      dataKey: "stockStakeHolderTypeId",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Year",
      controlType: "autocomplete/textfield",
      placeHolder: "Select Year",
      md: 5.5,
      lg: 5.5,
      sm: 5.5,
      xs: 12,
      masterName: "yearDetail",
      dataKey: "name",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Denomination",
      controlType: "radiogroup",
      md: 5.5,
      lg: 5.5,
      sm: 5.5,
      xs: 12,
      dataKey: "type",
      tableDataKey: "type",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "K",
          value: "0",
        },
        {
          label: "L",
          value: "1",
        },
        {
          label: "CR",
          value: "2",
        },
      ],
      isMandatory: true,
      getListId: "type",
    },
  ],

  fieldMetaStockHolder: [
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area",
      md: 5.5,
      lg: 5.5,
      sm: 5.5,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "area",
      getListId: "area",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Stock Holder Type",
      controlType: "autocomplete/textfield",
      placeHolder: "Select Stock Holder Type",
      md: 5.5,
      lg: 5.5,
      sm: 5.5,
      xs: 12,
      masterName: "stockStakeHolderTypelistDetail",
      dataKey: "stockStakeHolderTypeId",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Year",
      controlType: "autocomplete/textfield",
      placeHolder: "Select Year",
      md: 5.5,
      lg: 5.5,
      sm: 5.5,
      xs: 12,
      masterName: "yearDetail",
      dataKey: "name",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Denomination",
      controlType: "radiogroup",
      md: 5.5,
      lg: 5.5,
      sm: 5.5,
      xs: 12,
      dataKey: "type",
      tableDataKey: "type",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "K",
          value: "0",
        },
        {
          label: "L",
          value: "1",
        },
        {
          label: "CR",
          value: "2",
        },
      ],
      isMandatory: true,
      getListId: "type",
    },
  ],
};
