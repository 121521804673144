import { FormControl } from "@mui/base";
import CloseIcon from "@mui/icons-material/Close";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { pink } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { compose } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import DynamicFormWithoutValidation from "../../components/dynamicscreens/DynamicFormWithoutValidation";
import { withRouter } from "../../components/withRouter";
import { checkboxShadowColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { batchwiseStakeholderBillJson } from "../../DynamicFormsJson/Transaction/batchwiseStakeholderBill";
import { getStockStackHolders } from "../../Slice/StackHolder.slice";
import { getActiveTransporterListByUserTypeUpdated } from "../../Slice/transporter.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import BatchwiseStakeHolderPopUpForOffer from "./BatchwiseStakeHolderPopUpForOffer";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

class BillingWithOffer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      checkboxAllData: [],
      offerBillNewData: {},
      totalQty: 0,
      billOfferId: "",
      stockStakeHolderBillHeader: "",
      openBill: false,
      previousListOfDetails: [],
      sameAsBillTo: 1,
      vehicleNo: "",
      firmName: "",
      address: "",
      poNo: "",
      transporter: "",
      transporterId: "",
      eWayBillNo: "",
      lrRrGrNo: "",
      city: "",
      state: "",
      gstin: "",
      poDate: dayjs(),
      lrRrGrDate: dayjs(),
      eWayBillDate: dayjs(),
      name: "",
      formErrors: {},
      fieldData: {
        poDate: dayjs(),
        lrRrGrDate: dayjs(),
        eWayBillDate: dayjs(),
        sameAsBillTo: "1",
      },
      dynamicMasterData: {
        stakeholdersDetail: this.props.stakeholdersList.stockStakeHolders,
        transporterDetail: this.props.transporterList?.transporter,
      },
    };
  }
  async componentDidMount() {
    const {
      offerBillStockStakeHolderData,
      showLoador,
      getStockStackHolders,
      getActiveTransporterListByUserTypeUpdated,
      showNotification,
    } = this.props;
    const { previousListOfDetails } = this.state;
    let totalQty = 0;

    showLoador({ loador: true });
    await getStockStackHolders().then(({ success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      }
    });
    showLoador({ loador: true });
    await getActiveTransporterListByUserTypeUpdated().then(({ success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      }
    });
    offerBillStockStakeHolderData.stockStakeHolderBillHeader.stockStakeHolderBillDetails.map(
      (details) => {
        totalQty = totalQty + +details.qty;
        return {
          totalQty,
        };
      }
    );

    this.setState({
      totalQty: totalQty,
      offerBillNewData: offerBillStockStakeHolderData,
      stockStakeHolderBillHeader:
        offerBillStockStakeHolderData.stockStakeHolderOrderHeader,
      previousListOfDetails:
        offerBillStockStakeHolderData.stockStakeHolderBillsAfterApplyingOffers
          .stockStakeHolderBillDetails,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.stakeholdersList !== nextProps.stakeholdersList) {
      if (
        this.props.stakeholdersList?.stockStakeHolders !==
        nextProps.stakeholdersList?.stockStakeHolders
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeholdersDetail: nextProps.stakeholdersList?.stockStakeHolders,
          },
        });
      }
    }
    if (this.props.transporterList !== nextProps.transporterList) {
      if (
        this.props.transporterList?.transporter !==
        nextProps.transporterList?.transporter
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            transporterDetail: nextProps.transporterList?.transporter,
          },
        });
      }
    }
  }

  clickSubmitOfferHandler = () => {
    const { offerBillNewData, name, fieldData } = this.state;
    const { showLoador, showNotification, stakeholdersList, transporterList } =
      this.props;
    console.log(fieldData);
    let selectedData = stakeholdersList.stockStakeHolders.filter(
      (row) => row.id == fieldData.stakeholder
    );
    let selectedDataForTransporter = transporterList.transporter.filter(
      (row) => row.id == fieldData.transporter
    );
    console.log(selectedData);
    console.log(selectedDataForTransporter);
    let vehicleData = {
      poNo: fieldData.poNo ? fieldData.poNo : "",
      poDate: fieldData.poDate ? fieldData.poDate : "",
      transporterId: selectedDataForTransporter
        ? selectedDataForTransporter.gstNo ||
        (selectedDataForTransporter[0]
          ? selectedDataForTransporter[0].gstNo
          : "")
        : "",
      transporter: selectedDataForTransporter
        ? selectedDataForTransporter.name ||
        (selectedDataForTransporter[0]
          ? selectedDataForTransporter[0].name
          : "")
        : "",
      lrRrGrNo: "",
      vehicleNo: fieldData.vehicleNo ? fieldData.vehicleNo : "",
      sameAsBillTo: fieldData.sameAsBillTo,
      lrRrGrDate: fieldData.lrRrGrDate ? fieldData.lrRrGrDate : "",
      eWayBillNo: "",
      eWayBillDate: fieldData.eWayBillDate ? fieldData.eWayBillDate : "",
      remark: fieldData.remark ? fieldData.remark : "",
      firmName: selectedData
        ? selectedData.firmName ||
        (selectedData[0] ? selectedData[0].firmName : "")
        : "",
      address: selectedData
        ? selectedData.address ||
        (selectedData[0] ? selectedData[0].address : "")
        : "",
      state: selectedData
        ? selectedData.state || (selectedData[0] ? selectedData[0].state : "")
        : "",
      stateCode: selectedData
        ? selectedData.stateCode ||
        (selectedData[0] ? selectedData[0].stateCode : "")
        : "",
      city: selectedData
        ? selectedData.city || (selectedData[0] ? selectedData[0].city : "")
        : "",
      gstin: selectedData
        ? selectedData.gstNo || (selectedData[0] ? selectedData[0].gstNo : "")
        : "",
      panNo: selectedData
        ? selectedData.panNo || (selectedData[0] ? selectedData[0].panNo : "")
        : "",
      contactPersonNo: selectedData
        ? selectedData.contactPersonNo ||
        (selectedData[0] ? selectedData[0].contactPersonNo : "")
        : "",
    };
    console.log(vehicleData);
    let dataToSave = {
      ...offerBillNewData.stockStakeHolderBillsAfterApplyingOffers,
      vehicleNo: JSON.stringify(vehicleData),
    };
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        showLoador({ loador: true });
        apiPost({
          url:
            endpoint.stockStakeholderBillHeader +
            "/save-after-appying-offers-batch-wise",
          postBody: dataToSave,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            console.log(data);
            if (data.data.message !== "") {
              showNotification({
                msg: data.data.message,
              });
              window.location.replace("/batchwise-stakeholder-bill");
            } else {
              showNotification({
                msg: savemsg,
              });
              window.location.replace("/batchwise-stakeholder-bill");
            }
          } else {
            showNotification({
              msg: saveFailedMsg,
              severity: "error",
            });
          }
        });
      }
    });
  };

  clickSubmitHandler = () => {
    const { checkboxAllData, offerBillNewData, billOfferId } = this.state;
    if (offerBillNewData.length !== 0) {
      let dataToSave = [];
      if (
        Object.keys(offerBillNewData.stockStakeHolderBillHeader).length !== 0 &&
        offerBillNewData.stockStakeHolderBillHeader.id == billOfferId
      ) {
        let billCheckedOfferData = [];
        if (offerBillNewData.offerHeaders.length !== 0) {
          offerBillNewData.offerHeaders.map((offerData) => {
            if (checkboxAllData.length !== 0) {
              checkboxAllData.map((checkedData) => {
                if (checkedData.id == offerData.id) {
                  billCheckedOfferData.push(checkedData);
                }
              });
            }
          });
        }
        let billObjectData = {
          stockStakeHolderBillHeader:
            offerBillNewData.stockStakeHolderBillHeader,
          offerHeaders: billCheckedOfferData,
          productAndStocks: offerBillNewData.productAndStocks,
        };
        dataToSave.push(billObjectData);
      }
      if (dataToSave.length !== 0) {
        this.onSave(dataToSave[0]);
      }
    }
  };

  onSave = (dataToSave) => {
    const { offerBillNewData, openDialog, previousListOfDetails } = this.state;
    const { showLoador } = this.props;
    showLoador({ loador: true });
    apiPost({
      url: endpoint.stockStakeholderBillHeader + "/apply-offer-batch-wise",
      postBody: dataToSave,
    }).then(({ data, success }) => {
      showLoador({ loador: false });
      if (success) {
        if (
          Object.keys(data.data).length !== 0 &&
          Object.keys(data.data.stockStakeHolderBillsAfterApplyingOffers)
            .length !== 0
        ) {
          if (
            data.data.stockStakeHolderBillsAfterApplyingOffers
              .stockStakeHolderBillDetails.length !== 0
          ) {
            let newObjectToSave = {
              ...data.data.stockStakeHolderBillsAfterApplyingOffers,
              stockStakeHolderBillDetails:
                data.data.stockStakeHolderBillsAfterApplyingOffers.stockStakeHolderBillDetails.map(
                  (orderObject) => {
                    let productWithoutBillObject =
                      data.data.stockStakeHolderBillHeader.stockStakeHolderBillDetails.filter(
                        (row) => row.id == orderObject.id
                      );
                    if (productWithoutBillObject.length !== 0) {
                      orderObject = {
                        ...orderObject,
                        taxableAmtWithoutOffer:
                          productWithoutBillObject[0].taxableAmt,
                        totalAmtWithoutOffer:
                          productWithoutBillObject[0].totalAmt,
                        isChecked: 1,
                      };
                      return orderObject;
                    } else {
                      orderObject = {
                        ...orderObject,
                        taxableAmtWithoutOffer: 0.0,
                        totalAmtWithoutOffer: 0.0,
                        isChecked: 1,
                      };
                      return orderObject;
                    }
                  }
                ),
            };
            let newData = {
              ...data.data,
              stockStakeHolderBillsAfterApplyingOffers: newObjectToSave,
            };
            if (previousListOfDetails.length !== 0) {
              if (
                previousListOfDetails.length >
                newData.stockStakeHolderBillsAfterApplyingOffers
                  .stockStakeHolderBillDetails.length
              ) {
                let filterNewList1 = [];
                previousListOfDetails.map((previousObject) => {
                  if (!previousObject.hasOwnProperty("id")) {
                    filterNewList1.push(previousObject);
                  }
                });
                let filterNewList2 = [];
                newData.stockStakeHolderBillsAfterApplyingOffers.stockStakeHolderBillDetails.map(
                  (previousObject) => {
                    if (!previousObject.hasOwnProperty("id")) {
                      filterNewList2.push(previousObject);
                    }
                  }
                );
                var array3 = filterNewList1.filter(function (obj) {
                  return filterNewList2.indexOf(obj) == -1;
                });
                for (let i = 0; i < newData.productAndStocks.length; i++) {
                  for (let j = 0; j < array3.length; j++) {
                    if (
                      newData.productAndStocks[i].productId ==
                      array3[j].product.id
                    ) {
                      for (
                        let k = 0;
                        k < newData.productAndStocks[i].batchAndStocks.length;
                        k++
                      ) {
                        if (
                          newData.productAndStocks[i].batchAndStocks[k]
                            .batchCode == array3[j].batchCode
                        ) {
                          newData.productAndStocks[i].batchAndStocks[k].stock =
                            newData.productAndStocks[i].batchAndStocks[k]
                              .stock + array3[j].freeQty;
                        }
                      }
                    }
                  }
                }
              }
            }
            let flagApply = 0;
            let newList1 = [];

            if (
              Object.keys(newData).length !== 0 &&
              Object.keys(newData.stockStakeHolderBillsAfterApplyingOffers)
                .length !== 0 &&
              newData.stockStakeHolderBillsAfterApplyingOffers.appliedOffers
                .length !== 0
            ) {
              newList1 =
                newData.stockStakeHolderBillsAfterApplyingOffers.appliedOffers.map(
                  (offerObject) => {
                    if (
                      offerObject.levelWiseType == 3 &&
                      offerObject.isApplied == 1
                    ) {
                      if (offerObject.isShow == 0) {
                        flagApply++;
                      }
                      offerObject = {
                        ...offerObject,
                        isShow: offerObject.isShow == 1 ? 0 : 1,
                      };
                      return offerObject;
                    } else if (
                      offerObject.levelWiseType == 5 &&
                      offerObject.isApplied == 1
                    ) {
                      if (offerObject.isShow == 0) {
                        flagApply++;
                      }
                      offerObject = {
                        ...offerObject,
                        isShow: offerObject.isShow == 1 ? 0 : 1,
                      };
                      return offerObject;
                    } else if (
                      offerObject.levelWiseType == 6 &&
                      offerObject.isApplied == 1
                    ) {
                      if (offerObject.isShow == 0) {
                        flagApply++;
                      }
                      offerObject = {
                        ...offerObject,
                        isShow: offerObject.isShow == 1 ? 0 : 1,
                      };
                      return offerObject;
                    } else if (
                      offerObject.levelWiseType == 7 &&
                      offerObject.isApplied == 1
                    ) {
                      if (offerObject.isShow == 0) {
                        flagApply++;
                      }
                      offerObject = {
                        ...offerObject,
                        isShow: offerObject.isShow == 1 ? 0 : 1,
                      };
                      return offerObject;
                    } else {
                      return offerObject;
                    }
                  }
                );
              let newCheckdeList =
                offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.stockStakeHolderBillDetails.filter(
                  (row) => row.isChecked == 1
                );

              let newList2 = {
                ...newData.stockStakeHolderBillsAfterApplyingOffers,
                appliedOffers: newList1,
                stockStakeHolderBillDetails: newCheckdeList,
              };

              let newList3 = {
                ...newData,
                stockStakeHolderBillsAfterApplyingOffers: newList2,
              };

              let offerBillDataToSaveNew = {};

              if (
                offerBillNewData.stockStakeHolderBillsAfterApplyingOffers
                  .appliedOffers.length >
                newList3.stockStakeHolderBillsAfterApplyingOffers.appliedOffers
                  .length
              ) {
                let filterNewList1 = [];
                newList3.stockStakeHolderBillsAfterApplyingOffers.appliedOffers.map(
                  (newObject) => {
                    offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.stockStakeHolderBillDetails.map(
                      (previousObject) => {
                        if (
                          !previousObject.hasOwnProperty("id") &&
                          previousObject.levelWiseType ==
                          newObject.levelWiseType
                        ) {
                          filterNewList1.push(previousObject);
                        }
                      }
                    );
                  }
                );

                let newCheckdeList =
                  offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.stockStakeHolderBillDetails.filter(
                    (row) => row.isChecked == 1
                  );

                let offerBillAfterApplyingOffersData = {
                  ...offerBillNewData.stockStakeHolderBillsAfterApplyingOffers,
                  appliedOffers:
                    newList3.stockStakeHolderBillsAfterApplyingOffers
                      .appliedOffers,
                  stockStakeHolderBillDetails: newCheckdeList,
                };
                offerBillDataToSaveNew = {
                  ...offerBillNewData,
                  stockStakeHolderBillsAfterApplyingOffers:
                    offerBillAfterApplyingOffersData,
                };

                this.setState({
                  openBill: false,
                  offerBillNewData: offerBillDataToSaveNew,
                });
              } else {
                let newCheckdeList =
                  offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.stockStakeHolderBillDetails.filter(
                    (row) => row.isChecked == 1
                  );

                let offerBillAfterApplyingOffersData = {
                  ...newList3.stockStakeHolderBillsAfterApplyingOffers,
                  appliedOffers:
                    newList3.stockStakeHolderBillsAfterApplyingOffers
                      .appliedOffers,
                  stockStakeHolderBillDetails: newCheckdeList,
                };
                offerBillDataToSaveNew = {
                  ...offerBillNewData,
                  stockStakeHolderBillsAfterApplyingOffers:
                    offerBillAfterApplyingOffersData,
                };
              }

              if (flagApply > 0) {
                let newCheckdeList =
                  offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.stockStakeHolderBillDetails.filter(
                    (row) => row.isChecked == 1
                  );
                let offerBillAfterApplyingOffersData = {
                  ...newList3.stockStakeHolderBillsAfterApplyingOffers,
                  stockStakeHolderBillDetails: newCheckdeList,
                };
                offerBillDataToSaveNew = {
                  ...newList3,
                  stockStakeHolderBillsAfterApplyingOffers:
                    offerBillAfterApplyingOffersData,
                };

                this.setState({
                  openBill: true,
                  offerBillNewData: offerBillDataToSaveNew,
                });
              } else {
                this.setState({
                  openBill: false,
                  offerBillNewData: offerBillDataToSaveNew,
                });
              }
              this.setState({
                openDialog: !openDialog,
              });
            } else {
              this.setState({
                openBill: false,
                offerBillNewData: newData,
                openDialog: !openDialog,
              });
            }
          }
        }
      }
    });
  };

  onCheckBoxClick = (e, checkedValue, headerId, type, level) => {
    const { checkboxAllData, offerBillNewData } = this.state;
    let checkboxData = [];
    if (offerBillNewData.length !== 0) {
      if (
        Object.keys(offerBillNewData.stockStakeHolderBillsAfterApplyingOffers)
          .length !== 0 &&
        offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.id ===
        headerId
      ) {
        checkboxAllData.map((allOffer) => {
          if (
            allOffer.level == 0 &&
            allOffer.type == 0 &&
            checkedValue.level == 0 &&
            checkedValue.type == 0
          ) {
            if (allOffer.id == checkedValue.id) {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied == 1 ? 0 : 1,
                isShow: e.target.checked == true ? 1 : 0,
                appliedOfferId:
                  offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.id,
              };
              checkboxData.push(allOfferData);
            } else {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied == 1 ? 0 : 0,
                isShow: allOffer.isApplied == 1 ? 0 : 1,
              };
              checkboxData.push(allOfferData);
            }
          } else if (
            allOffer.level == 0 &&
            allOffer.type == 1 &&
            checkedValue.level == 0 &&
            checkedValue.type == 1
          ) {
            if (allOffer.id == checkedValue.id) {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied == 1 ? 0 : 1,
                isShow: e.target.checked == true ? 1 : 0,
                appliedOfferId:
                  offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.id,
              };
              checkboxData.push(allOfferData);
            } else {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied == 1 ? 0 : 0,
                isShow: allOffer.isApplied == 1 ? 0 : 1,
              };
              checkboxData.push(allOfferData);
            }
          } else if (
            allOffer.level == 1 &&
            allOffer.type == 0 &&
            checkedValue.level == 1 &&
            checkedValue.type == 0
          ) {
            if (allOffer.id == checkedValue.id) {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied == 1 ? 0 : 1,
                isShow: e.target.checked == true ? 1 : 0,
                appliedOfferId:
                  offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.id,
              };
              checkboxData.push(allOfferData);
            } else {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied,
                isShow: allOffer.isApplied == 1 ? 0 : 1,
              };
              checkboxData.push(allOfferData);
            }
          } else if (
            allOffer.level == 1 &&
            allOffer.type == 1 &&
            checkedValue.level == 1 &&
            checkedValue.type == 1
          ) {
            if (allOffer.id == checkedValue.id) {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied == 1 ? 0 : 1,
                isShow: e.target.checked == true ? 1 : 0,
                appliedOfferId:
                  offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.id,
              };
              checkboxData.push(allOfferData);
            } else {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied,
                isShow: allOffer.isApplied == 1 ? 0 : 1,
              };
              checkboxData.push(allOfferData);
            }
          } else {
            checkboxData.push(allOffer);
          }
        });
      }
    }

    let fitlerRow = checkboxData.filter((row) => row.id == checkedValue.id);

    for (
      let i = 0;
      i <
      offerBillNewData.stockStakeHolderBillsAfterApplyingOffers
        .stockStakeHolderBillDetails.length;
      i++
    ) {
      if (
        offerBillNewData.stockStakeHolderBillsAfterApplyingOffers
          .stockStakeHolderBillDetails[i].levelWiseType ==
        fitlerRow[0].levelWiseType &&
        offerBillNewData.stockStakeHolderBillsAfterApplyingOffers
          .stockStakeHolderBillDetails[i].level == level &&
        offerBillNewData.stockStakeHolderBillsAfterApplyingOffers
          .stockStakeHolderBillDetails[i].type == type
      ) {
        if (e.target.checked == false) {
          offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.stockStakeHolderBillDetails[
            i
          ].isChecked = 0;
        }
      }
    }

    let newCheckedList =
      offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.stockStakeHolderBillDetails.filter(
        (row) => row.isChecked == 1
      );
    let offerBillAfterApplyingOffersData = {
      ...offerBillNewData.stockStakeHolderBillsAfterApplyingOffers,
      stockStakeHolderBillDetails: newCheckedList,
    };

    let offerBillDataToSaveNew = {
      ...offerBillNewData,
      offerHeaders: checkboxData,
      stockStakeHolderBillsAfterApplyingOffers:
        offerBillAfterApplyingOffersData,
    };

    this.setState({
      checkboxAllData: checkboxData,
      offerBillNewData: offerBillDataToSaveNew,
    });
  };

  savaBillHandler = (rowData) => {
    const { offerBillNewData, checkboxAllData } = this.state;
    if (
      Object.keys(offerBillNewData).length !== 0 &&
      Object.keys(offerBillNewData.stockStakeHolderBillsAfterApplyingOffers)
        .length !== 0 &&
      offerBillNewData.stockStakeHolderBillsAfterApplyingOffers
        .stockStakeHolderBillDetails.length !== 0
    ) {
      let dataToSet = [
        ...offerBillNewData.stockStakeHolderBillsAfterApplyingOffers
          .stockStakeHolderBillDetails,
        ...rowData,
      ];
      let offerBillAfterApplyingOffersData = {
        ...offerBillNewData.stockStakeHolderBillsAfterApplyingOffers,
        stockStakeHolderBillDetails: dataToSet,
      };
      let offerBillDataToSaveNew = {
        ...offerBillNewData,
        stockStakeHolderBillsAfterApplyingOffers:
          offerBillAfterApplyingOffersData,
        offerHeaders: checkboxAllData,
      };

      this.setState({
        offerBillNewData: offerBillDataToSaveNew,
        openBill: false,
        openDialog: false,
      });
    }
  };

  cancelDialogHandler = () => {
    this.setState({
      openBill: false,
      openDialog: true,
    });
  };

  clickHandler = (billId) => {
    const { openDialog, offerBillNewData } = this.state;
    let checkboxData = [];
    this.setState({
      openDialog: !openDialog,
      checkboxAllData: [],
      billOfferId: billId,
    });
    if (offerBillNewData.length !== 0) {
      if (
        Object.keys(offerBillNewData.stockStakeHolderBillsAfterApplyingOffers)
          .length !== 0 &&
        offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.id ==
        billId &&
        offerBillNewData.offerHeaders.length !== 0
      ) {
        offerBillNewData.offerHeaders.map((allOffer) => {
          let allOfferData = {
            ...allOffer,
            appliedOfferId:
              offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.id,
          };
          checkboxData.push(allOfferData);
        });
      }
    }
    this.setState({
      checkboxAllData: checkboxData,
    });
  };

  onDataChange = (fieldName, newValue) => {
    if (fieldName == "DiscountPercentage") {
      let discountAmt = (this.state.total * +newValue) / 100;
      this.setState({
        totalAmt: +this.state.total - +discountAmt,
      });
    }
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    batchwiseStakeholderBillJson.fieldMeta.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };

  render() {
    const {
      openDialog,
      checkboxAllData,
      offerBillNewData,
      openBill,
      formErrors,
      totalQty,
      fieldData,
      dynamicMasterData,
    } = this.state;
    const { onCancel, vehicleList } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            screenTitle={"Billing Preview"}
            showTable={false}
            showBackButton={true}
            showAddButton={false}
            onCancel={onCancel}
          />
          <br />
          {Object.keys(offerBillNewData).length !== 0 &&
            Object.keys(
              offerBillNewData.stockStakeHolderBillsAfterApplyingOffers
            ).length !== 0 && (
              <>
                <DynamicDetails
                  DataToSet={[
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Stock Holder No.",
                      value: 1,
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Stock Holder Name",
                      value:
                        offerBillNewData.stockStakeHolderBillsAfterApplyingOffers !==
                          null &&
                          offerBillNewData
                            .stockStakeHolderBillsAfterApplyingOffers
                            .toBillStakeHolder !== null
                          ? offerBillNewData
                            .stockStakeHolderBillsAfterApplyingOffers
                            .toBillStakeHolder.ownerName
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Firm Name",
                      value:
                        offerBillNewData.stockStakeHolderBillsAfterApplyingOffers !==
                          null &&
                          offerBillNewData
                            .stockStakeHolderBillsAfterApplyingOffers
                            .toBillStakeHolder !== null
                          ? offerBillNewData
                            .stockStakeHolderBillsAfterApplyingOffers
                            .toBillStakeHolder.firmName
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "State Name",
                      value:
                        offerBillNewData.stockStakeHolderBillsAfterApplyingOffers !==
                          null &&
                          offerBillNewData
                            .stockStakeHolderBillsAfterApplyingOffers
                            .toBillStakeHolder !== null &&
                          offerBillNewData
                            .stockStakeHolderBillsAfterApplyingOffers
                            .toBillStakeHolder.state !== null
                          ? offerBillNewData
                            .stockStakeHolderBillsAfterApplyingOffers
                            .toBillStakeHolder.state.name
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Bill No",
                      value:
                        offerBillNewData.stockStakeHolderBillsAfterApplyingOffers !==
                          null
                          ? offerBillNewData
                            .stockStakeHolderBillsAfterApplyingOffers.billNo
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Bill Date",
                      value:
                        offerBillNewData.stockStakeHolderBillsAfterApplyingOffers !==
                          null
                          ? offerBillNewData
                            .stockStakeHolderBillsAfterApplyingOffers.billDate
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Total Quantity",
                      value: totalQty !== null ? totalQty.toFixed(2) : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Taxable Amount",
                      value:
                        offerBillNewData.stockStakeHolderBillsAfterApplyingOffers !==
                          null
                          ? offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.taxableAmt.toFixed(
                            2
                          )
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Total Amount",
                      value:
                        offerBillNewData.stockStakeHolderBillsAfterApplyingOffers !==
                          null
                          ? offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.totalAmt.toFixed(
                            2
                          )
                          : "",
                    },
                  ]}
                />
              </>
            )}

          {Object.keys(offerBillNewData).length !== 0 &&
            offerBillNewData.offerHeaders.length !== 0 && (
              <>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="flex-end"
                >
                  <ButtonCompo
                    sx={{
                      bgcolor: "dodgerblue", // theme.palette.primary.main
                      color: "#fbff00",
                      opacity: "0.9",
                      transform: "scale(0.9)",
                      ":hover": {
                        display: "inline-block",
                        bgcolor: "#fbff00", // theme.palette.primary.main
                        color: "dodgerblue",
                        opacity: "0.9",
                        transform: "scale(1.5)",
                        transitionDuration: "3s",
                        backfaceVisibility: "hidden",
                        transform: "translate(2px, 2px) rotate(0deg)  scale(2)",
                        config: {
                          tension: "300",
                          friction: "10",
                        },
                      },
                    }}
                    size="medium"
                    type="button"
                    variant="contained"
                    name={"Offer"}
                    onClick={(e) =>
                      this.clickHandler(
                        offerBillNewData
                          .stockStakeHolderBillsAfterApplyingOffers.id
                      )
                    }
                    fullWidth={false}
                  />
                </Grid>
              </>
            )}

          {Object.keys(offerBillNewData).length !== 0 && (
            <>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" sx={{ width: "5%" }}>
                        Sr. No
                      </TableCell>
                      <TableCell align="center" sx={{ width: "15%" }}>
                        Product Name
                      </TableCell>
                      <TableCell align="center" sx={{ width: "15%" }}>
                        Batch
                      </TableCell>
                      <TableCell align="center" sx={{ width: "10%" }}>
                        Quantity
                      </TableCell>
                      <TableCell align="center" sx={{ width: "10%" }}>
                        Free Quantity
                      </TableCell>
                      <TableCell align="center" sx={{ width: "10%" }}>
                        Rate
                      </TableCell>
                      <TableCell align="center" sx={{ width: "10%" }}>
                        Taxable Amount
                      </TableCell>
                      <TableCell align="center" sx={{ width: "10%" }}>
                        CGST
                      </TableCell>
                      <TableCell align="center" sx={{ width: "10%" }}>
                        SGST
                      </TableCell>
                      <TableCell align="center" sx={{ width: "10%" }}>
                        IGST
                      </TableCell>
                      <TableCell align="center" sx={{ width: "10%" }}>
                        Total Amount
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {Object.keys(
                    offerBillNewData.stockStakeHolderBillsAfterApplyingOffers
                  ).length !== 0 &&
                    offerBillNewData.stockStakeHolderBillsAfterApplyingOffers
                      .stockStakeHolderBillDetails.length !== 0 &&
                    offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.stockStakeHolderBillDetails.map(
                      (billDetails, index) => {
                        if (billDetails.isChecked == 1) {
                          return (
                            <>
                              <TableRow>
                                <TableCell align="center" sx={{ width: "5%" }}>
                                  {index + 1}
                                </TableCell>
                                <TableCell align="center" sx={{ width: "15%" }}>
                                  {billDetails !== null &&
                                    billDetails.product !== null
                                    ? billDetails.product.name
                                    : ""}
                                </TableCell>
                                <TableCell align="left" sx={{ width: "15%" }}>
                                  {billDetails !== null
                                    ? billDetails.batchCode
                                    : ""}
                                </TableCell>
                                <TableCell align="center" sx={{ width: "10%" }}>
                                  {billDetails !== null
                                    ? billDetails.qty.toFixed(2)
                                    : ""}
                                </TableCell>
                                <TableCell align="center" sx={{ width: "10%" }}>
                                  {billDetails !== null
                                    ? billDetails.freeQty.toFixed(2)
                                    : ""}
                                </TableCell>
                                <TableCell align="center" sx={{ width: "10%" }}>
                                  {billDetails !== null
                                    ? billDetails.rate.toFixed(2)
                                    : ""}
                                </TableCell>
                                <TableCell align="right" sx={{ width: "10%" }}>
                                  {billDetails !== null
                                    ? billDetails.taxableAmtWithoutOffer.toFixed(
                                      2
                                    )
                                    : ""}
                                </TableCell>
                                <TableCell align="center" sx={{ width: "10%" }}>
                                  {billDetails !== null
                                    ? billDetails.cgstAmt.toFixed(2)
                                    : ""}
                                </TableCell>
                                <TableCell align="center" sx={{ width: "10%" }}>
                                  {billDetails !== null
                                    ? billDetails.sgstAmt.toFixed(2)
                                    : ""}
                                </TableCell>
                                <TableCell align="center" sx={{ width: "10%" }}>
                                  {billDetails !== null
                                    ? billDetails.igstAmt.toFixed(2)
                                    : ""}
                                </TableCell>
                                <TableCell align="right" sx={{ width: "10%" }}>
                                  {billDetails !== null
                                    ? billDetails.totalAmtWithoutOffer.toFixed(
                                      2
                                    )
                                    : ""}
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        }
                      }
                    )}
                </Table>
                {Object.keys(offerBillNewData).length !== 0 &&
                  Object.keys(
                    offerBillNewData.stockStakeHolderBillsAfterApplyingOffers
                  ).length !== 0 &&
                  offerBillNewData.stockStakeHolderBillsAfterApplyingOffers
                    .appliedOffers.length !== 0 && (
                    <>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" sx={{ width: "5.5%" }}>
                              Sr. No
                            </TableCell>
                            <TableCell align="center" sx={{ width: "80%" }}>
                              Offer Name
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {offerBillNewData
                          .stockStakeHolderBillsAfterApplyingOffers
                          .appliedOffers.length !== 0 &&
                          offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.appliedOffers.map(
                            (offerAppliedData, index) => {
                              return (
                                <>
                                  <TableRow>
                                    <TableCell align="center">
                                      {index + 1}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      sx={{ width: "80%" }}
                                    >
                                      {offerAppliedData !== null
                                        ? offerAppliedData.name
                                        : ""}
                                    </TableCell>
                                  </TableRow>
                                </>
                              );
                            }
                          )}
                      </Table>
                    </>
                  )}
              </TableContainer>
            </>
          )}
          <br />
          <br />

          <DynamicFormWithoutValidation
            dynamicMasterData={dynamicMasterData}
            screenTitle={false}
            showTitle={false}
            fieldMeta={batchwiseStakeholderBillJson.fieldMetaForBilling}
            apiBaseURL={batchwiseStakeholderBillJson.apiBaseURL}
            formErrors={formErrors}
            showSaveBtn={false}
            showSaveBtnMain={false}
            getByIdApi={true}
            callApi={true}
            fieldData={fieldData}
            onDataChange={this.onDataChange}
            showBackToList={false}
          />

          {openDialog && !openBill && (
            <>
              <BootstrapDialog
                onClose={this.clickHandler}
                aria-labelledby="customized-dialog-title"
                open={true}
                fullWidth
                maxWidth="500px"
              >
                <BootstrapDialogTitle
                  id="customized-dialog-title"
                  onClose={this.clickHandler}
                >
                  Applicable Offers
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="flex-between"
                  >
                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="flex-start"
                    >
                      {checkboxAllData.length !== 0 &&
                        checkboxAllData.map((applicableOfferData) => {
                          if (
                            applicableOfferData.type == 0 &&
                            applicableOfferData.level == 0
                          ) {
                            return (
                              <>
                                <Grid
                                  item
                                  sm={12}
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  container
                                  justifyContent="flex-start"
                                >
                                  <Typography
                                    sx={{ p: 0, marginLeft: 2 }}
                                    variant="h6"
                                    color="textSecondary"
                                  >
                                    <FormControl component="fieldset">
                                      <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                          value="end"
                                          control={
                                            <Checkbox
                                              sx={{
                                                color: checkboxShadowColor,
                                                "& .MuiSvgIcon-root": {
                                                  fontSize: 30,
                                                },
                                                "&.Mui-checked": {
                                                  color: pink[600],
                                                },
                                              }}
                                              color="secondary"
                                            />
                                          }
                                          sx={{
                                            ".css-19g4vwf-MuiTypography-root": {
                                              fontSize: "1.3rem",
                                              color: "#000000",
                                            },
                                          }}
                                          label={applicableOfferData.name}
                                          onChange={(e) => {
                                            this.onCheckBoxClick(
                                              e,
                                              applicableOfferData,
                                              applicableOfferData.appliedOfferId,
                                              applicableOfferData.type,
                                              applicableOfferData.level
                                            );
                                          }}
                                          labelPlacement="end"
                                          checked={
                                            applicableOfferData.isApplied == 1
                                              ? true
                                              : false
                                          }
                                        />
                                      </FormGroup>{" "}
                                      <div
                                        style={{
                                          marginLeft: "40px",
                                          fontSize: "1.1rem",
                                        }}
                                      >
                                        {applicableOfferData.discription}
                                      </div>
                                      {applicableOfferData.isApplied == 2 && (
                                        <FormHelperText
                                          style={{
                                            marginLeft: "40px",
                                          }}
                                          error
                                        >
                                          {
                                            "Due to a shortage of stock, the offer is not applicable"
                                          }
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Typography>
                                </Grid>
                              </>
                            );
                          }
                        })}
                    </Grid>

                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="flex-start"
                    >
                      {checkboxAllData.length !== 0 &&
                        checkboxAllData.map((applicableOfferData) => {
                          if (
                            applicableOfferData.type == 1 &&
                            applicableOfferData.level == 0
                          ) {
                            return (
                              <>
                                <Grid
                                  item
                                  sm={12}
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  container
                                  justifyContent="flex-start"
                                >
                                  <Typography
                                    sx={{ p: 0, marginLeft: 2 }}
                                    variant="h6"
                                    color="textSecondary"
                                  >
                                    <FormControl component="fieldset">
                                      <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                          value="end"
                                          control={
                                            <Checkbox
                                              sx={{
                                                color: checkboxShadowColor,
                                                "& .MuiSvgIcon-root": {
                                                  fontSize: 30,
                                                },
                                                "&.Mui-checked": {
                                                  color: pink[600],
                                                },
                                              }}
                                              color="secondary"
                                            />
                                          }
                                          sx={{
                                            ".css-19g4vwf-MuiTypography-root": {
                                              fontSize: "1.3rem",
                                              color: "#000000",
                                            },
                                          }}
                                          label={applicableOfferData.name}
                                          onChange={(e) => {
                                            this.onCheckBoxClick(
                                              e,
                                              applicableOfferData,
                                              applicableOfferData.appliedOfferId,
                                              applicableOfferData.type,
                                              applicableOfferData.level
                                            );
                                          }}
                                          labelPlacement="end"
                                          checked={
                                            applicableOfferData.isApplied == 1
                                              ? true
                                              : false
                                          }
                                        />
                                        <div
                                          style={{
                                            marginLeft: "40px",
                                            fontSize: "1.1rem",
                                            marginTop: "-15px",
                                          }}
                                        >
                                          {applicableOfferData.discription}
                                        </div>
                                        {applicableOfferData.isApplied == 2 && (
                                          <FormHelperText
                                            style={{
                                              marginLeft: "40px",
                                            }}
                                            error
                                          >
                                            {
                                              "Due to a shortage of stock, the offer is not applicable"
                                            }
                                          </FormHelperText>
                                        )}
                                      </FormGroup>
                                    </FormControl>
                                  </Typography>
                                </Grid>
                              </>
                            );
                          }
                        })}
                    </Grid>
                  </Grid>
                  <br />
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="flex-between"
                  >
                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="flex-start"
                    >
                      {checkboxAllData.length !== 0 &&
                        checkboxAllData.map((applicableOfferData) => {
                          if (
                            applicableOfferData.type == 0 &&
                            applicableOfferData.level == 1
                          ) {
                            return (
                              <>
                                <Grid
                                  item
                                  sm={12}
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  container
                                  justifyContent="flex-start"
                                >
                                  <Typography
                                    sx={{ p: 0, marginLeft: 2 }}
                                    variant="h6"
                                    color="textSecondary"
                                  >
                                    <FormControl component="fieldset">
                                      <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                          value="end"
                                          control={
                                            <Checkbox
                                              sx={{
                                                color: checkboxShadowColor,
                                                "& .MuiSvgIcon-root": {
                                                  fontSize: 30,
                                                },
                                                "&.Mui-checked": {
                                                  color: pink[600],
                                                },
                                              }}
                                              color="secondary"
                                            />
                                          }
                                          sx={{
                                            ".css-19g4vwf-MuiTypography-root": {
                                              fontSize: "1.3rem",
                                              color: "#000000",
                                            },
                                          }}
                                          label={applicableOfferData.name}
                                          onChange={(e) => {
                                            this.onCheckBoxClick(
                                              e,
                                              applicableOfferData,
                                              applicableOfferData.appliedOfferId,
                                              applicableOfferData.type,
                                              applicableOfferData.level
                                            );
                                          }}
                                          labelPlacement="end"
                                          checked={
                                            applicableOfferData.isApplied == 1
                                              ? true
                                              : false
                                          }
                                        />
                                      </FormGroup>{" "}
                                      <div
                                        style={{
                                          marginLeft: "40px",
                                          fontSize: "1.1rem",
                                        }}
                                      >
                                        {applicableOfferData.discription}
                                      </div>
                                      {applicableOfferData.isApplied == 2 && (
                                        <FormHelperText
                                          style={{
                                            marginLeft: "40px",
                                          }}
                                          error
                                        >
                                          {
                                            "Due to a shortage of stock, the offer is not applicable"
                                          }
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Typography>
                                </Grid>
                              </>
                            );
                          }
                        })}
                    </Grid>

                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="flex-start"
                    >
                      {checkboxAllData.length !== 0 &&
                        checkboxAllData.map((applicableOfferData) => {
                          if (
                            applicableOfferData.type == 1 &&
                            applicableOfferData.level == 1
                          ) {
                            return (
                              <>
                                <Grid
                                  item
                                  sm={12}
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  container
                                  justifyContent="flex-start"
                                >
                                  <Typography
                                    sx={{ p: 0, marginLeft: 2 }}
                                    variant="h6"
                                    color="textSecondary"
                                  >
                                    <FormControl component="fieldset">
                                      <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                          value="end"
                                          control={
                                            <Checkbox
                                              sx={{
                                                color: checkboxShadowColor,
                                                "& .MuiSvgIcon-root": {
                                                  fontSize: 30,
                                                },
                                                "&.Mui-checked": {
                                                  color: pink[600],
                                                },
                                              }}
                                              color="secondary"
                                            />
                                          }
                                          sx={{
                                            ".css-19g4vwf-MuiTypography-root": {
                                              fontSize: "1.5rem",
                                              color: "#000000",
                                            },
                                          }}
                                          label={applicableOfferData.name}
                                          onChange={(e) => {
                                            this.onCheckBoxClick(
                                              e,
                                              applicableOfferData,
                                              applicableOfferData.appliedOfferId,
                                              applicableOfferData.type,
                                              applicableOfferData.level
                                            );
                                          }}
                                          labelPlacement="end"
                                          checked={
                                            applicableOfferData.isApplied == 1
                                              ? true
                                              : false
                                          }
                                        />
                                        <div
                                          style={{
                                            marginLeft: "40px",
                                            fontSize: "1.1rem",
                                            marginTop: "-15px",
                                          }}
                                        >
                                          {applicableOfferData.discription}
                                        </div>
                                        {applicableOfferData.isApplied == 2 && (
                                          <FormHelperText
                                            style={{
                                              marginLeft: "40px",
                                            }}
                                            error
                                          >
                                            {
                                              "Due to a shortage of stock, the offer is not applicable"
                                            }
                                          </FormHelperText>
                                        )}
                                      </FormGroup>
                                    </FormControl>
                                  </Typography>
                                </Grid>
                              </>
                            );
                          }
                        })}
                    </Grid>
                  </Grid>
                  <br />
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="flex-end"
                  >
                    <ButtonCompo
                      sx={{
                        bgcolor: "dodgerblue",
                        color: "#fbff00",
                      }}
                      size="medium"
                      type="button"
                      variant="contained"
                      name={"Submit"}
                      onClick={this.clickSubmitHandler}
                      fullWidth={false}
                    />
                  </Grid>
                </BootstrapDialogTitle>
              </BootstrapDialog>
            </>
          )}
          {openBill && !openDialog && (
            <>
              <BatchwiseStakeHolderPopUpForOffer
                offerBillData={offerBillNewData}
                cancelHandler={this.cancelDialogHandler}
                onDataSave={this.savaBillHandler}
              />
            </>
          )}
          <br />

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="flex-end"
          >
            <ButtonCompo
              sx={{
                bgcolor: "dodgerblue",
                color: "#fbff00",
              }}
              size="medium"
              type="button"
              variant="contained"
              name={"Save Bill"}
              onClick={this.clickSubmitOfferHandler}
              fullWidth={false}
            />
          </Grid>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stakeholdersList: state.stackholder,
  transporterList: state.transporter,
});
const mapDispatchToProps = {
  getStockStackHolders,
  getActiveTransporterListByUserTypeUpdated,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BillingWithOffer);
