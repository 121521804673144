import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { connect } from "react-redux";
import { StockBetweenDateJSON } from "../../DynamicFormsJson/Transaction/stockBetweenDate";
import { getDetailUpdated, getUpdatedStockBetweenDateList } from "../../Slice/manageStock.slice";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import { cyanBlue } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";
import PopUpStockBetweenDate from "./PopUpStockBetweenDate";
class StockBetweenDateTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValueForOutlets: "",
      popUpFlag: false,
      closingFlag: false,
      fromdate: dayjs().format("DD-MM-YYYY"),
      stockType: "0",
      todate: dayjs().format("DD-MM-YYYY"),
      columnFlag: "",
      batchFlag: "",
      openingQTyTotal: "",
      primarySellQtyTotal: "",
      secondarySellTotalQty: "",
      grnQtyTotal: "",
      crnQtyTotal: "",
      inStockQtyTotal: "",
      outStockQtyTotal: "",
      closingQtyTotal: "",
      stockInHandTotal: "",
      primaryStockTotal: "",
      adminFlag: false,
      companyTwoFlagForStockInHand: false,
    };
  }

  async componentDidMount() {
    const { getUpdatedStockBetweenDateList, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {

      const showCompanyFlag = localStorage.getItem("showCompanyFlag");
      if (showCompanyFlag == 2) {
        this.setState({
          companyTwoFlagForStockInHand: true,
        });
      }

      const userTypeId = localStorage.getItem("userTypeId");
      showLoador({ loador: true });
      await getUpdatedStockBetweenDateList({
        fromdate: dayjs().format("DD-MM-YYYY"),
        todate: dayjs().format("DD-MM-YYYY"),
        stockType: "0",
      }).then(({ success, response }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.columnTotal(response);
        }
      });



      const isClosingQtyClickable = localStorage.getItem("isClosingQtyClickable");

      this.setState({
        closingFlag: isClosingQtyClickable === "1" ? true : false,
        adminFlag: userTypeId == 1 || userTypeId == 6 ? true : false,
      });



      const isQtyInBatchWise = localStorage.getItem("isQtyInBatchWise");

      this.setState({
        batchFlag: isQtyInBatchWise === "1" ? true : false,
      });


    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  onSearchData = async (data) => {
    const { getUpdatedStockBetweenDateList, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getUpdatedStockBetweenDateList({
        fromdate: data.fromdate,
        todate: data.todate,
        stockType: "0",
      }).then(({ success, response }) => {
        showLoador({ loador: false });
        this.setState({
          fromdate: data.fromdate,
          todate: data.todate,
        });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.columnTotal(response);
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onLoadList = () => {
    const { getUpdatedStockBetweenDateList, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getUpdatedStockBetweenDateList({
        fromdate: dayjs().format("DD-MM-YYYY"),
        todate: dayjs().format("DD-MM-YYYY"),
        stockType: "0",
      }).then(({ success, response }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSearchForPerformingOutlets = (searchValueForOutlets) => {
    this.setState({
      searchValueForOutlets: searchValueForOutlets,
    });
  };

  getFilteredTableData = (searchData) => {
    const { searchValueForOutlets } = this.state;
    return searchData.filter((currentRow) => {
      let isValid = false;
      if (
        (currentRow["name"] &&
          currentRow["name"]
            .toString()
            .toLowerCase()
            .includes(searchValueForOutlets.trim().toLowerCase())) ||
        (currentRow["stakeHolderUomName"] &&
          currentRow["stakeHolderUomName"]
            .toString()
            .toLowerCase()
            .includes(searchValueForOutlets.trim().toLowerCase()))
      ) {
        isValid = true;
        return true;
      }
    });
  };

  handleCloses = () => {
    this.setState({
      popUpFlag: false,
    });
  };

  onClickHandler = (e, row, flag) => {
    const { getDetailUpdated, showLoador, showNotification } = this.props;
    const { fromdate, todate, columnFlag } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getDetailUpdated({
        fromdate: fromdate ? fromdate : dayjs().format("DD-MM-YYYY"),
        todate: todate ? todate : dayjs().format("DD-MM-YYYY"),
        productId: row.product,
        flag: flag,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            popUpFlag: true,
            columnFlag: flag,
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  columnTotal(response) {
    let openingQTyTotal = 0,
      purchaseQtyTotal = 0,
      primarySellQtyTotal = 0,
      secondarySellTotalQty = 0,
      grnQtyTotal = 0,
      crnQtyTotal = 0,
      inStockQtyTotal = 0,
      outStockQtyTotal = 0,
      closingQtyTotal = 0,
      stockInHandTotal = 0,
      primaryStockTotal = 0;
    response.productStocks.map((qtyData) => {
      openingQTyTotal =
        openingQTyTotal + (+qtyData.openingQty > 0 ? +qtyData.openingQty : 0);
      purchaseQtyTotal =
        purchaseQtyTotal +
        (+qtyData.purchaseQty > 0 ? +qtyData.purchaseQty : 0);
      primarySellQtyTotal =
        primarySellQtyTotal +
        (+qtyData.distributorSellQty > 0 ? +qtyData.distributorSellQty : 0);
      secondarySellTotalQty =
        secondarySellTotalQty +
        (+qtyData.outletSellQty > 0 ? +qtyData.outletSellQty : 0);
      grnQtyTotal = grnQtyTotal + (+qtyData.grnQty > 0 ? +qtyData.grnQty : 0);
      crnQtyTotal = crnQtyTotal + (+qtyData.crnQty > 0 ? +qtyData.crnQty : 0);
      inStockQtyTotal =
        inStockQtyTotal + (+qtyData.inStockQty > 0 ? +qtyData.inStockQty : 0);
      outStockQtyTotal =
        outStockQtyTotal +
        (+qtyData.outStockQty > 0 ? +qtyData.outStockQty : 0);
      closingQtyTotal =
        closingQtyTotal + (+qtyData.closingQty > 0 ? +qtyData.closingQty : 0);
      stockInHandTotal =
        stockInHandTotal +
        (+qtyData.stockInHand > 0 ? +qtyData.stockInHand : 0);
      primaryStockTotal =
        primaryStockTotal +
        (+qtyData.primaryClosingQty > 0 ? +qtyData.primaryClosingQty : 0);
    });

    this.setState({
      openingQTyTotal: openingQTyTotal.toFixed(2),
      purchaseQtyTotal: purchaseQtyTotal.toFixed(2),
      primarySellQtyTotal: primarySellQtyTotal.toFixed(2),
      secondarySellTotalQty: secondarySellTotalQty.toFixed(2),
      grnQtyTotal: grnQtyTotal.toFixed(2),
      crnQtyTotal: crnQtyTotal.toFixed(2),
      inStockQtyTotal: inStockQtyTotal.toFixed(2),
      outStockQtyTotal: outStockQtyTotal.toFixed(2),
      closingQtyTotal: closingQtyTotal.toFixed(2),
      stockInHandTotal: stockInHandTotal.toFixed(2),
      primaryStockTotal: primaryStockTotal.toFixed(0),
    });
  }
  generateExcelStatic = () => {
    const {
      closingQtyTotal,
      stockInHandTotal,
      outStockQtyTotal,
      inStockQtyTotal,
      secondarySellTotalQty,
      grnQtyTotal,
      crnQtyTotal,
      openingQTyTotal,
      purchaseQtyTotal,
      primarySellQtyTotal,
      fromdate,
      todate,
      stockType,
      primaryStockTotal,
    } = this.state;
    const { manageStockList } = this.props;
    const { showLoador, showNotification } = this.props;

    if (navigator.onLine) {

      showLoador({ loador: true });
      downloadF({
        isGet: true,
        url: endpoint.manageStockHeader + "/get-stock-between-date-excel?fromDate=" + fromdate + "&toDate=" + todate,
        ext: "xls",
        openNewTab: false,
      }).then((response) => {
        if (!response) {
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
        }
        showLoador({ loador: false });
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const {
      dynamicMasterData,
      searchValueForOutlets,
      closingQtyTotal,
      stockInHandTotal,
      outStockQtyTotal,
      inStockQtyTotal,
      secondarySellTotalQty,
      grnQtyTotal,
      crnQtyTotal,
      openingQTyTotal,
      purchaseQtyTotal,
      primarySellQtyTotal,
      popUpFlag,
      closingFlag,
      columnFlag,
      batchFlag,
      fromdate,
      todate,
      adminFlag,
      primaryStockTotal,
      companyTwoFlagForStockInHand,
    } = this.state;
    const { manageStockList } = this.props;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <DynamicMainScreen
            showDeleteIcon={false}
            dynamicMasterData={dynamicMasterData}
            searchList={StockBetweenDateJSON.searchList}
            showSearchBox={false}
            onSearchData={this.onSearchData}
            screenTitle={StockBetweenDateJSON.screenTitle}
            // changeExcelURL={"area-data" + '?sort={"sortOrder": "ASC"}'}
            getListById={this.getListById}
            showPdfDownload={false}
            showExcelDownload={false}
            isActive={false}
            isActiveURL={StockBetweenDateJSON.isActiveURL}
            showAddButton={false}
            tableColumnsToFilter={StockBetweenDateJSON.tableColumnsToFilter}
            tableColumns={[]}
            tableData={
              []
              // Object.keys(manageStockList.stockBetweenDate).length != 0
              //   ? manageStockList.stockBetweenDate.productStocks
              //   : []
            }
            apiBaseURL={StockBetweenDateJSON.apiBaseURL}
            callApi={false}
            getTableDataById={true}
            getTableData={this.onLoadList}
            showPdf={false}
            showEditIcon={false}
            showViewButtonInTable={false}
            isActionColActive={false}
            showPegination={false}
            today={true}
          />
          <br />
          <LandingScreenHeader
            screenTitle={""}
            showSearchBox={true}
            showTable={true}
            showFilter={false}
            showPdfDownload={false}
            generateExcel={this.generateExcelStatic}
            staticExcel={true}
            showExcelDownload={true}
            onSearch={this.onSearchForPerformingOutlets}
          />
          <br />
          <TableContainer style={{ maxHeight: "550px", overflow: "auto" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">SR.No</TableCell>
                  <TableCell align="center">product Name</TableCell>
                  <TableCell align="center">UOM</TableCell>
                  <TableCell align="center">Opening Quantity </TableCell>
                  <TableCell align="center">Purchase Quantity </TableCell>
                  {adminFlag && (
                    <TableCell align="center">Primary Sell Quantity </TableCell>
                  )}
                  <TableCell align="center">Secondary Sell Quantity </TableCell>
                  <TableCell align="center">GRN Quantity </TableCell>
                  <TableCell align="center">Credit Note Quantity </TableCell>
                  <TableCell align="center">InStock Quantity </TableCell>
                  <TableCell align="center">OutStock Quantity </TableCell>
                  <TableCell align="center">Closing Quantity </TableCell>
                  {!companyTwoFlagForStockInHand && (
                    <TableCell align="center">Stock In Hand</TableCell>
                  )}
                  <TableCell align="center">Total </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(manageStockList.stockBetweenDate).length != 0 &&
                  this.getFilteredTableData(
                    manageStockList.stockBetweenDate.productStocks
                  ).map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">
                        {row.stakeHolderUomName}
                      </TableCell>
                      <TableCell align="right">{row.openingQty}</TableCell>

                      <TableCell align="right">
                        <div
                          onClick={(e) => this.onClickHandler(e, row, 0)}
                          style={{
                            color: cyanBlue,
                            cursor: "pointer",
                          }}
                        >
                          {row.purchaseQty}
                        </div>
                      </TableCell>
                      {adminFlag && (
                        <TableCell align="right">
                          {" "}
                          <div
                            onClick={(e) => this.onClickHandler(e, row, 1)}
                            style={{
                              color: cyanBlue,
                              cursor: "pointer",
                            }}
                          >
                            {row.distributorSellQty}
                          </div>
                        </TableCell>
                      )}
                      <TableCell align="right">
                        {" "}
                        <div
                          onClick={(e) => this.onClickHandler(e, row, 2)}
                          style={{
                            color: cyanBlue,
                            cursor: "pointer",
                          }}
                        >
                          {row.outletSellQty}
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        <div
                          onClick={(e) => this.onClickHandler(e, row, 3)}
                          style={{
                            color: cyanBlue,
                            cursor: "pointer",
                          }}
                        >
                          {row.grnQty}
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        <div
                          onClick={(e) => this.onClickHandler(e, row, 4)}
                          style={{
                            color: cyanBlue,
                            cursor: "pointer",
                          }}
                        >
                          {row.crnQty}
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        <div
                          onClick={(e) => this.onClickHandler(e, row, 5)}
                          style={{
                            color: cyanBlue,
                            cursor: "pointer",
                          }}
                        >
                          {row.inStockQty}
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        <div
                          onClick={(e) => this.onClickHandler(e, row, 6)}
                          style={{
                            color: cyanBlue,
                            cursor: "pointer",
                          }}
                        >
                          {row.outStockQty}
                        </div>
                      </TableCell>

                      {closingFlag ? (
                        <TableCell align="right">
                          {" "}
                          <div
                            onClick={(e) => this.onClickHandler(e, row, 7)}
                            style={{
                              color: cyanBlue,
                              cursor: "pointer",
                            }}
                          >
                            {row.closingQty}
                          </div>
                        </TableCell>
                      ) : (
                        <TableCell align="right">{row.closingQty}</TableCell>
                      )}
                      {!companyTwoFlagForStockInHand && (
                        <TableCell align="right">{row.stockInHand}</TableCell>
                      )}
                      <TableCell align="right">{row.total}</TableCell>
                    </TableRow>
                  ))}
                <TableRow>
                  <TableCell align="center" width="2%" colSpan={3}>
                    Total
                  </TableCell>
                  <TableCell align="right">{openingQTyTotal}</TableCell>
                  <TableCell align="right">{purchaseQtyTotal}</TableCell>
                  {adminFlag && (
                    <TableCell align="right">{primarySellQtyTotal}</TableCell>
                  )}
                  <TableCell align="right">{secondarySellTotalQty}</TableCell>
                  <TableCell align="right">{grnQtyTotal}</TableCell>
                  <TableCell align="right">{crnQtyTotal}</TableCell>
                  <TableCell align="right">{inStockQtyTotal}</TableCell>
                  <TableCell align="right">{outStockQtyTotal}</TableCell>
                  <TableCell align="right">{closingQtyTotal}</TableCell>
                  {!companyTwoFlagForStockInHand && (
                    <TableCell align="right">{stockInHandTotal}</TableCell>
                  )}
                  <TableCell align="right">
                    {primaryStockTotal + " Primary Stock"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {popUpFlag && (
            <PopUpStockBetweenDate
              open={popUpFlag}
              handleCloses={this.handleCloses}
              fromdate={fromdate}
              todate={todate}
              columnFlag={
                columnFlag == 0
                  ? "	Purchase Quantity "
                  : columnFlag == 1
                    ? "Primary Sell Quantity"
                    : columnFlag == 2
                      ? "Secondary Sell Quantity"
                      : columnFlag == 3
                        ? "GRN Quantity"
                        : columnFlag == 4
                          ? "Credit Note Quantity"
                          : columnFlag == 5
                            ? "InStock Quantity"
                            : columnFlag == 6
                              ? "OutStock Quantity"
                              : "Closing Quantity"
              }
              columns={
                columnFlag == 0
                  ? batchFlag
                    ? [
                      {
                        title: "Sr. No.",
                        name: "index",
                        positionCenter: false,
                        showInscreen: true,
                        alignCenter: "center",
                        canSearch: true,
                      },
                      {
                        title: "Date",
                        name: "date",
                        positionCenter: false,
                        showInscreen: true,
                        alignCenter: "center",
                        canSearch: true,
                      },
                      {
                        title: "From Bill",
                        name: "fromBillFirmName",
                        positionCenter: false,
                        showInscreen: true,
                        alignCenter: "left",
                        canSearch: true,
                      },
                      {
                        title: "Quantity",
                        name: "qty",
                        positionCenter: false,
                        showInscreen: true,
                        alignCenter: "left",
                        canSearch: true,
                      },
                      {
                        title: "Batch Code",
                        name: "batchCode",
                        positionCenter: false,
                        showInscreen: true,
                        alignCenter: "left",
                        canSearch: true,
                      },
                    ]
                    : [
                      {
                        title: "Sr. No.",
                        name: "index",
                        positionCenter: false,
                        showInscreen: true,
                        alignCenter: "center",
                        canSearch: true,
                      },
                      {
                        title: "Date",
                        name: "date",
                        positionCenter: false,
                        showInscreen: true,
                        alignCenter: "center",
                        canSearch: true,
                      },
                      {
                        title: "From Bill",
                        name: "fromBillFirmName",
                        positionCenter: false,
                        showInscreen: true,
                        alignCenter: "left",
                        canSearch: true,
                      },
                      {
                        title: "Quantity",
                        name: "qty",
                        positionCenter: false,
                        showInscreen: true,
                        alignCenter: "left",
                        canSearch: true,
                      },
                    ]
                  : columnFlag == 1
                    ? batchFlag
                      ? [
                        {
                          title: "Sr. No.",
                          name: "index",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "center",
                          canSearch: true,
                        },
                        {
                          title: "Date",
                          name: "date",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "center",
                          canSearch: true,
                        },
                        {
                          title: "Quantity",
                          name: "qty",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                          canSearch: true,
                        },
                        {
                          title: "Batch Code",
                          name: "batchCode",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                          canSearch: true,
                        },
                      ]
                      : [
                        {
                          title: "Sr. No.",
                          name: "index",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "center",
                          canSearch: true,
                        },
                        {
                          title: "Date",
                          name: "date",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "center",
                          canSearch: true,
                        },
                        {
                          title: "Quantity",
                          name: "qty",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                          canSearch: true,
                        },
                      ]
                    : columnFlag == 2
                      ? batchFlag
                        ? [
                          {
                            title: "Sr. No.",
                            name: "index",
                            positionCenter: false,
                            showInscreen: true,
                            alignCenter: "center",
                            canSearch: true,
                          },
                          {
                            title: "Date",
                            name: "date",
                            positionCenter: false,
                            showInscreen: true,
                            alignCenter: "center",
                            canSearch: true,
                          },
                          {
                            title: "Outlet",
                            name: "outLetName",
                            positionCenter: false,
                            showInscreen: true,
                            alignCenter: "left",
                            canSearch: true,
                          },
                          {
                            title: "Quantity",
                            name: "qty",
                            positionCenter: false,
                            showInscreen: true,
                            alignCenter: "left",
                            canSearch: true,
                          },
                          {
                            title: "Batch Code",
                            name: "batchCode",
                            positionCenter: false,
                            showInscreen: true,
                            alignCenter: "left",
                            canSearch: true,
                          },
                        ]
                        : [
                          {
                            title: "Sr. No.",
                            name: "index",
                            positionCenter: false,
                            showInscreen: true,
                            alignCenter: "center",
                            canSearch: true,
                          },
                          {
                            title: "Date",
                            name: "date",
                            positionCenter: false,
                            showInscreen: true,
                            alignCenter: "center",
                            canSearch: true,
                          },
                          {
                            title: "Outlet",
                            name: "outLetName",
                            positionCenter: false,
                            showInscreen: true,
                            alignCenter: "left",
                            canSearch: true,
                          },
                          {
                            title: "Quantity",
                            name: "qty",
                            positionCenter: false,
                            showInscreen: true,
                            alignCenter: "left",
                            canSearch: true,
                          },
                        ]
                      : columnFlag == 3
                        ? batchFlag
                          ? [
                            {
                              title: "Sr. No.",
                              name: "index",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "center",
                              canSearch: true,
                            },
                            {
                              title: "Date",
                              name: "date",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "center",
                              canSearch: true,
                            },
                            {
                              title: "Quantity",
                              name: "qty",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "left",
                              canSearch: true,
                            },
                            {
                              title: "Batch Code",
                              name: "batchCode",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "left",
                              canSearch: true,
                            },
                          ]
                          : [
                            {
                              title: "Sr. No.",
                              name: "index",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "center",
                              canSearch: true,
                            },
                            {
                              title: "Date",
                              name: "date",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "center",
                              canSearch: true,
                            },
                            {
                              title: "Quantity",
                              name: "qty",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "left",
                              canSearch: true,
                            },
                          ]
                        : columnFlag == 4
                          ? batchFlag
                            ? [
                              {
                                title: "Sr. No.",
                                name: "index",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "center",
                                canSearch: true,
                              },
                              {
                                title: "Date",
                                name: "date",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "center",
                                canSearch: true,
                              },
                              {
                                title: "Quantity",
                                name: "qty",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "left",
                                canSearch: true,
                              },
                              {
                                title: "Batch Code",
                                name: "batchCode",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "left",
                                canSearch: true,
                              },
                            ]
                            : [
                              {
                                title: "Sr. No.",
                                name: "index",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "center",
                                canSearch: true,
                              },
                              {
                                title: "Date",
                                name: "date",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "center",
                                canSearch: true,
                              },
                              {
                                title: "Quantity",
                                name: "qty",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "left",
                                canSearch: true,
                              },
                            ]
                          : columnFlag == 5 || columnFlag == 6
                            ? batchFlag
                              ? [
                                {
                                  title: "Sr. No.",
                                  name: "index",
                                  positionCenter: false,
                                  showInscreen: true,
                                  alignCenter: "center",
                                  canSearch: true,
                                },
                                {
                                  title: "Date",
                                  name: "date",
                                  positionCenter: false,
                                  showInscreen: true,
                                  alignCenter: "center",
                                  canSearch: true,
                                },
                                {
                                  title: "Quantity",
                                  name: "qty",
                                  positionCenter: false,
                                  showInscreen: true,
                                  alignCenter: "left",
                                  canSearch: true,
                                },
                                {
                                  title: "Batch Code",
                                  name: "batchCode",
                                  positionCenter: false,
                                  showInscreen: true,
                                  alignCenter: "left",
                                  canSearch: true,
                                },
                              ]
                              : [
                                {
                                  title: "Sr. No.",
                                  name: "index",
                                  positionCenter: false,
                                  showInscreen: true,
                                  alignCenter: "center",
                                  canSearch: true,
                                },
                                {
                                  title: "Date",
                                  name: "date",
                                  positionCenter: false,
                                  showInscreen: true,
                                  alignCenter: "center",
                                  canSearch: true,
                                },
                                {
                                  title: "Quantity",
                                  name: "qty",
                                  positionCenter: false,
                                  showInscreen: true,
                                  alignCenter: "left",
                                  canSearch: true,
                                },
                              ]
                            : batchFlag
                              ? [
                                {
                                  title: "Sr. No.",
                                  name: "index",
                                  positionCenter: false,
                                  showInscreen: true,
                                  alignCenter: "center",
                                  canSearch: true,
                                },
                                {
                                  title: "Date",
                                  name: "date",
                                  positionCenter: false,
                                  showInscreen: true,
                                  alignCenter: "center",
                                  canSearch: true,
                                },
                                {
                                  title: "Batch Code",
                                  name: "batchCodeClosingQty",
                                  positionCenter: false,
                                  showInscreen: true,
                                  alignCenter: "left",
                                  canSearch: true,
                                },
                                {
                                  title: "Quantity",
                                  name: "qty",
                                  positionCenter: false,
                                  showInscreen: true,
                                  alignCenter: "left",
                                  canSearch: true,
                                },
                              ]
                              : [
                                {
                                  title: "Sr. No.",
                                  name: "index",
                                  positionCenter: false,
                                  showInscreen: true,
                                  alignCenter: "center",
                                  canSearch: true,
                                },
                                {
                                  title: "Date",
                                  name: "date",
                                  positionCenter: false,
                                  showInscreen: true,
                                  alignCenter: "center",
                                  canSearch: true,
                                },
                                {
                                  title: "Quantity",
                                  name: "qty",
                                  positionCenter: false,
                                  showInscreen: true,
                                  alignCenter: "left",
                                  canSearch: true,
                                },
                              ]
              }
              rows={manageStockList?.detailOnClick.filter(item => item.boxQty > 0 || item.pcsQty > 0)}
            />
          )}
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  manageStockList: state.manageStockByDate,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getUpdatedStockBetweenDateList,
  getDetailUpdated,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockBetweenDateTable);
