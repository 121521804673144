import CloseIcon from "@mui/icons-material/Close";
import { Divider, FormControlLabel, Grid, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { getAssetsChecklistDetailsByOutletId } from "../../../Slice/assetsChecklist.slice";
import { noInternetMsg, serverMsg } from "../../../config/messageconstant";
import { MyComponentWithIconProps } from "../../../components/Comman/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { imageUrl } from "../../../config/Url";
import { LabelCompo } from "../../../components/Comman/Label";
import { blackColor, titleColor } from "../../../config/ColorObj";
import NoDataFound from "../../DashbourdChanges/NoDataFound";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { classes, children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

class AssetsCheckListDetailsPopUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assetsCheckListDetailsPopUpOpenFlag: false,
        };
    }

    async componentDidMount() {
        const {
            getAssetsChecklistDetailsByOutletId,
            showLoador,
            showNotification,
            dataRow,
        } = this.props;
        if (navigator.onLine) {

            console.log(dataRow)


            showLoador({ loador: true });
            await getAssetsChecklistDetailsByOutletId({ outletId: dataRow.outletId }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });

            this.assetsCheckListDetailsOpen();

        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }


    assetsCheckListDetailsOpen = () => {
        this.setState({ assetsCheckListDetailsPopUpOpenFlag: true });
    }

    handleClose = () => {
        const { handleCloses } = this.props;
        handleCloses();
    }


    onSearch = (searchValue) => {
        this.setState({
            searchValue: searchValue,
        });
    };


    render() {
        const { assetsChecklistList, dataRow } = this.props;
        const { assetsCheckListDetailsPopUpOpenFlag } = this.state;
        return (
            <>
                <BootstrapDialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={assetsCheckListDetailsPopUpOpenFlag}
                    fullWidth
                    maxWidth="md"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={this.handleClose}
                    >
                        Assets Checklist Details
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Typography variant="h5" style={{ marginLeft: '13px' }}>
                            {dataRow.firmName}
                        </Typography>
                        <br />

                        <div
                            style={{
                                color: '#fff',
                                padding: '10px',
                                textAlign: 'center',
                            }}
                        >
                            {assetsChecklistList?.assetsChecklistDetails?.length !== 0 ? (
                                assetsChecklistList?.assetsChecklistDetails?.map((assetsDetailsObj, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div
                                                style={{
                                                    background: '#ffffff',
                                                    borderRadius: '15px',
                                                    padding: '10px',
                                                    marginBottom: '10px',
                                                    marginTop: '10px',
                                                }}
                                            >

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        marginBottom: '8px',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            color: '#333',
                                                            fontSize: '22px',
                                                            fontFamily: 'Poppins, sans-serif',
                                                            fontWeight: '500',
                                                            justifyContent: 'flex-start',
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        {assetsDetailsObj.assetsName}
                                                    </div>

                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '15px',
                                                        }}
                                                    >
                                                        <LabelCompo
                                                            className="text-black"
                                                            style={{ fontSize: 16, color: blackColor }}
                                                            label="View Image :"
                                                        />
                                                        <MyComponentWithIconProps
                                                            statusImage={VisibilityIcon}
                                                            color="primary"
                                                            fontSize="medium"
                                                            title="View"
                                                            onClick={() => {
                                                                window.open(imageUrl + assetsDetailsObj.image, '_blank');
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div
                                                    style={{
                                                        color: '#333',
                                                        fontSize: '18px',
                                                        fontFamily: 'Poppins, sans-serif',
                                                        fontWeight: '400',
                                                        marginBottom: '8px',
                                                        justifyContent: 'flex-start',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    {index + 1 + '. ' + assetsDetailsObj.questions}
                                                </div>

                                                <div
                                                    style={{
                                                        color: '#444',
                                                        fontSize: '18px',
                                                        fontFamily: 'Poppins, sans-serif',
                                                        fontWeight: '400',
                                                        justifyContent: 'flex-start',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            paddingLeft: '20px',
                                                            fontSize: '18px',
                                                            fontWeight: '400',
                                                        }}
                                                        key={index}
                                                    >
                                                        <RadioGroup value={assetsDetailsObj.answer} row>
                                                            <FormControlLabel
                                                                key={index}
                                                                value="1"
                                                                control={<Radio disabled={true} />}
                                                                label="Yes"
                                                                style={{
                                                                    fontSize: '18px',
                                                                    fontWeight: '400',
                                                                }}
                                                            />
                                                            <br />
                                                            <FormControlLabel
                                                                key={index}
                                                                value="0"
                                                                control={<Radio disabled={true} />}
                                                                label="No"
                                                                style={{
                                                                    fontSize: '18px',
                                                                    fontWeight: '400',
                                                                }}
                                                            />
                                                        </RadioGroup>
                                                    </div>
                                                </div>
                                            </div>

                                            <Divider style={{ marginTop: -10 }} />
                                        </React.Fragment>
                                    );
                                })
                            ) : (
                                <NoDataFound />
                            )}
                        </div>
                    </DialogContent>
                </BootstrapDialog >
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    assetsChecklistList: state.assetsChecklist,
});

const mapDispatchToProps = {
    getAssetsChecklistDetailsByOutletId,
    showLoador,
    showNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetsCheckListDetailsPopUp);
