import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { workingTypeColumns } from "../../../tableColumns/table-columns";
import { WorkingTypejson } from "../../../DynamicFormsJson/MastersJSON/workingTypeJson";
import { getWorkingType } from "../../../Slice/workingType.slice";

class workingTypeTable extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { getWorkingType, workingTypeList } = this.props;
        return (
            <>
                <DynamicMainScreen
                    formPath={WorkingTypejson.formPath}
                    screenTitle={WorkingTypejson.screenTitle}
                    fieldMeta={WorkingTypejson.fieldMeta}
                    showPdfDownload={false}
                    showExcelDownload={false}
                    showExtension={false}
                    pdfFileName={WorkingTypejson.pdfFileName}
                    excelFileName={WorkingTypejson.excelFileName}
                    showAddButton={WorkingTypejson.showAddButton}
                    tableColumnsToFilter={WorkingTypejson.tableColumnsToFilter}
                    tableColumns={workingTypeColumns}
                    tableData={workingTypeList?.workingType}
                    getTableData={getWorkingType}
                    apiBaseURL={WorkingTypejson.apiBaseURL}
                    baseIdColumn={WorkingTypejson.baseIdColumn}

                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    workingTypeList: state.workingType,
});
const mapDispatchToProps = {
    getWorkingType,
};
export default connect(mapStateToProps, mapDispatchToProps)(workingTypeTable);
