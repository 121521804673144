import endpoint from "../../config/endpoints";

export const assignRateConfigurationToStakeholderJson = {
    showSaveNextBtn: true,
    apiBaseURL: endpoint.assignRateConfigurationToStakeholder,
    formPath: "/assign-rate-configuration-to-stakeholder-form",
    formPathView:"/assign-rate-configuration-to-stakeholder-form-view",
    screenTitle: "Assign Configuration To Stock Holder",
    showTitle: true,
    showCancel: false,
    showSaveBtn: false,
    fieldMeta: [
        {
            label: "Stock Holder Type",
            controlType: "autocomplete",
            placeHolder: "Select Stock Holder ",
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "stakeHolderTypeDetail",
            dataKey: "stockStakeHolderType",
            getListId: "stockStakeHolderType,category",
            isRootLevelKey: false,
            isMandatory: true,
          },
          {
            label: "Category",
            controlType: "autocomplete",
            placeHolder: "Select Category",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "categoryDetail",
            dataKey: "category",
            getListId: "stockStakeHolderType,category",
            isRootLevelKey: false,
            isMandatory: true,
          },
          {
            label: "Stock Holder",
            controlType: "multiSelect",
            placeHolder: "Select Stock Holder Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "stakeHolderName",
            dataKey: "stackholder",
            getListFrom: "stockStakeHolderType",
            isRootLevelKey: false,
            isMandatory: true,
          },
          {
            label: "Rate Configuration",
            controlType: "autocomplete",
            placeHolder: "Select Rate Configuration",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "rateConfigurationDetail",
            getListFrom: "stockStakeHolderType,category",
            dataKey: "publishrateconfiguration",
            isRootLevelKey: false,
            isMandatory: true,
          },
        {
            label: "Publish Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "date",
            isMandatory: true,
            isMinDate: true,
            minDate: new Date(),
        },
        
       
    ],
    fieldMetaForSearch: [
        {
            label: "Stock Holder Type",
            controlType: "multiSelect",
            placeHolder: "Select Stock Holder Type",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            getListId: "stockStakeHolderType",
            masterName: "stakeHolderTypeDetail",
            dataKey: "stockStakeHolderType",
            isRootLevelKey: false,
            isMandatory: true,
          },
          {
            label: "Stock Holder",
            controlType: "multiSelect",
            placeHolder: "Select Stock Holder Name",
            md: 5,
            lg: 5,
            sm: 5,
            xs: 12,
            masterName: "stakeHolderName",
            dataKey: "stackholder",
            getListFrom: "stockStakeHolderType",
            isRootLevelKey: false,
            isMandatory: true,
          },
         
      
    ],
};
