import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  grievance: [],
};
let URL = endpoints.grievance;
const grievanceSlice = createSlice({
  name: "grievance",
  initialState,
  reducers: {
    grievanceSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        grievance: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { grievanceSuccess, resetState } = grievanceSlice.actions;

export default grievanceSlice.reducer;

export const getGrievance = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((grievanceObj, index) => {
          let grievanceData = {
            index: index + 1,
            id: grievanceObj.id === null ? "" : grievanceObj.id,
            grivienceCategory:
              grievanceObj.grivienceCategory === null
                ? ""
                : grievanceObj.grivienceCategory,
            outLet: grievanceObj.outLet === null ? "" : grievanceObj.outLet,
            remark: grievanceObj.remark === null ? "" : grievanceObj.remark,
            responseRemark:
              grievanceObj.responseRemark === null
                ? ""
                : grievanceObj.responseRemark,
            status: grievanceObj.status === null ? "" : grievanceObj.status,
          };
          return grievanceData;
        });
        dispatch(grievanceSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getGrievanceTypeAndArea =
  ({ dataJson }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/by-grivience-category-type-and-area-new",
          postBody: dataJson,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((grievanceObject, index) => {
              let grievanceData = {
                index: index + 1,
                id: grievanceObject.id === null ? "" : grievanceObject.id,
                grivienceCategory:
                  grievanceObject.grivienceCategory === null
                    ? ""
                    : grievanceObject.grivienceCategory,
                categoryName:
                  grievanceObject.grivienceCategory === null
                    ? ""
                    : grievanceObject.grivienceCategory.categoryName,
                remark:
                  grievanceObject.remark != null && grievanceObject.remark
                    ? grievanceObject.remark
                    : "",
                outLet:
                  grievanceObject.outLet === null ? "" : grievanceObject.outLet,
                insertedUserId:
                  grievanceObject.insertedUserId != null &&
                    grievanceObject.insertedUserId
                    ? grievanceObject.insertedUserId
                    : "",
                responseRemark:
                  grievanceObject.responseRemark != null &&
                    grievanceObject.responseRemark
                    ? grievanceObject.responseRemark
                    : "",
                userName:
                  grievanceObject.userName != null && grievanceObject.userName
                    ? grievanceObject.userName
                    : "",
                status:
                  grievanceObject.status != null ? grievanceObject.status : "",
                docPic:
                  grievanceObject.docPic != null ? grievanceObject.docPic : "",
                insertDateTime:
                  grievanceObject.insertDateTime != null &&
                    grievanceObject.insertDateTime
                    ? grievanceObject.insertDateTime
                    : "",
              };
              return grievanceData;
            });
            dispatch(grievanceSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const setGrievanceTypeAndArea = () => async (dispatch) => {
  dispatch(grievanceSuccess({ row: [] }));
};
