import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import DynamicFormForDocumentUpload from "../../components/dynamicscreens/DynamicFormForDocumentUpload";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { noticeMessageJSON } from "../../DynamicFormsJson/Transaction/noticeMessage";
import { getRegionNew, getZoneByRegionIds } from "../../Slice/region.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { getAreaByZoneIds } from "../../Slice/area.slice";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";

class NoticeMessageEditForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicMasterData: {
                regionDetail: this.props.regionList?.region,
                zoneDetail: this.props.regionList?.zone,
                areaDetail: this.props.zoneList?.area,
                stakeHolderTypeDetail:
                    this.props.stakeHolderTypeList?.stockStakeHolderType,
            },
        };
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.stakeHolderTypeList !== nextProps.stakeHolderTypeList) {
            if (
                this.props.stakeHolderTypeList?.stockStakeHolderType !==
                nextProps.stakeHolderTypeList?.stockStakeHolderType
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        stakeHolderTypeDetail:
                            nextProps.stakeHolderTypeList?.stockStakeHolderType,
                    },
                });
            }
        }

        if (this.props.regionList !== nextProps.regionList) {
            if (this.props.regionList?.region !== nextProps.regionList?.region) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        regionDetail: nextProps.regionList?.region,
                    },
                });
            }
        }

        if (this.props.regionList !== nextProps.regionList) {
            if (this.props.regionList?.zone !== nextProps.regionList?.zone) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        zoneDetail: nextProps.regionList?.zone,
                    },
                });
            }
        }

        if (this.props.areaList !== nextProps.areaList) {
            if (this.props.areaList?.area !== nextProps.areaList?.area) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        areaDetail: nextProps.areaList?.area,
                    },
                });
            }
        }
    }

    async componentDidMount() {
        const { getRegionNew, showLoador, showNotification, getStockStakeHolderType } =
            this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            await getStockStakeHolderType({ userTypeIds: "2,3,4" }).then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });

            showLoador({ loador: true });
            await getRegionNew().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }
    getListById = async (data) => {
        const {
            getZoneByRegionIds,
            getAreaByZoneIds,
            showLoador,
            showNotification,
        } = this.props;
        if (navigator.onLine) {
            if (data.regionIds && data.regionIds != "") {
                showLoador({ loador: true });
                await getZoneByRegionIds({ regionId: data.regionIds }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }

            if (data.zoneIds && data.zoneIds != "") {
                showLoador({ loador: true });
                await getAreaByZoneIds({ zoneIds: data.zoneIds }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    getDataById = ({ id }) => {
        const { showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            const response = apiGet({
                url: noticeMessageJSON.apiBaseURL + "/" + id,
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (success) {
                    if (!data.error) {
                        let rowData = data.data;
                        let dataToSet = {
                            ...rowData,
                        };
                        return { response: dataToSet, success };
                    } else {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                    return { response: {}, success: false };
                }
                return { response: {}, success: false };
            });
            return response;
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };
    render() {
        const { dynamicMasterData } = this.state;
        return (
            <>
                <DynamicFormForDocumentUpload
                    formPath={noticeMessageJSON.formPath}
                    getListById={this.getListById}
                    screenTitle={noticeMessageJSON.screenTitle}
                    fieldMeta={noticeMessageJSON.fieldMetaForEdit}
                    dynamicMasterData={dynamicMasterData}
                    apiBaseURL={noticeMessageJSON.apiBaseURL}
                    showSaveNextBtn={noticeMessageJSON.showSaveNextBtn}
                    callGetByIdApi={false}
                    getByIdApi={this.getDataById}
                    isEditURL={noticeMessageJSON.isEditURL}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    areaList: state.area,
    regionList: state.region,
    stakeHolderTypeList: state.stockStakeHolderType,
    stateList: state.states,
    districtList: state.district,
    talukaList: state.taluka,
});
const mapDispatchToProps = {
    getAreaByZoneIds,
    getRegionNew,
    getZoneByRegionIds,
    getStockStakeHolderType,
    showLoador,
    showNotification,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(NoticeMessageEditForm);