import React from "react";
import { connect } from "react-redux";
// import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import DynamicTabwiseTable from "../../../components/dynamicscreens/DynamicTabwiseTable";
import { OfferColumnsCurrent, OfferColumns } from "../../../tableColumns/table-columns";
import { offerJson } from "../../../DynamicFormsJson/MastersJSON/offer";
import { getOfferList, getOfferListComplete, getOfferListPast, getOfferListCurrent, getOfferListFuture } from "../../../Slice/offer.slice";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { changeActive, deleteConfirmMsg, deleteFailedMsg, deletemsg, noInternetMsg, serverMsg, statusFailedMsg, statusmsg } from "../../../config/messageconstant";
import { apiDelete, apiGet, apiPost } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import { withRouter } from "../../../components/withRouter";
import { compose } from "recompose";
export const OperationMode = {
  Status: "Status",
};
class offerTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: "",
      toDate: "",
      currentOperationMode: "",
      dynamicMasterData: {

        levelWiseType: [
          {
            id: 0,
            name: "Flat %"
          },
          {
            id: 1,
            name: "Flat amount"
          },
          {
            id: 2,
            name: " % upto given amount"
          },
          {
            id: 3,
            name: "Bill wise free item"
          },
          {
            id: 4,
            name: " % wise"
          },
          {
            id: 5,
            name: "Item wise free item"
          },
          {
            id: 6,
            name: "On Bill 1 on 1 Free"
          },
          {
            id: 7,
            name: "On Item 1 on 1 Free"
          },

        ],

        currentOfferList:
          this.props.offerList?.offerCurrentData,
        futureOfferList:
          this.props.offerList
            ?.offerFutureData,
        pastOfferList:
          this.props.offerList
            ?.offerPastData,
      },

      tabList: [
        {
          label: "Current Offer Data",
          allyProps: 0,
          columns: OfferColumnsCurrent,
          isActionColActive: true,
          value: "currentOfferList",
          searchList: offerJson.searchList,
          tableColumnsToFilter: offerJson.tableColumnsToFilter,
          showExcelDownload: false,
          showExtension: false,
          changeExcelURL: "offer-data",
        },
        {
          label: "Future Offer Data",
          allyProps: 1,
          columns: OfferColumns,
          tableColumnsToFilter: offerJson.tableColumnsToFilterFutureAndPast,
          isActionColActive: true,
          value: "futureOfferList",
          searchList: offerJson.searchList,
          showExcelDownload: false,
        },
        {
          label: "Past Offer Data",
          allyProps: 2,
          columns: OfferColumns,
          isActionColActive: true,
          value: "pastOfferList",
          searchList: offerJson.searchListForPast,
          tableColumnsToFilter: offerJson.tableColumnsToFilterFutureAndPast,
          showExcelDownload: false,

        },
      ],
    };
  }

  getCurrent = async () => {
    const {
      getOfferListCurrent,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getOfferListCurrent({ flag: "", level: "", levelWiseType: "" }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getFuture = async () => {
    const {
      getOfferListFuture,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getOfferListFuture({ flag: "", level: "", levelWiseType: "" }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getPast = async () => {
    const {
      getOfferListPast,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getOfferListPast({ flag: "", level: "", levelWiseType: "" }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }




  async componentDidMount() {
    await this.getTableData()

  }


  componentWillReceiveProps(nextProps) {
    if (this.props.offerList !== nextProps.offerList) {
      if (this.props.offerList?.offerCurrentData !== nextProps.offerList?.offerCurrentData) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            currentOfferList:
              nextProps.offerList?.offerCurrentData,
          },
        });
      }
    }

    if (this.props.offerList !== nextProps.offerList) {
      if (this.props.offerList?.offerFutureData !== nextProps.offerList?.offerFutureData) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            futureOfferList:
              nextProps.offerList?.offerFutureData,
          },
        });
      }
    }

    if (this.props.offerList !== nextProps.offerList) {
      if (this.props.offerList?.offerPastData !== nextProps.offerList?.offerPastData) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            pastOfferList:
              nextProps.offerList?.offerPastData,
          },
        });
      }
    }
  }

  onSearchData = async (data) => {
    console.log(data);
    const { getOfferListComplete, showLoador, showNotification } = this.props;
    this.setState({
      fromDate: data.fromDate,
      toDate: data.toDate,
    });
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getOfferListComplete({
        fromDate: data.fromDate,
        toDate: data.toDate,
        level: data.level,
        levelWiseType: data.levelWiseType,
        flag: data.flag,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  rowStatus = (row) => {
    console.log(row)
    if (navigator.onLine) {
      swal(changeActive, {
        buttons: ["Cancel", true],
      }).then((value) => {
        if (value) {
          this.props.showLoador({ loador: true });
          let status = row.isActive == 1 ? 0 : 1;
          apiPost({
            url:
              endpoint.offer +
              "/update-status?id=" +
              row.id +
              "&status=" + status,
          }).then(({ success }) => {
            this.props.showLoador({ loador: false });
            if (success) {
              this.getCurrent()
              this.props.showNotification({
                msg: statusmsg,
              });
            } else {
              console.log(row.isActive)
              this.props.showNotification({
                msg: statusFailedMsg,
                severity: "error",
              });
            }
          });
        }
      });
    } else {
      this.props.showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  rowDelete = (row) => {
    if (navigator.onLine) {
      swal({
        title: "Are you sure?",
        text: deleteConfirmMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.onDelete(row);
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };


  onDelete = (row) => {
    if (navigator.onLine) {
      this.props.showLoador({ loador: true });
      apiDelete({
        url: endpoint.offer + "/" + row.id,
      }).then(({ success }) => {
        if (success) {
          this.props.showLoador({ loador: false });
          this.props.showNotification({ msg: deletemsg });
          this.getCurrent(),
            this.getFuture(),
            this.getPast()
        } else {
          this.props.showNotification({
            msg: deleteFailedMsg,
            severity: "error",
          });
          this.props.showLoador({ loador: false });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  rowViewData = (row) => {
    this.props.navigate("/offer-view/" + row.id);
  }

  onAddButtonClick = () => {
    this.props.navigate(offerJson.formPath);
  };

  getTableData = async () => {
    this.getCurrent(),
      this.getFuture(),
      this.getPast()
  }

  getExcelUrl = () => {
    const { fromDate, toDate } = this.state;
    "notice?fromDate=" + fromDate + "&toDate=" + toDate
  }

  render() {
    const { offerList } = this.props;
    const { tabList, dynamicMasterData, fromDate, toDate } = this.state;
    return (
      <>
        <DynamicTabwiseTable
          rowList={dynamicMasterData}
          showHeadEdit={false}
          dynamicMasterData={dynamicMasterData}
          showView={true}
          formPath={offerJson.formPath}
          searchList={offerJson.searchList}
          screenTitle={offerJson.screenTitle}
          showPdfDownload={offerJson.showPdfDownload}
          showExcelDownload={false}
          changeExcelURL={"offer-data"}
          showExtension={false}
          formPathView={offerJson.formPathView}
          pdfFileName={offerJson.pdfFileName}
          excelFileName={offerJson.excelFileName}
          showAddButton={offerJson.showAddButton}
          tableColumnsToFilter={offerJson.tableColumnsToFilter}
          tableData={offerList?.offer}
          apiBaseURL={offerJson.apiBaseURL}
          showEditIcon={false}
          onSearchData={this.onSearchData}
          callApi={false}
          getTableDataById={true}
          isActiveURL={offerJson.isActiveURL}
          tabList={tabList}
          isNote={true}
          isNoteValue={"Offer For Outlet's & Stock Holder's"}
          rowStatus={this.rowStatus}
          rowDelete={this.rowDelete}
          rowViewData={this.rowViewData}
          getTableData={this.getTableData}
          onAddButtonClick={this.onAddButtonClick}

        />


      </>
    );
  }
}
const mapStateToProps = (state) => ({
  offerList: state.offer,
});
const mapDispatchToProps = {
  getOfferList,
  getOfferListComplete,
  showNotification,
  showLoador,
  getOfferListPast,
  getOfferListCurrent,
  getOfferListFuture,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(offerTable);
