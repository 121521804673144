import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { TypeJson } from "../../../DynamicFormsJson/MastersJSON/type";

class TypeForm extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <>
                <DynamicForm
                    formPath={TypeJson.formPath}
                    screenTitle={TypeJson.screenTitle}
                    fieldMeta={TypeJson.fieldMeta}
                    apiBaseURL={TypeJson.apiBaseURL}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({

});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(TypeForm);