import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  year: [],
  activeYear: [],
  yearCompanyTarget: [],
  isFetchYearCompanyTarget: false,
};
let URL = endpoints.year;
const yearSlice = createSlice({
  name: "year",
  initialState,
  reducers: {
    yearSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        year: row,
      };
    },

    yearActiveSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        activeYear: row,
      };
    },

    yearCompanyTargetSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        yearCompanyTarget: row,
        isFetchYearCompanyTarget: true,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  yearSuccess,
  targetRegionSuccess,
  yearCompanyTargetSuccess,
  yearActiveSuccess,
  resetState,
} = yearSlice.actions;

export default yearSlice.reducer;

export const getYear = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"fromDate": "ASC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((yearObject, index) => {
          let yearData = {
            index: index + 1,
            id: yearObject.id === null ? "" : yearObject.id,
            yearName: yearObject.yearName === null ? "" : yearObject.yearName,
            name: yearObject.yearName === null ? "" : yearObject.yearName,
            fromDate: yearObject.fromDate === null ? "" : yearObject.fromDate,
            toDate: yearObject.toDate === null ? "" : yearObject.toDate,
            isActive: yearObject.isActive === null ? "" : yearObject.isActive,
          };
          return yearData;
        });
        dispatch(yearSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const setYear =
  ({ row }) =>
  async (dispatch) => {
    dispatch(yearSuccess({ row }));
  };

export const getActiveYear = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/active",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((yearObject, index) => {
          let yearData = {
            index: index + 1,
            id: yearObject.id === null ? "" : yearObject.id,
            yearName: yearObject.yearName === null ? "" : yearObject.yearName,
            name: yearObject.yearName === null ? "" : yearObject.yearName,
            fromDate: yearObject.fromDate === null ? "" : yearObject.fromDate,
            toDate: yearObject.toDate === null ? "" : yearObject.toDate,
            isActive: yearObject.isActive === null ? "" : yearObject.isActive,
          };
          return yearData;
        });
        dispatch(yearActiveSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getYearRegion =
  ({ regionId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/not-in-target-region-percentage?regionId=" + regionId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((yearRegionObj, index) => {
            let yearRegionData = {
              index: index + 1,
              id: yearRegionObj.id === null ? "" : yearRegionObj.id,
              yearName:
                yearRegionObj.yearName === null ? "" : yearRegionObj.yearName,
              name:
                yearRegionObj.yearName === null ? "" : yearRegionObj.yearName,
              fromDate:
                yearRegionObj.fromDate === null ? "" : yearRegionObj.fromDate,
              toDate: yearRegionObj.toDate === null ? "" : yearRegionObj.toDate,
              isActive:
                yearRegionObj.isActive === null ? "" : yearRegionObj.isActive,
              insertDateTime:
                yearRegionObj.insertDateTime === null
                  ? ""
                  : yearRegionObj.insertDateTime,
            };
            return yearRegionData;
          });
          dispatch(yearSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getYearCompanyTarget = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/not-in-company-target",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((yearRegionObj, index) => {
          let yearRegionData = {
            index: index + 1,
            id: yearRegionObj.id === null ? "" : yearRegionObj.id,
            yearName:
              yearRegionObj.yearName === null ? "" : yearRegionObj.yearName,
            name: yearRegionObj.yearName === null ? "" : yearRegionObj.yearName,
            fromDate:
              yearRegionObj.fromDate === null ? "" : yearRegionObj.fromDate,
            toDate: yearRegionObj.toDate === null ? "" : yearRegionObj.toDate,
            isActive:
              yearRegionObj.isActive === null ? "" : yearRegionObj.isActive,
            insertDateTime:
              yearRegionObj.insertDateTime === null
                ? ""
                : yearRegionObj.insertDateTime,
          };
          return yearRegionData;
        });
        dispatch(yearCompanyTargetSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
