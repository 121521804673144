import React from "react";
import { Paper } from "@mui/material";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../../components/dynamicscreens/DynamicFormWithoutSave";
import {
  serverMsg,
  savemsg,
  saveFailedMsg,
  noInternetMsg,
  statusmsg,
  saveWarningMsg,
  dateExistMsg,
} from "../../../config/messageconstant";
import { stackHolderJson } from "../../../DynamicFormsJson/MastersJSON/StackHolder";
import { getRegion } from "../../../Slice/region.slice";
import { getStateByRegion } from "../../../Slice/state.slice";
import { getTalukaByDistrict } from "../../../Slice/taluka.slice";
import { getZoneByState } from "../../../Slice/zone.slice";
import { getAreaByTaluka } from "../../../Slice/area.slice";
import { getDistrictByZone } from "../../../Slice/district.slice";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { apiGet, apiPost } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import { withRouter } from "../../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import swal from "sweetalert";
import { getAreaByStockStakeHolderTypeZoneAndTaluka } from "../../../Slice/StackHolder.slice";
class StackHolderActiveStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        regionDetail: this.props.regionList?.region,
        stateDetail: this.props.stateList?.stateByRegion,
        zoneDetail: this.props.zoneList?.zoneByState,
        districtDetail: this.props.districtList?.districtByZone,
        talukaDetail: this.props.talukaList?.talukaByDistrict,
        areaDetail: this.props.areaList?.areaByBeatTaluka,
      },
      areaId: "",
      zoneIds: "",
      stockStakeHolderTypeId: "",
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByBeatTaluka !==
        nextProps.areaList?.areaByBeatTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByBeatTaluka,
          },
        });
      }
    }
  }

  async componentDidMount() {
    const { getRegion, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getRegion().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      if (this.props.params.id) {
        showLoador({ loador: true });
        apiGet({
          url: endpoint.stackholder + "/" + this.props.params.id,
        }).then(({ data, success }) => {
          console.log(data.data.stockStakeHolderType.id);
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.setState({
              stockStakeHolderTypeId: data.data.stockStakeHolderType.id,
            });
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getListById = async (data) => {
    console.log(data);
    const { areaId, zoneIds, stockStakeHolderTypeId } = this.state;
    const {
      showNotification,
      showLoador,
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByStockStakeHolderTypeZoneAndTaluka,
    } = this.props;
    if (navigator.onLine) {
      if (data.areaIds) {
        this.setState({
          areaId: data.areaIds,
        });
      }
      if (data.zoneIds) {
        this.setState({
          zoneIds: data.zoneIds,
        });
      }
      if (data.region && data.region.id) {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region.id }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state) {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.zoneIds) {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zoneIds }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.district) {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka) {
        if (this.props.params.id) {
          showLoador({ loador: true });
          await getAreaByStockStakeHolderTypeZoneAndTaluka({
            StockStakeHolderTypeId: stockStakeHolderTypeId,
            zoneIds: zoneIds,
            talukaIds: data.taluka,
          }).then(({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          });
        }
      }
      //   if (data.stockStakeHolderType) {
      //     this.setState({ stakeHolderId: data.stockStakeHolderType.id });
      //   }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSave = (rowData) => {
    console.log(rowData);
    const { showLoador, showNotification } = this.props;
    const { areaId } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiGet({
        url:
          endpoint.stackholder +
          "/make-active-inactive?stockStakeHolderId=" +
          this.props.params.id +
          "&areaId=" +
          areaId +
          "&flag=1",
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: statusmsg });
          this.props.navigate(-1);
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            getListById={this.getListById}
            getDataById={this.getDataById}
            showBackToList={true}
            dynamicMasterData={dynamicMasterData}
            showSaveBtn={true}
            showTitle={stackHolderJson.showTitle}
            screenTitle={stackHolderJson.screenTitle}
            fieldMeta={stackHolderJson.fieldMetaForActiveStatus}
            showCancel={stackHolderJson.showCancel}
            apiBaseURL={stackHolderJson.apiBaseURL}
            showSaveNextBtn={stackHolderJson.showSaveNextBtn}
            showSaveBtnMain={false}
            onSave={this.onSave}
          />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  areaList: state.stackholder,
});
const mapDispatchToProps = {
  getRegion,
  getStateByRegion,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTaluka,
  showLoador,
  getAreaByStockStakeHolderTypeZoneAndTaluka,
  showNotification,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(StackHolderActiveStatus);
