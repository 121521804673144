import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg } from "../../config/messageconstant";
import { getStockHolderAndProductConfigurationView } from "../../Slice/rateConfigurationEditingForStockholder.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { RateConfigurationEditingForStockHolderJson } from "../../DynamicFormsJson/Transaction/stockholderProduct";
class RateConfigurationEditingForStockholderView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
    };
    this.columns = [
      {
        title: "Sr. No.",
        name: "index",
        positionCenter: true,
        align: "center",
      },
      {
        title: "Product",
        name: "productName",
        positionCenter: false,
        canSearch: true,
        align: "left",
      },
      {
        title: "Code",
        name: "code",
        positionCenter: true,
        canSearch: true,
        align: "center",
      },
      {
        title: "UOM",
        name: "stakeHolderUomName",
        positionCenter: true,
        canSearch: true,
        align: "center",
      },
    ];
  }
  async componentDidMount() {
    const {
      getStockHolderAndProductConfigurationView,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (this.props.params.id) {
        showLoador({ loador: true });
        await getStockHolderAndProductConfigurationView({
          stockStakeHolderId: this.props.params.id,
        }).then(({ response, success }) => {
          console.log(response);
          showLoador({ loador: false });
          if (!success) {
          } else {
            this.setState({
              headerData: response,
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Stock Holder Name",
                  value:
                    response[0].stockStakeHolderName !== null &&
                    response.length !== 0
                      ? response[0].stockStakeHolderName
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Stock Holder Type",
                  value:
                    response[0].stockStakeHolderTypeName !== null &&
                    response.length !== 0
                      ? response[0].stockStakeHolderTypeName
                      : "",
                },
              ],
            });
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  render() {
    const { dynamicMasterData, DataToSet } = this.state;
    const { rateConfigurationEditingForStockholderList } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            DataToSet={DataToSet}
            dynamicMasterData={dynamicMasterData}
            showSaveBtn={RateConfigurationEditingForStockHolderJson.showSaveBtn}
            showTitle={true}
            screenTitle={"Rate Configuration Editing View"}
            fieldMeta={[]}
            showCancel={RateConfigurationEditingForStockHolderJson.showCancel}
            apiBaseURL={RateConfigurationEditingForStockHolderJson.apiBaseURL}
            showSaveNextBtn={
              RateConfigurationEditingForStockHolderJson.showSaveNextBtn
            }
            saveBtnText={"Search"}
            showSaveBtnMain={false}
            padding={true}
            paddingTop={true}
          />
          <RTTable
            columns={this.columns}
            tableData={
              rateConfigurationEditingForStockholderList.stockHolderAndProductConfigurationView
            }
            isActionColActive={false}
            showPegination={false}
          />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  rateConfigurationEditingForStockholderList:
    state.stockHolderAndProductConfiguration,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getStockHolderAndProductConfigurationView,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RateConfigurationEditingForStockholderView);
