import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  dashbourdMain: {},
  lineGraphData:{}
};
let URL = endpoints.distributorDashbourd;
let monthWiseTotalAmount = endpoints.monthWiseTotalAmount;

const areaSlice = createSlice({
  name: "dashbourd",
  initialState,
  reducers: {
    dashbourdMainSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        dashbourdMain: row,
      };
    },
    lineSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        lineGraphData: row,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { dashbourdMainSuccess,lineSuccess, resetState } = areaSlice.actions;

export default areaSlice.reducer;



export const getDashBourdMain = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL ,
    }).then(({ data, success }) => {
      if (success) {
       console.log(data.data)
        dispatch(dashbourdMainSuccess({ row :data.data}));
        return { response: data.data, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};


export const getDataForLineGraph = ({fromDate,toDate}) => async (dispatch) => {
  try {
    const response = apiGet({
      url: monthWiseTotalAmount +"?fromDate="+fromDate+"&toDate="+toDate,
    }).then(({ data, success }) => {
      if (success) {
       console.log(data.data)
        dispatch(lineSuccess({ row :data.data}));
        return { response: data.data, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
