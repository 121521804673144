import endpoint from "../../config/endpoints";

export const SalesExecutiveJson = {
  formPath: "/sales-executive-form",
  openFormPath: "/sales-executive-document",
  apiBaseURL: endpoint.salesExecutive,
  changeExcel: endpoint.exportData,
  isEditURL: endpoint.salesExecutive + "/update",
  isActiveURL: endpoint.salesExecutive + "/update-is-active",
  screenTitle: "Sales Executive",
  searchButtonGrid: 1,
  showAddButton: true,
  showPdfDownload: false,
  pdfFileName: "SalesExecutive",
  showExcelDownload: true,
  excelFileName: "SalesExecutive",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Person Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Region",
      columnKeyName: "regionName",
      isChecked: true,
    },
    {
      columnDisplayName: "State",
      columnKeyName: "stateNames",
      isChecked: true,
    },
    {
      columnDisplayName: "Zone",
      columnKeyName: "zoneNames",
      isChecked: true,
    },
    {
      columnDisplayName: "Area",
      columnKeyName: "areaNames",
      isChecked: true,
    },
    {
      columnDisplayName: "Contact Number",
      columnKeyName: "contactNo",
      isChecked: true,
    },
    {
      columnDisplayName: "Whatsapp Number",
      columnKeyName: "whatsAppNo",
      isChecked: true,
    },
    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],

  fieldMeta: [
    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "regionDetail",
      dataKey: "region",
      getListId: "region",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "multiSelect",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "zoneDetail",
      dataKey: "zoneIds",
      getListFrom: "region",
      getListId: "zoneIds",
      isRootLevelKey: true,
      isMandatory: true,
    },

    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "areaIds",
      getListFrom: "taluka",
      getListFrom: "zone",
      getListId: "areaIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Person Name",
      controlType: "textfield",
      placeHolder: "Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "name",
      isMandatory: true,
    },
    {
      label: "Employee Code",
      controlType: "textfield",
      placeHolder: "Employee Code",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "employeeCode",
      isMandatory: false,
    },
    {
      label: "Address",
      controlType: "textfield",
      placeHolder: "Address",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "address",
      isMandatory: true,
    },
    {
      label: "Email Id",
      controlType: "textfield",
      placeHolder: "Email Id",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "emailId",
      valid: true,
      validName: "email",
      isMandatory: true,
    },
    {
      label: "Contact Number",
      controlType: "textfield",
      placeHolder: "Contact Number",
      inputType: "number",
      maxLength: 10,
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "contactNo",
      valid: true,
      validName: "phoneNo",
      isMandatory: true,
      sameAs: "whatsAppNo",
      sameAsKey: "whatsAppNoSame",
      sameAsLabel: "Same as Contact",
    },
    {
      label: "Whatsapp Number",
      controlType: "textfield",
      placeHolder: "Whatsapp Number",
      maxLength: 10,
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "whatsAppNo",
      valid: true,
      validName: "phoneNo",
      isMandatory: true,
    },
    {
      label: "Executive Of",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "type",
      tableDataKey: "type",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Admin",
          value: "0",
        },
        {
          label: "Distributor",
          value: "1",
        },
      ],
      isMandatory: true,
    },
  ],
};
