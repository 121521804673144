import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import RTTable from "../../components/Comman/RT/RTTable";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg } from "../../config/messageconstant";
import { BatchWiseManageOutstockJson } from "../../DynamicFormsJson/Transaction/batchWiseManageOutstock";
import { getManageStockById } from "../../Slice/manageStock.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";

class BatchWiseManageOutstockView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DataToSet: [],
            manageStockList: [],
        };
        this.columns = [
            {
                title: "Sr.No.",
                name: "index",
                align: "center",
            },
            {
                title: "Product Name",
                name: "productName",
            },
            {
                title: "UOM",
                name: "stakeHolderUomName",
                align: "center",
            },
            {
                title: "Rate",
                name: "rate",
                align: "right",
            },
            {
                title: "Order Quantity",
                name: "qty",
                align: "right",
            },
            {
                title: "Total Amount",
                name: "totalAmt",
                align: "right",
            },
        ];
    }
    async componentDidMount() {
        const { getManageStockById, showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            if (this.props.params.id) {
                showLoador({ loador: true });
                await getManageStockById({
                    managestockHeaderId: this.props.params.id,
                }).then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                    } else {
                        this.setState({
                            DataToSet: [
                                {
                                    xs: 12,
                                    sm: 4,
                                    md: 4,
                                    lg: 4,
                                    label: "Consumption No",
                                    value:
                                        response.consumptionNo !== null
                                            ? response.consumptionNo
                                            : "",
                                },

                                {
                                    xs: 12,
                                    sm: 4,
                                    md: 4,
                                    lg: 3,
                                    label: "Date",
                                    value: response.date !== null ? response.date : "",
                                },
                                {
                                    xs: 12,
                                    sm: 4,
                                    md: 4,
                                    lg: 3,
                                    label: "Type",
                                    value: response.type !== null ? response.type === 0 ? "Out-Stock"
                                        : "In-Stock" : "",
                                },
                            ],
                        });
                    }
                });
            }
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    render() {
        const { DataToSet } = this.state;
        const { manageStockList } = this.props;

        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 0,
                    }}
                    elevation={0}
                >
                    <DynamicFormWithoutSave
                        showBackToList={true}
                        DataToSet={DataToSet}
                        showSaveBtn={BatchWiseManageOutstockJson.showSaveBtn}
                        showTitle={true}
                        screenTitle={"Manage Out-Stock View"}
                        showCancel={BatchWiseManageOutstockJson.showCancel}
                        apiBaseURL={BatchWiseManageOutstockJson.apiBaseURL}
                        showSaveNextBtn={BatchWiseManageOutstockJson.showSaveNextBtn}
                        showSaveBtnMain={false}
                        padding={true}
                        paddingTop={true}
                    />
                    <RTTable
                        columns={this.columns}
                        tableData={
                            Object.keys(manageStockList.manageStockById).length != 0
                                ? manageStockList.manageStockById
                                    .stockStakeHolderConsumptionDetails
                                : []
                        }
                        isActionColActive={false}
                        showPegination={false}
                    />
                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    manageStockList: state.manageStockByDate,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getManageStockById,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(BatchWiseManageOutstockView);
