import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  noticeMsg: [],
  noticeMsgById: {},
  isFetch: false,
};
let URL = endpoints.noticeMsg;
const noticeMsgSlice = createSlice({
  name: "noticeMsg",
  initialState,
  reducers: {
    noticeMsgSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        noticeMsg: row,
        isFetch: true,
      };
    },
    noticeMsgByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        noticeMsgById: row,
        isFetch: true,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { noticeMsgSuccess, noticeMsgByIdSuccess, resetState } =
  noticeMsgSlice.actions;
export default noticeMsgSlice.reducer;
export const getNoticeMsg =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-date?fromDate=" + fromDate + "&toDate=" + toDate,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((noticeMsgProduct, index) => {
            let noticeListData = {
              index: index + 1,
              id: noticeMsgProduct.id === null ? "" : noticeMsgProduct.id,
              noticeHeadline:
                noticeMsgProduct.noticeHeadline === null
                  ? ""
                  : noticeMsgProduct.noticeHeadline,
              description:
                noticeMsgProduct.description === null
                  ? ""
                  : noticeMsgProduct.description,

              type: noticeMsgProduct.type === null ? "" : noticeMsgProduct.type,
              typeName:
                noticeMsgProduct.type === null
                  ? ""
                  : noticeMsgProduct.type == 0
                  ? "Notice"
                  : "Message",
              fromdate:
                noticeMsgProduct.fromdate === null
                  ? ""
                  : noticeMsgProduct.fromdate,
              todate:
                noticeMsgProduct.todate === null ? "" : noticeMsgProduct.todate,
              isActive:
                noticeMsgProduct.isActive === null
                  ? ""
                  : noticeMsgProduct.isActive,
              flash:
                noticeMsgProduct.flash === null ? "" : noticeMsgProduct.flash,
              flashType:
                noticeMsgProduct.flash === null
                  ? "-"
                  : noticeMsgProduct.flash == 0
                  ? "No"
                  : "Yes",
              stockStakeHolderTypeIds:
                noticeMsgProduct.stockStakeHolderTypeIds === null
                  ? ""
                  : noticeMsgProduct.stockStakeHolderTypeIds,
              stockStakeHolderTypeName:
                noticeMsgProduct.stockStakeHolderTypeName === null
                  ? ""
                  : noticeMsgProduct.stockStakeHolderTypeName,
              regionIds:
                noticeMsgProduct.regionIds === null
                  ? ""
                  : noticeMsgProduct.regionIds,
              regionName:
                noticeMsgProduct.regionName === null
                  ? ""
                  : noticeMsgProduct.regionName,
              areaIds:
                noticeMsgProduct.areaIds === null
                  ? ""
                  : noticeMsgProduct.areaIds,
              areaName:
                noticeMsgProduct.areaName === null
                  ? ""
                  : noticeMsgProduct.areaName,
              zoneIds:
                noticeMsgProduct.zoneIds === null
                  ? ""
                  : noticeMsgProduct.zoneIds,
              zoneName:
                noticeMsgProduct.zoneName === null
                  ? ""
                  : noticeMsgProduct.zoneName,
              flashName:
                noticeMsgProduct.flashName === null
                  ? "-"
                  : noticeMsgProduct.flashName,

              image:
                noticeMsgProduct.image === null ? "" : noticeMsgProduct.image,
            };
            return noticeListData;
          });
          dispatch(noticeMsgSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getActiveNoticeMsg = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?filters={"isActive":"1","_mode" : "EXACT"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((noticeMsgProduct, index) => {
          let noticeListData = {
            index: index + 1,
            id: noticeMsgProduct.id === null ? "" : noticeMsgProduct.id,
            noticeHeadline:
              noticeMsgProduct.noticeHeadline === null
                ? ""
                : noticeMsgProduct.noticeHeadline,
            description:
              noticeMsgProduct.description === null
                ? ""
                : noticeMsgProduct.description,

            type: noticeMsgProduct.type === null ? "" : noticeMsgProduct.type,
            typeName:
              noticeMsgProduct.type === null
                ? ""
                : noticeMsgProduct.type == 0
                ? "Notice"
                : "Message",
            fromdate:
              noticeMsgProduct.fromdate === null
                ? ""
                : noticeMsgProduct.fromdate,
            todate:
              noticeMsgProduct.todate === null ? "" : noticeMsgProduct.todate,
            isActive:
              noticeMsgProduct.isActive === null
                ? ""
                : noticeMsgProduct.isActive,
            flash:
              noticeMsgProduct.flash === null ? "" : noticeMsgProduct.flash,
            flashType:
              noticeMsgProduct.flash === null
                ? "-"
                : noticeMsgProduct.flash == 0
                ? "No"
                : "Yes",
            stockStakeHolderTypeIds:
              noticeMsgProduct.stockStakeHolderTypeIds === null
                ? ""
                : noticeMsgProduct.stockStakeHolderTypeIds,
            stockStakeHolderTypeName:
              noticeMsgProduct.stockStakeHolderTypeName === null
                ? ""
                : noticeMsgProduct.stockStakeHolderTypeName,
            regionIds:
              noticeMsgProduct.regionIds === null
                ? ""
                : noticeMsgProduct.regionIds,
            regionName:
              noticeMsgProduct.regionName === null
                ? ""
                : noticeMsgProduct.regionName,
            areaIds:
              noticeMsgProduct.areaIds === null ? "" : noticeMsgProduct.areaIds,
            areaName:
              noticeMsgProduct.areaName === null
                ? ""
                : noticeMsgProduct.areaName,
            zoneIds:
              noticeMsgProduct.zoneIds === null ? "" : noticeMsgProduct.zoneIds,
            zoneName:
              noticeMsgProduct.zoneName === null
                ? ""
                : noticeMsgProduct.zoneName,
            flashName:
              noticeMsgProduct.flashName === null
                ? "-"
                : noticeMsgProduct.flashName,

            image:
              noticeMsgProduct.image === null ? "" : noticeMsgProduct.image,
          };
          return noticeListData;
        });
        dispatch(noticeMsgSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getNoticeMessageById =
  ({ noticeMsgHeaderId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + noticeMsgHeaderId,
      }).then(({ data, success }) => {
        let row;
        if (success) {
          const noticeMsgHeaderObject = data.data;
          let noticeMsgData = {
            id:
              noticeMsgHeaderObject.id === null ? "" : noticeMsgHeaderObject.id,
            stockStakeHolderTypeIds:
              noticeMsgHeaderObject.stockStakeHolderTypeIds === null
                ? ""
                : noticeMsgHeaderObject.stockStakeHolderTypeIds,
            stockStakeHolderTypeName:
              noticeMsgHeaderObject.stockStakeHolderTypeName === null
                ? ""
                : noticeMsgHeaderObject.stockStakeHolderTypeName,
            type:
              noticeMsgHeaderObject.type === null
                ? ""
                : noticeMsgHeaderObject.type,
            typeName:
              noticeMsgHeaderObject.type === null
                ? ""
                : noticeMsgHeaderObject.type == 0
                ? "Notice"
                : "Message",
            regionIds:
              noticeMsgHeaderObject.regionIds === null
                ? ""
                : noticeMsgHeaderObject.regionIds,
            regionName:
              noticeMsgHeaderObject.regionName === null
                ? ""
                : noticeMsgHeaderObject.regionName,
            zoneIds:
              noticeMsgHeaderObject.zoneIds === null
                ? ""
                : noticeMsgHeaderObject.zoneIds,
            zoneName:
              noticeMsgHeaderObject.zoneName === null
                ? ""
                : noticeMsgHeaderObject.zoneName,
            areaIds:
              noticeMsgHeaderObject.areaIds === null
                ? ""
                : noticeMsgHeaderObject.areaIds,
            areaName:
              noticeMsgHeaderObject.areaName === null
                ? ""
                : noticeMsgHeaderObject.areaName,
            noticeHeadline:
              noticeMsgHeaderObject.noticeHeadline === null
                ? ""
                : noticeMsgHeaderObject.noticeHeadline,
            description:
              noticeMsgHeaderObject.description === null
                ? ""
                : noticeMsgHeaderObject.description,
            flash:
              noticeMsgHeaderObject.flash === null
                ? ""
                : noticeMsgHeaderObject.flash,
            flashType:
              noticeMsgHeaderObject.flash === null
                ? ""
                : noticeMsgHeaderObject.flash == 0
                ? "No"
                : "Yes",
            fromdate:
              noticeMsgHeaderObject.fromdate === null
                ? ""
                : noticeMsgHeaderObject.fromdate,
            todate:
              noticeMsgHeaderObject.todate === null
                ? ""
                : noticeMsgHeaderObject.todate,
            isActive:
              noticeMsgHeaderObject.isActive === null
                ? ""
                : noticeMsgHeaderObject.isActive,
            flashName:
              noticeMsgHeaderObject.flashName === null
                ? "-"
                : noticeMsgHeaderObject.flashName,
            image:
              noticeMsgHeaderObject.image === null
                ? ""
                : noticeMsgHeaderObject.image,
          };
          dispatch(noticeMsgByIdSuccess({ row: noticeMsgData }));
          return { response: noticeMsgData, success };
        }
        return { response: {}, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getNoticeMsgByUserType = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/by-user",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((noticeMsgProduct, index) => {
          let noticeListData = {
            index: index + 1,
            id: noticeMsgProduct.id === null ? "" : noticeMsgProduct.id,
            noticeHeadline:
              noticeMsgProduct.noticeHeadline === null
                ? ""
                : noticeMsgProduct.noticeHeadline,
            description:
              noticeMsgProduct.description === null
                ? ""
                : noticeMsgProduct.description,

            type: noticeMsgProduct.type === null ? "" : noticeMsgProduct.type,
            typeName:
              noticeMsgProduct.type === null
                ? ""
                : noticeMsgProduct.type == 0
                ? "Notice"
                : "Message",
            fromdate:
              noticeMsgProduct.fromdate === null
                ? ""
                : noticeMsgProduct.fromdate,
            todate:
              noticeMsgProduct.todate === null ? "" : noticeMsgProduct.todate,
            isActive:
              noticeMsgProduct.isActive === null
                ? ""
                : noticeMsgProduct.isActive,
            flash:
              noticeMsgProduct.flash === null ? "" : noticeMsgProduct.flash,
            flashType:
              noticeMsgProduct.flash === null
                ? "-"
                : noticeMsgProduct.flash == 0
                ? "No"
                : "Yes",
            stockStakeHolderTypeIds:
              noticeMsgProduct.stockStakeHolderTypeIds === null
                ? ""
                : noticeMsgProduct.stockStakeHolderTypeIds,
            stockStakeHolderTypeName:
              noticeMsgProduct.stockStakeHolderTypeName === null
                ? ""
                : noticeMsgProduct.stockStakeHolderTypeName,
            regionIds:
              noticeMsgProduct.regionIds === null
                ? ""
                : noticeMsgProduct.regionIds,
            regionName:
              noticeMsgProduct.regionName === null
                ? ""
                : noticeMsgProduct.regionName,
            areaIds:
              noticeMsgProduct.areaIds === null ? "" : noticeMsgProduct.areaIds,
            areaName:
              noticeMsgProduct.areaName === null
                ? ""
                : noticeMsgProduct.areaName,
            zoneIds:
              noticeMsgProduct.zoneIds === null ? "" : noticeMsgProduct.zoneIds,
            zoneName:
              noticeMsgProduct.zoneName === null
                ? ""
                : noticeMsgProduct.zoneName,
            flashName:
              noticeMsgProduct.flashName === null
                ? "-"
                : noticeMsgProduct.flashName,

            image:
              noticeMsgProduct.image === null ? "" : noticeMsgProduct.image,
          };
          return noticeListData;
        });
        dispatch(noticeMsgSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const setNoticeMsg = () => async (dispatch) => {
  dispatch(noticeMsgSuccess({ row: [] }));
};
