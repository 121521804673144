import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  dashboard: {},
  dashboardDataForProduct: [],
  salesTeamList: [],
  salesManagerDataForProduct: [],
  salesManagerDataForOutlet: [],
};
let URL = endpoints.dashboardAdmin;
let URL1 = endpoints.outletBillHeader;
let URL2 = endpoints.product;

const dashboardAdminSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    dashboardAdminSucess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        dashboard: row,
      };
    },

    dashboardAdminForProductSucess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        dashboardDataForProduct: row,
      };
    },

    salesTeamListSucess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        salesTeamList: row,
      };
    },
    salesManagerAdminForProductSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        salesManagerDataForProduct: row,
      };
    },

    salesManagerAdminForOutletSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        salesManagerDataForOutlet: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  dashboardAdminSucess,
  dashboardAdminForProductSucess,
  salesTeamListSucess,
  salesManagerAdminForProductSuccess,
  salesManagerAdminForOutletSuccess,
} = dashboardAdminSlice.actions;

export default dashboardAdminSlice.reducer;
export const getAdminDashboard = () => async (dispatch) => {
  const { data, success } = await apiGet({
    url: URL,
  });
  if (success) {
    let dashboardData = {
      orderAndTargetAmt:
        data.data.orderAndTargetAmt === null ? "" : data.data.orderAndTargetAmt,
      currentTargetAmt:
        data.data.orderAndTargetAmt.currentTargetAmt === null
          ? ""
          : data.data.orderAndTargetAmt.currentTargetAmt,
      previousTargetAmt:
        data.data.orderAndTargetAmt.previousTargetAmt === null
          ? ""
          : data.data.orderAndTargetAmt.previousTargetAmt,
      currentAchievedAmt:
        data.data.orderAndTargetAmt.currentAchievedAmt === null
          ? ""
          : data.data.orderAndTargetAmt.currentAchievedAmt,
      totalOutletCount:
        data.data.orderAndTargetAmt.totalOutletCount === null
          ? ""
          : data.data.orderAndTargetAmt.totalOutletCount,
      totalStakeHolderCount:
        data.data.orderAndTargetAmt.totalStakeHolderCount === null
          ? ""
          : data.data.orderAndTargetAmt.totalStakeHolderCount,
      totalSalesTeamCount:
        data.data.orderAndTargetAmt.totalSalesTeamCount === null
          ? ""
          : data.data.orderAndTargetAmt.totalSalesTeamCount,
      monthYearWiseTarget:
        data.data.monthYearWiseTarget === null
          ? ""
          : data.data.monthYearWiseTarget,
      productWithOrders:
        data.data.productWithOrders === null ? "" : data.data.productWithOrders,
      targetAmt:
        data.data.monthYearWiseTarget.targetAmt === null
          ? ""
          : data.data.monthYearWiseTarget.targetAmt,
      achivedAmt:
        data.data.monthYearWiseTarget.achivedAmt === null
          ? ""
          : data.data.monthYearWiseTarget.achivedAmt,
      contribution: (
        (data.data.monthYearWiseTarget.achivedAmt /
          data.data.monthYearWiseTarget.targetAmt) *
        100
      ).toFixed(2),
      regionWithActualAmts: data.data.regionWithActualAmts.map(
        (regionObject) => {
          regionObject = {
            ...regionObject,
          };
          return regionObject;
        }
      ),
      salesTeamInformations: data.data.salesTeamInformations.map(
        (salesTeamObject) => {
          salesTeamObject = {
            ...salesTeamObject,
          };
          return salesTeamObject;
        }
      ),
    };
    let dashboardDataForProduct = data.data.productWithOrders.map(
      (productObject) => {
        return productObject;
      }
    );
    let salesTeamList = data.data.salesTeamInformations.map(
      (salesTeamObject) => {
        return salesTeamObject;
      }
    );
    dispatch(dashboardAdminSucess({ row: dashboardData }));
    dispatch(dashboardAdminForProductSucess({ row: dashboardDataForProduct }));
    dispatch(salesTeamListSucess({ row: salesTeamList }));
    return { data: dashboardData, success };
  } else {
    return { data: {}, success: false };
  }
};
export const getTopProductOrderListFromYearAndMonthBillWise =
  ({ month, year }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/top-product-bill-value-by-month-year-assign-area?month=" +
          month +
          "&year=" +
          year,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((productObject) => {
            finalTotal = finalTotal + productObject.orderVale;
          });
          const row = data.data.map((productObject, index) => {
            let productListData = {
              index: index + 1,
              id: productObject.id === null ? "" : productObject.id,
              name: productObject.name === null ? "" : productObject.name,
              code: productObject.code === null ? "" : productObject.code,
              uomConversionValue:
                productObject.uomConversionValue === null
                  ? ""
                  : productObject.uomConversionValue,
              orderVale:
                productObject.orderVale === null
                  ? ""
                  : productObject.orderVale.toFixed(2),
              contribution: (
                (productObject.orderVale / finalTotal) *
                100
              ).toFixed(2),
              qty: productObject.qty === null ? "" : productObject.qty,
              isActive:
                productObject.isActive === null ? "" : productObject.isActive,
              sortOrder:
                productObject.sortOrder === null ? "" : productObject.sortOrder,
              shortName:
                productObject.shortName === null ? "" : productObject.shortName,
              weight: productObject.weight === null ? "" : productObject.weight,
              description:
                productObject.description === null
                  ? ""
                  : productObject.description,
              selfLife:
                productObject.selfLife === null ? "" : productObject.selfLife,
              group: productObject.group === null ? "" : productObject.group,
              category:
                productObject.group.subCategory.category === null &&
                productObject.group.subCategory.category.name
                  ? ""
                  : productObject.group.subCategory.category.name,
              subCategory:
                productObject.group.subCategory === null &&
                productObject.group.subCategory.name === null
                  ? ""
                  : productObject.group.subCategory.name,
              taxPercentage:
                productObject.taxPercentage === null
                  ? ""
                  : productObject.taxPercentage.name,
              stakeHolderUom:
                productObject.stakeHolderUom === null
                  ? ""
                  : productObject.stakeHolderUom.name,
              outletUom:
                productObject.outletUom === null
                  ? ""
                  : productObject.outletUom.name,
            };
            return productListData;
          });
          dispatch(
            dashboardAdminForProductSucess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getTopProductOrderListFromAreaAndYearAndMonthBillWise =
  ({ areaIds, month, year }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/top-product-bill-value-by-month-year-areas?areaIds=" +
          areaIds +
          "&month=" +
          month +
          "&year=" +
          year,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((productObject) => {
            finalTotal = finalTotal + productObject.orderVale;
          });
          const row = data.data.map((productObject, index) => {
            let productListData = {
              index: index + 1,
              id: productObject.id === null ? "" : productObject.id,
              name: productObject.name === null ? "" : productObject.name,
              code: productObject.code === null ? "" : productObject.code,
              uomConversionValue:
                productObject.uomConversionValue === null
                  ? ""
                  : productObject.uomConversionValue,
              actualSale:
                productObject.orderVale === null
                  ? ""
                  : productObject.orderVale.toFixed(2),
              contribution: (
                (productObject.orderVale / finalTotal) *
                100
              ).toFixed(2),
              qty: productObject.qty === null ? "" : productObject.qty,
              isActive:
                productObject.isActive === null ? "" : productObject.isActive,
              sortOrder:
                productObject.sortOrder === null ? "" : productObject.sortOrder,
              shortName:
                productObject.shortName === null ? "" : productObject.shortName,
              weight: productObject.weight === null ? "" : productObject.weight,
              description:
                productObject.description === null
                  ? ""
                  : productObject.description,
              selfLife:
                productObject.selfLife === null ? "" : productObject.selfLife,
              group: productObject.group === null ? "" : productObject.group,
              category:
                productObject.group.subCategory.category === null &&
                productObject.group.subCategory.category.name
                  ? ""
                  : productObject.group.subCategory.category.name,
              subCategory:
                productObject.group.subCategory === null &&
                productObject.group.subCategory.name === null
                  ? ""
                  : productObject.group.subCategory.name,
              taxPercentage:
                productObject.taxPercentage === null
                  ? ""
                  : productObject.taxPercentage.name,
              stakeHolderUom:
                productObject.stakeHolderUom === null
                  ? ""
                  : productObject.stakeHolderUom.name,
              outletUom:
                productObject.outletUom === null
                  ? ""
                  : productObject.outletUom.name,
            };
            return productListData;
          });
          dispatch(
            salesManagerAdminForProductSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getTopOutletOrderListFromAreaAndYearAndMonth =
  ({ areaIds, month, year }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/top-outlet-bill-value-by-month-year-areas?areaIds=" +
          areaIds +
          "&month=" +
          month +
          "&year=" +
          year,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((outletObject) => {
            finalTotal = finalTotal + outletObject.orderValue;
          });
          const row = data.data.map((outletObject, index) => {
            let outletListData = {
              index: index + 1,
              id: outletObject.id === null ? "" : outletObject.id,
              firmName:
                outletObject.firmName === null ? "" : outletObject.firmName,
              beatName:
                outletObject.beatName === null
                  ? ""
                  : outletObject.beat.beatName,
              area:
                outletObject.beat !== null &&
                outletObject.beat.area !== null &&
                outletObject.beat.area.name
                  ? outletObject.beat.area.name
                  : "",
              salesExective: outletObject.salesTeam === null ? "-" : "0",
              outLetType:
                outletObject.outLetType === null
                  ? ""
                  : outletObject.outLetType.name,
              orderValue:
                outletObject.orderValue === null
                  ? ""
                  : +outletObject.orderValue.toFixed(0),
              actualSale:
                outletObject.orderVale === null
                  ? ""
                  : outletObject.orderVale.toFixed(2),

              address:
                outletObject.address === null ? "" : outletObject.address,
              state: outletObject.state === null ? "" : outletObject.state.name,
              mobileNo:
                outletObject.mobileNo === null ? "" : outletObject.mobileNo,
              emailId:
                outletObject.emailId === null ? "" : outletObject.emailId,
              gstNo: outletObject.gstNo === null ? "" : outletObject.gstNo,
              panNo: outletObject.panNo === null ? "" : outletObject.panNo,
              gstType:
                outletObject.gstType === null ? "" : outletObject.gstType,
              fssiFdiNo:
                outletObject.fssiFdiNo === null ? "" : outletObject.fssiFdiNo,
              isActive:
                outletObject.isActive === null ? "" : outletObject.isActive,
              status: outletObject.status === null ? "" : outletObject.status,
              image: outletObject.image === null ? "" : outletObject.image,
              referenceName:
                outletObject.referenceName === null
                  ? ""
                  : outletObject.referenceName,
              contribution: +(
                (outletObject.orderValue / +finalTotal) *
                100
              ).toFixed(0),
            };
            return outletListData;
          });
          dispatch(
            salesManagerAdminForOutletSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getTopProductForAdminDashboard = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL2 + "/with-order-value",
    }).then(({ data, success }) => {
      if (success) {
        dispatch(dashboardAdminForProductSucess({ row: data.data }));
        return { response: data.data, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};