// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import TextField from "@mui/material/TextField";
// import * as React from "react";
// //import frLocale from 'date-fns/locale/fr';
// import enINLocale from "date-fns/locale/en-IN";

// export default function BasicDatePicker({
//   label,
//   value,
//   isError = false,
//   errorText = " ",
//   maxDate,
//   minDate,
//   onChange,
//   disabled,
//   addOne=false
// }) {
//   let date =new Date()
//   return (
//     <LocalizationProvider dateAdapter={AdapterDateFns} locale={enINLocale}>
//       <DatePicker
//         label={label}
//         //value={value?moment(value).format('DD/MM/YYYY'):null}
//         value={value || null}
//         maxDate={maxDate}
//         // format={'DD/MM/YYYY'}
//         minDate={addOne?date.setDate(date.getDate()+1):minDate}
//         onChange={onChange}
//         disabled={disabled}
//         slotProps={{
//           textField: {
//             fullWidth: true, variant: 'standard', error: isError,
//             helperText: errorText,
//             value:value
//           }
//         }}

//       // renderInput={(params) => (
//       //   <TextField
//       //     size="small"
//       //     {...params}
//       //     sx={{width: '100%'}}
//       //     value={value}
//       //     error={isError}
//       //     helperText={errorText}
//       //   />
//       // )}
//       />
//     </LocalizationProvider>
//   );
// }

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as React from "react";

export default function DatePickerValue({
  label,
  value,
  isError = false,
  errorText = " ",
  maxDate,
  minDate,
  onChange,
  disabled,
  addOne = false,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={value || null}
        maxDate={maxDate}
        format={"DD/MM/YYYY"}
        // minDate={new Date()}
        minDate={minDate}
        onChange={(newValue) => onChange(newValue)}
        disabled={disabled}
        slotProps={{
          textField: {
            fullWidth: true,
            variant: "standard",
            error: isError,
            helperText: errorText,
            value: value,
          },
        }}
      />
    </LocalizationProvider>
  );
}
