import { Grid, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import DynamicFormWithoutValidation from "../../components/dynamicscreens/DynamicFormWithoutValidation";
import endpoint from "../../config/endpoints";
import {
  atleastOne,
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { mandatoryCheck, ValidationCheck } from "../../config/ValidationCheck";
import { CreditNoteForOutletNewJson } from "../../DynamicFormsJson/Transaction/creditNoteForOutletNew";
import { getAreaByUserType } from "../../Slice/area.slice";
import { getBeatByArea } from "../../Slice/beat.slice";
import { setCategoryAndItem } from "../../Slice/category.slice";
import { getActiveOutletByBeatId } from "../../Slice/outlet.slice";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import CreditNoteTableNew from "./CreditNoteTableNew";
import SubmitOrderCreditNote from "./SubmitOrderCreditNote";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../components/withRouter";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

class CreditNoteFormNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      dynamicMasterData: {
        areaDetail: this.props.areaList.area,
        beatDetail: this.props.beatList.beat,
        outletDetail: this.props.outletList.outlets,
      },
      formErrors: {},
      fieldData: { instockOutstock: "1" },
      DataToSet: [],
      value: 0,
      total: 0,
      totalAmt: 0,
      open: false,
      orderList: [],
      salesPersonId: "",
      openTabs: false,
      stockFlag: false,
    };
  }

  setCatList = ({ row }) => {
    const { setCategoryAndItem } = this.props;
    let dataTotal = 0;
    row.map((rowData) => {
      rowData.productWithMarginAndMrps.map((productData) => {
        dataTotal = dataTotal + +productData.total;
      });
    });
    const { fieldData } = this.state;
    const discount = fieldData.DiscountPercentage
      ? fieldData.DiscountPercentage
      : 0;
    let discountAmt = (dataTotal * +discount) / 100;
    this.setState({
      totalAmt: dataTotal - +discountAmt,
      total: dataTotal,
    });
    setCategoryAndItem({ row });
  };
  async componentDidMount() {
    const { getAreaByUserType, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getAreaByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.area !== nextProps.areaList?.area) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.area,
          },
        });
      }
    }

    if (this.props.beatList !== nextProps.beatList) {
      if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            beatDetail: nextProps.beatList?.beat,
          },
        });
      }
    }

    if (this.props.outletList !== nextProps.outletList) {
      if (this.props.outletList?.outlets !== nextProps.outletList?.outlets) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            outletDetail: nextProps.outletList?.outlets,
          },
        });
      }
    }
  }
  handleChangeNew = (event, newValue) => {
    this.setState({
      value: newValue,
      formErrors: {},
      fieldData: {},
    });
  };
  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };
  submitHandler = () => {
    if (this.checkValidationOnSubmit()) {
      const { category } = this.props;
      const { stockFlag } = this.state;
      let rowList = [];
      if (stockFlag) {
        category.category.map((catData) => {
          if (catData.flag == 3) {
            const filterData = catData.productWithMarginAndMrps.filter(
              (row) =>
                row.qty != 0 && row.secondaryClosingQty > 0 && !row.stockError
            );
            rowList = [...rowList, ...filterData];
            return catData;
          }
        });
      } else {
        category.category.map((catData) => {
          if (catData.flag == 3) {
            const filterData = catData.productWithMarginAndMrps.filter(
              (row) => row.qty != 0
            );
            rowList = [...rowList, ...filterData];
            return catData;
          }
        });
      }

      if (rowList.length != 0) {
        this.setState({
          orderList: rowList.map((rowData, index) => {
            return {
              index: index + 1,
              productName:
                rowData.products !== null && rowData.products.name
                  ? rowData.products.name
                  : "",
              outletName:
                rowData.products !== null &&
                  rowData.products.outletUom !== null &&
                  rowData.products.outletUom.name
                  ? rowData.products.outletUom.name
                  : "",
              qty: rowData.qty !== null ? rowData.qty : 0,
              rate: rowData.rate !== null ? rowData.rate : 0,
              ...rowData,
              uomConversionValue:
                rowData.uomConversionValue !== ""
                  ? rowData.uomConversionValue
                  : 0,
              total: rowData.total,
            };
          }),
        });
        this.toggle();
      } else {
        this.props.showNotification({ msg: atleastOne, severity: "error" });
      }
    }
  };
  onDataChange = (fieldName, newValue) => {
    if (fieldName == "DiscountPercentage") {
      let discountAmt = (this.state.total * +newValue) / 100;
      this.setState({
        totalAmt: +this.state.total - +discountAmt,
      });
    }
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    CreditNoteForOutletNewJson.fieldMeta.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        // dataToSearch[fieldName] = newValue;
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };
  getListById = async (data) => {
    const {
      getBeatByArea,
      getActiveOutletByBeatId,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      this.setState({
        fieldData: data,
      });
      if (data.area) {
        showLoador({ loador: true });
        await getBeatByArea({ areaId: data.area ? data.area.id : 0 }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.beat) {
        showLoador({ loador: true });

        await getActiveOutletByBeatId({
          beatIds: data.beat ? data.beat.id : 0,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.beat && data.outletIds) {
        let beatId = data.beat ? data.beat.id : 0;
        await apiGet({
          url:
            endpoint.categoryLatest +
            "/items-with-margin-and-mrp-by-beat-new?beatId=" +
            beatId,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success && data.data != null) {
            const rowData = data.data;
            if (rowData.errorMessage.error) {
              showNotification({
                msg: rowData.errorMessage.message,
                severity: "error",
              });
            } else {
              showLoador({ loador: true });
              const row = rowData.categoryAndItems.map((rowObj) => {
                return {
                  ...rowObj,
                  total: 0,
                  qty: 0,
                  productWithMarginAndMrps: rowObj.productWithMarginAndMrps.map(
                    (product) => {
                      return {
                        ...product,
                        uomConversionValue: product.products.uomConversionValue,
                        qty: 0,
                        freeQty: 0,
                        itemDiscPer: 0,
                        total: 0,
                      };
                    }
                  ),
                };
              });
              this.setCatList({ row });
              this.setState({ openTabs: true });
              showLoador({ loador: false });
            }
          } else {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  checkValidationOnSubmit = () => {
    const { fieldData } = this.state;
    const mandatoryCheckErrors = mandatoryCheck({
      fieldData,
      fieldMeta: CreditNoteForOutletNewJson.fieldMeta,
    });
    this.setState({
      formErrors: mandatoryCheckErrors.formErrors,
    });
    if (mandatoryCheckErrors.formValid) {
      const ValidationCheckErrors = ValidationCheck({
        fieldData,
        fieldMeta: CreditNoteForOutletNewJson.fieldMeta,
      });
      this.setState({
        formErrors: ValidationCheckErrors.formErrors,
      });
      return ValidationCheckErrors.formValid;
    } else {
      return false;
    }
  };
  getOrder = () => {
    const { orderList, fieldData } = this.state;
    const outletBillDetails = orderList.map((orderData) => {
      return {
        batchCode: "B1",
        billDetailId: "0",
        outletBillDetailId: "0",
        hsnCode: orderData.products.taxPercentage.hsnCode,
        product: orderData.products,
        qty: +orderData.qty,
        rate: orderData.rate,
      };
    });
    const dataToSave = {
      creditNoteDetail: outletBillDetails,
      date: moment(new Date()).format("DD-MM-YYYY"),
      instockOutstock: fieldData.instockOutstock,
      outLet: {
        id: fieldData.outletIds,
      },
      remark: "NA",
    };
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onSave(dataToSave);
      }
    });
  };
  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      this.toggle();
      showLoador({ loador: true });
      apiPost({
        url: endpoint.creditNote,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        if (success) {
          showLoador({ loador: false });
          this.props.navigate(-1);
          showNotification({ msg: savemsg });
        } else {
          showLoador({ loador: false });
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  toggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  getFilteredTableData = (tableData) => {
    const { searchValue } = this.state;
    return tableData.filter((currentRow) => {
      let isValid = false;
      let productName =
        currentRow.products != null ? currentRow.products.name : "-";
      let outletUom =
        currentRow.products.outletUom != null
          ? currentRow.products.outletUom.name
          : "-";
      let rate = currentRow.rate != null ? currentRow.rate : "-";
      // let qty = currentRow.qty != null ? currentRow.qty : "-";
      if (
        productName &&
        productName.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        outletUom &&
        outletUom.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (rate && rate.toLowerCase().includes(searchValue.toLowerCase())) {
        isValid = true;
        return true;
      }
      // if (qty && qty.toLowerCase().includes(searchValue.toLowerCase())) {
      //   isValid = true;
      //   return true;
      // }
      return isValid;
    });
  };
  render() {
    const {
      dynamicMasterData,
      DataToSet,
      value,
      total,
      totalAmt,
      open,
      orderList,
      formErrors,
      fieldData,
      openTabs,
      stockFlag,
    } = this.state;
    const { category } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutValidation
            getListById={this.getListById}
            dynamicMasterData={dynamicMasterData}
            showTitle={""}
            screenTitle={CreditNoteForOutletNewJson.screenTitle}
            fieldMeta={CreditNoteForOutletNewJson.fieldMeta}
            apiBaseURL={CreditNoteForOutletNewJson.apiBaseURL}
            formErrors={formErrors}
            showSaveBtn={CreditNoteForOutletNewJson.showSaveBtn}
            showSaveBtnMain={false}
            fieldData={fieldData}
            onDataChange={this.onDataChange}
          />
          {category.category.length != 0 && openTabs && (
            <>
              <Tabs
                value={value}
                onChange={this.handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
              >
                {category.category.map((catData) => {
                  return (
                    <Tab
                      label={
                        catData.category.name +
                        " ( " +
                        catData.productWithMarginAndMrps.length +
                        " )"
                      }
                    />
                  );
                })}
              </Tabs>
              {category.category.map((catData, index) => {
                return (
                  <TabPanel value={value} index={index}>
                    <LandingScreenHeader
                      screenTitle={""}
                      showSearchBox={true}
                      showTable={true}
                      showFilter={false}
                      showPdfDownload={false}
                      showExcelDownload={false}
                      onSearch={this.onSearch}
                    />
                    <CreditNoteTableNew
                      tableHead={
                        stockFlag
                          ? [
                            {
                              title: "Sr.No.",
                              name: "index",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "center",
                            },
                            {
                              title: "Product Name",
                              name: "name",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "left",
                            },
                            {
                              title: "UOM",
                              name: "name",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "center",
                            },
                            {
                              title: "Rate",
                              name: "name",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "left",
                            },
                            {
                              title: "Current stock",
                              name: "secondaryClosingQty",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "left",
                            },
                            {
                              title: "Quantity",
                              name: "target",
                              placeHolder: "Enter Target",
                              textFieldError: "targetErr",
                              positionCenter: false,
                              showInscreen: true,
                            },
                            {
                              title: "Total",
                              name: "name",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "left",
                            },
                          ]
                          : [
                            {
                              title: "Sr.No.",
                              name: "index",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "center",
                            },
                            {
                              title: "Product Name",
                              name: "name",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "left",
                            },
                            {
                              title: "UOM",
                              name: "name",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "center",
                            },
                            {
                              title: "Rate",
                              name: "name",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "left",
                            },
                            {
                              title: "Quantity",
                              name: "target",
                              placeHolder: "Enter Target",
                              textFieldError: "targetErr",
                              positionCenter: false,
                              showInscreen: true,
                            },
                            {
                              title: "Total",
                              name: "name",
                              positionCenter: false,
                              showInscreen: true,
                              alignCenter: "left",
                            },
                          ]
                      }
                      // data={catData.productWithMarginAndMrps}
                      data={this.getFilteredTableData(
                        catData.productWithMarginAndMrps
                      )}
                      rowList={category.category}
                      setList={this.setCatList}
                      flag={stockFlag}
                    />
                  </TabPanel>
                );
              })}

              <Grid container spacing={1}>
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ justifyContent: "flex-end" }}
                >
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name={"Proceed"}
                    onClick={this.submitHandler}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
        {open && (
          <SubmitOrderCreditNote
            formData={{
              total: total,
              discount: fieldData.DiscountPercentage
                ? fieldData.DiscountPercentage
                : 0,
              totalAmt: totalAmt,
            }}
            columns={[
              {
                title: "Sr. No.",
                name: "index",
                positionCenter: true,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Product Name",
                name: "productName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
                canSearch: true,
              },
              {
                title: "UOM",
                name: "outletName",
                positionCenter: true,
                showInscreen: true,
                alignCenter: "center",
                canSearch: true,
              },

              {
                title: "Quantity",
                name: "qty",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
                canSearch: true,
              },
              {
                title: "Rate",
                name: "rate",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
                canSearch: true,
              },
              {
                title: "Total",
                name: "total",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
                canSearch: true,
              },
            ]}
            open={open}
            handleCloses={this.toggle}
            rows={orderList}
            getOrder={this.getOrder}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  areaList: state.area,
  outletList: state.outlet,
  beatList: state.beat,
  outletOrderDetail: state.outletOrderDetail,
  category: state.category,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getAreaByUserType,
  getBeatByArea,
  getActiveOutletByBeatId,
  setCategoryAndItem,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CreditNoteFormNew);
