import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { ProductColumns } from "../../../tableColumns/table-columns";
import { productJson } from "../../../DynamicFormsJson/MastersJSON/product";
import { getProduct } from "../../../Slice/product.slice";

class Product extends React.Component {
 constructor(props) {
    super(props);
  }

  render() {
    const { productList, getProduct } = this.props;
    return (
      <>
        <DynamicMainScreen
          showSend={true}
          showOutlet={true}
          formPathForsend={productJson.formPathForsend}
          formPath={productJson.formPath}
          showAddButtonInTable={productJson.showAddButtonInTable}
          screenTitle={productJson.screenTitle}
          fieldMeta={productJson.fieldMeta}
          showPdfDownload={productJson.showPdfDownload}
          showExcelDownload={productJson.showExcelDownload}
          pdfFileName={productJson.pdfFileName}
          excelFileName={productJson.excelFileName}
          showAddButton={productJson.showAddButton}
          tableColumnsToFilter={productJson.tableColumnsToFilter}
          openFormPath={productJson.openFormPath}
          formPathForOutlet={productJson.formPathForOutlet}
          tableColumns={ProductColumns}
          tableData={productList?.product}
          getTableData={getProduct}
          apiBaseURL={productJson.apiBaseURL}
          baseIdColumn={productJson.baseIdColumn}
          isNote={true}
          isNoteValue={"Add Different Products"}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  productList: state.product,
});
const mapDispatchToProps = {
  getProduct,
};
export default connect(mapStateToProps, mapDispatchToProps)(Product);
