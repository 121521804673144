import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
    claimType: [],
    claimStructure: [],
};

let URL = endpoints.claimType;
let URL1 = endpoints.claimStructure;

const claimSlice = createSlice({
    name: "claim",
    initialState,
    reducers: {
        claimTypeSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                claimType: row,
            };
        },

        claimStructureSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                claimStructure: row,
            };
        },

        resetState: (state = cloneDeep(initialState), action) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const {
    claimTypeSuccess,
    claimStructureSuccess,
    resetState,
} = claimSlice.actions;

export default claimSlice.reducer;

export const getClaimType = () => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL,
        }).then(({ data, success }) => {
            if (success) {
                const { content } = data.data;
                const row = content.map((claimTypeObj, index) => {
                    let claimTypeData = {
                        index: index + 1,
                        id: claimTypeObj.id === null ? "" : claimTypeObj.id,
                        name:
                            claimTypeObj.name === null
                                ? ""
                                : claimTypeObj.name,
                        shortName:
                            claimTypeObj.shortName === null ? "" : claimTypeObj.shortName,
                        remark:
                            claimTypeObj.remark === null
                                ? ""
                                : claimTypeObj.remark,
                        limitValue: claimTypeObj.limitVal === null ? "" : claimTypeObj.limitVal == 1 ? "Yes" : "No",
                        limitVal:
                            claimTypeObj.limitVal === null ? "" : claimTypeObj.limitVal,
                        type:
                            claimTypeObj.type === null ? "" : claimTypeObj.type,
                        uomName:
                            claimTypeObj.uomName === null ? "" : claimTypeObj.uomName,
                        attachmentApplicable: claimTypeObj.attachmentApplicable === null ? "" : claimTypeObj.attachmentApplicable,
                        extra1:
                            claimTypeObj.extra1 === null ? "" : claimTypeObj.extra1,
                        company:
                            claimTypeObj.company === null ? "" : claimTypeObj.company,
                        companyId:
                            claimTypeObj.company === null ? "" : claimTypeObj.company.id,
                        companyName:
                            claimTypeObj.company === null ? "" : claimTypeObj.company.name,
                        claimAmt: 0,

                    };
                    return claimTypeData;
                });
                dispatch(claimTypeSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};



export const setClaimType =
    ({ row }) =>
        async (dispatch) => {
            dispatch(claimTypeSuccess({ row }));
        };


export const getClaimStructure = () => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL1,
        }).then(({ data, success }) => {
            if (success) {
                const { content } = data.data;
                const row = content.map((claimTypeObj, index) => {
                    let claimTypeData = {
                        index: index + 1,
                        id: claimTypeObj.id === null ? "" : claimTypeObj.id,
                        name:
                            claimTypeObj.name === null
                                ? ""
                                : claimTypeObj.name,
                        shortName:
                            claimTypeObj.shortName === null ? "" : claimTypeObj.shortName,
                        remark:
                            claimTypeObj.remark === null
                                ? ""
                                : claimTypeObj.remark,
                        extra1:
                            claimTypeObj.extra1 === null ? "" : claimTypeObj.extra1,
                        company:
                            claimTypeObj.company === null ? "" : claimTypeObj.company,
                        companyId:
                            claimTypeObj.company === null ? "" : claimTypeObj.company.id,
                        companyName:
                            claimTypeObj.company === null ? "" : claimTypeObj.company.name,

                    };
                    return claimTypeData;
                });
                dispatch(claimStructureSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};


export const getActiveClaimStructure = () => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL1 + "/get-active",
        }).then(({ data, success }) => {
            if (success) {
                const row = data.data.map((claimTypeObj, index) => {
                    let claimTypeData = {
                        index: index + 1,
                        id: claimTypeObj.id === null ? "" : claimTypeObj.id,
                        name:
                            claimTypeObj.name === null
                                ? ""
                                : claimTypeObj.name,
                        shortName:
                            claimTypeObj.shortName === null ? "" : claimTypeObj.shortName,
                        remark:
                            claimTypeObj.remark === null
                                ? ""
                                : claimTypeObj.remark,
                        extra1:
                            claimTypeObj.extra1 === null ? "" : claimTypeObj.extra1,
                        company:
                            claimTypeObj.company === null ? "" : claimTypeObj.company,
                        companyId:
                            claimTypeObj.company === null ? "" : claimTypeObj.company.id,
                        companyName:
                            claimTypeObj.company === null ? "" : claimTypeObj.company.name,

                    };
                    return claimTypeData;
                });
                dispatch(claimStructureSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};



