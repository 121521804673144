import React, { useEffect, useState } from "react";
import "./billPDFCss.css";
import { langugae } from "../../config/languageJSON";
import endpoint from "../../config/endpoints";
import { apiGet, apiPost } from "../../utils/api_service";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../components/withRouter";
import { connect } from "react-redux";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { imageUrl } from "../../config/Url";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import QRCode from "qrcode.react";
import NumberToWords from "../../components/NumberToWords";
const MyComponent = ({ showLoador, showNotification }) => {
  const params = useParams();
  const [billList, setBill] = useState([]);
  let lang = localStorage.getItem("lang");
  let billPdfNewId = localStorage.getItem("billPdfNewId");
  useEffect(() => {
    // window.print();
    if (navigator.onLine) {
      lang = localStorage.getItem("lang");
      billPdfNewId = localStorage.getItem("billPdfNewId");

      let billPdfNewData = {
        outletBillHeaderIds: billPdfNewId.split(","),
        languageId: lang,
      };

      showLoador({ loador: true });
      apiPost({
        url: endpoint.outletBillHeader + "/report-by-ids",
        postBody: billPdfNewData,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
        } else {
          console.log(data.data);

          setBill(data.data);
          setTimeout(function () {
            window.print();
          }, 1200);
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }, []);

  return (
    <>
      {/* <footer className='header'>
                <p>Tax Invoice</p>

            </footer>

            <header className='header2'>
                <p>Author: Hege Refsnes</p>
            </header> */}
      {billList.map((dataToSet, index) => {
        let toBill = dataToSet.outLet ? dataToSet.outLet : {};
        let taluka =
          toBill.beat != null &&
          toBill.beat.area != null &&
          toBill.beat.area.taluka != null
            ? toBill.beat.area.taluka
            : "";
        let talukaName = taluka != null ? taluka.name : "";
        let districtName =
          taluka != null && taluka.district != null ? taluka.district.name : "";
        let addr = toBill.address
          ? toBill.address + " - " + talukaName + " - " + districtName
          : "";
        let address = langugae[2].address ? langugae[2].address + " : " : "";
        var DAddrText = address + addr;
        let insertDateTime = dataToSet.insertDateTime
          ? dataToSet.insertDateTime.split(" ")
          : [];
        let insertDateTimeData =
          insertDateTime.length == 2 ? insertDateTime[1] : "";
        let toBillState = toBill.state != null ? toBill.state : {};
        let indexInfo = 0;
        let totalAmt = 0;
        let totalTaxableAmt = 0;
        let qtyTot = 0;
        let freeQtyTot = 0;
        let dataToSetState = dataToSet.state != null ? dataToSet.state : {};
        let stockStakeHolderBillDetails =
          dataToSet.outletBillDetails != null
            ? dataToSet.outletBillDetails
            : [];
        return (
          <>
            <div>
              <table style={{ width: "100%" }}>
                <tr>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td colSpan={11}>
                        <table
                          style={{ borderCollapse: "collapse", width: "100%" }}
                        >
                          <tr>
                            <td
                              align={"left"}
                              style={{
                                padding: "5px",
                                // paddingBottom: "5px",
                                // borderTop: "1px solid black",
                                // borderBottom: "1px solid black",
                                // borderRight: "1px solid black",
                                borderCollapse: "collapse",
                              }}
                            >
                              <img
                                style={{
                                  width: "100%",
                                  height: "160px",

                                  // borderCollapse: "collapse",
                                }}
                                src={
                                  imageUrl + localStorage.getItem("complogo")
                                }
                                alt="Logo"
                              />
                            </td>
                            <td
                              align={"center"}
                              style={{ paddingBottom: "3px" }}
                              // colSpan={1}
                            >
                              <div style={{ fontWeight: 600, fontSize: 45 }}>
                                {dataToSet.stockStakeHolderFirmName
                                  ? dataToSet.stockStakeHolderFirmName
                                  : "-"}
                              </div>

                              <div
                                style={{
                                  textAlign: "center",
                                  fontWeight: 500,
                                  fontSize: "18px",
                                }}
                              >
                                {dataToSet.stockStakeHolderAddress
                                  ? dataToSet.stockStakeHolderAddress
                                  : "-"}
                              </div>
                            </td>
                            {/* <td
                        align={"left"}
                        style={{
                          padding: "5px",
                          // borderTop: "1px solid black",
                          // borderBottom: "1px solid black",
                          // borderRight: "1px solid black",
                          height:"90px",
                          borderCollapse: "collapse",
                          width:"12%"
                        }}
                        // colspan={1}
                      >
                        

 
 <QRCode value={`upi://pay?pa=${encodeURIComponent(localStorage.getItem('upi'))}`} />

                      </td> */}
                            <td
                              align={"center"}
                              size={"20px"}
                              width={"15%"}
                              //  rowSpan={3}
                              //  className="tdsmallcell"
                            >
                              <QRCode
                                value={`upi://pay?pa=${encodeURIComponent(
                                  localStorage.getItem("upi")
                                )}`}
                              />
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={12}>
                        <table
                          style={{ borderCollapse: "collapse", width: "100%" }}
                        >
                          <tr>
                            <td
                              align={"right"}
                              style={{
                                paddingLeft: "10px",
                                paddingBottom: "2px",
                              }}
                              // colSpan={1}
                            >
                              <div
                                style={{
                                  textAlign: "right",
                                  flexDirection: "row",
                                  fontWeight: 600,
                                  fontSize: "16px",
                                }}
                              >
                                {"City : "}
                                {"-"}
                              </div>
                            </td>

                            <td
                              align={"right"}
                              style={{ paddingBottom: "2px" }}
                              // colSpan={1}
                            >
                              <div
                                style={{
                                  textAlign: "right",
                                  flexDirection: "row",
                                  fontWeight: 600,
                                  fontSize: "16px",
                                }}
                              >
                                {"Post Code : "}
                                {"-"}
                              </div>
                            </td>
                            <td
                              align={"left"}
                              style={{ paddingBottom: "2px" }}
                              // colSpan={1}
                            >
                              <div
                                style={{
                                  textAlign: "center",
                                  flexDirection: "row",
                                  fontWeight: 600,
                                  fontSize: "16px",
                                }}
                              >
                                {"Phone : "}
                                {dataToSet.stockStakeHolderContactPersonNo
                                  ? dataToSet.stockStakeHolderContactPersonNo
                                  : "-"}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                          width: "100%",
                          borderLeft: "1px solid black",
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                          borderRight: "1px solid black",
                          borderCollapse: "collapse",
                          // padding: "2px",
                        }}
                        colSpan={11}
                      >
                        <table
                          style={{ borderCollapse: "collapse", width: "100%" }}
                        >
                          <tr>
                            <td
                              align={"center"}
                              style={{
                                textAlign: "left",
                                width: "33%",
                                // borderLeft: "1px solid black",
                                // borderTop: "1px solid black",
                                // borderBottom: "1px solid black",
                                // borderRight: "1px solid black",
                                borderCollapse: "collapse",
                                // paddingLeft: "6px",
                                // paddingBottom: "8px",
                              }}
                              colSpan={1}
                            >
                              <div
                                style={{
                                  flexDirection: "row",
                                  fontWeight: 600,
                                  fontSize: "18px",
                                }}
                              >
                                {"GST REG :"}
                                {dataToSet.stockStakeHolderGstNo
                                  ? dataToSet.stockStakeHolderGstNo
                                  : "-"}
                              </div>
                            </td>

                            <td
                              align={"center"}
                              style={{
                                textAlign: "left",
                                width: "33%",
                                // borderLeft: "1px solid black",
                                // borderTop: "1px solid black",
                                // borderBottom: "1px solid black",
                                // borderRight: "1px solid black",
                                borderCollapse: "collapse",
                                // padding: "8px",
                                // paddingBottom: "8px",
                              }}
                              colSpan={1}
                            >
                              <div
                                style={{ fontWeight: 500, fontSize: "18px" }}
                              >
                                {"PAN :"}
                                {"-"}
                              </div>
                            </td>
                            <td
                              align={"center"}
                              style={{
                                textAlign: "left",
                                width: "33%",
                                // borderLeft: "1px solid black",
                                // borderTop: "1px solid black",
                                // borderBottom: "1px solid black",
                                // borderRight: "1px solid black",
                                borderCollapse: "collapse",
                                // padding: "8px",
                              }}
                              colSpan={1}
                            >
                              <div
                                style={{ fontWeight: 600, fontSize: "20px" }}
                              >
                                {"FSSAI NO :"}
                                {dataToSet.stockStakeHolderFssiFdiNo
                                  ? dataToSet.stockStakeHolderFssiFdiNo
                                  : "-"}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>

                    <tr>
                      <td
                        align={"center"}
                        style={{
                          textAlign: "center",
                          width: "100%",
                          borderLeft: "1px solid black",
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                          borderRight: "1px solid black",
                          borderCollapse: "collapse",
                          padding: "4px",
                          // paddingBottom: "8px",
                        }}
                        colSpan={3}
                      >
                        <div style={{ fontWeight: 600, fontSize: "22px" }}>
                          {dataToSet.stockStakeHolderGstNo == toBill.gSTNo
                            ? "STOCK TRANSFER"
                            : "TAX INVOICE"}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                          width: "50%",
                          borderLeft: "1px solid black",
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                          borderRight: "1px solid black",
                          borderCollapse: "collapse",
                          paddingLeft: "5px",
                          // paddingBottom: "8px",
                        }}
                        // colspan={1}
                      >
                        <div
                          style={{
                            fontSize: 18,
                            paddingTop: 0,
                            paddingBottom: "3px",
                          }}
                        >
                          {toBill.firmName ? toBill.firmName : ""}
                        </div>
                        {/* {langugae[2].ownerName
                          ? langugae[2].ownerName + " : "
                          : ""}{" "}
                        {dataToSet.stockStakeHolderOwnerName
                          ? dataToSet.stockStakeHolderOwnerName
                          : "-"}{" "} */}
                        {addr}

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            fontWeight: 500,
                            paddingBottom: "3px",
                          }}
                        >
                          <div
                            style={{
                              justifyContent: "flex-start",
                              width: "50%",
                              marginRight: "10px",
                            }}
                          >
                            {toBillState.name ? " " + toBillState.name : ""}{" "}
                            {/* {toBill.billNo != null ? toBill.billNo : "-"} */}
                          </div>
                          <div
                            style={{
                              justifyContent: "flex-start",
                              width: "50%",
                              // paddingBottom: "8px",
                            }}
                          >
                            {toBill.mobileNo ? toBill.mobileNo : "-"}
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            fontWeight: 500,
                            paddingBottom: "3px",
                          }}
                        >
                          <div
                            style={{
                              justifyContent: "flex-start",
                              width: "50%",
                              marginRight: "10px",
                            }}
                          >
                            {" State Code :"}
                            {toBillState.code ? " " + toBillState.code : "-"}
                          </div>
                          <div
                            style={{
                              justifyContent: "flex-start",
                              width: "50%",
                            }}
                          >
                            {" PAN :"}
                            {toBill.panNo ? toBill.panNo : "-"}
                          </div>
                        </div>

                        <div style={{ fontSize: "16px", paddingBottom: "3px" }}>
                          <div style={{ display: "inline", fontWeight: 600 }}>
                            {"GSTIN / UIN: "}
                          </div>{" "}
                          {toBill.gstNo ? toBill.gstNo : "-"}{" "}
                        </div>
                        {/* {langugae[2].FSSAINo ? langugae[2].FSSAINo + " : " : ""}{" "}
                        {dataToSet.stockStakeHolderFssiFdiNo
                          ? dataToSet.stockStakeHolderFssiFdiNo
                          : "-"}
                        <br />
                        Mode of Transport : By Road , Vehicle No.:{" "}
                        {dataToSet.vehicleNo
                          ? dataToSet.vehicleNo
                          : "-"}{" "}
                        <br />
                        E-Invoice ACK No: <br /> */}
                      </td>
                      <td
                        style={{
                          textAlign: "left",
                          // width: "60%",
                          borderLeft: "1px solid black",
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                          // borderRight: "1px solid black",
                          borderCollapse: "collapse",
                          paddingLeft: "5px",
                        }}
                        // colspan={1}
                      >
                        <div
                          style={{
                            fontWeight: 600,
                            fontSize: 16,
                            paddingTop: 0,
                            paddingBottom: "3px",
                          }}
                        >
                          {"Ship To :"}
                        </div>
                        {toBill.firmName ? toBill.firmName : ""} <br />
                        {addr}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            fontWeight: 600,
                            paddingBottom: "3px",
                          }}
                        >
                          <div
                            style={{
                              display: "inline",
                              justifyContent: "flex-start",
                              width: "50%",
                            }}
                          >
                            {"State : "}
                            {toBillState.shortName
                              ? " " + toBillState.shortName
                              : ""}{" "}
                            {/* {toBill.billNo != null ? toBill.billNo : "-"} */}
                          </div>
                          <div
                            style={{
                              justifyContent: "flex-start",
                              width: "50%",
                            }}
                          >
                            {"City : "}
                            {toBill.villageCity
                              ? " " + toBill.villageCity
                              : ""}{" "}
                          </div>
                        </div>
                        {/* State :  {toBillState.name ? " " + toBillState.name : ""} &nbsp;&nbsp;&nbsp;City:{toBillState.name ? " " + toBillState.name : ""} */}
                        <div
                          style={{
                            display: "inline",
                            fontSize: "16px",
                            paddingBottom: "3px",
                          }}
                        >
                          <div style={{ display: "inline", fontWeight: 600 }}>
                            {"GSTIN /UIN :"}
                          </div>{" "}
                          {toBill.gstNo != null ? toBill.gstNo : "-"}
                        </div>
                        {/* GSTIN -  {toBill.gstNo != null ? toBill.gstNo : "-"} */}
                      </td>
                      <td
                        align={"left"}
                        style={{
                          padding: "7px",
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                          borderRight: "1px solid black",
                          borderCollapse: "collapse",
                          width: "18%",
                        }}
                        // colspan={1}
                      >
                        <QRCode
                          value={`upi://pay?pa=${encodeURIComponent(
                            localStorage.getItem("upi")
                          )}`}
                        />
                      </td>
                    </tr>
                  </table>
                </tr>

                <tr>
                  <table style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td
                          style={{
                            borderLeft: "1px solid black",
                            textAlign: "left",
                            width: "50%",
                            borderBottom: "1px solid black",
                            borderRight: "1px solid black",
                            borderCollapse: "collapse",
                            paddingLeft: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: 600,
                              paddingBottom: "3px",
                            }}
                          >
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                              }}
                            >
                              {"Invoice No : "}

                              {dataToSet.billNo != null
                                ? dataToSet.billNo
                                : "-"}
                            </div>
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                              }}
                            >
                              {"Date Of Supply : "}
                              {/* {toBill.toDate != null ? toBill.toDate : "-"} */}
                              {"-"}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: 600,
                              paddingBottom: "3px",
                            }}
                          >
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                              }}
                            >
                              {"Invoice Date : "}
                              {dataToSet.billDate ? dataToSet.billDate : ""}
                              {/* {toBill.billNo != null ? toBill.billNo : "-"} */}
                            </div>
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                              }}
                            >
                              {"place Of Supply : "}
                              {toBillState.name ? toBillState.name : ""}
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "100%",
                              fontWeight: 600,
                              paddingBottom: "8px",
                            }}
                          >
                            {"IRN No : "}
                            {"-"}
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: 600,
                              paddingBottom: "3px",
                            }}
                          >
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                                marginRight: "10px",
                              }}
                            >
                              {"Ack No : "}
                              {"-"}
                              {/* {toBill.billNo != null ? toBill.billNo : "-"} */}
                            </div>
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                              }}
                            >
                              {"Ack Date : "}
                              {"-"}
                            </div>
                          </div>
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid black",
                            textAlign: "left",
                            width: "50%",
                            borderBottom: "1px solid black",
                            borderRight: "1px solid black",
                            borderCollapse: "collapse",
                            paddingLeft: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: 600,
                              paddingBottom: "3px",
                            }}
                          >
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                                marginRight: "10px",
                              }}
                            >
                              {"Po No : "}
                              {"-"}
                              {/* {toBill.billNo != null ? toBill.billNo : "-"} */}
                            </div>
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                              }}
                            >
                              {"Po Date : "}
                              {/* {toBill.toDate != null ? toBill.toDate : "-"} */}
                              {"-"}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: 600,
                              paddingBottom: "3px",
                            }}
                          >
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                                marginRight: "10px",
                              }}
                            >
                              {"Transporter : "}
                              {"-"}
                              {/* {toBill.billNo != null ? toBill.billNo : "-"} */}
                            </div>
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                              }}
                            >
                              {"LR/RR/GR No : "}
                              {/* {toBill.toDate != null ? toBill.toDate : "-"} */}
                              {"-"}
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: 600,
                              paddingBottom: "3px",
                            }}
                          >
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                                marginRight: "10px",
                              }}
                            >
                              {"Vehicle No : "}
                              {dataToSet.vehicleNo != null
                                ? dataToSet.vehicleNo
                                : "-"}
                              {/* {toBill.billNo != null ? toBill.billNo : "-"} */}
                            </div>
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                              }}
                            >
                              {"LR/RR/GR Date : "}
                              {/* {toBill.toDate != null ? toBill.toDate : "-"} */}
                              {"-"}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: 600,
                              paddingBottom: "3px",
                            }}
                          >
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                                marginRight: "10px",
                              }}
                            >
                              {"E-Way Bill No : "}
                              {"-"}
                              {/* {toBill.billNo != null ? toBill.billNo : "-"} */}
                            </div>
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                              }}
                            >
                              {"E-Way Bill Date : "}
                              {/* {toBill.toDate != null ? toBill.toDate : "-"} */}
                              {"-"}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </table>
                </tr>
              </table>

              <div style={{ borderCollapse: "collapse", marginTop: "8px" }}>
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <thead>
                    <tr>
                      <th className="thsmallcell">Sr No</th>
                      <th className="thbigcell" align={"center"}>
                        {" Description "}
                      </th>
                      <th className="thmedcell">{"HSN SAC"}</th>
                      <th className="thbigcell">
                        {langugae[2].qty ? langugae[2].qty : ""}
                      </th>
                      <th className="thbigcell">
                        {langugae[2].uom ? langugae[2].uom : ""}
                      </th>
                      <th className="thbigcell">{"Batch"}</th>

                      <th className="thmedcell">
                        {langugae[2].rate ? langugae[2].rate : ""}
                      </th>
                      <th className="thmedcell">{"Total Value"}</th>
                      <th className="thsmallcell">{"Disc(%)"}</th>
                      <th className="thsmallcell">
                        {"Add"}
                        <br />
                        {"Disc(%)"}
                      </th>
                      <th className="thsmallcell">{"Disc Value"}</th>
                      <th className="thmedcell">{"Taxable Net Value"}</th>
                      {toBillState.id == dataToSetState.id ? (
                        <>
                          <th colSpan={2} className="thmedcell">
                            {langugae[2].CGST ? langugae[2].CGST : ""}
                          </th>
                          <th colSpan={2} className="thmedcell">
                            {langugae[2].SGST ? langugae[2].SGST : ""}
                          </th>
                        </>
                      ) : (
                        <>
                          <th colSpan={4} className="thmedcell">
                            {langugae[2].IGST ? langugae[2].IGST : ""}
                          </th>
                        </>
                      )}
                      <th className="thmedcell">
                        {langugae[2].amount ? langugae[2].amount : ""}
                      </th>
                    </tr>
                    <tr>
                      <th className="thsmallcell"></th>
                      <th className="thmedcell"></th>
                      <th className="thsmallcell"></th>
                      <th className="thmedcell"></th>
                      <th className="thbigcell"></th>
                      <th className="thbigcell"></th>
                      <th className="thbigcell"></th>
                      <th className="thmedcell"></th>
                      <th className="thmedcell"></th>
                      <th className="thmedcell"></th>
                      <th className="thmedcell"></th>
                      <th className="thmedcell"></th>
                      {toBillState.id == dataToSetState.id ? (
                        <>
                          <th className="thmedcell">
                            {langugae[2].rate ? langugae[2].rate : ""}
                          </th>

                          <th className="thmedcell">{"Amt"}</th>
                          <th className="thmedcell">
                            {langugae[2].rate ? langugae[2].rate : ""}
                          </th>

                          <th className="thmedcell">{"Amt"}</th>
                        </>
                      ) : (
                        <>
                          <th className="thmedcell" colSpan={2}>
                            {langugae[2].rate ? langugae[2].rate : ""}
                          </th>

                          <th className="thmedcell" colSpan={2}>
                            {"Amt"}
                          </th>
                        </>
                      )}

                      <th className="thmedcell"></th>
                    </tr>
                  </thead>

                  <tbody style={{ width: "100%" }}>
                    {dataToSet.packagingTypes.map((packagingType) => {
                      return (
                        <>
                          {stockStakeHolderBillDetails.map(
                            (stockDetails, indexData) => {
                              let packagingTypeDetails =
                                stockDetails.product != null &&
                                stockDetails.product.packagingType != null
                                  ? stockDetails.product.packagingType
                                  : {};

                              if (packagingType.id == packagingTypeDetails.id) {
                                let productData = [];
                                let product =
                                  stockDetails.product != null
                                    ? stockDetails.product
                                    : {};
                                qtyTot = qtyTot + stockDetails.qty;
                                freeQtyTot = freeQtyTot + stockDetails.freeQty;
                                indexInfo = indexInfo + 1;
                                totalAmt = totalAmt + stockDetails.totalAmt;
                                totalTaxableAmt =
                                  totalTaxableAmt + stockDetails.taxableAmt;
                                return (
                                  <>
                                    <tr>
                                      <td className="tdsmallcell">
                                        {indexInfo}
                                      </td>

                                      <td
                                        align={"left"}
                                        // colSpan={2}
                                        className="tdsmallcell"
                                      >
                                        {product.name}
                                      </td>
                                      <td
                                        align={"center"}
                                        className="tdsmallcell"
                                      >
                                        {stockDetails.hsnCode}
                                      </td>
                                      <td
                                        align={"right"}
                                        className="tdsmallcell"
                                      >
                                        {stockDetails.qty}
                                      </td>
                                      <td
                                        align={"left"}
                                        className="tdsmallcell"
                                      >
                                        {stockDetails.uom != null
                                          ? stockDetails.uom.name
                                          : "-"}
                                      </td>
                                      <td
                                        align={"center"}
                                        className="tdsmallcell"
                                      >
                                        {stockDetails.batchCode != null
                                          ? stockDetails.batchCode
                                          : "-"}
                                      </td>
                                      <td
                                        align={"right"}
                                        className="tdsmallcell"
                                      >
                                        {(
                                          +stockDetails.taxableAmt /
                                          +stockDetails.qty
                                        ).toFixed(2)}
                                      </td>

                                      <td
                                        align={"right"}
                                        className="tdsmallcell"
                                      >
                                        {stockDetails.totalAmt.toFixed(2)}
                                      </td>

                                      <td
                                        align={"right"}
                                        className="tdsmallcell"
                                      >
                                        {stockDetails.itemDiscPer.toFixed(2)}
                                      </td>

                                      <td
                                        align={"right"}
                                        className="tdsmallcell"
                                      >
                                        {"0"}
                                      </td>

                                      <td
                                        align={"right"}
                                        className="tdsmallcell"
                                      >
                                        {stockDetails.itemDiscAmt.toFixed(2)}
                                      </td>
                                      <td
                                        align={"right"}
                                        className="tdsmallcell"
                                      >
                                        {stockDetails.taxableAmt.toFixed(2)}
                                      </td>

                                      {toBillState.id == dataToSetState.id ? (
                                        <>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                          >
                                            {stockDetails.cgstPer.toFixed(2)}
                                          </td>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                          >
                                            {stockDetails.cgstAmt.toFixed(2)}
                                          </td>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                          >
                                            {stockDetails.sgstPer.toFixed(2)}
                                          </td>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                          >
                                            {stockDetails.sgstAmt.toFixed(2)}
                                          </td>
                                        </>
                                      ) : (
                                        <>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                            colSpan={2}
                                          >
                                            {stockDetails.igstPer.toFixed(2)}
                                          </td>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                            colSpan={2}
                                          >
                                            {stockDetails.igstAmt.toFixed(2)}
                                          </td>
                                        </>
                                      )}
                                      <td
                                        align={"right"}
                                        className="tdsmallcell"
                                      >
                                        {stockDetails.taxableAmt.toFixed(2)}
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                            }
                          )}
                        </>
                      );
                    })}

                    <tr>
                      {/* <td className="tdsmallcell"></td> */}
                      <td
                        align={"right"}
                        className="tdsmallcell"
                        style={{ fontWeight: 600 }}
                        colspan={2}
                      >
                        {"Total QTY"}
                      </td>
                      <td className="tdsmallcell"></td>

                      <td
                        align={"center"}
                        style={{ fontWeight: 500 }}
                        className="tdsmallcell"
                        colspan={1}
                      >
                        {qtyTot}
                      </td>
                      <td className="tdsmallcell"></td>

                      {toBillState.id == dataToSetState.id ? (
                        <>
                          <td className="tdsmallcell"></td>
                          <td className="tdsmallcell"></td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 500 }}
                            className="tdsmallcell"
                            colspan={1}
                          >
                            {totalAmt.toFixed(2)}
                          </td>
                          <td className="tdsmallcell"></td>
                          <td className="tdsmallcell"></td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 500 }}
                            className="tdsmallcell"
                            colspan={1}
                          >
                            {""}
                          </td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 500 }}
                            className="tdsmallcell"
                            colspan={1}
                          >
                            {totalTaxableAmt.toFixed(2)}
                          </td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 500 }}
                            className="tdsmallcell"
                            colspan={1}
                          >
                            {""}
                          </td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 500 }}
                            className="tdsmallcell"
                            colspan={1}
                          >
                            {""}
                          </td>
                          <td className="tdsmallcell"></td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 500 }}
                            className="tdsmallcell"
                            colspan={1}
                          >
                            {""}
                          </td>
                        </>
                      ) : (
                        <>
                          <td className="tdsmallcell"></td>
                          <td className="tdsmallcell"></td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 500 }}
                            className="tdsmallcell"
                            colspan={1}
                          >
                            {totalAmt.toFixed(2)}
                          </td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 500 }}
                            className="tdsmallcell"
                            colspan={1}
                          >
                            {""}
                          </td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 500 }}
                            className="tdsmallcell"
                            colspan={1}
                          >
                            {""}
                          </td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 500 }}
                            className="tdsmallcell"
                            colspan={1}
                          >
                            {""}
                          </td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 500 }}
                            className="tdsmallcell"
                            colspan={1}
                          >
                            {totalTaxableAmt.toFixed(2)}
                          </td>

                          <td className="tdsmallcell" colspan={2}></td>

                          <td
                            align={"right"}
                            style={{ fontWeight: 500 }}
                            className="tdsmallcell"
                            colspan={2}
                          >
                            {""}
                          </td>
                        </>
                      )}
                      <td
                        align={"right"}
                        style={{ fontWeight: 600 }}
                        className="tdsmallcell"
                      >
                        {totalTaxableAmt.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td
                        align={"left"}
                        className="tdsmallcell"
                        style={{ fontWeight: 600 }}
                        colspan={11}
                      >
                        {"Invoice Total(In Words):"}
                      </td>

                      {toBillState.id == dataToSetState.id ? (
                        <>
                          <td
                            align={"left"}
                            style={{ fontWeight: 500 }}
                            className="tdsmallcell"
                            colspan={5}
                          >
                            {"Income Tax TCS"}
                          </td>
                        </>
                      ) : (
                        <>
                          <td
                            align={"left"}
                            style={{ fontWeight: 500 }}
                            className="tdsmallcell"
                            colspan={5}
                          >
                            {"Income Tax TCS"}
                          </td>
                        </>
                      )}
                      <td
                        align={"right"}
                        style={{ fontWeight: 500 }}
                        className="tdsmallcell"
                      >
                        {"0.00"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        align={"left"}
                        className="tdsmallcell"
                        style={{ fontWeight: 600 }}
                        colspan={11}
                      >
                        {totalTaxableAmt
                          ? NumberToWords(totalTaxableAmt.toFixed(2))
                          : "-"}
                      </td>

                      {toBillState.id == dataToSetState.id ? (
                        <>
                          <td
                            align={"left"}
                            style={{ fontWeight: 600 }}
                            className="tdsmallcell"
                            colspan={5}
                          >
                            {"Invoice Total"}
                          </td>
                        </>
                      ) : (
                        <>
                          <td
                            align={"left"}
                            style={{ fontWeight: 600 }}
                            className="tdsmallcell"
                            colspan={5}
                          >
                            {"Invoice Total"}
                          </td>
                        </>
                      )}
                      <td
                        align={"right"}
                        style={{ fontWeight: 600 }}
                        className="tdsmallcell"
                      >
                        {totalTaxableAmt.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td
                        align={"left"}
                        className="tdsmallcell"
                        style={{
                          fontWeight: 500,
                          paddingTop: 0,
                          paddingBottom: "0Px",
                        }}
                        colspan={12}
                        rowSpan={1}
                      >
                        <div style={{ display: "inline" }}>
                          <div style={{ display: "inline" }}>
                            {"Bank Name : "}
                          </div>
                          {"- "}
                          &nbsp;&nbsp;&nbsp;,
                          <div style={{ display: "inline" }}>
                            {"Bank Acc No : "}
                          </div>
                          {"- "}
                          &nbsp;&nbsp;&nbsp;,
                          <div style={{ display: "inline" }}>
                            {"IFSC Code : "}
                          </div>
                          {" -"}
                        </div>
                      </td>

                      {toBillState.id == dataToSetState.id ? (
                        <>
                          <td
                            align={"center"}
                            style={{ fontWeight: 600 }}
                            className="tdsmallcell"
                            colspan={6}
                            rowSpan={2}
                          >
                            <div
                              style={{
                                fontWeight: 600,
                                paddingBottom: "10px",
                                textAlign: "center",
                              }}
                            >
                              {dataToSet.stockStakeHolderFirmName
                                ? dataToSet.stockStakeHolderFirmName
                                : "-"}
                            </div>

                            {toBillState.id == dataToSetState.id ? (
                              <>
                                <div
                                  style={{
                                    fontWeight: 600,
                                    textAlign: "bottom",
                                    marginBottom: 0,
                                  }}
                                >
                                  {"Authorised Signature"}
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  style={{
                                    fontWeight: 600,
                                    textAlign: "bottom",
                                  }}
                                >
                                  {"Authorised Signature"}
                                </div>
                              </>
                            )}
                          </td>
                        </>
                      ) : (
                        <>
                          <>
                            <td
                              align={"center"}
                              style={{ fontWeight: 600 }}
                              className="tdsmallcell"
                              colspan={6}
                              rowSpan={2}
                            >
                              <div
                                style={{
                                  fontWeight: 600,
                                  paddingBottom: "10px",
                                  textAlign: "center",
                                }}
                              >
                                {dataToSet.stockStakeHolderFirmName
                                  ? dataToSet.stockStakeHolderFirmName
                                  : "-"}
                              </div>

                              <div
                                style={{
                                  fontWeight: 600,
                                  textAlign: "center",
                                  marginBottom: 0,
                                  marginTop: "5px",
                                }}
                              >
                                {"Authorised Signature"}
                              </div>
                            </td>
                          </>
                        </>
                      )}
                    </tr>

                    <tr>
                      <td
                        align={"left"}
                        className="tdsmallcell"
                        style={{ fontWeight: 500, borderTop: "1px solid gray" }}
                        colSpan={12}
                      >
                        {
                          "I/We hereby certify that food/foods mentioned in this invoice is/are warranted to be of the nature and quality which it/these purport to be"
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {index + 1 != billList.length && <div className="pagebreak"> </div>}
          </>
        );
      })}
    </>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showNotification,
  showLoador,
};
export default connect(mapStateToProps, mapDispatchToProps)(MyComponent);
