import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  states: [],
  stateByRegion: [],
  statesNew:[],
};
let URL = endpoints.state;
const stateSlice = createSlice({
  name: "state",
  initialState,
  reducers: {
    stateSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        states: row,
      };
    },

    stateNewSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        statesNew: row,
      };
    },

    stateByregionSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        stateByRegion: row,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { stateSuccess, stateNewSuccess,stateByregionSuccess, resetState } =
  stateSlice.actions;

export default stateSlice.reducer;

export const getStateList = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"sortOrderNo": "ASC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((stateListObject, index) => {
          let stateData = {
            index: index + 1,
            id: stateListObject.id === null ? "" : stateListObject.id,
            name: stateListObject.name === null ? "" : stateListObject.name,
            stateName:
              stateListObject.name === null ? "" : stateListObject.name,
            code: stateListObject.code === null ? "" : stateListObject.code,
            regionName:
              stateListObject.region.name === null
                ? ""
                : stateListObject.region.name,
            zoneCount:
              stateListObject.zoneCount === null
                ? ""
                : stateListObject.zoneCount,
            districtCount:
              stateListObject.districtCount === null
                ? ""
                : stateListObject.districtCount,
            talukaCount:
              stateListObject.talukaCount === null
                ? ""
                : stateListObject.talukaCount,
            areaCount:
              stateListObject.areaCount === null
                ? ""
                : stateListObject.areaCount,
            beatCount:
              stateListObject.beatCount === null
                ? ""
                : stateListObject.beatCount,
            outletCount:
              stateListObject.outletCount === null
                ? ""
                : stateListObject.outletCount,
            sortOrderNo:
              stateListObject.sortOrderNo === null
                ? ""
                : stateListObject.sortOrderNo,
            isActive:
              stateListObject.isActive === null ? "" : stateListObject.isActive,
            insertDateTime:
              stateListObject.insertDateTime === null
                ? ""
                : stateListObject.insertDateTime,
          };
          return stateData;
        });
        dispatch(stateSuccess({ row }));
        dispatch(stateNewSuccess({ row: content }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getUpdatedStateList = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '/get-all',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = data.data.length!=0 && data.data.map((stateListObject, index) => {
          let stateData = {
            index: index + 1,
            id: stateListObject.id === null ? "" : stateListObject.id,
            name: stateListObject.name === null ? "" : stateListObject.name,
            stateName:
              stateListObject.name === null ? "" : stateListObject.name,
            code: stateListObject.code === null ? "" : stateListObject.code,
            regionName:
              stateListObject.regionNAme === null
                ? ""
                : stateListObject.regionNAme,
            zoneCount:
              stateListObject.zoneCount === null
                ? ""
                : stateListObject.zoneCount,
            districtCount:
              stateListObject.districtCount === null
                ? ""
                : stateListObject.districtCount,
            talukaCount:
              stateListObject.talukaCount === null
                ? ""
                : stateListObject.talukaCount,
            areaCount:
              stateListObject.areaCount === null
                ? ""
                : stateListObject.areaCount,
            beatCount:
              stateListObject.beatCount === null
                ? ""
                : stateListObject.beatCount,
            outletCount:
              stateListObject.outletCount === null
                ? ""
                : stateListObject.outletCount,
            sortOrderNo:
              stateListObject.sortOrderNo === null
                ? ""
                : stateListObject.sortOrderNo,
            isActive:
              stateListObject.isActive === null ? "" : stateListObject.isActive,
            insertDateTime:
              stateListObject.insertDateTime === null
                ? ""
                : stateListObject.insertDateTime,
          };
          return stateData;
        });
        dispatch(stateSuccess({ row }));
        dispatch(stateNewSuccess({ row: content }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const setState =
  ({ row }) =>
  async (dispatch) => {
    dispatch(stateNewSuccess({ row }));
  };

export const getStateByRegion =
  ({ regionIds }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url: URL + "/by-regions?regionIds=" + regionIds,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((stateByRegion, index) => {
            let stateByRegionData = {
              index: index + 1,
              ...stateByRegion,
              id: stateByRegion.id === null ? "" : stateByRegion.id,
              name: stateByRegion.name === null ? "" : stateByRegion.name,
              shortName:
                stateByRegion.shortName === null ? "" : stateByRegion.shortName,
              sortOrder:
                stateByRegion.sortOrder === null ? "" : stateByRegion.sortOrder,
              regionId:
                stateByRegion.name === null ? "" : stateByRegion.region.id,
              region:
                stateByRegion.name === null ? "" : stateByRegion.region.name,
            };
            return stateByRegionData;
          });
          dispatch(stateByregionSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return { response: [],  success:false  };

    }
  };
