import dayjs from "dayjs";
import React from "react";
import { connect } from "react-redux";
import { generatedBillJSON } from "../../DynamicFormsJson/MastersJSON/generatedBill";
import {
  getGenratedBillListByDateNew,
  getGenratedBillListByDate,
  setGenratedBillList,
} from "../../Slice/orderBillList.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import endpoint from "../../config/endpoints";
import {
  cancelBillMsg,
  generateBillMsg,
  noInternetMsg,
  savemsg,
  serverMsg,
} from "../../config/messageconstant";
import { apiGet, apiPost } from "../../utils/api_service";

// import { getStockStackHolders } from "../../Slice/StackHolder.slice";
import { Paper } from "@mui/material";
import { GeneratedBillListColumns } from "../../tableColumns/table-columns";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";
import EWayPopUp from "./EWayPopUp";
class ListOfGeneratedBill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: dayjs().format("DD-MM-YYYY"),
      toDate: dayjs().format("DD-MM-YYYY"),
      popUpFlag: false,
      popUpData: {},
      statusFlag: false,
      companyFlagOfc: false,
      dynamicMasterData: {
        stakeholdersDetail: this.props.stakeholdersList.stockStakeHolders,
      },
    };
  }
  async componentDidMount() {
    const { setGenratedBillList, showLoador, showNotification } = this.props;

    setGenratedBillList({ row: [] });

    const showCompanyFlag = localStorage.getItem("showCompanyFlag");

    if (showCompanyFlag == 0) {
      this.setState({
        companyFlagOfc: true,
      });
    }
    this.getData();
  }

  getData = async () => {
    const {
      getGenratedBillListByDateNew,
      getGenratedBillListByDate,
      showLoador,
      showNotification,
    } = this.props;
    const { fromDate, toDate, companyFlagOfc } = this.state;
    if (navigator.onLine) {
      let status = "";

      const userTypeId = localStorage.getItem("userTypeId");

      if (userTypeId != 1 && userTypeId != 6) {
        if (companyFlagOfc) {
          showLoador({ loador: true });
          await getGenratedBillListByDate({
            fromDate: fromDate,
            toDate: toDate,
          }).then(({ response, success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            } else {
              if (response.length !== 0) {
                if (response.length !== 0) {
                  response.map((obj) => {
                    if (obj.status === 1) {
                      this.setState({
                        statusFlag: true,
                      });
                    }
                  });
                }
              }
            }
          });
        } else {
          showLoador({ loador: true });
          await getGenratedBillListByDateNew({
            fromDate: fromDate,
            toDate: toDate,
          }).then(({ response, success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            } else {
              if (response.length !== 0) {
                if (response.length !== 0) {
                  response.map((obj) => {
                    if (obj.status === 1) {
                      this.setState({
                        statusFlag: true,
                      });
                    }
                  });
                }
              }
            }
          });
        }
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.stakeholdersList !== nextProps.stakeholdersList) {
      if (
        this.props.stakeholdersList?.stockStakeHolders !==
        nextProps.stakeholdersList?.stockStakeHolders
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeholdersDetail: nextProps.stakeholdersList?.stockStakeHolders,
          },
        });
      }
    }
  }

  handleCloses = () => {
    this.setState({
      popUpFlag: false,
    });
  };

  onSearchList = async (data) => {
    const {
      getGenratedBillListByDateNew,
      getGenratedBillListByDate,
      showLoador,
      showNotification,
    } = this.props;
    const { companyFlagOfc } = this.state;
    let status = "";
    if (navigator.onLine) {
      if (companyFlagOfc) {
        showLoador({ loador: true });
        await getGenratedBillListByDate({
          fromDate: data.fromDate,
          toDate: data.toDate,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            if (response.length !== 0) {
              response.map((obj) => {
                if (obj.status === 1) {
                  this.setState({
                    statusFlag: true,
                  });
                }
              });
            }
          }

          this.setState({
            fromDate: data.fromDate ? data.fromDate : "",
            toDate: data.toDate ? data.toDate : "",
          });
        });
      } else {
        showLoador({ loador: true });
        await getGenratedBillListByDateNew({
          fromDate: data.fromDate,
          toDate: data.toDate,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            if (response.length !== 0) {
              response.map((obj) => {
                if (obj.status === 1) {
                  this.setState({
                    statusFlag: true,
                  });
                }
              });
            }
          }

          this.setState({
            fromDate: data.fromDate ? data.fromDate : "",
            toDate: data.toDate ? data.toDate : "",
          });
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  rowPDF = (rowData) => {
    const { lang } = this.state;
    const { showLoador, showNotification } = this.props;
    localStorage.setItem("lang", lang);
    const showPdfType = localStorage.getItem("showPdf");

    if (showPdfType == 0) {
      localStorage.setItem("myBillPdfID", rowData.id);
      window.open("./my-bill-pdf", "_blank");
    }
    if (showPdfType == 1) {
      localStorage.setItem("myNewBillPdfID", rowData.id);
      window.open("./new-my-bill-pdf", "_blank");
    }
    if (showPdfType == 2) {
      //   if (navigator.onLine) {

      //     showLoador({ loador: true });
      //     apiGet({
      //         url:
      //             endpoint.stockStakeholderBillHeader +
      //             "/report-by-ids?ids=" +
      //             rowData.id +
      //             "&languageId=" + lang,
      //     }).then(({ data, success }) => {
      //         showLoador({ loador: false });
      //         if (!success) {
      //             showNotification({
      //                 msg: serverMsg,
      //                 severity: "error",
      //             });
      //         } else {
      //             console.log(data.data);
      //              if(data.data.length!=0){
      //               this.generatePDFHeader(data.data)

      //              }

      //         }
      //     });
      // } else {
      //     showNotification({ msg: noInternetMsg, severity: "error" });
      // }
      localStorage.setItem("myBillPdfID", rowData.id);
      window.open("./my-bill-pdf", "_blank");
    }
  };

  generateExcelStatic = (rowData) => {
    const { fromDate, toDate } = this.state;
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      downloadF({
        url:
          endpoint.stockStakeholderBillHeader +
          "/generated-bills-excel-by-date?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
        ext: "xlsx",
        openNewTab: true,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onClickApply = (rowData) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      swal({
        title: "Are you sure?",
        text: generateBillMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          showLoador({ loador: true });
          apiPost({
            url: endpoint.eInvoice,
            postBody: rowData,
          }).then(({ data, success }) => {
            console.log(data);
            showLoador({ loador: false });
            if (!success) {
              this.props.showNotification({
                msg: serverMsg,
                severity: "error",
              });
            } else {
              if (data.data.message) {
                this.props.showNotification({ msg: data.data.message });
              } else {
                this.props.showNotification({ msg: savemsg });
              }
            }
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onClickEWay = (rowData) => {
    const { showLoador, showNotification } = this.props;
    this.setState({
      popUpFlag: true,
      popUpData: rowData,
    });
  };

  onClickCancel = (rowData) => {
    const { showLoador, showNotification } = this.props;
    swal({
      title: "Are you sure?",
      text: cancelBillMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        showLoador({ loador: true });
        apiGet({
          url:
            endpoint.stockStakeholderBillHeader +
            "/update-status?status=1&id=" +
            rowData.id,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            this.props.showNotification({
              msg: serverMsg,
              severity: "error",
            });
          } else {
            this.props.showNotification({ msg: savemsg });
          }
        });
      }
    });
  };

  render() {
    const { generatedBillList, setGenratedBillList } = this.props;
    const {
      dynamicMasterData,
      popUpFlag,
      popUpData,
      companyFlagOfc,
      statusFlag,
    } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <DynamicMainScreen
            showViewButtonInTable={true}
            showDeleteIcon={false}
            dynamicMasterData={dynamicMasterData}
            searchList={generatedBillJSON.searchList}
            onSearchData={this.onSearchList}
            formPathView={generatedBillJSON.formPathView}
            formPath={generatedBillJSON.formPath}
            screenTitle={generatedBillJSON.screenTitle}
            fieldMeta={generatedBillJSON.fieldMeta}
            showPdfDownload={false}
            staticExcel={true}
            generateExcelStatic={this.generateExcelStatic}
            isActiveURL={generatedBillJSON.isActiveURL}
            showAddButton={false}
            tableColumnsToFilter={generatedBillJSON.tableColumnsToFilter}
            tableColumns={GeneratedBillListColumns}
            tableData={generatedBillList.genratedBillNew}
            getTableData={this.getData}
            apiBaseURL={generatedBillJSON.apiBaseURL}
            callApi={false}
            getTableDataById={true}
            showSearchBox={true}
            rowPDF={this.rowPDF}
            showPdf={true}
            deleteURL={generatedBillJSON.deleteURL}
            deleteURLStatic={true}
            showPegination={false}
            // showSearch={false}
            showApply={companyFlagOfc ? true : false}
            credentialConditionName="showGeneratedInvoice"
            credentialConditionValue="0"
            showApplyTitle={"Generate Invoice"}
            rowApply={this.onClickApply}
            showEWay={companyFlagOfc ? true : false}
            showEWayTitle={"E-Way Bill"}
            rowEWay={this.onClickEWay}
            showCancel={companyFlagOfc ? true : false}
            cancelBillConditionName="isRecievedName"
            cancelBillConditionValue="No"
            rowCancel={this.onClickCancel}
            editConditionName="isRecieved"
            editConditionValue="0"
            showEditIcon={false}
          />
          {popUpFlag && (
            <>
              <EWayPopUp
                open={popUpFlag}
                handleCloses={this.handleCloses}
                popUpData={popUpData}
                onSave={this.saveHandler}
              />
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stakeholdersList: state.stackholder,
  generatedBillList: state.billHeaderList,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getGenratedBillListByDateNew,
  getGenratedBillListByDate,
  setGenratedBillList,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListOfGeneratedBill);
