import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { OrderConfigurationColumns } from "../../../tableColumns/table-columns";
import { orderConfigurationJson } from "../../../DynamicFormsJson/MastersJSON/orderConfiguration";
import { getOrderConfiguration } from "../../../Slice/orderConfiguration.slice";

class OrderConfigurationTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { orderConfigurationList, getOrderConfiguration } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={orderConfigurationJson.formPath}
          screenTitle={orderConfigurationJson.screenTitle}
          fieldMeta={orderConfigurationJson.fieldMeta}
          showPdfDownload={orderConfigurationJson.showPdfDownload}
          showExcelDownload={orderConfigurationJson.showExcelDownload}
          pdfFileName={orderConfigurationJson.pdfFileName}
          excelFileName={orderConfigurationJson.excelFileName}
          showAddButton={orderConfigurationJson.showAddButton}
          tableColumnsToFilter={orderConfigurationJson.tableColumnsToFilter}
          tableColumns={OrderConfigurationColumns}
          tableData={orderConfigurationList?.orderConfiguration}
          getTableData={getOrderConfiguration}
          apiBaseURL={orderConfigurationJson.apiBaseURL}
          baseIdColumn={orderConfigurationJson.baseIdColumn}
          showDeleteIcon={orderConfigurationJson.showDeleteIcon}
          isNote={true}
          isNoteValue={"Assign Configuration For The Stock Holders"}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  orderConfigurationList: state.orderConfiguration,
});
const mapDispatchToProps = {
  getOrderConfiguration,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderConfigurationTable);
