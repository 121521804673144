import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { OrderClosingJson } from "../../DynamicFormsJson/Transaction/orderClosing";
import { getBeatByUserType } from "../../Slice/beat.slice";
import { getOutletByBeatIdsPost } from "../../Slice/outlet.slice";
import {
  getOutletLostOrderDetailsNew,
  getOutletLostOrderDetailsPostNew,
} from "../../Slice/outletOrderDetail.slice";
import { getAllActiveProduct } from "../../Slice/product.slice";
import { getSalesExecutiveByBeatIds } from "../../Slice/salesExecutive.slice";
import { orderClosingColumns } from "../../tableColumns/table-columns";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import dayjs from "dayjs";

class OrderClosingList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      dynamicMasterData: {},
      searchBy: "0",
      flag: "",
    };
  }

  async componentDidMount() {
    await this.getData();
  }
  getData = async () => {
    const {
      getOutletLostOrderDetailsNew,
      getAllActiveProduct,
      getBeatByUserType,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getOutletLostOrderDetailsNew({
        fromdate: dayjs().format("DD-MM-YYYY"),
        todate: dayjs().format("DD-MM-YYYY"),
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getBeatByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      getAllActiveProduct().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  componentWillReceiveProps(nextProps) {
    if (this.props.beatList !== nextProps.beatList) {
      if (
        this.props.beatList?.beatByUserType !==
        nextProps.beatList?.beatByUserType
      ) {
        let formData = {
          beat:
            nextProps.beatList?.beatByUserType.length != 0
              ? [nextProps.beatList?.beatByUserType[0].id]
              : [],
        };

        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            beatDetail: nextProps.beatList?.beatByUserType,
          },
        });

        // if (this.state.flag == 1) {
        //   this.setState({
        //     formData: formData,
        //   });
        //   if (formData.beat.length != 0) {
        //     this.getListById(formData);
        //   }
        // }
      }
    }

    if (this.props.outletList !== nextProps.outletList) {
      if (this.props.outletList?.outlets !== nextProps.outletList?.outlets) {
        let formData = {
          outletIds:
            nextProps.outletList?.outlets.length != 0
              ? [nextProps.outletList?.outlets[0].id]
              : [],
        };

        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            outletDetail: nextProps.outletList?.outlets,
          },
        });
        // if (this.state.flag == 1) {
        //   this.setState({
        //     flag: 2,
        //     formData: formData,
        //   });
        //   if (formData.outletIds.length != 0) {
        //     this.onSearchList({
        //       ...formData,
        //     });
        //   }
        // }
      }
    }
    if (this.props.salesExecutive !== nextProps.salesExecutive) {
      if (
        this.props.salesExecutive?.salesExecutive !==
        nextProps.salesExecutive?.salesExecutive
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            salesPersonDetail: nextProps.salesExecutive?.salesExecutive,
          },
        });
      }
    }
    if (this.props.productList !== nextProps.productList) {
      if (
        this.props.productList?.activeAllProduct !==
        nextProps.productList?.activeAllProduct
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            productDetail: nextProps.productList?.activeAllProduct,
          },
        });
      }
    }
  }


  getListById = async (data) => {
    const {
      getOutletByBeatIdsPost,
      getSalesExecutiveByBeatIds,
      showLoador,
      showNotification,
    } = this.props;
    if (data.flag) {
      this.setState({
        searchBy: data.flag,
        formData: {
          flag: data.flag,
          beat: [],
          outletIds: [],
          salesPersonIds: [],
          productIds: [],
        },
      });
    }
    this.setState({
      mainData: { ...this.state.mainData, ...data },
    });
    if (navigator.onLine) {
      if (data.beat && data.beat != "") {
        if (this.state.searchBy == 0) {
          let beatJson = {
            beatIds: data.beat,
          }
          showLoador({ loador: true });
          await getOutletByBeatIdsPost({ beatIds: beatJson }).then(
            ({ success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              }
            }
          );
        } else {
          showLoador({ loador: true });
          await getSalesExecutiveByBeatIds({ beatIds: data.beat.join() }).then(
            ({ success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              }
            }
          );
        }
      }
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  onSearchList = (data) => {
    const { getOutletLostOrderDetailsPostNew, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      if (data.flag == 0) {
        let lostOrderData = {
          outletIds: data.outletIds,
          fromDate: data.fromdate,
          toDate: data.todate,
          productIds: data.productIds,
          flag: 0,
        };
        showLoador({ loador: true });
        getOutletLostOrderDetailsPostNew({ outletData: lostOrderData }).then(
          ({ response, success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            } else {
            }
          }
        );
      } else {
        let lostOrderDataForSalesPerson = {
          salesTeamIds: data.salesPersonIds,
          fromDate: data.fromdate,
          toDate: data.todate,
          productIds: data.productIds,
          flag: 1,
        };
        showLoador({ loador: true });
        getOutletLostOrderDetailsPostNew({
          outletData: lostOrderDataForSalesPerson,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const { orderClosingList, setGrnHistory } = this.props;
    const { dynamicMasterData, searchBy } = this.state;
    return (
      <>
        <DynamicMainScreen
          isActionColActive={false}
          deleteMsgAuto={true}
          showViewButtonInTable={false}
          showDeleteIcon={false}
          dynamicMasterData={dynamicMasterData}
          searchList={
            searchBy == 0
              ? OrderClosingJson.searchList
              : OrderClosingJson.searchListBySalsPerson
          }
          onSearchData={this.onSearchList}
          formPath={OrderClosingJson.formPath}
          screenTitle={OrderClosingJson.screenTitle}
          showPdfDownload={false}
          showExcelDownload={false}
          showAddButton={false}
          tableColumns={orderClosingColumns}
          tableData={orderClosingList.outletCloseOrderDetail}
          apiBaseURL={OrderClosingJson.apiBaseURL}
          callApi={false}
          getTableDataById={true}
          showSearchBox={true}
          showPdf={false}
          showEditIcon={false}
          showPegination={false}
          getTableData={this.getData}
          getListById={this.getListById}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  orderClosingList: state.outletOrderDetail,
  outletList: state.outlet,
  beatList: state.beat,
  salesExecutive: state.salesExecutive,
  productList: state.product,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getOutletLostOrderDetailsNew,
  getOutletByBeatIdsPost,
  getSalesExecutiveByBeatIds,
  getBeatByUserType,
  getAllActiveProduct,
  getOutletLostOrderDetailsPostNew,
  //   setGrnHistory,
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderClosingList);
