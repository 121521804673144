import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
import dayjs from "dayjs";
const initialState = {
    assetsImplementationTracking: [],
};

let URL = endpoints.assetsImplementationTracking;


const assetsImplementationTrackingSlice = createSlice({
    name: "assetsImplementationTracking",
    initialState,
    reducers: {
        assetsImplementationTrackingSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                assetsImplementationTracking: row,
            };
        },

        resetState: (state = cloneDeep(initialState), action) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const {
    assetsImplementationTrackingSuccess,
    resetState,
} = assetsImplementationTrackingSlice.actions;

export default assetsImplementationTrackingSlice.reducer;

export const getAssetsImplementationTracking = ({ assetsRequestDetailsId }) => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL + "/by-assets-request-details?assetsRequestDetailsId=" + assetsRequestDetailsId,
        }).then(({ data, success }) => {
            if (success) {
                const row = data.data.map((assetsRequestDetailObj, index) => {
                    let assetsRequestDetailsData = {
                        index: index + 1,
                        id: assetsRequestDetailObj.id === null ? "" : assetsRequestDetailObj.id,
                        image: assetsRequestDetailObj.image === null ? "" : assetsRequestDetailObj.image,
                        insertDateTime: assetsRequestDetailObj.insertDateTime !== null
                            ? assetsRequestDetailObj.insertDateTime.split(" ")[0]
                            : "",
                        insertedUserId: assetsRequestDetailObj.insertedUserId === null ? "" : assetsRequestDetailObj.insertedUserId,
                        isActive: assetsRequestDetailObj.isActive === null ? "" : assetsRequestDetailObj.isActive,
                        assetsRequestDetails:
                            assetsRequestDetailObj.assetsRequestDetails === null ? "" : assetsRequestDetailObj.assetsRequestDetails,
                    };
                    return assetsRequestDetailsData;
                });
                dispatch(assetsImplementationTrackingSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};

