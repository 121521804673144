import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import endpoint from "../../config/endpoints";
import {
  exportmsg,
  noInternetMsg,
  serverMsg,
} from "../../config/messageconstant";
import { ShowOrderListJson } from "../../DynamicFormsJson/MastersJSON/showOrderList";
import {
  getOrderListByDate,
  setOrderListShowOrder,
} from "../../Slice/OrderHeaderList.slice";
import { getStockStackHolders } from "../../Slice/StackHolder.slice";
import { OrderListColumns } from "../../tableColumns/table-columns";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";
class ShowOrderListTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromdate: "",
      todate: "",
      dynamicMasterData: {
        stakeholdersDetail: this.props.stakeholdersList.stockStakeHolders,
      },
    };
  }
  async componentDidMount() {
    // await this.getData();
  }
  getData = async () => {
    const { getOrderListByDate, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getOrderListByDate({ fromdate: "", todate: "" }).then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        }
      );
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.stakeholdersList !== nextProps.stakeholdersList) {
      if (
        this.props.stakeholdersList?.stockStakeHolders !==
        nextProps.stakeholdersList?.stockStakeHolders
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeholdersDetail: nextProps.stakeholdersList?.stockStakeHolders,
          },
        });
      }
    }
  }
  onSearchList = (data) => {
    const { getOrderListByDate, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getOrderListByDate({
        fromdate: data.fromdate,
        todate: data.todate,
      }).then(({ success }) => {
        this.setState({
          fromdate: data.fromdate ? data.fromdate : "",
          todate: data.todate ? data.todate : "",
        });
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  rowPDF = (rowData) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      downloadF({
        url:
          endpoint.stockStakeholderOrderHeader +
          "/report-by-id?documentType=1" +
          "&id=" +
          rowData.id,
        ext: "pdf",
        openNewTab: true,
      }).then((response) => {
        showLoador({ loador: false });
        if (!response) {
          this.props.showNotification({
            msg: serverMsg,
            severity: "error",
          });
        } else {
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  generateExcelStatic = (rowData) => {
    const { showLoador, showNotification } = this.props;
    const { fromdate, todate } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      downloadF({
        url:
          endpoint.stockStakeholderOrderHeader +
          "/order-history-excel-by-date?fromDate=" +
          fromdate +
          "&toDate=" +
          todate,
        ext: "xlsx",
        openNewTab: true,
      }).then((response) => {
        showLoador({ loador: false });
        if (!response) {
          this.props.showNotification({
            msg: serverMsg,
            severity: "error",
          });
        } else {
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const { showOrderList, setOrderListShowOrder } = this.props;
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          showViewButtonInTable={true}
          showDeleteIcon={false}
          dynamicMasterData={dynamicMasterData}
          searchList={ShowOrderListJson.searchList}
          onSearchData={this.onSearchList}
          formPathView={ShowOrderListJson.formPathView}
          formPath={ShowOrderListJson.formPath}
          screenTitle={ShowOrderListJson.screenTitle}
          fieldMeta={ShowOrderListJson.fieldMeta}
          showPdfDownload={false}
          staticExcel={true}
          generateExcelStatic={this.generateExcelStatic}
          isActiveURL={ShowOrderListJson.isActiveURL}
          showAddButton={false}
          tableColumnsToFilter={ShowOrderListJson.tableColumnsToFilter}
          tableColumns={OrderListColumns}
          tableData={showOrderList.orderList}
          getTableData={this.getData}
          apiBaseURL={ShowOrderListJson.apiBaseURL}
          callApi={false}
          getTableDataById={true}
          rowPDF={this.rowPDF}
          showPdf={false}
          deleteURL={ShowOrderListJson.deleteURL}
          deleteURLStatic={true}
          showPegination={false}
          // showSearch={false}
          showEditIcon={false}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stakeholdersList: state.stackholder,
  showOrderList: state.orderHeaderList,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getStockStackHolders,
  getOrderListByDate,
  setOrderListShowOrder,
};
export default connect(mapStateToProps, mapDispatchToProps)(ShowOrderListTable);
