import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { requestBatchwiseGRNJSON } from "../../DynamicFormsJson/Transaction/requestBatchwiseGRN";
import { getCrnHeaderId } from "../../Slice/creditNote.slice";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import endpoint from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";
class RequestBatchwiseGRNView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
      orderHistory: [],
    };
    this.columns = [
      {
        title: "Sr.No.",
        name: "index",
        align: "center",
      },
      {
        title: "Product Name",
        name: "productName",
      },
      {
        title: "UOM",
        name: "stakeHolderUom",
        align: "center",
      },
      {
        title: "Batch",
        name: "batchCode",
        align: "center",

      },
      {
        title: "Rate",
        name: "rate",
        align: "right",
      },
      {
        title: "Quantity",
        name: "qty",
        align: "right",
      },
      {
        title: "Total Amount",
        name: "totalAmt",
        align: "right",
      },
    ];
  }
  async componentDidMount() {
    const {
      getCrnHeaderId,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (this.props.params.id) {
        showLoador({ loador: true });
        await getCrnHeaderId({
          crnHeaderId: this.props.params.id,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.setState({
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "CRN Number",
                  value: response.creditNoteNo !== null ? response.creditNoteNo : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Date",
                  value: response.date !== null ? response.date : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Taxable Amount",
                  value:
                    response.taxableAmt !== null ? response.taxableAmt : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Total Amount",
                  value: response.totalAmt !== null ? response.totalAmt : "",
                },

                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Total Tax",
                  value:
                    response.totalAmt !== null && response.taxableAmt !== null
                      ? (+response.totalAmt - +response.taxableAmt).toFixed(2)
                      : "",
                },
              ],

              orderHistory:
                response.holderCreditNoteDetails.length !== 0
                  ? response.holderCreditNoteDetails
                  : [],
            });
          }
        });

      }


    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  render() {
    const { orderHistory, DataToSet } = this.state;
    console.log(orderHistory);
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            showTitle={requestBatchwiseGRNJSON.showTitle}
            screenTitle={"Manual GRN View"}
            showSaveBtnMain={false}
            showAddButton={false}
            showBackButton={true}
            onCancel={() => this.props.navigate(-1)}
          />
          <br />
          <DynamicDetails DataToSet={DataToSet} />
          <RTTable
            columns={this.columns}
            tableData={orderHistory}
            isActionColActive={false}
            showPegination={false}
          />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getCrnHeaderId,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RequestBatchwiseGRNView);
