import endpoint from "../../config/endpoints";
export const purchaseReturnJson = {
  screenTitle: "Purchase Return",
  showSaveBtn: false,
  apiBaseURL: endpoint.area,
  showSaveBtn: false,
  showBackToList: true,

  fieldMeta: [
    {
      label: "Is calculate in stock",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      disable: true,
      dataKey: "instockOutstock",
      tableDataKey: "instockOutstock",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: true,
    },
  ],

  fieldMetaBill: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromdate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "todate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "todate",
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
      isMandatory: true,
      onSubmit: "compare",
    },
    {
      label: "Bill",
      controlType: "autocomplete",
      placeHolder: "Select Bill",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "billDetail",
      dataKey: "billNo",
      isRootLevelKey: true,
      isMandatory: true,
      getListId: "billNo",
    },
    {
      label: "Is calculate in stock",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      disable: true,
      dataKey: "instockOutstock",
      tableDataKey: "instockOutstock",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: true,
    },
  ],
};
