import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { orderConfigurationJson } from "../../../DynamicFormsJson/MastersJSON/orderConfiguration";
import {
  getStockStakeHolderTypeWithoutType,
  getStockStakeHolderType,
} from "../../../Slice/stockStakeHolderType.slice";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { serverMsg, noInternetMsg } from "../../../config/messageconstant";


class OrderConfigurationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        stakeHolderTypeDetail:
          this.props.stakeHolderTypeList?.stockStakeHolderWithoutType,
        stakeHolderTypeDetailMultiSelect:
          this.props.stakeHolderTypeList?.stockStakeHolderType,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.stakeHolderTypeList !== nextProps.stakeHolderTypeList) {
      if (
        this.props.stakeHolderTypeList?.stockStakeHolderWithoutType !==
        nextProps.stakeHolderTypeList?.stockStakeHolderWithoutType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeHolderTypeDetail:
              nextProps.stakeHolderTypeList?.stockStakeHolderWithoutType,
          },
        });
      }
      if (
        this.props.stakeHolderTypeList?.stockStakeHolderType !==
        nextProps.stakeHolderTypeList?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeHolderTypeDetailMultiSelect:
              nextProps.stakeHolderTypeList?.stockStakeHolderType,
          },
        });
      }
    }
  }

  async componentDidMount() {
    const {
      getStockStakeHolderTypeWithoutType,
      getStockStakeHolderType,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getStockStakeHolderTypeWithoutType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getStockStakeHolderType({ userTypeIds: "1,2" }).then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        }
      );
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicForm
          formPath={orderConfigurationJson.formPath}
          screenTitle={orderConfigurationJson.screenTitle}
          fieldMeta={orderConfigurationJson.fieldMeta}
          dynamicMasterData={dynamicMasterData}
          apiBaseURL={orderConfigurationJson.apiBaseURL}
          showSaveNextBtn={orderConfigurationJson.showSaveNextBtn}

        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stakeHolderTypeList: state.stockStakeHolderType,
});
const mapDispatchToProps = {
  getStockStakeHolderTypeWithoutType,
  getStockStakeHolderType,
  showLoador,
  showNotification,

};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderConfigurationForm);
