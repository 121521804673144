import CloseIcon from "@mui/icons-material/Close";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import DynamicFormWithTable from "./DynamicFormWithTable";

import { connect } from "react-redux";

import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const SearchPopUp = ({today=false,title,onClear,showSearch,showClear=true, buttonRight=true,searchButtonGrid,onFormSearch,fieldDataForSearch, onDataChangeForSearch, searchList, dynamicMasterData, open, tableRow, handleCloses }) => {
 

  const handleClose = () => {
    handleCloses();
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="lg"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {title}
        </BootstrapDialogTitle>
        <DialogContent dividers>
        
        <DynamicFormWithTable
                    fieldMeta={searchList}
                    dynamicMasterData={dynamicMasterData}
                    fieldData={fieldDataForSearch}
                    onDataChange={onDataChangeForSearch}
                    onSave={onFormSearch}
                    searchButton={false}
                    today={today}
                    buttonRight  ={buttonRight}
                    buttonCenter={false}
                    searchButtonGrid={searchButtonGrid}
                    showClear= {showClear}
                    showSearch={showSearch}
       onClear= {onClear}
                  />
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};
const mapStateToProps = (state) => ({ feedBack: state.feedBack });

const mapDispatchToProps = { showNotification, showLoador };

export default connect(mapStateToProps, mapDispatchToProps)(SearchPopUp);
