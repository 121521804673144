import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  groupProduct: [],
  groupProductNew: [],
};
let URL = endpoints.group;
const groupProductSlice = createSlice({
  name: "groupProduct",
  initialState,
  reducers: {
    groupProductSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        groupProduct: row,
      };
    },

    groupProductNewSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        groupProductNew: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { groupProductSuccess, groupProductNewSuccess, resetState } =
  groupProductSlice.actions;

export default groupProductSlice.reducer;

export const getGroupProductList = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"sortOrder": "ASC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((groupProductObject, index) => {
          let groupProductObjectData = {
            index: index + 1,
            id: groupProductObject.id === null ? "" : groupProductObject.id,
            name:
              groupProductObject.name === null ? "" : groupProductObject.name,
            shortName:
              groupProductObject.shortName === null
                ? ""
                : groupProductObject.shortName,
            groupProductName:
              groupProductObject.name === null
                ? ""
                : groupProductObject.name +
                  " (" +
                  groupProductObject.shortName +
                  ")",
            code:
              groupProductObject.code === null ? "" : groupProductObject.code,
            subCategory:
              groupProductObject.subCategory === null
                ? "-"
                : groupProductObject.subCategory,
            categoryName:
              groupProductObject.subCategory.category === null
                ? "-"
                : groupProductObject.subCategory.category.name,
            brand:
              groupProductObject.brand === null ? "" : groupProductObject.brand,
            brandName:
              groupProductObject.brand === null
                ? ""
                : groupProductObject.brand.name,
            productCount:
              groupProductObject.productCount === null
                ? "-"
                : groupProductObject.productCount,
            sortOrder:
              groupProductObject.sortOrder === null
                ? "-"
                : groupProductObject.sortOrder,
            description:
              groupProductObject.description === null
                ? "-"
                : groupProductObject.description,
            products:
              groupProductObject.products === null
                ? "-"
                : groupProductObject.products,
            productCount:
              groupProductObject.productCount === null
                ? "-"
                : groupProductObject.productCount,
            isActive:
              groupProductObject.isActive === null
                ? ""
                : groupProductObject.isActive,
          };
          return groupProductObjectData;
        });
        dispatch(groupProductSuccess({ row }));
        dispatch(groupProductNewSuccess({ row: content }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const setGroupProduct =
  ({ row }) =>
  async (dispatch) => {
    dispatch(groupProductNewSuccess({ row }));
  };
