import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  packagingType: [],
  packagingTypeNew: [],
};
let URL = endpoints.packagingType;
const packagingTypeSlice = createSlice({
  name: "packagingType",
  initialState,
  reducers: {
    packagingTypeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        packagingType: row,
      };
    },

    packagingTypeNewSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        packagingTypeNew: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { packagingTypeSuccess, packagingTypeNewSuccess, resetState } =
  packagingTypeSlice.actions;

export default packagingTypeSlice.reducer;

export const getPackagingType = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"sortOrderNo": "ASC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((packagingTypeData, index) => {
          let packagingType = {
            index: index + 1,
            id: packagingTypeData.id === null ? "" : packagingTypeData.id,
            name: packagingTypeData.name === null ? "" : packagingTypeData.name,
            shortName:
              packagingTypeData.shortName === null
                ? ""
                : packagingTypeData.shortName,
            packagingName:
              packagingTypeData.name === null &&
              packagingTypeData.shortName === null
                ? ""
                : packagingTypeData.name +
                  " (" +
                  packagingTypeData.shortName +
                  ")",
            description:
              packagingTypeData.description === null
                ? ""
                : packagingTypeData.description,
            sortOrderNo:
              packagingTypeData.sortOrderNo === null
                ? ""
                : packagingTypeData.sortOrderNo,
            insertDateTime:
              packagingTypeData.insertDateTime === null
                ? ""
                : packagingTypeData.insertDateTime,
          };
          return packagingType;
        });
        dispatch(packagingTypeSuccess({ row }));
        dispatch(packagingTypeNewSuccess({ row: content }));

        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const setPackagingType =
  ({ row }) =>
  async (dispatch) => {
    dispatch(packagingTypeNewSuccess({ row }));
  };
