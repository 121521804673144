import endpoint from "../../config/endpoints";

export const transporterJson = {
  formPath: "/transporter-form",
  apiBaseURL: endpoint.transporter,
  changeExcel: endpoint.exportData,
  screenTitle: "Transporter",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Transporter",
  showExcelDownload: true,
  excelFileName: "Transporter",
  excelUrl: "uom-data" + '?sort={"insertDateTime": "DESC"}',

  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Region",
      columnKeyName: "regionName",
      isChecked: true,
    },
    {
      columnDisplayName: "Address",
      columnKeyName: "address",
      isChecked: true,
    },
    {
      columnDisplayName: "Pincode",
      columnKeyName: "pincode",
      isChecked: true,
    },
    {
      columnDisplayName: "SPOC",
      columnKeyName: "spocName",
      isChecked: true,
    },
    {
      columnDisplayName: "SPOC Contact Number",
      columnKeyName: "spocNo",
      isChecked: true,
    },
    {
      columnDisplayName: "Transporter For",
      columnKeyName: "transporterForData",
      isChecked: true,
    },
    {
      columnDisplayName: "GST NO",
      columnKeyName: "gstNo",
      isChecked: true,
    },
    {
      columnDisplayName: "Sort Order Number",
      columnKeyName: "sortOrder",
      isChecked: true,
    },
    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "Transporter For",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "transporterFor",
      tableDataKey: "transporterFor",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Admin",
          value: "0",
        },
        {
          label: "Distributor",
          value: "1",
        },
      ],
      isMandatory: true,
      disable: true,
    },
    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "regionDetail",
      dataKey: "region",
      getListId: "region",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "State",
      controlType: "multiSelect",
      placeHolder: "Select State",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stateDetails",
      dataKey: "stateIds",
      getListFrom: "region",
      getListId: "stateIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "multiSelect",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "zoneDetail",
      dataKey: "zoneIds",
      getListFrom: "stateIds",
      getListId: "zoneIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "District",
      controlType: "multiSelect",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "districtIdsDetail",
      dataKey: "districtIds",
      getListFrom: "zoneIds",
      getListId: "districtIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Taluka",
      controlType: "hideMultiSelect",
      placeHolder: "Select Taluka",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "talukaDetail",
      dataKey: "talukaIds",
      isRootLevelKey: true,
      hideGrid: true,
      isMandatory: false,
      getListId: "talukaIds",
      openDataKey: "transporterFor",
      open: "1",
      error: "transporterFor",
      tableDataKey: "transporterFor",
    },
    {
      label: "Area",
      controlType: "hideAutocomplete",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      hideGrid: true,
      dataKey: "area",
      getListFrom: "talukaIds",
      getListId: "area",
      isRootLevelKey: false,
      isMandatory: false,
      openDataKey: "transporterFor",
      open: "1",
      error: "transporterFor",
      tableDataKey: "transporterFor",
    },
    {
      label: "Stock Holder Type",
      controlType: "hideAutocomplete",
      placeHolder: "Select Stock Holder",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeHolderTypeDetail",
      hideGrid: true,
      dataKey: "stockStakeHolderType",
      getListId: "stockStakeHolderType",
      isRootLevelKey: false,
      isMandatory: false,
      openDataKey: "transporterFor",
      open: "1",
      error: "transporterFor",
      tableDataKey: "transporterFor",
    },
    {
      label: "Stock Holder Name",
      controlType: "hideAutocomplete",
      placeHolder: "Select Stock Holder Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeHolderName",
      dataKey: "stockStakeHolder",
      hideGrid: true,
      getListFrom: "stockStakeHolderType,area",
      getListId: "stockStakeHolder",
      isRootLevelKey: false,
      isMandatory: false,
      openDataKey: "transporterFor",
      open: "1",
      error: "transporterFor",
      tableDataKey: "transporterFor",
    },
    {
      label: "Transporter Name",
      controlType: "textfield",
      placeHolder: "Enter Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "name",
      isMandatory: true,
    },
    {
      label: "SPOC",
      controlType: "textfield",
      placeHolder: "Contact Person Name",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      dataKey: "spocName",
      isMandatory: true,
    },
    {
      label: "Contact Number",
      controlType: "textfield",
      placeHolder: "Contact Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      maxLength: 10,
      dataKey: "spocNo",
      valid: true,
      validName: "phoneNo",
      isMandatory: true,
    },
    {
      label: "Address",
      controlType: "textfield",
      placeHolder: "Enter Address",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "address",
      isMandatory: true,
    },
    {
      label: "Pincode",
      controlType: "textfield",
      placeHolder: "Enter Pincode",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "pincode",
      isMandatory: true,
    },
    {
      label: "GST Number",
      controlType: "textfield",
      placeHolder: "22AAAAA0000A1Z5",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "gstNo",
      isMandatory: false,
      valid: true,
      // validName: "gstNo",
      inputType: "numberText",
    },
    {
      label: "Sort Order No",
      controlType: "textfield",
      placeHolder: "Sort Order Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sortOrder",
      isMandatory: true,
    },
  ],
  fieldMetaForDistributor: [
    {
      label: "Transporter Name",
      controlType: "textfield",
      placeHolder: "Enter Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "name",
      isMandatory: true,
    },
    {
      label: "SPOC",
      controlType: "textfield",
      placeHolder: "Contact Person Name",
      md: 6,
      lg: 5,
      sm: 5,
      xs: 12,
      dataKey: "spocName",
      isMandatory: true,
    },
    {
      label: "Contact Number",
      controlType: "textfield",
      placeHolder: "Contact Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      maxLength: 10,
      dataKey: "spocNo",
      valid: true,
      validName: "phoneNo",
      isMandatory: true,
    },
    {
      label: "Address",
      controlType: "textfield",
      placeHolder: "Enter Address",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "address",
      isMandatory: true,
    },
    {
      label: "Pincode",
      controlType: "textfield",
      placeHolder: "Enter Pincode",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "pincode",
      isMandatory: true,
    },
    {
      label: "GST Number",
      controlType: "textfield",
      placeHolder: "22AAAAA0000A1Z5",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "gstNo",
      isMandatory: true,
      valid: true,
      validName: "gstNo",
      inputType: "numberText",
    },
    {
      label: "Sort Order No",
      controlType: "textfield",
      placeHolder: "Sort Order Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sortOrder",
      isMandatory: true,
    },
  ],
};
