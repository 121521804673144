// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-shading-mui {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  
    background: rgba(255, 255, 255, 0.26);
  }
  
  .loading-icon-mui {
    position: absolute;
    color:grey; 
    font-size: 60px;
    z-index: 9999;
    top: calc(40% - 30px);
    left: calc(50% - 30px);
  }
  `, "",{"version":3,"sources":["webpack://./src/CSS/loading.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;;IAEZ,qCAAqC;EACvC;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,eAAe;IACf,aAAa;IACb,qBAAqB;IACrB,sBAAsB;EACxB","sourcesContent":[".loading-shading-mui {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n  \n    background: rgba(255, 255, 255, 0.26);\n  }\n  \n  .loading-icon-mui {\n    position: absolute;\n    color:grey; \n    font-size: 60px;\n    z-index: 9999;\n    top: calc(40% - 30px);\n    left: calc(50% - 30px);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
