import { Checkbox, FormHelperText, Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { compose } from "@reduxjs/toolkit";
import * as React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import { TextFieldCompo } from "../../../components/Comman/TextFieldCompo";
import { withRouter } from "../../../components/withRouter";
import endpoint from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { serverMsg, noInternetMsg } from "../../../config/messageconstant";
import {
  getModuleAndSubModule,
  setModuleList,
} from "../../../Slice/accessRole.slice";
import { getStockStakeHolderTypeForAccessRole } from "../../../Slice/stockStakeHolderType.slice";
import { apiGet, apiPost } from "../../../utils/api_service";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import swal from "sweetalert";

class SubAdminUserAccessRole extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      stackHolderType: "",
      stackHolderTypeName: "",
      accessRoleName: "",
      shortName: "",
      formError: {},
      error: false,
      subAdminUserData: "",
    };
  }

  handleClick = (e, name, moduleSubs, row) => {
    const { setModuleList, accessRole } = this.props;
    const moduleandSubModuleList = accessRole.moduleList.map((module) => {
      if (module.moduleId === row.moduleId) {
        const subModuleList = module.moduleSubs.map((submodule) => {
          if (submodule.subModuleId === moduleSubs.subModuleId) {
            submodule = {
              ...submodule,
              [name]: e.target.checked ? 1 : 0,
            };
            return submodule;
          }
          return submodule;
        });
        module = {
          ...module,
          moduleSubs: subModuleList,
        };
        return module;
      }
      return module;
    });
    setModuleList({ row: moduleandSubModuleList });
  };

  handleClickAll = (e, row) => {
    const { setModuleList, accessRole } = this.props;
    const moduleandSubModuleList = accessRole.moduleList.map((module) => {
      if (module.moduleId === row.moduleId) {
        const submoduleList = module.moduleSubs.map((submodule) => {
          submodule = {
            ...submodule,
            editReject: e.target.checked ? 1 : 0,
            addApproveConfig: e.target.checked ? 1 : 0,
            deleteRejectApprove: e.target.checked ? 1 : 0,
            view: e.target.checked ? 1 : 0,
          };
          return submodule;
        });
        module = {
          ...module,
          checked: e.target.checked,
          moduleSubs: submoduleList,
        };
        return module;
      }

      return module;
    });
    setModuleList({ row: moduleandSubModuleList });
  };
  async componentDidMount() {
    const {
      getModuleAndSubModule,
      getStockStakeHolderTypeForAccessRole,
      setModuleList,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (this.props.params.id) {
        const { showLoador } = this.props;
        showLoador({ loador: true });
        await apiGet({
          url: endpoint.developerAdminUser + "/" + this.props.params.id,
        }).then(({ data, success }) => {
          if (success) {
            this.jsonHandler(data);
          }
          return success;
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  jsonHandler = (subAdminUserSuccess) => {
    const { setModuleList, showLoador, showNotification } = this.props;
    showLoador({ loador: true });
    apiGet({
      url: endpoint.userAccess + "/" + subAdminUserSuccess.data.accessRoleId,
    }).then(({ data, success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      } else {
        showLoador({ loador: false });
        if (!subAdminUserSuccess.error) {
          this.setState({
            subAdminUserData: subAdminUserSuccess.data,
            stackHolderTypeName:
              subAdminUserSuccess.data.stockStakeHolderType != null &&
              subAdminUserSuccess.data.stockStakeHolderType.name != null
                ? subAdminUserSuccess.data.stockStakeHolderType.name
                : "",
          });
          if (!data.error) {
            const moduleListUpdated = data.data.modules.map((module) => {
              let filteredModuleList = subAdminUserSuccess.data.modules.filter(
                (moduleData) => moduleData.moduleId === module.moduleId
              );
              if (filteredModuleList.length !== 0) {
                const moduleSubs = module.moduleSubs.map((moduleSubs) => {
                  let filteredSubModuleList =
                    filteredModuleList[0].moduleSubs.filter(
                      (moduleData) =>
                        moduleData.subModuleId === moduleSubs.subModuleId
                    );
                  moduleSubs = {
                    ...moduleSubs,
                    addApproveConfig:
                      filteredSubModuleList.length !== 0
                        ? filteredSubModuleList[0].addApproveConfig
                        : false,
                    deleteRejectApprove:
                      filteredSubModuleList.length !== 0
                        ? filteredSubModuleList[0].deleteRejectApprove
                        : false,
                    editReject:
                      filteredSubModuleList.length !== 0
                        ? filteredSubModuleList[0].editReject
                        : false,
                    view:
                      filteredSubModuleList.length !== 0
                        ? filteredSubModuleList[0].view
                        : false,
                  };
                  return moduleSubs;
                });

                module = {
                  ...module,
                  checked: true,
                  moduleSubs: moduleSubs,
                };
                return module;
              } else {
                const moduleSubs = module.moduleSubs.map((moduleSubs) => {
                  moduleSubs = {
                    ...moduleSubs,
                    addApproveConfig: false,
                    deleteRejectApprove: false,
                    editReject: false,
                    view: false,
                  };
                  return moduleSubs;
                });

                module = {
                  ...module,
                  checked: false,
                  moduleSubs: moduleSubs,
                };
              }
              return module;
            });

            setModuleList({ row: moduleListUpdated });
          }
        }
      }
    });
  };
  checkValidation = () => {
    const { accessRoleName, stackHolderType, shortName } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (accessRoleName === "" || accessRoleName === null) {
      formIsValid = false;
      formErrors["nameError"] = myConstClass.accessRoleMsg;
    }

    if (shortName === "" || shortName === null) {
      formIsValid = false;
      formErrors["shortNameError"] = myConstClass.shortNameMsg;
    }
    if (stackHolderType === "" || stackHolderType === null) {
      formIsValid = false;
      formErrors["stackHolderTypeError"] = myConstClass.stackHolderTypeMsg;
    }
    this.setState({
      formError: formErrors,
    });
    return formIsValid;
  };
  submitHandler = () => {
    const { accessRole, showLoador, showNotification } = this.props;
    const { subAdminUserData } = this.state;

    let moduleListToSave = [];
    accessRole.moduleList.map((module) => {
      let submoduleList = [];
      module.moduleSubs.map((submodule) => {
        if (
          submodule.addApproveConfig == 1 ||
          submodule.editReject == 1 ||
          submodule.deleteRejectApprove == 1 ||
          submodule.view == 1
        ) {
          submoduleList.push(submodule);
        }
      });
      if (submoduleList.length !== 0) {
        module = {
          ...module,
          moduleSubs: submoduleList.map((submoduleObject) => {
            submoduleObject = {
              ...submoduleObject,
              addApproveConfig:
                submoduleObject.addApproveConfig == false
                  ? 0
                  : submoduleObject.addApproveConfig,
              editReject:
                submoduleObject.editReject == false
                  ? 0
                  : submoduleObject.editReject,
              deleteRejectApprove:
                submoduleObject.deleteRejectApprove == false
                  ? 0
                  : submoduleObject.deleteRejectApprove,
              view: submoduleObject.view == false ? 0 : submoduleObject.view,
            };
            return submoduleObject;
          }),
        };
        delete module.checked;
        moduleListToSave.push(module);
      }
    });
    let accessRoleToSave = {
      ...subAdminUserData,
      modules: moduleListToSave,
      accessRoleJson: JSON.stringify(moduleListToSave),
    };
    if (accessRoleToSave.modules.length !== 0) {
      if (navigator.onLine) {
        swal({
          title: "Are you sure?",
          text: myConstClass.saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            showLoador({ loador: true });
            apiPost({
              url: endpoint.developerAdminUser + "/update",
              postBody: accessRoleToSave,
            }).then(({ data, success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              } else {
                showNotification({
                  msg: myConstClass.updateMsg,
                });
                this.props.navigate(-1);
              }
            });
          }
        });
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    } else if (accessRole.moduleList.length === 0) {
      this.setState({
        error: true,
      });
    }
  };
  goBack = () => {
    this.props.navigate(-1);
  };
  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };
  onAutocompleteChange = (name, newValue) => {
    if (newValue != null) {
      this.setState({
        [name]: newValue,
      });
    } else {
      this.setState({
        [name]: "",
      });
    }
  };
  render() {
    const { accessRole, stockStakeHolderType } = this.props;
    const {
      accessRoleName,
      shortName,
      formError,
      stackHolderName,
      stackHolderTypeName,
      error,
      id,
    } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            pt: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <Grid container justifyContent="space-evenly" direction="row">
            <Grid
              item
              xs={12}
              subAdminUserData
              md={12}
              size="small"
              container
              justifyContent="flex-end"
            >
              <ButtonCompo
                size="small"
                type="button"
                variant="outlined"
                name=" Back to List"
                onClick={this.goBack}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              spacing={1}
              justifyContent="space-evenly"
            >
              {" "}
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label=" *Stock Holder Type"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextFieldCompo
                    color="primary"
                    name="stackHolderType"
                    placeHolder="Stock Holder Type"
                    size="small"
                    value={stackHolderTypeName}
                    disabled={true}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              ></Grid>
            </Grid>
          </Grid>
          <br />
          <FormHelperText error>
            {error ? "*Please select atlest one checkbox" : ""}
          </FormHelperText>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width="10%">Sr. No.</TableCell>
                  <TableCell>Module Name</TableCell>
                  <TableCell align="center" width="10%">
                    Add
                  </TableCell>
                  <TableCell align="center" width="10%">
                    Edit
                  </TableCell>
                  <TableCell align="center" width="10%">
                    Delete
                  </TableCell>
                  <TableCell align="center" width="10%">
                    View
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accessRole.moduleList.map((row, index) => (
                  <>
                    <TableRow key={row.name}>
                      <TableCell align="center">
                        {index + 1}
                        <Checkbox
                          color="primary"
                          onClick={(event) => this.handleClickAll(event, row)}
                          checked={row.checked ? true : false}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row" colSpan={5}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, fontWeight: "bold" }}
                          label={row.moduleName}
                        />
                      </TableCell>
                    </TableRow>
                    {row.moduleSubs.map((submoduleData, index) => {
                      return (
                        <TableRow key={submoduleData.name}>
                          <TableCell align="center"></TableCell>
                          <TableCell component="th" scope="row">
                            &nbsp;&nbsp; &nbsp;&nbsp;{" "}
                            {submoduleData.subModuleName}
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            <Checkbox
                              color="primary"
                              onClick={(event) =>
                                this.handleClick(
                                  event,
                                  "addApproveConfig",
                                  submoduleData,
                                  row
                                )
                              }
                              checked={
                                submoduleData.addApproveConfig ? true : false
                              }
                            />
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            <Checkbox
                              color="primary"
                              onClick={(event) =>
                                this.handleClick(
                                  event,
                                  "editReject",
                                  submoduleData,
                                  row
                                )
                              }
                              checked={submoduleData.editReject ? true : false}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            <Checkbox
                              color="primary"
                              onClick={(event) =>
                                this.handleClick(
                                  event,
                                  "deleteRejectApprove",
                                  submoduleData,
                                  row
                                )
                              }
                              checked={
                                submoduleData.deleteRejectApprove ? true : false
                              }
                            />
                          </TableCell>
                          <TableCell align="center">
                            {" "}
                            <Checkbox
                              color="primary"
                              onClick={(event) =>
                                this.handleClick(
                                  event,
                                  "view",
                                  submoduleData,
                                  row
                                )
                              }
                              checked={submoduleData.view ? true : false}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          &nbsp;&nbsp; &nbsp;&nbsp;
          <div align="right">
            <ButtonCompo
              size="button"
              type="button"
              variant="outlined"
              name="Cancel"
              onClick={this.goBack}
            />
            &nbsp;&nbsp;
            <ButtonCompo
              size="button"
              type="button"
              variant="contained"
              name="Submit"
              onClick={this.submitHandler}
            />
          </div>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  accessRole: state.accessRole,
  stockStakeHolderType: state.stockStakeHolderType,
});
const mapDispatchToProps = {
  getModuleAndSubModule,
  showLoador,
  showNotification,
  setModuleList,
  getStockStakeHolderTypeForAccessRole,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SubAdminUserAccessRole);
