import React from "react";
import { connect } from "react-redux";
import { zoneJson } from "../../../DynamicFormsJson/MastersJSON/zone";
import { getZoneNew } from "../../../Slice/zone.slice";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import {
  AreaColumnsForPopup,
  DistrictColumnsForPopup,
  TalukaColumnsForPopup,
  ZoneColumns
} from "../../../tableColumns/table-columns";

class ZoneTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { zoneList, getZoneNew } = this.props;
    return (
      <>
        <DynamicMainScreen
          screenTitle={zoneJson.screenTitle}
          fieldMeta={zoneJson.fieldMeta}
          formPath={zoneJson.formPath}
          showPdfDownload={false}
          showExcelDownload={true}
          changeExcelURL={"zone-data" + '?sort={"sortOrder": "ASC"}'}
          showExtension={false}
          pdfFileName={false}
          excelFileName={zoneJson.excelFileName}
          showAddButton={zoneJson.showAddButton}
          tableColumnsToFilter={zoneJson.tableColumnsToFilter}
          tableColumns={ZoneColumns}
          tableData={zoneList?.zone}
          getTableData={getZoneNew}
          apiBaseURL={zoneJson.apiBaseURL}
          baseIdColumn={zoneJson.baseIdColumn}
          isNote={true}
          showDeleteIcon={false}
          isNoteValue={"Zone Is The Second Largest Entity Of The System"}
          showPopUpDataList={[
            {
              dataKey: "districtCount",
              columns: DistrictColumnsForPopup,
              title: "District List",
              header: {
                dataKey: "zoneName",
                label: "State",
              },
            },
            {
              dataKey: "talukaCount",
              columns: TalukaColumnsForPopup,
              title: "Taluka List",
              header: {
                dataKey: "zoneName",
                label: "State",
              },
            },
            {
              dataKey: "areaCount",
              columns: AreaColumnsForPopup,
              title: "Area List",
              header: {
                dataKey: "zoneName",
                label: "Zone",
              },
            },
          ]}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  zoneList: state.zone,
});
const mapDispatchToProps = {
  getZoneNew,
};
export default connect(mapStateToProps, mapDispatchToProps)(ZoneTable);
