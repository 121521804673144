import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  document: [],
};
let URL = endpoints.document;
const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    documentSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        document: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { documentSuccess, resetState } = documentSlice.actions;

export default documentSlice.reducer;

export const getDocument = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertDateTime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((documentData, index) => {
          let document = {
            index: index + 1,
            id: documentData.id === null ? "" : documentData.id,
            docName: documentData.docName === null ? "" : documentData.docName,
            type:
              documentData.type === null
                ? ""
                : documentData.type === 0
                ? "Stock Holder"
                : documentData.type === 1
                ? "Sales Manager"
                : documentData.type === 2
                ? "Sales Executive"
                : "Stock Holder,Sales Manager,Sales Executive",
          };
          return document;
        });
        dispatch(documentSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const setDocument =
  ({ row }) =>
  async (dispatch) => {
    dispatch(documentSuccess({ row }));
  };
