import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";

import { ButtonCompo } from "../../components/Comman/Button";
import { showLoador, showNotification } from "../Landing/Landing.slice";

import { auditJson } from "../../DynamicFormsJson/MastersJSON/audit";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { deleteFailedMsg } from "../../config/messageconstant";
import { apiDelete } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import swal from "sweetalert";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddQuestion = ({
  open,
  getAuditQestionList,
  handleCloses,
  onSave,
  dataToSave,
  showLoador,
  showNotification,
}) => {
  const [dynamicMasterData, setDynamicMasterData] = React.useState({
    optionTypeList: [
      {
        id: "1",
        name: "Optional",
      },
      {
        id: "2",
        name: "MultiSelect",
      },
    ],
  });
  const [dataToSet, setDataToSet] = React.useState([
    {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
      label: "Question",
      value: dataToSave.question,
    },
    {
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
      label: "OptionType",
      value: dataToSave.optionType == "1" ? "Optional" : "MultiSelect",
    },
  ]);

  const [optionList, setOptionList] = React.useState(
    dataToSave.auditQuestionOption &&
      dataToSave.auditQuestionOption.length !== 0
      ? dataToSave.auditQuestionOption
      : []
  );

  useEffect(() => {
    if (
      dataToSave.auditQuestionOption &&
      dataToSave.auditQuestionOption.length !== 0 &&
      optionList.length == 0
    ) {
      setOptionList(dataToSave.auditQuestionOption);
    }
  });
  const handleClose = () => {
    handleCloses();
  };

  const submitHandler = (dataToSave) => {
    const optiondata = {
      index: optionList.length + 1,
      ...dataToSave,
    };
    let options = [...optionList, optiondata];
    setOptionList(options.sort((a, b) => a.orderNo - b.orderNo));
  };

  let columns = [
    {
      title: "Sr. No.",
      name: "index",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },
    {
      name: "optionTitle",
      title: "Option",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
      canSearch: true,
    },
    {
      name: "points",
      title: "Points",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
      canSearch: true,
    },
    {
      name: "action",
      title: "Actions",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
      canSearch: true,
    },
  ];

  const rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (rowData.id) {
          onDelete(rowData);
        }
        const filteredList = optionList.filter(
          (row) => row.index !== rowData.index
        );
        setOptionList(
          filteredList.map((optionData, index) => {
            return { ...optionData, index: index + 1 };
          })
        );
      }
    });
  };

  const onDelete = (row) => {
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiDelete({
        url: endpoint.auditOption + "/" + row.id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          getAuditQestionList();
        } else {
          showNotification({ msg: deleteFailedMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  const saveHandler = () => {
    onSave(optionList, dataToSave);
  };
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="lg"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Add Options
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <DynamicFormWithoutSave
            paddingTop={false}
            showTitle={false}
            showBackToList={true}
            DataToSet={dataToSet}
            dynamicMasterData={dynamicMasterData}
            getByIdApi={false}
            callApi={false}
            screenTitle={auditJson.screenTitle}
            fieldMeta={auditJson.fieldMetaForOption}
            showCancel={auditJson.showCancel}
            apiBaseURL2={auditJson.apiBaseURL2}
            showSaveNextBtn={auditJson.showSaveNextBtn}
            saveBtnText={"Add"}
            resetAfterSave={true}
            onSave={submitHandler}
          />
          <br />
          <Grid item xs={12} sm={12} md={12}>
            <DynamicTable
              data={optionList}
              tableHead={columns}
              showHeadDelete={true}
              showPegination={false}
              rowDelete={rowDelete}
            />
          </Grid>
          <br />

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="right"
          >
            <ButtonCompo
              size="medium"
              type="Submit"
              variant="contained"
              name="Add Option"
              onClick={saveHandler}
            />
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};
const mapStateToProps = (state) => ({ feedBack: state.feedBack });

const mapDispatchToProps = { showLoador, showNotification };

export default connect(mapStateToProps, mapDispatchToProps)(AddQuestion);
