import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  configList: [],
};
let URL = endpoints.salesTeamOrderConfig;
const taxSlice = createSlice({
  name: "salesTeamOrderConfigSlice",
  initialState,
  reducers: {
    salesTeamOrderConfigSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        configList: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { salesTeamOrderConfigSuccess, resetState } = taxSlice.actions;

export default taxSlice.reducer;

export const getSalesTeamOrderConfigList = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-all",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        console.log(content);
        const row = content.map((rowData, index) => {
          let tax = {
            index: index + 1,
            id: rowData.id != null ? rowData.id : "",
            insertDateTime: rowData.insertDateTime,
            orderToIds: rowData.orderToIds != null ? rowData.orderToIds : "",
            salesPersonName:
              rowData.stockStakeHolderType != null &&
              rowData.stockStakeHolderType.name != null
                ? rowData.stockStakeHolderType.name
                : "",
            stakeHolderName:
              rowData.orderToNames != null ? rowData.orderToNames : "",
            stockStakeHolderType:
              rowData.stockStakeHolderType != null &&
              rowData.stockStakeHolderType.id != null
                ? rowData.stockStakeHolderType.id
                : "",
          };
          return tax;
        });
        dispatch(salesTeamOrderConfigSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
