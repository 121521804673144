import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { FormHelperText, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { MyComponentWithIconProps } from "../../../components/Comman/IconButton";
import DynamicDetails from "../../../components/dynamicscreens/DynamicDetails";
import { imageUrl } from "../../../config/Url";
import { ButtonCompo } from "../../../components/Comman/Button";
import AttachFile from "../../../components/Comman/AttachFile";
import { LabelCompo } from "../../../components/Comman/Label";
import { redColor, titleColor } from "../../../config/ColorObj";
import { imageMsg } from "../../../config/messageconstant";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { classes, children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const AssetsTrackingPopUp = ({
    handleCloses,
    open,
    tableData,
    listData,
    onAddImage,
    changeH,
    fileName,
    onClickViewPreview,
}) => {

    const [formErrors, setFormErrors] = React.useState({});

    const handleClose = () => {
        handleCloses();
    };

    const onClickViewPreviewMethod = (e, fileName) => {
        onClickViewPreview(e, fileName)
    }

    React.useEffect(() => {

    }, []);


    const onAddImageMethod = () => {
        if (checkValidation()) {
            onAddImage();
        }
    }

    const checkValidation = () => {

        let formErrors = {};
        let formIsValid = true;


        if (fileName === "" || fileName === null) {
            formIsValid = false;
            formErrors["imageError"] = imageMsg;
        }

        setFormErrors(formErrors);
        return formIsValid;
    };


    return (
        <>
            <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    fullWidth
                    maxWidth="lg"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleClose}
                    >
                        Assets Tracking Details
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <br />
                        <>
                            <DynamicDetails
                                DataToSet={[
                                    {
                                        xs: 12,
                                        sm: 4,
                                        md: 4,
                                        lg: 4,
                                        label: "Firm Name",
                                        value: tableData.outlet.firmName,
                                    },
                                    {
                                        xs: 12,
                                        sm: 4,
                                        md: 4,
                                        lg: 4,
                                        label: "Asset",
                                        value: tableData.assets.name,
                                    },
                                    {
                                        xs: 12,
                                        sm: 4,
                                        md: 4,
                                        lg: 4,
                                        label: "Asset Quantity",
                                        value: tableData.assetsQuantity,
                                    },
                                    {
                                        xs: 12,
                                        sm: 4,
                                        md: 4,
                                        lg: 4,
                                        label: "Request Date",
                                        value: tableData.requestDate,
                                    },
                                    {
                                        xs: 12,
                                        sm: 6,
                                        md: 6,
                                        lg: 6,
                                        label: "Request Action Date",
                                        value: tableData.requestActionDate,
                                    },
                                ]}
                            />
                            <br />
                            <TableContainer style={{ maxHeight: "auto", overflow: "auto" }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" width="5%">Sr. No.</TableCell>
                                            <TableCell align="center" width="30%">Image</TableCell>
                                            <TableCell align="center" width="10%">Image View</TableCell>
                                            <TableCell align="center" width="15%">Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {listData.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell align="center">{index + 1}</TableCell>
                                                <TableCell align="left">{row.image}</TableCell>
                                                <TableCell align={'center'}>
                                                    {row.image != null && row.image != '' ? (
                                                        <MyComponentWithIconProps
                                                            statusImage={VisibilityIcon}
                                                            color="primary"
                                                            fontSize="medium"
                                                            title="View"
                                                            onClick={() => {
                                                                window.open(imageUrl + row.image, '_blank')
                                                            }}
                                                        />
                                                    ) : (
                                                        <>
                                                        </>
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">{row.insertDateTime}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <br />
                            <Grid container spacing={1}>

                                <Grid
                                    item
                                    sm={12}
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    container
                                    justifyContent="space-between"
                                >
                                    <Grid
                                        item
                                        sm={6}
                                        xs={8}
                                        md={6}
                                        lg={6}
                                        container
                                        justifyContent="space-evenly"
                                    >
                                        <Grid item xs={12} md={5} sm={5}>
                                            <LabelCompo
                                                className="text-black"
                                                style={{ fontSize: 16, color: redColor }}
                                                label="* "
                                            />
                                            <LabelCompo
                                                className="text-black"
                                                style={{ fontSize: 16, color: titleColor }}
                                                label="Select Image:"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} sm={6}>
                                            <AttachFile
                                                name={"file"}
                                                fileName={changeH}
                                            />
                                            &nbsp; {fileName !== "" ? fileName.name : ""}
                                            &nbsp;{" "}
                                            {fileName !== "" ? (
                                                <MyComponentWithIconProps
                                                    statusImage={VisibilityIcon}
                                                    color="primary"
                                                    fontSize="medium"
                                                    title="View"
                                                    onClick={(e) => onClickViewPreviewMethod(e, fileName)}
                                                />
                                            ) : (
                                                <FormHelperText error>
                                                    {formErrors["imageError"] ? formErrors["imageError"] : ""}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    sx={{ justifyContent: "flex-end" }}
                                >
                                    <ButtonCompo
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        name={"Add Image"}
                                        onClick={onAddImageMethod}
                                    />
                                </Grid>
                            </Grid>
                        </>

                    </DialogContent>
                </BootstrapDialog>
            </Paper>
        </>
    );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AssetsTrackingPopUp);
