import endpoint from "../../config/endpoints";

export const categoryJson = {
  formPath: "/category-form",
  apiBaseURL: endpoint.category,
  changeExcel: endpoint.exportData,
  screenTitle: "Category",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Category",
  showExcelDownload: true,
  excelFileName: "Category",
  excelUrl: "category-data" + '?sort={"sortOrder": "ASC"}',
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "categoryName",
      isChecked: true,
    },
    {
      columnDisplayName: "Sub Category Count",
      columnKeyName: "subCategoryCount",
      isChecked: true,
    },
    {
      columnDisplayName: "Product Count",
      columnKeyName: "productCount",
      isChecked: true,
    },
    {
      columnDisplayName: "Sort Order Number",
      columnKeyName: "sortOrder",
      isChecked: true,
    },
    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "name",
      isMandatory: true,
      uniqueValidation: true,
    },
    {
      label: "Short Name",
      controlType: "textfield",
      placeHolder: "Short Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "shortName",
      isMandatory: true,
    },
    {
      label: "Sort Order Number",
      controlType: "textfield",
      placeHolder: "Sort Order Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sortOrder",
      isMandatory: true,
    },
  ],
};
