import endpoint from "../../config/endpoints";

export const OtherTaskJson = {
    showSaveNextBtn: true,
    screenTitle: "Other Task",
    formPath: "/other-task-form",
    apiBaseURL: endpoint.otherTask,
    showTitle: true,
    showCancel: false,
    showSaveBtn: true,
    showAddButton: true,

    fieldMeta: [

        {
            label: "Name",
            controlType: "textfield",
            placeHolder: "Name",
            md: 5,
            lg: 5,
            sm: 5,
            xs: 12,
            autoFocus: true,
            dataKey: "name",
            isMandatory: true,
            // uniqueValidation: true,
        },
    ],

};
