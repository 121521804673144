import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { NoOfStaffJson } from "../../../DynamicFormsJson/MastersJSON/noOfStaff";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
class NoOfStaffForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
      },
    };
  }

  render() {
    return (
      <>
        <DynamicForm
          formPath={NoOfStaffJson.formPath}
          screenTitle={NoOfStaffJson.screenTitle}
          fieldMeta={NoOfStaffJson.fieldMeta}
          apiBaseURL={NoOfStaffJson.apiBaseURL}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({

});
const mapDispatchToProps = {
  showLoador,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(NoOfStaffForm);
