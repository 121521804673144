import {
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import { TextFieldCompo } from "../../../components/Comman/TextFieldCompo";
import { withRouter } from "../../../components/withRouter";
import { redColor, titleColor } from "../../../config/ColorObj";
import endpoint from "../../../config/endpoints";
import {
  noInternetMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../../config/messageconstant";
import { getStockStackHolders } from "../../../Slice/StackHolder.slice";
import { apiGet, apiPost } from "../../../utils/api_service";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
class BankForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adminFlag: false,
      stockHolder: "",
      ifscCode: "",
      state: "",
      city: "",
      address: "",
      accNo: "",
      bankName: "",
      accType: 1,
      formError: {},
      dynamicMasterData: {
        stakeholdersDetail: this.props.stakeholdersList.stockStakeHolders,
      },
    };
  }

  async componentDidMount() {
    const { getStockStackHolders, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      if (this.props.params.id) {
        showLoador({ loador: true });
        apiGet({
          url: endpoint.bankDetails + "/" + this.props.params.id,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.setState({
              stockHolder: data.data.stockStakeHolderId,
              bankName: data.data.bankName,
              state: data.data.state,
              city: data.data.city,
              address: data.data.address,
              accNo: data.data.accNo,
              ifscCode: data.data.ifscCode,
              accType: data.data.accType,
            });
          }
        });
      }
      const userTypeId = localStorage.getItem("userTypeId");
      if (userTypeId == 1 || userTypeId == 6 ) {
        this.setState({
          adminFlag: true,
        });
        showLoador({ loador: true });
        await getStockStackHolders().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  onCancel = () => {
    this.props.navigate(-1);
  };
  onSubmitData = () => {
    if (this.checkValidation()) {
      const { showNotification, showLoador } = this.props;
      const {
        stockHolder,
        ifscCode,
        state,
        city,
        address,
        accNo,
        bankName,
        accType,
      } = this.state;
      let bankObjectToSave = "";
      bankObjectToSave = {
        stockStakeHolderId: stockHolder,
        bankName: bankName,
        state: state,
        city: city,
        address: address,
        accNo: accNo,
        ifscCode: ifscCode,
        accType: accType,
      };
      if (this.props.params.id) {
        bankObjectToSave = {
          id: this.props.params.id,
          stockStakeHolderId: stockHolder,
          bankName: bankName,
          state: state,
          city: city,
          address: address,
          accNo: accNo,
          ifscCode: ifscCode,
          accType: accType,
        };
      }
      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          if (navigator.onLine) {
            showLoador({ loador: true });
            apiPost({
              url: endpoint.bankDetails,
              postBody: bankObjectToSave,
            }).then(({ success }) => {
              if (success) {
                showLoador({ loador: false });
                showNotification({
                  msg: savemsg,
                });
                this.props.navigate(-1);
              } else {
                showNotification({
                  msg: serverMsg,
                  severity: "error",
                });
                showLoador({ loador: false });
              }
            });
          } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
          }
        }
      });
    }
  };
  onAutocompleteChange = (name, newValue) => {
    if (name == "stockHolder") {
      this.setState({
        stockHolder: newValue,
      });
    }
  };

  changeHandler = async (e) => {
    const { name, value } = e.target;
    if (name === "ifscCode") {
      this.setState({
        ifscCode: value,
      });
      const response = await fetch("https://ifsc.razorpay.com/" + value);
      const dataOfResponse = await response.json();
      if (Object.keys(dataOfResponse).length !== 0) {
        console.log(dataOfResponse);
        this.setState({
          state: dataOfResponse.STATE,
          city: dataOfResponse.CITY,
          address: dataOfResponse.ADDRESS,
          bankName: dataOfResponse.BANK,
        });
      }
    }

    if (name === "state") {
      this.setState({
        state: value,
      });
    }
    if (name === "city") {
      this.setState({
        city: value,
      });
    }
    if (name === "address") {
      this.setState({
        address: value,
      });
    }
    if (name === "accNo") {
      this.setState({
        accNo: value,
      });
    }
    if (name === "bankName") {
      this.setState({
        bankName: value,
      });
    }
  };

  handleChange = (event) => {
    this.setState({
      accType: event.target.value,
    });
  };

  checkValidation = () => {
    const { stockHolder, ifscCode, state, city, address, accNo, bankName } =
      this.state;
    let formErrors = {};
    let formIsValid = true;

    if (stockHolder === "" || stockHolder === null) {
      formIsValid = false;
      formErrors["stockHolderError"] = "Please Select Stock Holder";
    }

    if (ifscCode === "" || ifscCode === null) {
      formIsValid = false;
      formErrors["ifscCodeError"] = "Please Enter IFSC Code";
    }
    // else if (ifscCode !== ifscCodeRegex) {
    //   formIsValid = false;
    //   formErrors["ifscCodeError"] = "Please Enter IFSC Code";
    // }

    if (state === "" || state === null) {
      formIsValid = false;
      formErrors["stateError"] = "Please State";
    }

    if (city === "" || city === null) {
      formIsValid = false;
      formErrors["cityError"] = "Please Enter City";
    }

    if (address === "" || address === null) {
      formIsValid = false;
      formErrors["addressError"] = "Please Enter Address";
    }

    if (accNo === "" || accNo === null) {
      formIsValid = false;
      formErrors["accNoError"] = "Please Account Number";
    }

    if (bankName === "" || bankName === null) {
      formIsValid = false;
      formErrors["bankNameError"] = "Please Enter Bank Name";
    }
    this.setState({
      formError: formErrors,
    });
    return formIsValid;
  };

  render() {
    const {
      formError,
      adminFlag,
      stockHolder,
      ifscCode,
      state,
      city,
      address,
      accNo,
      bankName,
      accType,
    } = this.state;
    const { stakeholdersList } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            screenTitle={"Bank Details"}
            showTable={false}
            showBackButton={true}
            showAddButton={false}
            onCancel={this.onCancel}
          />
          <br />
          {adminFlag ? (
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={6}
                xs={6}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} md={6} sm={6}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label="*"
                  />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="Stock Holder Type"
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <AutoComplete
                    keyColName={"id"}
                    name={"stockHolder"}
                    value={stockHolder}
                    options={stakeholdersList.stockStakeHolders}
                    placeHolder="Stock Holder"
                    onChange={this.onAutocompleteChange}
                    isError={formError.stockHolderError ? true : false}
                    errorText={
                      formError.stockHolderError
                        ? formError.stockHolderError
                        : " "
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                item
                sm={6}
                xs={6}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} md={5} sm={5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label="* "
                  />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="IFSC Code :"
                  />
                </Grid>

                <Grid item xs={12} md={6} sm={6}>
                  <TextFieldCompo
                    placeHolder={"IFSC Code"}
                    color="primary"
                    name="ifscCode"
                    value={ifscCode}
                    fullWidth
                    onChange={this.changeHandler}
                    errorText={
                      formError["ifscCodeError"]
                        ? formError["ifscCodeError"]
                        : ""
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              sm={6}
              xs={6}
              md={6}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} md={6} sm={6}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="* "
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="IFSC Code :"
                />
              </Grid>

              <Grid item xs={12} md={6} sm={6}>
                <TextFieldCompo
                  placeHolder={"IFSC Code"}
                  color="primary"
                  name="ifscCode"
                  value={ifscCode}
                  fullWidth
                  onChange={this.changeHandler}
                  errorText={
                    formError["ifscCodeError"] ? formError["ifscCodeError"] : ""
                  }
                />
              </Grid>
            </Grid>
          )}

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-evenly"
          >
            <Grid
              item
              sm={6}
              xs={6}
              md={6}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} md={6} sm={6}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="*"
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="State"
                />
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <TextFieldCompo
                  placeHolder={"State"}
                  color="primary"
                  name="state"
                  value={state}
                  fullWidth
                  onChange={this.changeHandler}
                  errorText={
                    formError["stateError"] ? formError["stateError"] : ""
                  }
                />
              </Grid>
            </Grid>

            <Grid
              item
              sm={6}
              xs={6}
              md={6}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} md={5} sm={5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="* "
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="City :"
                />
              </Grid>

              <Grid item xs={12} md={6} sm={6}>
                <TextFieldCompo
                  placeHolder={"City"}
                  color="primary"
                  name="city"
                  value={city}
                  fullWidth
                  onChange={this.changeHandler}
                  errorText={
                    formError["cityError"] ? formError["cityError"] : ""
                  }
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12} sm={12} md={12} lg={12}>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label="*"
              />
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: titleColor }}
                label="Address:"
              />
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={9}>
              <TextFieldCompo
                placeHolder={"Address"}
                color="primary"
                name="address"
                value={address}
                fullWidth
                multiline
                onChange={this.changeHandler}
                errorText={
                  formError["addressError"] ? formError["addressError"] : ""
                }
              />
            </Grid>
          </Grid>

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-evenly"
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} md={3} sm={3}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="*"
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="Bank Name"
                />
              </Grid>
              <Grid item xs={12} md={9} sm={9}>
                <TextFieldCompo
                  placeHolder={"Enter Bank Name"}
                  color="primary"
                  name="bankName"
                  value={bankName}
                  fullWidth
                  onChange={this.changeHandler}
                  errorText={
                    formError["bankNameError"] ? formError["bankNameError"] : ""
                  }
                />
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} md={3} sm={3}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="*"
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="Account Number"
                />
              </Grid>
              <Grid item xs={12} md={9} sm={9}>
                <TextFieldCompo
                  placeHolder={"Account Number"}
                  color="primary"
                  name="accNo"
                  value={accNo}
                  fullWidth
                  onChange={this.changeHandler}
                  maxLength={10}
                  type="number"
                  errorText={
                    formError["accNoError"] ? formError["accNoError"] : ""
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sm={6}
            xs={6}
            md={6}
            lg={6}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} md={6} sm={6}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label="* "
              />
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: titleColor }}
                label="Account Type :"
              />
            </Grid>

            <Grid item xs={12} md={6} sm={6}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="accType"
                value={accType}
                onChange={this.handleChange}
                defaultValue={accType}
              >
                <Grid container direction="row" spacing={2}>
                  <Grid item>
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Saving"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label="Current"
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </Grid>
          </Grid>
          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="right"
          >
            <ButtonCompo
              size="medium"
              type="Submit"
              variant="contained"
              name="Submit"
              onClick={this.onSubmitData}
            />
          </Grid>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stakeholdersList: state.stackholder,
});
const mapDispatchToProps = {
  getStockStackHolders,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BankForm);
