import { Grid, Paper } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { redColor, titleColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import { addressMsg, cinNumberMsg, contactNoMsg, emailIdMsg, fdiFssiNumberMsg, gstNumberMsg, linkMsg, nameMsg, noInternetMsg, panNumberMsg, saveFailedMsg, serverMsg, updateData, updateMsg } from "../../config/messageconstant";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";

const VideoLinkStatic = ({ showLoador, showNotification }) => {
    const [link, setLink] = useState("");
    const [formErrors, setFormErrors] = React.useState({});


    const changeHandler = (e) => {
        const { name, value } = e.target;
        console.log(e)
        if (name === "link") {
            setLink(value);
        }
    };

    useEffect(() => {
        if (navigator.onLine) {
            showLoador({ loador: true });
            apiGet({
                url: endpoint.setting + "/by-key?key=video_link",
            }).then((data) => {
                showLoador({ loador: false });
                if (data.data.error) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    if (data !== null && Object.keys(data.data.data).length !== 0) {
                        setLink(data.data.data.settingValue === null
                            ? ""
                            : data.data.data.settingValue)
                    }
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }, []);

    const checkValidation = () => {
        let formErrors = {};
        let formIsValid = true;

        if (link === "") {
            formIsValid = false;
            formErrors["linkError"] = linkMsg;
        }
        setFormErrors(formErrors);
        return formIsValid;
    };


    const onSave = () => {
        if (navigator.onLine) {
            if (checkValidation()) {
                swal({
                    title: "Are you sure?",
                    text: updateData,
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((willDelete) => {
                    if (willDelete) {
                        showLoador({ loador: true });
                        apiPost({
                            url: endpoint.setting + "/update-setting-value?key=video_link&value=" + link,
                        }).then(({ data }) => {
                            showLoador({ loador: false });
                            if (data.data.error) {
                                showNotification({ msg: serverMsg, severity: "error" });
                            } else {
                                window.location.reload();
                                showNotification({ msg: updateMsg });
                            }
                        });
                    }
                });
            }
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    return (
        <>
            <Paper
                sx={{
                    p: 2,
                    borderRadius: 2,
                }}
                elevation={0}
            >
                <LandingScreenHeader
                    screenTitle={"Video Link"}
                    showTable={false}
                    showBackButton={false}
                    showAddButton={false}
                />
                <div>
                    <br></br>
                    <>
                        <Grid
                            container
                            item
                            sm={12}
                            xs={12}
                            md={12}
                            lg={12}
                            justifyContent="space-evenly"
                            alignItems="center"
                        >
                            <Grid item xs={12} md={12} sm={12} lg={12}>
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: "red" }}
                                    label="* "
                                />
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: titleColor }}
                                    label="Link :"
                                />
                            </Grid>
                            <Grid item xs={12} md={12} sm={12} lg={12}>
                                <TextFieldCompo
                                    placeHolder={"Link"}
                                    color="primary"
                                    name="link"
                                    value={link}
                                    fullWidth
                                    multiline
                                    onChange={changeHandler}
                                    errorText={
                                        formErrors["linkError"]
                                            ? formErrors["linkError"]
                                            : ""
                                    }
                                />
                            </Grid>
                        </Grid>

                        <DialogActions>
                            <Grid item sx={{ mr: 3 }} xs={12} sm={12} md={1}>
                                <ButtonCompo
                                    size="medium"
                                    onClick={onSave}
                                    variant="contained"
                                    name="Submit"
                                />
                            </Grid>
                        </DialogActions>
                    </>
                </div>
            </Paper>
        </>
    );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = { showNotification, showLoador };
export default connect(mapStateToProps, mapDispatchToProps)(VideoLinkStatic);
