import { Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { OrderClosingJson } from "../../DynamicFormsJson/Transaction/orderClosing";
import { getAreaByUserType } from "../../Slice/area.slice";
import { getBeatByArea, getBeatByUserType } from "../../Slice/beat.slice";
import { compose } from "@reduxjs/toolkit";
import {
  getOutletByBeatIds,
  getOutletByUserType,
} from "../../Slice/outlet.slice";
import {
  getOutletOrderDetailLatest,
  getOutletOrderDetailPost,
  setOutletOrderDetail,
  getOutletOrderDetailBySalesPerson,
} from "../../Slice/outletOrderDetail.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import CheckBoxSelectionTable from "../../components/Comman/RT/CheckBoxSelectionTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicMainScreen";
import endpoint from "../../config/endpoints";
import { getSalesExecutiveByBeatIds } from "../../Slice/salesExecutive.slice";

import {
  checkedListEmptyMsg,
  noInternetMsg,
  saveAndCloseWarningMsg,
  savemsg,
  serverMsg,
} from "../../config/messageconstant";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import OrderClosingTable from "./OrderClosingTable";
import { withRouter } from "../../components/withRouter";

class OrderClosing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      searchBy: "0",
      flag: 1,
      formData: {},
      mainData: {},
      fieldDataForModule: {},
      dynamicMasterData: {
        areaDetail: this.props.areaList.area,
        beatDetail: this.props.beatList.beat,
        outletDetail: this.props.outletList.outletByUserType,
      },
      showCheckRecord: false,
      selection: [],
    };
  }
  getFilteredTableData = (tableData) => {
    const { searchValue } = this.state;

    return tableData.filter((currentRow) => {
      let isValid = false;
      let productName = currentRow.productName;
      if (
        productName &&
        productName.toString().toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };
  async componentDidMount() {
    await this.getData();

    if (navigator.onLine) {

      const moduleObjData = localStorage.getItem("moduleObj");

      this.setState({
        fieldDataForModule:
          moduleObjData != null ? JSON.parse(moduleObjData) : {},
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  getData = async () => {
    const {
      getOutletOrderDetailLatest,
      getBeatByUserType,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getOutletOrderDetailLatest().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getBeatByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  componentWillReceiveProps(nextProps) {
    if (this.props.beatList !== nextProps.beatList) {
      if (
        this.props.beatList?.beatByUserType !==
        nextProps.beatList?.beatByUserType
      ) {
        let formData = {
          beat:
            nextProps.beatList?.beatByUserType.length != 0
              ? [nextProps.beatList?.beatByUserType[0].id]
              : [],
        };

        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            beatDetail: nextProps.beatList?.beatByUserType,
          },
        });

        // if (this.state.flag == 1) {
        //   this.setState({
        //     formData: formData,
        //   });
        //   if (formData.beat.length != 0) {
        //     this.getListById(formData);
        //   }
        // }
      }
    }

    if (this.props.outletList !== nextProps.outletList) {
      if (this.props.outletList?.outlets !== nextProps.outletList?.outlets) {
        let formData = {
          outletIds:
            nextProps.outletList?.outlets.length != 0
              ? [nextProps.outletList?.outlets[0].id]
              : [],
        };

        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            outletDetail: nextProps.outletList?.outlets,
          },
        });
        // if (this.state.flag == 1) {
        //   this.setState({
        //     flag: 2,
        //     formData: formData,
        //   });
        //   if (formData.outletIds.length != 0) {
        //     this.onSearchList({
        //       ...formData,
        //     });
        //   }
        // }
      }
    }
    if (this.props.salesExecutive !== nextProps.salesExecutive) {
      if (
        this.props.salesExecutive?.salesExecutive !==
        nextProps.salesExecutive?.salesExecutive
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            salesPersonDetail: nextProps.salesExecutive?.salesExecutive,
          },
        });
      }
    }
  }

  getListById = async (data) => {
    const {
      getOutletByBeatIds,
      getSalesExecutiveByBeatIds,
      showLoador,
      showNotification,
    } = this.props;
    if (data.flag) {
      this.setState({
        searchBy: data.flag,
        formData: {
          flag: data.flag,
          beat: [],
          outletIds: [],
          salesPersonIds: [],
        },
      });
    }
    this.setState({
      mainData: { ...this.state.mainData, ...data },
    });
    if (navigator.onLine) {
      if (data.beat && data.beat != "") {
        if (this.state.searchBy == 0) {
          showLoador({ loador: true });
          await getOutletByBeatIds({ beatIds: data.beat.join() }).then(
            ({ success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              }
            }
          );
        } else {
          showLoador({ loador: true });
          await getSalesExecutiveByBeatIds({ beatIds: data.beat.join() }).then(
            ({ success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              }
            }
          );
        }
      }
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  onSearchList = (data) => {
    const {
      getOutletOrderDetailPost,
      getOutletOrderDetailBySalesPerson,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (data.flag == 0) {
        let outletOrderDetailData = {
          outletIds: data.outletIds,
          fromDate: data.fromdate,
          toDate: data.todate,
        };

        showLoador({ loador: true });
        getOutletOrderDetailPost({ outletData: outletOrderDetailData }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            } else {
            }
          }
        );
      } else {
        showLoador({ loador: true });
        getOutletOrderDetailBySalesPerson({
          salesTeamIds: data.salesPersonIds,
          fromDate: data.fromdate,
          toDate: data.todate,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  handleCloses = () => {
    const { showCheckRecord } = this.state;
    this.setState({
      showCheckRecord: !showCheckRecord,
    });
  };

  onSearchFooter = () => {
    const { showCheckRecord } = this.state;
    const { outletOrderDetail, showNotification } = this.props;
    if (
      outletOrderDetail.outletOrderDetail.filter((row) => row.checked)
        .length !== 0
    ) {
      this.setState({
        showCheckRecord: !showCheckRecord,
      });
    } else {
      showNotification({
        msg: checkedListEmptyMsg,
        severity: "error",
      });
    }
  };
  saveOrderClosingConfigtation = () => {
    const { showNotification, showLoador } = this.props;
    const { selection } = this.state;
    swal({
      title: "Are you sure?",
      text: saveAndCloseWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (navigator.onLine) {
          let jsonData = {
            outletOrderDetailIds: selection,
            isBillGenerate: "2",
          };
          showLoador({ loador: true });
          apiPost({
            url: endpoint.outletOrderDetail + "/update-is-bill-generate-status",
            postBody: jsonData,
          }).then(({ success }) => {
            if (success) {
              this.handleCloses();
              showLoador({ loador: false });
              showNotification({
                msg: savemsg,
              });
              this.onSearchList(this.state.mainData);
            } else {
              showNotification({
                msg: serverMsg,
                severity: "error",
              });
              showLoador({ loador: false });
            }
          });
        } else {
          showNotification({ msg: noInternetMsg, severity: "error" });
        }
      }
    });
  };

  getSelectedList = (e) => {
    this.setState({
      selection: e,
    });
    const rowList = this.props.outletOrderDetail.outletOrderDetail.map(
      (rowData) => {
        const filterData = e.filter((id) => rowData.id == id);
        return { ...rowData, checked: filterData.length != 0 ? true : false };
      }
    );
    this.props.setOutletOrderDetail({ row: rowList });
  };
  setList = () => {
    this.props.setOutletOrderDetail({ row: [] });
  };

  render() {
    const {
      dynamicMasterData,
      searchBy,
      formData,
      showCheckRecord,
      selection,
      fieldDataForModule,
    } = this.state;
    const { outletOrderDetail, setOutletOrderDetail } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            callApi={false}
            showPdfDownload={false}
            showExcelDownload={false}
            showAddButton={false}
            getListById={this.getListById}
            dynamicMasterData={dynamicMasterData}
            showTitle={OrderClosingJson.showTitle}
            screenTitle={OrderClosingJson.screenTitle}
            searchList={
              searchBy == 0
                ? OrderClosingJson.fieldMeta
                : OrderClosingJson.fieldMetaBySalsPerson
            }
            formData={formData}
            searchTrue={false}
            onFilterData={this.onSearch}
            apiBaseURL={OrderClosingJson.apiBaseURL}
            saveBtnText={"Search"}
            onSearchData={this.onSearchList}
            padding={true}
            paddingTop={true}
            getTableData={this.getData}
            showSearchBox={false}
            showBackToList={true}
          />
          {outletOrderDetail.outletOrderDetail.length != 0 && (
            <>
              <CheckBoxSelectionTable
                selection={selection}
                onSelectionChange={this.getSelectedList}
                columns={[
                  {
                    title: "Sr.No.",
                    name: "index",
                    positionCenter: true,
                    showInscreen: true,
                    align: "center",
                  },
                  {
                    title: "Outlet Name",
                    name: "firmName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                    canSearch: true,
                  },
                  {
                    title: "Product Name",
                    name: "productName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                    canSearch: true,
                  },
                  {
                    title: "Order No",
                    name: "orderNo",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                    canSearch: true,
                  },
                  {
                    title: "Order Date",
                    name: "orderDate",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                    canSearch: true,
                  },
                  {
                    title: "Order Quantity",
                    name: "orderQty",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                    canSearch: true,
                  },
                  {
                    title: "Taxable Amt",
                    name: "taxableAmt",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                    canSearch: true,
                  },
                  {
                    title: "CGST",
                    name: "cgstAmt",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                    canSearch: true,
                  },
                  {
                    title: "SGST",
                    name: "sgstAmt",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                    canSearch: true,
                  },

                  {
                    title: "Total",
                    name: "total",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                    canSearch: true,
                  },
                ]}
                rows={this.getFilteredTableData(
                  outletOrderDetail.outletOrderDetail
                )}
                setList={setOutletOrderDetail}
                isActionColActive={false}
              />
              <br />
              {fieldDataForModule.editReject == 1 ? (

                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="right"
                >
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name={"Proceed"}
                    onClick={this.onSearchFooter}
                  />
                  &nbsp;
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name={"Cancel"}
                    onClick={() => this.props.navigate(-1)}
                  />
                </Grid>) : ("")}
            </>
          )}

          {showCheckRecord && (
            <>
              <OrderClosingTable
                handleCloses={this.handleCloses}
                open={open}
                columns={[
                  {
                    title: "Sr.No.",
                    name: "index",
                    positionCenter: true,
                    showInscreen: true,
                    align: "center",
                  },
                  {
                    title: "Outlet Name",
                    name: "firmName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                    canSearch: true,
                  },
                  {
                    title: "Order No",
                    name: "orderNo",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                    canSearch: true,
                  },
                  {
                    title: "Order Date",
                    name: "orderDate",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                    canSearch: true,
                  },
                  {
                    title: "Order Quantity",
                    name: "orderQty",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                    canSearch: true,
                  },
                  {
                    title: "Taxable Amt",
                    name: "taxableAmt",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                    canSearch: true,
                  },
                  {
                    title: "CGST",
                    name: "cgstAmt",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                    canSearch: true,
                  },
                  {
                    title: "SGST",
                    name: "sgstAmt",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                    canSearch: true,
                  },
                  {
                    title: "Total",
                    name: "total",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                    canSearch: true,
                  },
                ]}
                onFormSave={this.saveOrderClosingConfigtation}
                rows={outletOrderDetail.outletOrderDetail.filter(
                  (row) => row.checked
                )}
              />
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  areaList: state.area,
  outletList: state.outlet,
  beatList: state.beat,
  outletOrderDetail: state.outletOrderDetail,
  salesExecutive: state.salesExecutive,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getAreaByUserType,
  getBeatByArea,
  getOutletByBeatIds,
  getOutletByUserType,
  setOutletOrderDetail,
  getOutletOrderDetailPost,
  getBeatByUserType,
  getOutletOrderDetailBySalesPerson,
  getSalesExecutiveByBeatIds,
  getOutletOrderDetailLatest,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OrderClosing);
