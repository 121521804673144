import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg } from "../../config/messageconstant";
import { orderHistoryEditJSON } from "../../DynamicFormsJson/MastersJSON/orderHistoryEdit";
import { getBillHeaderId } from "../../Slice/orderBillList.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
class ShowMyBillView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DataToSet: [],
            billingData: [],
        };
        this.columns = [
            {
                title: "Sr.No.",
                name: "index",
                align: "center",
            },
            {
                title: "Product Name",
                name: "name",
            },
            {
                title: "UOM",
                name: "stakeHolderUom",
                align: "center",
            },
            {
                title: "Rate",
                name: "rate",
                align: "right",
            },
            {
                title: "Bill Quantity",
                name: "billQty",
                align: "right",
            },
            {
                title: "Total Amount",
                name: "totalAmt",
                align: "right",
            },
        ];
    }

    async componentDidMount() {
        const { getBillHeaderId, showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            if (this.props.params.id) {
                showLoador({ loador: true });
                await getBillHeaderId({
                    billHeaderId: this.props.params.id,
                }).then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                    } else {
                        this.setState({
                            DataToSet: [
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Bill Number",
                                    value: response.billNo !== null && response.billNo !== null
                                        ? response.billNo
                                        : "",

                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Bill Date",
                                    value: response.billDate !== null && response.billDate !== null
                                        ? response.billDate
                                        : "",
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Taxable Amount",
                                    value:
                                        response.taxableAmt !== null && response.taxableAmt !== null
                                            ? response.taxableAmt
                                            : "",
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Total Amount",
                                    value:
                                        Math.round(response.totalAmt).toFixed(2),
                                },
                            ],
                            billingData:
                                response.stockStakeHolderBillDetails.length !== 0
                                    ? response.stockStakeHolderBillDetails
                                    : [],
                        });
                    }
                });
            }
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }
    render() {
        const { dynamicMasterData, billingData, DataToSet } = this.state;
        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 0,
                    }}
                    elevation={0}
                >
                    <DynamicFormWithoutSave
                        showBackToList={true}
                        DataToSet={DataToSet}
                        dynamicMasterData={dynamicMasterData}
                        showTitle={true}
                        screenTitle={"Show My Bill List View"}
                        fieldMeta={[]}
                        apiBaseURL={orderHistoryEditJSON.apiBaseURL}
                        showSaveNextBtn={[]}
                        saveBtnText={"Search"}
                        showSaveBtnMain={false}
                    />

                    <RTTable
                        columns={this.columns}
                        tableData={billingData}
                        isActionColActive={false}
                        showPegination={false}
                    />
                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getBillHeaderId,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ShowMyBillView);
