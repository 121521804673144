import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import DynamicTabwiseTable from "../../components/dynamicscreens/DynamicTabwiseTable";
import { withRouter } from "../../components/withRouter";
import { assignRateConfigurationToStakeholderJson } from "../../DynamicFormsJson/Transaction/AssignRateConfigurationToStakeholder";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import {
  serverMsg,
  deletemsg,
  statusmsg,
  changeActive,
  deleteFailedMsg,
} from "../../config/messageconstant";
import swal from "sweetalert";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import {
  getAssignRateConfigurationToStakeholderCurrentList,
  getAssignRateConfigurationToStakeholderFutureList,
  getAssignRateConfigurationToStakeholderSearchList,
} from "../../Slice/assignRateConfigurationToStakeholder.slice";
import { apiDelete, apiGet } from "../../utils/api_service";
import endpoint from "../../config/endpoints";

import { getStakeHolderName } from "../../Slice/StackHolder.slice";
class AssignRateConfigurationToStakeholderTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stackHolderId: "",

      dynamicMasterDataToSearch: {
        stakeHolderTypeDetail:
          this.props.stakeHolderTypeList?.stockStakeHolderType,
        categoryDetail: this.props.categoryList?.category,
        stakeHolderName: this.props.stakeholderList?.stackholderName,
      },
      dynamicMasterData: {
        currentList:
          this.props.assignRateConfigurationToStakeholderCurrentList
            ?.assignRateCurrentList,
        futureList:
          this.props.assignRateConfigurationToStakeholderCurrentList
            ?.assignRateFutureList,
        priviousList:
          this.props.assignRateConfigurationToStakeholderCurrentList
            ?.assignRatePreviousList,
      },
      tabList: [
        {
          label: "Current Rate Configuration",
          allyProps: 0,
          columns: [
            {
              name: "index",
              title: "Sr. No.",
              width: "10%",
              alignCenter: "center",
              showInscreen: true,
            },
            {
              title: "Stock Holder Name",
              name: "ownerName",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Stock Holder Type",
              name: "stockStakeHolderType",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Category",
              name: "category",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Configuration Name",
              name: "productRateConfiguration",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Publish Date",
              name: "publishDate",
              alignCenter: "center",
              showInscreen: true,
              canSearch: true,
              width:100
            },
            {
              title: "Status",
              name: "isActive",
              alignCenter: "center",
              showInscreen: true,
            },

            {
              title: "Action",
              name: "action",
              alignCenter: "center",
              showInscreen: true,
              width:100
            },
          ],
          isActionColActive: true,
          value: "currentList",
        },
        {
          label: "Future Rate Configuration",
          allyProps: 1,
          columns: [
            {
              name: "index",
              title: "Sr. No.",
              width: "10%",
              alignCenter: "center",
              showInscreen: true,
            },
            {
              title: "Stock Holder Name",
              name: "ownerName",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Stock Holder Type",
              name: "stockStakeHolderType",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Category",
              name: "category",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Configuration Name",
              name: "productRateConfiguration",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Publish Date",
              name: "publishDate",
              alignCenter: "center",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Status",
              name: "isActive",
              alignCenter: "center",
              showInscreen: true,
            },

            {
              title: "Action",
              name: "action",
              alignCenter: "center",
              showInscreen: true,
              width:100
            },
          ],
          isActionColActive: true,
          value: "futureList",
        },
        {
          label: "Previous Rate Configuration",
          allyProps: 2,

          columns: [
            {
              name: "index",
              title: "Sr. No.",
              width: "10%",
              alignCenter: "center",
              showInscreen: true,
            },
            {
              title: "Stock Holder Name",
              name: "ownerName",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Stock Holder Type",
              name: "stockStakeHolderType",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Category",
              name: "category",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Configuration Name",
              name: "productRateConfiguration",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Published Date",
              name: "publishDate",
              alignCenter: "center",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Status",
              name: "isActive",
              alignCenter: "center",
              showInscreen: true,
            },
            {
              title: "Action",
              name: "action",
              alignCenter: "center",
              showInscreen: true,
              width:100
            },
          ],
          isActionColActive: true,
          value: "priviousList",
          fieldMeta:
            assignRateConfigurationToStakeholderJson.fieldMetaForSearch,
        },
      ],
    };
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.assignRateConfigurationToStakeholderCurrentList !==
      nextProps.assignRateConfigurationToStakeholderCurrentList
    ) {
      if (
        this.props.assignRateConfigurationToStakeholderCurrentList
          ?.assignRateCurrentList !==
        nextProps.assignRateConfigurationToStakeholderCurrentList
          ?.assignRateCurrentList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            currentList:
              nextProps.assignRateConfigurationToStakeholderCurrentList
                ?.assignRateCurrentList,
          },
        });
      }
      if (
        this.props.assignRateConfigurationToStakeholderCurrentList
          ?.assignRateFutureList !==
        nextProps.assignRateConfigurationToStakeholderCurrentList
          ?.assignRateFutureList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            futureList:
              nextProps.assignRateConfigurationToStakeholderCurrentList
                ?.assignRateFutureList,
          },
        });
      }
      if (
        this.props.assignRateConfigurationToStakeholderCurrentList
          ?.assignRatePreviousList !==
        nextProps.assignRateConfigurationToStakeholderCurrentList
          ?.assignRatePreviousList
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            priviousList:
              nextProps.assignRateConfigurationToStakeholderCurrentList
                ?.assignRatePreviousList,
          },
        });
      }
    }

    if (this.props.stakeHolderTypeList !== nextProps.stakeHolderTypeList) {
      if (
        this.props.stakeHolderTypeList?.stockStakeHolderType !==
        nextProps.stakeHolderTypeList?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterDataToSearch: {
            ...this.state.dynamicMasterDataToSearch,
            stakeHolderTypeDetail:
              nextProps.stakeHolderTypeList?.stockStakeHolderType,
          },
        });
      }
    }
    if (this.props.stakeholderList !== nextProps.stakeholderList) {
      if (
        this.props.stakeholderList?.stackholderName !==
        nextProps.stakeholderList?.stackholderName
      ) {
        this.setState({
          dynamicMasterDataToSearch: {
            ...this.state.dynamicMasterDataToSearch,
            stakeHolderName: nextProps.stakeholderList?.stackholderName,
          },
        });
      }
    }
  }
  getData = async () => {
    const {
      getStockStakeHolderType,
      getAssignRateConfigurationToStakeholderCurrentList,
      getAssignRateConfigurationToStakeholderFutureList,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getStockStakeHolderType({ userTypeIds: 2 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      await getAssignRateConfigurationToStakeholderCurrentList().then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        }
      );
      showLoador({ loador: true });
      await getAssignRateConfigurationToStakeholderFutureList().then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        }
      );
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  componentDidMount() {
    this.getData();
  }
  getListById = async (data) => {
    const { getStakeHolderName, showLoador, showNotification } = this.props;
    if (data.stockStakeHolderType && data.stockStakeHolderType) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        await getStakeHolderName({
          StockStakeHolderTypeIds: data.stockStakeHolderType.join(),
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };

  onSave = async (rowData) => {
    if (rowData.stackholder) {
      this.setState({
        stackHolderId: rowData.stackholder,
      });
      this.getListByStakeHolderId(rowData.stackholder);
    }
  };
  getListByStakeHolderId = async (stockStakeHolderIds) => {
    const {
      getAssignRateConfigurationToStakeholderSearchList,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getAssignRateConfigurationToStakeholderSearchList({
        stockStakeHolderIds: stockStakeHolderIds,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  rowDelete = (rowData) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiDelete({
        url: endpoint.assignRateConfigurationToStakeholder + "/" + rowData.id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: deletemsg });
          this.getData();
          if (this.state.stackHolderId != "") {
            this.getListByStakeHolderId(this.state.stackHolderId);
          }
        } else {
          showNotification({ msg: deleteFailedMsg });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  changeStatus = (rowData) => {
    swal({
      text: changeActive,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onchangeStatus(rowData);
      }
    });
  };
  onchangeStatus = (rowData) => {
    let isActive = rowData.isActive === 0 ? 1 : 0;
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiGet({
        url:
          endpoint.assignRateConfigurationToStakeholder +
          "/update-status?id=" +
          rowData.id +
          "&status=" +
          isActive,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: statusmsg });
          this.getData();
          if (this.state.stackHolderId != "") {
            this.getListByStakeHolderId(this.state.stackHolderId);
          }
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  rowViewData = (row) => {
    this.props.navigate("/assign-rate-configuration-to-stakeholder-view/" + row.id);
  };
  onAddButtonClick = () => {
    this.props.navigate(assignRateConfigurationToStakeholderJson.formPath);
  };
  render() {
    const { tabList, dynamicMasterData, dynamicMasterDataToSearch } =
      this.state;
    return (
      <>
        <DynamicTabwiseTable
          dynamicMasterData={dynamicMasterDataToSearch}
          rowList={dynamicMasterData}
          formPath={assignRateConfigurationToStakeholderJson.formPath}
          screenTitle={assignRateConfigurationToStakeholderJson.screenTitle}
          rowDelete={this.rowDelete}
          rowStatus={this.changeStatus}
          showHeadDelete={true}
          showPdfDownload={
            assignRateConfigurationToStakeholderJson.showPdfDownload
          }
          showExcelDownload={
            assignRateConfigurationToStakeholderJson.showExcelDownload
          }
          pdfFileName={assignRateConfigurationToStakeholderJson.pdfFileName}
          excelFileName={assignRateConfigurationToStakeholderJson.excelFileName}
          showAddButton={assignRateConfigurationToStakeholderJson.showAddButton}
          tableColumnsToFilter={
            assignRateConfigurationToStakeholderJson.tableColumnsToFilter
          }
          onSave={this.onSave}
          apiBaseURL={assignRateConfigurationToStakeholderJson.apiBaseURL}
          baseIdColumn={assignRateConfigurationToStakeholderJson.baseIdColumn}
          tabList={tabList}
          onAddButtonClick={this.onAddButtonClick}
          getListById={this.getListById}
          showView={true}
          formPathView={assignRateConfigurationToStakeholderJson.formPathView}
          rowViewData={this.rowViewData}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stakeHolderTypeList: state.stockStakeHolderType,
  stakeholderList: state.stackholder,
  assignRateConfigurationToStakeholderCurrentList:
    state.assignRateConfigurationToStakeholder,
});
const mapDispatchToProps = {
  getStockStakeHolderType,
  getStakeHolderName,
  getAssignRateConfigurationToStakeholderCurrentList,
  getAssignRateConfigurationToStakeholderFutureList,
  getAssignRateConfigurationToStakeholderSearchList,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AssignRateConfigurationToStakeholderTable);
