import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg } from "../../config/messageconstant";
import { noticeMessageJSON } from "../../DynamicFormsJson/Transaction/noticeMessage";
import { getAccessRoleForLeft } from "../../Slice/stockStakeHolderType.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import endpoint from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";
class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
    };
  }

  async componentDidMount() {
    const { showLoador, showNotification } = this.props;
    const userTypeId = localStorage.getItem("userTypeId");
    if (navigator.onLine) {
      if (userTypeId == 1 || userTypeId == 6 ) {
        showLoador({ loador: true });
        apiGet({
          url: endpoint.company + "/exist-or-not",
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
          } else {
            this.setState({
              headerData: data.data,
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Company Name",
                  value:
                    data.data.company !== null &&
                    data.data.company.name !== null
                      ? data.data.company.name
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Contact Number",
                  value:
                    data.data.company !== null &&
                    data.data.company.contactNo !== null
                      ? data.data.company.contactNo
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Email Id",
                  value:
                    data.data.company !== null &&
                    data.data.company.emailId !== null
                      ? data.data.company.emailId
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Address 1",
                  value:
                    data.data.company !== null &&
                    data.data.company.address1 !== null
                      ? data.data.company.address1
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Address 2",
                  value:
                    data.data.company !== null &&
                    data.data.company.address2 !== null
                      ? data.data.company.address2
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Contact Person Number",
                  value:
                    data.data.company !== null &&
                    data.data.company.contactPersonNo !== null
                      ? data.data.company.contactPersonNo
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Contact Person Address",
                  value:
                    data.data.company !== null &&
                    data.data.company.contactPersonAddress !== null
                      ? data.data.company.contactPersonAddress
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "CIN Number",
                  value:
                    data.data.company !== null &&
                    data.data.company.cinNo !== null
                      ? data.data.company.cinNo
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "FDI FSSAI Number",
                  value:
                    data.data.company !== null &&
                    data.data.company.name !== null
                      ? data.data.company.name
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "GST Number",
                  value:
                    data.data.company !== null &&
                    data.data.company.gstNo !== null
                      ? data.data.company.gstNo
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "PAN Number",
                  value:
                    data.data.company !== null &&
                    data.data.company.panNo !== null
                      ? data.data.company.panNo
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Invoice Prefix",
                  value:
                    data.data.company !== null &&
                    data.data.company.invoicePrefix !== null
                      ? data.data.company.invoicePrefix
                      : "",
                },
              ],
            });
          }
        });
      } else {
        showLoador({ loador: true });
        apiGet({
          url:
            endpoint.stockStakeHolderType +
            "/sales-team-and-stock-stake-holder-by-user-type",
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
          } else {
            this.setState({
              headerData: data.data,
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "User Name",
                  value: data.data.userName !== null ? data.data.userName : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Firm Name",
                  value: data.data.firmName !== null ? data.data.firmName : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Stock Holder Name",
                  value: data.data.name !== null ? data.data.name : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Region",
                  value:
                    data.data.region.name !== null ? data.data.region.name : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "State",
                  value:
                    data.data.state.name !== null ? data.data.state.name : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Zone",
                  value: data.data.zoneName !== null ? data.data.zoneName : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "District",
                  value:
                    data.data.district.name !== null
                      ? data.data.district.name
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Taluka",
                  value:
                    data.data.talukaName !== null ? data.data.talukaName : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Area",
                  value: data.data.areaName !== null ? data.data.areaName : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "SPOC",
                  value:
                    data.data.contactPersonName !== null
                      ? data.data.contactPersonName
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "SPOC NO",
                  value:
                    data.data.contactPersonNo !== null
                      ? data.data.contactPersonNo
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Email",
                  value: data.data.email !== null ? data.data.email : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Bill Name",
                  value: data.data.billName !== null ? data.data.billName : "",
                },
              ],
            });
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  render() {
    const { dynamicMasterData, DataToSet } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: 1.5,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            DataToSet={DataToSet}
            dynamicMasterData={dynamicMasterData}
            showSaveBtn={noticeMessageJSON.showSaveBtn}
            showTitle={true}
            screenTitle={"User Profile"}
            showCancel={noticeMessageJSON.showCancel}
            apiBaseURL={noticeMessageJSON.apiBaseURL}
            showSaveNextBtn={noticeMessageJSON.showSaveNextBtn}
            saveBtnText={"Search"}
            showSaveBtnMain={false}
            padding={true}
            paddingTop={true}
          />
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getAccessRoleForLeft,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(UserProfile);
