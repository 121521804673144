import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { rangeJson } from "../../../DynamicFormsJson/MastersJSON/range";
import { getRange } from "../../../Slice/range.slice";

class RangeForm extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <DynamicForm
          formPath={rangeJson.formPath}
          screenTitle={rangeJson.screenTitle}
          fieldMeta={rangeJson.fieldMeta}
          apiBaseURL={rangeJson.apiBaseURL}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  rangeList: state.range,
});
const mapDispatchToProps = {
  getRange,
};
export default connect(mapStateToProps, mapDispatchToProps)(RangeForm);
