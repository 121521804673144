import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg } from "../../config/messageconstant";
import { pendingGrnListJSON } from "../../DynamicFormsJson/Transaction/pendingGrnList";
import {
  getGRNHeaderById,
  getGRNHeaderDetailsById,
} from "../../Slice/requestGRN.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
class PendingGrnView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
      pendingGrnData: [],
    };
    this.columns = [
      {
        title: "Sr.No.",
        name: "index",
        align: "center",
      },
      {
        title: "Product Name",
        name: "productName",
      },
      {
        title: "UOM",
        name: "stakeHolderUomName",
        align: "center",
      },
      {
        title: "Rate",
        name: "rate",
        align: "right",
      },
      {
        title: "Secondary Quantity",
        name: "secQty",
        align: "right",
      },
      {
        title: "Taxable Amount",
        name: "taxableAmt",
        align: "right",
      },
      {
        title: "Total Amount",
        name: "totalAmt",
        align: "right",
      },
    ];
  }

  async componentDidMount() {
    const {
      getGRNHeaderDetailsById,
      getGRNHeaderById,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (this.props.params.id) {
        showLoador({ loador: true });
        await getGRNHeaderById({
          requestGRNHeaderId: this.props.params.id,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
          } else {
            this.setState({
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Firm Name",
                  value:
                    response.fromStockStakeHolderFirmName !== null
                      ? response.fromStockStakeHolderFirmName
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Owner Name",
                  value:
                    response.fromStockStakeHolderOwnerName !== null
                      ? response.fromStockStakeHolderOwnerName
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "GRN Number",
                  value: response.grnGvnNo !== null ? response.grnGvnNo : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Date",
                  value: response.date !== null ? response.date : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Taxable Amount",
                  value:
                    response.taxableAmt !== null ? response.taxableAmt : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Total Amount",
                  value: response.totalAmt !== null ? response.totalAmt : "",
                },

                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Total Tax",
                  value:
                    response.totalAmt !== null && response.taxableAmt !== null
                      ? (+response.totalAmt - +response.taxableAmt).toFixed(2)
                      : "",
                },

                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 7,
                  label: "Remark",
                  value:
                    response.remark !== null
                      ? response.remark
                      : "",
                },
              ],
            });
          }
        });

        showLoador({ loador: true });
        await getGRNHeaderDetailsById({
          requestGRNHeaderId: this.props.params.id,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
          } else {
            this.setState({
              pendingGrnData:
                response.grnGvnDetails.length !== 0
                  ? response.grnGvnDetails
                  : [],
            });
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  render() {
    const { dynamicMasterData, pendingGrnData, DataToSet } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            DataToSet={DataToSet}
            dynamicMasterData={dynamicMasterData}
            showTitle={true}
            screenTitle={"Pending GRN List View"}
            apiBaseURL={pendingGrnListJSON.apiBaseURL}
            saveBtnText={"Search"}
            showSaveBtnMain={false}
            formPathView={pendingGrnListJSON.formPathView}
          />

          <RTTable
            columns={this.columns}
            tableData={pendingGrnData}
            isActionColActive={false}
            showPegination={false}
          />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getGRNHeaderDetailsById,
  getGRNHeaderById,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PendingGrnView);
