import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";

const initialState = {
  moduleList: [],
  isFetch: false,
  accessRole: [],
  isFetchAccessRole: false,
  accessRoleById: {},
  stockHolderList: [],
  isFetchAccessRoleById: false,
  accessRoleByUserId: [],
  isFetchAccessRoleByUserId: false,
};
let accessRole = endpoints.userAccess;
let module = endpoints.module;
const moduleSubModuleSlice = createSlice({
  name: "moduleSubModule",
  initialState,
  reducers: {
    moduleSubModuleSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        moduleList: row,
        isFetch: true,
      };
    },
    accessRoleListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        accessRole: row,
        isFetchAccessRole: true,
      };
    },
    accessRoleByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        accessRoleById: data,
        isFetchAccessRoleById: true,
      };
    },
    accessRoleByUserIdSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        accessRoleByUserId: data,
        isFetchAccessRoleByUserId: true,
      };
    },
    stockHolderNameSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        stockHolderList: row,
      };
    },

    resetUserAccessSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        accessRoleById: {},
        isFetchAccessRoleById: false,
      };
    },
  },
});

export const {
  moduleSubModuleSuccess,
  accessRoleListSuccess,
  accessRoleByIdSuccess,
  accessRoleByUserIdSuccess,
  resetUserAccessSuccess,
  stockHolderNameSuccess,
} = moduleSubModuleSlice.actions;

export default moduleSubModuleSlice.reducer;

export const getModuleAndSubModule = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: module,
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        const row = content.map((module) => {
          module = {
            ...module,
            checked: 0,
          };
          return module;
        });
        dispatch(moduleSubModuleSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const setModuleList =
  ({ row }) =>
  async (dispatch) => {
    dispatch(moduleSubModuleSuccess({ row }));
  };
export const getAccessRoleList = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: accessRole + '?sort={"insertDateTime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((accessRoleData, index) => {
          const module = {
            index: index + 1,
            id: accessRoleData.id,
            name:
              accessRoleData.roleName +
              " (" +
              accessRoleData.roleShortName +
              " )",
            type:
              accessRoleData.stockStakeHolderType !== null &&
              accessRoleData.stockStakeHolderType.name
                ? accessRoleData.stockStakeHolderType.name +
                  " (" +
                  (accessRoleData.type == 0 ? "Regular" : "Default") +
                  ")"
                : "",

            isActive: accessRoleData.isActive,
          };
          return module;
        });
        dispatch(accessRoleListSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return { response: [], success: false };
  }
};

export const getAccessRoleByUserId = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: accessRole + "/by-user-id",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data.modules;
        if (content !== null) {
          const row = content.map((module) => {
            const iconDiv = module.iconDiv.split("@");
            module = {
              ...module,
              checked: false,
              baseClassName: iconDiv[1],
              className: iconDiv[0],
              color: iconDiv[2],
              moduleSubs: module.moduleSubs.map((subModule) => {
                subModule = {
                  ...subModule,
                  checked: false,
                };
                return subModule;
              }),
            };
            return module;
          });
          localStorage.setItem("moduleSubModule", JSON.stringify(row));
          dispatch(accessRoleByUserIdSuccess({ data: row }));
        }
      }
      return success;
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getAccessRoleById =
  ({ accessRoleId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: accessRole + "/" + accessRoleId,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          dispatch(accessRoleByIdSuccess({ data: content }));
        }
        return success;
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const resetUserAccessById = () => async (dispatch) => {
  try {
    dispatch(resetUserAccessSuccess());
  } catch (e) {
    return console.error(e.message);
  }
};
export const getStockHolderForAssignAccessRole =
  ({ accessRoleId, stockStakeHolderTypeId, userType, areaIds }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          accessRole +
          "/get-sales-team-and-stock-stake-holder-by-stock-stake-holder-type-and-areas?accessRoleId=" +
          accessRoleId +
          "&stockStakeHolderTypeId=" +
          stockStakeHolderTypeId +
          "&userType=" +
          userType +
          "&areaIds=" +
          areaIds,
      }).then(({ data, success }) => {
        if (success) {
          const stockHolderObject = data.data;
          const row = stockHolderObject.map((stockHolderNameObject, index) => {
            let stockHolderNameData = {
              index: index + 1,
              id:
                stockHolderNameObject.id === null
                  ? ""
                  : stockHolderNameObject.id,
              stockStakeHolderTypeId:
                stockHolderNameObject.stockStakeHolderType === null
                  ? ""
                  : stockHolderNameObject.stockStakeHolderType.id,
              holderName:
                stockHolderNameObject.stockStakeHolderType === null
                  ? ""
                  : stockHolderNameObject.stockStakeHolderType.name,
              userTypeId:
                stockHolderNameObject.stockStakeHolderType === null
                  ? ""
                  : stockHolderNameObject.stockStakeHolderType.userType.id,
              userTypeName:
                stockHolderNameObject.stockStakeHolderType === null
                  ? ""
                  : stockHolderNameObject.stockStakeHolderType.userType.name,
              firmName:
                stockHolderNameObject.firmName === null
                  ? ""
                  : stockHolderNameObject.firmName,
              ownerName:
                stockHolderNameObject.ownerName === null
                  ? ""
                  : stockHolderNameObject.ownerName,
              areaNames:
                stockHolderNameObject.areaNames === null
                  ? ""
                  : stockHolderNameObject.areaNames,

              ...stockHolderNameObject,
            };
            return stockHolderNameData;
          });
          dispatch(stockHolderNameSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const setStockHolderForAssignAccessRole =
  ({ row }) =>
  async (dispatch) => {
    dispatch(stockHolderNameSuccess({ row }));
  };
