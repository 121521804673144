import { Checkbox } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import * as React from "react";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    headCells,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    isCheckBox = true,
    flag,
  } = props;

  return (
    <>
      <TableHead>
        <TableRow>
          {isCheckBox && (
            <>
              <TableCell></TableCell>
            </>
          )}

          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={"center"}
              width={headCell.minWidth}
            >
              {headCell.title}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  rows,
  columns,
  stockHolderAndProductConfiguration,
  selection,
  onSelectionChange,
  disabled = false,
  isCheckBox = true,
  setList,
  flag,
  freeQtyFlag,
  newRowData,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      if (disabled) {
        let newSelecteds = [];
        rows.map((n) => {
          if (!n.disabled) {
            newSelecteds.push(n.id);
          }
        });
        onSelectionChange(newSelecteds);
      } else {
        const newSelecteds = rows.map((n) => n.id);
        onSelectionChange(newSelecteds);
      }

      return;
    }
    onSelectionChange([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selection.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selection, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selection.slice(1));
    } else if (selectedIndex === selection.length - 1) {
      newSelected = newSelected.concat(selection.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selection.slice(0, selectedIndex),
        selection.slice(selectedIndex + 1)
      );
    }

    onSelectionChange(newSelected);
  };

  const isSelected = (name) => selection.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            headCells={columns}
            numSelected={selection.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            isCheckBox={isCheckBox}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy)).map(
              (row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <Row
                    tableData={rows}
                    setList={setList}
                    row={row}
                    stockHolderAndProductConfiguration={
                      stockHolderAndProductConfiguration
                    }
                    handleClick={handleClick}
                    labelId={labelId}
                    isItemSelected={isItemSelected}
                    isCheckBox={isCheckBox}
                    flag={flag}
                    freeQtyFlag={freeQtyFlag}
                    newRowData={newRowData}
                  />
                );
              }
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
            {rows.length === 0 && (
              <TableRow
                style={{
                  height: 53 * 1,
                }}
              >
                <TableCell align="center" colSpan={columns.length + 1}>
                  No Record Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function Row({
  tableData,
  row,
  labelId,
  isItemSelected,
  handleClick,
  setList,
  isCheckBox = true,
  flag,
  stockHolderAndProductConfiguration,
  freeQtyFlag,
  newRowData,
}) {
  const onChangeTextField = (e, row, name) => {
    const { value } = e.target;
    const rowList = newRowData.map((rowData) => {
      return {
        ...rowData,
        [name]: row.id == rowData.id ? value : rowData[name],
      };
    });
    setList({ row: rowList });
  };
  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.name}
      selected={isItemSelected}
    >
      {isCheckBox && (
        <TableCell
          padding="checkbox"
          style={{ backgroundColor: !flag ? "" : row.checked ? "" : "#FFaaaa" }}
        >
          <Checkbox
            color="primary"
            onClick={(event) => handleClick(event, row.id)}
            checked={row.checked}
            disabled={flag ? true : false}
            inputProps={{
              "aria-labelledby": labelId,
            }}
          />
        </TableCell>
      )}

      <TableCell
        align="center"
        padding="checkbox"
        style={{ backgroundColor: !flag ? "" : row.checked ? "" : "#FFaaaa" }}
      >
        {row.index}
      </TableCell>
      <TableCell
        padding="checkbox"
        style={{ backgroundColor: !flag ? "" : row.checked ? "" : "#FFaaaa" }}
      >
        {row.firmName}
      </TableCell>
      <TableCell
        padding="checkbox"
        style={{ backgroundColor: !flag ? "" : row.checked ? "" : "#FFaaaa" }}
      >
        {row.beatName}
      </TableCell>
      <TableCell
        padding="checkbox"
        align="center"
        style={{ backgroundColor: !flag ? "" : row.checked ? "" : "#FFaaaa" }}
      >
        {row.orderNo}
      </TableCell>
      <TableCell
        padding="checkbox"
        sx={{ width: "8%" }}
        align="center"
        style={{ backgroundColor: !flag ? "" : row.checked ? "" : "#FFaaaa" }}
      >
        {row.orderDate}
      </TableCell>

      <TableCell
        padding="checkbox"
        sx={{ width: "8%" }}
        style={{ backgroundColor: !flag ? "" : row.checked ? "" : "#FFaaaa" }}
      >
        {row.productName}
      </TableCell>
      <TableCell
        align="center"
        padding="checkbox"
        style={{ backgroundColor: !flag ? "" : row.checked ? "" : "#FFaaaa" }}
      >
        {row.outletUom}
      </TableCell>
      {stockHolderAndProductConfiguration.rateEditOrNot
        .stockHolderAndProductConfiguration &&
      stockHolderAndProductConfiguration.rateEditOrNot.stockHolderAndProductConfiguration.filter(
        (rowData) =>
          rowData.product && row.product && rowData.product.id == row.product
      ).length != 0 ? (
        <TableCell
          align="right"
          style={{
            width: "10%",
            backgroundColor: !flag ? "" : row.checked ? "" : "#FFaaaa",
          }}
        >
          {/* <TextFieldCompo
            placeHolder={"Rate"}
            size="small"
            color="primary"
            type="number"
            id={"1"}
            value={row.rate}
            onChange={(e) => {
              onChangeTextField(e, row, "rate");
            }}
            fullWidth={false}
            errorText={row.rateError == 1 ? "rate Requi#FFaaaa" : ""}
          /> */}

          <input
            className={"customTextfield"}
            placeHolder={"Rate"}
            value={row.rate}
            name="rate"
            onChange={(e) => {
              onChangeTextField(e, row, "rate");
            }}
            type="text"
            id="rate"
          ></input>
        </TableCell>
      ) : (
        <TableCell
          style={{
            width: "10%",
            backgroundColor: !flag ? "" : row.checked ? "" : "#FFaaaa",
          }}
        >
          {row.rate}
        </TableCell>
      )}
      {flag && (
        <>
          <TableCell
            align="right"
            padding="checkbox"
            style={{
              backgroundColor: !flag ? "" : row.checked ? "" : "#FFaaaa",
            }}
          >
            {row.secondaryClosingQty}
          </TableCell>

          <TableCell
            align="right"
            padding="checkbox"
            style={{
              backgroundColor: !flag ? "" : row.checked ? "" : "#FFaaaa",
            }}
          >
            {row.productStock}
          </TableCell>
        </>
      )}
      <TableCell
        align="right"
        padding="checkbox"
        style={{ backgroundColor: !flag ? "" : row.checked ? "" : "#FFaaaa" }}
      >
        {row.billQty}
      </TableCell>
      <TableCell
        padding="checkbox"
        style={{ backgroundColor: !flag ? "" : row.checked ? "" : "#FFaaaa" }}
      >
        {/* <TextFieldCompo
          placeHolder={"Quantity"}
          size="small"
          color="primary"
          type="number"
          id={"1"}
          value={row.orderQty}
          onChange={(e) => {
            onChangeTextField(e, row, "orderQty");
          }}
          errorText={
            row.qtyError == 1
              ? "Order Quantity Requi#FFaaaa"
              : row.qtyError == 3
              ? "Order quantity should be integer"
              : row.qtyError == 4
              ? "Order quantity should be greater than 0"
              : ""
          }
          fullWidth={false}
          disabled={row.disabled}
        /> */}

        <input
          className={"customTextfield"}
          placeHolder={"Quantity"}
          value={row.orderQty}
          name="orderQty"
          onChange={(e) => {
            onChangeTextField(e, row, "orderQty");
          }}
          type="text"
          id="orderQty"
          disabled={true}
          size="small"
          style={{ opacity: "60%" }}
        ></input>
      </TableCell>
      {/* {freeQtyFlag == 1 && 
        <>
          <TableCell
            padding="checkbox"
            style={{
              backgroundColor: !flag ? "" : row.checked ? "" : "#FFaaaa",
            }}
          >
            <TextFieldCompo
              placeHolder={"Quantity"}
              size="small"
              color="primary"
              type="number"
              id={"1"}
              value={row.freeQty}
              onChange={(e) => {
                onChangeTextField(e, row, "freeQty");
              }}
              errorText={
                row.freeQtyError == 1 ? "Free Quantity Requi#FFaaaa" : ""
              }
              fullWidth={false}
              disabled={row.disabled}
            />
          </TableCell>
          <TableCell
            padding="checkbox"
            style={{
              backgroundColor: !flag ? "" : row.checked ? "" : "#FFaaaa",
            }}
          >
            <TextFieldCompo
              placeHolder={"Discount percentage"}
              size="small"
              color="primary"
              type="number"
              id={"1"}
              value={row.distcountAmt}
              onChange={(e) => {
                onChangeTextField(e, row, "distcountAmt");
              }}
              errorText={
                row.discountError == 1
                  ? "Discount Requi#FFaaaa"
                  : "" || row.discountError == 2
                  ? "Discount Percentage should be less than 100"
                  : ""
              }
              fullWidth={false}
              disabled={row.disabled}
            />
          </TableCell>
        </>
      )} */}

      <TableCell
        align="right"
        padding="checkbox"
        style={{ backgroundColor: !flag ? "" : row.checked ? "" : "#FFaaaa" }}
      >
        {row.total}
      </TableCell>
    </TableRow>
  );
}
