import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { timeSlotColumns } from "../../../tableColumns/table-columns";
import { TimeSlotJson } from "../../../DynamicFormsJson/MastersJSON/timeSlot";
import { getTimeSlot } from "../../../Slice/timeSlot.slice";

class TimeSlotTable extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { getTimeSlot, timeSlotList } = this.props;
        return (
            <>
                <DynamicMainScreen
                    formPath={TimeSlotJson.formPath}
                    screenTitle={TimeSlotJson.screenTitle}
                    fieldMeta={TimeSlotJson.fieldMeta}
                    showPdfDownload={false}
                    showExcelDownload={false}
                    showExtension={false}
                    pdfFileName={TimeSlotJson.pdfFileName}
                    excelFileName={TimeSlotJson.excelFileName}
                    showAddButton={TimeSlotJson.showAddButton}
                    tableColumnsToFilter={TimeSlotJson.tableColumnsToFilter}
                    tableColumns={timeSlotColumns}
                    tableData={timeSlotList?.timeSlot}
                    getTableData={getTimeSlot}
                    apiBaseURL={TimeSlotJson.apiBaseURL}
                    baseIdColumn={TimeSlotJson.baseIdColumn}

                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    timeSlotList: state.timeSlot,
});
const mapDispatchToProps = {
    getTimeSlot,
};
export default connect(mapStateToProps, mapDispatchToProps)(TimeSlotTable);
