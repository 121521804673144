import { Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  saveWarningMsg,
  serverMsg,
  yearEndMsg,
} from "../../config/messageconstant";
import { StockConsumptionHeaderJson } from "../../DynamicFormsJson/Transaction/stockConsumptionHeader";
import { getStock, setQuantityData } from "../../Slice/manageStock.slice";
import { stockConsumptionHeaderColums } from "../../tableColumns/table-columns";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
class stockConsumptionHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: "",
      toDate: "",
    };
  }

  async componentDidMount() {
    const { showLoador, showNotification, getStock } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getStock({ stockType: "0" }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          console.log(response);
          this.setState({
            fromDate: response.fromDate,
            toDate: response.toDate,
          });
        }
      });
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  }
  getListById = (data) => {
    console.log(data);
    const { getStock, showLoador, showNotification } = this.props;
    showLoador({ loador: true });
    getStock({
      stockType: data.qtyType,
    }).then(({ success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      }
    });
  };

  onSubmitData = () => {
    const { stockConsumptionHeaderList } = this.props;
    if (navigator.onLine) {
      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.onYearEnd(stockConsumptionHeaderList.stock);
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onYearEnd = () => {
    const { showLoador, showNotification, stockConsumptionHeaderList } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.yearEndHeader + "/closing-year",
        postBody: stockConsumptionHeaderList.stock,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (!data.data.error) {
          showNotification({ msg: yearEndMsg });
          window.location.reload();
        } else {
          showNotification({ msg: data.data.message, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  render() {
    const { stockConsumptionHeaderList, getStock } = this.props;
    const { fromDate, toDate } = this.state;
    console.log(stockConsumptionHeaderList?.productStocks);
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <DynamicMainScreen
          showClear={false}
          showSearchData={false}
          popUpClose={true}
            getListById={this.getListById}
            searchList={StockConsumptionHeaderJson.searchList}
            screenTitle={"Stock between " + fromDate + " to " + toDate}
            showViewButtonInTable={false}
            showAddButton={false}
            tableColumns={stockConsumptionHeaderColums}
            tableData={stockConsumptionHeaderList?.productStocks}
            getTableData={this.handleList}
            showDeleteIcon={false}
            showEditIcon={false}
            showSearchBox={true}
            searchButton={false}
            showPdfDownload={false}
            showExcelDownload={false}
            isActionColActive={false}
            callApi={false}
            showPegination={false}
            onSearchData={false}
          />
          <br />
          {Object.keys(stockConsumptionHeaderList.stock).length != 0 &&
            stockConsumptionHeaderList.stock.type == 1 && (
              <>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                  container
                  justifyContent="right"
                >
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name="Year End"
                    onClick={this.onSubmitData}
                  />
                </Grid>
              </>
            )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stockConsumptionHeaderList: state.manageStockByDate,
});
const mapDispatchToProps = {
  getStock,
  setQuantityData,
  showLoador,
  showNotification,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(stockConsumptionHeader);
