import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  report: [],
  reportData: {}
};
let URL = endpoints.matrixReport;
const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    reportSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        report: row,
      };
    },
    reportSaveSuccess: (state = cloneDeep(initialState), action) => {
      const { data } = action.payload;
      return {
        ...state,
        reportData: data,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
},
);

export const { reportSuccess, reportSaveSuccess, resetState } = reportSlice.actions;

export default reportSlice.reducer;

export const getReportName = ({ locationPath }) => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/by-type?type=" + locationPath,
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        const row = content.map((reportObject, index) => {

          const graphColumns =
            JSON.parse(reportObject.graphColumns) != null
              ? JSON.parse(reportObject.graphColumns)
              : [];


          reportObject = {
            ...reportObject,
            name: reportObject.reportName,
            graphColumns: graphColumns,
            reportParams: reportObject.params,
          };
          return reportObject;
        });
        dispatch(reportSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getReportInfo = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      let row;
      if (success) {
        const { content } = data.data;
        let reportList = content.map((reportObject, index) => {
          let reportData = {
            index: index + 1,
            ...reportObject,

            id: reportObject.id === null ? "" : reportObject.id,
            reportType:
              reportObject.reportType === null
                ? ""
                : reportObject.reportType,
            reportId:
              reportObject.reportId === null
                ? ""
                : reportObject.reportId,
            reportName:
              reportObject.reportName === null
                ? ""
                : reportObject.reportName,
            name:
              reportObject.reportName === null
                ? ""
                : reportObject.reportName,
            isPdf:
              reportObject.isPdf === null
                ? ""
                : reportObject.isPdf === 1
                  ? "Yes"
                  : "No",
            isExcel:
              reportObject.isExcel === null
                ? ""
                : reportObject.isExcel === 1
                  ? "Yes"
                  : "No",
            isGraph:
              reportObject.isGraph === null
                ? ""
                : reportObject.isGraph === 1
                  ? "Yes"
                  : "No",
            isSubreport:
              reportObject.isSubreport === null
                ? ""
                : reportObject.isSubreport === 1
                  ? "Yes"
                  : "No",
          };
          return reportData;
        })
        dispatch(reportSuccess({ row: reportList }));
        return { response: reportList, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const saveReport = ({ dataToSave }) => async (dispatch) => {
  try {
    const response = apiPost({
      url: URL,
      postBody: dataToSave,
    }).then(({ data, success }) => {
      if (success) {
        dispatch(reportSaveSuccess({ data: data.data }));
        return { response: data.data, success };
      }
      return { response: {}, success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const setSaveReport = ({ rowObject }) => async (dispatch) => {

  dispatch(reportSaveSuccess({ data: rowObject }));

};
