import endpoint from "../../config/endpoints";

export const yearJson = {
  showSaveNextBtn: true,
  formPath: "/year-form",
  apiBaseURL: endpoint.year,
  changeExcel: endpoint.exportData,
  excelUrl: "year-data" + '?sort={"fromDate": "ASC"}',
  screenTitle: "Financial Year",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Financial Year",
  showExcelDownload: true,
  excelFileName: "Financial Year",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "yearName",
      isChecked: true,
    },
    {
      columnDisplayName: "From Date",
      columnKeyName: "fromDate",
      isChecked: true,
    },
    {
      columnDisplayName: "To Date",
      columnKeyName: "toDate",
      isChecked: true,
    },
    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "yearName",
      isMandatory: true,
      uniqueValidation: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "fromDate-ge",
    },
  ],
};
