import React from "react";
import { Paper } from "@mui/material";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { serverMsg, savemsg, saveFailedMsg,noInternetMsg,saveWarningMsg,dateExistMsg } from "../../config/messageconstant";
import { publishRateConfigurationOutletJson } from "../../DynamicFormsJson/Transaction/PublishRateConfigurationOutlet";
import { getCategory } from "../../Slice/category.slice";
import { getCategoryForRateConfig } from "../../Slice/outletRateStructure.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { apiGet, apiPost } from '../../utils/api_service';
import endpoint from "../../config/endpoints";
import { withRouter } from "../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import swal from "sweetalert";

class PublishRateConfigurationOutletForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        categoryDetail: this.props.categoryList?.category,
        publishRateConfigDetail: this.props.rateForOutletList?.rateConfigForOutlet
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.categoryList !== nextProps.categoryList) {
      if (
        this.props.categoryList?.category !== nextProps.categoryList?.category
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            categoryDetail: nextProps.categoryList?.category,
          },
        });
      }
    }
    if (this.props.rateForOutletList !== nextProps.rateForOutletList) {
      if (
        this.props.rateForOutletList?.rateConfigForOutlet !== nextProps.rateForOutletList?.rateConfigForOutlet
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            publishRateConfigDetail: nextProps.rateForOutletList?.rateConfigForOutlet,
          },
        });
      }
    }
  }

 async componentDidMount() {
    const { getCategory, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
    showLoador({ loador: true });
    await getCategory().then(({ success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      }
    });
  }
  else {
    this.props.showNotification({ msg: noInternetMsg, severity: "error" });
  }
  }
  getListById = async (data) => {
    const { getCategoryForRateConfig, showLoador,showNotification } =
      this.props;
    if (
      data.category
    ) {
      if (navigator.onLine) {
      showLoador({ loador: true });
      await getCategoryForRateConfig({
        categoryIds: data.category.join(),
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    }
    else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
    }
  };

  onSubmit = (searchData) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
    showLoador({ loador: true });

    apiGet({
      url: endpoint.productRateConfOutlet + "/by-product-rate-configurations-outlet-and-publish-date?ProductRateConfigurationOutletIds=" + searchData.productRateConfiguration + "&publishDate=" + searchData.date,
    }).then(({ data, success }) => {
      showLoador({ loador: false });
      if (success) {
        if (data.data.length === 0) {
          const rateconfigurationList = searchData.productRateConfiguration.split(",")
          const dataToSave = rateconfigurationList.map((rateconfigurationListData) => {
            const rateconfigurationListObj = {
              "productRateConfigurationOutlet": {
                "id": rateconfigurationListData
              },
              
              "publishDate": searchData.date
            }
            return rateconfigurationListObj
          })
          swal({
            title: "Are you sure?",
            text: saveWarningMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              this.onSave(dataToSave);
            }
          });
          
        }
        else {
          showNotification({ msg: dateExistMsg, severity: "error" });
        }
      }
    })
  }
  else {
    this.props.showNotification({ msg: noInternetMsg, severity: "error" });
  }
  };
  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
  showLoador({ loador: true });
          apiPost({
            url: endpoint.productRateConfOutlet + "/save-list",
            postBody: dataToSave
          }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success) {
              this.props.navigate(-1);
              showNotification({ msg: savemsg });
            }
            else {
              showNotification({ msg: saveFailedMsg, severity: "error" });
            }
          })
        }
        else {
          this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
        }
  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
      <Paper
          sx={{
            padding: 2,
            borderRadius: 2,

          }}
          elevation={0}
        >
        <DynamicFormWithoutSave
          getListById={this.getListById}
          showBackToList={true}
          dynamicMasterData={dynamicMasterData}
          showSaveBtn={true}
          showTitle={publishRateConfigurationOutletJson.showTitle}
          screenTitle={publishRateConfigurationOutletJson.screenTitle}
          fieldMeta={publishRateConfigurationOutletJson.fieldMeta}
          showCancel={publishRateConfigurationOutletJson.showCancel}
          apiBaseURL={publishRateConfigurationOutletJson.apiBaseURL}
          showSaveNextBtn={publishRateConfigurationOutletJson.showSaveNextBtn}
          showSaveBtnMain={false}
          onSave={this.onSubmit}
        />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  categoryList: state.category,
  rateForOutletList: state.outletRateStructure,
});
const mapDispatchToProps = {
  getCategory,
  getCategoryForRateConfig,
  showLoador,
  showNotification,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PublishRateConfigurationOutletForm);
