import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  assignRateCurrentList: [],
  assignRateFutureList: [],
  assignRatePreviousList: [],
  assignRateConfigurationSaveList: [],
};
let URL = endpoints.assignRateConfigurationToStakeholder;
const assignRateConfigurationToStakeholderSlice = createSlice({
  name: "assignRateConfigurationToStakeholder",
  initialState,
  reducers: {
    assignRateConfigurationToStakeholderCurrentListSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        assignRateCurrentList: row,
      };
    },
    assignRateConfigurationToStakeholderFutureListSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        assignRateFutureList: row,
      };
    },
    assignRateConfigurationToStakeholderPreviousListSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        assignRatePreviousList: row,
      };
    },
    assignRateConfigurationSaveSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        assignRateConfigurationSaveList: row,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  assignRateConfigurationToStakeholderCurrentListSuccess,
  assignRateConfigurationToStakeholderFutureListSuccess,
  assignRateConfigurationToStakeholderPreviousListSuccess,
  assignRateConfigurationSaveSuccess,
  resetState,
} = assignRateConfigurationToStakeholderSlice.actions;

export default assignRateConfigurationToStakeholderSlice.reducer;

export const getAssignRateConfigurationToStakeholderCurrentList =
  () => async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/get-current",
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map(
            (assignRateConfigurationToStakeholderObject, index) => {
              let assignRateConfigurationToStakeholderData = {
                index: index + 1,
                id:
                  assignRateConfigurationToStakeholderObject.id === null
                    ? ""
                    : assignRateConfigurationToStakeholderObject.id,
                ownerName:
                  assignRateConfigurationToStakeholderObject.stockStakeHolder !==
                    null &&
                  assignRateConfigurationToStakeholderObject.stockStakeHolder
                    .ownerName
                    ? assignRateConfigurationToStakeholderObject
                        .stockStakeHolder.ownerName
                    : "",
                stockStakeHolderType:
                  assignRateConfigurationToStakeholderObject
                    .productRateConfiguration.stockStakeHolderType === null
                    ? ""
                    : assignRateConfigurationToStakeholderObject
                        .productRateConfiguration.stockStakeHolderType.name,
                category:
                  assignRateConfigurationToStakeholderObject.category !==
                    null &&
                  assignRateConfigurationToStakeholderObject.category.name
                    ? assignRateConfigurationToStakeholderObject.category.name
                    : "",
                productRateConfiguration:
                  assignRateConfigurationToStakeholderObject.productRateConfiguration !==
                    null &&
                  assignRateConfigurationToStakeholderObject
                    .productRateConfiguration.name
                    ? assignRateConfigurationToStakeholderObject
                        .productRateConfiguration.name
                    : "",
                publishDate:
                  assignRateConfigurationToStakeholderObject.publishDate ===
                  null
                    ? ""
                    : assignRateConfigurationToStakeholderObject.publishDate,
                isActive:
                  assignRateConfigurationToStakeholderObject.isActive === null
                    ? ""
                    : assignRateConfigurationToStakeholderObject.isActive,
              };
              return assignRateConfigurationToStakeholderData;
            }
          );
          dispatch(
            assignRateConfigurationToStakeholderCurrentListSuccess({ row })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getAssignRateConfigurationToStakeholderFutureList =
  () => async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/get-future",
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map(
            (assignRateConfigurationToStakeholderFutureListObject, index) => {
              let assignRateConfigurationToStakeholderFutureListData = {
                index: index + 1,
                id:
                  assignRateConfigurationToStakeholderFutureListObject.id ===
                  null
                    ? ""
                    : assignRateConfigurationToStakeholderFutureListObject.id,
                ownerName:
                  assignRateConfigurationToStakeholderFutureListObject.stockStakeHolder !==
                    null &&
                  assignRateConfigurationToStakeholderFutureListObject
                    .stockStakeHolder.ownerName
                    ? assignRateConfigurationToStakeholderFutureListObject
                        .stockStakeHolder.ownerName
                    : "",
                stockStakeHolderType:
                  assignRateConfigurationToStakeholderFutureListObject
                    .productRateConfiguration.stockStakeHolderType === null
                    ? ""
                    : assignRateConfigurationToStakeholderFutureListObject
                        .productRateConfiguration.stockStakeHolderType.name,
                category:
                  assignRateConfigurationToStakeholderFutureListObject.category !==
                    null &&
                  assignRateConfigurationToStakeholderFutureListObject.category
                    .name
                    ? assignRateConfigurationToStakeholderFutureListObject
                        .category.name
                    : "",

                productRateConfiguration:
                  assignRateConfigurationToStakeholderFutureListObject.productRateConfiguration !==
                    null &&
                  assignRateConfigurationToStakeholderFutureListObject
                    .productRateConfiguration.name
                    ? assignRateConfigurationToStakeholderFutureListObject
                        .productRateConfiguration.name
                    : "",
                publishDate:
                  assignRateConfigurationToStakeholderFutureListObject.publishDate ===
                  null
                    ? ""
                    : assignRateConfigurationToStakeholderFutureListObject.publishDate,
                isActive:
                  assignRateConfigurationToStakeholderFutureListObject.isActive ===
                  null
                    ? ""
                    : assignRateConfigurationToStakeholderFutureListObject.isActive,
              };
              return assignRateConfigurationToStakeholderFutureListData;
            }
          );
          dispatch(
            assignRateConfigurationToStakeholderFutureListSuccess({ row })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAssignRateConfigurationToStakeholderSearchList =
  ({ stockStakeHolderIds }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-previous-by-stock-stake-holders?stockStakeHolderIds=" +
          stockStakeHolderIds,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map(
            (assignRateConfigurationToStakeholderPreviousListObject, index) => {
              let assignRateConfigurationPreviousData = {
                index: index + 1,
                id:
                  assignRateConfigurationToStakeholderPreviousListObject.id ===
                  null
                    ? ""
                    : assignRateConfigurationToStakeholderPreviousListObject.id,
                ownerName:
                  assignRateConfigurationToStakeholderPreviousListObject.stockStakeHolder !==
                    null &&
                  assignRateConfigurationToStakeholderPreviousListObject
                    .stockStakeHolder.ownerName
                    ? assignRateConfigurationToStakeholderPreviousListObject
                        .stockStakeHolder.ownerName
                    : "",
                stockStakeHolderType:
                  assignRateConfigurationToStakeholderPreviousListObject
                    .productRateConfiguration.stockStakeHolderType === null
                    ? ""
                    : assignRateConfigurationToStakeholderPreviousListObject
                        .productRateConfiguration.stockStakeHolderType.name,
                category:
                  assignRateConfigurationToStakeholderPreviousListObject.category !==
                    null &&
                  assignRateConfigurationToStakeholderPreviousListObject
                    .category.name
                    ? assignRateConfigurationToStakeholderPreviousListObject
                        .category.name
                    : "",

                productRateConfiguration:
                  assignRateConfigurationToStakeholderPreviousListObject.productRateConfiguration !==
                    null &&
                  assignRateConfigurationToStakeholderPreviousListObject
                    .productRateConfiguration.name
                    ? assignRateConfigurationToStakeholderPreviousListObject
                        .productRateConfiguration.name
                    : "",
                publishDate:
                  assignRateConfigurationToStakeholderPreviousListObject.publishDate ===
                  null
                    ? ""
                    : assignRateConfigurationToStakeholderPreviousListObject.publishDate,
                isActive:
                  assignRateConfigurationToStakeholderPreviousListObject.isActive ===
                  null
                    ? ""
                    : assignRateConfigurationToStakeholderPreviousListObject.isActive,
              };

              return assignRateConfigurationPreviousData;
            }
          );
          dispatch(
            assignRateConfigurationToStakeholderPreviousListSuccess({ row })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAssignRateConfiguration = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((assignRateConfigurationObject, index) => {
          let assignRateConfigurationData = {
            index: index + 1,
            id:
              assignRateConfigurationObject.id === null
                ? ""
                : assignRateConfigurationObject.id,
            name:
              assignRateConfigurationObject.name === null
                ? ""
                : assignRateConfigurationObject.name,
            stockStakeHolderTypeName:
              assignRateConfigurationObject.stockStakeHolderType === null &&
              assignRateConfigurationObject.stockStakeHolderType.name === null
                ? ""
                : assignRateConfigurationObject.stockStakeHolderType.name,

            stockStakeHolderType:
              assignRateConfigurationObject.stockStakeHolderType === null
                ? ""
                : assignRateConfigurationObject.stockStakeHolderType.id,
            categoryName:
              assignRateConfigurationObject.category != null &&
              assignRateConfigurationObject.category.name
                ? assignRateConfigurationObject.category.name
                : "",
            category:
              assignRateConfigurationObject.category != null &&
              assignRateConfigurationObject.category.id
                ? assignRateConfigurationObject.category.id
                : "",

            publicationDate:
              assignRateConfigurationObject.publicationDate === null
                ? ""
                : assignRateConfigurationObject.publicationDate,
            isActive:
              assignRateConfigurationObject.isActive === null
                ? ""
                : assignRateConfigurationObject.isActive,
          };

          return assignRateConfigurationData;
        });
        dispatch(assignRateConfigurationSaveSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
