import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { RangeColumns } from "../../../tableColumns/table-columns";
import { rangeJson } from "../../../DynamicFormsJson/MastersJSON/range";
import { getRange } from "../../../Slice/range.slice";

class Range extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { rangeList, getRange } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={rangeJson.formPath}
          screenTitle={rangeJson.screenTitle}
          fieldMeta={rangeJson.fieldMeta}
          showPdfDownload={rangeJson.showPdfDownload}
          showExcelDownload={rangeJson.showExcelDownload}
          pdfFileName={rangeJson.pdfFileName}
          excelFileName={rangeJson.excelFileName}
          showAddButton={rangeJson.showAddButton}
          tableColumnsToFilter={rangeJson.tableColumnsToFilter}
          tableColumns={RangeColumns}
          tableData={rangeList?.range}
          getTableData={getRange}
          apiBaseURL={rangeJson.apiBaseURL}
          baseIdColumn={rangeJson.baseIdColumn}
          isNote={true}
          isNoteValue={"Maximum & Minimum Range For Outlet's & Stock Holder's"}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  rangeList: state.range,
});
const mapDispatchToProps = {
  getRange,
};
export default connect(mapStateToProps, mapDispatchToProps)(Range);
