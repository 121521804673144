import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { GrievanceCategoryColumns } from "../../../tableColumns/table-columns";
import { grievanceCategoryJson } from "../../../DynamicFormsJson/MastersJSON/grievanceCategory";
import { getGrievanceCategory } from "../../../Slice/grievanceCategory.slice";

class GrievanceCategory extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { grievanceCategoryList, getGrievanceCategory } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={grievanceCategoryJson.formPath}
          screenTitle={grievanceCategoryJson.screenTitle}
          fieldMeta={grievanceCategoryJson.fieldMeta}
          showPdfDownload={grievanceCategoryJson.showPdfDownload}
          showExcelDownload={grievanceCategoryJson.showExcelDownload}
          pdfFileName={grievanceCategoryJson.pdfFileName}
          excelFileName={grievanceCategoryJson.excelFileName}
          showAddButton={grievanceCategoryJson.showAddButton}
          tableColumnsToFilter={grievanceCategoryJson.tableColumnsToFilter}
          tableColumns={GrievanceCategoryColumns}
          tableData={grievanceCategoryList?.grievanceCategory}
          getTableData={getGrievanceCategory}
          apiBaseURL={grievanceCategoryJson.apiBaseURL}
          baseIdColumn={grievanceCategoryJson.baseIdColumn}
          isNote={true}
          isNoteValue={"Specify Grievance Categories"}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  grievanceCategoryList: state.grievanceCategory,
});
const mapDispatchToProps = {
  getGrievanceCategory,
};
export default connect(mapStateToProps, mapDispatchToProps)(GrievanceCategory);
