import { TextField } from "@mui/material";
export const TextFieldCompo = ({
  autoFocus = false,
  inputRef,
  size,
  inputProps,
  placeHolder,
  name,
  color = "primary",
  value = "",
  disabled = false,
  type = "text",
  multiline = false,
  onChange,
  errorText = "",
  minusValid = true,
  spValid = "",
  onBlur,
  variant = "standard"
}) => {
  const onChangeData = (event) => {
    if (minusValid && type == "number") {
      if (
        event.target.value === "" ||
        (event.target.value !== "e" && +event.target.value >= 0)
      ) {
        onChange(event);
      } else {
        if (event.target.value.length < value.length) {
          onChange(event);
        }
      }
    } else {
      onChange(event);
    }
  };
  return (
    <TextField
      inputRef={inputRef}
      autoFocus={autoFocus}
      onBlur={onBlur}
      size={size}
      color={color}
      name={name}
      id={name}
      disabled={disabled}
      inputProps={inputProps}
      type={"text"}
      placeholder={placeHolder}
      variant={variant}
      multiline={true}
      onChange={onChangeData}
      fullWidth
    ></TextField>
  );
};
