import { Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { openingTransactionForOutletJson } from "../../DynamicFormsJson/Transaction/openingTransactionForOutlet";
import { getOutletByUserType } from "../../Slice/outlet.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
class OpeningForOutlet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        outletDetail: this.props.outletList.outletByUserType,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.outletList !== nextProps.outletList) {
      if (
        this.props.outletList?.outletByUserType !==
        nextProps.outletList?.outletByUserType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            outletDetail: nextProps.outletList?.outletByUserType,
          },
        });
      }
    }
  }
  async componentDidMount() {
    const { showLoador, showNotification, getOutletByUserType } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getOutletByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  }
  onSearch = (data) => {
    const amount = data.amount * (data.transactionType == 0 ? 1 : -1);
    let dataToSave = {
      transactionId: "0",
      outLet: {
        id: data.outlet.id,
      },
      transactionType: data.transactionType,
      amt: amount,
      remark: "NA",
    };

    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onSave(dataToSave);
      }
    });
  };

  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.transactionOutlet + "/opening",
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg });
          window.location.reload();
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            screenTitle={openingTransactionForOutletJson.screenTitle}
            fieldMeta={openingTransactionForOutletJson.fieldMeta}
            apiBaseURL={openingTransactionForOutletJson.apiBaseURL}
            dynamicMasterData={dynamicMasterData}
            showSaveBtnMain={false}
            showSaveBtn={true}
            saveBtnText={"Submit"}
            onSave={this.onSearch}
          />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  outletList: state.outlet,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getOutletByUserType,
};
export default connect(mapStateToProps, mapDispatchToProps)(OpeningForOutlet);
