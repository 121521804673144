import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { DarkBlue } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
  deleteWarningMsg,
} from "../../config/messageconstant";
import { requestGRNJSON } from "../../DynamicFormsJson/Transaction/requestGRN";
import { getCategoryForStakeholder } from "../../Slice/category.slice";
import { getProductByCategoryForStakeHolder } from "../../Slice/product.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import CreditNoteHeaderForm from "./RequestGRNHeaderForm";
class RequestGRNForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowList: [],
      formErrors: {},
      fieldData: { calStock: "1" },
      dynamicMasterData: {
        categoryDetail: this.props.categoryList.categoryForStakeholder,
        productDetail: this.props.productList.productByCategoryForStakeholder,
      },
    };
  }

  async componentDidMount() {
    this.setState({
      rowList: [],
    });
    const { showLoador, showNotification, getCategoryForStakeholder } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getCategoryForStakeholder().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
        }
      });
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.categoryList.categoryForStakeholder !==
      nextProps.categoryList.categoryForStakeholder
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          categoryDetail: nextProps.categoryList.categoryForStakeholder,
        },
      });
    }
    if (this.props.productList !== nextProps.productList) {
      if (
        this.props.productList?.productByCategoryForStakeholder !==
        nextProps.productList?.productByCategoryForStakeholder
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            productDetail:
              nextProps.productList?.productByCategoryForStakeholder,
          },
        });
      }
    }
  }

  getListById = async (data) => {
    const { getProductByCategoryForStakeHolder, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      if (data.category) {
        showLoador({ loador: true });
        await getProductByCategoryForStakeHolder({
          categoryId: data.category,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  onSearch = (data) => {
    const { rowList, dynamicMasterData } = this.state;

    let productList = dynamicMasterData["productDetail"]
      ? dynamicMasterData["productDetail"].filter(
          (row) => row.id == data.product
        )
      : [];
    let catList = dynamicMasterData["categoryDetail"]
      ? dynamicMasterData["categoryDetail"].filter(
          (row) => row.id == data.category
        )
      : [];
    this.setState({
      rowList: [
        ...rowList,
        {
          index: rowList.length + 1,
          categoryName: catList.length != 0 ? catList[0].name : "-",
          productName: productList.length != 0 ? productList[0].name : "-",
          uom: productList.length != 0 ? productList[0].outletUomName : "-",
          uomConversionValue:
            productList.length != 0 ? productList[0].uomConversionValue : "-",
          calStock: data.calStock,
          ...data,
          total: (+data.rate * +data.qty).toFixed(2),
        },
      ],
    });
  };

  onSubmitData = () => {
    if (this.checkValidationOnSubmit()) {
      const { rowList, fieldData } = this.state;
      if (rowList.length != 0) {
        const grnGvnDetails = rowList.map((rowData) => {
          const rowObj = {
            product: {
              id: rowData.product,
            },
            qty: rowData.qty,
            batchCode: rowData.batch,
          };

          return rowObj;
        });
        const dataToSave = {
          remark: "NA",
          instockOutstock: fieldData.calStock,
          grnGvnDetails: grnGvnDetails,
          date: fieldData.date.format("DD-MM-YYYY"),
        };
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            this.onSave(dataToSave);
          }
        });
      } else {
        this.props.showNotification({
          msg: "Please add atleast one product",
          severity: "error",
        });
      }
    }
  };

  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.grnGVNHeader,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          this.props.navigate(-1);
          showNotification({ msg: savemsg });
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  mandatoryCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    requestGRNJSON.fieldMeta.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&
              fieldData[currentField.dataKey].id == null))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };

  ValidationCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    requestGRNJSON.fieldMeta.forEach((currentField) => {
      if (currentField.valid) {
        if (
          currentField.validName === "email" &&
          !validEmail.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "zero" &&
          fieldData[currentField.dataKey] === 0
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };

  checkValidationOnSubmit = () => {
    if (this.mandatoryCheck()) {
      return this.ValidationCheck();
    } else {
      return false;
    }
  };

  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    requestGRNJSON.fieldMeta.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        // dataToSearch[fieldName] = newValue;
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };

  getDataForDisabled = (value) => {
    const { dynamicMasterData } = this.state;

    let productList = dynamicMasterData["productDetail"]
      ? dynamicMasterData["productDetail"].filter((row) => row.id == value)
      : [];
    return {
      rate: (+productList[0].rate).toFixed(2),
      uom: productList[0].outletUomName,
      uomConversionValue: productList[0].uomConversionValue,
    };
  };

  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: deleteWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const rowListData = this.state.rowList.filter(
          (row) => row.index != rowData.index
        );
        const row = rowListData.map((rowListDataObj, index) => {
          return { ...rowListDataObj, index: index + 1 };
        });
        this.setState({
          rowList: row,
        });
      }
    });
  };

  render() {
    const { dynamicMasterData, rowList, formErrors, fieldData } = this.state;

    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <CreditNoteHeaderForm
            getListById={this.getListById}
            dynamicMasterData={dynamicMasterData}
            screenTitle={requestGRNJSON.screenTitle}
            fieldMeta={requestGRNJSON.fieldMeta}
            apiBaseURL={requestGRNJSON.apiBaseURL}
            formErrors={formErrors}
            showSaveBtn={requestGRNJSON.showSaveBtn}
            showSaveBtnMain={false}
            fieldData={fieldData}
            onDataChange={this.onDataChange}
          />
          <LabelCompo
            className="text-black"
            style={{ fontSize: "20px", color: DarkBlue }}
            label={"Add Product"}
          />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            sx={{ paddingTop: 2 }}
          >
            <DynamicFormWithoutSave
              showSaveBtn={true}
              showSaveBtnMain={false}
              dynamicMasterData={dynamicMasterData}
              getListById={this.getListById}
              searchList={requestGRNJSON.searchList}
              showTitle={requestGRNJSON.showTitle}
              screenTitle={requestGRNJSON.screenTitle}
              fieldMeta={requestGRNJSON.fieldMetaDetails}
              showCancel={requestGRNJSON.showCancel}
              showBackToList={requestGRNJSON.showBackToList}
              apiBaseURL={requestGRNJSON.apiBaseURL}
              showSaveNextBtn={requestGRNJSON.showSaveNextBtn}
              saveBtnText={"ADD"}
              onSave={this.onSearch}
              resetAfterSave={true}
              getDataForDisabled={this.getDataForDisabled}
            />
          </Grid>

          <br />
          <RTTable
            showPegination={false}
            columns={[
              { name: "index", title: "Sr. No." ,align: "center"},
              { name: "categoryName", title: "Category" },
              { name: "productName", title: "Product" },
              { name: "uom", title: "UOM", align: "center" },
              { name: "calStock", title: "Add To Scrap", align: "left" },
              { name: "batch", title: "Batch", align: "left" },
              { name: "qty", title: "Quantity", align: "right" },
              { name: "rate", title: "Rate", align: "right" },
              { name: "total", title: "Total", align: "right" },
              { name: "action", title: "Action" },
            ]}
            hiddenColumnNames={[]}
            tableData={rowList}
            showEditIcon={false}
            isActionColActive={true}
            showDeleteIcon={true}
            rowDelete={this.rowDelete}
          />
          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="right"
          >
            <ButtonCompo
              size="medium"
              type="Submit"
              variant="contained"
              name="Submit"
              onClick={this.onSubmitData}
            />
          </Grid>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  categoryList: state.category,
  productList: state.product,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getCategoryForStakeholder,
  getProductByCategoryForStakeHolder,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RequestGRNForm);
