import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  receivedOrderBill: {},
  billHeaderById: {},
  orderHeaderById: {},
  billDetailsById: [],
};
let URL = endpoints.stockStakeholderOrderHeader;
const receivedOrderListSlice = createSlice({
  name: "receivedOrderList",
  initialState,
  reducers: {
    orderHistorySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        receivedOrderBill: row,
        isFetch: true,
      };
    },

    billHeaderByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { billHeaderData } = action.payload;
      return {
        ...state,
        billHeaderById: billHeaderData,
        isFetch: true,
      };
    },

    billDetailsByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        billDetailsById: row,
        isFetch: true,
      };
    },

    orderHeaderByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        orderHeaderById: row,
        isFetch: true,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});
export const {
  orderHistorySuccess,
  billHeaderByIdSuccess,
  resetState,
  billDetailsByIdSuccess,
  orderHeaderByIdSuccess,
} = receivedOrderListSlice.actions;
export default receivedOrderListSlice.reducer;

export const getReceivedOrderList = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/received-order-new",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        const pendingList = content.pendingOrders.map(
          (pendingOrderObject, index) => {
            let totalQty = 0;
            pendingOrderObject.stockStakeHolderOrderDetails.map(
              (pendingDetailObj) => {
                totalQty = totalQty + +pendingDetailObj.qty;
              }
            );
            let pendingOrderListObj = {
              index: index + 1,
              id: pendingOrderObject.id === null ? "" : pendingOrderObject.id,
              orderNo:
                pendingOrderObject.orderNo === null
                  ? ""
                  : pendingOrderObject.orderNo,
              orderDate:
                pendingOrderObject.orderDate === null
                  ? ""
                  : pendingOrderObject.orderDate,
              dayDiff:
                pendingOrderObject.dayDiff === null
                  ? ""
                  : pendingOrderObject.dayDiff + " Day's Ago",
              fromOrderShCode:
                pendingOrderObject.fromOrderShCode === null
                  ? ""
                  : pendingOrderObject.fromOrderShCode,
              fromOrderOwnerName:
                pendingOrderObject.fromOrderFirmName === null
                  ? ""
                  : pendingOrderObject.fromOrderFirmName,

              fromOrderStateName:
                pendingOrderObject.fromOrderStateName === null
                  ? ""
                  : pendingOrderObject.fromOrderStateName,
              fromOrderAreaNames:
                pendingOrderObject.fromOrderAreaNames === null
                  ? ""
                  : pendingOrderObject.fromOrderAreaNames,
              totalQty: totalQty,
              cgstAmt:
                pendingOrderObject.cgstAmt === null
                  ? ""
                  : pendingOrderObject.cgstAmt.toFixed(2),
              sgstAmt:
                pendingOrderObject.sgstAmt === null
                  ? ""
                  : pendingOrderObject.sgstAmt.toFixed(2),
              igstAmt:
                pendingOrderObject.igstAmt === null
                  ? ""
                  : pendingOrderObject.igstAmt.toFixed(2),
              taxableAmt:
                pendingOrderObject.taxableAmt === null
                  ? ""
                  : pendingOrderObject.taxableAmt.toFixed(2),
              totalAmt:
                pendingOrderObject.totalAmt === null
                  ? ""
                  : pendingOrderObject.totalAmt.toFixed(2),
              discountOnbillAmt:
                pendingOrderObject.discountOnbillAmt === null
                  ? ""
                  : +pendingOrderObject.discountOnbillAmt.toFixed(2),
              itemDiscountAmt:
                pendingOrderObject.itemDiscountAmt === null
                  ? ""
                  : +pendingOrderObject.itemDiscountAmt.toFixed(2),
              discountOnbillPer:
                pendingOrderObject.discountOnbillPer === null
                  ? ""
                  : +pendingOrderObject.discountOnbillPer.toFixed(2),
              discountOnbillPerAmt:
                pendingOrderObject.discountOnbillPerAmt === null
                  ? ""
                  : +pendingOrderObject.discountOnbillPerAmt.toFixed(2),
              fromOrder:
                pendingOrderObject.fromOrder === null
                  ? ""
                  : pendingOrderObject.fromOrder,
              toOrder:
                pendingOrderObject.toOrder === null
                  ? ""
                  : pendingOrderObject.toOrder,
              igstAmt:
                pendingOrderObject.igstAmt === null
                  ? ""
                  : +pendingOrderObject.igstAmt.toFixed(2),
              stockStakeHolder:
                pendingOrderObject.stockStakeHolder === null
                  ? ""
                  : pendingOrderObject.stockStakeHolder,
              type:
                pendingOrderObject.type === null ? "" : pendingOrderObject.type,
              status:
                pendingOrderObject.status === null
                  ? ""
                  : pendingOrderObject.status,
              insertedUserId:
                pendingOrderObject.insertedUserId === null
                  ? ""
                  : pendingOrderObject.insertedUserId,
              insertDateTime:
                pendingOrderObject.insertDateTime === null
                  ? ""
                  : pendingOrderObject.insertDateTime,
            };
            return pendingOrderListObj;
          }
        );
        const billingOrderList = content.acceptedOrders.map(
          (acceptedOrderObject, index) => {
            let totalQty = 0;
            acceptedOrderObject.stockStakeHolderOrderDetails.map(
              (acceptedDetailObj) => {
                totalQty = totalQty + +acceptedDetailObj.qty;
              }
            );
            let acceptedOrderListObj = {
              index: index + 1,
              index: index + 1,
              id: acceptedOrderObject.id === null ? "" : acceptedOrderObject.id,
              orderNo:
                acceptedOrderObject.orderNo === null
                  ? ""
                  : acceptedOrderObject.orderNo,
              orderDate:
                acceptedOrderObject.orderDate === null
                  ? ""
                  : acceptedOrderObject.orderDate,
              dayDiff:
                acceptedOrderObject.dayDiff === null
                  ? ""
                  : acceptedOrderObject.dayDiff + " Day's Ago",
              fromOrderShCode:
                acceptedOrderObject.fromOrderShCode === null
                  ? ""
                  : acceptedOrderObject.fromOrderShCode,
              fromOrderOwnerName:
                acceptedOrderObject.fromOrderFirmName === null
                  ? ""
                  : acceptedOrderObject.fromOrderFirmName,

              fromOrderStateName:
                acceptedOrderObject.fromOrderStateName === null
                  ? ""
                  : acceptedOrderObject.fromOrderStateName,
              fromOrderAreaNames:
                acceptedOrderObject.fromOrderAreaNames === null
                  ? ""
                  : acceptedOrderObject.fromOrderAreaNames,
              totalQty: totalQty,
              cgstAmt:
                acceptedOrderObject.cgstAmt === null
                  ? ""
                  : acceptedOrderObject.cgstAmt.toFixed(2),
              sgstAmt:
                acceptedOrderObject.sgstAmt === null
                  ? ""
                  : acceptedOrderObject.sgstAmt.toFixed(2),
              igstAmt:
                acceptedOrderObject.igstAmt === null
                  ? ""
                  : +acceptedOrderObject.igstAmt.toFixed(2),
              taxableAmt:
                acceptedOrderObject.taxableAmt === null
                  ? ""
                  : acceptedOrderObject.taxableAmt.toFixed(2),
              totalAmt:
                acceptedOrderObject.totalAmt === null
                  ? ""
                  : acceptedOrderObject.totalAmt.toFixed(2),
              discountOnbillAmt:
                acceptedOrderObject.discountOnbillAmt === null
                  ? ""
                  : +acceptedOrderObject.discountOnbillAmt.toFixed(2),
              itemDiscountAmt:
                acceptedOrderObject.itemDiscountAmt === null
                  ? ""
                  : +acceptedOrderObject.itemDiscountAmt.toFixed(2),
              discountOnbillPer:
                acceptedOrderObject.discountOnbillPer === null
                  ? ""
                  : +acceptedOrderObject.discountOnbillPer.toFixed(2),
              discountOnbillPerAmt:
                acceptedOrderObject.discountOnbillPerAmt === null
                  ? ""
                  : +acceptedOrderObject.discountOnbillPerAmt.toFixed(2),
              fromOrder:
                acceptedOrderObject.fromOrder === null
                  ? ""
                  : acceptedOrderObject.fromOrder,
              toOrder:
                acceptedOrderObject.toOrder === null
                  ? ""
                  : acceptedOrderObject.toOrder,

              stockStakeHolder:
                acceptedOrderObject.stockStakeHolder === null
                  ? ""
                  : acceptedOrderObject.stockStakeHolder,
              type:
                acceptedOrderObject.type === null
                  ? ""
                  : acceptedOrderObject.type,
              status:
                acceptedOrderObject.status === null
                  ? ""
                  : acceptedOrderObject.status,
              insertedUserId:
                acceptedOrderObject.insertedUserId === null
                  ? ""
                  : acceptedOrderObject.insertedUserId,
              insertDateTime:
                acceptedOrderObject.insertDateTime === null
                  ? ""
                  : acceptedOrderObject.insertDateTime,
            };
            return acceptedOrderListObj;
          }
        );
        const row = {
          pendingList: pendingList,
          billingOrderList: billingOrderList,
        };
        dispatch(orderHistorySuccess({ row }));
        return { response: row, success };
      }
      return { response: {}, success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getReceivedOrderListNew = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-received-order",
    }).then(({ data, success }) => {
      if (success) {
        const content = data.data;
        const pendingList = content.pendingOrders.map(
          (pendingOrderObject, index) => {
            let totalQty = 0;
            let totalTaxableAmt = 0;
            let totalAmt = 0;
            let cgstAmt = 0;
            let sgstAmt = 0;
            let igstAmt = 0;
            pendingOrderObject.stockStakeHolderOrderDetails.map(
              (pendingDetailObj) => {
                totalQty = totalQty + +pendingDetailObj.qty;
                totalTaxableAmt =
                  totalTaxableAmt + +pendingDetailObj.taxableAmt;
                totalAmt = totalAmt + +pendingDetailObj.totalAmt;
                cgstAmt = cgstAmt + +pendingDetailObj.cgstAmt;
                sgstAmt = sgstAmt + +pendingDetailObj.sgstAmt;
                igstAmt = igstAmt + +pendingDetailObj.igstAmt;
              }
            );
            let pendingOrderListObj = {
              index: index + 1,
              id: pendingOrderObject.id === null ? "" : pendingOrderObject.id,
              orderNo:
                pendingOrderObject.orderNo === null
                  ? ""
                  : pendingOrderObject.orderNo,
              orderDate:
                pendingOrderObject.orderDate === null
                  ? ""
                  : pendingOrderObject.orderDate,
              dayDiff:
                pendingOrderObject.dayDiff === null
                  ? ""
                  : pendingOrderObject.dayDiff + " Day's Ago",
              fromOrderShCode:
                pendingOrderObject.fromOrderShCode === null
                  ? ""
                  : pendingOrderObject.fromOrderShCode,
              fromOrderOwnerName:
                pendingOrderObject.fromOrderFirmName === null
                  ? ""
                  : pendingOrderObject.fromOrderFirmName,

              fromOrderStateName:
                pendingOrderObject.fromOrderStateName === null
                  ? ""
                  : pendingOrderObject.fromOrderStateName,
              fromOrderAreaNames:
                pendingOrderObject.fromOrderAreaNames === null
                  ? ""
                  : pendingOrderObject.fromOrderAreaNames,
              totalQty: totalQty,
              cgstAmt:
                pendingOrderObject.cgstAmt === null
                  ? ""
                  : pendingOrderObject.cgstAmt.toFixed(2),
              sgstAmt:
                pendingOrderObject.sgstAmt === null
                  ? ""
                  : pendingOrderObject.sgstAmt.toFixed(2),
              igstAmt:
                pendingOrderObject.igstAmt === null
                  ? ""
                  : pendingOrderObject.igstAmt.toFixed(2),
              taxableAmt: totalTaxableAmt.toFixed(2),
              totalAmt: totalAmt.toFixed(2),
              discountOnbillAmt:
                pendingOrderObject.discountOnbillAmt === null
                  ? ""
                  : +pendingOrderObject.discountOnbillAmt.toFixed(2),
              itemDiscountAmt:
                pendingOrderObject.itemDiscountAmt === null
                  ? ""
                  : +pendingOrderObject.itemDiscountAmt.toFixed(2),
              discountOnbillPer:
                pendingOrderObject.discountOnbillPer === null
                  ? ""
                  : +pendingOrderObject.discountOnbillPer.toFixed(2),
              discountOnbillPerAmt:
                pendingOrderObject.discountOnbillPerAmt === null
                  ? ""
                  : +pendingOrderObject.discountOnbillPerAmt.toFixed(2),
              fromOrder:
                pendingOrderObject.fromOrder === null
                  ? ""
                  : pendingOrderObject.fromOrder,
              toOrder:
                pendingOrderObject.toOrder === null
                  ? ""
                  : pendingOrderObject.toOrder,
              igstAmt:
                pendingOrderObject.igstAmt === null
                  ? ""
                  : +pendingOrderObject.igstAmt.toFixed(2),
              stockStakeHolder:
                pendingOrderObject.stockStakeHolder === null
                  ? ""
                  : pendingOrderObject.stockStakeHolder,
              type:
                pendingOrderObject.type === null ? "" : pendingOrderObject.type,
              status:
                pendingOrderObject.status === null
                  ? ""
                  : pendingOrderObject.status,
              insertedUserId:
                pendingOrderObject.insertedUserId === null
                  ? ""
                  : pendingOrderObject.insertedUserId,
              insertDateTime:
                pendingOrderObject.insertDateTime === null
                  ? ""
                  : pendingOrderObject.insertDateTime,
            };
            return pendingOrderListObj;
          }
        );
        const billingOrderList = content.acceptedOrders.map(
          (acceptedOrderObject, index) => {
            let totalQty = 0;
            let totalTaxableAmt = 0;
            let totalAmt = 0;
            let cgstAmt = 0;
            let sgstAmt = 0;
            let igstAmt = 0;
            acceptedOrderObject.stockStakeHolderOrderDetails.map(
              (acceptDetailObj) => {
                totalQty = totalQty + +acceptDetailObj.qty;
                totalTaxableAmt = totalTaxableAmt + +acceptDetailObj.taxableAmt;
                totalAmt = totalAmt + +acceptDetailObj.totalAmt;
                cgstAmt = cgstAmt + +acceptDetailObj.cgstAmt;
                sgstAmt = sgstAmt + +acceptDetailObj.sgstAmt;
                igstAmt = igstAmt + +acceptDetailObj.igstAmt;
              }
            );
            let acceptedOrderListObj = {
              index: index + 1,
              id: acceptedOrderObject.id === null ? "" : acceptedOrderObject.id,
              orderNo:
                acceptedOrderObject.orderNo === null
                  ? ""
                  : acceptedOrderObject.orderNo,
              orderDate:
                acceptedOrderObject.orderDate === null
                  ? ""
                  : acceptedOrderObject.orderDate,
              dayDiff:
                acceptedOrderObject.dayDiff === null
                  ? ""
                  : acceptedOrderObject.dayDiff + " Day's Ago",
              fromOrderShCode:
                acceptedOrderObject.fromOrderShCode === null
                  ? ""
                  : acceptedOrderObject.fromOrderShCode,
              fromOrderOwnerName:
                acceptedOrderObject.fromOrderFirmName === null
                  ? ""
                  : acceptedOrderObject.fromOrderFirmName,

              fromOrderStateName:
                acceptedOrderObject.fromOrderStateName === null
                  ? ""
                  : acceptedOrderObject.fromOrderStateName,
              fromOrderAreaNames:
                acceptedOrderObject.fromOrderAreaNames === null
                  ? ""
                  : acceptedOrderObject.fromOrderAreaNames,
              totalQty: totalQty,
              cgstAmt: cgstAmt.toFixed(2),
              sgstAmt: sgstAmt.toFixed(2),
              igstAmt: igstAmt.toFixed(2),
              taxableAmt: totalTaxableAmt.toFixed(2),
              totalAmt: totalAmt.toFixed(2),
              discountOnbillAmt:
                acceptedOrderObject.discountOnbillAmt === null
                  ? ""
                  : +acceptedOrderObject.discountOnbillAmt.toFixed(2),
              itemDiscountAmt:
                acceptedOrderObject.itemDiscountAmt === null
                  ? ""
                  : +acceptedOrderObject.itemDiscountAmt.toFixed(2),
              discountOnbillPer:
                acceptedOrderObject.discountOnbillPer === null
                  ? ""
                  : +acceptedOrderObject.discountOnbillPer.toFixed(2),
              discountOnbillPerAmt:
                acceptedOrderObject.discountOnbillPerAmt === null
                  ? ""
                  : +acceptedOrderObject.discountOnbillPerAmt.toFixed(2),
              fromOrder:
                acceptedOrderObject.fromOrder === null
                  ? ""
                  : acceptedOrderObject.fromOrder,
              toOrder:
                acceptedOrderObject.toOrder === null
                  ? ""
                  : acceptedOrderObject.toOrder,

              stockStakeHolder:
                acceptedOrderObject.stockStakeHolder === null
                  ? ""
                  : acceptedOrderObject.stockStakeHolder,
              type:
                acceptedOrderObject.type === null
                  ? ""
                  : acceptedOrderObject.type,
              status:
                acceptedOrderObject.status === null
                  ? ""
                  : acceptedOrderObject.status,
              insertedUserId:
                acceptedOrderObject.insertedUserId === null
                  ? ""
                  : acceptedOrderObject.insertedUserId,
              insertDateTime:
                acceptedOrderObject.insertDateTime === null
                  ? ""
                  : acceptedOrderObject.insertDateTime,
            };
            return acceptedOrderListObj;
          }
        );
        const row = {
          pendingList: pendingList,
          billingOrderList: billingOrderList,
        };
        dispatch(orderHistorySuccess({ row }));
        return { response: row, success };
      }
      return { response: {}, success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getReceivedOrderListByDate =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/received-accepted-order-by-date?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          const pendingList = content.pendingOrders.map(
            (pendingOrderObject, index) => {
              let totalQty = 0;
              pendingOrderObject.stockStakeHolderOrderDetails.map(
                (pendingDetailObj) => {
                  totalQty = totalQty + +pendingDetailObj.qty;
                }
              );
              let pendingOrderListObj = {
                index: index + 1,
                id: pendingOrderObject.id === null ? "" : pendingOrderObject.id,
                orderNo:
                  pendingOrderObject.orderNo === null
                    ? ""
                    : pendingOrderObject.orderNo,
                orderDate:
                  pendingOrderObject.orderDate === null
                    ? ""
                    : pendingOrderObject.orderDate,
                dayDiff:
                  pendingOrderObject.dayDiff === null
                    ? ""
                    : pendingOrderObject.dayDiff + " Day's Ago",
                fromOrderShCode:
                  pendingOrderObject.fromOrderShCode === null
                    ? ""
                    : pendingOrderObject.fromOrderShCode,
                fromOrderOwnerName:
                  pendingOrderObject.fromOrderFirmName === null
                    ? ""
                    : pendingOrderObject.fromOrderFirmName,

                fromOrderStateName:
                  pendingOrderObject.fromOrderStateName === null
                    ? ""
                    : pendingOrderObject.fromOrderStateName,
                fromOrderAreaNames:
                  pendingOrderObject.fromOrderAreaNames === null
                    ? ""
                    : pendingOrderObject.fromOrderAreaNames,
                totalQty: totalQty,
                cgstAmt:
                  pendingOrderObject.cgstAmt === null
                    ? ""
                    : pendingOrderObject.cgstAmt.toFixed(2),
                sgstAmt:
                  pendingOrderObject.sgstAmt === null
                    ? ""
                    : pendingOrderObject.sgstAmt.toFixed(2),
                igstAmt:
                  pendingOrderObject.igstAmt === null
                    ? ""
                    : pendingOrderObject.igstAmt.toFixed(2),
                taxableAmt:
                  pendingOrderObject.taxableAmt === null
                    ? ""
                    : pendingOrderObject.taxableAmt.toFixed(2),
                totalAmt:
                  pendingOrderObject.totalAmt === null
                    ? ""
                    : pendingOrderObject.totalAmt.toFixed(2),
                discountOnbillAmt:
                  pendingOrderObject.discountOnbillAmt === null
                    ? ""
                    : +pendingOrderObject.discountOnbillAmt.toFixed(2),
                itemDiscountAmt:
                  pendingOrderObject.itemDiscountAmt === null
                    ? ""
                    : +pendingOrderObject.itemDiscountAmt.toFixed(2),
                discountOnbillPer:
                  pendingOrderObject.discountOnbillPer === null
                    ? ""
                    : +pendingOrderObject.discountOnbillPer.toFixed(2),
                discountOnbillPerAmt:
                  pendingOrderObject.discountOnbillPerAmt === null
                    ? ""
                    : +pendingOrderObject.discountOnbillPerAmt.toFixed(2),
                fromOrder:
                  pendingOrderObject.fromOrder === null
                    ? ""
                    : pendingOrderObject.fromOrder,
                toOrder:
                  pendingOrderObject.toOrder === null
                    ? ""
                    : pendingOrderObject.toOrder,
                igstAmt:
                  pendingOrderObject.igstAmt === null
                    ? ""
                    : +pendingOrderObject.igstAmt.toFixed(2),
                stockStakeHolder:
                  pendingOrderObject.stockStakeHolder === null
                    ? ""
                    : pendingOrderObject.stockStakeHolder,
                type:
                  pendingOrderObject.type === null
                    ? ""
                    : pendingOrderObject.type,
                status:
                  pendingOrderObject.status === null
                    ? ""
                    : pendingOrderObject.status,
                insertedUserId:
                  pendingOrderObject.insertedUserId === null
                    ? ""
                    : pendingOrderObject.insertedUserId,
                insertDateTime:
                  pendingOrderObject.insertDateTime === null
                    ? ""
                    : pendingOrderObject.insertDateTime,
              };
              return pendingOrderListObj;
            }
          );
          const billingOrderList = content.acceptedOrders.map(
            (acceptedOrderObject, index) => {
              let totalQty = 0;
              acceptedOrderObject.stockStakeHolderOrderDetails.map(
                (acceptedDetailObj) => {
                  totalQty = totalQty + +acceptedDetailObj.qty;
                }
              );
              let acceptedOrderListObj = {
                index: index + 1,
                index: index + 1,
                id:
                  acceptedOrderObject.id === null ? "" : acceptedOrderObject.id,
                orderNo:
                  acceptedOrderObject.orderNo === null
                    ? ""
                    : acceptedOrderObject.orderNo,
                orderDate:
                  acceptedOrderObject.orderDate === null
                    ? ""
                    : acceptedOrderObject.orderDate,
                dayDiff:
                  acceptedOrderObject.dayDiff === null
                    ? ""
                    : acceptedOrderObject.dayDiff + " Day's Ago",
                fromOrderShCode:
                  acceptedOrderObject.fromOrderShCode === null
                    ? ""
                    : acceptedOrderObject.fromOrderShCode,
                fromOrderOwnerName:
                  acceptedOrderObject.fromOrderFirmName === null
                    ? ""
                    : acceptedOrderObject.fromOrderFirmName,

                fromOrderStateName:
                  acceptedOrderObject.fromOrderStateName === null
                    ? ""
                    : acceptedOrderObject.fromOrderStateName,
                fromOrderAreaNames:
                  acceptedOrderObject.fromOrderAreaNames === null
                    ? ""
                    : acceptedOrderObject.fromOrderAreaNames,
                totalQty: totalQty,
                cgstAmt:
                  acceptedOrderObject.cgstAmt === null
                    ? ""
                    : acceptedOrderObject.cgstAmt.toFixed(2),
                sgstAmt:
                  acceptedOrderObject.sgstAmt === null
                    ? ""
                    : acceptedOrderObject.sgstAmt.toFixed(2),
                igstAmt:
                  acceptedOrderObject.igstAmt === null
                    ? ""
                    : +acceptedOrderObject.igstAmt.toFixed(2),
                taxableAmt:
                  acceptedOrderObject.taxableAmt === null
                    ? ""
                    : acceptedOrderObject.taxableAmt.toFixed(2),
                totalAmt:
                  acceptedOrderObject.totalAmt === null
                    ? ""
                    : acceptedOrderObject.totalAmt.toFixed(2),
                discountOnbillAmt:
                  acceptedOrderObject.discountOnbillAmt === null
                    ? ""
                    : +acceptedOrderObject.discountOnbillAmt.toFixed(2),
                itemDiscountAmt:
                  acceptedOrderObject.itemDiscountAmt === null
                    ? ""
                    : +acceptedOrderObject.itemDiscountAmt.toFixed(2),
                discountOnbillPer:
                  acceptedOrderObject.discountOnbillPer === null
                    ? ""
                    : +acceptedOrderObject.discountOnbillPer.toFixed(2),
                discountOnbillPerAmt:
                  acceptedOrderObject.discountOnbillPerAmt === null
                    ? ""
                    : +acceptedOrderObject.discountOnbillPerAmt.toFixed(2),
                fromOrder:
                  acceptedOrderObject.fromOrder === null
                    ? ""
                    : acceptedOrderObject.fromOrder,
                toOrder:
                  acceptedOrderObject.toOrder === null
                    ? ""
                    : acceptedOrderObject.toOrder,

                stockStakeHolder:
                  acceptedOrderObject.stockStakeHolder === null
                    ? ""
                    : acceptedOrderObject.stockStakeHolder,
                type:
                  acceptedOrderObject.type === null
                    ? ""
                    : acceptedOrderObject.type,
                status:
                  acceptedOrderObject.status === null
                    ? ""
                    : acceptedOrderObject.status,
                insertedUserId:
                  acceptedOrderObject.insertedUserId === null
                    ? ""
                    : acceptedOrderObject.insertedUserId,
                insertDateTime:
                  acceptedOrderObject.insertDateTime === null
                    ? ""
                    : acceptedOrderObject.insertDateTime,
              };
              return acceptedOrderListObj;
            }
          );
          const row = {
            pendingList: pendingList,
            billingOrderList: billingOrderList,
          };
          dispatch(orderHistorySuccess({ row }));
          return { response: row, success };
        }
        return { response: {}, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getReceivedOrderListByDateUpdated =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/received-accepted-order-by-date-new?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const pendingList = data.data.pendingOrders.map(
            (pendingOrderObject, index) => {
              let pendingOrderListObj = {
                index: index + 1,
                id: pendingOrderObject.id === null ? "" : pendingOrderObject.id,
                orderNo:
                  pendingOrderObject.orderNo === null
                    ? ""
                    : pendingOrderObject.orderNo,
                orderDate:
                  pendingOrderObject.orderDate === null
                    ? ""
                    : pendingOrderObject.orderDate,
                dayDiff:
                  pendingOrderObject.dayDiff === null
                    ? ""
                    : pendingOrderObject.dayDiff + " Day's Ago",
                fromOrderShCode:
                  pendingOrderObject.fromOrderShCode === null
                    ? ""
                    : pendingOrderObject.fromOrderShCode,
                fromOrderOwnerName:
                  pendingOrderObject.fromOrderFirmName === null
                    ? ""
                    : pendingOrderObject.fromOrderFirmName,

                fromOrderStateName:
                  pendingOrderObject.fromOrderStateName === null
                    ? ""
                    : pendingOrderObject.fromOrderStateName,
                fromOrderAreaNames:
                  pendingOrderObject.fromOrderAreaNames === null
                    ? ""
                    : pendingOrderObject.fromOrderAreaNames,
                totalQty:
                  pendingOrderObject.totalQty === null
                    ? ""
                    : pendingOrderObject.totalQty,
                cgstAmt:
                  pendingOrderObject.cgstAmt === null
                    ? ""
                    : pendingOrderObject.cgstAmt.toFixed(2),
                sgstAmt:
                  pendingOrderObject.sgstAmt === null
                    ? ""
                    : pendingOrderObject.sgstAmt.toFixed(2),
                igstAmt:
                  pendingOrderObject.igstAmt === null
                    ? ""
                    : pendingOrderObject.igstAmt.toFixed(2),
                taxableAmt:
                  pendingOrderObject.taxableAmt === null
                    ? ""
                    : pendingOrderObject.taxableAmt.toFixed(2),
                totalAmt:
                  pendingOrderObject.totalAmt === null
                    ? ""
                    : pendingOrderObject.totalAmt.toFixed(2),
                discountOnbillAmt:
                  pendingOrderObject.discountOnbillAmt === null
                    ? ""
                    : +pendingOrderObject.discountOnbillAmt.toFixed(2),
                itemDiscountAmt:
                  pendingOrderObject.itemDiscountAmt === null
                    ? ""
                    : +pendingOrderObject.itemDiscountAmt.toFixed(2),
                discountOnbillPer:
                  pendingOrderObject.discountOnbillPer === null
                    ? ""
                    : +pendingOrderObject.discountOnbillPer.toFixed(2),
                discountOnbillPerAmt:
                  pendingOrderObject.discountOnbillPerAmt === null
                    ? ""
                    : +pendingOrderObject.discountOnbillPerAmt.toFixed(2),
                fromOrder:
                  pendingOrderObject.fromOrder === null
                    ? ""
                    : pendingOrderObject.fromOrder,
                toOrder:
                  pendingOrderObject.toOrder === null
                    ? ""
                    : pendingOrderObject.toOrder,
                igstAmt:
                  pendingOrderObject.igstAmt === null
                    ? ""
                    : +pendingOrderObject.igstAmt.toFixed(2),
                stockStakeHolder:
                  pendingOrderObject.stockStakeHolder === null
                    ? ""
                    : pendingOrderObject.stockStakeHolder,
                type:
                  pendingOrderObject.type === null
                    ? ""
                    : pendingOrderObject.type,
                status:
                  pendingOrderObject.status === null
                    ? ""
                    : pendingOrderObject.status,
                insertedUserId:
                  pendingOrderObject.insertedUserId === null
                    ? ""
                    : pendingOrderObject.insertedUserId,
                insertDateTime:
                  pendingOrderObject.insertDateTime === null
                    ? ""
                    : pendingOrderObject.insertDateTime,
              };
              return pendingOrderListObj;
            }
          );
          const billingOrderList = data.data.acceptedOrders.map(
            (acceptedOrderObject, index) => {
              let acceptedOrderListObj = {
                index: index + 1,
                index: index + 1,
                id:
                  acceptedOrderObject.id === null ? "" : acceptedOrderObject.id,
                orderNo:
                  acceptedOrderObject.orderNo === null
                    ? ""
                    : acceptedOrderObject.orderNo,
                orderDate:
                  acceptedOrderObject.orderDate === null
                    ? ""
                    : acceptedOrderObject.orderDate,
                dayDiff:
                  acceptedOrderObject.dayDiff === null
                    ? ""
                    : acceptedOrderObject.dayDiff + " Day's Ago",
                fromOrderShCode:
                  acceptedOrderObject.fromOrderShCode === null
                    ? ""
                    : acceptedOrderObject.fromOrderShCode,
                fromOrderOwnerName:
                  acceptedOrderObject.fromOrderFirmName === null
                    ? ""
                    : acceptedOrderObject.fromOrderFirmName,

                fromOrderStateName:
                  acceptedOrderObject.fromOrderStateName === null
                    ? ""
                    : acceptedOrderObject.fromOrderStateName,
                fromOrderAreaNames:
                  acceptedOrderObject.fromOrderAreaNames === null
                    ? ""
                    : acceptedOrderObject.fromOrderAreaNames,
                totalQty:
                  acceptedOrderObject.totalQty === null
                    ? ""
                    : acceptedOrderObject.totalQty,
                cgstAmt:
                  acceptedOrderObject.cgstAmt === null
                    ? ""
                    : acceptedOrderObject.cgstAmt.toFixed(2),
                sgstAmt:
                  acceptedOrderObject.sgstAmt === null
                    ? ""
                    : acceptedOrderObject.sgstAmt.toFixed(2),
                igstAmt:
                  acceptedOrderObject.igstAmt === null
                    ? ""
                    : +acceptedOrderObject.igstAmt.toFixed(2),
                taxableAmt:
                  acceptedOrderObject.taxableAmt === null
                    ? ""
                    : acceptedOrderObject.taxableAmt.toFixed(2),
                totalAmt:
                  acceptedOrderObject.totalAmt === null
                    ? ""
                    : acceptedOrderObject.totalAmt.toFixed(2),
                discountOnbillAmt:
                  acceptedOrderObject.discountOnbillAmt === null
                    ? ""
                    : +acceptedOrderObject.discountOnbillAmt.toFixed(2),
                itemDiscountAmt:
                  acceptedOrderObject.itemDiscountAmt === null
                    ? ""
                    : +acceptedOrderObject.itemDiscountAmt.toFixed(2),
                discountOnbillPer:
                  acceptedOrderObject.discountOnbillPer === null
                    ? ""
                    : +acceptedOrderObject.discountOnbillPer.toFixed(2),
                discountOnbillPerAmt:
                  acceptedOrderObject.discountOnbillPerAmt === null
                    ? ""
                    : +acceptedOrderObject.discountOnbillPerAmt.toFixed(2),
                fromOrder:
                  acceptedOrderObject.fromOrder === null
                    ? ""
                    : acceptedOrderObject.fromOrder,
                toOrder:
                  acceptedOrderObject.toOrder === null
                    ? ""
                    : acceptedOrderObject.toOrder,

                stockStakeHolder:
                  acceptedOrderObject.stockStakeHolder === null
                    ? ""
                    : acceptedOrderObject.stockStakeHolder,
                type:
                  acceptedOrderObject.type === null
                    ? ""
                    : acceptedOrderObject.type,
                status:
                  acceptedOrderObject.status === null
                    ? ""
                    : acceptedOrderObject.status,
                insertedUserId:
                  acceptedOrderObject.insertedUserId === null
                    ? ""
                    : acceptedOrderObject.insertedUserId,
                insertDateTime:
                  acceptedOrderObject.insertDateTime === null
                    ? ""
                    : acceptedOrderObject.insertDateTime,
              };
              return acceptedOrderListObj;
            }
          );
          const row = {
            pendingList: pendingList,
            billingOrderList: billingOrderList,
          };
          dispatch(orderHistorySuccess({ row }));
          return { response: row, success };
        }
        return { response: {}, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getReceivedBillHeaderById =
  ({ billHeaderId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + billHeaderId,
      }).then(({ data, success }) => {
        if (success) {
          const billHeaderObject = data.data;
          let finalTotal = 0;
          let changedTotal = 0;

          let billHeaderData = {
            id: billHeaderObject.id === null ? "" : billHeaderObject.id,
            stockStakeHolderId:
              billHeaderObject.stockStakeHolderId === null
                ? ""
                : billHeaderObject.stockStakeHolderId,
            outLet:
              billHeaderObject.outLet === null ? "" : billHeaderObject.outLet,
            billNo:
              billHeaderObject.billNo === null ? "" : billHeaderObject.billNo,
            billDate:
              billHeaderObject.billDate === null
                ? ""
                : billHeaderObject.billDate,
            fromOrder:
              billHeaderObject.fromOrder === null
                ? ""
                : billHeaderObject.fromOrder,
            toOrder:
              billHeaderObject.toOrder === null ? "" : billHeaderObject.toOrder,
            orderNo:
              billHeaderObject.orderNo === null ? "" : billHeaderObject.orderNo,
            orderDate:
              billHeaderObject.orderDate === null
                ? ""
                : billHeaderObject.orderDate,
            discountOnbillPer:
              billHeaderObject.discountOnbillPer === null
                ? ""
                : billHeaderObject.discountOnbillPer,
            taxableAmt:
              billHeaderObject.taxableAmt === null
                ? ""
                : billHeaderObject.taxableAmt.toFixed(2),
            cgstAmt:
              billHeaderObject.cgstAmt === null ? "" : billHeaderObject.cgstAmt,
            sgstAmt:
              billHeaderObject.sgstAmt === null ? "" : billHeaderObject.sgstAmt,
            igstAmt:
              billHeaderObject.igstAmt === null ? "" : billHeaderObject.igstAmt,
            cessAmt:
              billHeaderObject.cessAmt === null ? "" : billHeaderObject.cessAmt,
            remainingAmt:
              billHeaderObject.remainingAmt === null
                ? ""
                : billHeaderObject.remainingAmt,
            totalAmt:
              billHeaderObject.totalAmt === null
                ? ""
                : billHeaderObject.totalAmt.toFixed(2),
            totalTax: (
              (billHeaderObject.totalAmt === null
                ? ""
                : billHeaderObject.totalAmt.toFixed(2)) -
              (billHeaderObject.taxableAmt === null
                ? ""
                : billHeaderObject.taxableAmt.toFixed(2))
            ).toFixed(2),
            status:
              billHeaderObject.status === null ? "" : billHeaderObject.status,
            remark:
              billHeaderObject.remark === null ? "" : billHeaderObject.remark,
            acknoledgeNo:
              billHeaderObject.acknoledgeNo === null
                ? ""
                : billHeaderObject.acknoledgeNo,
            payoutIsSettle:
              billHeaderObject.payoutIsSettle === null
                ? ""
                : billHeaderObject.payoutIsSettle,
            isSettle:
              billHeaderObject.isSettle === null
                ? ""
                : billHeaderObject.isSettle,
            insertedUserId:
              billHeaderObject.insertedUserId === null
                ? ""
                : billHeaderObject.insertedUserId,
            stockStakeHolderOrderDetails:
              billHeaderObject.stockStakeHolderOrderDetails === null
                ? ""
                : billHeaderObject.stockStakeHolderOrderDetails.map(
                    (billHistoryObject, index) => {
                      let billHistoryData = {
                        index: index + 1,
                        id:
                          billHistoryObject.id === null
                            ? ""
                            : billHistoryObject.id,
                        name:
                          billHistoryObject.product === null
                            ? ""
                            : billHistoryObject.product.name,
                        product:
                          billHistoryObject.product === null
                            ? ""
                            : billHistoryObject.product,
                        uom:
                          billHistoryObject.uom === null
                            ? ""
                            : billHistoryObject.uom,
                        stakeHolderUom:
                          billHistoryObject.product === null
                            ? ""
                            : billHistoryObject.product.stakeHolderUom == null
                            ? ""
                            : billHistoryObject.product.stakeHolderUom.name,
                        orderNo:
                          billHistoryObject.orderNo === null
                            ? ""
                            : billHistoryObject.orderNo,
                        orderDate:
                          billHistoryObject.orderDate === null
                            ? ""
                            : billHistoryObject.orderDate,
                        secondaryMrp:
                          billHistoryObject.secondaryMrp === null
                            ? ""
                            : billHistoryObject.secondaryMrp,
                        conversionValue:
                          billHistoryObject.conversionValue === null
                            ? ""
                            : billHistoryObject.conversionValue,
                        discountOnbillPer:
                          billHistoryObject.discountOnbillPer === null
                            ? ""
                            : billHistoryObject.discountOnbillPer,
                        discountOnbillPerAmt:
                          billHistoryObject.discountOnbillPerAmt === null
                            ? ""
                            : billHistoryObject.discountOnbillPerAmt,
                        discountOnbillamt:
                          billHistoryObject.discountOnbillamt === null
                            ? ""
                            : billHistoryObject.discountOnbillamt,
                        itemDiscAmt:
                          billHistoryObject.itemDiscAmt === null
                            ? ""
                            : billHistoryObject.itemDiscAmt,
                        cgstAmt:
                          billHistoryObject.cgstAmt === null
                            ? ""
                            : billHistoryObject.cgstAmt,
                        sgstAmt:
                          billHistoryObject.sgstAmt === null
                            ? ""
                            : billHistoryObject.sgstAmt.toFixed(2),
                        taxableAmt:
                          billHistoryObject.taxableAmt === null
                            ? ""
                            : billHistoryObject.taxableAmt.toFixed(2),
                        totalAmt:
                          billHistoryObject.totalAmt === null
                            ? ""
                            : (+billHistoryObject.totalAmt).toFixed(2),
                        distcountAmt:
                          billHistoryObject.distcountAmt === null
                            ? ""
                            : billHistoryObject.distcountAmt,
                        cgstPer:
                          billHistoryObject.cgstPer === null
                            ? ""
                            : billHistoryObject.cgstPer.toFixed(2),
                        sgstPer:
                          billHistoryObject.sgstPer === null
                            ? ""
                            : billHistoryObject.sgstPer.toFixed(2),
                        igstPer:
                          billHistoryObject.igstPer === null
                            ? ""
                            : billHistoryObject.igstPer.toFixed(2),
                        igstAmt:
                          billHistoryObject.igstAmt === null
                            ? ""
                            : billHistoryObject.igstAmt.toFixed(2),

                        stockStakeHolder:
                          billHistoryObject.stockStakeHolder === null
                            ? ""
                            : billHistoryObject.stockStakeHolder,
                        type:
                          billHistoryObject.type === null
                            ? ""
                            : billHistoryObject.type,
                        rate:
                          billHistoryObject.rate === null
                            ? ""
                            : billHistoryObject.rate.toFixed(2),
                        productStock: 0,
                        billQty:
                          billHistoryObject.qty === null
                            ? ""
                            : billHistoryObject.qty.toFixed(2),
                        qty:
                          billHistoryObject.qty === null
                            ? ""
                            : billHistoryObject.qty,
                        // freeQty:
                        //   billHistoryObject.freeQty === null
                        //     ? 0
                        //     : billHistoryObject.freeQty,
                        remainingStock: 0,
                        freeQty: 0,
                        itemDiscPer:
                          billHistoryObject.itemDiscPer === null
                            ? ""
                            : billHistoryObject.itemDiscPer,
                        total:
                          billHistoryObject.totalAmt === null
                            ? ""
                            : (+billHistoryObject.totalAmt).toFixed(2),
                        discountAmt: 0,

                        insertedUserId:
                          billHistoryObject.insertedUserId === null
                            ? ""
                            : billHistoryObject.insertedUserId,
                        insertDateTime:
                          billHistoryObject.insertDateTime === null
                            ? ""
                            : billHistoryObject.insertDateTime,
                        isBillGenerated:
                          billHistoryObject.isBillGenerated === null
                            ? ""
                            : billHistoryObject.isBillGenerated,
                        qtyError: 0,
                        freeQtyError: 0,
                        discountError: 0,
                        isDisable: false,
                      };
                      return billHistoryData;
                    }
                  ),
          };
          dispatch(billHeaderByIdSuccess({ billHeaderData }));
          dispatch(
            billDetailsByIdSuccess({
              row: billHeaderData.stockStakeHolderOrderDetails,
            })
          );
          return {
            response: billHeaderData,
            success,
            prodList: billHeaderData.stockStakeHolderOrderDetails,
          };
        }
        return { response: {}, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getReceivedBillHeaderByIdForGenerateBills =
  ({ billHeaderId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/pending-order-and-bill-by-id/" + billHeaderId,
      }).then(({ data, success }) => {
        if (success) {
          const billHeaderObject = data.data;
          let finalTotal = 0;
          let changedTotal = 0;

          let billHeaderData = {
            id: billHeaderObject.id === null ? "" : billHeaderObject.id,
            stockStakeHolderId:
              billHeaderObject.stockStakeHolderId === null
                ? ""
                : billHeaderObject.stockStakeHolderId,
            outLet:
              billHeaderObject.outLet === null ? "" : billHeaderObject.outLet,
            billNo:
              billHeaderObject.billNo === null ? "" : billHeaderObject.billNo,
            billDate:
              billHeaderObject.billDate === null
                ? ""
                : billHeaderObject.billDate,
            fromOrder:
              billHeaderObject.fromOrder === null
                ? ""
                : billHeaderObject.fromOrder,
            toOrder:
              billHeaderObject.toOrder === null ? "" : billHeaderObject.toOrder,
            orderNo:
              billHeaderObject.orderNo === null ? "" : billHeaderObject.orderNo,
            orderDate:
              billHeaderObject.orderDate === null
                ? ""
                : billHeaderObject.orderDate,
            discountOnbillPer:
              billHeaderObject.discountOnbillPer === null
                ? ""
                : billHeaderObject.discountOnbillPer,
            taxableAmt:
              billHeaderObject.taxableAmt === null
                ? ""
                : billHeaderObject.taxableAmt.toFixed(2),
            cgstAmt:
              billHeaderObject.cgstAmt === null ? "" : billHeaderObject.cgstAmt,
            sgstAmt:
              billHeaderObject.sgstAmt === null ? "" : billHeaderObject.sgstAmt,
            igstAmt:
              billHeaderObject.igstAmt === null ? "" : billHeaderObject.igstAmt,
            cessAmt:
              billHeaderObject.cessAmt === null ? "" : billHeaderObject.cessAmt,
            remainingAmt:
              billHeaderObject.remainingAmt === null
                ? ""
                : billHeaderObject.remainingAmt,
            totalAmt:
              billHeaderObject.totalAmt === null
                ? ""
                : billHeaderObject.totalAmt.toFixed(2),
            totalTax: (
              (billHeaderObject.totalAmt === null
                ? ""
                : billHeaderObject.totalAmt.toFixed(2)) -
              (billHeaderObject.taxableAmt === null
                ? ""
                : billHeaderObject.taxableAmt.toFixed(2))
            ).toFixed(2),
            status:
              billHeaderObject.status === null ? "" : billHeaderObject.status,
            remark:
              billHeaderObject.remark === null ? "" : billHeaderObject.remark,
            acknoledgeNo:
              billHeaderObject.acknoledgeNo === null
                ? ""
                : billHeaderObject.acknoledgeNo,
            payoutIsSettle:
              billHeaderObject.payoutIsSettle === null
                ? ""
                : billHeaderObject.payoutIsSettle,
            isSettle:
              billHeaderObject.isSettle === null
                ? ""
                : billHeaderObject.isSettle,
            stockStakeHolderOrderDetails:
              billHeaderObject.stockStakeHolderOrderDetails === null
                ? ""
                : billHeaderObject.stockStakeHolderOrderDetails.map(
                    (billHistoryObject, index) => {
                      let billHistoryData = {
                        index: index + 1,
                        id:
                          billHistoryObject.id === null
                            ? ""
                            : billHistoryObject.id,
                        name:
                          billHistoryObject.product === null
                            ? ""
                            : billHistoryObject.product.name,
                        product:
                          billHistoryObject.product === null
                            ? ""
                            : billHistoryObject.product,
                        uom:
                          billHistoryObject.uom === null
                            ? ""
                            : billHistoryObject.uom,
                        stakeHolderUom:
                          billHistoryObject.product === null
                            ? ""
                            : billHistoryObject.product.stakeHolderUom == null
                            ? ""
                            : billHistoryObject.product.stakeHolderUom.name,
                        orderNo:
                          billHistoryObject.orderNo === null
                            ? ""
                            : billHistoryObject.orderNo,
                        orderDate:
                          billHistoryObject.orderDate === null
                            ? ""
                            : billHistoryObject.orderDate,
                        secondaryMrp:
                          billHistoryObject.secondaryMrp === null
                            ? ""
                            : billHistoryObject.secondaryMrp,
                        conversionValue:
                          billHistoryObject.conversionValue === null
                            ? ""
                            : billHistoryObject.conversionValue,
                        discountOnbillPer:
                          billHistoryObject.discountOnbillPer === null
                            ? ""
                            : billHistoryObject.discountOnbillPer,
                        discountOnbillPerAmt:
                          billHistoryObject.discountOnbillPerAmt === null
                            ? ""
                            : billHistoryObject.discountOnbillPerAmt,
                        discountOnbillamt:
                          billHistoryObject.discountOnbillamt === null
                            ? ""
                            : billHistoryObject.discountOnbillamt,
                        itemDiscAmt:
                          billHistoryObject.itemDiscAmt === null
                            ? ""
                            : billHistoryObject.itemDiscAmt,
                        cgstAmt:
                          billHistoryObject.cgstAmt === null
                            ? ""
                            : billHistoryObject.cgstAmt,
                        sgstAmt:
                          billHistoryObject.sgstAmt === null
                            ? ""
                            : billHistoryObject.sgstAmt.toFixed(2),
                        taxableAmt:
                          billHistoryObject.taxableAmt === null
                            ? ""
                            : billHistoryObject.taxableAmt.toFixed(2),
                        totalAmt:
                          billHistoryObject.totalAmt === null
                            ? ""
                            : (+billHistoryObject.totalAmt).toFixed(2),
                        distcountAmt:
                          billHistoryObject.distcountAmt === null
                            ? ""
                            : billHistoryObject.distcountAmt,
                        cgstPer:
                          billHistoryObject.cgstPer === null
                            ? ""
                            : billHistoryObject.cgstPer.toFixed(2),
                        sgstPer:
                          billHistoryObject.sgstPer === null
                            ? ""
                            : billHistoryObject.sgstPer.toFixed(2),
                        igstPer:
                          billHistoryObject.igstPer === null
                            ? ""
                            : billHistoryObject.igstPer.toFixed(2),
                        igstAmt:
                          billHistoryObject.igstAmt === null
                            ? ""
                            : billHistoryObject.igstAmt.toFixed(2),

                        stockStakeHolder:
                          billHistoryObject.stockStakeHolder === null
                            ? ""
                            : billHistoryObject.stockStakeHolder,
                        type:
                          billHistoryObject.type === null
                            ? ""
                            : billHistoryObject.type,
                        rate:
                          billHistoryObject.rate === null
                            ? ""
                            : billHistoryObject.rate.toFixed(2),
                        productStock: 0,
                        billQty:
                          billHistoryObject.qty === null
                            ? ""
                            : billHistoryObject.qty.toFixed(2),
                        qty:
                          billHistoryObject.qty === null
                            ? ""
                            : billHistoryObject.qty,
                        // freeQty:
                        //   billHistoryObject.freeQty === null
                        //     ? 0
                        //     : billHistoryObject.freeQty,
                        remainingStock: 0,
                        freeQty: 0,
                        itemDiscPer:
                          billHistoryObject.itemDiscPer === null
                            ? ""
                            : billHistoryObject.itemDiscPer,
                        total:
                          billHistoryObject.totalAmt === null
                            ? ""
                            : (+billHistoryObject.totalAmt).toFixed(2),
                        discountAmt: 0,

                        insertedUserId:
                          billHistoryObject.insertedUserId === null
                            ? ""
                            : billHistoryObject.insertedUserId,
                        insertDateTime:
                          billHistoryObject.insertDateTime === null
                            ? ""
                            : billHistoryObject.insertDateTime,
                        isBillGenerated:
                          billHistoryObject.isBillGenerated === null
                            ? ""
                            : billHistoryObject.isBillGenerated,
                        qtyError: 0,
                        freeQtyError: 0,
                        discountError: 0,
                        isDisable: false,
                      };
                      return billHistoryData;
                    }
                  ),
          };
          dispatch(billHeaderByIdSuccess({ billHeaderData }));
          dispatch(
            billDetailsByIdSuccess({
              row: billHeaderData.stockStakeHolderOrderDetails,
            })
          );
          return {
            response: billHeaderData,
            success,
            prodList: billHeaderData.stockStakeHolderOrderDetails,
          };
        }
        return { response: {}, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getReceivedOrderHeader =
  ({ billHeaderId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-id?id=" + billHeaderId,
      }).then(({ data, success }) => {
        if (success) {
          const billHeaderObject = data.data;
          let billHeaderData = {
            id: billHeaderObject.id === null ? "" : billHeaderObject.id,
            stockStakeHolderId:
              billHeaderObject.stockStakeHolderId === null
                ? ""
                : billHeaderObject.stockStakeHolderId,
            outLet:
              billHeaderObject.outLet === null ? "" : billHeaderObject.outLet,
            billNo:
              billHeaderObject.billNo === null ? "" : billHeaderObject.billNo,
            billDate:
              billHeaderObject.billDate === null
                ? ""
                : billHeaderObject.billDate,
            fromOrder:
              billHeaderObject.fromOrder === null
                ? ""
                : billHeaderObject.fromOrder,
            fromOrderOwnerName:
              billHeaderObject.fromOrderOwnerName === null
                ? ""
                : billHeaderObject.fromOrderOwnerName,
            fromOrderFirmName:
              billHeaderObject.fromOrderFirmName === null
                ? ""
                : billHeaderObject.fromOrderFirmName,
            toOrder:
              billHeaderObject.toOrder === null ? "" : billHeaderObject.toOrder,
            orderNo:
              billHeaderObject.orderNo === null ? "" : billHeaderObject.orderNo,
            orderDate:
              billHeaderObject.orderDate === null
                ? ""
                : billHeaderObject.orderDate,
            discountOnbillPer:
              billHeaderObject.discountOnbillPer === null
                ? ""
                : billHeaderObject.discountOnbillPer,
            taxableAmt:
              billHeaderObject.taxableAmt === null
                ? ""
                : billHeaderObject.taxableAmt.toFixed(2),
            cgstAmt:
              billHeaderObject.cgstAmt === null ? "" : billHeaderObject.cgstAmt,
            sgstAmt:
              billHeaderObject.sgstAmt === null ? "" : billHeaderObject.sgstAmt,
            igstAmt:
              billHeaderObject.igstAmt === null ? "" : billHeaderObject.igstAmt,
            cessAmt:
              billHeaderObject.cessAmt === null ? "" : billHeaderObject.cessAmt,
            remainingAmt:
              billHeaderObject.remainingAmt === null
                ? ""
                : billHeaderObject.remainingAmt,
            totalAmt:
              billHeaderObject.totalAmt === null
                ? ""
                : billHeaderObject.totalAmt.toFixed(2),
            totalTax: (
              (billHeaderObject.totalAmt === null
                ? ""
                : billHeaderObject.totalAmt.toFixed(2)) -
              (billHeaderObject.taxableAmt === null
                ? ""
                : billHeaderObject.taxableAmt.toFixed(2))
            ).toFixed(2),
            status:
              billHeaderObject.status === null ? "" : billHeaderObject.status,
            acknoledgeNo:
              billHeaderObject.acknoledgeNo === null
                ? ""
                : billHeaderObject.acknoledgeNo,
            payoutIsSettle:
              billHeaderObject.payoutIsSettle === null
                ? ""
                : billHeaderObject.payoutIsSettle,
            isSettle:
              billHeaderObject.isSettle === null
                ? ""
                : billHeaderObject.isSettle,
          };
          dispatch(
            orderHeaderByIdSuccess({
              row: billHeaderData.stockStakeHolderOrderDetails,
            })
          );
          return { response: billHeaderData, success };
        }
        return { response: {}, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const setReceivedOrderHeader =
  ({ row }) =>
  async (dispatch) => {
    dispatch(orderHeaderByIdSuccess({ row }));
  };

export const setReceivedOrderBillData =
  ({ row }) =>
  async (dispatch) => {
    dispatch(billDetailsByIdSuccess({ row }));
  };
