import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { imageUrl } from "../../config/Url";
import endpoint from "../../config/endpoints";
import { langugae } from "../../config/languageJSON";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import "./billPDFCss.css";
import moment from "moment";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";

const Loadsheet = ({ showLoador, showNotification }) => {
  const params = useParams();
  const [billList, setBill] = useState([]);
  let lang = localStorage.getItem("lang");
  let paymentsummaryID = localStorage.getItem("paymentsummaryID");

  useEffect(() => {
    // window.print();
    if (navigator.onLine) {
      console.log(params);
      let lang = localStorage.getItem("lang");
      let paymentsummaryID = localStorage.getItem("paymentsummaryID");

      let paymentSummaryData = {
        outletBillHeaderIds: paymentsummaryID.split(","),
        languageId: lang,
      };

      showLoador({ loador: true });
      apiPost({
        url: endpoint.outletBillHeader + "/get-payment-summary",
        postBody: paymentSummaryData,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
        } else {
          console.log(data.data);
          setBill(data.data);
          window.print();
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }, []);
  let amt = 0;
  return (
    <>
      <div style={{ marginRight: "20px", marginLeft: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: 20,
            fontWeight: 600,
          }}
        >
          {langugae[2].paymentSumm ? langugae[2].paymentSumm : ""} {"From "}
          {params.fromDate} {"To "}
          {params.toDate}
        </div>

        <div style={{ borderCollapse: "collapse", marginTop: "8px" }}>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <thead>
              <tr>
                <th className="thsmallcell">#</th>
                <th className="thsmallcell">
                  {langugae[2].billNO ? langugae[2].billNO : ""}
                </th>
                <th className="thsmallcell">
                  {langugae[2].outletName ? langugae[2].outletName : ""}
                </th>
                <th className="thsmallcell">
                  {langugae[2].ownerName ? langugae[2].ownerName : ""}
                </th>
                <th className="thsmallcell">
                  {langugae[2].beatName ? langugae[2].beatName : ""}
                </th>
                <th className="thsmallcell">
                  {langugae[2].mobileNo ? langugae[2].mobileNo : ""}
                </th>
                <th className="thsmallcell">
                  {langugae[2].amount ? langugae[2].amount : ""}
                </th>
                <th className="thsmallcell">
                  {langugae[2].recAmt ? langugae[2].recAmt : ""}
                </th>
              </tr>
            </thead>

            <tbody style={{ width: "100%" }}>
              {billList.map((rowData, index) => {
                amt = amt + rowData.amt;
                return (
                  <>
                    <tr>
                      <td
                        align={"left"}
                        style={{ fontWeight: 400 }}
                        className="tdsmallcell"
                      >
                        {index + 1}
                      </td>
                      <td
                        align={"left"}
                        style={{ fontWeight: 400 }}
                        className="tdsmallcell"
                      >
                        {rowData.billNo}
                      </td>
                      <td
                        align={"left"}
                        style={{ fontWeight: 400 }}
                        className="tdsmallcell"
                      >
                        {rowData.firmName}
                      </td>
                      <td
                        align={"left"}
                        style={{ fontWeight: 400 }}
                        className="tdsmallcell"
                      >
                        {rowData.ownerName}
                      </td>
                      <td
                        align={"left"}
                        style={{ fontWeight: 400 }}
                        className="tdsmallcell"
                      >
                        {rowData.beatName}
                      </td>
                      <td
                        align={"center"}
                        style={{ fontWeight: 400 }}
                        className="tdsmallcell"
                      >
                        {rowData.mobileNo}
                      </td>
                      <td
                        align={"right"}
                        style={{ fontWeight: 400 }}
                        className="tdsmallcell"
                      >
                        {rowData.amt.toFixed(2)}
                      </td>
                      <td
                        align={"left"}
                        style={{ fontWeight: 400 }}
                        className="tdsmallcell"
                      ></td>
                    </tr>
                  </>
                );
              })}
              <tr>
                <td
                  align={"left"}
                  style={{ fontWeight: 600 }}
                  className="tdsmallcell"
                >
                  {langugae[2].total ? langugae[2].total : ""}
                </td>
                <td
                  align={"left"}
                  style={{ fontWeight: 600 }}
                  className="tdsmallcell"
                ></td>
                <td
                  align={"left"}
                  style={{ fontWeight: 600 }}
                  className="tdsmallcell"
                ></td>
                <td
                  align={"left"}
                  style={{ fontWeight: 600 }}
                  className="tdsmallcell"
                ></td>
                <td
                  align={"left"}
                  style={{ fontWeight: 600 }}
                  className="tdsmallcell"
                ></td>
                <td
                  align={"left"}
                  style={{ fontWeight: 600 }}
                  className="tdsmallcell"
                ></td>
                <td
                  align={"right"}
                  style={{ fontWeight: 600 }}
                  className="tdsmallcell"
                >
                  {amt.toFixed(2)}
                </td>
                <td
                  align={"left"}
                  style={{ fontWeight: 600 }}
                  className="tdsmallcell"
                ></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showNotification,
  showLoador,
};
export default connect(mapStateToProps, mapDispatchToProps)(Loadsheet);
