import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { batchwiseOutletBillingJSON } from "../../DynamicFormsJson/Transaction/batchwiseOutletBillingForm";
import { getAreaByUserType } from "../../Slice/area.slice";
import { getBeatByArea } from "../../Slice/beat.slice";
import { getCategoryOutletAndDateWise } from "../../Slice/category.slice";
import { getItemwiseBatch } from "../../Slice/manageStock.slice";
import { getOutletByBeatId } from "../../Slice/outlet.slice";
import { getProductByCategoryOutletAndDateWise } from "../../Slice/product.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { DarkBlue } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  addAtleastProductMsg,
  deleteWarningMsg,
  noInternetMsg,
  serverMsg,
} from "../../config/messageconstant";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import BatchwiseOutletBillingHeaderForm from "../Request GRN/RequestGRNHeaderForm";
import BatchwiseBillingWithOffer from "./BatchwiseBillingWithOffer";
import BatchwisePopUpForOffer from "./BatchwisePopUpForOffer";
class BatchwiseOutletBillingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowList: [],
      formErrors: {},
      fieldData: { type: "1" },
      open: false,
      openBill: false,
      offerBillData: "",
      offerBillDataToSave: "",
      outletId: "",
      batchwiseStockDetailList: [],
      dynamicMasterData: {
        areaDetail: this.props.areaList.area,
        beatDetail: this.props.beatList.beat,
        outletDetail: this.props.outletList.outlets,
        categoryDetail: this.props.categoryList.categoryOutletAndDateWise,
        batchDetail: this.props.batchList.itemwiseBatchData,
        productDetail:
          this.props.productList.productByCategoryAndOutletAndDateWise,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.area !== nextProps.areaList?.area) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.area,
          },
        });
      }
    }

    if (this.props.beatList !== nextProps.beatList) {
      if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            beatDetail: nextProps.beatList?.beat,
          },
        });
      }
    }

    if (this.props.outletList !== nextProps.outletList) {
      if (this.props.outletList?.outlets !== nextProps.outletList?.outlets) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            outletDetail: nextProps.outletList?.outlets,
          },
        });
      }
    }
    if (
      this.props.categoryList.categoryOutletAndDateWise !==
      nextProps.categoryList.categoryOutletAndDateWise
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          categoryDetail: nextProps.categoryList.categoryOutletAndDateWise,
        },
      });
    }
    if (this.props.productList !== nextProps.productList) {
      if (
        this.props.productList?.productByCategoryAndOutletAndDateWise !==
        nextProps.productList?.productByCategoryAndOutletAndDateWise
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            productDetail:
              nextProps.productList?.productByCategoryAndOutletAndDateWise,
          },
        });
      }
    }
    if (this.props.batchList !== nextProps.batchList) {
      if (
        this.props.batchList.itemwiseBatchData !==
        nextProps.batchList.itemwiseBatchData
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            batchDetail: nextProps.batchList.itemwiseBatchData,
          },
        });
      }
    }
  }

  getListById = async (data) => {
    const {
      getBeatByArea,
      getProductByCategoryOutletAndDateWise,
      getOutletByBeatId,
      showLoador,
      showNotification,
      getCategoryOutletAndDateWise,
      getItemwiseBatch,
      productList,
    } = this.props;
    const { outletId } = this.state;
    if (navigator.onLine) {
      if (data.area) {
        showLoador({ loador: true });
        await getBeatByArea({ areaId: data.area ? data.area : 0 }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.beat) {
        showLoador({ loador: true });
        await getOutletByBeatId({
          beatId: data.beat.id,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        });
      }
      if (data.category) {
        showLoador({ loador: true });
        await getProductByCategoryOutletAndDateWise({
          orderDate: this.state.fieldData.date.format("DD-MM-YYYY"),
          categoryId: data.category,
          outletId: outletId,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.outletId && data.date) {
        showLoador({ loador: true });
        this.setState({ outletId: data.outletId });
        await getCategoryOutletAndDateWise({
          orderDate: data.date.format("DD-MM-YYYY"),
          outletId: data.outletId,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        });
      }
      if (data.product && this.state.fieldData.date) {
        let productId =
          productList?.productByCategoryAndOutletAndDateWise.filter(
            (row) => row.outletOrderDetail == data.product
          );
        showLoador({ loador: true });
        await getItemwiseBatch({
          orderDate: this.state.fieldData.date.format("DD-MM-YYYY"),
          productId: productId[0].productId,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        });
      }
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  async componentDidMount() {
    this.setState({
      rowList: [],
    });
    const { showLoador, showNotification, getAreaByUserType } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getAreaByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  }
  onSearch = (data) => {
    const { rowList, dynamicMasterData, batchwiseStockDetailList } = this.state;
    let productList = dynamicMasterData["productDetail"]
      ? dynamicMasterData["productDetail"].filter(
        (row) => row.id == data.product
      )
      : [];
    let catList = dynamicMasterData["categoryDetail"]
      ? dynamicMasterData["categoryDetail"].filter(
        (row) => row.id == data.category
      )
      : [];
    this.setState({
      rowList: [
        ...rowList,
        {
          index: rowList.length + 1,
          categoryName: catList.length != 0 ? catList[0].name : "-",
          productName: productList.length != 0 ? productList[0].name : "-",
          productId: productList.length != 0 ? productList[0].productId : "-",
          mrp: productList.length != 0 ? productList[0].mrp : "-",
          uom: productList.length != 0 ? productList[0].outletUomName : "-",
          batch: data.batch != 0 ? data.batch : "-",
          uomConversionValue:
            productList.length != 0 ? productList[0].uomConversionValue : "-",
          cgstPer: productList.length != 0 ? productList[0].cgstPer : "-",
          sgstPer: productList.length != 0 ? productList[0].sgstPer : "-",
          igstPer: productList.length != 0 ? productList[0].igstPer : "-",
          uomId: productList.length != 0 ? productList[0].uomId : "-",
          rate: data.rate != 0 ? data.rate : "-",
          qty: data.qty != 0 ? data.qty : "-",
          total: (+data.rate * +data.qty).toFixed(2),
          freeQty: data.freeQty,
          itemDiscPer: data.itemDiscPer,
          outletOrderDetail:
            productList.length != 0 ? productList[0].outletOrderDetail : "-",
          ...data,
        },
      ],
    });
    let batchList = [];
    if (batchwiseStockDetailList.length !== 0) {
      batchwiseStockDetailList.map((stockData, index) => {
        if (data.batch == stockData.batch) {
          batchwiseStockDetailList.splice(index, 1);
          let stockDataNew = {
            batch: stockData.batch,
            qty: +stockData.qty + +data.qty,
            secondaryClosingQty: data.secondaryClosingQty,
          };
          batchwiseStockDetailList.push(stockDataNew);
          return stockDataNew;
        }
      });
    } else {
      let stockDataNew = {
        batch: data.batch,
        qty: +data.qty,
        secondaryClosingQty: data.secondaryClosingQty,
      };
      batchwiseStockDetailList.push(stockDataNew);
    }
    let batchData = {
      batchId: data.batch,
      stock: +data.secondaryClosingQty,
    };
    batchList.push(batchData);
  };

  onSubmitData = () => {
    const { showNotification } = this.props;
    if (this.checkValidationOnSubmit()) {
      const { rowList, fieldData } = this.state;
      if (rowList.length != 0) {
        let dataToSave = [];
        rowList.map((data) => {
          let batchList = [];
          rowList.map((data1) => {
            if (data.productId == data1.productId) {
              if (batchList.length !== 0) {
                let batchData = batchList.filter(
                  (row) => row.batchCode == data1.batch
                );
                if (batchData.length !== 0) {
                } else {
                  let second = {
                    batchCode: data1.batch,
                    stock: data1.secondaryClosingQty,
                  };
                  batchList.push(second);
                }
              } else {
                let second = {
                  batchCode: data1.batch,
                  stock: data1.secondaryClosingQty,
                };
                batchList.push(second);
              }
            }
          });

          let third = {
            productId: data.productId,
            stock: 0,
            batchAndStocks: batchList,
          };
          if (dataToSave.length !== 0) {
            let rowData = dataToSave.filter(
              (row) => row.productId == third.productId
            );
            if (rowData.length !== 0) {
            } else {
              dataToSave.push(third);
            }
          } else {
            dataToSave.push(third);
          }
        });
        const outletBillDetails = rowList.map((rowData) => {
          const rowObj = {
            product: {
              id: rowData.productId,
            },
            qty: rowData.qty,
            batchCode: rowData.batch !== null ? rowData.batch : 0,
            rate: rowData.rate,
            mrp: rowData.mrp,
            cgstPer: rowData.cgstPer,
            sgstPer: rowData.sgstPer,
            igstPer: rowData.igstPer,
            freeQty: rowData.freeQty,
            itemDiscPer: rowData.itemDiscPer,
            uom: {
              id: rowData.uomId,
            },
            outletOrderDetail: {
              id: rowData.outletOrderDetail,
            },
            conversionValue: rowData.uomConversionValue,
          };
          return rowObj;
        });
        const billDataToSave = {
          discountOnBillPer: fieldData.discountOnBillPer,
          outLet: { id: fieldData.outletId },
          outletBillDetails: outletBillDetails,
          billDate: fieldData.date.format("DD-MM-YYYY"),
        };
        let billHeaderDataToSave = {
          outletBillHeader: billDataToSave,
          productAndStocks: dataToSave,
        };
        this.onSave(billHeaderDataToSave);
      } else {
        showNotification({
          msg: addAtleastProductMsg,
          severity: "error",
        });
      }
    }
  };
  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url:
          endpoint.outletBillHeader + "/generate-with-applied-offer-batch-wise",
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          if (
            Object.keys(data.data).length !== 0 &&
            Object.keys(data.data.outletBillsAfterApplyingOffers).length !== 0
          ) {
            if (
              data.data.outletBillsAfterApplyingOffers.outletBillDetails
                .length !== 0
            ) {
              let newObjectToSave = {
                ...data.data.outletBillsAfterApplyingOffers,
                outletBillDetails:
                  data.data.outletBillsAfterApplyingOffers.outletBillDetails.map(
                    (orderObject) => {
                      let productWithoutBillObject =
                        data.data.outletBillHeader.outletBillDetails.filter(
                          (row) => row.id == orderObject.id
                        );
                      orderObject = {
                        ...orderObject,
                        taxableAmtWithoutOffer:
                          productWithoutBillObject[0].taxableAmt,
                        totalAmtWithoutOffer:
                          productWithoutBillObject[0].totalAmt,
                        isChecked: 1,
                      };
                      return orderObject;
                    }
                  ),
              };
              let newData = {
                ...data.data,
                outletBillsAfterApplyingOffers: newObjectToSave,
              };
              let flag = 0;
              let newList1 = [];
              if (
                Object.keys(newData).length !== 0 &&
                Object.keys(newData.outletBillsAfterApplyingOffers).length !==
                0 &&
                newData.outletBillsAfterApplyingOffers.appliedOffers.length !==
                0
              ) {
                newList1 =
                  newData.outletBillsAfterApplyingOffers.appliedOffers.map(
                    (offerObject) => {
                      if (offerObject.levelWiseType == 3) {
                        flag++;
                        offerObject = {
                          ...offerObject,
                          isShow: 1,
                        };
                        return offerObject;
                      } else if (offerObject.levelWiseType == 5) {
                        flag++;
                        offerObject = {
                          ...offerObject,
                          isShow: 1,
                        };
                        return offerObject;
                      } else if (offerObject.levelWiseType == 6) {
                        flag++;
                        offerObject = {
                          ...offerObject,
                          isShow: 1,
                        };
                        return offerObject;
                      } else if (offerObject.levelWiseType == 7) {
                        flag++;
                        offerObject = {
                          ...offerObject,
                          isShow: 1,
                        };
                        return offerObject;
                      } else {
                        return offerObject;
                      }
                    }
                  );
              }
              let newList2 = {
                ...newData.outletBillsAfterApplyingOffers,
                appliedOffers: newList1,
              };
              let newList3 = {
                ...newData,
                outletBillsAfterApplyingOffers: newList2,
              };

              if (flag > 0) {
                this.setState({
                  openBill: false,
                  open: true,
                  offerBillData: newList3,
                });
              } else {
                this.setState({
                  openBill: true,
                  open: true,
                  offerBillDataToSave: newList3,
                });
              }
            }
          }
        } else {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  mandatoryCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;
    let formErrors = {};
    batchwiseOutletBillingJSON.fieldMeta.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&
              fieldData[currentField.dataKey].id == null))
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  ValidationCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;
    let formErrors = {};
    batchwiseOutletBillingJSON.fieldMeta.forEach((currentField) => {
      if (currentField.valid) {
        if (
          currentField.validName === "email" &&
          !validEmail.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "zero" &&
          fieldData[currentField.dataKey] === 0
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "greater-thanHundered" &&
          fieldData[currentField.dataKey] > 100
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} should not be greater than 100 per.`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };

  checkValidationOnSubmit = () => {
    if (this.mandatoryCheck()) {
      return this.ValidationCheck();
    } else {
      return false;
    }
  };

  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    batchwiseOutletBillingJSON.fieldMeta.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };

  getDataForDisabled = (value) => {
    const { dynamicMasterData, rowList } = this.state;
    let productList = dynamicMasterData["productDetail"]
      ? dynamicMasterData["productDetail"].filter((row) => row.id == value)
      : [];
    let batchList = dynamicMasterData["batchDetail"]
      ? dynamicMasterData["batchDetail"].filter((row) => row.id == value)
      : [];
    if (batchList.length != 0) {
      let newBatchList = rowList.filter((row) => row.batch == batchList[0].id);
      if (newBatchList.length != 0) {
        let newBatchQty = 0;
        newBatchList.map((row) => {
          newBatchQty = newBatchQty + +row.qty;
        });
        return {
          secondaryClosingQty: (
            +batchList[0].secondaryClosingQty - newBatchQty
          ).toString(),
          batchQty: batchList.length != 0 && batchList[0].secondaryClosingQty,
        };
      }
      return {
        secondaryClosingQty:
          batchList.length != 0 && batchList[0].secondaryClosingQty,
        batchQty: batchList.length != 0 && batchList[0].secondaryClosingQty,
      };
    }
    if (productList.length != 0) {
      return {
        rate: productList.length != 0 && productList[0].rate,
        mrp: productList.length != 0 && productList[0].mrp,
        orderQty: productList.length != 0 && productList[0].orderQty,
        uom: productList.length != 0 && productList[0].outletUomName,
        uomConversionValue:
          productList.length != 0 && productList[0].uomConversionValue,
      };
    }
  };

  getDataForCalculate = (value, fieldData) => {
    const { dynamicMasterData, rowList } = this.state;
    let batchList = dynamicMasterData["batchDetail"]
      ? dynamicMasterData["batchDetail"].filter(
        (row) => row.id == fieldData.batch
      )
      : [];
    if (rowList.length != 0) {
      let newQty = fieldData.batchQty;
      let newFreeQty = fieldData.qty;
      rowList.map((row) => {
        if (row.batch == fieldData.batch) {
          if (batchList.length != 0 && fieldData.batch) {
            let newBatchList = batchList.filter(
              (rowData) => rowData.id == row.batch
            );
            if (newBatchList.length != 0) {
              newQty = newQty - +row.qty;
              newFreeQty = newQty - +row.freeQty;
            }
          }
        }
      });
      return {
        secondaryClosingQty: (+newQty - +value).toString(),
        secondaryQty: newQty,
      };
    } else if (fieldData.batch) {
      if (batchList.length != 0) {
        return {
          secondaryClosingQty: (
            +batchList[0].secondaryClosingQty - value
          ).toString(),
          secondaryQty: +batchList[0].secondaryClosingQty,
        };
      }
    }
  };
  rowDelete = (rowData) => {
    const { rowList } = this.state;
    swal({
      title: "Are you sure?",
      text: deleteWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (rowList.length !== 0) {
          const rowListData = rowList.filter(
            (row) => row.index != rowData.index
          );
          if (rowListData.length !== 0) {
            const row = rowListData.map((rowListDataObj, index) => {
              return { ...rowListDataObj, index: index + 1 };
            });
            this.setState({
              rowList: row,
            });
          } else {
            this.setState({
              rowList: [],
            });
          }
        }
      }
    });
  };

  toggle = () => {
    this.setState({
      open: false,
      openBill: false,
    });
  };

  savaBillHandler = (rowData) => {
    const { offerBillData } = this.state;
    if (
      Object.keys(offerBillData).length !== 0 &&
      Object.keys(offerBillData.outletBillsAfterApplyingOffers).length !== 0 &&
      offerBillData.outletBillsAfterApplyingOffers.outletBillDetails.length !==
      0
    ) {
      let newList1 =
        offerBillData.outletBillsAfterApplyingOffers.appliedOffers.map(
          (offerObject) => {
            if (offerObject.levelWiseType == 3) {
              offerObject = {
                ...offerObject,
                isShow: 0,
              };
              return offerObject;
            } else if (offerObject.levelWiseType == 5) {
              offerObject = {
                ...offerObject,
                isShow: 0,
              };
              return offerObject;
            } else if (offerObject.levelWiseType == 6) {
              offerObject = {
                ...offerObject,
                isShow: 0,
              };
              return offerObject;
            } else if (offerObject.levelWiseType == 7) {
              offerObject = {
                ...offerObject,
                isShow: 0,
              };
              return offerObject;
            } else {
              return offerObject;
            }
          }
        );

      let dataToSet = [
        ...offerBillData.outletBillsAfterApplyingOffers.outletBillDetails,
        ...rowData,
      ];
      let offerBillAfterApplyingOffersData = {
        ...offerBillData.outletBillsAfterApplyingOffers,
        outletBillDetails: dataToSet,
      };
      let offerBillDataToSaveNew = {
        ...offerBillData,
        outletBillsAfterApplyingOffers: offerBillAfterApplyingOffersData,
      };

      this.setState({
        offerBillDataToSave: offerBillDataToSaveNew,
        open: true,
        openBill: true,
      });
    }
  };

  render() {
    const {
      dynamicMasterData,
      rowList,
      formErrors,
      fieldData,
      open,
      offerBillData,
      openBill,
      offerBillDataToSave,
    } = this.state;
    return (
      <>
        {!open && !openBill && (
          <>
            <Paper
              sx={{
                padding: 2,
              }}
              elevation={0}
            >
              <BatchwiseOutletBillingHeaderForm
                dynamicMasterData={dynamicMasterData}
                screenTitle={batchwiseOutletBillingJSON.screenTitle}
                fieldMeta={batchwiseOutletBillingJSON.fieldMeta}
                apiBaseURL={batchwiseOutletBillingJSON.apiBaseURL}
                formErrors={formErrors}
                showSaveBtn={batchwiseOutletBillingJSON.showSaveBtn}
                showSaveBtnMain={false}
                fieldData={fieldData}
                onDataChange={this.onDataChange}
              />
              <LabelCompo
                className="text-black"
                style={{ fontSize: "20px", color: DarkBlue }}
                label={"Add Product"}
              />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                sx={{ paddingTop: 2 }}
              >
                <DynamicFormWithoutSave
                  showSaveBtn={true}
                  showSaveBtnMain={false}
                  dynamicMasterData={dynamicMasterData}
                  getListById={this.getListById}
                  searchList={batchwiseOutletBillingJSON.searchList}
                  showTitle={batchwiseOutletBillingJSON.showTitle}
                  screenTitle={batchwiseOutletBillingJSON.screenTitle}
                  fieldMeta={batchwiseOutletBillingJSON.fieldMetaDetails}
                  showCancel={batchwiseOutletBillingJSON.showCancel}
                  showBackToList={batchwiseOutletBillingJSON.showBackToList}
                  apiBaseURL={batchwiseOutletBillingJSON.apiBaseURL}
                  showSaveNextBtn={batchwiseOutletBillingJSON.showSaveNextBtn}
                  saveBtnText={"ADD"}
                  onSave={this.onSearch}
                  resetAfterSave={true}
                  getDataForCalculate={this.getDataForCalculate}
                  getDataForDisabled={this.getDataForDisabled}
                />
              </Grid>
              <br />
              <RTTable
                showPegination={false}
                columns={[
                  { name: "index", title: "Sr. No.", align: "center" },
                  { name: "categoryName", title: "Category" },
                  { name: "productName", title: "Product" },
                  { name: "uom", title: "UOM", align: "center" },
                  { name: "batch", title: "Batch", align: "left" },
                  { name: "rate", title: "Rate", align: "right" },
                  { name: "qty", title: "Quantity", align: "right" },
                  { name: "total", title: "Total", align: "right" },
                  { name: "action", title: "Action" },
                ]}
                tableData={rowList}
                isActionColActive={true}
                showEditIcon={false}
                showDeleteIcon={true}
                rowDelete={this.rowDelete}
              />
              <br />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="medium"
                  type="button"
                  variant="contained"
                  name="Proceed"
                  onClick={this.onSubmitData}
                />
              </Grid>
            </Paper>
          </>
        )}
        {open && !openBill && (
          <>
            <BatchwisePopUpForOffer
              offerBillData={offerBillData}
              cancelHandler={this.toggle}
              onDataSave={this.savaBillHandler}
            />
          </>
        )}
        {open && openBill && (
          <>
            <BatchwiseBillingWithOffer
              offerBillOutletData={offerBillDataToSave}
              onCancel={this.toggle}
            />
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  areaList: state.area,
  beatList: state.beat,
  outletList: state.outlet,
  categoryList: state.category,
  productList: state.product,
  batchList: state.manageStockByDate,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getCategoryOutletAndDateWise,
  getBeatByArea,
  getProductByCategoryOutletAndDateWise,
  getOutletByBeatId,
  getAreaByUserType,
  getItemwiseBatch,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BatchwiseOutletBillingForm);
