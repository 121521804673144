import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { BrandColumns } from "../../../tableColumns/table-columns";
import { BrandJson } from "../../../DynamicFormsJson/MastersJSON/brand";
import { getBrand } from "../../../Slice/brand.slice";
class BrandTable extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { brandList, getBrand } = this.props;
        return (
            <>
                <DynamicMainScreen
                    formPath={BrandJson.formPath}
                    screenTitle={BrandJson.screenTitle}
                    fieldMeta={BrandJson.fieldMeta}
                    showPdfDownload={BrandJson.showPdfDownload}
                    showExcelDownload={true}
                    changeExcelURL={BrandJson.excelUrl}
                    showExtension={false}
                    pdfFileName={BrandJson.pdfFileName}
                    excelFileName={BrandJson.excelFileName}
                    showAddButton={BrandJson.showAddButton}
                    tableColumnsToFilter={BrandJson.tableColumnsToFilter}
                    tableColumns={BrandColumns}
                    tableData={brandList?.brand}
                    getTableData={getBrand}
                    apiBaseURL={BrandJson.apiBaseURL}
                    baseIdColumn={BrandJson.baseIdColumn}
                    isNote={true}
                    isNoteValue={"Brand"}
                    showDeleteIcon={false}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    brandList: state.brand,
});
const mapDispatchToProps = {
    getBrand,
};
export default connect(mapStateToProps, mapDispatchToProps)(BrandTable);
