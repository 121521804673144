import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { imageUrl } from "../../config/Url";
import endpoint from "../../config/endpoints";
import { langugae } from "../../config/languageJSON";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { apiGet, apiPost } from "../../utils/api_service";
import QRCode, { QRCodeCanvas } from "qrcode.react";
import NumberToWords from "../../components/NumberToWords";
import dayjs from "dayjs";
import { showLoador, showNotification } from "../Landing/Landing.slice";

const MyComponent = ({ showLoador, showNotification }) => {
    const params = useParams();
    const [orderList, setOrder] = useState([]);
    const [qrCode, setQRCode] = useState("");
    const [cin, setCin] = useState("");
    let lang =
        localStorage.getItem("lang") && localStorage.getItem("lang") != null
            ? localStorage.getItem("lang")
            : 2;
    let myReceivedOrderPdfId = localStorage.getItem("myReceivedOrderPdfId");
    let bankName = "";
    let accNo = "";
    let ifscCode = "";

    useEffect(() => {
        // window.print();
        if (navigator.onLine) {
            lang = localStorage.getItem("lang");
            myReceivedOrderPdfId = localStorage.getItem("myReceivedOrderPdfId");

            let receivedOrderData = {
                stockStakeHolderOrderHeaderIds: myReceivedOrderPdfId.split(","),
                languageId: lang,
            };
            showLoador({ loador: true });
            apiPost({
                url: endpoint.stockStakeholderOrderHeader + "/get-report-by-ids",
                postBody: receivedOrderData,
            }).then(({ data, success }) => {
                console.log(data);
                showLoador({ loador: false });
                if (!success) {
                    showNotification({
                        msg: serverMsg,
                        severity: "error",
                    });
                } else {
                    // setQRCode(
                    //     data.data.length != 0 &&
                    //         data.data[0].einvoiceJson != "" &&
                    //         data.data[0].einvoiceJson != null
                    //         ? JSON.parse(data.data[0].einvoiceJson).SignedQRCode
                    //         : ""
                    // );
                    setOrder(data.data);
                    // window.print();
                }
            });

            showLoador({ loador: true });
            apiGet({
                url: endpoint.setting + "/by-key?key=cin_value",
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    if (data && data.data !== null && Object.keys(data.data).length !== 0) {
                        setCin(data.data.settingValue);
                    }
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }, []);

    return (
        <>
            {/* <footer className='header'>
 <p>Tax Invoice</p>
 
 </footer>
 
 <header className='header2'>
 <p>Author: Hege Refsnes</p>
 </header> */}
            {orderList.map((dataToSet, index) => {
                let bankDetails = [];

                if (dataToSet.bankDetails && dataToSet.bankDetails.length > 0) {
                    bankDetails = dataToSet.bankDetails.map((bankObj) => ({
                        bankName: bankObj.bankName,
                        accNo: bankObj.accNo,
                        ifscCode: bankObj.ifscCode,
                    }));
                }
                let einvoiceJsonData =
                    dataToSet.einvoiceJson != null
                        ? JSON.parse(dataToSet.einvoiceJson)
                        : {};
                let vehicleData =
                    dataToSet.vehicleNo != null ? JSON.parse(dataToSet.vehicleNo) : {};
                let toBill = dataToSet.toBill ? dataToSet.toBill : {};
                let taluka =
                    toBill.beat != null &&
                        toBill.beat.area != null &&
                        toBill.beat.area.taluka != null
                        ? toBill.beat.area.taluka
                        : "";
                let talukaName =
                    toBill != null && toBill.taluka && toBill.taluka.name
                        ? toBill.taluka.name
                        : "";
                let districtName =
                    toBill != null && toBill.district && toBill.district.name
                        ? toBill.district.name
                        : "";
                let addr = toBill.address ? toBill.address + " - " + districtName : "";
                let address = langugae[2].address ? langugae[2].address + " : " : "";
                var DAddrText = address + addr;
                let insertDateTime = dataToSet.insertDateTime
                    ? dataToSet.insertDateTime : "";
                let insertDateTimeData =
                    insertDateTime.length == 2 ? insertDateTime[1] : "";
                let toBillState = toBill.state != null ? toBill.state : {};
                let indexInfo = 0;
                let totalAmt = 0;
                let totalTaxableAmt = 0;
                let cgstTotalAmt = 0;
                let sgstTotalAmt = 0;
                let igstTotalAmt = 0;
                let qtyTot = 0;
                let freeQtyTot = 0;
                let dataToSetState = dataToSet.state != null ? dataToSet.state : {};
                let stockStakeHolderBillDetails =
                    dataToSet.stockStakeHolderBillDetails != null
                        ? dataToSet.stockStakeHolderBillDetails
                        : [];
                return (
                    <>
                        <div>
                            <table style={{ width: "100%" }}>
                                <tr>
                                    <table style={{ width: "100%" }}>
                                        <tr>
                                            <td colSpan={11}>
                                                <table
                                                    style={{ borderCollapse: "collapse", width: "100%" }}
                                                >
                                                    <tr>
                                                        <td
                                                            align={"right"}
                                                            style={{
                                                                padding: "5px",
                                                                // paddingBottom: "5px",
                                                                // borderTop: "1px solid black",
                                                                // borderBottom: "1px solid black",
                                                                // borderRight: "1px solid black",
                                                                borderCollapse: "collapse",
                                                            }}
                                                        >
                                                            <img
                                                                style={{
                                                                    width: "auto",
                                                                    height: "100%",
                                                                    maxHeight: "160px", // Optional: to limit the maximum height
                                                                    // borderCollapse: "collapse",
                                                                }}
                                                                src={
                                                                    imageUrl + localStorage.getItem("complogo")
                                                                }
                                                                alt="Logo"
                                                            />
                                                        </td>

                                                        <td
                                                            align={"center"}
                                                            style={{ paddingBottom: "3px" }}
                                                        // colSpan={1}
                                                        >
                                                            <div
                                                                style={{
                                                                    fontWeight: 600,
                                                                    fontSize: "26px", // Adjusted font size
                                                                }}
                                                            >
                                                                {dataToSet.fromOrder
                                                                    ? dataToSet.fromOrder.firmName
                                                                    : "-"}
                                                            </div>

                                                            <div
                                                                style={{
                                                                    textAlign: "center",
                                                                    fontWeight: 500,
                                                                    fontSize: "18px", // Adjusted font size
                                                                }}
                                                            >
                                                                {dataToSet.fromOrder
                                                                    ? dataToSet.fromOrder.address
                                                                    : "-"}
                                                            </div>
                                                        </td>

                                                        {/* <td
 align={"left"}
 style={{
 padding: "5px",
 // borderTop: "1px solid black",
 // borderBottom: "1px solid black",
 // borderRight: "1px solid black",
 height:"90px",
 borderCollapse: "collapse",
 width:"12%"
 }}
 // colspan={1}
 >
 
 
 
 <QRCode value={`upi://pay?pa=${encodeURIComponent(localStorage.getItem('upi'))}`} />
 
 </td> */}
                                                        <td
                                                            align={"center"}
                                                            size={"35px"}
                                                            width={"25%"}
                                                        // rowSpan={3}
                                                        // className="tdsmallcell"
                                                        >
                                                            {/* <div style={{
                                width: "auto",
                                height: "100%",
                              }}>
                                <QRCode
                                  value={`eyJhbGciOiJSUzI1NiIsImtpZCI6IkI4RDYzRUNCNThFQTVFNkY0QUFDM0Q1MjQ1NDNCMjI0NjY2OUIwRjgiLCJ4NXQiOiJ1TlkteTFqcVhtOUtyRDFTUlVPeUpHWnBzUGciLCJ0eXAiOiJKV1QifQ.eyJkYXRhIjoie1wiU2VsbGVyR3N0aW5cIjpcIjI3QUFEQ08wNjI1UDFaN1wiLFwiQnV5ZXJHc3RpblwiOlwiMjdBTFVQQjA2MzZOMVpRXCIsXCJEb2NOb1wiOlwiT0ZDUEwtMjQtMjUtMDAwMVwiLFwiRG9jVHlwXCI6XCJJTlZcIixcIkRvY0R0XCI6XCIwMi8wNC8yMDI0XCIsXCJUb3RJbnZWYWxcIjo1ODk0MC4wMyxcIkl0ZW1DbnRcIjo3LFwiTWFpbkhzbkNvZGVcIjpcIjE4MDY5MDEwXCIsXCJJcm5cIjpcIjZhNDcxZTE3ZTkwNmI5Y2FkYzAyOTQ0N2JlMGU0MzdkNDZlNzU3ODdiNmFiM2QxY2Q2MWU1M2ZhMWMxOGY5NmFcIixcIklybkR0XCI6XCIyMDI0LTA0LTAyIDEyOjU2OjAwXCJ9IiwiaXNzIjoiTklDIn0.ce39pkOzhHEk-GrE55rDUD3ZTWPS567mSTKYsO3LbrlCVeKe4gxBKKYxO1N3On90NNLNzeUfPsFpFdGmDPR9V3Gv7eI_iGSet9KJK0CR4SDMEf_3lCB5zdD-CbAOuRCWV6ZmGpQjBWJqPhrSd2iJ-fqQmOg0iDXllsERrS_W6XuZV3QfkQuW8Vgvzd0ca0w7TA96a6tuHIENbj-QeUuAcZeBbRVJmaMQli9oJ4lm_fVIp0-HrVlwjaaUV_D5hIKywTWaEprN8eJrN292DuC-UJm-GaA_uI32kW2rVjX00TWPPH5gkCV1DfPdo9gtCMYkY6qXwgS1jPUSdBLilBVd_A`}
                                />
                              </div> */}
                                                            {/* <img
                                                                style={{
                                                                    width: "auto",
                                                                    height: "100%",
                                                                }}
                                                                src={qrCode}
                                                                alt=""
                                                            /> */}
                                                            {/* <div
                                                                style={{
                                                                    width: "auto",
                                                                    height: "auto",
                                                                }}
                                                            >
                                                                <QRCodeCanvas value={qrCode} />
                                                            </div> */}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={12}>
                                                <table
                                                    style={{ borderCollapse: "collapse", width: "100%" }}
                                                >
                                                    <tr>
                                                        <td
                                                            align={"right"}
                                                            style={{
                                                                paddingLeft: "10px",
                                                                paddingBottom: "2px",
                                                            }}
                                                        // colSpan={1}
                                                        >
                                                            {/* <div
 style={{
 textAlign: "right",
 flexDirection: "row",
 fontWeight: 600,
 fontSize: "16px",
 }}
 >
 {"City : "}
 {"-"}
 </div> */}
                                                        </td>

                                                        <td
                                                            align={"right"}
                                                            style={{ paddingBottom: "2px" }}
                                                        // colSpan={1}
                                                        >
                                                            {/* <div
 style={{
 textAlign: "right",
 flexDirection: "row",
 fontWeight: 600,
 fontSize: "16px",
 }}
 >
 {"Post Code : "}
 {"-"}
 </div> */}
                                                        </td>
                                                        {/* <td
                              align={"left"}
                              style={{ paddingBottom: "2px" }}
                            // colSpan={1}
                            >
                              <div
                                style={{
                                  textAlign: "center",
                                  flexDirection: "row",
                                  fontWeight: 600,
                                  fontSize: "16px",
                                }}
                              >
                                {"Phone : "}
                                {dataToSet.fromBillContactPersonNo
                                  ? dataToSet.fromBillContactPersonNo
                                  : "-"}
                              </div>
                            </td> */}
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </tr>

                                <tr>
                                    <table style={{ width: "100%" }}>
                                        <table style={{ width: "100%" }}>
                                            <tr>
                                                <td
                                                    style={{
                                                        borderLeft: "1px solid black",
                                                        textAlign: "center",
                                                        width: "50%",
                                                        borderTop: "1px solid black",
                                                        borderBottom: "1px solid black",
                                                        borderRight: "1px solid black",
                                                        borderCollapse: "collapse",
                                                        paddingLeft: "5px",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            width: "100%",
                                                            fontWeight: 600,
                                                            paddingBottom: "3px",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                justifyContent: "flex-start",
                                                                width: "50%",
                                                            }}
                                                        >
                                                            {"Order No : "}

                                                            {dataToSet.orderNo != null
                                                                ? dataToSet.orderNo
                                                                : "-"}
                                                        </div>
                                                        <div
                                                            style={{
                                                                justifyContent: "flex-start",
                                                                width: "50%",
                                                            }}
                                                        >
                                                            {"Order Date : "}
                                                            {dataToSet.orderDate ? dataToSet.orderDate : ""}
                                                            {/* {toBill.billNo != null ? toBill.billNo : "-"} */}
                                                        </div>
                                                        <div
                                                            style={{
                                                                justifyContent: "flex-start",
                                                                width: "50%",
                                                            }}
                                                        >
                                                            {"Place Of Supply : "}
                                                            {dataToSet.state ? dataToSet.state.name : ""}
                                                        </div>

                                                    </div>



                                                </td>
                                            </tr>
                                        </table>
                                    </table>
                                </tr>
                            </table>

                            <div style={{ borderCollapse: "collapse", marginTop: "8px" }}>
                                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th className="thsmallcell">Sr No</th>
                                            <th className="thbigcell">{" Description "}</th>
                                            {/* <th className="thmedcell">{"HSN SAC"}</th> */}
                                            <th className="thbigcell">
                                                {langugae[2].qty ? langugae[2].qty : ""}
                                            </th>
                                            <th className="thbigcell">
                                                {langugae[2].uom ? langugae[2].uom : ""}
                                            </th>
                                            {/* <th className="thbigcell">{"Batch"}</th> */}
                                            <th className="thmedcell">{"MRP"}</th>
                                            <th className="thmedcell">
                                                {langugae[2].rate ? langugae[2].rate : ""}
                                            </th>

                                            <th className="thsmallcell">{"Disc(%)"}</th>
                                            {/* <th className="thsmallcell">{"Add Disc(%)"}</th> */}
                                            <th className="thsmallcell">{"Disc Value"}</th>
                                            <th className="thmedcell">{"Taxable Net Value"}</th>
                                            {dataToSet.state.id == dataToSet.fromOrder.state.id ? (
                                                <>
                                                    <th colSpan={2} className="thmedcell">
                                                        {langugae[2].CGST ? langugae[2].CGST : ""}
                                                    </th>
                                                    <th colSpan={2} className="thmedcell">
                                                        {langugae[2].SGST ? langugae[2].SGST : ""}
                                                    </th>
                                                </>
                                            ) : (
                                                <>
                                                    <th colSpan={4} className="thmedcell">
                                                        {langugae[2].IGST ? langugae[2].IGST : ""}
                                                    </th>
                                                </>
                                            )}

                                            <th className="thmedcell">{"Total Value"}</th>
                                        </tr>

                                        <tr>
                                            <th className="thsmallcell"></th>
                                            <th className="thsmallcell"></th>
                                            <th className="thmedcell"></th>
                                            <th className="thbigcell"></th>
                                            <th className="thbigcell"></th>
                                            <th className="thbigcell"></th>
                                            <th className="thmedcell"></th>
                                            <th className="thmedcell"></th>
                                            <th className="thmedcell"></th>
                                            {dataToSet.state.id == dataToSet.fromOrder.state.id ? (
                                                <>
                                                    <th className="thmedcell">
                                                        {langugae[2].rate ? langugae[2].rate : ""}
                                                    </th>

                                                    <th className="thmedcell">{"Amt"}</th>
                                                    <th className="thmedcell">
                                                        {langugae[2].rate ? langugae[2].rate : ""}
                                                    </th>

                                                    <th className="thmedcell">{"Amt"}</th>
                                                    <th className="thmedcell"></th>
                                                </>
                                            ) : (
                                                <>
                                                    <th className="thmedcell" colSpan={2}>
                                                        {langugae[2].rate ? langugae[2].rate : ""}
                                                    </th>

                                                    <th className="thmedcell" colSpan={2}>
                                                        {"Amt"}
                                                    </th>
                                                    <th className="thmedcell"></th>
                                                </>
                                            )}
                                        </tr>

                                    </thead>

                                    <tbody style={{ width: "100%" }}>
                                        {dataToSet.packagingTypes.map((packagingType) => {
                                            return (
                                                <>
                                                    {dataToSet.stockStakeHolderOrderDetails.map(
                                                        (stockDetails, indexData) => {
                                                            let packagingTypeDetails =
                                                                stockDetails.product != null &&
                                                                    stockDetails.product.packagingType != null
                                                                    ? stockDetails.product.packagingType
                                                                    : {};

                                                            if (packagingType.id == packagingTypeDetails.id) {
                                                                let productData = [];
                                                                let product =
                                                                    stockDetails.product != null
                                                                        ? stockDetails.product
                                                                        : {};
                                                                qtyTot = qtyTot + stockDetails.qty;
                                                                freeQtyTot = freeQtyTot + stockDetails.freeQty;
                                                                indexInfo = indexInfo + 1;
                                                                totalAmt = totalAmt + stockDetails.totalAmt;
                                                                totalTaxableAmt =
                                                                    totalTaxableAmt + stockDetails.taxableAmt;
                                                                cgstTotalAmt =
                                                                    cgstTotalAmt + stockDetails.cgstAmt;
                                                                sgstTotalAmt =
                                                                    sgstTotalAmt + stockDetails.sgstAmt;
                                                                igstTotalAmt =
                                                                    igstTotalAmt + stockDetails.igstAmt;
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td
                                                                                align={"center"}
                                                                                className="tdsmallcell">
                                                                                {indexInfo}
                                                                            </td>

                                                                            <td
                                                                                align={"left"}
                                                                                // colSpan={2}
                                                                                className="tdsmallcell"
                                                                            >
                                                                                {product.name}
                                                                            </td>
                                                                            {/* <td
                                                                                align={"center"}
                                                                                className="tdsmallcell"
                                                                            >
                                                                                {stockDetails.product.taxPercentage.hsnCode}
                                                                            </td> */}
                                                                            <td
                                                                                align={"right"}
                                                                                className="tdsmallcell"
                                                                            >
                                                                                {stockDetails.qty}
                                                                            </td>
                                                                            <td
                                                                                align={"left"}
                                                                                className="tdsmallcell"
                                                                            >
                                                                                {stockDetails.uom != null
                                                                                    ? stockDetails.uom.name
                                                                                    : "-"}
                                                                            </td>
                                                                            {/* <td
                                                                                align={"center"}
                                                                                className="tdsmallcell"
                                                                                width={"1%"}
                                                                            >
                                                                                {stockDetails.batchCode != null
                                                                                    ? stockDetails.batchCode
                                                                                    : "-"}
                                                                            </td> */}
                                                                            <td
                                                                                align={"right"}
                                                                                className="tdsmallcell"
                                                                            >
                                                                                {stockDetails.mrp != null
                                                                                    ? stockDetails.mrp
                                                                                    : "-"}
                                                                            </td>
                                                                            <td
                                                                                align={"right"}
                                                                                className="tdsmallcell"
                                                                            >
                                                                                {(
                                                                                    +stockDetails.taxableAmt /
                                                                                    +stockDetails.qty
                                                                                ).toFixed(2)}
                                                                            </td>

                                                                            <td
                                                                                align={"right"}
                                                                                className="tdsmallcell"
                                                                            >
                                                                                {stockDetails.itemDiscPer.toFixed(2)}
                                                                            </td>

                                                                            {/* <td
 align={"right"}
 className="tdsmallcell"
 >
 {"0"}
 </td> */}

                                                                            <td
                                                                                align={"right"}
                                                                                className="tdsmallcell"
                                                                            >
                                                                                {stockDetails.itemDiscAmt.toFixed(2)}
                                                                            </td>
                                                                            <td
                                                                                align={"right"}
                                                                                className="tdsmallcell"
                                                                            >
                                                                                {stockDetails.taxableAmt.toFixed(2)}
                                                                            </td>

                                                                            {dataToSet.state.id == dataToSet.fromOrder.state.id ? (
                                                                                <>
                                                                                    <td
                                                                                        align={"right"}
                                                                                        className="tdsmallcell"
                                                                                    >
                                                                                        {stockDetails.cgstPer.toFixed(2)}
                                                                                    </td>
                                                                                    <td
                                                                                        align={"right"}
                                                                                        className="tdsmallcell"
                                                                                    >
                                                                                        {stockDetails.cgstAmt.toFixed(2)}
                                                                                    </td>
                                                                                    <td
                                                                                        align={"right"}
                                                                                        className="tdsmallcell"
                                                                                    >
                                                                                        {stockDetails.sgstPer.toFixed(2)}
                                                                                    </td>
                                                                                    <td
                                                                                        align={"right"}
                                                                                        className="tdsmallcell"
                                                                                    >
                                                                                        {stockDetails.sgstAmt.toFixed(2)}
                                                                                    </td>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <td
                                                                                        align={"right"}
                                                                                        className="tdsmallcell"
                                                                                        colSpan={2}
                                                                                    >
                                                                                        {stockDetails.igstPer.toFixed(2)}
                                                                                    </td>
                                                                                    <td
                                                                                        align={"right"}
                                                                                        className="tdsmallcell"
                                                                                        colSpan={2}
                                                                                    >
                                                                                        {stockDetails.igstAmt.toFixed(2)}
                                                                                    </td>
                                                                                </>
                                                                            )}
                                                                            <td
                                                                                align={"right"}
                                                                                className="tdsmallcell"
                                                                            >
                                                                                {stockDetails.totalAmt.toFixed(2)}
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                );
                                                            }
                                                        }
                                                    )}
                                                </>
                                            );
                                        })}

                                        <tr>
                                            {/* <td className="tdsmallcell"></td> */}
                                            <td
                                                align={"right"}
                                                className="tdsmallcell"
                                                style={{ fontWeight: 600 }}
                                                colspan={2}
                                            >
                                                {"Total QTY"}
                                            </td>

                                            <td
                                                align={"right"}
                                                style={{ fontWeight: 500 }}
                                                className="tdsmallcell"
                                                colspan={1}
                                            >
                                                {qtyTot}
                                            </td>
                                            <td className="tdsmallcell"></td>


                                            {dataToSet.state.id == dataToSet.fromOrder.state.id ? (
                                                <>
                                                    <td className="tdsmallcell"></td>
                                                    <td
                                                        align={"right"}
                                                        style={{ fontWeight: 500 }}
                                                        className="tdsmallcell"
                                                        colspan={1}
                                                    ></td>
                                                    <td className="tdsmallcell"></td>
                                                    <td
                                                        align={"right"}
                                                        style={{ fontWeight: 500 }}
                                                        className="tdsmallcell"
                                                        colspan={1}
                                                    >
                                                        {""}
                                                    </td>
                                                    <td
                                                        align={"right"}
                                                        style={{ fontWeight: 500 }}
                                                        className="tdsmallcell"
                                                        colspan={1}
                                                    >
                                                        {totalTaxableAmt.toFixed(2)}
                                                    </td>
                                                    <td
                                                        align={"right"}
                                                        style={{ fontWeight: 500 }}
                                                        className="tdsmallcell"
                                                        colspan={1}
                                                    >
                                                        {""}
                                                    </td>
                                                    <td
                                                        align={"right"}
                                                        style={{ fontWeight: 500 }}
                                                        className="tdsmallcell"
                                                        colspan={1}
                                                    >
                                                        {cgstTotalAmt.toFixed(2)}
                                                    </td>
                                                    <td className="tdsmallcell"></td>
                                                    <td
                                                        align={"right"}
                                                        style={{ fontWeight: 500 }}
                                                        className="tdsmallcell"
                                                        colspan={1}
                                                    >
                                                        {sgstTotalAmt.toFixed(2)}
                                                    </td>
                                                </>
                                            ) : (
                                                <>
                                                    <td className="tdsmallcell"></td>
                                                    <td className="tdsmallcell"></td>
                                                    <td
                                                        align={"right"}
                                                        style={{ fontWeight: 500 }}
                                                        className="tdsmallcell"
                                                        colspan={1}
                                                    ></td>
                                                    <td
                                                        align={"right"}
                                                        style={{ fontWeight: 500 }}
                                                        className="tdsmallcell"
                                                        colspan={1}
                                                    >
                                                        {""}
                                                    </td>
                                                    <td
                                                        align={"right"}
                                                        style={{ fontWeight: 500 }}
                                                        className="tdsmallcell"
                                                        colspan={1}
                                                    >
                                                        {totalTaxableAmt.toFixed(2)}
                                                    </td>
                                                    <td
                                                        align={"right"}
                                                        style={{ fontWeight: 500 }}
                                                        className="tdsmallcell"
                                                        colspan={2}
                                                    >
                                                        {""}
                                                    </td>
                                                    <td
                                                        align={"right"}
                                                        style={{ fontWeight: 500 }}
                                                        className="tdsmallcell"
                                                        colspan={2}
                                                    >
                                                        {igstTotalAmt.toFixed(2)}
                                                    </td>
                                                </>
                                            )}
                                            <td
                                                align={"right"}
                                                style={{ fontWeight: 600 }}
                                                className="tdsmallcell"
                                            >
                                                {totalAmt.toFixed(2)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                align={"left"}
                                                className="tdsmallcell"
                                                style={{ fontWeight: 600 }}
                                                colspan={11}
                                            >
                                                {"Order Total(In Words) : "}
                                                {""}
                                                {totalAmt ? NumberToWords(totalAmt.toFixed(2)) : "-"}
                                            </td>

                                            {/* {toBillState.id == dataToSetState.id ? (
 <>
 <td
 align={"left"}
 style={{ fontWeight: 500 }}
 className="tdsmallcell"
 colspan={3}
 >
 {"Income Tax TCS"}
 </td>
 </>
 ) : (
 <>
 <td
 align={"left"}
 style={{ fontWeight: 500 }}
 className="tdsmallcell"
 colspan={3}
 >
 {"Income Tax TCS"}
 </td>
 </>
 )} */}
                                            {/* <td
 align={"right"}
 style={{ fontWeight: 500 }}
 className="tdsmallcell"
 >
 {"0.00"}
 </td> */}
                                            {dataToSet.state.id == dataToSet.fromOrder.state.id ? (
                                                <>
                                                    <td
                                                        align={"left"}
                                                        style={{ fontWeight: 600 }}
                                                        className="tdsmallcell"
                                                        colspan={2}
                                                    >
                                                        {"Order Total"}
                                                    </td>
                                                </>
                                            ) : (
                                                <>
                                                    <td
                                                        align={"left"}
                                                        style={{ fontWeight: 600 }}
                                                        className="tdsmallcell"
                                                        colspan={2}
                                                    >
                                                        {"Order Total"}
                                                    </td>
                                                </>
                                            )}
                                            <td
                                                align={"right"}
                                                style={{ fontWeight: 600 }}
                                                className="tdsmallcell"
                                            >
                                                {totalAmt.toFixed(2)}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td
                                                align={"left"}
                                                className="tdsmallcell"
                                                style={{ fontWeight: 500, borderTop: "1px solid gray" }}
                                                colSpan={15}
                                            >
                                                {"Remark : "}
                                                {""}
                                                {dataToSet.remark ? dataToSet.remark : "-"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                align={"left"}
                                                className="tdsmallcell"
                                                style={{ fontWeight: 500, borderTop: "1px solid gray" }}
                                                colSpan={15}
                                            >
                                                {
                                                    "Note : This is system generated PDF, Does not required signature."
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {index + 1 != orderList.length && <div className="pagebreak"> </div>}
                    </>
                );
            })}
        </>
    );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    showNotification,
    showLoador,
};
export default connect(mapStateToProps, mapDispatchToProps)(MyComponent);
