import endpoint from "../../config/endpoints";

export const manageStockProductWise = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.grnGVNHeader,
  screenTitle: "Manage Stock Product Wise",
  formPath: "/request-batchwise-grn-form",
  formPathEdit: "/request-batchwise-grn-edit",
  formPathView: "/request-batchwise-grn-view",
  showBackToList: true,
  showTitle: false,
  showAddButton: true,
  searchList: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "toDate",
      onSubmit: "compare",
      onSubmitParameter: "fromDate-ge",
      isMandatory: true,
      onSubmit: "compare",
    },
  ],

  fieldMeta: [
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "date",
      isMandatory: true,
    },
    {
      label: "Stock Type",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "stockType",
      tableDataKey: "stockType",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "In Stock",
          value: "1",
        },
        {
          label: "Out Stock",
          value: "0",
        },
      ],
      isMandatory: true,
    },
  ],
  fieldMetaDetails: [
    {
      label: "Category",
      controlType: "autocomplete",
      placeHolder: "Select Category ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "categoryDetail",
      dataKey: "category",
      isRootLevelKey: true,
      isMandatory: true,
      getListFrom: "outlet",
      getListId: "category",
    },
    {
      label: "Sub-Category",
      controlType: "autocomplete",
      placeHolder: "Select Sub-Category ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "subCategoryDetail",
      dataKey: "subCategory",
      isRootLevelKey: true,
      isMandatory: true,
      getListFrom: "category",
      getListId: "subCategory",
    },
    {
      label: "Product ",
      controlType: "autocomplete",
      placeHolder: "Select Product",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "productDetail",
      dataKey: "product",
      getListFrom: "subCategory",
      getListId: "product,date",
      isRootLevelKey: true,
      isMandatory: true,
      getData: true,
    },
    {
      label: "Current Stock",
      controlType: "textfield",
      placeHolder: "Current Stock",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "primaryClosingQty",
      isMandatory: false,
      spValid: "float",
      disableTrue: true,
    },
    {
      label: "Rate",
      controlType: "textfield",
      placeHolder: "Rate",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "rate",
      isMandatory: false,
      spValid: "float",
      disableTrue: true,
    },
    {
      label: "Batch ",
      controlType: "textfield",
      placeHolder: "Enter Batch",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "batch",
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      onSubmit: "compare",
      onSubmitParameter: "fromDate-ge",
      isMandatory: true,
      onSubmit: "compare",
    },
    {
      label: "Quantity",
      controlType: "textfield",
      placeHolder: "Enter Quantity",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "qty",
      isMandatory: true,
    },
  ],

  fieldMetaOutStock: [
    {
      label: "Category",
      controlType: "autocomplete",
      placeHolder: "Select Category ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "categoryDetail",
      dataKey: "category",
      isRootLevelKey: true,
      isMandatory: true,
      getListFrom: "outlet",
      getListId: "category",
    },
    {
      label: "Sub-Category",
      controlType: "autocomplete",
      placeHolder: "Select Sub-Category ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "subCategoryDetail",
      dataKey: "subCategory",
      isRootLevelKey: true,
      isMandatory: true,
      getListFrom: "category",
      getListId: "subCategory",
    },
    {
      label: "Product ",
      controlType: "autocomplete",
      placeHolder: "Select Product",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "productDetail",
      dataKey: "product",
      getListFrom: "subCategory",
      getListId: "product,date",
      isRootLevelKey: true,
      isMandatory: true,
      getData: true,
    },
    {
      label: "Batch ",
      controlType: "autocomplete",
      placeHolder: "Enter Batch",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "batchDetail",
      dataKey: "batch",
      getListFrom: "product",
      getListId: "batch",
      isRootLevelKey: true,
      isMandatory: true,
      getData: true,
    },
    {
      label: "Current Stock",
      controlType: "textfield",
      placeHolder: "Current Stock",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "primaryClosingQty",
      isMandatory: true,
      spValid: "float",
      disableTrue: true,
    },
    {
      label: "Rate",
      controlType: "textfield",
      placeHolder: "Rate",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "rate",
      isMandatory: true,
      spValid: "float",
      disableTrue: true,
    },

    {
      label: "Quantity",
      controlType: "textfield",
      placeHolder: "Enter Quantity",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      calculateMinus: true,
      dataKey: "qty",
      valid: true,
      validName: "greater-less",
      zeroValid: true,
      validCondition: "less-primaryClosingQty",
      isMandatory: true,
      validText: "Current Stock",
    },
  ],
};
