import React from "react";
import { connect } from "react-redux";
import MaterialUIRTTable from "../../../components/Comman/RT/MaterialUIRTTable";
import DynamicFormWithoutSave from "../../../components/dynamicscreens/DynamicFormWithoutSave";
import { Paper } from "@mui/material";

import { SingleMrpConfigurationByItemListColumns } from "../../../tableColumns/table-columns";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../../components/withRouter";
import endpoint from "../../../config/endpoints";
import swal from "sweetalert";
import { SingleMrpConfigurationByItemListJson } from "../../../DynamicFormsJson/Transaction/SingleMrpConfigurationByItemList";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import {
  serverMsg,
  noInternetMsg,
  deletemsg,
  deleteFailedMsg,
  saveFailedMsg,
  deleteConfirmMsg,
  savemsg,
} from "../../../config/messageconstant";

import { apiGet, apiDelete, apiPost } from "../../../utils/api_service";

class SingleMrpConfigurationByItemList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      DataToSet: [],
    };
  }

  async componentDidMount() {
    await this.getByProductId();
    await this.getList();
  }
  getList = async () => {
    const { showLoador, showNotification } = this.props;
    if (this.props.params.id) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        await apiGet({
          url:
            endpoint.product +
            "/" +
            this.props.params.id +
            "/get-product-mrp-configuration",
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            if (!data.error) {
              this.setState({
                tableData: data.data.map((mrpConf, index) => {
                  return { index: index + 1, ...mrpConf };
                }),
              });
            }
          } else {
            showNotification({ msg: serverMsg, severity: "error" });
          }

          return success;
        });
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };
  getByProductId = async () => {
    const { showLoador, showNotification } = this.props;
    if (this.props.params.id) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        await apiGet({
          url: endpoint.product + "/" + this.props.params.id,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            if (!data.error) {
              this.setState({
                DataToSet: [
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    label: "Product Name",
                    value:
                      data.data.name !== null && data.data.shortName !== null
                        ? data.data.name + " ( " + data.data.shortName + " ) "
                        : data.data.name !== null
                          ? data.data.name
                          : data.data.shortName !== null
                            ? data.data.shortName
                            : "",
                  },
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    label: "Product Weight",
                    value: data.data.weight !== null ? data.data.weight : "",
                  },
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    label: "Product Shelf Life",
                    value:
                      data.data.selfLife !== null ? data.data.selfLife : "",
                  },
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    label: "Stock Holder UOM",
                    value:
                      data.data.stakeHolderUom !== null &&
                        data.data.stakeHolderUom.name !== null
                        ? data.data.stakeHolderUom.name
                        : "",
                  },
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    label: "Outlet UOM",
                    value:
                      data.data.outletUom !== null &&
                        data.data.outletUom.name !== null
                        ? data.data.outletUom.name
                        : "",
                  },
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    label: "MRP",
                    value: data.data.mrp !== null ? data.data.mrp : "",
                  },
                ],
              });
            }
          } else {
            showNotification({ msg: serverMsg, severity: "error" });
          }

          return success;
        });
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };
  onSearch = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.mrpConfiguration,
        postBody: {
          product: {
            id: this.props.params.id,
          },
          ...dataToSave,
        },
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg });
          this.getList();
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  rowDelete = (rowData) => {
    const { tableData } = this.state;
    const { showNotification } = this.props;
    if (tableData.length > 1) {
      swal({
        title: "Are you sure?",
        text: deleteConfirmMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.onDelete(rowData);
        }
      });
    } else {
      showNotification({
        msg: "Having atleast one mrp configuration",
        severity: "error",
      });
    }
  };
  onDelete = (rowData) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      this.props.showLoador({ loador: true });
      apiDelete({
        url: endpoint.mrpConfiguration + "/" + rowData.id,
      }).then(({ success }) => {
        if (success) {
          this.props.showLoador({ loador: false });
          this.props.showNotification({ msg: deletemsg });
          this.getList();
        } else {
          this.props.showNotification({
            msg: deleteFailedMsg,
            severity: "error",
          });
          this.props.showLoador({ loador: false });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  render() {
    const { DataToSet, tableData } = this.state;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            resetAfterSave={true}
            showBackToList={true}
            formPath={SingleMrpConfigurationByItemListJson.formPath}
            DataToSet={DataToSet}
            showSaveBtn={SingleMrpConfigurationByItemListJson.showSaveBtn}
            showTitle={SingleMrpConfigurationByItemListJson.showTitle}
            screenTitle={SingleMrpConfigurationByItemListJson.screenTitle}
            fieldMeta={SingleMrpConfigurationByItemListJson.fieldMeta}
            showCancel={SingleMrpConfigurationByItemListJson.showCancel}
            apiBaseURL={SingleMrpConfigurationByItemListJson.apiBaseURL}
            showSaveNextBtn={
              SingleMrpConfigurationByItemListJson.showSaveNextBtn
            }
            saveBtnText={"Add"}
            tableColumns={SingleMrpConfigurationByItemListColumns}
            onSave={this.onSearch}
            isActionColActive={false}
          />

          <MaterialUIRTTable
            tableHead={SingleMrpConfigurationByItemListColumns}
            rowDelete={this.rowDelete}
            showHeadDelete={true}
            data={tableData}
            isActionColActive={tableData.length == 1 ? false : true}
          />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  mrpConfigration: state.mrpConfigration,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SingleMrpConfigurationByItemList);
