// import * as React from "react";
// import CircularProgress from "@material-ui/core/CircularProgress";

// import "../CSS/loading1.css";
// export const Loading1 = ({ size }) => (
//   <div className="loading-shading-mui">
//     <CircularProgress
//       size={size}
//       color="primary"
//       variant="indeterminate"
//       disableShrink={true}
//       thickness={2.5}
//       className="loading1"
//     />
//   </div>

// );

import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { white } from "../config/ColorObj";

export const Loading1 = () => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Backdrop
        sx={{ color: white, zIndex: 9999 }}
        open={true}
        onClick={handleClose}
      >
        <React.Fragment>
          <svg width={0} height={0}>
            <defs>
              <linearGradient
                id="my_gradient"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop offset="0%" stopColor="#e01cd5" />
                <stop offset="100%" stopColor="#1CB5E0" />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgress
            sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
          />
        </React.Fragment>
      </Backdrop>
    </div>
  );
};
