import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { billHistoryEditJSON } from "../../DynamicFormsJson/MastersJSON/billHistoryJson";
import {
  getBillHeaderById,
  getBillHistoryById,
  setBillHistoryEditDetail,
} from "../../Slice/billingHistory.slice";
import { getProductByCategory } from "../../Slice/product.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import CheckBoxTable from "../../components/Comman/RT/MaterialUITableWithTextField";
class BillingHistoryEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: [],
      DataToSet: [],
    };
  }
  async componentDidMount() {
    if (navigator.onLine) {
      if (this.props.params.id) {
        const {
          showLoador,
          showNotification,
          getBillHeaderById,
          getBillHistoryById,
        } = this.props;
        showLoador({ loador: true });
        await getBillHistoryById({
          outletBillHeaderId: this.props.params.id,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        });
        showLoador({ loador: true });
        await getBillHeaderById({
          billHeaderId: this.props.params.id,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.setState({
              headerData: response,
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Outlet Name",
                  value:
                    response.outLet !== null &&
                      response.outLet.firmName !== null
                      ? response.outLet.firmName
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Owner Name",
                  value:
                    response.outLet !== null &&
                      response.outLet.ownerName !== null
                      ? response.outLet.ownerName
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Bill Number",
                  value:
                    response.billNo !== null && response.billNo !== null
                      ? response.billNo
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Bill Date",
                  value:
                    response.billDate !== null && response.billDate !== null
                      ? response.billDate
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Taxable Amount",
                  value:
                    response.taxableAmt !== null && response.taxableAmt !== null
                      ? Math.round(response.taxableAmt).toFixed(2)
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Total Amount",
                  value:
                    response.totalAmt !== null && response.totalAmt !== null
                      ? response.totalAmt
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Total Tax",
                  value:
                    response.cgstAmt !== null && response.sgstAmt !== null
                      ? (+response.cgstAmt + +response.sgstAmt).toFixed(2)
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Discount % On Bill",
                  value:
                    response.discountOnBillPer !== null &&
                      response.discountOnBillPer !== null
                      ? response.discountOnBillPer
                      : "",
                },
              ],
            });
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  onSubmit = () => {
    const { billHistoryList } = this.props;
    let error = false;
    const rowList = billHistoryList.billHistoryById.map((rowObj) => {
      if (
        rowObj.qty.toString() == "" ||
        rowObj.freeQty.toString() == "" ||
        rowObj.itemDiscPer.toString() == ""
      ) {
        error = true;
      }
      rowObj = {
        ...rowObj,
        qtyError: rowObj.qty.toString() == "" ? 1 : 0,
        freeQtyError: rowObj.freeQty.toString() == "" ? 1 : 0,
        discountError: rowObj.itemDiscPer.toString() == "" ? 1 : 0,
      };

      return rowObj;
    });
    this.props.setBillHistoryEditDetail({ row: rowList });
    if (!error) {
      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          let billDataToSave = {
            ...billHistoryList.billHeaderById,
          };
          let billObjectToSave = billHistoryList.billHistoryById.map(
            (billObject) => {
              billObject = {
                ...billObject,
                product: { id: billObject.productId },
              };
              return billObject;
            }
          );
          billDataToSave = {
            ...billDataToSave,
            outletBillDetails: billObjectToSave,
          };
          this.onSave(billDataToSave);
        }
      });
    }
  };
  onSave = (beatNewObjectToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.outletBillHeader + "/update",
        postBody: beatNewObjectToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg });
          this.props.navigate(-1);
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const { selection, DataToSet } = this.state;
    const { billHistoryList, setBillHistoryEditDetail } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            showTitle={billHistoryEditJSON.showTitle}
            screenTitle={billHistoryEditJSON.screenTitle}
            showSaveBtnMain={false}
            showAddButton={false}
            showBackButton={true}
            onCancel={() => this.props.navigate(-1)}
          />
          <br />
          <DynamicDetails DataToSet={DataToSet} />
          <CheckBoxTable
            selection={selection}
            tableHead={[
              {
                title: "Sr.No.",
                name: "index",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Product Name",
                name: "product",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "UOM",
                name: "outletUom",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "Rate",
                name: "rate",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
              },
              {
                title: "Order Quantity",
                name: "qty",
                placeHolder: "Enter Order Quantity",
                textFieldError: "qtyError",
                error: "Order Quantity",
                textField: true,
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
              },
              // {
              //   title: "Free Quantity",
              //   name: "freeQty",
              //   placeHolder: "Enter Free Quantity",
              //   textFieldError: "freeQtyError",
              //   error: "Free Quantity",
              //   textField: true,
              //   positionCenter: false,
              //   showInscreen: true,
              //   alignCenter: "left",
              // },
              // {
              //   title: "Discount % On Item",
              //   name: "itemDiscPer",
              //   placeHolder: "Enter Discount Percentage",
              //   textFieldError: "discountError",
              //   error: "Discount Percentage",
              //   textField: true,
              //   positionCenter: false,
              //   showInscreen: true,
              //   alignCenter: "left",
              // },
              {
                title: "Total Amount",
                name: "totalAmt",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
              },
            ]}
            data={billHistoryList.billHistoryById}
            setList={setBillHistoryEditDetail}
            isActionColActive={false}
            isCheckBox={false}
            showEdit={false}
            showDelete={false}
          />
          {billHistoryList.billHistoryById.length != 0 && (
            <>
              <br />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Cancel"
                  onClick={() => this.props.navigate(-1)}
                />
                &nbsp;&nbsp;
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Update"
                  onClick={this.onSubmit}
                />
              </Grid>
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  outletOrderDetail: state.outletOrderDetail,
  billHistoryList: state.billingHistory,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  setBillHistoryEditDetail,
  getProductByCategory,
  getBillHeaderById,
  getBillHistoryById,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BillingHistoryEdit);
