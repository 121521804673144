import endpoint from "../../config/endpoints";

export const AssetsJson = {
    formPath: "/assets-form",
    apiBaseURL: endpoint.assets,
    screenTitle: "Assets",
    showAddButton: true,
    showPdfDownload: false,
    showExcelDownload: false,
    tableColumnsToFilter: [
        {
            columnDisplayName: "Name",
            columnKeyName: "name",
            isChecked: true,
        },
        {
            columnDisplayName: "Description",
            columnKeyName: "description",
            isChecked: true,
        },
        {
            columnDisplayName: "Status",
            columnKeyName: "isActive",
            isChecked: true,
        },
    ],

    fieldMeta: [
        {
            label: "Name",
            controlType: "textfield",
            placeHolder: "Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            autoFocus: true,
            dataKey: "name",
            isMandatory: true,
        },
        {
            label: "Description",
            controlType: "textfield",
            placeHolder: "Description",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            autoFocus: true,
            dataKey: "description",
            isMandatory: true,
        },

        {
            label: "Type",
            controlType: "radiogroup",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "type",
            tableDataKey: "type",
            defaultValue: "0",
            radioGroupItems: [
                {
                    label: "Assets",
                    value: "0",
                },
                {
                    label: "Parameter",
                    value: "1",
                },
            ],
            isMandatory: true,
        },

        {
            label: "Image Mandatory",
            controlType: "radiogroup",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "imageMandatory",
            tableDataKey: "imageMandatory",
            defaultValue: "0",
            radioGroupItems: [
                {
                    label: "No",
                    value: "0",
                },
                {
                    label: "Yes",
                    value: "1",
                },
            ],
            isMandatory: true,
        },

        {
            label: "Select Image",
            controlType: "selectImage",
            placeHolder: "Select Image",
            inputType: "number",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKeyToSave: "image",
            dataKey: "productImageShow",
            isMandatory: true,
        },
    ]
};
