import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { YearColumns } from "../../../tableColumns/table-columns";
import { yearJson } from "../../../DynamicFormsJson/MastersJSON/year";
import { getYear } from "../../../Slice/year.slice";

class YearTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { yearList, getYear } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={yearJson.formPath}
          screenTitle={yearJson.screenTitle}
          fieldMeta={yearJson.fieldMeta}
          showPdfDownload={false}
          showExcelDownload={true}
          changeExcelURL={yearJson.excelUrl}
          showExtension={false}
          pdfFileName={yearJson.pdfFileName}
          excelFileName={yearJson.excelFileName}
          showAddButton={yearJson.showAddButton}
          tableColumnsToFilter={yearJson.tableColumnsToFilter}
          tableColumns={YearColumns}
          tableData={yearList?.year}
          getTableData={getYear}
          apiBaseURL={yearJson.apiBaseURL}
          baseIdColumn={yearJson.baseIdColumn}
          isNote={true}
          isNoteValue={"Add Financial Year"}
          showDeleteIcon={false}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  yearList: state.year,
});
const mapDispatchToProps = {
  getYear,
};
export default connect(mapStateToProps, mapDispatchToProps)(YearTable);
