import React from "react";
import { connect } from "react-redux";
import DynamicFormWithoutValidation from "../../../components/dynamicscreens/DynamicFormWithoutValidation";
import { leaveStructureJson } from "../../../DynamicFormsJson/MastersJSON/leaveStructure";
import {
    showLoador,
    showNotification,
} from "../../Landing/Landing.slice";
import { serverMsg,noInternetMsg,saveWarningMsg,savemsg,saveFailedMsg ,addOneRecordMsg} from "../../../config/messageconstant";
import ManualOrderTable from "../../Manage Stock/ManageStockCategorywiseTable";
import { apiGet,apiPost } from "../../../utils/api_service";
import {setLeaveType} from "../../../Slice/LeaveType.slice"
import endpoint from "../../../config/endpoints"
import MaterialUITableWithTextField from "../../../components/Comman/RT/MaterialUITableWithTextField";
import { Grid, Paper } from "@mui/material";
import { ButtonCompo } from "../../../components/Comman/Button";
import { withRouter } from "../../../components/withRouter";
import swal from "sweetalert";
import { compose } from "@reduxjs/toolkit";
import { lifecycle } from "recompose";

class LeaveStructureForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: "",
            searchValue: "",
           
            formErrors: {},
            fieldData: { type: "1" },
            DataToSet: [],
            leaveList:[]
           
          };
    }
   
    async componentDidMount() {
        const { showLoador, showNotification } = this.props;
        const{fieldData}=this.state
       
        if (this.props.params.id) {
          if (navigator.onLine) {
          showLoador({ loador: true });
          await apiGet({
            url: leaveStructureJson.apiBaseURL + "/" + this.props.params.id,
          }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success) {
              if (!data.error) {
               console.log("data")
               console.log(data)
               this.setState({
                fieldData:{
                  ...fieldData,
                  name:data.data.name
                },
                leaveList:data.data.leaveStructureDetails.map((row,index)=>{
                  return{
                    index:index+1,
                   
                    leaveTypeName:row.leaveType!=null && row.leaveType.name?row.leaveType.name:"",
                    allotedLeaves:row.allotedLeaves,
                    ...row,
                  }
                })
               })
              
               
              }
            }else {
              showNotification({ msg: serverMsg, severity: "error" });
            }
            
          })
        } else {
          showNotification({ msg: noInternetMsg, severity: "error" });
        }
        }else{
          if (navigator.onLine) {
    
       
    
    
    
    
         
    
    
    
         
    
            showLoador({ loador: true });
            await apiGet({
             
              url: endpoint.leaveType + "/get-active-list",
            }).then(({ data, success }) => {
              showLoador({ loador: false });
              if (success && data.data != null) {
                const rowData = data.data;
                
                  const row = rowData.map((rowObj,index) => {
                  
                    return {
                      ...rowObj,
                      leaveType:rowObj,
                      index:index+1,
                      leaveTypeName:rowObj.name,
                      allotedLeaves: 0,
                     
                    
                    };
                  });
                  this.setState({leaveList:row})
                  this.setLeaveStructureList({ row });
               
              } else {
                showNotification({ msg: serverMsg, severity: "error" });
              }
            });
          } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
          }
        }
       
      }
      onDataChange = (fieldName, newValue) => {
        
        const { fieldData } = this.state;
        const dataToSearch = {};
        const dataToReset = { ...this.state.fieldData };
        let listError = false;
        leaveStructureJson.fieldMeta.map((currentField) => {
          if (currentField.getDate && currentField.dataKey === fieldName) {
            dataToReset[currentField.getDate] = newValue.add(365, "day");
          }
          if (currentField.dataKey === fieldName && currentField.getListId) {
            const masterKeyList = currentField.getListId.split(",");
            masterKeyList.map((key) => {
              if (fieldName === key) {
                dataToSearch[key] = newValue;
              } else if (fieldData[key]) {
                dataToSearch[key] = fieldData[key];
              }
              return null;
            });
          }
          if (currentField.getListFrom) {
            const masterKeyList = currentField.getListFrom.split(",");
            masterKeyList.map((key) => {
              if (key == fieldName && dataToReset[key]) {
                delete dataToReset[currentField.dataKey];
              }
            });
          }
          if (
            !listError &&
            fieldData[currentField.dataKey] &&
            currentField.controlType != "datepicker" &&
            currentField.getListFrom == undefined
          ) {
            dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
          }
          return null;
        });
    
        dataToReset[fieldName] = newValue;
        this.setState({
          fieldData: dataToReset,
        });
      };



     
      setLeaveStructureList = ({ row }) => {
        const { fieldData } = this.state;
    
      
    
    
        this.setState({
          leaveList: row,
         
        });
      };
  
      mandatoryCheck = () => {
        let formValid = true;
        const { fieldData } = this.state;
    
        let formErrors = {};
        leaveStructureJson.fieldMeta.forEach((currentField) => {
          if (currentField.isMandatory) {
            if (
              (currentField.controlType === "datepicker" &&
                (fieldData[currentField.dataKey] === undefined ||
                  fieldData[currentField.dataKey] === "")) ||
              (currentField.controlType !== "datepicker" &&
                currentField.controlType !== "autocomplete" &&
                (fieldData[currentField.dataKey] === undefined ||
                  fieldData[currentField.dataKey] === null ||
                  fieldData[currentField.dataKey].toString().trim() === ""))
            ) {
              formValid = false;
    
              formErrors = {
                ...formErrors,
                [currentField.dataKey]: {
                  isError: true,
                  errorText: `*${currentField.label} is required`,
                },
              };
            } else if (
              currentField.controlType === "autocomplete" &&
              (fieldData[currentField.dataKey] === undefined ||
                fieldData[currentField.dataKey] === null ||
                (!currentField.isRootLevelKey &&
                  fieldData[currentField.dataKey] != undefined &&
                  fieldData[currentField.dataKey] != null &&
                  fieldData[currentField.dataKey].id == null))
            ) {
              formValid = false;
    
              formErrors = {
                ...formErrors,
                [currentField.dataKey]: {
                  isError: true,
                  errorText: `*${currentField.label} is required`,
                },
              };
            }
          } else if (currentField.controlType === "hideTextfield") {
            if (
              fieldData[currentField.tableDataKey] === currentField.open &&
              (fieldData[currentField.dataKey] === undefined ||
                fieldData[currentField.dataKey] === "")
            ) {
              formValid = false;
    
              formErrors = {
                ...formErrors,
                [currentField.dataKey]: {
                  isError: true,
                  errorText: `*${currentField.error} is required`,
                },
              };
            }
          } else if (currentField.controlType === "hideDatepicker") {
            if (
              fieldData[currentField.tableDataKey] === currentField.open &&
              (fieldData[currentField.dataKey] === undefined ||
                fieldData[currentField.dataKey] === null ||
                fieldData[currentField.dataKey].toString().trim() === "")
            ) {
              formValid = false;
    
              formErrors = {
                ...formErrors,
                [currentField.dataKey]: {
                  isError: true,
                  errorText: `*${currentField.label} is required`,
                },
              };
            }
          } else if (currentField.controlType === "hideAutocomplete") {
            if (
              fieldData[currentField.tableDataKey] === currentField.open &&
              (fieldData[currentField.dataKey] === undefined ||
                fieldData[currentField.dataKey] === null ||
                fieldData[currentField.dataKey].toString().trim() === "")
            ) {
              formValid = false;
    
              formErrors = {
                ...formErrors,
                [currentField.dataKey]: {
                  isError: true,
                  errorText: `*${currentField.label} is required`,
                },
              };
            }
          } else if (currentField.controlType === "hideMultiSelect") {
            if (
              fieldData[currentField.tableDataKey] === currentField.open &&
              (fieldData[currentField.dataKey] === undefined ||
                fieldData[currentField.dataKey] === null ||
                (fieldData[currentField.dataKey] &&
                  fieldData[currentField.dataKey].length == 0))
            ) {
              formValid = false;
    
              formErrors = {
                ...formErrors,
                [currentField.dataKey]: {
                  isError: true,
                  errorText: `*${currentField.label} is required`,
                },
              };
            }
          }
        });
        this.setState({
          formErrors: formErrors,
        });
        return formValid;
      };
      ValidationCheck = () => {
        let formValid = true;
        const { fieldData } = this.state;
    
        let formErrors = {};
        leaveStructureJson.fieldMeta.forEach((currentField) => {
          if (currentField.valid) {
            if (
              currentField.validName === "email" &&
              !validEmail.test(fieldData[currentField.dataKey])
            ) {
              formValid = false;
              formErrors = {
                ...formErrors,
                [currentField.dataKey]: {
                  isError: true,
                  errorText: `${currentField.label} is Invalid`,
                },
              };
            }
            if (
              currentField.validName === "zero" &&
              fieldData[currentField.dataKey] === 0
            ) {
              formValid = false;
              formErrors = {
                ...formErrors,
                [currentField.dataKey]: {
                  isError: true,
                  errorText: `${currentField.label} is Invalid`,
                },
              };
            }
          }
        });
        this.setState({
          formErrors: formErrors,
        });
        return formValid;
      };
      checkValidationOnSubmit = () => {
        if (this.mandatoryCheck()) {
          return this.ValidationCheck();
        } else {
          return false;
        }
      };
      onSubmit = async () => {
        const { fieldData, leaveList } = this.state;
        const checkValidationData = await this.checkValidationOnSubmit();
        if(checkValidationData){
          if (navigator.onLine) {
            console.log(fieldData);
            
            let leaveTypeList=[]
            if (this.props.params.id) {
              leaveList.map((row)=>{
                if(row.allotedLeaves!=0 && row.allotedLeaves!=""){
                 
                 leaveTypeList.push({
                  ...row,
                 id:row.id,
                   leaveType:{
                     id:row.leaveType!=null && row.leaveType.id?row.leaveType.id:""
                   },
                   allotedLeaves:row.allotedLeaves,
                   isActive:1,
                   extra1:null,
                 
                 })
                }
                
                
                   
                 
                
                
               })

            }else{
              leaveList.map((row)=>{
                if(row.allotedLeaves!=0 && row.allotedLeaves!=""){
                 
                 leaveTypeList.push({
                 
                   leaveType:{
                     id:row.leaveType!=null && row.leaveType.id?row.leaveType.id:""
                   },
                   allotedLeaves:row.allotedLeaves,
                   isActive:1,
                   extra1:null,
                 
                 })
                }
                
                
                   
                 
                
                
               })
            }
   
           
      
            let dataToSave = {
             
                name: fieldData.name,
                isActive:1,
                extra1:null,
                leaveStructureDetails:leaveTypeList
             
            };
      console.log("dataToSave")
      console.log(dataToSave)
            if (this.props.params.id) {
              dataToSave = {
                id: this.props.params.id,
                name: fieldData.name,
                isActive:1,
                extra1:null,
                leaveStructureDetails:leaveTypeList
               
             
              };
            }
      if(leaveTypeList.length!=0){
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            this.onSave(dataToSave);
          }
        });
      }else{
        // Atleast one record Add
        this.props.showNotification({
          msg: addOneRecordMsg,
          severity: "error",
        });
      }
           
          } else {
            this.props.showNotification({
              msg: noInternetMsg,
              severity: "error",
            });
          }
        }
       
      };
    
      onSave = async (dataToSave) => {
        const { showLoador, setProductList, showNotification, category } =
          this.props;
    
        let error = false;
        let productList = [];
        // if (!error) {
          if (navigator.onLine) {
            showLoador({ loador: true });
            await apiPost({
              url: endpoint.leaveStructure,
              postBody: dataToSave,
            }).then(({ data, success }) => {
              showLoador({ loador: false });
              if (success) {
                showNotification({ msg: savemsg });
               window.location.replace("/leave-structure")
              } else {
                showNotification({ msg: saveFailedMsg, severity: "error" });
              }
              return success;
            });
          } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
          }
        // } else {
        //   setProductList({ row: productList });
        // }
      };

    render()   {
       const {leaveList,formErrors,fieldData}=this.state
    
        return (
            <>
                <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
             

<DynamicFormWithoutValidation
            getListById={this.getListById}
            showBackToList={true}
            showSaveBtnMain={false}
            
            screenTitle={leaveStructureJson.screenTitle}
            fieldMeta={leaveStructureJson.fieldMeta}
            apiBaseURL={leaveStructureJson.apiBaseURL}
           
            
            saveBtnText={"Submit"}
            onDataChange={this.onDataChange}
          
            fieldData={fieldData}
            formErrors={formErrors}
          />
          <br />
          <MaterialUITableWithTextField
                tableHead={[
                  {
                    title: "Sr.No.",
                    name: "index",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Leave Title",
                    name: "leaveTypeName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                  {
                    title: "No. of Leaves",
                    name: "allotedLeaves",
                    placeHolder: "Enter No. of Leaves",
                    textFieldError: "noOfLeavesErr",
                    error: "No.of Leaves",
                    textField: true,
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                ]}
                data={leaveList}
                setList={this.setLeaveStructureList}
                isActionColActive={false}
              />
              <br />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Submit"
                  onClick={this.onSubmit}
                />
              </Grid>
              </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
   leaveTypeList:state.leaveType
});
const mapDispatchToProps = {
    setLeaveType,
    showLoador,
    showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(LeaveStructureForm);
