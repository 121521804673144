import endpoint from "../../config/endpoints";

export const PaymentTermJson = {
  formPath: "/payment-term-form",
  apiBaseURL: endpoint.paymentTerm,
  screenTitle: "Payment Term",
  showAddButton: true,
  showPdfDownload: false,
  pdfFileName: "Payment Term",
  showExcelDownload: false,
  excelFileName: "Payment Term",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "paymentName",
      isChecked: true,
    },

    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "paymentName",
      isMandatory: true,
      uniqueValidation: true,
    },
  ],
};
