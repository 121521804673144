import endpoint from "../../config/endpoints";

export const publishRateConfigurationOutletJson = {
  showSaveNextBtn: false,
  apiBaseURL: endpoint.productRateConfOutlet,
  formPath: "/publish-rate-configuration-outlet-form",
  formPathView: "/publish-rate-configuration-outlet-view",
  screenTitle: "Publish Rate Configuration Outlet",
  showTitle: true,
  showCancel: false,
  showSaveBtn: false,
  fieldMeta: [
    {
      label: "Category",
      controlType: "multiSelect",
      placeHolder: "Select Category",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "categoryDetail",
      dataKey: "category",
      getListId: "category",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Rate Configuration",
      controlType: "multiSelect",
      placeHolder: "Select Rate Configuration",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "publishRateConfigDetail",
      getListFrom: "category",
      dataKey: "productRateConfiguration",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      isMinDate: true,
      minDate: new Date(),
      dataKey: "date",
      isMandatory: true,
    },
  ],
  fieldMetaForSearch: [
    {
      label: "Category",
      controlType: "multiSelect",
      placeHolder: "Select Category",
      md: 11,
      lg: 11,
      sm: 11,
      xs: 12,
      // labelSM: 3,
      // labelMD: 3,
      // labelLG: 3,
      // valueSM: 10,
      // valueMD: 10,
      // valueLG: 10,
      masterName: "categoryDetail",
      dataKey: "category",
      isRootLevelKey: false,
      isMandatory: true,
    },
  ],
};
