import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  beatTarget: [],
  notAssignbeat: [],
  beatTargetByUserType: [],
};
let URL = endpoints.beatTarget;
const beatTargetSlice = createSlice({
  name: "beat",
  initialState,
  reducers: {
    beatTargetSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        beatTarget: row,
      };
    },

    notAssignBeatSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        notAssignbeat: row,
      };
    },

    beatTargetByUserTypeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        beatTargetByUserType: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  beatTargetSuccess,
  notAssignBeatSuccess,
  beatTargetByUserTypeSuccess,
  resetState,
} = beatTargetSlice.actions;

export default beatTargetSlice.reducer;

export const getBeatTarget = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-year-with-beat-count",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((beatTargetObject, index) => {
          let beatTargetData = {
            index: index + 1,
            id: beatTargetObject.id === null ? "" : beatTargetObject.id,
            yearMaster:
              beatTargetObject.yearMaster === null
                ? ""
                : beatTargetObject.yearMaster,
            yearId:
              beatTargetObject.yearMaster.id === null
                ? ""
                : beatTargetObject.yearMaster.id,
            yearName:
              beatTargetObject.yearMaster === null &&
              beatTargetObject.yearMaster.yearName === null
                ? ""
                : beatTargetObject.yearMaster.yearName,
            fromDate:
              beatTargetObject.yearMaster === null &&
              beatTargetObject.yearMaster.fromDate === null
                ? ""
                : beatTargetObject.yearMaster.fromDate,
            toDate:
              beatTargetObject.yearMaster === null &&
              beatTargetObject.yearMaster.toDate === null
                ? ""
                : beatTargetObject.yearMaster.toDate,
            beatCount:
              beatTargetObject.beatCount === null
                ? ""
                : beatTargetObject.beatCount,
          };
          return beatTargetData;
        });
        dispatch(beatTargetSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getNotAssignBeat =
  ({ yearId, areaId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-year-and-area?yearId=" + yearId + "&areaId=" + areaId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((beatTargetObject, index) => {
            let beatTargetData = {
              index: index + 1,
              id: beatTargetObject.id === null ? "" : beatTargetObject.id,
              beatName:
                beatTargetObject.beatName === null
                  ? ""
                  : beatTargetObject.beatName,
              area: beatTargetObject.area === null ? "" : beatTargetObject.area,
              sortOrderNo:
                beatTargetObject.sortOrderNo === null
                  ? ""
                  : beatTargetObject.sortOrderNo,
              isActive:
                beatTargetObject.isActive === null
                  ? ""
                  : beatTargetObject.isActive,
              insertDateTime:
                beatTargetObject.insertDateTime === null
                  ? ""
                  : beatTargetObject.insertDateTime,
              outletCount:
                beatTargetObject.outletCount === null
                  ? ""
                  : beatTargetObject.outletCount,
            };
            return beatTargetData;
          });
          dispatch(notAssignBeatSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getBeatTargetByUserType = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/by-user-type",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((beatTargetObject, index) => {
          let beatTargetData = {
            index: index + 1,
            id: beatTargetObject.id === null ? "" : beatTargetObject.id,
            yearMaster:
              beatTargetObject.yearMaster === null
                ? ""
                : beatTargetObject.yearMaster,
            yearId:
              beatTargetObject.yearMaster.id === null
                ? ""
                : beatTargetObject.yearMaster.id,
            yearName:
              beatTargetObject.yearMaster === null &&
              beatTargetObject.yearMaster.yearName === null
                ? ""
                : beatTargetObject.yearMaster.yearName,
            fromDate:
              beatTargetObject.yearMaster === null &&
              beatTargetObject.yearMaster.fromDate === null
                ? ""
                : beatTargetObject.yearMaster.fromDate,
            toDate:
              beatTargetObject.yearMaster === null &&
              beatTargetObject.yearMaster.toDate === null
                ? ""
                : beatTargetObject.yearMaster.toDate,
            region:
              beatTargetObject.beat === null
                ? ""
                : beatTargetObject.beat.area.taluka.district.zone.state.region
                    .name,
            state:
              beatTargetObject.beat === null
                ? ""
                : beatTargetObject.beat.area.taluka.district.zone.state.name,
            zone:
              beatTargetObject.beat === null
                ? ""
                : beatTargetObject.beat.area.taluka.district.zone.name,
            area:
              beatTargetObject.beat === null
                ? ""
                : beatTargetObject.beat.area.name,
            beat:
              beatTargetObject.beat === null
                ? ""
                : beatTargetObject.beat.beatName,

            totalTarget:
              beatTargetObject.totalTarget === null
                ? ""
                : beatTargetObject.totalTarget,
            achievedTarget:
              beatTargetObject.achievedTarget === null
                ? ""
                : beatTargetObject.achievedTarget,
            jan: beatTargetObject.jan === null ? "" : beatTargetObject.jan,
            feb: beatTargetObject.feb === null ? "" : beatTargetObject.feb,
            march:
              beatTargetObject.march === null ? "" : beatTargetObject.march,
            april:
              beatTargetObject.april === null ? "" : beatTargetObject.april,
            may: beatTargetObject.may === null ? "" : beatTargetObject.may,
            jun: beatTargetObject.jun === null ? "" : beatTargetObject.jun,
            july: beatTargetObject.july === null ? "" : beatTargetObject.july,
            aug: beatTargetObject.aug === null ? "" : beatTargetObject.aug,
            sep: beatTargetObject.sep === null ? "" : beatTargetObject.sep,
            oct: beatTargetObject.oct === null ? "" : beatTargetObject.oct,
            nov: beatTargetObject.nov === null ? "" : beatTargetObject.nov,
            dec: beatTargetObject.dec === null ? "" : beatTargetObject.dec,
            flag: beatTargetObject.flag === null ? "" : beatTargetObject.flag,
          };
          return beatTargetData;
        });
        dispatch(beatTargetByUserTypeSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
