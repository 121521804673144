import { Divider, Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import RTTable from "../../components/Comman/RT/RTTable";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg } from "../../config/messageconstant";
import { ManageStockJSON } from "../../DynamicFormsJson/Transaction/manageStock";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { getAuditTransactionData } from "../../Slice/audit.slice";
import NoDataFound from "../DashbourdChanges/NoDataFound";
import { ButtonCompo } from "../../components/Comman/Button";

class AuditQuestionAnswer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DataToSet: [],
            response: [],
        };

    }
    async componentDidMount() {
        const { getAuditTransactionData, showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            if (this.props.params.id && this.props.params.areaId) {

                showLoador({ loador: true });
                await getAuditTransactionData({
                    auditHeadId: this.props.params.id,
                    areaId: this.props.params.areaId,
                }).then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                    } else {
                        this.setState({
                            response: response,
                        })
                    }
                });
            }
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    goBack = () => {
        this.props.navigate(-1);
    };


    render() {
        const { auditList } = this.props;
        const { response } = this.state;
        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 0,
                    }}
                    elevation={0}
                >

                    <Grid
                        item
                        xs={12}
                        md={12}
                        size="small"
                        container
                        justifyContent="flex-end"
                        style={{ marginBottom: 10 }}
                    >
                        <ButtonCompo
                            size="small"
                            type="button"
                            variant="outlined"
                            name=" Back to List"
                            onClick={this.goBack}
                        />
                    </Grid>

                    {response.length !== 0 ? (
                        <>
                            <div
                                className="Frame1000002996"
                                style={{
                                    height: "40px",
                                    flexDirection: "column",
                                    width: '100%',
                                    background: '#e5e5e5',
                                    borderRadius: 5,
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: 15,
                                }}
                            >
                                <div
                                    className="Frame427319153"
                                    style={{
                                        width: "100%",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: 8,
                                        display: "inline-flex",
                                    }}
                                >
                                    <div
                                        className="TotalSales"
                                        style={{
                                            color: "black",
                                            fontSize: 22,
                                            fontFamily: "Poppins",
                                            fontWeight: "500",
                                            // marginTop: 10,
                                            // verticalAlign:'middle',
                                        }}
                                    >
                                        {response.length !== 0 &&
                                            response[0].auditHeadName
                                        }


                                    </div>
                                </div>

                            </div>

                        </>
                    ) : (<NoDataFound />)}


                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'flex-start' }}>
                        {response.length != 0 && response.map((obj, index) => (
                            <div
                                key={index}
                                style={{
                                    background: '#ffffff',
                                    borderRadius: '10px',
                                    boxShadow: 'rgba(0, 0, 0, 0.5) 0px 0px 6px',
                                    padding: '20px',
                                    maxWidth: '50%',
                                    width: 'calc(50% - 20px)'
                                }}
                            >
                                <div style={{ background: '#ffffff', borderRadius: '10px 10px 0 0', padding: '10px', textAlign: 'center' }}>
                                    <h2
                                        style={{
                                            fontFamily: 'Poppins, sans-serif',
                                            color: "black",
                                            fontSize: 22,
                                            margin: 0,
                                            fontWeight: 500
                                        }}
                                    >
                                        {obj.outletFirmName}
                                    </h2>

                                    {obj.auditTransactionDetails.length != 0 && obj.auditTransactionDetails.map((detailsObj, detailsIndex) => (
                                        <div
                                            key={detailsIndex}
                                            style={{
                                                background: '#ffffff',
                                                borderRadius: '5px',
                                                padding: '10px',
                                                margin: '10px 0'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    color: '#333',
                                                    fontSize: '18px',
                                                    fontFamily: 'Poppins, sans-serif',
                                                    fontWeight: 500,
                                                    marginBottom: '5px',
                                                    display: 'flex'
                                                }}
                                            >
                                                {detailsIndex + 1 + ") " + detailsObj.question}
                                            </div>
                                            <div
                                                style={{
                                                    color: '#555',
                                                    fontSize: '16px',
                                                    fontFamily: 'Poppins, sans-serif',
                                                    fontWeight: 300,
                                                    display: 'flex'
                                                }}
                                            >
                                                {"Ans - " + (
                                                    detailsObj.auditQuestionOptionType == 2
                                                        ? detailsObj.answerForMultiSelect.length > 1
                                                            ? detailsObj.answerForMultiSelect.join(", ")
                                                            : detailsObj.answerForMultiSelect.join(" ")
                                                        : detailsObj.answer
                                                )}

                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>







                </Paper >
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    auditList: state.audit,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getAuditTransactionData,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AuditQuestionAnswer);
