import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import RTTable from "../../components/Comman/RT/RTTable";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg } from "../../config/messageconstant";
import { companyCategoryConfigurationJson } from "../../DynamicFormsJson/Transaction/companyCategoryConfiguration";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { apiGet } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
import { showLoador, showNotification } from "../Landing/Landing.slice";


class CompanyCategoryConfigurationView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DataToSet: [],
            companyCategoryConfigurationList: [],
        };
        this.columns = [
            {
                title: "Sr.No.",
                name: "index",
                align: "center",
            },
            {
                title: "Product",
                name: "productName",
            },
        ];
    }
    async componentDidMount() {
        const { showLoador, showNotification, apiBaseURL } = this.props;
        if (navigator.onLine) {
            if (this.props.params.id) {
                showLoador({ loador: true });
                apiGet({
                    url: endpoint.companyCategoryConfiguration + "/" + this.props.params.id + "/with-area-names",
                }).then(({ data, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                    } else {
                        let rowList = data.data.companyCategoryConfigurationDetails.map((companyObject, index) => {
                            let newList = {
                                index: index + 1,
                                productName: companyObject.product.name,
                                type: companyObject.type
                            }
                            return newList

                        })
                        this.setState({
                            companyCategoryConfigurationList: rowList,
                            DataToSet: [
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Name",
                                    value:
                                        data.data.name !== null
                                            ? data.data.name
                                            : "",
                                },

                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Description",
                                    value: data.data.description !== null ? data.data.description : "",
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Area",
                                    value:
                                        data.data.areaNames !== null
                                            ? data.data.areaNames
                                            : "-",
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Type",
                                    value:
                                        data.data.type !== null
                                            ? data.data.type == 0 ? "Continuos Occuring" : "Date Range"
                                            : "",
                                },
                            ],

                        });
                    }
                });
            }
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    render() {
        const { DataToSet, companyCategoryConfigurationList } = this.state;

        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 0,
                    }}
                    elevation={0}
                >
                    <DynamicFormWithoutSave
                        showBackToList={true}
                        DataToSet={DataToSet}
                        showSaveBtn={companyCategoryConfigurationJson.showSaveBtn}
                        showTitle={true}
                        screenTitle={"Company Category Configuration View"}
                        fieldMeta={[]}
                        showCancel={companyCategoryConfigurationJson.showCancel}
                        apiBaseURL={companyCategoryConfigurationJson.apiBaseURL}
                        showSaveNextBtn={companyCategoryConfigurationJson.showSaveNextBtn}
                        showSaveBtnMain={false}
                        padding={true}
                        paddingTop={true}
                    />
                    <RTTable
                        columns={this.columns}
                        tableData={companyCategoryConfigurationList
                        }
                        isActionColActive={false}
                        showPegination={false}
                    />
                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    companyCategoryConfigurationList: state.companyCateoryConfiguration,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(CompanyCategoryConfigurationView);
