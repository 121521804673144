import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { PackagingTypeColumns } from "../../../tableColumns/table-columns";
import { packagingTypeJson } from "../../../DynamicFormsJson/MastersJSON/PackagingType";
import { getPackagingType } from "../../../Slice/packagingType.slice";


class PackagingTypeList extends React.Component {
  render() {
    const { packagingTypeList, getPackagingType } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={packagingTypeJson.formPath}
          screenTitle={packagingTypeJson.screenTitle}
          fieldMeta={packagingTypeJson.fieldMeta}
          showPdfDownload={false}
          showExcelDownload={true}
          changeExcelURL={packagingTypeJson.excelUrl}
          showExtension={false}
          pdfFileName={packagingTypeJson.pdfFileName}
          excelFileName={packagingTypeJson.excelFileName}
          showAddButton={packagingTypeJson.showAddButton}
          tableColumnsToFilter={packagingTypeJson.tableColumnsToFilter}
          tableColumns={PackagingTypeColumns}
          tableData={packagingTypeList?.packagingType}
          getTableData={getPackagingType}
          apiBaseURL={packagingTypeJson.apiBaseURL}
          isNote={true}
          isNoteValue={"Types Of Packaging For Products"}
          showDeleteIcon={false}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  packagingTypeList: state.packagingType,
});
const mapDispatchToProps = {
  getPackagingType,
};
export default connect(mapStateToProps, mapDispatchToProps)(PackagingTypeList);
