import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { resetReducList } from "../../CommonActions/User.action";
import { ButtonCompo } from "../../components/Comman/Button";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import CheckBoxTable from "../../components/Comman/RT/MaterialUITableWithTextField";
import { onlyNumber } from "../../components/Comman/Util/Validations";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { billHistoryEditJSON } from "../../DynamicFormsJson/MastersJSON/billHistoryJson";
import {
  getBillHeaderId,
  setReceivedBillData,
  genratedBillHeader,
} from "../../Slice/orderBillList.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
class ReceivedOrderBillForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
      billDate: "",
      billDiscountPercentage: "",
      total: 0,
      totalAmt: 0,
      discountPer: 0,
    };
  }
  async componentDidMount() {
    if (navigator.onLine) {
      if (this.props.params.id) {
        const {
          showLoador,
          showNotification,
          getBillHeaderId,
          genratedBillHeader,
        } = this.props;
        showLoador({ loador: true });
        await getBillHeaderId({
          billHeaderId: this.props.params.id,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            let total = 0;
            let totalAmt = 0;
            response.stockStakeHolderBillDetails.map((rowData) => {
              total = total + +rowData.totalAmt;
              totalAmt = totalAmt + +rowData.totalAmt;
            });
            this.setState({
              totalAmt: totalAmt,
              total: total,
            });
          }
        });

        await genratedBillHeader({
          billHeaderId: this.props.params.id,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.setState({
              headerData: response,
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Firm Name",
                  value:
                    response.fromBillFirmName !== null
                      ? response.fromBillFirmName
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Owner Name",
                  value:
                    response.fromBillOwnerName !== null
                      ? response.fromBillOwnerName
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Bill Number",
                  value:
                    response.billNo !== null && response.billNo !== null
                      ? response.billNo
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Bill Date",
                  value:
                    response.billDate !== null && response.billDate !== null
                      ? response.billDate
                      : "",
                },
              ],
            });
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  onSubmit = (data) => {
    const { receivedBillList } = this.props;
    let error = false;
    const rowList = receivedBillList.newBillDetailsById.map((rowObj) => {
      if (
        rowObj.qty.toString() == "" ||
        rowObj.freeQty.toString() == "" ||
        rowObj.itemDiscPer.toString() == "" ||
        (+rowObj.itemDiscPer >= 100 && rowObj.itemDiscPer != 100)
      ) {
        error = true;
      }
      if (
        (rowObj.qty.toString() != "" &&
          (rowObj.qty.toString() == "0" ||
            !onlyNumber.test(rowObj.qty.toString()) ||
            +rowObj.qty.toString() > +rowObj.billQty.toString())) ||
        (rowObj.freeQty.toString() != "" &&
          !onlyNumber.test(rowObj.freeQty.toString()))
      ) {
        error = true;
      }
      rowObj = {
        ...rowObj,
        qtyError:
          rowObj.qty.toString() == ""
            ? 1
            : rowObj.qty.toString() != "" &&
              !onlyNumber.test(rowObj.qty.toString())
            ? 2
            : rowObj.qty.toString() != "" &&
              +rowObj.qty.toString() > +rowObj.billQty.toString()
            ? 3
            : rowObj.qty.toString() != "" && rowObj.qty.toString() == "0"
            ? 4
            : 0,
        freeQtyError:
          rowObj.freeQty.toString() == ""
            ? 1
            : rowObj.freeQty.toString() != "" &&
              !onlyNumber.test(rowObj.freeQty.toString())
            ? 2
            : 0,
        discountError:
          rowObj.itemDiscPer.toString() == ""
            ? 1
            : rowObj.itemDiscPer != 100 && +rowObj.itemDiscPer >= 100
            ? 2
            : 0,
      };
      return rowObj;
    });
    this.props.setReceivedBillData({
      row: rowList,
    });
    this.setState({
      billDate: data.date,
      billDiscountPercentage: data.discount,
    });
    if (!error) {
      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          let billDataToSave = {
            ...receivedBillList.billEdit,
            stockStakeHolderBillDetails: receivedBillList.newBillDetailsById,
          };
          this.onSave(billDataToSave);
        }
      });
    }
  };

  onSave = (beatNewObjectToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.stockStakeholderBillHeader + "/update",
        postBody: beatNewObjectToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg });
          this.props.navigate(-1);
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  setReceivedOrderBillList = ({ row }) => {
    const { setReceivedBillData } = this.props;
    const { headerData } = this.state;
    let dataTotal = 0;
    const rowlist = row.map((rowData) => {
      const objTotal = +rowData.qty * +rowData.rate;

      rowData = {
        ...rowData,
        totalAmt:
          rowData.itemDiscPer > 0
            ? (
                objTotal -
                (+rowData.qty * +rowData.rate * +rowData.itemDiscPer) / 100
              ).toFixed(2)
            : (+rowData.qty * +rowData.rate).toFixed(2),
      };
      dataTotal = dataTotal + +rowData.totalAmt;
      return rowData;
    });
    let discount = (dataTotal * +headerData.discountOnbillPer) / 100;
    console.log(dataTotal - +discount);
    console.log("dataTotal ==== " + dataTotal);
    console.log("totalAmt ==== " + (dataTotal - +discount));
    this.setState({
      totalAmt: dataTotal - +discount,
      total: dataTotal,
    });
    setReceivedBillData({ row: rowlist });
  };

  render() {
    const { DataToSet, dynamicMasterData, total, totalAmt } = this.state;
    const { setReceivedBillData, receivedBillList } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            showTitle={billHistoryEditJSON.showTitle}
            screenTitle={billHistoryEditJSON.screenTitle}
            showSaveBtnMain={false}
            showAddButton={false}
            showBackButton={true}
            onCancel={() => this.props.navigate(-1)}
          />
          <br />
          <DynamicDetails DataToSet={DataToSet} />
          <CheckBoxTable
            tableHead={[
              {
                title: "Sr.No.",
                name: "index",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Product Name",
                name: "productName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "UOM",
                name: "stakeHolderUom",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Rate",
                name: "rate",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
              },
              {
                title: "Bill Quantity",
                name: "billQty",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
              },
              {
                title: "Order Quantity",
                name: "qty",
                placeHolder: "Enter Order Quantity",
                textFieldError: "qtyError",
                error: "Order Quantity",
                textField: true,
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "Free Quantity",
                name: "freeQty",
                placeHolder: "Enter Free Quantity",
                textFieldError: "freeQtyError",
                error: "Free Quantity",
                textField: true,
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "Discount % On Item",
                name: "itemDiscPer",
                placeHolder: "Enter Discount Percentage",
                textFieldError: "discountError",
                error: "Discount Percentage",
                textField: true,
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "Total Amount",
                name: "totalAmt",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
              },
            ]}
            data={receivedBillList.newBillDetailsById}
            setList={this.setReceivedOrderBillList}
            isActionColActive={false}
            isCheckBox={false}
            showEdit={false}
            showDelete={false}
          />

          <br />
          <DynamicDetails
            DataToSet={[
              {
                xs: 12,
                sm: 4,
                md: 4,
                lg: 4,
                labelXS: 12,
                labelSM: 1.5,
                labelMD: 1.5,
                labelLG: 1.5,
                valueXS: 12,
                valueSM: 10.5,
                valueMD: 10.5,
                valueLG: 10.5,
                label: "Total",
                value: total.toFixed(2),
              },
              {
                xs: 12,
                sm: 4,
                md: 4,
                lg: 4,
                labelXS: 12,
                labelSM: 4,
                labelMD: 4,
                labelLG: 4,
                valueXS: 12,
                valueSM: 8,
                valueMD: 8,
                valueLG: 8,
                label: "Discount Amt",
                value: (total - totalAmt).toFixed(2),
              },
              {
                xs: 12,
                sm: 4,
                md: 4,
                lg: 4,
                labelXS: 12,
                labelSM: 3,
                labelMD: 3,
                labelLG: 3,
                valueXS: 12,
                valueSM: 9,
                valueMD: 9,
                valueLG: 9,
                label: "Total Amt",
                value: Math.round(totalAmt).toFixed(2),
              },
            ]}
          />
          <br />
          {receivedBillList.newBillDetailsById.length != 0 && (
            <>
              <br />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Cancel"
                  onClick={() => this.props.navigate(-1)}
                />
                &nbsp;&nbsp;
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Update"
                  onClick={this.onSubmit}
                />
              </Grid>
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  receivedBillList: state.billHeaderList,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  setReceivedBillData,
  resetReducList,
  getBillHeaderId,
  genratedBillHeader,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ReceivedOrderBillForm);
