import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import RTTable from "../../components/Comman/RT/RTTable";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg } from "../../config/messageconstant";
import { auditJson } from "../../DynamicFormsJson/MastersJSON/audit";
import { getManageStockById } from "../../Slice/manageStock.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { getAuditHeadById } from "../../Slice/audit.slice";
import AuditQuestionAnswer from "./AuditQuestionAnswer";
import { ButtonCompo } from "../../components/Comman/Button";

class AuditSurveyData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DataToSet: [],
        };



        this.columns = [
            {
                title: "Sr.No.",
                name: "index",
                align: "center",
            },
            {
                title: "Area Name",
                name: "areaName",
            },
            {
                title: "Visited Count",
                name: "surveyDoneCount",
                align: "center",
            },
            {
                title: "Unvisited Count",
                name: "surveyRemainCount",
                align: "center",
            },
            {
                title: "Action",
                name: "action",
            },

        ];
    }
    async componentDidMount() {
        const { getAuditHeadById, showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            if (this.props.params.id) {
                showLoador({ loador: true });
                await getAuditHeadById({
                    auditHeadId: this.props.params.id,
                }).then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                    } else {
                    }
                });
            }
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    rowTransfer = (rowData) => {
        console.log(rowData);
        this.props.navigate("/audit-question-answer/" + rowData.auditHeadId + "/" + rowData.areaId);

    }

    onBack = () => {
        this.props.navigate(-1);
    }



    render() {
        const { auditList } = this.props;

        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 0,
                    }}
                    elevation={0}
                >


                    <DynamicFormWithoutSave
                        showBackToList={true}
                        showSaveBtn={auditJson.showSaveBtn}
                        showTitle={true}
                        screenTitle={"Survey Details"}
                        fieldMeta={[]}
                        showCancel={auditJson.showCancel}
                        apiBaseURL={auditJson.apiBaseURL}
                        showSaveNextBtn={auditJson.showSaveNextBtn}
                        showSaveBtnMain={false}
                        padding={true}
                        paddingTop={true}
                    />
                    <RTTable
                        columns={this.columns}
                        tableData={auditList?.auditHeadById}
                        showTransferButtonInTable={true}
                        showTransferTitle={"Survey Details"}
                        rowTransfer={this.rowTransfer}
                        openFormPathStatus={auditJson.openFormPathStatusSurveyDetails}
                        showDeleteIcon={false}
                        showEditIcon={false}
                        isActionColActive={true}
                        showPegination={false}
                    />

                   
                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    auditList: state.audit,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getAuditHeadById,
    getManageStockById,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AuditSurveyData);
