import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  uom: [],
};
let URL = endpoints.unitOfMesurement;
const uomSlice = createSlice({
  name: "uom",
  initialState,
  reducers: {
    uomSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        uom: row,
        
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { uomSuccess, resetState } = uomSlice.actions;

export default uomSlice.reducer;

export const getUom = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL+  '?sort={"insertDateTime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        let index = 0;
        const { content } = data.data;
        const row = content.map((uomObject, index) => {
          let uomData = {
            index: index + 1,
            id: uomObject.id === null ? "" : uomObject.id,
            name: uomObject.name === null ? "" : uomObject.name,
            isActive: uomObject.isActive === null ? "" : uomObject.isActive,
          };
          return uomData;
        });
        dispatch(uomSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};


export const setUom =
  ({ row }) =>
  async (dispatch) => {
    dispatch(uomSuccess({ row }));
  };