import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { categoryJson } from "../../../DynamicFormsJson/MastersJSON/Category";
class CategoryForm extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <>
                <DynamicForm
                    formPath={categoryJson.formPath}
                    screenTitle={categoryJson.screenTitle}
                    fieldMeta={categoryJson.fieldMeta}
                    tableColumnsToFilter={categoryJson.tableColumnsToFilter}
                    apiBaseURL={categoryJson.apiBaseURL}
                    baseIdColumn={categoryJson.baseIdColumn}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
});
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(CategoryForm);
