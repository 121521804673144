import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
import moment from "moment";
import dayjs from "dayjs";
const initialState = {
  productRateStructure: [],
  productRateStructureAssignation: [],
  productRateStructurePublish: [],
  structureAssignation: [],
};
let URL = endpoints.productRateStructure;
let URL1 = endpoints.productRateStructureAssignation;
const productRateStructureSlice = createSlice({
  name: "productRateStructure",
  initialState,
  reducers: {
    productRateStructureSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        productRateStructure: row,
      };
    },

    productRateStructureAssignationSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        productRateStructureAssignation: row,
      };
    },

    productRateStructurePublishSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        productRateStructurePublish: row,
      };
    },

    structureAssignationSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        structureAssignation: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  productRateStructureSuccess,
  productRateStructureAssignationSuccess,
  productRateStructurePublishSuccess,
  structureAssignationSuccess,
  resetState,
} = productRateStructureSlice.actions;

export default productRateStructureSlice.reducer;

export const getproductRateStructure = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((productRateStructureObject, index) => {
          let productRateStructureData = {
            index: index + 1,
            id:
              productRateStructureObject.id === null
                ? ""
                : productRateStructureObject.id,
            name:
              productRateStructureObject.name === null
                ? ""
                : productRateStructureObject.name,
            structureDate:
              productRateStructureObject.structureDate === null
                ? ""
                : productRateStructureObject.structureDate,
          };
          return productRateStructureData;
        });
        dispatch(productRateStructureSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getProductRateStructureGetAll =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/get-all?fromDate=" + fromDate + "&toDate=" + toDate,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((productRateStructureObject, index) => {
            let assignCount = 0;
            let publishCount = 0;
            let stakeholderTypeList = [];
            productRateStructureObject.productRateStructureDetail.length != 0 &&
              productRateStructureObject.productRateStructureDetail.map(
                (rateObject) => {
                  assignCount = assignCount + rateObject.assignationCount;
                  publishCount = publishCount + rateObject.publishCount;
                  stakeholderTypeList.push(rateObject.stockStakeHolderType);
                }
              );
            let productRateStructureData = {
              index: index + 1,
              id:
                productRateStructureObject.id === null
                  ? ""
                  : productRateStructureObject.id,
              name:
                productRateStructureObject.name === null
                  ? ""
                  : productRateStructureObject.name,
              structureDate:
                productRateStructureObject.structureDate === null
                  ? ""
                  : productRateStructureObject.structureDate,

              assignCount: assignCount,
              publishCount: publishCount,
              stakeholderTypeList: stakeholderTypeList,
              isActive:
                productRateStructureObject.isActive === null
                  ? ""
                  : productRateStructureObject.isActive,
            };
            return productRateStructureData;
          });
          dispatch(productRateStructureSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getProductRateStructureAll = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-all-top-product-rate-structures",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((productRateStructureObject, index) => {
          let assignCount = 0;
          let publishCount = 0;
          let stakeholderTypeList = [];
          productRateStructureObject.productRateStructureDetail.length != 0 &&
            productRateStructureObject.productRateStructureDetail.map(
              (rateObject) => {
                assignCount = assignCount + rateObject.assignationCount;
                publishCount = publishCount + rateObject.publishCount;
                stakeholderTypeList.push(rateObject.stockStakeHolderType);
              }
            );
          let productRateStructureData = {
            index: index + 1,
            id:
              productRateStructureObject.id === null
                ? ""
                : productRateStructureObject.id,
            name:
              productRateStructureObject.name === null
                ? ""
                : productRateStructureObject.name,
            structureDate:
              productRateStructureObject.structureDate === null
                ? ""
                : productRateStructureObject.structureDate,
            assignCount: assignCount,
            publishCount: publishCount,
            stakeholderTypeList: stakeholderTypeList,
          };
          return productRateStructureData;
        });
        dispatch(productRateStructureSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getTopProductRateStructure = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-top-product-rate-structures-by-limit?limit=550",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((productRateStructureObject, index) => {
          let assignCount = 0;
          let publishCount = 0;
          let stakeholderTypeList = [];
          productRateStructureObject.dtoProductRateStructureDetails.length !=
            0 &&
            productRateStructureObject.dtoProductRateStructureDetails.map(
              (rateObject) => {
                assignCount = assignCount + rateObject.assignationCount;
                publishCount = publishCount + rateObject.publishCount;
                let stakeholderTypeObj = {
                  id: rateObject.stockStakeHolderTypeId,
                  name: rateObject.stockStakeHolderTypeName,
                };
                stakeholderTypeList.push(stakeholderTypeObj);
              }
            );
          let productRateStructureData = {
            index: index + 1,
            id:
              productRateStructureObject.id === null
                ? ""
                : productRateStructureObject.id,
            name:
              productRateStructureObject.name === null
                ? ""
                : productRateStructureObject.name,
            structureDate:
              productRateStructureObject.structureDate === null
                ? ""
                : productRateStructureObject.structureDate,

            json:
              productRateStructureObject.json === null
                ? ""
                : productRateStructureObject.json,
            assignCount: assignCount,
            publishCount: publishCount,
            stakeholderTypeList: stakeholderTypeList,
            isActive:
              productRateStructureObject.isActive === null
                ? ""
                : productRateStructureObject.isActive,
          };
          return productRateStructureData;
        });
        dispatch(productRateStructureSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getProductRateStructureNew = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-product-rate-structures",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((productRateStructureObject, index) => {
          let assignCount = 0;
          let publishCount = 0;
          let stakeholderTypeList = [];
          productRateStructureObject.dtoProductRateStructureDetails.length !=
            0 &&
            productRateStructureObject.dtoProductRateStructureDetails.map(
              (rateObject) => {
                assignCount = assignCount + rateObject.assignationCount;
                publishCount = publishCount + rateObject.publishCount;
                let stakeholderTypeObj = {
                  id: rateObject.stockStakeHolderTypeId,
                  name: rateObject.stockStakeHolderTypeName,
                };
                stakeholderTypeList.push(stakeholderTypeObj);
              }
            );
          let productRateStructureData = {
            index: index + 1,
            id:
              productRateStructureObject.id === null
                ? ""
                : productRateStructureObject.id,
            name:
              productRateStructureObject.name === null
                ? ""
                : productRateStructureObject.name,
            structureDate:
              productRateStructureObject.structureDate === null
                ? ""
                : productRateStructureObject.structureDate,
            assignCount: assignCount,
            publishCount: publishCount,
            stakeholderTypeList: stakeholderTypeList,
            isActive:
              productRateStructureObject.isActive === null
                ? ""
                : productRateStructureObject.isActive,
          };
          return productRateStructureData;
        });
        dispatch(productRateStructureSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const setProductRateStructure = () => async (dispatch) => {
  dispatch(productRateStructureSuccess({ row: [] }));
};

export const getproductRateStructureAssignation =
  ({ productRateStructureId, stockStakeHolderTypeId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/with-publish-flag-by-type-and-structure?productRateStructureId=" +
          productRateStructureId +
          "&stockStakeHolderTypeId=" +
          stockStakeHolderTypeId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map(
            (productRateStructureAssignationObject, index) => {
              let newPublishDate =
                productRateStructureAssignationObject.publishDate;
              let dateSplit = newPublishDate.split("-");
              let newDate =
                dateSplit[1] + "-" + dateSplit[0] + "-" + dateSplit[2];
              let productRateStructureAssignationData = {
                index: index + 1,
                id:
                  productRateStructureAssignationObject.id === null
                    ? ""
                    : productRateStructureAssignationObject.id,
                productRateStructureId:
                  productRateStructureAssignationObject.productRateStructure ===
                  null
                    ? ""
                    : productRateStructureAssignationObject.productRateStructure
                        .id,
                productRateStructureJson:
                  productRateStructureAssignationObject.productRateStructure ===
                  null
                    ? ""
                    : productRateStructureAssignationObject.productRateStructure
                        .json,
                strucureName:
                  productRateStructureAssignationObject.productRateStructure ===
                  null
                    ? ""
                    : productRateStructureAssignationObject.productRateStructure
                        .name,
                structureDate:
                  productRateStructureAssignationObject.productRateStructure ===
                  null
                    ? ""
                    : productRateStructureAssignationObject.productRateStructure
                        .structureDate,

                publishDate: newDate,
                publishDateToSave:
                  productRateStructureAssignationObject.publishDate === null
                    ? ""
                    : productRateStructureAssignationObject.publishDate,
                ownerName:
                  productRateStructureAssignationObject.stockStakeHolderOwnerName ===
                  null
                    ? ""
                    : productRateStructureAssignationObject.stockStakeHolderOwnerName,
                firmName:
                  productRateStructureAssignationObject.stockStakeHolderFirmName ===
                  null
                    ? ""
                    : productRateStructureAssignationObject.stockStakeHolderFirmName,
                areaName:
                  productRateStructureAssignationObject.stockStakeHolderAreaNames ===
                  null
                    ? ""
                    : productRateStructureAssignationObject.stockStakeHolderAreaNames,
                stockStakeHolderId:
                  productRateStructureAssignationObject.stockStakeHolderId ===
                  null
                    ? ""
                    : productRateStructureAssignationObject.stockStakeHolderId,
                json:
                  productRateStructureAssignationObject.json === null
                    ? ""
                    : productRateStructureAssignationObject.json,
              };
              return productRateStructureAssignationData;
            }
          );
          dispatch(productRateStructureAssignationSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getproductRateStructureAssignationNewAPI =
  ({ productRateStructureId, stockStakeHolderTypeId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/get-with-publish-flag-by-type-and-structure?productRateStructureId=" +
          productRateStructureId +
          "&stockStakeHolderTypeId=" +
          stockStakeHolderTypeId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map(
            (productRateStructureAssignationObject, index) => {
              let newPublishDate =
                productRateStructureAssignationObject.publishDate;
              let dateSplit = newPublishDate.split("-");
              let newDate =
                dateSplit[1] + "-" + dateSplit[0] + "-" + dateSplit[2];
              let productRateStructureAssignationData = {
                index: index + 1,
                id:
                  productRateStructureAssignationObject.id === null
                    ? ""
                    : productRateStructureAssignationObject.id,
                productRateStructureId:
                  productRateStructureAssignationObject.productRateStructureId ===
                  null
                    ? ""
                    : productRateStructureAssignationObject.productRateStructureId,

                strucureName:
                  productRateStructureAssignationObject.productRateStructureName ===
                  null
                    ? ""
                    : productRateStructureAssignationObject.productRateStructureName,

                structureDate:
                  productRateStructureAssignationObject.structureDate === null
                    ? ""
                    : productRateStructureAssignationObject.structureDate,

                publishDate: newDate,
                publishDateToSave:
                  productRateStructureAssignationObject.publishDate === null
                    ? ""
                    : productRateStructureAssignationObject.publishDate,
                ownerName:
                  productRateStructureAssignationObject.stockStakeHolderOwnerName ===
                  null
                    ? ""
                    : productRateStructureAssignationObject.stockStakeHolderOwnerName,
                firmName:
                  productRateStructureAssignationObject.stockStakeHolderFirmName ===
                  null
                    ? ""
                    : productRateStructureAssignationObject.stockStakeHolderFirmName,
                areaName:
                  productRateStructureAssignationObject.stockStakeHolderAreaNames ===
                  null
                    ? ""
                    : productRateStructureAssignationObject.stockStakeHolderAreaNames,
                stockStakeHolderId:
                  productRateStructureAssignationObject.stockStakeHolderId ===
                  null
                    ? ""
                    : productRateStructureAssignationObject.stockStakeHolderId,
                flag:
                  productRateStructureAssignationObject.flag === null
                    ? ""
                    : productRateStructureAssignationObject.flag,
                productMrpHolderWiseRates:
                  productRateStructureAssignationObject.productMrpHolderWiseRates ===
                  null
                    ? ""
                    : productRateStructureAssignationObject.productMrpHolderWiseRates,
              };
              return productRateStructureAssignationData;
            }
          );
          dispatch(productRateStructureAssignationSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getProductRateStructurePublish =
  ({ productRateStructureId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/get-pubish-by-product-rate-structure?productRateStructureId=" +
          productRateStructureId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((structurePublish, index) => {
            let structurePublishData = {
              index: index + 1,
              id: structurePublish.id === null ? "" : structurePublish.id,
              productRateStructure:
                structurePublish.productRateStructure === null
                  ? ""
                  : structurePublish.productRateStructure.id,
              strucureName:
                structurePublish.productRateStructure === null
                  ? ""
                  : structurePublish.productRateStructure.name,
              structureDate:
                structurePublish.productRateStructure === null
                  ? ""
                  : structurePublish.productRateStructure.structureDate,

              stockStakeHolder:
                structurePublish.stockStakeHolder === null
                  ? ""
                  : structurePublish.stockStakeHolder.id,
              firmName:
                structurePublish.stockStakeHolder === null
                  ? ""
                  : structurePublish.stockStakeHolder.firmName,
              ownerName:
                structurePublish.stockStakeHolder === null
                  ? ""
                  : structurePublish.stockStakeHolder.ownerName,

              publishDate:
                structurePublish.publishDate === null
                  ? ""
                  : structurePublish.publishDate,
              billName:
                structurePublish.stockStakeHolder === null
                  ? ""
                  : structurePublish.stockStakeHolder.billName,
              json: structurePublish.json === null ? "" : structurePublish.json,
              isChecked: false,
            };
            return structurePublishData;
          });
          dispatch(productRateStructurePublishSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getProductRateStructurePublishList =
  ({ productRateStructureId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/latest-pubish-by-product-rate-structure?productRateStructureId=" +
          productRateStructureId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((structurePublish, index) => {
            let structurePublishData = {
              index: index + 1,
              id: structurePublish.id === null ? "" : structurePublish.id,
              flag: structurePublish.flag === null ? "" : structurePublish.flag,
              productRateStructure:
                structurePublish.productRateStructureId === null
                  ? ""
                  : structurePublish.productRateStructureId,
              strucureName:
                structurePublish.productRateStructureName === null
                  ? ""
                  : structurePublish.productRateStructureName,

              stockStakeHolder:
                structurePublish.stockStakeHolderId === null
                  ? ""
                  : structurePublish.stockStakeHolderId,
              firmName:
                structurePublish.stockStakeHolderFirmName === null
                  ? ""
                  : structurePublish.stockStakeHolderFirmName,
              ownerName:
                structurePublish.stockStakeHolderOwnerName === null
                  ? ""
                  : structurePublish.stockStakeHolderOwnerName,
              areaName:
                structurePublish.stockStakeHolderAreaNames === null
                  ? ""
                  : structurePublish.stockStakeHolderAreaNames,
              publishDate:
                structurePublish.publishDate === null
                  ? ""
                  : structurePublish.publishDate,
              isChecked: false,
            };
            return structurePublishData;
          });
          dispatch(productRateStructurePublishSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getStructureAssignation =
  ({ structureData }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url: URL1 + "/by-stock-stake-holder-type-and-product-rate-structure",
        postBody: structureData,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((structureDataObject, index) => {
            let structure = {
              index: index + 1,
              id: structureDataObject.id === null ? "" : structureDataObject.id,
              stockStakeHolderId:
                structureDataObject.stockStakeHolder === null
                  ? ""
                  : structureDataObject.stockStakeHolder.id,
              stockStakeHolderFirmName:
                structureDataObject.stockStakeHolder === null
                  ? ""
                  : structureDataObject.stockStakeHolder.firmName +
                    "" +
                    "(" +
                    structureDataObject.stockStakeHolder.ownerName +
                    ")",
              productRateStructureName:
                structureDataObject.productRateStructure === null
                  ? ""
                  : structureDataObject.productRateStructure.name,
              stockStakeHolderType:
                structureDataObject.stockStakeHolder === null
                  ? ""
                  : structureDataObject.stockStakeHolder.stockStakeHolderType
                      .name,
              stockStakeHolderName:
                structureDataObject.stockStakeHolder === null
                  ? ""
                  : structureDataObject.stockStakeHolder.ownerName,

              stockStakeHolderTypeId:
                structureDataObject.stockStakeHolder === null
                  ? ""
                  : structureDataObject.stockStakeHolder.stockStakeHolderType
                      .id,

              productRateStructureId:
                structureDataObject.productRateStructure === null
                  ? ""
                  : structureDataObject.productRateStructure.id,

              structureDate:
                structureDataObject.productRateStructure === null
                  ? ""
                  : structureDataObject.productRateStructure.structureDate,
              json:
                structureDataObject.productRateStructure === null
                  ? ""
                  : structureDataObject.productRateStructure.json,
              publishDate:
                structureDataObject.publishDate === null
                  ? ""
                  : structureDataObject.publishDate,
              count:
                structureDataObject.count === null
                  ? ""
                  : structureDataObject.count,
            };

            return structure;
          });
          dispatch(structureAssignationSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getProductRateStructureOptimised = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-product-rate-structures-by-limit?limit=500",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((productRateStructureObject, index) => {
          let productRateStructureData = {
            index: index + 1,
            id:
              productRateStructureObject.id === null
                ? ""
                : productRateStructureObject.id,
            name:
              productRateStructureObject.name === null
                ? ""
                : productRateStructureObject.name,
            structureDate:
              productRateStructureObject.structureDate === null
                ? ""
                : productRateStructureObject.structureDate,
            isActive:
              productRateStructureObject.isActive === null
                ? ""
                : productRateStructureObject.isActive,
          };
          return productRateStructureData;
        });
        dispatch(productRateStructureSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const setStructureAssignation =
  ({ row }) =>
  async (dispatch) => {
    dispatch(structureAssignationSuccess({ row }));
  };
