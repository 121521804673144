import React from "react";
import { connect } from "react-redux";
import { ManagerJson } from "../../../DynamicFormsJson/MastersJSON/manager";
import { getAreaByUserType } from "../../../Slice/area.slice";
import { getUpdatedManager, getManagerAllUpdated } from "../../../Slice/manager.slice";
import { getStockStakeHolderType } from "../../../Slice/stockStakeHolderType.slice";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { ManagerColumns } from "../../../tableColumns/table-columns";
import { getRegionNew } from "../../../Slice/region.slice";
import { getStateByRegion } from "../../../Slice/state.slice";
import { getZoneByState } from "../../../Slice/zone.slice";
import { getDistrictByZone } from "../../../Slice/district.slice";
import { getTalukaByDistrict } from "../../../Slice/taluka.slice";
import { getAreaByTalukaPostBody } from "../../../Slice/area.slice";
import swal from "sweetalert";
import endpoint from "../../../config/endpoints";
import {
  FailTogenerateCredentialMsg,
  generateCredentialMsg,
  noInternetMsg,
  sendCredentialMsg,
  serverMsg
} from "../../../config/messageconstant";
import { apiGet } from "../../../utils/api_service";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
class SalesExecutiveTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        stockStakeHolderTypeDetail:
          this.props.stockStakeHolderType?.stockStakeHolderType,
        stateDetail: this.props.stateList?.stateByRegion,
        zoneDetail: this.props.zoneList?.zoneByState,
        districtDetail: this.props.districtList?.districtByZone,
        talukaDetail: this.props.talukaList?.talukaByDistrict,
        areaDetail: this.props.areaList?.areaByTaluka,

      },
    };
  }
  async componentDidMount() {
    const { getRegionNew, getStockStakeHolderType, getAreaByUserType, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      const userTypeId = localStorage.getItem("userTypeId");

      await this.getTableData()

      if (userTypeId == 1 || userTypeId == 6) {
        showLoador({ loador: true });
        await getRegionNew().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      } else {
        showLoador({ loador: true });
        await getAreaByUserType().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }

      showLoador({ loador: true });
      getStockStakeHolderType({ userTypeIds: 3 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.stockStakeHolderType !== nextProps.stockStakeHolderType) {
      if (
        this.props.stockStakeHolderType?.stockStakeHolderType !==
        nextProps.stockStakeHolderType?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stockStakeHolderTypeDetail:
              nextProps.stockStakeHolderType?.stockStakeHolderType,
          },
        });
      }
    }
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (this.props.stateList?.stateByRegion !== nextProps.stateList?.stateByRegion) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (this.props.districtList?.districtByZone !== nextProps.districtList?.districtByZone) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (this.props.talukaList?.talukaByDistrict !== nextProps.talukaList?.talukaByDistrict) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }

  }

  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTalukaPostBody,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      console.log(data);
      if (data.region && data.region != "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state && data.state != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.zone && data.zone != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zone }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.district && data.district != "") {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka && data.taluka != "") {
        let talukaJson = {
          talukaIds: data.taluka,
        }
        showLoador({ loador: true });
        await getAreaByTalukaPostBody({ talukaIds: talukaJson }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };



  onSearchData = async (data) => {
    const { getUpdatedManager, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      console.log(data);
      if (data.stockStakeHolderType != null && data.stockStakeHolderType != '' && data.area != null && data.area != '') {
        let managerJson = {

          stockStakeHolderTypeId: data.stockStakeHolderType,
          areaIds: data.area
        }

        showLoador({ loador: true });
        await getUpdatedManager({ managerJson: managerJson }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onClickApply = (rowData) => {
    const { showLoador, showNotification } = this.props;
    swal({
      title: "Are you sure?",
      text: sendCredentialMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (navigator.onLine) {
          this.props.showLoador({ loador: true });
          apiGet({
            url: endpoint.salesExecutive + "/" + rowData.id + "/password-reset",
          }).then(({ success }) => {
            if (success) {
              this.props.showLoador({ loador: false });
              this.props.showNotification({
                msg: generateCredentialMsg,
              });
            } else {
              this.props.showNotification({
                msg: FailTogenerateCredentialMsg,
                severity: "error",
              });
              this.props.showLoador({ loador: false });
            }
          });
        }
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    });
  };

  getTableData = async () => {
    const { getManagerAllUpdated, showLoador, showNotification } =
      this.props;
    showLoador({ loador: true });
    await getManagerAllUpdated().then(({ success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      }
    });

  }



  render() {
    const { managerList } = this.props;
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          dynamicMasterData={dynamicMasterData}
          getListById={this.getListById}
          searchList={ManagerJson.searchList}
          onSearchData={this.onSearchData}
          formPath={ManagerJson.formPath}
          screenTitle={ManagerJson.screenTitle}
          fieldMeta={ManagerJson.fieldMeta}
          showPdfDownload={false}
          showExcelDownload={true}
          changeExcelURL={ManagerJson.excelUrl}
          showExtension={false}
          pdfFileName={ManagerJson.pdfFileName}
          isActiveURL={ManagerJson.isActiveURL}
          excelFileName={ManagerJson.excelFileName}
          showAddButton={ManagerJson.showAddButton}
          tableColumnsToFilter={ManagerJson.tableColumnsToFilter}
          tableColumns={ManagerColumns}
          tableData={managerList?.manager}
          apiBaseURL={ManagerJson.apiBaseURL}
          callApi={false}
          getTableDataById={true}
          showApply={true}
          rowApply={this.onClickApply}
          rowDelete={this.rowDelete}
          isNote={true}
          getTableData={this.getTableData}
          isNoteValue={"Add Sales Manager & Details"}
          searchButtonGrid={ManagerJson.searchButtonGrid}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  managerList: state.manager,
  stockStakeHolderType: state.stockStakeHolderType,
  areaList: state.area,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
});
const mapDispatchToProps = {
  getUpdatedManager,
  getStateByRegion,
  getZoneByState,
  getStockStakeHolderType,
  getRegionNew,
  getAreaByUserType,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTalukaPostBody,
  showLoador,
  getManagerAllUpdated,
  showNotification,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesExecutiveTable);