import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import ListAltIcon from "@mui/icons-material/ListAlt";
import TableHead from "@mui/material/TableHead";
import CommentIcon from "@mui/icons-material/Comment";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import LinkIcon from "@mui/icons-material/Link";
import * as React from "react";
import EditIcon from "@mui/icons-material/Edit";
import {
  ChipCompo,
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../IconButton";
import PaidIcon from "@mui/icons-material/Paid";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SchoolIcon from "@mui/icons-material/School";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import TouchAppRoundedIcon from "@mui/icons-material/TouchAppRounded";
import ListTwoToneIcon from "@mui/icons-material/ListTwoTone";
import { FormHelperText, Grid } from "@mui/material";
function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    isActionColActive,
    order,
    tableHead,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {tableHead.map((headCell) => {
          if (headCell.showInscreen && headCell.title !== "Action") {
            return (
              <TableCell
                key={headCell.name}
                align={"center"}
                style={{ minWidth: headCell.width }}
                // padding={headCell.disablePadding ? 'none' : 'normal'}
                // sortDirection={orderBy === headCell.name ? order : false}
              >
                {headCell.title}
                {orderBy === headCell.name ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableCell>
            );
          } else if (
            headCell.showInscreen &&
            isActionColActive &&
            headCell.title === "Action"
          ) {
            return (
              <TableCell
                key={headCell.name}
                align={"center"}
                style={{ minWidth: headCell.width }}
                // padding={headCell.disablePadding ? 'none' : 'normal'}
                // sortDirection={orderBy === headCell.name ? order : false}
              >
                {headCell.title}
                {orderBy === headCell.name ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
}
function Row({
  row,
  tableHead,
  rowStatus,
  statusChange,
  rowDelete,
  rowEdit,
  rowId,
  rowView,
  rowViewData,
  showHeadEdit,
  showHeadDelete,
  studListEmpty = false,
  msg,
  showHeadAdd,
  showApply,
  showView,
  showRoundDetails,
  showRoundDetailsNew,
  rounds,
  isActionColActive,
  showPayFees,
  showPaymentDetails,
  showAppliedList,
  showAppliedListNew,
  showDetails,
  showSchoolIcon,
  showLinkIcon,
  showPersonAdd,
  rowPersonAddDetails,
  rowFeesPaid,
  rowAppliedList,
  rowDetails,
  rowSchoolDetails,
  generateDetails,
  rowPaymentDetails,
  showHeadPdf,
  marginTop,
  showHeadExcel,
  condition = [],
  pdfDetailsId,
  excelDetailsId,
  showFillMarks,
  rowFillMarks,
  showComment,
  rowComment,
}) {
  const [role, setRole] = React.useState("");

  const changeStatus = (e, row) => {
    e.stopPropagation();
    rowStatus(row);
  };

  const onClickFillMarks = (e, row) => {
    e.stopPropagation();
    rowFillMarks(row);
  };

  const changeRowStatus = (e, row) => {
    e.stopPropagation();
    statusChange(row);
  };

  const onClickDelete = (e, id) => {
    e.stopPropagation();
    rowDelete(id);
  };
  const pdfDetails = (e, row) => {
    e.stopPropagation();
    pdfDetailsId(row);
  };
  const excelDetails = (e, row) => {
    e.stopPropagation();
    excelDetailsId(row);
  };
  const onClickEdit = (e, id) => {
    e.stopPropagation();
    rowEdit(id);
  };
  const onClickRowId = (e, id) => {
    e.stopPropagation();
    rowId(id);
  };
  const onClickApply = (e, id) => {
    e.stopPropagation();
    rowView(id);
  };

  const onClickViewPreview = (e, row) => {
    e.stopPropagation();
    rowViewData(row);
  };

  const onClickComment = (e, row) => {
    e.stopPropagation();
    rowComment(row);
  };

  const roundForm = (e, id) => {
    e.stopPropagation();
    rounds(id);
  };

  const feesPaid = (e, params) => {
    e.stopPropagation();
    rowFeesPaid(params);
  };
  const appliedList = (e, params) => {
    e.stopPropagation();
    rowAppliedList(params);
  };

  const details = (e, params) => {
    e.stopPropagation();
    rowDetails(params);
  };
  const schoolDetails = (e, params) => {
    e.stopPropagation();
    rowSchoolDetails(params);
  };
  const linkDetails = (e, params) => {
    e.stopPropagation();
    generateDetails(params);
  };

  const personAddDetails = (e, params) => {
    e.stopPropagation();
    rowPersonAddDetails(params);
  };

  const paymentDetails = (e, params) => {
    e.stopPropagation();
    rowPaymentDetails(params);
  };

  const conditionApplied = condition.filter(
    (rowData) => rowData.value == row["index"]
  );
 
  React.useEffect(() => {
    let role = localStorage.getItem("role");
    if (role) {
      setRole(role);
    }
  }, []);
  return (
    <TableRow key={row.name}>
      {Object.keys(row).map(function (key, index) {
        const filteredList = tableHead.filter(
          (row) => row.name === key && row.showInscreen
        );

        if ( filteredList.length !== 0 )
        {
      
          if (
            filteredList[0].positionCenter &&
            key !== "isActive" &&
            key !== "isStatus"
          )
            return (
              <TableCell>
                <center>{row[key]}</center>
              </TableCell>
            );
          else if (key === "isActive") {
            return (
              <TableCell>
                {row[key] === 1 ? (
                  <center>
                    <ChipCompo
                      label="Active"
                      variant="outlined"
                      size="small"
                      color="success"
                      onClick={(e) => changeStatus(e, row)}
                    />
                  </center>
                ) : (
                  <center>
                    <ChipCompo
                      label="Inactive"
                      variant="outlined"
                      size="small"
                      color="error"
                      onClick={(e) => changeStatus(e, row)}
                    />
                  </center>
                )}
              </TableCell>
            );
          } else if (key === "isStatus") {
            return (
              <TableCell>
                {row[key] === "Completed" ? (
                  <center>
                    <ChipCompo
                      label={row[key]}
                      variant="outlined"
                      size="small"
                      color="success"
                      onClick={(e) => changeRowStatus(e, row)}
                    />
                  </center>
                ) : (
                  <center>
                    <ChipCompo
                      label={row[key]}
                      variant="outlined"
                      size="small"
                      color="error"
                      onClick={(e) => changeRowStatus(e, row)}
                    />
                  </center>
                )}
              </TableCell>
            );
          } else
            return (
              <TableCell align={filteredList[0].alignCenter}>
                {" "}
                {row[key]}
              </TableCell>
            );
        }
      })}

      {isActionColActive && (
        <>
          <TableCell align={"center"}>
            {condition.length !== 0 &&
            conditionApplied.length !== 0 &&
            conditionApplied[0].iconHide !== "showHeadPdf" &&
            showHeadPdf ? (
              <>
                &nbsp;&nbsp;
                <FontAwesomeIconCompo
                  className="fa-file-pdf"
                  color="error"
                  title="PDF"
                  baseClassName="fas"
                  fontSize="small"
                  onClick={(e) => pdfDetails(e, row)}
                />
              </>
            ) : condition.length !== 0 &&
              showHeadPdf &&
              conditionApplied.length ==0? (
              <>
                &nbsp;&nbsp;
                <FontAwesomeIconCompo
                  className="fa-file-pdf"
                  color="error"
                  title="PDF"
                  baseClassName="fas"
                  fontSize="small"
                  onClick={(e) => pdfDetails(e, row)}
                />
              </>
            ) : (
              condition.length !== 0 &&
              conditionApplied.length !== 0 &&
              conditionApplied[0].iconHide === "showHeadPdf" && (
                <> &nbsp;&nbsp;&emsp;</>
              )
            )}
            {condition.length === 0 && showHeadPdf && (
              <>
                &nbsp;&nbsp;
                <FontAwesomeIconCompo
                  className="fa-file-pdf"
                  color="error"
                  title="PDF"
                  baseClassName="fas"
                  fontSize="small"
                  onClick={(e) => pdfDetails(e, row)}
                />
              </>
            )}

            {condition.length !== 0 &&
            conditionApplied.length !== 0 &&
            conditionApplied[0].iconHide !== "showHeadExcel" &&
            showHeadExcel ? (
              <>
                &nbsp;&nbsp;
                <FontAwesomeIconCompo
                  className="fa-file-excel"
                  sx={{color:"#58D68D"}}
                  title="Excel"
                  baseClassName="fas"
                  fontSize="small"
                  onClick={(e) => excelDetails(e, row)}
                />
                  
              </>
            ) : condition.length !== 0 &&
              showHeadExcel &&
              conditionApplied.length === 0 ? (
              <>
                &nbsp;&nbsp;
                <FontAwesomeIconCompo
                  className="fa-file-excel"
                  sx={{color:"#58D68D"}}
                  title="Excel"
                  baseClassName="fas"
                  fontSize="small"
                  onClick={(e) => excelDetails(e, row)}
                />
                 
              </>
            ) : (
              condition.length !== 0 &&
              conditionApplied.length !== 0 &&
              conditionApplied[0].iconHide === "showHeadExcel" && (
                <> &nbsp;&nbsp;&emsp;</>
              )
            )}
            {condition.length === 0 && showHeadExcel && (
              <>
                &nbsp;&nbsp;
                <FontAwesomeIconCompo
                  className="fa-file-excel"
                  title="Excel"
                  baseClassName="fas"
                  fontSize="small"
                  onClick={(e) => excelDetails(e, row)}
                  sx={{color:"#58D68D"}}
                />
                
              </>
            )}
          </TableCell>
        </>
      )}
    </TableRow>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function ReportTable({
  tableHead,
  rowStatus,
  statusChange,
  rowDelete,
  rowEdit,
  data,
  showHeadDelete,
  studListEmpty = false,
  msg,
  showHeadEdit,
  rowId,
  showHeadAdd,
  rowView,
  rowViewData,
  showApply,
  showView,
  showRoundDetails,
  showRoundDetailsNew,
  showPegination = true,
  rounds,
  isActionColActive = true,
  rowFeesPaid,
  rowAppliedList,
  rowPaymentDetails,
  showPayFees,
  showPaymentDetails,
  showAppliedList,
  showAppliedListNew,
  showDetails,
  showLinkIcon,
  showSchoolIcon,
  showPersonAdd,
  rowDetails,
  rowSchoolDetails,
  generateDetails,
  rowPersonAddDetails,
  showHeadPdf,
  marginTop,
  showHeadExcel,
  pdfDetailsId,
  excelDetailsId,
  condition,
  rowFillMarks,
  showFillMarks,
  showComment,
  rowComment,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer sx={{ maxHeight: 500 }}>
        {studListEmpty === true && <FormHelperText error>{msg}</FormHelperText>}
        <Table stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            tableHead={tableHead}
            numSelected={selected.length}
            isActionColActive={isActionColActive}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
          />
          <TableBody>
            {showPegination
              ? stableSort(data, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <Row
                        key={row.id}
                        row={row}
                        tableHead={tableHead}
                        showHeadDelete={showHeadDelete}
                        showHeadEdit={showHeadEdit}
                        rowEdit={rowEdit}
                        rowStatus={rowStatus}
                        statusChange={statusChange}
                        rowDelete={rowDelete}
                        showHeadAdd={showHeadAdd}
                        showHeadPdf={showHeadPdf}
                        marginTop={marginTop}
                        showHeadExcel={showHeadExcel}
                        condition={condition}
                        rowId={rowId}
                        rowView={rowView}
                        rowViewData={rowViewData}
                        showApply={showApply}
                        showView={showView}
                        showComment={showComment}
                        rowComment={rowComment}
                        showRoundDetails={showRoundDetails}
                        showRoundDetailsNew={showRoundDetailsNew}
                        showPegination={showPegination}
                        rounds={rounds}
                        isActionColActive={isActionColActive}
                        rowFeesPaid={rowFeesPaid}
                        rowAppliedList={rowAppliedList}
                        rowDetails={rowDetails}
                        rowPaymentDetails={rowPaymentDetails}
                        showPayFees={showPayFees}
                        showPaymentDetails={showPaymentDetails}
                        showAppliedList={showAppliedList}
                        showAppliedListNew={showAppliedListNew}
                        showDetails={showDetails}
                        showSchoolIcon={showSchoolIcon}
                        showLinkIcon={showLinkIcon}
                        rowSchoolDetails={rowSchoolDetails}
                        generateDetails={generateDetails}
                        pdfDetailsId={pdfDetailsId}
                        excelDetailsId={excelDetailsId}
                        showPersonAdd={showPersonAdd}
                        rowPersonAddDetails={rowPersonAddDetails}
                        rowFillMarks={rowFillMarks}
                        showFillMarks={showFillMarks}
                        //   tableSubHead={tableSubHead}
                        //   tableSubList={tableSubList}
                      />
                    );
                  })
              : stableSort(data, getComparator(order, orderBy)).map(
                  (row, index) => {
                    return (
                      <Row
                        key={row.id}
                        row={row}
                        tableHead={tableHead}
                        showHeadDelete={showHeadDelete}
                        showHeadEdit={showHeadEdit}
                        rowEdit={rowEdit}
                        rowStatus={rowStatus}
                        statusChange={statusChange}
                        rowDelete={rowDelete}
                        showHeadAdd={showHeadAdd}
                        showHeadPdf={showHeadPdf}
                        marginTop={marginTop}
                        showHeadExcel={showHeadExcel}
                        condition={condition}
                        rowId={rowId}
                        rowView={rowView}
                        rowViewData={rowViewData}
                        showApply={showApply}
                        showView={showView}
                        showComment={showComment}
                        rowComment={rowComment}
                        showRoundDetails={showRoundDetails}
                        showRoundDetailsNew={showRoundDetailsNew}
                        showPegination={showPegination}
                        rounds={rounds}
                        isActionColActive={isActionColActive}
                        rowFeesPaid={rowFeesPaid}
                        rowAppliedList={rowAppliedList}
                        rowDetails={rowDetails}
                        rowPaymentDetails={rowPaymentDetails}
                        showPayFees={showPayFees}
                        showPaymentDetails={showPaymentDetails}
                        showAppliedList={showAppliedList}
                        showDetails={showDetails}
                        showSchoolIcon={showSchoolIcon}
                        showLinkIcon={showLinkIcon}
                        rowSchoolDetails={rowSchoolDetails}
                        generateDetails={generateDetails}
                        pdfDetailsId={pdfDetailsId}
                        excelDetailsId={excelDetailsId}
                        showPersonAdd={showPersonAdd}
                        rowPersonAddDetails={rowPersonAddDetails}
                        rowFillMarks={rowFillMarks}
                        showFillMarks={showFillMarks}
                        //   tableSubHead={tableSubHead}
                        //   tableSubList={tableSubList}
                      />
                    );
                  }
                )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={tableHead.length} />
              </TableRow>
            )}
            {data.length === 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * 1,
                }}
              >
                <TableCell align="center" colSpan={tableHead.length}>
                No Record Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {showPegination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
}
