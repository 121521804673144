import {
  Box,
  DialogActions,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import {
  getMapData,
  getTimelineForSalesExecutive,
} from "../../Slice/location.slice";
import { timeLineJson } from "../../DynamicFormsJson/MastersJSON/timeLine";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { cyanBlue, redColor, titleColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  dataNotFoundMsg,
  fromdateMsg,
  noInternetMsg,
  serverMsg,
  todateMsg,
} from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import TimelineDetailsPopup from "./TimelineDetailsPopup";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import { ButtonCompo } from "../../components/Comman/Button";
import dayjs from "dayjs";
import GMap from "./GMap";
import moment from "moment";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const Timeline = ({
  showNotification,
  outletDetails,
  location,
  getMapData,
  showLoador,
  getTimelineForSalesExecutive,
  saveData,
  dateFlag,
}) => {


  const params = useParams();
  const [value, setValue] = useState(0);
  const [rows, setRows] = useState([]);
  const [fromdate, setFromdate] = useState("");
  const [todate, setTodate] = useState("");
  const [date, setDate] = useState("");
  const [popUpFlag, setPopUpFlag] = useState(false);
  const [companyFlag, setCompanyFlag] = useState("");
  const [DataToSet, setDataToSet] = useState([]);
  const [dataToSetPastOrder, setDataToSetPastOrder] = useState([]);
  const [outletOrderDetails, setOutletOrderDetails] = useState([]);
  const [outletOrderDetailsPastOrder, setOutletOrderDetailsPastOrder] = useState([]);
  const [formErrors, setFormErrors] = React.useState({});
  const navigate = useNavigate();
  useEffect(() => {
    if (rows.length === 0) {
      getTimelineList();
    }

  }, []);
  const onCancel = () => {
    navigate(-1);
  };
  const getTimelineList = () => {
    const selectedDay = location.salesTeamTimeline[value]?.day;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getTimelineForSalesExecutive({
        fromDate: "",
        toDate: "",
        salesTeamIds: params.id,
        selectedDay,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {

          const newRows = response.map((rowData, index) => ({
            ...rowData,
            index,
          }));
          setRows(newRows);
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  const changeDate = (name) => (date) => {
    if (name === "fromdate") {
      setFromdate(date);
    } else if (name === "todate") {
      setTodate(date);
    }
  };

  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (fromdate === "") {
      formIsValid = false;
      formErrors["fromdateError"] = fromdateMsg;
    }
    if (todate === "") {
      formIsValid = false;
      formErrors["todateError"] = todateMsg;
    }
    setFormErrors(formErrors);
    return formIsValid;
  };

  const searchData = () => {
    const selectedDay = location.salesTeamTimeline[value]?.day;
    if (navigator.onLine) {
      if (checkValidation()) {

        setRows([]);

        showLoador({ loador: true });
        getTimelineForSalesExecutive({
          fromDate: dayjs(fromdate).format("DD-MM-YYYY"),
          toDate: dayjs(todate).format("DD-MM-YYYY"),
          salesTeamIds: params.id,
          selectedDay,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            const newRowsOnSearch = response.map((rowData, index) => ({
              ...rowData,
              index,
            }));
            setRows(newRowsOnSearch);
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    saveData(newValue + 1);
  };
  const timelineDetails = async (row) => {

    if (navigator.onLine) {

      setDataToSet([]);
      setOutletOrderDetails([]);

      setDataToSetPastOrder([]);
      setOutletOrderDetailsPastOrder([]);

      const showCompanyFlag = localStorage.getItem("showCompanyFlag");
      setCompanyFlag(showCompanyFlag);

      showLoador({ loador: true });
      await apiGet({
        url: endpoint.outletOrderHeader + "/" + row.outletOrderHeaderId,
      }).then(({ data, success }) => {
        if (success) {
          setDataToSet([
            {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              label: "Order No",
              value: data.data.orderNo,
            },
            {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              label: "Order Date",
              value: data.data.orderDate,
            },
            {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              label: "Beat Name",
              value: data.data.outLet.beat.beatName,
            },
            {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              label: "Outlet Name",
              value: data.data.outLet.firmName,
            },
            {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              label: "Owner Name",
              value: data.data.outLet.ownerName,
            },
            {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              label: "Mobile No",
              value: data.data.outLet.mobileNo
                ? data.data.outLet.mobileNo
                : "-",
            },
          ]);
          (outletDetails = data.data.outletOrderDetails.map(
            (rowData, index) => {
              let outletData = {
                index: index + 1,
                productName: rowData.product.name,
                uom: rowData.product.outletUom.name,
                rate: rowData.rate.toFixed(2),
                qty: rowData.qty,
                totalAmt: rowData.totalAmt.toFixed(2),
              };
              return outletData;
            }
          )),
            setOutletOrderDetails(outletDetails);
          setPopUpFlag(true);

          if (showCompanyFlag == 2) {
            showLoador({ loador: true });
            apiGet({
              url: endpoint.outletOrderHeader + "/last-visit-and-order?date=" + dateFlag + "&outletId=" + row.outletId,
            }).then(({ data, success }) => {
              if (success) {
                if (data && data.data && Object.keys(data.data).length !== 0) {
                  if (data.data.isVisitIsOrder !== null && data.data.outletOrderHerader !== null) {

                    setDataToSetPastOrder([
                      {
                        xs: 12,
                        sm: 4,
                        md: 4,
                        lg: 4,
                        label: "Order No",
                        value: data.data.outletOrderHerader !== null && data.data.outletOrderHerader.orderNo !== null ? data.data.outletOrderHerader.orderNo : "",
                      },
                      {
                        xs: 12,
                        sm: 4,
                        md: 4,
                        lg: 4,
                        label: "Order Date",
                        value: data.data.outletOrderHerader !== null && data.data.outletOrderHerader.orderDate
                          !== null ? data.data.outletOrderHerader.orderDate
                          : "",
                      },
                      {
                        xs: 12,
                        sm: 4,
                        md: 4,
                        lg: 4,
                        label: "Outlet Name",
                        value: data.data.outletOrderHerader !== null && data.data.outletOrderHerader.outLet !== null
                          && data.data.outletOrderHerader.outLet.firmName !== null ? data.data.outletOrderHerader.outLet.firmName : "",
                      },
                    ]);


                    if (data.data.outletOrderHerader && data.data.outletOrderHerader !== null) {


                      if (data.data.outletOrderHerader.outletOrderDetails && data.data.outletOrderHerader.outletOrderDetails.length !== 0) {
                        (outletDetails = data.data.outletOrderHerader.outletOrderDetails.map(
                          (rowData, index) => {
                            let outletData = {
                              index: index + 1,
                              productName: rowData.product.name,
                              uom: rowData.product.outletUom.name,
                              rate: rowData.rate.toFixed(2),
                              qty: rowData.qty,
                              totalAmt: rowData.totalAmt.toFixed(2),
                            };
                            return outletData;
                          }
                        )),

                          setOutletOrderDetailsPastOrder(outletDetails);
                        setPopUpFlag(true);
                      }
                    }
                    showLoador({ loador: false });
                  } else {
                    showLoador({ loador: false });
                    showNotification({ msg: dataNotFoundMsg, severity: "error" });
                  }
                } else {
                  showNotification({ msg: serverMsg, severity: "error" });
                }
              } else {
                showLoador({ loador: false });
                showNotification({ msg: serverMsg, severity: "error" });
              }
            });
          }
          showLoador({ loador: false });

        } else {

          if (showCompanyFlag == 2) {
            showLoador({ loador: true });
            apiGet({
              url: endpoint.outletOrderHeader + "/last-visit-and-order?date=" + dateFlag + "&outletId=" + row.outletId,
            }).then(({ data, success }) => {
              if (success) {
                if (data && data.data && Object.keys(data.data).length !== 0) {
                  if (data.data.isVisitIsOrder !== null && data.data.outletOrderHerader !== null) {

                    setDataToSetPastOrder([
                      {
                        xs: 12,
                        sm: 4,
                        md: 4,
                        lg: 4,
                        label: "Order No",
                        value: data.data.outletOrderHerader !== null && data.data.outletOrderHerader.orderNo !== null ? data.data.outletOrderHerader.orderNo : "",
                      },
                      {
                        xs: 12,
                        sm: 4,
                        md: 4,
                        lg: 4,
                        label: "Order Date",
                        value: data.data.outletOrderHerader !== null && data.data.outletOrderHerader.orderDate
                          !== null ? data.data.outletOrderHerader.orderDate
                          : "",
                      },
                      {
                        xs: 12,
                        sm: 4,
                        md: 4,
                        lg: 4,
                        label: "Outlet Name",
                        value: data.data.outletOrderHerader !== null && data.data.outletOrderHerader.outLet !== null
                          && data.data.outletOrderHerader.outLet.firmName !== null ? data.data.outletOrderHerader.outLet.firmName : "",
                      },
                    ]);


                    if (data.data.outletOrderHerader && data.data.outletOrderHerader !== null) {


                      if (data.data.outletOrderHerader.outletOrderDetails && data.data.outletOrderHerader.outletOrderDetails.length !== 0) {
                        (outletDetails = data.data.outletOrderHerader.outletOrderDetails.map(
                          (rowData, index) => {
                            let outletData = {
                              index: index + 1,
                              productName: rowData.product.name,
                              uom: rowData.product.outletUom.name,
                              rate: rowData.rate.toFixed(2),
                              qty: rowData.qty,
                              totalAmt: rowData.totalAmt.toFixed(2),
                            };
                            return outletData;
                          }
                        )),

                          setOutletOrderDetailsPastOrder(outletDetails);
                        setPopUpFlag(true);
                      }
                    }
                    showLoador({ loador: false });
                  } else {
                    showLoador({ loador: false });
                    showNotification({ msg: dataNotFoundMsg, severity: "error" });
                  }
                } else {
                  showNotification({ msg: serverMsg, severity: "error" });
                }
              } else {
                showLoador({ loador: false });
                showNotification({ msg: serverMsg, severity: "error" });
              }
            });
          } else {
            showLoador({ loador: false });
            showNotification({ msg: serverMsg, severity: "error" });
          }

        }
      });

    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  const handleClose = () => {
    setPopUpFlag(false);
  };
  console.log(rows);
  return (
    <Paper
      sx={{
        paddingLeft: 2,
        borderRadius: 2,
      }}
      elevation={0}
      style={{ width: "100%" }}
    >
      <LandingScreenHeader
        screenTitle={"Timeline"}
        showAddButton={false}
        showBackButton={true}
        onCancel={onCancel}
      />
      <br />
      <Grid
        item
        sm={6}
        xs={7}
        md={8}
        lg={6}
        container
        justifyContent="space-evenly"
      >
        <Grid item xs={12} md={5} sm={5}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 16, color: redColor }}
            label="* "
          />
          <LabelCompo
            className="text-black"
            style={{ fontSize: 16, color: titleColor }}
            label="From Date :"
          />
        </Grid>
        <Grid item xs={12} md={7} sm={7}>
          <BasicDatePicker
            color="primary"
            name="fromdate"
            size="small"
            value={fromdate}
            disabled={false}
            fullWidth
            onChange={changeDate("fromdate")}
            errorText={
              formErrors["fromdateError"] ? formErrors["fromdateError"] : ""
            }
            isError={
              formErrors["fromdateError"] ? formErrors["fromdateError"] : ""
            }
          />
        </Grid>

        <Grid item xs={12} md={5} sm={5}>
          <LabelCompo
            className="text-black"
            style={{ fontSize: 16, color: redColor }}
            label="* "
          />
          <LabelCompo
            className="text-black"
            style={{ fontSize: 16, color: titleColor }}
            label="To Date :"
          />
        </Grid>
        <Grid item xs={12} md={7} sm={7}>
          <BasicDatePicker
            color="primary"
            name="todate"
            size="small"
            value={todate}
            minDate={fromdate}
            maxDate={dayjs().subtract(1, "day")}
            disabled={false}
            fullWidth
            onChange={changeDate("todate")}
            errorText={
              formErrors["todateError"] ? formErrors["todateError"] : ""
            }
            isError={formErrors["todateError"] ? formErrors["todateError"] : ""}
          />
        </Grid>
      </Grid>
      <DialogActions>
        <Grid item sx={{ mr: 3 }} xs={12} sm={12} md={1}>
          <ButtonCompo
            size="medium"
            onClick={searchData}
            variant="contained"
            name="Search"
          />
        </Grid>
      </DialogActions>
      <br />

      <Box>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Tabs
              variant="scrollable"
              aria-label="scrollable force tabs example"
              value={value}
              onChange={handleChange}
              style={{ overflow: "auto", width: "100%" }}
            >
              {location.salesTeamTimeline.map((salesTeamData, index) => (
                <Tab key={index} label={salesTeamData.day} />
              ))}
            </Tabs>
            {location.salesTeamTimeline.map((salesTeamData, index) => {
              let totalAmount = 0;
              if (salesTeamData?.timelineData?.length !== 0) {
                salesTeamData.timelineData.map(data => {
                  totalAmount += +data.amount;
                });
              }

              return (
                <TabPanel key={index} value={value} index={index}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: titleColor }}
                      label="Beat Names :"
                    />{" "}
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={
                        rows[index]?.timelineData.length !== 0
                          ? rows[index]?.timelineData[0].beatName
                          : "-"
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: titleColor }}
                      label="Total Amount :"
                    />{" "}
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16 }}
                      label={totalAmount !== 0 ? totalAmount : "-"}

                    />
                  </Grid>
                  <TableContainer sx={{ width: "maxContent" }}>
                    <Table
                      sx={{ minWidth: "maxContent" }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">#</TableCell>
                          <TableCell align="center">Time</TableCell>
                          <TableCell align="center">Outlet Name</TableCell>
                          <TableCell align="center">Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows[index]?.timelineData.map((rowData, rowIndex) => (
                          <TableRow key={rowData.index}>
                            <TableCell align="center">
                              {rowData.index + 1}
                            </TableCell>
                            <TableCell align="center">{rowData.time}</TableCell>
                            <TableCell align="left">
                              <div
                                onClick={() => timelineDetails(rowData)}
                                style={{
                                  color: cyanBlue,
                                  cursor: "pointer",
                                }}
                              >
                                {rowData.outletName}
                              </div>
                            </TableCell>
                            <TableCell align="right">{rowData.amount}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabPanel>
              );
            })}
          </Grid>
        </Grid>
      </Box>
      {popUpFlag && (
        <TimelineDetailsPopup
          open={popUpFlag}
          handleCloses={handleClose}
          columns={[
            {
              title: "Sr. No.",
              name: "index",
              positionCenter: false,
              showInscreen: true,
              alignCenter: "center",
              canSearch: true,
            },
            {
              title: "Product Name",
              name: "productName",
              positionCenter: false,
              showInscreen: true,
              alignCenter: "left",
              canSearch: true,
            },
            {
              title: "UOM",
              name: "uom",
              positionCenter: false,
              showInscreen: true,
              alignCenter: "center",
              canSearch: true,
            },
            {
              title: "Rate",
              name: "rate",
              positionCenter: false,
              showInscreen: true,
              alignCenter: "right",
              canSearch: true,
            },
            {
              title: "Quantity",
              name: "qty",
              positionCenter: false,
              showInscreen: true,
              alignCenter: "right",
              canSearch: true,
            },
            {
              title: "Total Amount",
              name: "totalAmt",
              positionCenter: false,
              showInscreen: true,
              alignCenter: "right",
              canSearch: true,
            },
          ]}
          rows={outletOrderDetails}
          rowsPastOrder={outletOrderDetailsPastOrder}
          DataToSet={DataToSet}
          dataToSetPastOrder={dataToSetPastOrder}
          companyFlag={companyFlag}
        />
      )}
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  location: state.location,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getMapData,
  getTimelineForSalesExecutive,
};
export default connect(mapStateToProps, mapDispatchToProps)(Timeline);
