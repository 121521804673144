import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import { TextFieldCompo } from "../../../components/Comman/TextFieldCompo";
import { titleColor } from "../../../config/ColorObj";
import endpoint from "../../../config/endpoints";
import {
  jsonMsg,
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
} from "../../../config/messageconstant";
import { apiPost } from "../../../utils/api_service";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const OutletPopupForActiveInactiveForBeat = ({
  handleCloses,
  open,
  outletId,
  popupFlag,
  isActive,
  showLoador,
  showNotification,
}) => {
  const [name, setName] = React.useState("");

  const handleClose = () => {
    handleCloses();
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setName(value);
    }
  };

  const onSubmit = () => {
    if (navigator.onLine) {
      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          showLoador({ loador: true });
          const remark = name.trim();
          apiPost({
            url:
              endpoint.outlet +
              "/update-is-active?id=" +
              outletId +
              "&isActive=" +
              isActive +
              "&remark=" +
              remark,
          }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success) {
              showNotification({ msg: savemsg });
              handleCloses(true);

            } else {
              showNotification({ msg: saveFailedMsg, severity: "error" });
            }
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  return (
    <>
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="md"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            ACTIVE-INACTIVE OUTLET
          </BootstrapDialogTitle>

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            sx={{ p: 2 }}
            justifyContent="space-evenly"
          >
            <Grid item sm={12} xs={12} md={12} lg={12}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: titleColor }}
                label={"Remark : "}
              />
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                }}
              >
                <TextFieldCompo
                  placeholder="Enter Remark"
                  color="primary"
                  variant="outlined"
                  name="name"
                  size="small"
                  value={name}
                  multiline
                  fullWidth
                  onChange={onChangeHandler}
                />
              </Box>
            </Grid>
            <DialogContent>
              <br />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Submit"
                  onClick={onSubmit}
                />
              </Grid>
            </DialogContent>
          </Grid>
        </BootstrapDialog>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showNotification, showLoador };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutletPopupForActiveInactiveForBeat);
