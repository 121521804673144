import { Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import MaterialUITableWithTextField from "../../components/Comman/RT/MaterialUITableWithTextField";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import {
  serverMsg,
  saveFailedMsg,
  savemsg,
  noInternetMsg,
  saveWarningMsg,
} from "../../config/messageconstant";
import { outletRateStructureJson } from "../../DynamicFormsJson/Transaction/OutletRateStructure";
import {
  getProductByCategoryId,
  setProductList,
  getProductByOutletId,
} from "../../Slice/category.slice";
import endpoint from "../../config/endpoints";
import { LabelCompo } from "../../components/Comman/Label";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";

import { apiPost, apiGet } from "../../utils/api_service";
import { getCategoryForOutlet } from "../../Slice/outletRateStructure.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { withRouter } from "../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import swal from "sweetalert";
import { titleColor } from "../../config/ColorObj";
class OutletRateStructureFormEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
      fieldData: {},
      nameErr: "",
      dynamicMasterData: {
        categoryForOutletDetail:
          this.props.categoryForOutletList?.categoryForOutlet,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.categoryForOutletList !== nextProps.categoryForOutletList) {
      if (
        this.props.categoryForOutletList?.categoryForOutlet !==
        nextProps.categoryForOutletList?.categoryForOutlet
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            categoryForOutletDetail:
              nextProps.categoryForOutletList?.categoryForOutlet,
          },
        });
      }
    }
  }

  async componentDidMount() {
    const {
      getProductByOutletId,
      getCategoryForOutlet,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getCategoryForOutlet().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      if (this.props.params.id) {
        showLoador({ loador: true });
        await getProductByOutletId({ outletId: this.props.params.id }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            } else {
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  onTextFieldDataChange = (event) => {
    const { fieldData } = this.state;
    this.setState({
      fieldData: {
        ...fieldData,
        name: event.target.value,
      },
    });
  };
  onSubmit = () => {
    const { setProductList, category } = this.props;
    const { fieldData } = this.state;
    if (fieldData["name"] && fieldData["name"] != "") {
      let productList = [];
      let error = false;
      const productRateConfigurationDetailsOutlets = category.product.map(
        (productListObj) => {
          let productObj = {
            product: {
              id: productListObj.productId,
            },
            marginPercentage: productListObj.marginPercentage,
          };
          if (
            productListObj.marginPercentage == 0 ||
            productListObj.marginPercentage == ""
          ) {
            error = true;
            productListObj = {
              ...productListObj,
              marginPercentageErr:
                productListObj.marginPercentage == "" ? 1 : 2,
            };
          } else {
            productListObj = {
              ...productListObj,
              marginPercentageErr: 0,
            };
          }
          if (productListObj.id && productListObj.id != 0) {
            productObj = {
              ...productObj,
              id: productListObj.id,
            };
          }

          productList.push(productListObj);
          return productObj;
        }
      );
      setProductList({ row: productList });
      const dataToSave = {
        id: fieldData.id,
        stockStakeHolderType: fieldData.stockStakeHolderType,
        category: fieldData.category,
        name: fieldData.name,
        rateType: fieldData.rateType,
        productRateConfigurationDetailsOutlets:
          productRateConfigurationDetailsOutlets,
      };
      if (!error) {
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            this.onSave(dataToSave);
          }
        });
      }
    } else {
      this.setState({
        nameErr: "*Name required",
      });
    }
  };
  onSave = (dataToSave) => {
    let productList = [];
    let error = false;
    const { showLoador, showNotification } = this.props;
    if (!error) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        apiPost({
          url: endpoint.outletRateStructure,
          postBody: dataToSave,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            showNotification({ msg: savemsg });

            this.props.navigate(-1);
          } else {
            showNotification({ msg: saveFailedMsg, severity: "error" });
          }
          return success;
        });
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    } else {
      setProductList({ row: productList });
    }
  };

  onSearch = (data) => {
    const { getProductByCategoryId, showLoador, showNotification } = this.props;
    this.setState({
      fieldData: data,
    });
    if (data.category && data.category.id) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        getProductByCategoryId({
          catId: data.category.id,
          marginPercentage: data.marginPercentage,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };
  getDataById = (id) => {
    const { showLoador } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      const response = apiGet({
        url: endpoint.outletRateStructure + "/" + id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          if (!data.error) {
            let rowData = data.data;

            this.setState({
              fieldData: rowData,
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Category",
                  value:
                    rowData.category !== null && rowData.category.name
                      ? rowData.category.name
                      : "-",
                },
              ],
            });
            return { data: rowData, success };
          }
          return { data: {}, success: false };
        }

        return { data: {}, success: false };
      });
      return response;
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const { DataToSet, fieldData, nameErr } = this.state;
    const { dynamicMasterData } = this.state;
    const { setProductList, category } = this.props;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            DataToSet={DataToSet}
            getByIdApi={true}
            callApi={true}
            getDataById={this.getDataById}
            dynamicMasterData={dynamicMasterData}
            showSaveBtn={false}
            showTitle={outletRateStructureJson.showTitle}
            screenTitle={outletRateStructureJson.screenTitle}
            fieldMeta={[]}
            showCancel={outletRateStructureJson.showCancel}
            apiBaseURL={outletRateStructureJson.apiBaseURL}
            showSaveNextBtn={outletRateStructureJson.showSaveNextBtn}
            saveBtnText={"Search"}
            onSave={this.onSearch}
            showSaveBtnMain={false}
          />
          <br />
          <Grid container spacing={1}>
            <Grid container xs={12} sm={7} md={7} lg={7} sx={{ marginLeft: 2 }}>
              <Grid item xs={12} sm={4} md={4}>
                <LabelCompo
                  className="text-black"
                  style={{
                    fontSize: 16,
                    color: titleColor,
                  }}
                  label={"Configuration Name :"}
                />
              </Grid>
              <Grid item xs={12} sm={8} md={8}>
                <TextFieldCompo
                  placeHolder={"Enter Name of Configuration"}
                  size="small"
                  color="primary"
                  name={"name"}
                  id={"name"}
                  value={fieldData["name"] ? fieldData["name"] : ""}
                  type={"text"}
                  onChange={this.onTextFieldDataChange}
                  fullWidth
                  errorText={nameErr}
                />
              </Grid>
            </Grid>
          </Grid>

          <MaterialUITableWithTextField
            tableHead={[
              {
                title: "Sr. No.",
                name: "index",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Product Name",
                name: "name",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "MRP",
                name: "mrp",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
              },
              {
                title: "Margin (%)",
                textField: true,
                name: "marginPercentage",
                textFieldError: "marginPercentageErr",
                error: "Margin (%)",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
                placeHolder: "Enter Remark",
              },
              {
                title: "Projected Rate",
                name: "rate",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
              },
            ]}
            data={category.product}
            setList={setProductList}
            isActionColActive={false}
          />
          <br />

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
            container
            justifyContent="right"
          >
            <ButtonCompo
              size="medium"
              type="submit"
              variant="contained"
              name="Submit"
              onClick={this.onSubmit}
            />
          </Grid>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  mrpConfigrationList: state.mrpConfigration,
  categoryForOutletList: state.outletRateStructure,
  category: state.category,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  setProductList,
  getCategoryForOutlet,
  getProductByCategoryId,
  getProductByOutletId,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OutletRateStructureFormEdit);
