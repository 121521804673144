import endpoint from "../../config/endpoints";

export const StakeHolderDocumentJson = {
  showSaveNextBtn: false,
  apiBaseURL: endpoint.room,
  formPath: "/mrp-configuration-form",
  screenTitle: "Stock Holder Document",
  showTitle: true,
  showCancel: false,
  showSaveBtn: false,
  fieldMeta: [],
  fieldMetaForSearch: [],
};
