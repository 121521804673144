import endpoint from "../../config/endpoints";

export const addRateConfigurationJson = {
    //showSaveNextBtn: true,
    apiBaseURL: endpoint.room,
    formPath: "/mrp-configuration-form",
    screenTitle: "Add Cofiguration By Item List",
    showTitle: true,
    showCancel:false,
    showSaveBtn:false,
    fieldMeta: [
    ]
};
