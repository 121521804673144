import InfoIcon from "@mui/icons-material/Info";
import {
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { compose } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import React, { Component } from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import MultipleSelect from "../../components/Comman/MultipleSelect";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import TimePickerCompo from "../../components/Comman/TimePicker";
import { withRouter } from "../../components/withRouter";
import {
  blackColor,
  labelColor,
  redColor,
  titleColor,
} from "../../config/ColorObj";
import { showLoador, showNotification } from "../Landing/Landing.slice";
class RequestBatchwiseGRNHeaderForm extends Component {
  constructor(props) {
    super(props);
  }

  onAutocompleteChange = (dataKey, isRootLevelKey) => (name, newValue) => {
    let value = "";
    if (isRootLevelKey) {
      value = newValue;
    } else {
      value = {
        id: newValue,
      };
    }
    if (newValue != null) {
      this.props.onDataChange(dataKey, value);
    }
  };

  onMultiSelectChange = (dataKey, masterName) => (value, name) => {
    const { dynamicMasterData } = this.props;

    let dataValue = typeof value === "string" ? value.split(",") : value;
    const filterData = dataValue.filter((rowData) => rowData == 0);
    if (filterData.length != 0) {
      dataValue = dynamicMasterData[masterName].map((rowData) => {
        return rowData.id;
      });
    }
    this.props.onDataChange(dataKey, dataValue);
  };

  onTextFieldDataChange = (dataKey) => (event) => {
    this.props.onDataChange(dataKey, event.target.value);
  };

  onDateChange = (dataKey) => (date) => {
    this.props.onDataChange(dataKey, date);
  };
  timeChangeHandler = (dataKey) => (time) => {
    this.props.onDataChange(dataKey, time);
  };
  onRadioChange = (dataKey) => (event) => {
    this.props.onDataChange(dataKey, event.target.value);
  };

  onCancel = () => {
    this.props.navigate(-1);
  };

  render() {
    const {
      showTitle = true,
      fieldMeta = [],
      dynamicMasterData = {},
      showAddButton = false,
      screenTitle = "",
      DataToSet = [],
      showBackToList = true,
      showTable = false,
      paddingTop = false,
      padding = false,
      formErrors,
      fieldData,
    } = this.props;

    return (
      <>
        <Paper
          sx={{
            p: padding ? 2 : 0,
            pt: paddingTop ? 2 : 0,
            borderRadius: 2,
          }}
          elevation={0}
        >
          {showTitle && (
            <LandingScreenHeader
              screenTitle={screenTitle}
              showTable={showTable}
              showBackButton={showBackToList}
              showAddButton={showAddButton}
              onCancel={this.onCancel}
            />
          )}
          {showTitle && <br />}

          {DataToSet.length !== 0 && (
            <>
              <Grid container sx={{ margin: 1 }} spacing={1}>
                {DataToSet.map((dropDownData) => (
                  <Grid
                    container
                    xs={dropDownData.xs}
                    sm={dropDownData.sm}
                    md={dropDownData.md}
                    lg={dropDownData.lg}
                  >
                    <Grid
                      item
                      xs={dropDownData.labelXS ? dropDownData.labelXS : 4}
                      sm={dropDownData.labelSM ? dropDownData.labelSM : 4}
                      md={dropDownData.labelMD ? dropDownData.labelMD : 4}
                      lg={dropDownData.labelLG ? dropDownData.labelLG : 4}
                    >
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: titleColor }}
                        label={dropDownData.label + " :"}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={dropDownData.valueXS ? dropDownData.valueXS : 8}
                      sm={dropDownData.valueSM ? dropDownData.valueSM : 8}
                      md={dropDownData.valueMD ? dropDownData.valueMD : 8}
                      lg={dropDownData.valueLG ? dropDownData.valueLG : 8}
                    >
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={dropDownData.value}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              {/* <br /> */}
            </>
          )}

          <Grid container spacing={1}>
            {fieldMeta.map((currentField) => (
              <Grid
                container
                xs={currentField.xs}
                sm={currentField.sm}
                md={currentField.md}
                sx={{
                  marginTop: currentField.marginTop
                    ? currentField.marginTop
                    : 1,
                }}
              >
                {currentField.toolTip && currentField.toolTip !== "" && (
                  <>
                    <Tooltip placement="top" title={currentField.toolTip}>
                      <InfoIcon color="disabled" />
                    </Tooltip>
                  </>
                )}
                <Grid item xs={12} sm={4} md={4}>
                  {currentField.controlType !== "hideDatepicker" &&
                    currentField.controlType !== "hideTextfield" &&
                    currentField.controlType !== "hideMultiSelect" &&
                    currentField.controlType !== "hideAutocomplete" && (
                      <>
                        &nbsp;&nbsp;
                        {currentField.isMandatory && (
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16, color: redColor }}
                            label={"*"}
                          />
                        )}
                        <LabelCompo
                          className="text-black"
                          style={{
                            fontSize: 16,
                            color: currentField.isMandatory
                              ? titleColor
                              : blackColor,
                          }}
                          label={currentField.label + " :"}
                        />
                      </>
                    )}
                  {(currentField.controlType === "hideMultiSelect" ||
                    currentField.controlType === "hideAutocomplete" ||
                    currentField.controlType === "hideDatepicker") &&
                    fieldData[currentField.tableDataKey] ===
                      currentField.open && (
                      <>
                        &nbsp;&nbsp;
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: redColor }}
                          label={"*"}
                        />
                        <LabelCompo
                          className="text-black"
                          style={{
                            fontSize: 16,
                            color: currentField.isMandatory
                              ? titleColor
                              : blackColor,
                          }}
                          label={currentField.label + " :"}
                        />
                      </>
                    )}
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  {currentField.controlType === "textfield" && (
                    <>
                      <TextFieldCompo
                        placeHolder={currentField.placeHolder}
                        size="small"
                        color="primary"
                        name={currentField.label}
                        id={currentField.label}
                        value={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : ""
                        }
                        disabled={
                          fieldData["id"] && currentField.disable
                            ? true
                            : currentField.disableTrue
                            ? true
                            : false
                        }
                        inputProps={{
                          maxLength:
                            currentField.maxLength && currentField.maxLength,
                          min: currentField.minNum && currentField.minNum,
                        }}
                        type={
                          currentField.inputType
                            ? currentField.inputType
                            : "text"
                        }
                        onChange={this.onTextFieldDataChange(
                          currentField.dataKey
                        )}
                        fullWidth
                        errorText={
                          formErrors[currentField.dataKey]?.isError
                            ? formErrors[currentField.dataKey].errorText
                            : ""
                        }
                      />
                      {currentField.note &&
                        currentField.space &&
                        currentField.note.split("@").map((note) => {
                          return (
                            <>
                              <LabelCompo
                                className="text-black"
                                style={{
                                  fontSize: 12,
                                  color: labelColor,
                                  marginLeft: 2,
                                }}
                                label={note}
                              />
                              {currentField.space === "br" ? (
                                <br></br>
                              ) : currentField.space === "&nbsp;" ? (
                                " "
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                    </>
                  )}
                  {currentField.controlType === "textarea" && (
                    <TextFieldCompo
                      placeHolder={currentField.placeHolder}
                      size="small"
                      color="primary"
                      name={currentField.label}
                      id={currentField.label}
                      value={
                        fieldData[currentField.dataKey]
                          ? fieldData[currentField.dataKey]
                          : ""
                      }
                      disabled={
                        fieldData["id"] && currentField.disable ? true : false
                      }
                      inputProps={{
                        maxLength:
                          currentField.maxLength && currentField.maxLength,
                      }}
                      type={
                        currentField.inputType ? currentField.inputType : "text"
                      }
                      multiline
                      onChange={this.onTextFieldDataChange(
                        currentField.dataKey
                      )}
                      fullWidth
                      errorText={
                        formErrors[currentField.dataKey]?.isError
                          ? formErrors[currentField.dataKey].errorText
                          : ""
                      }
                    />
                  )}
                  {currentField.controlType === "hideTextfield" &&
                    fieldData[currentField.tableDataKey] ===
                      currentField.open && (
                      <TextFieldCompo
                        placeHolder={currentField.placeHolder}
                        size="small"
                        color="primary"
                        name={currentField.label}
                        disabled={
                          fieldData["id"] && currentField.disable
                            ? true
                            : currentField.disableTrue
                            ? true
                            : false
                        }
                        id={currentField.label}
                        value={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : ""
                        }
                        inputProps={{
                          maxLength:
                            currentField.maxLength && currentField.maxLength,
                        }}
                        type={
                          currentField.inputType
                            ? currentField.inputType
                            : "text"
                        }
                        onChange={this.onTextFieldDataChange(
                          currentField.dataKey
                        )}
                        fullWidth
                        errorText={
                          formErrors[currentField.dataKey]?.isError
                            ? formErrors[currentField.dataKey].errorText
                            : ""
                        }
                      />
                    )}
                  {currentField.controlType === "datepicker" && (
                    <BasicDatePicker
                      color="primary"
                      name="dob"
                      size="small"
                      value={
                        fieldData[currentField.dataKey]
                          ? fieldData[currentField.dataKey]
                          : null
                      }
                      {...(currentField.isMAxDate && {
                        maxDate: dayjs(),
                      })}
                      {...(currentField.isMinDate && {
                        minDate: currentField.addOne
                          ? dayjs().add(1, "day")
                          : dayjs(),
                      })}
                      disabled={
                        fieldData["id"] && currentField.disable ? true : false
                      }
                      fullWidth
                      onChange={this.onDateChange(currentField.dataKey)}
                      isError={formErrors[currentField.dataKey]?.isError}
                      errorText={formErrors[currentField.dataKey]?.errorText}
                    />
                  )}
                  {currentField.controlType === "hideDatepicker" &&
                    fieldData[currentField.tableDataKey] ===
                      currentField.open && (
                      <BasicDatePicker
                        color="primary"
                        name="dob"
                        size="small"
                        value={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : null
                        }
                        {...(currentField.isMAxDate && {
                          maxDate: dayjs(),
                        })}
                        {...(currentField.isMinDate && {
                          minDate: dayjs(),
                        })}
                        disabled={
                          fieldData["id"] && currentField.disable ? true : false
                        }
                        fullWidth
                        onChange={this.onDateChange(currentField.dataKey)}
                        isError={formErrors[currentField.dataKey]?.isError}
                        errorText={formErrors[currentField.dataKey]?.errorText}
                      />
                    )}
                  {currentField.controlType === "hideAutocomplete" &&
                    fieldData[currentField.tableDataKey] ===
                      currentField.open && (
                      <AutoComplete
                        label={currentField.label}
                        placeHolder={currentField.placeHolder}
                        keyColName={"id"}
                        value={
                          currentField.isRootLevelKey &&
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : !currentField.isRootLevelKey &&
                              fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey].id
                            : ""
                        }
                        options={
                          dynamicMasterData[currentField["masterName"]]
                            ? dynamicMasterData[currentField["masterName"]]
                            : []
                        }
                        disabled={
                          fieldData["id"] && currentField.disable ? true : false
                        }
                        onChange={this.onAutocompleteChange(
                          currentField.dataKey,
                          currentField.isRootLevelKey
                        )}
                        isError={formErrors[currentField.dataKey]?.isError}
                        errorText={formErrors[currentField.dataKey]?.errorText}
                      />
                    )}
                  {currentField.controlType === "autocomplete" && (
                    <>
                      <AutoComplete
                        label={currentField.label}
                        placeHolder={currentField.placeHolder}
                        keyColName={"id"}
                        value={
                          currentField.isRootLevelKey &&
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : !currentField.isRootLevelKey &&
                              fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey].id
                            : ""
                        }
                        options={
                          dynamicMasterData[currentField["masterName"]]
                            ? dynamicMasterData[currentField["masterName"]]
                            : []
                        }
                        disabled={
                          fieldData["id"] && currentField.disable ? true : false
                        }
                        onChange={this.onAutocompleteChange(
                          currentField.dataKey,
                          currentField.isRootLevelKey
                        )}
                        isError={formErrors[currentField.dataKey]?.isError}
                        errorText={formErrors[currentField.dataKey]?.errorText}
                      />
                      {currentField.note &&
                        currentField.space &&
                        currentField.note.split("@").map((note) => {
                          return (
                            <>
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 12, color: labelColor }}
                                label={note}
                              />
                              {currentField.space === "br" ? (
                                <br></br>
                              ) : currentField.space === "&nbsp;" ? (
                                " "
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                    </>
                  )}
                  {currentField.controlType === "autocomplete/textfield" && (
                    <>
                      {fieldData["id"] ? (
                        <TextFieldCompo
                          placeHolder={currentField.placeHolder}
                          size="small"
                          color="primary"
                          name={currentField.label}
                          id={currentField.label}
                          value={
                            fieldData[currentField.dataKeyName] &&
                            fieldData[currentField.dataKeyName]
                              ? fieldData[currentField.dataKeyName]
                              : ""
                          }
                          disabled={true}
                          inputProps={{
                            maxLength:
                              currentField.maxLength && currentField.maxLength,
                            min: currentField.minNum && currentField.minNum,
                          }}
                          type={
                            currentField.inputType
                              ? currentField.inputType
                              : "text"
                          }
                          onChange={this.onTextFieldDataChange(
                            currentField.dataKey
                          )}
                          fullWidth
                          errorText={
                            formErrors[currentField.dataKey]?.isError
                              ? formErrors[currentField.dataKey].errorText
                              : ""
                          }
                        />
                      ) : (
                        <>
                          <AutoComplete
                            label={currentField.label}
                            placeHolder={currentField.placeHolder}
                            keyColName={"id"}
                            value={
                              currentField.isRootLevelKey &&
                              fieldData[currentField.dataKey]
                                ? fieldData[currentField.dataKey]
                                : !currentField.isRootLevelKey &&
                                  fieldData[currentField.dataKey]
                                ? fieldData[currentField.dataKey].id
                                : ""
                            }
                            options={
                              dynamicMasterData[currentField["masterName"]]
                                ? dynamicMasterData[currentField["masterName"]]
                                : []
                            }
                            disabled={
                              fieldData["id"] && currentField.disable
                                ? true
                                : false
                            }
                            onChange={this.onAutocompleteChange(
                              currentField.dataKey,
                              currentField.isRootLevelKey
                            )}
                            isError={formErrors[currentField.dataKey]?.isError}
                            errorText={
                              formErrors[currentField.dataKey]?.errorText
                            }
                          />
                          {currentField.note &&
                            currentField.space &&
                            currentField.note.split("@").map((note) => {
                              return (
                                <>
                                  <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 12, color: labelColor }}
                                    label={note}
                                  />
                                  {currentField.space === "br" ? (
                                    <br></br>
                                  ) : currentField.space === "&nbsp;" ? (
                                    " "
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })}
                        </>
                      )}
                    </>
                  )}
                  {currentField.controlType === "multiSelect" && (
                    <MultipleSelect
                      label={currentField.placeHolder}
                      er={formErrors[currentField.dataKey]?.errorText}
                      name1={currentField.dataKey}
                      value1={
                        fieldData[currentField.dataKey]
                          ? fieldData[currentField.dataKey]
                          : []
                      }
                      names={
                        dynamicMasterData[currentField["masterName"]] &&
                        dynamicMasterData[currentField["masterName"]].length !=
                          0
                          ? [
                              { id: 0, name: "All" },
                              ...dynamicMasterData[currentField["masterName"]],
                            ]
                          : []
                      }
                      onChange={this.onMultiSelectChange(
                        currentField.dataKey,
                        currentField["masterName"]
                      )}
                    />
                  )}
                  {currentField.controlType === "hideMultiSelect" &&
                    fieldData[currentField.tableDataKey] ===
                      currentField.open && (
                      <MultipleSelect
                        label={currentField.placeHolder}
                        placeHolder={currentField.placeHolder}
                        name1={currentField.dataKey}
                        value1={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : []
                        }
                        names={
                          dynamicMasterData[currentField["masterName"]]
                            ? dynamicMasterData[currentField["masterName"]]
                            : []
                        }
                        onChange={this.onMultiSelectChange(
                          currentField.dataKey
                        )}
                        er={formErrors[currentField.dataKey]?.errorText}
                      />
                    )}
                  {currentField.controlType === "timePicker" && (
                    <TimePickerCompo
                      value={
                        fieldData[currentField.dataKey]
                          ? fieldData[currentField.dataKey]
                          : ""
                      }
                      onChange={this.timeChangeHandler(currentField.dataKey)}
                    />
                  )}
                  {currentField.controlType === "radiogroup" && (
                    <>
                      <RadioGroup
                        row
                        aria-label={currentField.label}
                        name={currentField.label}
                        id={currentField.label}
                        disabled={
                          fieldData["id"] && currentField.disable ? true : false
                        }
                        value={
                          fieldData[currentField.dataKey]
                            ? fieldData[currentField.dataKey]
                            : ""
                        }
                        onChange={this.onRadioChange(currentField.dataKey)}
                        size="small"
                      >
                        {currentField.radioGroupItems.map((radioItem) => (
                          <FormControlLabel
                            disabled={
                              fieldData["id"] && currentField.disable
                                ? true
                                : false
                            }
                            value={radioItem.value}
                            control={<Radio />}
                            label={radioItem.label}
                          />
                        ))}
                      </RadioGroup>
                      {currentField.note &&
                        currentField.note.split("@").map((note) => {
                          return (
                            <>
                              <LabelCompo
                                className="text-black"
                                style={{ fontSize: 12, color: labelColor }}
                                label={note}
                              />
                              <br></br>
                            </>
                          );
                        })}
                    </>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = { showLoador, showNotification };
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RequestBatchwiseGRNHeaderForm);
