import React from "react";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../../components/dynamicscreens/DynamicFormWithoutSave";
import { Paper, Grid } from "@mui/material";
import MaterialUITableWithTextField from "../../../components/Comman/RT/MaterialUITableWithTextField";
import { ButtonCompo } from "../../../components/Comman/Button";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../../components/withRouter";
import endpoint from "../../../config/endpoints";
import swal from "sweetalert";

import { addRateConfigurationJson } from "../../../DynamicFormsJson/Transaction/addRateConfiguration";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import {
  getProductRateConfigurationByProductId,
  getRateConfigurationList,
} from "../../../Slice/productRateConfiguration.slice";
import {
  serverMsg,
  savemsg,
  saveFailedMsg,
  noInternetMsg,
  saveWarningMsg,
} from "../../../config/messageconstant";
import { apiGet, apiPost } from "../../../utils/api_service";

class AddRateConfigurationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      DataToSet: [],
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.productRateList !== nextProps.productRateList) {
      if (
        this.props.productRateList?.productRate !==
        nextProps.productRateList?.productRate
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            productRateDetail: nextProps.productRateList?.productRate,
          },
        });
      }
    }
  }
  async componentDidMount() {
    const {
      getProductRateConfigurationByProductId,
      getRateConfigurationList,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      await this.getByProductId();
      showLoador({ loador: true });
      await getRateConfigurationList({ row: [] });

      showLoador({ loador: true });
      await getProductRateConfigurationByProductId({
        productId: this.props.params.id,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getByProductId = async () => {
    const { showLoador, showNotification } = this.props;
    if (this.props.params.id) {
      if (navigator.onLine) {
        const { showLoador } = this.props;
        showLoador({ loador: true });
        await apiGet({
          url: endpoint.product + "/" + this.props.params.id,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            if (!data.error) {
              this.setState({
                DataToSet: [
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    label: "Product Name",
                    value:
                      data.data.name !== null && data.data.shortName !== null
                        ? data.data.name + " ( " + data.data.shortName + " ) "
                        : data.data.name !== null
                          ? data.data.name
                          : data.data.shortName !== null
                            ? data.data.shortName
                            : "",
                  },
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    label: "Product Weight",
                    value: data.data.weight !== null ? data.data.weight : "",
                  },
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    label: "Product Shelf Life",
                    value:
                      data.data.selfLife !== null ? data.data.selfLife : "",
                  },
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    label: "Stock Holder UOM",
                    value:
                      data.data.stakeHolderUom !== null &&
                        data.data.stakeHolderUom.name !== null
                        ? data.data.stakeHolderUom.name
                        : "",
                  },
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    label: "Outlet UOM",
                    value:
                      data.data.outletUom !== null &&
                        data.data.outletUom.name !== null
                        ? data.data.outletUom.name
                        : "",
                  },
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    label: "MRP",
                    value: data.data.mrp !== null ? data.data.mrp : "",
                  },
                ],
              });
            }
          } else {
            showNotification({ msg: serverMsg, severity: "error" });
          }

          return success;
        });
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };

  rowDelete = (rowData) => {
    const { tableData } = this.state;
    const { showNotification } = this.props;
    if (tableData.length > 1) {
      swal({
        title: "Are you sure?",
        text: deleteConfirmMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.onDelete(rowData);
        }
      });
    } else {
      showNotification({ msg: "Having atleast one mrp", severity: "error" });
    }
  };
  onDelete = (rowData) => {
    const { tableData } = this.state;
    const latestTableData = tableData.filter(
      (row) => row.index != rowData.index
    );
    this.setState({
      tableData: latestTableData.map((row, index) => {
        return { ...row, index: index + 1 };
      }),
    });
  };

  onSubmit = () => {
    const { productRateList } = this.props;
    let filterMrpConfigList = [];
    productRateList.productRate.map((productListObject) => {
      if (productListObject.marginPercentage !== 0) {
        const productListObjectToSave = {
          productRateConfigurationId: productListObject.id,
          product: {
            id: this.props.params.id,
          },
          marginPercentage: productListObject.marginPercentage,
        };
        filterMrpConfigList.push(productListObjectToSave);
      }
    });

    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onSave(filterMrpConfigList);
      }
    });
  };
  onSave = (filterMrpConfigList) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.productRateConfigurationDetails,
        postBody: filterMrpConfigList,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg });
          window.location.replace("/product");
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const { DataToSet } = this.state;
    const { getRateConfigurationList, productRateList } = this.props;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            showSaveBtnMain={false}
            formPath={addRateConfigurationJson.formPath}
            DataToSet={DataToSet}
            showTitle={addRateConfigurationJson.showTitle}
            screenTitle={addRateConfigurationJson.screenTitle}
            fieldMeta={addRateConfigurationJson.fieldMeta}
            apiBaseURL={addRateConfigurationJson.apiBaseURL}
            isActionColActive={false}
          />

          <MaterialUITableWithTextField
            tableHead={[
              {
                title: "Sr. No.",
                name: "index",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Configuration Name",
                name: "name",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "Stackholder Type",
                name: "stockStakeHolderTypeName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "Category",
                name: "categoryName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },

              {
                title: "Margin Percentage",
                name: "marginPercentage",
                textFieldError: "changedValueErr",
                textField: true,
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
                placeHolder: "Enter Margin Percentage",
              },
            ]}
            data={productRateList?.productRate}
            setList={getRateConfigurationList}
            isActionColActive={false}
          />
          {productRateList?.productRate.length != 0 && (
            <>
              <br />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Submit"
                  onClick={this.onSubmit}
                />
              </Grid>
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  productRateList: state.productRateConfiguration,
});
const mapDispatchToProps = {
  getProductRateConfigurationByProductId,
  getRateConfigurationList,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AddRateConfigurationForm);
