import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import RTTable from "../../../components/Comman/RT/RTTable";
import DynamicFormWithoutSave from "../../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../../components/withRouter";
import { noInternetMsg, serverMsg } from "../../../config/messageconstant";
import { offerJson } from "../../../DynamicFormsJson/MastersJSON/offer";
import { getOfferHeaderById } from "../../../Slice/offer.slice";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { getActiveProduct } from "../../../Slice/product.slice";
class OfferView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
      offerDetails: [],
      productDetails: [],
      ProductIds: [],
      levelWiseType: "",
      headerData: {},
    };
    this.columns = [
      {
        title: "Sr.No.",
        name: "index",
        align: "center",
      },
      {
        title: "Product Name",
        name: "productName",
      },
      {
        title: "Quantity",
        name: "qty",
        align: "right",
      },
      {
        title: "Percentage (%)",
        name: "percentage",
        align: "right",
      },
    ];
    this.columnsForLevelWise = [
      {
        title: "Sr.No.",
        name: "index",
        align: "center",
      },
      {
        title: "Product Name",
        name: "name",
      },
      {
        title: "Code",
        name: "code",
        align: "center",
      },
      {
        title: "Category",
        name: "categoryName",
        align: "left",
      },
    ]
  }
  async componentDidMount() {
    const { getOfferHeaderById, getActiveProduct, productList, showLoador, showNotification } = this.props;
    const { ProductIds } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getActiveProduct().then(({ response, success }) => {
        console
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } {
          if (this.props.params.id) {
            showLoador({ loador: true });
            getOfferHeaderById({
              offerHeaderId: this.props.params.id,
            }).then(({ data, success }) => {
              console.log(data);
              showLoador({ loador: false });
              if (!success) {
              } else {
                this.setState({
                  headerData: data,
                  DataToSet: [
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      label: "Name",
                      value: data.name !== null ? data.name : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      label: "Description",
                      value:
                        data.discription !== null ? data.discription : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      label: "From Date",
                      value: data.fromDate !== null ? data.fromDate : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      label: "To Date",
                      value: data.toDate !== null ? data.toDate : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      label: "Type",
                      value: data.type !== null ? data.type : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      label: "From Date (Range)",
                      value:
                        data.fromRangeDate !== null
                          ? data.fromRangeDate
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      label: "To Date (Range)",
                      value:
                        data.toRangeDate !== null ? data.toRangeDate : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      label: "Offer For",
                      value: data.offerFor !== null ? data.offerFor : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      label: "Stock Holder Type",
                      value:
                        data.stockStakeHolderTypeName === null
                          ? "-"
                          : data.stockStakeHolderTypeName,
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      label: "Level",
                      value: data.levelName !== null ? data.levelName : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      label: "Level-Wise Type",
                      value:
                        data.levelWiseTypeName !== null
                          ? data.levelWiseTypeName
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      label: "Applicable Amount",
                      value:
                        data.applicableAmount !== null
                          ? data.applicableAmount
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      label: "Discount Amount",
                      value:
                        data.discountAmount !== null
                          ? data.discountAmount
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      label: "Discount Percentage",
                      value:
                        data.discountPercentage !== null
                          ? data.discountPercentage + " %"
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      label: "Product",
                      value:
                        data.productName !== null ? data.productName : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      label: "Quantity",
                      value: data.qty !== null ? data.qty : "",
                    },
                  ],
                  offerDetails:
                    data.offerDetails.length !== 0 ? data.offerDetails : [],

                  ProductIds: data.productIds.split(","),

                  levelWiseType: data.levelWiseType,
                });
                this.getProductDetails(response, data.productIds.split(","));
              }
            });


          }
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }




  }

  getProductDetails = (productList, row) => {
    const { productDetails } = this.state;

    this.setState({
      productDetails: row.map((productId) =>
        productList.filter((row) => productId === row.id)
      ).flat()
    })

  }

  render() {
    const { dynamicMasterData, offerDetails, ProductIds, levelWiseType, DataToSet, productDetails, headerData } = this.state;
    const { productList } = this.props;
    console.log(productDetails);
    return (
      <>
        <Paper
          sx={{
            p: 1,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            DataToSet={DataToSet}
            dynamicMasterData={dynamicMasterData}
            showSaveBtn={offerJson.showSaveBtn}
            showTitle={true}
            screenTitle={"Offer View"}
            showCancel={offerJson.showCancel}
            apiBaseURL={offerJson.apiBaseURL}
            showSaveNextBtn={offerJson.showSaveNextBtn}
            saveBtnText={"Search"}
            showSaveBtnMain={false}
            padding={true}
            paddingTop={true}
          />

          {Object.keys(productDetails).length !== 0 && (levelWiseType == "5" || levelWiseType == "7") && (
            <RTTable
              columns={this.columnsForLevelWise}
              tableData={productDetails}
              isActionColActive={false}
              showPegination={false}
            />
          )}
          <br />
          {Object.keys(offerDetails).length !== 0 && (
            <RTTable
              columns={this.columns}
              tableData={offerDetails}
              isActionColActive={false}
              showPegination={false}
            />
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  productList: state.product,

});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getOfferHeaderById,
  getActiveProduct,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OfferView);
