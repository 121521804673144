import endpoint from "../../config/endpoints";

export const userAccessJson = {
  showSaveNextBtn: true,
  formPath: "/user-access-form",
  formPathView: "/user-access-view",
  openFormPath: "/user-access-assignation-for-stockholder",
  searchButtonGrid: 1,
  apiBaseURL: endpoint.userAccess,
  screenTitle: "User Access",
  showAddButton: true,
  showPdfDownload: false,
  pdfFileName: "User Access",
  showExcelDownload: false,
  excelFileName: "User Access",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Type",
      columnKeyName: "type",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "regionDetail",
      dataKey: "region",
      getListId: "region",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "State",
      controlType: "autocomplete",
      placeHolder: "Select State",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stateDetail",
      dataKey: "state",
      getListFrom: "region",
      getListId: "state",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "autocomplete",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "zoneDetail",
      dataKey: "zone",
      getListFrom: "state",
      getListId: "zone",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "District",
      controlType: "autocomplete",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "districtDetail",
      dataKey: "district",
      getListFrom: "zone",
      getListId: "district",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Taluka",
      controlType: "autocomplete",
      placeHolder: "Select Taluka",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "talukaDetail",
      dataKey: "taluka",
      getListFrom: "district",
      getListId: "taluka",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "area",
      getListFrom: "taluka",
      getListId: "area",
      isRootLevelKey: true,
      isMandatory: true,
    },
    // {
    //   label: "Stock Holder Type",
    //   controlType: "autocomplete",
    //   placeHolder: "Select Stock Holder",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   masterName: "stakeHolderTypeDetail",
    //   dataKey: "stockStakeHolderType",
    //   isRootLevelKey: false,
    //   isMandatory: true,
    // },
  ],
};
