import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Box from '@mui/material/Box';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { compose } from "@reduxjs/toolkit";
import * as React from "react";
import { connect } from "react-redux";
import { saveReport } from '../../Slice/matrixReport.slice';
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import * as msgConstant from "../../config/messageconstant";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import AddTableColoumnsPopUp from "./AddTableColoumnsPopUp";

import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";

class UserAccessForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      stackHolderType: "",
      stackHolderTypeName: "",
      accessRoleName: "",
      shortName: "",
      formError: {},
      error: false,
      openDialog: false,
      rowData: {},
      rowInfo: {}
    };
  }



  checkValidation = () => {
    const { accessRoleName, stackHolderType, shortName } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (accessRoleName === "" || accessRoleName === null) {
      formIsValid = false;
      formErrors["nameError"] = myConstClass.accessRoleMsg;
    }

    if (shortName === "" || shortName === null) {
      formIsValid = false;
      formErrors["shortNameError"] = myConstClass.shortNameMsg;
    }
    if (stackHolderType === "" || stackHolderType === null) {
      formIsValid = false;
      formErrors["stackHolderTypeError"] = myConstClass.stackHolderTypeMsg;
    }
    this.setState({
      formError: formErrors,
    });
    return formIsValid;
  };
  submitHandler = () => {
    const { accessRole, showLoador, showNotification } = this.props;
    const { accessRoleName, stackHolderType, shortName, formError, id, error } =
      this.state;

    let moduleListToSave = [];
    accessRole.moduleList.map((module) => {
      let submoduleList = [];
      if (module.checked == 1) {
        module.moduleSubs.map((submodule) => {
          submoduleList.push(submodule);
        });
      } else {
        module.moduleSubs.map((submodule) => {
          if (
            submodule.addApproveConfig == 1 ||
            submodule.editReject == 1 ||
            submodule.deleteRejectApprove == 1 ||
            submodule.view == 1
          ) {
            submoduleList.push(submodule);
          }
        });
      }
      if (submoduleList.length !== 0) {
        module = {
          ...module,
          moduleSubs: submoduleList,
        };
        delete module.checked;
        moduleListToSave.push(module);
      }
    });
    let accessRoleToSave = {
      stockStakeHolderType: { id: stackHolderType },
      roleName: accessRoleName,
      roleShortName: shortName,
      modules: moduleListToSave,
    };
    if (id !== 0) {
      accessRoleToSave = {
        ...accessRoleToSave,
        id: id,
      };
    }
    if (this.checkValidation() && accessRoleToSave.modules.length !== 0) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        apiPost({
          url: endpoint.userAccess,
          postBody: accessRoleToSave,
        }).then(({ data, success }) => {
          showLoador({ loador: true });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.props.navigate(-1);
          }
        });
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    } else if (accessRole.moduleList.length === 0) {
      this.setState({
        error: true,
      });
    }
  };
  goBack = () => {
    this.props.navigate(-1);
  };
  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };
  onClickAdd = (e, row, rowInfo) => {
    e.stopPropagation();
    this.setState({
      openDialog: true,
      rowData: row,
      rowInfo: rowInfo
    });
  };
  onClickDelete = (e, row, subIndex) => {
    const { rowList } = this.props;
    console.log(subIndex)
    e.stopPropagation();
    let rowListAfterDelete = []
    let filterListIndex = 0
    if (filterListIndex == subIndex) {
      rowListAfterDelete = this.props.rowList.filter((rowInfo) => rowInfo.fieldName != row.fieldName)
    } else {
      this.props.rowList.map((rowData) => {
        if (rowData.isList && rowData.isList == 1) {
          filterListIndex = filterListIndex + 1
          let subList = rowData.subList ? rowData.subList : []
          let subListData = this.filterRowData(subList, row, filterListIndex, subIndex)
          rowListAfterDelete.push({ ...rowData, subList: subListData })

        }
        else {
          rowListAfterDelete.push(rowData)
        }
      })
    }
    console.log(rowListAfterDelete)
    this.rowDelete(rowListAfterDelete)
  };
  filterRowData = (subList, row, filterListIndex, subIndex) => {
    let rowListAfterDelete = []
    if (filterListIndex == subIndex) {
      rowListAfterDelete = subList.filter((rowInfo) => rowInfo.fieldName != row.fieldName)
    } else {
      subList.map((rowData) => {
        if (rowData.isList && rowData.isList == 1) {
          filterListIndex = filterListIndex + 1
          let subList = rowData.subList ? rowData.subList : []
          let subListData = this.filterRowData(subList, row, filterListIndex, subIndex)

          rowListAfterDelete.push({ ...rowData, subList: subListData })
        }
        else {
          rowListAfterDelete.push(rowData)
        }
      })
    }
    return rowListAfterDelete
  }
  rowDelete = (rowListAfterDelete) => {
    const { showNotification, showLoador, value } = this.props;
    swal({
      title: "Are you sure?",
      text: msgConstant.deleteWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        if (navigator.onLine) {
          let tableColumns = value == 3 ? "tableColumns1" : value == 5 ? "tableColumns2" : value == 7 ? 'tableColumns3' : ""
          this.onSave({
            ...this.props.report.reportData,
            [tableColumns]: JSON.stringify(rowListAfterDelete)
          }
          )
        } else {
          showNotification({ msg: msgConstant.noInternetMsg, severity: "error" });
        }
      }
    });
  };
  onSave = (dataToSave) => {

    if (navigator.onLine) {
      const {
        saveReport,
        showLoador,
        showNotification,
      } = this.props;
      showLoador({ loador: true });
      saveReport({ dataToSave }).then(({ success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({
            msg: dataToSave.id ? msgConstant.deletemsg : msgConstant.savemsg,
          });

        } else {
          showNotification({
            msg: msgConstant.saveFailedMsg,
            severity: "error",
          });
        }
      });
    } else {
      showNotification({
        msg: msgConstant.noInternetMsg,
        severity: "error",
      });
    }
  };
  onClickEdit = (e, rowData, index) => {
    e.stopPropagation();
    this.props.rowEdit({ ...rowData, index });
  };
  render() {
    const { rowList } = this.props;
    const {
      openDialog,
      rowData,
      rowInfo
    } = this.state;
    return (
      <>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width="10%">Sr. No.</TableCell>
                <TableCell>Column Name</TableCell>
                <TableCell align="center" width="10%">
                  Pojo Field Name
                </TableCell>
                <TableCell align="center" width="10%">
                  Field Name
                </TableCell>
                <TableCell align="center" width="10%">
                  Width
                </TableCell>
                <TableCell align="center" width="10%">
                  Is Show
                </TableCell>
                <TableCell align="center" width="10%">
                  allignment
                </TableCell>
                <TableCell align="center" width="10%">
                  Sort Number
                </TableCell>
                <TableCell align="center" width="10%">
                  Is Total
                </TableCell>
                <TableCell align="center" width="10%">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowList.map((row, index) => {
                let subIndex = 0
                return (<>
                  <TableRow key={row.id}>
                    <TableCell align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell scope="row" >
                      {row.columnName}
                    </TableCell>
                    <TableCell scope="row" >
                      {row.pojoFiledName}
                    </TableCell>
                    <TableCell scope="row" >
                      {row.fieldName}
                    </TableCell>
                    <TableCell scope="row" >
                      {row.isList == 1 ? "" : row.width}
                    </TableCell>

                    <TableCell scope="row" >
                      {row.isList == 1 ? "" : row.isShow == 1 ? "Yes" : "No"}
                    </TableCell>
                    <TableCell scope="row" >
                      {row.isList == 1 ? "" : row.allignment}
                    </TableCell>
                    <TableCell scope="row" >
                      {row.isList == 1 ? "" : row.sortNo}
                    </TableCell>
                    <TableCell scope="row" >
                      {row.isList == 1 ? "" : row.isTotal == 1 ? "Yes" : "No"}
                    </TableCell>
                    <TableCell scope="row" >
                      {
                        row.isList == 1 ?
                          <>
                            <MyComponentWithIconProps
                              statusImage={AddCircleIcon}
                              color="primary"
                              fontSize="medium"
                              title="Add Column"
                              onClick={(e) => this.onClickAdd(e, row, row)}
                            />
                            &nbsp;&nbsp;
                          </>
                          : ""
                      }
                      <MyComponentWithIconProps
                        statusImage={EditIcon}
                        color="primary"
                        fontSize="medium"
                        title="edit"
                        onClick={(e) => this.onClickEdit(e, row, index)}
                      />
                      &nbsp;&nbsp;
                      <MyComponentWithIconProps
                        statusImage={DeleteIcon}
                        color="error"
                        fontSize="medium"
                        title="Delete"
                        onClick={(e) => this.onClickDelete(e, row, subIndex)}
                      />
                    </TableCell>
                  </TableRow>
                  {row.isList == 1 && row.subList &&
                    <TableCell style={{ paddingBottom: 5, paddingTop: 0 }} colSpan={10}>
                      <Box sx={{ marginLeft: 2, marginRight: 2 }}>

                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <CreateTable rowSubList={row.subList} rowInfo={row} subIndex={subIndex} onClickAdd={this.onClickAdd} onClickDelete={this.onClickDelete} />
                          </Table>
                        </TableContainer>
                      </Box>
                    </TableCell>
                  }

                </>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {
          openDialog && <AddTableColoumnsPopUp openDialog={openDialog} setOpenDialouge={() => {
            this.setState({
              openDialog: false
            })
          }}
            rowData={rowData}
            rowInfo={rowInfo}
          />
        }

      </>
    );
  }
}
const CreateTable = ({ rowInfo, subIndex, rowSubList, onClickDelete, onClickAdd }) => {
  subIndex = subIndex + 1
  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell width="10%">Sr. No.</TableCell>
          <TableCell>Column Name</TableCell>
          <TableCell align="center" width="10%">
            Pojo Field Name
          </TableCell>
          <TableCell align="center" width="10%">
            Field Name
          </TableCell>
          <TableCell align="center" width="10%">
            Width
          </TableCell>
          <TableCell align="center" width="10%">
            Is Show
          </TableCell>
          <TableCell align="center" width="10%">
            allignment
          </TableCell>
          <TableCell align="center" width="10%">
            Sort Number
          </TableCell>
          <TableCell align="center" width="10%">
            Is Total
          </TableCell>
          <TableCell align="center" width="10%">
            Action
          </TableCell>
        </TableRow>
      </TableHead>
      {rowSubList.map((row, index) => (
        <>
          <TableRow key={row.id}>
            <TableCell align="center">
              {index + 1}

            </TableCell>
            <TableCell scope="row" >
              {row.columnName}
            </TableCell>
            <TableCell scope="row" >
              {row.pojoFiledName}
            </TableCell>
            <TableCell scope="row" >
              {row.fieldName}
            </TableCell>
            <TableCell scope="row" >
              {row.isList == 1 ? "" : row.width}
            </TableCell>

            <TableCell scope="row" >
              {row.isList == 1 ? "" : row.isShow == 1 ? "Yes" : "No"}

            </TableCell>
            <TableCell scope="row" >
              {row.isList == 1 ? "" : row.allignment}

            </TableCell>
            <TableCell scope="row" >
              {row.isList == 1 ? "" : row.sortNo}

            </TableCell>
            <TableCell scope="row" >
              {row.isList == 1 ? "" : row.isTotal == 1 ? "Yes" : "No"}

            </TableCell>
            <TableCell scope="row" >
              {
                row.isList == 1 ?
                  <>
                    <MyComponentWithIconProps
                      statusImage={AddCircleIcon}
                      color="primary"
                      fontSize="medium"
                      title="Add Column"
                      onClick={(e) => onClickAdd(e, row, rowInfo)}
                    />
                    &nbsp;&nbsp;
                  </> : ""
              }
              <MyComponentWithIconProps
                statusImage={DeleteIcon}
                color="error"
                fontSize="medium"
                title="Delete"
                onClick={(e) => onClickDelete(e, row, subIndex)}
              />
            </TableCell>
          </TableRow>
          {row.isList == 1 && row.subList &&
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
              <Box sx={{ marginLeft: 2, marginRight: 2 }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <CreateTable subIndex={subIndex} onClickDelete={onClickDelete} rowInfo={rowInfo} rowSubList={row.subList} onClickAdd={onClickAdd} />
                  </Table>
                </TableContainer>
              </Box>
            </TableCell>
          }
        </>
      ))}
    </>
  )
}
const mapStateToProps = (state) => ({ report: state.matrixReport });
const mapDispatchToProps = { showLoador, showNotification, saveReport };
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(UserAccessForm);

