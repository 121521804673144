import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { documentJson } from "../../../DynamicFormsJson/MastersJSON/document";
class DocumentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        type: [
          {
            id: "3",
            name: "All",
          },
          {
            id: "0",
            name: "Stock Holder",
          },
          {
            id: "1",
            name: "Sales Manager",
          },
          {
            id: "2",
            name: "Sales Executive",
          },
        ],
      },
    };
  }
  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicForm
          rowList={dynamicMasterData}
          dynamicMasterData={dynamicMasterData}
          formPath={documentJson.formPath}
          screenTitle={documentJson.screenTitle}
          fieldMeta={documentJson.fieldMeta}
          apiBaseURL={documentJson.apiBaseURL}
          showSaveNextBtn={documentJson.showSaveNextBtn}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(DocumentForm);
