import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { WorkingTypejson } from "../../../DynamicFormsJson/MastersJSON/workingTypeJson";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { getOtherTask } from "../../../Slice/otherTask.slice";
import { nameMsg, noInternetMsg, otherTaskMsg, saveFailedMsg, saveWarningMsg, savemsg, serverMsg, updateData } from "../../../config/messageconstant";
import { withRouter } from "../../../components/withRouter";
import { Grid, Paper } from "@mui/material";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import { TextFieldCompo } from "../../../components/Comman/TextFieldCompo";
import { compose } from "recompose";
import { redColor, titleColor } from "../../../config/ColorObj";
import { apiGet, apiPost } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
class WorkingTypeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            otherTask: "",
            formErrors: {},
            dynamicMasterData: {
                otherTaskDetail: this.props.otherTaskList?.otherTask,
            },
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.otherTaskList !== nextProps.otherTaskList) {
            if (
                this.props.otherTaskList?.otherTask !==
                nextProps.otherTaskList?.otherTask
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        otherTaskDetail: nextProps.otherTaskList?.otherTask,
                    },
                });
            }
        }
    }

    async componentDidMount() {
        const { getOtherTask, showLoador, showNotification } =
            this.props;
        if (navigator.onLine) {

            if (this.props.params.id) {
                this.getDataById(this.props.params.id)
            }

            showLoador({ loador: true });
            await getOtherTask().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });

        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }


    getDataById = (id) => {
        const { showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            const response = apiGet({
                url: WorkingTypejson.apiBaseURL + "/" + id,
            }).then(({ data, success }) => {
                console.log(data.data);
                showLoador({ loador: false });
                if (success) {
                    if (!data.error) {
                        if (data && data.data) {
                            this.setState({
                                name: data.data.name ? data.data.name : "",
                                otherTask: data.data.otherTask.id ? data.data.otherTask.id : "",

                            });
                        }
                    }
                } else {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
            return response;
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };


    goBack = () => {
        window.location.replace("/working-type");
    };

    changeHandler = (e) => {
        const { name, value } = e.target;
        if (name === "name") {
            this.setState({
                name: value,
            })
        }
    }

    onAutocompleteChange = (name, newValue) => {
        if (newValue != null) {
            this.setState({
                [name]: newValue,
            });
        } else {
            this.setState({
                [name]: "",
            });
        }
    };

    checkValidation = () => {
        const { name, otherTask } = this.state;
        let formErrors = {};
        let formIsValid = true;

        if (name === "") {
            formIsValid = false;
            formErrors["nameError"] = nameMsg;
        }
        if (otherTask === "") {
            formIsValid = false;
            formErrors["otherTaskError"] = otherTaskMsg;
        }
        this.setState({
            formErrors: formErrors,
        })
        return formIsValid;
    };


    submitHandler = () => {
        const { showLoador, showNotification } = this.props;
        const { name, otherTask } = this.state;

        if (navigator.onLine) {
            if (this.checkValidation()) {
                if (this.props.params.id) {
                    let workingTypeObject = {
                        id: this.props.params.id,
                        name: name,
                        otherTask: { id: otherTask },
                        isActive: 1,
                    };
                    swal({
                        title: "Are you sure?",
                        text: updateData,
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    }).then((willDelete) => {
                        if (willDelete) {
                            this.onSave(workingTypeObject);
                        }
                    });
                } else {
                    let workingTypeObject = {
                        name: name,
                        otherTask: { id: otherTask },
                        isActive: 1,
                    };
                    swal({
                        title: "Are you sure?",
                        text: saveWarningMsg,
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    }).then((willDelete) => {
                        if (willDelete) {
                            this.onSave(workingTypeObject);
                        }
                    });
                }

            }
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    onSave = (workingTypeObject) => {
        const { showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            apiPost({
                url: endpoint.workingType,
                postBody: workingTypeObject,
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (success) {
                    showNotification({ msg: savemsg });
                    window.location.replace("/working-type");
                } else {
                    showNotification({ msg: saveFailedMsg, severity: "error" });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };




    render() {
        const { otherTaskList } = this.props;
        const { formErrors, name, otherTask } = this.state;
        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 2,
                    }}
                    elevation={0}
                >
                    <LandingScreenHeader
                        screenTitle={"Working Type"}
                        showTable={false}
                        showBackButton={false}
                        showAddButton={false}
                    />
                    <Grid container justifyContent="space-between" direction="row">
                        <Grid
                            item
                            xs={12}
                            md={12}
                            size="small"
                            container
                            justifyContent="flex-end"
                        >

                            <ButtonCompo
                                size="medium"
                                type="button"
                                variant="outlined"
                                name=" Back to List"
                                onClick={this.goBack}
                            />
                        </Grid>
                    </Grid>


                    <br></br>
                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="space-between"
                    >
                        <br></br>
                        <br /> <br />
                        <Grid
                            item
                            sm={12}
                            xs={12}
                            md={12}
                            lg={12}
                            container
                            justifyContent="space-between"
                        >
                            <Grid
                                item
                                sm={6}
                                xs={7}
                                md={8}
                                lg={6}
                                container
                                justifyContent="space-evenly"
                            >
                                <Grid item xs={12} md={3} sm={3}>
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16, color: redColor }}
                                        label="* "
                                    />
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16, color: titleColor }}
                                        label="Name :"
                                    />
                                </Grid>
                                <Grid item xs={12} md={7} sm={7}>
                                    <TextFieldCompo
                                        placeHolder={"Working Type Name"}
                                        color="primary"
                                        name="name"
                                        size="small"
                                        value={name}
                                        fullWidth
                                        onChange={this.changeHandler}
                                        errorText={
                                            formErrors["nameError"] ? formErrors["nameError"] : ""
                                        }
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                sm={6}
                                xs={7}
                                md={8}
                                lg={6}
                                container
                                justifyContent="space-evenly"
                            >
                                <Grid item xs={12} md={3} sm={3}>
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16, color: redColor }}
                                        label="* "
                                    />
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16, color: titleColor }}
                                        label="Other Task:"
                                    />
                                </Grid>
                                <Grid item xs={12} md={7} sm={7}>
                                    <AutoComplete
                                        keyColName={"id"}
                                        name={"otherTask"}
                                        value={otherTask}
                                        options={otherTaskList.otherTask}
                                        placeHolder="Other Task"
                                        onChange={this.onAutocompleteChange}
                                        isError={formErrors.otherTaskError ? true : false}
                                        errorText={
                                            formErrors.otherTaskError
                                                ? formErrors.otherTaskError
                                                : " "
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    &nbsp;&nbsp; &nbsp;&nbsp;
                    <div align="right">
                        <ButtonCompo
                            size="button"
                            type="button"
                            variant="contained"
                            name="SAVE"
                            onClick={this.submitHandler}
                        />
                        &nbsp;&nbsp;
                        <ButtonCompo
                            size="button"
                            type="button"
                            variant="outlined"
                            name="CANCEL"
                            onClick={this.goBack}
                        />
                        &nbsp;&nbsp;

                    </div>
                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    otherTaskList: state.otherTask,
});
const mapDispatchToProps = {
    getOtherTask,
    showLoador,
    showNotification,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(WorkingTypeForm);

