

import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { imageUrl } from '../../config/Url';
import endpoint from '../../config/endpoints';
import { langugae } from "../../config/languageJSON";
import { noInternetMsg, serverMsg } from '../../config/messageconstant';
import { apiGet, apiPost } from '../../utils/api_service';
import '../Billing History/billPDFCss.css';
import { showLoador, showNotification } from "../Landing/Landing.slice";


const MyComponent = ({ showLoador, showNotification }) => {
    const params = useParams();
    const [billList, setBill] = useState([]);
    let lang = localStorage.getItem("lang") && localStorage.getItem("lang") != null ? localStorage.getItem("lang") : 2
    let myBillPdfId = localStorage.getItem("myBillPdfID");

    useEffect(() => {
        // window.print();
        if (navigator.onLine) {
            lang = localStorage.getItem("lang");
            myBillPdfId = localStorage.getItem("myBillPdfID");

            let myBillPdfData = {
                stockStakeHolderBillHeaderIds: myBillPdfId.split(","),
                languageId: lang
            }

            showLoador({ loador: true });
            apiPost({
                url:
                    endpoint.stockStakeholderBillHeader +
                    "/report-by-ids",
                postBody: myBillPdfData,
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({
                        msg: serverMsg,
                        severity: "error",
                    });
                } else {
                    console.log(data.data);

                    setBill(data.data)
                    window.print();
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }, []);

    return (

        <>

            {/* <footer className='header'>
                <p>Tax Invoice</p>

            </footer>

            <header className='header2'>
                <p>Author: Hege Refsnes</p>
            </header> */}
            {
                billList.map((dataToSet, index) => {
                    let toBill = dataToSet.toBill ? dataToSet.toBill : {};

                    let addr = toBill.address
                        ? toBill.address
                        : "";
                    let address = langugae[2].address
                        ? langugae[2].address + " : "
                        : ""
                    var DAddrText = address + addr;
                    let insertDateTime = dataToSet.insertDateTime
                        ? dataToSet.insertDateTime.split(" ")
                        : [];
                    let insertDateTimeData =
                        insertDateTime.length == 2 ? insertDateTime[1] : "";
                    let toBillState = toBill.state != null ? toBill.state : {};
                    let indexInfo = 0;
                    let totalAmt = 0;
                    let qtyTot = 0;
                    let dataToSetState = dataToSet.state != null ? dataToSet.state : {};
                    let stockStakeHolderBillDetails =
                        dataToSet.stockStakeHolderBillDetails != null ? dataToSet.stockStakeHolderBillDetails : [];
                    return (
                        <>
                            <div>
                                <table style={{ width: '100%' }}>
                                    <tr>
                                        <table style={{ width: '100%' }}>
                                            <tr>
                                                <td align={"center"} style={{ paddingBottom: "4px" }} colSpan={2}>
                                                    <div style={{ fontWeight: 600, fontSize: "20px" }}>
                                                        {dataToSet.fromBillGstNo === toBill.gstNo ? "Stock Transfer" : langugae[2].taxInvoice
                                                            ? langugae[2].taxInvoice
                                                            : ""}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>


                                                <td
                                                    style={{ textAlign: "left", width: '70%', borderLeft: '1px solid black', borderTop: '1px solid black', borderBottom: '1px solid black', borderRight: '1px solid black', borderCollapse: 'collapse', padding: '8px' }}>
                                                    <div style={{ fontWeight: 600, }}>
                                                        {dataToSet.fromBillFirmName
                                                            ? dataToSet.fromBillFirmName
                                                            : "-"}
                                                    </div>
                                                    {langugae[2].ownerName
                                                        ? langugae[2].ownerName + " : "
                                                        : ""} {dataToSet.fromBillOwnerName
                                                            ? dataToSet.fromBillOwnerName
                                                            : "-"} <br />
                                                    {langugae[2].address
                                                        ? langugae[2].address + " : "
                                                        : ""} {dataToSet.fromBillAddress
                                                            ? dataToSet.fromBillAddress
                                                            : "-"}<br />
                                                    {langugae[2].mobileNo
                                                        ? langugae[2].mobileNo + " : "
                                                        : ""} {dataToSet.fromBillContactPersonNo
                                                            ? dataToSet.fromBillContactPersonNo
                                                            : "-"} ,  State : {dataToSetState.name
                                                                ? " " + dataToSetState.name
                                                                : "-"}<br />
                                                    {langugae[2].gSTNo ? langugae[2].gSTNo + " : " : ""} {dataToSet.fromBillGstNo
                                                        ? dataToSet.fromBillGstNo
                                                        : "-"} , {langugae[2].FSSAINo
                                                            ? langugae[2].FSSAINo + " : "
                                                            : ""} {dataToSet.fromBillFssiFdiNo
                                                                ? dataToSet.fromBillFssiFdiNo
                                                                : "-"}<br />
                                                    Mode of Transport : By Road , Vehicle No.: <br />
                                                    E-Invoice ACK No:   <br />
                                                </td>

                                                <td
                                                    align={"center"}
                                                    style={{ padding: '6px', borderTop: '1px solid black', borderBottom: '1px solid black', borderRight: '1px solid black', borderCollapse: 'collapse' }}>
                                                    <img style={{
                                                        width: '95%', height: '175px',

                                                        // objectPosition: '15% 100%'
                                                    }} src={imageUrl + localStorage.getItem("complogo")} alt="Logo" />
                                                </td>
                                            </tr>

                                        </table>

                                    </tr >

                                    <tr>
                                        <table style={{ width: '100%' }}>

                                            <table style={{ width: '100%' }}>
                                                <tr>
                                                    <td
                                                        style={{ borderLeft: '1px solid black', textAlign: "left", width: '50%', borderBottom: '1px solid black', borderRight: '1px solid black', borderCollapse: 'collapse', padding: '8px' }}>
                                                        {langugae[2].billTo ? langugae[2].billTo + " : " : ""}
                                                        {toBill.firmName ? toBill.firmName : ""} <br />
                                                        {langugae[2].ownerName
                                                            ? langugae[2].ownerName + " : "
                                                            : ""} {toBill.ownerName ? toBill.ownerName : "-"} <br />
                                                        {DAddrText}<br />
                                                        {langugae[2].phoneNo
                                                            ? langugae[2].phoneNo + " : "
                                                            : ""} {toBill.mobileNo ? toBill.mobileNo : ""} , {langugae[2].gSTNo ? langugae[2].gSTNo + " : " : ""} {toBill.gstNo != null ? toBill.gstNo : "-"} , <br />
                                                        Place Of Supply: {toBillState.name ? " " + toBillState.name : ""} ,  {langugae[2].FSSAINo
                                                            ? langugae[2].FSSAINo + " : "
                                                            : ""} {toBill.fssiFdiNo != null && toBill.fssiFdiNo != ""
                                                                ? toBill.fssiFdiNo
                                                                : "-"}
                                                    </td>
                                                    <td
                                                        style={{ borderLeft: '1px solid black', textAlign: "left", width: '50%', borderBottom: '1px solid black', borderRight: '1px solid black', borderCollapse: 'collapse', padding: '8px' }}>
                                                        Ship To :
                                                        {toBill.firmName ? " " + toBill.firmName : ""} <br />

                                                        {DAddrText}<br />
                                                        {langugae[2].phoneNo
                                                            ? langugae[2].phoneNo + " : "
                                                            : ""} {toBill.mobileNo ? toBill.mobileNo : ""} , {langugae[2].gSTNo ? langugae[2].gSTNo + " : " : ""} {toBill.gstNo != null ? toBill.gstNo : "-"} , <br />

                                                        Place Of Supply: {toBillState.name ? " " + toBillState.name : ""} <br />
                                                        Reverse tax applicable ? ( Yes / No ) : No
                                                    </td>
                                                </tr>

                                            </table>

                                        </table>
                                    </tr>



                                </table >

                                <div style={{ borderCollapse: 'collapse', marginTop: '8px' }}>
                                    <table style={{ borderCollapse: 'collapse', width: '100%' }} >
                                        <thead>

                                            <tr >
                                                <td colSpan={11} >



                                                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                                        <tr>
                                                            <td >
                                                                <div style={{ fontSize: "18px" }} >
                                                                    <div style={{ display: "inline", fontWeight: 600 }}>
                                                                        {langugae[2].billNO
                                                                            ? langugae[2].billNO + " : "
                                                                            : ""}
                                                                    </div>   {dataToSet.billNo ? dataToSet.billNo : "-"} </div>
                                                            </td>

                                                            <td align={"right"} >
                                                                <div style={{ fontSize: "18px" }} >
                                                                    <div style={{ display: "inline", fontWeight: 600 }}>
                                                                        {langugae[2].date ? langugae[2].date + " : " : ""}
                                                                    </div>

                                                                    {dataToSet.billDate ? dataToSet.billDate : "-"}</div>
                                                                <div style={{ fontSize: "14px" }} >
                                                                    <div style={{ display: "inline" }}> {langugae[2].time ? langugae[2].time + " : " : ""}{insertDateTimeData}</div> </div>


                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th className="thsmallcell">#</th>
                                                <th className="thmedcell">{langugae[2].HSN ? langugae[2].HSN : ""}</th>
                                                <th className="thbigcell">{langugae[2].itemName ? langugae[2].itemName : ""}</th>
                                                <th className="thbigcell">{langugae[2].uom ? langugae[2].uom : ""}</th>
                                                <th className="thbigcell">{langugae[2].qty ? langugae[2].qty : ""}</th>
                                                <th className="thmedcell">{langugae[2].rate ? langugae[2].rate : ""}</th>
                                                {
                                                    toBillState.id == dataToSetState.id ? <>
                                                        <th colSpan={2} className="thmedcell">{langugae[2].CGST ?
                                                            langugae[2].CGST : ""}</th>
                                                        <th colSpan={2} className="thmedcell">{langugae[2].SGST ? langugae[2].SGST : ""}</th>
                                                    </>
                                                        :
                                                        <th colSpan={2} className="thmedcell">{langugae[2].IGST ? langugae[2].IGST : ""}</th>
                                                }

                                                <th className="thmedcell">{langugae[2].amount ? langugae[2].amount : ""}</th>
                                            </tr>
                                            <tr>
                                                <th className="thsmallcell"></th>
                                                <th className="thmedcell"></th>
                                                <th className="thbigcell"></th>
                                                <th className="thbigcell"></th>
                                                <th className="thbigcell"></th>
                                                <th className="thmedcell"></th>
                                                {
                                                    toBillState.id == dataToSetState.id ? <>
                                                        <th className="thmedcell">{"%"}</th>
                                                        <th className="thmedcell">{langugae[2].amount ? langugae[2].amount : ""}</th>
                                                        <th className="thmedcell">{"%"}</th>
                                                        <th className="thmedcell">{langugae[2].amount ? langugae[2].amount : ""}</th>
                                                    </>
                                                        :
                                                        <>
                                                            <th className="thmedcell">{"%"}</th>

                                                            <th className="thmedcell">{langugae[2].amount ? langugae[2].amount : ""}</th></>

                                                }

                                                <th className="thmedcell"></th>
                                            </tr>
                                        </thead>


                                        <tbody style={{ width: '100%' }}>
                                            {
                                                dataToSet.packagingTypes.map((packagingType) => {

                                                    return (<>
                                                        <tr>
                                                            <td align={"left"} colSpan={11} style={{ fontWeight: 600 }} className="tdsmallcell">{packagingType.name}</td>
                                                        </tr>

                                                        {

                                                            stockStakeHolderBillDetails.map((stockDetails, indexData) => {
                                                                let packagingTypeDetails =
                                                                    stockDetails.product != null &&
                                                                        stockDetails.product.packagingType != null
                                                                        ? stockDetails.product.packagingType
                                                                        : {};

                                                                if (packagingType.id == packagingTypeDetails.id) {
                                                                    let productData = [];
                                                                    let product =
                                                                        stockDetails.product != null ? stockDetails.product : {};
                                                                    qtyTot = qtyTot + stockDetails.qty;
                                                                    indexInfo = indexInfo + 1;
                                                                    totalAmt = totalAmt + stockDetails.totalAmt;
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td className="tdsmallcell">{indexInfo}</td>
                                                                                <td className="tdsmallcell">{stockDetails.hsnCode}</td>
                                                                                <td align={"left"} className="tdsmallcell">{product.name}</td>
                                                                                <td align={"left"} className="tdsmallcell">{stockDetails.uom != null ? stockDetails.uom.name : "-"}</td>
                                                                                <td align={"right"} className="tdsmallcell">{stockDetails.qty}</td>
                                                                                <td align={"right"} className="tdsmallcell">{stockDetails.rate.toFixed(2)}</td>
                                                                                {
                                                                                    toBillState.id == dataToSetState.id ?
                                                                                        <>
                                                                                            <td align={"right"} className="tdsmallcell">{stockDetails.cgstPer.toFixed(2)}</td>
                                                                                            <td align={"right"} className="tdsmallcell">{stockDetails.cgstAmt.toFixed(2)}</td>
                                                                                            <td align={"right"} className="tdsmallcell">{stockDetails.sgstPer.toFixed(2)}</td>
                                                                                            <td align={"right"} className="tdsmallcell">{stockDetails.sgstAmt.toFixed(2)}</td>
                                                                                        </> : <>
                                                                                            <td align={"right"} className="tdsmallcell">{stockDetails.igstPer.toFixed(2)}</td>
                                                                                            <td align={"right"} className="tdsmallcell">{stockDetails.igstAmt.toFixed(2)}</td>
                                                                                        </>
                                                                                }
                                                                                <td align={"right"} className="tdsmallcell">{stockDetails.totalAmt.toFixed(2)}</td>
                                                                            </tr>

                                                                        </>
                                                                    )
                                                                }
                                                            })
                                                        }

                                                    </>)
                                                })
                                            }

                                            <tr>
                                                <td align={"left"} className="tdsmallcell" style={{ fontWeight: 600 }}>{langugae[2].total ? langugae[2].total : ""}</td>
                                                <td className="tdsmallcell"></td>
                                                <td className="tdsmallcell"></td>
                                                <td className="tdsmallcell"></td>

                                                <td align={"right"} style={{ fontWeight: 600 }} className="tdsmallcell">{qtyTot}</td>
                                                <td className="tdsmallcell"></td>
                                                {
                                                    toBillState.id == dataToSetState.id ? <>
                                                        <td className="tdsmallcell"></td>
                                                        <td className="tdsmallcell"></td>
                                                        <td className="tdsmallcell"></td>
                                                        <td className="tdsmallcell"></td>
                                                    </>
                                                        :
                                                        <>

                                                            <td className="tdsmallcell"></td>

                                                            <td className="tdsmallcell"></td></>

                                                }
                                                <td align={"right"} style={{ fontWeight: 600 }} className="tdsmallcell">{totalAmt.toFixed(2)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <br />
                                    <table style={{ borderCollapse: 'collapse', width: '100%' }} >
                                        <tr>
                                            <th className="thsmallcell">{langugae[2].fillingType
                                                ? langugae[2].fillingType
                                                : ""}</th>
                                            {
                                                dataToSet.categories.map((categoryData, index) => {
                                                    return (
                                                        <th className="thsmallcell">{categoryData.name}</th>
                                                    )
                                                })
                                            }
                                        </tr>
                                        {
                                            dataToSet.packagingTypes.map((packagingTypeData) => {
                                                return (
                                                    <tr>
                                                        <td align={"left"} className="tdsmallcell">{packagingTypeData.name}</td>

                                                        {dataToSet.categories.map((categoryData) => {
                                                            let flag = 0;
                                                            return (
                                                                <>
                                                                    {

                                                                        dataToSet.dtoCategoryWithPackagingTypes.map((rowData) => {

                                                                            if (
                                                                                categoryData.id == rowData.category.id &&
                                                                                rowData.packagingType.id == packagingTypeData.id
                                                                            ) {
                                                                                flag = 1;
                                                                                return (
                                                                                    <td align={"right"} className="tdsmallcell">{rowData.totalQty}</td>
                                                                                )
                                                                            }
                                                                        })

                                                                    }
                                                                    {flag == 0 &&
                                                                        <td align={"left"} className="tdsmallcell"></td>}

                                                                </>
                                                            )

                                                        })}
                                                    </tr>
                                                )

                                            })
                                        }

                                    </table>

                                    <br />
                                    <table style={{ borderCollapse: 'collapse', width: '100%' }} >
                                        <tr>
                                            <td align={"left"} colSpan={3} className="tdsmallcell">{langugae[2].note ? langugae[2].note + " : " : ""}
                                                {dataToSet.orderRemarks != null ? dataToSet.orderRemarks : ""}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align={"left"} className="tdsmallcell">1. {langugae[2].orderBy
                                                ? langugae[2].orderBy + " : "
                                                : ""}</td>

                                            <td align={"left"} width={"25%"} rowSpan={3} className="tdsmallcell"></td>
                                            <td align={"left"} className="tdsmallcell" style={{ borderBottom: "none" }} >
                                                {dataToSet.fromBillFirmName
                                                    ? dataToSet.fromBillFirmName
                                                    : "-"}</td>
                                        </tr>
                                        <tr>
                                            <td align={"left"} className="tdsmallcell">2. {langugae[2].goodsCheckBeforeReceived
                                                ? langugae[2].goodsCheckBeforeReceived + " : "
                                                : ""}</td>
                                            <td align={"left"} style={{ borderRight: '1px solid rgb(172, 172, 172)' }}></td>

                                        </tr>
                                        <tr>
                                            <td align={"left"} className="tdsmallcell" >3. {langugae[2].subjectToJurisdiction
                                                ? langugae[2].subjectToJurisdiction + " : "
                                                : ""}{localStorage.getItem("districtName")}</td>
                                            <td align={"left"} className="tdsmallcell" style={{ borderTop: "none" }}>

                                                {langugae[2].aurthorisedSign
                                                    ? langugae[2].aurthorisedSign
                                                    : ""}</td>

                                        </tr>
                                    </table>
                                </div>
                            </div >
                            {
                                index + 1 != billList.length && <div className="pagebreak"> </div>
                            }

                        </>
                    )
                })
            }




        </>
    )
};
const mapStateToProps = (state) => ({
});
const mapDispatchToProps = {
    showNotification,
    showLoador
};
export default connect(mapStateToProps, mapDispatchToProps)(MyComponent);
