import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { LabelCompo } from "../../components/Comman/Label";
import { ButtonCompo } from "../../components/Comman/Button";
import TimePickerCompo from "../../components/Comman/TimePicker";
import moment from "moment";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { classes, children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const UpdateTimePopUp = ({
    handleCloses,
    open,
    onSave,
    editData,
    onChangeAll,
    data,
}) => {
    const [time, setTime] = React.useState({});
    React.useEffect(() => {
        console.log(data);
        const timeDate = moment(editData["fromTime"], ["h:mm A"]).format("HH:mm");
        const timeArr = timeDate?.split(":", -1);
        const timeDate1 = moment(editData["toTime"], ["h:mm A"]).format("HH:mm");
        const timeArr1 = timeDate1?.split(":", -1);
        setTime({
            ...editData,
            fromTime: new Date(null, null, null, timeArr[0], timeArr[1]),
            toTime: new Date(null, null, null, timeArr1[0], timeArr1[1]),
        });
    }, []);
    const handleClose = () => {
        handleCloses();
    };

    const onSaveEdit = () => {
        onSave(time);
    };

    const onChangeAllTime = (e, name) => {
        onChangeAll(e, name);
    }


    console.log(time);
    return (
        <>
            <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    fullWidth
                    maxWidth="md"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleClose}
                    >
                        Stock Holder Ordering Configuration
                    </BootstrapDialogTitle>

                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        sx={{ p: 2 }}
                        justifyContent="space-evenly"
                    >

                        <DialogContent>
                            <Grid
                                item
                                sm={12}
                                xs={12}
                                md={12}
                                lg={12}
                                container
                                sx={{ p: 2 }}
                                justifyContent="space-evenly"
                            >
                                <div>
                                    From Time :
                                    <br />
                                    {data.length !== 0 && (

                                        <Grid item xs={12} sm={10} md={6}>
                                            <TimePickerCompo
                                                placeHolder={"From Time"}
                                                color="primary"
                                                name="fromTime"
                                                size="small"
                                                value={"08:20:12"}
                                                fullWidth
                                                onChange={(e) => onChangeAllTime(e, "fromTime")}
                                            />
                                        </Grid>

                                    )}
                                </div>
                                <div>

                                    To Time :
                                    <br />
                                    {data.length !== 0 && (

                                        <Grid item xs={12} sm={10} md={6}>
                                            <TimePickerCompo
                                                placeHolder={"To Time"}
                                                color="primary"
                                                name="toTime"
                                                size="small"
                                                value={"08:20:12"}
                                                fullWidth
                                                onChange={(e) => onChangeAllTime(e, "toTime")}
                                            />
                                        </Grid>
                                    )}

                                </div>
                            </Grid>
                            <br />
                            <Grid
                                item
                                sm={12}
                                xs={12}
                                md={12}
                                lg={12}
                                rowSpacing={2}
                                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                                container
                                justifyContent="right"
                            >
                                <ButtonCompo
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    name="Submit"
                                    onClick={onSaveEdit}
                                />
                            </Grid>
                        </DialogContent>
                    </Grid>
                </BootstrapDialog >
            </Paper >
        </>
    );
};
const mapStateToProps = (state) => ({
    stockHolderNameList: state.stockHolderOrderConfiguration,

});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateTimePopUp);
