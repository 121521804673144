import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import {
  leaveTypeColumns,

} from "../../../tableColumns/table-columns";
import { leaveTypeJson } from "../../../DynamicFormsJson/MastersJSON/leaveTypeJson";
import { getLeaveType } from "../../../Slice/LeaveType.slice";
import swal from "sweetalert";
import { apiDelete } from "../../../utils/api_service";
import {

  deletemsg,
  deleteFailedMsg,

  deleteConfirmMsg,

  noInternetMsg,

} from "../../../config/messageconstant";
class leaveTypeTable extends React.Component {
  constructor(props) {
    super(props);
  }
  rowEdit = (rowData) => {
    window.location.replace(leaveTypeJson.formPath +"/" +rowData.id);
  };
  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: deleteConfirmMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData);
      }
    });
  };
  onDelete = (row) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiDelete({
        url: endpoint.leaveType + "/" + row.id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {

          showNotification({ msg: deletemsg });
        } else {
          showNotification({ msg: deleteFailedMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }; 
  render() {
    const { leaveTypeList, getLeaveType } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={leaveTypeJson.formPath}
          screenTitle={leaveTypeJson.screenTitle}
          fieldMeta={leaveTypeJson.fieldMeta}
          showPdfDownload={false}
          showExcelDownload={false}
          changeExcelURL={leaveTypeJson.excelUrl}
          showExtension={false}
          pdfFileName={leaveTypeJson.pdfFileName}
          excelFileName={leaveTypeJson.excelFileName}
          showAddButton={leaveTypeJson.showAddButton}
          tableColumnsToFilter={leaveTypeJson.tableColumnsToFilter}
          tableColumns={leaveTypeColumns}
          tableData={leaveTypeList?.leaveType}
          getTableData={getLeaveType}
          apiBaseURL={leaveTypeJson.apiBaseURL}
          baseIdColumn={leaveTypeJson.baseIdColumn}
          isNote={true}
          isNoteValue={"Add Leave Type"}
          showDeleteIcon={true}
          rowDelete={this.rowDelete}
          rowEdit={this.rowEdit}
          showPopUpDataList={[


          ]}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  leaveTypeList: state.leaveType,
});
const mapDispatchToProps = {
  getLeaveType,
};
export default connect(mapStateToProps, mapDispatchToProps)(leaveTypeTable);
