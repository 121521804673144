import endpoint from "../../config/endpoints";

export const rangeJson = {
  showSaveNextBtn: true,
  formPath: "/range-form",
  apiBaseURL: endpoint.range,
  changeExcel: endpoint.exportData,
  screenTitle: "Range",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Range",
  showExcelDownload: true,
  excelFileName: "Range",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Maximum Range",
      columnKeyName: "maxRange",
      isChecked: true,
    },
    {
      columnDisplayName: "Minimum Range",
      columnKeyName: "minRange",
      isChecked: true,
    },
    {
      columnDisplayName: "Type",
      columnKeyName: "type",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "Enter Range Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "name",
      isMandatory: true,
    },
    {
      label: "Maximum Range",
      controlType: "textfield",
      placeHolder: "Enter Max Range",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "maxRange",
      isMandatory: true,
    },
    {
      label: "Minimum Range",
      controlType: "textfield",
      placeHolder: "Enter Minimum Range",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "minRange",
      isMandatory: true,
    },
    {
      label: "Type",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "type",
      tableDataKey: "type",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Outlet",
          value: "0",
        },
        {
          label: "Stock Holder",
          value: "1",
        },
      ],
      isMandatory: true,
    },
  ],
};
