export const paymentFormStaticJson = {
  showSaveNextBtn: false,
  screenTitle: "Outlet Payment ",
  showAddButton: false,
  showPdfDownload: false,
  showExcelDownload: false,

  fieldMeta: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "area",
      getListId: "area",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Beat",
      controlType: "autocomplete",
      placeHolder: "Select Beat",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "beatDetail",
      dataKey: "beat",
      getListId: "beat",
      getListFrom: "area",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Outlet",
      controlType: "multiSelect",
      placeHolder: "Select Outlet",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "outletDetail",
      dataKey: "outlet",
      getListFrom: "beat",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
  fieldMetaByVehicle: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      isMandatory: true,
    },
    {
      label: "Vehicle Number",
      controlType: "autocomplete",
      placeHolder: "Select Vehicle Number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "vehicleDetail",
      dataKey: "vehicleNo",
      isRootLevelKey: false,
      isMandatory: true,
    },
  ],
};
