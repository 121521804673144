import React from "react";
import { connect } from "react-redux";
import { DistrictJson } from "../../../DynamicFormsJson/MastersJSON/district";
import { getDistrictUpdated } from "../../../Slice/district.slice";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import {
  AreaColumnsForPopup,
  DistrictColumns,
  TalukaColumnsForPopup
} from "../../../tableColumns/table-columns";

class DistrictTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { districtList, getDistrictUpdated } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={DistrictJson.formPath}
          screenTitle={DistrictJson.screenTitle}
          fieldMeta={DistrictJson.fieldMeta}
          showPdfDownload={DistrictJson.showPdfDownload}
          showExcelDownload={true}
          changeExcelURL={"district-data" + '?sort={"sortOrder": "ASC"}'}
          showExtension={false}
          pdfFileName={DistrictJson.pdfFileName}
          excelFileName={DistrictJson.excelFileName}
          showAddButton={DistrictJson.showAddButton}
          tableColumnsToFilter={DistrictJson.tableColumnsToFilter}
          tableColumns={DistrictColumns}
          tableData={districtList?.district}
          getTableData={getDistrictUpdated}
          apiBaseURL={DistrictJson.apiBaseURL}
          baseIdColumn={DistrictJson.baseIdColumn}
          isNote={true}
          showDeleteIcon={false}
          isNoteValue={"Add District From Region"}
          showPopUpDataList={[
            {
              dataKey: "talukaCount",
              columns: TalukaColumnsForPopup,
              title: "Taluka List",
              header: {
                dataKey: "districtName",
                label: "District",
              },
            },
            {
              dataKey: "areaCount",
              columns: AreaColumnsForPopup,
              title: "Area List",
              header: {
                dataKey: "districtName",
                label: "District",
              },
            },
          ]}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  districtList: state.district,
});
const mapDispatchToProps = {
  getDistrictUpdated,
};
export default connect(mapStateToProps, mapDispatchToProps)(DistrictTable);
