import React, { Component } from "react";
import { connect } from "react-redux";
import { onToggle } from "../../CommonActions/common.slice";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import endpoints from "../../config/endpoints";
import dayjs from "dayjs";
import AutoComplete from "../../components/Comman/AutoComplete";
import * as myConstClass from "../../config/messageconstant";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import "../../CSS/app.min.css";
import "../../CSS/bootstrap.min.css";
import "../../CSS/custom.min.css";
import "../../CSS/mermaid.min.css";
import "../../CSS/slickDashboard.css";
import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  getCurrentYearTargetForSales,
  getOutletListFromYearAndMonth,
  getSalesListFromYearAndMonth,
  getProductListFromYearAndMonth,
  getCount,
} from "../../Slice/salesDashboard.slice";
import {
  getMonthYearTargetForSales,
  getSalesTeamInfo,
} from "../../Slice/adminDashboard.slice";
import LineChart from "../../components/Line Chart";
import moment from "moment";
import { getStockStakeHolderTypeForList } from "../../Slice/stockStakeHolderType.slice";
import MonthCalender from "../../components/MonthCalender";
import { cyanBlue, yellowishBrownColor } from "../../config/ColorObj";
import MainCard from "../DashbourdChanges/MainCard";
import NoDataFound from "../DashbourdChanges/NoDataFound";
import OrdersTable from "../DashbourdChanges/OrdersTable";
import SearchBar from "../../components/Search";

class SalesDashboard extends Component {
  toggleDrawer = () => {
    this.props.onToggle();
  };
  state = {
    showFeedback: false,
    showPreview: false,
    date1: new Date(),
    newDate: "",
    currentDate: new Date(),
    curDate: new Date(),
    dob: "",
    dobDay: "",
    dobMonth: "",
    currenDate: "",
    currentMonth: "",
    timeTableList: [],
    subject: "",
    batch: "",
    yearDetail: "",
    semester: "",
    division: "",
    pieChartData: [],
    SalesLineChart: {},
    selfSalesLineChart: [],
    isLoading: false,
    showLoader: false,
    profile: "",
    nameFirstLetter: "",
    showZone: false,
    showArea: false,
    date: "",
    type: "",
    saleDate: "",
    outletDate: "",
    saleMonth: dayjs(),
    saleYear: "",
    formErrors: {},
    searchValueForOutlet: "",
    topPerformingOutletData: [],
    searchValueForProduct: "",
    topPerformingProductData: [],
    searchValueForSale: "",
    salesData: [],

    monthList: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  };

  componentDidMount() {
    const {
      getCurrentYearTargetForSales,
      showLoador,
      showNotification,
      getStockStakeHolderTypeForList,
      getCount,
      getSalesListFromYearAndMonth,
      getOutletListFromYearAndMonth,
      getProductListFromYearAndMonth,
      getMonthYearTargetForSales,
      getSalesTeamInfo
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getCurrentYearTargetForSales().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      getStockStakeHolderTypeForList().then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          if (response.length !== 0) {
            this.setState({
              type: response[0].id,
            });
            showLoador({ loador: true });
            getSalesListFromYearAndMonth({
              month: dayjs().format("MM"),
              year: dayjs().format("YYYY"),
              stockStakeHolderTypeId: response[0].id,
            }).then(({ response, success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              } else {
                this.setState({
                  salesData: response,
                });
              }
            });
          }
        }
      });
      showLoador({ loador: true });
      getCount().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      getOutletListFromYearAndMonth({
        month: dayjs().format("MM"),
        year: dayjs().format("YYYY"),
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            topPerformingOutletData: response,
          });
        }
      });
      showLoador({ loador: true });
      getProductListFromYearAndMonth({
        month: dayjs().format("MM"),
        year: dayjs().format("YYYY"),
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            topPerformingProductData: response,
          });
        }
      });

      showLoador({ loador: true });
      getMonthYearTargetForSales({ salesTeamId: "" }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            selfSalesLineChart: response
          })
        }
      });
      showLoador({ loador: true });
      getSalesTeamInfo().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  viewProfile = () => {
    window.location.replace("/empPreview");
  };
  getExtraData = (dtoSalesTeamInformations) => {
    let totalOutletCount = 0,
      zero = 0,
      unvisited = 0,
      totalAmount = 0;
    dtoSalesTeamInformations.map((salesTeam) => {
      totalOutletCount = totalOutletCount + +salesTeam.totalOutletCount;
      zero = zero + +salesTeam.zeroOrderCount;
      unvisited = unvisited + +salesTeam.unvisitedOutletCount;
      totalAmount = totalAmount + +salesTeam.totalAmount;
    });
    return [totalOutletCount, unvisited, zero, totalAmount];
  };
  GoBack = () => {
    this.setState({
      showPreview: false,
    });
  };
  openFeedBack = (e) => {
    e.preventDefault();
    this.setState({
      showFeedback: true,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  regionHandler = (e, row) => {
    this.setState({
      showZone: true,
    });
  };
  salesHandler = (e, id, name) => {
    const { getMonthYearTargetForSales, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getMonthYearTargetForSales({ salesTeamId: id }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          console.log(response)
          this.setState({
            SalesLineChart: { name: name, saleList: response }
          })
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  zoneHandler = (e, row) => {
    this.setState({
      showArea: true,
    });
  };

  areaHandler = (e, row) => { };

  onDateChangeSale = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }
    var dateFormat = moment(new Date(date)).format("DD-MM-YYYY");
    let splitDate = dateFormat.split("-");
    this.setState({
      saleDate: date,
      saleMonth: splitDate[1],
      saleYear: splitDate[2],
    });
  };

  onDateChangeOutlet = async (date, name) => {
    this.setState({
      [name]: date,
    });
    const {
      getOutletListFromYearAndMonth,
      getProductListFromYearAndMonth,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getOutletListFromYearAndMonth({
        month: date.format("MM"),
        year: date.format("YYYY"),
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getProductListFromYearAndMonth({
        month: date.format("MM"),
        year: date.format("YYYY"),
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onChangeMonthOrYear = (data, name) => {
    this.setState({
      [name]: data,
    });
  };
  handleFormValidation() {
    const { type, saleMonth } = this.state;
    let formErrors = {};
    let formIsValid = true;
    if (type === "" || type === null) {
      formIsValid = false;
      formErrors["typeError"] = myConstClass.stackHolderTypeMsg;
    }
    if (saleMonth === "" || saleMonth === null) {
      formIsValid = false;
      formErrors["dateError"] = myConstClass.dateMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  searchSalesList = () => {
    const { getSalesListFromYearAndMonth, showLoador } = this.props;
    const { saleMonth, saleYear, type } = this.state;
    if (this.handleFormValidation()) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        getSalesListFromYearAndMonth({
          month: saleMonth.format("MM"),
          year: saleMonth.format("YYYY"),
          stockStakeHolderTypeId: type,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };


  onSearchForTopPerformingOutlet = (searchData) => {
    const { dashboardData } = this.props;
    const { topPerformingOutletData } = this.state;
    console.log(searchData);
    if (searchData != "" || searchData != undefined) {
      this.setState({
        searchValueForOutlet: searchData,
      });
      const list = dashboardData.outletListFromYearAndMonth.filter(
        (currentRow) => {
          let isValid = false;
          if (
            currentRow["firmName"] &&
            currentRow["firmName"]
              .toString()
              .toLowerCase()
              .includes(searchData.toLowerCase())
          ) {
            isValid = true;
            return true;
          }
          return isValid;
        }
      );
      this.setState({
        topPerformingOutletData: list,
      });
    } else {
      this.setState({
        searchValueForOutlet: "",
      });
      this.setState({
        topPerformingOutletData:
          dashboardData.outletListFromYearAndMonth,
      });
    }
  };

  onSearchForTopPerformingProducts = (searchData) => {
    const { dashboardData } = this.props;
    const { topPerformingProductData } = this.state;
    console.log(searchData);
    if (searchData != "" || searchData != undefined) {
      this.setState({
        searchValueForProduct: searchData,
      });
      const list = dashboardData.productListFromYearAndMonth.filter(
        (currentRow) => {
          let isValid = false;
          if (
            currentRow["name"] &&
            currentRow["name"]
              .toString()
              .toLowerCase()
              .includes(searchData.toLowerCase()) || (
              currentRow["code"] &&
              currentRow["code"]
                .toString()
                .toLowerCase()
                .includes(searchData.toLowerCase())
            )
          ) {
            isValid = true;
            return true;
          }
          return isValid;
        }
      );
      this.setState({
        topPerformingProductData: list,
      });
    } else {
      this.setState({
        searchValueForProduct: "",
      });
      this.setState({
        topPerformingProductData:
          dashboardData.productListFromYearAndMonth,
      });
    }
  };


  onSearchForSale = (searchData) => {
    const { dashboardData } = this.props;
    const { salesData } = this.state;
    console.log(searchData);
    if (searchData != "" || searchData != undefined) {
      this.setState({
        searchValueForSale: searchData,
      });
      const list = dashboardData.salesListFromYearAndMonth.filter(
        (currentRow) => {
          let isValid = false;
          if (
            currentRow["name"] &&
            currentRow["name"]
              .toString()
              .toLowerCase()
              .includes(searchData.toLowerCase())
          ) {
            isValid = true;
            return true;
          }
          return isValid;
        }
      );
      this.setState({
        salesData: list,
      });
    } else {
      this.setState({
        searchValueForSale: "",
      });
      this.setState({
        salesData:
          dashboardData.salesListFromYearAndMonth,
      });
    }
  };



  libraryColumns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "title",
      title: "Book Title",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },

    {
      name: "mediaType",
      title: "Media Type",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "status",
      title: "Status",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "returnDate",
      title: "Return Date",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
    },
  ];

  columns = [
    {
      name: "index",
      title: "Sr.No",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "name",
      title: "Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "targetAmt",
      title: "Target Amount",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "actualAmt",
      title: "Achieved",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "contribution",
      title: "% Contribution",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "divisional",
      title: "%(-/+)",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
  ];
  outletColumns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "firmName",
      title: "Outlet Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },

    {
      name: "area",
      title: "Area Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "salesExecutiveName",
      title: "Sales Excutive Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "actualSale",
      title: "Achieved",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "contribution",
      title: "% contribution",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
  ];
  productColumns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "name",
      title: "Product Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },

    {
      name: "code",
      title: "Product Code",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "actualSale",
      title: "Achieved",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "contribution",
      title: "% contribution",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
  ];

  render() {
    const { dashboardData, dashboardData2, stockStakeHolderTypeList } =
      this.props;
    const { type, saleMonth, monthList, selfSalesLineChart, SalesLineChart, searchValueForOutlet,
      topPerformingOutletData, searchValueForProduct, topPerformingProductData, searchValueForSale, salesData } = this.state;
    const { typeError, dateError } = this.state.formErrors;
    return (
      <>
        <div id="layout-wrapper">
          <div className="vertical-overlay" />
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                          <div className="birthday-bg">
                            <span className="element">Welcome Back&nbsp;</span>
                          </div>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-8">
                            <div className="card card-animate flex-row">
                              <div className="card-body">
                                <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                  TOTAL TARGET AMOUNT
                                </p>
                                <div className="d-flex align-items-end justify-content-between mt-3">
                                  <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                      <span
                                        className="counter-value"
                                        data-target="559.25"
                                      >
                                        {
                                          dashboardData
                                            ?.currentYearTargetForSales
                                            .yearTargetAmt
                                        }
                                      </span>
                                    </h4>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body">
                                <p
                                  className="text-uppercase fw-medium text-muted text-truncate mb-3"
                                  style={{ whiteSpace: "normal" }}
                                >
                                  CURRENT MONTH TARGET AMOUNT
                                </p>
                                <div className="d-flex align-items-end justify-content-between mt-3">
                                  <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                      <span
                                        className="counter-value"
                                        data-target="559.25"
                                      >
                                        {
                                          dashboardData
                                            ?.currentYearTargetForSales
                                            .monthTragetAmt
                                        }
                                      </span>
                                    </h4>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body">
                                <p
                                  style={{ whiteSpace: "normal" }}
                                  className="text-uppercase fw-medium text-muted text-truncate mb-3"
                                >
                                  TOTAL ACHIEVED
                                </p>
                                <div className="d-flex align-items-end justify-content-between mt-3">
                                  <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                      <span
                                        className="counter-value"
                                        data-target="559.25"
                                      >
                                        {
                                          dashboardData
                                            ?.currentYearTargetForSales
                                            .salesTeamWithOrderAndTargetAmt
                                        }
                                      </span>
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4">
                            <div className="card card-animate flex-row">
                              <div className="card-body">
                                <p
                                  style={{ whiteSpace: "normal" }}
                                  className="text-uppercase fw-medium text-muted text-truncate mb-3"
                                >
                                  Sales Executive Count
                                </p>
                                <div className="d-flex align-items-end justify-content-between mt-3">
                                  <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                      <span
                                        className="counter-value"
                                        data-target="559.25"
                                      >
                                        {
                                          dashboardData?.count
                                            .salesExcutiveCount
                                        }
                                      </span>
                                    </h4>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body">
                                <p
                                  style={{ whiteSpace: "normal" }}
                                  className="text-uppercase fw-medium text-muted text-truncate mb-3"
                                >
                                  Check In Count
                                </p>
                                <div className="d-flex align-items-end justify-content-between mt-3">
                                  <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                      <span
                                        className="counter-value"
                                        data-target="559.25"
                                      >
                                        {dashboardData?.count.checkInCount}
                                      </span>
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <LineChart
                options={{
                  animationEnabled: true,
                  exportEnabled: true,
                  theme: "light2",
                  axisY: {
                    title: "Target Amount",
                    includeZero: false,
                    suffix: "",
                  },
                  axisX: {
                    title: "Month of Year",
                    prefix: "",
                    interval: 1,
                  },
                  data: [
                    {
                      type: "line",
                      toolTipContent: "Target Amount of {label}: {y}",

                      dataPoints: selfSalesLineChart.map(
                        (monthWiseData) => {
                          return {
                            label: monthList[monthWiseData.month - 1],

                            y: +monthWiseData.targetAmt,
                          };
                        }
                      ),
                    },
                    {
                      type: "line",
                      toolTipContent: "Achived Amount of {label}: {y}",

                      dataPoints: selfSalesLineChart.map(
                        (monthWiseData) => {
                          return {
                            label: monthList[monthWiseData.month - 1],

                            y: +monthWiseData.achivedAmt,
                          };
                        }
                      ),
                    },
                  ],
                }}
              />
              <br />
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header align-items-center d-flex">
                    <div className="col-lg-5">
                      <AutoComplete
                        keyColName={"id"}
                        placeHolder="Stock Stake Holder Type"
                        value={type}
                        name={"type"}
                        options={
                          stockStakeHolderTypeList?.stockStakeHolderTypeList
                        }
                        onChange={this.ChangeHandlerSearch}
                        isError={typeError ? true : false}
                        errorText={typeError ? typeError : " "}
                      />
                    </div>
                    <div className="col-lg-5">
                      <MonthCalender
                        title="year"
                        name="saleMonth"
                        value={saleMonth}
                        onChange={this.onChangeMonthOrYear}
                        isError={dateError ? true : false}
                        errorText={dateError ? dateError : " "}
                      />
                    </div>
                    <button
                      onClick={this.searchSalesList}
                      type="button"
                      className="btn btn-primary waves-effect waves-light btn-sm"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">Sales
                        <div
                          style={{
                            marginLeft: -25,
                          }}
                        >

                          <SearchBar
                            sx={{ width: "100%" }}
                            searchValueForSale={searchValueForSale}
                            filteredRows={this.onSearchForSale}
                          />
                        </div>
                      </h4>
                    </div>
                    <div className="card-body">
                      <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" sx={{ width: "2%" }}>
                                Sr.No
                              </TableCell>
                              <TableCell align="center" sx={{ width: "8%" }}>
                                Name
                              </TableCell>
                              <TableCell align="center" sx={{ width: "5%" }}>
                                Target Amount
                              </TableCell>
                              <TableCell align="center" sx={{ width: "5%" }}>
                                Acheived
                              </TableCell>
                              <TableCell align="center" sx={{ width: "5%" }}>
                                % Contribution
                              </TableCell>
                              <TableCell align="center" sx={{ width: "5%" }}>
                                %(-/+)
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {salesData.map(
                              (salesObject, index) => {
                                return (
                                  <TableRow>
                                    <TableCell align="center">
                                      {index + 1}
                                    </TableCell>
                                    <TableCell width="2%">
                                      <div
                                        onClick={(e) =>
                                          this.salesHandler(e, salesObject.id, salesObject.name)
                                        }
                                        style={{
                                          color: cyanBlue,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {salesObject.name}
                                      </div>

                                    </TableCell>
                                    <TableCell align="right">
                                      {salesObject.targetAmt}
                                    </TableCell>
                                    <TableCell align="right">
                                      {salesObject.actualAmt}
                                    </TableCell>
                                    <TableCell align="right">
                                      {salesObject.contribution}
                                    </TableCell>
                                    <TableCell align="right">
                                      {salesObject.divisional >= 100 ? (
                                        <div>{salesObject.divisional}</div>
                                      ) : (
                                        <div
                                          style={{
                                            color: yellowishBrownColor,
                                          }}
                                        >
                                          {salesObject.divisional == "-"
                                            ? "-"
                                            : "-" + salesObject.divisional}
                                        </div>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )}
                            <TableRow>
                              <TableCell align="center" width="2%" colSpan={3}>
                                Total
                              </TableCell>
                              <TableCell align="right">
                                {dashboardData.salesListFromYearAndMonthTotal}
                              </TableCell>
                              <TableCell align="center">-</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </div>

              {Object.keys(SalesLineChart).length != 0 &&
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                          Sales Graph of {SalesLineChart.name}
                        </h4>
                      </div>
                      <div className="card-body">
                        <>
                          <LineChart
                            options={{
                              animationEnabled: true,
                              exportEnabled: true,
                              theme: "light2",
                              axisY: {
                                title: "Target Amount",
                                includeZero: false,
                                suffix: "",
                              },
                              axisX: {
                                title: "Month of Year",
                                prefix: "",
                                interval: 1,
                              },
                              data: [
                                {
                                  type: "line",
                                  toolTipContent: "Target Amount of {label}: {y}",

                                  dataPoints: SalesLineChart.saleList.map(
                                    (monthWiseData) => {
                                      return {
                                        label: monthList[monthWiseData.month - 1],

                                        y: +monthWiseData.targetAmt,
                                      };
                                    }
                                  ),
                                },
                                {
                                  type: "line",
                                  toolTipContent: "Achived Amount of {label}: {y}",

                                  dataPoints: SalesLineChart.saleList.map(
                                    (monthWiseData) => {
                                      return {
                                        label: monthList[monthWiseData.month - 1],

                                        y: +monthWiseData.achivedAmt,
                                      };
                                    }
                                  ),
                                },
                              ],
                            }}
                          /> <br />
                        </>

                      </div>
                    </div>
                  </div>
                </div>
              }

              <div className="col-lg-5">
                <div className="card">
                  <div className="card-header align-items-center d-flex">
                    <div className="col-lg-12">
                      <MonthCalender
                        title="year"
                        name="saleMonth"
                        value={saleMonth}
                        onChange={this.onDateChangeOutlet}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Top 20 outlet
                        <div
                          style={{
                            marginLeft: -25,
                          }}
                        >
                          <SearchBar
                            placeHolder="Search..."
                            inputProps={{ "aria-label": "search" }}
                            searchValueForOutlet={searchValueForOutlet}
                            filteredRows={this.onSearchForTopPerformingOutlet}
                          ></SearchBar>
                        </div>
                      </h4>
                    </div>
                    <div className="card-body">
                      <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" sx={{ width: "2%" }}>
                                Sr.No
                              </TableCell>
                              <TableCell align="center" sx={{ width: "15%" }}>
                                Outlet Name
                              </TableCell>
                              <TableCell align="center" sx={{ width: "8%" }}>
                                Area Name
                              </TableCell>
                              <TableCell align="center" sx={{ width: "20%" }}>
                                Sales Excutive Name
                              </TableCell>
                              <TableCell align="center" sx={{ width: "5%" }}>
                                Acheived
                              </TableCell>
                              <TableCell align="center" sx={{ width: "5%" }}>
                                % Contribution
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {topPerformingOutletData.map(
                              (outletObject, index) => {
                                return (
                                  <TableRow>
                                    <TableCell align="center">
                                      {index + 1}
                                    </TableCell>
                                    <TableCell width="2%">
                                      {outletObject.firmName}
                                    </TableCell>
                                    <TableCell align="left">
                                      {outletObject.area}
                                    </TableCell>
                                    <TableCell align="left">
                                      {outletObject.salesExecutiveName}
                                    </TableCell>
                                    <TableCell align="right">
                                      {outletObject.actualSale}
                                    </TableCell>
                                    <TableCell align="right">
                                      {outletObject.contribution}
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )}
                            <TableRow>
                              <TableCell align="center" width="2%" colSpan={4}>
                                Total
                              </TableCell>
                              <TableCell align="right">
                                {dashboardData.outletListFromYearAndMonthTotal}
                              </TableCell>
                              <TableCell align="center">-</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Top 20 Product
                        <div
                          style={{
                            marginLeft: -25,
                          }}
                        >
                          <SearchBar
                            placeHolder="Search..."
                            inputProps={{ "aria-label": "search" }}
                            searchValueForProduct={searchValueForProduct}
                            filteredRows={this.onSearchForTopPerformingProducts}
                          ></SearchBar>
                        </div>
                      </h4>
                    </div>
                    <div className="card-body">
                      <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" sx={{ width: "2%" }}>
                                Sr.No
                              </TableCell>
                              <TableCell align="center" sx={{ width: "15%" }}>
                                Product Name
                              </TableCell>
                              <TableCell align="center" sx={{ width: "8%" }}>
                                Product Code
                              </TableCell>
                              <TableCell align="center" sx={{ width: "5%" }}>
                                Acheived
                              </TableCell>
                              <TableCell align="center" sx={{ width: "5%" }}>
                                % Contribution
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {topPerformingProductData.map(
                              (productObject, index) => {
                                return (
                                  <TableRow>
                                    <TableCell align="center">
                                      {index + 1}
                                    </TableCell>
                                    <TableCell width="2%">
                                      {productObject.name}
                                    </TableCell>
                                    <TableCell align="left">
                                      {productObject.code}
                                    </TableCell>
                                    <TableCell align="right">
                                      {productObject.actualSale}
                                    </TableCell>
                                    <TableCell align="right">
                                      {productObject.contribution}
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )}
                            <TableRow>
                              <TableCell align="center" width="2%" colSpan={3}>
                                Total
                              </TableCell>
                              <TableCell align="right">
                                {dashboardData.productListFromYearAndMonthTotal}
                              </TableCell>
                              <TableCell align="center">-</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </div>
              {/* <LineChart
                  options={{
                    animationEnabled: true,
                    exportEnabled: true,
                    theme: "light2", 
                    axisY: {
                      title: "Target Amount",
                      includeZero: false,
                      suffix: "%",
                    },
                    axisX: {
                      title: "Month of Year",
                      prefix: "",
                      interval: 1,
                    },
                    data: [
                      {
                        type: "line",
                        toolTipContent: "Target Amount of {label}: {y}%",

                        dataPoints: dashboardData2.monthYearTarget.map(
                          (monthWiseData) => {
                          return{
                            label: monthList[monthWiseData.month-1],
                          y: monthWiseData.targetAmt,} 
                         }
                        ),
                      },
                    ],
                  }}
                /> */}

              <Grid item xs={12} md={12} lg={12}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography variant="h5">Sales Team List </Typography>
                  </Grid>
                  <Grid item />
                </Grid>
                <MainCard sx={{ mt: 2 }} content={false}>
                  {dashboardData2.salesInfo.length != 0 ? (
                    <OrdersTable
                      rows={dashboardData2.salesInfo.map((sales) => {
                        const seriesData = [
                          {
                            label:
                              sales.dtoDateWiseTotalAmount != null
                                ? sales.dtoDateWiseTotalAmount.map(
                                  (rowData) => {
                                    return rowData.date;
                                  }
                                )
                                : [],
                            name: "Amount",
                            data:
                              sales.dtoDateWiseTotalAmount != null
                                ? sales.dtoDateWiseTotalAmount.map(
                                  (rowData) => {
                                    return rowData.totalAmount;
                                  }
                                )
                                : [],
                          },
                        ];
                        return {
                          ...sales,
                          salesTeam:
                            sales.salesTeam != null ? sales.salesTeam.name : "",
                          salesTeamId:
                            sales.salesTeam != null ? sales.salesTeam.id : "",
                          series: seriesData,
                          options: {
                            chart: {
                              type: "line",
                              width: 100,
                              height: 35,
                              sparkline: {
                                enabled: true,
                              },
                            },

                            stroke: {
                              curve: "straight",
                            },
                            tooltip: {
                              custom: function ({
                                series,
                                seriesIndex,
                                dataPointIndex,
                                w,
                              }) {
                                return (
                                  '<div class="arrow_box">' +
                                  "<span><b>" +
                                  seriesData[0].label[dataPointIndex] +
                                  " - " +
                                  series[seriesIndex][dataPointIndex] +
                                  "  ₹" +
                                  "</b></span>" +
                                  "</div>"
                                );
                              },
                            },
                            xaxis: {
                              categories:
                                sales.dtoDateWiseTotalAmount != null
                                  ? sales.dtoDateWiseTotalAmount.map(
                                    (rowData) => {
                                      return rowData.date;
                                    }
                                  )
                                  : [],
                            },
                          },
                        };
                      })}
                      extraData={this.getExtraData(dashboardData2.salesInfo)}
                    />
                  ) : (
                    <NoDataFound />
                  )}
                </MainCard>
              </Grid>
              <footer className="footer">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-6">2022 © Byte Elephants. </div>
                    <div className="col-sm-6">
                      <div className="text-sm-end d-none d-sm-block">
                        {" "}
                        Designed &amp; Developed by Byte Elephants Technologies
                        PVT LTD{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
  dashboardData: state.salesDashboard,
  dashboardData2: state.adminDashboard,
  stockStakeHolderTypeList: state.stockStakeHolderType,
});

const mapDispatchToProps = {
  onToggle,
  showLoador,
  getSalesListFromYearAndMonth,
  getOutletListFromYearAndMonth,
  getCurrentYearTargetForSales,
  getStockStakeHolderTypeForList,
  getProductListFromYearAndMonth,
  getCount,
  getMonthYearTargetForSales,
  getSalesTeamInfo,
  showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesDashboard);
