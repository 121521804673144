import React from "react";
import { connect } from "react-redux";
import { batchWiseGRNJSON } from "../../DynamicFormsJson/Transaction/batchWiseGRN";
import { getRequestGRNList } from "../../Slice/requestGRN.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  serverMsg,
  statusmsg,
} from "../../config/messageconstant";
import { requestGRNColumns } from "../../tableColumns/table-columns";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";
import dayjs from "dayjs";
class BatchWiseGRNList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: dayjs().format("DD-MM-YYYY"),
      toDate: dayjs().format("DD-MM-YYYY"),
    };
  }

  componentDidMount() {
    const { getRequestGRNList, showLoador, showNotification } = this.props;

    if (navigator.onLine) {
      showLoador({ loador: true });
      getRequestGRNList({
        fromDate: dayjs().format("DD-MM-YYYY"),
        toDate: dayjs().format("DD-MM-YYYY"),
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  onSearchData = async (data) => {
    const { getRequestGRNList, showLoador, showNotification } = this.props;
    this.setState({
      fromDate: data.fromDate,
      toDate: data.toDate,
    });
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getRequestGRNList({
        fromDate: data.fromDate,
        toDate: data.toDate,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  getData = async () => {
    const { getRequestGRNList, showLoador, showNotification } = this.props;
    const { fromDate, toDate } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getRequestGRNList({
        fromDate: fromDate,
        toDate: toDate,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  rowSendRequest = (rowData) => {
    const { showLoador, showNotification } = this.props;

    swal({
      text: "Do you want to send the request?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        if (navigator.onLine) {
          showLoador({ loador: true });
          await apiGet({
            url:
              endpoint.grnGVNHeader +
              "/" +
              rowData.id +
              "/update-status?status=1",
          }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success) {
              showNotification({ msg: statusmsg });
              this.getData();
            }
          });
        } else {
          showNotification({ msg: noInternetMsg, severity: "error" });
        }
      }
    });
  };

  rowPDF = (rowData) => {
    downloadF({
      url:
        endpoint.creditNote +
        "/report-by-id?id=" +
        rowData.id +
        "&documentType=1",
      ext: "pdf",
      openNewTab: true,
    }).then((response) => {
      if (!response) {
        this.props.showNotification({
          msg: serverMsg,
          severity: "error",
        });
      } else {
      }
    });
  };

  rowEdit = (rowData) => {
    window.location.replace("/batchwise-grn-edit/" + rowData.id);
  };

  render() {
    const { dynamicMasterData } = this.state;
    const { requestGRNList } = this.props;
    return (
      <>
        <DynamicMainScreen
          showDeleteIcon={true}
          dynamicMasterData={dynamicMasterData}
          searchList={batchWiseGRNJSON.searchList}
          onSearchData={this.onSearchData}
          formPathView={batchWiseGRNJSON.formPathView}
          formPathEdit={batchWiseGRNJSON.formPathEdit}
          formPath={batchWiseGRNJSON.formPath}
          screenTitle={batchWiseGRNJSON.screenTitle}
          fieldMeta={batchWiseGRNJSON.fieldMeta}
          showPdfDownload={false}
          showExcelDownload={false}
          isActiveURL={batchWiseGRNJSON.isActiveURL}
          showAddButton={batchWiseGRNJSON.showAddButton}
          tableColumnsToFilter={batchWiseGRNJSON.tableColumnsToFilter}
          tableColumns={requestGRNColumns}
          tableData={requestGRNList.requestGRNListData}
          apiBaseURL={batchWiseGRNJSON.apiBaseURL}
          callApi={false}
          getTableDataById={true}
          showSearchBox={false}
          rowPDF={this.rowPDF}
          showPdf={false}
          showEditIcon={true}
          rowEdit={this.rowEdit}
          deleteConditionName="status"
          deleteConditionValue="0"
          editConditionName="status"
          editConditionValue="0"
          showHeadView={true}
          showViewButtonInTable={true}
          showSendRequestIcon={true}
          rowSendRequest={this.rowSendRequest}
          sendRequestIconName="status"
          sendRequestIconValue="0"
          showPegination={false}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  requestGRNList: state.requestGRN,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getRequestGRNList,
};
export default connect(mapStateToProps, mapDispatchToProps)(BatchWiseGRNList);
