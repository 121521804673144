import endpoint from "../../config/endpoints";

export const orderHistoryEditJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.outletOrderDetail,
  formPath: "/order-history-edit",
  formPathView: "/payment-list",
  screenTitle: "Edit Order Details",
  showTitle: true,
  showCancel: false,
  showBackToList: false,
  showSaveBtn: false,
  fieldMeta: [
    {
      label: "Category",
      controlType: "autocomplete",
      placeHolder: "Select Category ",
      md: 3.5,
      lg: 3.5,
      sm: 3.5,
      xs: 12,
      masterName: "categoryDetail",
      dataKey: "category",
      isRootLevelKey: true,
      isMandatory: true,
      getListId: "category",
    },
    {
      label: "Product ",
      controlType: "autocomplete",
      placeHolder: "Select Product",
      md: 3.5,
      lg: 3.5,
      sm: 3.5,
      xs: 12,
      masterName: "productDetail",
      dataKey: "product",
      getListFrom: "category",
      isRootLevelKey: false,
      isMandatory: true,
      getListId: "product",
    },
    {
      label: "Quantity ",
      controlType: "textfield",
      placeHolder: "Enter Quantity",
      inputType: "number",
      md: 3.5,
      lg: 3.5,
      sm: 3.5,
      xs: 12,
      dataKey: "quantity",
      isRootLevelKey: false,
      isMandatory: true,
    },
  ],
  fieldMetaForSearch: [
    {
      label: "Stock Holder Type",
      controlType: "multiSelect",
      placeHolder: "Select Stock Holder Type",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      getListId: "stockStakeHolderType",
      masterName: "stakeHolderTypeDetail",
      dataKey: "stockStakeHolderType",
      isRootLevelKey: false,
      isMandatory: false,
    },
    {
      label: "Stock Holder Name",
      controlType: "multiSelect",
      placeHolder: "Select Stock Holder",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      masterName: "stakeHolderName",
      dataKey: "stackholder",
      getListFrom: "stockStakeHolderType",
      isRootLevelKey: false,
      isMandatory: true,
    },
  ],
  searchList: [
    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "area",
      getListId: "area",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Beat",
      controlType: "autocomplete",
      placeHolder: "Select Beat",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "beat",
      masterName: "beatDetail",
      getListFrom: "area",
      getListId: "beat",
      isMandatory: true,
      isRootLevelKey: false,
    },
    {
      label: "Outlet",
      controlType: "autocomplete",
      placeHolder: "Select Outlet",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "outlet",
      getListFrom: "beat",
      masterName: "outletDetail",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      isMandatory: true,
    },
  ],
};
