import endpoint from "../../config/endpoints";

export const paymentFormJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.paymentReceipt,
  formPath: "/payment",
  formPathView: "/payment-list",
  screenTitle: "Payment List",
  showPdfDownload: false,
  pdfFileName: "Payment",
  showExcelDownload: false,
  excelFileName: "Payment",
  showTitle: true,
  showCancel: false,
  showAddButton: false,
  showBackToList: true,
  showSaveBtn: false,
  fieldMeta: [
    {
      label: "Amount ",
      controlType: "textfield",
      placeHolder: "Enter Amount",
      inputType: "number",
      md: 5.5,
      lg: 5.5,
      sm: 5.5,
      xs: 12,
      dataKey: "amt",
      isRootLevelKey: false,
      isMandatory: true,
      spValid: "float",
    },
    {
      label: "Pay Type",
      controlType: "radiogroup",
      md: 5.5,
      lg: 5.5,
      sm: 5.5,
      xs: 12,
      dataKey: "payType",
      tableDataKey: "payType",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Cash",
          value: 0,
        },
        {
          label: "Cheque",
          value: 1,
        },
        {
          label: "Online",
          value: 2,
        },
      ],
      isMandatory: true,
    },
  ],
  fieldMetaForSearch: [
    {
      label: "Stock Holder Type",
      controlType: "multiSelect",
      placeHolder: "Select Stock Holder Type",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      getListId: "stockStakeHolderType",
      masterName: "stakeHolderTypeDetail",
      dataKey: "stockStakeHolderType",
      isRootLevelKey: false,
      isMandatory: false,
    },
    {
      label: "Stock Holder Name",
      controlType: "multiSelect",
      placeHolder: "Select Stock Holder",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      masterName: "stakeHolderName",
      dataKey: "stackholder",
      getListFrom: "stockStakeHolderType",
      isRootLevelKey: false,
      isMandatory: true,
    },
  ],
  searchList: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "area",
      getListFrom: "taluka",
      getListId: "area",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Beat",
      controlType: "multiSelect",
      placeHolder: "Select Beat",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "beat",
      masterName: "beatDetail",
      getListFrom: "area",
      getListId: "beat",
      isMandatory: true,
      isRootLevelKey: true,
    },
    {
      label: "Outlet",
      controlType: "multiSelect",
      placeHolder: "Select Outlet",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelXS: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueXS: 12,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "outlet",
      masterName: "outletDetail",
      getListFrom: "beat",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
};
