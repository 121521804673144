import Button from "@mui/material/Button";
import { DarkBlue } from "../../config/ColorObj";


export const ButtonCompo = ({
  disable,
  onClick,
  sx = "",
  className,
  type,
  variant,
  name,
  fullWidth,
  size,
  shape,
  color = "secondary",
}) => {
  return (
    <Button
      type={type}
      disabled={disable}
      size={size}
      {...(sx === "" ? {
        sx: sx,
      } : sx = {
        ':hover': {
          bgcolor: DarkBlue, // theme.palette.primary.main
          color: 'white',
        },
      })}

      // sx={sx==""&&variant=="contained"?{ color:variant=="contained"? 'white':"primary.darkMain", backgroundColor: "primary.darkMain" }:sx}
      color={color}
      className={className}
      fullWidth={fullWidth}
      variant={variant}
      onClick={onClick}
      shape={shape}
    >
      { name}
    </Button >
  );
};
ButtonCompo.defaultProps = {
  variant: "contained",
  type: "button",
};
