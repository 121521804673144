import React from "react";
import { connect } from "react-redux";
import { PaymentTermJson } from "../../../DynamicFormsJson/MastersJSON/paymentTerm";
import { getPaymentTerm } from "../../../Slice/paymentTerm.Slice";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import {
    PaymentTermColumns,
} from "../../../tableColumns/table-columns";

class PaymentTermTable extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { paymentTermList, getPaymentTerm } = this.props;
        return (
            <>
                <DynamicMainScreen
                    screenTitle={PaymentTermJson.screenTitle}
                    fieldMeta={PaymentTermJson.fieldMeta}
                    formPath={PaymentTermJson.formPath}
                    showPdfDownload={PaymentTermJson.showPdfDownload}
                    showExcelDownload={false}
                    showExtension={false}
                    pdfFileName={PaymentTermJson.pdfFileName}
                    excelFileName={PaymentTermJson.excelFileName}
                    showAddButton={PaymentTermJson.showAddButton}
                    tableColumnsToFilter={PaymentTermJson.tableColumnsToFilter}
                    tableColumns={PaymentTermColumns}
                    tableData={paymentTermList?.paymentTerm}
                    getTableData={getPaymentTerm}
                    apiBaseURL={PaymentTermJson.apiBaseURL}
                    baseIdColumn={PaymentTermJson.baseIdColumn}
                    showDeleteIcon={true}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    paymentTermList: state.paymentTerm,
});
const mapDispatchToProps = {
    getPaymentTerm,
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentTermTable);
