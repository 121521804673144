import endpoint from "../../config/endpoints";

export const NoOfStaffJson = {
    formPath: "/no-of-staff-form",
    apiBaseURL: endpoint.noOfStaff,
    screenTitle: "No. Of Staff",
    showAddButton: true,
    showPdfDownload: false,
    showExcelDownload: false,
    tableColumnsToFilter: [
        {
            columnDisplayName: "Name",
            columnKeyName: "name",
            isChecked: true,
        },
        {
            columnDisplayName: "Status",
            columnKeyName: "isActive",
            isChecked: true,
        },
    ],

    fieldMeta: [
        {
            label: "Name",
            controlType: "textfield",
            inputType:"number",
            placeHolder: "Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            autoFocus: true,
            dataKey: "name",
            isMandatory: true,
        },
       
    ]
};
