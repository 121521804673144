// import Box from "@mui/material/Box";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import * as React from "react";

// export default function EnhancedTable({
//     reportDetails,
//     tableData,

// }) {
//     let parameterCheck = reportDetails.parameterCheck == "" ? "" : JSON.parse(reportDetails.parameterCheck)
//     let tableColumns1 = reportDetails.tableColumns1 ? JSON.parse(reportDetails.tableColumns1) : []
//     let tableColumns2 = reportDetails.tableColumns2 ? JSON.parse(reportDetails.tableColumns2) : []
//     let tableColumns3 = reportDetails.tableColumns3 ? JSON.parse(reportDetails.tableColumns3) : []
//     return (

//         <Box sx={{ width: "100%" }}>
//             <TableContainer sx={{ maxHeight: 500 }}>
//                 <Table stickyHeader aria-label="sticky table">
//                     <TableHead>
//                         <TableRow>
//                             <TableCell
//                                 style={{
//                                     minWidth: 100,
//                                 }}
//                             >

//                             </TableCell>
//                             {tableData.response1.map((headCell, index) => {
//                                 let colData = tableColumns1.map((col) => {
//                                     return headCell[col.fieldName]
//                                 })
//                                 return (
//                                     <>


//                                         <TableCell
//                                             key={headCell.name}
//                                             align={"center"}
//                                             style={{
//                                                 minWidth: 200,
//                                             }}
//                                         >
//                                             {
//                                                 colData.join()
//                                             }
//                                         </TableCell>
//                                     </>

//                                 );
//                             })}
//                         </TableRow>
//                     </TableHead>
//                     <TableBody>
//                         {tableData.response2.map((cellData1, index1) => {
//                             let cellData1Value = tableColumns2.map((col) => {
//                                 return cellData1[col.fieldName]
//                             })
//                             return (
//                                 <>
//                                     <TableRow>
//                                         <TableCell
//                                         >
//                                             {
//                                                 cellData1Value.join()
//                                             }
//                                         </TableCell>

//                                         {
//                                             tableData.response1.map((cellData2, index2) => {
//                                                 let flag = 0

//                                                 return (

//                                                     <>

//                                                         {
//                                                             tableData.response3.map((cellData3, index3) => {
//                                                                 // if (cellData3.product_id == cellData2.product_id && cellData3.bill_date == cellData1.selected_date) {

//                                                                 if (cellData3[parameterCheck.xAxisKey] == cellData2[parameterCheck.xAxisValue] && cellData3[parameterCheck.yAxisKey] == cellData1[parameterCheck.yAxisValue]) {
//                                                                     flag = 1
//                                                                     let cellData3Value = tableColumns3.map((col) => {
//                                                                         return col.isTotal == 1 && cellData3[col.fieldName] ? cellData3[col.fieldName].toFixed(2) : cellData3[col.fieldName]
//                                                                     })
//                                                                     return (
//                                                                         <TableCell
//                                                                         >
//                                                                             {
//                                                                                 cellData3Value.join()
//                                                                             }
//                                                                         </TableCell>
//                                                                     );

//                                                                 }

//                                                             })

//                                                         }
//                                                         {
//                                                             flag == 0 ? <TableCell
//                                                                 align="center"
//                                                             >
//                                                                 -
//                                                             </TableCell>
//                                                                 : ""
//                                                         }



//                                                     </>
//                                                 )


//                                             })
//                                         }
//                                     </TableRow>

//                                 </>
//                             )


//                         })}


//                     </TableBody>
//                 </Table>
//             </TableContainer>


//         </Box >
//     );
// }

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

export default function EnhancedTable({
    reportDetails,
    tableData,
    columns
}) {
    console.log(reportDetails)
    let tableColumns2 = reportDetails.tableColumns2 ? JSON.parse(reportDetails.tableColumns2) : []
    let tableColumns2Data = tableColumns2.filter((col) => col.isExtra == 1)
    return (

        <Box sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: 500 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                align={"center"}
                                style={{
                                    minWidth: 100,
                                }}
                            >
                                {tableColumns2.length != 0 ? tableColumns2[0].columnName : ""}
                            </TableCell>
                            {tableColumns2Data.map((headCell, index) => {

                                return (
                                    <>


                                        <TableCell
                                            key={headCell.columnName}
                                            align={"center"}
                                            style={{
                                                minWidth: 200,
                                            }}
                                        >
                                            {
                                                headCell.columnName
                                            }
                                        </TableCell>
                                    </>

                                );
                            })}
                            {columns.map((headCell, index) => {

                                return (
                                    <>


                                        <TableCell
                                            key={headCell.name}
                                            align={"center"}
                                            style={{
                                                minWidth: 200,
                                            }}
                                        >
                                            {
                                                headCell.name
                                            }
                                        </TableCell>
                                    </>

                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.map((cellData1, index1) => {

                            return (
                                <>
                                    <TableRow>
                                        <TableCell
                                        //   align={"center"}
                                        >
                                            {
                                                cellData1.name
                                            }
                                        </TableCell>
                                        {
                                            cellData1.extraData && cellData1.extraData.map((extraData) => {
                                                return (
                                                    <TableCell
                                                    align={"center"}
                                                    >
                                                        {
                                                            extraData
                                                        }
                                                    </TableCell>
                                                )
                                            })
                                        }
                                        {
                                            cellData1.detailsList && cellData1.detailsList.map((cellData2, index2) => {
                                                const valueData = cellData2.map((rowData) => {
                                                    return rowData.value
                                                })
                                                return (

                                                    <>
                                                        <TableCell align="center">
                                                            <>
                                                                {cellData2.length != 0 && cellData2.length == 1 ?

                                                                    cellData2[0].value
                                                                    :
                                                                    cellData2.map((rowData) => {
                                                                        return (
                                                                            <TableRow>
                                                                                {
                                                                                    rowData.label == undefined ? rowData.columnName : rowData.label
                                                                                }
                                                                                {rowData.value == undefined ? " : " + 0 : " : " + rowData.value}
                                                                            </TableRow>
                                                                        )
                                                                    })

                                                                }
                                                            </>

                                                        </TableCell>
                                                    </>
                                                )


                                            })
                                        }
                                    </TableRow>

                                </>
                            )


                        })}


                    </TableBody>
                </Table>
            </TableContainer>


        </Box >
    );
}
