import endpoint from "../../config/endpoints";
import dayjs from "dayjs";
export const ManualOrderAdminJson = {
  apiBaseURL: endpoint.area,
  screenTitle: "Manual Order",
  showSaveBtn: false,
  showBackToList: false,

  fieldMeta: [
    {
      label: "Stock Holder",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeholdersDetail",
      dataKey: "stakeholder",
      getListId: "stakeholder",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
  fieldMetaForBilling: [
    {
      label: "Po No",
      controlType: "textfield",
      placeHolder: "Enter Po No",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "poNo",
      isMandatory: false,
    },
    {
      label: "Po Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "poDate",
      defaultValue: dayjs(),
      isMandatory: false,
    },
    {
      label: "Transporter ID",
      controlType: "textfield",
      placeHolder: "Transporter ID",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "transporterId",
      isMandatory: false,
    },
    {
      label: "Transporter",
      controlType: "textfield",
      placeHolder: "Transporter",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "transporter",
      isMandatory: false,
    },
    {
      label: "LR/RR/GR No",
      controlType: "textfield",
      placeHolder: "LR/RR/GR No",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "lrRrGrNo",
      isMandatory: false,
    },
    {
      label: "LR/RR/GR Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "lrRrGrDate",
      defaultValue: dayjs(),
      isMandatory: false,
    },
    {
      label: "E-Way Bill No",
      controlType: "textfield",
      placeHolder: "E-Way Bill No",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "eWayBillNo",
      isMandatory: false,
    },
    {
      label: "E-Way Bill Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "eWayBillDate",
      defaultValue: dayjs(),
      isMandatory: false,
    },
    {
      label: "Vehicle No",
      controlType: "textfield",
      placeHolder: "Vehicle No",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "vehicleNo",
      isMandatory: false,
    },
    {
      label: "Same AS Bill To",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sameAsBillTo",
      tableDataKey: "sameAsBillTo",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: false,
    },
    {
      label: "Stock Holder",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeholdersDetail",
      dataKey: "stakeholder",
      getListId: "stakeholder",
      isRootLevelKey: true,
      isMandatory: false,
    },
    {
      label: "Remark",
      controlType: "textfield",
      placeHolder: "Remark",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "remark",
      isMandatory: false,
    },
  ],
};
