import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  brand: [],
  brandNew: [],
};
let URL = endpoints.brand;
const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    brandSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        brand: row,
      };
    },

    brandNewSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        brandNew: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { brandSuccess, brandNewSuccess, resetState } = brandSlice.actions;

export default brandSlice.reducer;

export const getBrand = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"sortOrder": "ASC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((brandObject, index) => {
          let brandData = {
            index: index + 1,
            id: brandObject.id === null ? "" : brandObject.id,
            name: brandObject.name === null ? "" : brandObject.name,
            shortName:
              brandObject.shortName === null ? "" : brandObject.shortName,
            sortOrder:
              brandObject.sortOrder === null ? "" : brandObject.sortOrder,
          };
          return brandData;
        });
        dispatch(brandSuccess({ row }));
        dispatch(brandNewSuccess({ row: content }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const setBrand =
  ({ row }) =>
  async (dispatch) => {
    dispatch(brandNewSuccess({ row }));
  };
