import endpoint from "../../config/endpoints";
import dayjs from "dayjs";

export const BillingHistoryTableJson = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.area,
  screenTitle: "Invoice List",
  formPathView: "/billing-history-view",
  //deleteURL: endpoint.adminDashboard + "/by-is-bill-generate",
  formPath: "/billing-history-edit",

  searchListBySalsPerson: [
    {
      label: "Search By",
      controlType: "radiogroup",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelXS: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueXS: 12,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      getListId: "flag",
      dataKey: "flag",
      tableDataKey: "Outlet wise",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Outlet wise",
          value: "0",
        },
        {
          label: "Sales Person wise",
          value: "1",
        },
      ],
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromdate",
      defaultValue: dayjs(),
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "todate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      defaultValue: dayjs(),
      dataKey: "todate",
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
    },
    {
      label: "Sales person",
      controlType: "multiSelect",
      placeHolder: "Select Sales Person",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelXS: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueXS: 12,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "salesPersonIds",
      masterName: "salesPersonDetail",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
  searchList: [
    {
      label: "Search By",
      controlType: "radiogroup",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelXS: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueXS: 12,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      getListId: "flag",
      dataKey: "flag",
      tableDataKey: "Outlet wise",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Outlet wise",
          value: "0",
        },
        {
          label: "Sales Person wise",
          value: "1",
        },
      ],
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromdate",
      defaultValue: dayjs(),
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "todate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      defaultValue: dayjs(),
      dataKey: "todate",
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
    },
    {
      label: "Beat",
      controlType: "multiSelect",
      placeHolder: "Select Beat",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelXS: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueXS: 12,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "beat",
      masterName: "beatDetail",
      getListId: "beat",
      isMandatory: true,
      isRootLevelKey: false,
    },
    {
      label: "Outlet",
      controlType: "multiSelect",
      placeHolder: "Select Outlet",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelXS: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueXS: 12,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "outletIds",
      getListFrom: "beat",
      masterName: "outletDetail",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
};
