import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import endpoint from "../../config/endpoints";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { pendingGrnListJSON } from "../../DynamicFormsJson/Transaction/pendingGrnList";
import {
  getPendingGrnList,
  getPendingGrnListByDate,
} from "../../Slice/requestGRN.slice";
import { PendingGrnListColumns } from "../../tableColumns/table-columns";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";
import { apiGet } from "../../utils/api_service";
class PendingGrnList extends React.Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const {
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {

      const showCompanyFlag = localStorage.getItem("showCompanyFlag");

      if (showCompanyFlag == 0) {
        this.getData();
      }


    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getData = async () => {
    const {
      getPendingGrnList,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getPendingGrnList().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };


  rowPDF = (rowData) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      downloadF({
        url:
          endpoint.grnGVNHeader +
          "/report-by-id?documentType=1&id=" +
          rowData.id,
        ext: "pdf",
        openNewTab: true,
      }).then((response) => {
        showLoador({ loador: false });
        if (!response) {
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
        } else {
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onSearchList = (data) => {
    const { getPendingGrnListByDate, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getPendingGrnListByDate({
        fromdate: data.fromdate,
        todate: data.todate,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const { pendingGrnList } = this.props;
    return (
      <>
        <DynamicMainScreen
          screenTitle={"Pending GRN"}
          formPathView={pendingGrnListJSON.formPathView}
          formPathForOutlet={pendingGrnListJSON.formPathForOutlet}
          searchList={pendingGrnListJSON.searchList}
          showPdfDownload={false}
          showExcelDownload={false}
          showViewButtonInTable={true}
          showAddButton={false}
          tableColumns={PendingGrnListColumns}
          tableData={pendingGrnList?.pendingGrnList}
          getTableData={this.getData}
          callApi={false}
          showDeleteIcon={false}
          showEditIcon={false}
          rowPDF={this.rowPDF}
          showPdf={false}
          showOutlet={true}
          showPegination={false}
          onSearchData={this.onSearchList}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  pendingGrnList: state.requestGRN,
});
const mapDispatchToProps = {
  getPendingGrnList,
  getPendingGrnListByDate,
  showLoador,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(PendingGrnList);
