import endpoint from "../../config/endpoints";

export const CreditNoteForOutletNewJson = {
  // formPath: "/region-and-target-form",
  apiBaseURL: endpoint.area,
  screenTitle: "Credit Note For Outlet",
  showSaveBtn: false,
  showBackToList: true,

  fieldMeta: [
    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      getListId: "area",
      dataKey: "area",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Beat",
      controlType: "autocomplete",
      placeHolder: "Select Beat",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "beat",
      masterName: "beatDetail",
      getListFrom: "area",
      getListId: "beat",
      isMandatory: true,
      isRootLevelKey: false,
    },
    {
      label: "Outlet",
      controlType: "autocomplete",
      placeHolder: "Select Outlet",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "outletIds",
      getListFrom: "beat",
      getListId: "beat,outletIds",
      masterName: "outletDetail",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Is calculate in stock",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      disable: true,
      dataKey: "instockOutstock",
      tableDataKey: "instockOutstock",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: true,
    },
  ],
  fieldMetaBill: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromdate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "todate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "todate",
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
      isMandatory: true,
      onSubmit: "compare",
    },
    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      getListId: "area",
      dataKey: "area",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Beat",
      controlType: "autocomplete",
      placeHolder: "Select Beat",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "beat",
      masterName: "beatDetail",
      getListFrom: "area",
      getListId: "beat",
      isMandatory: true,
      isRootLevelKey: false,
    },
    {
      label: "Outlet",
      controlType: "autocomplete",
      placeHolder: "Select Outlet",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "outletIds",
      masterName: "outletDetail",
      getListFrom: "beat",
      isRootLevelKey: true,
      isMandatory: true,
      getListId: "outletIds",
    },
    {
      label: "Bill",
      controlType: "autocomplete",
      placeHolder: "Select Bill",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "billDetail",
      dataKey: "billNo",
      isRootLevelKey: true,
      isMandatory: true,
      getListFrom: "outletIds",
      getListId: "billNo",
    },
    {
      label: "Is calculate in stock",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      disable: true,
      dataKey: "instockOutstock",
      tableDataKey: "instockOutstock",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: true,
    },
  ],
  fieldMetaDetails: [
    {
      label: "Bill",
      controlType: "autocomplete",
      placeHolder: "Select Bill",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "billDetail",
      dataKey: "billNo",
      isRootLevelKey: true,
      isMandatory: true,
      getListFrom: "outletIds",
      getListId: "billNo",
    },
    {
      label: "Product",
      controlType: "autocomplete",
      placeHolder: "Select Product",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "productDetail",
      dataKey: "product",
      getListFrom: "billNo",
      isRootLevelKey: true,
      isMandatory: true,
      getData: true,
    },
    {
      label: "Batch",
      controlType: "textfield",
      placeHolder: "Enter Batch",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "batch",
      isMandatory: true,
    },
    {
      label: "Quantity",
      controlType: "textfield",
      placeHolder: "Enter Quantity",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "qty",
      isMandatory: true,
    },
    {
      label: "Rate",
      controlType: "textfield",
      placeHolder: "Enter Rate",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "rate",
      isMandatory: false,
      spValid: "float",
      disableTrue: true,
    },
    {
      label: "UOM",
      controlType: "textfield",
      placeHolder: "Enter Rate",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "uom",
      isMandatory: false,
      disableTrue: true,
    },
  ],
};
