import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  forgotPassword: [],
  isFetch: false,
  GetpassOTPmsg: "",
  ispassGetOTP: false,
  getValidateOtpmsg: "",
  isValidateOtp: false,
  isFail: false,
  error: "",
  isValidateOtpFail: false,
  errorMsg: "",
  forgotPasswordUpdated: "",
  isFetchPasswordUpdate: false,
};

let URL = endpoints.forgotPassword;
const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    forgotPasswordSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        forgotPassword: row,
        isFetch: true,
      };
    },
    passwordGenrateOTPSuccess: (state = cloneDeep(initialState), action) => {
      const { message } = action.payload;
      return {
        ...state,
        GetpassOTPmsg: message,
        ispassGetOTP: true,
        isValidateOtpFail: false,
        errorMsg: "",
      };
    },
    passwordValidateOTPSuccess: (state = cloneDeep(initialState), action) => {
      const { message } = action.payload;
      return {
        ...state,
        getValidateOtpmsg: message,
        isValidateOtp: true,
      };
    },
    passwordValidateOTPFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        errorMsg: "Invalid otp",
        isValidateOtpFail: true,
      };
    },
    passwordGenerateOTPFailure: (state = cloneDeep(initialState), action) => {
      const errorMsg = action.payload;

      return {
        ...state,
        error: errorMsg,
        isFail: true,
      };
    },
    passwordValidateOtpDetailsFail: (
      state = cloneDeep(initialState),
      action
    ) => {
      return {
        ...state,
        errorMsg: "",
        isValidateOtpFail: false,
      };
    },
    passwordGenerateOtpDetailsFail: (
      state = cloneDeep(initialState),
      action
    ) => {
      return {
        ...state,
        error: "",
        isFail: false,
      };
    },
    forgotPasswordFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        forgotPassword: [],
        isFetch: false,
      };
    },
    personRegistrationSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        forgotPassword: row,
        isFetch: true,
      };
    },
    personRegistrationSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        forgotPassword: [],
        isFetch: false,
      };
    },
    passwordUpdateSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        forgotPasswordUpdated: "Password Update",
        isFetchPasswordUpdate: true,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  forgotPasswordSuccess,
  passwordGenerateOTPFailure,
  passwordGenerateOtpDetailsFail,
  passwordGenrateOTPSuccess,
  passwordValidateOTPSuccess,
  passwordValidateOTPFailure,
  userPasswordFailure,
  passwordUpdateSuccess,
  passwordValidateOtpDetailsFail,
  resetState,
} = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;

export const getOTP =
  ({ userName }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/generate-otp?userName=" + userName,
      })
        .then(({ response }) => {
          console.log("Success With OTP Send ========>", response.data);
          const { message } = response.data.data;
          dispatch(passwordGenrateOTPSuccess({ message }));
          return true;
        })
        .catch((error) => {
          console.log("Error With OTP Send ========>", error.response.data);
          const errorMsg = error.response.data.errorMessage;
          dispatch(passwordGenerateOTPFailure(errorMsg));
          return false;
        });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getValidateOTP =
  ({ userName, otp }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/validate-otp?userName=" + userName + "otp=" + otp,
      })
        .then((response) => {
          console.log("Success With id validate OTP ========>", response);
          const { message } = response.data.data;
          dispatch(passwordValidateOTPSuccess({ message }));
          return true;
        })
        .catch((error) => {
          console.log("Error With Validate OTP ========>", error.response.data);
          dispatch(passwordValidateOTPFailure());
          return false;
        });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getUpdatePassword =
  ({ token, password}) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/update-password?token=" + token + "password=" + password,
      })
        .then((response) => {
          console.log("Success With saveReservCat ========>", response.data);
          const { data } = response.data;
          dispatch(passwordUpdateSuccess());
          return data;
        })
        .catch((error) => {
          console.log("ErrorsaveReservCat ========>", error.response.data);
        });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
