import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  range: [],
};
let URL = endpoints.range;
const rangeSlice = createSlice({
  name: "range",
  initialState,
  reducers: {
    rangeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        range: row,
        
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { rangeSuccess, resetState } = rangeSlice.actions;

export default rangeSlice.reducer;

export const getRange = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL+  '?sort={"insertDateTime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((rangeObject, index) => {
          let rangeData = {
            index: index + 1,
            id: rangeObject.id === null ? "" : rangeObject.id,
            name: rangeObject.name === null ? "" : rangeObject.name,
            maxRange: rangeObject.maxRange === null ? "" : rangeObject.maxRange,
            minRange: rangeObject.minRange === null ? "" : rangeObject.minRange,
            type: rangeObject.type === null ? "" : rangeObject.type ==0 ? "Outlet" :"Stock Holder",
          };
          return rangeData;
        });
        dispatch(rangeSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
