import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { taxJson } from "../../../DynamicFormsJson/MastersJSON/Tax";

class TaxForm extends React.Component {
  
  render() {
    return (
      <>
        <DynamicForm
          formPath={taxJson.formPath}
          screenTitle={taxJson.screenTitle}
          fieldMeta={taxJson.fieldMeta}
          apiBaseURL={taxJson.apiBaseURL}
          showSaveNextBtn={taxJson.showSaveNextBtn}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
 taxList: state.tax,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(TaxForm);
