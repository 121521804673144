import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import RTTable from "../../components/Comman/RT/RTTable";
import { getPaymentRecieptOutletDetail } from "../../Slice/payment.slice";
import { noInternetMsg } from "../../config/messageconstant";
import { paymentFormJSON } from "../../DynamicFormsJson/Transaction/paymentForm";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { apiGet } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
class PaymentListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
      tableData: [],
    };
  
  }

  async componentDidMount() {
    const { showLoador, showNotification, getPaymentRecieptOutletDetail } =
      this.props;
    if (navigator.onLine) {
      if (this.props.params.id) {
        showLoador({ loador: true });
        apiGet({
          url: endpoint.paymentReceiptOutlet + "/" + this.props.params.id,
        }).then(({ data, success }) => {
          console.log(data.data);
          showLoador({ loador: false });
          if (!success) {
          } else {
            this.setState({
              headerData: data.data,
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Beat",
                  value:
                    data.data.outLet !== null &&
                    data.data.outLet.beat.beatName !== null
                      ? data.data.outLet.beat.beatName
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Outlet",
                  value:
                    data.data.outLet.firmName !== null
                      ? data.data.outLet.firmName
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Date",
                  value: data.data.date !== null ? data.data.date : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Amount",
                  value: data.data.amt !== null ? data.data.amt : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "PayType",
                  value:
                    data.data.payType === 0
                      ? "Cash"
                      : data.data.payType === 1
                      ? "Cheque"
                      : "Online",
                },
              ],
            });
            showLoador({ loador: true });
            getPaymentRecieptOutletDetail({
              paymentRecieptOutletId: "4a760510-c8ec-44a3-9954-212afcecae22",
            }).then(({ response, success }) => {
              console.log(response);
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              }
            });
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  render() {
    const { dynamicMasterData, DataToSet, tableData } = this.state;
    const { paymentOutletDetailList } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 0,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            DataToSet={DataToSet}
            dynamicMasterData={dynamicMasterData}
            showSaveBtn={paymentFormJSON.showSaveBtn}
            showTitle={true}
            screenTitle={"Payment"}
            showCancel={paymentFormJSON.showCancel}
            apiBaseURL={paymentFormJSON.apiBaseURL}
            showSaveNextBtn={paymentFormJSON.showSaveNextBtn}
            saveBtnText={"Search"}
            showSaveBtnMain={false}
            padding={true}
            paddingTop={true}
          />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  paymentOutletDetailList: state.payment,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getPaymentRecieptOutletDetail,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PaymentListView);