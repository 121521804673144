import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box, Grid, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { onToggle } from "../../CommonActions/common.slice";
import AutoComplete from "../../components/Comman/AutoComplete";
import MonthCalender from "../../components/MonthCalender";
import { serverMsg } from "../../config/messageconstant";
import * as myConstClass from "../../config/messageconstant";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import "../../CSS/app.min.css";
import "../../CSS/bootstrap.min.css";
import "../../CSS/custom.min.css";
import "../../CSS/mermaid.min.css";
import "../../CSS/slickDashboard.css";
import MainCard from "../DashbourdChanges/MainCard";
import NoDataFound from "../DashbourdChanges/NoDataFound";
import OrdersTable from "../DashbourdChanges/OrdersTable";
import { getMonthYearTargetForSales } from "../../Slice/adminDashboard.slice";
import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import {
  getAreaListFromYearAndMonth,
  getCurrentYearTarget,
  getOrderListFromYearAndMonth,
  getProductListFromYearAndMonth,
  getRegionListFromYearAndMonth,
  getSalesListFromYearAndMonth,
  getTillMonthYearTarget,
  getZoneListFromYearAndMonth,
  getMonthYearTarget,
  getSalesTeamInfo,
} from "../../Slice/adminDashboard.slice";
import { getStockStakeHolderTypeForList } from "../../Slice/stockStakeHolderType.slice";
import LineChart from "../../components/Line Chart";
import { cyanBlue, yellowishBrownColor } from "../../config/ColorObj";
class MainDashboard extends Component {
  toggleDrawer = () => {
    this.props.onToggle();
  };
  state = {
    showFeedback: false,
    showPreview: false,
    date1: new Date(),
    newDate: "",
    currentDate: new Date(),
    curDate: new Date(),
    dob: "",
    dobDay: "",
    dobMonth: "",
    currenDate: "",
    currentMonth: "",
    timeTableList: [],
    subject: "",
    batch: "",
    yearDetail: "",
    semester: "",
    division: "",
    pieChartData: [],
    isLoading: false,
    showLoader: false,
    profile: "",
    nameFirstLetter: "",
    showZone: false,
    showArea: false,
    date: "",
    saleDate: "",
    outletDate: "",
    monthDate: "",
    yearDate: "",
    type: "",
    saleMonth: dayjs(),
    saleYear: "",
    month: "",
    year: "",
    formErrors: {},
    graphList: [],
    monthList: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  };

  async componentDidMount() {
    // const {
    //   getCurrentYearTarget,
    //   getTillMonthYearTarget,
    //   showLoador,
    //   showNotification,
    //   getStockStakeHolderTypeForList,
    //   getRegionListFromYearAndMonth,
    //   getOrderListFromYearAndMonth,
    //   getProductListFromYearAndMonth,
    //   getSalesTeamInfo,
    //   getSalesListFromYearAndMonth,
    //   getMonthYearTarget,
    // } = this.props;
    // if (navigator.onLine) {
    //   showLoador({ loador: true });
    //   await getCurrentYearTarget().then(({ success }) => {
    //     showLoador({ loador: false });
    //     if (!success) {
    //       showNotification({ msg: serverMsg, severity: "error" });
    //     }
    //   });
    //   showLoador({ loador: true });
    //   await getTillMonthYearTarget().then(({ success }) => {
    //     showLoador({ loador: false });
    //     if (!success) {
    //       showNotification({ msg: serverMsg, severity: "error" });
    //     }
    //   });
    //   showLoador({ loador: true });
    //   await getStockStakeHolderTypeForList().then(({ response, success }) => {
    //     showLoador({ loador: false });
    //     if (!success) {
    //       showNotification({ msg: serverMsg, severity: "error" });
    //     } else {
    //       if (response.length !== 0) {
    //         showLoador({ loador: true });
    //         this.setState({
    //           type: response[0].id,
    //         });
    //         getSalesListFromYearAndMonth({
    //           month: dayjs().format("MM"),
    //           year: dayjs().format("YYYY"),
    //           stockStakeHolderTypeId: response[0].id,
    //         }).then(({ success }) => {
    //           showLoador({ loador: false });
    //           if (!success) {
    //             showNotification({ msg: serverMsg, severity: "error" });
    //           }
    //         });
    //       }
    //     }
    //   });
    //   showLoador({ loador: true });
    //   await getRegionListFromYearAndMonth({
    //     month: dayjs().format("MM"),
    //     year: dayjs().format("YYYY"),
    //   }).then(({ success }) => {
    //     showLoador({ loador: false });
    //     if (!success) {
    //       showNotification({ msg: serverMsg, severity: "error" });
    //     }
    //   });
    //   showLoador({ loador: true });
    //   await getOrderListFromYearAndMonth({
    //     month: dayjs().format("MM"),
    //     year: dayjs().format("YYYY"),
    //   }).then(({ success }) => {
    //     showLoador({ loador: false });
    //     if (!success) {
    //       showNotification({ msg: serverMsg, severity: "error" });
    //     }
    //   });
    //   showLoador({ loador: true });
    //   await getProductListFromYearAndMonth({
    //     month: dayjs().format("MM"),
    //     year: dayjs().format("YYYY"),
    //   }).then(({ success }) => {
    //     showLoador({ loador: false });
    //     if (!success) {
    //       showNotification({ msg: serverMsg, severity: "error" });
    //     }
    //   });
    //   showLoador({ loador: true });
    //   await getMonthYearTarget().then(({ success }) => {
    //     showLoador({ loador: false });
    //     if (!success) {
    //       showNotification({ msg: serverMsg, severity: "error" });
    //     }
    //   });
    //   showLoador({ loador: true });
    //   await getSalesTeamInfo().then(({ success }) => {
    //     showLoador({ loador: false });
    //     if (!success) {
    //       showNotification({ msg: serverMsg, severity: "error" });
    //     }
    //   });
    //   this.setState({
    //     saleMonth: dayjs(),
    //     month: dayjs().format("MM"),
    //     year: dayjs().format("YYYY"),
    //   });
    // } else {
    //   showNotification({ msg: noInternetMsg, severity: "error" });
    // }
  }
  viewProfile = () => {
    window.location.replace("/empPreview");
  };
  GoBack = () => {
    this.setState({
      showPreview: false,
    });
  };
  openFeedBack = (e) => {
    e.preventDefault();
    this.setState({
      showFeedback: true,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  regionHandler = (e, regionId) => {
    const { getZoneListFromYearAndMonth, showLoador, showNotification } =
      this.props;
    const { month, year } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getZoneListFromYearAndMonth({
        month: month,
        year: year,
        regionId: regionId,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            showZone: true,
            showArea: false,
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  salesHandler = (e, id) => {
    const { getMonthYearTargetForSales, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getMonthYearTargetForSales({ salesTeamId: id }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  salesTeamGraphHandler = (e, regionId) => {
    const { getZoneListFromYearAndMonth, showLoador, showNotification } =
      this.props;
    const { month, year } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getZoneListFromYearAndMonth({
        month: month,
        year: year,
        regionId: regionId,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            showZone: true,
            showArea: false,
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  zoneHandler = (e, zoneId) => {
    const { getAreaListFromYearAndMonth, showLoador, showNotification } =
      this.props;
    const { month, year } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getAreaListFromYearAndMonth({
        month: month,
        year: year,
        zoneId: zoneId,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            showArea: true,
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onDateChange = (date, name) => {
    this.setState({
      month: date.format("MM"),
      year: date.format("YYYY"),
      saleMonth: date,
      showZone: false,
      showArea: false,
    });
    const { getRegionListFromYearAndMonth, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getRegionListFromYearAndMonth({
        month: date.format("MM"),
        year: date.format("YYYY"),
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onDateChangeSale = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }

    this.setState({
      saleDate: date,
    });
  };

  onDateChangeOutlet = async (date, name) => {
    this.setState({
      [name]: date,
    });
    const { getOrderListFromYearAndMonth, getProductListFromYearAndMonth } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getOrderListFromYearAndMonth({
        month: date.format("MM"),
        year: date.format("YYYY"),
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getProductListFromYearAndMonth({
        month: date.format("MM"),
        year: date.format("YYYY"),
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  handleFormValidation() {
    const { type, saleMonth } = this.state;
    let formErrors = {};
    let formIsValid = true;
    if (type === "" || type === null) {
      formIsValid = false;
      formErrors["typeError"] = myConstClass.stackHolderTypeMsg;
    }
    if (saleMonth === "" || saleMonth === null) {
      formIsValid = false;
      formErrors["dateError"] = myConstClass.dateMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  searchSalesList = () => {
    const { getSalesListFromYearAndMonth } = this.props;
    const { saleMonth, type } = this.state;

    if (this.handleFormValidation()) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        getSalesListFromYearAndMonth({
          month: saleMonth.format("MM"),
          year: saleMonth.format("YYYY"),
          stockStakeHolderTypeId: type,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };

  productColumns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "name",
      title: "Product Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },

    {
      name: "code",
      title: "Product Code",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "actualSale",
      title: "Acheived ",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "contribution",
      title: "% contribution",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
  ];

  outletColumns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "firmName",
      title: "Outlet Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },

    {
      name: "area",
      title: "Area Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "actualSale",
      title: "Acheived ",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "contribution",
      title: "% contribution",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "salePersonName",
      title: "Sales Excutive Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
  ];

  columns = [
    {
      name: "index",
      title: "Sr.No",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "name",
      title: "Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "targetAmt",
      title: "Target Amount",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "actualAmt",
      title: "Acheived",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "contribution",
      title: "% Contribution",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "divisional",
      title: "%(-/+)",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
  ];
  graphList = [{ x: 1, y: 64 }];
  onChangeMonthOrYear = (data, name) => {
    this.setState({
      [name]: data,
    });
  };
  getExtraData = (dtoSalesTeamInformations) => {
    let totalOutletCount = 0,
      zero = 0,
      unvisited = 0,
      totalAmount = 0;
    dtoSalesTeamInformations.map((salesTeam) => {
      totalOutletCount = totalOutletCount + +salesTeam.totalOutletCount;
      zero = zero + +salesTeam.zeroOrderCount;
      unvisited = unvisited + +salesTeam.unvisitedOutletCount;
      totalAmount = totalAmount + +salesTeam.totalAmount;
    });
    return [totalOutletCount, unvisited, zero, totalAmount];
  };
  render() {
    const { dashboardData, dashboardData2, stockStakeHolderTypeList } =
      this.props;
    const { type, showZone, showArea, saleMonth, monthList } = this.state;
    const { typeError, dateError } = this.state.formErrors;
    return (
      <>
        <div id="layout-wrapper">
          {/* ========== App Menu ========== */}

          {/* Left Sidebar End */}
          {/* Vertical Overlay*/}
          <div className="vertical-overlay" />
          {/* ============================================================== */}
          {/* Start right Content here */}
          {/* ============================================================== */}
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                          <div className="birthday-bg">
                            <span className="element">WELCOME BACK&nbsp;</span>
                          </div>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-5">
                            <div className="card card-animate flex-row">
                              <div className="card-body">
                                <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                  Company Target Amount
                                </p>
                                <div className="d-flex align-items-end justify-content-between mt-3">
                                  <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                      <span
                                        className="counter-value"
                                        data-target="559.25"
                                      >
                                        {(+dashboardData?.currentYearTarget
                                          .currentTargetAmt).toFixed(2)}
                                      </span>
                                    </h4>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body">
                                <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                  Company Acheived Amount
                                </p>
                                <div className="d-flex align-items-end justify-content-between mt-3">
                                  <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                      <span
                                        className="counter-value"
                                        data-target="559.25"
                                      >
                                        {(+dashboardData?.currentYearTarget
                                          .currentAchievedAmt).toFixed(2)}
                                      </span>
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div className="card">
                              <div className="card-header align-items-center d-flex">
                                <h4 className="text-uppercase card-title mb-0 flex-grow-1">
                                  Till current month
                                </h4>
                              </div>
                            </div>
                            <div className="card card-animate flex-row">
                              <div className="card-body">
                                <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                  Company Target Amount
                                </p>
                                <div className="d-flex align-items-end justify-content-between mt-3">
                                  <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                      <span
                                        className="counter-value"
                                        data-target="559.25"
                                      >
                                        {(+dashboardData?.tillMonthYearTarget
                                          .targetAmt).toFixed(2)}
                                      </span>
                                    </h4>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body">
                                <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                  Company Acheived Amount
                                </p>
                                <div className="d-flex align-items-end justify-content-between mt-3">
                                  <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                      <span
                                        className="counter-value"
                                        data-target="559.25"
                                      >
                                        {(+dashboardData?.tillMonthYearTarget
                                          .achivedAmt).toFixed(2)}
                                      </span>
                                    </h4>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body">
                                <div className="d-flex align-items-end justify-content-between mt-3">
                                  <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                      <span
                                        className="counter-value"
                                        data-target="559.25"
                                      >
                                        {dashboardData?.tillMonthYearTarget
                                          .contribution >= 100 ? (
                                          <div>
                                            {(+dashboardData
                                              ?.tillMonthYearTarget
                                              .contribution).toFixed(2) + " %"}
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              color: yellowishBrownColor,
                                            }}
                                          >
                                            {"- " +
                                              (+dashboardData
                                                ?.tillMonthYearTarget
                                                .contribution).toFixed(2) +
                                              " %"}
                                          </div>
                                        )}
                                      </span>
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <LineChart
                  options={{
                    animationEnabled: true,
                    exportEnabled: true,
                    theme: "light2",
                    axisY: {
                      title: "Target Amount",
                      includeZero: false,
                      suffix: "",
                    },
                    axisX: {
                      title: "Month of Year",
                      prefix: "",
                      interval: 1,
                    },
                    data: [
                      {
                        type: "line",
                        toolTipContent: "Target Amount of {label}: {y}",

                        dataPoints: dashboardData.monthYearTarget.map(
                          (monthWiseData) => {
                            return {
                              label: monthList[monthWiseData.month - 1],

                              y: monthWiseData.targetAmt,
                            };
                          }
                        ),
                      },
                      {
                        type: "line",
                        toolTipContent: "Achived Amount of {label}: {y}",

                        dataPoints: dashboardData.monthYearTarget.map(
                          (monthWiseData) => {
                            return {
                              label: monthList[monthWiseData.month - 1],

                              y: monthWiseData.achivedAmt,
                            };
                          }
                        ),
                      },
                    ],
                  }}
                />
                <div className="col-lg-5">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <div className="col-lg-12">
                        <MonthCalender
                          title="Year"
                          name="saleMonth"
                          value={saleMonth}
                          onChange={this.onDateChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="text-uppercase card-title mb-0 flex-grow-1">
                        Region wise sales
                      </h4>
                    </div>
                    <div className="card-body">
                      <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" sx={{ width: "2%" }}>
                                Sr.No
                              </TableCell>
                              <TableCell align="center" sx={{ width: "8%" }}>
                                Region Name
                              </TableCell>
                              <TableCell align="center" sx={{ width: "5%" }}>
                                Acheived
                              </TableCell>
                              <TableCell align="center" sx={{ width: "5%" }}>
                                % contribution in total
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dashboardData.regionListFromYearAndMonth.map(
                              (regionObject, index) => {
                                return (
                                  <TableRow>
                                    <TableCell align="center">
                                      {index + 1}
                                    </TableCell>
                                    <TableCell width="2%">
                                      <div
                                        onClick={(e) =>
                                          this.regionHandler(e, regionObject.id)
                                        }
                                        style={{
                                          color: cyanBlue,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {regionObject.name}
                                      </div>
                                    </TableCell>
                                    <TableCell align="right">
                                      {regionObject.total}
                                    </TableCell>
                                    <TableCell align="right">
                                      {regionObject.contribution}
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )}
                            <TableRow>
                              <TableCell align="center" width="2%" colSpan={2}>
                                Total
                              </TableCell>
                              <TableCell align="right">
                                {dashboardData.regionListFromYearAndMonthTotal}
                              </TableCell>
                              <TableCell align="center">-</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
                {showZone ? (
                  <>
                    <div className="col-lg-6">
                      <div className="card">
                        <div className="card-header align-items-center d-flex">
                          <h4 className="text-uppercase card-title mb-0 flex-grow-1">
                            Zone wise sales
                          </h4>
                        </div>
                        <div className="card-body">
                          <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    sx={{ width: "2%" }}
                                  >
                                    Sr.No
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{ width: "8%" }}
                                  >
                                    Zone Name
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{ width: "5%" }}
                                  >
                                    Acheived
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{ width: "5%" }}
                                  >
                                    % contribution in total
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {dashboardData.zoneListFromYearAndMonth.map(
                                  (zoneObject, index) => {
                                    return (
                                      <TableRow>
                                        <TableCell align="center">
                                          {" "}
                                          {index + 1}
                                        </TableCell>
                                        <TableCell width="2%">
                                          <div
                                            onClick={(e) =>
                                              this.zoneHandler(e, zoneObject.id)
                                            }
                                            style={{
                                              color: cyanBlue,
                                              cursor: "pointer",
                                            }}
                                          >
                                            {zoneObject.name}
                                          </div>
                                        </TableCell>
                                        <TableCell align="right">
                                          {zoneObject.total}
                                        </TableCell>
                                        <TableCell align="right">
                                          {zoneObject.contribution == "NaN"
                                            ? "-"
                                            : zoneObject.contribution}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  }
                                )}
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    width="2%"
                                    colSpan={2}
                                  >
                                    Total
                                  </TableCell>

                                  <TableCell align="right">
                                    {
                                      dashboardData.zoneListFromYearAndMonthTotal
                                    }
                                  </TableCell>
                                  <TableCell align="center">-</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="col-lg-6"></div>
                )}
              </div>
              <div className="row">
                {showArea ? (
                  <>
                    <div className="col-lg-6">
                      <div className="card">
                        <div className="card-header align-items-center d-flex">
                          <h4 className="text-uppercase card-title mb-0 flex-grow-1">
                            Area wise sales
                          </h4>
                        </div>
                        <div className="card-body">
                          <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    sx={{ width: "2%" }}
                                  >
                                    Sr.No
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{ width: "8%" }}
                                  >
                                    Area Name
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{ width: "5%" }}
                                  >
                                    Acheived
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{ width: "5%" }}
                                  >
                                    % contribution in total
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {dashboardData.areaListFromYearAndMonth.map(
                                  (areaObject, index) => {
                                    return (
                                      <TableRow>
                                        <TableCell align="center">
                                          {index + 1}
                                        </TableCell>
                                        <TableCell width="2%">
                                          {areaObject.name}
                                        </TableCell>
                                        <TableCell align="right">
                                          {areaObject.total}
                                        </TableCell>
                                        <TableCell align="right">
                                          {areaObject.contribution == "NaN"
                                            ? "-"
                                            : areaObject.contribution}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  }
                                )}
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    width="2%"
                                    colSpan={2}
                                  >
                                    Total
                                  </TableCell>
                                  <TableCell align="right">
                                    {
                                      dashboardData.areaListFromYearAndMonthTotal
                                    }
                                  </TableCell>
                                  <TableCell align="center">-</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="col-lg-6"></div>
                )}
              </div>

              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header align-items-center d-flex">
                    <div className="col-lg-5">
                      <AutoComplete
                        keyColName={"id"}
                        placeHolder="Stock Stake Holder Type"
                        value={type}
                        name={"type"}
                        options={
                          stockStakeHolderTypeList?.stockStakeHolderTypeList
                        }
                        onChange={this.ChangeHandlerSearch}
                        isError={typeError ? true : false}
                        errorText={typeError ? typeError : " "}
                      />
                    </div>
                    <div className="col-lg-5">
                      <MonthCalender
                        title="Year"
                        name="saleMonth"
                        value={saleMonth}
                        onChange={this.onChangeMonthOrYear}
                        isError={dateError ? true : false}
                        errorText={dateError ? dateError : " "}
                      />
                    </div>
                    <button
                      onClick={this.searchSalesList}
                      type="button"
                      className="btn btn-primary waves-effect waves-light btn-sm"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="text-uppercase card-title mb-0 flex-grow-1">
                        Sales
                      </h4>
                    </div>

                    <div className="card-body">
                      <div>
                        <LandingScreenHeader
                          screenTitle={""}
                          showTable={true}
                          showBackButton={false}
                          showAddButton={false}
                          showSearch={true}
                          showSearchBox={true}
                          showPdfDownload={false}
                          showExcelDownload={false}
                        />
                      </div>
                      <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" sx={{ width: "2%" }}>
                                Sr.No
                              </TableCell>
                              <TableCell align="center" sx={{ width: "8%" }}>
                                Name
                              </TableCell>
                              <TableCell align="center" sx={{ width: "5%" }}>
                                Target Amount
                              </TableCell>
                              <TableCell align="center" sx={{ width: "5%" }}>
                                Acheived
                              </TableCell>
                              <TableCell align="center" sx={{ width: "5%" }}>
                                % Contribution
                              </TableCell>
                              <TableCell align="center" sx={{ width: "5%" }}>
                                % count (+ve, -ve)
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dashboardData.salesListFromYearAndMonth.map(
                              (salesObject, index) => {
                                return (
                                  <TableRow>
                                    <TableCell align="center">
                                      {index + 1}
                                    </TableCell>
                                    <TableCell width="2%">
                                      <div
                                        onClick={(e) =>
                                          this.salesHandler(e, salesObject.id)
                                        }
                                        style={{
                                          color: cyanBlue,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {salesObject.name}
                                      </div>
                                    </TableCell>
                                    <TableCell align="right">
                                      {salesObject.targetAmt}
                                    </TableCell>
                                    <TableCell align="right">
                                      {salesObject.actualAmt}
                                    </TableCell>
                                    <TableCell align="right">
                                      {salesObject.contribution}
                                    </TableCell>
                                    <TableCell align="right">
                                      {salesObject.divisional}
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )}
                            <TableRow>
                              <TableCell align="center" width="2%" colSpan={3}>
                                Total
                              </TableCell>
                              <TableCell align="right">
                                {dashboardData.salesListFromYearAndMonthTotal}
                              </TableCell>
                              <TableCell align="center">-</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </div>
              <LineChart
                options={{
                  animationEnabled: true,
                  exportEnabled: true,
                  theme: "light2",
                  axisY: {
                    title: "Target Amount",
                    includeZero: false,
                    suffix: "",
                  },
                  axisX: {
                    title: "Month of Year",
                    prefix: "",
                    interval: 1,
                  },
                  data: [
                    {
                      type: "line",
                      toolTipContent: "Target Amount of {label}: {y}",

                      dataPoints: dashboardData2.monthYearTargetForSale.map(
                        (monthWiseData) => {
                          return {
                            label: monthList[monthWiseData.month - 1],

                            y: monthWiseData.targetAmt,
                          };
                        }
                      ),
                    },
                    {
                      type: "line",
                      toolTipContent: "Achived Amount of {label}: {y}",

                      dataPoints: dashboardData2.monthYearTargetForSale.map(
                        (monthWiseData) => {
                          return {
                            label: monthList[monthWiseData.month - 1],

                            y: monthWiseData.achivedAmt,
                          };
                        }
                      ),
                    },
                  ],
                }}
              />

              <div className="col-lg-5">
                <div className="card">
                  <div className="card-header align-items-center d-flex">
                    <div className="col-lg-12">
                      <MonthCalender
                        title="Year"
                        name="saleMonth"
                        value={saleMonth}
                        onChange={this.onDateChangeOutlet}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="text-uppercase card-title mb-0 flex-grow-1">
                        Top 20 outlet
                      </h4>
                    </div>
                    <div className="card-body">
                      <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" sx={{ width: "2%" }}>
                                Sr.No
                              </TableCell>
                              <TableCell align="center" sx={{ width: "15%" }}>
                                Outlet Name
                              </TableCell>
                              <TableCell align="center" sx={{ width: "8%" }}>
                                Area Name
                              </TableCell>
                              <TableCell align="center" sx={{ width: "20%" }}>
                                Sales Excutive Name
                              </TableCell>
                              <TableCell align="center" sx={{ width: "5%" }}>
                                Acheived
                              </TableCell>
                              <TableCell align="center" sx={{ width: "5%" }}>
                                % Contribution
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dashboardData.outletListFromYearAndMonth.map(
                              (outletObject, index) => {
                                return (
                                  <TableRow>
                                    <TableCell align="center">
                                      {index + 1}
                                    </TableCell>
                                    <TableCell width="2%">
                                      <div
                                        onClick={(e) =>
                                          this.regionHandler(e, outletObject.id)
                                        }
                                        style={{
                                          color: cyanBlue,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {outletObject.firmName}
                                      </div>
                                    </TableCell>
                                    <TableCell align="left">
                                      {outletObject.area}
                                    </TableCell>
                                    <TableCell align="left">
                                      {outletObject.salePersonName}
                                    </TableCell>
                                    <TableCell align="right">
                                      {outletObject.actualSale}
                                    </TableCell>
                                    <TableCell align="right">
                                      {outletObject.contribution}
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )}
                            <TableRow>
                              <TableCell align="center" width="2%" colSpan={4}>
                                Total
                              </TableCell>
                              <TableCell align="right">
                                {dashboardData.outletListFromYearAndMonthTotal}
                              </TableCell>
                              <TableCell align="center">-</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="text-uppercase card-title mb-0 flex-grow-1">
                        Top 20 Product
                      </h4>
                    </div>
                    <div className="card-body">
                      <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" sx={{ width: "2%" }}>
                                Sr.No
                              </TableCell>
                              <TableCell align="center" sx={{ width: "15%" }}>
                                Product Name
                              </TableCell>
                              <TableCell align="center" sx={{ width: "8%" }}>
                                Product Code
                              </TableCell>
                              <TableCell align="center" sx={{ width: "5%" }}>
                                Acheived
                              </TableCell>
                              <TableCell align="center" sx={{ width: "5%" }}>
                                % Contribution
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dashboardData.productListFromYearAndMonth.map(
                              (productObject, index) => {
                                return (
                                  <TableRow>
                                    <TableCell align="center">
                                      {index + 1}
                                    </TableCell>
                                    <TableCell width="2%">
                                      <div
                                        onClick={(e) =>
                                          this.regionHandler(
                                            e,
                                            productObject.id
                                          )
                                        }
                                        style={{
                                          color: cyanBlue,
                                          cursor: "pointer",
                                        }}
                                      >
                                        {productObject.name}
                                      </div>
                                    </TableCell>
                                    <TableCell align="left">
                                      {productObject.code}
                                    </TableCell>
                                    <TableCell align="right">
                                      {productObject.actualSale}
                                    </TableCell>
                                    <TableCell align="right">
                                      {productObject.contribution}
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )}
                            <TableRow>
                              <TableCell align="center" width="2%" colSpan={3}>
                                Total
                              </TableCell>
                              <TableCell align="right">
                                {dashboardData.productListFromYearAndMonthTotal}
                              </TableCell>
                              <TableCell align="center">-</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </div>
              <Grid item xs={12} md={12} lg={12}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography variant="h5">Sales Team List </Typography>
                  </Grid>
                  <Grid item />
                </Grid>
                {dashboardData.salesInfo.length != 0 ? (
                  <OrdersTable
                    rows={dashboardData.salesInfo.map((sales) => {
                      const seriesData = [
                        {
                          label:
                            sales.dtoDateWiseTotalAmount != null
                              ? sales.dtoDateWiseTotalAmount.map((rowData) => {
                                  return rowData.date;
                                })
                              : [],
                          name: "Amount",
                          data:
                            sales.dtoDateWiseTotalAmount != null
                              ? sales.dtoDateWiseTotalAmount.map((rowData) => {
                                  return rowData.totalAmount;
                                })
                              : [],
                        },
                      ];
                      return {
                        ...sales,
                        salesTeam:
                          sales.salesTeam != null ? sales.salesTeam.name : "",
                        salesTeamId:
                          sales.salesTeam != null ? sales.salesTeam.id : "",
                        series: seriesData,
                        options: {
                          chart: {
                            type: "line",
                            width: 100,
                            height: 35,
                            sparkline: {
                              enabled: true,
                            },
                          },

                          stroke: {
                            curve: "straight",
                          },
                          tooltip: {
                            custom: function ({
                              series,
                              seriesIndex,
                              dataPointIndex,
                              w,
                            }) {
                              return (
                                '<div class="arrow_box">' +
                                "<span><b>" +
                                seriesData[0].label[dataPointIndex] +
                                " - " +
                                series[seriesIndex][dataPointIndex] +
                                "  ₹" +
                                "</b></span>" +
                                "</div>"
                              );
                            },
                          },
                          xaxis: {
                            categories:
                              sales.dtoDateWiseTotalAmount != null
                                ? sales.dtoDateWiseTotalAmount.map(
                                    (rowData) => {
                                      return rowData.date;
                                    }
                                  )
                                : [],
                          },
                        },
                      };
                    })}
                    extraData={this.getExtraData(dashboardData.salesInfo)}
                  />
                ) : (
                  <NoDataFound />
                )}
              </Grid>
              <footer className="footer">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-6">2022 © Byte Elephants. </div>
                    <div className="col-sm-6">
                      <div className="text-sm-end d-none d-sm-block">
                        {" "}
                        Designed &amp; Developed by Byte Elephants Technologies
                        PVT LTD{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
  dashboardData: state.adminDashboard,
  dashboardData2: state.adminDashboard,
  stockStakeHolderTypeList: state.stockStakeHolderType,
});

const mapDispatchToProps = {
  onToggle,
  showLoador,
  getCurrentYearTarget,
  getTillMonthYearTarget,
  showNotification,
  getRegionListFromYearAndMonth,
  getZoneListFromYearAndMonth,
  getAreaListFromYearAndMonth,
  getStockStakeHolderTypeForList,
  getSalesListFromYearAndMonth,
  getOrderListFromYearAndMonth,
  getProductListFromYearAndMonth,
  getMonthYearTarget,
  getSalesTeamInfo,
  getMonthYearTargetForSales,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainDashboard);
