import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  outletOrderDetail: [],
  outletCloseOrderDetail: [],
  pendingOrderByMonthYear: [],
};
let URL = endpoints.outletOrderDetail;
const outletOrderDetailSlice = createSlice({
  name: "outletOrderDetail",
  initialState,
  reducers: {
    outletOrderDetailSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        outletOrderDetail: row,
        isFetch: true,
      };
    },
    outletCloseOrderDetailSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        outletCloseOrderDetail: row,
        isFetch: true,
      };
    },

    pendingOrderByMonthYearSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        pendingOrderByMonthYear: row,
        isFetch: true,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  outletOrderDetailSuccess,
  outletCloseOrderDetailSuccess,
  pendingOrderByMonthYearSuccess,
  resetState,
} = outletOrderDetailSlice.actions;

export default outletOrderDetailSlice.reducer;
export const getTodaysOutletOrderDetails = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/todays-order",
    }).then(({ data, success }) => {
      console.log(data.data[0]);
      if (success) {
        const row = data.data.map((outletOrderDetailObject, index) => {
          let outletOrderDetailData = {
            index: index + 1,
            id:
              outletOrderDetailObject.id === null
                ? ""
                : outletOrderDetailObject.id,
            firmName:
              outletOrderDetailObject.outletOrderHerader === null
                ? ""
                : outletOrderDetailObject.outletOrderHerader.outLet.firmName,
            beatName:
              outletOrderDetailObject.outletOrderHerader === null
                ? ""
                : outletOrderDetailObject.outletOrderHerader.outLet.beat
                    .beatName,
            productName:
              outletOrderDetailObject.product === null
                ? ""
                : outletOrderDetailObject.product.name,
            outletUom:
              outletOrderDetailObject.product === null
                ? ""
                : outletOrderDetailObject.product.outletUom.name,
            rate:
              outletOrderDetailObject.rate === null
                ? ""
                : outletOrderDetailObject.rate.toFixed(2),
            secondaryClosingQty: 0,
            productStock: 0,
            billQty:
              outletOrderDetailObject.qty === null
                ? ""
                : outletOrderDetailObject.qty,
            orderQty:
              outletOrderDetailObject.qty === null
                ? ""
                : outletOrderDetailObject.qty,

            freeQty: 0,
            distcountAmt: 0,
            orderNo:
              outletOrderDetailObject.outletOrderHerader === null
                ? ""
                : outletOrderDetailObject.outletOrderHerader.orderNo,
            orderDate:
              outletOrderDetailObject.outletOrderHerader === null
                ? ""
                : outletOrderDetailObject.outletOrderHerader.orderDate,
            orderTime:
              outletOrderDetailObject.status === null
                ? ""
                : outletOrderDetailObject.status + " Day's Ago",
            taxableAmt:
              outletOrderDetailObject === null
                ? ""
                : outletOrderDetailObject.taxableAmt.toFixed(2),
            cgstAmt:
              outletOrderDetailObject.outletOrderHerader === null
                ? ""
                : outletOrderDetailObject.cgstAmt.toFixed(2),
            sgstAmt:
              outletOrderDetailObject.outletOrderHerader === null
                ? ""
                : outletOrderDetailObject.sgstAmt.toFixed(2),
            uomConversionRate:
              outletOrderDetailObject.product === null
                ? ""
                : +outletOrderDetailObject.product.uomConversionValue.toFixed(
                    2
                  ),
            total: (
              +outletOrderDetailObject.qty * +outletOrderDetailObject.rate
            ).toFixed(2),
            outlet:
              outletOrderDetailObject.outletOrderHerader === null
                ? ""
                : outletOrderDetailObject.outletOrderHerader.outLet,
            product:
              outletOrderDetailObject.product === null
                ? ""
                : outletOrderDetailObject.product,
            qtyError: 0,
            freeQtyError: 0,
            discountError: 0,
            conversionValueErr: 0,
            stockQuantityError: 0,
            checked: false,
            disabled: false,
          };

          return outletOrderDetailData;
        });
        dispatch(outletOrderDetailSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getOutletOrderDetail =
  ({ outletIds, fromDate, toDate }) =>
  async (dispatch) => {
    let json = {
      outletIds: outletIds,
      fromDate: fromDate,
      toDate: toDate,
    };
    try {
      const response = apiPost({
        url: URL + "/by-outlets-and-date",
        postBody: json,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((outletOrderDetailObject, index) => {
            let outletOrderDetailData = {
              index: index + 1,
              id:
                outletOrderDetailObject.id === null
                  ? ""
                  : outletOrderDetailObject.id,
              firmName:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.outLet.firmName,
              beatName:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.outLet.beat
                      .beatName,
              productName:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product.name,
              outletUom:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product.outletUom.name,
              rate:
                outletOrderDetailObject.rate === null
                  ? ""
                  : outletOrderDetailObject.rate.toFixed(2),
              secondaryClosingQty: 0,
              productStock: 0,
              billQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,
              orderQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,

              freeQty: 0,
              distcountAmt: 0,
              orderNo:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.orderNo,
              orderDate:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.orderDate,
              orderTime:
                outletOrderDetailObject.status === null
                  ? ""
                  : outletOrderDetailObject.status + " Day's Ago",
              taxableAmt:
                outletOrderDetailObject === null
                  ? ""
                  : outletOrderDetailObject.taxableAmt.toFixed(2),
              cgstAmt:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.cgstAmt.toFixed(2),
              sgstAmt:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.sgstAmt.toFixed(2),
              uomConversionRate:
                outletOrderDetailObject.product === null
                  ? ""
                  : +outletOrderDetailObject.product.uomConversionValue.toFixed(
                      2
                    ),
              total: (
                +outletOrderDetailObject.qty * +outletOrderDetailObject.rate
              ).toFixed(2),
              outlet:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.outLet,
              product:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product,

              insertDateTime:
                outletOrderDetailObject.insertDateTime === null
                  ? ""
                  : outletOrderDetailObject.insertDateTime,
              inserteUserId:
                outletOrderDetailObject.inserteUserId === null
                  ? ""
                  : outletOrderDetailObject.inserteUserId,
              qtyError: 0,
              freeQtyError: 0,
              discountError: 0,
              conversionValueErr: 0,
              stockQuantityError: 0,
              checked: false,
              disabled: false,
            };

            return outletOrderDetailData;
          });
          dispatch(outletOrderDetailSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      console.log(e);
      return { response: [], success: false };
    }
  };

export const getOutletOrderDetailUpdated =
  ({ outletIds, fromDate, toDate }) =>
  async (dispatch) => {
    let json = {
      outletIds: outletIds,
      fromDate: fromDate,
      toDate: toDate,
    };
    try {
      const response = apiPost({
        url: URL + "/get-by-outlets-and-date",
        postBody: json,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((outletOrderDetailObject, index) => {
            let outletOrderDetailData = {
              index: index + 1,
              id:
                outletOrderDetailObject.id === null
                  ? ""
                  : outletOrderDetailObject.id,
              firmName:
                outletOrderDetailObject.outletFirmName === null
                  ? ""
                  : outletOrderDetailObject.outletFirmName,
              beatName:
                outletOrderDetailObject.beatName === null
                  ? ""
                  : outletOrderDetailObject.beatName,
              productName:
                outletOrderDetailObject.productName === null
                  ? ""
                  : outletOrderDetailObject.productName,
              outletUom:
                outletOrderDetailObject.uomName === null
                  ? ""
                  : outletOrderDetailObject.uomName,
              rate:
                outletOrderDetailObject.rate === null
                  ? ""
                  : outletOrderDetailObject.rate.toFixed(2),
              secondaryClosingQty: 0,
              productStock: 0,
              billQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,
              orderQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,

              freeQty: 0,
              distcountAmt: 0,
              orderNo:
                outletOrderDetailObject.orderNo === null
                  ? ""
                  : outletOrderDetailObject.orderNo,
              orderDate:
                outletOrderDetailObject.orderDate === null
                  ? ""
                  : outletOrderDetailObject.orderDate,
              orderTime:
                outletOrderDetailObject.status === null
                  ? ""
                  : outletOrderDetailObject.status + " Day's Ago",
              taxableAmt:
                outletOrderDetailObject === null
                  ? ""
                  : outletOrderDetailObject.taxableAmt.toFixed(2),
              cgstAmt:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.cgstAmt.toFixed(2),
              sgstAmt:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.sgstAmt.toFixed(2),
              // uomConversionRate:
              //   outletOrderDetailObject.product === null
              //     ? ""
              //     : +outletOrderDetailObject.product.uomConversionValue.toFixed(
              //         2
              //       ),
              total: (
                +outletOrderDetailObject.qty * +outletOrderDetailObject.rate
              ).toFixed(2),
              outlet:
                outletOrderDetailObject.outletId === null
                  ? ""
                  : outletOrderDetailObject.outletId,
              product:
                outletOrderDetailObject.productId === null
                  ? ""
                  : outletOrderDetailObject.productId,

              insertDateTime:
                outletOrderDetailObject.insertDateTime === null
                  ? ""
                  : outletOrderDetailObject.insertDateTime,
              inserteUserId:
                outletOrderDetailObject.inserteUserId === null
                  ? ""
                  : outletOrderDetailObject.inserteUserId,
              qtyError: 0,
              freeQtyError: 0,
              discountError: 0,
              conversionValueErr: 0,
              stockQuantityError: 0,
              checked: false,
              disabled: false,
            };

            return outletOrderDetailData;
          });
          dispatch(outletOrderDetailSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      console.log(e);
      return { response: [], success: false };
    }
  };

export const getOutletOrderDetailForGet =
  ({ outletIds, fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-outlets-and-date?outletIds=" +
          outletIds +
          "&fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((outletOrderDetailObject, index) => {
            let outletOrderDetailData = {
              index: index + 1,
              id:
                outletOrderDetailObject.id === null
                  ? ""
                  : outletOrderDetailObject.id,
              firmName:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.outLet.firmName,
              productName:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product.name,
              outletUom:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product.outletUom.name,
              rate:
                outletOrderDetailObject.rate === null
                  ? ""
                  : outletOrderDetailObject.rate.toFixed(2),
              secondaryClosingQty: 0,
              productStock: 0,
              billQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,
              orderQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,

              freeQty: 0,
              distcountAmt: 0,
              orderNo:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.orderNo,
              orderDate:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.orderDate,
              orderTime:
                outletOrderDetailObject.status === null
                  ? ""
                  : outletOrderDetailObject.status + " Day's Ago",
              taxableAmt:
                outletOrderDetailObject === null
                  ? ""
                  : outletOrderDetailObject.taxableAmt.toFixed(2),
              cgstAmt:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.cgstAmt.toFixed(2),
              sgstAmt:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.sgstAmt.toFixed(2),
              uomConversionRate:
                outletOrderDetailObject.product === null
                  ? ""
                  : +outletOrderDetailObject.product.uomConversionValue.toFixed(
                      2
                    ),
              total: (
                +outletOrderDetailObject.qty * +outletOrderDetailObject.rate
              ).toFixed(2),
              outlet:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.outLet,
              product:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product,
              qtyError: 0,
              freeQtyError: 0,
              discountError: 0,
              conversionValueErr: 0,
              stockQuantityError: 0,
              checked: false,
              disabled: false,
            };

            return outletOrderDetailData;
          });
          dispatch(outletOrderDetailSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getOutletOrderDetailLatest = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/pending-order",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((outletOrderDetailObject, index) => {
          let outletOrderDetailData = {
            index: index + 1,
            id:
              outletOrderDetailObject.id === null
                ? ""
                : outletOrderDetailObject.id,
            firmName:
              outletOrderDetailObject.outletOrderHerader === null
                ? ""
                : outletOrderDetailObject.outletOrderHerader.outLet.firmName,
            productName:
              outletOrderDetailObject.product === null
                ? ""
                : outletOrderDetailObject.product.name,
            outletUom:
              outletOrderDetailObject.product === null
                ? ""
                : outletOrderDetailObject.product.outletUom.name,
            rate:
              outletOrderDetailObject.rate === null
                ? ""
                : outletOrderDetailObject.rate.toFixed(2),
            secondaryClosingQty: 0,
            productStock: 0,
            billQty:
              outletOrderDetailObject.qty === null
                ? ""
                : outletOrderDetailObject.qty,

            freeQty: 0,
            distcountAmt: 0,
            orderNo:
              outletOrderDetailObject.outletOrderHerader === null
                ? ""
                : outletOrderDetailObject.outletOrderHerader.orderNo,
            orderDate:
              outletOrderDetailObject.outletOrderHerader === null
                ? ""
                : outletOrderDetailObject.outletOrderHerader.orderDate,
            orderQty:
              outletOrderDetailObject.qty === null
                ? ""
                : outletOrderDetailObject.qty,

            orderTime:
              outletOrderDetailObject.status === null
                ? ""
                : outletOrderDetailObject.status + " Day's Ago",
            taxableAmt:
              outletOrderDetailObject === null
                ? ""
                : outletOrderDetailObject.taxableAmt.toFixed(2),
            cgstAmt:
              outletOrderDetailObject.outletOrderHerader === null
                ? ""
                : outletOrderDetailObject.cgstAmt.toFixed(2),
            sgstAmt:
              outletOrderDetailObject.outletOrderHerader === null
                ? ""
                : outletOrderDetailObject.sgstAmt.toFixed(2),
            uomConversionRate:
              outletOrderDetailObject.product === null
                ? ""
                : +outletOrderDetailObject.product.uomConversionValue.toFixed(
                    2
                  ),
            total: (
              +outletOrderDetailObject.qty * +outletOrderDetailObject.rate
            ).toFixed(2),
            outlet:
              outletOrderDetailObject.outletOrderHerader === null
                ? ""
                : outletOrderDetailObject.outletOrderHerader.outLet,
            product:
              outletOrderDetailObject.product === null
                ? ""
                : outletOrderDetailObject.product,
            qtyError: 0,
            freeQtyError: 0,
            discountError: 0,
            conversionValueErr: 0,
            stockQuantityError: 0,
            checked: false,
            disabled: false,
          };

          return outletOrderDetailData;
        });
        dispatch(outletOrderDetailSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getOutletLostOrderDetails =
  ({ fromdate, todate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/lost-order?fromDate=" + fromdate + "&toDate=" + todate,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((outletOrderDetailObject, index) => {
            let outletOrderDetailData = {
              index: index + 1,
              id:
                outletOrderDetailObject.id === null
                  ? ""
                  : outletOrderDetailObject.id,
              firmName:
                outletOrderDetailObject.outLet.firmName === null
                  ? ""
                  : outletOrderDetailObject.outLet.firmName,
              salesTeamName:
                outletOrderDetailObject.salesTeamName === null
                  ? ""
                  : outletOrderDetailObject.salesTeamName,
              productName:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product.name,
              outletUom:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product.outletUom.name,
              rate:
                outletOrderDetailObject.rate === null
                  ? ""
                  : outletOrderDetailObject.rate.toFixed(2),
              secondaryClosingQty: 0,
              productStock: 0,
              billQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,

              freeQty: 0,
              distcountAmt: 0,
              orderNo:
                outletOrderDetailObject.orderNo === null
                  ? ""
                  : outletOrderDetailObject.orderNo,
              orderDate:
                outletOrderDetailObject.orderDate === null
                  ? ""
                  : outletOrderDetailObject.orderDate,
              orderQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,
              orderTime:
                outletOrderDetailObject.status === null
                  ? ""
                  : outletOrderDetailObject.status + " Day's Ago",
              taxableAmt:
                outletOrderDetailObject === null
                  ? ""
                  : outletOrderDetailObject.taxableAmt.toFixed(2),
              cgstAmt:
                outletOrderDetailObject.cgstAmt === null
                  ? ""
                  : outletOrderDetailObject.cgstAmt.toFixed(2),
              sgstAmt:
                outletOrderDetailObject.sgstAmt === null
                  ? ""
                  : outletOrderDetailObject.sgstAmt.toFixed(2),
              uomConversionRate:
                outletOrderDetailObject.product === null
                  ? ""
                  : +outletOrderDetailObject.product.uomConversionValue.toFixed(
                      2
                    ),
              total: (
                +outletOrderDetailObject.qty * +outletOrderDetailObject.rate
              ).toFixed(2),
              outlet:
                outletOrderDetailObject.outLet === null
                  ? ""
                  : outletOrderDetailObject.outLet,
              product:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product,
              qtyError: 0,
              freeQtyError: 0,
              discountError: 0,
              conversionValueErr: 0,
              stockQuantityError: 0,
              checked: false,
              disabled: false,
            };

            return outletOrderDetailData;
          });
          dispatch(outletCloseOrderDetailSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getOutletLostOrderDetailsNew =
  ({ fromdate, todate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/get-lost-order?fromDate=" + fromdate + "&toDate=" + todate,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((outletOrderDetailObject, index) => {
            let outletOrderDetailData = {
              index: index + 1,
              id:
                outletOrderDetailObject.id === null
                  ? ""
                  : outletOrderDetailObject.id,
              firmName:
                outletOrderDetailObject.outLetFirmName === null
                  ? ""
                  : outletOrderDetailObject.outLetFirmName,
              salesTeamName:
                outletOrderDetailObject.salesTeamName === null
                  ? ""
                  : outletOrderDetailObject.salesTeamName,
              productName:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product.productName,
              outletUom:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product.outletUomName,
              rate:
                outletOrderDetailObject.rate === null
                  ? ""
                  : outletOrderDetailObject.rate.toFixed(2),
              secondaryClosingQty: 0,
              productStock: 0,
              billQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,

              freeQty: 0,
              distcountAmt: 0,
              orderNo:
                outletOrderDetailObject.orderNo === null
                  ? ""
                  : outletOrderDetailObject.orderNo,
              orderDate:
                outletOrderDetailObject.orderDate === null
                  ? ""
                  : outletOrderDetailObject.orderDate,
              orderQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,
              totalQty:
                outletOrderDetailObject.totalQty === null
                  ? ""
                  : outletOrderDetailObject.totalQty,
              orderTime:
                outletOrderDetailObject.status === null
                  ? ""
                  : outletOrderDetailObject.status + " Day's Ago",
              taxableAmt:
                outletOrderDetailObject === null
                  ? ""
                  : outletOrderDetailObject.taxableAmt.toFixed(2),
              cgstAmt:
                outletOrderDetailObject.cgstAmt === null
                  ? ""
                  : outletOrderDetailObject.cgstAmt.toFixed(2),
              sgstAmt:
                outletOrderDetailObject.sgstAmt === null
                  ? ""
                  : outletOrderDetailObject.sgstAmt.toFixed(2),
              uomConversionRate:
                outletOrderDetailObject.product === null
                  ? ""
                  : +outletOrderDetailObject.product.uomConversionValue.toFixed(
                      2
                    ),
              total: (
                +outletOrderDetailObject.totalQty *
                +outletOrderDetailObject.rate
              ).toFixed(2),
              outlet:
                outletOrderDetailObject.outLet === null
                  ? ""
                  : outletOrderDetailObject.outLet,
              product:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product,
              qtyError: 0,
              freeQtyError: 0,
              discountError: 0,
              conversionValueErr: 0,
              stockQuantityError: 0,
              checked: false,
              disabled: false,
            };

            return outletOrderDetailData;
          });
          dispatch(outletCloseOrderDetailSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getOutletLostOrderDetailsPost =
  ({ outletData }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url:
          URL + "/lost-order-by-date-products-and-sales-teams-or-outlets-new",
        postBody: outletData,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((outletOrderDetailObject, index) => {
            let outletOrderDetailData = {
              index: index + 1,
              id:
                outletOrderDetailObject.id === null
                  ? ""
                  : outletOrderDetailObject.id,
              firmName:
                outletOrderDetailObject.outLet.firmName === null
                  ? ""
                  : outletOrderDetailObject.outLet.firmName,
              salesTeamName:
                outletOrderDetailObject.salesTeamName === null
                  ? ""
                  : outletOrderDetailObject.salesTeamName,
              productName:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product.name,
              outletUom:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product.outletUom.name,
              rate:
                outletOrderDetailObject.rate === null
                  ? ""
                  : outletOrderDetailObject.rate.toFixed(2),
              secondaryClosingQty: 0,
              productStock: 0,
              billQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,

              freeQty: 0,
              distcountAmt: 0,
              orderNo:
                outletOrderDetailObject.orderNo === null
                  ? ""
                  : outletOrderDetailObject.orderNo,
              orderDate:
                outletOrderDetailObject.orderDate === null
                  ? ""
                  : outletOrderDetailObject.orderDate,
              orderQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,
              orderTime:
                outletOrderDetailObject.status === null
                  ? ""
                  : outletOrderDetailObject.status + " Day's Ago",
              taxableAmt:
                outletOrderDetailObject === null
                  ? ""
                  : outletOrderDetailObject.taxableAmt.toFixed(2),
              cgstAmt:
                outletOrderDetailObject.cgstAmt === null
                  ? ""
                  : outletOrderDetailObject.cgstAmt.toFixed(2),
              sgstAmt:
                outletOrderDetailObject.sgstAmt === null
                  ? ""
                  : outletOrderDetailObject.sgstAmt.toFixed(2),
              uomConversionRate:
                outletOrderDetailObject.product === null
                  ? ""
                  : +outletOrderDetailObject.product.uomConversionValue.toFixed(
                      2
                    ),
              total: (
                +outletOrderDetailObject.qty * +outletOrderDetailObject.rate
              ).toFixed(2),
              outlet:
                outletOrderDetailObject.outLet === null
                  ? ""
                  : outletOrderDetailObject.outLet,
              product:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product,
              qtyError: 0,
              freeQtyError: 0,
              discountError: 0,
              conversionValueErr: 0,
              stockQuantityError: 0,
              checked: false,
              disabled: false,
            };

            return outletOrderDetailData;
          });
          dispatch(outletCloseOrderDetailSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getOutletLostOrderDetailsPostNew =
  ({ outletData }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url:
          URL + "/get-lost-order-by-date-products-and-sales-teams-or-outlets",
        postBody: outletData,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((outletOrderDetailObject, index) => {
            let outletOrderDetailData = {
              index: index + 1,
              id:
                outletOrderDetailObject.id === null
                  ? ""
                  : outletOrderDetailObject.id,
              firmName:
                outletOrderDetailObject.outLetFirmName === null
                  ? ""
                  : outletOrderDetailObject.outLetFirmName,
              salesTeamName:
                outletOrderDetailObject.salesTeamName === null
                  ? ""
                  : outletOrderDetailObject.salesTeamName,
              productName:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product.productName,
              outletUom:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product.outletUomName,
              rate:
                outletOrderDetailObject.rate === null
                  ? ""
                  : outletOrderDetailObject.rate.toFixed(2),
              secondaryClosingQty: 0,
              productStock: 0,
              billQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,

              freeQty: 0,
              distcountAmt: 0,
              orderNo:
                outletOrderDetailObject.orderNo === null
                  ? ""
                  : outletOrderDetailObject.orderNo,
              orderDate:
                outletOrderDetailObject.orderDate === null
                  ? ""
                  : outletOrderDetailObject.orderDate,
              orderQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,
              totalQty:
                outletOrderDetailObject.totalQty === null
                  ? ""
                  : outletOrderDetailObject.totalQty,
              orderTime:
                outletOrderDetailObject.status === null
                  ? ""
                  : outletOrderDetailObject.status + " Day's Ago",
              taxableAmt:
                outletOrderDetailObject === null
                  ? ""
                  : outletOrderDetailObject.taxableAmt.toFixed(2),
              cgstAmt:
                outletOrderDetailObject.cgstAmt === null
                  ? ""
                  : outletOrderDetailObject.cgstAmt.toFixed(2),
              sgstAmt:
                outletOrderDetailObject.sgstAmt === null
                  ? ""
                  : outletOrderDetailObject.sgstAmt.toFixed(2),
              uomConversionRate:
                outletOrderDetailObject.product === null
                  ? ""
                  : +outletOrderDetailObject.product.uomConversionValue.toFixed(
                      2
                    ),
              total: (
                +outletOrderDetailObject.totalQty *
                +outletOrderDetailObject.rate
              ).toFixed(2),
              outlet:
                outletOrderDetailObject.outLet === null
                  ? ""
                  : outletOrderDetailObject.outLet,
              product:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product,
              qtyError: 0,
              freeQtyError: 0,
              discountError: 0,
              conversionValueErr: 0,
              stockQuantityError: 0,
              checked: false,
              disabled: false,
            };

            return outletOrderDetailData;
          });
          dispatch(outletCloseOrderDetailSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getOutletOrderDetailPost =
  ({ outletData }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url: URL + "/by-outlets-and-date",
        postBody: outletData,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((outletOrderDetailObject, index) => {
            let outletOrderDetailData = {
              index: index + 1,
              id:
                outletOrderDetailObject.id === null
                  ? ""
                  : outletOrderDetailObject.id,
              firmName:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.outLet.firmName,
              productName:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product.name,
              outletUom:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product.outletUom.name,
              rate:
                outletOrderDetailObject.rate === null
                  ? ""
                  : outletOrderDetailObject.rate.toFixed(2),
              secondaryClosingQty: 0,
              productStock: 0,
              billQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,

              freeQty: 0,
              distcountAmt: 0,
              orderNo:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.orderNo,
              orderDate:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.orderDate,
              orderQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,
              orderTime:
                outletOrderDetailObject.status === null
                  ? ""
                  : outletOrderDetailObject.status + " Day's Ago",
              taxableAmt:
                outletOrderDetailObject === null
                  ? ""
                  : outletOrderDetailObject.taxableAmt.toFixed(2),
              cgstAmt:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.cgstAmt.toFixed(2),
              sgstAmt:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.sgstAmt.toFixed(2),
              uomConversionRate:
                outletOrderDetailObject.product === null
                  ? ""
                  : +outletOrderDetailObject.product.uomConversionValue.toFixed(
                      2
                    ),
              total: (
                +outletOrderDetailObject.qty * +outletOrderDetailObject.rate
              ).toFixed(2),
              outlet:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.outLet,
              product:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product,
              qtyError: 0,
              freeQtyError: 0,
              discountError: 0,
              conversionValueErr: 0,
              stockQuantityError: 0,
              checked: false,
              disabled: false,
            };

            return outletOrderDetailData;
          });
          dispatch(outletOrderDetailSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getOutletOrderDetailBySales =
  ({ salesPersonIds, fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-sales-teams-and-date?salesTeamIds=" +
          salesPersonIds +
          "&fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((outletOrderDetailObject, index) => {
            let outletOrderDetailData = {
              index: index + 1,
              id:
                outletOrderDetailObject.id === null
                  ? ""
                  : outletOrderDetailObject.id,
              firmName:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.outLet.firmName,
              beatName:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.outLet.beat
                      .beatName,
              productName:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product.name,
              outletUom:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product.outletUom.name,
              rate:
                outletOrderDetailObject.rate === null
                  ? ""
                  : outletOrderDetailObject.rate.toFixed(2),
              secondaryClosingQty: 0,
              productStock: 0,
              billQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,
              orderQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,

              freeQty: 0,
              distcountAmt: 0,
              orderNo:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.orderNo,
              orderDate:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.orderDate,
              orderTime:
                outletOrderDetailObject.status === null
                  ? ""
                  : outletOrderDetailObject.status + " Day's Ago",
              taxableAmt:
                outletOrderDetailObject === null
                  ? ""
                  : outletOrderDetailObject.taxableAmt.toFixed(2),
              cgstAmt:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.cgstAmt.toFixed(2),
              sgstAmt:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.sgstAmt.toFixed(2),
              uomConversionRate:
                outletOrderDetailObject.product === null
                  ? ""
                  : +outletOrderDetailObject.product.uomConversionValue.toFixed(
                      2
                    ),
              total: (
                +outletOrderDetailObject.qty * +outletOrderDetailObject.rate
              ).toFixed(2),
              outlet:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.outLet,
              product:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product,
              qtyError: 0,
              freeQtyError: 0,
              discountError: 0,
              conversionValueErr: 0,
              stockQuantityError: 0,
              checked: false,
              disabled: false,
            };

            return outletOrderDetailData;
          });
          dispatch(outletOrderDetailSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getOutletOrderDetailBySalesNew =
  ({ dataToPost }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url: URL + "/get-by-sales-teams-and-date",
        postBody: dataToPost,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((outletOrderDetailObject, index) => {
            let outletOrderDetailData = {
              index: index + 1,
              id:
                outletOrderDetailObject.id === null
                  ? ""
                  : outletOrderDetailObject.id,
              firmName:
                outletOrderDetailObject === null
                  ? ""
                  : outletOrderDetailObject.outletFirmName,
              beatName:
                outletOrderDetailObject === null
                  ? ""
                  : outletOrderDetailObject.beatName,
              productName:
                outletOrderDetailObject.productName === null
                  ? ""
                  : outletOrderDetailObject.productName,
              outletUom:
                outletOrderDetailObject.uomName === null
                  ? ""
                  : outletOrderDetailObject.uomName,
              rate:
                outletOrderDetailObject.rate === null
                  ? ""
                  : outletOrderDetailObject.rate.toFixed(2),
              secondaryClosingQty: 0,
              productStock: 0,
              billQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,
              orderQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,

              freeQty: 0,
              distcountAmt: 0,
              orderNo:
                outletOrderDetailObject === null
                  ? ""
                  : outletOrderDetailObject.orderNo,
              orderDate:
                outletOrderDetailObject === null
                  ? ""
                  : outletOrderDetailObject.orderDate,
              orderTime:
                outletOrderDetailObject.status === null
                  ? ""
                  : outletOrderDetailObject.status + " Day's Ago",
              taxableAmt:
                outletOrderDetailObject === null
                  ? ""
                  : outletOrderDetailObject.taxableAmt.toFixed(2),
              cgstAmt:
                outletOrderDetailObject === null
                  ? ""
                  : outletOrderDetailObject.cgstAmt.toFixed(2),
              sgstAmt:
                outletOrderDetailObject === null
                  ? ""
                  : outletOrderDetailObject.sgstAmt.toFixed(2),
              uomConversionRate: "",
              total: (
                +outletOrderDetailObject.qty * +outletOrderDetailObject.rate
              ).toFixed(2),
              outlet:
                outletOrderDetailObject === null
                  ? ""
                  : outletOrderDetailObject.outletId,
              product:
                outletOrderDetailObject.productId === null
                  ? ""
                  : outletOrderDetailObject.productId,
              qtyError: 0,
              freeQtyError: 0,
              discountError: 0,
              conversionValueErr: 0,
              stockQuantityError: 0,
              checked: false,
              disabled: false,
            };

            return outletOrderDetailData;
          });
          dispatch(outletOrderDetailSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getOutletOrderDetailBySalesUpdated =
  ({ salesPersonIds, fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url: URL + "/get-by-sales-teams-and-date",
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((outletOrderDetailObject, index) => {
            let outletOrderDetailData = {
              index: index + 1,
              id:
                outletOrderDetailObject.id === null
                  ? ""
                  : outletOrderDetailObject.id,
              firmName:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.outLet.firmName,
              beatName:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.outLet.beat
                      .beatName,
              productName:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product.name,
              outletUom:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product.outletUom.name,
              rate:
                outletOrderDetailObject.rate === null
                  ? ""
                  : outletOrderDetailObject.rate.toFixed(2),
              secondaryClosingQty: 0,
              productStock: 0,
              billQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,
              orderQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,

              freeQty: 0,
              distcountAmt: 0,
              orderNo:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.orderNo,
              orderDate:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.orderDate,
              orderTime:
                outletOrderDetailObject.status === null
                  ? ""
                  : outletOrderDetailObject.status + " Day's Ago",
              taxableAmt:
                outletOrderDetailObject === null
                  ? ""
                  : outletOrderDetailObject.taxableAmt.toFixed(2),
              cgstAmt:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.cgstAmt.toFixed(2),
              sgstAmt:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.sgstAmt.toFixed(2),
              uomConversionRate:
                outletOrderDetailObject.product === null
                  ? ""
                  : +outletOrderDetailObject.product.uomConversionValue.toFixed(
                      2
                    ),
              total: (
                +outletOrderDetailObject.qty * +outletOrderDetailObject.rate
              ).toFixed(2),
              outlet:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.outLet,
              product:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product,
              qtyError: 0,
              freeQtyError: 0,
              discountError: 0,
              conversionValueErr: 0,
              stockQuantityError: 0,
              checked: false,
              disabled: false,
            };

            return outletOrderDetailData;
          });
          dispatch(outletOrderDetailSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getOutletOrderDetailBySalesPerson =
  ({ salesTeamIds, fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/parked-and-pending-by-sales-teams-and-date?salesTeamIds=" +
          salesTeamIds +
          "&fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((outletOrderDetailObject, index) => {
            let outletOrderDetailData = {
              index: index + 1,
              id:
                outletOrderDetailObject.id === null
                  ? ""
                  : outletOrderDetailObject.id,
              firmName:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.outLet.firmName,
              productName:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product.name,
              outletUom:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product.outletUom.name,
              rate:
                outletOrderDetailObject.rate === null
                  ? ""
                  : outletOrderDetailObject.rate.toFixed(2),
              secondaryClosingQty: 0,
              productStock: 0,
              billQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,
              orderQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,

              freeQty: 0,
              distcountAmt: 0,
              orderNo:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.orderNo,
              orderDate:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.orderDate,
              orderTime:
                outletOrderDetailObject.status === null
                  ? ""
                  : outletOrderDetailObject.status + " Day's Ago",
              taxableAmt:
                outletOrderDetailObject === null
                  ? ""
                  : outletOrderDetailObject.taxableAmt.toFixed(2),
              cgstAmt:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.cgstAmt.toFixed(2),
              sgstAmt:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.sgstAmt.toFixed(2),
              uomConversionRate:
                outletOrderDetailObject.product === null
                  ? ""
                  : +outletOrderDetailObject.product.uomConversionValue.toFixed(
                      2
                    ),
              total: (
                +outletOrderDetailObject.qty * +outletOrderDetailObject.rate
              ).toFixed(2),
              outlet:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.outLet,
              product:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product,
              qtyError: 0,
              freeQtyError: 0,
              discountError: 0,
              conversionValueErr: 0,
              stockQuantityError: 0,
              checked: false,
              disabled: false,
            };

            return outletOrderDetailData;
          });
          dispatch(outletOrderDetailSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const setOutletOrderDetail =
  ({ row }) =>
  async (dispatch) => {
    const rowList = row.map((rowObject, index) => {
      const objTotal = +rowObject.orderQty * +rowObject.rate;
      const addObj = {
        ...rowObject,
        total:
          rowObject.distcountAmt > 0
            ? (
                objTotal -
                (+rowObject.orderQty *
                  +rowObject.rate *
                  +rowObject.distcountAmt) /
                  100
              ).toFixed(2)
            : (+rowObject.orderQty * +rowObject.rate).toFixed(2),
      };
      return addObj;
    });
    dispatch(outletOrderDetailSuccess({ row: rowList }));
  };

export const getOutletOrderDetailFromHeaderIds =
  ({ outletOrderHeaderIds }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-outlet-order-headers?outletOrderHeaderIds=" +
          outletOrderHeaderIds,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((outletOrderDetailObject, index) => {
            let outletOrderDetailData = {
              index: index + 1,
              id:
                outletOrderDetailObject.id === null
                  ? ""
                  : outletOrderDetailObject.id,
              firmName:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.outLet.firmName,
              productName:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product.name,
              outletUom:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product.outletUom.name,
              rate:
                outletOrderDetailObject.rate === null
                  ? ""
                  : outletOrderDetailObject.rate.toFixed(2),
              secondaryClosingQty: 0,
              productStock: 0,
              billQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,
              orderQty:
                outletOrderDetailObject.qty === null
                  ? ""
                  : outletOrderDetailObject.qty,

              freeQty: 0,
              distcountAmt: 0,
              orderNo:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.orderNo,
              orderDate:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.orderDate,
              orderTime:
                outletOrderDetailObject.status === null
                  ? ""
                  : outletOrderDetailObject.status + " Day's Ago",
              taxableAmt:
                outletOrderDetailObject === null
                  ? ""
                  : outletOrderDetailObject.taxableAmt.toFixed(2),
              cgstAmt:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.cgstAmt.toFixed(2),
              sgstAmt:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.sgstAmt.toFixed(2),
              uomConversionRate:
                outletOrderDetailObject.product === null
                  ? ""
                  : +outletOrderDetailObject.product.uomConversionValue.toFixed(
                      2
                    ),
              total: (
                +outletOrderDetailObject.qty * +outletOrderDetailObject.rate
              ).toFixed(2),
              outlet:
                outletOrderDetailObject.outletOrderHerader === null
                  ? ""
                  : outletOrderDetailObject.outletOrderHerader.outLet,
              product:
                outletOrderDetailObject.product === null
                  ? ""
                  : outletOrderDetailObject.product,
              qtyError: 0,
              freeQtyError: 0,
              discountError: 0,
              conversionValueErr: 0,
              stockQuantityError: 0,
              checked: false,
              disabled: false,
            };

            return outletOrderDetailData;
          });
          dispatch(outletOrderDetailSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };



// export const getPendingOrderByMonthYear =
// ({ month, year }) =>
// async (dispatch) => {
//   try {
//     const response = apiGet({
//       url:
//         URL +
//         "/pending-order-by-month-year-new?month=" +
//         month +
//         "&year=" +
//         year,
//     }).then(({ data, success }) => {
//       if (success) {
//         const row = data.data.map((pendingOrderByMonthYearObj, index) => {
//           let pendingOrderByMonthYearData = {
//             index: index + 1,
//             id:
//               pendingOrderByMonthYearObj.orderHeaderId === null
//                 ? ""
//                 : pendingOrderByMonthYearObj.orderHeaderId,
//             orderNo:
//               pendingOrderByMonthYearObj.orderNo === null
//                 ? ""
//                 : pendingOrderByMonthYearObj.orderNo,
//             orderDate:
//               pendingOrderByMonthYearObj.orderDate === null
//                 ? ""
//                 : pendingOrderByMonthYearObj.orderDate,
//             firmName:
//               pendingOrderByMonthYearObj.outletName === null
//                 ? ""
//                 : pendingOrderByMonthYearObj.outletName,
//             beatName:
//               pendingOrderByMonthYearObj.outletName === null
//                 ? ""
//                 : pendingOrderByMonthYearObj.beatName,
//             detailCount:
//               pendingOrderByMonthYearObj.detailCount === null
//                 ? ""
//                 : pendingOrderByMonthYearObj.detailCount,
//             totalAmount:
//               pendingOrderByMonthYearObj.totalAmount === null
//                 ? ""
//                 : +pendingOrderByMonthYearObj.totalAmount.toFixed(0),
//           };

//           return pendingOrderByMonthYearData;
//         });
//         dispatch(pendingOrderByMonthYearSuccess({ row }));
//         return { response: row, success };
//       }
//       return { response: [], success };
//     });
//     return response;
//   } catch (e) {
//     return console.error(e.message);
//   }
// };

// new updated API for pending bill - 31/07/2024

export const getPendingOrderByMonthYear =
  ({ month, year }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/pending-order-till-month-end-date?month=" +
          month +
          "&year=" +
          year,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((pendingOrderByMonthYearObj, index) => {
            let pendingOrderByMonthYearData = {
              index: index + 1,
              id:
                pendingOrderByMonthYearObj.orderHeaderId === null
                  ? ""
                  : pendingOrderByMonthYearObj.orderHeaderId,
              orderNo:
                pendingOrderByMonthYearObj.orderNo === null
                  ? ""
                  : pendingOrderByMonthYearObj.orderNo,
              orderDate:
                pendingOrderByMonthYearObj.orderDate === null
                  ? ""
                  : pendingOrderByMonthYearObj.orderDate,
              firmName:
                pendingOrderByMonthYearObj.outletName === null
                  ? ""
                  : pendingOrderByMonthYearObj.outletName,
              beatName:
                pendingOrderByMonthYearObj.outletName === null
                  ? ""
                  : pendingOrderByMonthYearObj.beatName,
              detailCount:
                pendingOrderByMonthYearObj.detailCount === null
                  ? ""
                  : pendingOrderByMonthYearObj.detailCount,
              totalAmount:
                pendingOrderByMonthYearObj.totalAmount === null
                  ? ""
                  : +pendingOrderByMonthYearObj.totalAmount.toFixed(0),
            };

            return pendingOrderByMonthYearData;
          });
          dispatch(pendingOrderByMonthYearSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
