import React from "react";
import { Grid, Paper } from "@mui/material";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { RegionAndTargetColumns } from "../../tableColumns/table-columns";
import { companyTargetJson } from "../../DynamicFormsJson/Transaction/companyTarget";
import { getYearCompanyTarget } from "../../Slice/year.slice";
import { getLastYearComapnyTarget } from "../../Slice/companyTarget.slice";
import endpoint from "../../config/endpoints";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import {
  serverMsg,
  noInternetMsg,
  saveWarningMsg,
} from "../../config/messageconstant";
import swal from "sweetalert";
import { saveFailedMsg, savemsg } from "../../config/messageconstant";
import { ButtonCompo } from "../../components/Comman/Button";
import { apiPost, apiGet } from "../../utils/api_service";
import MaterialUITableWithTextField from "../../components/Comman/RT/MaterialUITableWithTextField";
import { withRouter } from "../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import DynamicFormWithoutValidation from "../../components/dynamicscreens/DynamicFormWithoutValidation";
import { ConvertAmount } from "../../components/Comman/ConvertValueWithDenominator";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";

class CompanyAndTargetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldData: { type: "0" },
      formErrors: {},
      totalTarget: 0,
      DataToSet: [],
      dataToSave: {},
      conversionFactorText: "",
      dynamicMasterData: {
        yearDetail: this.props.yearList?.yearCompanyTarget,
      },
      companyTargetList: [
        {
          index: 1,
          name: "January",
          target: "",
          dataKey: "jan",
        },
        {
          index: 2,
          name: "February",
          target: "",
          dataKey: "feb",
        },
        {
          index: 3,
          name: "March",
          target: "",
          dataKey: "march",
        },
        {
          index: 4,
          name: "April",
          target: "",
          dataKey: "april",
        },
        {
          index: 5,
          name: "May",
          target: "",
          dataKey: "may",
        },
        {
          index: 6,
          name: "June",
          target: "",
          dataKey: "jun",
        },
        {
          index: 7,
          name: "July",
          target: "",
          dataKey: "july",
        },
        {
          index: 8,
          name: "August",
          target: "",
          dataKey: "aug",
        },
        {
          index: 9,
          name: "September",
          target: "",
          dataKey: "sep",
        },
        {
          index: 10,
          name: "October",
          target: "",
          dataKey: "oct",
        },
        {
          index: 11,
          name: "November",
          target: "",
          dataKey: "nov",
        },
        {
          index: 12,
          name: "December",
          target: "",
          dataKey: "dec",
        },
      ],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.yearList !== nextProps.yearList) {
      if (
        this.props.yearList?.yearCompanyTarget !==
        nextProps.yearList?.yearCompanyTarget
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetail: nextProps.yearList?.yearCompanyTarget,
          },
        });
      }
    }
  }

  async componentDidMount() {
    const {
      getLastYearComapnyTarget,
      getYearCompanyTarget,
      showLoador,
      showNotification,
    } = this.props;
    const { fieldData } = this.state;

    if (navigator.onLine) {

      let conversionFactor = "";
      if (fieldData.type === "0") {
        conversionFactor = "K";
      }

      this.setState({
        conversionFactorText: conversionFactor,
      });

      showLoador({ loador: true });
      await getYearCompanyTarget().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      if (this.props.params.id) {
        showLoador({ loador: true });
        await apiGet({
          url: companyTargetJson.apiBaseURL + "/" + this.props.params.id,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            if (!data.error) {
              showLoador({ loador: true });
              getLastYearComapnyTarget({
                yearId: data.data.yearMaster.id,
              }).then(({ response, success }) => {
                console.log(response);
                showLoador({ loador: false });
                if (!success) {
                  showNotification({ msg: serverMsg, severity: "error" });
                  return {};
                } else {
                  let rowData = data.data;
                  const listData = this.state.companyTargetList.map(
                    (companyTarget) => {
                      return {
                        ...companyTarget,
                        target: rowData[companyTarget.dataKey],
                      };
                    }
                  );

                  this.setState({
                    totalTarget: rowData.totalTarget,
                    fieldData: {
                      year: rowData.yearMaster.id,
                    },
                    companyTargetList: listData,
                    DataToSet: [
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        label: "Year",
                        value:
                          rowData.yearMaster !== null &&
                            rowData.yearMaster.yearName !== null
                            ? rowData.yearMaster.yearName
                            : "-",
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        label: "Total Target",
                        value:
                          rowData.totalTarget !== null
                            ? ConvertAmount({ amount: rowData.totalTarget })
                            : "-",
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        label: "Last Year Target",
                        value:
                          response.totalTarget !== undefined &&
                            response.totalTarget !== null
                            ? response.totalTarget
                            : "-",
                      },

                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        label: "Achieved Target",
                        value:
                          response.achievedTarget != undefined &&
                            response.achievedTarget !== null
                            ? response.achievedTarget
                            : "-",
                      },

                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        label: "Percentage (%)",
                        value:
                          response.achievedTarget &&
                            response.totalTarget !== null
                            ? (
                              (response.achievedTarget /
                                response.totalTarget) *
                              100
                            ).toFixed(2)
                            : (0).toFixed(2),
                      },
                    ],
                  });
                }
              });
            }
          }
          return success;
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  onSubmit = async () => {
    const { fieldData, companyTargetList } = this.state;
    const totalTarget = companyTargetList.reduce(
      (sum, item) => sum + Number(item.target),
      0
    );
    if (navigator.onLine) {
      console.log(fieldData);
      let conversionFactor = 1;
      if (fieldData.type === "0") {
        conversionFactor = 1000;
      } else if (fieldData.type === "1") {
        conversionFactor = 100000;
      } else if (fieldData.type === "2") {
        conversionFactor = 10000000;
      }

      const convertedTargets = companyTargetList.map((item) => ({
        ...item,
        target: Number(item.target) * conversionFactor,
      }));

      let dataToSave = {
        yearMaster: {
          id: fieldData.year,
        },
        totalTarget: totalTarget * conversionFactor,
        jan: convertedTargets[0].target,
        feb: convertedTargets[1].target,
        march: convertedTargets[2].target,
        april: convertedTargets[3].target,
        may: convertedTargets[4].target,
        jun: convertedTargets[5].target,
        july: convertedTargets[6].target,
        aug: convertedTargets[7].target,
        sep: convertedTargets[8].target,
        oct: convertedTargets[9].target,
        nov: convertedTargets[10].target,
        dec: convertedTargets[11].target,
      };

      if (this.props.params.id) {
        dataToSave = {
          id: this.props.params.id,
          yearMaster: {
            id: fieldData.year,
          },
          totalTarget: totalTarget,
          jan: companyTargetList[0].target,
          feb: companyTargetList[1].target,
          march: companyTargetList[2].target,
          april: companyTargetList[3].target,
          may: companyTargetList[4].target,
          jun: companyTargetList[5].target,
          july: companyTargetList[6].target,
          aug: companyTargetList[7].target,
          sep: companyTargetList[8].target,
          oct: companyTargetList[9].target,
          nov: companyTargetList[10].target,
          dec: companyTargetList[11].target,
        };
      }

      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.onSave(dataToSave);
        }
      });
    } else {
      this.props.showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  onSave = async (dataToSave) => {
    const { showLoador, setProductList, showNotification, category } =
      this.props;

    let error = false;
    let productList = [];
    if (!error) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        await apiPost({
          url: endpoint.companyTarget,
          postBody: dataToSave,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            showNotification({ msg: savemsg });
            this.props.navigate(-1);
          } else {
            showNotification({ msg: saveFailedMsg, severity: "error" });
          }
          return success;
        });
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    } else {
      setProductList({ row: productList });
    }
  };

  setComapnyTargetList = ({ row }) => {
    const { fieldData } = this.state;

    let totalTarget = 0;
    row.map((rowData) => {
      totalTarget = totalTarget + +rowData.target;
    });


    this.setState({
      companyTargetList: row,
      totalTarget: totalTarget,
    });
  };

  getListById = async (data) => {
    const { fieldData } = this.state;
    console.log(data);
    const { getLastYearComapnyTarget, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getLastYearComapnyTarget({
        yearId: data.yearName,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            fieldData: {
              ...this.state.fieldData,
              year: data.yearName,
            },
            DataToSet: [
              {
                xs: 12,
                sm: 4,
                md: 4,
                lg: 4,
                label: "Last Year Target",
                value:
                  response.totalTarget !== null ? response.totalTarget : "-",
              },

              {
                xs: 12,
                sm: 4,
                md: 4,
                lg: 4,
                label: "Achieved Target",
                value:
                  response.achievedTarget !== null
                    ? response.achievedTarget
                    : "-",
              },

              {
                xs: 12,
                sm: 4,
                md: 4,
                lg: 4,
                label: "Percentage (%)",
                value:
                  response.achievedTarget && response.totalTarget !== null
                    ? (
                      (response.achievedTarget / response.totalTarget) *
                      100
                    ).toFixed(2)
                    : (0).toFixed(2),
              },
            ],
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    companyTargetJson.fieldMeta.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        // dataToSearch[fieldName] = newValue;
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    let conversionFactorOnChange = "";
    if (newValue === "0") {
      conversionFactorOnChange = "K";
    } else if (newValue === "1") {
      conversionFactorOnChange = "L";
    } else if (newValue === "2") {
      conversionFactorOnChange = "CR";
    }

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
      conversionFactorText: conversionFactorOnChange,
    });
  };

  render() {
    const {
      dynamicMasterData,
      companyTargetList,
      fieldData,
      DataToSet,
      formErrors,
      totalTarget,
      conversionFactorText,
    } = this.state;
    const { regionList } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutValidation
            getListById={this.getListById}
            showBackToList={true}
            showSaveBtnMain={false}
            dynamicMasterData={dynamicMasterData}
            screenTitle={companyTargetJson.screenTitle}
            fieldMeta={this.props.params.id ? [] : companyTargetJson.fieldMeta}
            apiBaseURL={companyTargetJson.apiBaseURL}
            tableColumns={RegionAndTargetColumns}
            tableData={regionList?.targetRegion}
            saveBtnText={"Submit"}
            onDataChange={this.onDataChange}
            DataToSet={DataToSet}
            fieldData={fieldData}
            formErrors={formErrors}
          />
          {this.props.params.id && <br />}
          {Object.keys(fieldData).length !== 0 && (
            <>
              <MaterialUITableWithTextField
                tableHead={[
                  {
                    title: "Sr.No.",
                    name: "index",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Month",
                    name: "name",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                  {
                    title: "Target",
                    name: "target",
                    placeHolder: "Enter Target",
                    textFieldError: "targetErr",
                    error: "Target",
                    textField: true,
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                ]}
                data={companyTargetList}
                setList={this.setComapnyTargetList}
                isActionColActive={false}
              />
              <br />
              {this.props.params.id ? (<DynamicDetails
                DataToSet={[
                  {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    label: "Total target",
                    value: totalTarget,
                  }
                ]}
              />) :
                (<DynamicDetails
                  DataToSet={[
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      label: "Total target",
                      value: totalTarget + "" + conversionFactorText,
                    }
                  ]}
                />
                )
              }
              <br />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Submit"
                  onClick={this.onSubmit}
                />
              </Grid>
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  yearList: state.year,
  regionList: state.region,
});
const mapDispatchToProps = {
  getYearCompanyTarget,
  showLoador,
  showNotification,
  getLastYearComapnyTarget,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CompanyAndTargetForm);
