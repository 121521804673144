import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  creditNoteHeader: [],
  historyOfCreditNote: [],
  creditNoteByDate: [],
  creditNoteLatest: [],
};
let URL = endpoints.creditNote;
let creaditNoteHeader = endpoints.creaditNoteHeader;

const creditNoteHeaderSlice = createSlice({
  name: "creditNoteHeader",
  initialState,
  reducers: {
    creditNoteHeaderSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        creditNoteHeader: row,
      };
    },

    historyOfCreditNoteSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        historyOfCreditNote: row,
        isFetch: true,
      };
    },

    creditNoteHistoryViewSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        historyOfCreditNoteView: row,
      };
    },
    creditNoteOutletHistoryViewSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        historyOfCreditNoteOutletView: row,
      };
    },
    creditNoteSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        creditNoteByDate: row,
      };
    },
    creditNoteLatestSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        creditNoteLatest: row,
      };
    },
    creditNoteHistoryDetailSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        historyOfCreditNoteDetail: row,
        isFetch: true,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  creditNoteHeaderSuccess,
  creditNoteHistoryDetailSuccess,
  historyOfCreditNoteSuccess,
  creditNoteHistoryViewSuccess,
  creditNoteOutletHistoryViewSuccess,
  creditNoteSuccess,
  creditNoteLatestSuccess,
  resetState,
} = creditNoteHeaderSlice.actions;

export default creditNoteHeaderSlice.reducer;

export const getCreditNoteHeader =
  ({ outletId, fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-outlet?outletId=" +
          outletId +
          "&fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          const row = content.map((creditNoteHeaderObject, index) => {
            creditNoteHeaderObject = {
              index: index + 1,
              id:
                creditNoteHeaderObject.id === null
                  ? ""
                  : creditNoteHeaderObject.id,
              date:
                creditNoteHeaderObject.date === null
                  ? ""
                  : creditNoteHeaderObject.date,
              beatName:
                creditNoteHeaderObject.outLet !== null &&
                creditNoteHeaderObject.outLet.beat !== null &&
                creditNoteHeaderObject.outLet.beat.beatName
                  ? creditNoteHeaderObject.outLet.beat.beatName
                  : "",
              outletName:
                creditNoteHeaderObject.outLet !== null &&
                creditNoteHeaderObject.outLet.firmName
                  ? creditNoteHeaderObject.outLet.firmName
                  : "",

              isCalculated:
                creditNoteHeaderObject.instock_outstock === null
                  ? ""
                  : creditNoteHeaderObject.instock_outstock != 0
                  ? "Yes"
                  : "No",
              taxableAmt:
                creditNoteHeaderObject.taxableAmt === null
                  ? ""
                  : creditNoteHeaderObject.taxableAmt.toFixed(2),
              cgstAmt:
                creditNoteHeaderObject.cgstAmt === null
                  ? ""
                  : creditNoteHeaderObject.cgstAmt.toFixed(2),
              igstAmt:
                creditNoteHeaderObject.igstAmt === null
                  ? ""
                  : creditNoteHeaderObject.igstAmt.toFixed(2),
              sgstAmt:
                creditNoteHeaderObject.sgstAmt === null
                  ? ""
                  : creditNoteHeaderObject.sgstAmt.toFixed(2),
              totalAmt:
                creditNoteHeaderObject.totalAmt === null
                  ? ""
                  : creditNoteHeaderObject.totalAmt.toFixed(2),
            };
            return creditNoteHeaderObject;
          });
          dispatch(creditNoteHeaderSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const setCreditNoteHeader = () => async (dispatch) => {
  dispatch(creditNoteHeaderSuccess({ row: [] }));
};
export const getCreditNoteHeaderPost =
  ({ creditNoteData }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url: URL + "/by-date-and-outlets",
        postBody: creditNoteData,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((creditNoteHeaderObject, index) => {
            let total = 0;
            creditNoteHeaderObject.creditNoteDetail.map(
              (creditNoteDetailObj) => {
                total = total + +creditNoteDetailObj.qty;
              }
            );
            return {
              index: index + 1,
              id:
                creditNoteHeaderObject.id === null
                  ? ""
                  : creditNoteHeaderObject.id,
              date:
                creditNoteHeaderObject.date === null
                  ? ""
                  : creditNoteHeaderObject.date,
              beatName:
                creditNoteHeaderObject.outLet !== null &&
                creditNoteHeaderObject.outLet.beat !== null &&
                creditNoteHeaderObject.outLet.beat.beatName
                  ? creditNoteHeaderObject.outLet.beat.beatName
                  : "",
              outletName:
                creditNoteHeaderObject.outLet !== null &&
                creditNoteHeaderObject.outLet.firmName
                  ? creditNoteHeaderObject.outLet.firmName
                  : "",
              totalQty: total,
              taxableAmt:
                creditNoteHeaderObject.taxableAmt === null
                  ? ""
                  : creditNoteHeaderObject.taxableAmt.toFixed(2),
              cgstAmt:
                creditNoteHeaderObject.cgstAmt === null
                  ? ""
                  : creditNoteHeaderObject.cgstAmt.toFixed(2),
              igstAmt:
                creditNoteHeaderObject.igstAmt === null
                  ? ""
                  : creditNoteHeaderObject.igstAmt.toFixed(2),
              sgstAmt:
                creditNoteHeaderObject.sgstAmt === null
                  ? ""
                  : creditNoteHeaderObject.sgstAmt.toFixed(2),
              totalAmt:
                creditNoteHeaderObject.totalAmt === null
                  ? ""
                  : creditNoteHeaderObject.totalAmt.toFixed(2),
              isCalculated:
                creditNoteHeaderObject.instock_outstock === null
                  ? ""
                  : creditNoteHeaderObject.instock_outstock != 0
                  ? "Yes"
                  : "No",
            };
            return creditNoteHeaderData;
          });
          dispatch(creditNoteHeaderSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getTodaysCreditNoteForOutlet = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/todays-crn",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((creditNoteHeaderObject, index) => {
          let total = 0;
          creditNoteHeaderObject.creditNoteDetail.map((creditNoteDetailObj) => {
            total = total + +creditNoteDetailObj.qty;
          });

          return {
            index: index + 1,
            id:
              creditNoteHeaderObject.id === null
                ? ""
                : creditNoteHeaderObject.id,
            date:
              creditNoteHeaderObject.date === null
                ? ""
                : creditNoteHeaderObject.date,
            beatName:
              creditNoteHeaderObject.outLet !== null &&
              creditNoteHeaderObject.outLet.beat !== null &&
              creditNoteHeaderObject.outLet.beat.beatName
                ? creditNoteHeaderObject.outLet.beat.beatName
                : "",
            outletName:
              creditNoteHeaderObject.outLet !== null &&
              creditNoteHeaderObject.outLet.firmName
                ? creditNoteHeaderObject.outLet.firmName
                : "",
            totalQty: total,
            taxableAmt:
              creditNoteHeaderObject.taxableAmt === null
                ? ""
                : creditNoteHeaderObject.taxableAmt.toFixed(2),
            cgstAmt:
              creditNoteHeaderObject.cgstAmt === null
                ? ""
                : creditNoteHeaderObject.cgstAmt.toFixed(2),
            igstAmt:
              creditNoteHeaderObject.igstAmt === null
                ? ""
                : creditNoteHeaderObject.igstAmt.toFixed(2),
            sgstAmt:
              creditNoteHeaderObject.sgstAmt === null
                ? ""
                : creditNoteHeaderObject.sgstAmt.toFixed(2),
            totalAmt:
              creditNoteHeaderObject.totalAmt === null
                ? ""
                : creditNoteHeaderObject.totalAmt.toFixed(2),
            isCalculated:
              creditNoteHeaderObject.instock_outstock === null
                ? ""
                : creditNoteHeaderObject.instock_outstock != 0
                ? "Yes"
                : "No",
          };
          return creditNoteHeaderObject;
        });
        dispatch(creditNoteHeaderSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getHistoryOfCreditNoteByDate =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          creaditNoteHeader +
          "/history-by-date?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((historyOfCreditNoteObject, index) => {
            let historyOfCreditNoteData = {
              index: index + 1,
              id:
                historyOfCreditNoteObject.id === null
                  ? ""
                  : historyOfCreditNoteObject.id,
              acknoledgeNo:
                historyOfCreditNoteObject.acknoledgeNo === null
                  ? ""
                  : historyOfCreditNoteObject.acknoledgeNo,
              grnGvnHeaderId:
                historyOfCreditNoteObject.grnGvnHeaderId === null
                  ? ""
                  : historyOfCreditNoteObject.grnGvnHeaderId,
              claimId:
                historyOfCreditNoteObject.claimId === null
                  ? ""
                  : historyOfCreditNoteObject.claimId,
              creditNoteNo:
                historyOfCreditNoteObject.creditNoteNo === null
                  ? ""
                  : historyOfCreditNoteObject.creditNoteNo,
              date:
                historyOfCreditNoteObject.date === null
                  ? ""
                  : historyOfCreditNoteObject.date,
              einvoiceJson:
                historyOfCreditNoteObject.einvoiceJson === null
                  ? ""
                  : historyOfCreditNoteObject.einvoiceJson,
              toStockStakeHolderFirmName:
                historyOfCreditNoteObject.toStockStakeHolderFirmName === null
                  ? ""
                  : historyOfCreditNoteObject.toStockStakeHolderFirmName,
              toStockStakeHolderStateName:
                historyOfCreditNoteObject.toStockStakeHolderStateName === null
                  ? ""
                  : historyOfCreditNoteObject.toStockStakeHolderStateName,
              toStockStakeHolderAreaNames:
                historyOfCreditNoteObject.toStockStakeHolderAreaNames === null
                  ? ""
                  : historyOfCreditNoteObject.toStockStakeHolderAreaNames,
              ownerNameAndFirmName:
                historyOfCreditNoteObject.toStockStakeHolderOwnerName === null
                  ? ""
                  : historyOfCreditNoteObject.toStockStakeHolderFirmName +
                    " (" +
                    historyOfCreditNoteObject.toStockStakeHolderOwnerName +
                    ")",
              toStockStakeHolderOwnerName:
                historyOfCreditNoteObject.toStockStakeHolderOwnerName === null
                  ? ""
                  : historyOfCreditNoteObject.toStockStakeHolderOwnerName,

              fromStockStakeHolder:
                historyOfCreditNoteObject.fromStockStakeHolder === null
                  ? ""
                  : historyOfCreditNoteObject.fromStockStakeHolder,
              fromStockStakeHolderFirmName:
                historyOfCreditNoteObject.fromStockStakeHolderFirmName === null
                  ? ""
                  : historyOfCreditNoteObject.fromStockStakeHolderFirmName,
              fromStockStakeHolderOwnerName:
                historyOfCreditNoteObject.fromStockStakeHolderOwnerName === null
                  ? ""
                  : historyOfCreditNoteObject.fromStockStakeHolderOwnerName,
              fromStockStakeHolderEinvoiceUsername:
                historyOfCreditNoteObject.fromStockStakeHolderEinvoiceUsername ===
                null
                  ? ""
                  : historyOfCreditNoteObject.fromStockStakeHolderEinvoiceUsername,
              fromStockStakeHolderEinvoicePassword:
                historyOfCreditNoteObject.fromStockStakeHolderEinvoicePassword ===
                null
                  ? ""
                  : historyOfCreditNoteObject.fromStockStakeHolderEinvoicePassword,
              fromStockStakeHolderIsEinvoiceApplicable:
                historyOfCreditNoteObject.fromStockStakeHolderIsEinvoiceApplicable ===
                null
                  ? ""
                  : historyOfCreditNoteObject.fromStockStakeHolderIsEinvoiceApplicable,

              toStockStakeHolder:
                historyOfCreditNoteObject.toStockStakeHolder === null
                  ? ""
                  : historyOfCreditNoteObject.toStockStakeHolder,
              toStockStakeHolderGstNo:
                historyOfCreditNoteObject.toStockStakeHolderGstNo === null
                  ? ""
                  : historyOfCreditNoteObject.toStockStakeHolderGstNo,
              toStockStakeHolderGstType:
                historyOfCreditNoteObject.toStockStakeHolderGstType === null
                  ? ""
                  : historyOfCreditNoteObject.toStockStakeHolderGstType,

              taxableAmt:
                historyOfCreditNoteObject.taxableAmt === null
                  ? ""
                  : historyOfCreditNoteObject.taxableAmt.toFixed(2),
              taxAmt:
                historyOfCreditNoteObject.taxAmt === null
                  ? ""
                  : historyOfCreditNoteObject.taxAmt.toFixed(2),
              totalAmt:
                historyOfCreditNoteObject.totalAmt === null
                  ? ""
                  : historyOfCreditNoteObject.totalAmt.toFixed(2),
              remark:
                historyOfCreditNoteObject.remark === null
                  ? ""
                  : historyOfCreditNoteObject.remark,
              insertedUserId:
                historyOfCreditNoteObject.insertedUserId === null
                  ? ""
                  : historyOfCreditNoteObject.insertedUserId,
              insertDateTime:
                historyOfCreditNoteObject.insertDateTime === null
                  ? ""
                  : historyOfCreditNoteObject.insertDateTime,
              holderCreditNoteDetails:
                historyOfCreditNoteObject.holderCreditNoteDetails === null
                  ? ""
                  : historyOfCreditNoteObject.holderCreditNoteDetails,
              showGeneratedInvoice:
                historyOfCreditNoteObject.toStockStakeHolderGstType === null &&
                historyOfCreditNoteObject.toStockStakeHolderGstNo === null &&
                historyOfCreditNoteObject.acknoledgeNo === null &&
                historyOfCreditNoteObject.fromStockStakeHolderEinvoiceUsername ===
                  null &&
                historyOfCreditNoteObject.fromStockStakeHolderIsEinvoiceApplicable ===
                  null &&
                historyOfCreditNoteObject.fromStockStakeHolderEinvoicePassword ===
                  null
                  ? ""
                  : historyOfCreditNoteObject.einvoiceJson === "" ||
                    (historyOfCreditNoteObject.einvoiceJson === null &&
                      historyOfCreditNoteObject.toStockStakeHolderGstNo !==
                        "" &&
                      historyOfCreditNoteObject.fromStockStakeHolderIsEinvoiceApplicable ==
                        1 &&
                      historyOfCreditNoteObject.fromStockStakeHolderEinvoiceUsername !==
                        "" &&
                      historyOfCreditNoteObject.acknoledgeNo !== "" &&
                      historyOfCreditNoteObject.fromStockStakeHolderEinvoicePassword !==
                        "")
                  ? "0"
                  : "1",
            };
            return historyOfCreditNoteData;
          });
          dispatch(historyOfCreditNoteSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getHistoryOfCreditNoteByDateUpdated =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          creaditNoteHeader +
          "/history-by-date-new?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((historyOfCreditNoteObject, index) => {
            let historyOfCreditNoteData = {
              index: index + 1,
              id:
                historyOfCreditNoteObject.id === null
                  ? ""
                  : historyOfCreditNoteObject.id,
              acknoledgeNo:
                historyOfCreditNoteObject.acknoledgeNo === null
                  ? ""
                  : historyOfCreditNoteObject.acknoledgeNo,
              grnGvnHeaderId:
                historyOfCreditNoteObject.grnGvnHeaderId === null
                  ? ""
                  : historyOfCreditNoteObject.grnGvnHeaderId,
              claimId:
                historyOfCreditNoteObject.claimId === null
                  ? ""
                  : historyOfCreditNoteObject.claimId,
              creditNoteNo:
                historyOfCreditNoteObject.creditNoteNo === null
                  ? ""
                  : historyOfCreditNoteObject.creditNoteNo,
              date:
                historyOfCreditNoteObject.date === null
                  ? ""
                  : historyOfCreditNoteObject.date,
              einvoiceJson:
                historyOfCreditNoteObject.einvoiceJson === null
                  ? ""
                  : historyOfCreditNoteObject.einvoiceJson,
              toStockStakeHolderFirmName:
                historyOfCreditNoteObject.toStockStakeHolderFirmName === null
                  ? ""
                  : historyOfCreditNoteObject.toStockStakeHolderFirmName,
              toStockStakeHolderStateName:
                historyOfCreditNoteObject.toStockStakeHolderStateName === null
                  ? ""
                  : historyOfCreditNoteObject.toStockStakeHolderStateName,
              toStockStakeHolderAreaNames:
                historyOfCreditNoteObject.toStockStakeHolderAreaNames === null
                  ? ""
                  : historyOfCreditNoteObject.toStockStakeHolderAreaNames,
              ownerNameAndFirmName:
                historyOfCreditNoteObject.toStockStakeHolderOwnerName === null
                  ? ""
                  : historyOfCreditNoteObject.toStockStakeHolderFirmName +
                    " (" +
                    historyOfCreditNoteObject.toStockStakeHolderOwnerName +
                    ")",
              toStockStakeHolderOwnerName:
                historyOfCreditNoteObject.toStockStakeHolderOwnerName === null
                  ? ""
                  : historyOfCreditNoteObject.toStockStakeHolderOwnerName,

              fromStockStakeHolder:
                historyOfCreditNoteObject.fromStockStakeHolder === null
                  ? ""
                  : historyOfCreditNoteObject.fromStockStakeHolder,
              fromStockStakeHolderFirmName:
                historyOfCreditNoteObject.fromStockStakeHolderFirmName === null
                  ? ""
                  : historyOfCreditNoteObject.fromStockStakeHolderFirmName,
              fromStockStakeHolderOwnerName:
                historyOfCreditNoteObject.fromStockStakeHolderOwnerName === null
                  ? ""
                  : historyOfCreditNoteObject.fromStockStakeHolderOwnerName,
              fromStockStakeHolderEinvoiceUsername:
                historyOfCreditNoteObject.fromStockStakeHolderEinvoiceUsername ===
                null
                  ? ""
                  : historyOfCreditNoteObject.fromStockStakeHolderEinvoiceUsername,
              fromStockStakeHolderEinvoicePassword:
                historyOfCreditNoteObject.fromStockStakeHolderEinvoicePassword ===
                null
                  ? ""
                  : historyOfCreditNoteObject.fromStockStakeHolderEinvoicePassword,
              fromStockStakeHolderIsEinvoiceApplicable:
                historyOfCreditNoteObject.fromStockStakeHolderIsEinvoiceApplicable ===
                null
                  ? ""
                  : historyOfCreditNoteObject.fromStockStakeHolderIsEinvoiceApplicable,

              toStockStakeHolder:
                historyOfCreditNoteObject.toStockStakeHolder === null
                  ? ""
                  : historyOfCreditNoteObject.toStockStakeHolder,
              toStockStakeHolderGstNo:
                historyOfCreditNoteObject.toStockStakeHolderGstNo === null
                  ? ""
                  : historyOfCreditNoteObject.toStockStakeHolderGstNo,
              toStockStakeHolderGstType:
                historyOfCreditNoteObject.toStockStakeHolderGstType === null
                  ? ""
                  : historyOfCreditNoteObject.toStockStakeHolderGstType,

              taxableAmt:
                historyOfCreditNoteObject.taxableAmt === null
                  ? ""
                  : historyOfCreditNoteObject.taxableAmt.toFixed(2),
              taxAmt:
                historyOfCreditNoteObject.taxAmt === null
                  ? ""
                  : historyOfCreditNoteObject.taxAmt.toFixed(2),
              totalAmt:
                historyOfCreditNoteObject.totalAmt === null
                  ? ""
                  : historyOfCreditNoteObject.totalAmt.toFixed(2),
              remark:
                historyOfCreditNoteObject.remark === null
                  ? ""
                  : historyOfCreditNoteObject.remark,
              insertedUserId:
                historyOfCreditNoteObject.insertedUserId === null
                  ? ""
                  : historyOfCreditNoteObject.insertedUserId,
              insertDateTime:
                historyOfCreditNoteObject.insertDateTime === null
                  ? ""
                  : historyOfCreditNoteObject.insertDateTime,
              holderCreditNoteDetails:
                historyOfCreditNoteObject.holderCreditNoteDetails === null
                  ? ""
                  : historyOfCreditNoteObject.holderCreditNoteDetails,
              showGeneratedInvoice:
                historyOfCreditNoteObject.toStockStakeHolderGstType === null &&
                historyOfCreditNoteObject.toStockStakeHolderGstNo === null &&
                historyOfCreditNoteObject.acknoledgeNo === null &&
                historyOfCreditNoteObject.fromStockStakeHolderEinvoiceUsername ===
                  null &&
                historyOfCreditNoteObject.fromStockStakeHolderIsEinvoiceApplicable ===
                  null &&
                historyOfCreditNoteObject.fromStockStakeHolderEinvoicePassword ===
                  null
                  ? ""
                  : historyOfCreditNoteObject.einvoiceJson === "" ||
                    (historyOfCreditNoteObject.einvoiceJson === null &&
                      historyOfCreditNoteObject.toStockStakeHolderGstNo !==
                        "" &&
                      historyOfCreditNoteObject.fromStockStakeHolderIsEinvoiceApplicable ==
                        1 &&
                      historyOfCreditNoteObject.fromStockStakeHolderEinvoiceUsername !==
                        "" &&
                      historyOfCreditNoteObject.acknoledgeNo !== "" &&
                      historyOfCreditNoteObject.fromStockStakeHolderEinvoicePassword !==
                        "")
                  ? "0"
                  : "1",
            };
            return historyOfCreditNoteData;
          });
          dispatch(historyOfCreditNoteSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getHistoryOfCreditNote =
  ({ fromDate, toDate, holderIds }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          creaditNoteHeader +
          "/history?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate +
          "&holderIds=" +
          holderIds,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((historyOfCreditNoteObject, index) => {
            let historyOfCreditNoteData = {
              index: index + 1,
              id:
                historyOfCreditNoteObject.id === null
                  ? ""
                  : historyOfCreditNoteObject.id,
              grnGvnHeaderId:
                historyOfCreditNoteObject.grnGvnHeaderId === null
                  ? ""
                  : historyOfCreditNoteObject.grnGvnHeaderId,
              claimId:
                historyOfCreditNoteObject.claimId === null
                  ? ""
                  : historyOfCreditNoteObject.claimId,
              creditNoteNo:
                historyOfCreditNoteObject.creditNoteNo === null
                  ? ""
                  : historyOfCreditNoteObject.creditNoteNo,
              date:
                historyOfCreditNoteObject.date === null
                  ? ""
                  : historyOfCreditNoteObject.date,
              toStockStakeHolderFirmName:
                historyOfCreditNoteObject.toStockStakeHolderFirmName === null
                  ? ""
                  : historyOfCreditNoteObject.toStockStakeHolderFirmName,
              toStockStakeHolderStateName:
                historyOfCreditNoteObject.toStockStakeHolderStateName === null
                  ? ""
                  : historyOfCreditNoteObject.toStockStakeHolderStateName,
              toStockStakeHolderAreaNames:
                historyOfCreditNoteObject.toStockStakeHolderAreaNames === null
                  ? ""
                  : historyOfCreditNoteObject.toStockStakeHolderAreaNames,
              ownerNameAndFirmName:
                historyOfCreditNoteObject.toStockStakeHolderOwnerName === null
                  ? ""
                  : historyOfCreditNoteObject.toStockStakeHolderFirmName +
                    " (" +
                    historyOfCreditNoteObject.toStockStakeHolderOwnerName +
                    ")",
              toStockStakeHolderOwnerName:
                historyOfCreditNoteObject.toStockStakeHolderOwnerName === null
                  ? ""
                  : historyOfCreditNoteObject.toStockStakeHolderOwnerName,

              fromStockStakeHolder:
                historyOfCreditNoteObject.fromStockStakeHolder === null
                  ? ""
                  : historyOfCreditNoteObject.fromStockStakeHolder,
              fromStockStakeHolderFirmName:
                historyOfCreditNoteObject.fromStockStakeHolderFirmName === null
                  ? ""
                  : historyOfCreditNoteObject.fromStockStakeHolderFirmName,
              fromStockStakeHolderOwnerName:
                historyOfCreditNoteObject.fromStockStakeHolderOwnerName === null
                  ? ""
                  : historyOfCreditNoteObject.fromStockStakeHolderOwnerName,
              toStockStakeHolder:
                historyOfCreditNoteObject.toStockStakeHolder === null
                  ? ""
                  : historyOfCreditNoteObject.toStockStakeHolder,
              taxableAmt:
                historyOfCreditNoteObject.taxableAmt === null
                  ? ""
                  : historyOfCreditNoteObject.taxableAmt.toFixed(2),
              taxAmt:
                historyOfCreditNoteObject.taxAmt === null
                  ? ""
                  : historyOfCreditNoteObject.taxAmt.toFixed(2),
              totalAmt:
                historyOfCreditNoteObject.totalAmt === null
                  ? ""
                  : historyOfCreditNoteObject.totalAmt.toFixed(2),
              remark:
                historyOfCreditNoteObject.remark === null
                  ? ""
                  : historyOfCreditNoteObject.remark,
              insertedUserId:
                historyOfCreditNoteObject.insertedUserId === null
                  ? ""
                  : historyOfCreditNoteObject.insertedUserId,
              insertDateTime:
                historyOfCreditNoteObject.insertDateTime === null
                  ? ""
                  : historyOfCreditNoteObject.insertDateTime,
            };
            return historyOfCreditNoteData;
          });
          dispatch(historyOfCreditNoteSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const setHistoryOfCrn = () => async (dispatch) => {
  dispatch(historyOfCreditNoteSuccess({ row: [] }));
};
export const getCrnHeaderId =
  ({ crnHeaderId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: creaditNoteHeader + "/" + crnHeaderId,
      }).then(({ data, success }) => {
        if (success) {
          const crnHeaderObject = data.data;
          let crnHeaderData = {
            id: crnHeaderObject.id === null ? "" : crnHeaderObject.id,
            grnGvnHeaderId:
              crnHeaderObject.grnGvnHeaderId === null
                ? ""
                : crnHeaderObject.grnGvnHeaderId,
            claimId:
              crnHeaderObject.claimId === null ? "" : crnHeaderObject.claimId,
            creditNoteNo:
              crnHeaderObject.creditNoteNo === null
                ? ""
                : crnHeaderObject.creditNoteNo,
            date: crnHeaderObject.date === null ? "" : crnHeaderObject.date,
            fromStockStakeHolder:
              crnHeaderObject.fromStockStakeHolder === null
                ? ""
                : crnHeaderObject.fromStockStakeHolder,
            toStockStakeHolder:
              crnHeaderObject.toStockStakeHolder === null
                ? ""
                : crnHeaderObject.toStockStakeHolder,
            taxableAmt:
              crnHeaderObject.taxableAmt === null
                ? ""
                : crnHeaderObject.taxableAmt,
            taxAmt:
              crnHeaderObject.taxAmt === null ? "" : crnHeaderObject.taxAmt,
            totalAmt:
              crnHeaderObject.totalAmt === null ? "" : crnHeaderObject.totalAmt,
            remark:
              crnHeaderObject.remark === null ? "" : crnHeaderObject.remark,
            insertedUserId:
              crnHeaderObject.insertedUserId === null
                ? ""
                : crnHeaderObject.insertedUserId,
            insertDateTime:
              crnHeaderObject.insertDateTime === null
                ? ""
                : crnHeaderObject.insertDateTime,
            holderCreditNoteDetails:
              crnHeaderObject.holderCreditNoteDetails === null
                ? ""
                : crnHeaderObject.holderCreditNoteDetails.map(
                    (billObject, index) => {
                      let billData = {
                        index: index + 1,
                        id: billObject.id === null ? "" : billObject.id,
                        grnGvnDetail:
                          billObject.grnGvnDetail === null
                            ? ""
                            : billObject.grnGvnDetail,
                        product:
                          billObject.product === null ? "" : billObject.product,
                        productName:
                          billObject.product === null
                            ? ""
                            : billObject.product.name,
                        outletUom:
                          billObject.product === null
                            ? ""
                            : billObject.product.outletUom.name,
                        stakeHolderUom:
                          billObject.product === null
                            ? ""
                            : billObject.product.stakeHolderUom.name,
                        batchCode:
                          billObject.batchCode === null
                            ? ""
                            : billObject.batchCode,
                        rate:
                          billObject.rate === null
                            ? ""
                            : billObject.rate.toFixed(2),
                        qty:
                          billObject.qty === null
                            ? ""
                            : billObject.qty.toString(),
                        uom: billObject.uom === null ? "" : billObject.uom,
                        hsnCode:
                          billObject.hsnCode === null ? "" : billObject.hsnCode,
                        taxableAmt:
                          billObject.taxableAmt === null
                            ? ""
                            : billObject.taxableAmt.toFixed(2),
                        cgstAmt:
                          billObject.cgstAmt === null
                            ? ""
                            : billObject.cgstAmt.toFixed(2),
                        sgstAmt:
                          billObject.sgstAmt === null
                            ? ""
                            : billObject.sgstAmt.toFixed(2),
                        igstAmt:
                          billObject.igstAmt === null
                            ? ""
                            : billObject.igstAmt.toFixed(2),
                        totalAmt:
                          billObject.totalAmt === null
                            ? ""
                            : billObject.totalAmt.toFixed(2),
                        cgstPer:
                          billObject.cgstPer === null ? "" : billObject.cgstPer,

                        sgstPer:
                          billObject.sgstPer === null ? "" : billObject.sgstPer,
                        igstPer:
                          billObject.igstPer === null ? "" : billObject.igstPer,
                        insertedUserId:
                          billObject.insertedUserId === null
                            ? ""
                            : billObject.insertedUserId,
                        insertDateTime:
                          billObject.insertDateTime === null
                            ? ""
                            : billObject.insertDateTime,
                      };
                      return billData;
                    }
                  ),
          };

          dispatch(creditNoteHistoryViewSuccess({ row: crnHeaderData }));
          dispatch(
            creditNoteHistoryDetailSuccess({
              row: crnHeaderData.holderCreditNoteDetails,
            })
          );
          return { response: crnHeaderData, success };
        }
        return { response: {}, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getTodaysCreditNote = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: creaditNoteHeader + "/todays-crn",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((creditNoteListObject, index) => {
          let creditNoteListData = {
            index: index + 1,
            id: creditNoteListObject.id === null ? "" : creditNoteListObject.id,
            grnGvnHeaderId:
              creditNoteListObject.grnGvnHeaderId === null
                ? ""
                : creditNoteListObject.grnGvnHeaderId,
            creditNoteNo:
              creditNoteListObject.creditNoteNo === null
                ? ""
                : creditNoteListObject.creditNoteNo,
            date:
              creditNoteListObject.date === null
                ? ""
                : creditNoteListObject.date,
            fromStockStakeHolder:
              creditNoteListObject.fromStockStakeHolder === null
                ? ""
                : creditNoteListObject.fromStockStakeHolder,
            fromStockStakeHolderFirmName:
              creditNoteListObject.fromStockStakeHolderFirmName === null
                ? ""
                : creditNoteListObject.fromStockStakeHolderFirmName,
            fromStockStakeHolderOwnerName:
              creditNoteListObject.fromStockStakeHolderOwnerName === null
                ? ""
                : creditNoteListObject.fromStockStakeHolderOwnerName,
            toStockStakeHolder:
              creditNoteListObject.toStockStakeHolder === null
                ? ""
                : creditNoteListObject.toStockStakeHolder,
            toStockStakeHolderFirmName:
              creditNoteListObject.toStockStakeHolderFirmName === null
                ? ""
                : creditNoteListObject.toStockStakeHolderFirmName,
            toStockStakeHolderOwnerName:
              creditNoteListObject.toStockStakeHolderOwnerName === null
                ? ""
                : creditNoteListObject.toStockStakeHolderOwnerName,
            taxableAmt:
              creditNoteListObject.taxableAmt === null
                ? ""
                : creditNoteListObject.taxableAmt.toFixed(2),
            taxAmt:
              creditNoteListObject.taxAmt === null
                ? ""
                : creditNoteListObject.taxAmt.toFixed(2),
            totalAmt:
              creditNoteListObject.totalAmt === null
                ? ""
                : creditNoteListObject.totalAmt.toFixed(2),
            remark:
              creditNoteListObject.remark === null
                ? ""
                : creditNoteListObject.remark,
            insertedUserId:
              creditNoteListObject.insertedUserId === null
                ? ""
                : creditNoteListObject.insertedUserId,
            insertDateTime:
              creditNoteListObject.insertDateTime === null
                ? ""
                : creditNoteListObject.insertDateTime,
          };

          return creditNoteListData;
        });
        dispatch(creditNoteSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getCreditNoteByDateSearchList =
  ({ fromdate, todate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          creaditNoteHeader +
          "/generated?fromDate=" +
          fromdate +
          "&toDate=" +
          todate,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((creditNoteListObject, index) => {
            let creditNoteListData = {
              index: index + 1,
              id:
                creditNoteListObject.id === null ? "" : creditNoteListObject.id,
              grnGvnHeaderId:
                creditNoteListObject.grnGvnHeaderId === null
                  ? ""
                  : creditNoteListObject.grnGvnHeaderId,
              creditNoteNo:
                creditNoteListObject.creditNoteNo === null
                  ? ""
                  : creditNoteListObject.creditNoteNo,
              date:
                creditNoteListObject.date === null
                  ? ""
                  : creditNoteListObject.date,
              fromStockStakeHolder:
                creditNoteListObject.fromStockStakeHolder === null
                  ? ""
                  : creditNoteListObject.fromStockStakeHolder,
              fromStockStakeHolderFirmName:
                creditNoteListObject.fromStockStakeHolderFirmName === null
                  ? ""
                  : creditNoteListObject.fromStockStakeHolderFirmName,
              fromStockStakeHolderOwnerName:
                creditNoteListObject.fromStockStakeHolderOwnerName === null
                  ? ""
                  : creditNoteListObject.fromStockStakeHolderOwnerName,
              toStockStakeHolder:
                creditNoteListObject.toStockStakeHolder === null
                  ? ""
                  : creditNoteListObject.toStockStakeHolder,
              toStockStakeHolderFirmName:
                creditNoteListObject.toStockStakeHolderFirmName === null
                  ? ""
                  : creditNoteListObject.toStockStakeHolderFirmName,
              toStockStakeHolderOwnerName:
                creditNoteListObject.toStockStakeHolderOwnerName === null
                  ? ""
                  : creditNoteListObject.toStockStakeHolderOwnerName,
              taxableAmt:
                creditNoteListObject.taxableAmt === null
                  ? ""
                  : creditNoteListObject.taxableAmt.toFixed(2),
              taxAmt:
                creditNoteListObject.taxAmt === null
                  ? ""
                  : creditNoteListObject.taxAmt.toFixed(2),
              totalAmt:
                creditNoteListObject.totalAmt === null
                  ? ""
                  : creditNoteListObject.totalAmt.toFixed(2),
              remark:
                creditNoteListObject.remark === null
                  ? ""
                  : creditNoteListObject.remark,
              insertedUserId:
                creditNoteListObject.insertedUserId === null
                  ? ""
                  : creditNoteListObject.insertedUserId,
              insertDateTime:
                creditNoteListObject.insertDateTime === null
                  ? ""
                  : creditNoteListObject.insertDateTime,
            };

            return creditNoteListData;
          });
          dispatch(creditNoteSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getCreditNoteLatest = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/latest",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((creditNoteListObject, index) => {
          return {
            index: index + 1,
            transactionNo: creditNoteListObject.transactionNo,
            date: creditNoteListObject.date,
            totalAmt: creditNoteListObject.totalAmt,
            ...creditNoteListObject,
          };
        });
        dispatch(creditNoteLatestSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getCreditNoteById =
  ({ crnHeaderId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + crnHeaderId,
      }).then(({ data, success }) => {
        if (success) {
          const crnHeaderObject = data.data;
          let crnHeaderData = {
            id: crnHeaderObject.id === null ? "" : crnHeaderObject.id,
            stockStakeHolderId:
              crnHeaderObject.stockStakeHolderId === null
                ? ""
                : crnHeaderObject.stockStakeHolderId,
            transactionNo:
              crnHeaderObject.transactionNo === null
                ? ""
                : crnHeaderObject.transactionNo,
            outLet:
              crnHeaderObject.outLet === null ? "" : crnHeaderObject.outLet,
            firmName:
              crnHeaderObject.outLet === null
                ? ""
                : crnHeaderObject.outLet.firmName,
            date: crnHeaderObject.date === null ? "" : crnHeaderObject.date,
            taxableAmt:
              crnHeaderObject.taxableAmt === null
                ? ""
                : crnHeaderObject.taxableAmt,
            totalAmt:
              crnHeaderObject.totalAmt === null ? "" : crnHeaderObject.totalAmt,
            cgstAmt:
              crnHeaderObject.cgstAmt === null ? "" : crnHeaderObject.cgstAmt,
            sgstAmt:
              crnHeaderObject.sgstAmt === null ? "" : crnHeaderObject.sgstAmt,
            igstAmt:
              crnHeaderObject.igstAmt === null ? "" : crnHeaderObject.igstAmt,
            instockOutstock:
              crnHeaderObject.instockOutstock === null
                ? ""
                : crnHeaderObject.instockOutstock,
            remark:
              crnHeaderObject.remark === null ? "" : crnHeaderObject.remark,
            roundOff:
              crnHeaderObject.roundOff === null ? "" : crnHeaderObject.roundOff,
            creditNoteDetail:
              crnHeaderObject.creditNoteDetail === null
                ? ""
                : crnHeaderObject.creditNoteDetail.map((billObject, index) => {
                    let billData = {
                      index: index + 1,
                      id: billObject.id === null ? "" : billObject.id,
                      product:
                        billObject.product === null ? "" : billObject.product,
                      productName:
                        billObject.product === null
                          ? ""
                          : billObject.product.name,
                      outletUom:
                        billObject.product === null
                          ? ""
                          : billObject.product.outletUom.name,
                      stakeHolderUom:
                        billObject.product === null
                          ? ""
                          : billObject.product.stakeHolderUom.name,

                      rate:
                        billObject.rate === null
                          ? ""
                          : billObject.rate.toFixed(2),
                      qty:
                        billObject.qty === null
                          ? ""
                          : billObject.qty.toString(),
                      uom: billObject.uom === null ? "" : billObject.uom,
                      uomName:
                        billObject.uom === null ? "" : billObject.uom.name,
                      hsnCode:
                        billObject.hsnCode === null ? "" : billObject.hsnCode,
                      taxableAmt:
                        billObject.taxableAmt === null
                          ? ""
                          : billObject.taxableAmt.toFixed(2),
                      cgstAmt:
                        billObject.cgstAmt === null
                          ? ""
                          : billObject.cgstAmt.toFixed(2),
                      sgstAmt:
                        billObject.sgstAmt === null
                          ? ""
                          : billObject.sgstAmt.toFixed(2),
                      igstAmt:
                        billObject.igstAmt === null
                          ? ""
                          : billObject.igstAmt.toFixed(2),
                      totalAmt:
                        billObject.totalAmt === null
                          ? ""
                          : billObject.totalAmt.toFixed(2),
                      cgstPer:
                        billObject.cgstPer === null ? "" : billObject.cgstPer,

                      sgstPer:
                        billObject.sgstPer === null ? "" : billObject.sgstPer,
                      igstPer:
                        billObject.igstPer === null ? "" : billObject.igstPer,
                      batchCode:
                        billObject.batchCode === null
                          ? ""
                          : billObject.batchCode,
                      insertedUserId:
                        billObject.insertedUserId === null
                          ? ""
                          : billObject.insertedUserId,
                      insertDateTime:
                        billObject.insertDateTime === null
                          ? ""
                          : billObject.insertDateTime,
                    };
                    return billData;
                  }),
          };
          dispatch(creditNoteOutletHistoryViewSuccess({ row: crnHeaderData }));
          dispatch(
            creditNoteHistoryDetailSuccess({
              row: crnHeaderData.creditNoteDetail,
            })
          );
          return { response: crnHeaderData, success };
        }
        return { response: {}, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
