import React from "react";
import { connect } from "react-redux";
import { paymentReceiptDetailJson } from "../../DynamicFormsJson/Transaction/paymentReceiptDetail";
import { getPaymentByDate } from "../../Slice/paymentReciept.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { paymentDetailsTableColumns } from "../../tableColumns/table-columns";
import { showLoador, showNotification } from "../Landing/Landing.slice";

class PaymentDetailsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {},
        };
    }

    getCreditNoteListData = ({ fromDate, toDate }) => {
        const { getPaymentByDate, showLoador, showNotification } =
            this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            getPaymentByDate({
                fromDate: fromDate,
                toDate: toDate,
            }).then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };
    onSearchList = (data) => {
        this.setState({
            formData: data,
        });
        this.getCreditNoteListData({
            fromDate: data.fromDate,
            toDate: data.toDate,
        });
    };

    render() {
        const { paymentByDateList } = this.props;

        return (
            <>
                <DynamicMainScreen
                    showViewButtonInTable={true}
                    showEditIcon={false}
                    showDeleteIcon={false}
                    searchList={paymentReceiptDetailJson.searchList}
                    onSearchData={this.onSearchList}
                    screenTitle={paymentReceiptDetailJson.screenTitle}
                    showPdfDownload={false}
                    showExcelDownload={false}
                    isActiveURL={paymentReceiptDetailJson.isActiveURL}
                    showAddButton={false}
                    tableColumns={paymentDetailsTableColumns}
                    tableData={paymentByDateList.paymentByDate}
                    apiBaseURL={paymentReceiptDetailJson.apiBaseURL}
                    callApi={false}
                    getTableDataById={true}
                    showSearchBox={false}
                    showPegination={false}
                    formPathView={paymentReceiptDetailJson.formPathView}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    paymentByDateList: state.paymentReciept,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getPaymentByDate,
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetailsTable);
