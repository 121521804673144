import { useRef } from 'react';
import jsPDF from 'jspdf';

function App() {
	
	function generateHorizontalPageBreak({headerList}) {
	
		var columns = [
			{ title: "A", dataKey: "A" },
			{ title: "B", dataKey: "B" },
			{ title: "C", dataKey: "C" },
		];
		
		var rows = [
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
			{ A: "A", B: "B", C: "C" },
		];
		
		  var doc = new jsPDF('p', 'pt');
		  doc.setFontSize(20);
		  doc.setTextColor( 3, 169, 244);
		  //   doc.setFontStyle('normal');
		let xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth("Outlet Amount") * doc.internal.getFontSize() / 2);
		doc.text("Outlet Amount", xOffset, 35);
		doc.setFontSize(12);
		let yValue=50
		headerList.map((head,index)=>{
			if (index%2==0)   
{
				doc.setTextColor( 3, 169, 244);
				doc.text( head.label+" : ", 20, yValue, { maxWidth: 100 });
		
				doc.setTextColor( 0, 0, 0);
				doc.text(head.value, 130, yValue,{ maxWidth:headerList[index+1]!=undefined? 170:500 });
		
			}
			else{
				doc.setTextColor( 3, 169, 244);
				doc.text(head.label+" : ", 300, yValue, { maxWidth: 100 });
				
				doc.setTextColor( 0, 0, 0);
				doc.text(head.value, 400, yValue,{ maxWidth: 180 });
				yValue=yValue+30
			}
		})
	
		  doc.autoTable(columns, rows, {
			startY: doc.autoTableEndPosY() + yValue+30,
			margin: { horizontal: 20,top:70 },
			styles: { overflow: 'linebreak' },
			bodyStyles: { valign: 'top' },
			columnStyles: { email: { columnWidth: 'wrap' } },
			theme: "striped",
			showHead: "everyPage",
			didDrawPage: function (data) {
			  doc.setFontSize(20);
			  doc.setTextColor(40);
			  let pageInfo = doc.internal.getCurrentPageInfo();
			  console.log(pageInfo.pageNumber) // Logs your current page number.
if(pageInfo.pageNumber!=1){
  doc.text(' shraddha', 40, 30);
  doc.text(' Report of Distribution', 40, 50);
}
			  // Footer
			  var str = "Page " + doc.internal.getNumberOfPages();
		  
			  doc.setFontSize(10);
		  
			  // jsPDF 1.4+ uses getWidth, <1.4 uses .width
			  var pageSize = doc.internal.pageSize;
			  var pageHeight = pageSize.height
				? pageSize.height
				: pageSize.getHeight();
			  doc.text(str, data.settings.margin.left, pageHeight - 10);
			}
		  });
		
		
		  doc.save('repro.pdf');
		
		
	}
	function setThemeCustomStyle() {
		var doc = new jsPDF('p', 'pt', 'letter')
		// generate the above data table
		var body = [
				   [1, 'GIZMORE Multimedia Speaker with Remote Control, Black,Lorem Ipsum is simply dummy...', 75000, '2021'],
				   [2, 'Realme', 25000, '2022'],
				   [3, 'Oneplus', 30000, '2021'],
				   ]
		// New Header and Footer Data Include the table
		var y = 10;
		doc.setLineWidth(2);
		doc.text(200, y = y + 30, "Product detailed report");
		doc.autoTable({
			body: body,
			startY: 70,
			head:[['SL.No', 'Product Name', 'Price', 'Model']],
			headStyles :{lineWidth: 1,fillColor: [30, 212, 145],textColor: [255,255,255],
				},
			theme: 'grid',
			columnStyles: {
				0: {
					halign: 'right',
					cellWidth: 50,
					fillColor: [232, 252, 245],
				   },
				1: {
					halign: 'left',
					cellWidth: 380,
					fillColor: [232, 252, 245],
				   },
				2: {
					halign: 'right',
					cellWidth: 50,
					fillColor: [232, 252, 245],
				   },
				3: {
					halign: 'right',
					cellWidth: 50,
					fillColor: [232, 252, 245],
				   }
			},
		   })
		   // save the data to this file
		   doc.save('auto_table_theme_custom_styles');
	}
	
	return (
		<div>
			<button className="button" onClick={()=>generateHorizontalPageBreak({headerList:[
				{
				label:"dfkj sjdfj",
				value:"jsdhfkj skdhfk"
				}
			]})}>
				Generate PDF
			</button>
			{/* <div ref={reportTemplateRef}>
				<ReportTemplate />
			</div> */}
		</div>
	);
}

export default App;





// import React from 'react';
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';

// const generatePDF = () => {
// 	// Create a new jsPDF instance
// 	const doc = new jsPDF();

// 	// Define the table columns and rows
// 	const columns = ['ID', 'Name', 'Age'];
// 	const rows = [
// 		['Name', 'Age', 'Country'],
// 		['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'],
// 		['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'],
// 		['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'],
// 		['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'],
// 		['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'],
// 		['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'],
// 		['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'],
// 		['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'],
// 		['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'],
// 		['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'],
// 		['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'],
// 		['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'],
// 		['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'],
// 		['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'],
// 		['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'],
// 		['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'], ['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'], ['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'], ['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'], ['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'], ['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'],
// 		['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'],
// 		['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'],
// 		['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'],
// 		['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'],
// 		['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'], ['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'], ['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'], ['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'], ['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'], ['John Doe', 25, 'USA'],
// 		['Jane Smith', 30, 'Canada'],
// 		['Bob Johnson', 22, 'UK'],

// 	];


// 	// Define the custom header function
// 	const customHeader = function (data) {
// 		doc.setFontSize(18);
// 		doc.setTextColor(40);
// 		doc.setFont('helvetica', 'bold'); // Set font style
// 		doc.text('Custom Table Header', data.settings.margin.left, 20);
// 		doc.text('Custom Table Header', data.settings.margin.left, 20);

// 	};

// 	// Add the table to the PDF
// 	doc.autoTable({
// 		head: [columns],
// 		body: rows,
// 		startY: 30,
// 		showHead: 'firstPage',
// 		didDrawPage: customHeader, // Attach the custom header function
// 	});

// 	// Save the PDF
// 	doc.save('table.pdf');
// };

// const App = () => {
// 	return (
// 		<div>
// 			<button onClick={generatePDF}>Generate PDF</button>
// 		</div>
// 	);
// };

// export default App;


// $(function () {
// 	window.jsPDF = window.jspdf.jsPDF;
// 	applyPlugin(window.jsPDF);
  
// 	$('#Btn_1').dxButton({
// 	  text: 'Export to PDF',
// 	  onClick: () => {
// 		const pdfDoc = new jsPDF('p', 'pt', 'a4');
// 		const options = {
// 		  pdfDoc: pdfDoc,
// 		  component: $('#DataGrid').dxDataGrid('instance')
// 		};
  
// 		exportDataGrid(options).then(() => {
// 			pdfDoc.setFontSize(12);
// 			const pageCount = pdfDoc.internal.getNumberOfPages();
// 			for(let i = 1; i <= pageCount; i++) {
// 			  pdfDoc.setPage(i);
// 			  const pageSize = pdfDoc.internal.pageSize;
// 			  const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
// 			  const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
// 			  const header = 'Report 2014';
// 			  const footer = `Page ${i} of ${pageCount}`;
  
// 			  // Header
// 			  pdfDoc.text(header, 40, 15, { baseline: 'top' });
  
// 			  // Footer
// 			  pdfDoc.text(footer, pageWidth / 2 - (pdfDoc.getTextWidth(footer) / 2), pageHeight - 15, { baseline: 'bottom' });
// 		  }
// 		}).then(() => {
// 		  pdfDoc.save('filePDF.pdf');
// 		});
// 	  }
// 	});
  
// 	$('#DataGrid').dxDataGrid({
// 	  showBorders: true,
// 	  dataSource: orders,
// 	  keyExpr: "ID",
// 	  showBorders: true,
// 	  columns: [{
// 		dataField: "OrderNumber",
// 		width: 130,
// 		caption: "Invoice Number"
// 	  }, {
// 		dataField: "OrderDate",
// 		dataType: "date",
// 		width: 160
// 	  },
// 				"Employee", {
// 				  caption: "City",
// 				  dataField: "CustomerStoreCity"
// 				}, {
// 				  caption: "State",
// 				  dataField: "CustomerStoreState"
// 				}, {
// 				  dataField: "SaleAmount",
// 				  alignment: "right",
// 				  format: "currency"
// 				}
// 			   ]
// 	});
//   });
  
//   var orders = [{
// 	  "ID": 1,
// 	  "OrderNumber": 35703,
// 	  "OrderDate": "2014-04-10",
// 	  "SaleAmount": 11800,
// 	  "Terms": "15 Days",
// 	  "TotalAmount": 12175,
// 	  "CustomerStoreState": "California",
// 	  "CustomerStoreCity": "Los Angeles",
// 	  "Employee": "Harv Mudd"
//   }, {
// 	  "ID": 4,
// 	  "OrderNumber": 35711,
// 	  "OrderDate": "2014-01-12",
// 	  "SaleAmount": 16050,
// 	  "Terms": "15 Days",
// 	  "TotalAmount": 16550,
// 	  "CustomerStoreState": "California",
// 	  "CustomerStoreCity": "San Jose",
// 	  "Employee": "Jim Packard"
//   }, {
// 	  "ID": 5,
// 	  "OrderNumber": 35714,
// 	  "OrderDate": "2014-01-22",
// 	  "SaleAmount": 14750,
// 	  "Terms": "15 Days",
// 	  "TotalAmount": 15250,
// 	  "CustomerStoreState": "Nevada",
// 	  "CustomerStoreCity": "Las Vegas",
// 	  "Employee": "Harv Mudd"
//   }, {
// 	  "ID": 7,
// 	  "OrderNumber": 35983,
// 	  "OrderDate": "2014-02-07",
// 	  "SaleAmount": 3725,
// 	  "Terms": "15 Days",
// 	  "TotalAmount": 3850,
// 	  "CustomerStoreState": "Colorado",
// 	  "CustomerStoreCity": "Denver",
// 	  "Employee": "Todd Hoffman"
//   }, {
// 	  "ID": 9,
// 	  "OrderNumber": 36987,
// 	  "OrderDate": "2014-03-11",
// 	  "SaleAmount": 14200,
// 	  "Terms": "15 Days",
// 	  "TotalAmount": 14800,
// 	  "CustomerStoreState": "Utah",
// 	  "CustomerStoreCity": "Salt Lake City",
// 	  "Employee": "Clark Morgan"
//   }, {
// 	  "ID": 11,
// 	  "OrderNumber": 38466,
// 	  "OrderDate": "2014-03-01",
// 	  "SaleAmount": 7800,
// 	  "Terms": "15 Days",
// 	  "TotalAmount": 8200,
// 	  "CustomerStoreState": "California",
// 	  "CustomerStoreCity": "Los Angeles",
// 	  "Employee": "Harv Mudd"
//   }, {
// 	  "ID": 15,
// 	  "OrderNumber": 39874,
// 	  "OrderDate": "2014-02-04",
// 	  "SaleAmount": 9050,
// 	  "Terms": "30 Days",
// 	  "TotalAmount": 19100,
// 	  "CustomerStoreState": "Nevada",
// 	  "CustomerStoreCity": "Las Vegas",
// 	  "Employee": "Harv Mudd"
//   }, {
// 	  "ID": 18,
// 	  "OrderNumber": 42847,
// 	  "OrderDate": "2014-02-15",
// 	  "SaleAmount": 20400,
// 	  "Terms": "30 Days",
// 	  "TotalAmount": 20800,
// 	  "CustomerStoreState": "Wyoming",
// 	  "CustomerStoreCity": "Casper",
// 	  "Employee": "Todd Hoffman"
//   }, {
// 	  "ID": 19,
// 	  "OrderNumber": 43982,
// 	  "OrderDate": "2014-05-29",
// 	  "SaleAmount": 6050,
// 	  "Terms": "30 Days",
// 	  "TotalAmount": 6250,
// 	  "CustomerStoreState": "Utah",
// 	  "CustomerStoreCity": "Salt Lake City",
// 	  "Employee": "Clark Morgan"
//   }, {
// 	  "ID": 29,
// 	  "OrderNumber": 56272,
// 	  "OrderDate": "2014-02-06",
// 	  "SaleAmount": 15850,
// 	  "Terms": "30 Days",
// 	  "TotalAmount": 16350,
// 	  "CustomerStoreState": "Utah",
// 	  "CustomerStoreCity": "Salt Lake City",
// 	  "Employee": "Clark Morgan"
//   }, {
// 	  "ID": 30,
// 	  "OrderNumber": 57429,
// 	  "OrderDate": "2013-12-31",
// 	  "SaleAmount": 11050,
// 	  "Terms": "30 Days",
// 	  "TotalAmount": 11400,
// 	  "CustomerStoreState": "Arizona",
// 	  "CustomerStoreCity": "Phoenix",
// 	  "Employee": "Clark Morgan"
//   }, {
// 	  "ID": 32,
// 	  "OrderNumber": 58292,
// 	  "OrderDate": "2014-05-13",
// 	  "SaleAmount": 13500,
// 	  "Terms": "15 Days",
// 	  "TotalAmount": 13800,
// 	  "CustomerStoreState": "California",
// 	  "CustomerStoreCity": "Los Angeles",
// 	  "Employee": "Harv Mudd"
//   }, {
// 	  "ID": 36,
// 	  "OrderNumber": 62427,
// 	  "OrderDate": "2014-01-27",
// 	  "SaleAmount": 23500,
// 	  "Terms": "15 Days",
// 	  "TotalAmount": 24000,
// 	  "CustomerStoreState": "Nevada",
// 	  "CustomerStoreCity": "Las Vegas",
// 	  "Employee": "Harv Mudd"
//   }, {
// 	  "ID": 39,
// 	  "OrderNumber": 65977,
// 	  "OrderDate": "2014-02-05",
// 	  "SaleAmount": 2550,
// 	  "Terms": "15 Days",
// 	  "TotalAmount": 2625,
// 	  "CustomerStoreState": "Wyoming",
// 	  "CustomerStoreCity": "Casper",
// 	  "Employee": "Todd Hoffman"
//   }, {
// 	  "ID": 40,
// 	  "OrderNumber": 66947,
// 	  "OrderDate": "2014-03-23",
// 	  "SaleAmount": 3500,
// 	  "Terms": "15 Days",
// 	  "TotalAmount": 3600,
// 	  "CustomerStoreState": "Utah",
// 	  "CustomerStoreCity": "Salt Lake City",
// 	  "Employee": "Clark Morgan"
//   }, {
// 	  "ID": 42,
// 	  "OrderNumber": 68428,
// 	  "OrderDate": "2014-04-10",
// 	  "SaleAmount": 10500,
// 	  "Terms": "15 Days",
// 	  "TotalAmount": 10900,
// 	  "CustomerStoreState": "California",
// 	  "CustomerStoreCity": "Los Angeles",
// 	  "Employee": "Harv Mudd"
//   }, {
// 	  "ID": 43,
// 	  "OrderNumber": 69477,
// 	  "OrderDate": "2014-03-09",
// 	  "SaleAmount": 14200,
// 	  "Terms": "15 Days",
// 	  "TotalAmount": 14500,
// 	  "CustomerStoreState": "California",
// 	  "CustomerStoreCity": "Anaheim",
// 	  "Employee": "Harv Mudd"
//   }, {
// 	  "ID": 46,
// 	  "OrderNumber": 72947,
// 	  "OrderDate": "2014-01-14",
// 	  "SaleAmount": 13350,
// 	  "Terms": "30 Days",
// 	  "TotalAmount": 13650,
// 	  "CustomerStoreState": "Nevada",
// 	  "CustomerStoreCity": "Las Vegas",
// 	  "Employee": "Harv Mudd"
//   }, {
// 	  "ID": 47,
// 	  "OrderNumber": 73088,
// 	  "OrderDate": "2014-03-25",
// 	  "SaleAmount": 8600,
// 	  "Terms": "30 Days",
// 	  "TotalAmount": 8850,
// 	  "CustomerStoreState": "Nevada",
// 	  "CustomerStoreCity": "Reno",
// 	  "Employee": "Clark Morgan"
//   }, {
// 	  "ID": 50,
// 	  "OrderNumber": 76927,
// 	  "OrderDate": "2014-04-27",
// 	  "SaleAmount": 9800,
// 	  "Terms": "30 Days",
// 	  "TotalAmount": 10050,
// 	  "CustomerStoreState": "Utah",
// 	  "CustomerStoreCity": "Salt Lake City",
// 	  "Employee": "Clark Morgan"
//   }, {
// 	  "ID": 51,
// 	  "OrderNumber": 77297,
// 	  "OrderDate": "2014-04-30",
// 	  "SaleAmount": 10850,
// 	  "Terms": "30 Days",
// 	  "TotalAmount": 11100,
// 	  "CustomerStoreState": "Arizona",
// 	  "CustomerStoreCity": "Phoenix",
// 	  "Employee": "Clark Morgan"
//   }, {
// 	  "ID": 56,
// 	  "OrderNumber": 84744,
// 	  "OrderDate": "2014-02-10",
// 	  "SaleAmount": 4650,
// 	  "Terms": "30 Days",
// 	  "TotalAmount": 4750,
// 	  "CustomerStoreState": "Nevada",
// 	  "CustomerStoreCity": "Las Vegas",
// 	  "Employee": "Harv Mudd"
//   }, {
// 	  "ID": 57,
// 	  "OrderNumber": 85028,
// 	  "OrderDate": "2014-05-17",
// 	  "SaleAmount": 2575,
// 	  "Terms": "30 Days",
// 	  "TotalAmount": 2625,
// 	  "CustomerStoreState": "Nevada",
// 	  "CustomerStoreCity": "Reno",
// 	  "Employee": "Clark Morgan"
//   }, {
// 	  "ID": 59,
// 	  "OrderNumber": 87297,
// 	  "OrderDate": "2014-04-21",
// 	  "SaleAmount": 14200,
// 	  "Terms": "30 Days",
// 	  "TotalAmount": 0,
// 	  "CustomerStoreState": "Wyoming",
// 	  "CustomerStoreCity": "Casper",
// 	  "Employee": "Todd Hoffman"
//   }, {
// 	  "ID": 60,
// 	  "OrderNumber": 88027,
// 	  "OrderDate": "2014-02-14",
// 	  "SaleAmount": 13650,
// 	  "Terms": "30 Days",
// 	  "TotalAmount": 14050,
// 	  "CustomerStoreState": "Utah",
// 	  "CustomerStoreCity": "Salt Lake City",
// 	  "Employee": "Clark Morgan"
//   }, {
// 	  "ID": 65,
// 	  "OrderNumber": 94726,
// 	  "OrderDate": "2014-05-22",
// 	  "SaleAmount": 20500,
// 	  "Terms": "15 Days",
// 	  "TotalAmount": 20800,
// 	  "CustomerStoreState": "California",
// 	  "CustomerStoreCity": "San Jose",
// 	  "Employee": "Jim Packard"
//   }, {
// 	  "ID": 66,
// 	  "OrderNumber": 95266,
// 	  "OrderDate": "2014-03-10",
// 	  "SaleAmount": 9050,
// 	  "Terms": "15 Days",
// 	  "TotalAmount": 9250,
// 	  "CustomerStoreState": "Nevada",
// 	  "CustomerStoreCity": "Las Vegas",
// 	  "Employee": "Harv Mudd"
//   }, {
// 	  "ID": 69,
// 	  "OrderNumber": 98477,
// 	  "OrderDate": "2014-01-01",
// 	  "SaleAmount": 23500,
// 	  "Terms": "15 Days",
// 	  "TotalAmount": 23800,
// 	  "CustomerStoreState": "Wyoming",
// 	  "CustomerStoreCity": "Casper",
// 	  "Employee": "Todd Hoffman"
//   }, {
// 	  "ID": 70,
// 	  "OrderNumber": 99247,
// 	  "OrderDate": "2014-02-08",
// 	  "SaleAmount": 2100,
// 	  "Terms": "15 Days",
// 	  "TotalAmount": 2150,
// 	  "CustomerStoreState": "Utah",
// 	  "CustomerStoreCity": "Salt Lake City",
// 	  "Employee": "Clark Morgan"
//   }, {
// 	  "ID": 78,
// 	  "OrderNumber": 174884,
// 	  "OrderDate": "2014-04-10",
// 	  "SaleAmount": 7200,
// 	  "Terms": "30 Days",
// 	  "TotalAmount": 7350,
// 	  "CustomerStoreState": "Colorado",
// 	  "CustomerStoreCity": "Denver",
// 	  "Employee": "Todd Hoffman"
//   }, {
// 	  "ID": 81,
// 	  "OrderNumber": 188877,
// 	  "OrderDate": "2014-02-11",
// 	  "SaleAmount": 8750,
// 	  "Terms": "30 Days",
// 	  "TotalAmount": 8900,
// 	  "CustomerStoreState": "Arizona",
// 	  "CustomerStoreCity": "Phoenix",
// 	  "Employee": "Clark Morgan"
//   }, {
// 	  "ID": 82,
// 	  "OrderNumber": 191883,
// 	  "OrderDate": "2014-02-05",
// 	  "SaleAmount": 9900,
// 	  "Terms": "30 Days",
// 	  "TotalAmount": 10150,
// 	  "CustomerStoreState": "California",
// 	  "CustomerStoreCity": "Los Angeles",
// 	  "Employee": "Harv Mudd"
//   }, {
// 	  "ID": 83,
// 	  "OrderNumber": 192474,
// 	  "OrderDate": "2014-01-21",
// 	  "SaleAmount": 12800,
// 	  "Terms": "30 Days",
// 	  "TotalAmount": 13100,
// 	  "CustomerStoreState": "California",
// 	  "CustomerStoreCity": "Anaheim",
// 	  "Employee": "Harv Mudd"
//   }, {
// 	  "ID": 84,
// 	  "OrderNumber": 193847,
// 	  "OrderDate": "2014-03-21",
// 	  "SaleAmount": 14100,
// 	  "Terms": "30 Days",
// 	  "TotalAmount": 14350,
// 	  "CustomerStoreState": "California",
// 	  "CustomerStoreCity": "San Diego",
// 	  "Employee": "Harv Mudd"
//   }, {
// 	  "ID": 85,
// 	  "OrderNumber": 194877,
// 	  "OrderDate": "2014-03-06",
// 	  "SaleAmount": 4750,
// 	  "Terms": "30 Days",
// 	  "TotalAmount": 4950,
// 	  "CustomerStoreState": "California",
// 	  "CustomerStoreCity": "San Jose",
// 	  "Employee": "Jim Packard"
//   }, {
// 	  "ID": 86,
// 	  "OrderNumber": 195746,
// 	  "OrderDate": "2014-05-26",
// 	  "SaleAmount": 9050,
// 	  "Terms": "30 Days",
// 	  "TotalAmount": 9250,
// 	  "CustomerStoreState": "Nevada",
// 	  "CustomerStoreCity": "Las Vegas",
// 	  "Employee": "Harv Mudd"
//   }, {
// 	  "ID": 87,
// 	  "OrderNumber": 197474,
// 	  "OrderDate": "2014-03-02",
// 	  "SaleAmount": 6400,
// 	  "Terms": "30 Days",
// 	  "TotalAmount": 6600,
// 	  "CustomerStoreState": "Nevada",
// 	  "CustomerStoreCity": "Reno",
// 	  "Employee": "Clark Morgan"
//   }, {
// 	  "ID": 88,
// 	  "OrderNumber": 198746,
// 	  "OrderDate": "2014-05-09",
// 	  "SaleAmount": 15700,
// 	  "Terms": "30 Days",
// 	  "TotalAmount": 16050,
// 	  "CustomerStoreState": "Colorado",
// 	  "CustomerStoreCity": "Denver",
// 	  "Employee": "Todd Hoffman"
//   }, {
// 	  "ID": 91,
// 	  "OrderNumber": 214222,
// 	  "OrderDate": "2014-02-08T00:00:00",
// 	  "SaleAmount": 11050,
// 	  "Terms": "30 Days",
// 	  "TotalAmount": 11250,
// 	  "CustomerStoreState": "Arizona",
// 	  "CustomerStoreCity": "Phoenix",
// 	  "Employee": "Clark Morgan"
//   }];