import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { compose } from "@reduxjs/toolkit";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import AutoComplete from "../../components/Comman/AutoComplete";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { TitleAndBreadCrum } from "../../components/Comman/TitleAndBreadCrum";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import {
  getActiveLanguage,
  getLanguageJson,
} from "../../Slice/multilingual.slice";
import {
  getActiveProduct,
  getChangeActiveProduct,
} from "../../Slice/product.slice";
import { getAreaByUserType } from "../../Slice/area.slice";
import { getBeatByArea, getChangeActiveBeat } from "../../Slice/beat.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";

class AreaMultilingualForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      area: "",
    };
  }

  async componentDidMount() {
    const {
      showLoador,
      showNotification,
      getActiveLanguage,
      getAreaByUserType,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getActiveLanguage().then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getAreaByUserType().then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  handleChange = (e, row) => {
    const { name, value } = e.target;
    const { beatList, getChangeActiveBeat } = this.props;
    let newList = [];
    beatList.beat.map((beatObject) => {
      if (beatObject.id == row.id) {
        if (name == "Marathi") {
          beatObject = {
            ...beatObject,
            languageMarathi: value,
          };
        } else if (name == "Hindi") {
          beatObject = {
            ...beatObject,
            languageHindi: value,
          };
        } else {
          beatObject = {
            ...beatObject,
            languageEnglish: value,
          };
        }
        newList.push(beatObject);
      } else {
        newList.push(beatObject);
      }
    });
    getChangeActiveBeat({ row: newList });
  };
  submitHandler = () => {
    const { beatList, showNotification } = this.props;
    const { area } = this.state;
    let newDataToSave = "";
    let saveJson = [];
    beatList.beat.map((beatObject) => {
      if (beatObject.languageMarathi !== "") {
        let beatSaveObject = {
          id: beatObject.id,
          language: {
            id: "0",
          },
          name: beatObject.languageMarathi,
        };
        saveJson.push(beatSaveObject);
      }
      if (beatObject.languageHindi !== "") {
        let beatSaveObject = {
          id: beatObject.id,
          language: {
            id: "1",
          },
          name: beatObject.languageHindi,
        };
        saveJson.push(beatSaveObject);
      }
      if (beatObject.languageEnglish !== "") {
        let beatSaveObject = {
          id: beatObject.id,
          language: {
            id: "2",
          },
          name: beatObject.languageEnglish,
        };
        saveJson.push(beatSaveObject);
      }
    }),
      (newDataToSave = {
        type: 2,
        areaBeatId: area,
        json: saveJson,
      });
    if (saveJson.length !== 0) {
      swal({
        title: "Are you sure?",
        text: myConstClass.saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.saveHandler(newDataToSave);
        }
      });
    } else {
      showNotification({
        msg: myConstClass.enterValueMsg,
        severity: "error",
      });
    }
  };
  saveHandler = (newDataToSave) => {
    const { showNotification, showLoador, navigate } = this.props;
    showLoador({ loador: true });
    apiPost({
      url: endpoint.multilingual + "-json",
      postBody: newDataToSave,
    }).then(({ success }) => {
      showLoador({ loador: false });
      if (success) {
        showNotification({
          msg: "Data saved successfully",
        });
        window.location.replace("/area-multilingual-form");
      } else {
        showNotification({ msg: serverMsg, severity: "error" });
      }
    });
  };
  goBack = () => {
    this.props.navigate(-1);
  };
  onAutocompleteChange = (name, newValue) => {
    const {
      getBeatByArea,
      getChangeActiveBeat,
      getLanguageJson,
      showLoador,
      showNotification,
    } = this.props;
    if (newValue != null) {
      this.setState({
        [name]: newValue,
      });
      showLoador({ loador: true });
      getBeatByArea({ areaId: newValue }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          getLanguageJson({ id: newValue }).then(
            ({ responseData, success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              } else {
                let row = [];
                if (responseData !== null && responseData.length !== 0) {
                  if (
                    responseData[0].jsonList !== null &&
                    responseData[0].jsonList.length !== 0
                  ) {
                    response.map((beatData) => {
                      responseData[0].jsonList.map((jsonData) => {
                        if (beatData.id == jsonData.id) {
                          if (
                            jsonData.language !== null &&
                            jsonData.language.id !== ""
                          ) {
                            beatData = {
                              ...beatData,
                              languageMarathi:
                                jsonData.language.id == "0"
                                  ? jsonData.name
                                  : beatData.languageMarathi,
                              languageHindi:
                                jsonData.language.id == "1"
                                  ? jsonData.name
                                  : beatData.languageHindi,
                              languageEnglish:
                                jsonData.language.id == "2"
                                  ? jsonData.name
                                  : beatData.languageEnglish,
                            };
                          }
                        }
                      });
                      row.push(beatData);
                    });
                    getChangeActiveBeat({ row: row });
                  }
                }
              }
            }
          );
        }
      });
    } else {
      this.setState({
        [name]: "",
      });
    }
  };
  render() {
    const { multilingualList, beatList, areaList } = this.props;

    const { area } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <Grid container justifyContent="flex-start" direction="row">
            <TitleAndBreadCrum title={"Language Json For Area"} />
          </Grid>
        </Paper>
        <br />
        <Paper
          sx={{
            p: 2,
            pt: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            spacing={1}
            justifyContent="space-evenly"
          >
            {" "}
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} sm={12} md={12}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16 }}
                  label=" *Area"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <AutoComplete
                  keyColName={"id"}
                  name={"area"}
                  value={area}
                  options={areaList.area}
                  placeHolder="Select Area"
                  onChange={this.onAutocompleteChange}
                  isError={false}
                  errorText={" "}
                />
              </Grid>
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              md={6}
              lg={6}
              container
              justifyContent="space-evenly"
            ></Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" width="10%">
                    Sr. No.
                  </TableCell>
                  <TableCell width="30%">Beat Name</TableCell>
                  {multilingualList.language.map((languageObject) => {
                    return (
                      <>
                        <TableCell align="center" width="10%">
                          {languageObject.name}
                        </TableCell>
                      </>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {beatList.beat.map((row, index) => (
                  <>
                    <TableRow key={row.beatName}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell>{row.beatName}</TableCell>
                      {multilingualList.language.map((languageObject) => {
                        return (
                          <>
                            <TableCell>
                              <TextFieldCompo
                                color="primary"
                                name={languageObject.name}
                                placeHolder={
                                  "Enter " +
                                  languageObject.name +
                                  " Transalation"
                                }
                                size="small"
                                value={
                                  languageObject.name == row.languageMarathiName
                                    ? row.languageMarathi
                                    : languageObject.name ==
                                      row.languageHindiName
                                    ? row.languageHindi
                                    : row.languageEnglish
                                }
                                onChange={(event) =>
                                  this.handleChange(event, row)
                                }
                                fullWidth
                                helperText={""}
                              />
                            </TableCell>
                          </>
                        );
                      })}
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          &nbsp;&nbsp; &nbsp;&nbsp;
          <div align="right">
            <ButtonCompo
              size="button"
              type="button"
              variant="outlined"
              name="Cancel"
              onClick={this.goBack}
            />
            &nbsp;&nbsp;
            <ButtonCompo
              size="button"
              type="button"
              variant="contained"
              name="Submit"
              onClick={this.submitHandler}
            />
          </div>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  multilingualList: state.multilingual,
  productList: state.product,
  areaList: state.area,
  beatList: state.beat,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getActiveLanguage,
  getActiveProduct,
  getChangeActiveProduct,
  getLanguageJson,
  getAreaByUserType,
  getBeatByArea,
  getChangeActiveBeat,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AreaMultilingualForm);
