import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { stateJson } from "../../../DynamicFormsJson/MastersJSON/state";
import { getRegionNew } from "../../../Slice/region.slice";
import {
    showLoador,
    showNotification,
} from "../../Landing/Landing.slice";
import { serverMsg ,noInternetMsg,} from "../../../config/messageconstant";

class StateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicMasterData: {
                regionDetail: this.props.regionList?.region,
            },
        };
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.regionList !== nextProps.regionList) {
            if (this.props.regionList?.region !== nextProps.regionList?.region) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        regionDetail: nextProps.regionList?.region,
                    },
                });
            }
        }
    }
   async componentDidMount() {
        const { getRegionNew, showLoador, showNotification } = this.props;
        if (navigator.onLine) {
        showLoador({ loador: true });
        await getRegionNew().then(({ success }) => {
            showLoador({ loador: false });
            if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
            }
        });
    }
    else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
    }

    render() {
        const{dynamicMasterData}=this.state;
        return (
            <>
                <DynamicForm
                formPath={stateJson.formPath}
                    screenTitle={stateJson.screenTitle}
                    fieldMeta={stateJson.fieldMeta}
                    tableColumnsToFilter={stateJson.tableColumnsToFilter}
                    apiBaseURL={stateJson.apiBaseURL}
                    baseIdColumn={stateJson.baseIdColumn}
                    dynamicMasterData={dynamicMasterData}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    regionList: state.region,

});
const mapDispatchToProps = {
    getRegionNew,
    showLoador,
    showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(StateForm);
