import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Stack,
    Pagination,
} from "@mui/material";
import { Box, Grid, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { onToggle } from "../../CommonActions/common.slice";
import AutoComplete from "../../components/Comman/AutoComplete";
import MonthCalender from "../../components/MonthCalender";
import { serverMsg } from "../../config/messageconstant";
import * as myConstClass from "../../config/messageconstant";
import { apiGet, apiPost } from "../../utils/api_service";

import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import "../../CSS/app.min.css";
import "../../CSS/bootstrap.min.css";
import "../../CSS/custom.min.css";
import "../../CSS/mermaid.min.css";
import "../../CSS/slickDashboard.css";
import MainCard from "../DashbourdChanges/MainCard";
import NoDataFound from "../DashbourdChanges/NoDataFound";
import OrdersTable from "../DashbourdChanges/OrdersTable";
import { getMonthYearTargetForSales } from "../../Slice/adminDashboard.slice";
import {
    showLoador,
    showNotification,
} from "../../Pages/Landing/Landing.slice";
import {
    getAreaListFromYearAndMonth,
    getCurrentYearTarget,
    getOrderListFromYearAndMonth,
    getProductListFromYearAndMonth,
    getRegionListFromYearAndMonth,
    getSalesListFromYearAndMonth,
    getTillMonthYearTarget,
    getZoneListFromYearAndMonth,
    getMonthYearTarget,
    getSalesTeamInfo,
    setRegionListFromYearAndMonth,
} from "../../Slice/adminDashboard.slice";
import { getAreaByUserType } from "../../Slice/area.slice";
import { getZoneByUserType } from "../../Slice/zone.slice";
import { getStockStakeHolderTypeForList } from "../../Slice/stockStakeHolderType.slice";
import LineChart from "../../components/Line Chart";
import { cyanBlue, yellowishBrownColor } from "../../config/ColorObj";
import MonthlyBarChart from "../DashbourdChanges/MonthlyBarChart";
import image4 from "../../Images/image4.png";
import image5 from "../../Images/image5.png";
import image6 from "../../Images/image6.png";
import image7BgClr from "../../Images/image7BgClr.png";
import target1 from "../../Images/target1.png";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { ConvertAmount } from "../../components/Comman/ConvertValueWithDenominator";
import SearchBar from "../../components/Search";
import endpoint from "../../config/endpoints";



class StockHolderDashboard extends Component {
    toggleDrawer = () => {
        this.props.onToggle();
    };
    state = {
        showFeedback: false,
        showPreview: false,
        date1: new Date(),
        newDate: "",
        currentDate: new Date(),
        curDate: new Date(),
        dob: "",
        dobDay: "",
        dobMonth: "",
        currenDate: "",
        currentMonth: "",
        timeTableList: [],
        subject: "",
        batch: "",
        yearDetail: "",
        semester: "",
        division: "",
        pieChartData: [],
        isLoading: false,
        showLoader: false,
        profile: "",
        nameFirstLetter: "",
        showZone: false,
        showArea: false,
        date: "",
        saleDate: "",
        productArea: "",
        productZone: "",
        outletArea: "",
        outletZone: "",
        outletDate: "",
        monthDate: "",
        yearDate: "",
        type: "",
        saleMonth: dayjs(),
        saleYear: "",
        month: "",
        year: "",
        searchValue: "",
        searchValueForZone: "",
        searchValueForSalesRegion: "",
        searchValueForZoneDetails: "",
        searchValueForAreaDetails: "",
        searchValuePersonWiseSales: "",
        searchValueForProduct: "",
        searchValueForOutlets: "",
        regionListFromYearAndMonthData: [],
        regionListFromYearAndMonthDataForZone: [],
        regionListFromYearAndMonthDataForSalesRegion: [],
        zoneDetailsData: [],
        areaDetailsData: [],
        personWiseSalesData: [],
        topPerformingProductData: [],
        topPerformingOutletData: [],
        salesTeamData: [],
        formErrors: {},
        graphList: [],
        radioValue: [],
        lineGraphData: [],
        salesGraphList: [],
        sellBillList: [],
        monthList: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ],
        columns: [
            { id: "name", label: "Name", minWidth: 170 },
            { id: "code", label: "ISO\u00a0Code", minWidth: 100 },
            {
                id: "population",
                label: "Population",
                minWidth: 170,
                align: "right",
                format: (value) => value.toLocaleString("en-US"),
            },
            {
                id: "size",
                label: "Size\u00a0(km\u00b2)",
                minWidth: 170,
                align: "right",
                format: (value) => value.toLocaleString("en-US"),
            },
            {
                id: "density",
                label: "Density",
                minWidth: 170,
                align: "right",
                format: (value) => value.toFixed(2),
            },
        ],
        rows: [
            this.createData("India", "IN", 1324171354, 3287263),
            this.createData("China", "CN", 1403500365, 9596961),
            this.createData("Italy", "IT", 60483973, 301340),
            this.createData("United States", "US", 327167434, 9833520),
            this.createData("Canada", "CA", 37602103, 9984670),
            this.createData("Australia", "AU", 25475400, 7692024),
            this.createData("Germany", "DE", 83019200, 357578),
            this.createData("Ireland", "IE", 4857000, 70273),
            this.createData("Mexico", "MX", 126577691, 1972570),
            this.createData("Japan", "JP", 126317000, 377973),
            this.createData("France", "FR", 67022000, 640679),
            this.createData("United Kingdom", "GB", 67545757, 242495),
            this.createData("Russia", "RU", 146793744, 17098246),
            this.createData("Nigeria", "NG", 200962417, 923768),
            this.createData("Brazil", "BR", 210147125, 8515767),
        ],
        rowsPerPage: 10,
        page: 0,
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
        });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: +event.target.value,
            page: 0,
        });
    };

    createData(name, code, population, size) {
        const density = population / size;
        return { name, code, population, size, density };
    }

    async componentDidMount() {
        const {
            getCurrentYearTarget,
            getTillMonthYearTarget,
            showLoador,
            showNotification,
            getStockStakeHolderTypeForList,
            getRegionListFromYearAndMonth,
            getOrderListFromYearAndMonth,
            getProductListFromYearAndMonth,
            getSalesTeamInfo,
            getSalesListFromYearAndMonth,
            getZoneListFromYearAndMonth,
            getAreaListFromYearAndMonth,
            getAreaByUserType,
            getZoneByUserType,
            getMonthYearTarget,
            getMonthYearTargetForSales,
        } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            await getAreaByUserType().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });

            showLoador({ loador: true });
            await getZoneByUserType().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });

            showLoador({ loador: true });
            await getCurrentYearTarget().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    // showNotification({ msg: serverMsg, severity: "error" });
                }
            });
            showLoador({ loador: true });
            await getTillMonthYearTarget().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    // showNotification({ msg: serverMsg, severity: "error" });
                }
            });
            //   showLoador({ loador: true });
            await getStockStakeHolderTypeForList().then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    // showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    if (response.length !== 0) {
                        showLoador({ loador: true });
                        this.setState({
                            type: response[0].id,
                        });
                        getSalesListFromYearAndMonth({
                            month: dayjs().format("MM"),
                            year: dayjs().format("YYYY"),
                            stockStakeHolderTypeId: response[0].id,
                        }).then(({ response, success }) => {
                            showLoador({ loador: false });
                            if (!success) {
                                // showNotification({ msg: serverMsg, severity: "error" });
                            } else {
                                getMonthYearTargetForSales({
                                    salesTeamId: response[0].id,
                                }).then(({ response, success }) => {
                                    showLoador({ loador: false });
                                    if (!success) {
                                        // showNotification({ msg: serverMsg, severity: "error" });
                                    } else {
                                        let lineGraphDataNew = [];
                                        for (var i = 0; i < response.length; i++) {
                                            lineGraphDataNew.push(+response[i].achivedAmt);
                                        }
                                        this.setState({
                                            lineGraphData: lineGraphDataNew,
                                        });
                                    }
                                });
                            }
                        });
                    }
                }
            });
            // showLoador({ loador: true });
            // await getRegionListFromYearAndMonth({
            //     month: dayjs().format("MM"),
            //     year: dayjs().format("YYYY"),
            // }).then(({ response, success }) => {
            //     showLoador({ loador: false });
            //     if (!success) {
            //         showNotification({ msg: serverMsg, severity: "error" });
            //     } else {
            //         this.setState({
            //             regionListFromYearAndMonthData: response,
            //             regionListFromYearAndMonthDataForZone: response,
            //             regionListFromYearAndMonthDataForSalesRegion: response
            //         });
            //         getZoneListFromYearAndMonth({
            //             month: dayjs().format("MM"),
            //             year: dayjs().format("YYYY"),
            //             regionId: response[0].id,
            //         }).then(({ response, success }) => {
            //             if (!success) {
            //                 showNotification({ msg: serverMsg, severity: "error" });
            //             } else {
            //                 this.setState({
            //                     zoneDetailsData: response,
            //                 });
            //                 getAreaListFromYearAndMonth({
            //                     month: dayjs().format("MM"),
            //                     year: dayjs().format("YYYY"),
            //                     zoneId: response[0].id,
            //                 }).then(({ success }) => {
            //                     if (!success) {
            //                         showNotification({ msg: serverMsg, severity: "error" });
            //                     } else {
            //                         this.setState({
            //                             areaDetailsData: response,
            //                         });
            //                     }
            //                 });
            //             }
            //         });
            //     }
            // });
            showLoador({ loador: true });
            await getOrderListFromYearAndMonth({
                month: dayjs().format("MM"),
                year: dayjs().format("YYYY"),
            }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    // showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    this.setState({
                        topPerformingOutletData: response,
                    });
                }
            });
            showLoador({ loador: true });
            await getProductListFromYearAndMonth({
                month: dayjs().format("MM"),
                year: dayjs().format("YYYY"),
            }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    this.setState({
                        topPerformingProductData: response,
                    });
                }
            });
            //   showLoador({ loador: true });
            await getMonthYearTarget().then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    // showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    let totalTargetNewList = [];
                    let totalAchievedNewList = [];
                    for (var i = 0; i < response.length; i++) {
                        totalAchievedNewList.push(+response[i].achivedAmt);
                        totalTargetNewList.push(+response[i].targetAmt);
                    }
                    this.setState({
                        personWiseSalesData: response,
                        salesGraphList: response,
                        radioValue: [
                            {
                                name: "Target",
                                data: totalTargetNewList,
                            },
                            {
                                name: "Achieved",
                                data: totalAchievedNewList,
                            },
                        ],
                    });
                }
            });
            // showLoador({ loador: true });
            await getSalesTeamInfo().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    // showNotification({ msg: serverMsg, severity: "error" });
                }
            });
            //   this.setState({
            //     saleMonth: dayjs(),
            //     month: dayjs().format("MM"),
            //     year: dayjs().format("YYYY"),
            //   });
            // } else {
            //   showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    viewProfile = () => {
        window.location.replace("/empPreview");
    };
    GoBack = () => {
        this.setState({
            showPreview: false,
        });
    };
    openFeedBack = (e) => {
        e.preventDefault();
        this.setState({
            showFeedback: true,
        });
    };

    ChangeHandlerSearch = (name, newValue) => {
        if (newValue !== null) {
            this.setState({ [name]: newValue });
        } else {
            this.setState({ [name]: "" });
        }
    };
    topProductHandler = (e, productId) => {
        const { saleMonth } = this.state;
        // console.log(saleMonth);
        // console.log(productId);
        apiGet({
            url:
                endpoint.outletOrderHeader +
                "/month-wise-amt-by-product?productId=" +
                productId +
                "&month=" +
                saleMonth.format("MM") +
                "&year=" +
                saleMonth.format("YYYY"),
        }).then(({ data, success }) => {
            // console.log(data);
        });
    };

    regionHandler = (e, regionId) => {
        const { getZoneListFromYearAndMonth, showLoador, showNotification } =
            this.props;
        const { month, year } = this.state;
        if (navigator.onLine) {
            showLoador({ loador: true });
            getZoneListFromYearAndMonth({
                month: month,
                year: year,
                regionId: regionId,
            }).then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    // showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    this.setState({
                        showZone: true,
                        showArea: false,
                    });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };
    salesHandler = (e, id) => {
        const { getMonthYearTargetForSales, showLoador, showNotification } =
            this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            getMonthYearTargetForSales({ salesTeamId: id }).then(
                ({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        // showNotification({ msg: serverMsg, severity: "error" });
                    } else {
                        let lineGraphDataNew = [];
                        for (var i = 0; i < response.length; i++) {
                            lineGraphDataNew.push(+response[i].achivedAmt);
                        }
                        this.setState({
                            lineGraphData: lineGraphDataNew,
                        });
                    }
                }
            );
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };
    salesTeamGraphHandler = (e, regionId) => {
        const { getZoneListFromYearAndMonth, showLoador, showNotification } =
            this.props;
        const { month, year } = this.state;
        if (navigator.onLine) {
            showLoador({ loador: true });
            getZoneListFromYearAndMonth({
                month: month,
                year: year,
                regionId: regionId,
            }).then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    // showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    this.setState({
                        showZone: true,
                        showArea: false,
                    });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };
    zoneHandler = (e, zoneId) => {
        const { getAreaListFromYearAndMonth, showLoador, showNotification } =
            this.props;
        const { month, year } = this.state;
        if (navigator.onLine) {
            showLoador({ loador: true });
            getAreaListFromYearAndMonth({
                month: month,
                year: year,
                zoneId: zoneId,
            }).then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    // showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    this.setState({
                        showArea: true,
                    });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    onChangeRadioValue = (value) => {
        if (value == "0") {
            this.setState({
                radioValue: [
                    {
                        name: "Target",
                        data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
                    },
                    {
                        name: "Achieved",
                        data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
                    },
                ],
            });
        } else if (value == "1") {
            this.setState({
                radioValue: [
                    {
                        name: "Target",
                        data: [50, 60, 57, 56, 61, 58, 63, 60, 66],
                    },
                    {
                        name: "Achieved",
                        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
                    },
                ],
            });
        } else if (value == "2") {
            this.setState({
                radioValue: [
                    {
                        name: "Target",
                        data: [80, 70, 57, 56, 61, 58, 63, 60, 66],
                    },
                    {
                        name: "Achieved",
                        data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
                    },
                ],
            });
        } else if (value == "3") {
            this.setState({
                radioValue: [
                    {
                        name: "Target",
                        data: [10, 20, 57, 56, 61, 58, 63, 60, 66],
                    },
                    {
                        name: "Achieved",
                        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
                    },
                ],
            });
        }
    };

    onDateChange = (date, name) => {
        // console.log(name);
        this.setState({
            month: date.format("MM"),
            year: date.format("YYYY"),
            saleMonth: date,
            showZone: false,
            showArea: false,
        });
        const { type } = this.state;
        const {
            getRegionListFromYearAndMonth,
            showLoador,
            showNotification,
            getSalesListFromYearAndMonth,
        } = this.props;
        if (navigator.onLine) {
            // showLoador({ loador: true });
            // getRegionListFromYearAndMonth({
            //     month: date.format("MM"),
            //     year: date.format("YYYY"),
            // }).then(({ success }) => {
            //     showLoador({ loador: false });
            //     if (!success) {
            //         // showNotification({ msg: serverMsg, severity: "error" });
            //     }
            // });
            getSalesListFromYearAndMonth({
                month: date.format("MM"),
                year: date.format("YYYY"),
                stockStakeHolderTypeId: type,
            }).then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    // showNotification({ msg: serverMsg, severity: "error" });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };
    onDateChangeSale = (dataKey) => (date) => {
        if (date !== null) {
            this.setState({
                [dataKey]: date,
            });
        }

        this.setState({
            saleDate: date,
        });
    };

    onDateChangeOutlet = async (date, name) => {
        const { type } = this.state;
        this.setState({
            [name]: date,
        });
        const { getOrderListFromYearAndMonth, getProductListFromYearAndMonth } =
            this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            await getOrderListFromYearAndMonth({
                month: date.format("MM"),
                year: date.format("YYYY"),
            }).then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    // showNotification({ msg: serverMsg, severity: "error" });
                }
            });
            showLoador({ loador: true });
            await getProductListFromYearAndMonth({
                month: date.format("MM"),
                year: date.format("YYYY"),
            }).then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    // showNotification({ msg: serverMsg, severity: "error" });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };
    handleFormValidation() {
        const { type, saleMonth } = this.state;
        let formErrors = {};
        let formIsValid = true;
        if (type === "" || type === null) {
            formIsValid = false;
            formErrors["typeError"] = myConstClass.stackHolderTypeMsg;
        }
        if (saleMonth === "" || saleMonth === null) {
            formIsValid = false;
            formErrors["dateError"] = myConstClass.dateMsg;
        }
        this.setState({ formErrors: formErrors });
        return formIsValid;
    }

    searchSalesList = () => {
        const { getSalesListFromYearAndMonth } = this.props;
        const { saleMonth, type } = this.state;

        if (this.handleFormValidation()) {
            if (navigator.onLine) {
                showLoador({ loador: true });
                getSalesListFromYearAndMonth({
                    month: saleMonth.format("MM"),
                    year: saleMonth.format("YYYY"),
                    stockStakeHolderTypeId: type,
                }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        // showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            } else {
                this.props.showNotification({ msg: noInternetMsg, severity: "error" });
            }
        }
    };

    productColumns = [
        {
            name: "index",
            title: "Sr. No.",
            positionCenter: true,
            showInscreen: true,
            alignCenter: "center",
        },
        {
            name: "name",
            title: "Product Name",
            positionCenter: false,
            showInscreen: true,
            alignCenter: "left",
        },

        {
            name: "code",
            title: "Product Code",
            positionCenter: false,
            showInscreen: true,
            alignCenter: "left",
        },
        {
            name: "actualSale",
            title: "Acheived ",
            positionCenter: false,
            showInscreen: true,
            alignCenter: "right",
        },
        {
            name: "contribution",
            title: "% contribution",
            positionCenter: false,
            showInscreen: true,
            alignCenter: "right",
        },
    ];

    outletColumns = [
        {
            name: "index",
            title: "Sr. No.",
            positionCenter: true,
            showInscreen: true,
            alignCenter: "center",
        },
        {
            name: "firmName",
            title: "Outlet Name",
            positionCenter: false,
            showInscreen: true,
            alignCenter: "left",
        },

        {
            name: "area",
            title: "Area Name",
            positionCenter: false,
            showInscreen: true,
            alignCenter: "left",
        },
        {
            name: "actualSale",
            title: "Acheived ",
            positionCenter: false,
            showInscreen: true,
            alignCenter: "right",
        },
        {
            name: "contribution",
            title: "% contribution",
            positionCenter: false,
            showInscreen: true,
            alignCenter: "right",
        },
        {
            name: "salePersonName",
            title: "Sales Excutive Name",
            positionCenter: false,
            showInscreen: true,
            alignCenter: "left",
        },
    ];

    columns = [
        {
            name: "index",
            title: "Sr.No",
            positionCenter: false,
            showInscreen: true,
            alignCenter: "center",
        },

        {
            name: "name",
            title: " Product Name",
            positionCenter: false,
            showInscreen: true,
            alignCenter: "right",
        },
        {
            name: "code",
            title: "Product Code",
            positionCenter: false,
            showInscreen: true,
            alignCenter: "left",
        },
        {
            name: "actualSale",
            title: "Acheived",
            positionCenter: false,
            showInscreen: true,
            alignCenter: "right",
        },
        {
            name: "contribution",
            title: "% Contribution",
            positionCenter: false,
            showInscreen: true,
            alignCenter: "right",
        },
    ];
    graphList = [{ x: 1, y: 64 }];
    onChangeMonthOrYear = (data, name) => {
        this.setState({
            [name]: data,
        });
    };
    getExtraData = (dtoSalesTeamInformations) => {
        let totalOutletCount = 0,
            zero = 0,
            unvisited = 0,
            totalAmount = 0;
        dtoSalesTeamInformations.map((salesTeam) => {
            totalOutletCount = totalOutletCount + +salesTeam.totalOutletCount;
            zero = zero + +salesTeam.zeroOrderCount;
            unvisited = unvisited + +salesTeam.unvisitedOutletCount;
            totalAmount = totalAmount + +salesTeam.totalAmount;
        });
        return [totalOutletCount, unvisited, zero, totalAmount];
    };

    onSearch = (searchData) => {
        const { dashboardData, setRegionListFromYearAndMonth } = this.props;
        const { regionListFromYearAndMonthData } = this.state;
        // console.log(searchData);
        if (searchData != "" || searchData != undefined) {
            this.setState({
                searchValue: searchData,
            });
            const list = dashboardData.regionListFromYearAndMonth.filter(
                (currentRow) => {
                    let isValid = false;
                    if (
                        currentRow["name"] &&
                        currentRow["name"]
                            .toString()
                            .toLowerCase()
                            .includes(searchData.toLowerCase())
                    ) {
                        isValid = true;
                        return true;
                    }
                    return isValid;
                }
            );
            this.setState({
                regionListFromYearAndMonthData: list,
            });
        } else {
            this.setState({
                searchValue: "",
            });
            this.setState({
                regionListFromYearAndMonthData:
                    dashboardData.regionListFromYearAndMonth,
            });
        }
    };

    onSearchForZone = (searchData) => {
        const { dashboardData, setRegionListFromYearAndMonth } = this.props;
        const { regionListFromYearAndMonthDataForZone } = this.state;
        // console.log(searchData);
        if (searchData != "" || searchData != undefined) {
            this.setState({
                searchValueForZone: searchData,
            });
            const list = dashboardData.regionListFromYearAndMonth.filter(
                (currentRow) => {
                    let isValid = false;
                    if (
                        currentRow["name"] &&
                        currentRow["name"]
                            .toString()
                            .toLowerCase()
                            .includes(searchData.toLowerCase())
                    ) {
                        isValid = true;
                        return true;
                    }
                    return isValid;
                }
            );
            this.setState({
                regionListFromYearAndMonthDataForZone: list,
            });
        } else {
            this.setState({
                searchValueForZone: "",
            });
            this.setState({
                regionListFromYearAndMonthDataForZone:
                    dashboardData.regionListFromYearAndMonth,
            });
        }
    };

    onSearchForSalesRegion = (searchData) => {
        const { dashboardData, setRegionListFromYearAndMonth } = this.props;
        const { regionListFromYearAndMonthDataForSalesRegion } = this.state;
        // console.log(searchData);
        if (searchData != "" || searchData != undefined) {
            this.setState({
                searchValueForSalesRegion: searchData,
            });
            const list = dashboardData.regionListFromYearAndMonth.filter(
                (currentRow) => {
                    let isValid = false;
                    if (
                        currentRow["name"] &&
                        currentRow["name"]
                            .toString()
                            .toLowerCase()
                            .includes(searchData.toLowerCase())
                    ) {
                        isValid = true;
                        return true;
                    }
                    return isValid;
                }
            );
            this.setState({
                regionListFromYearAndMonthDataForSalesRegion: list,
            });
        } else {
            this.setState({
                searchValueForSalesRegion: "",
            });
            this.setState({
                regionListFromYearAndMonthDataForSalesRegion:
                    dashboardData.regionListFromYearAndMonth,
            });
        }
    };

    onSearchForZoneDetails = (searchData) => {
        const { dashboardData } = this.props;
        const { zoneDetailsData } = this.state;
        // console.log(searchData);
        if (searchData != "" || searchData != undefined) {
            this.setState({
                searchValueForZoneDetails: searchData,
            });
            const list = dashboardData.zoneListFromYearAndMonth.filter(
                (currentRow) => {
                    let isValid = false;
                    if (
                        currentRow["name"] &&
                        currentRow["name"]
                            .toString()
                            .toLowerCase()
                            .includes(searchData.toLowerCase())
                    ) {
                        isValid = true;
                        return true;
                    }
                    return isValid;
                }
            );
            this.setState({
                zoneDetailsData: list,
            });
        } else {
            this.setState({
                searchValueForZoneDetails: "",
            });
            this.setState({
                zoneDetailsData:
                    dashboardData.zoneListFromYearAndMonth,
            });
        }
    };

    onSearchForAreaDetails = (searchData) => {
        const { dashboardData } = this.props;
        const { areaDetailsData } = this.state;
        // console.log(searchData);
        if (searchData != "" || searchData != undefined) {
            this.setState({
                searchValueForAreaDetails: searchData,
            });
            const list = dashboardData.areaListFromYearAndMonth.filter(
                (currentRow) => {
                    let isValid = false;
                    if (
                        currentRow["name"] &&
                        currentRow["name"]
                            .toString()
                            .toLowerCase()
                            .includes(searchData.toLowerCase())
                    ) {
                        isValid = true;
                        return true;
                    }
                    return isValid;
                }
            );
            this.setState({
                areaDetailsData: list,
            });
        } else {
            this.setState({
                searchValueForAreaDetails: "",
            });
            this.setState({
                areaDetailsData:
                    dashboardData.areaListFromYearAndMonth,
            });
        }
    };

    // onSearchPersonWiseSales = (searchData) => {
    //   const { dashboardData } = this.props;
    //   const { personWiseSalesData } = this.state;
    //   console.log(searchData);
    //   if (searchData != "" || searchData != undefined) {
    //     this.setState({
    //       searchValuePersonWiseSales: searchData,
    //     });
    //     const list = dashboardData.salesListFromYearAndMonth.filter(
    //       (currentRow) => {
    //         let isValid = false;
    //         if (
    //           currentRow["name"] &&
    //           currentRow["name"]
    //             .toString()
    //             .toLowerCase()
    //             .includes(searchData.toLowerCase())
    //         ) {
    //           isValid = true;
    //           return true;
    //         }
    //         return isValid;
    //       }
    //     );
    //     this.setState({
    //       personWiseSalesData: list,
    //     });
    //   } else {
    //     this.setState({
    //       searchValuePersonWiseSales: "",
    //     });
    //     this.setState({
    //       personWiseSalesData:
    //         dashboardData.salesListFromYearAndMonth,
    //     });
    //   }
    // };

    onSearchForTopPerformingProducts = (searchData) => {
        const { dashboardData } = this.props;
        const { topPerformingProductData } = this.state;
        console.log(searchData);
        if (searchData != "" || searchData != undefined) {
            this.setState({
                searchValueForProduct: searchData,
            });
            const list = dashboardData.productListFromYearAndMonth.filter(
                (currentRow) => {
                    let isValid = false;
                    if (
                        currentRow["name"] &&
                        currentRow["name"]
                            .toString()
                            .toLowerCase()
                            .includes(searchData.toLowerCase()) || (
                            currentRow["code"] &&
                            currentRow["code"]
                                .toString()
                                .toLowerCase()
                                .includes(searchData.toLowerCase())
                        )
                    ) {
                        isValid = true;
                        return true;
                    }
                    return isValid;
                }
            );
            this.setState({
                topPerformingProductData: list,
            });
        } else {
            this.setState({
                searchValueForProduct: "",
            });
            this.setState({
                topPerformingProductData:
                    dashboardData.productListFromYearAndMonth,
            });
        }
    };

    onSearchForTopPerformingOutlets = (searchData) => {
        const { dashboardData } = this.props;
        const { topPerformingOutletData } = this.state;
        console.log(searchData);
        if (searchData != "" || searchData != undefined) {
            this.setState({
                searchValueForOutlets: searchData,
            });
            const list = dashboardData.outletListFromYearAndMonth.filter(
                (currentRow) => {
                    let isValid = false;
                    if (
                        currentRow["firmName"] &&
                        currentRow["firmName"]
                            .toString()
                            .toLowerCase()
                            .includes(searchData.toLowerCase())
                    ) {
                        isValid = true;
                        return true;
                    }
                    return isValid;
                }
            );
            this.setState({
                topPerformingOutletData: list,
            });
        } else {
            this.setState({
                searchValueForOutlets: "",
            });
            this.setState({
                topPerformingOutletData:
                    dashboardData.outletListFromYearAndMonth,
            });
        }
    };


    render() {
        const {
            dashboardData,
            dashboardData2,
            stockStakeHolderTypeList,
            zoneList,
            areaList,
        } = this.props;
        const {
            type,
            showZone,
            showArea,
            saleMonth,
            monthList,
            columns,
            rows,
            rowsPerPage,
            page,
            radioValue,
            productArea,
            productZone,
            outletArea,
            outletZone,
            regionListFromYearAndMonthData,
            regionListFromYearAndMonthDataForZone,
            regionListFromYearAndMonthDataForSalesRegion,
            zoneDetailsData,
            areaDetailsData,
            topPerformingProductData,
            topPerformingOutletData,
            salesTeamData,
            personWiseSalesData,
            searchValue,
            searchValueForZone,
            searchValueForSalesRegion,
            searchValueForZoneDetails,
            searchValueForAreaDetails,
            searchValuePersonWiseSales,
            searchValueForProduct,
            searchValueForOutlets,
            lineGraphData,
            salesGraphList,
            sellBillList,
        } = this.state;
        const { typeError, dateError } = this.state.formErrors;

        return (
            <>
                <div
                    id="layout-wrapper"
                    style={{
                        backgroundColor: "#fff",
                    }}
                >
                    {/* ========== App Menu ========== */}

                    {/* Left Sidebar End */}
                    {/* Vertical Overlay*/}
                    <div className="vertical-overlay" />
                    {/* ============================================================== */}
                    {/* Start right Content here 
            {/* ============================================================== */}
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <div
                                    className="row"
                                    style={{
                                        gap: 8,
                                        paddingBottom: 10,
                                    }}
                                >
                                    {/* Card 1 */}
                                    <div
                                        className="Frame427319159"
                                        style={{
                                            width: "24.5%",
                                            height: "auto",
                                            paddingTop: 22,
                                            paddingBottom: 22,
                                            background: "#FFFDE7",
                                            borderRadius: 8,
                                            overflow: "hidden",
                                            // border: "1px solid",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "inline-flex",
                                        }}
                                    >
                                        <div
                                            className="Frame11262"
                                            style={{
                                                flex: "1 1 0",
                                                alignSelf: "stretch",
                                                paddingLeft: 16,
                                                paddingRight: 16,
                                                justifyContent: "space-between",
                                                alignItems: "flex-start",
                                                display: "inline-flex",
                                            }}
                                        >
                                            <div
                                                className="Frame427319153"
                                                style={{
                                                    width: "60%",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "flex-start",
                                                    gap: 8,
                                                    display: "inline-flex",
                                                }}
                                            >
                                                <img
                                                    className="Image4"
                                                    style={{ width: 32, height: 32 }}
                                                    src={image4}
                                                />

                                                <div
                                                    className="TotalSales"
                                                    style={{
                                                        color: "black",
                                                        fontSize: 22,
                                                        fontFamily: "Poppins",
                                                        fontWeight: "400",
                                                        wordWrap: "break-word",
                                                        top: 5,
                                                    }}
                                                >
                                                </div>
                                            </div>
                                            <div
                                                className="Frame427319116"
                                                style={{
                                                    // alignSelf: "stretch",
                                                    flexDirection: "column",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    display: "inline-flex",
                                                }}
                                            >
                                                <div
                                                    className="Cr"
                                                    style={{
                                                        color: "black",
                                                        fontSize: 28,
                                                        fontFamily: "Poppins",
                                                        fontWeight: "500",
                                                        textTransform: "capitalize",
                                                        lineHeight: 1.8,
                                                        wordWrap: "break-word",
                                                    }}
                                                >
                                                    2.5 CR
                                                    {/* {ConvertAmount({
                                                        amount:
                                                            +dashboardData?.tillMonthYearTarget.achivedAmt,
                                                    })} */}
                                                </div>
                                                <div
                                                    className="Frame427319115"
                                                    style={{
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                        gap: 2,
                                                        display: "inline-flex",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            color: "black",
                                                            fontSize: 16,
                                                            fontFamily: "Poppins",
                                                            fontWeight: "400",
                                                            textTransform: "capitalize",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        {/* {(+dashboardData?.tillMonthYearTarget
                                                            .contribution).toFixed(2)} */}
                                                        Sell
                                                    </div>
                                                    <div
                                                        className="HeroiconsMiniArrowSmallUp"
                                                        style={{
                                                            width: 20,
                                                            height: 20,
                                                            position: "relative",
                                                        }}
                                                    >
                                                        <div
                                                            className="Frame427319115"
                                                            style={{
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                gap: 3,
                                                                display: "inline-flex",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    color: "#2268D1",
                                                                    fontSize: 18,
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: "500",
                                                                    textTransform: "capitalize",
                                                                    wordWrap: "break-word",
                                                                }}
                                                            >
                                                                {/* {(+dashboardData?.tillMonthYearTarget
                                                            .contribution).toFixed(2)} */}
                                                                +10%
                                                            </div>
                                                            <div
                                                                style={{
                                                                    color: '#2268D1',
                                                                    fontSize: 12,
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: '400',
                                                                    wordWrap: 'break-word',
                                                                }}>
                                                                From last month
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="VectorStroke"
                                                            style={{
                                                                width: 8,
                                                                height: 17,
                                                                left: 6,
                                                                // top: 5,
                                                                position: "absolute",
                                                                background: "#43C622",
                                                            }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Card 1  end*/}

                                    {/* Card 2 */}

                                    <div
                                        className="Frame427319162"
                                        style={{
                                            width: "24.5%",
                                            height: "auto",
                                            paddingTop: 22,
                                            paddingBottom: 22,
                                            background: "rgba(253, 126, 20, 0.10)",
                                            borderRadius: 8,
                                            overflow: "hidden",
                                            // border: "1px solid",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "inline-flex",
                                        }}
                                    >
                                        <div
                                            className="Frame11262"
                                            style={{
                                                flex: "1 1 0",
                                                alignSelf: "stretch",
                                                paddingLeft: 16,
                                                paddingRight: 16,
                                                justifyContent: "space-between",
                                                alignItems: "flex-start",
                                                display: "inline-flex",
                                            }}
                                        >
                                            <div
                                                className="Frame427319153"
                                                style={{
                                                    width: "60%",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "flex-start",
                                                    gap: 8,
                                                    display: "inline-flex",
                                                }}
                                            >
                                                <img
                                                    className="Image5"
                                                    style={{ width: 32, height: 32 }}
                                                    src={image5}
                                                />
                                                <div
                                                    className="TotalSales"
                                                    style={{
                                                        color: "black",
                                                        fontSize: 22,
                                                        fontFamily: "Poppins",
                                                        fontWeight: "400",
                                                        wordWrap: "break-word",
                                                    }}
                                                >
                                                    Outlet
                                                </div>
                                            </div>
                                            <div
                                                className="Frame427319116"
                                                style={{
                                                    // alignSelf: "stretch",
                                                    flexDirection: "column",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    display: "inline-flex",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        color: "black",
                                                        fontSize: 28,
                                                        fontFamily: "Poppins",
                                                        fontWeight: "500",
                                                        textTransform: "capitalize",
                                                        lineHeight: 1.8,
                                                        wordWrap: "break-word",
                                                    }}
                                                >
                                                    {+dashboardData?.currentYearTarget.totalOutletCount}
                                                </div>
                                                <div
                                                    className="Frame427319115"
                                                    style={{
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                        gap: 2,
                                                        display: "inline-flex",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            color: "#43C622",
                                                            fontSize: 16,
                                                            fontFamily: "Poppins",
                                                            fontWeight: "400",
                                                            textTransform: "capitalize",
                                                            wordWrap: "break-word",
                                                        }}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Card 2 end */}

                                    {/* Card 3 */}

                                    <div
                                        className="Frame427319160"
                                        style={{
                                            width: "24.5%",
                                            height: "auto",
                                            paddingTop: 22,
                                            paddingBottom: 22,
                                            background: "rgba(39, 197, 231, 0.10)",
                                            borderRadius: 8,
                                            overflow: "hidden",
                                            // border: "1px solid",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "inline-flex",
                                        }}
                                    >
                                        <div
                                            className="Frame11262"
                                            style={{
                                                flex: "1 1 0",
                                                alignSelf: "stretch",
                                                paddingLeft: 16,
                                                paddingRight: 16,
                                                justifyContent: "space-between",
                                                alignItems: "flex-start",
                                                display: "inline-flex",
                                            }}
                                        >
                                            <div
                                                className="Frame427319153"
                                                style={{
                                                    width: "60%",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "flex-start",
                                                    gap: 8,
                                                    display: "inline-flex",
                                                }}
                                            >
                                                <img
                                                    className="Image5"
                                                    style={{ width: 32, height: 32 }}
                                                    src={image6}
                                                />
                                                <div
                                                    className="TotalSales"
                                                    style={{
                                                        color: "black",
                                                        fontSize: 22,
                                                        fontFamily: "Poppins",
                                                        fontWeight: "400",
                                                        wordWrap: "break-word",
                                                    }}
                                                >
                                                    StokeHolder
                                                </div>
                                            </div>
                                            <div
                                                className="Frame427319116"
                                                style={{
                                                    // alignSelf: "stretch",
                                                    flexDirection: "column",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    display: "inline-flex",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        color: "black",
                                                        fontSize: 28,
                                                        fontFamily: "Poppins",
                                                        fontWeight: "500",
                                                        textTransform: "capitalize",
                                                        lineHeight: 1.8,
                                                        wordWrap: "break-word",
                                                    }}
                                                >
                                                    {
                                                        +dashboardData?.currentYearTarget
                                                            .totalStakeHolderCount
                                                    }
                                                </div>
                                                <div
                                                    className="Frame427319115"
                                                    style={{
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                        gap: 2,
                                                        display: "inline-flex",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            color: "#43C622",
                                                            fontSize: 16,
                                                            fontFamily: "Poppins",
                                                            fontWeight: "400",
                                                            textTransform: "capitalize",
                                                            wordWrap: "break-word",
                                                        }}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Card 3 end */}

                                    {/* Card 4 */}
                                    <div
                                        className="Frame427319161"
                                        style={{
                                            width: "24.5%",
                                            height: "auto",
                                            paddingTop: 22,
                                            paddingBottom: 22,
                                            background: "rgba(243, 91, 138, 0.10)",
                                            borderRadius: 8,
                                            overflow: "hidden",
                                            // border: "1px solid",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "inline-flex",
                                        }}
                                    >
                                        <div
                                            className="Frame11262"
                                            style={{
                                                flex: "1 1 0",
                                                alignSelf: "stretch",
                                                paddingLeft: 16,
                                                paddingRight: 16,
                                                justifyContent: "space-between",
                                                alignItems: "flex-start",
                                                display: "inline-flex",
                                            }}
                                        >
                                            <div
                                                className="Frame427319153"
                                                style={{
                                                    width: "60%",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "flex-start",
                                                    gap: 8,
                                                    display: "inline-flex",
                                                }}
                                            >
                                                <img
                                                    className="Image7"
                                                    style={{ width: 32, height: 32 }}
                                                    src={image7BgClr}
                                                />
                                                <div
                                                    className="TotalSales"
                                                    style={{
                                                        color: "black",
                                                        fontSize: 22,
                                                        fontFamily: "Poppins",
                                                        fontWeight: "400",
                                                        wordWrap: "break-word",
                                                    }}
                                                >
                                                    Manpower
                                                </div>
                                            </div>
                                            <div
                                                className="Frame427319116"
                                                style={{
                                                    // alignSelf: "stretch",
                                                    flexDirection: "column",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    display: "inline-flex",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        color: "black",
                                                        fontSize: 28,
                                                        fontFamily: "Poppins",
                                                        fontWeight: "500",
                                                        textTransform: "capitalize",
                                                        lineHeight: 1.8,
                                                        wordWrap: "break-word",
                                                    }}
                                                >
                                                    {
                                                        +dashboardData?.currentYearTarget
                                                            .totalSalesTeamCount
                                                    }
                                                </div>
                                                <div
                                                    className="Frame427319115"
                                                    style={{
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                        gap: 2,
                                                        display: "inline-flex",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            color: "#43C622",
                                                            fontSize: 16,
                                                            fontFamily: "Poppins",
                                                            fontWeight: "400",
                                                            textTransform: "capitalize",
                                                            wordWrap: "break-word",
                                                        }}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Card 4 end */}
                                </div>

                                <div
                                    className="row"
                                    style={{
                                        gap: 8,
                                        paddingBottom: 10,
                                    }}
                                >
                                    {/* Target Card Start */}

                                    <div
                                        className="Frame1000002990"
                                        style={{
                                            width: "24%",
                                            height: "auto",
                                            flexDirection: "column",
                                            justifyContent: "flex-start",
                                            alignItems: "flex-start",
                                            gap: 12,
                                            display: "inline-flex",
                                        }}
                                    >
                                        <div
                                            className="Frame1000002999"
                                            style={{
                                                width: "100%",
                                                height: "auto",
                                                padding: 12,
                                                background: "#FFE3FB",
                                                borderRadius: 8,
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                display: "inline-flex",
                                            }}
                                        >
                                            <div
                                                className="Frame1000002995"
                                                style={{
                                                    height: 76,
                                                    flexDirection: "column",
                                                    justifyContent: "space-between",
                                                    alignItems: "flex-start",
                                                    display: "inline-flex",
                                                }}
                                            >
                                                <div
                                                    className="Frame427319178"
                                                    style={{
                                                        width: 44,
                                                        height: 44,
                                                        padding: 10,
                                                        background: "#E205BF",
                                                        borderRadius: 23,
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        gap: 10,
                                                        display: "inline-flex",
                                                    }}
                                                >
                                                    <div
                                                        className="Target1"
                                                        style={{
                                                            width: 32,
                                                            height: 32,
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            display: "flex",
                                                        }}
                                                    >
                                                        <div
                                                            className="Frame"
                                                            style={{
                                                                width: 32,
                                                                height: 32,
                                                                position: "relative",
                                                                flexDirection: "column",
                                                                justifyContent: "flex-start",
                                                                alignItems: "flex-start",
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <img
                                                                className="Image7"
                                                                style={{ width: 32, height: 32 }}
                                                                src={target1}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="TotalTarget"
                                                    style={{
                                                        color: "black",
                                                        fontSize: 16,
                                                        fontFamily: "Poppins",
                                                        fontWeight: "400",
                                                        wordWrap: "break-word",
                                                    }}
                                                >
                                                    Total Target
                                                </div>
                                            </div>
                                            <div
                                                className="Frame1000002994"
                                                style={{
                                                    alignSelf: "stretch",
                                                    flexDirection: "column",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    display: "inline-flex",
                                                }}
                                            >
                                                <div
                                                    className="Frame427319117"
                                                    style={{
                                                        flexDirection: "column",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                        gap: 4,
                                                        display: "flex",
                                                    }}
                                                >
                                                    <div
                                                        className="Frame427319116"
                                                        style={{
                                                            width: 121,
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            display: "inline-flex",
                                                        }}
                                                    >
                                                        <div
                                                            className="Cr"
                                                            style={{
                                                                flex: "1 1 0",
                                                                textAlign: "center",
                                                                color: "black",
                                                                fontSize: 18,
                                                                fontFamily: "Poppins",
                                                                fontWeight: "500",
                                                                textTransform: "capitalize",
                                                                lineHeight: 1,
                                                                wordWrap: "break-word",
                                                            }}
                                                        >
                                                            {ConvertAmount({
                                                                amount:
                                                                    +dashboardData?.currentYearTarget
                                                                        .currentTargetAmt,
                                                            })}
                                                        </div>
                                                        <div
                                                            className="Frame427319115"
                                                            style={{
                                                                justifyContent: "flex-end",
                                                                alignItems: "right",
                                                                // gap: 2,
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    color: "#43C622",
                                                                    fontSize: 14,
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: "500",
                                                                    textTransform: "capitalize",
                                                                    wordWrap: "break-word",
                                                                }}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="Total"
                                                        style={{
                                                            color: "#888888",
                                                            fontSize: 12,
                                                            fontFamily: "Poppins",
                                                            fontWeight: "400",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        Total{" "}
                                                    </div>
                                                </div>
                                                <div
                                                    className="Frame427319118"
                                                    style={{
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        gap: 7,
                                                        display: "flex",
                                                    }}
                                                >
                                                    <div
                                                        className="Frame427319116"
                                                        style={{
                                                            justifyContent: "flex-start",
                                                            alignItems: "center",
                                                            display: "inline-flex",
                                                        }}
                                                    >
                                                        <div
                                                            className="Cr"
                                                            style={{
                                                                width: 80,
                                                                textAlign: "center",
                                                                color: "black",
                                                                fontSize: 18,
                                                                fontFamily: "Poppins",
                                                                fontWeight: "500",
                                                                textTransform: "capitalize",
                                                                lineHeight: 1,
                                                                wordWrap: "break-word",
                                                            }}
                                                        >
                                                            {ConvertAmount({
                                                                amount:
                                                                    +dashboardData?.currentYearTarget
                                                                        .currentAchievedAmt,
                                                            })}
                                                        </div>
                                                        <div
                                                            className="Frame427319115"
                                                            style={{
                                                                justifyContent: "flex-start",
                                                                alignItems: "center",
                                                                gap: 2,
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    color: "#43C622",
                                                                    fontSize: 14,
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: "500",
                                                                    textTransform: "capitalize",
                                                                    wordWrap: "break-word",
                                                                }}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="Achieved"
                                                        style={{
                                                            color: "#888888",
                                                            fontSize: 12,
                                                            fontFamily: "Poppins",
                                                            fontWeight: "400",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        Achieved
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="Frame1000002996"
                                            style={{
                                                width: "100%",
                                                height: "auto",
                                                padding: 12,
                                                background: "#EEF6FF",
                                                borderRadius: 8,
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                display: "inline-flex",
                                            }}
                                        >
                                            <div
                                                className="Frame1000002995"
                                                style={{
                                                    height: 76,
                                                    flexDirection: "column",
                                                    justifyContent: "space-between",
                                                    alignItems: "flex-start",
                                                    display: "inline-flex",
                                                }}
                                            >
                                                <div
                                                    className="Frame427319178"
                                                    style={{
                                                        width: 44,
                                                        height: 44,
                                                        padding: 10,
                                                        background: "#056BE2",
                                                        borderRadius: 23,
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        gap: 10,
                                                        display: "inline-flex",
                                                    }}
                                                >
                                                    <div
                                                        className="Target1"
                                                        style={{
                                                            width: 32,
                                                            height: 32,
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            display: "flex",
                                                        }}
                                                    >
                                                        <div
                                                            className="Frame"
                                                            style={{
                                                                width: 32,
                                                                height: 32,
                                                                position: "relative",
                                                                flexDirection: "column",
                                                                justifyContent: "flex-start",
                                                                alignItems: "flex-start",
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <img
                                                                className="Image7"
                                                                style={{ width: 32, height: 32 }}
                                                                src={target1}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="MonthlyTarget"
                                                    style={{
                                                        color: "black",
                                                        fontSize: 16,
                                                        fontFamily: "Poppins",
                                                        fontWeight: "400",
                                                        wordWrap: "break-word",
                                                    }}
                                                >
                                                    Monthly Target
                                                </div>
                                            </div>
                                            <div
                                                className="Frame1000002994"
                                                style={{
                                                    alignSelf: "stretch",
                                                    flexDirection: "column",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    display: "inline-flex",
                                                }}
                                            >
                                                <div
                                                    className="Frame427319117"
                                                    style={{
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        gap: 4,
                                                        display: "flex",
                                                    }}
                                                >
                                                    <div
                                                        className="Frame427319116"
                                                        style={{
                                                            width: 121,
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            display: "inline-flex",
                                                        }}
                                                    >
                                                        <div
                                                            className="Cr"
                                                            style={{
                                                                flex: "1 1 0",
                                                                textAlign: "center",
                                                                color: "black",
                                                                fontSize: 18,
                                                                fontFamily: "Poppins",
                                                                fontWeight: "500",
                                                                textTransform: "capitalize",
                                                                lineHeight: 1,
                                                                wordWrap: "break-word",
                                                            }}
                                                        >
                                                            {ConvertAmount({
                                                                amount:
                                                                    +dashboardData?.tillMonthYearTarget.targetAmt,
                                                            })}
                                                        </div>
                                                        <div
                                                            className="Frame427319115"
                                                            style={{
                                                                justifyContent: "flex-start",
                                                                alignItems: "center",
                                                                gap: 2,
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    color: "#43C622",
                                                                    fontSize: 14,
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: "500",
                                                                    textTransform: "capitalize",
                                                                    wordWrap: "break-word",
                                                                }}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="Total"
                                                        style={{
                                                            color: "#888888",
                                                            fontSize: 12,
                                                            fontFamily: "Poppins",
                                                            fontWeight: "400",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        Total{" "}
                                                    </div>
                                                </div>
                                                <div
                                                    className="Frame427319118"
                                                    style={{
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        gap: 7,
                                                        display: "flex",
                                                    }}
                                                >
                                                    <div
                                                        className="Frame427319116"
                                                        style={{
                                                            justifyContent: "flex-start",
                                                            alignItems: "center",
                                                            display: "inline-flex",
                                                        }}
                                                    >
                                                        <div
                                                            className="Cr"
                                                            style={{
                                                                width: 80,
                                                                textAlign: "center",
                                                                color: "black",
                                                                fontSize: 18,
                                                                fontFamily: "Poppins",
                                                                fontWeight: "500",
                                                                textTransform: "capitalize",
                                                                lineHeight: 1,
                                                                wordWrap: "break-word",
                                                            }}
                                                        >
                                                            {ConvertAmount({
                                                                amount:
                                                                    +dashboardData?.tillMonthYearTarget
                                                                        .achivedAmt,
                                                            })}
                                                        </div>
                                                        <div
                                                            className="Frame427319115"
                                                            style={{
                                                                justifyContent: "flex-start",
                                                                alignItems: "center",
                                                                gap: 2,
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    color: "#43C622",
                                                                    fontSize: 14,
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: "500",
                                                                    textTransform: "capitalize",
                                                                    wordWrap: "break-word",
                                                                }}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="Achieved"
                                                        style={{
                                                            color: "#888888",
                                                            fontSize: 12,
                                                            fontFamily: "Poppins",
                                                            fontWeight: "400",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        Achieved
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Target Card Ends */}
                                    {/* Bar Graph starts */}

                                    {/* {console.log(radioValue)} */}
                                    <div
                                        className="Group11172"
                                        style={{ width: "75%", position: "relative" }}
                                    >
                                        <div
                                            className="SalesOverview"
                                            style={{
                                                left: 18,
                                                top: 10,
                                                position: "absolute",
                                                color: "black",
                                                fontSize: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: "400",
                                                wordWrap: "break-word",
                                            }}
                                        >
                                            Sales overview
                                        </div>
                                        <div>
                                            <MonthlyBarChart
                                                flag={false}
                                                height={300}
                                                radioValue={this.onChangeRadioValue}
                                                series={radioValue}
                                                optionsObj={{
                                                    chart: {
                                                        type: "bar",
                                                        height: 65,
                                                        events: {
                                                            dataPointSelection: (
                                                                event,
                                                                chartContext,
                                                                config
                                                            ) => {
                                                                onClickLineChart(config.dataPointIndex);
                                                            },
                                                        },
                                                    },

                                                    plotOptions: {
                                                        bar: {
                                                            columnWidth: "55%",
                                                            endingShape: "rounded",
                                                            horizontal: false,
                                                        },
                                                    },
                                                    stroke: {
                                                        show: true,
                                                        width: 2,
                                                        // colors: ["transparent"],
                                                    },
                                                    dataLabels: {
                                                        enabled: false,
                                                    },
                                                    xaxis: {
                                                        categories: [
                                                            "Apr",
                                                            "May",
                                                            "Jun",
                                                            "Jul",
                                                            "Aug",
                                                            "Sep",
                                                            "Oct",
                                                        ],
                                                    },
                                                    yaxis: {
                                                        title: [
                                                            "Feb",
                                                            "Mar",
                                                            "Apr",
                                                            "May",
                                                            "Jun",
                                                            "Jul",
                                                            "Aug",
                                                            "Sep",
                                                            "Oct",
                                                        ],
                                                    },
                                                    fill: {
                                                        opacity: 1,
                                                    },
                                                    // colors: [info],

                                                    tooltip: {
                                                        theme: "light",
                                                    },
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {/* Bar Graph ends */}
                                </div>

                                {/* Sales & Manpower Div starts */}

                                <div
                                    className="Frame427319149"
                                    style={{
                                        width: "100%",
                                        height: 36,
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        gap: 12,
                                        display: "inline-flex",
                                    }}
                                >

                                </div>

                                {/* Sales & Manpower Div end */}
                                <div
                                    className="row"
                                    style={{
                                        gap: 8,
                                        marginBottom: 10,
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "35%",
                                            background: "white",
                                            padding: 5,
                                            borderRadius: 8,
                                            border: "1px #E6E6E6 solid",
                                        }}
                                    >
                                        {/* Sales & Manpower Table 1 starts */}
                                        <div
                                            // className="card"
                                            style={{
                                                marginBottom: -2,
                                                background: "white",
                                                borderRadius: 8,
                                                border: "0 #E6E6E6 solid",
                                            }}
                                        >
                                            <div
                                                className="card-header align-items-center d-flex"
                                                style={{
                                                    background: "white",
                                                    borderRadius: 8,
                                                    border: "0 #E6E6E6 solid",
                                                    overflow: "scroll",
                                                }}
                                            >
                                                <h4 className="text-uppercase card-title mb-0 flex-grow-1">
                                                    Sell Bill
                                                    <div
                                                        style={{
                                                            marginLeft: -25,
                                                        }}
                                                    >
                                                        <SearchBar
                                                            placeHolder="Search..."
                                                            inputProps={{ "aria-label": "search" }}
                                                            searchValue={searchValue}
                                                            filteredRows={this.onSearch}
                                                        ></SearchBar>
                                                    </div>
                                                </h4>
                                                <h4
                                                    className="text-uppercase card-title mb-0 flex-grow-1"
                                                    style={{
                                                        textAlign: "end",
                                                        width: 180,
                                                    }}
                                                >
                                                    <MonthCalender
                                                        title=""
                                                        name="saleMonth"
                                                        value={saleMonth}
                                                        onChange={this.onDateChange}
                                                        sx={{
                                                            paddingBottom: 0,
                                                        }}
                                                    />
                                                </h4>
                                            </div>
                                        </div>
                                        <TableContainer
                                            sx={{
                                                maxHeight: 440,
                                                marginTop: 0,
                                                padding: 0,
                                                // width: "40%",
                                            }}
                                        >
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead
                                                    style={{
                                                        backgroundColor: "#fff",
                                                    }}
                                                >
                                                    <TableRow>
                                                        <TableCell
                                                            color="#000"
                                                            align="center"
                                                            sx={{ width: "10%", color: "#000" }}
                                                        >
                                                            Sr.No
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "15%", color: "#000" }}
                                                        >
                                                            Invoice number
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "15%", color: "#000" }}
                                                        >
                                                            Party name
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "15%", color: "#000" }}
                                                        >
                                                            Amount
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        // dashboardData.regionListFromYearAndMonth.map(
                                                        sellBillList.map(
                                                            (regionObject, index) => {
                                                                return (
                                                                    <TableRow>
                                                                        <TableCell align="center">
                                                                            {index + 1}
                                                                        </TableCell>
                                                                        <TableCell width="2%">
                                                                            <div
                                                                                onClick={(e) =>
                                                                                    this.regionHandler(e, regionObject.id)
                                                                                }
                                                                                style={{
                                                                                    color: cyanBlue,
                                                                                    cursor: "pointer",
                                                                                }}
                                                                            >
                                                                                {regionObject.invoiceNumber}
                                                                            </div>
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            {regionObject.partyName}
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            {regionObject.amount}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            }
                                                        )
                                                    }
                                                    <TableRow>
                                                        <TableCell align="center" width="2%" colSpan={3}>
                                                            Total
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {dashboardData.regionListFromYearAndMonthTotal}
                                                        </TableCell>
                                                        {/* <TableCell align="center">-</TableCell> */}
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        {/* Sales & Manpower Table 1 end */}
                                    </div>

                                    <div
                                        style={{
                                            width: "63%",
                                            background: "white",
                                            padding: 5,
                                            borderRadius: 8,
                                            border: "1px #E6E6E6 solid",
                                        }}
                                    >
                                        {/* Sales & Manpower Table 2 starts */}
                                        <div
                                            // className="card"
                                            style={{
                                                marginBottom: -2,
                                                background: "white",
                                                borderRadius: 8,
                                                border: "0 #E6E6E6 solid",
                                            }}
                                        >
                                            <div
                                                className="card-header align-items-center d-flex"
                                                style={{
                                                    background: "white",
                                                    borderRadius: 8,
                                                    border: "0 #E6E6E6 solid",
                                                    // marginTop: 20,
                                                    overflow: "scroll",
                                                    marginBottom: -20,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        flexDirection: "row",
                                                    }}
                                                >
                                                    <h4 className="text-uppercase card-title mb-0 flex-grow-1">
                                                        Zone
                                                        <div
                                                            style={{
                                                                marginLeft: -25,
                                                            }}
                                                        >
                                                            <SearchBar
                                                                placeHolder="Search..."
                                                                inputProps={{ "aria-label": "search" }}
                                                                searchValueForZone={searchValueForZone}
                                                                filteredRows={this.onSearchForZone}
                                                            ></SearchBar>
                                                        </div>
                                                    </h4>
                                                </div>
                                                <h4
                                                    className="text-uppercase card-title mb-0 flex-grow-1"
                                                    style={{
                                                        textAlign: "end",
                                                    }}
                                                >
                                                    Region
                                                </h4>
                                            </div>
                                        </div>
                                        <TableContainer
                                            sx={{
                                                maxHeight: 440,
                                                // width: 1020,
                                                padding: 0,
                                                marginTop: 2.5,
                                            }}
                                        >
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead
                                                    style={{
                                                        backgroundColor: "#fff",
                                                    }}
                                                >
                                                    <TableRow>
                                                        <TableCell
                                                            color="#000"
                                                            align="center"
                                                            sx={{ width: "2%", color: "#000" }}
                                                        >
                                                            Sr.No
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "8%", color: "#000" }}
                                                        >
                                                            Region Name
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "5%", color: "#000" }}
                                                        >
                                                            Acheived
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "5%", color: "#000" }}
                                                        >
                                                            Contribution in total(%)
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {/* {dashboardData.regionListFromYearAndMonth.map( */}
                                                    {
                                                        regionListFromYearAndMonthDataForZone.map(
                                                            (regionObject, index) => {
                                                                return (
                                                                    <TableRow>
                                                                        <TableCell align="center">
                                                                            {index + 1}
                                                                        </TableCell>
                                                                        <TableCell width="2%">
                                                                            <div
                                                                                onClick={(e) =>
                                                                                    this.regionHandler(e, regionObject.id)
                                                                                }
                                                                                style={{
                                                                                    color: cyanBlue,
                                                                                    cursor: "pointer",
                                                                                }}
                                                                            >
                                                                                {regionObject.name}
                                                                            </div>
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            {regionObject.total}
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            {regionObject.contribution}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            }
                                                        )}
                                                    <TableRow>
                                                        <TableCell align="center" width="2%" colSpan={2}>
                                                            Total
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {dashboardData.regionListFromYearAndMonthTotal}
                                                        </TableCell>
                                                        <TableCell align="center">-</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        {/* Sales & Manpower Table 2 end */}
                                    </div>
                                </div>

                                <Grid item xs={12} md={12} lg={12}>
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Grid item>
                                            <Typography variant="h5">Sales graph</Typography>
                                        </Grid>
                                        <Grid item />
                                    </Grid>
                                    <MainCard sx={{ mt: 2, border: "0" }} content={false}>
                                        <>
                                            <MonthlyBarChart
                                                series={[
                                                    {
                                                        data:
                                                            salesGraphList.length !== 0
                                                                ? salesGraphList.map((category) => {
                                                                    return category.achivedAmt;
                                                                })
                                                                : [],
                                                    },
                                                ]}
                                                optionsObj={{
                                                    chart: {
                                                        type: "bar",
                                                        height: 365,
                                                        toolbar: {
                                                            show: false,
                                                        },
                                                        events: {
                                                            dataPointSelection: (
                                                                event,
                                                                chartContext,
                                                                config
                                                            ) => {
                                                                // onClickLineChart(config.dataPointIndex);
                                                            },
                                                        },
                                                    },

                                                    plotOptions: {
                                                        bar: {
                                                            borderRadius: 4,
                                                            horizontal: true,
                                                        },
                                                    },
                                                    dataLabels: {
                                                        enabled: false,
                                                    },
                                                    xaxis: {
                                                        categories:
                                                            salesGraphList.length !== 0
                                                                ? salesGraphList.map((category) => {
                                                                    return category.dateMonth;
                                                                })
                                                                : [],

                                                        axisBorder: {
                                                            show: false,
                                                        },
                                                        axisTicks: {
                                                            show: false,
                                                        },
                                                        labels: {
                                                            style: {
                                                                // colors: [
                                                                //   secondary,
                                                                //   secondary,
                                                                //   secondary,
                                                                //   secondary,
                                                                //   secondary,
                                                                //   secondary,
                                                                //   secondary,
                                                                // ],
                                                            },
                                                        },
                                                    },
                                                    // yaxis: {
                                                    //   show: false,
                                                    // },
                                                    grid: {
                                                        show: false,
                                                    },
                                                    // colors: [info],

                                                    tooltip: {
                                                        theme: "light",
                                                    },
                                                }}
                                            />
                                        </>
                                    </MainCard>
                                </Grid>

                                {/* Sales Div starts */}

                                <div
                                    className="Frame427319149"
                                    style={{
                                        width: "100%",
                                        height: 36,
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        gap: 12,
                                        display: "inline-flex",
                                    }}
                                >
                                    <div
                                        className="SalesManpower"
                                        style={{
                                            color: "black",
                                            fontSize: 24.28,
                                            fontFamily: "Poppins",
                                            fontWeight: "500",
                                            textTransform: "capitalize",
                                            wordWrap: "break-word",
                                        }}
                                    >
                                        Sales
                                    </div>
                                </div>

                                {/* Sales Div end */}
                                <div
                                    className="row"
                                    style={{
                                        gap: 8,
                                        marginBottom: 10,
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "32%",
                                            background: "white",
                                            padding: 5,
                                            borderRadius: 8,
                                            border: "1px #E6E6E6 solid",
                                        }}
                                    >
                                        {/* Sales Table 1 starts */}
                                        <div
                                            // className="card"
                                            style={{
                                                marginBottom: -2,
                                                background: "white",
                                                borderRadius: 8,
                                                border: "0 #E6E6E6 solid",
                                            }}
                                        >
                                            <div
                                                className="card-header align-items-center d-flex"
                                                style={{
                                                    background: "white",
                                                    borderRadius: 8,
                                                    border: "0 #E6E6E6 solid",
                                                    overflow: "scroll",
                                                }}
                                            >
                                                <h4 className="text-uppercase card-title mb-0 flex-grow-1">
                                                    Region
                                                    <div
                                                        style={{
                                                            marginLeft: -25,
                                                        }}
                                                    >
                                                        <SearchBar
                                                            placeHolder="Search..."
                                                            inputProps={{ "aria-label": "search" }}
                                                            searchValueForSalesRegion={searchValueForSalesRegion}
                                                            filteredRows={this.onSearchForSalesRegion}
                                                        ></SearchBar>
                                                    </div>
                                                </h4>
                                                <h4
                                                    className="text-uppercase card-title mb-0 flex-grow-1"
                                                    style={{
                                                        textAlign: "end",
                                                        width: 180,
                                                    }}
                                                >
                                                    <MonthCalender
                                                        title=""
                                                        name="saleMonth"
                                                        value={saleMonth}
                                                        onChange={this.onDateChange}
                                                        sx={{
                                                            paddingBottom: 0,
                                                        }}
                                                    />
                                                </h4>
                                            </div>
                                        </div>
                                        <TableContainer
                                            sx={{
                                                maxHeight: 440,
                                                marginTop: 0,
                                                // width: "32%",
                                                padding: 0,
                                            }}
                                        >
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead
                                                    style={{
                                                        backgroundColor: "#fff",
                                                    }}
                                                >
                                                    <TableRow>
                                                        <TableCell
                                                            color="#000"
                                                            align="center"
                                                            sx={{ width: "2%", color: "#000" }}
                                                        >
                                                            Sr.No
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "8%", color: "#000" }}
                                                        >
                                                            Region Name
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "5%", color: "#000" }}
                                                        >
                                                            Acheived
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "5%", color: "#000" }}
                                                        >
                                                            Contribution in total(%)
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {regionListFromYearAndMonthDataForSalesRegion.map(
                                                        (regionObject, index) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell align="center">
                                                                        {index + 1}
                                                                    </TableCell>
                                                                    <TableCell width="2%">
                                                                        <div
                                                                            onClick={(e) =>
                                                                                this.regionHandler(e, regionObject.id)
                                                                            }
                                                                            style={{
                                                                                color: cyanBlue,
                                                                                cursor: "pointer",
                                                                            }}
                                                                        >
                                                                            {regionObject.name}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {regionObject.total}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {regionObject.contribution}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        }
                                                    )}
                                                    <TableRow>
                                                        <TableCell align="center" width="2%" colSpan={2}>
                                                            Total
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {dashboardData.regionListFromYearAndMonthTotal}
                                                        </TableCell>
                                                        <TableCell align="center">-</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {/* Sales Table 1 end */}
                                    </div>

                                    <div
                                        style={{
                                            width: "32%",
                                            background: "white",
                                            padding: 5,
                                            borderRadius: 8,
                                            border: "1px #E6E6E6 solid",
                                        }}
                                    >
                                        {/* Sales Table 2 starts */}
                                        <div
                                            // className="card"
                                            style={{
                                                marginBottom: -2,
                                                background: "white",
                                                borderRadius: 8,
                                                border: "0 #E6E6E6 solid",
                                            }}
                                        >
                                            <div
                                                className="card-header align-items-center d-flex justify-between"
                                                style={{
                                                    background: "white",
                                                    borderRadius: 8,
                                                    border: "0 #E6E6E6 solid",
                                                    marginBottom: -20,
                                                    overflow: "scroll",
                                                }}
                                            >
                                                <h4 className="text-uppercase card-title mb-0 flex-grow-1">
                                                    Zone Details
                                                    <div
                                                        style={{
                                                            marginLeft: -25,
                                                        }}
                                                    >
                                                        <SearchBar
                                                            placeHolder="Search..."
                                                            inputProps={{ "aria-label": "search" }}
                                                            searchValueForZoneDetails={searchValueForZoneDetails}
                                                            filteredRows={this.onSearchForZoneDetails}
                                                        ></SearchBar>
                                                    </div>
                                                </h4>
                                                <h4
                                                    className="text-uppercase card-title mb-0 flex-grow-1"
                                                    style={{
                                                        textAlign: "end",
                                                    }}
                                                >
                                                    {/* Nashik  */}
                                                </h4>
                                            </div>
                                        </div>
                                        <TableContainer
                                            sx={{
                                                maxHeight: 440,
                                                // width: "32%",
                                                padding: 0,
                                                marginTop: 2.5,
                                            }}
                                        >
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead
                                                    style={{
                                                        backgroundColor: "#fff",
                                                    }}
                                                >
                                                    <TableRow>
                                                        <TableCell
                                                            color="#000"
                                                            align="center"
                                                            sx={{ width: "2%", color: "#000" }}
                                                        >
                                                            Sr.No
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "8%", color: "#000" }}
                                                        >
                                                            Zone Name
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "5%", color: "#000" }}
                                                        >
                                                            Acheived
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "5%", color: "#000" }}
                                                        >
                                                            Contribution in total(%)
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {zoneDetailsData.map(
                                                        (zoneObject, index) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell align="center">
                                                                        {" "}
                                                                        {index + 1}
                                                                    </TableCell>
                                                                    <TableCell width="2%">
                                                                        <div
                                                                            onClick={(e) =>
                                                                                this.zoneHandler(e, zoneObject.id)
                                                                            }
                                                                            style={{
                                                                                color: cyanBlue,
                                                                                cursor: "pointer",
                                                                            }}
                                                                        >
                                                                            {zoneObject.name}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {zoneObject.total}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {zoneObject.contribution == "NaN"
                                                                            ? "-"
                                                                            : zoneObject.contribution}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        }
                                                    )}
                                                    <TableRow>
                                                        <TableCell align="center" width="2%" colSpan={2}>
                                                            Total
                                                        </TableCell>

                                                        <TableCell align="right">
                                                            {dashboardData.zoneListFromYearAndMonthTotal}
                                                        </TableCell>
                                                        <TableCell align="center">-</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        {/* Sales Table 2 end */}
                                    </div>

                                    <div
                                        style={{
                                            width: "32%",
                                            background: "white",
                                            padding: 5,
                                            borderRadius: 8,
                                            border: "1px #E6E6E6 solid",
                                        }}
                                    >
                                        {/* Sales Table 3 starts */}
                                        <div
                                            // className="card"
                                            style={{
                                                marginBottom: -2,
                                                background: "white",
                                                borderRadius: 8,
                                                border: "0 #E6E6E6 solid",
                                            }}
                                        >
                                            <div
                                                className="card-header align-items-center d-flex"
                                                style={{
                                                    background: "white",
                                                    borderRadius: 8,
                                                    border: "0 #E6E6E6 solid",
                                                    marginBottom: -20,
                                                    overflow: "scroll",
                                                }}
                                            >
                                                <h4 className="text-uppercase card-title mb-0 flex-grow-1">
                                                    Area Details
                                                    <div
                                                        style={{
                                                            marginLeft: -25,
                                                        }}
                                                    >
                                                        <SearchBar
                                                            placeHolder="Search..."
                                                            inputProps={{ "aria-label": "search" }}
                                                            searchValueForAreaDetails={searchValueForAreaDetails}
                                                            filteredRows={this.onSearchForAreaDetails}
                                                        ></SearchBar>
                                                    </div>
                                                </h4>
                                                <h4
                                                    className="text-uppercase card-title mb-0 flex-grow-1"
                                                    style={{
                                                        textAlign: "end",
                                                    }}
                                                >
                                                    {/* Nashik Zone */}
                                                </h4>
                                            </div>
                                        </div>
                                        <TableContainer
                                            sx={{
                                                maxHeight: 440,
                                                // width: "32%",
                                                padding: 0,
                                                marginTop: 2.5,
                                            }}
                                        >
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead
                                                    style={{
                                                        backgroundColor: "#fff",
                                                    }}
                                                >
                                                    <TableRow>
                                                        <TableCell
                                                            color="#000"
                                                            align="center"
                                                            sx={{ width: "2%", color: "#000" }}
                                                        >
                                                            Sr.No
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "8%", color: "#000" }}
                                                        >
                                                            Area Name
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "5%", color: "#000" }}
                                                        >
                                                            Acheived
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{ width: "5%", color: "#000" }}
                                                        >
                                                            Contribution in total(%)
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {areaDetailsData.map(
                                                        (areaObject, index) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell align="center">
                                                                        {index + 1}
                                                                    </TableCell>
                                                                    <TableCell width="2%">
                                                                        {areaObject.name}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {areaObject.total}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {areaObject.contribution == "NaN"
                                                                            ? "-"
                                                                            : areaObject.contribution}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        }
                                                    )}
                                                    <TableRow>
                                                        <TableCell align="center" width="2%" colSpan={2}>
                                                            Total
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {dashboardData.areaListFromYearAndMonthTotal}
                                                        </TableCell>
                                                        <TableCell align="center">-</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        {/* Sales Table 3 end */}
                                    </div>
                                </div>

                                {/* <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row">
                            <div className="col-lg-5">
                              <div className="card card-animate flex-row">
                                <div className="card-body">
                                  <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                    Company Target Amount
                                  </p>
                                  <div className="d-flex align-items-end justify-content-between mt-3">
                                    <div>
                                      <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                        <span
                                          className="counter-value"
                                          data-target="559.25"
                                        >
                                          {(+dashboardData?.currentYearTarget
                                            .currentTargetAmt).toFixed(2)}
                                        </span>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-body">
                                  <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                    Company Acheived Amount
                                  </p>
                                  <div className="d-flex align-items-end justify-content-between mt-3">
                                    <div>
                                      <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                        <span
                                          className="counter-value"
                                          data-target="559.25"
                                        >
                                          {(+dashboardData?.currentYearTarget
                                            .currentAchievedAmt).toFixed(2)}
                                        </span>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-7">
                              <div className="card">
                                <div className="card-header align-items-center d-flex">
                                  <h4 className="text-uppercase card-title mb-0 flex-grow-1">
                                    Till current month
                                  </h4>
                                </div>
                              </div>
                              <div className="card card-animate flex-row">
                                <div className="card-body">
                                  <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                    Company Target Amount
                                  </p>
                                  <div className="d-flex align-items-end justify-content-between mt-3">
                                    <div>
                                      <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                        <span
                                          className="counter-value"
                                          data-target="559.25"
                                        >
                                          {(+dashboardData?.tillMonthYearTarget
                                            .targetAmt).toFixed(2)}
                                        </span>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-body">
                                  <p className="text-uppercase fw-medium text-muted text-truncate mb-3">
                                    Company Acheived Amount
                                  </p>
                                  <div className="d-flex align-items-end justify-content-between mt-3">
                                    <div>
                                      <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                        <span
                                          className="counter-value"
                                          data-target="559.25"
                                        >
                                          {(+dashboardData?.tillMonthYearTarget
                                            .achivedAmt).toFixed(2)}
                                        </span>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-body">
                                  <div className="d-flex align-items-end justify-content-between mt-3">
                                    <div>
                                      <h4 className="fs-22 fw-semibold ff-secondary mb-2">
                                        <span
                                          className="counter-value"
                                          data-target="559.25"
                                        >
                                          {dashboardData?.tillMonthYearTarget
                                            .contribution >= 100 ? (
                                            <div>
                                              {(+dashboardData
                                                ?.tillMonthYearTarget
                                                .contribution).toFixed(2) + " %"}
                                            </div>
                                          ) : (
                                            <div
                                              style={{
                                                color: yellowishBrownColor,
                                              }}
                                            >
                                              {"- " +
                                                (+dashboardData
                                                  ?.tillMonthYearTarget
                                                  .contribution).toFixed(2) +
                                                " %"}
                                            </div>
                                          )}
                                        </span>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                                {/* <LineChart
                    options={{
                      animationEnabled: true,
                      exportEnabled: true,
                      theme: "light2",
                      axisY: {
                        title: "Target Amount",
                        // includeZero: falRegionse,
                        suffix: "",
                      },
                      axisX: {
                        title: "Month of Year",
                        prefix: "",
                        interval: 1,
                      },
                      data: [
                        {
                          type: "line",
                          toolTipContent: "Target Amount of {label}: {y}",
  
                          dataPoints: dashboardData.monthYearTarget.map(
                            (monthWiseData) => {
                              return {
                                label: monthList[monthWiseData.month - 1],
  
                                y: monthWiseData.targetAmt,
                              };
                            }
                          ),
                        },
                        {
                          type: "line",
                          toolTipContent: "Achived Amount of {label}: {y}",
  
                          dataPoints: dashboardData.monthYearTarget.map(
                            (monthWiseData) => {
                              return {
                                label: monthList[monthWiseData.month - 1],
  
                                y: monthWiseData.achivedAmt,
                              };
                            }
                          ),
                        },
                      ],
                    }}
                  /> */}
                                {/* <div className="col-lg-5">
                    <div className="card">
                      <div className="card-header align-items-center d-flex">
                        <div className="col-lg-12">
                          <MonthCalender
                            title="Year"
                            name="saleMonth"
                            value={saleMonth}
                            onChange={this.onDateChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                                <div
                                    className="row"
                                    style={{
                                        gap: 8,
                                        marginBottom: 10,
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "62%",
                                            background: "white",
                                            padding: 5,
                                            borderRadius: 8,
                                            border: "1px #E6E6E6 solid",
                                        }}
                                    >
                                        {/* Sales & Manpower Table 1 starts */}
                                        <div
                                            // className="card"
                                            style={{
                                                marginBottom: -2,
                                                background: "white",
                                                borderRadius: 8,
                                                border: "0 #E6E6E6 solid",
                                            }}
                                        >
                                            <div
                                                className="card-header align-items-center d-flex"
                                                style={{
                                                    background: "white",
                                                    borderRadius: 8,
                                                    border: "0 #E6E6E6 solid",
                                                    overflow: "scroll",
                                                }}
                                            >
                                                <h4 className="text-uppercase card-title mb-0 flex-grow-1">
                                                    Person Wise Sales
                                                    <div
                                                        style={{
                                                            marginLeft: -25,
                                                        }}
                                                    >
                                                        {/* <SearchBar
                                placeHolder="Search..."
                                inputProps={{ "aria-label": "search" }}
                                searchValuePersonWiseSales={searchValuePersonWiseSales}
                                filteredRows={this.onSearchPersonWiseSales}
                              ></SearchBar> */}
                                                    </div>
                                                </h4>
                                                <h4
                                                    className="text-uppercase card-title mb-0 flex-grow-1"
                                                    style={{
                                                        marginTop: 2,
                                                    }}
                                                >
                                                    <AutoComplete
                                                        keyColName={"id"}
                                                        placeHolder="Stock Stake Holder Type"
                                                        value={type}
                                                        name={"type"}
                                                        options={
                                                            stockStakeHolderTypeList?.stockStakeHolderTypeList
                                                        }
                                                        onChange={this.ChangeHandlerSearch}
                                                        isError={typeError ? true : false}
                                                        errorText={typeError ? typeError : " "}
                                                        sx={{
                                                            marginTop: 1.2,
                                                            marginRight: 5,
                                                        }}
                                                    />
                                                </h4>

                                                <h4
                                                    className="text-uppercase card-title mb-0 flex-grow-1"
                                                    style={{
                                                        textAlign: "end",
                                                        width: 180,
                                                    }}
                                                >
                                                    <MonthCalender
                                                        title=""
                                                        name="saleMonth"
                                                        value={saleMonth}
                                                        onChange={this.onDateChange}
                                                        sx={{
                                                            paddingBottom: 0,
                                                        }}
                                                    />
                                                </h4>
                                            </div>
                                        </div>
                                        <TableContainer
                                            sx={{
                                                maxHeight: 440,
                                                marginTop: 0,
                                                // width: "60%",
                                                padding: 0,
                                            }}
                                        >
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead
                                                    style={{
                                                        backgroundColor: "#fff",
                                                    }}
                                                >
                                                    <TableRow>
                                                        <TableCell align="center" sx={{ width: "2%" }}>
                                                            Sr.No
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ width: "8%" }}>
                                                            Name
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ width: "5%" }}>
                                                            Target Amount
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ width: "5%" }}>
                                                            Acheived
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ width: "5%" }}>
                                                            % Contribution
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ width: "5%" }}>
                                                            % count (+ve, -ve)
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {dashboardData.salesListFromYearAndMonth.map(
                                                        (regionObject, index) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell align="center">
                                                                        {index + 1}
                                                                    </TableCell>
                                                                    <TableCell width="2%">
                                                                        <div
                                                                            onClick={(e) =>
                                                                                this.salesHandler(e, regionObject.id)
                                                                            }
                                                                            style={{
                                                                                color: cyanBlue,
                                                                                cursor: "pointer",
                                                                            }}
                                                                        >
                                                                            {regionObject.name}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {regionObject.targetAmt}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {regionObject.actualAmt}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {regionObject.contribution}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {regionObject.divisional}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        }
                                                    )}
                                                    <TableRow>
                                                        <TableCell align="center" width="2%" colSpan={3}>
                                                            Total
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {dashboardData.salesListFromYearAndMonthTotal}
                                                        </TableCell>
                                                        <TableCell align="center">-</TableCell>
                                                        <TableCell align="center">-</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        {/* Sales & Manpower Table 1 end */}
                                    </div>

                                    <div
                                        style={{
                                            width: "500px",
                                            background: "white",
                                            padding: 5,
                                            borderRadius: 8,
                                            border: "1px #E6E6E6 solid",
                                        }}
                                    >
                                        {/* Sales & Manpower Table 2 starts */}
                                        <MonthlyBarChart
                                            // radioValue={onChangeRadioValue}
                                            height={550}
                                            type={"line"}
                                            series={[
                                                {
                                                    name: "Desktops",
                                                    data: lineGraphData,
                                                },
                                            ]}
                                            optionsObj={{
                                                chart: {
                                                    height: 350,
                                                    type: "line",
                                                    zoom: {
                                                        enabled: false,
                                                    },
                                                },
                                                dataLabels: {
                                                    enabled: false,
                                                },
                                                stroke: {
                                                    curve: "straight",
                                                },
                                                title: {
                                                    text: "Performance",
                                                    align: "left",
                                                },
                                                grid: {
                                                    row: {
                                                        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                                                        opacity: 0.5,
                                                    },
                                                },
                                                xaxis: {
                                                    categories: [
                                                        "Apr",
                                                        "May",
                                                        "Jun",
                                                        "Jul",
                                                        "Aug",
                                                        "Sep",
                                                        "Oct",
                                                    ],
                                                },
                                            }}
                                        />

                                        {/* Sales & Manpower Table 2 end */}
                                    </div>
                                </div>

                                {/* Top 20 Div starts */}

                                {/* <div
                    className="Frame427319149"
                    style={{
                      width: "100%",
                      height: 36,
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 12,
                      display: "inline-flex",
                    }}
                  >
                    <div
                      className="SalesManpower"
                      style={{
                        color: "black",
                        fontSize: 24.28,
                        fontFamily: "Poppins",
                        fontWeight: "500",
                        textTransform: "capitalize",
                        wordWrap: "break-word",
                      }}
                    >
                      Top 20 Performing Product
                    </div>
                  </div> */}

                                {/* Top 20 Div end */}
                                <div
                                    className="row"
                                    style={{
                                        gap: 8,
                                        marginBottom: 10,
                                        width: "100%",
                                        marginLeft: 5,
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "56.4%",
                                            background: "white",
                                            padding: 5,
                                            borderRadius: 8,
                                            border: "1px #E6E6E6 solid",
                                        }}
                                    >
                                        {/* sales Overview graph starts */}
                                        <div
                                            // className="card"
                                            style={{
                                                marginBottom: -2,
                                                background: "white",
                                                borderRadius: 8,
                                                border: "0 #E6E6E6 solid",
                                            }}
                                        >
                                            <div
                                                className="card-header align-items-center d-flex"
                                                style={{
                                                    background: "white",
                                                    borderRadius: 8,
                                                    border: "0 #E6E6E6 solid",
                                                    overflow: "scroll",
                                                }}
                                            >
                                                <h4 className="text-uppercase card-title mb-0 flex-grow-1">
                                                    Top 20 Performing Product
                                                    <div
                                                        style={{
                                                            marginLeft: -25,
                                                        }}
                                                    >
                                                        <SearchBar
                                                            placeHolder="Search..."
                                                            inputProps={{ "aria-label": "search" }}
                                                            searchValueForProduct={searchValueForProduct}
                                                            filteredRows={this.onSearchForTopPerformingProducts}
                                                        ></SearchBar>
                                                    </div>
                                                </h4>
                                                <h4
                                                    className="text-uppercase card-title mb-0 flex-grow-1"
                                                    style={{
                                                        marginTop: 2,
                                                    }}
                                                >
                                                    <AutoComplete
                                                        keyColName={"id"}
                                                        placeHolder="Zone"
                                                        value={productZone}
                                                        name={"productZone"}
                                                        options={zoneList?.zoneByUserType}
                                                        onChange={this.ChangeHandlerSearch}
                                                        // isError={typeError ? true : false}
                                                        // errorText={typeError ? typeError : " "}
                                                        sx={{
                                                            marginTop: 1.2,
                                                            marginRight: 5,
                                                        }}
                                                    />
                                                </h4>
                                                <h4
                                                    className="text-uppercase card-title mb-0 flex-grow-1"
                                                    style={{
                                                        marginTop: 2,
                                                    }}
                                                >
                                                    <AutoComplete
                                                        keyColName={"id"}
                                                        placeHolder="Area"
                                                        value={productArea}
                                                        name={"productArea"}
                                                        options={areaList?.area}
                                                        onChange={this.ChangeHandlerSearch}
                                                        // isError={typeError ? true : false}
                                                        // errorText={typeError ? typeError : " "}
                                                        sx={{
                                                            marginTop: 1.2,
                                                            marginRight: 5,
                                                        }}
                                                    />
                                                </h4>
                                                <h4
                                                    className="text-uppercase card-title mb-0 flex-grow-1"
                                                    style={{
                                                        textAlign: "end",
                                                        width: 180,
                                                    }}
                                                >
                                                    <MonthCalender
                                                        title=""
                                                        name="saleMonth"
                                                        value={saleMonth}
                                                        onChange={this.onDateChange}
                                                        sx={{
                                                            paddingBottom: 0,
                                                        }}
                                                    />
                                                </h4>
                                            </div>
                                        </div>
                                        <TableContainer
                                            sx={{
                                                maxHeight: 440,
                                                marginTop: 0,
                                                // width: "60%",
                                                // padding: 1.5,
                                            }}
                                        >
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead
                                                    style={{
                                                        backgroundColor: "#fff",
                                                    }}
                                                >
                                                    <TableRow>
                                                        <TableCell align="center" sx={{ width: "2%" }}>
                                                            Sr.No
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ width: "8%" }}>
                                                            Name
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ width: "5%" }}>
                                                            Product Code
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ width: "5%" }}>
                                                            Acheived
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ width: "5%" }}>
                                                            % Contribution
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {topPerformingProductData.map(
                                                        (regionObject, index) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell align="center">
                                                                        {index + 1}
                                                                    </TableCell>
                                                                    <TableCell width="2%">
                                                                        <div
                                                                            onClick={(e) =>
                                                                                this.topProductHandler(
                                                                                    e,
                                                                                    regionObject.id
                                                                                )
                                                                            }
                                                                            style={{
                                                                                color: cyanBlue,
                                                                                cursor: "pointer",
                                                                            }}
                                                                        >
                                                                            {regionObject.name}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell>{regionObject.code}</TableCell>
                                                                    <TableCell align="right">
                                                                        {regionObject.actualSale}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {regionObject.contribution}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        }
                                                    )}
                                                    <TableRow>
                                                        <TableCell align="center" width="2%" colSpan={3}>
                                                            Total
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {dashboardData.productListFromYearAndMonthTotal}
                                                        </TableCell>
                                                        <TableCell align="center">-</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        {/* Sales & Manpower Table 1 end */}
                                    </div>

                                    <div
                                        style={{
                                            width: "40%",
                                            background: "white",
                                            padding: 5,
                                            borderRadius: 8,
                                            border: "1px #E6E6E6 solid",
                                        }}
                                    >
                                        {/* Sales & Manpower Table 2 starts */}
                                        <MonthlyBarChart
                                            // radioValue={onChangeRadioValue}
                                            height={550}
                                            series={[
                                                {
                                                    name: "Net Profit",
                                                    data: [44, 60, 57, 70, 61, 80],
                                                },
                                            ]}
                                            optionsObj={{
                                                chart: {
                                                    type: "bar",
                                                    height: 365,
                                                    events: {
                                                        dataPointSelection: (
                                                            event,
                                                            chartContext,
                                                            config
                                                        ) => {
                                                            onClickLineChart(config.dataPointIndex);
                                                        },
                                                    },
                                                },

                                                plotOptions: {
                                                    bar: {
                                                        columnWidth: "40%",
                                                        distributed: true,
                                                    },
                                                },
                                                stroke: {
                                                    show: true,
                                                    width: 2,
                                                    // colors: ["transparent"],
                                                },
                                                dataLabels: {
                                                    enabled: false,
                                                },
                                                xaxis: {
                                                    categories: [
                                                        "May",
                                                        "Jun",
                                                        "Jul",
                                                        "Aug",
                                                        "Sep",
                                                        "Oct",
                                                    ],
                                                },
                                                yaxis: {
                                                    title: {
                                                        text: "$ (thousands)",
                                                    },
                                                },
                                                fill: {
                                                    opacity: 1,
                                                },
                                                colors: ["#FF0000", "#00FFFF", "#008000", "#000FFF"],

                                                tooltip: {
                                                    theme: "light",
                                                },
                                            }}
                                        />

                                        {/* Sales & Manpower Table 2 end */}
                                    </div>
                                </div>

                                {/* <div
                    className="Frame427319149"
                    style={{
                      width: "100%",
                      height: 36,
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 12,
                      display: "inline-flex",
                    }}
                  >
                    <div
                      className="SalesManpower"
                      style={{
                        color: "black",
                        fontSize: 24.28,
                        fontFamily: "Poppins",
                        fontWeight: "500",
                        textTransform: "capitalize",
                        wordWrap: "break-word",
                      }}
                    >
                      Top 20 Performing Outlet
                    </div>
                  </div> */}

                                {/* sales Overview Div starts */}
                                <div
                                    className="row"
                                    style={{
                                        gap: 8,
                                        marginBottom: 10,
                                        width: "100%",
                                        marginLeft: 5,
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "56.4%",
                                            background: "white",
                                            padding: 5,
                                            borderRadius: 8,
                                            border: "1px #E6E6E6 solid",
                                        }}
                                    >
                                        {/* sales Overview graph starts */}
                                        <div
                                            // className="card"
                                            style={{
                                                marginBottom: -2,
                                                background: "white",
                                                borderRadius: 8,
                                                border: "0 #E6E6E6 solid",
                                            }}
                                        >
                                            <div
                                                className="card-header align-items-center d-flex"
                                                style={{
                                                    background: "white",
                                                    borderRadius: 8,
                                                    border: "0 #E6E6E6 solid",
                                                    overflow: "scroll",
                                                }}
                                            >
                                                <h4 className="text-uppercase card-title mb-0 flex-grow-1">
                                                    Top 20 Performing Outlet
                                                    <div
                                                        style={{
                                                            marginLeft: -25,
                                                        }}
                                                    >
                                                        <SearchBar
                                                            placeHolder="Search..."
                                                            inputProps={{ "aria-label": "search" }}
                                                            searchValueForOutlets={searchValueForOutlets}
                                                            filteredRows={this.onSearchForTopPerformingOutlets}
                                                        ></SearchBar>
                                                    </div>
                                                </h4>
                                                <h4
                                                    className="text-uppercase card-title mb-0 flex-grow-1"
                                                    style={{
                                                        marginTop: 2,
                                                    }}
                                                >
                                                    <AutoComplete
                                                        keyColName={"id"}
                                                        placeHolder="Zone"
                                                        value={outletZone}
                                                        name={"outletZone"}
                                                        options={zoneList?.zoneByUserType}
                                                        onChange={this.ChangeHandlerSearch}
                                                        // isError={typeError ? true : false}
                                                        // errorText={typeError ? typeError : " "}
                                                        sx={{
                                                            marginTop: 1.2,
                                                            marginRight: 5,
                                                        }}
                                                    />
                                                </h4>
                                                <h4
                                                    className="text-uppercase card-title mb-0 flex-grow-1"
                                                    style={{
                                                        marginTop: 2,
                                                    }}
                                                >
                                                    <AutoComplete
                                                        keyColName={"id"}
                                                        placeHolder="Area"
                                                        value={outletArea}
                                                        name={"outletArea"}
                                                        options={areaList?.area}
                                                        onChange={this.ChangeHandlerSearch}
                                                        // isError={typeError ? true : false}
                                                        // errorText={typeError ? typeError : " "}
                                                        sx={{
                                                            marginTop: 1.2,
                                                            marginRight: 5,
                                                        }}
                                                    />
                                                </h4>
                                                <h4
                                                    className="text-uppercase card-title mb-0 flex-grow-1"
                                                    style={{
                                                        textAlign: "end",
                                                        width: 180,
                                                    }}
                                                >
                                                    <MonthCalender
                                                        title=""
                                                        name="saleMonth"
                                                        value={saleMonth}
                                                        onChange={this.onDateChange}
                                                        sx={{
                                                            paddingBottom: 0,
                                                        }}
                                                    />
                                                </h4>
                                            </div>
                                        </div>
                                        <TableContainer
                                            sx={{
                                                maxHeight: 440,
                                                marginTop: 0,
                                                // width: "60%",
                                                // padding: 1.5,
                                            }}
                                        >
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead
                                                    style={{
                                                        backgroundColor: "#fff",
                                                    }}
                                                >
                                                    <TableRow>
                                                        <TableCell align="center" sx={{ width: "2%" }}>
                                                            Sr.No
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ width: "8%" }}>
                                                            Outlet Name
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ width: "5%" }}>
                                                            Area Name
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ width: "8%" }}>
                                                            Sales-Executive
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ width: "5%" }}>
                                                            Achieved
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ width: "5%" }}>
                                                            % Contribution
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {topPerformingOutletData.map(
                                                        (regionObject, index) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell align="center">
                                                                        {index + 1}
                                                                    </TableCell>
                                                                    <TableCell width="2%">
                                                                        <div
                                                                            onClick={(e) =>
                                                                                this.regionHandler(e, regionObject.id)
                                                                            }
                                                                            style={{
                                                                                color: cyanBlue,
                                                                                cursor: "pointer",
                                                                            }}
                                                                        >
                                                                            {regionObject.firmName}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell>{regionObject.area}</TableCell>
                                                                    <TableCell>
                                                                        {regionObject.salePersonName}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {regionObject.actualSale}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {regionObject.contribution}
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        }
                                                    )}
                                                    <TableRow>
                                                        <TableCell align="center" width="2%" colSpan={4}>
                                                            Total
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {dashboardData.outletListFromYearAndMonthTotal}
                                                        </TableCell>
                                                        <TableCell align="center">-</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        {/* Sales & Manpower Table 1 end */}
                                    </div>

                                    <div
                                        style={{
                                            width: "40%",
                                            background: "white",
                                            padding: 5,
                                            borderRadius: 8,
                                            border: "1px #E6E6E6 solid",
                                        }}
                                    >
                                        {/* Sales & Manpower Table 2 starts */}
                                        <MonthlyBarChart
                                            // radioValue={onChangeRadioValue}
                                            height={550}
                                            series={[
                                                {
                                                    name: "Net Profit",
                                                    data: [70, 60, 80, 10, 20, 50],
                                                },
                                            ]}
                                            optionsObj={{
                                                chart: {
                                                    type: "bar",
                                                    height: 365,
                                                    events: {
                                                        dataPointSelection: (
                                                            event,
                                                            chartContext,
                                                            config
                                                        ) => {
                                                            onClickLineChart(config.dataPointIndex);
                                                        },
                                                    },
                                                },

                                                plotOptions: {
                                                    bar: {
                                                        columnWidth: "40%",
                                                        distributed: true,
                                                    },
                                                },
                                                stroke: {
                                                    show: true,
                                                    width: 2,
                                                    // colors: ["transparent"],
                                                },
                                                dataLabels: {
                                                    enabled: false,
                                                },
                                                xaxis: {
                                                    categories: [
                                                        "May",
                                                        "Jun",
                                                        "Jul",
                                                        "Aug",
                                                        "Sep",
                                                        "Oct",
                                                    ],
                                                },
                                                yaxis: {
                                                    title: {
                                                        text: "$ (thousands)",
                                                    },
                                                },
                                                fill: {
                                                    opacity: 1,
                                                },
                                                colors: ["#FF0000", "#00FFFF", "#008000", "#000FFF"],

                                                tooltip: {
                                                    theme: "light",
                                                },
                                            }}
                                        />
                                        {/* Sales & Manpower Table 2 end */}
                                    </div>
                                </div>

                                {/* Top 20 Div starts */}

                                {/* Top 20 Div end */}
                                <div
                                    className="row"
                                    style={{
                                        gap: 8,
                                        marginBottom: 10,
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "100%",
                                            background: "white",
                                            padding: 5,
                                            borderRadius: 8,
                                            border: "1px #E6E6E6 solid",
                                        }}
                                    >
                                        <div
                                            style={{
                                                marginLeft: -25,
                                            }}
                                        >

                                        </div>
                                        {/* Top 20 Table 1 starts */}
                                        <MainCard sx={{ mt: 2 }} content={false}>
                                            {dashboardData.salesInfo.length != 0 ? (
                                                <OrdersTable
                                                    rows={dashboardData.salesInfo.map((sales) => {
                                                        const seriesData = [
                                                            {
                                                                label:
                                                                    sales.dtoDateWiseTotalAmount != null
                                                                        ? sales.dtoDateWiseTotalAmount.map(
                                                                            (rowData) => {
                                                                                return rowData.date;
                                                                            }
                                                                        )
                                                                        : [],
                                                                name: "Amount",
                                                                data:
                                                                    sales.dtoDateWiseTotalAmount != null
                                                                        ? sales.dtoDateWiseTotalAmount.map(
                                                                            (rowData) => {
                                                                                return rowData.totalAmount;
                                                                            }
                                                                        )
                                                                        : [],
                                                            },
                                                        ];
                                                        return {
                                                            ...sales,
                                                            salesTeam:
                                                                sales.salesTeam != null
                                                                    ? sales.salesTeam.name
                                                                    : "",
                                                            salesTeamId:
                                                                sales.salesTeam != null
                                                                    ? sales.salesTeam.id
                                                                    : "",
                                                            series: seriesData,
                                                            options: {
                                                                chart: {
                                                                    type: "line",
                                                                    width: 100,
                                                                    height: 35,
                                                                    sparkline: {
                                                                        enabled: true,
                                                                    },
                                                                },

                                                                stroke: {
                                                                    curve: "straight",
                                                                },
                                                                tooltip: {
                                                                    custom: function ({
                                                                        series,
                                                                        seriesIndex,
                                                                        dataPointIndex,
                                                                        w,
                                                                    }) {
                                                                        return (
                                                                            '<div class="arrow_box">' +
                                                                            "<span><b>" +
                                                                            seriesData[0].label[dataPointIndex] +
                                                                            " - " +
                                                                            series[seriesIndex][dataPointIndex] +
                                                                            "  ₹" +
                                                                            "</b></span>" +
                                                                            "</div>"
                                                                        );
                                                                    },
                                                                },
                                                                xaxis: {
                                                                    categories:
                                                                        sales.dtoDateWiseTotalAmount != null
                                                                            ? sales.dtoDateWiseTotalAmount.map(
                                                                                (rowData) => {
                                                                                    return rowData.date;
                                                                                }
                                                                            )
                                                                            : [],
                                                                },
                                                            },
                                                        };
                                                    })}
                                                    extraData={this.getExtraData(dashboardData.salesInfo)}
                                                />
                                            ) : (
                                                <NoDataFound />
                                            )}
                                        </MainCard>

                                        {/* Top 20 Table 1 end */}
                                    </div>
                                </div>
                            </div>

                            {/* Person-wise sales Div starts */}

                            {/* Sales & Manpower Div end */}

                            <footer className="footer">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-sm-6">2022 © Byte Elephants. </div>
                                        <div className="col-sm-6">
                                            <div className="text-sm-end d-none d-sm-block">
                                                {" "}
                                                Designed &amp; Developed by Byte Elephants Technologies
                                                PVT LTD{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </footer>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    common: state.common,
    dashboardData: state.adminDashboard,
    dashboardData2: state.adminDashboard,
    stockStakeHolderTypeList: state.stockStakeHolderType,
    zoneList: state.zone,
    areaList: state.area,
});

const mapDispatchToProps = {
    onToggle,
    showLoador,
    getCurrentYearTarget,
    getTillMonthYearTarget,
    showNotification,
    getRegionListFromYearAndMonth,
    getZoneListFromYearAndMonth,
    getAreaListFromYearAndMonth,
    getStockStakeHolderTypeForList,
    getSalesListFromYearAndMonth,
    getOrderListFromYearAndMonth,
    getProductListFromYearAndMonth,
    getMonthYearTarget,
    getSalesTeamInfo,
    getMonthYearTargetForSales,
    getAreaByUserType,
    getZoneByUserType,
    setRegionListFromYearAndMonth,
};
export default connect(mapStateToProps, mapDispatchToProps)(StockHolderDashboard);
