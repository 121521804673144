export const placedOrderJson = {
  screenTitle: "Purchase Order",
  showSaveBtn: false,

  fieldMeta: [
    // {
    //   label: "Order Date",
    //   controlType: "datepicker",
    //   placeHolder: "",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "orderDate",
    //   isMandatory: true,
    //  disabledTrue:true, 
    // },
    // {
    //   label: "Discount %",
    //   controlType: "textfield",
    //   placeHolder: "Discount %",
    //   inputType: "number",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   dataKey: "DiscountPercentage",
    //   isMandatory: false,
    // },
  ],
};
