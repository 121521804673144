import endpoint from "../../config/endpoints";

export const documentJson = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.document,
  changeExcel: endpoint.exportData,
  formPath: "/document-form",
  screenTitle: "Document",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Document",
  showExcelDownload: true,
  excelFileName: "Document",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "docName",
      isChecked: true,
    },
    {
      columnDisplayName: "Type",
      columnKeyName: "type",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "docName",
      isMandatory: true,
      uniqueValidation: true,
    },
    {
      label: "Type",
      controlType: "autocomplete",
      placeHolder: "Select Type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "type",
      dataKey: "type",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
};
