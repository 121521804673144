import {
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import CheckBoxSelectionTable from "../../components/Comman/RT/CheckBoxSelectionTable";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutValidation";
import { redColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  checkedListEmptyMsg,
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { mandatoryCheck, ValidationCheck } from "../../config/ValidationCheck";
import { stockistSettleBillFormJSON } from "../../DynamicFormsJson/Transaction/stockistSettleBill";
import { getStockStackHolders } from "../../Slice/StackHolder.slice";
import {
  getPaymentReceiptTransactionForStakeholder,
  getSettleBillWithPaymentListForStakeholder,
  resetData,
  setBillData,
} from "../../Slice/stockistSettleBill.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import PaymentModeDialog from "./PaymentModeDialog";
const columns = [
  {
    title: "Sr. No.",
    name: "index",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    align: "center",
  },
  {
    title: "Bill No.",
    name: "billNo",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "center",
  },
  {
    title: "Bill Date",
    name: "billDate",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "center",
  },
  {
    title: "Bill Amount.",
    name: "totalAmt",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "right",
  },
  {
    title: "Remaining Amount",
    name: "remainingAmt",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "right",
  },
];
class StockistSettleBillForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentRecTransId: "",
      fromStockStakeHolderId: "",
      isErrorListEmpty: false,
      DataToSet: [],
      dynamicMasterData: {
        stakeholdersDetail: this.props.stakeholdersList.stockStakeHolders,
        paymentRecTranDetail:
          this.props.stockistSettleBillList
            ?.paymentReceiptTransactionForStakeholderData,
      },
      formErrors: {},
      fieldData: {},
      openOutletDialog: false,
      value: "0",
      totalRemainingAmt: 0,
      totalReceivedAmt: 0,
      setList: [],
      remainAmt: 0,
    };
  }

  handleChange = (event) => {
    this.props.setBillData({ row: [] });
    this.setState({
      value: event.target.value,
      formErrors: {},
      fieldData: {},
      DataToSet: [
        {
          xs: 12,
          sm: 4,
          md: 4,
          lg: 4,
          label: "Remaining Amount ",
          value: 0,
        },
      ],
    });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.stakeholdersList !== nextProps.stakeholdersList) {
      if (
        this.props.stakeholdersList?.stockStakeHolders !==
        nextProps.stakeholdersList?.stockStakeHolders
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeholdersDetail: nextProps.stakeholdersList?.stockStakeHolders,
          },
        });
      }
    }

    if (
      this.props.stockistSettleBillList !== nextProps.stockistSettleBillList
    ) {
      if (
        this.props.stockistSettleBillList
          ?.paymentReceiptTransactionForStakeholderData !==
        nextProps.stockistSettleBillList
          ?.paymentReceiptTransactionForStakeholderData
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            paymentRecTranDetail:
              nextProps.stockistSettleBillList
                ?.paymentReceiptTransactionForStakeholderData,
          },
        });
      }
    }
  }

  getListById = async (data) => {
    const {
      getPaymentReceiptTransactionForStakeholder,
      showLoador,
      showNotification,
      resetData,
    } = this.props;
    if (navigator.onLine) {
      if (data.stakeholder) {
        showLoador({ loador: true });
        await getPaymentReceiptTransactionForStakeholder({
          stakeholderId: data.stakeholder ? data.stakeholder : 0,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }

      if (data.paymentRecTran == "") {
        resetData();
        this.setState({
          DataToSet: [
            {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              label: "Remaining Amount ",
              value: 0,
            },
          ],
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  async componentDidMount() {
    const {
      getAreaByUserType,
      showLoador,
      showNotification,
      setBillData,
      getStockStackHolders,
    } = this.props;
    if (navigator.onLine) {
      setBillData({ row: [] });
      showLoador({ loador: true });
      await getStockStackHolders().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getRow = (e, id) => {
    const { totalRemainingAmt, setList, remainAmt } = this.state;
    const { stockistSettleBillList, setBillData } = this.props;
    let remAmt = 0;

    const billDataList = stockistSettleBillList?.settleBillWithPaymentData.map(
      (billDataObject) => {
        if (billDataObject.id == id) {
          remAmt = e.target.checked
            ? +totalRemainingAmt - +billDataObject.remainingAmt
            : +totalRemainingAmt + +billDataObject.remainingAmt;
          billDataObject = {
            ...billDataObject,
            checked: e.target.checked,
          };
          return billDataObject;
        } else {
          return billDataObject;
        }
      }
    );

    const stockList = billDataList.map((billDataObject) => {
      billDataObject = {
        ...billDataObject,
        disabled: !billDataObject.checked && remAmt <= 0 ? true : false,
      };
      return billDataObject;
    });
    this.setState({
      DataToSet: [
        {
          xs: 12,
          sm: 4,
          md: 4,
          lg: 4,
          label: "Remaining Amount ",
          value: remAmt <= 0 ? 0 : remAmt.toFixed(2),
        },
      ],
      totalRemainingAmt: remAmt,
      remainAmt: remAmt <= 0 ? 0 : remAmt,
      setList: e.target.checked
        ? [...setList, id]
        : setList.filter((row) => row != id),
    });
    setBillData({ row: stockList });
  };

  onSearch = async (data) => {
    const {
      getSettleBillWithPaymentListForStakeholder,
      showLoador,
      showNotification,
      stockistSettleBillList,
    } = this.props;
    const { value } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getSettleBillWithPaymentListForStakeholder({
        stakeholderId: data.stakeholder,
      }).then(({ response, success, selectedList }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            setList: selectedList,
          });
          if (value == 0) {
            const settleBillRemainingAmt =
              stockistSettleBillList?.paymentReceiptTransactionForStakeholderData.filter(
                (rowData) => {
                  if (rowData.id == data.paymentRecTran.id) {
                    let amountData = {
                      settleAmt: rowData.settleRemainingAmt,
                      receivedAmt: rowData.amt,
                    };
                    return amountData;
                  }
                }
              );
            if (settleBillRemainingAmt.length != 0) {
              this.setState({
                DataToSet: [
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    label: "Remaining Amount ",
                    value:
                      value == 0
                        ? (+settleBillRemainingAmt[0]
                            .settleRemainingAmt).toFixed(2)
                        : (+data.amount).toFixed(2),
                  },
                ],
                totalRemainingAmt: settleBillRemainingAmt[0].settleRemainingAmt,
                totalReceivedAmt: settleBillRemainingAmt[0].amt,
                paymentRecTransId: data.paymentRecTran.id,
                isErrorListEmpty: false,
                fromStockStakeHolderId: data.stakeholder,
              });
            }
          } else {
            this.setState({
              DataToSet: [
                {
                  xs: 12,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  label: "Remaining Amount ",
                  value: (+data.amount).toFixed(2),
                },
              ],
              totalRemainingAmt: data.amount,
              totalReceivedAmt: data.amount,
              stakeholderId: data.stakeholder.id,
              isErrorListEmpty: false,
            });
          }
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  checkValidationOnSubmit = () => {
    const { fieldData, value } = this.state;
    const metaData =
      value == 0
        ? stockistSettleBillFormJSON.fieldMeta
        : stockistSettleBillFormJSON.fieldMetaForPayment;
    const mandatoryCheckErrors = mandatoryCheck({
      fieldData,
      fieldMeta: metaData,
    });
    this.setState({
      formErrors: mandatoryCheckErrors.formErrors,
    });
    if (mandatoryCheckErrors.formValid) {
      const ValidationCheckErrors = ValidationCheck({
        fieldData,
        fieldMeta: metaData,
      });
      this.setState({
        formErrors: ValidationCheckErrors.formErrors,
      });
      return ValidationCheckErrors.formValid;
    } else {
      return false;
    }
  };

  onFormSave = () => {
    if (this.checkValidationOnSubmit()) {
      const { fieldData, value } = this.state;
      const metaData =
        value == 0
          ? stockistSettleBillFormJSON.fieldMeta
          : stockistSettleBillFormJSON.fieldMetaForPayment;
      const dataToSave = {
        isActive: 1,
        // currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
      };

      if (fieldData["id"]) {
        dataToSave["id"] = fieldData["id"];
      }

      metaData.forEach((currentField) => {
        if (currentField.controlType === "multiSelect") {
          let multiselectValue = fieldData[currentField.dataKey]
            ? fieldData[currentField.dataKey].join()
            : "";

          dataToSave[currentField.dataKey] = multiselectValue;
        } else if (currentField.controlType === "hideMultiSelect") {
          if (fieldData[currentField.tableDataKey] == currentField.open) {
            let multiselectValue = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey].join()
              : "";

            dataToSave[currentField.dataKey] = multiselectValue;
          }
        } else if (currentField.controlType === "timePicker") {
          let timeValue =
            fieldData[currentField.dataKey] === ""
              ? ""
              : moment(fieldData[currentField.dataKey], "h:mm:ss A").format(
                  "HH:mm"
                );
          dataToSave[currentField.dataKey] = timeValue ? timeValue : "";
        } else if (currentField.controlType === "datepicker") {
          if (fieldData[currentField.dataKey]) {
            let date = new Date(fieldData[currentField.dataKey]);

            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();

            dataToSave[currentField.dataKey] = day + "-" + month + "-" + year;
          } else {
            dataToSave[currentField.dataKey] = "";
          }
        } else if (
          currentField.controlType === "hideTextfield" &&
          !currentField.rootValue
        ) {
          if (fieldData[currentField.tableDataKey] === currentField.open) {
            dataToSave[currentField.tableDataKey] = fieldData[
              currentField.dataKey
            ]
              ? fieldData[currentField.dataKey]
              : "";
          }
        } else if (currentField.controlType === "hideDatepicker") {
          if (fieldData[currentField.tableDataKey] === currentField.open) {
            if (fieldData[currentField.dataKey]) {
              let date = new Date(fieldData[currentField.dataKey]);

              let day = date.getDate();
              let month = date.getMonth() + 1;
              let year = date.getFullYear();

              fieldData[currentField.dataKey] = day + "-" + month + "-" + year;
            }

            dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey]
              : "";
          }
        } else {
          dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
            ? fieldData[currentField.dataKey]
            : "";
        }
      });

      this.onSearch(dataToSave);
    }
  };

  saveData = (DialogBoxData) => {
    const {
      openOutletDialog,
      totalReceivedAmt,
      setList,
      fromStockStakeHolderId,
    } = this.state;
    let dataToSave = {
      amt: totalReceivedAmt,
      settleRemainingAmt: totalReceivedAmt,
      fromStockStakeHolder: fromStockStakeHolderId,
      ...DialogBoxData,
    };
    this.onSave(dataToSave, setList);
    this.setState({
      openOutletDialog: !openOutletDialog,
    });
  };

  onSave = async (objectToSave, setList) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      await showLoador({ loador: true });
      apiPost({
        url:
          endpoint.stockStakeholderBillHeader +
          "/settle-bill-direct-payment?stockStakeHolderBillHeaderIds=" +
          setList,
        postBody: objectToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg, severity: "success" });
          window.location.replace("/stockist-settle-bill");
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSubmit = () => {
    const { openOutletDialog, value, paymentRecTransId, setList } = this.state;
    if (
      this.props.stockistSettleBillList?.settleBillWithPaymentData.length != 0
    ) {
      if (setList.length != 0) {
        if (value == 0) {
          swal({
            title: "Are you sure?",
            text: saveWarningMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then(async (willDelete) => {
            if (willDelete) {
              const { showLoador, showNotification } = this.props;
              if (navigator.onLine) {
                showLoador({ loador: true });
                await apiPost({
                  url:
                    endpoint.stockStakeholderBillHeader +
                    "/settle-bill?stockStakeHolderBillHeaderIds=" +
                    setList +
                    "&paymentRecieptId=" +
                    paymentRecTransId,
                }).then(({ data, success }) => {
                  showLoador({ loador: false });
                  if (success) {
                    showNotification({ msg: savemsg, severity: "success" });
                    window.location.replace("/stockist-settle-bill");
                  } else {
                    showNotification({ msg: saveFailedMsg, severity: "error" });
                  }
                  return success;
                });
              } else {
                showNotification({
                  msg: noInternetMsg,
                  severity: "error",
                });
              }
            }
          });
        } else {
          this.setState({
            openOutletDialog: !openOutletDialog,
          });
        }
      } else {
        this.setState({
          isErrorListEmpty: true,
        });
      }
    } else {
      this.setState({
        isErrorListEmpty: true,
      });
    }
  };

  handleClosesForOutlet = () => {
    const { openOutletDialog } = this.state;
    this.setState({
      openOutletDialog: !openOutletDialog,
    });
  };

  onDataChange = (fieldName, newValue) => {
    const { fieldData, value } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    const metaData =
      value == 0
        ? stockistSettleBillFormJSON.fieldMeta
        : stockistSettleBillFormJSON.fieldMetaForPayment;
    metaData.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        // dataToSearch[fieldName] = newValue;
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };

  render() {
    const {
      dynamicMasterData,
      openOutletDialog,
      DataToSet,
      value,
      totalReceivedAmt,
      isErrorListEmpty,
      formErrors,
      fieldData,
      setList,
    } = this.state;
    const { stockistSettleBillList } = this.props;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            showTitle={stockistSettleBillFormJSON.showTitle}
            screenTitle={stockistSettleBillFormJSON.screenTitle}
            showSaveBtnMain={false}
            showAddButton={false}
          />
          <br />

          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={this.handleChange}
              row
            >
              <FormControlLabel
                value="0"
                control={<Radio />}
                label="Settle Bill"
              />
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Settle Bill with Payment"
              />
            </RadioGroup>
            <br />
          </FormControl>
          <DynamicFormWithoutSave
            dynamicMasterData={dynamicMasterData}
            getListById={this.getListById}
            searchList={stockistSettleBillFormJSON.searchList}
            showSaveBtn={stockistSettleBillFormJSON.showSaveBtn}
            showTitle={false}
            screenTitle={stockistSettleBillFormJSON.screenTitle}
            fieldMeta={
              value == 0
                ? stockistSettleBillFormJSON.fieldMeta
                : stockistSettleBillFormJSON.fieldMetaForPayment
            }
            showCancel={stockistSettleBillFormJSON.showCancel}
            apiBaseURL={stockistSettleBillFormJSON.apiBaseURL}
            showSaveNextBtn={stockistSettleBillFormJSON.showSaveNextBtn}
            saveBtnText={"Search"}
            onFormSave={this.onFormSave}
            fieldData={fieldData}
            formErrors={formErrors}
            onDataChange={this.onDataChange}
            disableCondition={
              stockistSettleBillList?.settleBillWithPaymentData.filter(
                (row) => row.checked
              ).length != 0
                ? true
                : false
            }
          />
          <DynamicDetails DataToSet={DataToSet} />

          {isErrorListEmpty && (
            <Grid item sm={12} xs={12} md={12} lg={12} justifyContent="right">
              <LabelCompo
                className="text-black"
                style={{ fontSize: 12, color: redColor }}
                label={checkedListEmptyMsg}
              />
            </Grid>
          )}
          <CheckBoxSelectionTable
            disabled={true}
            rows={stockistSettleBillList?.settleBillWithPaymentData}
            columns={columns}
            selection={setList}
            showDetailsIcon={true}
            isActionColActive={false}
            onSelectionChange={this.getRow}
            handleCheckFlag={true}
            selectAllFlag={false}
          />
          <>
            <br />
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Submit"
                onClick={this.onSubmit}
              />
            </Grid>
          </>
        </Paper>
        {openOutletDialog && (
          <PaymentModeDialog
            open={openOutletDialog}
            handleCloses={this.handleClosesForOutlet}
            saveData={this.saveData}
            totalReceivedAmt={totalReceivedAmt}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  stockistSettleBillList: state.stockistSettleBill,
  stakeholdersList: state.stackholder,
});
const mapDispatchToProps = {
  getStockStackHolders,
  showLoador,
  showNotification,
  setBillData,
  getSettleBillWithPaymentListForStakeholder,
  getPaymentReceiptTransactionForStakeholder,
  resetData,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StockistSettleBillForm);
