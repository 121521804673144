import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { vehicleColumns } from "../../../tableColumns/table-columns";
import { vehicleJson } from "../../../DynamicFormsJson/MastersJSON/vehicle";
import { getUpdatedVehicleNoListByStockholder } from "../../../Slice/veichleSlice.slice";

class VehicleList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { getUpdatedVehicleNoListByStockholder, vehicleList } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={vehicleJson.formPath}
          screenTitle={vehicleJson.screenTitle}
          fieldMeta={vehicleJson.fieldMeta}
          showPdfDownload={false}
          showExcelDownload={false}
          changeExcelURL={vehicleJson.excelUrl}
          showExtension={false}
          pdfFileName={vehicleJson.pdfFileName}
          excelFileName={vehicleJson.excelFileName}
          showAddButton={vehicleJson.showAddButton}
          tableColumnsToFilter={vehicleJson.tableColumnsToFilter}
          tableColumns={vehicleColumns}
          tableData={vehicleList?.vehicle}
          getTableData={getUpdatedVehicleNoListByStockholder}
          apiBaseURL={vehicleJson.apiBaseURL}
          baseIdColumn={vehicleJson.baseIdColumn}
          isNote={true}
          isNoteValue={"Add Vehicle Number"}
          //   showDeleteIcon={false}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  vehicleList: state.vehicle,
});
const mapDispatchToProps = {
  getUpdatedVehicleNoListByStockholder,
};
export default connect(mapStateToProps, mapDispatchToProps)(VehicleList);
