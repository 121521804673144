import { Box, Grid, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import NoDataFound from "./NoDataFound";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import AnalyticEcommerce from "./AnalyticEcommerce";
import MainCard from "./MainCard";
import MonthlyBarChart from "./MonthlyBarChart";
import OrdersTable from "./OrdersTable";
import PieChart from "./PieChart";
import ReportAreaChart from "./ReportAreaChart";
import TablePopUp from "./TablePopUp";

// assets
import { connect } from "react-redux";
import {
  getDashBourdMain,
  getDataForLineGraph,
} from "../../Slice/distributorDashbourd.slice";
import endpoint from "../../config/endpoints";
import { serverMsg } from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import RadarChart from "./RadarChart";
// avatar style
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: "1rem",
};

// action style
const actionSX = {
  mt: 0.75,
  ml: 1,
  top: "auto",
  right: "auto",
  alignSelf: "flex-start",
  transform: "none",
};

// sales report status
const status = [
  {
    value: "today",
    label: "Today",
  },
  {
    value: "month",
    label: "This Month",
  },
  {
    value: "year",
    label: "This Year",
  },
];

// ==============================|| DASHBOARD - DEFAULT ||============================== //

const DashboardDefault = ({
  showLoador,
  getDashBourdMain,
  distributorDashbourd,
  showNotification,
  getDataForLineGraph,
}) => {
  const [openTable, setOpenTable] = useState(false);
  const [title, setTitle] = useState("");
  const [flagData, setFlagData] = useState("");
  const [rows, setRows] = useState([]);
  const theme = useTheme();
  const line = theme.palette.divider;
  const [coloumnChartList, setColoumnChartList] = useState([]);

  const { primary, secondary } = theme.palette.text;
  const info = theme.palette.info.light;

  const [value, setValue] = useState("today");
  const [slot, setSlot] = useState("week");
  const toggle = (name, urlData, flag) => () => {
    setFlagData(flag);
    setTitle(name);
    if (name != "") {
      if (navigator.onLine) {
        showLoador({ loador: true });
        apiGet({
          url: urlData,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            setRows(
              data.data.map((rowObj, index) => {
                if (flag == 0) {
                  return {
                    index: index + 1,
                    salesTeamName:
                      rowObj.salesTeamName != null ? rowObj.salesTeamName : "-",
                    beatName: rowObj.beatName,
                    firmName: rowObj.firmName,
                    ownerName: rowObj.ownerName,
                  };
                }
                if (flag == 2) {
                  return {
                    index: index + 1,
                    salesTeamName:
                      rowObj.salesTeam != null ? rowObj.salesTeam.name : "-",
                    checkInTime:
                      rowObj.checkInTime == null ? "-" : rowObj.checkInTime,
                    checkOutTime:
                      rowObj.checkOutTime == null ? "-" : rowObj.checkOutTime,
                  };
                }
                if (flag == 3) {
                  return {
                    index: index + 1,
                    salesTeamName: rowObj.name != null ? rowObj.name : "-",
                    contactNo:
                      rowObj.contactNo == null ? "-" : rowObj.contactNo,
                    emailId: rowObj.emailId == null ? "-" : rowObj.emailId,
                  };
                }
                if (flag == 4) {
                  return {
                    index: index + 1,
                    ownerName:
                      rowObj.outLet != null ? rowObj.outLet.ownerName : "-",
                    firmName:
                      rowObj.outLet != null ? rowObj.outLet.firmName : "-",
                    totalAmt:
                      rowObj.totalAmt != null ? rowObj.totalAmt.toFixed(2) : 0,
                  };
                }
                if (flag == 5) {
                  return {
                    index: index + 1,
                    ownerName:
                      rowObj.toStockStakeHolder != null
                        ? rowObj.toStockStakeHolder.ownerName
                        : "-",
                    firmName:
                      rowObj.toStockStakeHolder != null
                        ? rowObj.toStockStakeHolder.firmName
                        : "-",
                    totalAmt:
                      rowObj.totalAmt != null ? rowObj.totalAmt.toFixed(2) : 0,
                  };
                }
                return {
                  index: index + 1,
                  orderDate: rowObj.orderDate,
                  firmName:
                    rowObj.outLet != null ? rowObj.outLet.firmName : "-",
                  totalAmt:
                    rowObj.totalAmt != null ? rowObj.totalAmt.toFixed(2) : 0,
                };
              })
            );

            setOpenTable(!openTable);
          }
        });
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    } else {
      setOpenTable(!openTable);
    }
  };
  useEffect(() => {
    const getUsers = async () => {
      showLoador({ loador: true });
      await getDashBourdMain().then(({ success }) => {
        if (!success) {
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
        }
        showLoador({ loador: false });
      });
      showLoador({ loador: true });
      await getDataForLineGraph({ fromDate: "", toDate: "" }).then(
        ({ success }) => {
          if (!success) {
            showNotification({
              msg: serverMsg,
              severity: "error",
            });
          }
          showLoador({ loador: false });
        }
      );
    };

    getUsers(); // run it, run it

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  const getPercentage = (curretnAmt, pervAmt) => {
    if (curretnAmt != 0 && pervAmt != 0) {
      let amt = curretnAmt - pervAmt;
      let value = amt / pervAmt;
      let per = (value * 100).toFixed(2);
      return per;
    }
    return 0;
  };
  const rowLink = (rowData) => {
    setFlagData(7);

    setTitle("Outlet Details");
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiGet({
        url: endpoint.outletBillHeader + "/by-outlet?outletId=" + rowData.id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          setRows(
            data.data.map((rowObj, index) => {
              return {
                index: index + 1,
                billNo: rowObj.billNo,
                billDate: rowObj.billDate != null ? rowObj.billDate : "-",
                remainingAmt:
                  rowObj.remainingAmt != null
                    ? rowObj.remainingAmt.toFixed(2)
                    : "-",
                totalAmt: rowObj.totalAmt.toFixed(2),
              };
            })
          );

          setOpenTable(!openTable);
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  const getProfit = (curretnAmt, pervAmt) => {
    if (curretnAmt != 0 && pervAmt != 0) {
      let amt = curretnAmt - pervAmt;
      let value = amt / pervAmt;
      let per = (value * 100).toFixed(2);

      return per < 0 ? true : false;
    }
    return false;
  };
  const getExtraData = (dtoSalesTeamInformations) => {
    let totalOutletCount = 0,
      zero = 0,
      unvisited = 0,
      totalAmount = 0;
    dtoSalesTeamInformations.map((salesTeam) => {
      totalOutletCount = totalOutletCount + +salesTeam.totalOutletCount;
      zero = zero + +salesTeam.zeroOrderCount;
      unvisited = unvisited + +salesTeam.unvisitedOutletCount;
      totalAmount = totalAmount + +salesTeam.totalAmount;
    });
    return [totalOutletCount, unvisited, zero, totalAmount.toFixed(2)];
  };

  const onClickBar = (index) => {
    let catOBJ =
      distributorDashbourd.dashbourdMain.dtoBeatWiseTotalAmounts[index];
    if (Object.keys(catOBJ).length != 0) {
      setFlagData(4);

      setTitle("Outlet List");
      if (navigator.onLine) {
        showLoador({ loador: true });
        apiGet({
          url:
            endpoint.outletBillHeader +
            "/outlet-wise-total-bill-amount?fromDate=&toDate=&beatId=" +
            catOBJ.beat.id,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            setRows(
              data.data.map((rowObj, index) => {
                return {
                  index: index + 1,
                  name: rowObj.name,
                  firmName:
                    rowObj.outLet != null ? rowObj.outLet.firmName : "-",
                  ownerName:
                    rowObj.outLet != null ? rowObj.outLet.ownerName : "-",
                  totalAmt: rowObj.totalBillAmt.toFixed(2),
                };
              })
            );

            setOpenTable(!openTable);
          }
        });
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };
  const onCLickPie = (index) => {
    let catOBJ =
      distributorDashbourd.dashbourdMain.dtoOutletBillCategoryWiseTotalAmounts[
        index
      ];
    if (Object.keys(catOBJ).length != 0) {
      setFlagData(6);

      setTitle("Product List");
      if (navigator.onLine) {
        showLoador({ loador: true });
        apiGet({
          url:
            endpoint.product +
            "/by-date-and-product-wise?fromDate=&toDate=&categoryId=" +
            catOBJ.category.id +
            "&type=0",
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            setRows(
              data.data.map((rowObj, index) => {
                return {
                  index: index + 1,
                  name: rowObj.name,
                  uom: rowObj.outletUom != null ? rowObj.outletUom.name : "-",
                  totalAmt: rowObj.totalAmt.toFixed(2),
                };
              })
            );

            setOpenTable(!openTable);
          }
        });
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };
  const onCLickOrderPie = (index) => {
    let catOBJ =
      distributorDashbourd.dashbourdMain.dtoOutletOrderCategoryWiseTotalAmounts[
        index
      ];
    if (Object.keys(catOBJ).length != 0) {
      setFlagData(6);

      setTitle("Product List");
      if (navigator.onLine) {
        showLoador({ loador: true });
        apiGet({
          url:
            endpoint.product +
            "/todays-product-wise-total-amt-by-category?categoryId=" +
            catOBJ.category.id,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            setRows(
              data.data.map((rowObj, index) => {
                return {
                  index: index + 1,
                  name: rowObj.name,
                  uom: rowObj.outletUom != null ? rowObj.outletUom.name : "-",
                  totalAmt: rowObj.totalAmt.toFixed(2),
                };
              })
            );

            setOpenTable(!openTable);
          }
        });
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };
  const onClickLineChart = (index) => {
    setFlagData(6);
    let catOBJ =
      distributorDashbourd.lineGraphData.monthWiseOutletTotalBill[index];
    if (Object.keys(catOBJ).length != 0) {
      setTitle("Product List");
      if (navigator.onLine) {
        showLoador({ loador: true });
        apiGet({
          url:
            endpoint.monthWiseTotalAmount +
            "/date-wise?monthYear=" +
            catOBJ.monthYear +
            "&type=0",
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            setColoumnChartList(
              data.data.map((rowObj, index) => {
                return {
                  index: index + 1,
                  date: rowObj.date,
                  totalAmt: rowObj.totalAmount.toFixed(2),
                };
              })
            );
          }
        });
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };

  return (
    <>
      <Paper
        sx={{
          p: 2,
          borderRadius: 2,
        }}
        elevation={0}
      >
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
          <Grid item xs={12} sx={{ mb: -2.25 }}>
            <Typography variant="h5">Today's data</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Checked-in count"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.checkedInCount
                  : 0
              }
              // percentage={70.5}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.checkedInCount
                  : 0
              }
              onClick={toggle(
                "Sales executive Checked-in List",
                endpoint.checkin + "/checked-in-list-by-distributor",
                2
              )}
              msgRight=""
              msgLeft="Checked-in count is = "
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Bill amount"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.todaysOutletBillTotalAmt.toFixed(
                      2
                    )
                  : 0
              }
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.todaysOutletBillTotalAmt.toFixed(
                      2
                    )
                  : 0
              }
              onClickFlag={false}
              msgRight=""
              msgLeft="Bill amount is = "
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Order amount"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.todaysOutletOrderTotalAmt.toFixed(
                      2
                    )
                  : 0
              }
              onClickFlag={false}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.todaysOutletOrderTotalAmt.toFixed(
                      2
                    )
                  : 0
              }
              msgRight=""
              msgLeft="Order amount count is = "
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            {Object.keys(distributorDashbourd.dashbourdMain).length != 0 &&
            distributorDashbourd.dashbourdMain
              .dtoOutletOrderCategoryWiseTotalAmounts &&
            distributorDashbourd.dashbourdMain
              .dtoOutletOrderCategoryWiseTotalAmounts.length != 0 ? (
              <>
                <RadarChart
                  series={distributorDashbourd.dashbourdMain.dtoOutletOrderCategoryWiseTotalAmounts.map(
                    (category) => {
                      return category.category != null
                        ? category.totalAmt.toFixed(2)
                        : "";
                    }
                  )}
                  options={{
                    chart: {
                      type: "polarArea",
                      events: {
                        dataPointSelection: (event, chartContext, config) => {
                          onCLickOrderPie(config.dataPointIndex);
                        },
                      },
                    },
                    stroke: {
                      colors: ["#fff"],
                    },
                    fill: {
                      opacity: 0.8,
                    },
                    labels:
                      distributorDashbourd.dashbourdMain.dtoOutletOrderCategoryWiseTotalAmounts.map(
                        (category) => {
                          return category.category != null
                            ? category.category.name
                            : "";
                        }
                      ),
                    responsive: [
                      {
                        breakpoint: 480,
                        options: {
                          chart: {
                            width: 200,
                          },
                          legend: {
                            position: "bottom",
                          },
                        },
                      },
                    ],
                  }}
                  width={320}
                />
              </>
            ) : (
              <NoDataFound />
            )}
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Sales team list </Typography>
              </Grid>
              <Grid item />
            </Grid>
            <MainCard sx={{ mt: 2 }} content={false}>
              {Object.keys(distributorDashbourd.dashbourdMain).length != 0 &&
              distributorDashbourd.dashbourdMain.dtoSalesTeamInformations !=
                null ? (
                <OrdersTable
                  rows={
                    Object.keys(distributorDashbourd.dashbourdMain).length !=
                      0 &&
                    distributorDashbourd.dashbourdMain
                      .dtoSalesTeamInformations != null
                      ? distributorDashbourd.dashbourdMain.dtoSalesTeamInformations.map(
                          (sales) => {
                            const seriesData = [
                              {
                                label:
                                  sales.dtoDateWiseTotalAmount != null
                                    ? sales.dtoDateWiseTotalAmount.map(
                                        (rowData) => {
                                          return rowData.date;
                                        }
                                      )
                                    : [],
                                name: "Amount",
                                data:
                                  sales.dtoDateWiseTotalAmount != null
                                    ? sales.dtoDateWiseTotalAmount.map(
                                        (rowData) => {
                                          return rowData.totalAmount;
                                        }
                                      )
                                    : [],
                              },
                            ];
                            return {
                              ...sales,
                              salesTeam:
                                sales.salesTeam != null
                                  ? sales.salesTeam.name
                                  : "",
                              salesTeamId:
                                sales.salesTeam != null
                                  ? sales.salesTeam.id
                                  : "",
                              series: seriesData,
                              options: {
                                chart: {
                                  type: "line",
                                  width: 100,
                                  height: 35,
                                  sparkline: {
                                    enabled: true,
                                  },
                                },

                                stroke: {
                                  curve: "straight",
                                },
                                tooltip: {
                                  custom: function ({
                                    series,
                                    seriesIndex,
                                    dataPointIndex,
                                    w,
                                  }) {
                                    return (
                                      '<div class="arrow_box">' +
                                      "<span><b>" +
                                      seriesData[0].label[dataPointIndex] +
                                      " - " +
                                      series[seriesIndex][
                                        dataPointIndex
                                      ].toFixed(2) +
                                      "  ₹" +
                                      "</b></span>" +
                                      "</div>"
                                    );
                                  },
                                },
                                xaxis: {
                                  categories:
                                    sales.dtoDateWiseTotalAmount != null
                                      ? sales.dtoDateWiseTotalAmount.map(
                                          (rowData) => {
                                            return rowData.date;
                                          }
                                        )
                                      : [],
                                },
                              },
                            };
                          }
                        )
                      : []
                  }
                  extraData={getExtraData(
                    Object.keys(distributorDashbourd.dashbourdMain).length !=
                      0 &&
                      distributorDashbourd.dashbourdMain
                        .dtoSalesTeamInformations != null
                      ? distributorDashbourd.dashbourdMain
                          .dtoSalesTeamInformations
                      : []
                  )}
                />
              ) : (
                <NoDataFound />
              )}
            </MainCard>
          </Grid>
          <Grid item xs={12} sx={{ mb: -2.25 }}>
            <Typography variant="h5">Current month</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title=" Sell amount"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? (+distributorDashbourd.dashbourdMain
                      .currentMonthOutletTotalAmt).toFixed(2)
                  : 0
              }
              percentage={getPercentage(
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain
                      .currentMonthOutletTotalAmt
                  : 0,
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain
                      .previousMonthOutletTotalAmt
                  : 0
              )}
              // percentage={getPercentage(100, 200)}
              onClickFlag={false}
              isLoss={getProfit(
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain
                      .currentMonthOutletTotalAmt
                  : 0,
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain
                      .previousMonthOutletTotalAmt
                  : 0
              )}
              // isLoss={getProfit(100, 200)}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? (+distributorDashbourd.dashbourdMain
                      .currentMonthOutletTotalAmt).toFixed(2)
                  : 0
              }
              msgRight=""
              msgLeft={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? "Previous month outlet bill amount is = " +
                    distributorDashbourd.dashbourdMain
                      .previousMonthOutletTotalAmt +
                    " and Current month outlet bill amount is = "
                  : 0 + ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Unvisited count"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.unvisitedOutletCount
                  : 0
              }
              // percentage={70.5}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.unvisitedOutletCount
                  : 0
              }
              onClick={toggle(
                "Unvisited outlets",
                endpoint.adminDashboard + "/get-unvisited-list",
                0
              )}
              msgRight=""
              msgLeft="Unvisited outlet count is = "
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Zero order"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.zeroOrderOutletCount
                  : 0
              }
              onClick={toggle(
                "Zero order",
                endpoint.adminDashboard + "/get-zero-order-list",
                0
              )}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.zeroOrderOutletCount
                  : 0
              }
              msgRight=""
              msgLeft="Zero order outlet count is = "
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Bill not generated"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.countOfOutletOrder
                  : 0
              }
              onClick={toggle(
                "Bill not generated",
                endpoint.outletOrderHeader +
                  "/get-bill-not-generated-outlet-order-list",
                1
              )}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.countOfOutletOrder
                  : 0
              }
              msgRight=""
              msgLeft="Bill not generated is = "
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Credit note amount"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.creditNoteTotalAmt.toFixed(
                      2
                    )
                  : 0
              }
              onClickFlag={false}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.creditNoteTotalAmt.toFixed(
                      2
                    )
                  : 0
              }
              msgRight=""
              msgLeft="Total amount is = "
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: -2.25 }}>
            <Typography variant="h5">Sales executive counts</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Total"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.countOfSalesExecutive
                  : 0
              }
              onClickFlag={false}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.countOfSalesExecutive
                  : 0
              }
              msgRight=""
              msgLeft="Sales executive count is = "
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title=" Beat not assign count"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.notAssignSalesTeamCount
                  : 0
              }
              // percentage={70.5}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.notAssignSalesTeamCount
                  : 0
              }
              onClick={toggle(
                "Sales executive Beat not assign List",
                endpoint.salesExecutive + "/not-assigned-sales-team",
                3
              )}
              msgRight=""
              msgLeft="Beat not assign count is = "
            />
          </Grid>

          {/* <Grid item xs={12} sx={{ mb: -2.25 }}>
            <Typography variant="h5">Credit Note Amount</Typography>
          </Grid>
          <Grid item xs={12} sx={{ mb: -2.25 }}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <AnalyticEcommerce
                title="Credit Note Amount"
                count={
                  Object.keys(distributorDashbourd.dashbourdMain).length != 0
                    ? distributorDashbourd.dashbourdMain.creditNoteTotalAmt.toFixed(
                        2
                      )
                    : 0
                }
                onClickFlag={false}
                extra={
                  Object.keys(distributorDashbourd.dashbourdMain).length != 0
                    ? distributorDashbourd.dashbourdMain.creditNoteTotalAmt.toFixed(
                        2
                      )
                    : 0
                }
                msgRight=""
                msgLeft="Total amount is = "
              />
            </Grid>
          </Grid> */}

          {/* row 2 */}
          <Grid item xs={12} md={8} lg={8}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">
                  Current month beat list with amount
                </Typography>
              </Grid>
            </Grid>
            <MainCard content={false} sx={{ mt: 1.5 }}>
              <Box sx={{ pt: 1, pr: 2 }}>
                {Object.keys(distributorDashbourd.dashbourdMain).length != 0 &&
                distributorDashbourd.dashbourdMain.dtoBeatWiseTotalAmounts &&
                distributorDashbourd.dashbourdMain.dtoBeatWiseTotalAmounts
                  .length != 0 ? (
                  <MonthlyBarChart
                    series={[
                      {
                        data: distributorDashbourd.dashbourdMain.dtoBeatWiseTotalAmounts.map(
                          (beatObj) => {
                            return beatObj.beat != null
                              ? +beatObj.totalBillAmt.toFixed(2)
                              : "";
                          }
                        ),
                      },
                    ]}
                    optionsObj={{
                      chart: {
                        type: "bar",
                        height: 365,
                        toolbar: {
                          show: false,
                        },
                        events: {
                          dataPointSelection: (event, chartContext, config) => {
                            onClickBar(config.dataPointIndex);
                          },
                        },
                      },
                      plotOptions: {
                        bar: {
                          columnWidth: "45%",
                          borderRadius: 4,
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      xaxis: {
                        categories:
                          distributorDashbourd.dashbourdMain.dtoBeatWiseTotalAmounts.map(
                            (beatObj) => {
                              return beatObj.beat != null
                                ? beatObj.beat.beatName
                                : "";
                            }
                          ),
                        axisBorder: {
                          show: false,
                        },
                        axisTicks: {
                          show: false,
                        },
                        labels: {
                          style: {
                            colors: [
                              secondary,
                              secondary,
                              secondary,
                              secondary,
                              secondary,
                              secondary,
                              secondary,
                            ],
                          },
                        },
                      },
                      // yaxis: {
                      //   show: false,
                      // },
                      grid: {
                        show: false,
                      },
                      colors: [info],

                      tooltip: {
                        theme: "light",
                      },
                    }}
                  />
                ) : (
                  <NoDataFound />
                )}
              </Box>
            </MainCard>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Category wise outlet count</Typography>
              </Grid>
            </Grid>

            <MainCard content={false} sx={{ mt: 1.5 }}>
              <Box sx={{ pt: 1, pr: 2 }}>
                {Object.keys(distributorDashbourd.dashbourdMain).length != 0 &&
                distributorDashbourd.dashbourdMain
                  .dtoOutletBillCategoryWiseTotalAmounts &&
                distributorDashbourd.dashbourdMain
                  .dtoOutletBillCategoryWiseTotalAmounts.length != 0 ? (
                  <PieChart
                    series={distributorDashbourd.dashbourdMain.dtoOutletBillCategoryWiseTotalAmounts.map(
                      (category) => {
                        return category.category != null
                          ? category.totalAmt
                          : "";
                      }
                    )}
                    options={{
                      chart: {
                        width: 380,
                        type: "pie",
                        events: {
                          dataPointSelection: (event, chartContext, config) => {
                            onCLickPie(config.dataPointIndex);
                          },
                        },
                      },
                      animations: {
                        enabled: true,
                        easing: "easeinout",
                        speed: 1000,
                        animateGradually: {
                          enabled: true,
                          delay: 150,
                        },
                        dynamicAnimation: {
                          enabled: true,
                          speed: 100,
                        },
                      },
                      labels:
                        distributorDashbourd.dashbourdMain.dtoOutletBillCategoryWiseTotalAmounts.map(
                          (category) => {
                            return category.category != null
                              ? category.category.name
                              : "";
                          }
                        ),
                      responsive: [
                        {
                          breakpoint: 480,
                          options: {
                            chart: {
                              width: 300,
                            },
                            legend: {
                              position: "bottom",
                            },
                          },
                        },
                      ],
                    }}
                  />
                ) : (
                  <NoDataFound />
                )}
              </Box>
            </MainCard>
          </Grid>

          {/* <Grid item xs={12} md={12} lg={12}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Sale Bill Amount List </Typography>
              </Grid>
            </Grid>
            <MainCard content={false} sx={{ mt: 1.5 }}>
              <Box sx={{ pt: 1, pr: 2 }}>
                {Object.keys(distributorDashbourd.dashbourdMain).length != 0 &&
                distributorDashbourd.dashbourdMain.dtoOutletWiseTotalAmounts &&
                distributorDashbourd.dashbourdMain.dtoOutletWiseTotalAmounts
                  .length != 0 ? (
                  <MonthlyBarChart
                    series={[
                      {
                        data: distributorDashbourd.dashbourdMain.dtoOutletWiseTotalAmounts.map(
                          (beatObj) => {
                            return beatObj.totalBillAmt != null
                              ? +beatObj.totalBillAmt.toFixed(2)
                              : "";
                          }
                        ),
                      },
                    ]}
                    optionsObj={{
                      chart: {
                        type: "bar",
                        height: 365,
                        toolbar: {
                          show: false,
                        },
                      },

                      plotOptions: {
                        bar: {
                          columnWidth: "45%",
                          borderRadius: 4,
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      xaxis: {
                        categories:
                          distributorDashbourd.dashbourdMain.dtoOutletWiseTotalAmounts.map(
                            (beatObj) => {
                              return beatObj.outLet != null
                                ? beatObj.outLet.firmName
                                : "";
                            }
                          ),
                        axisBorder: {
                          show: false,
                        },
                        axisTicks: {
                          show: false,
                        },
                        labels: {
                          style: {
                            colors: [
                              secondary,
                              secondary,
                              secondary,
                              secondary,
                              secondary,
                              secondary,
                              secondary,
                            ],
                          },
                        },
                      },
                      yaxis: {
                        show: false,
                      },
                      grid: {
                        show: false,
                      },
                      colors: [info],

                      tooltip: {
                        theme: "light",
                      },
                    }}
                  />
                ) : (
                  <NoDataFound />
                )}
              </Box>
            </MainCard>
          </Grid> */}
          <Grid item xs={12} md={12} lg={12}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Sale graph</Typography>
              </Grid>
              <Grid item />
            </Grid>
            <MainCard sx={{ mt: 2 }} content={false}>
              {Object.keys(distributorDashbourd.dashbourdMain).length != 0 &&
              distributorDashbourd.lineGraphData.monthWiseOutletTotalBill &&
              distributorDashbourd.lineGraphData.monthWiseOutletTotalBill
                .length != 0 ? (
                <>
                  <MonthlyBarChart
                    series={[
                      {
                        data: distributorDashbourd.lineGraphData.monthWiseOutletTotalBill.map(
                          (category) => {
                            return +category.totalAmount.toFixed(2);
                          }
                        ),
                      },
                    ]}
                    optionsObj={{
                      chart: {
                        type: "bar",
                        height: 365,
                        toolbar: {
                          show: false,
                        },
                        events: {
                          dataPointSelection: (event, chartContext, config) => {
                            onClickLineChart(config.dataPointIndex);
                          },
                        },
                      },

                      plotOptions: {
                        bar: {
                          borderRadius: 4,
                          horizontal: true,
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      xaxis: {
                        categories:
                          distributorDashbourd.lineGraphData.monthWiseOutletTotalBill.map(
                            (category) => {
                              return category.monthName != null
                                ? category.monthName
                                : "";
                            }
                          ),

                        axisBorder: {
                          show: false,
                        },
                        axisTicks: {
                          show: false,
                        },
                        labels: {
                          style: {
                            colors: [
                              secondary,
                              secondary,
                              secondary,
                              secondary,
                              secondary,
                              secondary,
                              secondary,
                            ],
                          },
                        },
                      },
                      // yaxis: {
                      //   show: false,
                      // },
                      grid: {
                        show: false,
                      },
                      colors: [info],

                      tooltip: {
                        theme: "light",
                      },
                    }}
                  />
                  {/* <ReportAreaChart
                  options={{
                    chart: {
                      height: 350,
                      type: "line",
                      zoom: {
                        enabled: false,
                      },
                      events: {
                        dataPointSelection: (event, chartContext, config) => {
                          onClickLineChart(config.dataPointIndex);
                        },
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      curve: "straight",
                    },
                    // title: {
                    //   text: 'Product Trends by Month',
                    //   align: 'left'
                    // },
                    grid: {
                      row: {
                        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                        opacity: 0.5,
                      },
                    },
                    xaxis: {
                      categories:
                        distributorDashbourd.lineGraphData.monthWiseOutletTotalBill.map(
                          (category) => {
                            return category.monthName != null
                              ? category.monthName
                              : "";
                          }
                        ),
                    },
                  }}
                  series={[
                    {
                      name: "Amount",
                      data: distributorDashbourd.lineGraphData.monthWiseOutletTotalBill.map(
                        (category) => {
                          return +category.totalAmount.toFixed(2);
                        }
                      ),
                    },
                  ]}
                /> */}
                </>
              ) : (
                <NoDataFound />
              )}
            </MainCard>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Datewise graph</Typography>
              </Grid>
              <Grid item />
            </Grid>
            <MainCard sx={{ mt: 2 }} content={false}>
              {coloumnChartList.length != 0 ? (
                <>
                  <ReportAreaChart
                    options={{
                      chart: {
                        height: 350,
                        type: "line",
                        dropShadow: {
                          enabled: true,
                          color: "#000",
                          top: 18,
                          left: 7,
                          blur: 10,
                          opacity: 0.2,
                        },
                        // toolbar: {
                        //   show: false
                        // }
                      },
                      colors: ["#77B6EA", "#545454"],
                      dataLabels: {
                        enabled: true,
                      },
                      stroke: {
                        curve: "smooth",
                      },

                      grid: {
                        borderColor: "#e7e7e7",
                        row: {
                          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                          opacity: 0.5,
                        },
                      },
                      markers: {
                        size: 1,
                      },
                      xaxis: {
                        categories: coloumnChartList.map((category) => {
                          return category.date != null ? category.date : "";
                        }),
                        title: {
                          text: "Dates",
                        },
                      },
                      yaxis: {
                        title: {
                          text: "Amount",
                        },
                      },
                      legend: {
                        position: "top",
                        horizontalAlign: "right",
                        floating: true,
                        offsetY: -25,
                        offsetX: -5,
                      },
                    }}
                    series={[
                      {
                        name: "Amount",
                        data: coloumnChartList.map((category) => {
                          return +category.totalAmt;
                        }),
                      },
                    ]}
                  />
                  {/* <ColumnDobleChart
                  options={{
                    chart: {
                      height: 350,
                      type: "bar",
                      zoom: {
                        enabled: false,
                      },
                 
                    },
                    plotOptions: {
                      bar: {
                        borderRadius: 4,
                        horizontal: true,
                      }
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      curve: "straight",
                    },
                    // title: {
                    //   text: 'Product Trends by Month',
                    //   align: 'left'
                    // },
                    grid: {
                      row: {
                        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                        opacity: 0.5,
                      },
                    },
                    xaxis: {
                      categories:
                      coloumnChartList.map(
                          (category) => {
                            return category.date != null
                              ? category.date
                              : "";
                          }
                        ),
                    },
                  }}
                  series={[
                    {
                      name: "Amount",
                      data: coloumnChartList.map(
                        (category) => {
                          return +category.totalAmt;
                        }
                      ),
                    },
                  ]}
                /> */}
                </>
              ) : (
                <NoDataFound />
              )}
            </MainCard>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Pending amount list</Typography>
              </Grid>
              <Grid item />
            </Grid>
            <MainCard sx={{ mt: 2 }} content={false}>
              {Object.keys(distributorDashbourd.dashbourdMain).length != 0 &&
              distributorDashbourd.dashbourdMain.dtoOutletPendingBill != null &&
              distributorDashbourd.dashbourdMain.dtoOutletPendingBill.length !=
                0 ? (
                <DynamicTable
                  data={
                    Object.keys(distributorDashbourd.dashbourdMain).length !=
                      0 &&
                    distributorDashbourd.dashbourdMain.dtoOutletPendingBill !=
                      null
                      ? distributorDashbourd.dashbourdMain.dtoOutletPendingBill.map(
                          (sales, index) => {
                            return {
                              index: index + 1,
                              name:
                                sales.outLet != null
                                  ? sales.outLet.firmName
                                  : "",
                              id: sales.outLet != null ? sales.outLet.id : "",
                              remainingAmt: sales.remainingAmt.toFixed(2),
                            };
                          }
                        )
                      : []
                  }
                  tableHead={[
                    {
                      title: "Sr. No.",
                      name: "index",
                      positionCenter: false,
                      showInscreen: true,
                      alignCenter: "center",
                    },
                    {
                      title: "Outlet Name",
                      name: "name",
                      positionCenter: false,
                      showInscreen: true,
                      alignCenter: "left",
                      linkFlag: true,
                    },
                    {
                      title: "Remaining Amount",
                      name: "remainingAmt",
                      positionCenter: false,
                      showInscreen: true,
                      alignCenter: "right",
                    },
                  ]}
                  showPegination={false}
                  isActionColActive={false}
                  rowLink={rowLink}
                />
              ) : (
                <NoDataFound />
              )}
            </MainCard>
          </Grid>

          {/* row 4 */}
        </Grid>
      </Paper>
      {openTable && (
        <TablePopUp
          columns={
            flagData == 1
              ? [
                  {
                    title: "Sr. No.",
                    name: "index",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Order Date",
                    name: "orderDate",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },

                  {
                    title: "Outlet Name",
                    name: "firmName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                  {
                    title: "Order Amount",
                    name: "totalAmt",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                  },
                ]
              : flagData == 2
              ? [
                  {
                    title: "Sr. No.",
                    name: "index",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Sales Person Name",
                    name: "salesTeamName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },

                  {
                    title: "Check In Time",
                    name: "checkInTime",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Check Out Time",
                    name: "checkOutTime",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                ]
              : flagData == 3
              ? [
                  {
                    title: "Sr. No.",
                    name: "index",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Sales Person Name",
                    name: "salesTeamName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },

                  {
                    title: "Contact Number",
                    name: "contactNo",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Email Id",
                    name: "emailId",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                ]
              : flagData == 4 || flagData == 5
              ? [
                  {
                    title: "Sr. No.",
                    name: "index",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },

                  {
                    title: "Outlet Name",
                    name: "firmName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                  {
                    title: "Owner Name",
                    name: "ownerName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                  {
                    title: "Amount",
                    name: "totalAmt",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                  },
                ]
              : flagData == 6
              ? [
                  {
                    title: "Sr. No.",
                    name: "index",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Product name",
                    name: "name",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                  {
                    title: "UOM",
                    name: "uom",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                  {
                    title: "Sell Amount",
                    name: "totalAmt",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                  },
                ]
              : flagData == 7
              ? [
                  {
                    title: "Sr. No.",
                    name: "index",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Bill No",
                    name: "billNo",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Bill Date",
                    name: "billDate",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Remaining Amount",
                    name: "remainingAmt",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                  },
                  {
                    title: "Order Amount",
                    name: "totalAmt",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                  },
                ]
              : [
                  {
                    title: "Sr. No.",
                    name: "index",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Sales Team Name",
                    name: "salesTeamName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                  {
                    title: "Beat Name",
                    name: "beatName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },

                  {
                    title: "Outlet Name",
                    name: "firmName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                  {
                    title: "Owner Name",
                    name: "ownerName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                ]
          }
          open={openTable}
          handleCloses={toggle("", "", "")}
          rows={rows}
          title={title}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  distributorDashbourd: state.distributorDashbourd,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getDashBourdMain,
  getDataForLineGraph,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDefault);
