import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import { accessRoleColumns } from "../../tableColumns/table-columns";
import { userAccessJson } from "../../DynamicFormsJson/MastersJSON/userAccess";
import { getAccessRoleList } from "../../Slice/accessRole.slice";
import UserAccessView from "./UserAccessView";

class UserAccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
    };
  }

  // rowViewHandler = (row) => {
  //   console.log(row);
  //   this.setState({
  //     showTable: false,
  //   });
  // };

  render() {
    const { accessRole, getAccessRoleList } = this.props;
    const { showTable } = this.state;
    return (
      <>
        {showTable && (
          <DynamicMainScreen
            formPath={userAccessJson.formPath}
            formPathView={userAccessJson.formPathView}
            screenTitle={userAccessJson.screenTitle}
            fieldMeta={userAccessJson.fieldMeta}
            showPdfDownload={userAccessJson.showPdfDownload}
            showExcelDownload={userAccessJson.showExcelDownload}
            showViewButtonInTable={true}
            pdfFileName={userAccessJson.pdfFileName}
            excelFileName={userAccessJson.excelFileName}
            showAddButton={userAccessJson.showAddButton}
            tableColumnsToFilter={userAccessJson.tableColumnsToFilter}
            tableColumns={accessRoleColumns}
            showDeleteIcon={false}
            tableData={accessRole?.accessRole}
            getTableData={getAccessRoleList}
            onDelete={this.onDelete}
            onSave={this.onSave}
            apiBaseURL={userAccessJson.apiBaseURL}
            baseIdColumn={userAccessJson.baseIdColumn}
            staticView={false}
            showAddButtonInTable={true}
            onClickAdd={this.onClickAdd}
            openFormPath={userAccessJson.openFormPath}
            searchButtonGrid={userAccessJson.searchButtonGrid}
          // showApply={true}
          />
        )}
        {/* {!showTable && <UserAccessView />} */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  accessRole: state.accessRole,
});
const mapDispatchToProps = {
  getAccessRoleList,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserAccess);
