import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import "../../CSS/app.min.css";
import "../../CSS/bootstrap.min.css";
import "../../CSS/custom.min.css";
import "../../CSS/mermaid.min.css";
import "../../CSS/slickDashboard.css";
import gokulImg from "../../Images/GokulFinalLog.svg";
import bizintelImg from "../../Images/bizintelNewLogo.svg";
import ofcImg from "../../Images/ofcNewLogo.png";
import sunderImg from "../../Images/sunderLogo.png";
import jollizImg from "../../Images/jolliz.png";
import welcomeImg from "../../Images/welcomeImg.jpg";
import { getNoticeMsgByUserType } from "../../Slice/noticeMsg.slice";
import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import { serverMsg } from "../../config/messageconstant";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

class WelcomePage extends Component {
  state = {
    noticeMspPopUp: true,
  };

  async componentDidMount() {
    const { getNoticeMsgByUserType, showLoador, showNotification } = this.props;

    const userTypeIdFlag =
      localStorage.getItem("userTypeId") == 1
        ? false
        : localStorage.getItem("userTypeId") == 6
        ? false
        : true;
    const noticeMsgListDataList = localStorage.getItem("noticeMsgListData");
    const noticeMsgListDataLocalStorage = JSON.parse(noticeMsgListDataList);
    console.log(localStorage.getItem("userTypeId"));
    console.log(userTypeIdFlag);
    if (userTypeIdFlag) {
      if (!noticeMsgListDataLocalStorage) {
        showLoador({ loador: true });
        await getNoticeMsgByUserType().then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            const noticeMsgList = response.length !== 0 ? response : [];
            localStorage.setItem(
              "noticeMsgListData",
              JSON.stringify(noticeMsgList)
            );

            this.setState({
              noticeMspPopUp: true,
            });
          }
        });
      }
    }
  }

  handleClose = () => {
    this.setState({
      noticeMspPopUp: false,
    });
  };

  render() {
    const { noticeList } = this.props;
    const { noticeMspPopUp } = this.state;
    const userTypeIdFlag =
      localStorage.getItem("userTypeId") == 1
        ? false
        : localStorage.getItem("userTypeId") == 6
        ? false
        : true;
    const noticeMsgListDataList = localStorage.getItem("noticeMsgListData");
    const noticeMsgListDataLocalStorage = JSON.parse(noticeMsgListDataList);
    return (
      <div style={{ width: "100%", position: "relative", height: "100%" }}>
        <img style={{ width: "100%", height: "100%" }} src={welcomeImg} />
        <div
          style={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            top: "1%",
            left: "1%",
            color: "#004969",
          }}
        >
          {" "}
          <span>Welcome,</span>{" "}
          <span style={{ fontSize: "1.5rem", fontWeight: "600" }}>
            {" "}
            {localStorage.getItem("firmName")}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between  ",
            position: "absolute",
            alignItems: "center",
            top: "26%",
            width: "100%",
            left: "0",
            gap: "0",
          }}
        >
          <din
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{
                height: "32%",
                width: "30rem",
              }}
              src={
                localStorage.getItem("showCompanyFlag") &&
                localStorage.getItem("showCompanyFlag") == 0
                  ? ofcImg
                  : localStorage.getItem("showCompanyFlag") == 1
                  ? gokulImg
                  : localStorage.getItem("showCompanyFlag") == 2
                  ? sunderImg
                  : localStorage.getItem("showCompanyFlag") == 3
                  ? jollizImg
                  : bizintelImg
              }
            />
          </din>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "50%",
            }}
          >
            <div
              style={{
                fontFamily: "roboto",
                fontSize: "100px",
                color: "white",
                textOverflow: "-moz-initial",
                fontWeight: "700",
              }}
            >
              Welcome to
            </div>
            <span
              style={{
                fontFamily: "roboto",
                fontSize: "140px",
                color: "white",
                lineHeight: "90%",
                fontWeight: "700",
              }}
            >
              DMS{" "}
            </span>
          </div>
        </div>
        {userTypeIdFlag &&
          noticeMspPopUp &&
          (noticeMsgListDataLocalStorage
            ? noticeMsgListDataLocalStorage
            : noticeList.noticeMsg
          ).length !== 0 && (
            <BootstrapDialog
              // onClose={this.handleClose}
              aria-labelledby="customized-dialog-title"
              open={true}
              fullWidth
              maxWidth="md"
            >
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={this.handleClose}
              >
                Notification -{" "}
                {
                  (noticeMsgListDataLocalStorage
                    ? noticeMsgListDataLocalStorage
                    : noticeList.noticeMsg
                  ).length
                }
              </BootstrapDialogTitle>
              <DialogContent dividers>
                {(noticeMsgListDataLocalStorage
                  ? noticeMsgListDataLocalStorage
                  : noticeList.noticeMsg
                ).map((noticeObject, index) => (
                  <React.Fragment key={index}>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      container
                      justifyContent="center"
                    >
                      {" "}
                      <img
                        className="Image14"
                        style={{ width: 810, height: 700 }}
                        src={
                          "https://bzintel-bucket.s3.ap-south-1.amazonaws.com/distribution/document/" +
                          noticeObject.image
                        }
                        alt={noticeObject.noticeHeadline}
                      />
                    </Grid>
                    <br />
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      container
                      justifyContent="center"
                    >
                      <div style={{ fontSize: "large", fontWeight: "550" }}>
                        {noticeObject.noticeHeadline}
                      </div>
                    </Grid>
                    <br />
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      container
                      justifyContent="left"
                    >
                      <div style={{ fontSize: "small" }}>
                        {noticeObject.description}
                      </div>
                    </Grid>
                    <br />
                  </React.Fragment>
                ))}
              </DialogContent>
            </BootstrapDialog>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  noticeList: state.noticeMsg,
});

const mapDispatchToProps = {
  showLoador,
  showNotification,
  getNoticeMsgByUserType,
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage);
