import jsPDF from "jspdf";
import "jspdf-autotable";
export const generatePDF = (data, tableColumn, title) => {

  const doc = new jsPDF();
  const tableRows = [];

  data.map((value, index, institute) => {
    let tableRows_temp = [];
    Object.keys(value).map((innerkey, innerindex) => {
     return tableRows_temp.push(value[innerkey]);
    });
    tableRows.push(tableRows_temp);
    return tableRows
  });

  doc.autoTable(tableColumn, tableRows, { startY: 20 });
  const date = Date().split(" ");
  const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
  doc.text(title, 100, 15, "center");
  doc.save(`report_${dateStr}.pdf`);
};

