export const ConvertAmount = ({ amount }) => {
  if (amount >= 1000) {
    const denominationList = [
      { key: "K", value: "1000-99999" },
      { key: "L", value: "100000-9999999" },
      { key: "Cr", value: "10000000-9999999999" },
    ];
    let total = 0;
    let denomination = "";
    denominationList.map((deno) => {
      const valueSplt = deno.value.split("-");
      if (valueSplt[0] <= amount && valueSplt[1] >= amount) {
        total = amount / valueSplt[0];
        denomination = deno.key;
      }
    });
    return total.toFixed(2) + " " + denomination;
  }
  return +amount;
};

export const ConvertAmountToDenomination = (lakhString) => {
  // Extract the numeric part (excluding "lakh")
  const numericPart = parseFloat(lakhString);
  let numberFormat = localStorage.getItem("denomination");
  if (numberFormat != null) {
    if (numberFormat === "L") {
      // Convert to the actual number in lakhs
      const numericValue = numericPart * 100000;
      return numericValue;
    } else if (numberFormat === "K") {
      // Convert to the actual number in lakhs
      const numericValue = numericPart * 1000;
      return numericValue;
    } else {
      const numericValue = numericPart * 10000000;
      return numericValue;
    }
  } else {
    return numericValue;
  }
};
