import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  projection: [],
  roasterData: [],
  roasterDateWise: [],
};
let URL = endpoints.projection;
const projectionSlice = createSlice({
  name: "projection",
  initialState,
  reducers: {
    projectionSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        projection: row,
      };
    },
    roasterSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        roasterData: row,
      };
    },

    roasterDateWiseSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        roasterDateWise: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  projectionSuccess,
  roasterSuccess,
  roasterDateWiseSuccess,
  resetState,
} = projectionSlice.actions;

export default projectionSlice.reducer;

export const getProjection =
  ({ salesTeamId, fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-sales-team-and-from-to-date?salesTeamId=" +
          salesTeamId +
          "&fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const content = data.data;
          const row = content.map((projectionData, index) => {
            let projection = {
              index: index + 1,
              id: projectionData.id === null ? "" : projectionData.id,
              flag: projectionData.flag === null ? "" : projectionData.flag,
              date: projectionData.date === null ? "" : projectionData.date,
              salesTeam:
                projectionData.salesTeam === null
                  ? ""
                  : projectionData.salesTeam,
              beatWithOutletCounts:
                projectionData.beatWithOutletCounts === null
                  ? ""
                  : projectionData.beatWithOutletCounts,
              beatNames:
                projectionData.beatNames === null
                  ? ""
                  : projectionData.beatNames,
            };
            return projection;
          });
          dispatch(projectionSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getRoaster =
  ({ area, fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-projection-detail?areaId=" +
          area +
          "&fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const row = [];
          dispatch(roasterSuccess({ row: data.data }));
          return { response: data.data, success };
        }
        return { response: data.data, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getRoasterDateWise =
  ({ salesTeamId, date }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-sales-team-and-date?salesTeamId=" +
          salesTeamId +
          "&date=" +
          date,
      }).then(({ data, success }) => {
        if (success) {
          const row = [];
          dispatch(roasterDateWiseSuccess({ row: data.data }));
          return { response: data.data, success };
        }
        return { response: data.data, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
