import React, { useEffect, useState } from "react";
import "./billPDFCss.css";
import { langugae } from "../../config/languageJSON";
import endpoint from "../../config/endpoints";
import { apiGet, apiPost } from "../../utils/api_service";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../components/withRouter";
import { connect } from "react-redux";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { imageUrl } from "../../config/Url";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import QRCode from "qrcode.react";

const MyComponent = ({ showLoador, showNotification }) => {
  const data = "9579061161@ybl";
  const params = useParams();
  const [billList, setBill] = useState([]);
  let lang = localStorage.getItem("lang");
  let purchaseBillPdfId = localStorage.getItem("purchaseBillPdfId");
  useEffect(() => {
    if (navigator.onLine) {
      lang = localStorage.getItem("lang");
      purchaseBillPdfId = localStorage.getItem("purchaseBillPdfId");

      let purchaseBillPdfData = {
        stockStakeHolderBillHeaderIds: purchaseBillPdfId.split(","),
        languageId: lang,
      };

      // showLoador({ loador: true });
      // apiPost({
      //
      //     url: endpoint.stockStakeholderBillHeader + "/report-by-ids",
      //     postBody: purchaseBillPdfData,
      // }).then(({ data, success }) => {
      //     console.log(data);
      //     showLoador({ loador: false });
      //     if (!success) {
      //         showNotification({
      //             msg: serverMsg,
      //             severity: "error",
      //         });
      //     } else {
      //         setBill(data.data);
      //         setTimeout(function () {
      //             window.print();
      //         }, 1200);
      //     }
      // });

      showLoador({ loador: true });
      apiPost({
        // http://localhost:8088/api/distribution/v1/stock-stake-holder-bill-header/get-report-by-ids
        url: endpoint.stockStakeholderBillHeader + "/get-report-by-ids",
        postBody: purchaseBillPdfData,
      }).then(({ data, success }) => {
        console.log(data);
        showLoador({ loador: false });
        if (!success) {
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
        } else {
          setBill(data.data);
          setTimeout(function () {
            window.print();
          }, 1200);
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }, []);

  return (
    <>
      {/* <footer className='header'>
                <p>Tax Invoice</p>

            </footer>

            <header className='header2'>
                <p>Author: Hege Refsnes</p>
            </header> */}
      {billList.map((dataToSet, index) => {
        let invoiceJson =
          dataToSet.einvoiceJson != "" && dataToSet.einvoiceJson != null
            ? JSON.parse(dataToSet.einvoiceJson)
            : {};
        // let toBill = dataToSet.toBill ? dataToSet.toBill : {};
        // let taluka =
        //     toBill.beat != null &&
        //         toBill.beat.area != null &&
        //         toBill.beat.area.taluka != null
        //         ? toBill.beat.area.taluka
        //         : "";
        // let talukaName = taluka != null ? taluka.name : "";
        // let districtName =
        //     taluka != null && taluka.district != null ? taluka.district.name : "";
        let addr = dataToSet.toBillAddress ? dataToSet.toBillAddress : "";
        let address = langugae[2].address ? langugae[2].address + " : " : "";
        var DAddrText = address + addr;
        let village = langugae[2].village ? langugae[2].village + " : " : "";
        var villageCity = dataToSet.villageCity
          ? village + dataToSet.villageCity
          : "";

        let insertDateTime = dataToSet.insertDateTime
          ? dataToSet.insertDateTime.split(" ")
          : [];
        let insertDateTimeData =
          insertDateTime.length == 2 ? insertDateTime[1] : "";
        // let toBillState = toBill.state != null ? toBill.state : {};
        let indexInfo = 0;
        let totalAmt = 0;
        let qtyTot = 0;
        let freeQtyTot = 0;
        let cgstTot = 0;
        let sgstTot = 0;
        let igstTot = 0;
        let totalAmtWithCgstSgst = 0;
        let totalAmtWithIgst = 0;
        let finalTot = 0;
        let finalTaxableTot = 0;
        let finalTotWithIgst = 0;
        // let dataToSetState = dataToSet.state != null ? dataToSet.state : {};
        let stockStakeHolderBillDetails =
          dataToSet.stockStakeHolderBillDetails != null
            ? dataToSet.stockStakeHolderBillDetails
            : [];

        return (
          <>
            <div style={{ marginRight: "20px", marginLeft: "20px" }}>
              <table style={{ width: "100%" }}>
                <tr>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td
                        align={"center"}
                        style={{ paddingBottom: "4px" }}
                        colSpan={2}
                      >
                        <div style={{ fontWeight: 600, fontSize: "20px" }}>
                          {dataToSet.fromBillGstNo == dataToSet.toBillGstNo
                            ? langugae[2].taxInvoice
                            : langugae[2].taxInvoice
                            ? langugae[2].taxInvoice
                            : ""}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                          width: "70%",
                          borderLeft: "1px solid black",
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                          borderRight: "1px solid black",
                          borderCollapse: "collapse",
                          padding: "8px",
                        }}
                      >
                        <div style={{ fontWeight: 600 }}>
                          {dataToSet.fromBillFirmName
                            ? dataToSet.fromBillFirmName
                            : "-"}
                        </div>
                        {langugae[2].ownerName
                          ? langugae[2].ownerName + " : "
                          : ""}{" "}
                        {dataToSet.fromBillOwnerName
                          ? dataToSet.fromBillOwnerName
                          : "-"}{" "}
                        <br />
                        {langugae[2].address
                          ? langugae[2].address + " : "
                          : ""}{" "}
                        {dataToSet.fromBillAddress
                          ? dataToSet.fromBillAddress
                          : "-"}
                        <br />
                        {langugae[2].mobileNo
                          ? langugae[2].mobileNo + " : "
                          : ""}{" "}
                        {dataToSet.fromBillContactPersonNo
                          ? dataToSet.fromBillContactPersonNo
                          : "-"}{" "}
                        , State :{" "}
                        {dataToSet.fromBillStateName
                          ? " " + dataToSet.fromBillStateName
                          : "-"}
                        <br />
                        {langugae[2].gSTNo
                          ? langugae[2].gSTNo + " : "
                          : ""}{" "}
                        {dataToSet.fromBillGstNo
                          ? dataToSet.fromBillGstNo
                          : "-"}{" "}
                        ,{" "}
                        {langugae[2].FSSAINo ? langugae[2].FSSAINo + " : " : ""}{" "}
                        {dataToSet.fromBillFssiFdiNo
                          ? dataToSet.fromBillFssiFdiNo
                          : "-"}
                        <br />
                        Mode of Transport : By Road , Vehicle No.:{" "}
                        {dataToSet.vehicleNo ? dataToSet.vehicleNo : "-"} <br />
                        E-Invoice ACK No:{" "}
                        {dataToSet.acknoledgeNo ? dataToSet.acknoledgeNo : "-"}
                        <br />
                      </td>

                      <td
                        align={"center"}
                        style={{
                          padding: "6px",
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                          borderRight: "1px solid black",
                          borderCollapse: "collapse",
                        }}
                      >
                        <img
                          style={{
                            width: "95%",
                            height: "175px",

                            // objectPosition: '15% 100%'
                          }}
                          src={imageUrl + localStorage.getItem("complogo")}
                          alt="Logo"
                        />
                      </td>
                    </tr>
                  </table>
                </tr>

                <tr>
                  <table style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td
                          style={{
                            borderLeft: "1px solid black",
                            textAlign: "left",
                            width: "50%",
                            borderBottom: "1px solid black",
                            borderRight: "1px solid black",
                            borderCollapse: "collapse",
                            padding: "8px",
                          }}
                        >
                          {langugae[2].billTo ? (
                            <span style={{ fontWeight: "bold" }}>
                              {langugae[2].billTo} :{" "}
                            </span>
                          ) : (
                            ""
                          )}
                          <span style={{ fontWeight: "bold" }}>
                            {dataToSet.toBillFirmName
                              ? dataToSet.toBillFirmName
                              : ""}
                          </span>{" "}
                          <br />
                          {langugae[2].ownerName
                            ? langugae[2].ownerName + " : "
                            : ""}{" "}
                          {dataToSet.toBillOwnerName
                            ? dataToSet.toBillOwnerName
                            : "-"}{" "}
                          <br />
                          {langugae[2].beatName
                            ? langugae[2].beatName + " : "
                            : ""}
                          {dataToSet.beatName && dataToSet.beatName != null
                            ? dataToSet.beatName
                            : "-"}{" "}
                          <br />
                          {DAddrText}
                          <br />
                          {langugae[2].phoneNo
                            ? langugae[2].phoneNo + " : "
                            : ""}{" "}
                          {dataToSet.toBillContactPersonNo
                            ? dataToSet.toBillContactPersonNo
                            : ""}{" "}
                          , {langugae[2].gSTNo ? langugae[2].gSTNo + " : " : ""}{" "}
                          {dataToSet.toBillGstNo != null
                            ? dataToSet.toBillGstNo
                            : "-"}{" "}
                          , <br />
                          Place Of Supply:{" "}
                          {dataToSet.toBillStateName
                            ? " " + dataToSet.toBillStateName
                            : ""}{" "}
                          ,{" "}
                          {langugae[2].FSSAINo
                            ? langugae[2].FSSAINo + " : "
                            : ""}{" "}
                          {dataToSet.toBillFssiFdiNo != null &&
                          dataToSet.toBillFssiFdiNo != ""
                            ? dataToSet.toBillFssiFdiNo
                            : "-"}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid black",
                            textAlign: "left",
                            width: "50%",
                            borderBottom: "1px solid black",
                            borderRight: "1px solid black",
                            borderCollapse: "collapse",
                            padding: "8px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>Ship To : </span>
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            {dataToSet.toBillFirmName
                              ? " " + dataToSet.toBillFirmName
                              : ""}
                          </span>{" "}
                          <br />
                          {langugae[2].beatName
                            ? langugae[2].beatName + " : "
                            : ""}
                          {dataToSet.beatName && dataToSet.beatName != null
                            ? dataToSet.beatName
                            : "-"}{" "}
                          <br />
                          {DAddrText}
                          <br />
                          {villageCity}
                          <br />
                          {langugae[2].phoneNo
                            ? langugae[2].phoneNo + " : "
                            : ""}{" "}
                          {dataToSet.toBillContactPersonNo
                            ? dataToSet.toBillContactPersonNo
                            : ""}{" "}
                          , {langugae[2].gSTNo ? langugae[2].gSTNo + " : " : ""}{" "}
                          {dataToSet.toBillGstNo != null
                            ? dataToSet.toBillGstNo
                            : "-"}{" "}
                          , <br />
                          Place Of Supply:{" "}
                          {dataToSet.toBillStateName
                            ? " " + dataToSet.toBillStateName
                            : ""}{" "}
                          <br />
                          Reverse tax applicable ? ( Yes / No ) : No
                        </td>
                      </tr>
                    </table>
                  </table>
                </tr>
              </table>

              <div
                style={{
                  borderCollapse: "collapse",
                  marginTop: "8px",
                }}
              >
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <thead>
                    <tr>
                      <td colSpan={12}>
                        <table
                          style={{ borderCollapse: "collapse", width: "100%" }}
                        >
                          <tr>
                            <td>
                              <div style={{ fontSize: "18px" }}>
                                <div
                                  style={{ display: "inline", fontWeight: 600 }}
                                >
                                  {langugae[2].billNO
                                    ? langugae[2].billNO + " : "
                                    : ""}
                                </div>
                                {dataToSet.billNo ? dataToSet.billNo : "-"}{" "}
                              </div>
                            </td>

                            <td align={"right"}>
                              <div style={{ fontSize: "18px" }}>
                                <div
                                  style={{ display: "inline", fontWeight: 600 }}
                                >
                                  {langugae[2].date
                                    ? langugae[2].date + " : "
                                    : ""}
                                </div>

                                {dataToSet.billDate ? dataToSet.billDate : "-"}
                              </div>
                              <div style={{ fontSize: "14px" }}>
                                <div style={{ display: "inline" }}>
                                  {" "}
                                  {langugae[2].time
                                    ? langugae[2].time + " : "
                                    : ""}
                                  {insertDateTimeData}
                                </div>{" "}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>

                    <tr>
                      <th className="thsmallcell">#</th>
                      <th className="thmedcell">
                        {langugae[2].HSN ? langugae[2].HSN : ""}
                      </th>
                      <th className="thbigcell">
                        {langugae[2].itemName ? langugae[2].itemName : ""}
                      </th>
                      <th className="thbigcell">
                        {langugae[2].uom ? langugae[2].uom : ""}
                      </th>
                      <th className="thbigcell">
                        {langugae[2].qty ? langugae[2].qty : ""}
                      </th>
                      <th className="thbigcell">
                        {langugae[2].freeQty ? langugae[2].freeQty : ""}
                      </th>
                      <th className="thmedcell">
                        {langugae[2].rate ? langugae[2].rate : ""}
                      </th>
                      {dataToSet.toBillStateId == dataToSet.fromBillStateId ? (
                        <>
                          <th colSpan={2} className="thmedcell">
                            {langugae[2].CGST ? langugae[2].CGST : ""}
                          </th>
                          <th colSpan={2} className="thmedcell">
                            {langugae[2].SGST ? langugae[2].SGST : ""}
                          </th>
                        </>
                      ) : (
                        <th colSpan={2} className="thmedcell">
                          {langugae[2].IGST ? langugae[2].IGST : ""}
                        </th>
                      )}
                      <th className="thmedcell">{"Taxable Amt"}</th>
                      <th className="thmedcell">
                        {langugae[2].amount ? langugae[2].amount : ""}
                      </th>
                    </tr>
                    <tr>
                      <th className="thsmallcell"></th>
                      <th className="thmedcell"></th>
                      <th className="thbigcell"></th>
                      <th className="thbigcell"></th>
                      <th className="thbigcell"></th>
                      <th className="thmedcell"></th>
                      <th className="thmedcell"></th>
                      {dataToSet.toBillStateId == dataToSet.fromBillStateId ? (
                        <>
                          <th className="thmedcell">{"%"}</th>
                          <th className="thmedcell">
                            {langugae[2].amount ? langugae[2].amount : ""}
                          </th>
                          <th className="thmedcell">{"%"}</th>

                          <th className="thmedcell">
                            {langugae[2].amount ? langugae[2].amount : ""}
                          </th>
                        </>
                      ) : (
                        <>
                          <th className="thmedcell">{"%"}</th>

                          <th className="thmedcell">
                            {langugae[2].amount ? langugae[2].amount : ""}
                          </th>
                        </>
                      )}

                      <th className="thmedcell"></th>
                      <th className="thmedcell"></th>
                    </tr>
                  </thead>

                  <tbody style={{ width: "100%" }}>
                    {dataToSet.packagingTypes.map((packagingType) => {
                      return (
                        <>
                          <tr>
                            <td
                              align={"left"}
                              colSpan={
                                dataToSet.toBillStateId ==
                                dataToSet.fromBillStateId
                                  ? 12
                                  : 10
                              }
                              style={{ fontWeight: 600 }}
                              className="tdsmallcell"
                            >
                              {packagingType.name}
                            </td>
                          </tr>

                          {stockStakeHolderBillDetails.map(
                            (stockDetails, indexData) => {
                              if (
                                packagingType.id == stockDetails.packagingTypeId
                              ) {
                                let productData = [];
                                let product =
                                  stockDetails.product != null
                                    ? stockDetails.product
                                    : {};
                                qtyTot = qtyTot + stockDetails.qty;
                                freeQtyTot = freeQtyTot + stockDetails.freeQty;
                                cgstTot = cgstTot + stockDetails.cgstAmt;
                                sgstTot = sgstTot + stockDetails.sgstAmt;
                                igstTot = igstTot + stockDetails.igstAmt;
                                indexInfo = indexInfo + 1;
                                totalAmtWithCgstSgst =
                                  stockDetails.cgstAmt +
                                  stockDetails.sgstAmt +
                                  stockDetails.taxableAmt;
                                totalAmtWithIgst =
                                  stockDetails.igstAmt + stockDetails.taxableAmt;
                                finalTaxableTot = stockDetails.taxableAmt + finalTaxableTot;
                                totalAmt = totalAmt + stockDetails.taxableAmt;
                                finalTot = cgstTot + sgstTot + totalAmt;
                                finalTotWithIgst = igstTot + totalAmt;
                                return (
                                  <>
                                    <tr>
                                      <td className="tdsmallcell">
                                        {indexInfo}
                                      </td>
                                      <td className="tdsmallcell">
                                        {stockDetails.hsnCode}
                                      </td>
                                      <td
                                        align={"left"}
                                        className="tdsmallcell"
                                      >
                                        {stockDetails.productName}
                                      </td>
                                      <td
                                        align={"left"}
                                        className="tdsmallcell"
                                      >
                                        {stockDetails.uomName != null
                                          ? stockDetails.uomName
                                          : "-"}
                                      </td>
                                      <td
                                        align={"right"}
                                        className="tdsmallcell"
                                      >
                                        {stockDetails.qty}
                                      </td>
                                      <td
                                        align={"right"}
                                        className="tdsmallcell"
                                      >
                                        {stockDetails.freeQty}
                                      </td>
                                      <td
                                        align={"right"}
                                        className="tdsmallcell"
                                      >
                                        {stockDetails.rate.toFixed(2)}
                                      </td>
                                      {dataToSet.toBillStateId ==
                                      dataToSet.fromBillStateId ? (
                                        <>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                          >
                                            {stockDetails.cgstPer.toFixed(2)}
                                          </td>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                          >
                                            {stockDetails.cgstAmt.toFixed(2)}
                                          </td>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                          >
                                            {stockDetails.sgstPer.toFixed(2)}
                                          </td>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                          >
                                            {stockDetails.sgstAmt.toFixed(2)}
                                          </td>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                          >
                                            {stockDetails.taxableAmt.toFixed(2)}
                                          </td>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                          >
                                            {totalAmtWithCgstSgst.toFixed(2)}
                                          </td>
                                        </>
                                      ) : (
                                        <>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                          >
                                            {stockDetails.igstPer.toFixed(2)}
                                          </td>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                          >
                                            {stockDetails.igstAmt.toFixed(2)}
                                          </td>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                          >
                                            {stockDetails.taxableAmt.toFixed(2)}
                                          </td>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                          >
                                            {totalAmtWithIgst.toFixed(2)}
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                  </>
                                );
                              }
                            }
                          )}
                        </>
                      );
                    })}

                    <tr>
                      <td
                        align={"left"}
                        className="tdsmallcell"
                        style={{ fontWeight: 600 }}
                      >
                        {langugae[2].total ? langugae[2].total : ""}
                      </td>
                      <td className="tdsmallcell"></td>
                      <td className="tdsmallcell"></td>
                      <td className="tdsmallcell"></td>
                      <td
                        align={"right"}
                        style={{ fontWeight: 600 }}
                        className="tdsmallcell"
                      >
                        {qtyTot}
                      </td>
                      <td
                        align={"right"}
                        style={{ fontWeight: 600 }}
                        className="tdsmallcell"
                      >
                        {freeQtyTot}
                      </td>

                      {dataToSet.toBillStateId == dataToSet.fromBillStateId ? (
                        <>
                          <td className="tdsmallcell"></td>
                          <td className="tdsmallcell"></td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 600 }}
                            className="tdsmallcell"
                          >
                            {cgstTot.toFixed(2)}
                          </td>
                          <td className="tdsmallcell"></td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 600 }}
                            className="tdsmallcell"
                          >
                            {sgstTot.toFixed(2)}
                          </td>
                        </>
                      ) : (
                        <>
                          <td className="tdsmallcell"></td>
                          <td className="tdsmallcell"></td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 600 }}
                            className="tdsmallcell"
                          >
                            {igstTot.toFixed(2)}
                          </td>
                        </>
                      )}

                      {dataToSet.toBillStateId == dataToSet.fromBillStateId ? (
                        <>
                          <td
                            align={"right"}
                            style={{ fontWeight: 600 }}
                            className="tdsmallcell"
                          >
                            {finalTaxableTot.toFixed(2)}
                          </td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 600 }}
                            className="tdsmallcell"
                          >
                            {finalTot.toFixed(2)}
                          </td>
                        </>
                      ) : (
                        <>
                          <td
                            align={"right"}
                            style={{ fontWeight: 600 }}
                            className="tdsmallcell"
                          >
                            {finalTaxableTot.toFixed(2)}
                          </td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 600 }}
                            className="tdsmallcell"
                          >
                            {finalTotWithIgst.toFixed(2)}
                          </td>
                        </>
                      )}
                    </tr>
                  </tbody>
                </table>
                <br />
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <tr>
                    <th className="thsmallcell">
                      {langugae[2].fillingType ? langugae[2].fillingType : ""}
                    </th>
                    {dataToSet.categories.map((categoryData, index) => {
                      return (
                        <th className="thsmallcell">{categoryData.name}</th>
                      );
                    })}
                  </tr>
                  {dataToSet.packagingTypes.map((packagingTypeData) => {
                    return (
                      <tr>
                        <td align={"left"} className="tdsmallcell">
                          {packagingTypeData.name}
                        </td>

                        {dataToSet.categories.map((categoryData) => {
                          let flag = 0;
                          return (
                            <>
                              {dataToSet.dtoCategoryWithPackagingTypes.map(
                                (rowData) => {
                                  if (
                                    categoryData.id == rowData.categoryId &&
                                    rowData.packagingTypeId ==
                                      packagingTypeData.id
                                  ) {
                                    flag = 1;
                                    return (
                                      <td
                                        align={"right"}
                                        className="tdsmallcell"
                                      >
                                        {rowData.totalQty}
                                      </td>
                                    );
                                  }
                                }
                              )}
                              {flag == 0 && (
                                <td align={"left"} className="tdsmallcell"></td>
                              )}
                            </>
                          );
                        })}
                      </tr>
                    );
                  })}
                </table>

                <br />
                {console.log(dataToSet)}
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <tr>
                    <td align={"left"} colSpan={3} className="tdsmallcell">
                      {langugae[2].note ? langugae[2].note + " : " : ""}
                      {dataToSet.orderRemarks != null
                        ? dataToSet.orderRemarks
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td align={"left"} className="tdsmallcell">
                      1.{" "}
                      {langugae[2].orderBy ? langugae[2].orderBy + " : " : ""}
                      {dataToSet.salesTeamNames ? dataToSet.salesTeamNames : ""}
                      {"-"}
                      {dataToSet.salesTeamNo ? dataToSet.salesTeamNo : ""}
                    </td>
                    {localStorage.getItem("upi") != "" &&
                      localStorage.getItem("upi") != null && (
                        <td
                          align={"center"}
                          width={"25%"}
                          rowSpan={3}
                          className="tdsmallcell"
                        >
                          <QRCode
                            value={`upi://pay?pa=${encodeURIComponent(
                              localStorage.getItem("upi")
                            )}`}
                          />
                        </td>
                      )}

                    <td
                      align={"left"}
                      className="tdsmallcell"
                      style={{ borderBottom: "none" }}
                    >
                      {dataToSet.fromBillFirmName
                        ? dataToSet.fromBillFirmName
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td align={"left"} className="tdsmallcell">
                      2.{" "}
                      {langugae[2].goodsCheckBeforeReceived
                        ? langugae[2].goodsCheckBeforeReceived + " : "
                        : ""}
                    </td>
                    <td
                      align={"left"}
                      style={{ borderRight: "1px solid rgb(172, 172, 172)" }}
                    ></td>
                  </tr>
                  <tr>
                    <td align={"left"} className="tdsmallcell">
                      3.{" "}
                      {langugae[2].subjectToJurisdiction
                        ? langugae[2].subjectToJurisdiction + " : "
                        : ""}
                      {localStorage.getItem("districtName")}
                    </td>
                    <td
                      align={"left"}
                      className="tdsmallcell"
                      style={{ borderTop: "none" }}
                    >
                      {langugae[2].aurthorisedSign
                        ? langugae[2].aurthorisedSign
                        : ""}
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div
              style={{
                // position: "absolute",//
                marginBottom: "0",
                width: "100%",
                backgroundColor: "fff",
                padding: "10px",
                textAlign: "right",
              }}
            >
              Number of bill {index + 1} of {billList.length}
            </div>
            {index + 1 != billList.length && (
              <>
                <div className="pagebreak"></div>
              </>
            )}
          </>
        );
      })}
    </>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showNotification,
  showLoador,
};
export default connect(mapStateToProps, mapDispatchToProps)(MyComponent);

// Define a functional component
// const QRCodeGenerator = () => {
//   // Data to be encoded in the QR code
//   const data = 'https://example.com';

//   return (
//     <div>
//       <h1>QR Code Generator</h1>
//       {/* Use the QRCode component and pass the data as a prop */}

//     </div>
//   );
// };

// // Export the component
// export default QRCodeGenerator;
