import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { grievanceJson } from "../../DynamicFormsJson/Transaction/grievance";
import { getGrievanceTypeAndArea, setGrievanceTypeAndArea } from "../../Slice/grievance.slice";
import { GrievanceColumns } from "../../tableColumns/table-columns";
import { getRegion } from "../../Slice/region.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getAreaByTalukaPostBody } from "../../Slice/area.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import GrievanceDialog from "./GrievanceDialog";
import endpoint from "../../config/endpoints";
import { downloadF } from "../PDF/PDFDownload";

class GrievanceTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldData: {},
      responseRemark: "",
      fromdate: "",
      todate: "",
      type: "",
      areaId: "",
      status: "",
      dynamicMasterData: {
        regionDetail: this.props.regionList?.region,
        stateDetail: this.props.stateList?.stateByRegion,
        zoneDetail: this.props.zoneList?.zoneByState,
        districtDetail: this.props.districtList?.districtByZone,
        talukaDetail: this.props.talukaList?.talukaByDistrict,
        areaDetail: this.props.areaList?.areaByTaluka,
        typeList: [
          { id: "0", name: "Regular" },
          { id: "1", name: "Retailer" },
        ],
        statusList: [
          { id: "0", name: "Pending" },
          { id: "1", name: "Closed" },
        ],
      },
      grievanceTypeAndAreaList: [],
      openGrievanceDialog: false,
    };
  }
  async componentDidMount() {
    const { getRegion, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getRegion().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (this.props.stateList?.stateByRegion !== nextProps.stateList?.stateByRegion) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (this.props.districtList?.districtByZone !== nextProps.districtList?.districtByZone) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (this.props.talukaList?.talukaByDistrict !== nextProps.talukaList?.talukaByDistrict) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
  }

  onTextFieldDataChange = (event) => {
    this.setState({
      responseRemark: event.target.value,
    });
  };
  onSearchData = async (data) => {
    const { getGrievanceTypeAndArea, showLoador, showNotification } =
      this.props;

    this.setState({
      fromdate: data.fromdate,
      todate: data.todate,
      type: data.type,
      areaId: data.area,
      status: data.status,
    });

    if (navigator.onLine) {

      let dataToSave = {
        fromDate: data.fromdate,
        toDate: data.todate,
        type: data.type,
        areaIds: data.area,
        status: data.status,
      }

      showLoador({ loador: true });
      await getGrievanceTypeAndArea({ dataJson: dataToSave }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {

        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSubmitRefreshList = async () => {
    const { getGrievanceTypeAndArea, showLoador, showNotification } =
      this.props;
    const { fromdate, todate, type, areaId, status } = this.state;

    if (navigator.onLine) {
      let dataToSave = {
        fromDate: fromdate,
        toDate: todate,
        type: type,
        areaIds: areaId,
        status: status,
      }

      showLoador({ loador: true });
      await getGrievanceTypeAndArea({ dataJson: dataToSave }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {

        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  rowView = (rowData) => {
    this.setState({
      openGrievanceDialog: true,
      fieldData: rowData,
      insertedUserId: rowData.insertedUserId,
      insertDateTime: rowData.insertDateTime,
      responseRemark: rowData.responseRemark,
      remark: rowData.remark,
    });
  }

  handleClosesForOutlet = () => {
    this.setState({
      openGrievanceDialog: false,
      responseRemark: "",
      fieldData: {},
    });
  };

  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTalukaPostBody,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      console.log(data);
      if (data.region && data.region != "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state && data.state != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.zone && data.zone != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zone }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.district && data.district != "") {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka && data.taluka != "") {
        let talukaIdsByPost = {
          talukaIds: data.taluka,
        };
        showLoador({ loador: true });
        await getAreaByTalukaPostBody({ talukaIds: talukaIdsByPost }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };


  generateExcelStatic = () => {
    const { fromdate, todate, type, areaId, status } = this.state;
    const { showLoador, showNotification } = this.props;

    if (navigator.onLine) {

      showLoador({ loador: true });
      downloadF({
        isGet: true,
        url: endpoint.grievance + "/by-grivience-category-type-and-area-excel?fromdate=" + fromdate + "&todate=" + todate + "&type=" + type + "&areaIds=" + areaId + "&status=" + status,
        ext: "xls",
        openNewTab: false,
      }).then((response) => {
        if (!response) {
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
        }
        showLoador({ loador: false });
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };


  render() {
    const { grievanceTypeAndAreaList, setGrievanceTypeAndArea } = this.props;
    const {
      dynamicMasterData,
      openGrievanceDialog,
      fieldData,
      responseRemark,
      status,
    } = this.state;
    return (
      <>
        <DynamicMainScreen
          staticView={true}
          rowView={this.rowView}
          showEditIcon={false}
          showViewButtonInTable={true}
          getListById={this.getListById}
          dynamicMasterData={dynamicMasterData}
          searchList={grievanceJson.searchList}
          onSearchData={this.onSearchData}
          getTableDataById={true}
          formPath={grievanceJson.formPath}
          screenTitle={grievanceJson.screenTitle}
          fieldMeta={grievanceJson.fieldMeta}
          showPdfDownload={false}
          showExcelDownload={true}
          staticExcel={true}
          generateExcelStatic={this.generateExcelStatic}
          showExtension={false}
          deleteURL={grievanceJson.deleteURL}
          excelFileName={grievanceJson.excelFileName}
          showAddButton={false}
          tableColumnsToFilter={grievanceJson.tableColumnsToFilter}
          tableColumns={GrievanceColumns}
          tableData={grievanceTypeAndAreaList?.grievance}
          apiBaseURL={grievanceJson.apiBaseURL}
          callApi={false}
          showDeleteIcon={false}
          showPegination={false}
          getTableData={setGrievanceTypeAndArea}
        />

        {openGrievanceDialog && (
          <GrievanceDialog
            fieldData={fieldData}
            responseRemark={responseRemark}
            open={openGrievanceDialog}
            onSubmitRefreshList={this.onSubmitRefreshList}
            handleCloses={this.handleClosesForOutlet}
            onFormSave={this.rowAppliedList}
            onTextFieldDataChange={this.onTextFieldDataChange}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  grievanceTypeAndAreaList: state.grievance,
  areaList: state.area,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
});
const mapDispatchToProps = {
  getGrievanceTypeAndArea,
  getRegion,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTalukaPostBody,
  getStateByRegion,
  getZoneByState,
  showLoador,
  showNotification,
  setGrievanceTypeAndArea
};
export default connect(mapStateToProps, mapDispatchToProps)(GrievanceTable);
