import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { imageUrl } from "../../config/Url";
import endpoint from "../../config/endpoints";
import { langugae } from "../../config/languageJSON";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { apiGet, apiPost } from "../../utils/api_service";
import "./billPDFCss.css";
import QRCode, { QRCodeCanvas } from "qrcode.react";
import NumberToWords from "../../components/NumberToWords";
import dayjs from "dayjs";
import { showLoador, showNotification } from "../Landing/Landing.slice";

const MyComponent = ({ showLoador, showNotification }) => {
  const params = useParams();
  const [billList, setBill] = useState([]);
  const [qrCode, setQRCode] = useState("");
  const [cin, setCin] = useState("");
  let lang =
    localStorage.getItem("lang") && localStorage.getItem("lang") != null
      ? localStorage.getItem("lang")
      : 2;
  let myNewBillPdfID = localStorage.getItem("myNewBillPdfID");
  let bankName = "";
  let accNo = "";
  let ifscCode = "";

  useEffect(() => {
    // window.print();
    if (navigator.onLine) {
      lang = localStorage.getItem("lang");
      myNewBillPdfID = localStorage.getItem("myNewBillPdfID");

      let myNewBillPdfData = {
        stockStakeHolderBillHeaderIds: myNewBillPdfID.split(","),
        languageId: lang,
      };
      showLoador({ loador: true });
      apiPost({
        url: endpoint.stockStakeholderBillHeader + "/batch-wise-report-by-ids",
        postBody: myNewBillPdfData,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
        } else {
          setQRCode(
            data.data.length != 0 &&
              data.data[0].einvoiceJson != "" &&
              data.data[0].einvoiceJson != null
              ? JSON.parse(data.data[0].einvoiceJson).SignedQRCode
              : ""
          );
          setBill(data.data);
          // window.print();
        }
      });

      showLoador({ loador: true });
      apiGet({
        url: endpoint.setting + "/by-key?key=cin_value",
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          if (data !== null && Object.keys(data.data).length !== 0) {
            setCin(data.data.settingValue);
          }
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }, []);

  return (
    <>
      {/* <footer className='header'>
 <p>Tax Invoice</p>
 
 </footer>
 
 <header className='header2'>
 <p>Author: Hege Refsnes</p>
 </header> */}
      {billList.map((dataToSet, index) => {
        let bankDetails = [];

        if (dataToSet.bankDetails && dataToSet.bankDetails.length > 0) {
          bankDetails = dataToSet.bankDetails.map((bankObj) => ({
            bankName: bankObj.bankName,
            accNo: bankObj.accNo,
            ifscCode: bankObj.ifscCode,
          }));
        }
        let einvoiceJsonData =
          dataToSet.einvoiceJson !== null || dataToSet.einvoiceJson !== ""
            ? JSON.parse(dataToSet.einvoiceJson)
            : {};
        let vehicleData =
          dataToSet.vehicleNo != null ? JSON.parse(dataToSet.vehicleNo) : {};
        let toBill = dataToSet.toBill ? dataToSet.toBill : {};
        let taluka =
          toBill.beat != null &&
          toBill.beat.area != null &&
          toBill.beat.area.taluka != null
            ? toBill.beat.area.taluka
            : "";
        let talukaName =
          toBill != null && toBill.taluka && toBill.taluka.name
            ? toBill.taluka.name
            : "";
        let districtName =
          toBill != null && toBill.district && toBill.district.name
            ? toBill.district.name
            : "";
        let addr = toBill.address ? toBill.address + " - " + districtName : "";
        let address = langugae[2].address ? langugae[2].address + " : " : "";
        var DAddrText = address + addr;
        let insertDateTime = dataToSet.insertDateTime
          ? dataToSet.insertDateTime.split(" ")
          : [];
        let insertDateTimeData =
          insertDateTime.length == 2 ? insertDateTime[1] : "";
        let toBillState = toBill.state != null ? toBill.state : {};
        let indexInfo = 0;
        let totalAmt = 0;
        let totalTaxableAmt = 0;
        let cgstTotalAmt = 0;
        let sgstTotalAmt = 0;
        let igstTotalAmt = 0;
        let qtyTot = 0;
        let freeQtyTot = 0;
        let dataToSetState = dataToSet.state != null ? dataToSet.state : {};
        let stockStakeHolderBillDetails =
          dataToSet.stockStakeHolderBillDetails != null
            ? dataToSet.stockStakeHolderBillDetails
            : [];
        return (
          <>
            <div>
              <table style={{ width: "100%" }}>
                <tr>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td colSpan={11}>
                        <table
                          style={{ borderCollapse: "collapse", width: "100%" }}
                        >
                          <tr>
                            <td
                              align={"left"}
                              style={{
                                padding: "5px",
                                // paddingBottom: "5px",
                                // borderTop: "1px solid black",
                                // borderBottom: "1px solid black",
                                // borderRight: "1px solid black",
                                borderCollapse: "collapse",
                              }}
                            >
                              <img
                                style={{
                                  width: "auto",
                                  height: "100%",
                                  maxHeight: "160px", // Optional: to limit the maximum height
                                  // borderCollapse: "collapse",
                                }}
                                src={
                                  imageUrl + localStorage.getItem("complogo")
                                }
                                alt="Logo"
                              />
                            </td>

                            <td
                              align={"center"}
                              style={{ paddingBottom: "3px" }}
                              // colSpan={1}
                            >
                              <div
                                style={{
                                  fontWeight: 600,
                                  fontSize: "26px", // Adjusted font size
                                }}
                              >
                                {dataToSet.fromBillFirmName
                                  ? dataToSet.fromBillFirmName
                                  : "-"}
                              </div>

                              <div
                                style={{
                                  textAlign: "center",
                                  fontWeight: 500,
                                  fontSize: "18px", // Adjusted font size
                                }}
                              >
                                {dataToSet.fromBillAddress
                                  ? dataToSet.fromBillAddress
                                  : "-"}
                              </div>
                            </td>

                            {/* <td
 align={"left"}
 style={{
 padding: "5px",
 // borderTop: "1px solid black",
 // borderBottom: "1px solid black",
 // borderRight: "1px solid black",
 height:"90px",
 borderCollapse: "collapse",
 width:"12%"
 }}
 // colspan={1}
 >
 
 
 
 <QRCode value={`upi://pay?pa=${encodeURIComponent(localStorage.getItem('upi'))}`} />
 
 </td> */}
                            <QRCode value={qrCode} size={256} />
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={12}>
                        <table
                          style={{ borderCollapse: "collapse", width: "100%" }}
                        >
                          <tr>
                            <td
                              align={"right"}
                              style={{
                                paddingLeft: "10px",
                                paddingBottom: "2px",
                              }}
                              // colSpan={1}
                            >
                              {/* <div
 style={{
 textAlign: "right",
 flexDirection: "row",
 fontWeight: 600,
 fontSize: "16px",
 }}
 >
 {"City : "}
 {"-"}
 </div> */}
                            </td>

                            <td
                              align={"right"}
                              style={{ paddingBottom: "2px" }}
                              // colSpan={1}
                            >
                              {/* <div
 style={{
 textAlign: "right",
 flexDirection: "row",
 fontWeight: 600,
 fontSize: "16px",
 }}
 >
 {"Post Code : "}
 {"-"}
 </div> */}
                            </td>
                            {/* <td
                              align={"left"}
                              style={{ paddingBottom: "2px" }}
                            // colSpan={1}
                            >
                              <div
                                style={{
                                  textAlign: "center",
                                  flexDirection: "row",
                                  fontWeight: 600,
                                  fontSize: "16px",
                                }}
                              >
                                {"Phone : "}
                                {dataToSet.fromBillContactPersonNo
                                  ? dataToSet.fromBillContactPersonNo
                                  : "-"}
                              </div>
                            </td> */}
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          width: "100%",
                          borderLeft: "1px solid black",
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                          borderRight: "1px solid black",
                          borderCollapse: "collapse",
                          padding: "8px",
                        }}
                        colSpan={11}
                      >
                        <table
                          style={{ borderCollapse: "collapse", width: "100%" }}
                        >
                          <tr>
                            <td
                              align={"center"}
                              style={{
                                textAlign: "left",
                                width: "20%",
                                // borderLeft: "1px solid black",
                                // borderTop: "1px solid black",
                                // borderBottom: "1px solid black",
                                // borderRight: "1px solid black",
                                borderCollapse: "collapse",
                                // paddingLeft: "6px",
                                // paddingBottom: "8px",
                              }}
                              colSpan={1}
                            >
                              <div
                                style={{
                                  flexDirection: "row",
                                  fontWeight: 600,
                                  fontSize: "16px",
                                }}
                              >
                                {"GST No :"}
                                {dataToSet.fromBillGstNo
                                  ? dataToSet.fromBillGstNo
                                  : "-"}
                              </div>
                            </td>

                            <td
                              align={"center"}
                              style={{
                                textAlign: "left",
                                width: "20%",
                                // borderLeft: "1px solid black",
                                // borderTop: "1px solid black",
                                // borderBottom: "1px solid black",
                                // borderRight: "1px solid black",
                                borderCollapse: "collapse",
                                // padding: "8px",
                                // paddingBottom: "8px",
                              }}
                              colSpan={1}
                            >
                              <div
                                style={{
                                  flexDirection: "row",
                                  fontWeight: 600,
                                  fontSize: "16px",
                                }}
                              >
                                {"CIN : "}
                                {cin}
                              </div>
                            </td>

                            <td
                              align={"center"}
                              style={{
                                textAlign: "left",
                                width: "20%",
                                // borderLeft: "1px solid black",
                                // borderTop: "1px solid black",
                                // borderBottom: "1px solid black",
                                // borderRight: "1px solid black",
                                borderCollapse: "collapse",
                                // padding: "8px",
                              }}
                              colSpan={1}
                            >
                              <div
                                style={{
                                  flexDirection: "row",
                                  fontWeight: 600,
                                  fontSize: "16px",
                                }}
                              >
                                {"FSSAI NO :"}
                                {dataToSet.fromBillFssiFdiNo
                                  ? dataToSet.fromBillFssiFdiNo
                                  : "-"}
                              </div>
                            </td>
                            <td
                              align={"center"}
                              style={{
                                textAlign: "left",
                                width: "20%",
                                // borderLeft: "1px solid black",
                                // borderTop: "1px solid black",
                                // borderBottom: "1px solid black",
                                // borderRight: "1px solid black",
                                borderCollapse: "collapse",
                                // padding: "8px",
                              }}
                              colSpan={1}
                            >
                              <div
                                style={{
                                  flexDirection: "row",
                                  fontWeight: 600,
                                  fontSize: "16px",
                                }}
                              >
                                {"PAN :"}
                                {""}
                                {dataToSet.fromBillPanNo
                                  ? dataToSet.fromBillPanNo
                                  : "-"}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>

                    <tr>
                      <td
                        align={"center"}
                        style={{
                          textAlign: "center",
                          width: "100%",
                          borderLeft: "1px solid black",
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                          borderRight: "1px solid black",
                          borderCollapse: "collapse",
                          padding: "4px",
                          // paddingBottom: "8px",
                        }}
                        colSpan={3}
                      >
                        <div style={{ fontWeight: 600, fontSize: "22px" }}>
                          {dataToSet.fromBillGstNo == toBill.gstNo
                            ? "STOCK TRANSFER"
                            : "TAX INVOICE"}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                          width: "50%",
                          borderLeft: "1px solid black",
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                          borderRight: "1px solid black",
                          borderCollapse: "collapse",
                          paddingLeft: "5px",
                          // paddingBottom: "8px",
                        }}
                        // colspan={1}
                      >
                        <div
                          style={{
                            fontWeight: 600,
                            fontSize: 16,
                            paddingTop: 0,
                            paddingBottom: "3px",
                          }}
                        >
                          {"Bill To :"}
                        </div>
                        <div
                          style={{
                            fontSize: 18,
                            paddingTop: 0,
                            paddingBottom: "3px",
                          }}
                        >
                          {toBill.firmName ? toBill.firmName : ""}
                        </div>
                        {/* {langugae[2].ownerName
 ? langugae[2].ownerName + " : "
 : ""}{" "}
 {dataToSet.stockStakeHolderOwnerName
 ? dataToSet.stockStakeHolderOwnerName
 : "-"}{" "} */}
                        {addr}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            fontWeight: 600,
                            paddingBottom: "3px",
                          }}
                        >
                          <div
                            style={{
                              justifyContent: "flex-start",
                              width: "100%",
                              marginRight: "10px",
                            }}
                          >
                            {"Pincode :"}
                            {toBill.pincode ? " " + toBill.pincode : ""}{" "}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            fontWeight: 600,
                            paddingBottom: "3px",
                          }}
                        >
                          <div
                            style={{
                              justifyContent: "flex-start",
                              width: "50%",
                              marginRight: "10px",
                            }}
                          >
                            {toBillState.name ? " " + toBillState.name : ""}{" "}
                          </div>
                          <div
                            style={{
                              justifyContent: "flex-start",
                              width: "50%",
                              marginRight: "10px",
                            }}
                          >
                            {" State Code :"}
                            {toBillState.code ? " " + toBillState.code : "-"}
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            fontWeight: 600,
                            paddingBottom: "3px",
                          }}
                        >
                          <div
                            style={{
                              justifyContent: "flex-start",
                              width: "50%",
                            }}
                          >
                            {" PAN :"}
                            {toBill.panNo ? toBill.panNo : "-"}
                          </div>
                        </div>

                        <div style={{ fontSize: "16px", paddingBottom: "3px" }}>
                          <div style={{ display: "inline", fontWeight: 600 }}>
                            {"GSTIN/ UIN : "}
                            {toBill.gstNo ? toBill.gstNo : "-"}{" "}
                          </div>{" "}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            fontWeight: 600,
                            paddingBottom: "3px",
                          }}
                        >
                          <div
                            style={{
                              justifyContent: "flex-start",
                              width: "50%",
                            }}
                          >
                            {"Contact :"}
                            {toBill.contactPersonNo
                              ? " " + toBill.contactPersonNo
                              : ""}{" "}
                          </div>
                        </div>
                        {/* {langugae[2].FSSAINo ? langugae[2].FSSAINo + " : " : ""}{" "}
 {dataToSet.stockStakeHolderFssiFdiNo
 ? dataToSet.stockStakeHolderFssiFdiNo
 : "-"}
 <br />
 Mode of Transport : By Road , Vehicle No.:{" "}
 {dataToSet.vehicleNo
 ? dataToSet.vehicleNo
 : "-"}{" "}
 <br />
 E-Invoice ACK No: <br /> */}
                      </td>

                      {vehicleData.sameAsBillTo == 1 ? (
                        <td
                          style={{
                            textAlign: "left",
                            // width: "60%",
                            borderLeft: "1px solid black",
                            borderTop: "1px solid black",
                            borderBottom: "1px solid black",
                            borderRight: "1px solid black",
                            borderCollapse: "collapse",
                            paddingLeft: "5px",
                          }}
                          // colspan={1}
                        >
                          <div
                            style={{
                              fontWeight: 600,
                              fontSize: 16,
                              paddingTop: 0,
                              paddingBottom: "3px",
                            }}
                          >
                            {"Ship To :"}
                          </div>
                          {toBill.firmName ? toBill.firmName : ""} <br />
                          {addr}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: 600,
                              paddingBottom: "3px",
                            }}
                          >
                            <div
                              style={{
                                display: "inline",
                                justifyContent: "flex-start",
                                width: "50%",
                              }}
                            >
                              {"Pincode :"}
                              {toBill.pincode ? " " + toBill.pincode : ""}{" "}
                              {/* {toBill.billNo != null ? toBill.billNo : "-"} */}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: 600,
                              paddingBottom: "3px",
                            }}
                          >
                            <div
                              style={{
                                display: "inline",
                                justifyContent: "flex-start",
                                width: "50%",
                              }}
                            >
                              {toBillState.name ? " " + toBillState.name : ""}{" "}
                              {/* {toBill.billNo != null ? toBill.billNo : "-"} */}
                            </div>
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                                marginRight: "10px",
                              }}
                            >
                              {" State Code :"}
                              {toBillState.code ? " " + toBillState.code : "-"}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: 600,
                              paddingBottom: "3px",
                            }}
                          >
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                              }}
                            >
                              {" PAN :"}
                              {toBill.panNo ? toBill.panNo : "-"}
                            </div>
                          </div>
                          {/* State : {toBillState.name ? " " + toBillState.name : ""} &nbsp;&nbsp;&nbsp;City:{toBillState.name ? " " + toBillState.name : ""} */}
                          <div
                            style={{
                              display: "inline",
                              fontSize: "16px",
                              paddingBottom: "3px",
                            }}
                          >
                            <div style={{ display: "inline", fontWeight: 600 }}>
                              {"GSTIN/ UIN : "}
                              {toBill.gstNo != null ? toBill.gstNo : "-"}
                            </div>{" "}
                          </div>
                          <br />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: 600,
                              paddingBottom: "3px",
                            }}
                          >
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "100%",
                              }}
                            >
                              {"Contact :"}
                              {toBill.contactPersonNo
                                ? " " + toBill.contactPersonNo
                                : ""}{" "}
                            </div>
                          </div>
                          {/* GSTIN - {toBill.gstNo != null ? toBill.gstNo : "-"} */}
                        </td>
                      ) : (
                        <td
                          style={{
                            textAlign: "left",
                            // width: "60%",
                            borderLeft: "1px solid black",
                            borderTop: "1px solid black",
                            borderBottom: "1px solid black",
                            borderRight: "1px solid black",
                            borderCollapse: "collapse",
                            paddingLeft: "5px",
                          }}
                          // colspan={1}
                        >
                          <div
                            style={{
                              fontWeight: 600,
                              fontSize: 16,
                              paddingTop: 0,
                              paddingBottom: "3px",
                            }}
                          >
                            {"Ship To :"}
                          </div>
                          {vehicleData.firmName ? vehicleData.firmName : ""}{" "}
                          <br />
                          {vehicleData.address ? vehicleData.address : ""}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: 500,
                              paddingBottom: "3px",
                            }}
                          >
                            <div
                              style={{
                                display: "inline",
                                justifyContent: "flex-start",
                                width: "50%",
                              }}
                            >
                              {vehicleData.state ? vehicleData.state : ""}{" "}
                              {/* {toBill.billNo != null ? toBill.billNo : "-"} */}
                            </div>
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                              }}
                            >
                              {vehicleData.contactPersonNo
                                ? vehicleData.contactPersonNo
                                : "-"}{" "}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: 500,
                              paddingBottom: "3px",
                            }}
                          >
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                              }}
                            >
                              {" State Code :"}
                              {vehicleData.stateCode
                                ? " " + vehicleData.stateCode
                                : "-"}
                            </div>
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                              }}
                            >
                              {" PAN :"}
                              {vehicleData.panNo ? vehicleData.panNo : "-"}
                            </div>
                          </div>
                          {/* State : {toBillState.name ? " " + toBillState.name : ""} &nbsp;&nbsp;&nbsp;City:{toBillState.name ? " " + toBillState.name : ""} */}
                          <div
                            style={{
                              display: "inline",
                              fontSize: "16px",
                              paddingBottom: "3px",
                            }}
                          >
                            <div style={{ display: "inline", fontWeight: 600 }}>
                              {"GSTIN/ UIN : "}
                              {vehicleData.gstin != null
                                ? vehicleData.gstin
                                : "-"}
                            </div>{" "}
                          </div>
                          {/* GSTIN - {toBill.gstNo != null ? toBill.gstNo : "-"} */}
                        </td>
                      )}
                    </tr>
                  </table>
                </tr>

                <tr>
                  <table style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td
                          style={{
                            borderLeft: "1px solid black",
                            textAlign: "left",
                            width: "50%",
                            borderBottom: "1px solid black",
                            borderRight: "1px solid black",
                            borderCollapse: "collapse",
                            paddingLeft: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: 600,
                              paddingBottom: "3px",
                            }}
                          >
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                              }}
                            >
                              {"Invoice No : "}

                              {dataToSet.billNo != null
                                ? dataToSet.billNo
                                : "-"}
                            </div>
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                              }}
                            >
                              {"Place Of Supply : "}
                              {toBillState.name ? toBillState.name : ""}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: 600,
                              paddingBottom: "3px",
                            }}
                          >
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                              }}
                            >
                              {"Invoice Date : "}
                              {dataToSet.billDate ? dataToSet.billDate : ""}
                              {/* {toBill.billNo != null ? toBill.billNo : "-"} */}
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "100%",
                              fontWeight: 600,
                              paddingBottom: "8px",
                            }}
                          >
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                              }}
                            >
                              {"Ack No : "}
                              {dataToSet.acknoledgeNo
                                ? dataToSet.acknoledgeNo
                                : ""}
                              {/* {toBill.billNo != null ? toBill.billNo : "-"} */}
                            </div>
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                                marginRight: "10px",
                              }}
                            >
                              {"Ack Date : "}
                              {einvoiceJsonData ? einvoiceJsonData.AckDt : ""}
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: 600,
                              paddingBottom: "3px",
                            }}
                          >
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "100%",
                              }}
                            >
                              {"IRN No : "}
                              {einvoiceJsonData ? einvoiceJsonData.Irn : ""}
                            </div>
                          </div>
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid black",
                            textAlign: "left",
                            width: "50%",
                            borderBottom: "1px solid black",
                            borderRight: "1px solid black",
                            borderCollapse: "collapse",
                            paddingLeft: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: 600,
                              paddingBottom: "3px",
                            }}
                          >
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                                marginRight: "10px",
                              }}
                            >
                              <div
                                style={{ display: "inline", fontWeight: 600 }}
                              >
                                {"Po No : "}
                              </div>{" "}
                              {vehicleData.poNo != null
                                ? vehicleData.poNo
                                : "-"}
                            </div>
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                              }}
                            >
                              {"Po Date : "}
                              {vehicleData.poDate != null
                                ? dayjs(vehicleData.poDate).format("DD-MM-YYYY")
                                : "-"}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: 600,
                              paddingBottom: "3px",
                            }}
                          >
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                                marginRight: "10px",
                              }}
                            >
                              {"Transporter ID : "}
                              {vehicleData.transporterId != null
                                ? vehicleData.transporterId
                                : "-"}
                            </div>

                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                              }}
                            >
                              {"Transporter : "}
                              {vehicleData.transporter != null
                                ? vehicleData.transporter
                                : "-"}
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: 600,
                              paddingBottom: "3px",
                            }}
                          >
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                                marginRight: "10px",
                              }}
                            >
                              {"LR/RR/GR No : "}
                              {vehicleData.lrRrGrNo != null
                                ? vehicleData.lrRrGrNo
                                : "-"}
                            </div>

                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                              }}
                            >
                              {"LR/RR/GR Date : "}
                              {vehicleData.lrRrGrDate != null
                                ? dayjs(vehicleData.lrRrGrDate).format(
                                    "DD-MM-YYYY"
                                  )
                                : "-"}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              fontWeight: 600,
                              paddingBottom: "3px",
                            }}
                          >
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                                marginRight: "10px",
                              }}
                            >
                              {"E-Way No : "}
                              {vehicleData.eWayBillNo != null
                                ? vehicleData.eWayBillNo
                                : "-"}
                            </div>
                            <div
                              style={{
                                justifyContent: "flex-start",
                                width: "50%",
                              }}
                            >
                              {"E-Way Date : "}
                              {vehicleData.eWayBillDate != null
                                ? dayjs(vehicleData.eWayBillDate).format(
                                    "DD-MM-YYYY"
                                  )
                                : "-"}
                            </div>
                          </div>
                          <div
                            style={{
                              justifyContent: "flex-start",
                              width: "50%",
                              fontWeight: 600,
                              marginRight: "10px",
                            }}
                          >
                            {"Vehicle No : "}
                            {vehicleData.vehicleNo != null
                              ? vehicleData.vehicleNo
                              : "-"}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </table>
                </tr>
              </table>

              <div style={{ borderCollapse: "collapse", marginTop: "8px" }}>
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <thead>
                    <tr>
                      <th className="thsmallcell">Sr No</th>
                      <th className="thbigcell">{" Description "}</th>
                      <th className="thmedcell">{"HSN SAC"}</th>
                      <th className="thbigcell">
                        {langugae[2].qty ? langugae[2].qty : ""}
                      </th>
                      <th className="thbigcell">
                        {langugae[2].uom ? langugae[2].uom : ""}
                      </th>
                      <th className="thbigcell">{"Batch"}</th>

                      <th className="thmedcell">
                        {langugae[2].rate ? langugae[2].rate : ""}
                      </th>

                      <th className="thsmallcell">{"Disc(%)"}</th>
                      {/* <th className="thsmallcell">{"Add Disc(%)"}</th> */}
                      <th className="thsmallcell">{"Disc Value"}</th>
                      <th className="thmedcell">{"Taxable Net Value"}</th>
                      {toBillState.id == dataToSetState.id ? (
                        <>
                          <th colSpan={2} className="thmedcell">
                            {langugae[2].CGST ? langugae[2].CGST : ""}
                          </th>
                          <th colSpan={2} className="thmedcell">
                            {langugae[2].SGST ? langugae[2].SGST : ""}
                          </th>
                        </>
                      ) : (
                        <>
                          <th colSpan={4} className="thmedcell">
                            {langugae[2].IGST ? langugae[2].IGST : ""}
                          </th>
                        </>
                      )}

                      <th className="thmedcell">{"Total Value"}</th>
                    </tr>
                    <tr>
                      <th className="thsmallcell"></th>
                      <th className="thmedcell"></th>
                      <th className="thsmallcell"></th>
                      <th className="thmedcell"></th>
                      <th className="thbigcell"></th>
                      <th className="thbigcell"></th>
                      <th className="thbigcell"></th>
                      <th className="thmedcell"></th>
                      <th className="thmedcell"></th>
                      <th className="thmedcell"></th>
                      {toBillState.id == dataToSetState.id ? (
                        <>
                          <th className="thmedcell">
                            {langugae[2].rate ? langugae[2].rate : ""}
                          </th>

                          <th className="thmedcell">{"Amt"}</th>
                          <th className="thmedcell">
                            {langugae[2].rate ? langugae[2].rate : ""}
                          </th>

                          <th className="thmedcell">{"Amt"}</th>
                          <th className="thmedcell"></th>
                        </>
                      ) : (
                        <>
                          <th className="thmedcell" colSpan={2}>
                            {langugae[2].rate ? langugae[2].rate : ""}
                          </th>

                          <th className="thmedcell" colSpan={2}>
                            {"Amt"}
                          </th>
                          <th className="thmedcell"></th>
                        </>
                      )}
                    </tr>
                  </thead>

                  <tbody style={{ width: "100%" }}>
                    {dataToSet.packagingTypes.map((packagingType) => {
                      return (
                        <>
                          {stockStakeHolderBillDetails.map(
                            (stockDetails, indexData) => {
                              let packagingTypeDetails =
                                stockDetails.product != null &&
                                stockDetails.product.packagingType != null
                                  ? stockDetails.product.packagingType
                                  : {};

                              if (packagingType.id == packagingTypeDetails.id) {
                                let productData = [];
                                let product =
                                  stockDetails.product != null
                                    ? stockDetails.product
                                    : {};
                                qtyTot = qtyTot + stockDetails.qty;
                                freeQtyTot = freeQtyTot + stockDetails.freeQty;
                                indexInfo = indexInfo + 1;
                                totalAmt = totalAmt + stockDetails.totalAmt;
                                totalTaxableAmt =
                                  totalTaxableAmt + stockDetails.taxableAmt;
                                cgstTotalAmt =
                                  cgstTotalAmt + stockDetails.cgstAmt;
                                sgstTotalAmt =
                                  sgstTotalAmt + stockDetails.sgstAmt;
                                igstTotalAmt =
                                  igstTotalAmt + stockDetails.igstAmt;
                                return (
                                  <>
                                    <tr>
                                      <td className="tdsmallcell">
                                        {indexInfo}
                                      </td>

                                      <td
                                        align={"left"}
                                        // colSpan={2}
                                        className="tdsmallcell"
                                      >
                                        {product.name}
                                      </td>
                                      <td
                                        align={"center"}
                                        className="tdsmallcell"
                                      >
                                        {stockDetails.hsnCode}
                                      </td>
                                      <td
                                        align={"right"}
                                        className="tdsmallcell"
                                      >
                                        {stockDetails.qty}
                                      </td>
                                      <td
                                        align={"left"}
                                        className="tdsmallcell"
                                      >
                                        {stockDetails.uom != null
                                          ? stockDetails.uom.name
                                          : "-"}
                                      </td>
                                      <td
                                        align={"center"}
                                        className="tdsmallcell"
                                        width={"1%"}
                                      >
                                        {stockDetails.batchCode != null
                                          ? stockDetails.batchCode
                                          : "-"}
                                      </td>
                                      <td
                                        align={"right"}
                                        className="tdsmallcell"
                                      >
                                        {(
                                          +stockDetails.taxableAmt /
                                          +stockDetails.qty
                                        ).toFixed(4)}
                                      </td>

                                      <td
                                        align={"right"}
                                        className="tdsmallcell"
                                      >
                                        {stockDetails.itemDiscPer.toFixed(2)}
                                      </td>

                                      {/* <td
 align={"right"}
 className="tdsmallcell"
 >
 {"0"}
 </td> */}

                                      <td
                                        align={"right"}
                                        className="tdsmallcell"
                                      >
                                        {stockDetails.itemDiscAmt.toFixed(2)}
                                      </td>
                                      <td
                                        align={"right"}
                                        className="tdsmallcell"
                                      >
                                        {stockDetails.taxableAmt.toFixed(2)}
                                      </td>

                                      {toBillState.id == dataToSetState.id ? (
                                        <>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                          >
                                            {stockDetails.cgstPer.toFixed(2)}
                                          </td>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                          >
                                            {stockDetails.cgstAmt.toFixed(2)}
                                          </td>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                          >
                                            {stockDetails.sgstPer.toFixed(2)}
                                          </td>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                          >
                                            {stockDetails.sgstAmt.toFixed(2)}
                                          </td>
                                        </>
                                      ) : (
                                        <>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                            colSpan={2}
                                          >
                                            {stockDetails.igstPer.toFixed(2)}
                                          </td>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                            colSpan={2}
                                          >
                                            {stockDetails.igstAmt.toFixed(2)}
                                          </td>
                                        </>
                                      )}
                                      <td
                                        align={"right"}
                                        className="tdsmallcell"
                                      >
                                        {stockDetails.totalAmt.toFixed(2)}
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                            }
                          )}
                        </>
                      );
                    })}

                    <tr>
                      {/* <td className="tdsmallcell"></td> */}
                      <td
                        align={"right"}
                        className="tdsmallcell"
                        style={{ fontWeight: 600 }}
                        colspan={2}
                      >
                        {"Total QTY"}
                      </td>
                      <td className="tdsmallcell"></td>

                      <td
                        align={"center"}
                        style={{ fontWeight: 500 }}
                        className="tdsmallcell"
                        colspan={1}
                      >
                        {qtyTot.toFixed(2)}
                      </td>
                      <td className="tdsmallcell"></td>

                      {toBillState.id == dataToSetState.id ? (
                        <>
                          <td className="tdsmallcell"></td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 500 }}
                            className="tdsmallcell"
                            colspan={1}
                          ></td>
                          <td className="tdsmallcell"></td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 500 }}
                            className="tdsmallcell"
                            colspan={1}
                          >
                            {""}
                          </td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 500 }}
                            className="tdsmallcell"
                            colspan={1}
                          >
                            {totalTaxableAmt.toFixed(2)}
                          </td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 500 }}
                            className="tdsmallcell"
                            colspan={1}
                          >
                            {""}
                          </td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 500 }}
                            className="tdsmallcell"
                            colspan={1}
                          >
                            {cgstTotalAmt.toFixed(2)}
                          </td>
                          <td className="tdsmallcell"></td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 500 }}
                            className="tdsmallcell"
                            colspan={1}
                          >
                            {sgstTotalAmt.toFixed(2)}
                          </td>
                        </>
                      ) : (
                        <>
                          <td className="tdsmallcell"></td>
                          <td className="tdsmallcell"></td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 500 }}
                            className="tdsmallcell"
                            colspan={1}
                          ></td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 500 }}
                            className="tdsmallcell"
                            colspan={1}
                          >
                            {""}
                          </td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 500 }}
                            className="tdsmallcell"
                            colspan={1}
                          >
                            {totalTaxableAmt.toFixed(2)}
                          </td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 500 }}
                            className="tdsmallcell"
                            colspan={2}
                          >
                            {""}
                          </td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 500 }}
                            className="tdsmallcell"
                            colspan={2}
                          >
                            {igstTotalAmt.toFixed(2)}
                          </td>
                        </>
                      )}
                      <td
                        align={"right"}
                        style={{ fontWeight: 600 }}
                        className="tdsmallcell"
                      >
                        {totalAmt.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td
                        align={"left"}
                        className="tdsmallcell"
                        style={{ fontWeight: 600 }}
                        colspan={11}
                      >
                        {"Invoice Total(In Words) : "}
                        {""}
                        {totalAmt ? NumberToWords(totalAmt.toFixed(2)) : "-"}
                      </td>

                      {/* {toBillState.id == dataToSetState.id ? (
 <>
 <td
 align={"left"}
 style={{ fontWeight: 500 }}
 className="tdsmallcell"
 colspan={3}
 >
 {"Income Tax TCS"}
 </td>
 </>
 ) : (
 <>
 <td
 align={"left"}
 style={{ fontWeight: 500 }}
 className="tdsmallcell"
 colspan={3}
 >
 {"Income Tax TCS"}
 </td>
 </>
 )} */}
                      {/* <td
 align={"right"}
 style={{ fontWeight: 500 }}
 className="tdsmallcell"
 >
 {"0.00"}
 </td> */}
                      {toBillState.id == dataToSetState.id ? (
                        <>
                          <td
                            align={"left"}
                            style={{ fontWeight: 600 }}
                            className="tdsmallcell"
                            colspan={3}
                          >
                            {"Invoice Total"}
                          </td>
                        </>
                      ) : (
                        <>
                          <td
                            align={"left"}
                            style={{ fontWeight: 600 }}
                            className="tdsmallcell"
                            colspan={3}
                          >
                            {"Invoice Total"}
                          </td>
                        </>
                      )}
                      <td
                        align={"right"}
                        style={{ fontWeight: 600 }}
                        className="tdsmallcell"
                      >
                        {totalAmt.toFixed(2)}
                      </td>
                    </tr>

                    <tr>
                      <td
                        align={"left"}
                        className="tdsmallcell"
                        style={{
                          fontWeight: 500,
                          paddingTop: 0,
                          paddingBottom: "0Px",
                        }}
                        colspan={12}
                        rowSpan={1}
                      >
                        <div style={{ display: "inline" }}>
                          <div style={{ display: "inline" }}>
                            {"Payment Term : "}
                            {""}
                            {toBill != null &&
                            toBill.paymentTerm &&
                            toBill.paymentTerm.paymentName
                              ? toBill.paymentTerm.paymentName
                              : "-"}{" "}
                          </div>
                        </div>
                      </td>

                      {toBillState.id == dataToSetState.id ? (
                        <>
                          <td
                            align={"center"}
                            style={{ fontWeight: 600 }}
                            className="tdsmallcell"
                            colspan={6}
                            rowSpan={3}
                          >
                            <div
                              style={{
                                fontWeight: 600,
                                paddingBottom: "10px",
                                textAlign: "center",
                                fontSize: "18px",
                              }}
                            >
                              {dataToSet.fromBillFirmName
                                ? dataToSet.fromBillFirmName
                                : "-"}
                            </div>

                            {toBillState.id == dataToSetState.id ? (
                              <>
                                <div
                                  style={{
                                    fontWeight: 600,
                                    textAlign: "bottom",
                                    marginBottom: 0,
                                  }}
                                >
                                  {"Authorised Signature"}
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  style={{
                                    fontWeight: 600,
                                    textAlign: "bottom",
                                  }}
                                >
                                  {"Authorised Signature"}
                                </div>
                              </>
                            )}
                          </td>
                        </>
                      ) : (
                        <>
                          <>
                            <td
                              align={"center"}
                              style={{ fontWeight: 600 }}
                              className="tdsmallcell"
                              colspan={6}
                              rowSpan={2}
                            >
                              <div
                                style={{
                                  fontWeight: 600,
                                  paddingBottom: "10px",
                                  textAlign: "center",
                                }}
                              >
                                {dataToSet.fromBillFirmName
                                  ? dataToSet.fromBillFirmName
                                  : "-"}
                              </div>

                              <div
                                style={{
                                  fontWeight: 600,
                                  textAlign: "center",
                                  marginBottom: 0,
                                  marginTop: "5px",
                                }}
                              >
                                {"Authorised Signature"}
                              </div>
                            </td>
                          </>
                        </>
                      )}
                    </tr>

                    <tr>
                      <td
                        align={"left"}
                        className="tdsmallcell"
                        style={{
                          fontWeight: 500,
                          paddingTop: 0,
                          paddingBottom: "0Px",
                        }}
                        colspan={12}
                        rowSpan={1}
                      >
                        <div style={{ display: "inline" }}>
                          <div style={{ display: "inline" }}>
                            {"Bank Name : "}
                            {""}
                            {bankDetails && bankDetails.length > 0
                              ? bankDetails[0].bankName
                              : "-"}{" "}
                          </div>
                          &nbsp;&nbsp;&nbsp;,
                          <div style={{ display: "inline" }}>
                            {"Bank Acc No : "}
                            {""}
                            {bankDetails && bankDetails.length > 0
                              ? bankDetails[0].accNo
                              : "-"}
                          </div>
                          &nbsp;&nbsp;&nbsp;,
                          <div style={{ display: "inline" }}>
                            {"IFSC Code : "}
                            {""}
                            {bankDetails && bankDetails.length > 0
                              ? bankDetails[0].ifscCode
                              : "-"}
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td
                        align={"left"}
                        className="tdsmallcell"
                        style={{ fontWeight: 500, borderTop: "1px solid gray" }}
                        colSpan={12}
                      >
                        {
                          <span style={{ textDecoration: "underline" }}>
                            Declaration
                          </span>
                        }
                        <br />
                        {
                          "I/We hereby certify that food/foods mentioned in this invoice is/are warranted to be of the nature and quality which it/these purport to be, Also "
                        }
                        <br />
                        {
                          "We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct."
                        }
                      </td>
                    </tr>
                    <tr>
                      <td
                        align={"left"}
                        className="tdsmallcell"
                        style={{ fontWeight: 500, borderTop: "1px solid gray" }}
                        colSpan={15}
                      >
                        {"Remark : "}
                        {""}
                        {vehicleData.remark ? vehicleData.remark : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td
                        align={"left"}
                        className="tdsmallcell"
                        style={{ fontWeight: 500, borderTop: "1px solid gray" }}
                        colSpan={15}
                      >
                        {
                          "Note : This is system generated PDF, Does not required signature."
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {index + 1 != billList.length && <div className="pagebreak"> </div>}
          </>
        );
      })}
    </>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showNotification,
  showLoador,
};
export default connect(mapStateToProps, mapDispatchToProps)(MyComponent);
