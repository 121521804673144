import endpoint from "../../config/endpoints";

export const billHistoryEditJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.outletOrderDetail,
  formPath: "/billing-history-edit",
  formPathView: "/payment-list",
  screenTitle: "Edit Bill Details",
  showTitle: true,
  showCancel: false,
  showBackToList: false,
  showSaveBtn: false,
};
