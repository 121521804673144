import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { transporterJson } from "../../../DynamicFormsJson/MastersJSON/transporter";
import { getAllTransporterListByUserTypeUpdated } from "../../../Slice/transporter.slice";
import { transporterColumns } from "../../../tableColumns/table-columns";

class TransporterList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { getAllTransporterListByUserTypeUpdated, transporterList } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={transporterJson.formPath}
          screenTitle={transporterJson.screenTitle}
          fieldMeta={transporterJson.fieldMeta}
          showPdfDownload={false}
          isActiveURL={transporterJson.apiBaseURL + "/update-is-active"}
          showExcelDownload={false}
          changeExcelURL={transporterJson.excelUrl}
          showExtension={false}
          pdfFileName={transporterJson.pdfFileName}
          excelFileName={transporterJson.excelFileName}
          showAddButton={transporterJson.showAddButton}
          tableColumnsToFilter={transporterJson.tableColumnsToFilter}
          tableColumns={transporterColumns}
          tableData={transporterList?.transporter}
          getTableData={getAllTransporterListByUserTypeUpdated}
          apiBaseURL={transporterJson.apiBaseURL}
          baseIdColumn={transporterJson.baseIdColumn}
          isNote={true}
          isNoteValue={"Add Transporter Details"}
          //   showDeleteIcon={false}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  transporterList: state.transporter,
});
const mapDispatchToProps = {
  getAllTransporterListByUserTypeUpdated,
};
export default connect(mapStateToProps, mapDispatchToProps)(TransporterList);
