import React from "react";
import { connect } from "react-redux";
import { creditNoteJSON } from "../../DynamicFormsJson/Transaction/creditNote";
import {
  serverMsg,
  noInternetMsg,
  savemsg,
  saveFailedMsg,
  saveWarningMsg,
} from "../../config/messageconstant";
import { Paper, Grid } from "@mui/material";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { getAreaByUserType } from "../../Slice/area.slice";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { getBeatByArea } from "../../Slice/beat.slice";
import RTTable from "../../components/Comman/RT/RTTable";
import { ButtonCompo } from "../../components/Comman/Button";
import endpoint from "../../config/endpoints";
import swal from "sweetalert";
import { apiPost } from "../../utils/api_service";
import { getOutletByBeatId } from "../../Slice/outlet.slice";
import { getCategoryByOutlet } from "../../Slice/category.slice";
import CreditNoteHeaderForm from "./CreditNoteHeaderForm";
import { getProductByCategory } from "../../Slice/product.slice";
import { LabelCompo } from "../../components/Comman/Label";
import { withRouter } from "../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import { DarkBlue } from "../../config/ColorObj";
class creditNoteForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowList: [],
      formErrors: {},
      fieldData: { calStock: "1" },
      dynamicMasterData: {
        areaDetail: this.props.areaList.area,
        beatDetail: this.props.beatList.beat,
        outletDetail: this.props.outletList.outlets,
        categoryDetail: this.props.categoryList.categoryByOutlet,
        productDetail: this.props.productList.productByCategory,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.area !== nextProps.areaList?.area) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.area,
          },
        });
      }
    }

    if (this.props.beatList !== nextProps.beatList) {
      if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            beatDetail: nextProps.beatList?.beat,
          },
        });
      }
    }

    if (this.props.outletList !== nextProps.outletList) {
      if (this.props.outletList?.outlets !== nextProps.outletList?.outlets) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            outletDetail: nextProps.outletList?.outlets,
          },
        });
      }
    }
    if (
      this.props.categoryList.categoryByOutlet !==
      nextProps.categoryList.categoryByOutlet
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          categoryDetail: nextProps.categoryList.categoryByOutlet,
        },
      });
    }
    if (this.props.productList !== nextProps.productList) {
      if (
        this.props.productList?.productByCategory !==
        nextProps.productList?.productByCategory
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            productDetail: nextProps.productList?.productByCategory,
          },
        });
      }
    }
  }

  getListById = async (data) => {
    const {
      getBeatByArea,
      getCategoryByOutlet,
      getProductByCategory,
      getOutletByBeatId,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (data.area) {
        showLoador({ loador: true });
        await getBeatByArea({ areaId: data.area ? data.area : 0 }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.beat) {
        showLoador({ loador: true });
        await getOutletByBeatId({
          beatId: data.beat.id,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        });
      }
      if (data.outlet && data.outlet.id) {
        showLoador({ loador: true });
        await getCategoryByOutlet({
          outletId: data.outlet.id,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        });
      }
      if (data.category) {
        showLoador({ loador: true });
        await getProductByCategory({ categoryId: data.category }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  async componentDidMount() {
    this.setState({
      rowList: [],
    });
    const { getAreaByUserType, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getAreaByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  }

  onSearch = (data) => {
    console.log(data);
    const { rowList, dynamicMasterData } = this.state;

    let productList = dynamicMasterData["productDetail"]
      ? dynamicMasterData["productDetail"].filter(
          (row) => row.id == data.product
        )
      : [];
    let catList = dynamicMasterData["categoryDetail"]
      ? dynamicMasterData["categoryDetail"].filter(
          (row) => row.id == data.category
        )
      : [];
    this.setState({
      rowList: [
        ...rowList,
        {
          index: rowList.length + 1,
          categoryName: catList.length != 0 ? catList[0].name : "-",
          productName: productList.length != 0 ? productList[0].name : "-",
          uom: productList.length != 0 ? productList[0].outletUomName : "-",
          calStock: data.calStock,
          ...data,

          total: (data.rate * +data.qty).toFixed(2),
        },
      ],
    });
  };

  onSubmitData = () => {
    if (this.checkValidationOnSubmit()) {
      const { rowList, fieldData } = this.state;
      if (rowList.length != 0) {
        const creditNoteDetail = rowList.map((rowData) => {
          const rowObj = {
            product: {
              id: rowData.product,
            },

            qty: rowData.qty,
            hsnCode: "0",
            rate: rowData.rate,
            billDetailId: "0",
            batchCode: rowData.batch,
          };

          return rowObj;
        });
        const dataToSave = {
          outLet: fieldData.outlet,
          remark: "NA",
          instockOutstock: fieldData.calStock,
          creditNoteDetail: creditNoteDetail,
          date: fieldData.date.format("DD-MM-YYYY"),
        };
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            this.onSave(dataToSave);
          }
        });
      } else {
        this.props.showNotification({
          msg: "Please add atleast one product",
          severity: "error",
        });
      }
    }
  };
  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.creditNote,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          this.props.navigate(-1);
          showNotification({ msg: savemsg });
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  mandatoryCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    creditNoteJSON.fieldMeta.forEach((currentField) => {
      if (currentField.isMandatory) {
        if (
          (currentField.controlType === "datepicker" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === "")) ||
          (currentField.controlType !== "datepicker" &&
            currentField.controlType !== "autocomplete" &&
            (fieldData[currentField.dataKey] === undefined ||
              fieldData[currentField.dataKey] === null ||
              fieldData[currentField.dataKey].toString().trim() === ""))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        } else if (
          currentField.controlType === "autocomplete" &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey] === "" ||
            (!currentField.isRootLevelKey &&
              fieldData[currentField.dataKey] != undefined &&
              fieldData[currentField.dataKey] != null &&
              fieldData[currentField.dataKey].id == null) ||
            fieldData[currentField.dataKey].id == "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideTextfield") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.error} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideAutocomplete") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            fieldData[currentField.dataKey].toString().trim() === "")
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      } else if (currentField.controlType === "hideMultiSelect") {
        if (
          fieldData[currentField.tableDataKey] === currentField.open &&
          (fieldData[currentField.dataKey] === undefined ||
            fieldData[currentField.dataKey] === null ||
            (fieldData[currentField.dataKey] &&
              fieldData[currentField.dataKey].length == 0))
        ) {
          formValid = false;

          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `*${currentField.label} is required`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  ValidationCheck = () => {
    let formValid = true;
    const { fieldData } = this.state;

    let formErrors = {};
    creditNoteJSON.fieldMeta.forEach((currentField) => {
      if (currentField.valid) {
        if (
          currentField.validName === "email" &&
          !validEmail.test(fieldData[currentField.dataKey])
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
        if (
          currentField.validName === "zero" &&
          fieldData[currentField.dataKey] === 0
        ) {
          formValid = false;
          formErrors = {
            ...formErrors,
            [currentField.dataKey]: {
              isError: true,
              errorText: `${currentField.label} is Invalid`,
            },
          };
        }
      }
    });
    this.setState({
      formErrors: formErrors,
    });
    return formValid;
  };
  checkValidationOnSubmit = () => {
    if (this.mandatoryCheck()) {
      return this.ValidationCheck();
    } else {
      return false;
    }
  };
  onDataChange = (fieldName, newValue) => {
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    creditNoteJSON.fieldMeta.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        // dataToSearch[fieldName] = newValue;
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };

  getDataForDisabled = (value) => {
    const { dynamicMasterData } = this.state;

    let productList = dynamicMasterData["productDetail"]
      ? dynamicMasterData["productDetail"].filter((row) => row.id == value)
      : [];
    return { rate: productList[0].rate, uom: productList[0].outletUomName };
  };
  render() {
    const { dynamicMasterData, rowList, formErrors, fieldData } = this.state;

    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <CreditNoteHeaderForm
            getListById={this.getListById}
            dynamicMasterData={dynamicMasterData}
            screenTitle={creditNoteJSON.screenTitle}
            fieldMeta={creditNoteJSON.fieldMeta}
            apiBaseURL={creditNoteJSON.apiBaseURL}
            formErrors={formErrors}
            showSaveBtn={creditNoteJSON.showSaveBtn}
            showSaveBtnMain={false}
            fieldData={fieldData}
            onDataChange={this.onDataChange}
          />
          <LabelCompo
            className="text-black"
            style={{ fontSize: "20px", color: DarkBlue }}
            label={"Add Product"}
          />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            sx={{ paddingTop: 2 }}
          >
            <DynamicFormWithoutSave
              showSaveBtn={true}
              showSaveBtnMain={false}
              dynamicMasterData={dynamicMasterData}
              getListById={this.getListById}
              searchList={creditNoteJSON.searchList}
              showTitle={creditNoteJSON.showTitle}
              screenTitle={creditNoteJSON.screenTitle}
              fieldMeta={creditNoteJSON.fieldMetaDetails}
              showCancel={creditNoteJSON.showCancel}
              showBackToList={creditNoteJSON.showBackToList}
              apiBaseURL={creditNoteJSON.apiBaseURL}
              showSaveNextBtn={creditNoteJSON.showSaveNextBtn}
              saveBtnText={"ADD"}
              onSave={this.onSearch}
              resetAfterSave={true}
              getDataForDisabled={this.getDataForDisabled}
            />
          </Grid>

          <br />
          <RTTable
            showPegination={false}
            columns={[
              { name: "index", title: "Sr. No.", align: "center" },
              { name: "categoryName", title: "Category" },
              { name: "productName", title: "Product" },
              { name: "uom", title: "UOM", align: "center" },
              { name: "calStock", title: "Add To Scrap", align: "left" },
              { name: "batch", title: "Batch", align: "left" },
              { name: "qty", title: "Quantity", align: "right" },
              { name: "rate", title: "Rate", align: "right" },
              { name: "total", title: "Total", align: "right" },
            ]}
            hiddenColumnNames={[]}
            tableData={rowList}
            isActionColActive={false}
          />
          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="right"
          >
            <ButtonCompo
              size="medium"
              type="Submit"
              variant="contained"
              name="Cancel"
              onClick={() => {
                this.props.navigate(-1);
              }}
            />
            &nbsp;&nbsp;
            <ButtonCompo
              size="medium"
              type="Submit"
              variant="contained"
              name="Submit"
              onClick={this.onSubmitData}
            />
          </Grid>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  areaList: state.area,
  beatList: state.beat,
  outletList: state.outlet,
  categoryList: state.category,
  productList: state.product,
});
const mapDispatchToProps = {
  getAreaByUserType,
  getBeatByArea,
  getOutletByBeatId,
  showLoador,
  showNotification,
  getCategoryByOutlet,
  getProductByCategory,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(creditNoteForm);
