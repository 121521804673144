import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  transporter: [],
};
let URL = endpoints.transporter;
const transporterSlice = createSlice({
  name: "transporter",
  initialState,
  reducers: {
    transporterSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        transporter: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { transporterSuccess, resetState } = transporterSlice.actions;

export default transporterSlice.reducer;

export const getTransporterList = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertDateTime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((transporterObject, index) => {
          let transporterData = {
            index: index + 1,
            id: transporterObject.id === null ? "" : transporterObject.id,
            name: transporterObject.name === null ? "" : transporterObject.name,
            region:
              transporterObject.region === null
                ? ""
                : transporterObject.region.id,
            regionName:
              transporterObject.region === null
                ? ""
                : transporterObject.region.name,
            address:
              transporterObject.address === null
                ? ""
                : transporterObject.address,
            pincode:
              transporterObject.pincode === null
                ? ""
                : transporterObject.pincode,
            spocName:
              transporterObject.spocName === null
                ? ""
                : transporterObject.spocName,
            spocNo:
              transporterObject.spocNo === null ? "" : transporterObject.spocNo,
            transporterFor:
              transporterObject.transporterFor === null
                ? ""
                : transporterObject.transporterFor,
            transporterForData:
              transporterObject.transporterFor === null
                ? ""
                : transporterObject.transporterFor == 0
                ? "Admin"
                : "Distributor",
            gstNo:
              transporterObject.gstNo === null ? "" : transporterObject.gstNo,
            sortOrder:
              transporterObject.sortOrder === null
                ? ""
                : transporterObject.sortOrder,
            isActive:
              transporterObject.isActive === null
                ? ""
                : transporterObject.isActive,
            districtIds:
              transporterObject.districtIds === null
                ? ""
                : transporterObject.districtIds,
            zoneIds:
              transporterObject.zoneIds === null
                ? ""
                : transporterObject.zoneIds,
            stateIds:
              transporterObject.stateIds === null
                ? ""
                : transporterObject.stateIds,
            area:
              transporterObject.area === null ? "" : transporterObject.area.id,
            stockStakeHolderType:
              transporterObject.stockStakeHolderType === null
                ? ""
                : transporterObject.stockStakeHolderType.id,

            stockStakeHolder:
              transporterObject.stockStakeHolder === null
                ? ""
                : transporterObject.stockStakeHolder.id,
            talukaIds:
              transporterObject.talukaIds === null
                ? ""
                : transporterObject.talukaIds,
          };
          return transporterData;
        });
        dispatch(transporterSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const setTransporter =
  ({ row }) =>
  async (dispatch) => {
    dispatch(transporterSuccess({ row }));
  };
export const getActiveTransporterListByUserType = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/active-by-user-type" + '?sort={"insertDateTime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((transporterObject, index) => {
          let transporterData = {
            index: index + 1,
            id: transporterObject.id === null ? "" : transporterObject.id,
            name: transporterObject.name === null ? "" : transporterObject.name,
            region:
              transporterObject.region === null
                ? ""
                : transporterObject.region.id,
            regionName:
              transporterObject.region === null
                ? ""
                : transporterObject.region.name,
            address:
              transporterObject.address === null
                ? ""
                : transporterObject.address,
            pincode:
              transporterObject.pincode === null
                ? ""
                : transporterObject.pincode,
            spocName:
              transporterObject.spocName === null
                ? ""
                : transporterObject.spocName,
            spocNo:
              transporterObject.spocNo === null ? "" : transporterObject.spocNo,
            transporterFor:
              transporterObject.transporterFor === null
                ? ""
                : transporterObject.transporterFor,
            transporterForData:
              transporterObject.transporterFor === null
                ? ""
                : transporterObject.transporterFor == 0
                ? "Admin"
                : "Distributor",
            gstNo:
              transporterObject.gstNo === null ? "" : transporterObject.gstNo,
            sortOrder:
              transporterObject.sortOrder === null
                ? ""
                : transporterObject.sortOrder,
            isActive:
              transporterObject.isActive === null
                ? ""
                : transporterObject.isActive,
            districtIds:
              transporterObject.districtIds === null
                ? ""
                : transporterObject.districtIds,
            zoneIds:
              transporterObject.zoneIds === null
                ? ""
                : transporterObject.zoneIds,
            stateIds:
              transporterObject.stateIds === null
                ? ""
                : transporterObject.stateIds,
            area:
              transporterObject.area === null ? "" : transporterObject.area.id,
            stockStakeHolderType:
              transporterObject.stockStakeHolderType === null
                ? ""
                : transporterObject.stockStakeHolderType.id,

            stockStakeHolder:
              transporterObject.stockStakeHolder === null
                ? ""
                : transporterObject.stockStakeHolder.id,
            talukaIds:
              transporterObject.talukaIds === null
                ? ""
                : transporterObject.talukaIds,
          };
          return transporterData;
        });
        dispatch(transporterSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getActiveTransporterListByUserTypeUpdated = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/list-active-by-user-type" + '?sort={"insertDateTime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((transporterObject, index) => {
          let transporterData = {
            index: index + 1,
            id: transporterObject.id === null ? "" : transporterObject.id,
            name: transporterObject.name === null ? "" : transporterObject.name,
            region:
              transporterObject.regionId === null
                ? ""
                : transporterObject.regionId,
            regionName:
              transporterObject.regionName === null
                ? ""
                : transporterObject.regionName,
            address:
              transporterObject.address === null
                ? ""
                : transporterObject.address,
            pincode:
              transporterObject.pincode === null
                ? ""
                : transporterObject.pincode,
            spocName:
              transporterObject.spocName === null
                ? ""
                : transporterObject.spocName,
            spocNo:
              transporterObject.spocNo === null ? "" : transporterObject.spocNo,
            transporterFor:
              transporterObject.transporterFor === null
                ? ""
                : transporterObject.transporterFor,
            transporterForData:
              transporterObject.transporterFor === null
                ? ""
                : transporterObject.transporterFor == 0
                ? "Admin"
                : "Distributor",
            gstNo:
              transporterObject.gstNo === null ? "" : transporterObject.gstNo,
            sortOrder:
              transporterObject.sortOrder === null
                ? ""
                : transporterObject.sortOrder,
            isActive:
              transporterObject.isActive === null
                ? ""
                : transporterObject.isActive,
            districtIds:
              transporterObject.districtIds === null
                ? ""
                : transporterObject.districtIds,
            zoneIds:
              transporterObject.zoneIds === null
                ? ""
                : transporterObject.zoneIds,
            stateIds:
              transporterObject.stateIds === null
                ? ""
                : transporterObject.stateIds,
            area:
              transporterObject.areaId === null ? "" : transporterObject.areaId,
            stockStakeHolderType:
              transporterObject.stockStakeHolderType === null
                ? ""
                : transporterObject.stockStakeHolderType.id,

            stockStakeHolder:
              transporterObject.stockStakeHolderId === null
                ? ""
                : transporterObject.stockStakeHolderId,
            talukaIds:
              transporterObject.talukaIds === null
                ? ""
                : transporterObject.talukaIds,
          };
          return transporterData;
        });
        dispatch(transporterSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAllTransporterListByUserType = () => async (dispatch) => {
  try {
    const response = apiGet({
      url:
        URL + "/transporter-by-user-type" + '?sort={"insertDateTime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((transporterObject, index) => {
          let transporterData = {
            index: index + 1,
            id: transporterObject.id === null ? "" : transporterObject.id,
            name: transporterObject.name === null ? "" : transporterObject.name,
            region:
              transporterObject.region === null
                ? ""
                : transporterObject.region.id,
            regionName:
              transporterObject.region === null
                ? ""
                : transporterObject.region.name,
            address:
              transporterObject.address === null
                ? ""
                : transporterObject.address,
            pincode:
              transporterObject.pincode === null
                ? ""
                : transporterObject.pincode,
            spocName:
              transporterObject.spocName === null
                ? ""
                : transporterObject.spocName,
            spocNo:
              transporterObject.spocNo === null ? "" : transporterObject.spocNo,
            transporterFor:
              transporterObject.transporterFor === null
                ? ""
                : transporterObject.transporterFor,
            transporterForData:
              transporterObject.transporterFor === null
                ? ""
                : transporterObject.transporterFor == 0
                ? "Admin"
                : "Distributor",
            gstNo:
              transporterObject.gstNo === null ? "" : transporterObject.gstNo,
            sortOrder:
              transporterObject.sortOrder === null
                ? ""
                : transporterObject.sortOrder,
            isActive:
              transporterObject.isActive === null
                ? ""
                : transporterObject.isActive,
            districtIds:
              transporterObject.districtIds === null
                ? ""
                : transporterObject.districtIds,
            zoneIds:
              transporterObject.zoneIds === null
                ? ""
                : transporterObject.zoneIds,
            stateIds:
              transporterObject.stateIds === null
                ? ""
                : transporterObject.stateIds,
            area:
              transporterObject.area === null ? "" : transporterObject.area.id,
            stockStakeHolderType:
              transporterObject.stockStakeHolderType === null
                ? ""
                : transporterObject.stockStakeHolderType.id,

            stockStakeHolder:
              transporterObject.stockStakeHolder === null
                ? ""
                : transporterObject.stockStakeHolder.id,
            talukaIds:
              transporterObject.talukaIds === null
                ? ""
                : transporterObject.talukaIds,
          };
          return transporterData;
        });
        dispatch(transporterSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAllTransporterListByUserTypeUpdated = () => async (dispatch) => {
  try {
    const response = apiGet({
      url:
        URL + "/list-by-user-type" + '?sort={"insertDateTime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((transporterObject, index) => {
          let transporterData = {
            index: index + 1,
            id: transporterObject.id === null ? "" : transporterObject.id,
            name: transporterObject.name === null ? "" : transporterObject.name,
            region:
              transporterObject.regionId === null
                ? ""
                : transporterObject.regionId,
            regionName:
              transporterObject.regionName === null
                ? ""
                : transporterObject.regionName,
            address:
              transporterObject.address === null
                ? ""
                : transporterObject.address,
            pincode:
              transporterObject.pincode === null
                ? ""
                : transporterObject.pincode,
            spocName:
              transporterObject.spocName === null
                ? ""
                : transporterObject.spocName,
            spocNo:
              transporterObject.spocNo === null ? "" : transporterObject.spocNo,
            transporterFor:
              transporterObject.transporterFor === null
                ? ""
                : transporterObject.transporterFor,
            transporterForData:
              transporterObject.transporterFor === null
                ? ""
                : transporterObject.transporterFor == 0
                ? "Admin"
                : "Distributor",
            gstNo:
              transporterObject.gstNo === null ? "" : transporterObject.gstNo,
            sortOrder:
              transporterObject.sortOrder === null
                ? ""
                : transporterObject.sortOrder,
            isActive:
              transporterObject.isActive === null
                ? ""
                : transporterObject.isActive,
            districtIds:
              transporterObject.districtIds === null
                ? ""
                : transporterObject.districtIds,
            zoneIds:
              transporterObject.zoneIds === null
                ? ""
                : transporterObject.zoneIds,
            stateIds:
              transporterObject.stateIds === null
                ? ""
                : transporterObject.stateIds,
            area:
              transporterObject.areaId === null ? "" : transporterObject.areaId,
            stockStakeHolderType:
              transporterObject.stockStakeHolderType === null
                ? ""
                : transporterObject.stockStakeHolderType.id,

            stockStakeHolder:
              transporterObject.stockStakeHolderId === null
                ? ""
                : transporterObject.stockStakeHolderId,

            talukaIds:
              transporterObject.talukaIds === null
                ? ""
                : transporterObject.talukaIds,
          };
          return transporterData;
        });
        dispatch(transporterSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

