import FormControl from '@mui/material/FormControl';
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import * as React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



export default function MultipleSelect({ chipCompo = false, er = '', disabled = false, label, name1, value1, names, onChange }) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    onChange(value, name1)

  };

  return (
    <>
      {
        chipCompo ?
          <>
            <FormControl color={"primary"} fullWidth={true}>
              <Select
                // labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                displayEmpty
                variant="standard"
                value={value1}
                name={name1}
                disabled={disabled}
                className="text-black"
                onChange={handleChange}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>{label}</em>;
                  }
                  return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>

                    {
                      selected.map((selectData) => {
                        const filterData = names.filter((item) => item.id === selectData)
                        if (filterData.length != 0) {
                          return <Chip key={filterData[0].name} label={filterData[0].name} />
                        }
                        return ""
                      })
                    }
                  </Box>
                }}
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
                size="small"
                {...(er !== '' && { error: true })}
              >
                {names.map((name) => (
                  <MenuItem
                    key={name.id}
                    value={name.id}
                  >
                    {name.name}
                  </MenuItem>
                ))}

              </Select>

              <FormHelperText error>
                {er !== '' ? er : ' '}
              </FormHelperText>

            </FormControl>
          </> :
          <>
            <FormControl color={"primary"} fullWidth={true}>
              <Select
                // labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                displayEmpty
                variant="standard"
                value={value1}
                name={name1}
                disabled={disabled}
                className="text-black"
                onChange={handleChange}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>{label}</em>;
                  }
                  return selected.map((selectData) => {
                    const filterData = names.filter((item) => item.id === selectData)
                    if (filterData.length != 0) {
                      return filterData[0].name
                    }
                    return ""
                  }).join(', ');
                }}
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
                size="small"
                {...(er !== '' && { error: true })}
              >
                {names.map((name) => (
                  <MenuItem
                    key={name.id}
                    value={name.id}
                  >
                    {name.name}
                  </MenuItem>
                ))}

              </Select>

              <FormHelperText error>
                {er !== '' ? er : ' '}
              </FormHelperText>

            </FormControl>
          </>
      }
    </>

  );
}



// import * as React from 'react';
// import { useTheme } from '@mui/material/styles';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

// const names = [
//   'Oliver Hansen',
//   'Van Henry',
//   'April Tucker',
//   'Ralph Hubbard',
//   'Omar Alexander',
//   'Carlos Abbott',
//   'Miriam Wagner',
//   'Bradley Wilkerson',
//   'Virginia Andrews',
//   'Kelly Snyder',
// ];

// function getStyles(name, personName, theme) {
//   return {
//     fontWeight:
//       personName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

// export default function MultipleSelectPlaceholder() {
//   const theme = useTheme();
//   const [personName, setPersonName] = React.useState([]);

//   const handleChange = (event) => {
//     const {
//       target: { value },
//     } = event;
//     setPersonName(
//       // On autofill we get a stringified value.
//       typeof value === 'string' ? value.split(',') : value,
//     );
//   };

//   return (
//     <div>
//       <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
//         <Select
//           multiple
//           displayEmpty
//           value={personName}
//           onChange={handleChange}
//           variant="standard"
//           renderValue={(selected) => {
//             if (selected.length === 0) {
//               return <em>Placeholder</em>;
//             }

//             return selected.join(', ');
//           }}
//           MenuProps={MenuProps}
//           inputProps={{ 'aria-label': 'Without label' }}
//         >
//           {names.map((name) => (
//             <MenuItem
//               key={name}
//               value={name}
//               style={getStyles(name, personName, theme)}
//             >
//               {name}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>
//     </div>
//   );
// }
