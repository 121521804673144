import endpoint from "../../config/endpoints";

export const ShowOrderListJson = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.stockStakeholderOrderHeader,
  screenTitle: "Order List",
  formPathView: "/order-list-view",
  // generateExcel: apiBaseURL + "/order-history-excel-by-date?fromDate=&toDate=",
  searchList: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromdate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "todate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "todate",
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
    },
    // {
    //   label: "Stock Holder",
    //   controlType: "multiSelect",
    //   placeHolder: "Select Stock Holder",
    //   md: 12,
    //   lg: 12,
    //   sm: 12,
    //   xs: 12,
    //   labelSM: 2,
    //   labelMD: 2,
    //   labelLG: 2,
    //   valueSM: 10,
    //   valueMD: 10,
    //   valueLG: 10,
    //   masterName: "stakeholdersDetail",
    //   dataKey: "stackholder",
    //   isRootLevelKey: true,
    //   isMandatory: true,
    // },
  ],
};
