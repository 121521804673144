import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { yearJson } from "../../../DynamicFormsJson/MastersJSON/year";
import { getYear } from "../../../Slice/year.slice";
import { apiGet } from "../../../utils/api_service";
import { showLoador, showNotification } from "../../Landing/Landing.slice";

class YearFrom extends React.Component {
  constructor(props) {
    super(props);
  }
  callBeforeSaveMethod = async (dataToSave, editData) => {
    if (
      Object.keys(editData).length == 0 ||
      (Object.keys(editData).length != 0 &&
        editData.fromDate != dataToSave.fromDate)
    ) {
      let typeData = Object.keys(editData).length == 0 ? "0" : "1";
      const { showLoador, showNotification } = this.props;
      const fromDateArr = dataToSave.fromDate.split("-");
      const toDateArr = dataToSave.toDate.split("-");
      if (fromDateArr[1].length < 2) fromDateArr[1] = "0" + fromDateArr[1];
      if (fromDateArr[0].length < 2) fromDateArr[0] = "0" + fromDateArr[0];
      if (toDateArr[1].length < 2) toDateArr[1] = "0" + toDateArr[1];
      if (toDateArr[0].length < 2) toDateArr[0] = "0" + toDateArr[0];
      const newFromDate = [fromDateArr[0], fromDateArr[1], fromDateArr[2]].join(
        "-"
      );
      const newToDate = [toDateArr[0], toDateArr[1], toDateArr[2]].join("-");
      if (navigator.onLine) {
        showLoador({ loador: true });
        const response = await apiGet({
          url:
            yearJson.apiBaseURL +
            "/exist-or-not?fromDate=" +
            newFromDate +
            "&toDate=" +
            newToDate +
            "&type=" +
            typeData,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            if (data.data.error) {
              showNotification({
                msg: data.data.message,
                severity: "error",
              });
            }
            return !data.data.error;
          }
          return false;
        });
        return response;
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
    return true;
  };
  render() {
    return (
      <>
        <DynamicForm
          callBeforeSave={true}
          callBeforeSaveMethod={this.callBeforeSaveMethod}
          formPath={yearJson.formPath}
          screenTitle={yearJson.screenTitle}
          fieldMeta={yearJson.fieldMeta}
          apiBaseURL={yearJson.apiBaseURL}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  yearList: state.year,
});
const mapDispatchToProps = {
  getYear,
  showLoador,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(YearFrom);
