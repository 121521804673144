import endpoint from "../../config/endpoints";

export const MRPConfigrationJson = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.room,
  formPath: "/mrp-configuration-form",
  screenTitle: "MRP Configuration",
  showTitle: true,
  showCancel: false,
  showSaveBtn: false,
  fieldMeta: [
    {
      label: "Category",
      controlType: "autocomplete",
      placeHolder: "Select Category",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "categoryDetail",
      getListId: "category",
      dataKey: "category",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Sub-Category",
      controlType: "multiSelect",
      placeHolder: "Select Sub-Category",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "subCategoryDetail",
      getListFrom: "category",
      dataKey: "subCategory",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "MRP",
      controlType: "textfield",
      placeHolder: "MRP",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "value",
      isMandatory: true,
    },
    {
      label: "Type",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "type",
      tableDataKey: "type",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "By MRP",
          value: "1",
        },
        {
          label: "Percentage",
          value: "0",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Add/Substract",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "addSub",
      tableDataKey: "addSubs",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Addition",
          value: "1",
        },
        {
          label: "Substraction",
          value: "0",
        },
      ],
      isMandatory: true,
    },
  ],
  fieldMetaForSearch: [
    {
      label: "MRP",
      controlType: "textfield",
      placeHolder: "Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "name",
      isMandatory: true,
    },
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 5.5,
      lg: 5.5,
      sm: 5.5,
      xs: 12,
      dataKey: "date",
      isMandatory: true,
    },
  ],
};
