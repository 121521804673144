import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  orderHistoryById: [],
  placeOrderByDate: [],
  orderList: [],
  orderListFromStakeholderAndDate: [],
  placeOrderList: {},
  orderProductList: [],
  primaryOrder: {},
  primaryLatestOrder: [],
};
let URL = endpoints.stockStakeholderOrderHeader;
const orderHeaderListSlice = createSlice({
  name: "orderHeaderList",
  initialState,
  reducers: {
    placeOrderSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        placeOrderByDate: row,
      };
    },
    orderListFromStakeholderAndDateSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        orderListFromStakeholderAndDate: row,
      };
    },
    orderHeaderByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        placeOrderList: row,
      };
    },
    orderHeaderByIdForStakeholderSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        orderProductList: row,
      };
    },
    orderListSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        orderList: row,
        isFetch: true,
      };
    },
    primarySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        primaryOrder: row,
      };
    },
    primaryLatestOrderSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        primaryLatestOrder: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});
export const {
  orderHistorySuccess,
  placeOrderSuccess,
  orderListSuccess,
  orderHeaderByIdSuccess,
  orderListFromStakeholderAndDateSuccess,
  orderHeaderByIdForStakeholderSuccess,
  resetState,
  primarySuccess,
  primaryLatestOrderSuccess,
} = orderHeaderListSlice.actions;
export default orderHeaderListSlice.reducer;
export const getPhase3OrderList = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/received-order",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((orderHistoryObject, index) => {
          let orderHistoryData = {
            index: index + 1,
            id: orderHistoryObject.id === null ? "" : orderHistoryObject.id,
            outLet:
              orderHistoryObject.outLet === null
                ? ""
                : orderHistoryObject.outLet,
            orderNo:
              orderHistoryObject.orderNo === null
                ? ""
                : orderHistoryObject.orderNo,
            orderDate:
              orderHistoryObject.orderDate === null
                ? ""
                : orderHistoryObject.orderDate,
            cgstAmt:
              orderHistoryObject.cgstAmt === null
                ? ""
                : +orderHistoryObject.cgstAmt.toFixed(2),
            sgstAmt:
              orderHistoryObject.sgstAmt === null
                ? ""
                : +orderHistoryObject.sgstAmt.toFixed(2),
            taxableAmt:
              orderHistoryObject.taxableAmt === null
                ? ""
                : +orderHistoryObject.taxableAmt.toFixed(2),
            totalAmt:
              orderHistoryObject.totalAmt === null
                ? ""
                : +orderHistoryObject.totalAmt.toFixed(2),
            distcountAmt:
              orderHistoryObject.distcountAmt === null
                ? ""
                : +orderHistoryObject.distcountAmt.toFixed(2),

            igstAmt:
              orderHistoryObject.igstAmt === null
                ? ""
                : +orderHistoryObject.igstAmt.toFixed(2),

            stockStakeHolder:
              orderHistoryObject.stockStakeHolder === null
                ? ""
                : orderHistoryObject.stockStakeHolder,
            type:
              orderHistoryObject.type === null ? "" : orderHistoryObject.type,
            insertedUserId:
              orderHistoryObject.insertedUserId === null
                ? ""
                : orderHistoryObject.insertedUserId,
            insertDateTime:
              orderHistoryObject.insertDateTime === null
                ? ""
                : orderHistoryObject.insertDateTime,
          };

          return orderHistoryData;
        });
        dispatch(orderHistorySuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getOrdersFromStakeholderIdAndDate =
  ({ stakeholderId, orderDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/for-batch-billing?orderDate=" +
          orderDate +
          "&holderId=" +
          stakeholderId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((orderHistoryObject, index) => {
            let orderHistoryData = {
              index: index + 1,
              cgstAmt:
                orderHistoryObject.cgstAmt == null
                  ? ""
                  : +orderHistoryObject.cgstAmt.toFixed(2),
              discountOnbillAmt:
                orderHistoryObject.discountOnbillAmt == null
                  ? ""
                  : +orderHistoryObject.discountOnbillAmt.toFixed(2),
              discountOnbillPer:
                orderHistoryObject.discountOnbillPer == null
                  ? ""
                  : +orderHistoryObject.discountOnbillPer.toFixed(2),
              discountOnbillPerAmt:
                orderHistoryObject.discountOnbillPerAmt == null
                  ? ""
                  : +orderHistoryObject.discountOnbillPerAmt.toFixed(2),
              fromOrder:
                orderHistoryObject.fromOrder == null
                  ? ""
                  : orderHistoryObject.fromOrder,
              fromOrderFirmName:
                orderHistoryObject.fromOrderFirmName == null
                  ? ""
                  : orderHistoryObject.fromOrderFirmName,
              fromOrderOwnerName:
                orderHistoryObject.fromOrderOwnerName == null
                  ? ""
                  : orderHistoryObject.fromOrderOwnerName,
              id: orderHistoryObject.id == null ? "" : orderHistoryObject.id,
              igstAmt:
                orderHistoryObject.igstAmt == null
                  ? ""
                  : +orderHistoryObject.igstAmt.toFixed(2),
              insertedUserId:
                orderHistoryObject.insertedUserId == null
                  ? ""
                  : orderHistoryObject.insertedUserId,
              itemDiscountAmt:
                orderHistoryObject.itemDiscountAmt == null
                  ? ""
                  : +orderHistoryObject.itemDiscountAmt.toFixed(2),
              orderDate:
                orderHistoryObject.orderDate == null
                  ? ""
                  : orderHistoryObject.orderDate,
              orderNo:
                orderHistoryObject.orderNo == null
                  ? ""
                  : orderHistoryObject.orderNo,
              name:
                orderHistoryObject.orderNo == null
                  ? ""
                  : orderHistoryObject.orderNo,
              sgstAmt:
                orderHistoryObject.sgstAmt == null
                  ? ""
                  : +orderHistoryObject.sgstAmt.toFixed(2),
              status:
                orderHistoryObject.status == null
                  ? ""
                  : +orderHistoryObject.status.toFixed(2),
              taxableAmt:
                orderHistoryObject.taxableAmt == null
                  ? ""
                  : +orderHistoryObject.taxableAmt.toFixed(2),
              toOrder:
                orderHistoryObject.toOrder == null
                  ? ""
                  : orderHistoryObject.toOrder,
              toOrderFirmName:
                orderHistoryObject.toOrderFirmName == null
                  ? ""
                  : orderHistoryObject.toOrderFirmName,
              toOrderOwnerName:
                orderHistoryObject.toOrderOwnerName == null
                  ? ""
                  : orderHistoryObject.toOrderOwnerName,
              totalAmt:
                orderHistoryObject.totalAmt == null
                  ? ""
                  : +orderHistoryObject.totalAmt.toFixed(2),
              type:
                orderHistoryObject.type == null ? "" : orderHistoryObject.type,
            };
            return orderHistoryData;
          });
          dispatch(orderListFromStakeholderAndDateSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getOrdersFromStakeholderIdAndDateNew =
  ({ stakeholderId, orderDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-for-batch-billing?orderDate=" +
          orderDate +
          "&holderId=" +
          stakeholderId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((orderHistoryObject, index) => {
            let orderHistoryData = {
              index: index + 1,
              cgstAmt:
                orderHistoryObject.cgstAmt == null
                  ? ""
                  : +orderHistoryObject.cgstAmt.toFixed(2),
              discountOnbillAmt:
                orderHistoryObject.discountOnbillAmt == null
                  ? ""
                  : +orderHistoryObject.discountOnbillAmt.toFixed(2),
              discountOnbillPer:
                orderHistoryObject.discountOnbillPer == null
                  ? ""
                  : +orderHistoryObject.discountOnbillPer.toFixed(2),
              discountOnbillPerAmt:
                orderHistoryObject.discountOnbillPerAmt == null
                  ? ""
                  : +orderHistoryObject.discountOnbillPerAmt.toFixed(2),
              fromOrder:
                orderHistoryObject.fromOrder == null
                  ? ""
                  : orderHistoryObject.fromOrder,
              fromOrderFirmName:
                orderHistoryObject.fromOrderFirmName == null
                  ? ""
                  : orderHistoryObject.fromOrderFirmName,
              fromOrderOwnerName:
                orderHistoryObject.fromOrderOwnerName == null
                  ? ""
                  : orderHistoryObject.fromOrderOwnerName,
              id: orderHistoryObject.id == null ? "" : orderHistoryObject.id,
              igstAmt:
                orderHistoryObject.igstAmt == null
                  ? ""
                  : +orderHistoryObject.igstAmt.toFixed(2),
              insertedUserId:
                orderHistoryObject.insertedUserId == null
                  ? ""
                  : orderHistoryObject.insertedUserId,
              itemDiscountAmt:
                orderHistoryObject.itemDiscountAmt == null
                  ? ""
                  : +orderHistoryObject.itemDiscountAmt.toFixed(2),
              orderDate:
                orderHistoryObject.orderDate == null
                  ? ""
                  : orderHistoryObject.orderDate,
              orderNo:
                orderHistoryObject.orderNo == null
                  ? ""
                  : orderHistoryObject.orderNo,
              name:
                orderHistoryObject.orderNo == null
                  ? ""
                  : orderHistoryObject.orderNo,
              sgstAmt:
                orderHistoryObject.sgstAmt == null
                  ? ""
                  : +orderHistoryObject.sgstAmt.toFixed(2),
              status:
                orderHistoryObject.status == null
                  ? ""
                  : +orderHistoryObject.status.toFixed(2),
              taxableAmt:
                orderHistoryObject.taxableAmt == null
                  ? ""
                  : +orderHistoryObject.taxableAmt.toFixed(2),
              toOrder:
                orderHistoryObject.toOrder == null
                  ? ""
                  : orderHistoryObject.toOrder,
              toOrderFirmName:
                orderHistoryObject.toOrderFirmName == null
                  ? ""
                  : orderHistoryObject.toOrderFirmName,
              toOrderOwnerName:
                orderHistoryObject.toOrderOwnerName == null
                  ? ""
                  : orderHistoryObject.toOrderOwnerName,
              totalAmt:
                orderHistoryObject.totalAmt == null
                  ? ""
                  : +orderHistoryObject.totalAmt.toFixed(2),
              type:
                orderHistoryObject.type == null ? "" : orderHistoryObject.type,
            };
            return orderHistoryData;
          });
          dispatch(orderListFromStakeholderAndDateSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getTodaysPlaceOrder = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/todays-placed-order",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((orderListObject, index) => {
          let total = 0;
          orderListObject.stockStakeHolderOrderDetails.map(
            (stockStakeHolderOrderObj) => {
              total = total + +stockStakeHolderOrderObj.qty;
            }
          );

          return {
            index: index + 1,
            id: orderListObject.id === null ? "" : orderListObject.id,

            orderNo:
              orderListObject.orderNo === null ? "" : orderListObject.orderNo,
            orderDate:
              orderListObject.orderDate === null
                ? ""
                : orderListObject.orderDate,
            totalQty: total,
            totalAmt:
              orderListObject.totalAmt === null
                ? ""
                : (+orderListObject.totalAmt).toFixed(2),
            status:
              orderListObject.status === null ? "" : orderListObject.status,
            statusName:
              orderListObject.status == 0
                ? "Pending"
                : orderListObject.status == 1
                ? "Sent"
                : orderListObject.status == 2
                ? "Accept Order"
                : orderListObject.status == 3
                ? "Generate Bill"
                : "Reject Order",
            remark:
              orderListObject.remark === null ? "" : orderListObject.remark,
            color:
              orderListObject.status == 0
                ? "success"
                : orderListObject.status === 1
                ? "primary"
                : orderListObject.status === 2
                ? "primary"
                : orderListObject.status === 3
                ? "primary"
                : "error",
          };
        });
        dispatch(placeOrderSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getPlaceOrderSearchListHeaderDetails =
  ({ fromdate, todate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/placed-order-by-date?fromDate=" +
          fromdate +
          "&toDate=" +
          todate,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((orderListObject, index) => {
            let total = 0;
            orderListObject.stockStakeHolderOrderDetails.map(
              (stockStakeHolderOrderObj) => {
                total = total + +stockStakeHolderOrderObj.qty;
              }
            );

            return {
              index: index + 1,
              id: orderListObject.id === null ? "" : orderListObject.id,

              orderNo:
                orderListObject.orderNo === null ? "" : orderListObject.orderNo,
              orderDate:
                orderListObject.orderDate === null
                  ? ""
                  : orderListObject.orderDate,
              totalQty: total,
              totalAmt:
                orderListObject.totalAmt === null
                  ? ""
                  : (+orderListObject.totalAmt).toFixed(2),
              status:
                orderListObject.status === null ? "" : orderListObject.status,
              statusName:
                orderListObject.status == 0
                  ? "Pending"
                  : orderListObject.status == 1
                  ? "Sent"
                  : orderListObject.status == 2
                  ? "Accept Order"
                  : orderListObject.status == 3
                  ? "Generate Bill"
                  : "Reject Order",
              color:
                orderListObject.status == 0
                  ? "success"
                  : orderListObject.status === 1
                  ? "primary"
                  : orderListObject.status === 2
                  ? "primary"
                  : orderListObject.status === 3
                  ? "primary"
                  : "error",
            };
          });
          dispatch(placeOrderSuccess({ row }));
          return { response: row, success };
        }
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getPlaceOrderSearchList =
  ({ fromdate, todate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/placed-order?fromDate=" + fromdate + "&toDate=" + todate,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((orderListObject, index) => {
            let orderListData = {
              index: index + 1,
              id: orderListObject.id === null ? "" : orderListObject.id,

              orderNo:
                orderListObject.orderNo === null ? "" : orderListObject.orderNo,
              orderDate:
                orderListObject.orderDate === null
                  ? ""
                  : orderListObject.orderDate,
              totalAmt:
                orderListObject.totalAmt === null
                  ? ""
                  : (+orderListObject.totalAmt).toFixed(2),
              status:
                orderListObject.status === null ? "" : orderListObject.status,
              statusName:
                orderListObject.status == 0
                  ? "Pending"
                  : orderListObject.status == 1
                  ? "Sent"
                  : orderListObject.status == 2
                  ? "Accept Order"
                  : orderListObject.status == 3
                  ? "Generate Bill"
                  : "Reject Order",
              color:
                orderListObject.status == 0
                  ? "success"
                  : orderListObject.status === 1
                  ? "primary"
                  : orderListObject.status === 2
                  ? "primary"
                  : orderListObject.status === 3
                  ? "primary"
                  : "error",
            };

            return orderListData;
          });
          dispatch(placeOrderSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const setPlaceOrderSearchList = () => async (dispatch) => {
  dispatch(placeOrderSuccess({ row: [] }));
};
export const getPlaceOrderHeaderById =
  ({ orderHeaderId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + orderHeaderId,
      }).then(({ data, success }) => {
        let row;
        if (success) {
          const orderHeaderObject = data.data;
          let orderHeaderData = {
            id: orderHeaderObject.id === null ? "" : orderHeaderObject.id,
            fromOrder:
              orderHeaderObject.fromOrder === null
                ? ""
                : orderHeaderObject.fromOrder,
            toOrder:
              orderHeaderObject.toOrder === null
                ? ""
                : orderHeaderObject.toOrder,
            orderNo:
              orderHeaderObject.orderNo === null
                ? ""
                : orderHeaderObject.orderNo,
            orderDate:
              orderHeaderObject.orderDate === null
                ? ""
                : orderHeaderObject.orderDate,
            itemDiscountAmt:
              orderHeaderObject.itemDiscountAmt === null
                ? ""
                : orderHeaderObject.itemDiscountAmt,
            discountOnbillPer:
              orderHeaderObject.discountOnbillPer === null
                ? ""
                : orderHeaderObject.discountOnbillPer,
            discountOnbillPerAmt:
              orderHeaderObject.discountOnbillPerAmt === null
                ? ""
                : orderHeaderObject.discountOnbillPerAmt,
            discountOnbillAmt:
              orderHeaderObject.discountOnbillAmt === null
                ? ""
                : orderHeaderObject.discountOnbillAmt,
            taxableAmt:
              orderHeaderObject.taxableAmt === null
                ? ""
                : orderHeaderObject.taxableAmt.toFixed(2),
            cgstAmt:
              orderHeaderObject.cgstAmt === null
                ? ""
                : orderHeaderObject.cgstAmt.toFixed(2),
            sgstAmt:
              orderHeaderObject.sgstAmt === null
                ? ""
                : orderHeaderObject.sgstAmt.toFixed(2),
            igstAmt:
              orderHeaderObject.igstAmt === null
                ? ""
                : orderHeaderObject.igstAmt.toFixed(2),
            totalAmt:
              orderHeaderObject.totalAmt === null
                ? ""
                : orderHeaderObject.totalAmt.toFixed(2),
            type: orderHeaderObject.type === null ? "" : orderHeaderObject.type,
            remark:
              orderHeaderObject.remark === null ? "" : orderHeaderObject.remark,
            status:
              orderHeaderObject.status === null ? "" : orderHeaderObject.status,
            insertedUserId:
              orderHeaderObject.insertedUserId === null
                ? ""
                : orderHeaderObject.insertedUserId,
            insertDateTime:
              orderHeaderObject.insertDateTime === null
                ? ""
                : orderHeaderObject.insertDateTime,
            stockStakeHolderOrderDetails:
              orderHeaderObject.stockStakeHolderOrderDetails === null
                ? ""
                : orderHeaderObject.stockStakeHolderOrderDetails.map(
                    (orderObject, index) => {
                      let orderData = {
                        index: index + 1,
                        id: orderObject.id === null ? "" : orderObject.id,
                        product:
                          orderObject.product === null
                            ? ""
                            : orderObject.product,
                        productName:
                          orderObject.product === null
                            ? ""
                            : orderObject.product.name,
                        uom: orderObject.uom === null ? "" : orderObject.uom,
                        name:
                          orderObject.product !== null &&
                          orderObject.product.outletUom !== null &&
                          orderObject.product.outletUom.name
                            ? orderObject.product.outletUom.name
                            : "",
                        stakeHolderUom:
                          orderObject.product !== null &&
                          orderObject.product.stakeHolderUom !== null &&
                          orderObject.product.stakeHolderUom.name
                            ? orderObject.product.stakeHolderUom.name
                            : "",
                        rate:
                          orderObject.rate === null
                            ? ""
                            : orderObject.rate.toFixed(2),
                        qty: orderObject.qty === null ? "" : orderObject.qty,
                        taxableAmt:
                          orderObject.taxableAmt === null
                            ? ""
                            : orderObject.taxableAmt.toFixed(2),
                        totalAmt:
                          orderObject.totalAmt === null
                            ? ""
                            : orderObject.totalAmt.toFixed(2),
                        secondaryMrp:
                          orderObject.secondaryMrp === null
                            ? ""
                            : orderObject.secondaryMrp,
                        mrp: orderObject.mrp === null ? "" : orderObject.mrp,
                        conversionValue:
                          orderObject.conversionValue === null
                            ? ""
                            : orderObject.conversionValue,
                        discountOnbillPer:
                          orderObject.discountOnbillPer === null
                            ? ""
                            : orderObject.discountOnbillPer,
                        discountOnbillPerAmt:
                          orderObject.discountOnbillPerAmt === null
                            ? ""
                            : orderObject.discountOnbillPerAmt,
                        discountOnbillamt:
                          orderObject.discountOnbillamt === null
                            ? ""
                            : orderObject.discountOnbillamt,
                        itemDiscPer:
                          orderObject.itemDiscPer === null
                            ? ""
                            : orderObject.itemDiscPer,
                        itemDiscAmt:
                          orderObject.itemDiscAmt === null
                            ? ""
                            : orderObject.itemDiscAmt,
                        cgstAmt:
                          orderObject.cgstAmt === null
                            ? ""
                            : orderObject.cgstAmt.toFixed(2),
                        sgstAmt:
                          orderObject.sgstAmt === null
                            ? ""
                            : orderObject.sgstAmt.toFixed(2),
                        igstAmt:
                          orderObject.igstAmt === null
                            ? ""
                            : orderObject.igstAmt.toFixed(2),
                        cgstPer:
                          orderObject.cgstPer === null
                            ? ""
                            : orderObject.cgstPer,
                        sgstPer:
                          orderObject.sgstPer === null
                            ? ""
                            : orderObject.sgstPer,
                        igstPer:
                          orderObject.igstPer === null
                            ? ""
                            : orderObject.igstPer,
                        isBillGenerated:
                          orderObject.isBillGenerated === null
                            ? ""
                            : orderObject.isBillGenerated,
                        inserteUserId:
                          orderObject.inserteUserId === null
                            ? ""
                            : orderObject.inserteUserId,
                        insertDateTime:
                          orderObject.insertDateTime === null
                            ? ""
                            : orderObject.insertDateTime,
                      };
                      return orderData;
                    }
                  ),
          };
          dispatch(orderHeaderByIdSuccess({ row: orderHeaderData }));
          return { response: orderHeaderData, success };
        }
        return { response: {}, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getPlaceOrderHeaderByIdForStakeholderBilling =
  ({ orderHeaderId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + orderHeaderId,
      }).then(({ data, success }) => {
        let row;
        if (success) {
          const orderHeaderObject = data.data;
          let orderHeaderData = {
            // ...orderHeaderObject,
            taxableAmt:
              orderHeaderObject.taxableAmt === null
                ? ""
                : orderHeaderObject.taxableAmt.toFixed(2),
            cgstAmt:
              orderHeaderObject.cgstAmt === null
                ? ""
                : orderHeaderObject.cgstAmt.toFixed(2),
            sgstAmt:
              orderHeaderObject.sgstAmt === null
                ? ""
                : orderHeaderObject.sgstAmt.toFixed(2),
            igstAmt:
              orderHeaderObject.igstAmt === null
                ? ""
                : orderHeaderObject.igstAmt.toFixed(2),
            totalAmt:
              orderHeaderObject.totalAmt === null
                ? ""
                : orderHeaderObject.totalAmt.toFixed(2),

            stockStakeHolderOrderDetails:
              orderHeaderObject.stockStakeHolderOrderDetails === null
                ? ""
                : orderHeaderObject.stockStakeHolderOrderDetails.map(
                    (orderObject, index) => {
                      let orderData = {
                        index: index + 1,
                        headerId: orderObject.id === null ? "" : orderObject.id,
                        id:
                          orderObject.product === null
                            ? ""
                            : orderObject.product.id,
                        // product:
                        //   orderObject.product === null
                        //     ? ""
                        //     : orderObject.product,
                        name:
                          orderObject.product === null
                            ? ""
                            : orderObject.product.name,
                        cgstPer:
                          orderObject.cgstPer === null
                            ? ""
                            : (+orderObject.cgstPer).toFixed(2),
                        igstPer:
                          orderObject.igstPer === null
                            ? ""
                            : (+orderObject.igstPer).toFixed(2),
                        sgstPer:
                          orderObject.sgstPer === null
                            ? ""
                            : (+orderObject.sgstPer).toFixed(2),

                        uom: orderObject.uom === null ? "" : orderObject.uom,
                        uomId:
                          orderObject.uom === null ? "" : orderObject.uom.id,
                        outletUomName:
                          orderObject.product !== null &&
                          orderObject.product.outletUom !== null &&
                          orderObject.product.outletUom.name
                            ? orderObject.product.outletUom.name
                            : "",
                        stakeHolderUom:
                          orderObject.product !== null &&
                          orderObject.product.stakeHolderUom !== null &&
                          orderObject.product.stakeHolderUom.name
                            ? orderObject.product.stakeHolderUom.name
                            : "",
                        rate:
                          orderObject.rate === null
                            ? ""
                            : orderObject.rate.toFixed(2),
                        orderQty:
                          orderObject.qty === null ? "" : orderObject.qty,
                        taxableAmt:
                          orderObject.taxableAmt === null
                            ? ""
                            : orderObject.taxableAmt.toFixed(2),
                        totalAmt:
                          orderObject.totalAmt === null
                            ? ""
                            : orderObject.totalAmt.toFixed(2),
                        secondaryMrp:
                          orderObject.secondaryMrp === null
                            ? ""
                            : orderObject.secondaryMrp,
                        mrp: orderObject.mrp === null ? "" : orderObject.mrp,
                        uomConversionValue:
                          orderObject.product === null
                            ? ""
                            : orderObject.product.uomConversionValue,
                        // ...orderObject,
                      };
                      return orderData;
                    }
                  ),
          };
          dispatch(
            orderHeaderByIdForStakeholderSuccess({
              row: orderHeaderData.stockStakeHolderOrderDetails,
            })
          );
          return {
            response: orderHeaderData.stockStakeHolderOrderDetails,
            success,
          };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getPlaceOrderHeaderByIdForStakeholderBillingNew =
  ({ orderHeaderId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/get-products/" + orderHeaderId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((orderObject, index) => {
            let orderData = {
              index: index + 1,
              headerId: orderObject.id === null ? "" : orderObject.id,
              id: orderObject.product === null ? "" : orderObject.product.id,
              name:
                orderObject.product === null ? "" : orderObject.product.name,
              cgstPer:
                orderObject.product === null
                  ? ""
                  : (+orderObject.product.taxPercentage.cgst).toFixed(2),
              igstPer:
                orderObject.product === null
                  ? ""
                  : (+orderObject.product.taxPercentage.igst).toFixed(2),
              sgstPer:
                orderObject.product === null
                  ? ""
                  : (+orderObject.product.taxPercentage.sgst).toFixed(2),

              uomId:
                orderObject.product === null
                  ? ""
                  : orderObject.product.stakeHolderUom.id,
              outletUomName:
                orderObject.product !== null &&
                orderObject.product.outletUom !== null &&
                orderObject.product.outletUom.name
                  ? orderObject.product.outletUom.name
                  : "",
              stakeHolderUom:
                orderObject.product !== null &&
                orderObject.product.stakeHolderUom !== null &&
                orderObject.product.stakeHolderUom.name
                  ? orderObject.product.stakeHolderUom.name
                  : "",
              rate:
                orderObject.rate === null ? "" : orderObject.rate.toFixed(4),
              orderQty: orderObject.qty === null ? "" : orderObject.qty,
              mrp: orderObject.mrp === null ? "" : orderObject.mrp,
              uomConversionValue:
                orderObject.product === null
                  ? ""
                  : orderObject.product.uomConversionValue,
              conversionValue:
                orderObject.conversionValue === null
                  ? ""
                  : orderObject.conversionValue,
            };

            return orderData;
          });
          dispatch(orderHeaderByIdForStakeholderSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getOrderListByDate =
  ({ fromdate, todate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/order-history-by-date?fromDate=" +
          fromdate +
          "&toDate=" +
          todate,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((orderListObject, index) => {
            let totalQty = 0;
            orderListObject.stockStakeHolderOrderDetails.map((detailsObj) => {
              totalQty = totalQty + +detailsObj.qty;
            });
            let orderListData = {
              index: index + 1,
              id: orderListObject.id === null ? "" : orderListObject.id,
              fromOrder:
                orderListObject.fromOrder === null
                  ? ""
                  : orderListObject.fromOrder,
              fromBill:
                orderListObject.fromBill === null
                  ? ""
                  : orderListObject.fromBill,
              toOrder:
                orderListObject.toOrder === null ? "" : orderListObject.toOrder,
              orderNo:
                orderListObject.orderNo === null ? "" : orderListObject.orderNo,
              orderDate:
                orderListObject.orderDate === null
                  ? ""
                  : orderListObject.orderDate,
              stockHolder:
                orderListObject.fromOrderFirmName === null
                  ? ""
                  : orderListObject.fromOrderFirmName,
              fromOrderShCode:
                orderListObject.fromOrderShCode === null
                  ? ""
                  : orderListObject.fromOrderShCode,
              fromOrderStateName:
                orderListObject.fromOrderStateName === null
                  ? ""
                  : orderListObject.fromOrderStateName,
              fromOrderAreaNames:
                orderListObject.fromOrderAreaNames === null
                  ? ""
                  : orderListObject.fromOrderAreaNames,
              fromOrderOwnerName:
                orderListObject.fromOrderFirmName === null
                  ? ""
                  : orderListObject.fromOrderFirmName +
                    " ( " +
                    (orderListObject.fromOrderOwnerName === null
                      ? ""
                      : orderListObject.fromOrderOwnerName) +
                    " ) ",
              itemDiscountAmt:
                orderListObject.itemDiscountAmt === null
                  ? ""
                  : orderListObject.itemDiscountAmt,
              discountOnbillPer:
                orderListObject.discountOnbillPer === null
                  ? ""
                  : orderListObject.discountOnbillPer,
              discountOnbillPerAmt:
                orderListObject.discountOnbillPerAmt === null
                  ? ""
                  : orderListObject.discountOnbillPerAmt,
              discountOnbillAmt:
                orderListObject.discountOnbillAmt === null
                  ? ""
                  : orderListObject.discountOnbillAmt,
              totalQty: totalQty,
              taxableAmt:
                orderListObject.taxableAmt === null
                  ? ""
                  : orderListObject.taxableAmt.toFixed(2),
              cgstAmt:
                orderListObject.cgstAmt === null
                  ? ""
                  : orderListObject.cgstAmt.toFixed(2),
              sgstAmt:
                orderListObject.sgstAmt === null
                  ? ""
                  : orderListObject.sgstAmt.toFixed(2),
              igstAmt:
                orderListObject.igstAmt === null
                  ? ""
                  : orderListObject.igstAmt.toFixed(2),
              totalAmt:
                orderListObject.totalAmt === null
                  ? ""
                  : orderListObject.totalAmt.toFixed(2),

              type: orderListObject.type === null ? "" : orderListObject.type,
              statusName:
                orderListObject.status === null
                  ? ""
                  : orderListObject.status === 1
                  ? "Send Order"
                  : orderListObject.status === 2
                  ? "Accept Order"
                  : orderListObject.status === 3
                  ? "Bill Generated"
                  : "Reject Order",
              status:
                orderListObject.status === null ? "" : orderListObject.status,
              insertedUserId:
                orderListObject.insertedUserId === null
                  ? ""
                  : orderListObject.insertedUserId,
              insertDateTime:
                orderListObject.insertDateTime === null
                  ? ""
                  : orderListObject.insertDateTime,
            };
            return orderListData;
          });
          dispatch(orderListSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getOrderList =
  ({ holderIds, fromdate, todate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/order-history?holderIds=" +
          holderIds +
          "&fromDate=" +
          fromdate +
          "&toDate=" +
          todate,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((orderListObject, index) => {
            let orderListData = {
              index: index + 1,
              id: orderListObject.id === null ? "" : orderListObject.id,
              fromOrder:
                orderListObject.fromOrder === null
                  ? ""
                  : orderListObject.fromOrder,
              fromBill:
                orderListObject.fromBill === null
                  ? ""
                  : orderListObject.fromBill,
              toOrder:
                orderListObject.toOrder === null ? "" : orderListObject.toOrder,
              orderNo:
                orderListObject.orderNo === null ? "" : orderListObject.orderNo,
              orderDate:
                orderListObject.orderDate === null
                  ? ""
                  : orderListObject.orderDate,
              stockHolder:
                orderListObject.fromOrderFirmName === null
                  ? ""
                  : orderListObject.fromOrderFirmName,
              fromOrderStateName:
                orderListObject.fromOrderStateName === null
                  ? ""
                  : orderListObject.fromOrderStateName,
              fromOrderAreaNames:
                orderListObject.fromOrderAreaNames === null
                  ? ""
                  : orderListObject.fromOrderAreaNames,
              fromOrderOwnerName:
                orderListObject.fromOrderFirmName === null
                  ? ""
                  : orderListObject.fromOrderFirmName +
                    " ( " +
                    (orderListObject.fromOrderOwnerName === null
                      ? ""
                      : orderListObject.fromOrderOwnerName) +
                    " ) ",
              itemDiscountAmt:
                orderListObject.itemDiscountAmt === null
                  ? ""
                  : orderListObject.itemDiscountAmt,
              discountOnbillPer:
                orderListObject.discountOnbillPer === null
                  ? ""
                  : orderListObject.discountOnbillPer,
              discountOnbillPerAmt:
                orderListObject.discountOnbillPerAmt === null
                  ? ""
                  : orderListObject.discountOnbillPerAmt,
              discountOnbillAmt:
                orderListObject.discountOnbillAmt === null
                  ? ""
                  : orderListObject.discountOnbillAmt,
              taxableAmt:
                orderListObject.taxableAmt === null
                  ? ""
                  : orderListObject.taxableAmt.toFixed(2),
              cgstAmt:
                orderListObject.cgstAmt === null
                  ? ""
                  : orderListObject.cgstAmt.toFixed(2),
              sgstAmt:
                orderListObject.sgstAmt === null
                  ? ""
                  : orderListObject.sgstAmt.toFixed(2),
              igstAmt:
                orderListObject.igstAmt === null
                  ? ""
                  : orderListObject.igstAmt.toFixed(2),
              totalAmt:
                orderListObject.totalAmt === null
                  ? ""
                  : orderListObject.totalAmt.toFixed(2),

              type: orderListObject.type === null ? "" : orderListObject.type,
              statusName:
                orderListObject.status === null
                  ? ""
                  : orderListObject.status === 1
                  ? "Send Order"
                  : orderListObject.status === 2
                  ? "Accept Order"
                  : orderListObject.status === 3
                  ? "Bill Generated"
                  : "Reject Order",
              status:
                orderListObject.status === null ? "" : orderListObject.status,
              insertedUserId:
                orderListObject.insertedUserId === null
                  ? ""
                  : orderListObject.insertedUserId,
              insertDateTime:
                orderListObject.insertDateTime === null
                  ? ""
                  : orderListObject.insertDateTime,
            };
            return orderListData;
          });
          dispatch(orderListSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const setOrderListShowOrder = () => async (dispatch) => {
  dispatch(orderListSuccess({ row: [] }));
};

export const setOrderList =
  ({ row }) =>
  async (dispatch) => {
    const updatedRow = {
      ...row,
      totalAmt: 0,
      stockStakeHolderOrderDetails: row.stockStakeHolderOrderDetails.map(
        (rowObject, index) => {
          const totalAmt = (+rowObject.qty * +rowObject.rate).toFixed(2);
          return {
            ...rowObject,
            totalAmt: totalAmt,
          };
        }
      ),
    };
    dispatch(orderHeaderByIdSuccess({ row: updatedRow }));
  };
export const getPrimaryOrderForDistributor = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/primary-order",
    }).then(({ data, success }) => {
      if (success) {
        dispatch(primarySuccess({ row: data.data }));
        return { response: data.data, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getPrimaryLatestOrder = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/latest-order",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((rowData, index) => {
          return { index: index + 1, ...rowData };
        });
        dispatch(primaryLatestOrderSuccess({ row }));
        return { response: data.data, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getPrimaryLatestOrderByFilter =
  ({ fromDate, toDate, status }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-date?fromDate=" +
          fromDate +
          "&toDate=" +
          toDate +
          "&status=" +
          status,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((rowData, index) => {
            return { index: index + 1, ...rowData };
          });
          dispatch(primaryLatestOrderSuccess({ row }));
          return { response: data.data, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
