import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { grievanceCategoryJson } from "../../../DynamicFormsJson/MastersJSON/grievanceCategory";
import { getGrievanceCategory } from "../../../Slice/grievanceCategory.slice";

class GrievanceCategoryForm extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <DynamicForm
          formPath={grievanceCategoryJson.formPath}
          screenTitle={grievanceCategoryJson.screenTitle}
          fieldMeta={grievanceCategoryJson.fieldMeta}
          apiBaseURL={grievanceCategoryJson.apiBaseURL}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  grievanceCategoryList: state.grievanceCategory,
});
const mapDispatchToProps = {
  getGrievanceCategory,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GrievanceCategoryForm);
