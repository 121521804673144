import PropTypes from "prop-types";

// material-ui
import { Box, Chip, Grid, Stack, Typography } from "@mui/material";
import Link from "@mui/material/Link";

// project import
import MainCard from "./MainCard";

// assets
import { RiseOutlined, FallOutlined } from "@ant-design/icons";

// ==============================|| STATISTICS - ECOMMERCE CARD  ||============================== //

const AnalyticEcommerce = ({
  color,
  title,
  count,
  percentage,
  isLoss,
  extra,
  msgRight = "",
  msgLeft = "",
  onClickFlag = true,
  onClick,
}) => (
  <MainCard contentSX={{ p: 2.25 }}>
    {!onClickFlag ? (
      <MainCardData
        color={color}
        title={title}
        count={count}
        percentage={percentage}
        isLoss={isLoss}
        extra={extra}
        msgRight={msgRight}
        msgLeft={msgLeft}
      />
    ) : (
      <Link
        component="button"
        color="textSecondary"
        variant="h6"
        onClick={onClick}
        // sx={{ justifyContent: "flex-start" }}
        underline="none"
      >
        <MainCardData
          color={color}
          title={title}
          count={count}
          percentage={percentage}
          isLoss={isLoss}
          extra={extra}
          msgRight={msgRight}
          msgLeft={msgLeft}
        />
      </Link>
    )}
  </MainCard>
);
const MainCardData = ({
  color,
  title,
  count,
  percentage,
  isLoss,
  extra,
  msgRight = "",
  msgLeft = "",
}) => {
  return (
    <>
      <Stack spacing={0.5}>
        {/* {!onClickFlag ? ( */}
        <Typography variant="h6" color="textSecondary">
          {title}
        </Typography>
        {/* ) : (
          <Link
            component="button"
            color="textSecondary"
            variant="h6"
            onClick={onClick}
            sx={{ justifyContent: "flex-start" }}
          >
            {title}
          </Link>
        )} */}
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="h4" color="inherit">
              {count}
            </Typography>
          </Grid>
          {percentage && percentage != 0 && (
            <Grid item>
              <Chip
                variant="combined"
                color={isLoss ? "error" : "success"}
                icon={
                  <>
                    {!isLoss && (
                      <RiseOutlined
                        style={{ fontSize: "0.75rem", color: "inherit" }}
                      />
                    )}
                    {isLoss && (
                      <FallOutlined
                        style={{ fontSize: "0.75rem", color: "inherit" }}
                      />
                    )}
                  </>
                }
                label={`${percentage}%`}
                sx={{ ml: 1.25, pl: 1 }}
                size="small"
              />
            </Grid>
          )}
        </Grid>
      </Stack>

      <Box sx={{ pt: 2.25 }}>
        <Typography variant="caption" color="textSecondary">
          {msgLeft}
          <Typography
            component="span"
            variant="caption"
            sx={{ color: `${color || "primary"}.main` }}
          >
            {extra}
          </Typography>
          {msgRight}
        </Typography>
      </Box>
    </>
  );
};
AnalyticEcommerce.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.string,
  percentage: PropTypes.number,
  isLoss: PropTypes.bool,
  extra: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

AnalyticEcommerce.defaultProps = {
  color: "primary",
};

export default AnalyticEcommerce;
