import endpoint from "../../config/endpoints";

export const orderConfigurationJson = {
  formPath: "/order-configuration-form",
  apiBaseURL: endpoint.orderConfiguration,
  showDeleteIcon:false,
  screenTitle: "Order Configuration",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "OrderConfiguration",
  showExcelDownload: true,
  excelFileName: "OrderConfiguration",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Order To Name",
      columnKeyName: "orderToName",
      isChecked: true,
    },
  ],

  fieldMeta: [
    {
      label: "Stock Holder Type",
      controlType: "autocomplete/textfield",
      placeHolder: "Select Stock Holder Type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeHolderTypeDetail",
      dataKey: "stockStakeHolderType",
      dataKeyName: "stockStakeHolderTypeName",
      isRootLevelKey: false,
      isMandatory: true,
    },

    {
      label: "Stock Holder Type(Multiple)",
      controlType: "multiSelect",
      placeHolder: "Select Stock Holder Type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeHolderTypeDetailMultiSelect",
      dataKey: "orderToIds",
      isRootLevelKey: false,
      isMandatory: true,
    },
  ],
};
