import endpoint from "../../config/endpoints";

export const vehicleJson = {
  formPath: "/vehicle-form",
  apiBaseURL: endpoint.vehicle,
  changeExcel: endpoint.exportData,
  screenTitle: "Vehicle",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Vehicle",
  showExcelDownload: true,
  excelFileName: "Vehicle",
  excelUrl: "uom-data" + '?sort={"insertDateTime": "DESC"}',

  tableColumnsToFilter: [
    {
      columnDisplayName: "Transporter",
      columnKeyName: "transporterName",
      isChecked: true,
    },
    {
      columnDisplayName: "Vehicle Number",
      columnKeyName: "name",
      isChecked: true,
    },

    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "Transporter",
      controlType: "autocomplete",
      placeHolder: "Select Transporter",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "transporterDetail",
      dataKey: "transporter",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Vehicle Number",
      controlType: "textfield",
      placeHolder: "Enter Vehicle Number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "vehicleNo",
      inputType: "numberText",
      isMandatory: true,
      uniqueValidation: true,
    },
    {
      label: "Driver Name",
      controlType: "textfield",
      placeHolder: "Enter Driver Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "driverNumber",
      isMandatory: false,
    },
    {
      label: "Description",
      controlType: "textfield",
      placeHolder: "Enter Description",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "description",
      isMandatory: false,
    },
  ],
};
