import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../components/dynamicscreens/DynamicMainScreen";
import { DistributionReportsJson } from "../DynamicFormsJson/Transaction/DynamicReport";
import { getAreaByUserType } from "../Slice/area.slice";
import { getOutletByBeatIds } from "../Slice/outlet.slice";
import { getBeatByArea } from "../Slice/beat.slice";
import { getZoneByState } from "../Slice/zone.slice";
import { getDistrictByZone } from "../Slice/district.slice";
import { getTalukaByDistrict } from "../Slice/taluka.slice";
import { getAreaByTaluka } from "../Slice/area.slice";
import jsPDF from "jspdf";
import { showLoador, showNotification } from "./Landing/Landing.slice";
import { serverMsg, noInternetMsg } from "../config/messageconstant";
import { ladgerJson } from "../DynamicFormsJson/Transaction/ladger";
import { LadgerColumns } from "../tableColumns/table-columns";
import { getladger } from '../Slice/ladger.slice'
import { getStateByRegion } from "../Slice/state.slice";
import { getRegion } from "../Slice/region.slice";
import { getSalesExecutiveByBeatIds } from "../Slice/salesExecutive.slice";
import moment from "moment";

class Ladger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicMasterData: {
                salesPersonDetail:this.props.salesExecutive?.salesExecutive,
                areaDetail: this.props.areaList?.area,
                beatDetail: this.props.beatList.beat,
                outletDetail: this.props.outletList.outlets,
            },
            searchBy: "0",
        };
    }

    getLedgerByOutlet = async (flag,outletIds, fromDate, toDate) => {
        const {
            getladger,
            showLoador,
            showNotification,
        } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            await getladger({ flag:flag,fromDate: fromDate, toDate: toDate, outletId: outletIds }).then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }
    async componentDidMount() {
        const { getRegion, getAreaByUserType, showLoador, showNotification } =
            this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            await getAreaByUserType().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });

        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.salesExecutive !== nextProps.salesExecutive) {
            if (
              this.props.salesExecutive?.salesExecutive !==
              nextProps.salesExecutive?.salesExecutive
            ) {
              this.setState({
                dynamicMasterData: {
                  ...this.state.dynamicMasterData,
                  salesPersonDetail: nextProps.salesExecutive?.salesExecutive,
                },
              });
            }
          }
        if (this.props.outletList !== nextProps.outletList) {
            if (this.props.outletList?.outlets !== nextProps.outletList?.outlets) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        outletDetail: nextProps.outletList?.outlets,
                    },
                });
            }
        }
        if (this.props.beatList !== nextProps.beatList) {
            if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        beatDetail: nextProps.beatList?.beat,
                    },
                });
            }
        }
        if (this.props.areaList !== nextProps.areaList) {
            if (this.props.areaList?.area !== nextProps.areaList?.area) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        areaDetail: nextProps.areaList?.area,
                    },
                });
            }
        }

    }

    getListById = async (data) => {
        const {
            getOutletByBeatIds,
            getSalesExecutiveByBeatIds,
            showLoador,
            showNotification,
            getBeatByArea
        } = this.props;
        if (navigator.onLine) {
            if (data.flag) {
                this.setState({
                  searchBy: data.flag,
                  formData: {
                    flag: data.flag,
                    beat: [],
                    outletIds: [],
                    salesPersonIds: [],
                  },
                });
              }
            if (data.area) {
                showLoador({ loador: true });
                await getBeatByArea({ areaId: data.area ? data.area : 0 }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }

            if (data.beat && data.beat != "") {
                if (this.state.searchBy == 0) {
                    showLoador({ loador: true });
                    await getOutletByBeatIds({ beatIds: data.beat.join() }).then(
                        ({ success }) => {
                            showLoador({ loador: false });
                            if (!success) {
                                showNotification({ msg: serverMsg, severity: "error" });
                            }
                        }
                    );
                } else {
                    showLoador({ loador: true });
                    await getSalesExecutiveByBeatIds({ beatIds: data.beat.join() }).then(
                        ({ success }) => {
                            showLoador({ loador: false });
                            if (!success) {
                                showNotification({ msg: serverMsg, severity: "error" });
                            }
                        }
                    );
                }
            }
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    onSearchList = (data) => {
        this.setState({
            fieldData: data,
        });
        const { showNotification } = this.props;
        if (navigator.onLine) {
            //   if (data.flag == 0) {
            this.getLedgerByOutlet(data.flag,data.outletIds, data.fromdate, data.todate);
            //   } else {
            //     this.getOrderListBySalesPerson(
            //       data.salesPersonIds,
            //       data.fromdate,
            //       data.todate
            //     );
            //   }
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    generatePDFStatic = () => {
        var doc = new jsPDF();
        let pdfTitle = "Ladger Report"
        var textWidth =
            (doc.getStringUnitWidth(pdfTitle) * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;

        var pageWidth = doc.internal.pageSize.getWidth();
        var xPos = (pageWidth - textWidth) / 2;
        doc.text(pdfTitle, xPos, 15, { maxWidth: 540 });
        doc.setFontSize(12);
        var head = [
            ["Sr No", "Date", "Description", "Voucher No", "Debit Amount", "Credit Amount", "Balance Amount"],
        ];
        let tableList = [];
        this.props.ladger.ladger.map((rowData, index) => {
            let tableData = [];
            tableData.push(rowData.index);
            tableData.push(rowData.date);
            tableData.push(rowData.remark);
            tableData.push(rowData.type);
            tableData.push(rowData.debitAmt);
            tableData.push(rowData.creditAmt);

            tableData.push(rowData.balanceAmt);
            tableList.push(tableData);
        });
        const columnStyles = {
            6: { halign: "right" },
        };

        doc.autoTable({
            head: head,
            body: tableList,
            startY: 25,
            columnStyles: columnStyles,
            margin: { right: 5, left: 5, top: 20 },
            headStyles: {
                halign: "center",
                lineWidth: 0.2,
                lineColor: "#fff",
                fillColor: [3, 169, 244],
                textColor: [255, 255, 255],
            },
            theme: "grid",

            showHead: "everyPage",

            didDrawPage: function (data) {
                doc.setTextColor(40);
                var pageWidth = doc.internal.pageSize.getWidth();

                var str = "Page " + doc.internal.getNumberOfPages();

                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                var pageSize = doc.internal.pageSize;
                var pageHeight = pageSize.height
                    ? pageSize.height
                    : pageSize.getHeight();
                let pb = "Powered By Bizintel DMS";
                const compName =  localStorage.getItem("compName");
                var textWidthCN =
                    (doc.getStringUnitWidth(compName) * doc.internal.getFontSize()) /
                    doc.internal.scaleFactor;
                var textWidth =
                    (doc.getStringUnitWidth(pb) * doc.internal.getFontSize()) /
                    doc.internal.scaleFactor;

                // Calculate the X position to align to the right of the page

                doc.setLineWidth(0.2);
                if (data.pageNumber > 1) {
                    doc.rect(5, 7, pageWidth - 10, pageHeight - 12);
                } else {
                    doc.rect(5, 5, pageWidth - 10, pageHeight - 10);
                }
                // Adjust for a margin if needed
                doc.setFontSize(10);
                var xPosCN = (pageWidth - textWidthCN) / 2;
                var xPos = pageWidth - textWidth - 10;
                doc.text(compName, 10, pageHeight - 10);

                doc.setFontSize(10);
                doc.text(pb, xPos, pageHeight - 10);
                doc.text(str, xPosCN, pageHeight - 10);
            },
        });
        doc.save(pdfTitle+" "+moment().format("DD-MM-YYYY")+".pdf");
    };
    render() {
        const { searchBy,dynamicMasterData } = this.state;
        const {  ladger } = this.props;

        return (
            <>
                <DynamicMainScreen
                    onSearchData={this.onSearchList}
                    dynamicMasterData={dynamicMasterData}
                    callApi={false}
                    formPath={ladgerJson.formPath}
                    screenTitle={ladgerJson.screenTitle}
                    searchList={searchBy == 0
                        ? ladgerJson.fieldMeta
                        : ladgerJson.fieldMetaBySalsPerson}

                    showPdfDownload={ladgerJson.showPdfDownload}
                    showExcelDownload={ladgerJson.showExcelDownload}
                    pdfFileName={ladgerJson.pdfFileName}
                    excelFileName={ladgerJson.excelFileName}
                    showAddButton={ladgerJson.showAddButton}
                    tableColumnsToFilter={ladgerJson.tableColumnsToFilter}
                    tableColumns={LadgerColumns}
                    showPegination={false}
                    tableData={ladger.ladger}
                    getListById={this.getListById}
                    apiBaseURL={ladgerJson.apiBaseURL}
                    baseIdColumn={ladgerJson.baseIdColumn}
                    isNote={false}
                    showEditIcon={false}
                    showDeleteIcon={false}
                    isActionColActive={false}
                    staticPDF={true}
                    generatePDFStatic={this.generatePDFStatic}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    areaList: state.area,
    regionList: state.region,
    stateList: state.states,
    zoneList: state.zone,
    districtList: state.district,
    talukaList: state.taluka,
    ladger: state.ladger,
    beatList: state.beat,
    outletList: state.outlet,
    salesExecutive: state.salesExecutive,
});
const mapDispatchToProps = {
    getStateByRegion,
    getZoneByState,
    getRegion,
    getAreaByUserType,
    getDistrictByZone,
    getTalukaByDistrict,
    getAreaByTaluka,
    showLoador,
    showNotification,
    getladger,
    getBeatByArea,
    getOutletByBeatIds,
    getSalesExecutiveByBeatIds
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Ladger);
