import endpoint from "../../config/endpoints";

export const leaveTypeJson = {
  showSaveNextBtn: true,
  formPath: "/leave-type-form",
  apiBaseURL: endpoint.leaveType,
  changeExcel: endpoint.leaveType,
  screenTitle: "Leave Type",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Leave-Type",
  showExcelDownload: false,
  excelFileName: "Leave-Type",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Short Name",
      columnKeyName: "shortName",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "Leave Type",
      controlType: "textfield",
      placeHolder: "Leave Type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "name",
      isMandatory: true,
      uniqueValidation: true,
    },
    {
      label: "Short Name",
      controlType: "textfield",
      placeHolder: "Short Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "shortName",
      isMandatory: true,
    },
    {
        label: "Leave Summary",
        controlType: "autocomplete",
        placeHolder: "Leave Summary",
        md: 6,
        lg: 6,
        sm: 6,
        xs: 12,
        masterName: "leaveSummaryDetail",
        dataKey: "leaveSummary",
        isRootLevelKey: false,
        isMandatory: true,
      },
      {
        label: "Remark",
        controlType: "textfield",
        placeHolder: "Remark",
        md: 6,
        lg: 6,
        sm: 6,
        xs: 12,
        dataKey: "remark",
        isMandatory: false,
      },
  ],
};
