import {
    Grid,
    RadioGroup,
    FormControlLabel,
    Radio
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
    showLoador,
    showNotification,
} from "../../Pages/Landing/Landing.slice";
import { saveReport, setSaveReport } from '../../Slice/report.slice'

import { redColor, titleColor } from "../../config/ColorObj";
import * as msgConstant from "../../config/messageconstant";
import { serverMsg } from "../../config/messageconstant";
import { withRouter } from "../../components/withRouter";
import { LabelCompo } from "../../components/Comman/Label";
import { validText } from "../../components/Comman/Util/Validations";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import RTTable from '../../components/Comman/RT/RTTable'
import AddFilter from './AddFilter'
import { ButtonCompo } from "../../components/Comman/Button";
class AddFilterForm extends Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        this.state = {
            parameterRequired: [],
            type: "",
            isGraph: "1",
            isSubReport: "0",
            formValidErrors: {},
            fieldData: {},
            fieldDataEdit: {},
            formErrors: {},
            editData: {},
            openDialog: false,
            rowData: {}
        };
    }

    onCancel = () => {
        this.props.navigate(-1);
    };

    onFormSave = () => {
        this.props.changeTab(2)
    };

    toggleFilter = () => {
        if (this.state.openDialog) {
            this.setState({
                rowData: {},
            })
        }
        this.setState({
            openDialog: !this.state.openDialog,
        })
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.report != this.props.report) {
            if (nextProps.report.reportData != this.props.report.reportData) {
                console.log(nextProps.report.reportData)
                this.setState({
                    parameterRequired: JSON.parse(nextProps.report.reportData.parameterRequired) != null ? JSON.parse(nextProps.report.reportData.parameterRequired).map((parameter, index) => {
                        return { index: index + 1, label: parameter.label, dataKey: parameter.dataKey, controlType: parameter.controlType, url: parameter.url, ...parameter, index: index + 1, }
                    }) : [],

                });
            }
        }
    }
    componentDidMount() {
        console.log(JSON.parse(this.props.report.reportData.parameterRequired))
        this.setState({
            parameterRequired: JSON.parse(this.props.report.reportData.parameterRequired) != null ? JSON.parse(this.props.report.reportData.parameterRequired).map((parameter, index) => {
                return { index: index + 1, label: parameter.label, dataKey: parameter.dataKey, controlType: parameter.controlType, url: parameter.url, ...parameter, index: index + 1, }
            }) : [],

        });
    }
    rowDelete = (rowData) => {
        const { showNotification, showLoador } = this.props;
        swal({
            title: "Are you sure?",
            text: msgConstant.deleteWarningMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                if (navigator.onLine) {
                    let filterData = this.state.parameterRequired.filter((row) => row.dataKey != rowData.dataKey)
                    let filterList = filterData.map((data, index) => {
                        return { index: index + 1, ...data }
                    })
                    this.onSave({
                        ...this.props.report.reportData,
                        parameterRequired: JSON.stringify(filterList)
                    }
                    )
                } else {
                    showNotification({ msg: msgConstant.noInternetMsg, severity: "error" });
                }
            }
        });
    };

    rowEdit = (rowData) => {
        this.setState({
            rowData: rowData
        })
        this.toggleFilter()
    }
    onSave = (dataToSave) => {

        if (navigator.onLine) {
            const {
                saveReport,
                showLoador,
                showNotification,
            } = this.props;
            showLoador({ loador: true });
            saveReport({ dataToSave }).then(({ success }) => {
                showLoador({ loador: false });
                if (success) {
                    showNotification({
                        msg: dataToSave.id ? msgConstant.deletemsg : msgConstant.savemsg,
                    });

                } else {
                    showNotification({
                        msg: msgConstant.saveFailedMsg,
                        severity: "error",
                    });
                }
            });
        } else {
            showNotification({
                msg: msgConstant.noInternetMsg,
                severity: "error",
            });
        }
    };
    render() {
        const {
            report
        } = this.props;

        const { parameterRequired, rowData, openDialog } = this.state;

        return (
            <>

                <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={2}
                    columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                    container
                    justifyContent="right"
                >
                    <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name="Add Filter"
                        // fullWidth={true}
                        onClick={this.toggleFilter}
                    />

                </Grid>
                <br />
                <RTTable
                    columns={[
                        { title: "Sr. No.", name: "index", showInExcel: true, align: "center" },
                        {
                            title: "Label",
                            name: "label",
                            formDataKey: "label",
                            showInExcel: true,
                            canSearch: true,
                        },
                        {
                            title: "Datakey",
                            name: "dataKey",
                            formDataKey: "dataKey",
                            showInExcel: true,
                            canSearch: true,
                        },
                        {
                            title: "Control type",
                            name: "controlType",
                            formDataKey: "controlType",
                            showInExcel: true,
                            canSearch: true,
                            align: "right",
                        },
                        {
                            title: "URL",
                            name: "url",
                            formDataKey: "url",
                            showInExcel: true,
                            canSearch: true,
                            align: "right",
                        },
                        { name: "action", title: "Action" },
                    ]}
                    hiddenColumnNames={[]}
                    tableData={parameterRequired}
                    rowDelete={this.rowDelete}
                    showPegination={false}
                    showEditIcon={true}
                    rowEdit={this.rowEdit}
                    isActionColActive={true}
                />
                <br />
                <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={2}
                    columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                    container
                    justifyContent="right"
                >

                    <ButtonCompo
                        size="medium"
                        type="submit"
                        variant="contained"
                        name="Save and Next"
                        // fullWidth={true}
                        onClick={this.onFormSave}
                    />


                </Grid>
                {openDialog &&
                    <AddFilter rowData={rowData} parameterRequired={parameterRequired} openDialog={openDialog} setOpenDialouge={this.toggleFilter}
                    />
                }
            </>
        );
    }
}
const mapStateToProps = (state) => ({ report: state.report });
const mapDispatchToProps = { showLoador, showNotification, saveReport };
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AddFilterForm);
