import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  checkPublicationDate: [],
  publishRateConfigCurrent: [],
  publishRateConfigFuture: [],
  publishRateConfigPrevious: [],
  publishRateConfigHistory: [],
  isFetch: false,
};
let URL = endpoints.checkPublicationDate;
const publishRateConfigurationSlice = createSlice({
  name: "checkPublicationDate",
  initialState,
  reducers: {
    publishRateConfigurationSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        checkPublicationDate: row,
        isFetch: true,
      };
    },
    publishRateConfigurationCurrentSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        publishRateConfigCurrent: row,
        isFetch: true,
      };
    },
    publishRateConfigurationFutureSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        publishRateConfigFuture: row,
        isFetch: true,
      };
    },

    publishRateConfigurationSearchSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        publishRateConfigPrevious: row,
        isFetch: true,
      };
    },

    publishRateConfigurationHistorySuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        publishRateConfigHistory: row,
        isFetch: true,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  publishRateConfigurationSuccess,
  publishRateConfigurationCurrentSuccess,
  publishRateConfigurationFutureSuccess,
  publishRateConfigurationSearchSuccess,
  publishRateConfigurationHistorySuccess,
  resetState,
} = publishRateConfigurationSlice.actions;

export default publishRateConfigurationSlice.reducer;
export const checkPublicationDateByRateConfiguration =
  ({ ProductRateConfigurationIds, publishDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-product-rate-configurations-and-publish-date?ProductRateConfigurationIds=" +
          ProductRateConfigurationIds +
          "&publishDate=" +
          publishDate,
      }).then(({ data, success }) => {
        if (success) {
          const { content } = data.data;
          const row = content.map((publishRateConfigurationObject, index) => {
            let publishRateConfigurationData = {
              index: index + 1,
              id:
                publishRateConfigurationObject.id === null
                  ? ""
                  : publishRateConfigurationObject.id,
              name:
                publishRateConfigurationObject.name === null
                  ? ""
                  : publishRateConfigurationObject.name,
              stockStakeHolderTypeName:
                publishRateConfigurationObject.stockStakeHolderType === null &&
                publishRateConfigurationObject.stockStakeHolderType.name ===
                  null
                  ? ""
                  : publishRateConfigurationObject.stockStakeHolderType.name,

              stockStakeHolderType:
                publishRateConfigurationObject.stockStakeHolderType === null
                  ? ""
                  : publishRateConfigurationObject.stockStakeHolderType.id,
              categoryName:
                publishRateConfigurationObject.category !== null &&
                publishRateConfigurationObject.category.name
                  ? publishRateConfigurationObject.category.name
                  : "",
              category:
                publishRateConfigurationObject.category !== null &&
                publishRateConfigurationObject.category.id
                  ? publishRateConfigurationObject.category.id
                  : "",

              publicationDate:
                publishRateConfigurationObject.publicationDate === null
                  ? ""
                  : publishRateConfigurationObject.publicationDate,
            };
            return publishRateConfigurationData;
          });
          dispatch(publishRateConfigurationSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getpublishRateConfigCurrent = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-current",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map(
          (publishRateConfigurationCurrentObject, index) => {
            let publishRateConfigurationCurrentData = {
              index: index + 1,
              id:
                publishRateConfigurationCurrentObject.id === null
                  ? ""
                  : publishRateConfigurationCurrentObject.id,
              productRateConfiguration:
                publishRateConfigurationCurrentObject.productRateConfiguration ===
                null
                  ? ""
                  : publishRateConfigurationCurrentObject.productRateConfiguration,
              structureName:
                publishRateConfigurationCurrentObject.productRateConfiguration ===
                null
                  ? ""
                  : publishRateConfigurationCurrentObject
                      .productRateConfiguration.name,
              name:
                publishRateConfigurationCurrentObject.productRateConfiguration
                  .stockStakeHolderType === null &&
                publishRateConfigurationCurrentObject.productRateConfiguration
                  .stockStakeHolderType.name === null
                  ? ""
                  : publishRateConfigurationCurrentObject
                      .productRateConfiguration.stockStakeHolderType.name,
              stockStakeHolderType:
                publishRateConfigurationCurrentObject.productRateConfiguration
                  .stockStakeHolderType === null &&
                publishRateConfigurationCurrentObject.productRateConfiguration
                  .stockStakeHolderType.id === null
                  ? ""
                  : publishRateConfigurationCurrentObject
                      .productRateConfiguration.stockStakeHolderType.id,
              publishDate:
                publishRateConfigurationCurrentObject.publishDate === null
                  ? ""
                  : publishRateConfigurationCurrentObject.publishDate,
            };
            return publishRateConfigurationCurrentData;
          }
        );
        dispatch(publishRateConfigurationCurrentSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getpublishRateConfigFuture = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-future",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map(
          (publishRateConfigurationFutureObject, index) => {
            let publishRateConfigurationFutureData = {
              index: index + 1,
              id:
                publishRateConfigurationFutureObject.id === null
                  ? ""
                  : publishRateConfigurationFutureObject.id,
              productRateConfiguration:
                publishRateConfigurationFutureObject.productRateConfiguration ===
                null
                  ? ""
                  : publishRateConfigurationFutureObject.productRateConfiguration,
              structureName:
                publishRateConfigurationFutureObject.productRateConfiguration ===
                null
                  ? ""
                  : publishRateConfigurationFutureObject
                      .productRateConfiguration.name,
              name:
                publishRateConfigurationFutureObject.productRateConfiguration
                  .stockStakeHolderType === null &&
                publishRateConfigurationFutureObject.productRateConfiguration
                  .stockStakeHolderType.name === null
                  ? ""
                  : publishRateConfigurationFutureObject
                      .productRateConfiguration.stockStakeHolderType.name,
              stockStakeHolderType:
                publishRateConfigurationFutureObject.productRateConfiguration
                  .stockStakeHolderType === null &&
                publishRateConfigurationFutureObject.productRateConfiguration
                  .stockStakeHolderType.id === null
                  ? ""
                  : publishRateConfigurationFutureObject
                      .productRateConfiguration.stockStakeHolderType.id,
              publishDate:
                publishRateConfigurationFutureObject.publishDate === null
                  ? ""
                  : publishRateConfigurationFutureObject.publishDate,
            };
            return publishRateConfigurationFutureData;
          }
        );
        dispatch(publishRateConfigurationFutureSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getpublishRateConfigSearch =
  ({ stockStakeHolderTypeIds, categoryIds }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-previous-by-stake-holder-type-and-categories?stockStakeHolderTypeId=" +
          stockStakeHolderTypeIds +
          "&categoryIds=" +
          categoryIds,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map(
            (publishRateConfigurationPreviousObject, index) => {
              let publishRateConfigurationPreviousData = {
                index: index + 1,
                id:
                  publishRateConfigurationPreviousObject.id === null
                    ? ""
                    : publishRateConfigurationPreviousObject.id,
                productRateConfiguration:
                  publishRateConfigurationPreviousObject.productRateConfiguration ===
                  null
                    ? ""
                    : publishRateConfigurationPreviousObject.productRateConfiguration,
                structureName:
                  publishRateConfigurationPreviousObject.productRateConfiguration ===
                  null
                    ? ""
                    : publishRateConfigurationPreviousObject
                        .productRateConfiguration.name,
                name:
                  publishRateConfigurationPreviousObject
                    .productRateConfiguration.stockStakeHolderType === null &&
                  publishRateConfigurationPreviousObject
                    .productRateConfiguration.stockStakeHolderType.name === null
                    ? ""
                    : publishRateConfigurationPreviousObject
                        .productRateConfiguration.stockStakeHolderType.name,
                stockStakeHolderType:
                  publishRateConfigurationPreviousObject
                    .productRateConfiguration.stockStakeHolderType === null &&
                  publishRateConfigurationPreviousObject
                    .productRateConfiguration.stockStakeHolderType.id === null
                    ? ""
                    : publishRateConfigurationPreviousObject
                        .productRateConfiguration.stockStakeHolderType.id,
                publishDate:
                  publishRateConfigurationPreviousObject.publishDate === null
                    ? ""
                    : publishRateConfigurationPreviousObject.publishDate,
              };

              return publishRateConfigurationPreviousData;
            }
          );
          dispatch(publishRateConfigurationSearchSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getpublishRateApplicableConfiguration =
  ({ productRateConfigurationId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-product-rate-configuration?productRateConfigurationId=" +
          productRateConfigurationId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map(
            (publishRateConfigurationCurrentObject, index) => {
              let publishRateConfigurationCurrentData = {
                index: index + 1,
                id:
                  publishRateConfigurationCurrentObject.id === null
                    ? ""
                    : publishRateConfigurationCurrentObject.id,
                productRateConfiguration:
                  publishRateConfigurationCurrentObject.productRateConfiguration ===
                  null
                    ? ""
                    : publishRateConfigurationCurrentObject.productRateConfiguration,
                stockStakeHolderType:
                  publishRateConfigurationCurrentObject.productRateConfiguration ===
                  null
                    ? ""
                    : publishRateConfigurationCurrentObject
                        .productRateConfiguration.stockStakeHolderType.name,
                category:
                  publishRateConfigurationCurrentObject.productRateConfiguration ===
                  null
                    ? ""
                    : publishRateConfigurationCurrentObject
                        .productRateConfiguration.category.name,
                name:
                  publishRateConfigurationCurrentObject.productRateConfiguration ===
                  null
                    ? ""
                    : publishRateConfigurationCurrentObject
                        .productRateConfiguration.name,
                dateForMrpRate:
                  publishRateConfigurationCurrentObject.productRateConfiguration ===
                  null
                    ? ""
                    : publishRateConfigurationCurrentObject
                        .productRateConfiguration.dateForMrpRate,
                rateType:
                  publishRateConfigurationCurrentObject.productRateConfiguration ===
                  null
                    ? ""
                    : publishRateConfigurationCurrentObject
                        .productRateConfiguration.rateType,
                publishDate:
                  publishRateConfigurationCurrentObject.publishDate === null
                    ? ""
                    : publishRateConfigurationCurrentObject.publishDate,
              };
              return publishRateConfigurationCurrentData;
            }
          );
          dispatch(publishRateConfigurationHistorySuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
