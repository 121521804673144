import React from "react";
import { connect } from "react-redux";
import { PlaceOrderTableJson } from "../../DynamicFormsJson/MastersJSON/PlaceOrderTable";
import {
  getPlaceOrderSearchListHeaderDetails,
  getTodaysPlaceOrder,
} from "../../Slice/OrderHeaderList.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import endpoint from "../../config/endpoints";
import {
  creditLimitMsg,
  noInternetMsg,
  sendOrder,
  sendPlacedOrderMsg,
  serverMsg,
  statusmsg,
} from "../../config/messageconstant";
import { PlaceOrderColumns } from "../../tableColumns/table-columns";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";

class PlaceOrderTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromdate: "",
      todate: "",
      comment: "",
      popUpFlag: false,
      DataToSet: [],
      creditLimit: 0,
      companyFlag: "",
    };
  }
  async componentDidMount() {
    const { companyFlag } = this.state;
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {

      const showCompanyFlag = localStorage.getItem("showCompanyFlag");
      this.setState({
        companyFlag: showCompanyFlag,
      });

      if (showCompanyFlag == 1) {
        showLoador({ loador: true });
        apiGet({
          url: endpoint.sapCreditLimit,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({
              msg: creditLimitMsg,
              severity: "error",
            });
          } else {
            if (Object.keys(data.data).length !== 0) {
              if (data.error) {
                showNotification({ msg: serverMsg, severity: "error" });
              } else {
                this.setState({
                  DataToSet: [
                    {
                      xs: 12,
                      sm: 4,
                      md: 4,
                      lg: 4,
                      label: "Credit Limit",
                      value: data.data.balance * -1,
                    },
                  ],
                  creditLimit: data.data.balance,
                });
              }
            }
          }
        });
      }

    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }

    await this.getData();
  }
  getData = async () => {
    const { getTodaysPlaceOrder, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getTodaysPlaceOrder().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onSearchList = (data) => {
    const {
      getPlaceOrderSearchListHeaderDetails,
      showLoador,
      showNotification,
    } = this.props;

    this.setState({
      fromdate: data.fromdate,
      todate: data.todate,
    });
    if (navigator.onLine) {
      showLoador({ loador: true });
      getPlaceOrderSearchListHeaderDetails({
        fromdate: data.fromdate,
        todate: data.todate,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  rowPDF = (rowData) => {
    const { showNotification } = this.props;
    downloadF({
      url:
        endpoint.stockStakeholderOrderHeader +
        "/report-by-id?documentType=1&id=" +
        rowData.id,
      ext: "pdf",
      openNewTab: true,
    }).then((response) => {
      if (!response) {
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      } else {
      }
    });
  };

  onChangeStatusStatic = (row) => {
    console.log(row);
    const { getTodaysPlaceOrder, showLoador, showNotification } = this.props;
    const { fromdate, todate, comment, creditLimit, companyFlag } = this.state;

    let newCreditLimit = creditLimit * -1;
    console.log(newCreditLimit);
    if (+row.totalAmt > newCreditLimit && companyFlag == 1) {
      showNotification({
        msg: sendPlacedOrderMsg,
        severity: "error",
      });
    } else {
      if (row.id) {
        swal({
          text: sendOrder,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            if (navigator.onLine) {
              showLoador({ loador: true });
              apiGet({
                url:
                  endpoint.stockHolderOrderingConfiguration +
                  "/is-show-send-button",
              }).then(({ data, success }) => {
                if (success) {
                  showLoador({ loador: false });
                  if (data.data.statusCode == 500) {
                    showLoador({ loador: false });
                    showNotification({
                      msg: "Order sending duration has already exceeded, Due to which unable to send order to the superior",
                      severity: "error",
                    });
                  } else {
                    showLoador({ loador: true });
                    apiGet({
                      url:
                        endpoint.stockStakeholderOrderHeader +
                        "/update-status?ids=" +
                        row.id +
                        "&status=1&remark=" +
                        row.remark,
                    }).then(({ success }) => {
                      if (success) {
                        showLoador({ loador: false });
                        showNotification({
                          msg: statusmsg,
                        });
                        this.getData();
                      } else {
                        showNotification({
                          msg: serverMsg,
                          severity: "error",
                        });
                        showLoador({ loador: false });
                      }
                    });
                  }
                } else {
                  showNotification({
                    msg: serverMsg,
                    severity: "error",
                  });
                  showLoador({ loador: false });
                }
              });
            } else {
              showNotification({ msg: noInternetMsg, severity: "error" });
            }
          }
        });
      }
    }
  };

  render() {
    const { orderList } = this.props;
    const { comment, popUpFlag, DataToSet } = this.state;

    return (
      <>
        <DynamicMainScreen
          deleteMsgAuto={true}
          showViewButtonInTable={true}
          showDeleteIcon={false}
          searchList={PlaceOrderTableJson.searchList}
          onSearchData={this.onSearchList}
          formPathView={PlaceOrderTableJson.formPathView}
          formPath={PlaceOrderTableJson.formPath}
          screenTitle={PlaceOrderTableJson.screenTitle}
          fieldMeta={PlaceOrderTableJson.fieldMeta}
          showPdfDownload={false}
          showExcelDownload={false}
          isActiveURL={PlaceOrderTableJson.isActiveURL}
          showAddButton={PlaceOrderTableJson.showAddButton}
          tableColumns={PlaceOrderColumns}
          tableData={orderList.placeOrderByDate}
          apiBaseURL={PlaceOrderTableJson.apiBaseURL}
          callApi={false}
          getTableDataById={true}
          showSearchBox={true}
          rowPDF={this.rowPDF}
          showPdf={false}
          showPegination={false}
          onChangeStatusStatic={this.onChangeStatusStatic}
          editConditionName="status"
          editConditionValue="0"
          chipCompoColor={true}
          getTableData={this.getData}
          DataToSet={DataToSet}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  orderList: state.orderHeaderList,
});

const mapDispatchToProps = {
  showNotification,
  showLoador,
  getPlaceOrderSearchListHeaderDetails,
  getTodaysPlaceOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaceOrderTable);
