import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutValidation";
import { blackColor, checkboxColor, redColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  checkedListEmptyMsg,
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { mandatoryCheck, ValidationCheck } from "../../config/ValidationCheck";
import { settleBillFormJSON } from "../../DynamicFormsJson/Transaction/settleBill";
import { getAreaByUserType } from "../../Slice/area.slice";
import { getBeatByArea } from "../../Slice/beat.slice";
import { getOutletByBeatId, getOutletByUserType } from "../../Slice/outlet.slice";
import {
  getPaymentReceiptTransaction,
  getSettleBillWithPaymentList,
  resetData,
  setBillData,
} from "../../Slice/settleBill.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import PaymentModeDialog from "./PaymentModeDialog";
class SettleBillForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      outletId: "",
      paymentRecTransId: "",
      isErrorListEmpty: false,
      DataToSet: [],
      dynamicMasterData: {
        areaDetail: this.props.areaList.area,
        beatDetail: this.props.beatList.beat,
        outletDetail: this.props.outletList.outletByUserType,
        paymentRecTranDetail:
          this.props.settleBillList.paymentReceiptTransactionData,
      },
      formErrors: {},
      fieldDataForModule: {},
      fieldData: {},
      openOutletDialog: false,
      value: "0",
      totalRemainingAmt: 0,
      totalReceivedAmt: 0,
      billHeaderList: [],
      remAmt: 0,
    };
  }

  handleChange = (event) => {
    this.props.setBillData({ row: [] });
    this.setState({
      value: event.target.value,
      formErrors: {},
      fieldData: {},
      DataToSet: [
        {
          xs: 12,
          sm: 4,
          md: 4,
          lg: 4,
          label: "Remaining Amount ",
          value: 0,
        },
      ],
    });
  };

  componentWillReceiveProps(nextProps) {
    // if (this.props.areaList !== nextProps.areaList) {
    //   if (this.props.areaList?.area !== nextProps.areaList?.area) {
    //     this.setState({
    //       dynamicMasterData: {
    //         ...this.state.dynamicMasterData,
    //         areaDetail: nextProps.areaList?.area,
    //       },
    //     });
    //   }
    // }

    // if (this.props.beatList !== nextProps.beatList) {
    //   if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
    //     this.setState({
    //       dynamicMasterData: {
    //         ...this.state.dynamicMasterData,
    //         beatDetail: nextProps.beatList?.beat,
    //       },
    //     });
    //   }
    // }

    if (this.props.outletList !== nextProps.outletList) {
      if (this.props.outletList?.outletByUserType !== nextProps.outletList?.outletByUserType) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            outletDetail: nextProps.outletList?.outletByUserType,
          },
        });
      }
    }

    if (this.props.settleBillList !== nextProps.settleBillList) {
      if (
        this.props.settleBillList?.paymentReceiptTransactionData !==
        nextProps.settleBillList?.paymentReceiptTransactionData
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            paymentRecTranDetail:
              nextProps.settleBillList?.paymentReceiptTransactionData,
          },
        });
      }
    }
  }

  getListById = async (data) => {
    const {
      getBeatByArea,
      showLoador,
      showNotification,
      getOutletByBeatId,
      getPaymentReceiptTransaction,
      resetData,
    } = this.props;
    if (navigator.onLine) {
      // if (data.area) {
      //   showLoador({ loador: true });
      //   await getBeatByArea({ areaId: data.area ? data.area : 0 }).then(
      //     ({ success }) => {
      //       showLoador({ loador: false });
      //       if (!success) {
      //         showNotification({ msg: serverMsg, severity: "error" });
      //       }
      //     }
      //   );
      // }
      // if (data.beat) {
      //   showLoador({ loador: true });
      //   await getOutletByBeatId({
      //     beatId: data.beat.id,
      //   }).then(({ response, success }) => {
      //     showLoador({ loador: false });
      //     if (!success) {
      //       showNotification({ msg: serverMsg, severity: "error" });
      //     } else {
      //     }
      //   });
      // }

      if (data.outlet) {
        showLoador({ loador: true });
        await getPaymentReceiptTransaction({
          outletId: data.outlet.id,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        });
      }

      if (data.paymentRecTran == "") {
        resetData();
        this.setState({
          DataToSet: [
            {
              xs: 12,
              sm: 4,
              md: 4,
              lg: 4,
              label: "Remaining Amount ",
              value: 0,
            },
          ],
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  async componentDidMount() {
    const { getOutletByUserType, showLoador, showNotification, setBillData } =
      this.props;
    if (navigator.onLine) {
      setBillData({ row: [] });
      showLoador({ loador: true });
      await getOutletByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });


      const moduleObjData = localStorage.getItem("moduleObj");

      this.setState({
        fieldDataForModule:
          moduleObjData != null ? JSON.parse(moduleObjData) : {},
      });

    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  onSearch = async (data) => {
    const {
      getSettleBillWithPaymentList,
      showLoador,
      showNotification,
      settleBillList,
    } = this.props;
    const { value } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getSettleBillWithPaymentList({
        outletId: data.outlet.id,
      }).then(({ response, success, selectedList }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          if (value == 0) {
            const settleBillRemainingAmt =
              settleBillList.paymentReceiptTransactionData.filter((rowData) => {
                if (rowData.id == data.paymentRecTran.id) {
                  let amountData = {
                    settleAmt: rowData.settleRemainingAmt,
                    receivedAmt: rowData.amt,
                  };
                  return amountData;
                }
              });
            if (settleBillRemainingAmt.length != 0) {
              this.setState({
                DataToSet: [
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    label: "Remaining Amount ",
                    value:
                      value == 0
                        ? (+settleBillRemainingAmt[0]
                          .settleRemainingAmt).toFixed(2)
                        : +data.amount.toFixed(2),
                  },
                ],
                totalRemainingAmt: settleBillRemainingAmt[0].settleRemainingAmt,
                totalReceivedAmt: settleBillRemainingAmt[0].amt,
                paymentRecTransId: data.paymentRecTran.id,
                isErrorListEmpty: false,
              });
            }
          } else {
            this.setState({
              DataToSet: [
                {
                  xs: 12,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  label: "Remaining Amount ",
                  value: data.amount,
                },
              ],
              totalRemainingAmt: data.amount,
              totalReceivedAmt: data.amount,
              outletId: data.outlet.id,
              isErrorListEmpty: false,
            });
          }
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  checkValidationOnSubmit = () => {
    const { fieldData, value } = this.state;
    const metaData =
      value == 0
        ? settleBillFormJSON.fieldMeta
        : settleBillFormJSON.fieldMetaForPayment;
    const mandatoryCheckErrors = mandatoryCheck({
      fieldData,
      fieldMeta: metaData,
    });
    this.setState({
      formErrors: mandatoryCheckErrors.formErrors,
    });
    if (mandatoryCheckErrors.formValid) {
      const ValidationCheckErrors = ValidationCheck({
        fieldData,
        fieldMeta: metaData,
      });
      this.setState({
        formErrors: ValidationCheckErrors.formErrors,
      });
      return ValidationCheckErrors.formValid;
    } else {
      return false;
    }
  };
  onFormSave = () => {
    if (this.checkValidationOnSubmit()) {
      const { fieldData, value } = this.state;
      const metaData =
        value == 0
          ? settleBillFormJSON.fieldMeta
          : settleBillFormJSON.fieldMetaForPayment;
      const dataToSave = {
        isActive: 1,
        // currentOperationMode === OperationMode.Add ? "1" : fieldData.isActive,
      };

      if (fieldData["id"]) {
        dataToSave["id"] = fieldData["id"];
      }

      metaData.forEach((currentField) => {
        if (currentField.controlType === "multiSelect") {
          let multiselectValue = fieldData[currentField.dataKey]
            ? fieldData[currentField.dataKey].join()
            : "";

          dataToSave[currentField.dataKey] = multiselectValue;
        } else if (currentField.controlType === "hideMultiSelect") {
          if (fieldData[currentField.tableDataKey] == currentField.open) {
            let multiselectValue = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey].join()
              : "";

            dataToSave[currentField.dataKey] = multiselectValue;
          }
        } else if (currentField.controlType === "timePicker") {
          let timeValue =
            fieldData[currentField.dataKey] === ""
              ? ""
              : moment(fieldData[currentField.dataKey], "h:mm:ss A").format(
                "HH:mm"
              );
          dataToSave[currentField.dataKey] = timeValue ? timeValue : "";
        } else if (currentField.controlType === "datepicker") {
          if (fieldData[currentField.dataKey]) {
            let date = new Date(fieldData[currentField.dataKey]);

            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();

            dataToSave[currentField.dataKey] = day + "-" + month + "-" + year;
          } else {
            dataToSave[currentField.dataKey] = "";
          }
        } else if (
          currentField.controlType === "hideTextfield" &&
          !currentField.rootValue
        ) {
          if (fieldData[currentField.tableDataKey] === currentField.open) {
            dataToSave[currentField.tableDataKey] = fieldData[
              currentField.dataKey
            ]
              ? fieldData[currentField.dataKey]
              : "";
          }
        } else if (currentField.controlType === "hideDatepicker") {
          if (fieldData[currentField.tableDataKey] === currentField.open) {
            if (fieldData[currentField.dataKey]) {
              let date = new Date(fieldData[currentField.dataKey]);

              let day = date.getDate();
              let month = date.getMonth() + 1;
              let year = date.getFullYear();

              fieldData[currentField.dataKey] = day + "-" + month + "-" + year;
            }

            dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
              ? fieldData[currentField.dataKey]
              : "";
          }
        } else {
          dataToSave[currentField.dataKey] = fieldData[currentField.dataKey]
            ? fieldData[currentField.dataKey]
            : "";
        }
      });

      this.onSearch(dataToSave);
    }
  };
  onCheckBoxClick = (billHeaderId, e) => {
    const { totalRemainingAmt, totalReceivedAmt } = this.state;
    const { settleBillList, setBillData } = this.props;
    let remAmt = 0;
    let billHeaderIdList = [];
    const setBeatList = settleBillList?.settleBillWithPaymentData.map(
      (billListObject) => {
        if (billHeaderId == billListObject.id) {
          if (e.target.checked) {
            remAmt = +totalRemainingAmt - +billListObject.remainingAmt;
            this.setState({
              DataToSet: [
                {
                  xs: 12,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  label: "Remaining Amount ",
                  value: remAmt <= 0 ? 0 : remAmt.toFixed(2),
                },
              ],
              totalRemainingAmt: remAmt,
            });
            billListObject = {
              ...billListObject,
              checked: true,
            };
            return billListObject;
          } else {
            remAmt = +totalRemainingAmt + +billListObject.totalAmt;
            this.setState({
              DataToSet: [
                {
                  xs: 12,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  label: "Remaining Amount ",
                  value: remAmt <= 0 ? 0 : remAmt.toFixed(2),
                },
              ],
              totalRemainingAmt: remAmt,
            });
            billListObject = {
              ...billListObject,
              checked: false,
            };
            return billListObject;
          }
        } else {
          return billListObject;
        }
      }
    );
    let checkedList = [];
    checkedList = setBeatList.map((billListObject) => {
      if (remAmt <= 0) {
        if (billListObject.checked) {
          billListObject = {
            ...billListObject,
            disabled: false,
          };
          return billListObject;
        } else {
          billListObject = {
            ...billListObject,
            disabled: true,
          };
          return billListObject;
        }
      } else {
        if (billListObject.checked) {
          billListObject = {
            ...billListObject,
            disabled: false,
          };
          return billListObject;
        } else {
          billListObject = {
            ...billListObject,
            disabled: false,
          };
          return billListObject;
        }
      }
    });
    checkedList.filter((rowData) => {
      if (rowData.checked == true) {
        billHeaderIdList.push(rowData.id);
      }
    });
    this.setState({
      billHeaderList: billHeaderIdList,
    });
    this.setState({
      remAmt: remAmt,
    });
    setBillData({ row: checkedList });
  };

  saveData = (DialogBoxData) => {
    const { openOutletDialog, billHeaderList, totalReceivedAmt, outletId } =
      this.state;
    let dataToSave = {
      outLet: { id: outletId },
      amt: totalReceivedAmt,
      settleRemainingAmt: totalReceivedAmt,
      ...DialogBoxData,
    };
    this.onSave(dataToSave, billHeaderList.join(","));
    this.setState({
      openOutletDialog: !openOutletDialog,
    });
  };

  onSave = async (objectToSave, billHeaderIds) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      await showLoador({ loador: true });
      apiPost({
        url:
          endpoint.outletBillHeader +
          "/settle-bill-direct-payment?outletBillHeaderIds=" +
          billHeaderIds,
        postBody: objectToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg, severity: "success" });
          window.location.replace("/settle-bill");
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSubmit = () => {
    const { openOutletDialog, billHeaderList, value, paymentRecTransId } =
      this.state;
    if (this.props.settleBillList?.settleBillWithPaymentData.length != 0) {
      if (billHeaderList.length != 0) {
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            if (value == 0) {
              const { showLoador, showNotification } = this.props;
              if (navigator.onLine) {
                showLoador({ loador: true });
                await apiPost({
                  url:
                    endpoint.outletBillHeader +
                    "/settle-bill?outletBillHeaderIds=" +
                    billHeaderList.join(",") +
                    "&paymentRecieptOutletId=" +
                    paymentRecTransId,
                }).then(({ data, success }) => {
                  showLoador({ loador: false });
                  if (success) {
                    showNotification({ msg: savemsg, severity: "success" });
                    window.location.replace("/settle-bill");
                  } else {
                    showNotification({ msg: saveFailedMsg, severity: "error" });
                  }
                  return success;
                });
              } else {
                this.props.showNotification({
                  msg: noInternetMsg,
                  severity: "error",
                });
              }
            } else {
              this.setState({
                openOutletDialog: !openOutletDialog,
              });
            }
          }
        });
      } else {
        this.setState({
          isErrorListEmpty: true,
        });
      }
    } else {
      this.setState({
        isErrorListEmpty: true,
      });
    }
  };

  handleClosesForOutlet = () => {
    const { openOutletDialog } = this.state;
    this.setState({
      openOutletDialog: !openOutletDialog,
    });
  };
  onDataChange = (fieldName, newValue) => {
    const { fieldData, value } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    const metaData =
      value == 0
        ? settleBillFormJSON.fieldMeta
        : settleBillFormJSON.fieldMetaForPayment;
    metaData.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        // dataToSearch[fieldName] = newValue;
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };
  render() {
    const {
      dynamicMasterData,
      openOutletDialog,
      DataToSet,
      value,
      totalReceivedAmt,
      isErrorListEmpty,
      formErrors,
      remAmt,
      fieldData,
      fieldDataForModule,
    } = this.state;
    const { settleBillList } = this.props;
    console.log(remAmt)
    console.log(remAmt <= 0)
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            showTitle={settleBillFormJSON.showTitle}
            screenTitle={settleBillFormJSON.screenTitle}
            showSaveBtnMain={false}
            showAddButton={false}
          />
          <br />

          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={this.handleChange}
              row
            >
              <FormControlLabel
                value="0"
                control={<Radio />}
                label="Settle Bill"
              />
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Settle Bill with Payment"
              />
            </RadioGroup>
            <br />
          </FormControl>
          <DynamicFormWithoutSave
            dynamicMasterData={dynamicMasterData}
            getListById={this.getListById}
            searchList={settleBillFormJSON.searchList}
            showSaveBtn={settleBillFormJSON.showSaveBtn}
            showTitle={false}
            screenTitle={settleBillFormJSON.screenTitle}
            fieldMeta={
              value == 0
                ? settleBillFormJSON.fieldMeta
                : remAmt <= 0
                  ? settleBillFormJSON.fieldMetaForPayment
                  : [
                    ...settleBillFormJSON.fieldMetaForPayment,
                    {
                      label: "Amount",
                      controlType: "textfield",
                      placeHolder: "Amount",
                      inputType: "number",
                      md: 4,
                      lg: 4,
                      sm: 4,
                      xs: 12,
                      dataKey: "amount",
                      isMandatory: true,
                      disableTrue: true,

                    },
                  ]
            }
            showCancel={settleBillFormJSON.showCancel}
            apiBaseURL={settleBillFormJSON.apiBaseURL}
            showSaveNextBtn={settleBillFormJSON.showSaveNextBtn}
            saveBtnText={"Search"}
            onFormSave={this.onFormSave}
            fieldData={fieldData}
            formErrors={formErrors}
            onDataChange={this.onDataChange}
            disableCondition={
              settleBillList?.settleBillWithPaymentData.filter(
                (row) => row.checked
              ).length != 0
                ? true
                : false
            }
          />
          <DynamicDetails DataToSet={DataToSet} />

          {isErrorListEmpty && (
            <Grid item sm={12} xs={12} md={12} lg={12} justifyContent="right">
              <LabelCompo
                className="text-black"
                style={{ fontSize: 12, color: redColor }}
                label={checkedListEmptyMsg}
              />
            </Grid>
          )}
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ width: "5%" }}></TableCell>
                  <TableCell align={"center"} sx={{ width: "5%" }}>
                    Sr. No.
                  </TableCell>
                  <TableCell align="center" sx={{ width: "20%" }}>
                    Bill No.
                  </TableCell>
                  <TableCell align="center" sx={{ width: "20%" }}>
                    Bill Date
                  </TableCell>
                  <TableCell align="center" sx={{ width: "25%" }}>
                    Bill Amount
                  </TableCell>
                  <TableCell align="center" sx={{ width: "25%" }}>
                    Remaining Amount
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {settleBillList?.settleBillWithPaymentData.map(
                  (matrixDataRow, indexRow) => {
                    return (
                      <TableRow>
                        <TableCell>
                          <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                              <FormControlLabel
                                value="end"
                                control={
                                  <Checkbox
                                    sx={{
                                      color: blackColor,
                                      "&.Mui-checked": {
                                        color: checkboxColor,
                                      },
                                    }}
                                    checked={matrixDataRow.checked}
                                    disabled={matrixDataRow.disabled}
                                  />
                                }
                                label=""
                                onChange={(e) => {
                                  this.onCheckBoxClick(matrixDataRow.id, e);
                                }}
                                labelPlacement="start"
                              />
                            </FormGroup>
                          </FormControl>
                        </TableCell>
                        <TableCell align="center">
                          {matrixDataRow.index}
                        </TableCell>
                        <TableCell align="center">
                          {matrixDataRow.billNo}
                        </TableCell>
                        <TableCell align="center">
                          {matrixDataRow.billDate}
                        </TableCell>
                        <TableCell align="right">
                          {matrixDataRow.totalAmt}
                        </TableCell>
                        <TableCell align="right">
                          {matrixDataRow.remainingAmt}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <>
            <br />
            {fieldDataForModule.editReject == 1 ? (

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Submit"
                  onClick={this.onSubmit}
                />
              </Grid>) : ("")}
          </>
        </Paper>
        {openOutletDialog && (
          <PaymentModeDialog
            open={openOutletDialog}
            handleCloses={this.handleClosesForOutlet}
            saveData={this.saveData}
            totalReceivedAmt={totalReceivedAmt}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  areaList: state.area,
  beatList: state.beat,
  outletList: state.outlet,
  settleBillList: state.settleBill,
});
const mapDispatchToProps = {
  getAreaByUserType,
  getOutletByBeatId,
  getOutletByUserType,
  showLoador,
  showNotification,
  setBillData,
  getBeatByArea,
  getPaymentReceiptTransaction,
  getSettleBillWithPaymentList,
  resetData,
};
export default connect(mapStateToProps, mapDispatchToProps)(SettleBillForm);
