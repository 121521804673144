import { Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { compose } from "@reduxjs/toolkit";
import { rateConfigurationJson } from "../../DynamicFormsJson/Transaction/RateConfiguration";
import { getCategory } from "../../Slice/category.slice";
import {
    getProductRateConfigurationByCategoryIdAndDate,
    getProductRateConfigurationByCategoryIdAndDateForEdit,
    setRateConfList,
} from "../../Slice/productRateConfiguration.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import MaterialUITableWithTextField from "../../components/Comman/RT/MaterialUITableWithTextField";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import {
    noInternetMsg,
    serverMsg
} from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
class RateConfigurationView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameErr: "",
            fieldData: {},
            DataToSet: [],
            dynamicMasterData: {
                stockStakeHolderTypeDetail:
                    this.props.stockStakeHolderType?.stockStakeHolderType,
                categoryDetail: this.props.categoryList?.category,
            },
        };
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.stockStakeHolderType !== nextProps.stockStakeHolderType) {
            if (
                this.props.stockStakeHolderType?.stockStakeHolderType !==
                nextProps.stockStakeHolderType?.stockStakeHolderType
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        stockStakeHolderTypeDetail:
                            nextProps.stockStakeHolderType?.stockStakeHolderType,
                    },
                });
            }
        }
        if (this.props.categoryList !== nextProps.categoryList) {
            if (
                this.props.categoryList?.category !== nextProps.categoryList?.category
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        categoryDetail: nextProps.categoryList?.category,
                    },
                });
            }
        }
    }

    async componentDidMount() {
        const {
            getStockStakeHolderType,
            getCategory,
            getProductRateConfigurationByCategoryIdAndDateForEdit,
            showLoador,
            showNotification,
        } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            await getStockStakeHolderType({ userTypeIds: 2 }).then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
            showLoador({ loador: true });
            await getCategory().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
            if (this.props.params.id) {
                showLoador({ loador: true });
                await getProductRateConfigurationByCategoryIdAndDateForEdit({
                    productRateConfigurationHeaderId: this.props.params.id,
                }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    } else {
                    }
                });
            }
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    getDataById = (id) => {
        const { showLoador } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            const response = apiGet({
                url: rateConfigurationJson.apiBaseURL + "/" + id,
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (success) {
                    if (!data.error) {
                        let rowData = data.data;

                        this.setState({
                            fieldData: rowData,
                            DataToSet: [
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Stock Holder Type",
                                    value:
                                        rowData.stockStakeHolderType !== null &&
                                            rowData.stockStakeHolderType.name !== null
                                            ? rowData.stockStakeHolderType.name
                                            : "-",
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Category",
                                    value:
                                        rowData.category !== null && rowData.category.name
                                            ? rowData.category.name
                                            : "-",
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Reference Date ",
                                    value:
                                        rowData.dateForMrpRate !== null
                                            ? rowData.dateForMrpRate
                                            : "-",
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Type",
                                    value:
                                        rowData.rateType != null && rowData.rateType == 0
                                            ? "Rate Inclusive"
                                            : "Rate Exclusive",
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Configuration Name",
                                    value:
                                        rowData.name !== null
                                            ? rowData.name
                                            : "-",
                                },
                            ],
                        });
                        return { data: rowData, success };
                    }
                    return { data: {}, success: false };
                }

                return { data: {}, success: false };
            });
            return response;
        } else {
            this.props.showNotification({
                msg: noInternetMsg,
                severity: "error",
            });
        }
    };

    render() {
        const { DataToSet, fieldData, nameErr } = this.state;
        const { dynamicMasterData } = this.state;
        const { setRateConfList, productRateConfiguration } = this.props;
        return (
            <>
                <Paper
                    sx={{
                        padding: 2,
                        borderRadius: 2,
                    }}
                    elevation={0}
                >
                    <DynamicFormWithoutSave
                        showBackToList={true}
                        DataToSet={DataToSet}
                        dynamicMasterData={dynamicMasterData}
                        getByIdApi={true}
                        callApi={true}
                        getDataById={this.getDataById}
                        showTitle={rateConfigurationJson.showTitle}
                        screenTitle={rateConfigurationJson.screenTitle}
                        fieldMeta={[]}
                        showCancel={rateConfigurationJson.showCancel}
                        apiBaseURL={rateConfigurationJson.apiBaseURL}
                        showSaveNextBtn={rateConfigurationJson.showSaveNextBtn}
                        showSaveBtnMain={false}
                        showSaveBtn={false}
                    />
                    <br />

                    <MaterialUITableWithTextField
                        tableHead={[
                            {
                                title: "Sr. No.",
                                name: "index",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "center",
                            },
                            {
                                title: "Product Name",
                                name: "name",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "left",
                            },
                            {
                                title: "MRP",
                                name: "mrp",

                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "right",
                                placeHolder: "Enter Remark",
                            },
                            {
                                title: "Percentage",
                                name: "marginPercentage",
                                placeHolder: "Enter Percentage",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "right",
                            },
                            {
                                title: "Projected Rate",
                                name: "rate",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "right",
                            },
                        ]}
                        data={productRateConfiguration.product}
                        setList={setRateConfList}
                        isActionColActive={false}
                    />
                    <br />
                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    stockStakeHolderType: state.stockStakeHolderType,
    categoryList: state.category,
    productRateConfiguration: state.productRateConfiguration,
});
const mapDispatchToProps = {
    showLoador,
    showNotification,
    getStockStakeHolderType,
    getCategory,
    getProductRateConfigurationByCategoryIdAndDate,
    setRateConfList,
    getProductRateConfigurationByCategoryIdAndDateForEdit,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(RateConfigurationView);
