import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
    assets: [],
    assetsRequestDetails: [],
};

let URL = endpoints.assets;
let URL1 = endpoints.assetsRequestDetails;


const assetsSlice = createSlice({
    name: "assets",
    initialState,
    reducers: {
        assetsSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                assets: row,
            };
        },

        assetsRequestDetailSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                assetsRequestDetails: row,
            };
        },

        resetState: (state = cloneDeep(initialState), action) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const {
    assetsSuccess,
    assetsRequestDetailSuccess,
    resetState,
} = assetsSlice.actions;

export default assetsSlice.reducer;

export const getAssets = () => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL,
        }).then(({ data, success }) => {
            if (success) {
                const { content } = data.data;
                const row = content.map((assetsObj, index) => {
                    let areaData = {
                        index: index + 1,
                        id: assetsObj.id === null ? "" : assetsObj.id,
                        name:
                            assetsObj.name === null
                                ? ""
                                : assetsObj.name,
                        type:
                            assetsObj.type !== null
                                ? assetsObj.type
                                : "",
                        description:
                            assetsObj.description === null ? "" : assetsObj.description,
                        isActive:
                            assetsObj.isActive === null ? "" : assetsObj.isActive,
                        imageMandatory:
                            assetsObj.imageMandatory === null ? "" : assetsObj.imageMandatory,
                    };
                    return areaData;
                });
                dispatch(assetsSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};


export const getAssetsRequestDetailsByAssetsAndRequestStatus = ({ assetIds, requestStatus }) => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL1 + "/by-assets-and-request-status?assetIds=" + assetIds + "&requestStatus=" + requestStatus,
        }).then(({ data, success }) => {
            if (success) {
                const row = data.data.map((assetsRequestDetailObj, index) => {
                    let assetsRequestData = {
                        index: index + 1,
                        id: assetsRequestDetailObj.id === null ? "" : assetsRequestDetailObj.id,
                        outletId: assetsRequestDetailObj.outlet !== null && assetsRequestDetailObj.outlet.id !== null
                            ? assetsRequestDetailObj.outlet.id : "",
                        firmName:
                            assetsRequestDetailObj.outlet !== null && assetsRequestDetailObj.outlet.firmName !== null
                                ? assetsRequestDetailObj.outlet.firmName : "",
                        assetsName:
                            assetsRequestDetailObj.assets !== null && assetsRequestDetailObj.assets.name !== null
                                ? assetsRequestDetailObj.assets.name : "",
                        vender: "",
                        cost: "0",
                        isChecked: false,
                        assetsQuantity: assetsRequestDetailObj.assetsQuantity !== null ? assetsRequestDetailObj.assetsQuantity : "",

                    };
                    return assetsRequestData;
                });
                dispatch(assetsRequestDetailSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};



export const getAssetsRequestDetailsByAreaOutletsAndRequestStatus = ({ JsonData }) => async (dispatch) => {
    try {

        const response = apiPost({
            url: URL1 + "/by-area-outlets-and-request-status",
            postBody: JsonData,
        }).then(({ data, success }) => {
            if (success) {
                const row = data.data.map((assetsRequestDetailObj, index) => {
                    let assetsRequestData = {
                        index: index + 1,
                        id: assetsRequestDetailObj.id === null ? "" : assetsRequestDetailObj.id,
                        outletId: assetsRequestDetailObj.outlet !== null && assetsRequestDetailObj.outlet.id !== null
                            ? assetsRequestDetailObj.outlet.id : "",
                        firmName:
                            assetsRequestDetailObj.outlet !== null && assetsRequestDetailObj.outlet.firmName !== null
                                ? assetsRequestDetailObj.outlet.firmName : "",
                        assetsName:
                            assetsRequestDetailObj.assets !== null && assetsRequestDetailObj.assets.name !== null
                                ? assetsRequestDetailObj.assets.name : "",
                        vender: "",
                        cost: "0",
                        isChecked: false,
                        assetsQuantity: assetsRequestDetailObj.assetsQuantity !== null ? assetsRequestDetailObj.assetsQuantity : "",
                    };
                    return assetsRequestData;
                });
                dispatch(assetsRequestDetailSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};




export const setAssetsRequestDetails =
    ({ row }) =>
        async (dispatch) => {
            dispatch(assetsRequestDetailSuccess({ row }));
        };




export const getAssetsRequestDetails = ({ requestStatus }) => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL1 + "/with-assets-implementaion-details-by-request-status?requestStatus=" + requestStatus,
        }).then(({ data, success }) => {
            if (success) {
                const row = data.data.map((assetsRequestDetailObj, index) => {
                    let assetsRequestData = {
                        index: index + 1,
                        id: assetsRequestDetailObj.id === null ? "" : assetsRequestDetailObj.id,
                        outletId: assetsRequestDetailObj.outlet !== null && assetsRequestDetailObj.outlet.id !== null
                            ? assetsRequestDetailObj.outlet.id : "",
                        firmName:
                            assetsRequestDetailObj.outlet !== null && assetsRequestDetailObj.outlet.firmName !== null
                                ? assetsRequestDetailObj.outlet.firmName : "",
                        assetsId
                            : assetsRequestDetailObj.assets === null ? "" : assetsRequestDetailObj.assets.id,
                        assetsName: assetsRequestDetailObj.assets === null ? "" : assetsRequestDetailObj.assets.name,
                        assetsQuantity: assetsRequestDetailObj.assetsQuantity === null ? "" : assetsRequestDetailObj.assetsQuantity,
                        implementaionDate: assetsRequestDetailObj.implementaionDate === null ? "" : assetsRequestDetailObj.implementaionDate,
                        requestDate: assetsRequestDetailObj.requestDate !== null ? assetsRequestDetailObj.requestDate : "",
                        requestActionDate: assetsRequestDetailObj.requestActionDate !== null ? assetsRequestDetailObj.requestActionDate : "",
                        venderId: assetsRequestDetailObj.venderId !== null ? assetsRequestDetailObj.venderId : "",
                        venderName: assetsRequestDetailObj.venderName !== null ? assetsRequestDetailObj.venderName : "",
                        implementaionCost: assetsRequestDetailObj.implementaionCost === null ? "" : assetsRequestDetailObj.implementaionCost,
                        isActive: assetsRequestDetailObj.isActive === null ? "" : assetsRequestDetailObj.isActive,
                        isChecked: false,
                    };
                    return assetsRequestData;
                });
                dispatch(assetsRequestDetailSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};



export const getAssetsRequestDetailsGetAll = () => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL1 + "/get-all-with-assets-implementaion-details",
        }).then(({ data, success }) => {
            if (success) {
                const row = data.data.map((assetsRequestDetailObj, index) => {
                    let assetsRequestData = {
                        index: index + 1,
                        id: assetsRequestDetailObj.id === null ? "" : assetsRequestDetailObj.id,
                        outletId: assetsRequestDetailObj.outlet !== null && assetsRequestDetailObj.outlet.id !== null
                            ? assetsRequestDetailObj.outlet.id : "",
                        firmName:
                            assetsRequestDetailObj.outlet !== null && assetsRequestDetailObj.outlet.firmName !== null
                                ? assetsRequestDetailObj.outlet.firmName : "",
                        assetsId
                            : assetsRequestDetailObj.assets === null ? "" : assetsRequestDetailObj.assets.id,
                        assetsName: assetsRequestDetailObj.assets === null ? "" : assetsRequestDetailObj.assets.name,
                        assetsQuantity: assetsRequestDetailObj.assetsQuantity === null ? "" : assetsRequestDetailObj.assetsQuantity,
                        implementaionDate: assetsRequestDetailObj.implementaionDate === null ? "" : assetsRequestDetailObj.implementaionDate,
                        requestDate: assetsRequestDetailObj.requestDate !== null ? assetsRequestDetailObj.requestDate : "",
                        requestActionDate: assetsRequestDetailObj.requestActionDate !== null ? assetsRequestDetailObj.requestActionDate : "",
                        venderId: assetsRequestDetailObj.venderId !== null ? assetsRequestDetailObj.venderId : "",
                        venderName: assetsRequestDetailObj.venderName !== null ? assetsRequestDetailObj.venderName : "",
                        implementaionCost: assetsRequestDetailObj.implementaionCost === null ? "" : assetsRequestDetailObj.implementaionCost,
                        isActive: assetsRequestDetailObj.isActive === null ? "" : assetsRequestDetailObj.isActive,
                        requestStatus: assetsRequestDetailObj.requestStatus === null ? "" : assetsRequestDetailObj.requestStatus,
                        isChecked: false,
                    };
                    return assetsRequestData;
                });
                dispatch(assetsRequestDetailSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};







