import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  subAdminUser: [],
  stackholderNameByUser: [],
};
let URL = endpoints.developerAdminUser;
const SubAdminUserSlice = createSlice({
  name: "SubAdminUser",
  initialState,
  reducers: {
    subAdminUserSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        subAdminUser: row,
      };
    },
    stakeHolderNameByUserSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        stackholderNameByUser: row,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { subAdminUserSuccess, stakeHolderNameByUserSuccess, resetState } =
  SubAdminUserSlice.actions;

export default SubAdminUserSlice.reducer;

export const getSubAdminUser = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.content.map((subAdminUserObject, index) => {
          let subAdminUserData = {
            index: index + 1,
            id: subAdminUserObject.id === null ? "" : subAdminUserObject.id,
            userId:
              subAdminUserObject.userId === null
                ? ""
                : subAdminUserObject.userId,
            userName:
              subAdminUserObject.userName === null
                ? ""
                : subAdminUserObject.userName,
            name:
              subAdminUserObject.name === null ? "" : subAdminUserObject.name,
            stockStakeHolderTypeId:
              subAdminUserObject.stockStakeHolderType === null
                ? ""
                : subAdminUserObject.stockStakeHolderType.id,
            stockStakeHolderType:
              subAdminUserObject.stockStakeHolderType === null
                ? ""
                : subAdminUserObject.stockStakeHolderType.name,
            contactNo:
              subAdminUserObject.contactNo === null
                ? ""
                : subAdminUserObject.contactNo,
            emailId:
              subAdminUserObject.emailId === null
                ? ""
                : subAdminUserObject.emailId,
            isActive:
              subAdminUserObject.isActive === null
                ? ""
                : subAdminUserObject.isActive,
            accessRoleId:
              subAdminUserObject.accessRoleId === null
                ? ""
                : subAdminUserObject.accessRoleId,
            accessRoleJson:
              subAdminUserObject.accessRoleJson === null
                ? ""
                : subAdminUserObject.accessRoleJson,
          };
          return subAdminUserData;
        });
        dispatch(subAdminUserSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getStakeHolderNameByUserType =
  ({ userTypeIds, reverseTrue = false }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-user-type-ids?userTypeIds=" + userTypeIds,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((stakeHolderTypeObj, index) => {
            const stakeHolderTypeData = {
              index: index + 1,
              id: stakeHolderTypeObj.id === null ? "" : stakeHolderTypeObj.id,
              userId:
                stakeHolderTypeObj.userId === null
                  ? ""
                  : stakeHolderTypeObj.userId,
              userName:
                stakeHolderTypeObj.userName === null
                  ? ""
                  : stakeHolderTypeObj.userName,
              name:
                stakeHolderTypeObj.name === null ? "" : stakeHolderTypeObj.name,
              stockStakeHolderTypeId:
                stakeHolderTypeObj.stockStakeHolderType === null
                  ? ""
                  : stakeHolderTypeObj.stockStakeHolderType.id,
              stockStakeHolderType:
                stakeHolderTypeObj.stockStakeHolderType === null
                  ? ""
                  : stakeHolderTypeObj.stockStakeHolderType.name,
              contactNo:
                stakeHolderTypeObj.contactNo === null
                  ? ""
                  : stakeHolderTypeObj.contactNo,
              emailId:
                stakeHolderTypeObj.emailId === null
                  ? ""
                  : stakeHolderTypeObj.emailId,
              isActive:
                stakeHolderTypeObj.isActive === null
                  ? ""
                  : stakeHolderTypeObj.isActive,
              accessRoleId:
                stakeHolderTypeObj.accessRoleId === null
                  ? ""
                  : stakeHolderTypeObj.accessRoleId,
              accessRoleJson:
                stakeHolderTypeObj.accessRoleJson === null
                  ? ""
                  : stakeHolderTypeObj.accessRoleJson,
            };
            return stakeHolderTypeData;
          });
          if (reverseTrue) {
            dispatch(stakeHolderNameByUserSuccess({ row: row.reverse() }));
          } else {
            dispatch(stakeHolderNameByUserSuccess({ row: row }));
          }
          return { response: [], success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
