import endpoint from "../../config/endpoints";

export const companyCategoryConfigurationJson = {
    showSaveNextBtn: true,
    apiBaseURL: endpoint.companyCategoryConfiguration,
    screenTitle: "Company Category Configuration",
    formPath: "/company-category-configuration-form",
    formPathView: "/company-category-configuration-view",
    showBackToList: true,
    showTitle: false,
    showAddButton: true,
    fieldMetaAdmin: [
        {
            label: "Region",
            controlType: "autocomplete",
            placeHolder: "Select Region",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "regionDetail",
            dataKey: "region",
            getListId: "region",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "State",
            controlType: "autocomplete",
            placeHolder: "Select State",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "stateDetail",
            dataKey: "state",
            getListFrom: "region",
            getListId: "state",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Zone",
            controlType: "multiSelect",
            placeHolder: "Select Zone",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "zoneDetail",
            dataKey: "zoneIds",
            getListFrom: "state",
            getListId: "zoneIds",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "District",
            controlType: "multiSelect",
            placeHolder: "Select District",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "districtDetail",
            dataKey: "districtIds",
            getListFrom: "zoneIds",
            getListId: "districtIds",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Taluka",
            controlType: "multiSelect",
            placeHolder: "Select Taluka",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "talukaDetail",
            dataKey: "talukaIds",
            getListFrom: "districtIds",
            getListId: "talukaIds",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Area",
            controlType: "multiSelect",
            placeHolder: "Select Area",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "areaDetail",
            dataKey: "areaIds",
            getListFrom: "talukaIds",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Name",
            controlType: "textfield",
            placeHolder: "Enter Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "name",
            isMandatory: true,
        },
        {
            label: "Description",
            controlType: "textfield",
            placeHolder: "Enter Description",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "description",
            isMandatory: true,
        },

        {
            label: "Type",
            controlType: "radiogroup",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "type",
            tableDataKey: "type",
            defaultValue: "1",
            radioGroupItems: [
                {
                    label: "Date Range",
                    value: "1",
                },
                {
                    label: "Continuos Occuring",
                    value: "0",
                },

            ],
            isMandatory: true,
        },
        {
            label: "From Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "fromDate",
            isMandatory: true,
            onSubmit: "compare",
            onSubmitParameter: "toDate-le",
        },
        {
            label: "To Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "toDate",
            onSubmit: "compare",
            onSubmitParameter: "fromDate-ge",
            isMandatory: true,
            onSubmit: "compare",
        },
    ],
    fieldMeta: [
        {
            label: "Name",
            controlType: "textfield",
            placeHolder: "Enter Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "name",
            isMandatory: true,
        },
        {
            label: "Description",
            controlType: "textfield",
            placeHolder: "Enter Description",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "description",
            isMandatory: true,
        },
        {
            label: "Area",
            controlType: "multiSelect",
            placeHolder: "Select Area",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "areaDetail",
            dataKey: "areaIds",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Type",
            controlType: "radiogroup",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "type",
            tableDataKey: "type",
            defaultValue: "0",
            radioGroupItems: [
                {
                    label: "Date Range",
                    value: "1",
                },
                {
                    label: "Continuos Occuring",
                    value: "0",
                },

            ],
            isMandatory: true,
        },
        {
            label: "From Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "fromDate",
            isMandatory: true,
            onSubmit: "compare",
            onSubmitParameter: "toDate-le",
        },
        {
            label: "To Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "toDate",
            onSubmit: "compare",
            onSubmitParameter: "fromDate-ge",
            isMandatory: true,
        },
    ],
    fieldMetaDetails: [
        {
            label: "Category",
            controlType: "autocomplete",
            placeHolder: "Select Category ",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "categoryDetail",
            dataKey: "category",
            isRootLevelKey: true,
            isMandatory: true,
            getListId: "category",
        },
        {
            label: "Product ",
            controlType: "autocomplete",
            placeHolder: "Select Product",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "productDetail",
            dataKey: "product",
            getListFrom: "category",
            isRootLevelKey: true,
            getListId: "product",
            isMandatory: true,
            getData: true,
        },
    ],
};
