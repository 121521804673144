import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg } from "../../config/messageconstant";
import { generatedBillJSON } from "../../DynamicFormsJson/MastersJSON/generatedBill";
import {
  getBillHeaderId,
  genratedBillHeader,
} from "../../Slice/orderBillList.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
class ListOfGeneratedBillView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
      billingData: [],
    };
    this.columns = [
      {
        title: "Sr.No.",
        name: "index",
        align: "center",
      },
      {
        title: "Product Name",
        name: "productName",
      },
      {
        title: "UOM",
        name: "stakeHolderUom",
        align: "center",
      },
      {
        title: "Rate",
        name: "rate",
        align: "right",
      },
      {
        title: "Bill Quantity",
        name: "billQty",
        align: "right",
      },
      {
        title: "Order Quantity",
        name: "qty",
        align: "right",
      },
      {
        title: "Free Quantity",
        name: "freeQty",
        align: "right",
      },
      {
        title: "Discount % On Item",
        name: "itemDiscPer",
        align: "right",
      },
      {
        title: "Total Amount",
        name: "totalAmt",
        align: "right",
      },
    ];
  }

  async componentDidMount() {
    if (navigator.onLine) {
      if (this.props.params.id) {
        const {
          showLoador,
          showNotification,
          getBillHeaderId,
          genratedBillHeader,
        } = this.props;
        showLoador({ loador: true });
        await getBillHeaderId({
          billHeaderId: this.props.params.id,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.setState({
              billingData:
                response.stockStakeHolderBillDetails.length != 0
                  ? response.stockStakeHolderBillDetails
                  : [],
            });
          }
        });

        await genratedBillHeader({
          billHeaderId: this.props.params.id,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.setState({
              headerData: response,
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Firm Name",
                  value:
                    response.fromBillFirmName !== null
                      ? response.fromBillFirmName
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Owner Name",
                  value:
                    response.fromBillOwnerName !== null
                      ? response.fromBillOwnerName
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Bill Number",
                  value:
                    response.billNo !== null && response.billNo !== null
                      ? response.billNo
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Bill Date",
                  value:
                    response.billDate !== null && response.billDate !== null
                      ? response.billDate
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Taxable Amount",
                  value:
                    response.taxableAmt !== null && response.taxableAmt !== null
                      ? response.taxableAmt
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Total Amount",
                  value:
                    response.totalAmt !== null && response.totalAmt !== null
                      ? response.totalAmt
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Total Tax",
                  value:
                    response.cgstAmt !== null && response.sgstAmt !== null
                      ? (+response.cgstAmt + +response.sgstAmt).toFixed(2)
                      : "",
                },
              ],
            });
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  render() {
    const { dynamicMasterData, billingData, DataToSet } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            DataToSet={DataToSet}
            dynamicMasterData={dynamicMasterData}
            showTitle={true}
            screenTitle={"Generated Bill View"}
            apiBaseURL={generatedBillJSON.apiBaseURL}
            saveBtnText={"Search"}
            showSaveBtnMain={false}
          />

          <RTTable
            columns={this.columns}
            tableData={billingData}
            isActionColActive={false}
            showPegination={false}
          />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getBillHeaderId,
  genratedBillHeader,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ListOfGeneratedBillView);
