import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { SubCategoryColumns, ProductColumnsForPopUp } from "../../../tableColumns/table-columns";
import { subCategoryJson } from "../../../DynamicFormsJson/MastersJSON/Subcategory";
import { getSubCategory } from "../../../Slice/subCategory.slice";

class SubCategoryTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { subCategoryList, getSubCategory } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={subCategoryJson.formPath}
          screenTitle={subCategoryJson.screenTitle}
          fieldMeta={subCategoryJson.fieldMeta}
          showPdfDownload={false}
          showExcelDownload={true}
          changeExcelURL={subCategoryJson.excelUrl}
          showExtension={false}
          pdfFileName={subCategoryJson.pdfFileName}
          excelFileName={subCategoryJson.excelFileName}
          showAddButton={subCategoryJson.showAddButton}
          tableColumnsToFilter={subCategoryJson.tableColumnsToFilter}
          tableColumns={SubCategoryColumns}
          tableData={subCategoryList?.subCategory}
          getTableData={getSubCategory}
          apiBaseURL={subCategoryJson.apiBaseURL}
          isNote={true}
          isNoteValue={"Add Various Categories Of Products"}
          showDeleteIcon={false}
          showPopUpDataList={[
            {
              dataKey: "productCount",
              columns: ProductColumnsForPopUp,
              title: "Product List",
              header: {
                dataKey: "name",
                label: "Sub-Category",
              },
            },
          ]}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  subCategoryList: state.subCategory,
});
const mapDispatchToProps = {
  getSubCategory,
};
export default connect(mapStateToProps, mapDispatchToProps)(SubCategoryTable);
