import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  genratedBill: [],
};
let URL = endpoints.stockStakeholderBillHeader;
const genratedBillSlice = createSlice({
  name: "genratedBill",
  initialState,
  reducers: {
    genratedBillSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        genratedBill: row,
        isFetch: true,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});
export const { genratedBillSuccess, resetState } = genratedBillSlice.actions;
export default genratedBillSlice.reducer;
// export const getGenratedBillList =
//   ({ billHolderIds, fromDate, toDate }) =>
//   async (dispatch) => {
//     try {
//       const response = apiGet({
//         url:
//           URL +
//           "/generated-bills?billHolderIds=" +
//           billHolderIds +
//           "&fromDate=" +
//           fromDate +
//           "&toDate=" +
//           toDate,
//       }).then(({ data, success }) => {
//         if (success) {
//           const row = data.data.map((genratedBillListObject, index) => {
//             let genratedBillListData = {
//               index: index + 1,
//               id:
//                 genratedBillListObject.id === null
//                   ? ""
//                   : genratedBillListObject.id,
//               billNo:
//                 genratedBillListObject.billNo === null
//                   ? ""
//                   : genratedBillListObject.billNo,
//               fromBill:
//                 genratedBillListObject.fromBill === null
//                   ? ""
//                   : genratedBillListObject.fromBill,
//               toBill:
//                 genratedBillListObject.toBill === null
//                   ? ""
//                   : genratedBillListObject.toBill,

//               billDate:
//                 genratedBillListObject.billDate === null
//                   ? ""
//                   : genratedBillListObject.billDate,
//               itemDiscountAmt:
//                 genratedBillListObject.itemDiscountAmt === null
//                   ? ""
//                   : genratedBillListObject.itemDiscountAmt,
//               discountOnbillPer:
//                 genratedBillListObject.discountOnbillPer === null
//                   ? ""
//                   : genratedBillListObject.discountOnbillPer,
//               discountOnbillPerAmt:
//                 genratedBillListObject.discountOnbillPerAmt === null
//                   ? ""
//                   : genratedBillListObject.discountOnbillPerAmt,
//               discountOnbillAmt:
//                 genratedBillListObject.discountOnbillAmt === null
//                   ? ""
//                   : genratedBillListObject.discountOnbillAmt,
//               cessAmt:
//                 genratedBillListObject.cessAmt === null
//                   ? ""
//                   : genratedBillListObject.cessAmt,
//               payoutAmt:
//                 genratedBillListObject.payoutAmt === null
//                   ? ""
//                   : genratedBillListObject.payoutAmt,
//               taxableAmt:
//                 genratedBillListObject.taxableAmt === null
//                   ? ""
//                   : genratedBillListObject.taxableAmt.toFixed(2),
//               totalAmt:
//                 genratedBillListObject.totalAmt === null
//                   ? ""
//                   : genratedBillListObject.totalAmt.toFixed(2),
//               cgstAmt:
//                 genratedBillListObject.cgstAmt === null
//                   ? ""
//                   : genratedBillListObject.cgstAmt.toFixed(2),
//               sgstAmt:
//                 genratedBillListObject.sgstAmt === null
//                   ? ""
//                   : genratedBillListObject.sgstAmt.toFixed(2),
//               igstAmt:
//                 genratedBillListObject.igstAmt === null
//                   ? ""
//                   : genratedBillListObject.igstAmt.toFixed(2),
//               isRecievedName:
//                 genratedBillListObject.isRecieved === null
//                   ? ""
//                   : genratedBillListObject.isRecieved == 0
//                   ? "No"
//                   : "Yes",
//               isRecieved:
//                 genratedBillListObject.isRecieved === null
//                   ? ""
//                   : genratedBillListObject.isRecieved,
//               acknoledgeNo:
//                 genratedBillListObject.acknoledgeNo === null
//                   ? ""
//                   : genratedBillListObject.acknoledgeNo,
//               payoutIsSettle:
//                 genratedBillListObject.payoutIsSettle === null
//                   ? ""
//                   : genratedBillListObject.payoutIsSettle,
//               remainingAmt:
//                 genratedBillListObject.remainingAmt === null
//                   ? ""
//                   : genratedBillListObject.remainingAmt,
//               isSettle:
//                 genratedBillListObject.isSettle === null
//                   ? ""
//                   : genratedBillListObject.isSettle,
//               roundOff:
//                 genratedBillListObject.roundOff === null
//                   ? ""
//                   : genratedBillListObject.roundOff,
//               insertedUserId:
//                 genratedBillListObject.insertedUserId === null
//                   ? ""
//                   : genratedBillListObject.insertedUserId,
//               insertDateTime:
//                 genratedBillListObject.insertDateTime === null
//                   ? ""
//                   : genratedBillListObject.insertDateTime,
//             };
//             return genratedBillListData;
//           });
//           dispatch(genratedBillSuccess({ row }));
//           return { response: row, success };
//         }
//         return { response: [], success };
//       });
//       return response;
//     } catch (e) {
//       return console.error(e.message);
//     }
//   };
