import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicReportGenerate";
import { DistributionReportsJson } from "../../DynamicFormsJson/Transaction/distributionReports";
import { getAreaByUserType } from "../../Slice/area.slice";
import { getOutletByBeatId } from "../../Slice/outlet.slice";
import { getBeatByArea } from "../../Slice/beat.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { serverMsg, noInternetMsg } from "../../config/messageconstant";

class DistributionReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        areaDetail: this.props.areaList.area,
        beatDetail: this.props.beatList.beat,
        outletDetail: this.props.outletList.outlets,
        stockStakeHolderTypeDetail:
          this.props.stockStakeHolderType?.stockStakeHolderType,
      },
    };
  }

  async componentDidMount() {
    const {
      getAreaByUserType,
      getStockStakeHolderType,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getAreaByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getStockStakeHolderType({ userTypeIds: "3,4" }).then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        }
      );
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.area !== nextProps.areaList?.area) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.area,
          },
        });
      }
    }

    if (this.props.beatList !== nextProps.beatList) {
      if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            beatDetail: nextProps.beatList?.beat,
          },
        });
      }
    }

    if (this.props.outletList !== nextProps.outletList) {
      if (this.props.outletList?.outlets !== nextProps.outletList?.outlets) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            outletDetail: nextProps.outletList?.outlets,
          },
        });
      }
    }
    if (this.props.stockStakeHolderType !== nextProps.stockStakeHolderType) {
      if (
        this.props.stockStakeHolderType?.stockStakeHolderType !==
        nextProps.stockStakeHolderType?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stockStakeHolderTypeDetail:
              nextProps.stockStakeHolderType?.stockStakeHolderType,
          },
        });
      }
    }
  }

  getListById = async (data) => {
    const { getBeatByArea, getOutletByBeatId, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
    if (data.area) {
      
      showLoador({ loador: true });
      await getBeatByArea({ areaId: data.area }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    }
    if (data.beat) {
     
      showLoador({ loador: true });
      await getOutletByBeatId({ beatId: data.beat }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    }
  } else {
    showNotification({ msg: noInternetMsg, severity: "error" });
  }
  };

  rowData = [
    {
      index: 1,
      reportName: "Projection Report (D0, D5, D6)",
      filter: "area,fromDate,toDate",
      url: "/api/distribution/v1/sales-excutive-beat-projection-header/roaster-excel-report?areaId={area}&fromDate={fromDate}&toDate={toDate}",
    },
    {
      index: 2,
      reportName: "Check In - Out Report (D4, D5, D6)",
      filter: "stockStakeHolderTypeId,fromDate,toDate",
      url: "/api/distribution/v1/check-in-out/by-stock-stake-holder-type-and-from-to-date-report?stockStakeHolderTypeId={stockStakeHolderTypeId}&fromDate={fromDate}&toDate={toDate}",
    },
    {
      index: 3,
      reportName: "Outlet Wise Order Report (D0, D2, D3, D5, D6)",
      filter: "area,beat,outletIds,fromDate,toDate",
      url: "/api/distribution/v1/order/header-excel-by-date-and-outlet?fromDate={fromDate}&toDate={toDate}&outletIds={outletIds}",
    },
    {
      index: 4,
      reportName: "Outet Wise Order Detail Report (D0, D2, D3, D5, D6)",
      filter: "area,beat,outletIds,fromDate,toDate",
      url: "/api/distribution/v1/order/detail-and-header-excel-by-date-and-outlet?fromDate={fromDate}&toDate={toDate}&outletIds={outletIds}",
    },
    {
      index: 5,
      reportName: "Sales List (D4)",
      filter: "stockStakeHolderTypeId",
      url: "/api/distribution/v1/sales-team/excel-by-stock-stake-holder-type-and-assign-area?stockStakeHolderTypeId={stockStakeHolderTypeId}",
    },
    {
      index: 6,
      reportName: "Sales Team List With Target Amount And Actual Amount (D1, D4, D5, D6)",
      filter: "areaIds,fromDate,toDate,stockStakeHolderTypeId",
      url: "/api/distribution/v1/order/sales-team-excel-with-target-and-actual-amt?areaIds={areaIds}&fromdate={fromDate}&todate={toDate}&stockStakeHolderTypeId={stockStakeHolderTypeId}",
    }, 
    
  ];

  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          screenTitle={DistributionReportsJson.screenTitle}
          fieldMeta={DistributionReportsJson.fieldMeta}
          buttonCenter={DistributionReportsJson.buttonCenter}
          dynamicMasterData={dynamicMasterData}
          getListById={this.getListById}
          searchButton={DistributionReportsJson.searchButton}
          PDFButton={DistributionReportsJson.PDFButton}
          EXCELButton={DistributionReportsJson.EXCELButton}
          baseIdColumn={DistributionReportsJson.baseIdColumn}
          apiBaseURL={DistributionReportsJson.apiBaseURL}
          marginTop={DistributionReportsJson.marginTop}
          openNewTab={true}
          rowData={this.rowData}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  areaList: state.area,
  outletList: state.outlet,
  beatList: state.beat,
  stockStakeHolderType: state.stockStakeHolderType,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getAreaByUserType,
  getBeatByArea,
  getOutletByBeatId,
  getStockStakeHolderType,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributionReports);
