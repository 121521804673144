import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import {
  deleteFailedMsg,
  deletemsg,
  deleteWarningMsg,
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { ButtonCompo } from "../../components/Comman/Button";
import { ManageStockJSON } from "../../DynamicFormsJson/Transaction/manageStock";
import { getCategoryForStakeholder } from "../../Slice/category.slice";
import { getProductByCategoryForStakeHolder } from "../../Slice/product.slice";
import {
  getManageStockById,
  setManageStockDetails,
} from "../../Slice/manageStock.slice";
import { apiDelete, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import MaterialUITableWithTextField from "../../components/Comman/RT/MaterialUITableWithTextField";

class ManageStockEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        categoryDetail: this.props.categoryList.categoryForStakeholder,
        productDetail: this.props.productList.productByCategoryForStakeholder,
      },

      headerData: {},
      DataToSet: [],
      historyData: {},
    };
  }

  async componentDidMount() {
    const {
      getManageStockById,
      showLoador,
      showNotification,
      getCategoryForStakeholder,
    } = this.props;
    if (navigator.onLine) {
      if (this.props.params.id) {
        showLoador({ loador: true });
        await getManageStockById({
          managestockHeaderId: this.props.params.id,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
          } else {
            this.setState({
              headerData: response,
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Consumption No",
                  value:
                    response.consumptionNo !== null
                      ? response.consumptionNo
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Date",
                  value: response.date !== null ? response.date : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "type",
                  value:
                    response.type !== null
                      ? response.type === 0
                        ? "Out-Stock"
                        : "In-Stock"
                      : "",
                },
              ],
            });
          }
        });

        showLoador({ loador: true });
        await getCategoryForStakeholder().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.categoryList.categoryForStakeholder !==
      nextProps.categoryList.categoryForStakeholder
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          categoryDetail: nextProps.categoryList.categoryForStakeholder,
        },
      });
    }
    if (this.props.productList !== nextProps.productList) {
      if (
        this.props.productList?.productByCategoryForStakeholder !==
        nextProps.productList?.productByCategoryForStakeholder
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            productDetail:
              nextProps.productList?.productByCategoryForStakeholder,
          },
        });
      }
    }
  }

  getListById = async (data) => {
    const { getProductByCategoryForStakeHolder, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      if (data.category) {
        showLoador({ loador: true });
        await getProductByCategoryForStakeHolder({
          categoryId: data.category,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };

  getDataForDisabled = (value) => {
    const { dynamicMasterData } = this.state;

    let productList = dynamicMasterData["productDetail"]
      ? dynamicMasterData["productDetail"].filter((row) => row.id == value)
      : [];
    return {
      rate: productList[0].rate,
      mrp: productList[0].mrp,
      uom: productList[0].outletUomName,
      uomConversionValue: productList[0].uomConversionValue,
    };
  };

  onSearchList = (data) => {
    const { productList, manageStockList, showNotification } = this.props;

    if (navigator.onLine) {
      let productName = "";
      let rate = 0;
      let mrp = 0;
      let outLetUom = "";
      let stakeHolderUomName = "";
      let uom = "";
      let uomConversionValue = "";
      productList.productByCategoryForStakeholder.filter((rowData) => {
        if (rowData.id == data.product) {
          productName = rowData.name;
          rate = rowData.rate;
          mrp = rowData.mrp;
          outLetUom = rowData.outletUomName;
          uom = rowData.outletUom;
          stakeHolderUomName = rowData.stakeHolderUomName;
          uomConversionValue = rowData.uomConversionValue;
        }
      });
      let row =
        Object.keys(manageStockList.manageStockById).length != 0
          ? manageStockList.manageStockById.stockStakeHolderConsumptionDetails
          : [];

      let dataToSave = {
        index: row.length + 1,
        productName: productName,
        stakeHolderUomName: stakeHolderUomName,
        outletUomName: outLetUom,
        product: { id: data.product },
        mrp: "",
        type: "",
        rate: rate,
        qty: data.qty,
        batchCode: "",
        totalAmt: (+rate * +data.qty).toFixed(2),
      };

      row = [...row, dataToSave];
      this.setListDetails({
        row: row,
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onSubmit = () => {
    const { manageStockList } = this.props;
    let error = false;
    const rowList = {
      ...manageStockList.manageStockById,
      stockStakeHolderConsumptionDetails:
        manageStockList.manageStockById.stockStakeHolderConsumptionDetails.map(
          (rowObj) => {
            if (rowObj.qty.toString() == "") {
              error = true;
            }
            rowObj = {
              ...rowObj,
              qtyError: rowObj.qty.toString() == "" ? 1 : 0,
            };
            return rowObj;
          }
        ),
    };
    this.props.setManageStockDetails({ row: rowList });
    if (!error) {
      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.onSave(manageStockList.manageStockById);
        }
      });
    }
  };
  onSave = (beatNewObjectToSave) => {
    const { showLoador, showNotification } = this.props;

    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.manageStockHeader,
        postBody: beatNewObjectToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg });
          window.location.replace("/manage-stock");
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  rowDelete = (rowData) => {
    const { showNotification, showLoador, manageStockList } = this.props;
    if (rowData.id) {
      swal({
        title: "Are you sure?",
        text: deleteWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          if (navigator.onLine) {
            showLoador({ loador: true });
            await apiDelete({
              url: endpoint.manageStockDetail + "/" + rowData.id,
            }).then(({ data, success }) => {
              showLoador({ loador: false });
              if (data.data.error == false) {
                showNotification({ msg: deletemsg, severity: "success" });
                const rowList =
                  manageStockList.manageStockById.stockStakeHolderConsumptionDetails.filter(
                    (row) => row.index != rowData.index
                  );
                const row = rowList.map((rowListData, index) => {
                  return { ...rowListData, index: index + 1 };
                });
                this.setListDetails({
                  row: row,
                });
              } else {
                showNotification({ msg: deleteFailedMsg, severity: "error" });
              }
            });
          } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
          }
        }
      });
    } else {
      const rowList =
        manageStockList.manageStockById.stockStakeHolderConsumptionDetails.filter(
          (row) => row.index != rowData.index
        );
      const row = rowList.map((rowListData, index) => {
        return { ...rowListData, index: index + 1 };
      });
      this.setListDetails({
        row: row,
      });
    }
  };
  setListDetails = ({ row }) => {
    const { manageStockList, setManageStockDetails } = this.props;

    let data = {
      ...manageStockList.manageStockById,
      stockStakeHolderConsumptionDetails: row,
    };

    setManageStockDetails({ row: data });
  };

  render() {
    const { dynamicMasterData, DataToSet } = this.state;
    const { manageStockList } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showSaveBtn={true}
            showSaveBtnMain={false}
            screenTitle={"Edit Manage Stock Details"}
            DataToSet={DataToSet}
            getListById={this.getListById}
            dynamicMasterData={dynamicMasterData}
            fieldMeta={ManageStockJSON.fieldMetaDetails}
            apiBaseURL={ManageStockJSON.apiBaseURL}
            saveBtnText={"Add"}
            onSave={this.onSearchList}
            padding={true}
            paddingTop={true}
            showBackToList={true}
            resetAfterSave={true}
            getDataForDisabled={this.getDataForDisabled}
          />

          <MaterialUITableWithTextField
            tableHead={[
              {
                title: "Sr.No.",
                name: "index",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Product Name",
                name: "productName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "UOM",
                name: "outletUomName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Rate",
                name: "rate",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
              },
              {
                title: "Quantity",
                name: "qty",
                placeHolder: "Enter Order Quantity",
                textFieldError: "qtyError",
                error: "Order Quantity",
                textField: true,
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "Total Amount",
                name: "totalAmt",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
              },
              {
                title: "Action",
                name: "total",
                positionCenter: true,
                showInscreen: true,
                alignCenter: "center",
              },
            ]}
            data={
              Object.keys(manageStockList.manageStockById).length != 0
                ? manageStockList.manageStockById
                    .stockStakeHolderConsumptionDetails
                : []
            }
            setList={this.setListDetails}
            isActionColActive={true}
            showHeadDelete={true}
            rowDelete={this.rowDelete}
          />

          <br />

          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
            container
            justifyContent="right"
          >
            <ButtonCompo
              size="medium"
              type="submit"
              variant="contained"
              name="Cancel"
              onClick={() => this.props.navigate(-1)}
            />
            &nbsp;&nbsp;
            <ButtonCompo
              size="medium"
              type="submit"
              variant="contained"
              name="Update"
              onClick={this.onSubmit}
            />
          </Grid>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  categoryList: state.category,
  productList: state.product,
  manageStockList: state.manageStockByDate,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getCategoryForStakeholder,
  getProductByCategoryForStakeHolder,
  getManageStockById,
  setManageStockDetails,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ManageStockEdit);
