import { Checkbox, Grid, Paper } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { projectionJson } from "../../DynamicFormsJson/Transaction/ProjectionForm";
import { getBeatBySalesTeamId } from "../../Slice/beat.slice";
import { getProjection } from "../../Slice/projection.slice";
import { getRegion } from "../../Slice/region.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getAreaByTaluka, getAreaByUserType } from "../../Slice/area.slice";
import { getSalesExecutiveTypeByAreaId } from "../../Slice/salesExecutive.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { blackColor, checkboxColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  beatNotAssignMsg,
  beatSelectMsg,
  noInternetMsg,
  saveFailedMsg,
  saveWarningMsg,
  savemsg,
  serverMsg,
} from "../../config/messageconstant";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";

class ProjectionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
      beatAssignList: [],
      adminFlag: false,
      salesTeamId: "",
      dynamicMasterData: {
        regionDetail: this.props.regionList?.region,
        stateDetail: this.props.stateList?.stateByRegion,
        zoneDetail: this.props.zoneList?.zoneByState,
        districtDetail: this.props.districtList?.districtByZone,
        talukaDetail: this.props.talukaList?.talukaByDistrict,
        areaDetail: this.props.areaList?.areaByTaluka,
        areaUserTypeDetail: this.props.areaList?.area,
        salesExecutiveDetail: this.props.salesExecutive.salesExecutive,
      },
      matrixData: [],
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.projection !== nextProps.projection) {
      if (this.props.projection?.beat !== nextProps.projection?.projection) {
        if (this.props.beatList?.beat.length != 0) {
          this.setMatrix(
            this.props.beatList?.beat,
            nextProps.projection.projection
          );
        }
      }
    }
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.area !== nextProps.areaList?.area
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaUserTypeDetail: nextProps.areaList?.area,
          },
        });
      }
    }
    if (this.props.salesExecutive !== nextProps.salesExecutive) {
      if (
        this.props.salesExecutive?.salesExecutive !==
        nextProps.salesExecutive?.salesExecutive
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            salesExecutiveDetail: nextProps.salesExecutive?.salesExecutive,
          },
        });
      }
    }
  }
  setMatrix = (beatList, projection) => {
    const matList = projection.map((dayObj) => {
      let matObj = {
        ...dayObj,
      };

      const beatListData = beatList.map((beatObj) => {
        let filterBeat = [];
        if (dayObj.beatWithOutletCounts.length != 0) {
          filterBeat = dayObj.beatWithOutletCounts.filter(
            (rowData) => beatObj.beatId == rowData.beat.id
          );
        }
        let beatDataObj = {
          ...beatObj,
          beatName: beatObj.name,
          checked: filterBeat.length != 0 ? true : false,
          disabled: false,
        };
        if (filterBeat.length != 0) {
          beatDataObj = {
            ...beatDataObj,
            id: filterBeat[0].id,
          };
        }
        return beatDataObj;
      });
      matObj = {
        ...matObj,
        beatListData: beatListData,
      };

      return matObj;
    });
    this.setState({
      matrixData: matList,
    });
  };
  async componentDidMount() {
    const { getAreaByUserType, getRegion, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      const userTypeId = localStorage.getItem("userTypeId");

      if (userTypeId == 1 || userTypeId == 6) {
        this.setState({
          adminFlag: true,
        });
      }
        showLoador({ loador: true });
        getAreaByUserType().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } 
        });
      
      getRegion().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
    if (this.props.params.id) {
      this.getDataById(this.props.params.id);
    }
  }
  getDataById = (dayWiseBitAssignId) => {
    const { showLoador } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      const response = apiGet({
        url: endpoint.dayWiseBitAssign + "/" + dayWiseBitAssignId,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          if (!data.error) {
            let rowData = data.data;
            this.setState({
              DataToSet: [
                {
                  xs: 12,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  label: "Sales Executive",
                  value:
                    data.data.salesTeam !== null &&
                      data.data.salesTeam.name !== null
                      ? data.data.salesTeam.name
                      : "",
                },
              ],
              beatAssignList: rowData.beatAssignDaywiseDetail,
            });
            this.onSearch(rowData);

            return { data: rowData, success };
          }
          return { data: {}, success: false };
        }

        return { data: {}, success: false };
      });
      return response;
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onCheckBoxClick = (indexRow, e) => {
    const { matrixData } = this.state;

    const DataToSet = matrixData.map((matrixDataObj, index) => {
      if (index == indexRow) {
        let beatListData = matrixDataObj.beatListData.map((beatObj) => {
          return {
            ...beatObj,
            disabled: e.currentTarget.checked,
            checked: false,
          };
        });
        matrixDataObj = {
          ...matrixDataObj,
          beatListData: beatListData,
        };
        return matrixDataObj;
      }
      return matrixDataObj;
    });
    this.setState({
      matrixData: DataToSet,
    });
  };
  onSelectClick = (indexRow, beatId, e) => {
    const { matrixData } = this.state;
    const DataToSet = matrixData.map((matrixDataObj, index) => {
      if (indexRow == index) {
        const beatListData = matrixDataObj.beatListData.map((beatObj) => {
          return {
            ...beatObj,
            checked:
              beatObj.beatId == beatId
                ? e.currentTarget.checked
                : beatObj.checked,
          };
        });
        matrixDataObj = {
          ...matrixDataObj,
          beatListData: beatListData,
        };
        return matrixDataObj;
      }
      return matrixDataObj;
    });
    this.setState({
      matrixData: DataToSet,
    });
  };
  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTaluka,
      getSalesExecutiveTypeByAreaId,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (data.region && data.region != "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state && data.state != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.zone && data.zone != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.district && data.district != "") {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka && data.taluka != "") {
        showLoador({ loador: true });
        await getAreaByTaluka({ talukaIds: data.taluka }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.area && data.area != "") {
        showLoador({ loador: true });
        await getSalesExecutiveTypeByAreaId({ areaId: data.area }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onSearch = async (data) => {
    const {
      getProjection,
      getBeatBySalesTeamId,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      this.setState({
        salesTeamId: data.salesTeam.id,
        matrixData: [],
      });

      showLoador({ loador: true });
      const BeatListCount = await getBeatBySalesTeamId({
        salesTeamId: data.salesTeam.id,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          if (response.length == 0) {
            this.props.showNotification({
              msg: beatNotAssignMsg,
              severity: "error",
            });
          }
        }
        return response.length;
      });
      if (BeatListCount != 0) {
        showLoador({ loador: true });
        await getProjection({
          salesTeamId: data.salesTeam.id,
          fromDate: data.fromDate,
          toDate: data.toDate,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onSubmit = () => {
    const { matrixData, salesTeamId } = this.state;
    const { showLoador, showNotification } = this.props;
    const DataToSet = matrixData.map((matrixDataObj) => {
      let beatList = [];
      matrixDataObj.beatListData.map((beatObj) => {
        if (beatObj.checked) {
          let beat = {
            beat: {
              id: beatObj.beatId,
            },
          };
          beatList.push(beat);
        }
      });

      let dayData = {
        salesTeam: {
          id: salesTeamId,
        },
        date: matrixDataObj.date,
        userId:
          matrixDataObj.salesTeam != null ? matrixDataObj.salesTeam.userId : "",
        salesExcutiveBeatProjectionDetails: beatList,
      };
      if (matrixDataObj.flag != 0) {
        dayData = {
          ...dayData,
          id: matrixDataObj.flag,
        };
      }

      return dayData;
    });
    if (DataToSet.length != 0) {
      if (navigator.onLine) {
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            showLoador({ loador: true });
            apiPost({
              url: this.props.params.id
                ? endpoint.projection + "/update"
                : endpoint.projection + "/save-list",
              postBody: DataToSet,
            }).then(({ success }) => {
              if (success) {
                showLoador({ loador: false });
                showNotification({
                  msg: savemsg,
                });
                window.location.replace("/projection-form");
              } else {
                showNotification({
                  msg: saveFailedMsg,
                  severity: "error",
                });
                showLoador({ loador: false });
              }
            });
          }
        });
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    } else {
      showNotification({ msg: beatSelectMsg, severity: "error" });
    }
  };
  onCancel = () => {
    this.setState({
      matrixData: [],
    });
  };
  render() {
    const { matrixData, dynamicMasterData, DataToSet, adminFlag } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={false}
            DataToSet={DataToSet}
            dynamicMasterData={dynamicMasterData}
            showTitle={projectionJson.showTitle}
            screenTitle={projectionJson.screenTitle}
            fieldMeta={this.props.params.id ? [] : adminFlag ? projectionJson.fieldMeta:projectionJson.fieldMetaForStockHolder  }
            showCancel={projectionJson.showCancel}
            apiBaseURL={projectionJson.apiBaseURL}
            showSaveNextBtn={projectionJson.showSaveNextBtn}
            saveBtnText={"Search"}
            showSaveBtnMain={false}
            showSaveBtn={true}
            getListById={this.getListById}
            onSave={this.onSearch}
          />
          <br />
          {matrixData.length != 0 && (
            <>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{ width: "5%" }}
                      ></TableCell>
                      <TableCell align="center" sx={{ width: "5%" }}>
                        Date
                      </TableCell>

                      <TableCell align="center" sx={{ width: "95%" }}>
                        Beats
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {matrixData.map((matrixDataRow, indexRow) => {
                      return (
                        <TableRow>
                          <TableCell>
                            <FormControl component="fieldset">
                              <FormGroup aria-label="position" row>
                                <FormControlLabel
                                  value="end"
                                  control={
                                    <Checkbox
                                      sx={{
                                        color: blackColor,
                                        "&.Mui-checked": {
                                          color: checkboxColor,
                                        },
                                      }}
                                    />
                                  }
                                  label="N/A"
                                  onChange={(e) => {
                                    this.onCheckBoxClick(indexRow, e);
                                  }}
                                  labelPlacement="end"
                                />
                              </FormGroup>
                            </FormControl>
                          </TableCell>
                          <TableCell width="10%" align="center">
                            {matrixDataRow.date}
                            {"\n"}
                            {matrixDataRow.beatNames}
                          </TableCell>
                          <TableCell>
                            {matrixDataRow.beatListData.map(
                              (columnName, index) => {
                                return (
                                  <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                      <FormControlLabel
                                        value="end"
                                        control={
                                          <Checkbox
                                            sx={{
                                              color: blackColor,
                                              "&.Mui-checked": {
                                                color: checkboxColor,
                                              },
                                            }}
                                            disabled={columnName.disabled}
                                            checked={columnName.checked}
                                          />
                                        }
                                        label={columnName.beatName}
                                        onChange={(e) => {
                                          this.onSelectClick(
                                            indexRow,
                                            columnName.beatId,
                                            e
                                          );
                                        }}
                                        labelPlacement="end"
                                      />
                                    </FormGroup>
                                  </FormControl>
                                );
                              }
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="medium"
                  type="cancel"
                  variant="contained"
                  name="Clear"
                  onClick={this.onCancel}
                />
                &nbsp;&nbsp;
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Submit"
                  onClick={this.onSubmit}
                />
              </Grid>
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  areaList: state.area,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  beatList: state.beat,
  salesExecutive: state.salesExecutive,
  projection: state.projection,
});
const mapDispatchToProps = {
  showLoador,
  getRegion,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTaluka,
  getStateByRegion,
  showNotification,
  getSalesExecutiveTypeByAreaId,
  getBeatBySalesTeamId,
  getProjection,
  getAreaByUserType,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ProjectionForm);
