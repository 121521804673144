import React from "react";
import { connect } from "react-redux";
import { ManageStockJSON } from "../../DynamicFormsJson/Transaction/manageStock";
import {
  getManageStockList,
  setManageStockList,
} from "../../Slice/manageStock.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import endpoint from "../../config/endpoints";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { MangeStockListColumns } from "../../tableColumns/table-columns";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";
import { apiGet } from "../../utils/api_service";

class manageStockTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyFlagOFC: false,
    };
  }

  async componentDidMount() {
    const {
      setManageStockList,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      setManageStockList({ row: [] });

      const showCompanyFlag = localStorage.getItem("showCompanyFlag");

      if (showCompanyFlag == 0) {
        this.setState({
          companyFlagOFC: true,
        })
      }

    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  onSearchData = async (data) => {
    const { getManageStockList, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getManageStockList({
        fromdate: data.fromdate,
        todate: data.todate,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  rowPDF = (rowData) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      downloadF({
        url:
          endpoint.manageStockHeader +
          "/report-by-id?id=" +
          rowData.id +
          "&documentType=1",
        ext: "pdf",
        openNewTab: true,
      }).then((response) => {
        showLoador({ loador: false });
        if (!response) {
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
        } else {
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  rowEdit = (rowData) => {
    window.location.replace("/manage-stock-edit/" + rowData.id);
  };

  render() {
    const { dynamicMasterData, companyFlagOFC } = this.state;
    const { manageStockList, setManageStockList } = this.props;
    return (
      <>
        <DynamicMainScreen
          showDeleteIcon={true}
          dynamicMasterData={dynamicMasterData}
          searchList={ManageStockJSON.searchList}
          onSearchData={this.onSearchData}
          formPathView={ManageStockJSON.formPathView}
          formPath={companyFlagOFC ? ManageStockJSON.formPathOfc : ManageStockJSON.formPath}
          screenTitle={ManageStockJSON.screenTitle}
          fieldMeta={ManageStockJSON.fieldMeta}
          getListById={this.getListById}
          showPdfDownload={false}
          showExcelDownload={false}
          isActiveURL={ManageStockJSON.isActiveURL}
          showAddButton={ManageStockJSON.showAddButton}
          tableColumnsToFilter={ManageStockJSON.tableColumnsToFilter}
          tableColumns={MangeStockListColumns}
          tableData={manageStockList.manageStockByDate}
          apiBaseURL={ManageStockJSON.apiBaseURL}
          callApi={false}
          getTableDataById={true}
          showSearchBox={false}
          rowPDF={this.rowPDF}
          showPdf={true}
          showEditIcon={true}
          showViewButtonInTable={true}
          showPegination={false}
          isNote={true}
          isNoteValue={"Manage Stock For Product"}
          getTableData={setManageStockList}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  manageStockList: state.manageStockByDate,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getManageStockList,
  setManageStockList,
};
export default connect(mapStateToProps, mapDispatchToProps)(manageStockTable);
