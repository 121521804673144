import endpoint from "../../config/endpoints";

export const WorkingTypejson = {
  showSaveNextBtn: true,
  screenTitle: "Working Type",
  formPath: "/working-type-form",
  apiBaseURL: endpoint.workingType,
  showTitle: true,
  showCancel: false,
  showSaveBtn: true,
  showAddButton: true,

  fieldMeta: [
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "name",
      isMandatory: true,
    },

    {
      label: "Other Task",
      controlType: "autocomplete",
      placeHolder: "Select Other Task",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "otherTaskDetail",
      dataKey: "otherTask",
      isRootLevelKey: false,
      isMandatory: true,
    },


  ],

};
