import { Grid, TextField, Paper } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import endpoint from "../../config/endpoints";
import * as msgConstant from "../../config/messageconstant";
import * as myConstClass from "../../config/messageconstant";
import { apiGet } from "../../utils/api_service";
import { showLoador1, showNotification } from "../Landing/Landing.slice";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../components/withRouter";
class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    userName: "",
    password: "",
    enterPassWord: false,
    confirmPassword: "",
    newPassword: "",
    formErrors: [],
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  getOldPassWord = () => {
    const { password } = this.state;
    const { showLoador1, showNotification } = this.props;
    if (this.handleUserNameValidation()) {
      if (navigator.onLine) {
        showLoador1({ loador: true });
        apiGet({
          url: endpoint.user + "/validate-password?password=" + password,
        }).then(({ data, success }) => {
          console.log(data.data.message);
          showLoador1({ loador: false });
          if (success) {
            if (data.data.message == "Correct Password") {
              this.setState({
                enterPassWord: true,
              });
            } else {
              let formErrors = {};
              let formIsValid = true;
              formIsValid = false;
              formErrors["userNameErr"] = data.data.message;
              this.setState({ formErrors: formErrors });
            }
          } else {
            showNotification({
              msg: myConstClass.serverMsg,
              severity: "error",
            });
          }

          return success;
        });
      } else {
        this.props.showNotification({
          msg: msgConstant.noInternetMsg,
          severity: "error",
        });
      }
    }
  };
  handleUserNameValidation() {
    const { password } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (password === "") {
      formIsValid = false;
      formErrors["userNameErr"] = myConstClass.userOldPassEmptyMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  passwordFormValidation(name) {
    const { newPassword, confirmPassword } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (newPassword === "") {
      formIsValid = false;
      formErrors["enterPasswordErr"] = myConstClass.enterPasswordMsg;
    }

    if (confirmPassword === "") {
      formIsValid = false;
      formErrors["confirmPasswordErr"] = myConstClass.confirmPasswordMsg;
    }
    if (
      confirmPassword !== "" &&
      newPassword !== "" &&
      confirmPassword != newPassword
    ) {
      formIsValid = false;
      formErrors["confirmPasswordErr"] =
        myConstClass.confirmPasswordIncorrectMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = () => {
    const { confirmPassword } = this.state;
    const { showLoador1, showNotification } = this.props;
    if (navigator.onLine) {
      if (this.passwordFormValidation()) {
        showLoador1({ loador: true });
        apiGet({
          url: endpoint.user + "/change-password?password=" + confirmPassword,
        }).then(({ data, success }) => {
          showLoador1({ loador: false });
          if (success) {
            if (!data.error) {
              showNotification({ msg: "Password changed successfully" });
              window.location.replace("/login");
            }
          } else {
            showNotification({
              msg: myConstClass.serverMsg,
              severity: "error",
            });
          }

          return success;
        });
      }
    } else {
      this.props.showNotification({
        msg: msgConstant.noInternetMsg,
        severity: "error",
      });
    }
  };

  onCancel = () => {
    this.props.navigate(-1);
  };
  render() {
    const { password, newPassword, confirmPassword, enterPassWord } =
      this.state;

    const {
      userNameErr,
      enterPasswordErr,
      confirmPasswordErr,
      incorrectPasswordErr,
    } = this.state.formErrors;

    return (
      <Paper
        sx={{
          p: 2,
          borderRadius: 2,
        }}
        elevation={0}
      >
        <>
          <div align="center">
            <div className="new_content">
              <div className="dash_login">
                <Grid item container justifyContent="space-between">
                  <Grid item md={4}></Grid>
                  <Grid item md={4}>
                    <div className="lgn_bx">
                      <h3 className="lgn_title">Change Password</h3>

                      <div className="lgn_container">
                        {!enterPassWord && (
                          <div className="user_lgn">
                            <div className="user_label">
                              Enter Old Password :
                            </div>
                            <div className="user_field">
                              <TextField
                                placeholder=""
                                color="primary"
                                name="password"
                                size="small"
                                value={password}
                                onChange={this.changedHandler}
                                fullWidth
                                disabled={enterPassWord ? true : false}
                                error={userNameErr ? true : false}
                                helperText={userNameErr ? userNameErr : " "}
                              />
                            </div>
                          </div>
                        )}

                        {!enterPassWord && (
                          <div className="lgn_btn">
                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={this.getOldPassWord}
                            >
                              Submit
                            </button>

                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={this.onCancel}
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                        {enterPassWord && (
                          <>
                            <div className="user_lgn">
                              <div className="user_label">
                                *Enter New Password :
                              </div>
                              <div className="user_field">
                                <TextField
                                  color="primary"
                                  name="newPassword"
                                  type="password"
                                  size="small"
                                  value={newPassword}
                                  onChange={this.changedHandler}
                                  onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                  }}
                                  onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                  }}
                                  fullWidth
                                  error={enterPasswordErr ? true : false}
                                  helperText={
                                    enterPasswordErr ? enterPasswordErr : " "
                                  }
                                />
                              </div>
                            </div>
                            <div className="user_lgn">
                              <div className="user_label">
                                *Confirm Password :
                              </div>
                              <div className="user_field">
                                <TextField
                                  color="primary"
                                  name="confirmPassword"
                                  size="small"
                                  type="password"
                                  value={confirmPassword}
                                  onChange={this.changedHandler}
                                  fullWidth
                                  onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                  }}
                                  onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                  }}
                                  error={
                                    confirmPasswordErr || incorrectPasswordErr
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    confirmPasswordErr
                                      ? confirmPasswordErr
                                      : incorrectPasswordErr
                                      ? incorrectPasswordErr
                                      : " "
                                  }
                                />
                              </div>
                            </div>

                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={this.submitHandler}
                            >
                              Save
                            </button>
                            <button
                              type="button"
                              className="register_btn sendOtp"
                              onClick={this.onCancel}
                            >
                              Cancel
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid item md={4}></Grid>
                </Grid>
              </div>
            </div>
          </div>
        </>
      </Paper>
    );
  }
}
const mapStateToProps = (state) => ({
  passwordData: state.activeLoginUser,
});
const mapDispatchToProps = {
  showLoador1,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ChangePassword);
