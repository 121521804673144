import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
    workingType: [],
};
let URL = endpoints.workingType;
const workingTypeSlice = createSlice({
    name: "workingType",
    initialState,
    reducers: {
        workingTypeSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                workingType: row,

            };
        },

        resetState: (state = cloneDeep(initialState), action) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const { workingTypeSuccess, resetState } = workingTypeSlice.actions;

export default workingTypeSlice.reducer;

export const getWorkingType = () => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL + '?sort={"insertDateTime": "DESC"}',
        }).then(({ data, success }) => {
            if (success) {
                const { content } = data.data;
                const row = content.map((workingTypeObject, index) => {
                    let workingTypeData = {
                        index: index + 1,
                        id: workingTypeObject.id === null ? "" : workingTypeObject.id,
                        name: workingTypeObject.name === null ? "" : workingTypeObject.name,
                        otherTask: workingTypeObject.otherTask === null ? "" : workingTypeObject.otherTask.id,
                        otherTaskName: workingTypeObject.otherTask === null ? "" : workingTypeObject.otherTask.name,
                        isActive: workingTypeObject.isActive === null ? "" : workingTypeObject.isActive,
                        type: workingTypeObject.type === null ? "" : workingTypeObject.type,
                        typeForTable: workingTypeObject.type === null ? "" :
                            workingTypeObject.type === 0 ? "Order" :
                                workingTypeObject.type === 1 ? "Stock" :
                                    workingTypeObject.type === 2 ? "Audit/Survey" :
                                        workingTypeObject.type === 3 ? "Feedback" :
                                            "Other",
                    };
                    return workingTypeData;
                });
                dispatch(workingTypeSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};


export const getWorkingTypeWithTaskName = () => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL + "/get-all-with-task-name",
        }).then(({ data, success }) => {
            if (success) {
                const row = data.data.map((workingTypeObject, index) => {
                    let workingTypeData = {
                        index: index + 1,
                        id: workingTypeObject.id === null ? "" : workingTypeObject.id,
                        name: workingTypeObject.name === null ? "" : workingTypeObject.name,
                        otherTaskName: workingTypeObject.otherTaskName === null ? "" : workingTypeObject.otherTaskName,
                        isActive: workingTypeObject.isActive === null ? "" : workingTypeObject.isActive,
                        type: workingTypeObject.type === null ? "" : workingTypeObject.type,
                        typeForTable: workingTypeObject.type === null ? "" :
                            workingTypeObject.type === 0 ? "Order" :
                                workingTypeObject.type === 1 ? "Stock" :
                                    workingTypeObject.type === 2 ? "Audit/Survey" :
                                        workingTypeObject.type === 3 ? "Feedback" :
                                            "Other",
                    };
                    return workingTypeData;
                });
                dispatch(workingTypeSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};