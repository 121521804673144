import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg } from "../../config/messageconstant";
import { noticeMessageJSON } from "../../DynamicFormsJson/Transaction/noticeMessage";
import { getNoticeMessageById } from "../../Slice/noticeMsg.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
class NoticeMsgView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
    };
  }
  async componentDidMount() {
    const { getNoticeMessageById, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      if (this.props.params.id) {
        showLoador({ loador: true });
        await getNoticeMessageById({
          noticeMsgHeaderId: this.props.params.id,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
          } else {
            this.setState({
              headerData: response,
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Stock Holder Type",
                  value:
                    response.stockStakeHolderTypeName !== null
                      ? response.stockStakeHolderTypeName
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Type",
                  value: response.typeName !== null ? response.typeName : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Region",
                  value:
                    response.regionName !== null ? response.regionName : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Zone",
                  value: response.zoneName !== null ? response.zoneName : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Area",
                  value: response.areaName !== null ? response.areaName : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Headline",
                  value:
                    response.noticeHeadline !== null
                      ? response.noticeHeadline
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Description",
                  value:
                    response.description !== null ? response.description : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Flash",
                  value: response.flashType !== null ? response.flashType : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "From Date",
                  value: response.fromdate !== null ? response.fromdate : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "To Date",
                  value: response.todate !== null ? response.todate : "",
                },
              ],
            });
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  render() {
    const { dynamicMasterData, DataToSet } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: 1.5,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            DataToSet={DataToSet}
            dynamicMasterData={dynamicMasterData}
            showSaveBtn={noticeMessageJSON.showSaveBtn}
            showTitle={true}
            screenTitle={"Notice / Message View"}
            showCancel={noticeMessageJSON.showCancel}
            apiBaseURL={noticeMessageJSON.apiBaseURL}
            showSaveNextBtn={noticeMessageJSON.showSaveNextBtn}
            saveBtnText={"Search"}
            showSaveBtnMain={false}
            padding={true}
            paddingTop={true}
          />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getNoticeMessageById,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(NoticeMsgView);
