import { Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { LabelCompo } from "../../components/Comman/Label";
import Map from "./Map";
export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {} = this.props;
    const {} = this.state;  

    return (
      <>
        <Map />
        <Paper
          sx={{
            p: 2,
            pt: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            display="flex"
            justifyContent="center"
          >
            <LabelCompo
              className="text-black"
              style={{ fontSize: 18 }}
              label={"Welcome to Distribution App"}
            />
          </Grid>
          <br />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = () => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
