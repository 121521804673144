import { FormControl } from "@mui/base";
import CloseIcon from "@mui/icons-material/Close";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { pink } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { compose } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getActiveVehicleNoListByStockholderForBilling, getVehicleNoList } from "../../Slice/veichleSlice.slice";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import { withRouter } from "../../components/withRouter";
import {
  blackColor,
  checkboxShadowColor,
  redColor,
  titleColor,
  yellowColor,
} from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  saveFailedMsg,
  saveWarningMsg,
  savemsg,
  serverMsg,
  vehicleNoMsg,
} from "../../config/messageconstant";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "90vw",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    width: "90vw",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

class BillingWithOffer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      checkboxAllData: [],
      offerBillNewData: [],
      billOfferId: "",
      vehicleNo: "",
      name: "",
      formErrors: {},
      stockChangedList: [],
    };
  }
  componentDidMount() {
    const {
      getActiveVehicleNoListByStockholderForBilling,
      getVehicleNoList,
      offerBillData,
      flag,
      showLoador,
      showNotification,
    } = this.props;


    const showCompanyFlag = localStorage.getItem("showCompanyFlag");

    if (showCompanyFlag == 2) {
      showLoador({ loador: true });
      getVehicleNoList().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showLoador({ loador: true });
      getActiveVehicleNoListByStockholderForBilling().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    }


    const stockCurrentList = localStorage.getItem("stockList");
    const valuesArray = JSON.parse(stockCurrentList);
    this.setState({
      offerBillNewData: offerBillData,
    });
    if (flag) {
      let i;
      let j;
      let stockDummyNewList = valuesArray;
      for (i = 0; i < stockDummyNewList.length; i++) {
        offerBillData.map((billObject) => {
          for (
            j = 0;
            j <
            billObject.outletBillsAfterApplyingOffers.outletBillDetails.length;
            j++
          ) {
            if (
              stockDummyNewList[i].productId ==
              billObject.outletBillsAfterApplyingOffers.outletBillDetails[j]
                .product.id
            ) {
              stockDummyNewList[i].stock =
                stockDummyNewList[i].stock -
                billObject.outletBillsAfterApplyingOffers.outletBillDetails[j]
                  .freeQty;
            }
          }
        });
      }
      this.setState({ stockChangedList: stockDummyNewList });
    }
  }
  clickSubmitOfferHandler = () => {
    const { offerBillNewData, name } = this.state;
    const {
      billDate,
      redirect,
      showLoador,
      showNotification,
      stockProductData,
    } = this.props;
    let dataToSave = [];
    // offerBillNewData.map((billObejct) => {
    //   dataToSave.push(billObejct.outletBillsAfterApplyingOffers);
    // });

    dataToSave = offerBillNewData.map((billObject) => {
      return {
        ...billObject.outletBillsAfterApplyingOffers,
        vehicleNo: name,
      };
    });

    let selectList = [];
    if (stockProductData.length !== 0) {
      stockProductData.map((uncheckedObjct) => {
        selectList.push(uncheckedObjct.id);
      });
    }

    if (this.checkValidation()) {
      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          if (selectList.length !== 0) {
            let jsonData = {
              outletOrderDetailIds: selectList,
              isBillGenerate: "2",
            };
            showLoador({ loador: true });
            apiPost({
              url:
                endpoint.outletBillHeader +
                "/save-list-after-appying-offers?billDate=" +
                billDate,
              postBody: dataToSave,
            }).then(({ data, success }) => {
              showLoador({ loador: false });
              showNotification({ msg: savemsg });
              if (success) {
                showLoador({ loador: true });
                apiPost({
                  url:
                    endpoint.outletOrderDetail +
                    "/update-is-bill-generate-status",
                  postBody: jsonData,
                }).then(({ success }) => {
                  if (success) {
                    if (redirect) {
                      window.location.replace(
                        "/functional-dashboard-distributor"
                      );
                    } else {
                      window.location.reload("/order-list");
                    }
                  }
                });
              } else {
                showNotification({ msg: saveFailedMsg, severity: "error" });
              }
            });
          } else {
            showLoador({ loador: true });
            apiPost({
              url:
                endpoint.outletBillHeader +
                "/save-list-after-appying-offers?billDate=" +
                billDate,
              postBody: dataToSave,
            }).then(({ data, success }) => {
              showLoador({ loador: false });
              showNotification({ msg: savemsg });
              if (success) {
                if (redirect) {
                  window.location.replace("/functional-dashboard-distributor");
                } else {
                  window.location.reload("/order-list");
                }
              } else {
                showNotification({ msg: saveFailedMsg, severity: "error" });
              }
            });
          }
        }
      });
    }
  };

  clickSubmitHandler = () => {
    const { checkboxAllData, offerBillNewData, billOfferId, stockChangedList } =
      this.state;
    if (offerBillNewData.length !== 0) {
      let dataToSave = [];
      offerBillNewData.map((billData) => {
        if (
          Object.keys(billData.outletBillHeader).length !== 0 &&
          billData.outletBillHeader.id == billOfferId
        ) {
          let billCheckedOfferData = [];
          if (billData.offerHeaders.length !== 0) {
            billData.offerHeaders.map((offerData) => {
              if (checkboxAllData.length !== 0) {
                checkboxAllData.map((checkedData) => {
                  if (checkedData.id == offerData.id) {
                    billCheckedOfferData.push(checkedData);
                  }
                });
              }
            });
          }
          let billObjectData = {
            outletBillHeader: billData.outletBillHeader,
            offerHeaders: billCheckedOfferData,
            productAndStocks: stockChangedList,
          };
          dataToSave.push(billObjectData);
        }
      });
      this.onSave(dataToSave[0]);
    }
  };

  onSave = (dataToSave) => {
    const { offerBillNewData, openDialog } = this.state;
    const { flag, showLoador, showNotification } = this.props;
    const stockCurrentList = localStorage.getItem("stockList");
    const valuesArray = JSON.parse(stockCurrentList);
    showLoador({ loador: true });
    apiPost({
      url: endpoint.outletBillHeader + "/apply-offer",
      postBody: dataToSave,
    }).then(({ data, success }) => {
      showLoador({ loador: false });
      if (success) {
        let newOfferData = offerBillNewData.map((offerNewObject) => {
          if (
            offerNewObject.outletBillHeader.id == data.data.outletBillHeader.id
          ) {
            return data.data;
          } else {
            return offerNewObject;
          }
        });

        if (flag) {
          let i;
          let j;
          let stockOriginalDummyList = valuesArray;
          for (i = 0; i < stockOriginalDummyList.length; i++) {
            newOfferData.map((offerObject) => {
              for (
                j = 0;
                j <
                offerObject.outletBillsAfterApplyingOffers.outletBillDetails
                  .length;
                j++
              ) {
                if (
                  stockOriginalDummyList[i].productId ==
                  offerObject.outletBillsAfterApplyingOffers.outletBillDetails[
                    j
                  ].product.id
                ) {
                  stockOriginalDummyList[i].stock =
                    stockOriginalDummyList[i].stock -
                    offerObject.outletBillsAfterApplyingOffers
                      .outletBillDetails[j].freeQty;
                }
              }
            });
          }
          this.setState({ stockChangedList: stockOriginalDummyList });
        }
        let newObjectToSave = newOfferData.map((billObject) => {
          let newBillObject = {
            ...billObject.outletBillsAfterApplyingOffers,
            outletBillDetails:
              billObject.outletBillsAfterApplyingOffers.outletBillDetails.map(
                (orderObject) => {
                  let productWithoutBillObject =
                    billObject.outletBillHeader.outletBillDetails.filter(
                      (row) => row.id == orderObject.id
                    );
                  if (productWithoutBillObject.length !== 0) {
                    orderObject = {
                      ...orderObject,
                      taxableAmtWithoutOffer:
                        productWithoutBillObject[0].taxableAmt,
                      totalAmtWithoutOffer:
                        productWithoutBillObject[0].totalAmt,
                    };
                    return orderObject;
                  } else {
                    orderObject = {
                      ...orderObject,
                      taxableAmtWithoutOffer: 0.0,
                      totalAmtWithoutOffer: 0.0,
                    };
                    return orderObject;
                  }
                }
              ),
          };
          billObject = {
            ...billObject,
            outletBillsAfterApplyingOffers: newBillObject,
          };
          return billObject;
        });
        showNotification({ msg: savemsg });

        this.setState({
          offerBillNewData: newObjectToSave,
          openDialog: !openDialog,
        });
      } else {
        showNotification({ msg: saveFailedMsg, severity: "error" });
      }
    });
  };

  onCheckBoxClick = (checkedValue, headerId) => {
    const { checkboxAllData, offerBillNewData } = this.state;
    let checkboxData = [];
    if (offerBillNewData.length !== 0) {
      offerBillNewData.map((offerData) => {
        if (
          Object.keys(offerData.outletBillsAfterApplyingOffers).length !== 0 &&
          offerData.outletBillsAfterApplyingOffers.id === headerId
        ) {
          checkboxAllData.map((allOffer) => {
            if (
              allOffer.level == 0 &&
              allOffer.type == 0 &&
              checkedValue.level == 0 &&
              checkedValue.type == 0
            ) {
              if (allOffer.id == checkedValue.id) {
                let allOfferData = {
                  ...allOffer,
                  isApplied: allOffer.isApplied == 1 ? 0 : 1,
                  appliedOfferId: offerData.outletBillsAfterApplyingOffers.id,
                };
                checkboxData.push(allOfferData);
              } else {
                let allOfferData = {
                  ...allOffer,
                  isApplied: allOffer.isApplied == 1 ? 0 : 0,
                };
                checkboxData.push(allOfferData);
              }
            } else if (
              allOffer.level == 0 &&
              allOffer.type == 1 &&
              checkedValue.level == 0 &&
              checkedValue.type == 1
            ) {
              if (allOffer.id == checkedValue.id) {
                let allOfferData = {
                  ...allOffer,
                  isApplied: allOffer.isApplied == 1 ? 0 : 1,
                  appliedOfferId: offerData.outletBillsAfterApplyingOffers.id,
                };
                checkboxData.push(allOfferData);
              } else {
                let allOfferData = {
                  ...allOffer,
                  isApplied: allOffer.isApplied == 1 ? 0 : 0,
                };
                checkboxData.push(allOfferData);
              }
            } else if (
              allOffer.level == 1 &&
              allOffer.type == 0 &&
              checkedValue.level == 1 &&
              checkedValue.type == 0
            ) {
              if (allOffer.id == checkedValue.id) {
                let allOfferData = {
                  ...allOffer,
                  isApplied: allOffer.isApplied == 1 ? 0 : 1,
                  appliedOfferId: offerData.outletBillsAfterApplyingOffers.id,
                };
                checkboxData.push(allOfferData);
              } else {
                let allOfferData = {
                  ...allOffer,
                  isApplied: allOffer.isApplied,
                };
                checkboxData.push(allOfferData);
              }
            } else if (
              allOffer.level == 1 &&
              allOffer.type == 1 &&
              checkedValue.level == 1 &&
              checkedValue.type == 1
            ) {
              if (allOffer.id == checkedValue.id) {
                let allOfferData = {
                  ...allOffer,
                  isApplied: allOffer.isApplied == 1 ? 0 : 1,
                  appliedOfferId: offerData.outletBillsAfterApplyingOffers.id,
                };
                checkboxData.push(allOfferData);
              } else {
                let allOfferData = {
                  ...allOffer,
                  isApplied: allOffer.isApplied,
                };
                checkboxData.push(allOfferData);
              }
            } else {
              checkboxData.push(allOffer);
            }
          });
        }
      });
    }
    this.setState({
      checkboxAllData: checkboxData,
    });
  };

  clickHandler = (billId) => {
    const { openDialog, offerBillNewData } = this.state;
    let checkboxData = [];
    this.setState({
      openDialog: !openDialog,
      checkboxAllData: [],
      billOfferId: billId,
    });
    if (offerBillNewData.length !== 0) {
      offerBillNewData.map((offerData) => {
        if (
          Object.keys(offerData.outletBillsAfterApplyingOffers).length !== 0 &&
          offerData.outletBillsAfterApplyingOffers.id == billId &&
          offerData.offerHeaders.length !== 0
        ) {
          offerData.offerHeaders.map((allOffer) => {
            let allOfferData = {
              ...allOffer,
              appliedOfferId: offerData.outletBillsAfterApplyingOffers.id,
            };
            checkboxData.push(allOfferData);
          });
        }
      });
    }
    this.setState({
      checkboxAllData: checkboxData,
    });
  };

  checkValidation = () => {
    const { vehicleNo } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (vehicleNo === "") {
      formIsValid = false;
      formErrors["vehicleNoError"] = vehicleNoMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  };
  changeHandler = (e, value) => {
    const selectedVehicles = this.props.vehicleList?.vehicle.filter(
      (row) => row.id === value
    );
    if (selectedVehicles && selectedVehicles.length > 0) {
      const selectedVehicle = selectedVehicles[0];
      const { name } = selectedVehicle;
      this.setState({
        vehicleNo: value,
        name: name,
      });
    }
  };

  render() {
    const {
      openDialog,
      checkboxAllData,
      offerBillNewData,
      vehicleNo,
      formErrors,
    } = this.state;
    const { onCancel, vehicleList } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 1.5,
            borderRadius: 1.5,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            screenTitle={"Billing Preview"}
            showTable={false}
            showBackButton={true}
            showAddButton={false}
            onCancel={onCancel}
          />
          <br />

          {offerBillNewData.length !== 0 &&
            offerBillNewData.map((billData, index) => {
              return (
                <>
                  <DynamicDetails
                    DataToSet={[
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "Outlet No.",
                        value: index + 1,
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "Outlet Name",
                        value:
                          billData.outletBillsAfterApplyingOffers !== null &&
                            billData.outletBillsAfterApplyingOffers.outLet !==
                            null
                            ? billData.outletBillsAfterApplyingOffers.outLet
                              .firmName
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "Owner Name",
                        value:
                          billData.outletBillsAfterApplyingOffers !== null &&
                            billData.outletBillsAfterApplyingOffers.outLet !==
                            null
                            ? billData.outletBillsAfterApplyingOffers.outLet
                              .ownerName
                            : "",
                      },

                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "State Name",
                        value:
                          billData.outletBillsAfterApplyingOffers !== null &&
                            billData.outletBillsAfterApplyingOffers.outLet !==
                            null &&
                            billData.outletBillsAfterApplyingOffers.outLet
                              .state !== null
                            ? billData.outletBillsAfterApplyingOffers.outLet
                              .state.name
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "Bill No",
                        value:
                          billData.outletBillsAfterApplyingOffers !== null
                            ? billData.outletBillsAfterApplyingOffers.billNo
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "Taxable Amount",
                        value:
                          billData.outletBillsAfterApplyingOffers !== null
                            ? billData.outletBillsAfterApplyingOffers.taxableAmt.toFixed(
                              2
                            )
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "Total Amount",
                        value:
                          billData.outletBillsAfterApplyingOffers !== null
                            ? billData.outletBillsAfterApplyingOffers.totalAmt.toFixed(
                              2
                            )
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "Discount Amount",
                        value: (
                          (billData.outletBillHeader.totalAmt === null
                            ? ""
                            : billData.outletBillHeader.totalAmt.toFixed(2)) -
                          (billData.outletBillsAfterApplyingOffers.totalAmt ===
                            null
                            ? ""
                            : billData.outletBillsAfterApplyingOffers.totalAmt.toFixed(
                              2
                            ))
                        ).toFixed(2),
                      },
                    ]}
                  />

                  {Object.keys(billData).length !== 0 &&
                    billData.offerHeaders.length !== 0 && (
                      <>
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={12}
                          lg={12}
                          container
                          justifyContent="flex-end"
                        >
                          <ButtonCompo
                            sx={{
                              bgcolor: "dodgerblue", // theme.palette.primary.main
                              color: yellowColor,
                              opacity: "0.9",
                              transform: "scale(0.9)",
                              ":hover": {
                                display: "inline-block",
                                bgcolor: yellowColor, // theme.palette.primary.main
                                color: "dodgerblue",
                                opacity: "0.9",
                                transform: "scale(1.5)",
                                transitionDuration: "3s",
                                backfaceVisibility: "hidden",
                                transform:
                                  "translate(2px, 2px) rotate(0deg)  scale(2)",
                                config: {
                                  tension: "300",
                                  friction: "10",
                                },
                              },
                            }}
                            size="medium"
                            type="button"
                            variant="contained"
                            name={"Offer"}
                            onClick={(e) =>
                              this.clickHandler(
                                billData.outletBillsAfterApplyingOffers.id
                              )
                            }
                            fullWidth={false}
                          />
                        </Grid>
                      </>
                    )}

                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" sx={{ width: "5%" }}>
                            #
                          </TableCell>
                          <TableCell align="center" sx={{ width: "15%" }}>
                            Product Name
                          </TableCell>
                          <TableCell align="center" sx={{ width: "10%" }}>
                            Quantity
                          </TableCell>
                          <TableCell align="center" sx={{ width: "10%" }}>
                            Free Quantity
                          </TableCell>
                          <TableCell align="center" sx={{ width: "15%" }}>
                            Taxable Amount
                          </TableCell>
                          <TableCell align="center" sx={{ width: "15%" }}>
                            Total Tax
                          </TableCell>
                          <TableCell align="center" sx={{ width: "15%" }}>
                            Total Amount
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      {Object.keys(billData.outletBillsAfterApplyingOffers)
                        .length !== 0 &&
                        billData.outletBillsAfterApplyingOffers
                          .outletBillDetails.length !== 0 &&
                        billData.outletBillsAfterApplyingOffers.outletBillDetails.map(
                          (billDetails, index) => {
                            return (
                              <>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    sx={{ width: "5%" }}
                                  >
                                    {index + 1}
                                  </TableCell>
                                  <TableCell align="left" sx={{ width: "15%" }}>
                                    {billDetails !== null &&
                                      billDetails.product !== null
                                      ? billDetails.product.name
                                      : ""}
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    sx={{ width: "10%" }}
                                  >
                                    {billDetails !== null
                                      ? billDetails.qty.toFixed(2)
                                      : ""}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{ width: "10%" }}
                                  >
                                    {billDetails !== null
                                      ? billDetails.freeQty.toFixed(2)
                                      : ""}
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    sx={{ width: "15%" }}
                                  >
                                    {billDetails !== null
                                      ? billDetails.taxableAmtWithoutOffer.toFixed(
                                        2
                                      )
                                      : ""}
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    sx={{ width: "15%" }}
                                  >
                                    {(
                                      (billDetails.totalAmtWithoutOffer === null
                                        ? ""
                                        : billDetails.totalAmtWithoutOffer.toFixed(
                                          2
                                        )) -
                                      (billDetails.taxableAmtWithoutOffer ===
                                        null
                                        ? ""
                                        : billDetails.taxableAmtWithoutOffer.toFixed(
                                          2
                                        ))
                                    ).toFixed(2)}
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    sx={{ width: "15%" }}
                                  >
                                    {billDetails !== null
                                      ? billDetails.totalAmtWithoutOffer.toFixed(
                                        2
                                      )
                                      : ""}
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          }
                        )}
                    </Table>
                    {Object.keys(billData.outletBillsAfterApplyingOffers)
                      .length !== 0 &&
                      billData.outletBillsAfterApplyingOffers.appliedOffers
                        .length !== 0 && (
                        <>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  sx={{ width: "5.5%" }}
                                >
                                  #
                                </TableCell>
                                <TableCell align="center" sx={{ width: "80%" }}>
                                  Offer Name
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            {billData.outletBillsAfterApplyingOffers
                              .appliedOffers.length !== 0 &&
                              billData.outletBillsAfterApplyingOffers.appliedOffers.map(
                                (offerAppliedData, index) => {
                                  return (
                                    <>
                                      <TableRow>
                                        <TableCell align="center">
                                          {index + 1}
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          sx={{ width: "80%" }}
                                        >
                                          {offerAppliedData !== null
                                            ? offerAppliedData.name
                                            : ""}
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  );
                                }
                              )}
                          </Table>
                        </>
                      )}
                  </TableContainer>
                  <br />
                </>
              );
            })}
          <br />
          <Grid
            item
            sm={6}
            xs={8}
            md={6}
            lg={6}
            container
            justifyContent="space-evenly"
          >
            <Grid item xs={12} md={5} sm={5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label="* "
              />
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: titleColor }}
                label="Vehicle No :"
              />
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <AutoComplete
                keyColName={"id"}
                placeHolder="Select Vehicle No"
                value={vehicleNo}
                name={"vehicleNo"}
                options={vehicleList?.vehicle}
                errorText={
                  formErrors["vehicleNoError"]
                    ? formErrors["vehicleNoError"]
                    : ""
                }
                isError={
                  formErrors["vehicleNoError"]
                    ? formErrors["vehicleNoError"]
                    : ""
                }
                onChange={this.changeHandler}
              />
            </Grid>
          </Grid>
          {openDialog && (
            <>
              <BootstrapDialog
                onClose={this.clickHandler}
                aria-labelledby="customized-dialog-title"
                open={true}
                fullWidth
                maxWidth="500px"
              >
                <BootstrapDialogTitle
                  id="customized-dialog-title"
                  onClose={this.clickHandler}
                >
                  Applicable Offers
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="flex-between"
                  >
                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="flex-start"
                    >
                      {checkboxAllData.length !== 0 &&
                        checkboxAllData.map((applicableOfferData) => {
                          if (
                            applicableOfferData.type == 0 &&
                            applicableOfferData.level == 0
                          ) {
                            return (
                              <>
                                <Typography
                                  sx={{ p: 0, marginLeft: 2 }}
                                  variant="h6"
                                  color="textSecondary"
                                >
                                  <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                      <FormControlLabel
                                        value="end"
                                        control={
                                          <Checkbox
                                            sx={{
                                              color: checkboxShadowColor,
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 30,
                                              },
                                              "&.Mui-checked": {
                                                color: pink[600],
                                              },
                                            }}
                                            color="secondary"
                                          />
                                        }
                                        sx={{
                                          ".css-19g4vwf-MuiTypography-root": {
                                            fontSize: "1.3rem",
                                            color: blackColor,
                                          },
                                        }}
                                        label={applicableOfferData.name}
                                        onChange={(e) => {
                                          this.onCheckBoxClick(
                                            applicableOfferData,
                                            applicableOfferData.appliedOfferId
                                          );
                                        }}
                                        labelPlacement="end"
                                        checked={
                                          applicableOfferData.isApplied == 1
                                            ? true
                                            : false
                                        }
                                      />
                                    </FormGroup>{" "}
                                    {applicableOfferData.isApplied !== 2 ? (
                                      <div
                                        style={{
                                          marginLeft: "40px",
                                          fontSize: "1.1rem",
                                        }}
                                      >
                                        {applicableOfferData.discription}
                                      </div>
                                    ) : (
                                      <FormHelperText
                                        style={{
                                          marginLeft: "40px",
                                        }}
                                        error
                                      >
                                        {
                                          "Due to a shortage of stock, the offer is not applicable"
                                        }
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Typography>
                              </>
                            );
                          }
                        })}
                    </Grid>

                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="flex-start"
                    >
                      {checkboxAllData.length !== 0 &&
                        checkboxAllData.map((applicableOfferData) => {
                          if (
                            applicableOfferData.type == 1 &&
                            applicableOfferData.level == 0
                          ) {
                            return (
                              <>
                                <Typography
                                  sx={{ p: 0, marginLeft: 2 }}
                                  variant="h6"
                                  color="textSecondary"
                                >
                                  <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                      <FormControlLabel
                                        value="end"
                                        control={
                                          <Checkbox
                                            sx={{
                                              color: checkboxShadowColor,
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 30,
                                              },
                                              "&.Mui-checked": {
                                                color: pink[600],
                                              },
                                            }}
                                            color="secondary"
                                          />
                                        }
                                        sx={{
                                          ".css-19g4vwf-MuiTypography-root": {
                                            fontSize: "1.3rem",
                                            color: blackColor,
                                          },
                                        }}
                                        label={applicableOfferData.name}
                                        onChange={(e) => {
                                          this.onCheckBoxClick(
                                            applicableOfferData,
                                            applicableOfferData.appliedOfferId
                                          );
                                        }}
                                        labelPlacement="end"
                                        checked={
                                          applicableOfferData.isApplied == 1
                                            ? true
                                            : false
                                        }
                                      />
                                      {applicableOfferData.isApplied !== 2 ? (
                                        <div
                                          style={{
                                            marginLeft: "40px",
                                            fontSize: "1.1rem",
                                          }}
                                        >
                                          {applicableOfferData.discription}
                                        </div>
                                      ) : (
                                        <FormHelperText
                                          style={{
                                            marginLeft: "40px",
                                          }}
                                          error
                                        >
                                          {
                                            "Due to a shortage of stock, the offer is not applicable"
                                          }
                                        </FormHelperText>
                                      )}
                                    </FormGroup>
                                  </FormControl>
                                </Typography>
                              </>
                            );
                          }
                        })}
                    </Grid>
                  </Grid>
                  <br />
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="flex-between"
                  >
                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="flex-start"
                    >
                      {checkboxAllData.length !== 0 &&
                        checkboxAllData.map((applicableOfferData) => {
                          if (
                            applicableOfferData.type == 0 &&
                            applicableOfferData.level == 1
                          ) {
                            return (
                              <>
                                <Typography
                                  sx={{ p: 0, marginLeft: 2 }}
                                  variant="h6"
                                  color="textSecondary"
                                >
                                  <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                      <FormControlLabel
                                        value="end"
                                        control={
                                          <Checkbox
                                            sx={{
                                              color: checkboxShadowColor,
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 30,
                                              },
                                              "&.Mui-checked": {
                                                color: pink[600],
                                              },
                                            }}
                                            color="secondary"
                                          />
                                        }
                                        sx={{
                                          ".css-19g4vwf-MuiTypography-root": {
                                            fontSize: "1.3rem",
                                            color: blackColor,
                                          },
                                        }}
                                        label={applicableOfferData.name}
                                        onChange={(e) => {
                                          this.onCheckBoxClick(
                                            applicableOfferData,
                                            applicableOfferData.appliedOfferId
                                          );
                                        }}
                                        labelPlacement="end"
                                        checked={
                                          applicableOfferData.isApplied == 1
                                            ? true
                                            : false
                                        }
                                      />
                                    </FormGroup>{" "}
                                    {applicableOfferData.isApplied !== 2 ? (
                                      <div
                                        style={{
                                          marginLeft: "40px",
                                          fontSize: "1.1rem",
                                        }}
                                      >
                                        {applicableOfferData.discription}
                                      </div>
                                    ) : (
                                      <FormHelperText
                                        style={{
                                          marginLeft: "40px",
                                        }}
                                        error
                                      >
                                        {
                                          "Due to a shortage of stock, the offer is not applicable"
                                        }
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Typography>
                              </>
                            );
                          }
                        })}
                    </Grid>

                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="flex-start"
                    >
                      {checkboxAllData.length !== 0 &&
                        checkboxAllData.map((applicableOfferData) => {
                          if (
                            applicableOfferData.type == 1 &&
                            applicableOfferData.level == 1
                          ) {
                            return (
                              <>
                                <Typography
                                  sx={{ p: 0, marginLeft: 2 }}
                                  variant="h6"
                                  color="textSecondary"
                                >
                                  <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                      <FormControlLabel
                                        value="end"
                                        control={
                                          <Checkbox
                                            sx={{
                                              color: checkboxShadowColor,
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 30,
                                              },
                                              "&.Mui-checked": {
                                                color: pink[600],
                                              },
                                            }}
                                            color="secondary"
                                          />
                                        }
                                        sx={{
                                          ".css-19g4vwf-MuiTypography-root": {
                                            fontSize: "1.5rem",
                                            color: blackColor,
                                          },
                                        }}
                                        label={applicableOfferData.name}
                                        onChange={(e) => {
                                          this.onCheckBoxClick(
                                            applicableOfferData,
                                            applicableOfferData.appliedOfferId
                                          );
                                        }}
                                        labelPlacement="end"
                                        checked={
                                          applicableOfferData.isApplied == 1
                                            ? true
                                            : false
                                        }
                                      />
                                      {applicableOfferData.isApplied !== 2 ? (
                                        <div
                                          style={{
                                            marginLeft: "40px",
                                            fontSize: "1.1rem",
                                          }}
                                        >
                                          {applicableOfferData.discription}
                                        </div>
                                      ) : (
                                        <FormHelperText
                                          style={{
                                            marginLeft: "40px",
                                          }}
                                          error
                                        >
                                          {
                                            "Due to a shortage of stock, the offer is not applicable"
                                          }
                                        </FormHelperText>
                                      )}
                                    </FormGroup>
                                  </FormControl>
                                </Typography>
                              </>
                            );
                          }
                        })}
                    </Grid>
                  </Grid>
                  <br />
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="flex-end"
                  >
                    <ButtonCompo
                      sx={{
                        bgcolor: "dodgerblue",
                        color: yellowColor,
                      }}
                      size="medium"
                      type="button"
                      variant="contained"
                      name={"Submit"}
                      onClick={this.clickSubmitHandler}
                      fullWidth={false}
                    />
                  </Grid>
                </BootstrapDialogTitle>
              </BootstrapDialog>
            </>
          )}
          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="flex-end"
          >
            <ButtonCompo
              sx={{
                bgcolor: "dodgerblue",
                color: yellowColor,
              }}
              size="medium"
              type="button"
              variant="contained"
              name={"Save Bill"}
              onClick={this.clickSubmitOfferHandler}
              fullWidth={false}
            />
          </Grid>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({ vehicleList: state.vehicle });
const mapDispatchToProps = {
  getActiveVehicleNoListByStockholderForBilling,
  getVehicleNoList,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BillingWithOffer);
