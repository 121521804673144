import endpoint from "../../config/endpoints";

export const ExcelReportsForInactiveJson = {
    showSaveNextBtn: true,
    apiBaseURL: endpoint.area,
    screenTitle: "Inactive Reports",
    fieldMeta: [
        {
            label: "From Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "fromDate",
            isMandatory: true,
            onSubmit: "compare",
            onSubmitParameter: "toDate-le",
            format: "DD-MM-YYYY",
        },
        {
            label: "To Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "toDate",
            onSubmit: "compare",
            onSubmitParameter: "fromDate-ge",
            isMandatory: true,
            onSubmit: "compare",
            format: "DD-MM-YYYY",
        },
        {
            label: "Category",
            controlType: "multiSelect",
            placeHolder: "Select Category ",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "categoryDetail",
            dataKey: "category",
            isRootLevelKey: true,
            isMandatory: true,
            getListId: "category",
        },
        {
            label: "Product ",
            controlType: "multiSelect",
            placeHolder: "Select Product",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "productDetail",
            dataKey: "product",
            getListFrom: "category",
            isRootLevelKey: true,
            isMandatory: true,
          },

        {
            label: "Stock Holder Type",
            controlType: "autocomplete",
            placeHolder: "Select Stock Holder Type",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "stockStakeHolderTypeDetail",
            dataKey: "stockStakeHolderTypeId",
            getListId: "stockStakeHolderTypeId",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Stock Holder Name",
            controlType: "autocomplete",
            placeHolder: "Select Stock Holder Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "stakeHolderName",
            dataKey: "stockStakeHolder",
            getListFrom: "stockStakeHolderTypeId,area",
            isRootLevelKey: true,
            isMandatory: true,
        },
    ],
    fieldMetaForDistributor: [
        {
            label: "From Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "fromDate",
            isMandatory: true,
            onSubmit: "compare",
            onSubmitParameter: "toDate-le",
            format: "DD-MM-YYYY",
        },
        {
            label: "To Date",
            controlType: "datepicker",
            placeHolder: "",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            dataKey: "toDate",
            onSubmit: "compare",
            onSubmitParameter: "fromDate-ge",
            isMandatory: true,
            onSubmit: "compare",
            format: "DD-MM-YYYY",
        },
        {
            label: "Area",
            controlType: "multiSelect",
            placeHolder: "Select Area",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "areaDetailForDealer",
            dataKey: "area",
            getListId: "area",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Beat",
            controlType: "multiSelect",
            placeHolder: "Select Beat",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "beatDetail",
            dataKey: "beat",
            getListFrom: "area",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Category",
            controlType: "multiSelect",
            placeHolder: "Select Category ",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "categoryDetail",
            dataKey: "category",
            isRootLevelKey: true,
            isMandatory: true,
            getListId: "category",
        },
        {
            label: "Product ",
            controlType: "multiSelect",
            placeHolder: "Select Product",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "productDetail",
            dataKey: "product",
            getListFrom: "category",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Sales Person",
            controlType: "multiSelect",
            placeHolder: "Select Sales Person",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            masterName: "salesPersonDetail",
            dataKey: "salesPerson",
            getListFrom: "area",
            isRootLevelKey: true,
            isMandatory: true,
        },
    ],
};
