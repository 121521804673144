import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  StockHolderOrderConfiguration: [],
  StockHolderOrderConfigurationFilter: [],
  stockHolderStockIdsAndAreaIds: [],
};
let URL = endpoints.stockHolderOrderingConfiguration;
const StockHolderOrderConfigurationSlice = createSlice({
  name: "StockHolderOrderConfiguration",
  initialState,
  reducers: {
    StockHolderOrderConfigurationSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        StockHolderOrderConfiguration: row,
      };
    },

    stockHolderStockIdsAndAreaIdsSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        stockHolderStockIdsAndAreaIds: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  StockHolderOrderConfigurationSuccess,
  stockHolderStockIdsAndAreaIdsSuccess,
  resetState,
} = StockHolderOrderConfigurationSlice.actions;

export default StockHolderOrderConfigurationSlice.reducer;

export const getStockHolderOrderConfiguration = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map(
          (StockHolderOrderConfigurationObject, index) => {
            let StockHolderOrderConfigurationData = {
              index: index + 1,
              id:
                StockHolderOrderConfigurationObject.id === null
                  ? ""
                  : StockHolderOrderConfigurationObject.id,
              stockHolderId:
                StockHolderOrderConfigurationObject.stockStakeHolder === null
                  ? ""
                  : StockHolderOrderConfigurationObject.stockStakeHolder.id,
              stockStakeHolderUser:
                StockHolderOrderConfigurationObject.stockStakeHolder === null
                  ? ""
                  : StockHolderOrderConfigurationObject.stockStakeHolder
                      .userName,
              brandName:
                StockHolderOrderConfigurationObject.stockStakeHolder === null
                  ? ""
                  : StockHolderOrderConfigurationObject.stockStakeHolder.brand
                      .name,
              stockStakeHolderType:
                StockHolderOrderConfigurationObject.stockStakeHolderType ===
                null
                  ? ""
                  : StockHolderOrderConfigurationObject.stockStakeHolder
                      .stockStakeHolderType.name,
              stockStakeHolderOwner:
                StockHolderOrderConfigurationObject.stockStakeHolder === null
                  ? ""
                  : StockHolderOrderConfigurationObject.stockStakeHolder
                      .ownerName,
              stockStakeHolderFirm:
                StockHolderOrderConfigurationObject.stockStakeHolder === null
                  ? ""
                  : StockHolderOrderConfigurationObject.stockStakeHolder
                      .firmName,
              stockStakeHolderContactPerson:
                StockHolderOrderConfigurationObject.stockStakeHolder === null
                  ? ""
                  : StockHolderOrderConfigurationObject.stockStakeHolder
                      .contactPersonName,
              stockStakeHolderContactPersonNo:
                StockHolderOrderConfigurationObject.stockStakeHolder === null
                  ? ""
                  : StockHolderOrderConfigurationObject.stockStakeHolder
                      .contactPersonNo,
              address:
                StockHolderOrderConfigurationObject.stockStakeHolder === null
                  ? ""
                  : StockHolderOrderConfigurationObject.stockStakeHolder
                      .address,
              state:
                StockHolderOrderConfigurationObject.stockStakeHolder === null
                  ? ""
                  : StockHolderOrderConfigurationObject.stockStakeHolder.state
                      .name,
              region:
                StockHolderOrderConfigurationObject.stockStakeHolder === null
                  ? ""
                  : StockHolderOrderConfigurationObject.stockStakeHolder.state
                      .region.name,
              country:
                StockHolderOrderConfigurationObject.stockStakeHolder === null
                  ? ""
                  : StockHolderOrderConfigurationObject.stockStakeHolder.state
                      .region.country.name,
              emailId:
                StockHolderOrderConfigurationObject.stockStakeHolder === null
                  ? ""
                  : StockHolderOrderConfigurationObject.stockStakeHolder
                      .emailId,
              billName:
                StockHolderOrderConfigurationObject.stockStakeHolder === null
                  ? ""
                  : StockHolderOrderConfigurationObject.stockStakeHolder
                      .billName,
              shCode:
                StockHolderOrderConfigurationObject.stockStakeHolder === null
                  ? ""
                  : StockHolderOrderConfigurationObject.stockStakeHolder.shCode,
              gstNo:
                StockHolderOrderConfigurationObject.stockStakeHolder === null
                  ? ""
                  : StockHolderOrderConfigurationObject.stockStakeHolder.gstNo,
              panNo:
                StockHolderOrderConfigurationObject.stockStakeHolder === null
                  ? ""
                  : StockHolderOrderConfigurationObject.stockStakeHolder.panNo,
              adhaarNo:
                StockHolderOrderConfigurationObject.stockStakeHolder === null
                  ? ""
                  : StockHolderOrderConfigurationObject.stockStakeHolder
                      .adhaarNo,
              creditLimit:
                StockHolderOrderConfigurationObject.stockStakeHolder === null
                  ? ""
                  : StockHolderOrderConfigurationObject.stockStakeHolder
                      .creditLimit,
              gstType:
                StockHolderOrderConfigurationObject.stockStakeHolder === null
                  ? ""
                  : StockHolderOrderConfigurationObject.stockStakeHolder
                      .gstType,
              fssiFdiNo:
                StockHolderOrderConfigurationObject.stockStakeHolder === null
                  ? ""
                  : StockHolderOrderConfigurationObject.stockStakeHolder
                      .fssiFdiNo,
              orderTo:
                StockHolderOrderConfigurationObject.stockStakeHolder === null
                  ? ""
                  : StockHolderOrderConfigurationObject.stockStakeHolder
                      .orderTo,
              fromTime:
                StockHolderOrderConfigurationObject.stockStakeHolder === null
                  ? ""
                  : StockHolderOrderConfigurationObject.fromTime,
              toTime:
                StockHolderOrderConfigurationObject.stockStakeHolder === null
                  ? ""
                  : StockHolderOrderConfigurationObject.toTime,
              isActive:
                StockHolderOrderConfigurationObject.stockStakeHolder === null
                  ? ""
                  : StockHolderOrderConfigurationObject.isActive,
            };
            return StockHolderOrderConfigurationData;
          }
        );
        dispatch(StockHolderOrderConfigurationSuccess({ row: row }));
        return { response: [], success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getStockHolderOrderConfigurationFilter =
  ({ stockStakeHolderTypeIds, fromTime, toTime }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-stock-stake-holder-and-time?stockStakeHolderTypeIds=" +
          stockStakeHolderTypeIds +
          "&fromTime=" +
          fromTime +
          "&toTime=" +
          toTime,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map(
            (StockHolderOrderConfigurationObject, index) => {
              let StockHolderOrderConfigurationData = {
                index: index + 1,
                id:
                  StockHolderOrderConfigurationObject.id === null
                    ? ""
                    : StockHolderOrderConfigurationObject.id,
                stockStakeHolderUser:
                  StockHolderOrderConfigurationObject.stockStakeHolder === null
                    ? ""
                    : StockHolderOrderConfigurationObject.stockStakeHolder
                        .userName,
                brandName:
                  StockHolderOrderConfigurationObject.stockStakeHolder === null
                    ? ""
                    : StockHolderOrderConfigurationObject.stockStakeHolder.brand
                        .name,
                stockStakeHolderType:
                  StockHolderOrderConfigurationObject.stockStakeHolderType ===
                  null
                    ? ""
                    : StockHolderOrderConfigurationObject.stockStakeHolder
                        .stockStakeHolderType.name,
                stockStakeHolderOwner:
                  StockHolderOrderConfigurationObject.stockStakeHolder === null
                    ? ""
                    : StockHolderOrderConfigurationObject.stockStakeHolder
                        .ownerName,
                stockStakeHolderFirm:
                  StockHolderOrderConfigurationObject.stockStakeHolder === null
                    ? ""
                    : StockHolderOrderConfigurationObject.stockStakeHolder
                        .firmName,
                stockStakeHolderContactPerson:
                  StockHolderOrderConfigurationObject.stockStakeHolder === null
                    ? ""
                    : StockHolderOrderConfigurationObject.stockStakeHolder
                        .contactPersonName,
                stockStakeHolderContactPersonNo:
                  StockHolderOrderConfigurationObject.stockStakeHolder === null
                    ? ""
                    : StockHolderOrderConfigurationObject.stockStakeHolder
                        .contactPersonNo,
                address:
                  StockHolderOrderConfigurationObject.stockStakeHolder === null
                    ? ""
                    : StockHolderOrderConfigurationObject.stockStakeHolder
                        .address,
                state:
                  StockHolderOrderConfigurationObject.stockStakeHolder === null
                    ? ""
                    : StockHolderOrderConfigurationObject.stockStakeHolder.state
                        .name,
                region:
                  StockHolderOrderConfigurationObject.stockStakeHolder === null
                    ? ""
                    : StockHolderOrderConfigurationObject.stockStakeHolder.state
                        .region.name,
                country:
                  StockHolderOrderConfigurationObject.stockStakeHolder === null
                    ? ""
                    : StockHolderOrderConfigurationObject.stockStakeHolder.state
                        .region.country.name,
                emailId:
                  StockHolderOrderConfigurationObject.stockStakeHolder === null
                    ? ""
                    : StockHolderOrderConfigurationObject.stockStakeHolder
                        .emailId,
                billName:
                  StockHolderOrderConfigurationObject.stockStakeHolder === null
                    ? ""
                    : StockHolderOrderConfigurationObject.stockStakeHolder
                        .billName,
                shCode:
                  StockHolderOrderConfigurationObject.stockStakeHolder === null
                    ? ""
                    : StockHolderOrderConfigurationObject.stockStakeHolder
                        .shCode,
                gstNo:
                  StockHolderOrderConfigurationObject.stockStakeHolder === null
                    ? ""
                    : StockHolderOrderConfigurationObject.stockStakeHolder
                        .gstNo,
                panNo:
                  StockHolderOrderConfigurationObject.stockStakeHolder === null
                    ? ""
                    : StockHolderOrderConfigurationObject.stockStakeHolder
                        .panNo,
                adhaarNo:
                  StockHolderOrderConfigurationObject.stockStakeHolder === null
                    ? ""
                    : StockHolderOrderConfigurationObject.stockStakeHolder
                        .adhaarNo,
                creditLimit:
                  StockHolderOrderConfigurationObject.stockStakeHolder === null
                    ? ""
                    : StockHolderOrderConfigurationObject.stockStakeHolder
                        .creditLimit,
                gstType:
                  StockHolderOrderConfigurationObject.stockStakeHolder === null
                    ? ""
                    : StockHolderOrderConfigurationObject.stockStakeHolder
                        .gstType,
                fssiFdiNo:
                  StockHolderOrderConfigurationObject.stockStakeHolder === null
                    ? ""
                    : StockHolderOrderConfigurationObject.stockStakeHolder
                        .fssiFdiNo,
                orderTo:
                  StockHolderOrderConfigurationObject.stockStakeHolder === null
                    ? ""
                    : StockHolderOrderConfigurationObject.stockStakeHolder
                        .orderTo,
                fromTime:
                  StockHolderOrderConfigurationObject.stockStakeHolder === null
                    ? ""
                    : StockHolderOrderConfigurationObject.fromTime,
                toTime:
                  StockHolderOrderConfigurationObject.stockStakeHolder === null
                    ? ""
                    : StockHolderOrderConfigurationObject.toTime,
                isActive:
                  StockHolderOrderConfigurationObject.stockStakeHolder === null
                    ? ""
                    : StockHolderOrderConfigurationObject.isActive,
              };
              return StockHolderOrderConfigurationData;
            }
          );
          dispatch(StockHolderOrderConfigurationSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const setStockHolderOrderConfiguration =
  ({ row }) =>
  async (dispatch) => {
    dispatch(StockHolderOrderConfigurationSuccess({ row }));
  };

export const getStockHolderByStockIdsAndAreaIds =
  ({ stockStakeHolderTypeIds, areaIds }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-stock-stake-holder?stockStakeHolderTypeIds=" +
          stockStakeHolderTypeIds +
          "&areaIds=" +
          areaIds,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((stakeHolderTypeObj) => {
            const stakeHolderTypeData = {
              ...stakeHolderTypeObj,
              name:
                stakeHolderTypeObj.ownerName !== null &&
                stakeHolderTypeObj.ownerName
                  ? stakeHolderTypeObj.ownerName
                  : "",
              fromTime: "",
              toTime: "",
            };
            return stakeHolderTypeData;
          });
          dispatch(stockHolderStockIdsAndAreaIdsSuccess({ row: row }));
          return { response: [], success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStockHolderByStockIdsAndAreaIdsUpdated =
  ({ stockHolderByAreaAndTypesJson }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url: URL + "/get-stock-holder-by-areas-and-stock-holder-types",
        postBody: stockHolderByAreaAndTypesJson,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((stakeHolderTypeObj) => {
            const stakeHolderTypeData = {
              ...stakeHolderTypeObj,
              name:
                stakeHolderTypeObj.ownerName !== null &&
                stakeHolderTypeObj.ownerName
                  ? stakeHolderTypeObj.ownerName
                  : "",
              fromTime: "",
              toTime: "",
            };
            return stakeHolderTypeData;
          });
          dispatch(stockHolderStockIdsAndAreaIdsSuccess({ row: row }));
          return { response: [], success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const setStockHolderByStockIdsAndAreaIds =
  ({ row }) =>
  async (dispatch) => {
    dispatch(stockHolderStockIdsAndAreaIdsSuccess({ row }));
  };
