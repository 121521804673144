import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicReportGenerate";
import { ExcelReportsForInactiveJson } from "../../DynamicFormsJson/MastersJSON/excelReportsForInactiveJson";
import { getInactiveSalesPersonByArea } from "../../Slice/salesExecutive.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import {
  serverMsg,
  noInternetMsg,
  savemsg,
  saveFailedMsg,
} from "../../config/messageconstant";
import { getRegion } from "../../Slice/region.slice";
import { getBeatsByAreasPostUpdated } from "../../Slice/beat.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import {
  getAreaByTalukaPostBodyUpdated,
  getAreaByUserType,
} from "../../Slice/area.slice";
import { getCategory } from "../../Slice/category.slice";
import { getActiveProductByCategoryForAllPost } from "../../Slice/product.slice";
import { getInActiveStockStakeHolder } from "../../Slice/StackHolder.slice";
import dayjs from "dayjs";
import { apiGet, apiPost } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
import { downloadExcel, downloadF } from "../PDF/PDFDownload";
import config from "../../config/config";

class ExcelReportsForInactive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        stockStakeHolderTypeDetail:
          this.props.stockStakeHolderType?.stockStakeHolderType,
        salesPersonDetail: this.props.salesPersonList?.salesPersonByArea,
        categoryDetail: this.props.categoryList.category,
        productDetail: this.props.productList.activeProductByCategory,
      },
    };
  }

  async componentDidMount() {
    const {
      getStockStakeHolderType,
      getCategory,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getStockStakeHolderType({ userTypeIds: 2 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      await getCategory().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.salesPersonList !== nextProps.salesPersonList) {
      if (
        this.props.salesPersonList?.salesPersonByArea !==
        nextProps.salesPersonList?.salesPersonByArea
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            salesPersonDetail: nextProps.salesPersonList?.salesPersonByArea,
          },
        });
      }
    }

    if (this.props.stockStakeHolderType !== nextProps.stockStakeHolderType) {
      if (
        this.props.stockStakeHolderType?.stockStakeHolderType !==
        nextProps.stockStakeHolderType?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stockStakeHolderTypeDetail:
              nextProps.stockStakeHolderType?.stockStakeHolderType,
          },
        });
      }
    }
    if (this.props.stakeholderList !== nextProps.stakeholderList) {
      if (
        this.props.stakeholderList?.stackholderNameFromType !==
        nextProps.stakeholderList?.stackholderNameFromType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeHolderName: nextProps.stakeholderList?.stackholderNameFromType,
          },
        });
      }
    }

    if (this.props.categoryList !== nextProps.categoryList) {
      if (
        this.props.categoryList?.category !== nextProps.categoryList?.category
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            categoryDetail: nextProps.categoryList?.category,
          },
        });
      }
    }

    if (this.props.productList !== nextProps.productList) {
      if (
        this.props.productList?.activeProductByCategory !==
        nextProps.productList?.activeProductByCategory
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            productDetail: nextProps.productList?.activeProductByCategory,
          },
        });
      }
    }
  }

  getListById = async (data) => {
    const {
      showLoador,
      getInActiveStockStakeHolder,
      getActiveProductByCategoryForAllPost,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      console.log(data);

      this.setState({
        fromDate: dayjs(data.fromDate).format("DD-MM-YYYY"),
        toDate: dayjs(data.toDate).format("DD-MM-YYYY"),
      });

      if (data.stockStakeHolderTypeId && data.stockStakeHolderTypeId != "") {
        let jsonData = {
          stockStakeHolderTypeId: data.stockStakeHolderTypeId,
        };
        showLoador({ loador: true });
        await getInActiveStockStakeHolder({
          jsonData: jsonData,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }

      if (data.category && data.category != "") {
        showLoador({ loador: true });
        await getActiveProductByCategoryForAllPost({
          categoryIds: data.category,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  getExcel = (row, rowData) => {
    const { showLoador, showNotification } = this.props;

    if (navigator.onLine) {
      if (rowData.index == 1) {
        const dataToSave = {
          fromDate: row.fromDate,
          toDate: row.toDate,
          stockStakeHolderIds: row.stockStakeHolder,
          categoryIds: row.category,
        };

        showLoador({ loador: true });
        downloadF({
          isGet: false,
          url: endpoint.exportData + "/product-wise-sale-register-excel",
          postBody: dataToSave,
          ext: "xls",
          openNewTab: false,
        }).then((response) => {
          if (!response) {
            showNotification({
              msg: serverMsg,
              severity: "error",
            });
          }
          showLoador({ loador: false });
        });
      }
      if (rowData.index == 3) {
        const dataToSave = {
          fromDate: row.fromDate,
          toDate: row.toDate,
          stockStakeHolderId: row.stockStakeHolder,
          categoryIds: row.category,
          productIds: row.product,
        };

        showLoador({ loador: true });
        downloadF({
          isGet: false,
          url: endpoint.monthlySalesAnalysis + "/in-active",
          postBody: dataToSave,
          ext: "xls",
          openNewTab: false,
        }).then((response) => {
          if (!response) {
            showNotification({
              msg: serverMsg,
              severity: "error",
            });
          }
          showLoador({ loador: false });
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  rowDataGklAdm = [
    {
      isStatic: true,
      index: 1,
      reportName: "Product Wise Sale Register (Admin - D0 to D4)",
      filter:
        "fromDate,toDate,category,stockStakeHolderTypeId,stockStakeHolder",
      url:
        config.urlStart +
        "/v1/report/product-sale-register?fromDate={fromDate}&toDate={toDate}&stockStakeHolderType={stockStakeHolderTypeId}&stockStakeHolder={stockStakeHolder}",
    },
    {
      index: 2,
      reportName: "Distributor Report (Admin - D0 to D1, D3, D4)",
      filter: "fromDate,toDate,stockStakeHolderTypeId,stockStakeHolder,",
      url:
        config.urlStart +
        "/v1/monthly-sale-bill-analysis/new-report?fromDate={fromDate}&toDate={toDate}&distributorId={stockStakeHolder}",
    },
    {
      isStatic: true,
      index: 3,
      reportName: "Monthly Sales Analysis (Admin - D0 to D5)",
      filter:
        "fromDate,toDate,category,product,stockStakeHolderTypeId,stockStakeHolder",
      url: config.urlStart + "/v1/monthly-sales-analysis/in-active",
    },
  ];

  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          getExcel={this.getExcel}
          screenTitle={ExcelReportsForInactiveJson.screenTitle}
          fieldMeta={ExcelReportsForInactiveJson.fieldMeta}
          buttonCenter={ExcelReportsForInactiveJson.buttonCenter}
          dynamicMasterData={dynamicMasterData}
          getListById={this.getListById}
          searchButton={ExcelReportsForInactiveJson.searchButton}
          PDFButton={ExcelReportsForInactiveJson.PDFButton}
          EXCELButton={ExcelReportsForInactiveJson.EXCELButton}
          baseIdColumn={ExcelReportsForInactiveJson.baseIdColumn}
          apiBaseURL={ExcelReportsForInactiveJson.apiBaseURL}
          marginTop={ExcelReportsForInactiveJson.marginTop}
          openNewTab={true}
          rowData={this.rowDataGklAdm}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  beatList: state.beat,
  areaList: state.area,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  stockStakeHolderType: state.stockStakeHolderType,
  categoryList: state.category,
  stakeholderList: state.stockholder,
  productList: state.product,
  salesPersonList: state.salesExecutive,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getRegion,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTalukaPostBodyUpdated,
  getAreaByUserType,
  getStateByRegion,
  getBeatsByAreasPostUpdated,
  getStockStakeHolderType,
  getCategory,
  getActiveProductByCategoryForAllPost,
  getInActiveStockStakeHolder,
  getInactiveSalesPersonByArea,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExcelReportsForInactive);
