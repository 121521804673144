import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
import dayjs from "dayjs";
const initialState = {
  leaveStructure: [],
  activeleaveStructure: [],
  leaveStructureAllotment: [],
};

let URL = endpoints.leaveStructure;
let URL1 = endpoints.leaveStructureAllotment;

const leaveStructureSlice = createSlice({
  name: "leaveStructure",
  initialState,
  reducers: {
    leaveStructureSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        leaveStructure: row,
      };
    },
    activeLeaveStructutureSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        activeleaveStructure: row,
      };
    },

    leaveStructureAllotmentSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        leaveStructureAllotment: row,
      };
    },


    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { leaveStructureSuccess, leaveStructureAllotmentSuccess, activeLeaveStructutureSuccess, resetState } = leaveStructureSlice.actions;

export default leaveStructureSlice.reducer;

export const getleaveStructureList = () => async (dispatch) => {
  try {
    const response = apiGet({

      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((leaveStructureObject, index) => {
          let leaveStructureData = {
            index: index + 1,
            id: leaveStructureObject.id === null ? "" : leaveStructureObject.id,
            name:
              leaveStructureObject.name === null
                ? ""
                : leaveStructureObject.name,




            isActive: leaveStructureObject.isActive === null
              ? ""
              : leaveStructureObject.isActive,
            extra1: leaveStructureObject.extra1 === null
              ? ""
              : leaveStructureObject.extra1,
            company: leaveStructureObject.company === null
              ? ""
              : leaveStructureObject.company,
            leaveStructureDetails: leaveStructureObject.leaveStructureDetails === null
              ? ""
              : leaveStructureObject.leaveStructureDetails,


          };
          return leaveStructureData;
        });
        dispatch(leaveStructureSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};


export const getActiveLeaveStructure = () => async (dispatch) => {
  try {
    const response = apiGet({

      url: URL + "/get-active-list",
    }).then(({ data, success }) => {
      if (success) {

        const row = data.data.map((leaveTypeObject, index) => {
          let leaveTypeData = {
            index: index + 1,
            id: leaveTypeObject.id === null ? "" : leaveTypeObject.id,
            name:
              leaveTypeObject.name === null
                ? ""
                : leaveTypeObject.name,
            leaveSummaryName: leaveTypeObject.name === null
              ? ""
              : leaveTypeObject.name,

            shortName:
              leaveTypeObject.shortName === null
                ? ""
                : leaveTypeObject.shortName,
            isActive: leaveTypeObject.isActive === null
              ? ""
              : leaveTypeObject.isActive,
            extra1: leaveTypeObject.extra1 === null
              ? ""
              : leaveTypeObject.extra1,
          };
          return leaveTypeData;
        });
        dispatch(activeLeaveStructutureSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};



export const getSalesTeamWithLeaveStructure = ({ dataJson }) => async (dispatch) => {
  try {
    const response = apiPost({
      url: URL1 + "/get-sales-team-with-leave-structure",
      postBody: dataJson,
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((leaveStructureObj, index) => {
          let leaveTypeData = {
            index: index + 1,
            id: leaveStructureObj.dtoSalesTeamWithAreaNames && leaveStructureObj.dtoSalesTeamWithAreaNames.id === null ? "" : leaveStructureObj.dtoSalesTeamWithAreaNames.id,
            leaveStructureAllotmentId: leaveStructureObj.leaveStructureAllotment === null || leaveStructureObj.leaveStructureAllotment.id === null
              ? ""
              : leaveStructureObj.leaveStructureAllotment.id,
            name:
              leaveStructureObj.dtoSalesTeamWithAreaNames && leaveStructureObj.dtoSalesTeamWithAreaNames.name === null
                ? ""
                : leaveStructureObj.dtoSalesTeamWithAreaNames.name,
            areaNames: leaveStructureObj.dtoSalesTeamWithAreaNames && leaveStructureObj.dtoSalesTeamWithAreaNames.areaNames === null
              ? ""
              : leaveStructureObj.dtoSalesTeamWithAreaNames.areaNames,
            isChecked: false,
            address: leaveStructureObj.dtoSalesTeamWithAreaNames && leaveStructureObj.dtoSalesTeamWithAreaNames.address === null
              ? ""
              : leaveStructureObj.dtoSalesTeamWithAreaNames.address,
            fromDate: leaveStructureObj.leaveStructureAllotment === null || leaveStructureObj.leaveStructureAllotment.fromDate === null
              ? ""
              : dayjs(leaveStructureObj.leaveStructureAllotment.fromDate, "DD-MM-YYYY"),
            fromDateFlag: false,
            toDate: leaveStructureObj.leaveStructureAllotment === null || leaveStructureObj.leaveStructureAllotment.toDate === null
              ? ""
              : dayjs(leaveStructureObj.leaveStructureAllotment.toDate, "DD-MM-YYYY"),
          };
          return leaveTypeData;
        });
        dispatch(leaveStructureAllotmentSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};




export const setSalesTeamWithLeaveStructure =
  ({ row }) =>
    async (dispatch) => {
      dispatch(leaveStructureAllotmentSuccess({ row }));
    };
