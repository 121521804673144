import endpoint from "../../config/endpoints";

export const subCategoryJson = {
  formPath: "/sub-category-form",
  apiBaseURL: endpoint.subCategory,
  changeExcel: endpoint.exportData,
  screenTitle: "Sub Category",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "SubCategory",
  showExcelDownload: true,
  excelFileName: "SubCategory",
  excelUrl: "sub-category-data" + '?sort={"sortOrder": "ASC"}',

  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "subCategoryName",
      isChecked: true,
    },
    {
      columnDisplayName: "Category",
      columnKeyName: "categoryName",
      isChecked: true,
    },
    {
      columnDisplayName: "Product Count",
      columnKeyName: "productCount",
      isChecked: true,
    },
    {
      columnDisplayName: "Sort Order Number",
      columnKeyName: "sortOrder",
      isChecked: true,
    },
    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "Category",
      controlType: "autocomplete",
      placeHolder: "Select Category",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "categoryDetail",
      dataKey: "category",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "name",
      isMandatory: true,
      uniqueValidation: true,
    },
    {
      label: "Short Name",
      controlType: "textfield",
      placeHolder: "Short Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "shortName",
      isMandatory: true,
    },

    {
      label: "Sort Order No",
      controlType: "textfield",
      placeHolder: "Sort Order Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sortOrder",
      isMandatory: true,
    },
  ],
};
