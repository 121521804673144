import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  paymentData: [],
  remainingAmt: {},
  paymentReceiptOutletDetail: [],
};
let URL = endpoints.paymentReceiptOutlet;
let URL1 = endpoints.paymentRecieptOutletDetail;
const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    paymentSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        paymentData: row,
        isFetch: true,
      };
    },
    remainingAmtSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        remainingAmt: row,
        isFetch: true,
      };
    },
    paymentReceiptOutletDetailSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        paymentReceiptOutletDetail: row,
        isFetch: true,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  paymentSuccess,
  remainingAmtSuccess,
  paymentReceiptOutletDetailSuccess,
  resetState,
} = paymentSlice.actions;

export default paymentSlice.reducer;
export const getLatestPayment = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/latest",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((paymentObject, index) => {
          let paymentData = {
            index: index + 1,
            id: paymentObject.id === null ? "" : paymentObject.id,
            billNo: paymentObject.billNo === null ? "" : paymentObject.billNo,
            billDate:
              paymentObject.billDate === null ? "" : paymentObject.billDate,
            beatName:
              paymentObject.outLet === null
                ? ""
                : paymentObject.outLet.beat.beatName,
            firmName:
              paymentObject.outLet === null
                ? ""
                : paymentObject.outLet.firmName,
            date: paymentObject.date === null ? "" : paymentObject.date,
            amt: paymentObject.amt === null ? "" : paymentObject.amt.toFixed(2),
            settleRemainingAmt:
              paymentObject.settleRemainingAmt === null
                ? ""
                : paymentObject.settleRemainingAmt.toFixed(2),
            payType:
              paymentObject.payType === null
                ? ""
                : paymentObject.payType === 0
                ? "Cash"
                : paymentObject.payType === 1
                ? "Online"
                : "Cheque",
            remark: paymentObject.remark ? paymentObject.remark : "-",
            stockStakeHolderId:
              paymentObject.stockStakeHolderId === null
                ? ""
                : paymentObject.stockStakeHolderId,
            outLet: paymentObject.outLet === null ? "" : paymentObject.outLet,
            trasactionNo:
              paymentObject.trasactionNo === null
                ? ""
                : paymentObject.trasactionNo,

            payRefNo:
              paymentObject.payRefNo === null ? "" : paymentObject.payRefNo,

            payBank:
              paymentObject.payBank === null ? "" : paymentObject.payBank,
            remark2:
              paymentObject.remark2 === null ? "" : paymentObject.remark2,
            insertedUserId:
              paymentObject.insertedUserId === null
                ? ""
                : paymentObject.insertedUserId,
            insertDateTime:
              paymentObject.insertDateTime === null
                ? ""
                : paymentObject.insertDateTime,
            deleteStatus:
              paymentObject.amt === paymentObject.settleRemainingAmt ? 0 : 1,
          };

          return paymentData;
        });
        dispatch(paymentSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getPayment =
  ({ outletIds, fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-outlets?outletIds=" +
          outletIds +
          "&fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((paymentObject, index) => {
            let paymentData = {
              index: index + 1,
              id: paymentObject.id === null ? "" : paymentObject.id,
              billNo: paymentObject.billNo === null ? "" : paymentObject.billNo,
              billDate:
                paymentObject.billDate === null ? "" : paymentObject.billDate,
              beatName:
                paymentObject.outLet === null
                  ? ""
                  : paymentObject.outLet.beat.beatName,
              firmName:
                paymentObject.outLet === null
                  ? ""
                  : paymentObject.outLet.firmName,
              date: paymentObject.date === null ? "" : paymentObject.date,
              amt:
                paymentObject.amt === null ? "" : paymentObject.amt.toFixed(2),
              settleRemainingAmt:
                paymentObject.settleRemainingAmt === null
                  ? ""
                  : paymentObject.settleRemainingAmt.toFixed(2),
              payType:
                paymentObject.payType === null
                  ? ""
                  : paymentObject.payType === 0
                  ? "Cash"
                  : paymentObject.payType === 1
                  ? "Online"
                  : "Cheque",

              remark: paymentObject.remark === null ? "" : paymentObject.remark,
              stockStakeHolderId:
                paymentObject.stockStakeHolderId === null
                  ? ""
                  : paymentObject.stockStakeHolderId,
              outLet: paymentObject.outLet === null ? "" : paymentObject.outLet,
              trasactionNo:
                paymentObject.trasactionNo === null
                  ? ""
                  : paymentObject.trasactionNo,

              payRefNo:
                paymentObject.payRefNo === null ? "" : paymentObject.payRefNo,

              payBank:
                paymentObject.payBank === null ? "" : paymentObject.payBank,
              remark2:
                paymentObject.remark2 === null ? "" : paymentObject.remark2,
              insertedUserId:
                paymentObject.insertedUserId === null
                  ? ""
                  : paymentObject.insertedUserId,
              insertDateTime:
                paymentObject.insertDateTime === null
                  ? ""
                  : paymentObject.insertDateTime,
              deleteStatus:
                paymentObject.amt === paymentObject.settleRemainingAmt ? 0 : 1,
            };

            return paymentData;
          });
          dispatch(paymentSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getPaymentPost =
  ({ paymentData }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url: URL + "/by-outlets",
        postBody: paymentData,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((paymentObject, index) => {
            let paymentData = {
              index: index + 1,
              id: paymentObject.id === null ? "" : paymentObject.id,
              billNo: paymentObject.billNo === null ? "" : paymentObject.billNo,
              billDate:
                paymentObject.billDate === null ? "" : paymentObject.billDate,
              beatName:
                paymentObject.outLet === null
                  ? ""
                  : paymentObject.outLet.beat.beatName,
              firmName:
                paymentObject.outLet === null
                  ? ""
                  : paymentObject.outLet.firmName,
              date: paymentObject.date === null ? "" : paymentObject.date,
              amt:
                paymentObject.amt === null ? "" : paymentObject.amt.toFixed(2),
              settleRemainingAmt:
                paymentObject.settleRemainingAmt === null
                  ? ""
                  : paymentObject.settleRemainingAmt.toFixed(2),
              payType:
                paymentObject.payType === null
                  ? ""
                  : paymentObject.payType === 0
                  ? "Cash"
                  : paymentObject.payType === 1
                  ? "Online"
                  : "Cheque",

              remark: paymentObject.remark === null ? "" : paymentObject.remark,
              stockStakeHolderId:
                paymentObject.stockStakeHolderId === null
                  ? ""
                  : paymentObject.stockStakeHolderId,
              outLet: paymentObject.outLet === null ? "" : paymentObject.outLet,
              trasactionNo:
                paymentObject.trasactionNo === null
                  ? ""
                  : paymentObject.trasactionNo,

              payRefNo:
                paymentObject.payRefNo === null ? "" : paymentObject.payRefNo,

              payBank:
                paymentObject.payBank === null ? "" : paymentObject.payBank,
              remark2:
                paymentObject.remark2 === null ? "" : paymentObject.remark2,
              insertedUserId:
                paymentObject.insertedUserId === null
                  ? ""
                  : paymentObject.insertedUserId,
              insertDateTime:
                paymentObject.insertDateTime === null
                  ? ""
                  : paymentObject.insertDateTime,
              deleteStatus:
                paymentObject.amt === paymentObject.settleRemainingAmt ? 0 : 1,
            };
            return paymentData;
          });
          dispatch(paymentSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return { response: [], success: false };
    }
  };


  export const getUpdatedPaymentPost =
  ({ paymentData }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        // http://localhost:8088/api/distribution/v1/payment-reciept-outlet/by-outlets-new
        url: URL + "/by-outlets-new",
        postBody: paymentData,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((paymentObject, index) => {
            let paymentData = {
              index: index + 1,
              id: paymentObject.id === null ? "" : paymentObject.id,
              billNo: paymentObject.billNo === null ? "" : paymentObject.billNo,
              billDate:
                paymentObject.billDate === null ? "" : paymentObject.billDate,
              beatName:
                paymentObject.beatName === null
                  ? ""
                  : paymentObject.beatName,
              firmName:
                paymentObject.firmName === null
                  ? ""
                  : paymentObject.firmName,
              date: paymentObject.date === null ? "" : paymentObject.date,
              amt:
                paymentObject.amt === null ? "" : paymentObject.amt.toFixed(2),
              settleRemainingAmt:
                paymentObject.settleRemainingAmt === null
                  ? ""
                  : paymentObject.settleRemainingAmt.toFixed(2),
              payType:
                paymentObject.payType === null
                  ? ""
                  : paymentObject.payType === 0
                  ? "Cash"
                  : paymentObject.payType === 1
                  ? "Online"
                  : "Cheque",

              remark: paymentObject.remark === null ? "" : paymentObject.remark,
              stockStakeHolderId:
                paymentObject.stockStakeHolderId === null
                  ? ""
                  : paymentObject.stockStakeHolderId,
              outLet: paymentObject.outletId === null ? "" : paymentObject.outletId,
              trasactionNo:
                paymentObject.transactionNo === null
                  ? ""
                  : paymentObject.transactionNo,

              payRefNo:
                paymentObject.payRefNo === null ? "" : paymentObject.payRefNo,

              payBank:
                paymentObject.payBank === null ? "" : paymentObject.payBank,
              remark2:
                paymentObject.remark2 === null ? "" : paymentObject.remark2,
              insertedUserId:
                paymentObject.insertedUserId === null
                  ? ""
                  : paymentObject.insertedUserId,
              insertDateTime:
                paymentObject.insertDateTime === null
                  ? ""
                  : paymentObject.insertDateTime,
              deleteStatus:
                paymentObject.amt === paymentObject.settleRemainingAmt ? 0 : 1,
            };
            return paymentData;
          });
          dispatch(paymentSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return { response: [], success: false };
    }
  };

export const setPayment = () => async (dispatch) => {
  dispatch(paymentSuccess({ row: [] }));
};

export const getRemainigAmt =
  ({ outletId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/settle-remaining-sum-by-outlet?outletId=" + outletId,
      }).then(({ data, success }) => {
        if (success) {
          dispatch(paymentSuccess({ row: [] }));
          return { response: [], success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getPaymentRecieptOutletDetail =
  ({ paymentRecieptOutletId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/by-payment-reciept-outlet?paymentRecieptOutletId=" +
          paymentRecieptOutletId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((paymentRecieptOutletDetailObj, index) => {
            let paymentRecieptOutletDetailData = {
              // ...paymentRecieptOutletDetailObj,
              index: index + 1,
              id:
                paymentRecieptOutletDetailObj.id === null
                  ? ""
                  : paymentRecieptOutletDetailObj.id,

              beatName:
                paymentRecieptOutletDetailObj.paymentRecieptOutlet.outLet ===
                null
                  ? ""
                  : paymentRecieptOutletDetailObj.paymentRecieptOutlet.outLet
                      .beat.beatName,
              firmName:
                paymentRecieptOutletDetailObj.paymentRecieptOutlet.outLet ===
                null
                  ? ""
                  : paymentRecieptOutletDetailObj.paymentRecieptOutlet.outLet
                      .firmName,
              billDate:
                paymentRecieptOutletDetailObj.outletBillHeader.billDate === null
                  ? ""
                  : paymentRecieptOutletDetailObj.outletBillHeader.billDate,
              billNo:
                paymentRecieptOutletDetailObj.outletBillHeader.billNo === null
                  ? ""
                  : paymentRecieptOutletDetailObj.outletBillHeader.billNo,
              date:
                paymentRecieptOutletDetailObj.paymentRecieptOutlet.date === null
                  ? ""
                  : paymentRecieptOutletDetailObj.paymentRecieptOutlet.date,
              amt:
                paymentRecieptOutletDetailObj.paymentRecieptOutlet.amt === null
                  ? ""
                  : paymentRecieptOutletDetailObj.paymentRecieptOutlet.amt.toFixed(
                      2
                    ),
              settleRemainingAmt:
                paymentRecieptOutletDetailObj.paymentRecieptOutlet
                  .settleRemainingAmt === null
                  ? ""
                  : paymentRecieptOutletDetailObj.paymentRecieptOutlet
                      .settleRemainingAmt,
              payType:
                paymentRecieptOutletDetailObj.paymentRecieptOutlet.payType ===
                null
                  ? ""
                  : paymentRecieptOutletDetailObj.paymentRecieptOutlet
                      .payType === 0
                  ? "Cash"
                  : paymentRecieptOutletDetailObj.payType === 1
                  ? "Cheque"
                  : "Online",
              remark:
                paymentRecieptOutletDetailObj.paymentRecieptOutlet.remark ===
                null
                  ? ""
                  : paymentRecieptOutletDetailObj.paymentRecieptOutlet.remark,
              payRefNo:
                paymentRecieptOutletDetailObj.payRefNo === null
                  ? ""
                  : paymentRecieptOutletDetailObj.payRefNo,
              payBank:
                paymentRecieptOutletDetailObj.payBank === null
                  ? ""
                  : paymentRecieptOutletDetailObj.payBank,

              remark2:
                paymentRecieptOutletDetailObj.remark2 === null
                  ? ""
                  : paymentRecieptOutletDetailObj.remark2,
              insertedUserId:
                paymentRecieptOutletDetailObj.insertedUserId === null
                  ? ""
                  : paymentRecieptOutletDetailObj.insertedUserId,
              insertDateTime:
                paymentRecieptOutletDetailObj.insertDateTime === null
                  ? ""
                  : paymentRecieptOutletDetailObj.insertDateTime,
            };

            return paymentRecieptOutletDetailData;
          });
          dispatch(paymentReceiptOutletDetailSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
