import endpoint from "../../config/endpoints";

export const leaveStructureJson = {
  showSaveNextBtn: true,
  formPath: "/leave-structure-form",
  apiBaseURL: endpoint.leaveStructure,
  changeExcel: endpoint.leaveStructure,
  screenTitle: "Leave Structure",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Leave-Structure",
  showExcelDownload: false,
  excelFileName: "Leave-Structure",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Leave Structure",
      columnKeyName: "name",
      isChecked: true,
    },
    
  ],
  fieldMeta: [
    {
      label: "Leave Structure",
      controlType: "textfield",
      placeHolder: "Leave Structure",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "name",
      isMandatory: true,
      uniqueValidation: true,
    },
    
   
     
  ],
};
