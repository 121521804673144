import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import DynamicFormForDocumentUpload from "../../../components/dynamicscreens/DynamicFormForDocumentUpload";
import { withRouter } from "../../../components/withRouter";
import { VenderJson } from "../../../DynamicFormsJson/MastersJSON/vender";
import { noInternetMsg, serverMsg } from "../../../config/messageconstant";
import { apiGet } from "../../../utils/api_service";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { getRegionNew } from "../../../Slice/region.slice";
import { getZoneByRegionId } from "../../../Slice/zone.slice";

import { getAreaByZoneIds } from "../../../Slice/area.slice";

class VenderForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicMasterData: {
            },
        };
    }


    async componentDidMount() {
        const { getRegionNew, showLoador, showNotification } =
            this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            getRegionNew().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });

        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }


    componentWillReceiveProps(nextProps) {
        if (this.props.regionList !== nextProps.regionList) {
            if (this.props.regionList?.region !== nextProps.regionList?.region) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        regionDetail: nextProps.regionList?.region,
                    },
                });
            }
        }

        if (this.props.zoneList !== nextProps.zoneList) {
            if (
                this.props.zoneList?.zoneByRegionId !== nextProps.zoneList?.zoneByRegionId
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        zoneDetail: nextProps.zoneList?.zoneByRegionId,
                    },
                });
            }
        }


        if (this.props.areaList !== nextProps.areaList) {
            if (
                this.props.areaList?.area !== nextProps.areaList?.area
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        areaDetail: nextProps.areaList?.area,
                    },
                });
            }
        }

    }



    getListById = async (data) => {
        const {
            getZoneByRegionId,
            getAreaByZoneIds,
            showLoador,
            showNotification,
        } = this.props;
        if (navigator.onLine) {

            if (data.region && data.region.id != "") {
                showLoador({ loador: true });
                await getZoneByRegionId({ regionId: data.region.id }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }

            if (data.zoneIds && data.zoneIds != "") {
                showLoador({ loador: true });
                await getAreaByZoneIds({ zoneIds: data.zoneIds }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };




    getDataById = ({ id }) => {
        const { showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            const response = apiGet({
                url: VenderJson.apiBaseURL + "/" + id,
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (success) {
                    if (!data.error) {
                        let rowData = data.data;
                        let dataToSet = {
                            ...rowData,
                        };
                        return { response: dataToSet, success };
                    } else {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                    return { response: {}, success: false };
                }
                return { response: {}, success: false };
            });
            return response;
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };
    render() {
        const { dynamicMasterData } = this.state;
        return (
            <>
                <DynamicFormForDocumentUpload
                    formPath={VenderJson.formPath}
                    screenTitle={VenderJson.screenTitle}
                    fieldMeta={VenderJson.fieldMeta}
                    dynamicMasterData={dynamicMasterData}
                    apiBaseURL={VenderJson.apiBaseURL}
                    showSaveNextBtn={VenderJson.showSaveNextBtn}
                    callGetByIdApi={false}
                    getListById={this.getListById}
                    getByIdApi={this.getDataById}
                    isEditURL={VenderJson.isEditURL}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    areaList: state.area,
    regionList: state.region,
    zoneList: state.zone,

});
const mapDispatchToProps = {
    getRegionNew,
    getZoneByRegionId,
    getAreaByZoneIds,
    showLoador,
    showNotification,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(VenderForm);