import endpoint from "../../config/endpoints";

export const projectionJson = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.assignRateConfigurationToStakeholder,
  formPath: "/day-wise-beat-assign-form",
  formPathView: "/day-wise-beat-assign-view",
  screenTitle: "PJP Creation",
  showTitle: true,
  showCancel: false,
  showSaveBtn: false,
  fieldMeta: [
    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "regionDetail",
      dataKey: "region",
      getListId: "region",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "State",
      controlType: "autocomplete",
      placeHolder: "Select State",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "stateDetail",
      dataKey: "state",
      getListFrom: "region",
      getListId: "state",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "autocomplete",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "zoneDetail",
      dataKey: "zone",
      getListFrom: "state",
      getListId: "zone",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "District",
      controlType: "autocomplete",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "districtDetail",
      dataKey: "district",
      getListFrom: "zone",
      getListId: "district",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Taluka",
      controlType: "autocomplete",
      placeHolder: "Select Taluka",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "talukaDetail",
      dataKey: "taluka",
      getListFrom: "district",
      getListId: "taluka",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "areaDetail",
      dataKey: "area",
      getListFrom: "taluka",
      getListId: "area",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Sales Executive ",
      controlType: "autocomplete",
      placeHolder: "Select Sales Executive",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "salesExecutiveDetail",
      dataKey: "salesTeam",
      getListFrom: "area",
      isRootLevelKey: false,
      isMandatory: true,
    },

    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      isMinDate: true,
      minDate: new Date(),
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      isMinDate: true,
      minDate: new Date(),
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "fromDate-ge",
    },
  ],
  fieldMetaForSearch: [
    {
      label: "Stock Holder Type",
      controlType: "multiSelect",
      placeHolder: "Select Stock Holder Type",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      getListId: "stockStakeHolderType",
      masterName: "stakeHolderTypeDetail",
      dataKey: "stockStakeHolderType",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Stock Holder Name",
      controlType: "multiSelect",
      placeHolder: "Select Stock Holder",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      masterName: "stakeHolderName",
      dataKey: "stackholder",
      getListFrom: "stockStakeHolderType",
      isRootLevelKey: false,
      isMandatory: true,
    },
  ],
  searchList: [
    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "area",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
  fieldMetaForStockHolder: [
    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaUserTypeDetail",
      dataKey: "area",
      isRootLevelKey: true,
      isMandatory: true,
      getListId: "area",
    },
    {
      label: "Sales Executive ",
      controlType: "autocomplete",
      placeHolder: "Select Sales Executive",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "salesExecutiveDetail",
      dataKey: "salesTeam",
      getListFrom: "area",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      isMinDate: true,
      minDate: new Date(),
      addOne: true,
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      isMinDate: true,
      minDate: new Date(),
      addOne: true,
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "fromDate-ge",
    },
  ],
};
