import { Box, Chip, Grid, Stack, Typography } from "@mui/material";

const NoDataFound = ({
  title="",
}) => (
    <Typography sx={{p:2}}variant="h6" color="textSecondary">
      {title==""?"No data found":title}
    </Typography>
);
export default NoDataFound;
