import { FormControl } from "@mui/base";
import CloseIcon from "@mui/icons-material/Close";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { pink } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { compose } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import { withRouter } from "../../components/withRouter";
import { checkboxShadowColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  saveFailedMsg,
  saveWarningMsg,
  savemsg,
} from "../../config/messageconstant";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import BatchwisePopUpForOffer from "./BatchwisePopUpForOffer";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

class BillingWithOffer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      checkboxAllData: [],
      offerBillNewData: {},
      billOfferId: "",
      outletHeader: "",
      stockChangedList: [],
      openBill: false,
      previousListOfDetails: [],
    };
  }
  componentDidMount() {
    const { offerBillOutletData } = this.props;
    this.setState({
      offerBillNewData: offerBillOutletData,
      outletHeader: offerBillOutletData.outletOrderHerader,
      previousListOfDetails:
        offerBillOutletData.outletBillsAfterApplyingOffers.outletBillDetails,
    });
  }
  clickSubmitOfferHandler = () => {
    const { offerBillNewData } = this.state;
    const { showNotification, showLoador } = this.props;
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        showLoador({ loador: true });
        apiPost({
          url:
            endpoint.outletBillHeader + "/batch-wise-save-after-appying-offers",
          postBody: offerBillNewData.outletBillsAfterApplyingOffers,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            showNotification({
              msg: savemsg,
            });
            window.location.replace("/batchwise-outlet-billing-list");
          } else {
            showNotification({
              msg: saveFailedMsg,
              severity: "error",
            });
          }
        });
      }
    });
  };
  clickSubmitHandler = () => {
    const { checkboxAllData, offerBillNewData, billOfferId } = this.state;
    if (offerBillNewData.length !== 0) {
      let dataToSave = [];
      if (
        Object.keys(offerBillNewData.outletBillHeader).length !== 0 &&
        offerBillNewData.outletBillHeader.id == billOfferId
      ) {
        let billCheckedOfferData = [];
        if (offerBillNewData.offerHeaders.length !== 0) {
          offerBillNewData.offerHeaders.map((offerData) => {
            if (checkboxAllData.length !== 0) {
              checkboxAllData.map((checkedData) => {
                if (checkedData.id == offerData.id) {
                  let newCheckedData = {
                    ...checkedData,
                    isShow: checkedData.isShow == 1 ? 0 : 1,
                  };
                  billCheckedOfferData.push(newCheckedData);
                }
              });
            }
          });
        }
        let billObjectData = {
          outletBillHeader: offerBillNewData.outletBillHeader,
          offerHeaders: billCheckedOfferData,
          productAndStocks: offerBillNewData.productAndStocks,
        };
        dataToSave.push(billObjectData);
      }
      if (dataToSave.length !== 0) {
        this.onSave(dataToSave[0]);
      }
    }
  };

  onSave = (dataToSave) => {
    const { openDialog, previousListOfDetails, offerBillNewData } = this.state;
    const { showLoador } = this.props;
    showLoador({ loador: true });
    apiPost({
      url: endpoint.outletBillHeader + "/apply-offer-batch-wise",
      postBody: dataToSave,
    }).then(({ data, success }) => {
      showLoador({ loador: false });
      if (success) {
        if (
          Object.keys(data.data).length !== 0 &&
          Object.keys(data.data.outletBillsAfterApplyingOffers).length !== 0
        ) {
          if (
            data.data.outletBillsAfterApplyingOffers.outletBillDetails
              .length !== 0
          ) {
            let newObjectToSave = {
              ...data.data.outletBillsAfterApplyingOffers,
              outletBillDetails:
                data.data.outletBillsAfterApplyingOffers.outletBillDetails.map(
                  (orderObject) => {
                    let productWithoutBillObject =
                      data.data.outletBillHeader.outletBillDetails.filter(
                        (row) => row.id == orderObject.id
                      );
                    if (productWithoutBillObject.length !== 0) {
                      orderObject = {
                        ...orderObject,
                        taxableAmtWithoutOffer:
                          productWithoutBillObject[0].taxableAmt,
                        totalAmtWithoutOffer:
                          productWithoutBillObject[0].totalAmt,
                        isChecked: 1,
                      };
                      return orderObject;
                    } else {
                      orderObject = {
                        ...orderObject,
                        taxableAmtWithoutOffer: 0.0,
                        totalAmtWithoutOffer: 0.0,
                        isChecked: 1,
                      };
                      return orderObject;
                    }
                  }
                ),
            };
            let newData = {
              ...data.data,
              outletBillsAfterApplyingOffers: newObjectToSave,
            };
            if (previousListOfDetails.length !== 0) {
              if (
                previousListOfDetails.length >
                newData.outletBillsAfterApplyingOffers.outletBillDetails.length
              ) {
                let filterNewList1 = [];
                previousListOfDetails.map((previousObject) => {
                  if (!previousObject.hasOwnProperty("id")) {
                    filterNewList1.push(previousObject);
                  }
                });
                let filterNewList2 = [];
                newData.outletBillsAfterApplyingOffers.outletBillDetails.map(
                  (previousObject) => {
                    if (!previousObject.hasOwnProperty("id")) {
                      filterNewList2.push(previousObject);
                    }
                  }
                );
                var array3 = filterNewList1.filter(function (obj) {
                  return filterNewList2.indexOf(obj) == -1;
                });
                for (let i = 0; i < newData.productAndStocks.length; i++) {
                  for (let j = 0; j < array3.length; j++) {
                    if (
                      newData.productAndStocks[i].productId ==
                      array3[j].product.id
                    ) {
                      for (
                        let k = 0;
                        k < newData.productAndStocks[i].batchAndStocks.length;
                        k++
                      ) {
                        if (
                          newData.productAndStocks[i].batchAndStocks[k]
                            .batchCode == array3[j].batchCode
                        ) {
                          newData.productAndStocks[i].batchAndStocks[k].stock =
                            newData.productAndStocks[i].batchAndStocks[k]
                              .stock + array3[j].freeQty;
                        }
                      }
                    }
                  }
                }
              }
            }
            let flagApply = 0;
            let newList1 = [];
            if (
              Object.keys(newData).length !== 0 &&
              Object.keys(newData.outletBillsAfterApplyingOffers).length !==
                0 &&
              newData.outletBillsAfterApplyingOffers.appliedOffers.length !== 0
            ) {
              newList1 =
                newData.outletBillsAfterApplyingOffers.appliedOffers.map(
                  (offerObject) => {
                    if (
                      offerObject.levelWiseType == 3 &&
                      offerObject.isApplied == 1
                    ) {
                      if (offerObject.isShow == 0) {
                        flagApply++;
                      }
                      offerObject = {
                        ...offerObject,
                        isShow: offerObject.isShow == 1 ? 0 : 1,
                      };
                      return offerObject;
                    } else if (
                      offerObject.levelWiseType == 5 &&
                      offerObject.isApplied == 1
                    ) {
                      if (offerObject.isShow == 0) {
                        flagApply++;
                      }
                      offerObject = {
                        ...offerObject,
                        isShow: offerObject.isShow == 1 ? 0 : 1,
                      };
                      return offerObject;
                    } else if (
                      offerObject.levelWiseType == 6 &&
                      offerObject.isApplied == 1
                    ) {
                      if (offerObject.isShow == 0) {
                        flagApply++;
                      }
                      offerObject = {
                        ...offerObject,
                        isShow: offerObject.isShow == 1 ? 0 : 1,
                      };
                      return offerObject;
                    } else if (
                      offerObject.levelWiseType == 7 &&
                      offerObject.isApplied == 1
                    ) {
                      if (offerObject.isShow == 0) {
                        flagApply++;
                      }
                      offerObject = {
                        ...offerObject,
                        isShow: offerObject.isShow == 1 ? 0 : 1,
                      };
                      return offerObject;
                    } else {
                      return offerObject;
                    }
                  }
                );
              let newCheckdeList =
                offerBillNewData.outletBillsAfterApplyingOffers.outletBillDetails.filter(
                  (row) => row.isChecked == 1
                );
              let newList2 = {
                ...newData.outletBillsAfterApplyingOffers,
                appliedOffers: newList1,
                outletBillDetails: newCheckdeList,
              };

              let newList3 = {
                ...newData,
                outletBillsAfterApplyingOffers: newList2,
              };

              let offerBillDataToSaveNew = {};
              if (
                offerBillNewData.outletBillsAfterApplyingOffers.appliedOffers
                  .length >
                newList3.outletBillsAfterApplyingOffers.appliedOffers.length
              ) {
                let filterNewList1 = [];
                newList3.outletBillsAfterApplyingOffers.appliedOffers.map(
                  (newObject) => {
                    offerBillNewData.outletBillsAfterApplyingOffers.outletBillDetails.map(
                      (previousObject) => {
                        if (
                          !previousObject.hasOwnProperty("id") &&
                          previousObject.levelWiseType ==
                            newObject.levelWiseType
                        ) {
                          filterNewList1.push(previousObject);
                        }
                      }
                    );
                  }
                );

                let newCheckdeList =
                  offerBillNewData.outletBillsAfterApplyingOffers.outletBillDetails.filter(
                    (row) => row.isChecked == 1
                  );

                let offerBillAfterApplyingOffersData = {
                  ...newList3.outletBillsAfterApplyingOffers,
                  appliedOffers:
                    newList3.outletBillsAfterApplyingOffers.appliedOffers,
                  outletBillDetails: newCheckdeList,
                };
                offerBillDataToSaveNew = {
                  ...offerBillNewData,
                  outletBillsAfterApplyingOffers:
                    offerBillAfterApplyingOffersData,
                };
                this.setState({
                  openBill: false,
                  offerBillNewData: offerBillDataToSaveNew,
                });
              } else {
                let newCheckdeList =
                  offerBillNewData.outletBillsAfterApplyingOffers.outletBillDetails.filter(
                    (row) => row.isChecked == 1
                  );

                let offerBillAfterApplyingOffersData = {
                  ...newList3.outletBillsAfterApplyingOffers,
                  appliedOffers:
                    newList3.outletBillsAfterApplyingOffers.appliedOffers,
                  outletBillDetails: newCheckdeList,
                };
                offerBillDataToSaveNew = {
                  ...offerBillNewData,
                  outletBillsAfterApplyingOffers:
                    offerBillAfterApplyingOffersData,
                };
              }

              if (flagApply > 0) {
                let newCheckdeList =
                  offerBillNewData.outletBillsAfterApplyingOffers.outletBillDetails.filter(
                    (row) => row.isChecked == 1
                  );
                let offerBillAfterApplyingOffersData = {
                  ...newList3.outletBillsAfterApplyingOffers,
                  outletBillDetails: newCheckdeList,
                };
                offerBillDataToSaveNew = {
                  ...newList3,
                  outletBillsAfterApplyingOffers:
                    offerBillAfterApplyingOffersData,
                };

                this.setState({
                  openBill: true,
                  offerBillNewData: offerBillDataToSaveNew,
                });
              } else {
                this.setState({
                  openBill: false,
                  offerBillNewData: offerBillDataToSaveNew,
                });
              }
              this.setState({
                openDialog: !openDialog,
              });
            } else {
              this.setState({
                openBill: false,
                offerBillNewData: newData,
                openDialog: !openDialog,
              });
            }
          }
        }
      }
    });
  };

  onCheckBoxClick = (e, checkedValue, headerId, type, level) => {
    const { checkboxAllData, offerBillNewData } = this.state;
    let checkboxData = [];
    if (offerBillNewData.length !== 0) {
      if (
        Object.keys(offerBillNewData.outletBillsAfterApplyingOffers).length !==
          0 &&
        offerBillNewData.outletBillsAfterApplyingOffers.id === headerId
      ) {
        checkboxAllData.map((allOffer) => {
          if (
            allOffer.level == 0 &&
            allOffer.type == 0 &&
            checkedValue.level == 0 &&
            checkedValue.type == 0
          ) {
            if (allOffer.id == checkedValue.id) {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied == 1 ? 0 : 1,
                isShow: e.target.checked == true ? 1 : 0,
                appliedOfferId:
                  offerBillNewData.outletBillsAfterApplyingOffers.id,
              };
              checkboxData.push(allOfferData);
            } else {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied == 1 ? 0 : 0,
                isShow: allOffer.isApplied == 1 ? 0 : 1,
              };
              checkboxData.push(allOfferData);
            }
          } else if (
            allOffer.level == 0 &&
            allOffer.type == 1 &&
            checkedValue.level == 0 &&
            checkedValue.type == 1
          ) {
            if (allOffer.id == checkedValue.id) {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied == 1 ? 0 : 1,
                isShow: e.target.checked == true ? 1 : 0,
                appliedOfferId:
                  offerBillNewData.outletBillsAfterApplyingOffers.id,
              };
              checkboxData.push(allOfferData);
            } else {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied == 1 ? 0 : 0,
                isShow: allOffer.isApplied == 1 ? 0 : 1,
              };
              checkboxData.push(allOfferData);
            }
          } else if (
            allOffer.level == 1 &&
            allOffer.type == 0 &&
            checkedValue.level == 1 &&
            checkedValue.type == 0
          ) {
            if (allOffer.id == checkedValue.id) {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied == 1 ? 0 : 1,
                isShow: e.target.checked == true ? 1 : 0,
                appliedOfferId:
                  offerBillNewData.outletBillsAfterApplyingOffers.id,
              };
              checkboxData.push(allOfferData);
            } else {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied,
                isShow: allOffer.isApplied == 1 ? 0 : 1,
              };
              checkboxData.push(allOfferData);
            }
          } else if (
            allOffer.level == 1 &&
            allOffer.type == 1 &&
            checkedValue.level == 1 &&
            checkedValue.type == 1
          ) {
            if (allOffer.id == checkedValue.id) {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied == 1 ? 0 : 1,
                isShow: e.target.checked == true ? 1 : 0,
                appliedOfferId:
                  offerBillNewData.outletBillsAfterApplyingOffers.id,
              };
              checkboxData.push(allOfferData);
            } else {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied,
                isShow: allOffer.isApplied == 1 ? 0 : 1,
              };
              checkboxData.push(allOfferData);
            }
          } else {
            checkboxData.push(allOffer);
          }
        });
      }
    }

    let fitlerRow = checkboxData.filter((row) => row.id == checkedValue.id);

    for (
      let i = 0;
      i <
      offerBillNewData.outletBillsAfterApplyingOffers.outletBillDetails.length;
      i++
    ) {
      if (
        offerBillNewData.outletBillsAfterApplyingOffers.outletBillDetails[i]
          .levelWiseType == fitlerRow[0].levelWiseType &&
        offerBillNewData.outletBillsAfterApplyingOffers.outletBillDetails[i]
          .level == level &&
        offerBillNewData.outletBillsAfterApplyingOffers.outletBillDetails[i]
          .type == type
      ) {
        if (e.target.checked == false) {
          offerBillNewData.outletBillsAfterApplyingOffers.outletBillDetails[
            i
          ].isChecked = 0;
        }
      }
    }

    let newCheckedList =
      offerBillNewData.outletBillsAfterApplyingOffers.outletBillDetails.filter(
        (row) => row.isChecked == 1
      );
    let offerBillAfterApplyingOffersData = {
      ...offerBillNewData.outletBillsAfterApplyingOffers,
      outletBillDetails: newCheckedList,
    };

    let offerBillDataToSaveNew = {
      ...offerBillNewData,
      offerHeaders: checkboxData,
      outletBillsAfterApplyingOffers: offerBillAfterApplyingOffersData,
    };

    this.setState({
      checkboxAllData: checkboxData,
      offerBillNewData: offerBillDataToSaveNew,
    });
  };

  savaBillHandler = (rowData) => {
    const { offerBillNewData, checkboxAllData } = this.state;

    if (
      Object.keys(offerBillNewData).length !== 0 &&
      Object.keys(offerBillNewData.outletBillsAfterApplyingOffers).length !==
        0 &&
      offerBillNewData.outletBillsAfterApplyingOffers.outletBillDetails
        .length !== 0
    ) {
      let dataToSet = [
        ...offerBillNewData.outletBillsAfterApplyingOffers.outletBillDetails,
        ...rowData,
      ];
      let offerBillAfterApplyingOffersData = {
        ...offerBillNewData.outletBillsAfterApplyingOffers,
        outletBillDetails: dataToSet,
      };
      let offerBillDataToSaveNew = {
        ...offerBillNewData,
        outletBillsAfterApplyingOffers: offerBillAfterApplyingOffersData,
        offerHeaders: checkboxAllData,
      };

      this.setState({
        offerBillNewData: offerBillDataToSaveNew,
        openBill: false,
        openDialog: false,
      });
    }
  };

  cancelDialogHandler = () => {
    this.setState({
      openBill: false,
      openDialog: true,
    });
  };

  clickHandler = (billId) => {
    const { openDialog, offerBillNewData } = this.state;
    let checkboxData = [];
    this.setState({
      openDialog: !openDialog,
      checkboxAllData: [],
      billOfferId: billId,
    });
    if (offerBillNewData.length !== 0) {
      if (
        Object.keys(offerBillNewData.outletBillsAfterApplyingOffers).length !==
          0 &&
        offerBillNewData.outletBillsAfterApplyingOffers.id == billId &&
        offerBillNewData.offerHeaders.length !== 0
      ) {
        offerBillNewData.offerHeaders.map((allOffer) => {
          let allOfferData = {
            ...allOffer,
            appliedOfferId: offerBillNewData.outletBillsAfterApplyingOffers.id,
          };
          checkboxData.push(allOfferData);
        });
      }
    }
    this.setState({
      checkboxAllData: checkboxData,
    });
  };

  render() {
    const { openDialog, checkboxAllData, offerBillNewData, openBill } =
      this.state;
    const { onCancel } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            screenTitle={"Billing Preview"}
            showTable={false}
            showBackButton={true}
            showAddButton={false}
            onCancel={onCancel}
          />
          <br />
          {Object.keys(offerBillNewData).length !== 0 &&
            Object.keys(offerBillNewData.outletBillsAfterApplyingOffers)
              .length !== 0 && (
              <>
                <DynamicDetails
                  DataToSet={[
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Outlet No.",
                      value: 1,
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Outlet Name",
                      value:
                        offerBillNewData.outletBillsAfterApplyingOffers !==
                          null &&
                        offerBillNewData.outletBillsAfterApplyingOffers
                          .outLet !== null
                          ? offerBillNewData.outletBillsAfterApplyingOffers
                              .outLet.firmName
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Owner Name",
                      value:
                        offerBillNewData.outletBillsAfterApplyingOffers !==
                          null &&
                        offerBillNewData.outletBillsAfterApplyingOffers
                          .outLet !== null
                          ? offerBillNewData.outletBillsAfterApplyingOffers
                              .outLet.ownerName
                          : "",
                    },
                   
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "State Name",
                      value:
                        offerBillNewData.outletBillsAfterApplyingOffers !==
                          null &&
                        offerBillNewData.outletBillsAfterApplyingOffers
                          .outLet !== null &&
                        offerBillNewData.outletBillsAfterApplyingOffers.outLet
                          .state !== null
                          ? offerBillNewData.outletBillsAfterApplyingOffers
                              .outLet.state.name
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Bill No",
                      value:
                        offerBillNewData.outletBillsAfterApplyingOffers !== null
                          ? offerBillNewData.outletBillsAfterApplyingOffers
                              .billNo
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Bill Date",
                      value:
                        offerBillNewData.outletBillsAfterApplyingOffers !== null
                          ? offerBillNewData.outletBillsAfterApplyingOffers
                              .billDate
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Taxable Amount",
                      value:
                        offerBillNewData.outletBillsAfterApplyingOffers !== null
                          ? offerBillNewData.outletBillsAfterApplyingOffers.taxableAmt.toFixed(
                              2
                            )
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Total Amount",
                      value:
                        offerBillNewData.outletBillsAfterApplyingOffers !== null
                          ? offerBillNewData.outletBillsAfterApplyingOffers.totalAmt.toFixed(
                              2
                            )
                          : "",
                    },
                  ]}
                />
              </>
            )}

          {Object.keys(offerBillNewData).length !== 0 &&
            offerBillNewData.offerHeaders.length !== 0 && (
              <>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="flex-end"
                >
                  <ButtonCompo
                    sx={{
                      bgcolor: "dodgerblue",
                      color: "#fbff00",
                      opacity: "0.9",
                      transform: "scale(0.9)",
                      ":hover": {
                        display: "inline-block",
                        bgcolor: "#fbff00",
                        color: "dodgerblue",
                        opacity: "0.9",
                        transform: "scale(1.5)",
                        transitionDuration: "3s",
                        backfaceVisibility: "hidden",
                        transform: "translate(2px, 2px) rotate(0deg) scale(2)",
                        config: {
                          tension: "300",
                          friction: "10",
                        },
                      },
                    }}
                    size="medium"
                    type="button"
                    variant="contained"
                    name={"Offer"}
                    onClick={(e) =>
                      this.clickHandler(
                        offerBillNewData.outletBillsAfterApplyingOffers.id
                      )
                    }
                    fullWidth={false}
                  />
                </Grid>
              </>
            )}

          {Object.keys(offerBillNewData).length !== 0 && (
            <>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" sx={{ width: "5%" }}>
                        Sr. No
                      </TableCell>
                      <TableCell align="center" sx={{ width: "15%" }}>
                        Product Name
                      </TableCell>
                      <TableCell align="center" sx={{ width: "15%" }}>
                        Batch
                      </TableCell>
                      <TableCell align="center" sx={{ width: "10%" }}>
                        Quantity
                      </TableCell>
                      <TableCell align="center" sx={{ width: "10%" }}>
                        Free Quantity
                      </TableCell>
                      <TableCell align="center" sx={{ width: "10%" }}>
                        Taxable Amount
                      </TableCell>
                      <TableCell align="center" sx={{ width: "10%" }}>
                        Total Amount
                      </TableCell>
                      <TableCell align="center" sx={{ width: "10%" }}>
                        Taxable Amount with offer
                      </TableCell>
                      <TableCell align="center" sx={{ width: "10%" }}>
                        Total Amount with offer
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {Object.keys(offerBillNewData.outletBillsAfterApplyingOffers)
                    .length !== 0 &&
                    offerBillNewData.outletBillsAfterApplyingOffers
                      .outletBillDetails.length !== 0 &&
                    offerBillNewData.outletBillsAfterApplyingOffers.outletBillDetails.map(
                      (billDetails, index) => {
                        if (billDetails.isChecked == 1) {
                          return (
                            <>
                              <TableRow>
                                <TableCell align="center" sx={{ width: "5%" }}>
                                  {index + 1}
                                </TableCell>
                                <TableCell align="left" sx={{ width: "15%" }}>
                                  {billDetails !== null &&
                                  billDetails.product !== null
                                    ? billDetails.product.name
                                    : ""}
                                </TableCell>
                                <TableCell align="left" sx={{ width: "15%" }}>
                                  {billDetails !== null
                                    ? billDetails.batchCode
                                    : ""}
                                </TableCell>
                                <TableCell align="center" sx={{ width: "10%" }}>
                                  {billDetails !== null
                                    ? billDetails.qty.toFixed(2)
                                    : ""}
                                </TableCell>
                                <TableCell align="center" sx={{ width: "10%" }}>
                                  {billDetails !== null
                                    ? billDetails.freeQty.toFixed(2)
                                    : ""}
                                </TableCell>
                                <TableCell align="right" sx={{ width: "10%" }}>
                                  {billDetails !== null
                                    ? billDetails.taxableAmtWithoutOffer.toFixed(
                                        2
                                      )
                                    : ""}
                                </TableCell>
                                <TableCell align="right" sx={{ width: "10%" }}>
                                  {billDetails !== null
                                    ? billDetails.totalAmtWithoutOffer.toFixed(
                                        2
                                      )
                                    : ""}
                                </TableCell>
                                <TableCell align="right" sx={{ width: "10%" }}>
                                  {billDetails !== null
                                    ? billDetails.taxableAmt.toFixed(2)
                                    : ""}
                                </TableCell>
                                <TableCell align="right" sx={{ width: "10%" }}>
                                  {billDetails !== null
                                    ? billDetails.totalAmt.toFixed(2)
                                    : ""}
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        }
                      }
                    )}
                </Table>
                {Object.keys(offerBillNewData).length !== 0 &&
                  Object.keys(offerBillNewData.outletBillsAfterApplyingOffers)
                    .length !== 0 &&
                  offerBillNewData.outletBillsAfterApplyingOffers.appliedOffers
                    .length !== 0 && (
                    <>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" sx={{ width: "5.5%" }}>
                              Sr. No
                            </TableCell>
                            <TableCell align="center" sx={{ width: "80%" }}>
                              Offer Name
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {offerBillNewData.outletBillsAfterApplyingOffers
                          .appliedOffers.length !== 0 &&
                          offerBillNewData.outletBillsAfterApplyingOffers.appliedOffers.map(
                            (offerAppliedData, index) => {
                              return (
                                <>
                                  <TableRow>
                                    <TableCell align="center">
                                      {index + 1}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      sx={{ width: "80%" }}
                                    >
                                      {offerAppliedData !== null
                                        ? offerAppliedData.name
                                        : ""}
                                    </TableCell>
                                  </TableRow>
                                </>
                              );
                            }
                          )}
                      </Table>
                    </>
                  )}
              </TableContainer>
            </>
          )}
          <br />
          {openDialog && !openBill && (
            <>
              <BootstrapDialog
                onClose={this.clickHandler}
                aria-labelledby="customized-dialog-title"
                open={true}
                fullWidth
                maxWidth="500px"
              >
                <BootstrapDialogTitle
                  id="customized-dialog-title"
                  onClose={this.clickHandler}
                >
                  Applicable Offers
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="flex-between"
                  >
                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="flex-start"
                    >
                      {checkboxAllData.length !== 0 &&
                        checkboxAllData.map((applicableOfferData) => {
                          if (
                            applicableOfferData.type == 0 &&
                            applicableOfferData.level == 0
                          ) {
                            return (
                              <>
                                <Grid
                                  item
                                  sm={12}
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  container
                                  justifyContent="flex-start"
                                >
                                  <Typography
                                    sx={{ p: 0, marginLeft: 2 }}
                                    variant="h6"
                                    color="textSecondary"
                                  >
                                    <FormControl component="fieldset">
                                      <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                          value="end"
                                          control={
                                            <Checkbox
                                              sx={{
                                                color: checkboxShadowColor,
                                                "& .MuiSvgIcon-root": {
                                                  fontSize: 30,
                                                },
                                                "&.Mui-checked": {
                                                  color: pink[600],
                                                },
                                              }}
                                              color="secondary"
                                            />
                                          }
                                          sx={{
                                            ".css-19g4vwf-MuiTypography-root": {
                                              fontSize: "1.3rem",
                                              color: "#000000",
                                            },
                                          }}
                                          label={applicableOfferData.name}
                                          onChange={(e) => {
                                            this.onCheckBoxClick(
                                              e,
                                              applicableOfferData,
                                              applicableOfferData.appliedOfferId,
                                              applicableOfferData.type,
                                              applicableOfferData.level
                                            );
                                          }}
                                          labelPlacement="end"
                                          checked={
                                            applicableOfferData.isApplied == 1
                                              ? true
                                              : false
                                          }
                                        />
                                      </FormGroup>{" "}
                                      <div
                                        style={{
                                          marginLeft: "40px",
                                          fontSize: "1.1rem",
                                          marginTop: "-15px",
                                        }}
                                      >
                                        {applicableOfferData.discription}
                                      </div>
                                      {applicableOfferData.isApplied == 2 && (
                                        <FormHelperText
                                          style={{
                                            marginLeft: "40px",
                                          }}
                                          error
                                        >
                                          {
                                            "Due to a shortage of stock, the offer is not applicable"
                                          }
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Typography>
                                </Grid>
                              </>
                            );
                          }
                        })}
                    </Grid>

                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="flex-start"
                    >
                      {checkboxAllData.length !== 0 &&
                        checkboxAllData.map((applicableOfferData) => {
                          if (
                            applicableOfferData.type == 1 &&
                            applicableOfferData.level == 0
                          ) {
                            return (
                              <>
                                <Grid
                                  item
                                  sm={12}
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  container
                                  justifyContent="flex-start"
                                >
                                  <Typography
                                    sx={{ p: 0, marginLeft: 2 }}
                                    variant="h6"
                                    color="textSecondary"
                                  >
                                    <FormControl component="fieldset">
                                      <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                          value="end"
                                          control={
                                            <Checkbox
                                              sx={{
                                                color: checkboxShadowColor,
                                                "& .MuiSvgIcon-root": {
                                                  fontSize: 30,
                                                },
                                                "&.Mui-checked": {
                                                  color: pink[600],
                                                },
                                              }}
                                              color="secondary"
                                            />
                                          }
                                          sx={{
                                            ".css-19g4vwf-MuiTypography-root": {
                                              fontSize: "1.3rem",
                                              color: "#000000",
                                            },
                                          }}
                                          label={applicableOfferData.name}
                                          onChange={(e) => {
                                            this.onCheckBoxClick(
                                              e,
                                              applicableOfferData,
                                              applicableOfferData.appliedOfferId,
                                              applicableOfferData.type,
                                              applicableOfferData.level
                                            );
                                          }}
                                          labelPlacement="end"
                                          checked={
                                            applicableOfferData.isApplied == 1
                                              ? true
                                              : false
                                          }
                                        />
                                        <div
                                          style={{
                                            marginLeft: "40px",
                                            fontSize: "1.1rem",
                                            marginTop: "-15px",
                                          }}
                                        >
                                          {applicableOfferData.discription}
                                        </div>
                                        {applicableOfferData.isApplied == 2 && (
                                          <FormHelperText
                                            style={{
                                              marginLeft: "40px",
                                            }}
                                            error
                                          >
                                            {
                                              "Due to a shortage of stock, the offer is not applicable"
                                            }
                                          </FormHelperText>
                                        )}
                                      </FormGroup>
                                    </FormControl>
                                  </Typography>
                                </Grid>
                              </>
                            );
                          }
                        })}
                    </Grid>
                  </Grid>
                  <br />
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="flex-between"
                  >
                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="flex-start"
                    >
                      {checkboxAllData.length !== 0 &&
                        checkboxAllData.map((applicableOfferData) => {
                          if (
                            applicableOfferData.type == 0 &&
                            applicableOfferData.level == 1
                          ) {
                            return (
                              <>
                                <Grid
                                  item
                                  sm={12}
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  container
                                  justifyContent="flex-start"
                                >
                                  <Typography
                                    sx={{ p: 0, marginLeft: 2 }}
                                    variant="h6"
                                    color="textSecondary"
                                  >
                                    <FormControl component="fieldset">
                                      <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                          value="end"
                                          control={
                                            <Checkbox
                                              sx={{
                                                color: checkboxShadowColor,
                                                "& .MuiSvgIcon-root": {
                                                  fontSize: 30,
                                                },
                                                "&.Mui-checked": {
                                                  color: pink[600],
                                                },
                                              }}
                                              color="secondary"
                                            />
                                          }
                                          sx={{
                                            ".css-19g4vwf-MuiTypography-root": {
                                              fontSize: "1.3rem",
                                              color: "#000000",
                                            },
                                          }}
                                          label={applicableOfferData.name}
                                          onChange={(e) => {
                                            this.onCheckBoxClick(
                                              e,
                                              applicableOfferData,
                                              applicableOfferData.appliedOfferId,
                                              applicableOfferData.type,
                                              applicableOfferData.level
                                            );
                                          }}
                                          labelPlacement="end"
                                          checked={
                                            applicableOfferData.isApplied == 1
                                              ? true
                                              : false
                                          }
                                        />
                                      </FormGroup>{" "}
                                      <div
                                        style={{
                                          marginLeft: "40px",
                                          fontSize: "1.1rem",
                                          marginTop: "-15px",
                                        }}
                                      >
                                        {applicableOfferData.discription}
                                      </div>
                                      {applicableOfferData.isApplied == 2 && (
                                        <FormHelperText
                                          style={{
                                            marginLeft: "40px",
                                          }}
                                          error
                                        >
                                          {
                                            "Due to a shortage of stock, the offer is not applicable"
                                          }
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Typography>
                                </Grid>
                              </>
                            );
                          }
                        })}
                    </Grid>

                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="flex-start"
                    >
                      {checkboxAllData.length !== 0 &&
                        checkboxAllData.map((applicableOfferData) => {
                          if (
                            applicableOfferData.type == 1 &&
                            applicableOfferData.level == 1
                          ) {
                            return (
                              <>
                                <Grid
                                  item
                                  sm={12}
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  container
                                  justifyContent="flex-start"
                                >
                                  <Typography
                                    sx={{ p: 0, marginLeft: 2 }}
                                    variant="h6"
                                    color="textSecondary"
                                  >
                                    <FormControl component="fieldset">
                                      <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                          value="end"
                                          control={
                                            <Checkbox
                                              sx={{
                                                color: checkboxShadowColor,
                                                "& .MuiSvgIcon-root": {
                                                  fontSize: 30,
                                                },
                                                "&.Mui-checked": {
                                                  color: pink[600],
                                                },
                                              }}
                                              color="secondary"
                                            />
                                          }
                                          sx={{
                                            ".css-19g4vwf-MuiTypography-root": {
                                              fontSize: "1.5rem",
                                              color: "#000000",
                                            },
                                          }}
                                          label={applicableOfferData.name}
                                          onChange={(e) => {
                                            this.onCheckBoxClick(
                                              e,
                                              applicableOfferData,
                                              applicableOfferData.appliedOfferId,
                                              applicableOfferData.type,
                                              applicableOfferData.level
                                            );
                                          }}
                                          labelPlacement="end"
                                          checked={
                                            applicableOfferData.isApplied == 1
                                              ? true
                                              : false
                                          }
                                        />

                                        <div
                                          style={{
                                            marginLeft: "40px",
                                            fontSize: "1.1rem",
                                            marginTop: "-15px",
                                          }}
                                        >
                                          {applicableOfferData.discription}
                                        </div>
                                        {applicableOfferData.isApplied == 2 && (
                                          <FormHelperText
                                            style={{
                                              marginLeft: "40px",
                                            }}
                                            error
                                          >
                                            {
                                              "Due to a shortage of stock, the offer is not applicable"
                                            }
                                          </FormHelperText>
                                        )}
                                      </FormGroup>
                                    </FormControl>
                                  </Typography>
                                </Grid>
                              </>
                            );
                          }
                        })}
                    </Grid>
                  </Grid>
                  <br />
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="flex-end"
                  >
                    <ButtonCompo
                      sx={{
                        bgcolor: "dodgerblue",
                        color: "#fbff00",
                      }}
                      size="medium"
                      type="button"
                      variant="contained"
                      name={"Submit"}
                      onClick={this.clickSubmitHandler}
                      fullWidth={false}
                    />
                  </Grid>
                </BootstrapDialogTitle>
              </BootstrapDialog>
            </>
          )}
          {openBill && !openDialog && (
            <>
              <BatchwisePopUpForOffer
                offerBillData={offerBillNewData}
                cancelHandler={this.cancelDialogHandler}
                onDataSave={this.savaBillHandler}
              />
            </>
          )}
          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="flex-end"
          >
            <ButtonCompo
              sx={{
                bgcolor: "dodgerblue",
                color: "#fbff00",
              }}
              size="medium"
              type="button"
              variant="contained"
              name={"Save Bill"}
              onClick={this.clickSubmitOfferHandler}
              fullWidth={false}
            />
          </Grid>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showNotification,
  showLoador,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BillingWithOffer);
