import { Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import MaterialUITableWithTextField from "../../components/Comman/RT/MaterialUITableWithTextField";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import {
  serverMsg,
  saveFailedMsg,
  savemsg,
  noInternetMsg,
  saveWarningMsg,
} from "../../config/messageconstant";
import { rateConfigurationJson } from "../../DynamicFormsJson/Transaction/RateConfiguration";
import { getCategory } from "../../Slice/category.slice";
import {
  getProductRateConfigurationByCategoryIdAndDate,
  getProductRateConfigurationByCategoryIdAndDateForEdit,
  setRateConfList,
} from "../../Slice/productRateConfiguration.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { withRouter } from "../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import { apiPost, apiGet } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
import swal from "sweetalert";
class RateConfigurationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldData: {},
      dynamicMasterData: {
        stockStakeHolderTypeDetail:
          this.props.stockStakeHolderType?.stockStakeHolderType,
        categoryDetail: this.props.categoryList?.category,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.stockStakeHolderType !== nextProps.stockStakeHolderType) {
      if (
        this.props.stockStakeHolderType?.stockStakeHolderType !==
        nextProps.stockStakeHolderType?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stockStakeHolderTypeDetail:
              nextProps.stockStakeHolderType?.stockStakeHolderType,
          },
        });
      }
    }
    if (this.props.categoryList !== nextProps.categoryList) {
      if (
        this.props.categoryList?.category !== nextProps.categoryList?.category
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            categoryDetail: nextProps.categoryList?.category,
          },
        });
      }
    }
  }

  async componentDidMount() {
    const {
      getStockStakeHolderType,
      getCategory,
      getProductRateConfigurationByCategoryIdAndDateForEdit,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getStockStakeHolderType({ userTypeIds: 2 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getCategory().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      if (this.props.params.id) {
        showLoador({ loador: true });
        await getProductRateConfigurationByCategoryIdAndDateForEdit({
          productRateConfigurationHeaderId: this.props.params.id,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  onSubmit = async () => {
    const { setRateConfList, productRateConfiguration } = this.props;
    const { fieldData } = this.state;
    if (fieldData["name"] && fieldData["name"] != "") {
      if (navigator.onLine) {
        let productList = [];
        let error = false;

        const productRateConfigurationDetailsOutlets =
          productRateConfiguration.product.map((productListObj) => {
            let productObj = {
              product: {
                id: productListObj.productId,
              },
              marginPercentage: productListObj.marginPercentage,
            };
            if (
              productListObj.marginPercentage == 0 ||
              productListObj.marginPercentage == ""
            ) {
              error = true;
              productListObj = {
                ...productListObj,
                marginPercentageErr:
                  productListObj.marginPercentage == "" ? 1 : 2,
              };
            } else {
              productListObj = {
                ...productListObj,
                marginPercentageErr: 0,
              };
            }
            productList.push(productListObj);

            return productObj;
          });
        setRateConfList({ row: productList });
        let dataToSave = {
          stockStakeHolderType: fieldData.stockStakeHolderType,
          category: fieldData.category,
          name: fieldData.name,
          dateForMrpRate: fieldData.dateForMrpRate,
          rateType: fieldData.rateType,
          productRateConfigurationDetails:
            productRateConfigurationDetailsOutlets,
        };

        if (!error) {
          swal({
            title: "Are you sure?",
            text: saveWarningMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              this.onSave(dataToSave);
            }
          });
        }
      } else {
        this.props.showNotification({
          msg: noInternetMsg,
          severity: "error",
        });
      }
    } else {
      this.setState({
        nameErr: "*Name required",
      });
    }
  };

  onSave = async (dataToSave) => {
    const { showLoador, showNotification } = this.props;

    if (navigator.onLine) {
      showLoador({ loador: true });
      await apiPost({
        url: endpoint.productRateConfiguration,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg });
          this.props.navigate(-1);
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      this.props.showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };
  onSearch = async (data) => {
    const {
      getProductRateConfigurationByCategoryIdAndDate,
      showLoador,
      showNotification,
    } = this.props;
    this.setState({
      fieldData: data,
    });
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getProductRateConfigurationByCategoryIdAndDate({
        categoryId: data.category.id,
        selectedDate: data.dateForMrpRate,
        marginPercentage: data.marginPercentage,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  getDataById = (id) => {
    const { showLoador } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      const response = apiGet({
        url: rateConfigurationJson.apiBaseURL + "/" + id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          if (!data.error) {
            let rowData = data.data;
            rowData = {
              ...rowData,
              category: rowData.category,
            };
            this.setState({
              fieldData: rowData,
            });
            return { data: rowData, success };
          }
          return { data: {}, success: false };
        }

        return { data: {}, success: false };
      });
      return response;
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const { DataToSet, fieldData } = this.state;
    const { dynamicMasterData } = this.state;
    const { setRateConfList, productRateConfiguration } = this.props;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            DataToSet={DataToSet}
            dynamicMasterData={dynamicMasterData}
            getByIdApi={true}
            callApi={true}
            getDataById={this.getDataById}
            showTitle={rateConfigurationJson.showTitle}
            screenTitle={rateConfigurationJson.screenTitle}
            fieldMeta={rateConfigurationJson.fieldMeta}
            showCancel={rateConfigurationJson.showCancel}
            apiBaseURL={rateConfigurationJson.apiBaseURL}
            showSaveNextBtn={rateConfigurationJson.showSaveNextBtn}
            showSaveBtnMain={false}
            showSaveBtn={
              Object.keys(fieldData).length != 0 && fieldData["id"]
                ? false
                : true
            }
            saveBtnText={"Search"}
            onSave={this.onSearch}
          />

          {productRateConfiguration.product.length != 0 && (
            <>
              <MaterialUITableWithTextField
                tableHead={[
                  {
                    title: "Sr. No.",
                    name: "index",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Product Name",
                    name: "name",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                  {
                    title: "MRP",
                    name: "mrp",

                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                    placeHolder: "Enter Remark",
                  },
                  {
                    title: "Percentage",
                    name: "marginPercentage",
                    placeHolder: "Enter Percentage",
                    textFieldError: "marginPercentageErr",
                    error: "Percentage",
                    textField: true,
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                  },
                  {
                    title: "Projected Rate",
                    name: "rate",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                  },
                ]}
                data={productRateConfiguration.product}
                setList={setRateConfList}
                isActionColActive={false}
              />
              {productRateConfiguration.product.length != 0 && (
                <>
                  <br />
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={2}
                    columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                    container
                    justifyContent="right"
                  >
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Submit"
                      onClick={this.onSubmit}
                    />
                  </Grid>
                </>
              )}
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stockStakeHolderType: state.stockStakeHolderType,
  categoryList: state.category,
  productRateConfiguration: state.productRateConfiguration,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getStockStakeHolderType,
  getCategory,
  getProductRateConfigurationByCategoryIdAndDate,
  setRateConfList,
  getProductRateConfigurationByCategoryIdAndDateForEdit,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RateConfigurationForm);