import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";

import swal from "sweetalert";
import {
  checkedListEmptyMsg,
  deleteConfirmMsg,
  deleteFailedMsg,
  deletemsg,
  updateData,
} from "../../config/messageconstant";
import { showNotification, showLoador } from "../Landing/Landing.slice";
import { apiDelete, apiPost } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
import { cyanBlue } from "../../config/ColorObj";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const StakeholderPopUp = ({
  handleCloses,
  open,
  rows,
  DataToSet,
  stakeholderHandler,
}) => {
  const [rowData, setRowData] = React.useState([]);

  React.useEffect(() => {
    setRowData(rows);
  }, []);

  const handleClose = () => {
    handleCloses(1);
  };

  const onDateChange = (row, name) => (date) => { };

  const handleClick = (e, id) => { };

  const onUpdateData = () => { };

  const onDeleteIds = () => { };

  const onClickDelete = (e, row) => { };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Stake Holder List
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <DynamicDetails DataToSet={DataToSet} />
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Sr. No.</TableCell>
                  <TableCell align="center">Stock Holder</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.map((row, index) => (
                < TableRow >
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="left">
                    <div
                      onClick={(e) => stakeholderHandler(e, row)}
                      style={{
                        color: cyanBlue,
                        cursor: "pointer",
                      }}
                    >
                      {row.name}
                    </div>
                  </TableCell>
                </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </BootstrapDialog >
    </>
  );
};
const mapStateToProps = (state) => ({
  publishList: state.stockholder,
});

const mapDispatchToProps = {
  showNotification,
  showLoador,
};

export default connect(mapStateToProps, mapDispatchToProps)(StakeholderPopUp);
