import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { imageUrl } from "../../config/Url";
import endpoint from "../../config/endpoints";
import { langugae } from "../../config/languageJSON";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { apiGet, apiPost } from "../../utils/api_service";
import "../Billing History/billPDFCss.css";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import logo from "../../Images/gokulImg.png";
import ofc from "../../Images/OFC_logo.png";
const CrediteNotePDF = ({ showLoador, showNotification }) => {
  const params = useParams();
  const [billList, setBill] = useState([]);
  let lang = localStorage.getItem("lang");
  let creditNoteID = localStorage.getItem("creditNoteID");

  useEffect(() => {
    // window.print();
    if (navigator.onLine) {
      console.log("params");
      console.log(params);
      lang = localStorage.getItem("lang");
      creditNoteID = localStorage.getItem("creditNoteID");

      let creditNoteData = {
        creditNoteHeaderIds: creditNoteID.split(","),
        languageId: lang,
      };
      // showLoador({ loador: true });
      // apiPost({

      //   url: endpoint.creditNote + "/report-by-ids",
      //   postBody: creditNoteData,
      // }).then(({ data, success }) => {
      //   showLoador({ loador: false });
      //   if (!success) {
      //     showNotification({
      //       msg: serverMsg,
      //       severity: "error",
      //     });
      //   } else {
      //     console.log(data.data);

      //     setBill(data.data);
      //     window.print();
      //   }
      // });

      showLoador({ loador: true });
      apiPost({
        url: endpoint.creditNote + "/get-report-by-ids",
        postBody: creditNoteData,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({
            msg: serverMsg,
            severity: "error",
          });
        } else {
          console.log(data.data);

          setBill(data.data);
          window.print();
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }, []);

  return (
    <>
      {/* <footer className='header'>
                <p>Tax Invoice</p>

            </footer>

            <header className='header2'>
                <p>Author: Hege Refsnes</p>
            </header> */}
      {billList.map((dataToSet, index) => {
        // let toBill = dataToSet.outLet ? dataToSet.outLet : {};
        // let taluka =
        //   toBill.beat != null &&
        //   toBill.beat.area != null &&
        //   toBill.beat.area.taluka != null
        //     ? toBill.beat.area.taluka
        //     : "";
        let talukaName =
          dataToSet.talukaName != null ? dataToSet.talukaName : "";
        let districtName =
          dataToSet.districtName != null ? dataToSet.districtName : "";
        let addr = dataToSet.address
          ? dataToSet.address + " - " + talukaName + " - " + districtName
          : "";
        let address = langugae[2].address ? langugae[2].address + " : " : "";
        var DAddrText = address + addr;
        let villageCity = langugae[2].village
          ? langugae[2].village + " : "
          : "";
        let villageData = dataToSet.villageCity ? dataToSet.villageCity : "";
        let village = villageCity + villageData;
        let insertDateTime = dataToSet.insertDateTime
          ? dataToSet.insertDateTime.split(" ")
          : [];
        let insertDateTimeData =
          insertDateTime.length == 2 ? insertDateTime[1] : "";
        let pdfLogo =
          localStorage.getItem("compId") ==
          "5c140f80-3929-46dc-83b1-592b7bc30d1f"
            ? logo
            : ofc;
        // let toBillState = toBill.state != null ? toBill.state : {};
        let indexInfo = 0;
        let totalAmt = 0;
        let qtyTot = 0;
        let cgstTot = 0;
        let igstTot = 0;
        let sgstTot = 0;
        // let dataToSetState = dataToSet.state != null ? dataToSet.state : {};
        let stockStakeHolderBillDetails =
          dataToSet.dtoCreditNoteDetailAndHeaders != null
            ? dataToSet.dtoCreditNoteDetailAndHeaders
            : [];
        return (
          <>
            <div style={{ marginRight: "20px", marginLeft: "20px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: "20px",
                    width: "55%",
                    textAlign: "end",
                  }}
                >
                  {dataToSet.stockStakeHolderGstNo === dataToSet.gstNo
                    ? "Stock Transfer"
                    : langugae[2].purchaseReturn
                    ? langugae[2].purchaseReturn
                    : ""}
                </div>
                <div
                  style={{
                    // position: "absolute",//
                    marginBottom: "0",
                    backgroundColor: "fff",
                    textAlign: "right",
                  }}
                >
                  {index + 1} of {billList.length}
                </div>
              </div>

              <table style={{ width: "100%" }}>
                <tr>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td
                        style={{
                          textAlign: "left",
                          width: "50%",
                          borderLeft: "1px solid black",
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                          borderRight: "1px solid black",
                          borderCollapse: "collapse",
                          padding: "8px",
                          verticalAlign: "top",
                        }}
                      >
                        <div style={{ fontWeight: 600 }}>
                          {dataToSet.stockStakeHolderFirmName
                            ? dataToSet.stockStakeHolderFirmName
                            : "-"}
                        </div>
                        {langugae[2].ownerName
                          ? langugae[2].ownerName + " : "
                          : ""}{" "}
                        {dataToSet.stockStakeHolderOwnerName
                          ? dataToSet.stockStakeHolderOwnerName
                          : "-"}{" "}
                        <br />
                        {langugae[2].address
                          ? langugae[2].address + " : "
                          : ""}{" "}
                        {dataToSet.stockStakeHolderAddress
                          ? dataToSet.stockStakeHolderAddress
                          : "-"}
                        <br />
                        {langugae[2].mobileNo
                          ? langugae[2].mobileNo + " : "
                          : ""}{" "}
                        {dataToSet.stockStakeHolderContactPersonNo
                          ? dataToSet.stockStakeHolderContactPersonNo
                          : "-"}{" "}
                        , State :{" "}
                        {dataToSet.stockStakeHolderStateName
                          ? " " + dataToSet.stockStakeHolderStateName
                          : "-"}
                        <br />
                        {langugae[2].gSTNo
                          ? langugae[2].gSTNo + " : "
                          : ""}{" "}
                        {dataToSet.stockStakeHolderGstNo
                          ? dataToSet.stockStakeHolderGstNo
                          : "-"}{" "}
                        ,{" "}
                        {langugae[2].FSSAINo ? langugae[2].FSSAINo + " : " : ""}{" "}
                        {dataToSet.stockStakeHolderFssiFdiNo
                          ? dataToSet.stockStakeHolderFssiFdiNo
                          : "-"}
                        <br />
                      </td>

                      <td
                        style={{
                          textAlign: "left",
                          width: "50%",
                          borderLeft: "1px solid black",
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                          borderRight: "1px solid black",
                          borderCollapse: "collapse",
                          padding: "8px",
                          verticalAlign: "top",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            margin: "0px 0px 25px",
                          }}
                        >
                          <div style={{ fontWeight: 600 }}>
                            {langugae[2].billTo
                              ? langugae[2].billTo + " : "
                              : ""}{" "}
                            {dataToSet.firmName ? dataToSet.firmName : ""}
                          </div>
                          {langugae[2].ownerName
                            ? langugae[2].ownerName + " : "
                            : ""}{" "}
                          {dataToSet.ownerName ? dataToSet.ownerName : "-"}{" "}
                          <br />
                          {langugae[2].beatName
                            ? langugae[2].beatName + " : "
                            : ""}
                          {dataToSet.beatName ? dataToSet.beatName : "-"} <br />
                          {langugae[2].address
                            ? langugae[2].address + " : "
                            : ""}{" "}
                          {addr ? addr : "-"}
                          <br />
                          {village ? village : "-"}
                          <br />
                          {langugae[2].phoneNo
                            ? langugae[2].phoneNo + " : "
                            : ""}{" "}
                          {dataToSet.outletMobileNo
                            ? dataToSet.outletMobileNo
                            : ""}{" "}
                          , {langugae[2].gSTNo ? langugae[2].gSTNo + " : " : ""}{" "}
                          {dataToSet.gstNo ? dataToSet.gstNo : "-"} , <br />
                          Place Of Supply:{" "}
                          {dataToSet.outletStateName
                            ? " " + dataToSet.outletStateName
                            : ""}{" "}
                          ,{" "}
                          {langugae[2].FSSAINo
                            ? langugae[2].FSSAINo + " : "
                            : ""}{" "}
                          {dataToSet.outLet && dataToSet.outLet.fssiFdiNo != ""
                            ? dataToSet.outLet.fssiFdiNo
                            : "-"}
                        </div>
                      </td>
                    </tr>
                  </table>
                </tr>
              </table>

              <div style={{ borderCollapse: "collapse", marginTop: "8px" }}>
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <thead>
                    <tr>
                      <td colSpan={11}>
                        <table
                          style={{ borderCollapse: "collapse", width: "100%" }}
                        >
                          <tr>
                            <td>
                              <div style={{ fontSize: "18px" }}>
                                <div
                                  style={{ display: "inline", fontWeight: 600 }}
                                >
                                  Transaction No. :
                                </div>{" "}
                                {dataToSet.transactionNo
                                  ? dataToSet.transactionNo
                                  : "-"}{" "}
                              </div>
                            </td>

                            <td align={"right"}>
                              <div style={{ fontSize: "18px" }}>
                                <div
                                  style={{ display: "inline", fontWeight: 600 }}
                                >
                                  {langugae[2].date
                                    ? langugae[2].date + " : "
                                    : ""}
                                </div>
                                {dataToSet.date ? dataToSet.date : "-"}
                                {", "}
                                {langugae[2].time
                                  ? langugae[2].time + " : "
                                  : ""}
                                {insertDateTimeData}
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>

                    <tr>
                      <th className="thsmallcell">#</th>
                      <th className="thmedcell">
                        {langugae[2].HSN ? langugae[2].HSN : ""}
                      </th>
                      <th className="thbigcell">
                        {langugae[2].itemName ? langugae[2].itemName : ""}
                      </th>
                      <th className="thbigcell">
                        {langugae[2].uom ? langugae[2].uom : ""}
                      </th>
                      <th className="thbigcell">
                        {langugae[2].qty ? langugae[2].qty : ""}
                      </th>
                      <th className="thmedcell">
                        {langugae[2].rate ? langugae[2].rate : ""}
                      </th>
                      {dataToSet.stockStakeHolderStateId ==
                      dataToSet.outletStateId ? (
                        <>
                          <th className="thmedcell">
                            {" "}
                            {langugae[2].CGST ? langugae[2].CGST : ""} {"%"}
                          </th>
                          <th className="thmedcell">
                            {langugae[2].CGST ? langugae[2].CGST : ""} {"A"}
                          </th>

                          <th className="thmedcell">
                            {langugae[2].SGST ? langugae[2].SGST : ""} {"%"}
                          </th>
                          <th className="thmedcell">
                            {langugae[2].SGST ? langugae[2].SGST : ""} {"A"}
                          </th>
                        </>
                      ) : (
                        <>
                          <th className="thmedcell">
                            {langugae[2].IGST ? langugae[2].IGST : ""} {"%"}
                          </th>
                          <th className="thmedcell">
                            {langugae[2].IGST ? langugae[2].IGST : ""} {"A"}
                          </th>
                        </>
                      )}

                      <th className="thmedcell">
                        {langugae[2].amount ? langugae[2].amount : ""}
                      </th>
                    </tr>
                  </thead>

                  <tbody style={{ width: "100%" }}>
                    {dataToSet.packagingTypes.map((packagingType) => {
                      return (
                        <>
                          <tr>
                            <td
                              align={"left"}
                              colSpan={11}
                              style={{ fontWeight: 600 }}
                              className="tdsmallcell"
                            >
                              {packagingType.name}
                            </td>
                          </tr>

                          {stockStakeHolderBillDetails.map(
                            (stockDetails, indexData) => {
                              if (
                                packagingType.id == stockDetails.packagingTypeId
                              ) {
                                let productData = [];

                                qtyTot = qtyTot + stockDetails.qty;
                                cgstTot = cgstTot + stockDetails.cgstAmt;
                                sgstTot = sgstTot + stockDetails.sgstAmt;
                                igstTot = igstTot + stockDetails.igstAmt;
                                indexInfo = indexInfo + 1;
                                totalAmt = totalAmt + stockDetails.totalAmt;
                                return (
                                  <>
                                    <tr>
                                      <td className="tdsmallcell">
                                        {indexInfo}
                                      </td>
                                      <td className="tdsmallcell">
                                        {stockDetails.hsnCode}
                                      </td>
                                      <td
                                        align={"left"}
                                        className="tdsmallcell"
                                      >
                                        {stockDetails.productName != null
                                          ? stockDetails.productName
                                          : ""}
                                      </td>
                                      <td
                                        align={"left"}
                                        className="tdsmallcell"
                                      >
                                        {stockDetails.uomName != null
                                          ? stockDetails.uomName
                                          : "-"}
                                      </td>
                                      <td
                                        align={"right"}
                                        className="tdsmallcell"
                                      >
                                        {stockDetails.qty}
                                      </td>
                                      <td
                                        align={"right"}
                                        className="tdsmallcell"
                                      >
                                        {(
                                          (stockDetails.rate * 100) /
                                          (100 +
                                            stockDetails.cgstPer +
                                            stockDetails.sgstPer +
                                            stockDetails.igstPer)
                                        ).toFixed(2)}
                                      </td>
                                      {dataToSet.stockStakeHolderStateId ==
                                      dataToSet.outletStateId ? (
                                        <>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                          >
                                            {stockDetails.cgstPer.toFixed(2)}
                                          </td>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                          >
                                            {stockDetails.cgstAmt.toFixed(2)}
                                          </td>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                          >
                                            {stockDetails.sgstPer.toFixed(2)}
                                          </td>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                          >
                                            {stockDetails.sgstAmt.toFixed(2)}
                                          </td>
                                        </>
                                      ) : (
                                        <>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                          >
                                            {stockDetails.igstPer.toFixed(2)}
                                          </td>
                                          <td
                                            align={"right"}
                                            className="tdsmallcell"
                                          >
                                            {stockDetails.igstAmt.toFixed(2)}
                                          </td>
                                        </>
                                      )}
                                      <td
                                        align={"right"}
                                        className="tdsmallcell"
                                      >
                                        {stockDetails.totalAmt.toFixed(2)}
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                            }
                          )}
                        </>
                      );
                    })}

                    <tr>
                      <td className="tdsmallcell"></td>
                      <td
                        align={"left"}
                        className="tdsmallcell"
                        style={{ fontWeight: 600 }}
                      >
                        {" "}
                        {langugae[2].total ? langugae[2].total : ""}
                      </td>
                      <td className="tdsmallcell"></td>
                      <td className="tdsmallcell"></td>

                      <td
                        align={"right"}
                        style={{ fontWeight: 600 }}
                        className="tdsmallcell"
                      >
                        {qtyTot}
                      </td>
                      <td className="tdsmallcell"></td>
                      {dataToSet.stockStakeHolderStateId ==
                      dataToSet.outletStateId ? (
                        <>
                          <td className="tdsmallcell"></td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 600 }}
                            className="tdsmallcell"
                          >
                            {cgstTot.toFixed(2)}
                          </td>
                          <td className="tdsmallcell"></td>
                          <td
                            align={"right"}
                            style={{ fontWeight: 600 }}
                            className="tdsmallcell"
                          >
                            {sgstTot.toFixed(2)}
                          </td>
                        </>
                      ) : (
                        <>
                          <td className="tdsmallcell"></td>

                          <td
                            align={"right"}
                            style={{ fontWeight: 600 }}
                            className="tdsmallcell"
                          >
                            {igstTot.toFixed(2)}
                          </td>
                        </>
                      )}
                      <td
                        align={"right"}
                        style={{ fontWeight: 600 }}
                        className="tdsmallcell"
                      >
                        {totalAmt.toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <tr>
                    <th style={{ fontSize: "14px" }} className="thsmallcell">
                      {langugae[2].fillingType ? langugae[2].fillingType : ""}
                    </th>
                    {dataToSet.categories.map((categoryData, index) => {
                      return (
                        <th
                          style={{ fontSize: "14px" }}
                          className="thsmallcell"
                        >
                          {categoryData.name}
                        </th>
                      );
                    })}
                  </tr>
                  {dataToSet.packagingTypes.map((packagingTypeData) => {
                    return (
                      <tr>
                        <td
                          style={{ fontSize: "14px" }}
                          align={"left"}
                          className="tdsmallcell"
                        >
                          {packagingTypeData.name}
                        </td>

                        {dataToSet.categories.map((categoryData) => {
                          let flag = 0;
                          return (
                            <>
                              {dataToSet.dtoCategoryWithPackagingTypes.map(
                                (rowData) => {
                                  if (
                                    categoryData.id == rowData.categoryId &&
                                    rowData.packagingTypeId ==
                                      packagingTypeData.id
                                  ) {
                                    flag = 1;
                                    return (
                                      <td
                                        align={"right"}
                                        className="tdsmallcell"
                                      >
                                        {rowData.totalQty}
                                      </td>
                                    );
                                  }
                                }
                              )}
                              {flag == 0 && (
                                <td align={"left"} className="tdsmallcell"></td>
                              )}
                            </>
                          );
                        })}
                      </tr>
                    );
                  })}
                </table>

                <br />
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <tr>
                    <td align={"left"} colSpan={3} className="tdsmallcell">
                      {langugae[2].note ? langugae[2].note + " : " : ""}
                      {dataToSet.remark != null ? dataToSet.remark : ""}
                    </td>
                  </tr>
                  <tr>
                    <td align={"left"} className="tdsmallcell">
                      1.{" "}
                      {langugae[2].orderBy ? langugae[2].orderBy + " : " : ""}
                      {dataToSet.salesTeamNames ? dataToSet.salesTeamNames : ""}
                    </td>

                    <td
                      align={"left"}
                      width={"25%"}
                      rowSpan={3}
                      className="tdsmallcell"
                    ></td>
                    <td
                      align={"left"}
                      className="tdsmallcell"
                      style={{ borderBottom: "none" }}
                    >
                      {dataToSet.stockStakeHolderFirmName
                        ? dataToSet.stockStakeHolderFirmName
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td align={"left"} className="tdsmallcell">
                      2.{" "}
                      {langugae[2].goodsCheckBeforeReceived
                        ? langugae[2].goodsCheckBeforeReceived + " : "
                        : ""}
                    </td>
                    <td
                      align={"left"}
                      style={{ borderRight: "1px solid rgb(172, 172, 172)" }}
                    ></td>
                  </tr>
                  <tr>
                    <td align={"left"} className="tdsmallcell">
                      3.{" "}
                      {langugae[2].subjectToJurisdiction
                        ? langugae[2].subjectToJurisdiction + " : "
                        : ""}
                      {localStorage.getItem("districtName")}
                    </td>
                    <td
                      align={"left"}
                      className="tdsmallcell"
                      style={{ borderTop: "none" }}
                    >
                      {langugae[2].aurthorisedSign
                        ? langugae[2].aurthorisedSign
                        : ""}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            {index + 1 != billList.length && <div className="pagebreak"> </div>}
          </>
        );
      })}
    </>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showNotification,
  showLoador,
};
export default connect(mapStateToProps, mapDispatchToProps)(CrediteNotePDF);
