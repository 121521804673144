import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg } from "../../config/messageconstant";
import { stockHolderTargetJson } from "../../DynamicFormsJson/Transaction/stockholderTarget";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";

class StockHolderTargetView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      DataToSet: [],
      totalOfTargets: 0,
      beatTargetList: [],
    };
    this.columns = [
      {
        title: "Sr.No.",
        name: "index",
        align: "center",
      },
      {
        title: "Region Name",
        name: "regionName",
        align: "left",
      },
      {
        title: "State Name",
        name: "stateName",
        align: "left",
      },
      {
        title: "Zone Name",
        name: "zoneName",
        align: "left",
      },
      {
        title: "Area Name",
        name: "areaName",
        align: "left",
      },
      {
        title: "Beat Name",
        name: "beatName",
        align: "left",
      },
      {
        title: "Jan",
        name: "jan",
        align: "right",
      },
      {
        title: "Feb",
        name: "feb",
        align: "right",
      },
      {
        title: "March",
        name: "march",
        align: "right",
      },
      {
        title: "April",
        name: "april",
        align: "right",
      },
      {
        title: "May",
        name: "may",
        align: "right",
      },
      {
        title: "June",
        name: "jun",
        align: "right",
      },
      {
        title: "July",
        name: "july",
        align: "right",
      },
      {
        title: "Aug",
        name: "aug",
        align: "right",
      },
      {
        title: "Sep",
        name: "sep",
        align: "right",
      },
      {
        title: "Oct",
        name: "oct",
        align: "right",
      },
      {
        title: "Nov",
        name: "nov",
        align: "right",
      },
      {
        title: "Dec",
        name: "dec",
        align: "right",
      },
      {
        title: "Total Target",
        name: "totalTarget",
        align: "right",
      },
    ];
  }
  async componentDidMount() {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      if (this.props.params.id) {
        showLoador({ loador: true });
        try {
          const response = await apiGet({
            url:
              stockHolderTargetJson.apiBaseURL +
              "/list-by-year?yearId=" +
              this.props.params.id,
          });
          showLoador({ loador: false });
          const { data, success } = response;
          if (success) {
            const stockHolderTargetData = data.data[0];
            const yearMaster = stockHolderTargetData.yearMaster;
            const targetData = {
              yearName: yearMaster ? yearMaster.yearName : "-",
              fromDate: yearMaster ? yearMaster.fromDate : "-",
              toDate: yearMaster ? yearMaster.toDate : "-",
            };


            let totalOfTarget = 0;

            const beatTargetList = data.data.map((item, index) => {
              totalOfTarget = totalOfTarget + +item.totalTarget;
              return {
                index: index + 1,
                id: item.id,
                regionName: item.regionName,
                stateName: item.stateName,
                zoneName: item.zoneName,
                areaName: item.areaName,
                beatName: item.beat.beatName,
                jan: item.jan,
                feb: item.feb,
                march: item.march,
                april: item.april,
                may: item.may,
                jun: item.jun,
                july: item.july,
                aug: item.aug,
                sep: item.sep,
                oct: item.oct,
                nov: item.nov,
                dec: item.dec,
                totalTarget: item.totalTarget,
              }
            });
            const DataToSet = [
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Year Name",
                value: targetData.yearName,
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "From Date",
                value: targetData.fromDate,
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "To Date",
                value: targetData.toDate,
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Total Target",
                value: totalOfTarget,
              },
            ];
            this.setState({ DataToSet, beatTargetList });
          } else {
          }
        } catch (error) {
          showLoador({ loador: false });
        }
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };
  getFilteredTableData = (beatTargetList) => {
    const { searchValue } = this.state;
    return beatTargetList.filter((currentRow) => {
      let isValid = false;
      let beatName = currentRow.beat != null ? currentRow.beat.beatName : "-";
      if (
        beatName &&
        beatName.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  render() {
    const { DataToSet, beatTargetList } = this.state;

    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            DataToSet={DataToSet}
            showSaveBtn={stockHolderTargetJson.showSaveBtn}
            showTitle={true}
            screenTitle={"Stock Holder Target Assignation View"}
            fieldMeta={[]}
            showCancel={stockHolderTargetJson.showCancel}
            apiBaseURL={stockHolderTargetJson.apiBaseURL}
            showSaveNextBtn={stockHolderTargetJson.showSaveNextBtn}
            showSaveBtnMain={false}
            padding={true}
            paddingTop={true}
          />
          <LandingScreenHeader
            screenTitle={""}
            showSearchBox={true}
            showTable={true}
            showFilter={false}
            showPdfDownload={false}
            showExcelDownload={false}
            onSearch={this.onSearch}
          />
          <RTTable
            columns={this.columns}
            tableData={this.getFilteredTableData(beatTargetList)}
            isActionColActive={false}
            showPagination={false}
            showSearchBox={true}
          />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  beatTargetList: state.beatTarget,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(StockHolderTargetView);
