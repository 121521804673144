import ReportIcon from '@mui/icons-material/Report';
import { Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import * as React from "react";
import { MyComponentWithIconProps } from "../IconButton";
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { order, tableHead, hiddenColumnNames, orderBy } = props;

    return (
        <TableHead>
            <TableRow>
            <TableCell align ="center" width="10%">Sr. No.</TableCell>
                {tableHead.map((headCell, index) => {
                    if (headCell.isList != 1) {

                        const hiddenColumn = hiddenColumnNames.filter(
                            (hiddenCol) => hiddenCol === headCell.name
                        );

                        return (
                            <TableCell
                                key={headCell.name}
                                align={"center"}
                                sx={{ display: hiddenColumn.length !== 0 && "none" }}
                                style={{
                                    minWidth:
                                        headCell.name === "index"
                                            ? 10
                                            : headCell.width
                                                ? headCell.width
                                                : 100,
                                }}
                            // width={headCell.name === "index" ? "10%" : headCell.width?headCell.width:''}
                            // padding={headCell.disablePadding ? 'none' : 'normal'}
                            // sortDirection={orderBy === headCell.name ? order : false}
                            >
                                {headCell.title}
                                {orderBy === headCell.name ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableCell>
                        );
                    }
                })}
            </TableRow>
        </TableHead>
    );
}
function Row({
    row,
    tableHead,
    isActionColActive,
    hiddenColumnNames,
    showReportIcon,
    rowReport,
    index
}) {

    const onClickReport = (e, row) => {
        e.stopPropagation();
        rowReport(row);
    };

    let subReport = tableHead.filter((subReportData) => subReportData.isList == 1)
    return (

        <>
            <TableRow key={row.fieldName}>
            <TableCell
                                    width={"10%"}
                                    align={'center'}
                                >
                                    {index + 1}
                                </TableCell>
                {
                    tableHead.map((tableInfo) => {
                        if (tableInfo.isList != 1) {
                            const hiddenColumn = hiddenColumnNames.filter(
                                (hiddenCol) => hiddenCol === tableInfo.fieldName
                            );
                          
                            if (row[tableInfo.fieldName] != undefined) {
                                return <TableCell
                                    width={tableInfo.fieldName === "index" ? "10%" : ""}
                                    align={tableInfo.align}
                                    sx={{ display: hiddenColumn.length !== 0 && "none" }}
                                >
                                    {row[tableInfo.fieldName]}
                                </TableCell>
                            }

                        }


                    })
                }
                {isActionColActive && (
                    <>
                        <TableCell align={"center"} style={{ width: "13%" }}>

                            {showReportIcon && (
                                <>
                                    &nbsp;&nbsp;
                                    <MyComponentWithIconProps
                                        statusImage={ReportIcon}
                                        color="error"
                                        fontSize="medium"
                                        title="Sub Report"
                                        onClick={(e) => onClickReport(e, row)}
                                    />
                                </>
                            )}

                        </TableCell>
                    </>
                )}
            </TableRow>
            {subReport.length != 0 &&
                <TableCell style={{ paddingBottom: 5, paddingTop: 0 }} colSpan={10}>
                    <Box sx={{ marginLeft: 2, marginRight: 2 }}>

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <CreateTable rowSubList={row} subReport={subReport[0]} />
                            </Table>
                        </TableContainer>
                    </Box>
                </TableCell>
            }
        </>
    );
}

const CreateTable = ({ subReport, rowSubList = [] }) => {
    return (
        <>
            <TableHead>
                <TableRow>
                    <TableCell align ="center" width="10%">Sr. No.</TableCell>


                    {subReport.subList.map((headCell, index) => {
                        if (headCell.isList != 1) {

                            // const hiddenColumn = hiddenColumnNames.filter(
                            //     (hiddenCol) => hiddenCol === headCell.name
                            // );

                            return (
                                <TableCell
                                    key={headCell.fieldName}
                                    align={"center"}
                                    // sx={{ display: hiddenColumn.length !== 0 && "none" }}
                                    style={{
                                        minWidth:
                                            headCell.fieldName === "index"
                                                ? 100
                                                : headCell.width
                                                    ? headCell.width
                                                    : 100,
                                    }}

                                >
                                    {headCell.columnName}

                                </TableCell>
                            );
                        }
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {subReport["name"] && rowSubList[subReport["name"]] && rowSubList[subReport["name"]].map((subObj,index) => {
                    return <Row
                        row={subObj}
                        tableHead={subReport.subList}
                        isActionColActive={false}
                        hiddenColumnNames={[]}
                        showReportIcon={false}
                        index={index}
                    />
                })}

            </TableBody>
        </>
    )
}
EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
    columns,
    tableData = [],
    hiddenColumnNames = [],
    showPegination = true,
    isActionColActive = true,
    totalList = [],
    showReportIcon,
    rowReport
}) {
    console.log(totalList)
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = tableData.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

    return (
        <Box sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: 500 }}>
                <Table stickyHeader aria-label="sticky table">
                    <EnhancedTableHead
                        tableHead={columns}
                        hiddenColumnNames={hiddenColumnNames}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={tableData.length}
                    />
                    <TableBody>
                        {showPegination
                            ? stableSort(tableData, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <Row
                                            row={row}
                                            tableHead={columns}
                                            isActionColActive={isActionColActive}
                                            hiddenColumnNames={hiddenColumnNames}
                                            showReportIcon={showReportIcon}
                                            rowReport={rowReport}
                                            index={index}
                                        />
                                    );
                                })
                            : stableSort(tableData, getComparator(order, orderBy)).map(
                                (row, index) => {
                                    return (
                                        <Row
                                            row={row}
                                            tableHead={columns}
                                            isActionColActive={isActionColActive}
                                            hiddenColumnNames={hiddenColumnNames}
                                            showReportIcon={showReportIcon}
                                            rowReport={rowReport}
                                            index={index}
                                        />
                                    );
                                }
                            )}
                        {totalList.filter((row) => row != ""&&row != "0.00").length != 0 && totalList.length != 0 && < TableRow
                            style={{
                                height: 53 * emptyRows,
                            }}
                        >
                            <TableCell ></TableCell>
                            {totalList.map((total) => {
                                return (<TableCell align="right">{total}</TableCell>)
                            })}

                        </TableRow>
                        }
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows,
                                }}
                            >
                                <TableCell colSpan={columns.length} />
                            </TableRow>
                        )}
                        {tableData.length === 0 && (
                            <TableRow
                                style={{
                                    height: 53 * 1,
                                }}
                            >
                                <TableCell align="center" colSpan={columns.length+1}>
                                    No Record Found
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                showPegination && (
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        component="div"
                        count={tableData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )
            }
        </Box >
    );
}
