import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
    distributorInquiryDocumentDetails: [],
};
let URL = endpoints.distributorInquiryDocumentDetails;
const distributorInquiryDocumentDetailsSlice = createSlice({
    name: "distributorInquiryDocumentDetails",
    initialState,
    reducers: {
        distributorInquiryDocumentDetailsSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                distributorInquiryDocumentDetails: row,
            };
        },
        resetState: (state = cloneDeep(initialState), action) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const {
    distributorInquiryDocumentDetailsSuccess,
    resetState,
} = distributorInquiryDocumentDetailsSlice.actions;

export default distributorInquiryDocumentDetailsSlice.reducer;

export const getDistributorInquiryDocumentDetails = () => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL + '?sort={"documentName": "ASC"}',
        }).then(({ data, success }) => {
            if (success) {
                const { content } = data.data;
                const row = content.map((distributorInquiryObject, index) => {
                    let distributorInquiryData = {
                        index: index + 1,
                        id: distributorInquiryObject.id === null ? "" : distributorInquiryObject.id,
                        distributorInquiryId:
                            distributorInquiryObject.distributorInquiry === null
                                ? ""
                                : distributorInquiryObject.distributorInquiry.id,
                        documentName:
                            distributorInquiryObject.documentName === null ? "" : distributorInquiryObject.documentName,
                        documentImage:
                            distributorInquiryObject.documentImage === null ? "" : distributorInquiryObject.documentImage,
                        isActive:
                            distributorInquiryObject.isActive === null ? "" : distributorInquiryObject.isActive,
                        insertDateTime:
                            distributorInquiryObject.insertDateTime === null ? "" : distributorInquiryObject.insertDateTime,
                    };
                    return distributorInquiryData;
                });
                dispatch(distributorInquiryDocumentDetailsSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};


export const setDistributorInquiryDocumentDetails =
    ({ row }) =>
        async (dispatch) => {
            dispatch(distributorInquiryDocumentDetailsSuccess({ row }));
        };












