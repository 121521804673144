import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TableContainer,
} from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { connect } from "react-redux";
import { blackColor, checkboxColor } from "../../config/ColorObj";

class StakeholderCollapsibleCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      dummyList: [
        {
          id: 0,
          flag: true,
          checked: false,
          name: "distributor",
          list: [
            {
              id: 1,
              name: "A",
              checked: false,
            },
            {
              id: 2,
              name: "B",
              checked: false,
            },
            {
              id: 3,
              name: "C",
              checked: false,
            },
          ],
        },
        {
          id: 1,
          flag: false,
          checked: false,
          name: "stockist",
          list: [
            {
              id: 1,
              name: "E",
              checked: false,
            },
            {
              id: 2,
              name: "F",
              checked: false,
            },
            {
              id: 3,
              name: "G",
              checked: false,
            },
          ],
        },
        {
          id: 2,
          flag: false,
          checked: false,
          name: "superstockist",
          list: [
            {
              id: 1,
              name: "H",
              checked: false,
            },
            {
              id: 2,
              name: "I",
              checked: false,
            },
            {
              id: 3,
              name: "J",
              checked: false,
            },
          ],
        },
      ],
    };
  }
  openHandler = (rowId) => {
    const { dummyList } = this.state;
    let newList = dummyList.map((dummyObject) => {
      if (dummyObject.id == rowId) {
        dummyObject = {
          ...dummyObject,
          flag: !dummyObject.flag,
        };
        return dummyObject;
      } else {
        return dummyObject;
      }
    });
    this.setState({
      //   open: flag,
      dummyList: newList,
    });
  };

  onCheckBoxClick = (row, flag, e) => {
    const { dummyList } = this.state;
    let newList = dummyList.map((dummyObject) => {
      if (flag == 0) {
        if (dummyObject.id == row.id) {
          dummyObject = {
            ...dummyObject,
            checked: !dummyObject.checked,
            list: dummyObject.list.map((listObject) => {
              listObject = {
                ...listObject,
                checked: e.target.checked,
              };
              return listObject;
            }),
          };
          return dummyObject;
        } else {
          return dummyObject;
        }
      } else {
        dummyObject = {
          ...dummyObject,
          list: dummyObject.list.map((listObject) => {
            if (listObject.id == row.id) {
              listObject = {
                ...listObject,
                checked: !row.checked,
              };
              return listObject;
            } else {
              return listObject;
            }
          }),
        };
        return dummyObject;
      }
    });
    console.log(newList);
    this.setState({
      dummyList: newList,
    });
  };

  render() {
    const { open, dummyList } = this.state;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
        >
          {dummyList.map((dummyObject) => {
            return (
              <>
                <TableContainer>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" sx={{ width: "5%" }}>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => this.openHandler(dummyObject.id)}
                          >
                            {dummyObject.flag ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell align="center" sx={{ width: "5%" }}>
                          <FormControl component="fieldset">
                            <FormGroup aria-label="position">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    sx={{
                                      color: blackColor,
                                      "&.Mui-checked": {
                                        color: checkboxColor,
                                      },
                                    }}
                                  />
                                }
                                checked={dummyObject.checked}
                                onChange={(e) => {
                                  this.onCheckBoxClick(dummyObject, 0, e);
                                }}
                              />
                            </FormGroup>
                          </FormControl>
                        </TableCell>
                        <TableCell>{dummyObject.name}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={10}
                        >
                          <Collapse
                            in={dummyObject.flag}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box sx={{ margin: 1 }}>
                              <Table size="small" aria-label="purchases">
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      align="center"
                                      sx={{ width: "5%" }}
                                    ></TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{ width: "10%" }}
                                    >
                                      Sr. No
                                    </TableCell>
                                    <TableCell align="center">Name</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {dummyObject.list.map((listObject, index) => {
                                    return (
                                      <TableRow>
                                        <TableCell component="td" scope="row">
                                          <FormControl component="fieldset">
                                            <FormGroup aria-label="position">
                                              <FormControlLabel
                                                value="end"
                                                control={
                                                  <Checkbox
                                                    sx={{
                                                      color: blackColor,
                                                      "&.Mui-checked": {
                                                        color: checkboxColor,
                                                      },
                                                    }}
                                                  />
                                                }
                                                checked={listObject.checked}
                                                onChange={(e) => {
                                                  this.onCheckBoxClick(
                                                    listObject,
                                                    1,
                                                    e
                                                  );
                                                }}
                                              />
                                            </FormGroup>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell component="td" scope="row">
                                          <center> {index + 1}</center>
                                        </TableCell>
                                        <TableCell component="td" scope="row">
                                          {listObject.name}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
              </>
            );
          })}
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StakeholderCollapsibleCheck);
