import { Checkbox, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { compose } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getAssetsRequestDetails, getAssetsRequestDetailsGetAll, setAssetsRequestDetails } from "../../../Slice/assets.slice";
import { getAssetsImplementationTracking } from "../../../Slice/assetsImplementationTracking.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import { ChipCompo } from "../../../components/Comman/IconButton";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import { withRouter } from "../../../components/withRouter";
import { cyanBlue } from "../../../config/ColorObj";
import endpoint from "../../../config/endpoints";
import {
    changeActive,
    checkedListEmptyMsg,
    closedMsg,
    closeWarningMsg,
    noInternetMsg,
    saveFailedMsg,
    savemsg,
    saveWarningMsg,
    serverMsg
} from "../../../config/messageconstant";
import { apiGet, apiPost, apiPostForImage } from "../../../utils/api_service";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import AssetsTrackingPopUp from "./AssetsTrackingPopUp";
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

class AssetsRequestDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: "",
            searchValue: "",
            dynamicMasterData: {
            },
            formErrors: {},
            fieldData: {},
            value: "0",
            total: 0,
            totalAmt: 0,
            open: false,
            orderList: [],
            selection: [],
            qty: 0,
            flag: false,
            allChecked: false,
            distcountAmt: 0,
            headerData: {},
            assetsTrackingFlag: false,
            tableData: {},
            fileName: "",
            rows: [],
            assetsRequestDetailsId: "",
        };
    }


    async componentDidMount() {
        const { showLoador, showNotification, getAssetsRequestDetailsGetAll } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            await getAssetsRequestDetailsGetAll().then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.stackHolderList !== nextProps.stackHolderList) {
            if (
                this.props.stackHolderList?.stackholder !==
                nextProps.stackHolderList?.stackholder
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        orderDetail: nextProps.stackHolderList?.stackholder,
                    },
                });
            }
        }
    }
    handleChange = (event, newValue) => {

        this.setState({
            value: newValue,
        });
    };

    onSearch = (searchValue) => {
        this.setState({
            searchValue: searchValue,
        });
    };

    changeH = (fileName) => {
        this.setState({
            ...this.state,
            fileName: fileName,
        });
    };

    onClickViewPreview = (e, row) => {
        e.stopPropagation();
        const url = URL.createObjectURL(row);
        window.open(url);
    };



    onAddImage = () => {
        const { fileName, rows } = this.state;
        const { showLoador, showNotification, hideTable } = this.props;

        let fileNameData = dayjs(fileName.lastModifiedDate).format("hh:mm:ss") + fileName.name

        const formData = new FormData();
        formData.append("file", fileName);
        formData.append("imageName", fileNameData);


        swal({
            title: "Are you sure?",
            text: saveWarningMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                showLoador({ loador: true });
                apiPostForImage({
                    url: endpoint.uploadImage,
                    postBody: formData,
                }).then(({ data, success }) => {

                    if (success) {
                        showLoador({ loador: false });

                        const newRow = {
                            index: rows.length + 1,
                            fileName: data,
                            docName: fileName.name,
                        };

                        if (hideTable) {
                            this.setState({
                                rows: [newRow],
                            });
                        } else {
                            this.setState({
                                rows: [...rows, newRow],
                            });
                        }
                        this.onSubmitData();
                    } else {
                        showLoador({ loador: false });
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
        });
    };

    onSubmitData = () => {
        const {
            fileName,
            assetsRequestDetailsId,
        } = this.state;
        const { showLoador, showNotification } = this.props;
        let fileNameData = dayjs(fileName.lastModifiedDate).format("hh:mm:ss") + fileName.name

        let saveDataJson = {};

        saveDataJson = {
            assetsRequestDetails: {
                id: assetsRequestDetailsId,
            },
            image: fileNameData ? fileNameData : null,
        }
        console.log(saveDataJson);

        showLoador({ loador: true });
        apiPost({
            url: endpoint.assetsImplementationTracking,
            postBody: saveDataJson,
        }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success) {
                this.setState({ fileName: "" })
                showNotification({ msg: savemsg });
                this.assetsTracking(assetsRequestDetailsId);
            } else {
                showNotification({ msg: saveFailedMsg, severity: "error" });
            }
            return success;
        });
    }

    getFilteredTableData = (searchData) => {
        const { searchValue } = this.state;
        return searchData.filter((currentRow) => {
            let isValid = false;
            if (
                (currentRow["firmName"] &&
                    currentRow["firmName"]
                        .toString()
                        .toLowerCase()
                        .includes(searchValue.trim().toLowerCase()))
            ) {
                isValid = true;
                return true;
            }
        });
    };

    onCancel = () => {
        this.props.navigate(-1);
    };


    handleAllCheckedClick = (e) => {
        const { assetsList, setAssetsRequestDetails } = this.props;

        this.setState({ selection: e });

        this.setState({ allChecked: e.target.checked });

        let dataList = assetsList.assetsRequestDetails.map((dataObj) => {
            return {
                ...dataObj,
                isChecked: e.target.checked,
            };
        });
        console.log(dataList);
        setAssetsRequestDetails({ row: dataList });
    };

    handleClick = (e, id) => {
        const { assetsList, setAssetsRequestDetails } = this.props;

        this.setState({ selection: e });

        let data = assetsList.assetsRequestDetails.map((dataObj) => {
            if (dataObj.id == id) {
                return {
                    ...dataObj,
                    isChecked: e.target.checked,
                };
            } else {
                return dataObj;
            }
        });
        setAssetsRequestDetails({ row: data });
    };

    assetsTracking = (id) => {
        const { getAssetsImplementationTracking, showLoador, showNotification } = this.props;
        if (navigator.onLine) {

            this.setState({ assetsRequestDetailsId: id })
            showLoador({ loador: true });
            apiGet({
                url: endpoint.assetsRequestDetails + "/" + id,
            }).then(({ data, success }) => {
                if (success) {
                    showLoador({ loador: false });
                    console.log(data.data);
                    this.setState({
                        tableData: data.data,
                        assetsTrackingFlag: true,
                    });
                } else {
                    showLoador({ loador: false });
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });


            showLoador({ loador: true });
            getAssetsImplementationTracking({ assetsRequestDetailsId: id }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    console.log(response);
                }
            });



        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    toggle = () => {
        this.setState({
            assetsTrackingFlag: false,
        });
    };

    changeStatus = () => {
        const { assetsList, showNotification, showLoador } = this.props;
        const { value, selection } = this.state;

        if (selection.length !== 0) {


            let statusField = 0;

            if (value == 0) {
                statusField = 2;
            } else if (value == 1) {
                statusField = 3;
            } else if (value == 2) {
                statusField = 4;
            }

            const idsString = assetsList.assetsRequestDetails.filter((rowData) => rowData.isChecked)
                .map((dataObj) => dataObj.id).join(",");

            swal({
                title: "Are you sure?",
                text: changeActive,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    if (navigator.onLine) {
                        showLoador({ loador: true });
                        apiPost({
                            url: endpoint.assetsRequestDetails + "/update-request-status-and-action-date?ids=" + idsString + "&status=" + statusField + "&date=" + dayjs().format("DD-MM-YYYY"),
                        }).then(({ success }) => {
                            if (success) {
                                showLoador({ loador: false });
                                showNotification({
                                    msg: closedMsg,
                                });
                                window.location.reload();
                            } else {
                                showLoador({ loador: false });
                                showNotification({
                                    msg: saveFailedMsg,
                                    severity: "error",
                                });
                            }
                        });
                    } else {
                        showNotification({ msg: noInternetMsg, severity: "error" });
                    }
                }
            });
        } else {
            showNotification({
                msg: checkedListEmptyMsg,
                severity: "error",
            });
        }
    }


    onClosedHandler = () => {
        const { assetsList, showNotification, showLoador } = this.props;
        const { selection } = this.state;

        if (selection.length !== 0) {

            const idsString = assetsList.assetsRequestDetails.filter((rowData) => rowData.isChecked)
                .map((dataObj) => dataObj.id).join(",");

            swal({
                title: "Are you sure?",
                text: closeWarningMsg,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    if (navigator.onLine) {
                        showLoador({ loador: true });
                        apiPost({
                            url: endpoint.assetsRequestDetails + "/update-request-status-and-action-date?ids=" + idsString + "&status=4&date=" + dayjs().format("DD-MM-YYYY"),
                        }).then(({ success }) => {
                            if (success) {
                                showLoador({ loador: false });
                                showNotification({
                                    msg: closedMsg,
                                });
                                window.location.reload();
                            } else {
                                showLoador({ loador: false });
                                showNotification({
                                    msg: saveFailedMsg,
                                    severity: "error",
                                });
                            }
                        });
                    } else {
                        showNotification({ msg: noInternetMsg, severity: "error" });
                    }
                }
            });

        } else {
            showNotification({
                msg: checkedListEmptyMsg,
                severity: "error",
            });
        }
    };

    render() {
        const {
            value,
            allChecked,
            tableData,
            assetsTrackingFlag,
            fileName,
        } = this.state;
        const { assetsImplementationTrackingList, assetsList } = this.props;
        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 2,
                    }}
                    elevation={0}
                >
                    <LandingScreenHeader
                        showTitle={true}
                        screenTitle={"Assets Request Details"}
                        showSaveBtnMain={false}
                        showAddButton={false}
                        showBackButton={false}
                        onCancel={this.onCancel}
                    />
                    <br />
                    <>
                        <Tabs
                            value={value}
                            onChange={this.handleChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            aria-label="scrollable force tabs example"
                        >
                            <Tab value="0" label={"Approved" + " ( " + assetsList?.assetsRequestDetails.filter((row) => row.requestStatus == 1).length + " ) "} />
                            <Tab value="1" label={"Delivered" + " ( " + assetsList?.assetsRequestDetails.filter((row) => row.requestStatus == 2).length + " ) "} />
                            <Tab value="2" label={"Implemented" + " ( " + assetsList?.assetsRequestDetails.filter((row) => row.requestStatus == 3).length + " ) "} />
                            <Tab value="3" label={"Closed" + " ( " + assetsList?.assetsRequestDetails.filter((row) => row.requestStatus == 4).length + " ) "} />
                        </Tabs>

                        <br/>

                        <TabPanel value={value} index={value}>
                            <LandingScreenHeader
                                screenTitle=""
                                showSearchBox={true}
                                showTable={true}
                                showFilter={false}
                                showPdfDownload={false}
                                showExcelDownload={false}
                                onSearch={this.onSearch}
                            />

                            <TableContainer style={{ maxHeight: "550px", overflow: "auto" }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="error"
                                                    onClick={(event) => this.handleAllCheckedClick(event)}
                                                    checked={allChecked}
                                                    disabled={false}
                                                    inputProps={{
                                                        "aria-label": "select all desserts",
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="center" width="5%">Sr. No.</TableCell>
                                            <TableCell align="center" width="20%">Firm Name</TableCell>
                                            <TableCell align="center" width="15%">Assets</TableCell>
                                            <TableCell align="center" width="9%">Assets Quantity</TableCell>
                                            <TableCell align="center" width="8%">Request Date</TableCell>
                                            <TableCell align="center" width="8%">Action Date</TableCell>
                                            <TableCell align="center" width="10%">Vender</TableCell>
                                            <TableCell align="center">Cost</TableCell>
                                            <TableCell align="center">Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.getFilteredTableData(value == 0 ? assetsList?.assetsRequestDetails.filter((row) => row.requestStatus == 1) : value == 1 ? assetsList?.assetsRequestDetails.filter((row) => row.requestStatus == 2) : value == 2 ? assetsList?.assetsRequestDetails.filter((row) => row.requestStatus == 3) : assetsList?.assetsRequestDetails.filter((row) => row.requestStatus == 4))?.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        onClick={(event) => this.handleClick(event, row.id)}
                                                        checked={row.isChecked}
                                                        disabled={false}
                                                        inputProps={{
                                                            "aria-labelledby": `enhanced-table-checkbox-${index}`,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">{index + 1}</TableCell>
                                                <TableCell align="left">{row.firmName}</TableCell>
                                                <TableCell width="left" >
                                                    <div
                                                        onClick={(e) =>
                                                            this.assetsTracking(row.id)
                                                        }
                                                        style={{
                                                            color: cyanBlue,
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        {row.assetsName}
                                                    </div>
                                                </TableCell>
                                                <TableCell align="right">{row.assetsQuantity}</TableCell>
                                                <TableCell align="center">{row.requestDate}</TableCell>
                                                <TableCell align="center">{row.requestActionDate}</TableCell>
                                                <TableCell align="center">{row.venderName}</TableCell>
                                                <TableCell align="right">{row.implementaionCost}</TableCell>
                                                <TableCell align="center">
                                                    <ChipCompo
                                                        label={value == 0 ? "Approved" : value == 1 ? "Delivered" :
                                                            value == 2 ? "Implemented" : "Closed"}
                                                        variant="outlined"
                                                        size="small"
                                                        color="success"
                                                        onClick={(e) => this.changeStatus(e, row)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                        <br />
                        {assetsTrackingFlag && (
                            <AssetsTrackingPopUp
                                tableData={tableData}
                                listData={assetsImplementationTrackingList?.assetsImplementationTracking}
                                open={assetsTrackingFlag}
                                handleCloses={this.toggle}
                                onAddImage={this.onAddImage}
                                changeH={this.changeH}
                                fileName={fileName}
                                onClickViewPreview={this.onClickViewPreview}
                            />
                        )}

                        {value == 0 && (
                            <Grid container spacing={1}>
                                <Grid
                                    container
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    sx={{ justifyContent: "flex-end" }}
                                >
                                    <ButtonCompo
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        name={"Closed"}
                                        onClick={this.onClosedHandler}
                                    />
                                </Grid>
                            </Grid>
                        )
                        }

                    </>

                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    assetsList: state.assets,
    assetsImplementationTrackingList: state.assetsImplementationTracking,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getAssetsRequestDetails,
    setAssetsRequestDetails,
    getAssetsImplementationTracking,
    getAssetsRequestDetailsGetAll
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AssetsRequestDetails);
