import endpoint from "../../config/endpoints";

export const BrandJson = {
  showSaveNextBtn: true,
  formPath: "/brand-form",
  apiBaseURL: endpoint.brand,
  changeExcel: endpoint.exportData,
  screenTitle: "Brand",
  showAddButton: true,
  showPdfDownload: false,
  pdfFileName: "brand",
  showExcelDownload: false,
  excelFileName: "brand",
  excelUrl: "brand-data" + '?sort={"sortOrder": "ASC"}',
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Sort Order Number",
      columnKeyName: "sortOrder",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "name",
      isMandatory: true,
      uniqueValidation: true,
    },
    {
      label: "Short Name",
      controlType: "textfield",
      placeHolder: "Short Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "shortName",
      isMandatory: true,
    },
    {
      label: "Sort Order No",
      controlType: "textfield",
      placeHolder: "Sort Order Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sortOrder",
      isMandatory: true,
    },
  ],
};
