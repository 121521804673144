import { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";

// third-party
import ReactApexChart from "react-apexcharts";
import React from "react";

// class RadarChart extends React.Component {


//   render() {
//     const {options,series,width=320}=this.props
//     return (

//         <ReactApexChart
//           options={options}
//           series={series}
//           type="radialBar"
//          width={360}
//         />

//     );
//   }
// }

// class RadarChart extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {

//       series: [76, 67, 61, 90],
//       options: {
//         chart: {
//           height: 390,
//           type: 'radialBar',
//         },
//         plotOptions: {
//           radialBar: {
//             offsetY: 0,
//             startAngle: 0,
//             endAngle: 270,
//             hollow: {
//               margin: 5,
//               size: '30%',
//               background: 'transparent',
//               image: undefined,
//             },
//             dataLabels: {
//               name: {
//                 show: false,
//               },
//               value: {
//                 show: false,
//               }
//             }
//           }
//         },
//         colors: ['#1ab7ea', '#0084ff', '#39539E', '#0077B5'],
//         labels: ['Vimeo', 'Messenger', 'Facebook', 'LinkedIn'],
//         legend: {
//           show: true,
//           floating: true,
//           fontSize: '16px',
//           position: 'left',
//           offsetX: 10,
//           offsetY: 5,
//           labels: {
//             useSeriesColors: true,
//           },
//           markers: {
//             size: 0
//           },
//           formatter: function(seriesName, opts) {
//             return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
//           },
//           itemMargin: {
//             vertical: 3
//           }
//         },
//         responsive: [{
//           breakpoint: 480,
//           options: {
//             legend: {
//                 show: false
//             }
//           }
//         }]
//       },


//     };
//   }



//   render() {
//     return (


// <div id="chart">
// <ReactApexChart options={this.props.options} series={this.props.series} type="radialBar" height={390} />
// </div>


//     );
//   }
// }

class RadarChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      series: [14, 100, 21, 17, 15, 10, 12, 17, 21],
      options: {
        chart: {
          type: 'polarArea',
        },
        stroke: {
          colors: ['#fff']
        },
        fill: {
          opacity: 0.8
        },
        labels: [
          "D.Va",
          "Orisa",
          "Reinhartd",
          "Roadhog",
          "Sigma",
          "Winston",
          "Wrecking Ball",
          "Zarya",
        ],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },


    };
  }



  render() {
    return (
      <div id="chart">
        <ReactApexChart options={this.props.options} series={this.props.series} type="polarArea" />
      </div>
    );
  }
}
export default RadarChart;
