import { Box, Tab, Tabs, Typography } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import "../../CSS/app.min.css";
import "../../CSS/bootstrap.min.css";
import "../../CSS/custom.min.css";
import "../../CSS/mermaid.min.css";
import "../../CSS/slickDashboard.css";
import image4 from "../../Images/image4.png";
import image5 from "../../Images/image5.png";
import image6 from "../../Images/image6.png";
import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import {
  getPrimaryLatestOrder
} from "../../Slice/OrderHeaderList.slice";
import {
  getPrimaryLatestBill
} from "../../Slice/orderBillList.slice";
import {
  getGrnLatest
} from "../../Slice/requestGRN.slice";

import {
  getCreditNoteLatest
} from "../../Slice/creditNote.slice";
import { ConvertAmount } from "../../components/Comman/ConvertValueWithDenominator";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { withRouter } from "../../components/withRouter";
import { serverMsg } from "../../config/messageconstant";



let tabColumns = [
  {
    name: "index",
    title: "Sr. No.",
    width: "10%",
    positionCenter: true,
    showInscreen: true,
    alignCell: "center",
    align: "center",
  },
  {
    title: "Order No",
    name: "orderNo",
    width: "15%",
    positionCenter: true,
    showInscreen: true,
    alignCell: "center",

    canSearch:true,
    align: "center",
  },
  {
    title: "Order Date",
    name: "orderDate",
    width: "15%",
    positionCenter: true,
    canSearch:true,
    showInscreen: true,
    alignCell: "center",
    align: "center",
  },
  {
    title: "Total",
    name: "totalAmt",
    canSearch:true,
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "right",
  }
 
];
let billColumns = [
  {
    name: "index",
    title: "Sr. No.",
    width: "10%",
    positionCenter: true,
    showInscreen: true,
    alignCell: "center",
    align: "center",
  },
  {
    title: "Bill No",
    name: "billNo",
    width: "15%",
    canSearch:true,
    positionCenter: true,
    showInscreen: true,
    alignCell: "center",
    align: "center",
  },
  {
    title: "Bill Date",
    name: "billDate",
    width: "15%",
    canSearch:true,
    positionCenter: true,
    showInscreen: true,
    alignCell: "center",
    align: "center",
  },
  {
    title: "Total",
    name: "totalAmt",
    positionCenter: false,
    showInscreen: true,
    canSearch:true,
    alignCell: "center",
    alignCenter: "right",
  }
];
let GRNColumns = [
  {
    name: "index",
    title: "Sr. No.",
    width: "10%",
    positionCenter: true,
    showInscreen: true,
    alignCell: "center",
    align: "center",
  },
  
  {
    title: "GRN GVN No",
    name: "grnGvnNo",
    width: "15%",
    positionCenter: true,
    showInscreen: true,
    alignCell: "center",
    align: "center",
    canSearch:true,
  },
  {
    title: "Date",
    name: "date",
    width: "15%",
    positionCenter: true,
    showInscreen: true,
    alignCell: "center",
    align: "center",
    canSearch:true,
  },
  {
    title: "Total",
    name: "totalAmt",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "right",
    canSearch:true,
  }
 
];
let CRNColumns = [
  {
    name: "index",
    title: "Sr. No.",
    width: "10%",
    positionCenter: true,
    showInscreen: true,
    alignCell: "center",
    align: "center",
  },
  
  {
    title: "Transaction No",
    name: "transactionNo",
    width: "15%",
    positionCenter: true,
    showInscreen: true,
    alignCell: "center",
    align: "center",
    canSearch:true,
  },
  {
    title: "Date",
    name: "date",
    width: "15%",
    positionCenter: true,
    showInscreen: true,
    alignCell: "center",
    align: "center",
    canSearch:true,
  },
  {
    title: "Total",
    name: "totalAmt",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "right",
    canSearch:true,
  }
 
];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

class PrimaryOrder extends Component {
  
  state = {
    openTab: 2,
    tabValue: 0,
    tabValuePayment: 0,
    searchValue:""
   
  };
  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };
  rowViewData = (row) => {
    this.props.navigate("/order-history-view/" + row.id);
  };

 

  a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

 
 
  async componentDidMount() {
    const {
        showNotification,
      showLoador,
      getPrimaryLatestOrder,
      getPrimaryLatestBill,
      getGrnLatest,
      getCreditNoteLatest
    } = this.props;
    if (navigator.onLine) {
      
      showLoador({ loador: true });
      await getPrimaryLatestOrder().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getPrimaryLatestBill().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getGrnLatest().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getCreditNoteLatest().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }


  
 
  onClickTab = (value) => {
    this.setState({
        openTab: value
    })
}
handledChange = (e, newValue) => {
  this.setState({
    tabValue: newValue,
  });
};
getFilteredTableData = (tableColumns,tableData) => {
  const { searchValue } = this.state;
  const columsFil = tableColumns.filter((item) => {
    return item.canSearch;
  });
  return tableData.filter((currentRow) => {
    let isValid = false;
    columsFil.some((col) => {
      if (
        currentRow[col.name] &&
        currentRow[col.name]
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      return null;
    });
    return isValid;
  });
};
  render() {
    const {
        orderHeaderList,
        billHeaderList,
        grnByStakeholderIdList,
        creditNoteHeader
    } = this.props;
    const {
      openTab,
      tabValue
    
    } = this.state;
    return (
      <>
      
                  
                    <div
                      className="row"
                      style={{
                        gap: "26px",
                        paddingBottom: 10,
                        padding: "12px",
                      }}
                    >
                      {/* Card 1 */}
                      <div

onClick={() => {
  this.onClickTab(1)
}}
                        className="Frame427319159"
                        style={{
                          width: "32%",
                          height: "auto",
                          paddingTop: 22,
                          paddingBottom: 22,
                          background:
                          openTab == 1
                              ? "#ffe19cd1"
                              : "rgba(253, 126, 20, 0.10)",
                          borderRadius: 8,
                          border:
                          openTab == 1 ? "1px solid" : "",
                          overflow: "hidden",
                        
                          justifyContent: "center",
                          alignItems: "center",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          className="Frame11262"
                          style={{
                            flex: "1 1 0",
                            alignSelf: "stretch",
                            paddingLeft: 16,
                            paddingRight: 16,
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            display: "inline-flex",
                          }}
                        >
                          <div
                            className="Frame427319153"
                            style={{
                              width: "60%",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "flex-start",
                              gap: 8,
                              display: "inline-flex",
                            }}
                          >
                            <img
                              className="Image4"
                              style={{ width: 32, height: 32 }}
                              src={image4}
                            />

                            <div
                              className="TotalSales"
                              style={{
                                color: "black",
                                fontSize: 22,
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                wordWrap: "break-word",
                                top: 5,
                              }}
                            >
                             ORDER
                            </div>
                          </div>
                          <div
                            className="Frame427319116"
                            style={{
                              // alignSelf: "stretch",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              alignItems: "center",
                              display: "inline-flex",
                            }}
                          >
                            <div
                              className="Cr"
                              style={{
                                color: "black",
                                fontSize: 28,
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                textTransform: "capitalize",
                                lineHeight: 1.8,
                                wordWrap: "break-word",
                              }}
                            >
                              {ConvertAmount({
                                amount:Object.keys( orderHeaderList?.primaryOrder).length!=0?
                                orderHeaderList.primaryOrder.due:0
                              })}
                            </div>
                            <div
                              className="Frame427319115"
                              style={{
                                justifyContent: "flex-end",
                                alignItems: "center",
                                gap: 2,
                                display: "inline-flex",
                              }}
                            >
                              <div
                                style={{
                                  color: "#43C622",
                                  fontSize: 16,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  textTransform: "capitalize",
                                  wordWrap: "break-word",
                                }}
                              >
                               PENDING ORDER COUNT/ PAYMENT DUE AMT
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Card 1  end*/}

                      {/* Card 2 */}

                      <div
                       onClick={() => {
                        this.onClickTab(2)
                    }}
                        className="Frame427319162"
                        style={{
                          width: "32%",
                          height: "auto",
                          paddingTop: 22,
                          paddingBottom: 22,
                          background:
                           openTab==2
                              ? "#ffe19cd1"
                              : "rgba(253, 126, 20, 0.10)",
                          borderRadius: 8,
                          overflow: "hidden",
                          border:openTab==2 ? "1px solid" : "",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          className="Frame11262"
                          style={{
                            flex: "1 1 0",
                            alignSelf: "stretch",
                            paddingLeft: 16,
                            paddingRight: 16,
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            display: "inline-flex",
                          }}
                        >
                          <div
                            className="Frame427319153"
                            style={{
                              width: "60%",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "flex-start",
                              gap: 8,
                              display: "inline-flex",
                            }}
                          >
                            <img
                              className="Image5"
                              style={{ width: 32, height: 32 }}
                              src={image5}
                            />
                            <div
                              className="TotalSales"
                              style={{
                                color: "black",
                                fontSize: 22,
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                wordWrap: "break-word",
                              }}
                            >
                              BILL LIST
                            </div>
                          </div>
                          <div
                            className="Frame427319116"
                            style={{
                              // alignSelf: "stretch",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              alignItems: "center",
                              display: "inline-flex",
                            }}
                          >
                            <div
                              style={{
                                color: "black",
                                fontSize: 28,
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                textTransform: "capitalize",
                                lineHeight: 1.8,
                                wordWrap: "break-word",
                              }}
                            >
                             {ConvertAmount({
                                amount:Object.keys( orderHeaderList?.primaryOrder).length!=0?
                                orderHeaderList.primaryOrder.notRecievedBillCount:0
                              })}
                            </div>
                            <div
                              className="Frame427319115"
                              style={{
                                justifyContent: "flex-end",
                                alignItems: "center",
                                gap: 2,
                                display: "inline-flex",
                              }}
                            >
                              <div
                                style={{
                                  color: "#43C622",
                                  fontSize: 16,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  textTransform: "capitalize",
                                  wordWrap: "break-word",
                                }}
                              >
                                NOT RECEIVED BILL COUNT
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Card 2 end */}

                      {/* Card 3 */}

                      <div
                       onClick={() => {
                        this.onClickTab(3)
                    }}
                        className="Frame427319160"
                        style={{
                          width: "32%",
                          height: "auto",
                          paddingTop: 22,
                          paddingBottom: 22,
                          background:
                            openTab==3
                              ? "#ffe19cd1"
                              : "rgba(253, 126, 20, 0.10)",
                          borderRadius: 8,
                          overflow: "hidden",
                          border:
                            openTab==3 ? "1px solid" : "",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          className="Frame11262"
                          style={{
                            flex: "1 1 0",
                            alignSelf: "stretch",
                            paddingLeft: 16,
                            paddingRight: 16,
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            display: "inline-flex",
                          }}
                        >
                          <div
                            className="Frame427319153"
                            style={{
                              width: "60%",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "flex-start",
                              gap: 8,
                              display: "inline-flex",
                            }}
                          >
                            <img
                              className="Image5"
                              style={{ width: 32, height: 32 }}
                              src={image6}
                            />
                            <div
                              className="TotalSales"
                              style={{
                                color: "black",
                                fontSize: 22,
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                wordWrap: "break-word",
                              }}
                            >
                              GRN
                            </div>
                          </div>
                          <div
                            className="Frame427319116"
                            style={{
                              // alignSelf: "stretch",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              alignItems: "center",
                              display: "inline-flex",
                            }}
                          >
                            <div
                              style={{
                                color: "black",
                                fontSize: 28,
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                textTransform: "capitalize",
                                lineHeight: 1.8,
                                wordWrap: "break-word",
                              }}
                            >
                              {ConvertAmount({
                                amount:Object.keys( orderHeaderList?.primaryOrder).length!=0?
                                orderHeaderList.primaryOrder.grnGvnAmt:0
                              })}
                            </div>
                            <div
                              className="Frame427319115"
                              style={{
                                justifyContent: "flex-end",
                                alignItems: "center",
                                gap: 2,
                                display: "inline-flex",
                              }}
                            >
                              <div
                                style={{
                                  color: "#43C622",
                                  fontSize: 16,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  textTransform: "capitalize",
                                  wordWrap: "break-word",
                                }}
                              >
                               GRN AMT CRN NOT GENERATED
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Card 3 end */}
                    </div>

                   
                    {openTab==1 ? (
                      <>
                     
                      <LandingScreenHeader
            screenTitle={""}
            showSearchBox={true}
            onOpenFilter={()=>{
              this.props.onOpenFilter(1)}}
            showTable={true}
            showFilter={true}
            showPdfDownload ={false} 
      showExcelDownload ={false} 
      onSearch={this.onSearch}
          />
                   

                      <DynamicTable
                              data={this.getFilteredTableData(tabColumns,orderHeaderList?.primaryLatestOrder) }
                              tableHead={tabColumns}
                              isActionColActive={false}
                              showPegination={false}
                            />
                      </>
                    ): openTab==2? 
                    (
                      <>
                     
                      <LandingScreenHeader
            screenTitle={""}
            showSearchBox={true}
            onOpenFilter={()=>{
              this.props.onOpenFilter(3)}}
            showTable={true}
            showFilter={true}
            showPdfDownload ={false} 
      showExcelDownload ={false} 
      onSearch={this.onSearch}
          />
                   

                      <DynamicTable                            
                              data={this.getFilteredTableData(billColumns,billHeaderList?.primaryLatestBill) }
                              tableHead={billColumns}
                              isActionColActive={false}
                              showPegination={false}
                              
                            />
                      </>
                    ):
                    (
                      <>
                     
                      <LandingScreenHeader
            screenTitle={""}
            showSearchBox={true}
            onOpenFilter={()=>{
              this.props.onOpenFilter(1)}}
            showTable={true}
            showFilter={true}
            showPdfDownload ={false} 
      showExcelDownload ={false} 
      onSearch={this.onSearch}
          />
                   

                   <Box sx={{ width: "99.5%" }}>
                          <Tabs
                            onChange={this.handledChange}
                            value={tabValue}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                            sx={{
                              border: "2px solid black",
                              borderColor: "#eded9b",
                              borderRadius: "8px",
                              backgroundColor: "#ffe19cd1",
                            }}
                          >
                            <Tab
                              label={
                                "GRN ("+grnByStakeholderIdList.GRNList.length+")"
                              }
                              {...this.a11yProps(0)}
                            />
                            <Tab
                              label={
                                "CRN ("+creditNoteHeader.creditNoteLatest.length+")"
                              }
                              {...this.a11yProps(1)}
                            />
                          </Tabs>
                          <SwipeableViews
                            index={tabValue}
                            onChangeIndex={this.handleChange}
                          >
                           
                                  <TabPanel
                                    value={0}
                                    index={0}
                                  >
                                    <DynamicTable
                                      data={this.getFilteredTableData(GRNColumns,grnByStakeholderIdList.GRNList) }
                             
                                      tableHead={GRNColumns}
                                      isActionColActive={false}
                                      showPegination={false}
                                      
                                    />
                                  </TabPanel>
                                  <TabPanel
                                    value={1}
                                    index={1}
                                  >
                                    <DynamicTable
                                      data={this.getFilteredTableData(CRNColumns,creditNoteHeader.creditNoteLatest) }
                             
                                      tableHead={CRNColumns}
                                      isActionColActive={false}
                                      showPegination={false}
                                     
                                    />
                                  </TabPanel>
                          </SwipeableViews>
                        </Box>
                      </>
                    )}
                 
          </>
        
         

    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
  stockStakeHolderTypeList: state.stockStakeHolderType,
  zoneList: state.zone,
  billHeaderList: state.billHeaderList,
  grnByStakeholderIdList: state.requestGRN,
  functionalDashboardDistributorData: state.functionalDashboardDistributor,
  outletOrderDetail: state.outletOrderDetail,
  orderHeaderList:state.orderHeaderList,
  creditNoteHeader:state.creditNoteHeader
});

const mapDispatchToProps = {
  showLoador,
  showNotification,
  getPrimaryLatestOrder,
  getPrimaryLatestBill,
  getGrnLatest,
  getCreditNoteLatest
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PrimaryOrder);