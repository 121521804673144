import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
    minutesOfMeeting: [],
};
let URL = endpoints.minutesOfMeetingHeader;
const minutesOfMeetingSlice = createSlice({
    name: "minutesOfMeeting",
    initialState,
    reducers: {
        minutesOfMeetingSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                minutesOfMeeting: row,
            };
        },


        resetState: (state = cloneDeep(initialState), action) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const {
    minutesOfMeetingSuccess,
    resetState,
} = minutesOfMeetingSlice.actions;

export default minutesOfMeetingSlice.reducer;

export const getMinutesOfMeeting =
    ({ distributorInquiryId }) =>
        async (dispatch) => {
            try {
                const response = apiGet({
                    url: URL + "/get-counts-by-distributor-inquiry?distributorInquiryId=" + distributorInquiryId,
                }).then(({ data, success }) => {
                    if (success) {
                        const row = data.data.map((momObj, index) => {
                            let momListData = {
                                index: index + 1,
                                id: momObj.id === null ? "" : momObj.id,
                                meetingPurpose:
                                    momObj.meetingPurpose === null
                                        ? ""
                                        : momObj.meetingPurpose,
                                minutesOfMeetingDate:
                                    momObj.minutesOfMeetingDate === null
                                        ? ""
                                        : momObj.minutesOfMeetingDate,

                                count: momObj.count === null ? "" : momObj.count + ' ' + "People",

                                stockStakeHolderTypeId:
                                    momObj.stockStakeHolderTypeId === null
                                        ? ""
                                        : momObj.stockStakeHolderTypeId,
                                distributorInquiry:
                                    momObj.distributorInquiry === null ? "" : momObj.distributorInquiry,
                                insertedUserId:
                                    momObj.insertedUserId === null
                                        ? ""
                                        : momObj.insertedUserId,
                                insertDateTime:
                                    momObj.insertDateTime === null ? "" : momObj.insertDateTime,
                            };
                            return momListData;
                        });
                        dispatch(minutesOfMeetingSuccess({ row }));
                        return { response: row, success };
                    }
                    return { response: [], success };
                });
                return response;
            } catch (e) {
                return console.error(e.message);
            }
        };


export const setMinutesOfMeetings =
    ({ row }) =>
        async (dispatch) => {
            dispatch(minutesOfMeetingSuccess({ row }));
        };


