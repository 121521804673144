import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  tax: [],
};
let URL = endpoints.tax;
const taxSlice = createSlice({
  name: "tax",
  initialState,
  reducers: {
    taxSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        tax: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { taxSuccess, resetState } = taxSlice.actions;

export default taxSlice.reducer;

export const getTax = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertDateTime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((taxData, index) => {
          let tax = {
            index: index + 1,
            id: taxData.id === null ? "" : taxData.id,
            name:
              taxData.name === null &&
              taxData.hsnCode === null &&
              taxData.cgst === null &&
              taxData.sgst === null
                ? ""
                : taxData.name +
                  " - " +
                  taxData.hsnCode +
                  " - " +
                  taxData.cgst +
                  " - " +
                  taxData.sgst,
            taxName: taxData.name === null ? "" : taxData.name,
            sgst: taxData.sgst === null ? "" : taxData.sgst,
            cgst: taxData.cgst === null ? "" : taxData.cgst,
            igst: taxData.igst === null ? "" : taxData.igst,
            cess: taxData.cess === null ? "" : taxData.cess,
            hsnCode: taxData.hsnCode === null ? "" : taxData.hsnCode,
            isActive: taxData.isActive === null ? "" : taxData.isActive,
          };
          return tax;
        });
        dispatch(taxSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const setTax =
  ({ row }) =>
  async (dispatch) => {
    dispatch(taxSuccess({ row }));
  };
