import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { ManagerJson } from "../../../DynamicFormsJson/MastersJSON/manager";
import { getRegionNew, getZoneByRegion } from "../../../Slice/region.slice";
import { getStockStakeHolderType } from "../../../Slice/stockStakeHolderType.slice";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { serverMsg, noInternetMsg } from "../../../config/messageconstant";
import {
  getAreaByStockStakeHolderTypeIdAndZoneIds,
  getAreaByStockStakeHolderTypeIdAndZoneIdsEdit,
} from "../../../Slice/salesExecutive.slice";
import { withRouter } from "../../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import { apiGet } from "../../../utils/api_service";

class ManagerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        regionDetail: this.props.regionList?.region,
        zoneDetail: this.props.regionList?.zone,
        areaDetail: this.props.salesExecutiveList?.area,
        stockStakeHolderTypeDetail:
          this.props.stockStakeHolderTypeList?.stockStakeHolderType,
      },
      stockStakeHolderTypeId: "",
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }

      if (this.props.regionList?.zone !== nextProps.regionList?.zone) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.regionList?.zone,
          },
        });
      }
    }
    if (this.props.salesExecutiveList !== nextProps.salesExecutiveList) {
      if (
        this.props.salesExecutiveList?.area !==
        nextProps.salesExecutiveList?.area
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.salesExecutiveList?.area,
          },
        });
      }
    }
    if (this.props.stockStakeHolderTypeList !== nextProps.stockStakeHolderTypeList) {
      if (
        this.props.stockStakeHolderTypeList?.stockStakeHolderType !==
        nextProps.stockStakeHolderTypeList?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stockStakeHolderTypeDetail:
              nextProps.stockStakeHolderTypeList?.stockStakeHolderType,
          },
        });
      }
    }
  }

  async componentDidMount() {
    const { getStockStakeHolderType, getRegionNew, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getRegionNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      getStockStakeHolderType({ userTypeIds: 3 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getListById = async (data) => {
    const { stockStakeHolderTypeId } = this.state;
    const {
      getAreaByStockStakeHolderTypeIdAndZoneIds,
      getAreaByStockStakeHolderTypeIdAndZoneIdsEdit,
      getZoneByRegion,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      console.log(data);
      if (data.stockStakeHolderTypeList && data.stockStakeHolderTypeList.id) {
        this.setState({
          stockStakeHolderTypeId: data.stockStakeHolderTypeList.id,
        });
      }
      if (data.zoneIds && data.stockStakeHolderType && data.stockStakeHolderType.id != "") {
        if (this.props.params.id) {
          showLoador({ loador: true });
          await getAreaByStockStakeHolderTypeIdAndZoneIdsEdit({
            saleExcId: this.props.params.id,
            StockStakeHolderTypeId: data.stockStakeHolderType.id,
            zoneIds: data.zoneIds,
          }).then(({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          });
        } else {
          showLoador({ loador: true });
          await getAreaByStockStakeHolderTypeIdAndZoneIds({
            StockStakeHolderTypeId: data.stockStakeHolderType.id,
            zoneIds: data.zoneIds,
          }).then(({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          });
        }
      }
      if (data.region && data.region.id) {
        showLoador({ loador: true });
        await getZoneByRegion({ regionId: data.region.id }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  callBeforeSaveMethod = async (dataToSave, editData) => {
    if (
      Object.keys(editData).length == 0 ||
      (Object.keys(editData).length != 0 &&
        editData.employeeCode != dataToSave.employeeCode)
    ) {
      const { showLoador, showNotification } = this.props;
      if (navigator.onLine) {
        showLoador({ loador: true });
        const response = await apiGet({
          url:
            ManagerJson.apiBaseURL +
            "/employee-code-exist-or-not?employeeCode=" +
            dataToSave.employeeCode,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            if (!data.data.error) {
              showNotification({
                msg: data.data.message,
                severity: "error",
              });
            }
            return data.data.error;
          }
          return false;
        });
        return response;
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
    return true;
  };


  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicForm
          callBeforeSave={true}
          callBeforeSaveMethod={this.callBeforeSaveMethod}
          formPath={ManagerJson.formPath}
          getListById={this.getListById}
          screenTitle={ManagerJson.screenTitle}
          fieldMeta={ManagerJson.fieldMeta}
          dynamicMasterData={dynamicMasterData}
          apiBaseURL={ManagerJson.apiBaseURL}
          showSaveNextBtn={ManagerJson.showSaveNextBtn}
          isEditURL={ManagerJson.isEditURL}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  zoneList: state.zone,
  stockStakeHolderTypeList: state.stockStakeHolderType,
  salesExecutiveList: state.salesExecutive,
  regionList: state.region,
});
const mapDispatchToProps = {
  getRegionNew,
  getZoneByRegion,
  getAreaByStockStakeHolderTypeIdAndZoneIds,
  getAreaByStockStakeHolderTypeIdAndZoneIdsEdit,
  getStockStakeHolderType,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ManagerForm);