import endpoint from "../../config/endpoints";

export const UomJson = {
  formPath: "/uom-form",
  apiBaseURL: endpoint.unitOfMesurement,
  changeExcel: endpoint.exportData,
  screenTitle: "Unit Of Measurement",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "UOM",
  showExcelDownload: true,
  excelFileName: "UOM",
  excelUrl: "uom-data" + '?sort={"insertDateTime": "DESC"}',

  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "name",
      isChecked: true,
    },

    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "Enter Name",
      xs: 7,
      autoFocus: true,
      dataKey: "name",
      isMandatory: true,
      uniqueValidation: true,
    },
  ],
};
