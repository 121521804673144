import React from "react";
import { connect } from "react-redux";
import { ReportInfoColumns } from "../../tableColumns/table-columns";
import { matrixReportInfoJson } from "../../DynamicFormsJson/Transaction/matrixReportGenerate";
import { getReportInfo } from "../../Slice/matrixReport.slice";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";

class ReportTable extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { reportList, getReportInfo } = this.props;
        return (
            <>
                <DynamicMainScreen
                    formPath={matrixReportInfoJson.formPath}
                    screenTitle={matrixReportInfoJson.screenTitle}
                    showPdfDownload={false}
                    showExcelDownload={false}
                    showAddButton={true}
                    tableColumnsToFilter={matrixReportInfoJson.tableColumnsToFilter}
                    tableColumns={ReportInfoColumns}
                    tableData={reportList?.report}
                    getTableData={getReportInfo}
                    apiBaseURL={matrixReportInfoJson.apiBaseURL}
                    baseIdColumn={matrixReportInfoJson.baseIdColumn}
                    showPegination={false}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    reportList: state.matrixReport,
});
const mapDispatchToProps = {
    getReportInfo,
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportTable);
