import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
    assetsChecklist: [],
};
let URL = endpoints.assetsChecklist;
let URL1 = endpoints.assetsChecklistDetails;
const assetsChecklistSlice = createSlice({
    name: "assetsChecklist",
    initialState,
    reducers: {
        assetsChecklistSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                assetsChecklist: row,
            };
        },

        assetsChecklistDetailsSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                assetsChecklistDetails: row,
            };
        },

        resetState: (state = cloneDeep(initialState), action) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const {
    assetsChecklistSuccess,
    assetsChecklistDetailsSuccess,
    resetState,
} = assetsChecklistSlice.actions;

export default assetsChecklistSlice.reducer;

export const getAssetsChecklist = () => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL,
        }).then(({ data, success }) => {
            if (success) {
                const { content } = data.data;
                const row = content.map((assetsChecklistObj, index) => {
                    let areaData = {
                        index: index + 1,
                        id: assetsChecklistObj.id === null ? "" : assetsChecklistObj.id,
                        questions: assetsChecklistObj.questions === null ? "" : assetsChecklistObj.questions,
                        assets: assetsChecklistObj.assets === null ? "" : assetsChecklistObj.assets.id,
                        assetsName: assetsChecklistObj.assets !== null && assetsChecklistObj.assets.name !== null ? assetsChecklistObj.assets.name : "",
                        assetsType: assetsChecklistObj.assets !== null && assetsChecklistObj.assets.type !== null ? assetsChecklistObj.assets.type : "",
                        assetsDescription: assetsChecklistObj.assets !== null && assetsChecklistObj.assets.description !== null ? assetsChecklistObj.assets.description : "",
                        region:
                            assetsChecklistObj.region !== null
                                ? assetsChecklistObj.region.id
                                : "",
                        zoneIds:
                            assetsChecklistObj.zoneIds === null ? "" : assetsChecklistObj.zoneIds,
                        isActive:
                            assetsChecklistObj.isActive === null ? "" : assetsChecklistObj.isActive,
                        imageMandatory:
                            assetsChecklistObj.imageMandatory === null ? "" : assetsChecklistObj.imageMandatory,
                    };
                    return areaData;
                });
                dispatch(assetsChecklistSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};



export const getAssetsChecklistDetails = () => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL1,
        }).then(({ data, success }) => {
            if (success) {
                const { content } = data.data;
                const row = content.map((assetsChecklistObj, index) => {
                    let areaData = {
                        index: index + 1,
                        id: assetsChecklistObj.id === null ? "" : assetsChecklistObj.id,
                        outletId: assetsChecklistObj.outlet && assetsChecklistObj.outlet.id === null ? "" : assetsChecklistObj.outlet.id,
                        firmName: assetsChecklistObj.outlet && assetsChecklistObj.outlet.firmName === null ? "" : assetsChecklistObj.outlet.firmName,
                        firmOwnerName: assetsChecklistObj.outlet && assetsChecklistObj.outlet.ownerName === null ? "" : assetsChecklistObj.outlet.ownerName,
                        outLetType: assetsChecklistObj.outlet && assetsChecklistObj.outlet.outLetType && assetsChecklistObj.outlet.outLetType.name === null ? "" : assetsChecklistObj.outlet.outLetType.name,
                        assetsCheckListId: assetsChecklistObj.assetsCheckList && assetsChecklistObj.assetsCheckList.id === null ? "" : assetsChecklistObj.assetsCheckList.id,
                        assetsId: assetsChecklistObj.assetsCheckList && assetsChecklistObj.assetsCheckList.assets === null ? "" : assetsChecklistObj.assetsCheckList.assets.id,
                        assetsName: assetsChecklistObj.assetsCheckList && assetsChecklistObj.assetsCheckList.assets === null ? "" : assetsChecklistObj.assetsCheckList.assets.name,
                        questions: assetsChecklistObj.assetsCheckList && assetsChecklistObj.assetsCheckList.questions === null ? "" : assetsChecklistObj.assetsCheckList.questions,
                        answer: assetsChecklistObj.answer === null ? "" : assetsChecklistObj.answer,
                        image: assetsChecklistObj.image === null ? "" : assetsChecklistObj.image,
                        remark: assetsChecklistObj.remark === null ? "" : assetsChecklistObj.remark,
                        isActive: assetsChecklistObj.isActive === null ? "" : assetsChecklistObj.isActive,

                    };
                    return areaData;
                });
                dispatch(assetsChecklistDetailsSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};



export const getAssetsChecklistDetailsByOutletId = ({ outletId }) => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL1 + "/by-outlet?outletId=" + outletId,
        }).then(({ data, success }) => {
            if (success) {
                const row = data.data.map((assetsChecklistObj, index) => {
                    let areaData = {
                        index: index + 1,
                        id: assetsChecklistObj.id === null ? "" : assetsChecklistObj.id,
                        outletId: assetsChecklistObj.outlet && assetsChecklistObj.outlet.id === null ? "" : assetsChecklistObj.outlet.id,
                        firmName: assetsChecklistObj.outlet && assetsChecklistObj.outlet.firmName === null ? "" : assetsChecklistObj.outlet.firmName,
                        firmOwnerName: assetsChecklistObj.outlet && assetsChecklistObj.outlet.ownerName === null ? "" : assetsChecklistObj.outlet.ownerName,
                        outLetType: assetsChecklistObj.outlet && assetsChecklistObj.outlet.outLetType && assetsChecklistObj.outlet.outLetType.name === null ? "" : assetsChecklistObj.outlet.outLetType.name,
                        assetsCheckListId: assetsChecklistObj.assetsCheckList && assetsChecklistObj.assetsCheckList.id === null ? "" : assetsChecklistObj.assetsCheckList.id,
                        assetsId: assetsChecklistObj.assetsCheckList && assetsChecklistObj.assetsCheckList.assets === null ? "" : assetsChecklistObj.assetsCheckList.assets.id,
                        assetsName: assetsChecklistObj.assetsCheckList && assetsChecklistObj.assetsCheckList.assets === null ? "" : assetsChecklistObj.assetsCheckList.assets.name,
                        questions: assetsChecklistObj.assetsCheckList && assetsChecklistObj.assetsCheckList.questions === null ? "" : assetsChecklistObj.assetsCheckList.questions,
                        answer: assetsChecklistObj.answer === null ? "" : assetsChecklistObj.answer,
                        image: assetsChecklistObj.image === null ? "" : assetsChecklistObj.image,
                        remark: assetsChecklistObj.remark === null ? "" : assetsChecklistObj.remark,
                        isActive: assetsChecklistObj.isActive === null ? "" : assetsChecklistObj.isActive,

                    };
                    return areaData;
                });
                dispatch(assetsChecklistDetailsSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};



