import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { compose } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import React, { Component } from "react";
import { connect } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import CheckBoxSelectionTable from "../../components/Comman/RT/CheckBoxSelectionTable";
import { withRouter } from "../../components/withRouter";
import * as myConstClass from "../../config/messageconstant";
import { serverMsg } from "../../config/messageconstant";
import { apiPost } from "../../utils/api_service";
import CheckBoxSelectionTable1 from "../OrderList/CheckBoxSelectionTable";

import PropTypes from "prop-types";
import "../../CSS/app.min.css";
import "../../CSS/bootstrap.min.css";
import "../../CSS/custom.min.css";
import "../../CSS/mermaid.min.css";
import "../../CSS/slickDashboard.css";
import { OrderListFooterJsons } from "../../DynamicFormsJson/Transaction/orderListFooter";
import image4 from "../../Images/image4.png";
import image5 from "../../Images/image5.png";
import image6 from "../../Images/image6.png";
import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import {
  getOutletOutstandingForDistributor,
  getParkOrderForDistributor,
  getPaymentReceiptData,
  getPendingOrderForDistributor,
  getSecondaryBillHistory,
  getSecondaryPaymentDateWise,
} from "../../Slice/functionalDashboardDistributor.slice";
import {
  getOutletOrderDetailFromHeaderIds,
  setOutletOrderDetail,
} from "../../Slice/outletOrderDetail.slice";
import { ConvertAmount } from "../../components/Comman/ConvertValueWithDenominator";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { onlyNumber } from "../../components/Comman/Util/Validations";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import endpoint from "../../config/endpoints";
import BillingWithOffer from "../OrderList/BillingWithOffer";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
let orderColumns = [
  {
    title: "Sr.No.",
    name: "index",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "Outlet Name",
    name: "firmName",
    positionCenter: true,
    canSearch:true,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    title: "Order No",
    name: "orderNo",
    positionCenter: true,
    showInscreen: true,
    canSearch:true,
    alignCenter: "right",
  },
  {
    title: "Order Date",
    name: "orderDate",
    positionCenter: false,
    showInscreen: true,
    canSearch:true,
    alignCenter: "center",
  },
  {
    title: "Order Placed Time",
    name: "orderTime",
    canSearch:true,
    positionCenter: false,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "Product Name",
    name: "productName",
    canSearch:true,
    positionCenter: true,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    title: "UOM",
    name: "outletUom",
    canSearch:true,
    positionCenter: false,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "Rate",
    name: "rate",
    positionCenter: true,
    canSearch:true,
    showInscreen: true,
    alignCenter: "right",
  },
  {
    title: "Order Quantity",
    name: "billQty",
    positionCenter: true,
    showInscreen: true,
    canSearch:true,
    alignCenter: "right",
  },
  {
    title: "Bill Quantity",
    name: "orderQty",
    placeHolder: "Enter Bill Quantity",
    textFieldError: "percentageErr",
    canSearch:true,
    error: "Percentage",
    textField: true,
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
  },
  {
    title: "Free Quantity",
    name: "freeQty",
    placeHolder: "Enter Free Quantity",
    canSearch:true,
    textFieldError: "percentageErr",
    error: "Percentage",
    textField: true,
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
  },
  {
    title: "Discount Percentage",
    name: "distcountAmt",
    canSearch:true,
    placeHolder: "Enter Discount Percentage",
    textFieldError: "discountError",
    error: "Discount  Percentage",
    textField: true,
    positionCenter: false,
    showInscreen: true,
    alignCenter: "right",
  },
  {
    title: "UOM Conversion Value",
    name: "uomConversionRate",
    canSearch:true,
    placeHolder: "Enter UOM Conversion Value",
    textFieldError: "conversionValueErr",
    error: "ConversionValue",
    textField: true,
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
  },
  {
    title: "Total",
    name: "total",
    positionCenter: true,
    canSearch:true,
    showInscreen: true,
    alignCenter: "right",
  },
];
let tabList1 = [
  {
    label: "Outlet Outstanding",
    
    allyProps: 0,
    columns: [
      {
        name: "index",
        title: "Sr. No.",
        width: "10%",
        alignCenter: "center",
        showInscreen: true,
      },
      {
        title: "Bill No",
        name: "billNo",
        alignCenter: "center",
        canSearch:true,
        showInscreen: true,
      },
      {
        title: "Bill Date",
        name: "billDate",
        canSearch:true,
        alignCenter: "center",
        showInscreen: true,
      },
      {
        title: "Remaining Amt",
        canSearch:true,
        name: "remainingAmt",
        alignCenter: "right",
        showInscreen: true,
      },
      {
        title: "Action",
        name: "action",
        alignCenter: "center",
        showInscreen: true,
      },
    ],
    isActionColActive: true,
    value: "currentOfferList",
  },
  {
    label: "Date-Wise Outstanding",
    allyProps: 1,

    columns: [
      {
        name: "index",
        title: "Sr. No.",
        width: "10%",
        alignCenter: "center",
        showInscreen: true,
      },
      {
        title: "Bill No",
        canSearch:true,
        name: "billNo",
        alignCenter: "center",
        showInscreen: true,
      },
      {
        title: "Bill Date",
        name: "billDate",
        canSearch:true,
        alignCenter: "center",
        showInscreen: true,
      },
      {
        title: "Remaining Amt",
        name: "remainingAmt",
        canSearch:true,
        alignCenter: "right",
        showInscreen: true,
      },
      {
        title: "Action",
        name: "action",
        alignCenter: "center",
        showInscreen: true,
      },
    ],
    isActionColActive: true,
    value: "futureOfferList",
  },
  {
    label: "Payment Receipt",
    allyProps: 2,

    columns: [
      {
        name: "index",
        title: "Sr. No.",
        width: "10%",
        alignCenter: "center",
        showInscreen: true,
      },
      {
        title: "Amount",
        name: "amt",
        alignCenter: "center",
        canSearch:true,
        showInscreen: true,
      },
      {
        title: "Date",
        name: "date",
        canSearch:true,
        alignCenter: "center",
        showInscreen: true,
      },
      {
        title: "Outlet Name",
        name: "outletName",
        alignCenter: "right",
        canSearch:true,
        showInscreen: true,
      },
      {
        title: "Transaction No",
        canSearch:true,
        name: "transactionNo",
        alignCenter: "center",
        showInscreen: true,
      }
    ],
    isActionColActive: true,
    value: "futureOfferList",
  },
];

let tabColumns = [
  {
    name: "index",
    title: "Sr. No.",
    width: "10%",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    align: "center",
  },
  {
    title: "Outlet Name",
    name: "outletName",
    positionCenter: false,
    canSearch:true,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "left",
  },
  {
    title: "Order No",
    name: "orderNo",
    width: "15%",
    positionCenter: true,
    showInscreen: true,
    canSearch:true,
    alignCell: "center",
    align: "center",
  },
  {
    title: "Order Date",
    name: "orderDate",
    width: "15%",
    positionCenter: true,
    showInscreen: true,
    canSearch:true,
    alignCell: "center",
    align: "center",
  },
  {
    title: "Action",
    name: "action",
    positionCenter: true,
    showInscreen: true,
    alignCell: "center",
    align: "center",
  },
];
let billColumns = [
  {
    name: "index",
    title: "Sr. No.",
    width: "10%",
    positionCenter: true,
    showInscreen: true,
    alignCell: "center",
    
    align: "center",
  },

  {
    title: "Bill No",
    name: "billNo",
    width: "15%",
    positionCenter: true,
    showInscreen: true,
    canSearch:true,
    alignCell: "center",
    align: "center",
  },
  {
    title: "Bill Date",
    name: "billDate",
    width: "15%",
    positionCenter: true,
    canSearch:true,
    showInscreen: true,
    alignCell: "center",
    align: "center",
  },
  {
    title: "Total",
    name: "totalAmt",
    canSearch:true,
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "right",
  },
  {
    title: "Action",
    name: "action",
    positionCenter: true,
    
    showInscreen: true,
    alignCell: "center",
    align: "center",
  },
];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

class FunctionalDashboardDistributor extends Component {

  state = {
    showFeedback: false,
    showPreview: false,

    curDate: new Date(),
    dob: "",
    dobDay: "",
    dobMonth: "",
    currenDate: "",
    currentMonth: "",
    timeTableList: [],
    subject: "",
    batch: "",
    yearDetail: "",
    semester: "",
    division: "",
    pieChartData: [],
    isLoading: false,
    showLoader: false,
    profile: "",
    nameFirstLetter: "",
    showZone: false,
    showArea: false,
    date: "",
    saleDate: "",
    productArea: "",
    productZone: "",
    outletArea: "",
    outletZone: "",
    outletDate: "",
    monthDate: "",
    yearDate: "",
    type: "",
    saleMonth: dayjs(),
    tabValue: 0,
    tabValuePayment: 0,
    showOrderGeneration: false,
    showBillGeneration: false,
    showPaymentGeneration: true,
    setList: [],
    selection: [],
    showDashboard: true,
    offerBillData: [],
    showBilling: false,
    showOrderTable: false,
    billDate: "",
    billDiscountPercentage: "",

    saleYear: "",
    month: "",
    year: "",
    searchValue: "",
    searchValueForZone: "",
    searchValueForSalesRegion: "",
    searchValueForZoneDetails: "",
    searchValueForAreaDetails: "",
    searchValuePersonWiseSales: "",
    searchValueForProduct: "",
    searchValueForOutlets: "",
    regionListFromYearAndMonthData: [],
    regionListFromYearAndMonthDataForZone: [],
    regionListFromYearAndMonthDataForSalesRegion: [],
    zoneDetailsSalesData: [],
    zoneDetailsRegionwiseData: [],
    salesGraphData: [],
    salesMonthData: [],
    areaDetailsData: [],
    personWiseSalesData: [],
    topPerformingProductData: [],
    topPerformingOutletData: [],
    salesTeamData: [],
    formErrors: {},
    graphList: [],
    radioValue: [],
    lineGraphData: [],
    salesGraphList: [],
    salesRegionName: "",
   
  };

 
  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };


  orderGenerationHandler = (e, index) => {
    console.log(index);
    if (index == 0) {
      this.setState({
        showOrderGeneration: true,
        showBillGeneration: false,
        showPaymentGeneration: false,
      });
    } else if (index == 1) {
      this.setState({
        showOrderGeneration: false,
        showBillGeneration: true,
        showPaymentGeneration: false,
      });
    } else {
      this.setState({
        showOrderGeneration: false,
        showBillGeneration: false,
        showPaymentGeneration: true,
      });
    }
  };
  rowViewData = (row) => {
    this.props.navigate("/order-history-view/" + row.id);
  };

  handledChange = (e, newValue) => {
    this.setState({
      tabValue: newValue,
      setList: [],
    });
  };

  clickHandler = () => {
    this.setState({
      showOrderTable: false,
    });
  };

  onCancelBilling = () => {
    this.setState({
      showBilling: false,
      showDashboard: true,
    });
    this.props.setShowDashbourd(true)
  };

  handledChangePayment = (e, newValue) => {
    this.setState({
      tabValuePayment: newValue,
    });
  };
  a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  getRow = (e) => {
    this.setState({ setList: e });
  };

  getSelectedList = (e) => {
    const { outletOrderDetail, setOutletOrderDetail } = this.props;
    this.setState({ selection: e });
    const rowList = outletOrderDetail.outletOrderDetail.map((rowData) => {
      const filterData = e.filter((id) => rowData.id == id);
      return {
        ...rowData,
        checked: filterData.length != 0 ? true : false,
      };
    });
    setOutletOrderDetail({ row: rowList });
  };

  orderHeaderHandler = () => {
    const { setList } = this.state;
    const { getOutletOrderDetailFromHeaderIds, showLoador } = this.props;
    showLoador({ loador: true });
    getOutletOrderDetailFromHeaderIds({ outletOrderHeaderIds: setList }).then(
      ({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({ showOrderTable: true });
        }
      }
    );
  };

  outletOrderDetails = ({ row }) => {
    const { setOutletOrderDetail } = this.props;
    setOutletOrderDetail({ row: row });
  };

  checkListValidation = (data) => {
    const { outletOrderDetail, showNotification } = this.props;
    const { productStockData } = this.state;
    const filterCheck = outletOrderDetail.outletOrderDetail.filter(
      (row) => row.checked
    );
    if (filterCheck.length != 0) {
      let error = false;
      const rowList = outletOrderDetail.outletOrderDetail.map((rowObj) => {
        if (rowObj.checked) {
          if (
            rowObj.orderQty.toString() == "" ||
            rowObj.freeQty.toString() == "" ||
            rowObj.distcountAmt.toString() == "" ||
            (+rowObj.distcountAmt >= 100 && rowObj.distcountAmt != 100) ||
            rowObj.uomConversionRate.toString() == ""
          ) {
            error = true;
          }
          if (rowObj.productStock > 0) {
            if (
              (rowObj.orderQty.toString() != "" &&
                (rowObj.orderQty.toString() == "0" ||
                  !onlyNumber.test(rowObj.orderQty.toString()))) ||
              (rowObj.uomConversionRate.toString() != "" &&
                (rowObj.uomConversionRate.toString() == "0" ||
                  !onlyNumber.test(rowObj.uomConversionRate.toString())))
            ) {
              error = true;
            }
          }

          rowObj = {
            ...rowObj,
            qtyError:
              rowObj.orderQty.toString() == ""
                ? 1
                : rowObj.orderQty.toString() != "" &&
                  !onlyNumber.test(rowObj.orderQty.toString())
                  ? 3
                  : 0,
            freeQtyError: rowObj.freeQty.toString() == "" ? 1 : 0,
            discountError:
              rowObj.distcountAmt.toString() == ""
                ? 1
                : rowObj.distcountAmt != 100 && +rowObj.distcountAmt >= 100
                  ? 2
                  : 0,
            conversionValueErr:
              rowObj.uomConversionRate.toString() == ""
                ? 1
                : rowObj.uomConversionRate.toString() != "" &&
                  rowObj.uomConversionRate.toString() == "0"
                  ? 2
                  : rowObj.uomConversionRate.toString() != "" &&
                    !onlyNumber.test(rowObj.uomConversionRate.toString())
                    ? 3
                    : 0,
          };
        }

        return rowObj;
      });
      this.props.setOutletOrderDetail({ row: rowList });
      if (!error) {
        this.saveBillConfigtation(data);
      }
    } else {
      showNotification({
        msg: myConstClass.checkedListEmptyMsg,
        severity: "error",
      });
    }
  };
  saveBillConfigtation = (data) => {
    const { showNotification, showLoador } = this.props;
    const { flag, productStockData } = this.state;
    this.setState({
      showCheckRecord: false,
      billDate: data.date,
      billDiscountPercentage: data.discount,
    });
    let billingCheckedList = [];
    let stockList = [];
    let dataToSave = {};
    if (flag) {
      billingCheckedList =
        this.props.outletOrderDetail.outletOrderDetail.filter(
          (row) => row.checked && +row.orderQty > 0
        );
      stockList = productStockData.productStocks.map((stockObject) => {
        let dataToReturn = {
          productId: stockObject.product.id,
          stock: stockObject.secondaryClosingQty,
        };
        return dataToReturn;
      });
      localStorage.setItem("stockList", JSON.stringify(stockList));
    } else {
      billingCheckedList =
        this.props.outletOrderDetail.outletOrderDetail.filter(
          (row) => row.checked
        );
    }

    let billingDataToSave = billingCheckedList.map((billingObject) => {
      let billData = {
        outletOrderDetail: {
          id: billingObject.id,
        },
        outLet: {
          id: billingObject.outlet.id,
        },
        product: {
          id: billingObject.product.id,
        },
        qty: +billingObject.orderQty,
        freeQty: +billingObject.freeQty,
        itemDiscPer: +billingObject.distcountAmt,
        conversionValue: +billingObject.uomConversionRate,
      };
      return billData;
    });

    dataToSave = {
      dtoOutletBillDetails: billingDataToSave,
      productAndStocks: stockList,
    };
    showLoador({ loador: true });
    apiPost({
      url:
        endpoint.outletBillHeader +
        "/generate-with-applied-offer?billDate=" +
        data.date +
        "&billDiscountPercentage=" +
        data.discount,
      postBody: dataToSave,
    }).then(({ data, success }) => {
      showLoador({ loador: false });
      if (success) {
        if (Object.keys(data).length !== 0 && data.data.length !== 0) {
          // this.handleCloses();
          let newObjectToSave = data.data.map((billObject) => {
            let newBillObject = {
              ...billObject.outletBillsAfterApplyingOffers,
              outletBillDetails:
                billObject.outletBillsAfterApplyingOffers.outletBillDetails.map(
                  (orderObject) => {
                    let productWithoutBillObject =
                      billObject.outletBillHeader.outletBillDetails.filter(
                        (row) => row.id == orderObject.id
                      );
                    if (productWithoutBillObject.length !== 0) {
                      orderObject = {
                        ...orderObject,
                        taxableAmtWithoutOffer:
                          productWithoutBillObject[0].taxableAmt,
                        totalAmtWithoutOffer:
                          productWithoutBillObject[0].totalAmt,
                      };
                      return orderObject;
                    } else {
                      orderObject = {
                        ...orderObject,
                        taxableAmtWithoutOffer: 0.0,
                        totalAmtWithoutOffer: 0.0,
                      };
                      return orderObject;
                    }
                  }
                ),
            };
            billObject = {
              ...billObject,
              outletBillsAfterApplyingOffers: newBillObject,
            };
            return billObject;
          });

          this.setState({
            showBilling: true,
            showDashboard: false,
            offerBillData: newObjectToSave,
          });
          this.props.setShowDashbourd(false)
        }
      } else {
        showNotification({
          msg: saveFailedMsg,
          severity: "error",
        });
      }
    });
  };


  async componentDidMount() {
    const {
      getPendingOrderForDistributor,
      getParkOrderForDistributor,
      getSecondaryBillHistory,
      getOutletOutstandingForDistributor,
      showLoador,
      getSecondaryPaymentDateWise,
      getPaymentReceiptData
    } = this.props;
    if (navigator.onLine) {

      showLoador({ loador: true });
      await getOutletOutstandingForDistributor().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      await getPendingOrderForDistributor().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getParkOrderForDistributor().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getSecondaryBillHistory().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getSecondaryPaymentDateWise({
        fromDate: "",
        toDate: ""
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getPaymentReceiptData().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

 
  getFilteredTableData = (tableColumns,tableData) => {
    const { searchValue } = this.state;
    const columsFil = tableColumns.filter((item) => {
      return item.canSearch;
    });
    return tableData.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
        return null;
      });
      return isValid;
    });
  };

  render() {
    const {
      dashboardData,
      dashboardData2,
      stockStakeHolderTypeList,
      zoneList,
      areaList,
      functionalDashboardDistributorData,
      outletOrderDetail,
    } = this.props;
    const {
           tabValue,
      tabValuePayment,
      showOrderGeneration,
      showBillGeneration,
      showPaymentGeneration,
      setList,
      selection,
      showDashboard,
      showBilling,
      showOrderTable,
      offerBillData,
      billDate,
      
    } = this.state;
    const { typeError, dateError } = this.state.formErrors;
    console.log(functionalDashboardDistributorData);
    return (
      <>
        {showDashboard && (
          <>


            <div
              className="row"
              style={{
                gap: "26px",
                paddingBottom: 10,
                padding: "12px",
              }}
            >
              {/* Card 1 */}
              <div
                className="Frame427319159"
                style={{
                  width: "32%",
                  height: "auto",
                  paddingTop: 22,
                  paddingBottom: 22,
                  background:
                    showOrderGeneration == true
                      ? "#ffe19cd1"
                      : "rgba(253, 126, 20, 0.10)",
                  borderRadius: 8,
                  overflow: "hidden",
                  border:
                    showOrderGeneration == true ? "1px solid" : "",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "inline-flex",
                }}
                onClick={(e) => this.orderGenerationHandler(e, 0)}
              >
                <div
                  className="Frame11262"
                  style={{
                    flex: "1 1 0",
                    alignSelf: "stretch",
                    paddingLeft: 16,
                    paddingRight: 16,
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    display: "inline-flex",
                  }}
                >
                  <div
                    className="Frame427319153"
                    style={{
                      width: "60%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      gap: 8,
                      display: "inline-flex",
                    }}
                  >
                    <img
                      className="Image4"
                      style={{ width: 32, height: 32 }}
                      src={image4}
                    />

                    <div
                      className="TotalSales"
                      style={{
                        color: "black",
                        fontSize: 22,
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        wordWrap: "break-word",
                        top: 5,
                      }}
                    >
                      Order Generation
                    </div>
                  </div>
                  <div
                    className="Frame427319116"
                    style={{
                      // alignSelf: "stretch",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      display: "inline-flex",
                    }}
                  >
                    <div
                      className="Cr"
                      style={{
                        color: "black",
                        fontSize: 28,
                        fontFamily: "Poppins",
                        fontWeight: "500",
                        textTransform: "capitalize",
                        lineHeight: 1.8,
                        wordWrap: "break-word",
                      }}
                    >
                      {ConvertAmount({
                        amount:
                          functionalDashboardDistributorData
                            ?.orderCounts.orderBillNotGenerateAmt,
                      })}
                    </div>
                    <div
                      className="Frame427319115"
                      style={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: 2,
                        display: "inline-flex",
                      }}
                    >
                      <div
                        style={{
                          color: "#43C622",
                          fontSize: 16,
                          fontFamily: "Poppins",
                          fontWeight: "400",
                          textTransform: "capitalize",
                          wordWrap: "break-word",
                        }}
                      >
                        BILL NOT GENERATED
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Card 1  end*/}

              {/* Card 2 */}

              <div
                className="Frame427319162"
                style={{
                  width: "32%",
                  height: "auto",
                  paddingTop: 22,
                  paddingBottom: 22,
                  background:
                    showBillGeneration == true
                      ? "#ffe19cd1"
                      : "rgba(253, 126, 20, 0.10)",
                  borderRadius: 8,
                  overflow: "hidden",
                  border: showBillGeneration == true ? "1px solid" : "",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "inline-flex",
                }}
                onClick={(e) => this.orderGenerationHandler(e, 1)}
              >
                <div
                  className="Frame11262"
                  style={{
                    flex: "1 1 0",
                    alignSelf: "stretch",
                    paddingLeft: 16,
                    paddingRight: 16,
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    display: "inline-flex",
                  }}
                >
                  <div
                    className="Frame427319153"
                    style={{
                      width: "60%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      gap: 8,
                      display: "inline-flex",
                    }}
                  >
                    <img
                      className="Image5"
                      style={{ width: 32, height: 32 }}
                      src={image5}
                    />
                    <div
                      className="TotalSales"
                      style={{
                        color: "black",
                        fontSize: 22,
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        wordWrap: "break-word",
                      }}
                    >
                      Bill Generation{" "}
                    </div>
                  </div>
                  <div
                    className="Frame427319116"
                    style={{
                      // alignSelf: "stretch",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      display: "inline-flex",
                    }}
                  >
                    <div
                      style={{
                        color: "black",
                        fontSize: 28,
                        fontFamily: "Poppins",
                        fontWeight: "500",
                        textTransform: "capitalize",
                        lineHeight: 1.8,
                        wordWrap: "break-word",
                      }}
                    >
                      {ConvertAmount({
                        amount:
                          functionalDashboardDistributorData
                            ?.orderCounts.currentMonthBillAmt,
                      })}
                    </div>
                    <div
                      className="Frame427319115"
                      style={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: 2,
                        display: "inline-flex",
                      }}
                    >
                      <div
                        style={{
                          color: "#43C622",
                          fontSize: 16,
                          fontFamily: "Poppins",
                          fontWeight: "400",
                          textTransform: "capitalize",
                          wordWrap: "break-word",
                        }}
                      >
                        CURRENT MONTH BILL AMT
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Card 2 end */}

              {/* Card 3 */}

              <div
                className="Frame427319160"
                style={{
                  width: "32%",
                  height: "auto",
                  paddingTop: 22,
                  paddingBottom: 22,
                  background:
                    showPaymentGeneration == true
                      ? "#ffe19cd1"
                      : "rgba(253, 126, 20, 0.10)",
                  borderRadius: 8,
                  overflow: "hidden",
                  border:
                    showPaymentGeneration == true ? "1px solid" : "",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "inline-flex",
                }}
                onClick={(e) => this.orderGenerationHandler(e, 2)}
              >
                <div
                  className="Frame11262"
                  style={{
                    flex: "1 1 0",
                    alignSelf: "stretch",
                    paddingLeft: 16,
                    paddingRight: 16,
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    display: "inline-flex",
                  }}
                >
                  <div
                    className="Frame427319153"
                    style={{
                      width: "60%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      gap: 8,
                      display: "inline-flex",
                    }}
                  >
                    <img
                      className="Image5"
                      style={{ width: 32, height: 32 }}
                      src={image6}
                    />
                    <div
                      className="TotalSales"
                      style={{
                        color: "black",
                        fontSize: 22,
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        wordWrap: "break-word",
                      }}
                    >
                      Payment
                    </div>
                  </div>
                  <div
                    className="Frame427319116"
                    style={{
                      // alignSelf: "stretch",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      display: "inline-flex",
                    }}
                  >
                    <div
                      style={{
                        color: "black",
                        fontSize: 28,
                        fontFamily: "Poppins",
                        fontWeight: "500",
                        textTransform: "capitalize",
                        lineHeight: 1.8,
                        wordWrap: "break-word",
                      }}
                    >
                      {ConvertAmount({
                        amount:
                          functionalDashboardDistributorData
                            ?.orderCounts.totalOutStandingAmt,
                      })}{" "}
                      /{" "}
                      {ConvertAmount({
                        amount:
                          functionalDashboardDistributorData
                            ?.orderCounts.todaysCollectionAmt,
                      })}
                    </div>
                    <div
                      className="Frame427319115"
                      style={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: 2,
                        display: "inline-flex",
                      }}
                    >
                      <div
                        style={{
                          color: "#43C622",
                          fontSize: 16,
                          fontFamily: "Poppins",
                          fontWeight: "400",
                          textTransform: "capitalize",
                          wordWrap: "break-word",
                        }}
                      >
                        TOTAL OUTSTANDING/ TODAY'S COLLECTION
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Card 3 end */}
            </div>



            {showOrderGeneration && (
              <>
                <br />
                <LandingScreenHeader
                  screenTitle={""}
                  showSearchBox={true}
                  onOpenFilter={() => {
                    this.props.onOpenFilter(4)
                  }}
                  showTable={true}
                  showFilter={tabValue == 0 ? true : false}
                  showPdfDownload={false}
                  showExcelDownload={false}
                  onSearch={this.onSearch}
                />

                <Box sx={{ width: "99.5%" }}>
                  <Tabs
                    onChange={this.handledChange}
                    value={tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    sx={{
                      border: "2px solid black",
                      borderColor: "#eded9b",
                      borderRadius: "8px",
                      backgroundColor: "#ffe19cd1",
                    }}
                  >
                    <Tab
                      label={
                        "Pending outlet order (" +
                        functionalDashboardDistributorData?.pendingOrder
                          .length +
                        ")"
                      }
                      {...this.a11yProps(0)}
                    />
                    <Tab
                      label={
                        "Park order (" +
                        functionalDashboardDistributorData?.parkedOrder
                          .length +
                        ")"
                      }
                      {...this.a11yProps(1)}
                    />
                  </Tabs>
                  <SwipeableViews
                    index={tabValue}
                    onChangeIndex={this.handleChange}
                  >
                    <CheckBoxSelectionTable
                      
                      rows={this.getFilteredTableData(tabColumns,functionalDashboardDistributorData.pendingOrder
                        .length !== 0
                        ? functionalDashboardDistributorData.pendingOrder
                        : []) }
                
                      columns={tabColumns}
                      selection={setList}
                      showView={true}
                      rowViewData={this.rowViewData}
                      isActionColActive={true}
                      onSelectionChange={this.getRow}
                    />
                    <CheckBoxSelectionTable
                      
                      rows={this.getFilteredTableData(tabColumns,functionalDashboardDistributorData.parkedOrder
                        .length !== 0
                        ? functionalDashboardDistributorData.parkedOrder
                        : []) }
                      columns={tabColumns}
                      selection={setList}
                      showView={true}
                      rowViewData={this.rowViewData}
                      isActionColActive={true}
                      onSelectionChange={this.getRow}
                    />
                  </SwipeableViews>
                </Box>
                <br />
                <Grid
                  container
                  justifyContent="flex-end"
                  item
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <button
                    onClick={this.orderHeaderHandler}
                    type="button"
                    className="btn btn-primary waves-effect waves-light btn-lg"
                  >
                    Proceed
                  </button>
                </Grid>
                {showOrderTable && (
                  <>
                    <Paper
                      sx={{
                        p: 2,
                        borderRadius: 2,
                      }}
                      elevation={0}
                    >
                      <BootstrapDialog
                        aria-labelledby="customized-dialog-title"
                        open={true}
                        fullWidth
                        maxWidth="200% !important"
                      >
                        <BootstrapDialogTitle
                          id="customized-dialog-title"
                          onClose={this.clickHandler}
                        >
                          <br />
                          <CheckBoxSelectionTable1
                            selection={selection}
                            onSelectionChange={this.getSelectedList}
                             rows={this.getFilteredTableData(orderColumns,outletOrderDetail.outletOrderDetail) }
                
                            columns={orderColumns}
                            setList={this.outletOrderDetails}
                            isActionColActive={false}
                          // flag={flag}
                          />
                          <br />
                          <DynamicFormWithoutSave
                            showBackToList={false}
                            // dynamicMasterData={dynamicMasterData}
                            showSaveBtn={
                              OrderListFooterJsons.showSaveBtn
                            }
                            showTitle={OrderListFooterJsons.showTitle}
                            screenTitle={
                              OrderListFooterJsons.screenTitle
                            }
                            fieldMeta={OrderListFooterJsons.fieldMeta}
                            showCancel={OrderListFooterJsons.showCancel}
                            apiBaseURL={OrderListFooterJsons.apiBaseURL}
                            showSaveNextBtn={
                              OrderListFooterJsons.showSaveNextBtn
                            }
                            saveBtnText={"Proceed"}
                            onSave={this.checkListValidation}
                          />
                        </BootstrapDialogTitle>
                      </BootstrapDialog>
                    </Paper>
                  </>
                )}
              </>
            )}
            {showBillGeneration && (
              <>
                <br />
                <LandingScreenHeader
                  screenTitle={""}
                  showSearchBox={true}
                  onOpenFilter={() => {
                    this.props.onOpenFilter(2)
                  }}
                  showTable={true}
                  showFilter={true}
                  showPdfDownload={false}
                  showExcelDownload={false}
                  onSearch={this.onSearch}
                />

                <DynamicTable
                  
                  data={this.getFilteredTableData(billColumns,functionalDashboardDistributorData?.secondaryBill) }
                            
                  tableHead={billColumns}
                  isActionColActive={true}
                  showPegination={false}
                  showView={true}
                  rowViewData={this.rowViewData}
                />

              </>
            )}

            {showPaymentGeneration && (
              <>
                <LandingScreenHeader
                  screenTitle={""}
                  showSearchBox={true}
                  onOpenFilter={() => {
                    this.props.onOpenFilter(tabValuePayment == 2 ? 5 : 6)
                  }}
                  showTable={true}
                  showFilter={tabValuePayment == 0 ? false : true}
                  showPdfDownload={false}
                  showExcelDownload={false}
                  onSearch={this.onSearch}
                />
                <Box sx={{ width: "100%" }}>
                  <Tabs
                    onChange={this.handledChangePayment}
                    value={tabValuePayment}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    sx={{
                      border: "2px solid black",
                      borderColor: "#eded9b",
                      borderRadius: "8px",
                      backgroundColor: "#dae9e9",
                    }}
                  >
                    <Tab
                      label={
                        tabList1[0].label +
                        " (" +
                        functionalDashboardDistributorData?.paymentData
                          .length +
                        ")"
                      }
                      {...this.a11yProps(tabList1[0].a11yProps)}
                    />
                    <Tab
                      label={
                        tabList1[1].label +
                        " (" +
                        functionalDashboardDistributorData?.secondaryPaymentDateWise
                          .length +
                        ")"
                      }
                      {...this.a11yProps(tabList1[1].a11yProps)}
                    />
                    <Tab
                      label={
                        tabList1[2].label +
                        " (" +
                        functionalDashboardDistributorData?.paymentReceiptData
                          .length +
                        ")"
                      }
                      {...this.a11yProps(tabList1[2].a11yProps)}
                    />
                  </Tabs>
                  <SwipeableViews
                    index={tabValuePayment}
                    onChangeIndex={this.handleChange}
                  >
                    {tabList1.map((data) => {
                      return (
                        <>
                          <TabPanel
                            value={tabValuePayment}
                            index={data.allyProps}
                          >
                            <DynamicTable
                              
                              data={this.getFilteredTableData(data.columns ,tabValuePayment == 0
                                ? functionalDashboardDistributorData?.paymentData
                                : tabValuePayment == 1
                                  ? functionalDashboardDistributorData?.secondaryPaymentDateWise
                                  : functionalDashboardDistributorData?.paymentReceiptData) }
                      
                              tableHead={data.columns}
                              isActionColActive={data.allyProps != 2 ? true : false}
                              showPegination={false}
                              showView={true}
                              rowViewData={this.rowViewData}
                            />
                          </TabPanel>
                        </>
                      );
                    })}
                  </SwipeableViews>
                </Box>
              </>
            )}

          </>
        )}
        {showBilling && (
          <>
            <BillingWithOffer
              offerBillData={offerBillData}
              onCancel={this.onCancelBilling}
              billDate={billDate}
              // flag={flag}
              redirect={true}
            />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
  dashboardData: state.adminDashboard,
  dashboardData2: state.adminDashboard,
  stockStakeHolderTypeList: state.stockStakeHolderType,
  zoneList: state.zone,
  areaList: state.area,

  functionalDashboardDistributorData: state.functionalDashboardDistributor,
  outletOrderDetail: state.outletOrderDetail,
});

const mapDispatchToProps = {
  showLoador,
  showNotification,
  getPendingOrderForDistributor,
  getParkOrderForDistributor,
  getOutletOutstandingForDistributor,
  setOutletOrderDetail,
  getOutletOrderDetailFromHeaderIds,
  getSecondaryBillHistory,
  getSecondaryPaymentDateWise,
  getPaymentReceiptData
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(FunctionalDashboardDistributor);