import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  regionAndTarget: [],
  yearForRegionAndTarget: [],
  monthsFromYearId: [],
  headerListForRegionAndTarget: [],
};
let URL = endpoints.regionAndTarget;
let URL1 = endpoints.target;
const regionAndTargetSlice = createSlice({
  name: "regionAndTarget",
  initialState,
  reducers: {
    regionAndTargetSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        regionAndTarget: row,
        isFetch: true,
      };
    },
    yearForRegionAndTargetSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        yearForRegionAndTarget: row,
      };
    },

    monthsFromYearIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        monthsFromYearId: row,
      };
    },

    headerListForRegionAndTargetSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        headerListForRegionAndTarget: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  regionAndTargetSuccess,
  yearForRegionAndTargetSuccess,
  monthsFromYearIdSuccess,
  headerListForRegionAndTargetSuccess,

  resetState,
} = regionAndTargetSlice.actions;

export default regionAndTargetSlice.reducer;

export const getRegionAndTarget = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((regionAndTargetObj, index) => {
          let regionAndTargetData = {
            index: index + 1,
            id: regionAndTargetObj.id === null ? "" : regionAndTargetObj.id,
            region:
              regionAndTargetObj.region === null
                ? ""
                : regionAndTargetObj.region,
            yearMaster:
              regionAndTargetObj.yearMaster === null
                ? ""
                : regionAndTargetObj.yearMaster,
            jan: regionAndTargetObj.jan === null ? "" : regionAndTargetObj.jan,
            feb: regionAndTargetObj.feb === null ? "" : regionAndTargetObj.feb,
            march:
              regionAndTargetObj.march === null ? "" : regionAndTargetObj.march,
            april:
              regionAndTargetObj.april === null ? "" : regionAndTargetObj.april,
            may: regionAndTargetObj.may === null ? "" : regionAndTargetObj.may,
            jun: regionAndTargetObj.jun === null ? "" : regionAndTargetObj.jun,
            july:
              regionAndTargetObj.july === null ? "" : regionAndTargetObj.july,
            aug: regionAndTargetObj.aug === null ? "" : regionAndTargetObj.aug,
            sep: regionAndTargetObj.sep === null ? "" : regionAndTargetObj.sep,
            oct: regionAndTargetObj.oct === null ? "" : regionAndTargetObj.oct,
            nov: regionAndTargetObj.nov === null ? "" : regionAndTargetObj.nov,
            dec: regionAndTargetObj.dec === null ? "" : regionAndTargetObj.dec,
            insertDateTime:
              regionAndTargetObj.insertDateTime === null
                ? ""
                : regionAndTargetObj.insertDateTime,
          };
          return regionAndTargetData;
        });
        dispatch(regionAndTargetSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getYearForRegionAndTarget =
  ({ yearId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-year?yearId=" + yearId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.regionTargetPercentages.map(
            (yearObject, index) => {
              let yearData = {
                index: index + 1,
                id: yearObject.id === null ? "" : yearObject.id,
                region: yearObject.region === null ? "" : yearObject.region,
                regionId:
                  yearObject.region !== null && yearObject.region.id
                    ? yearObject.region.id
                    : "",
                name:
                  yearObject.region !== null && yearObject.region.name
                    ? yearObject.region.name
                    : "",
                yearMaster:
                  yearObject.yearMaster === null ? "" : yearObject.yearMaster,
                jan: yearObject.jan === null ? "" : yearObject.jan + "%",
                feb: yearObject.feb === null ? "" : yearObject.feb + "%",
                march: yearObject.march === null ? "" : yearObject.march + "%",
                april: yearObject.april === null ? "" : yearObject.april + "%",
                may: yearObject.may === null ? "" : yearObject.may + "%",
                jun: yearObject.jun === null ? "" : yearObject.jun + "%",
                july: yearObject.july === null ? "" : yearObject.july + "%",
                aug: yearObject.aug === null ? "" : yearObject.aug + "%",
                sep: yearObject.sep === null ? "" : yearObject.sep + "%",
                oct: yearObject.oct === null ? "" : yearObject.oct + "%",
                nov: yearObject.nov === null ? "" : yearObject.nov + "%",
                dec: yearObject.dec === null ? "" : yearObject.dec + "%",
                insertDateTime:
                  yearObject.insertDateTime === null
                    ? ""
                    : yearObject.insertDateTime,
              };
              return yearData;
            }
          );
          dispatch(yearForRegionAndTargetSuccess({ row }));
          dispatch(
            headerListForRegionAndTargetSuccess({
              row: data.data.dateSequences,
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getMonthsFromYearId =
  ({ yearId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL1 + "/get-month-year-by-year?yearId=" + yearId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((yearObject, index) => {
            let yearData = {
              index: index + 1,
              name: yearObject.monthYear === null ? "" : yearObject.monthYear,
              month: yearObject.month === null ? "" : yearObject.month,
              year: yearObject.year === null ? "" : yearObject.year,
              order:
                yearObject.order !== null && yearObject.order.id
                  ? yearObject.order
                  : "",
              percentage: "",
            };
            return yearData;
          });
          dispatch(monthsFromYearIdSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
