import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import { ComapnayCategoryConfigurationColumns } from "../../tableColumns/table-columns";
import { companyCategoryConfigurationJson } from "../../DynamicFormsJson/Transaction/companyCategoryConfiguration";
import { getCompanyCategoryList } from "../../Slice/companyCategoryConfiguration.slice";

class CompanyCategoryConfigurationTable extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { companyCategoryList, getCompanyCategoryList } = this.props;
        return (
            <>
                <DynamicMainScreen
                    formPath={companyCategoryConfigurationJson.formPath}
                    screenTitle={companyCategoryConfigurationJson.screenTitle}
                    showViewButtonInTable={companyCategoryConfigurationJson.formPathView}
                    fieldMeta={companyCategoryConfigurationJson.fieldMeta}
                    showAddButton={companyCategoryConfigurationJson.showAddButton}
                    tableColumns={ComapnayCategoryConfigurationColumns}
                    tableData={companyCategoryList?.companyCategoryConfiguration}
                    getTableData={getCompanyCategoryList}
                    apiBaseURL={companyCategoryConfigurationJson.apiBaseURL}
                    baseIdColumn={companyCategoryConfigurationJson.baseIdColumn}
                    showEditIcon={true}
                    showPdfDownload={false}
                    showExcelDownload={false}
                    formPathView={companyCategoryConfigurationJson.formPathView}

                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    companyCategoryList: state.companyCateoryConfiguration,
});
const mapDispatchToProps = {
    getCompanyCategoryList,
};
export default connect(mapStateToProps, mapDispatchToProps)(CompanyCategoryConfigurationTable);
