import React from 'react';

const NumberToWords = ({ amount }) => {
  const convertNumberToWords = (number) => {
    const units = ['', 'ONE', 'TWO', 'THREE', 'FOUR', 'FIVE', 'SIX', 'SEVEN', 'EIGHT', 'NINE'];
    const teens = ['', 'ELEVEN', 'TWELVE', 'THIRTEEN', 'FOURTEEN', 'FIFTEEN', 'SIXTEEN', 'SEVENTEEN', 'EIGHTEEN', 'NINETEEN'];
    const tens = ['', 'TEN', 'TWENTY', 'THIRTY', 'FORTY', 'FIFTY', 'SIXTY', 'SEVENTY', 'EIGHTY', 'NINETY'];

    const convert = (num) => {
      if (num < 10) {
        return units[num];
      } else if (num < 20) {
        return teens[num - 10];
      } else if (num < 100) {
        const unit = num % 10;
        return (tens[Math.floor(num / 10)] + (unit ? ' ' + units[unit] : '')).trim();
      } else {
        return (units[Math.floor(num / 100)] + ' HUNDRED ' + convert(num % 100)).trim();
      }
    };

    const convertCents = (cents) => {
      if (cents === 0) {
        return '';
      } else if (cents < 10) {
        return ' AND ' + units[cents] + ' PAISA ONLY';
      } else if (cents < 20) {
        return ' AND ' + teens[cents - 10] + ' PAISA ONLY';
      } else {
        return ' AND ' + (tens[Math.floor(cents / 10)] + ' ' + units[cents % 10] + ' PAISA ONLY').trim();
      }
    };

    const convertAmount = (num, unit) => {
      if (num === 0) {
        return '';
      }
      return convert(num) + ' ' + unit + (num !== 1 ? '' : '');
    };

    const crore = Math.floor(number / 10000000);
    const lakh = Math.floor((number % 10000000) / 100000);
    const thousands = Math.floor((number % 100000) / 1000);
    const remaining = Math.floor(number % 1000);
    const cents = Math.round((number - Math.floor(number)) * 100);

    return (
      convertAmount(crore, 'CRORE') + ' ' +
      convertAmount(lakh, 'LAKH') + ' ' +
      convertAmount(thousands, 'THOUSAND') + ' ' +
      convertAmount(remaining, '') +
      convertCents(cents)
    ).trim();
  };

  const words = convertNumberToWords(amount);

  return words
};

const App = (amount) => {
//   const amount = 6830321.36; // Example amount in crores, lakhs, and rupees

  return (
    <div>
    
      <NumberToWords amount={amount} />
    </div>
  );
};

export default App;
