import CloseIcon from "@mui/icons-material/Close";
import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { LabelCompo } from "../../components/Comman/Label";
import { onlyNumber } from "../../components/Comman/Util/Validations";
import * as myConstClass from "../../config/messageconstant";
import AutoComplete from "../../components/Comman/AutoComplete";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import swal from "sweetalert";
import { redColor, titleColor } from "../../config/ColorObj";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const PaymentModeDialog = ({
  open,
  handleCloses,
  totalReceivedAmt,
  saveData,
}) => {
  const [totalPayment, setFeesTotal] = useState("");
  const [type, setType] = useState(0);
  const [transactionDate, setTransactionDate] = useState(new Date());
  const [number, setNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [remark, setRemark] = useState("");
  const [formErrors, setFormErrors] = React.useState({});
  const [modeOfPay, setModeOfpay] = React.useState([
    {
      id: 0,
      name: "Cash",
    },
    {
      id: 1,
      name: "Cheque",
    },
    {
      id: 2,
      name: "Online",
    },
  ]);

  const changeFromDate = (date) => {
    setTransactionDate(date);
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;

    if (name === "totalPayment") {
      setFeesTotal(value);
    } else if (name === "type") {
      setType(value);
    } else if (name === "number") {
      setNumber(value);
    } else if (name === "remark") {
      setRemark(value);
    } else {
      setBankName(value);
    }
  };

  const changeHandlerForDropdown = (name, newValue) => {
    if (newValue != null) {
      setType(newValue);
    } else {
      setType("");
    }
  };

  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (type == 1 || type == 2) {
      if (number === "") {
        formIsValid = false;
        formErrors["numberError"] = myConstClass.numberMsg;
      }
    }

    if (type == 1) {
      if (bankName === "") {
        formIsValid = false;
        formErrors["bankNameError"] = myConstClass.bankNameMsg;
      }
    }

    // if (transactionDate === "" || transactionDate === null) {
    //   formIsValid = false;
    //   formErrors["transactionDateError"] = myConstClass.transactionDateMsg;
    // }

    setFormErrors(formErrors);
    return formIsValid;
  };

  const savePaymentData = () => {
    if (checkValidation()) {
      let saveToData = {
        payType: type,
        payRefNo: number,
        payBank: bankName,
        remark: remark,
        remark2: "",
      };
      swal({
        title: "Are you sure?",
        text: myConstClass.saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          saveData(saveToData);
        }
      });
    }
  };

  useEffect(() => {}, []);
  return (
    <>
      <div>
        <BootstrapDialog
          onClose={handleCloses}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="lg"
          onBackdropClick="true"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloses}
          >
            Payment
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={2}
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="flex-start"
              >
                <Grid item xs={12} sm={12} md={6}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: "#00bcd4" }}
                    label="Amount : "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label={totalReceivedAmt}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={6}
                lg={6}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="flex-start"
              />
            </Grid>

            <Grid
              item
              sx={{ mt: 1 }}
              sm={12}
              xs={12}
              md={12}
              lg={12}
              spacing={1}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={6}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} md={6} sm={6}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label="* "
                  />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="Mode of pay: "
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <AutoComplete
                    label={"*Mode of pay"}
                    placeHolder={"Mode of pay"}
                    keyColName={"id"}
                    value={type}
                    options={modeOfPay}
                    onChange={changeHandlerForDropdown}
                  />
                  <FormHelperText> </FormHelperText>
                </Grid>
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                {/* <Grid item xs={12} sm={12} md={6}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Amount : "
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label={rows.amount}
                  />
                </Grid> */}
              </Grid>
            </Grid>
            <Grid
              item
              // sx={{ m: 1 }}
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              spacing={1}
              justifyContent="space-evenly"
            >
              {/* <Grid
                item
                sm={6}
                xs={12}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} md={12} sm={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="*Transaction Date "
                  />
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <BasicDatePicker
                    color="primary"
                    name="transactionDate"
                    size="small"
                    value={transactionDate}
                    disabled={false}
                    fullWidth
                    onChange={changeFromDate}
                     isError={formErrors.transactionDateError ? true : false}
                    errorText={
                      formErrors.transactionDateError
                        ? formErrors.transactionDateError
                        : " "
                    }
                  />
                  <BasicDatePicker
                    color="primary"
                    name="transactionDate"
                    size="small"
                    value={transactionDate}
                    maxDate={new Date()}
                    onChange={changeFromDate}
                    isError={formErrors.transactionDateError ? true : false}
                    errorText={
                      formErrors.transactionDateError
                        ? formErrors.transactionDateError
                        : " "
                    }
                  />
                </Grid>
              </Grid> */}
              {type == 1 || type == 2 ? (
                <>
                  <Grid
                    item
                    sm={6}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} md={6} sm={6}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: redColor }}
                        label="* "
                      />
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16, color: titleColor }}
                        label="Transaction Id: "
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <TextFieldCompo
                        placeHolder={"Transaction Id"}
                        color="primary"
                        name="number"
                        size="small"
                        value={number}
                        fullWidth
                        onChange={changeHandler}
                        errorText={
                          formErrors["numberError"]
                            ? formErrors["numberError"]
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  />
                </>
              ) : (
                <>
                  <Grid
                    item
                    sm={6}
                    xs={6}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-evenly"
                  />
                </>
              )}

              {/*   <Grid item  xs={12} md={0.5} sm={0.5}></Grid> */}
            </Grid>
            {type == 1 && (
              <>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} md={3} sm={3}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: redColor }}
                      label="* "
                    />
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: titleColor }}
                      label="Bank Name: "
                    />
                  </Grid>
                  <Grid item xs={12} md={9} sm={9}>
                    <TextFieldCompo
                      placeHolder={"Bank Name"}
                      color="primary"
                      name="bankName"
                      size="small"
                      value={bankName}
                      fullWidth
                      onChange={changeHandler}
                      errorText={
                        formErrors["bankNameError"]
                          ? formErrors["bankNameError"]
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <Grid
              item
              // sx={{ m: 1 }}
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              justifyContent="space-evenly"
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} md={3} sm={3}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16 }}
                    label="Remark: "
                  />
                </Grid>
                <Grid item xs={12} md={9} sm={9}>
                  <TextFieldCompo
                    placeHolder={"Remark"}
                    color="primary"
                    name="remark"
                    size="small"
                    value={remark}
                    fullWidth
                    onChange={changeHandler}
                    multiline={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid item sx={{ mr: 3 }} xs={12} sm={12} md={1}>
              <ButtonCompo
                size="medium"
                onClick={savePaymentData}
                variant="contained"
                name="Submit"
                fullWidth={true}
              />
            </Grid>
          </DialogActions>
        </BootstrapDialog>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentModeDialog);
