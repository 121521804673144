import React from "react";
import { connect } from "react-redux";
import { subAdminUserJson } from "../../../DynamicFormsJson/Transaction/subAdminUser";
import { getStakeHolderNameByUserType } from "../../../Slice/subAdminUser.slice";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { SubAdminUserColumns } from "../../../tableColumns/table-columns";
import { showLoador } from "../../Landing/Landing.slice";
import { withRouter } from "../../../components/withRouter";
import { compose } from "recompose";
import {
  FailTogenerateCredentialMsg,
  generateCredentialMsg,
  noInternetMsg,
  sendCredentialMsg,
  serverMsg,
} from "../../../config/messageconstant";
import { showNotification } from "../../Landing/Landing.slice";
import { apiGet } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";

class SubAdminUserTable extends React.Component {
  constructor(props) {
    super(props);
  }
  

  rowEdit = (rowData) => {
    this.props.navigate("/sub-admin-user-edit/" + rowData.id);
  };

  onClickApplySend = (rowData) => {
    swal({
      title: "Are you sure?",
      text: sendCredentialMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (navigator.onLine) {
          this.props.showLoador({ loador: true });
          apiGet({
            url:
              endpoint.developerAdminUser +
              "/" +
              rowData.id +
              "/password-reset",
          }).then(({ success }) => {
            if (success) {
              this.props.showLoador({ loador: false });
              this.props.showNotification({
                msg: generateCredentialMsg,
              });
            } else {
              this.props.showNotification({
                msg: FailTogenerateCredentialMsg,
                severity: "error",
              });
              this.props.showLoador({ loador: false });
            }
          });
        } else {
          this.props.showNotification({
            msg: noInternetMsg,
            severity: "error",
          });
        }
      }
    });
  };
  getTableData=async()=>{
    const { getStakeHolderNameByUserType, showNotification } = this.props;
    let resData={ response:[], success:false }
    if (navigator.onLine) {
      showLoador({ loador: true });
      resData= await getStakeHolderNameByUserType({
        userTypeIds: "6",
        reverseTrue: false,
      }).then(({ response,success }) => {
        return { response, success };
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
      return { response:[], success:false };
    }
   return  resData
  }
  render() {
    const { subAdminUserList } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={subAdminUserJson.formPath}
          formPathEdit={subAdminUserJson.formPathEdit}
          screenTitle={subAdminUserJson.screenTitle}
          fieldMeta={subAdminUserJson.fieldMeta}
          showPdfDownload={false}
          showExcelDownload={false}
          showExtension={false}
          pdfFileName={subAdminUserJson.pdfFileName}
          excelFileName={subAdminUserJson.excelFileName}
          showAddButton={subAdminUserJson.showAddButton}
          tableColumnsToFilter={subAdminUserJson.tableColumnsToFilter}
          tableColumns={SubAdminUserColumns}
          tableData={subAdminUserList?.stackholderNameByUser}
          showTransferButtonInTable={true}
          openFormPathStatus={subAdminUserJson.openFormPathStatus}
          getTableData={this.getTableData}
          apiBaseURL={subAdminUserJson.apiBaseURL}
          baseIdColumn={subAdminUserJson.baseIdColumn}
          rowEdit={this.rowEdit}
          showApply={true}
          rowApply={this.onClickApplySend}
          isActiveURL={subAdminUserJson.isActiveURL}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  subAdminUserList: state.subAdminUser,
});
const mapDispatchToProps = {
  getStakeHolderNameByUserType,
  showNotification,
  showLoador,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SubAdminUserTable);
