import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { ClaimTypeJson } from "../../../DynamicFormsJson/MastersJSON/claimType";
import { FormControlLabel, Grid, Paper, Radio, RadioGroup } from "@mui/material";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import { LabelCompo } from "../../../components/Comman/Label";
import { TextFieldCompo } from "../../../components/Comman/TextFieldCompo";
import { redColor, titleColor } from "../../../config/ColorObj";
import { ButtonCompo } from "../../../components/Comman/Button";
import { claimTypeName, nameAlreadyExistMsg, noInternetMsg, remarkMsg, saveWarningMsg, savemsg, serverMsg, shortNameMsg, uomNameMsg, updateData, updateMsg } from "../../../config/messageconstant";
import swal from "sweetalert";
import { apiGet, apiPost } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "../../../components/withRouter";

class ClaimTypeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            shortName: "",
            remark: "",
            uomName: "",
            attachmentApplicable: "1",
            limitVal: "1",
            type: "0",
            formErrors: {},
            uomFlag: true,
            limitValueFlag: false,
            nameChangeFlag: false,
        };
    }

    async componentDidMount() {
        const { showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            if (this.props.params.id) {
                showLoador({ loador: true });
                apiGet({
                    url: endpoint.claimType + "/" + this.props.params.id,
                }).then(({ data, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    } else {
                        if (data && data.data && Object.keys(data.data).length !== 0) {
                            this.setState({
                                name: data.data.name ? data.data.name : "",
                                shortName: data.data.shortName ? data.data.shortName : "",
                                remark: data.data.remark ? data.data.remark : "",
                                uomName: data.data.uomName ? data.data.uomName : "",
                                attachmentApplicable: data.data.attachmentApplicable !== null ? data.data.attachmentApplicable : "",
                                limitVal: data.data.limitVal !== null ? data.data.limitVal : "",
                                type: data.data.type !== null ? data.data.type : "",
                                uomFlag: data.data.type !== null && data.data.type == 0 ? true : false,
                                limitValueFlag: data.data.type !== null && data.data.type == 2 ? true : false,
                            });
                        }
                    }
                });
            }
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }


    changeHandler = (e) => {
        const { name, value } = e.target;
        if (name === "name") {
            this.setState({ name: value, nameChangeFlag: true });
        } else if (name === "shortName") {
            this.setState({ shortName: value });
        } else if (name === "remark") {
            this.setState({ remark: value });
        } else if (name === "uomName") {
            this.setState({ uomName: value });
        } else if (name === "type") {
            this.setState({ type: value });
            if (value === "0") {
                this.setState({ uomFlag: true });
                this.setState({ limitValueFlag: false });
            } else if (value === "1") {
                this.setState({ uomFlag: false });
                this.setState({ limitValueFlag: false });
            } else if (value === "2") {
                this.setState({ uomFlag: false });
                this.setState({ limitValueFlag: true });
            }
        } else if (name === "attachmentApplicable") {
            this.setState({ attachmentApplicable: value });
        } else if (name === "limitVal") {
            this.setState({ limitVal: value });
        }
    };


    checkValidation = () => {
        const { name, shortName, remark, uomFlag, uomName } = this.state;

        let formErrors = {};
        let formIsValid = true;

        if (name === "") {
            formIsValid = false;
            formErrors["nameError"] = claimTypeName;
        }
        if (shortName === "") {
            formIsValid = false;
            formErrors["shortNameError"] = shortNameMsg;
        }
        if (remark === "") {
            formIsValid = false;
            formErrors["remarkError"] = remarkMsg;
        } if (uomFlag) {
            if (uomName === "") {
                formIsValid = false;
                formErrors["uomNameError"] = uomNameMsg;
            }
        }
        this.setState({ formErrors: formErrors });
        return formIsValid;
    };

    onCancel = () => {
        this.props.navigate(-1);
    };

    submit = () => {
        const { name, shortName, remark, type, uomName, attachmentApplicable, limitVal, nameChangeFlag } = this.state;
        const { showLoador, showNotification } = this.props;
        if (this.checkValidation()) {
            let saveDataJson = {};
            if (this.props.params.id) {
                saveDataJson = {
                    id: this.props.params.id,
                    name: name,
                    shortName: shortName,
                    remark: remark,
                    isActive: 1,
                    type: type,
                    uomName: uomName ? uomName : null,
                    attachmentApplicable: attachmentApplicable,
                    limitVal: limitVal ? limitVal : null,
                }
            } else {
                saveDataJson = {
                    name: name,
                    shortName: shortName,
                    remark: remark,
                    isActive: 1,
                    type: type,
                    uomName: uomName ? uomName : null,
                    attachmentApplicable: attachmentApplicable,
                    limitVal: limitVal ? limitVal : null,
                }
            }

            if (nameChangeFlag) { 
                showLoador({ loador: true });
                apiGet({
                    url: endpoint.claimType + "/claim-type-name-exist-or-not?claimTypeName=" + name,
                }).then(({ data, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({
                            msg: serverMsg,
                            severity: "error",
                        });
                    } else {
                        if (data && data.data && Object.keys(data.data).length !== 0) {
                            if (data.data.statusCode == 500) {
                                showNotification({ msg: data.data.message, severity: "error" });
                            } else {
                                swal({
                                    title: "Are you sure?",
                                    text: this.props.params.id ? updateData : saveWarningMsg,
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                }).then((willDelete) => {
                                    if (willDelete) {
                                        showLoador({ loador: true });
                                        apiPost({
                                            url: endpoint.claimType,
                                            postBody: saveDataJson,
                                        }).then(({ success }) => {
                                            showLoador({ loador: false });
                                            if (!success) {
                                                showNotification({
                                                    msg: serverMsg,
                                                    severity: "error",
                                                });
                                            } else {
                                                showNotification({ msg: this.props.params.id ? updateMsg : savemsg });
                                                window.location.replace("/claim-type-list");
                                            }
                                        });
                                    }
                                });
                            }
                        }
                    }
                });
            } else {
                swal({
                    title: "Are you sure?",
                    text: this.props.params.id ? updateData : saveWarningMsg,
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((willDelete) => {
                    if (willDelete) {
                        showLoador({ loador: true });
                        apiPost({
                            url: endpoint.claimType,
                            postBody: saveDataJson,
                        }).then(({ success }) => {
                            showLoador({ loador: false });
                            if (!success) {
                                showNotification({
                                    msg: serverMsg,
                                    severity: "error",
                                });
                            } else {
                                showNotification({ msg: this.props.params.id ? updateMsg : savemsg });
                                window.location.replace("/claim-type-list");
                            }
                        });
                    }
                });
            }
        }
    }

    render() {
        const { name, shortName, remark, limitVal, uomName, attachmentApplicable, type, uomFlag, limitValueFlag, formErrors } = this.state;
        console.log(type);
        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 2,
                    }}
                    elevation={0}
                >
                    <LandingScreenHeader
                        screenTitle={"Claim Type"}
                        showTable={false}
                        showBackButton={true}
                        showAddButton={false}
                        onCancel={this.onCancel}
                    />
                    <br />

                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="space-between"
                    >
                        <Grid
                            item
                            sm={6}
                            xs={8}
                            md={6}
                            lg={6}
                            container
                            justifyContent="space-evenly"
                        >
                            <Grid item xs={12} md={5} sm={5}>
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: redColor }}
                                    label="* "
                                />
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: titleColor }}
                                    label="Name :"
                                />
                            </Grid>
                            <Grid item xs={12} md={7} sm={7}>
                                <TextFieldCompo
                                    placeHolder={"Name"}
                                    color="primary"
                                    name="name"
                                    size="small"
                                    value={name}
                                    fullWidth
                                    onChange={this.changeHandler}
                                    errorText={
                                        formErrors["nameError"] ? formErrors["nameError"] : ""
                                    }
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            sm={6}
                            xs={8}
                            md={6}
                            lg={6}
                            container
                            justifyContent="space-evenly"
                        >
                            <Grid item xs={12} md={5} sm={5}>
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: redColor }}
                                    label="* "
                                />
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: titleColor }}
                                    label="Short Name :"
                                />
                            </Grid>
                            <Grid item xs={12} md={7} sm={7}>
                                <TextFieldCompo
                                    placeHolder={"Short Name"}
                                    color="primary"
                                    name="shortName"
                                    size="small"
                                    value={shortName}
                                    fullWidth
                                    onChange={this.changeHandler}
                                    errorText={
                                        formErrors["shortNameError"] ? formErrors["shortNameError"] : ""
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="space-between"
                    >
                        <Grid
                            item
                            sm={6}
                            xs={8}
                            md={6}
                            lg={6}
                            container
                            justifyContent="space-evenly"
                        >
                            <Grid item xs={12} md={5} sm={5}>
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: redColor }}
                                    label="* "
                                />
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: titleColor }}
                                    label="Type :"
                                />
                            </Grid>
                            <Grid item xs={12} md={7} sm={7}>
                                <RadioGroup
                                    row
                                    aria-label={"Type"}
                                    name={"type"}
                                    id={"type"}
                                    value={type}
                                    onChange={this.changeHandler}
                                    size="small"
                                >
                                    <FormControlLabel
                                        value={"0"}
                                        control={<Radio />}
                                        label={"Per-UOM"}
                                    />
                                    <FormControlLabel
                                        value={"1"}
                                        control={<Radio />}
                                        label={"Custom"}
                                    />
                                    <FormControlLabel
                                        value={"2"}
                                        control={<Radio />}
                                        label={"Limit"}
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>


                        <Grid
                            item
                            sm={6}
                            xs={8}
                            md={6}
                            lg={6}
                            container
                            justifyContent="space-evenly"
                        >
                            <Grid item xs={12} md={5} sm={5}>
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: redColor }}
                                    label="* "
                                />
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: titleColor }}
                                    label="Attachment Applicable :"
                                />
                            </Grid>
                            <Grid item xs={12} md={7} sm={7}>
                                <RadioGroup
                                    row
                                    aria-label={"Attachment Applicable"}
                                    name={"attachmentApplicable"}
                                    id={"attachmentApplicable"}
                                    value={attachmentApplicable}
                                    onChange={this.changeHandler}
                                    size="small"
                                >
                                    <FormControlLabel
                                        value={"0"}
                                        control={<Radio />}
                                        label={"Yes"}
                                    />
                                    <FormControlLabel
                                        value={"1"}
                                        control={<Radio />}
                                        label={"No"}
                                    />

                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="space-between"
                    >
                        {uomFlag && (
                            <Grid
                                item
                                sm={6}
                                xs={8}
                                md={6}
                                lg={6}
                                container
                                justifyContent="space-evenly"
                            >
                                <Grid item xs={12} md={5} sm={5}>
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16, color: redColor }}
                                        label="* "
                                    />
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16, color: titleColor }}
                                        label="UOM Name :"
                                    />
                                </Grid>
                                <Grid item xs={12} md={7} sm={7}>
                                    <TextFieldCompo
                                        placeHolder={"UOM Name"}
                                        color="primary"
                                        name="uomName"
                                        size="small"
                                        value={uomName}
                                        fullWidth
                                        onChange={this.changeHandler}
                                        errorText={
                                            formErrors["uomNameError"] ? formErrors["uomNameError"] : ""
                                        }
                                    />
                                </Grid>
                            </Grid>
                        )}

                        {limitValueFlag && (
                            <Grid
                                item
                                sm={6}
                                xs={8}
                                md={6}
                                lg={6}
                                container
                                justifyContent="space-evenly"
                            >
                                <Grid item xs={12} md={5} sm={5}>
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16, color: redColor }}
                                        label="* "
                                    />
                                    <LabelCompo
                                        className="text-black"
                                        style={{ fontSize: 16, color: titleColor }}
                                        label="Limit Value :"
                                    />
                                </Grid>
                                <Grid item xs={12} md={7} sm={7}>
                                    <RadioGroup
                                        row
                                        aria-label={"Attachment Applicable"}
                                        name={"limitVal"}
                                        id={"limitVal"}
                                        value={limitVal}
                                        onChange={this.changeHandler}
                                        size="small"
                                    >
                                        <FormControlLabel
                                            value={"1"}
                                            control={<Radio />}
                                            label={"Yes"}
                                        />
                                        <FormControlLabel
                                            value={"2"}
                                            control={<Radio />}
                                            label={"No"}
                                        />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>

                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="space-between"
                    >
                        <Grid
                            item
                            sm={6}
                            xs={8}
                            md={6}
                            lg={6}
                            container
                            justifyContent="space-evenly"
                        >
                            <Grid item xs={12} md={5} sm={5}>
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: redColor }}
                                    label="* "
                                />
                                <LabelCompo
                                    className="text-black"
                                    style={{ fontSize: 16, color: titleColor }}
                                    label="Remark :"
                                />
                            </Grid>
                            <Grid item xs={12} md={7} sm={7}>
                                <TextFieldCompo
                                    placeHolder={"Remark"}
                                    color="primary"
                                    name="remark"
                                    size="small"
                                    value={remark}
                                    fullWidth
                                    onChange={this.changeHandler}
                                    errorText={
                                        formErrors["remarkError"] ? formErrors["remarkError"] : ""
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <br />
                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="right"
                    >
                        <ButtonCompo
                            size="medium"
                            type="submit"
                            variant="contained"
                            name={"Submit"}
                            onClick={this.submit}
                        />
                    </Grid>



                </Paper>
            </>
        );
    }
}

const mapStateToProps = (state) => ({

});
const mapDispatchToProps = {
    showLoador,
    showNotification
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ClaimTypeForm);