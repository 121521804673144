import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
    companyCategoryConfiguration: [],
};
let URL = endpoints.companyCategoryConfiguration;
const companyCategoryConfigurationSlice = createSlice({
    name: "companyCategoryConfiguration",
    initialState,
    reducers: {
        companyCategoryConfigurationSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                companyCategoryConfiguration: row,
            };
        },
        resetState: (state = cloneDeep(initialState), action) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const { companyCategoryConfigurationSuccess, resetState } =
    companyCategoryConfigurationSlice.actions;

export default companyCategoryConfigurationSlice.reducer;

export const getCompanyCategoryList = () => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL,
        }).then(({ data, success }) => {
            if (success) {
                const { content } = data.data;
                const row = content.map((companyCategoryObject, index) => {
                    let companyCategoryData = {
                        index: index + 1,
                        id: companyCategoryObject.id === null ? "" : companyCategoryObject.id,
                        fromDate: companyCategoryObject.fromDate === null ? "" : companyCategoryObject.fromDate,
                        toDate: companyCategoryObject.toDate === null ? "" : companyCategoryObject.toDate,
                        name: companyCategoryObject.name === null ? "" : companyCategoryObject.name,
                        areaIds: companyCategoryObject.areaIds === null ? "-" : companyCategoryObject.areaIds,
                        description: companyCategoryObject.description === null ? "-" : companyCategoryObject.description,
                        type: companyCategoryObject.type === null ? "" : companyCategoryObject.type,
                        typeForColumn: companyCategoryObject.type === null ? "" : companyCategoryObject.type === 0 ? "Continuos" : "Date Range",
                        isActive: companyCategoryObject.isActive === null ? "" : companyCategoryObject.isActive,
                    };
                    return companyCategoryData;
                });
                dispatch(companyCategoryConfigurationSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};