import {
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { withRouter } from "../../components/withRouter";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import PaymentFormByVehicle from "./PaymentFormByVehicle";
import PaymentFormStatic from "./PaymentFormStatic";

class OutletPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "0",
      stockData: [],
    };
  }
  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  async componentDidMount() {}

  onCancel = () => {
    this.props.navigate(-1);
  };
  render() {
    const { value, stockData } = this.state;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            screenTitle={"Outlet Payment"}
            showTable={false}
            showBackButton={false}
            showAddButton={false}
            onCancel={this.onCancel}
          />
          <br />
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={this.handleChange}
              row
              defaultValue={value}
            >
              <FormControlLabel
                value="0"
                control={<Radio />}
                label="Outletwise Payment"
              />
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Vehiclewise Payment"
              />
            </RadioGroup>
          </FormControl>
        </Paper>
        {value == "0" ? (
          <PaymentFormStatic stockData={stockData} />
        ) : (
          <PaymentFormByVehicle />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OutletPayment);
