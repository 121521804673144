import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { ConvertAmount } from "../components/Comman/ConvertValueWithDenominator";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  companyTarget: [],
};
let URL = endpoints.companyTarget;
const companyTargetSlice = createSlice({
  name: "companyTarget",
  initialState,
  reducers: {
    companyTargetSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        companyTarget: row,
        isFetch: true,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },

    lastYearCompanySuccess: (state = cloneDeep(initialState), action) => {
      const { lastYearCompnayObject } = action.payload;
      return {
        ...state,
        lastYearCompnay: lastYearCompnayObject,
      };
    },
  },
});

export const { companyTargetSuccess, lastYearCompanySuccess, resetState } =
  companyTargetSlice.actions;

export default companyTargetSlice.reducer;

export const getComapnyTarget = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((companyTargetObj, index) => {
          let companyTargetData = {
            index: index + 1,
            id: companyTargetObj.id === null ? "" : companyTargetObj.id,
            yearMaster:
              companyTargetObj.yearMaster === null
                ? ""
                : companyTargetObj.yearMaster,
            name:
              companyTargetObj.yearMaster !== null &&
              companyTargetObj.yearMaster.yearName
                ? companyTargetObj.yearMaster.yearName
                : "",
            jan:
              companyTargetObj.jan === null
                ? ""
                : ConvertAmount({ amount: companyTargetObj.jan }),
            feb:
              companyTargetObj.feb === null
                ? ""
                : ConvertAmount({ amount: companyTargetObj.feb }),
            march:
              companyTargetObj.march === null
                ? ""
                : ConvertAmount({ amount: companyTargetObj.march }),
            april:
              companyTargetObj.april === null
                ? ""
                : ConvertAmount({ amount: companyTargetObj.april }),
            may:
              companyTargetObj.may === null
                ? ""
                : ConvertAmount({ amount: companyTargetObj.may }),
            jun:
              companyTargetObj.jun === null
                ? ""
                : ConvertAmount({ amount: companyTargetObj.jun }),
            july:
              companyTargetObj.july === null
                ? ""
                : ConvertAmount({ amount: companyTargetObj.july }),
            aug:
              companyTargetObj.aug === null
                ? ""
                : ConvertAmount({ amount: companyTargetObj.aug }),
            sep:
              companyTargetObj.sep === null
                ? ""
                : ConvertAmount({ amount: companyTargetObj.sep }),
            oct:
              companyTargetObj.oct === null
                ? ""
                : ConvertAmount({ amount: companyTargetObj.oct }),
            nov:
              companyTargetObj.nov === null
                ? ""
                : ConvertAmount({ amount: companyTargetObj.nov }),
            dec:
              companyTargetObj.dec === null
                ? ""
                : ConvertAmount({ amount: companyTargetObj.dec }),
            total:
              companyTargetObj.total === null
                ? ""
                : ConvertAmount({
                    amount:
                      companyTargetObj.jan +
                      companyTargetObj.feb +
                      companyTargetObj.march +
                      companyTargetObj.april +
                      companyTargetObj.may +
                      companyTargetObj.jun +
                      companyTargetObj.july +
                      companyTargetObj.aug +
                      companyTargetObj.sep +
                      companyTargetObj.oct +
                      companyTargetObj.nov +
                      companyTargetObj.dec,
                  }),
            insertDateTime:
              companyTargetObj.insertDateTime === null
                ? ""
                : companyTargetObj.insertDateTime,
          };
          return companyTargetData;
        });
        dispatch(companyTargetSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getLastYearComapnyTarget =
  ({ yearId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/get-last-year-by-year?yearId=" + yearId,
      }).then(({ data, success }) => {
        if (success) {
          console.log(data);
          const lastYearCompnayObject = data.data;
          if (data.data !== null && Object.keys(data.data).length !== 0) {
            let lastYearCompanyTargetData = {
              id:
                lastYearCompnayObject.id === null
                  ? ""
                  : lastYearCompnayObject.id,
              yearMaster:
                lastYearCompnayObject.yearMaster === null
                  ? ""
                  : lastYearCompnayObject.yearMaster,
              yearName:
                lastYearCompnayObject.yearMaster !== null &&
                lastYearCompnayObject.yearMaster.yearName
                  ? lastYearCompnayObject.yearMaster.yearName
                  : "",
              name:
                lastYearCompnayObject.yearMaster !== null &&
                lastYearCompnayObject.yearMaster.yearName
                  ? lastYearCompnayObject.yearMaster.yearName
                  : "",

              jan:
                lastYearCompnayObject.jan === null
                  ? ""
                  : lastYearCompnayObject.jan,
              feb:
                lastYearCompnayObject.feb === null
                  ? ""
                  : lastYearCompnayObject.feb,
              march:
                lastYearCompnayObject.march === null
                  ? ""
                  : lastYearCompnayObject.march,
              april:
                lastYearCompnayObject.april === null
                  ? ""
                  : lastYearCompnayObject.april,
              may:
                lastYearCompnayObject.may === null
                  ? ""
                  : lastYearCompnayObject.may,
              jun:
                lastYearCompnayObject.jun === null
                  ? ""
                  : lastYearCompnayObject.jun,
              july:
                lastYearCompnayObject.july === null
                  ? ""
                  : lastYearCompnayObject.july,
              aug:
                lastYearCompnayObject.aug === null
                  ? ""
                  : lastYearCompnayObject.aug,
              sep:
                lastYearCompnayObject.sep === null
                  ? ""
                  : lastYearCompnayObject.sep,
              oct:
                lastYearCompnayObject.oct === null
                  ? ""
                  : lastYearCompnayObject.oct,
              nov:
                lastYearCompnayObject.nov === null
                  ? ""
                  : lastYearCompnayObject.nov,
              dec:
                lastYearCompnayObject.dec === null
                  ? ""
                  : lastYearCompnayObject.dec,
              totalTarget:
                lastYearCompnayObject.totalTarget === null
                  ? ""
                  : ConvertAmount({
                      amount: lastYearCompnayObject.totalTarget,
                    }),
              achievedTarget:
                lastYearCompnayObject.achievedTarget === null
                  ? ""
                  : ConvertAmount({
                      amount: lastYearCompnayObject.achievedTarget,
                    }),
              insertDateTime:
                lastYearCompnayObject.insertDateTime === null
                  ? ""
                  : lastYearCompnayObject.insertDateTime,
            };
            dispatch(lastYearCompanySuccess({ lastYearCompanyTargetData }));
            return { response: lastYearCompanyTargetData, success };
          }
        }
        return { response: {}, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
