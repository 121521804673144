import React from "react";
import { connect } from "react-redux";

import { Grid, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { compose } from "@reduxjs/toolkit";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { withRouter } from "../../components/withRouter";
import ProductRateStructure from "./ProductRateStructure";
import AssignStructure from "./AssignStructure";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

class Structure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
      fieldData: {},
      DataToSet: [],
      value: 0,
      total: 0,
      totalAmt: 25.5,
      open: false,
      orderList: [],
      salesPersonId: "",
      saveDataObject: "",
    };
  }

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  toggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  changeTab = (value, data) => {
    this.setState({
      value: value,
      saveDataObject: data,
    });
  };
  onCancel = () => {
    this.props.navigate(-1);
  };
  render() {
    // console.log(this.props.params.id);
    const { value, saveDataObject } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            tableColumnsToFilter={[]}
            screenTitle={"Rate Configuration"}
            showPdfDownload={false}
            showExcelDownload={false}
            showAddButton={false}
            showTable={false}
            showBackButton={true}
            onCancel={this.onCancel}
          />
          <br />
          <>
            <Tabs
              value={value}
              onChange={this.handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
            >
              <Tab label={"Product Rate Structure"} />
              {!this.props.params.id && (
                <>
                  <Tab
                    // disabled={
                    //   Object.keys(accountLedgerList.accountLedgerData).length ==
                    //   0
                    //     ? true
                    //     : false
                    // }
                    label={"Assign Structure"}
                  />
                </>
              )}
            </Tabs>
            <TabPanel index={0} value={value}>
              <ProductRateStructure
                copyFlag={this.props.copyFlag}
                changeTab={this.changeTab}
              />
            </TabPanel>
            {!this.props.params.id && (
              <>
                <TabPanel index={1} value={value}>
                  <AssignStructure
                    changeTab={this.changeTab}
                    saveDataObject={saveDataObject}
                  />
                </TabPanel>
              </>
            )}
          </>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  accountLedgerList: state.accountLedger,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Structure);
