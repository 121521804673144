import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  grievanceCategory: [],
};
let URL = endpoints.grievanceCategory;
const grievanceCategorySlice = createSlice({
  name: "grievanceCategory",
  initialState,
  reducers: {
    grievanceCategorySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        grievanceCategory: row,
        
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { grievanceCategorySuccess, resetState } =
  grievanceCategorySlice.actions;

export default grievanceCategorySlice.reducer;

export const getGrievanceCategory = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertDateTime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((grievanceCategoryObject, index) => {
          let grievanceCategoryData = {
            index: index + 1,
            id:
              grievanceCategoryObject.id === null
                ? ""
                : grievanceCategoryObject.id,
            categoryName:
              grievanceCategoryObject.categoryName === null
                ? ""
                : grievanceCategoryObject.categoryName,
            type:
              grievanceCategoryObject.type === null
                ? ""
                : grievanceCategoryObject.type == 0
                ? "Regular"
                : "Outlet",
          };
          return grievanceCategoryData;
        });
        dispatch(grievanceCategorySuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
