import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  manageStockByDate: [],
  manageStockById: {},
  stockBetweenDate: {},
  stock: {},
  productStocks: [],
  itemwiseBatchData: [],
  multipleStockData: {},
  detailOnClick: [],
};
let URL = endpoints.manageStockHeader;
let URL1 = endpoints.stockStakeholderBillHeader;
const manageStockListSlice = createSlice({
  name: "manageStockByDate",
  initialState,
  reducers: {
    managestockByDateSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        manageStockByDate: row,
      };
    },
    manageStockByIdSucess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        manageStockById: row,
      };
    },
    stockBetweenDateSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        stockBetweenDate: row,
      };
    },
    itemwiseBatchDataSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        itemwiseBatchData: row,
      };
    },

    stockConsumptionHeaderSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        stock: row,
      };
    },

    stockConsumptionDetailSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        productStocks: row,
        isFetch: true,
      };
    },
    multipleStockSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        multipleStockData: row,
      };
    },

    detailSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        detailOnClick: row,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});
export const {
  managestockByDateSuccess,
  manageStockByIdSucess,
  stockBetweenDateSuccess,
  stockConsumptionHeaderSuccess,
  stockConsumptionDetailSuccess,
  resetState,
  itemwiseBatchDataSuccess,
  multipleStockSuccess,
  detailSuccess,
} = manageStockListSlice.actions;
export default manageStockListSlice.reducer;

export const getManageStockList =
  ({ fromdate, todate }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/by-date?fromDate=" + fromdate + "&toDate=" + todate,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((manageStockListObject, index) => {
              let manageStockListData = {
                index: index + 1,
                id:
                  manageStockListObject.id === null
                    ? ""
                    : manageStockListObject.id,
                stockStakeHolderId:
                  manageStockListObject.stockStakeHolderId === null
                    ? ""
                    : manageStockListObject.stockStakeHolderId,
                consumptionNo:
                  manageStockListObject.consumptionNo === null
                    ? ""
                    : manageStockListObject.consumptionNo,
                date:
                  manageStockListObject.date === null
                    ? ""
                    : manageStockListObject.date,
                stockType:
                  manageStockListObject.stockType === null
                    ? ""
                    : manageStockListObject.stockType,
                type:
                  manageStockListObject.type === null
                    ? ""
                    : manageStockListObject.type === 0
                      ? "Out-Stock"
                      : "In-Stock",
                remark:
                  manageStockListObject.remark === null
                    ? ""
                    : manageStockListObject.remark,
                insertedUserId:
                  manageStockListObject.insertedUserId === null
                    ? ""
                    : manageStockListObject.insertedUserId,
                insertDateTime:
                  manageStockListObject.insertDateTime === null
                    ? ""
                    : manageStockListObject.insertDateTime,
              };

              return manageStockListData;
            });
            dispatch(managestockByDateSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
export const setManageStockList = () => async (dispatch) => {
  dispatch(managestockByDateSuccess({ row: [] }));
};
export const getManageStockById =
  ({ managestockHeaderId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/" + managestockHeaderId,
        }).then(({ data, success }) => {
          if (success) {
            const grnData = data.data;
            let manageStockData = {
              id: grnData.id === null ? "" : grnData.id,
              stockStakeHolderId:
                grnData.stockStakeHolderId === null
                  ? ""
                  : grnData.stockStakeHolderId,
              consumptionNo:
                grnData.consumptionNo === null ? "" : grnData.consumptionNo,
              date: grnData.date === null ? "" : grnData.date,
              stockType: grnData.stockType === null ? "" : grnData.stockType,
              type: grnData.type === null ? "" : grnData.type,

              remark: grnData.remark === null ? "" : grnData.remark,
              insertedUserId:
                grnData.insertedUserId === null ? "" : grnData.insertedUserId,
              insertDateTime:
                grnData.insertDateTime === null ? "" : grnData.insertDateTime,

              stockStakeHolderConsumptionDetails:
                grnData.stockStakeHolderConsumptionDetails === null
                  ? ""
                  : grnData.stockStakeHolderConsumptionDetails.map(
                    (orderHistoryObject, index) => {
                      let orderHistoryData = {
                        index: index + 1,
                        id:
                          orderHistoryObject.id === null
                            ? ""
                            : orderHistoryObject.id,
                        product:
                          orderHistoryObject.product === null
                            ? ""
                            : orderHistoryObject.product,
                        productName:
                          orderHistoryObject.product === null
                            ? ""
                            : orderHistoryObject.product.name +
                            " ( " +
                            orderHistoryObject.product.shortName +
                            " ) ",
                        subCategory:
                          orderHistoryObject.product === null
                            ? ""
                            : orderHistoryObject.product.subCategory != null
                              ? orderHistoryObject.product.subCategory.id
                              : "",
                        subCategoryName:
                          orderHistoryObject.product === null
                            ? ""
                            : orderHistoryObject.product.subCategory != null
                              ? orderHistoryObject.product.subCategory.name
                              : "",
                        stakeHolderUomName:
                          orderHistoryObject.product === null
                            ? ""
                            : orderHistoryObject.product.stakeHolderUom != null
                              ? orderHistoryObject.product.stakeHolderUom.name
                              : "",
                        outletUomName:
                          orderHistoryObject.product === null
                            ? ""
                            : orderHistoryObject.product.outletUom != null
                              ? orderHistoryObject.product.outletUom.name
                              : "",
                        product:
                          orderHistoryObject.product === null
                            ? ""
                            : orderHistoryObject.product,
                        uom:
                          orderHistoryObject.uom === null
                            ? ""
                            : orderHistoryObject.uom,
                        uomName:
                          orderHistoryObject.uom === null
                            ? ""
                            : orderHistoryObject.uom.name,

                        mrp:
                          orderHistoryObject.mrp === null
                            ? ""
                            : orderHistoryObject.mrp,
                        primaryApprovedQty:
                          orderHistoryObject.primaryApprovedQty === null
                            ? ""
                            : orderHistoryObject.primaryApprovedQty,
                        primaryQty:
                          orderHistoryObject.primaryQty === null
                            ? ""
                            : orderHistoryObject.primaryQty,

                        rate:
                          orderHistoryObject.rate === null
                            ? ""
                            : (+orderHistoryObject.rate).toFixed(2),
                        qty:
                          orderHistoryObject.qty === null
                            ? ""
                            : orderHistoryObject.qty,

                        totalAmt:
                          orderHistoryObject.totalAmt === null
                            ? ""
                            : orderHistoryObject.totalAmt.toFixed(2),
                        batchCode:
                          orderHistoryObject.batchCode === null
                            ? ""
                            : orderHistoryObject.batchCode,

                        inserteUserId:
                          orderHistoryObject.inserteUserId === null
                            ? ""
                            : orderHistoryObject.inserteUserId,
                        insertedUserId:
                          orderHistoryObject.insertedUserId === null
                            ? ""
                            : orderHistoryObject.insertedUserId,
                        insertDateTime:
                          orderHistoryObject.insertDateTime === null
                            ? ""
                            : orderHistoryObject.insertDateTime,
                        uomConversionValue:
                          orderHistoryObject.uomConversionValue === null
                            ? ""
                            : orderHistoryObject.uomConversionValue,
                      };
                      return orderHistoryData;
                    }
                  ),
            };
            dispatch(manageStockByIdSucess({ row: manageStockData }));

            return { response: manageStockData, success };
          }
          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const setManageStockDetails =
  ({ row }) =>
    async (dispatch) => {
      const updatedRow = {
        ...row,
        totalAmt: 0,
        stockStakeHolderConsumptionDetails:
          row.stockStakeHolderConsumptionDetails.map((rowObject, index) => {
            const totalAmt = (+rowObject.qty * +rowObject.rate).toFixed(2);
            return {
              ...rowObject,
              totalAmt: totalAmt,
            };
          }),
      };

      dispatch(manageStockByIdSucess({ row: updatedRow }));
    };

export const getStockBetweenDateList =
  ({ fromdate, todate, stockType }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/get-stock-between-date?fromDate=" +
            fromdate +
            "&toDate=" +
            todate,
        }).then(({ data, success }) => {
          if (success) {
            const manageStockListObject = data.data;
            let manageStockListData = {
              id:
                manageStockListObject.id === null ? "" : manageStockListObject.id,
              fromDate:
                manageStockListObject.fromDate === null
                  ? ""
                  : manageStockListObject.fromDate,
              toDate:
                manageStockListObject.toDate === null
                  ? ""
                  : manageStockListObject.toDate,
              type:
                manageStockListObject.type === null
                  ? ""
                  : manageStockListObject.type,

              productStocks:
                manageStockListObject.productStocks === null
                  ? ""
                  : manageStockListObject.productStocks.map(
                    (orderHistoryObject, index) => {
                      let orderHistoryData = {
                        index: index + 1,

                        product:
                          orderHistoryObject.product !== null &&
                            orderHistoryObject.product.id
                            ? orderHistoryObject.product.id
                            : "",

                        name:
                          orderHistoryObject.product === null
                            ? ""
                            : orderHistoryObject.product.name,
                        stakeHolderUomName:
                          orderHistoryObject.product === null
                            ? ""
                            : orderHistoryObject.product.stakeHolderUom != null
                              ? orderHistoryObject.product.stakeHolderUom.name
                              : "",

                        outletUom:
                          orderHistoryObject.product !== null &&
                            orderHistoryObject.product.outletUom
                            ? orderHistoryObject.product.outletUom
                            : "",
                        outletUomName:
                          orderHistoryObject.product !== null &&
                            orderHistoryObject.product.outletUom !== null &&
                            orderHistoryObject.product.outletUom.name
                            ? orderHistoryObject.product.outletUom.name
                            : "",
                        mrp:
                          orderHistoryObject.product !== null &&
                            orderHistoryObject.product.mrp
                            ? orderHistoryObject.product.mrp
                            : "",
                        openingQty:
                          stockType == "0"
                            ? (
                              orderHistoryObject.secondaryOpeningQty /
                              orderHistoryObject.product.uomConversionValue
                            ).toFixed(2)
                            : orderHistoryObject.secondaryOpeningQty.toFixed(2),
                        purchaseQty:
                          stockType == "0"
                            ? orderHistoryObject.primaryPurchaseQty.toFixed(2)
                            : orderHistoryObject.secondaryPurchaseQty.toFixed(
                              2
                            ),
                        distributorSellQty:
                          stockType == "0"
                            ? (
                              orderHistoryObject.secondaryDistributorSellQty /
                              orderHistoryObject.product.uomConversionValue
                            ).toFixed(2)
                            : orderHistoryObject.secondaryDistributorSellQty.toFixed(
                              2
                            ),
                        outletSellQty:
                          stockType == "0"
                            ? (
                              orderHistoryObject.secondaryOutletSellQty /
                              orderHistoryObject.product.uomConversionValue
                            ).toFixed(2)
                            : orderHistoryObject.secondaryOutletSellQty.toFixed(
                              2
                            ),
                        grnQty:
                          stockType == "0"
                            ? (
                              orderHistoryObject.secondaryGrnQty /
                              orderHistoryObject.product.uomConversionValue
                            ).toFixed(2)
                            : orderHistoryObject.secondaryGrnQty.toFixed(2),
                        crnQty:
                          stockType == "0"
                            ? (
                              orderHistoryObject.secondaryCrnStock /
                              orderHistoryObject.product.uomConversionValue
                            ).toFixed(2)
                            : orderHistoryObject.secondaryCrnStock.toFixed(2),
                        inStockQty:
                          stockType == "0"
                            ? (
                              orderHistoryObject.secondaryInStockQty /
                              orderHistoryObject.product.uomConversionValue
                            ).toFixed(2)
                            : orderHistoryObject.secondaryInStockQty.toFixed(2),
                        outStockQty:
                          stockType == "0"
                            ? (
                              orderHistoryObject.secondaryOutStockQty /
                              orderHistoryObject.product.uomConversionValue
                            ).toFixed(2)
                            : orderHistoryObject.secondaryOutStockQty.toFixed(
                              2
                            ),
                        closingQty:
                          stockType == "0"
                            ? (
                              orderHistoryObject.secondaryClosingQty /
                              orderHistoryObject.product.uomConversionValue
                            ).toFixed(2)
                            : orderHistoryObject.secondaryClosingQty.toFixed(2),
                        primaryOpeningQty:
                          orderHistoryObject.primaryOpeningQty === null
                            ? ""
                            : orderHistoryObject.primaryOpeningQty.toFixed(2),
                        secondaryOpeningQty:
                          orderHistoryObject.secondaryOpeningQty === null
                            ? ""
                            : orderHistoryObject.secondaryOpeningQty.toFixed(2),
                        primaryPurchaseQty:
                          orderHistoryObject.primaryPurchaseQty === null
                            ? ""
                            : orderHistoryObject.primaryPurchaseQty.toFixed(2),
                        secondaryPurchaseQty:
                          orderHistoryObject.secondaryPurchaseQty === null
                            ? ""
                            : orderHistoryObject.secondaryPurchaseQty.toFixed(
                              2
                            ),
                        primaryDistributorSellQty:
                          orderHistoryObject.primaryDistributorSellQty === null
                            ? ""
                            : orderHistoryObject.primaryDistributorSellQty.toFixed(
                              2
                            ),
                        secondaryDistributorSellQty:
                          orderHistoryObject.secondaryDistributorSellQty ===
                            null
                            ? ""
                            : orderHistoryObject.secondaryDistributorSellQty.toFixed(
                              2
                            ),
                        primaryOutletSellQty:
                          orderHistoryObject.primaryOutletSellQty === null
                            ? ""
                            : orderHistoryObject.primaryOutletSellQty.toFixed(
                              2
                            ),
                        secondaryOutletSellQty:
                          orderHistoryObject.secondaryOutletSellQty === null
                            ? ""
                            : orderHistoryObject.secondaryOutletSellQty.toFixed(
                              2
                            ),
                        primaryGrnQty:
                          orderHistoryObject.primaryGrnQty === null
                            ? ""
                            : orderHistoryObject.primaryGrnQty.toFixed(2),
                        secondaryGrnQty:
                          orderHistoryObject.secondaryGrnQty === null
                            ? ""
                            : orderHistoryObject.secondaryGrnQty.toFixed(2),
                        primaryInStockQty:
                          orderHistoryObject.primaryInStockQty === null
                            ? ""
                            : orderHistoryObject.primaryInStockQty.toFixed(2),
                        secondaryInStockQty:
                          orderHistoryObject.secondaryInStockQty === null
                            ? ""
                            : orderHistoryObject.secondaryInStockQty.toFixed(2),
                        primaryOutStockQty:
                          orderHistoryObject.primaryOutStockQty === null
                            ? ""
                            : orderHistoryObject.primaryOutStockQty.toFixed(2),
                        secondaryOutStockQty:
                          orderHistoryObject.secondaryOutStockQty === null
                            ? ""
                            : orderHistoryObject.secondaryOutStockQty.toFixed(
                              2
                            ),
                        primaryClosingQty:
                          orderHistoryObject.primaryClosingQty === null
                            ? ""
                            : orderHistoryObject.primaryClosingQty.toFixed(2),
                        secondaryClosingQty:
                          orderHistoryObject.secondaryClosingQty === null
                            ? ""
                            : orderHistoryObject.secondaryClosingQty.toFixed(2),
                        stockInHand:
                          orderHistoryObject.secondaryClosingQty === null &&
                            orderHistoryObject.product.outletRate === null
                            ? ""
                            : (
                              orderHistoryObject.secondaryClosingQty *
                              orderHistoryObject.product.outletRate
                            ).toFixed(2),
                        totalValue:
                          stockType == "0"
                            ? orderHistoryObject.primaryOpeningQty +
                            orderHistoryObject.primaryPurchaseQty -
                            orderHistoryObject.primaryDistributorSellQty -
                            orderHistoryObject.primaryOutletSellQty -
                            orderHistoryObject.primaryGrnQty +
                            orderHistoryObject.primaryInStockQty -
                            orderHistoryObject.primaryOutStockQty
                            : orderHistoryObject.secondaryOpeningQty +
                            orderHistoryObject.secondaryPurchaseQty -
                            orderHistoryObject.secondaryDistributorSellQty -
                            orderHistoryObject.secondaryOutletSellQty +
                            -orderHistoryObject.secondaryGrnQty +
                            orderHistoryObject.secondaryInStockQty -
                            orderHistoryObject.secondaryOutStockQty,
                        total:
                          Math.floor(
                            orderHistoryObject.secondaryClosingQty /
                            orderHistoryObject.product.uomConversionValue
                          ) +
                          " " +
                          orderHistoryObject.product.stakeHolderUom.name +
                          " " +
                          (orderHistoryObject.secondaryClosingQty %
                            orderHistoryObject.product.uomConversionValue) +
                          " " +
                          orderHistoryObject.product.outletUom.name +
                          " (" +
                          orderHistoryObject.product.uomConversionValue +
                          ")",
                      };
                      return orderHistoryData;
                    }
                  ),
            };

            dispatch(stockBetweenDateSuccess({ row: manageStockListData }));
            return { response: manageStockListData, success };
          }
          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getUpdatedStockBetweenDateList =
  ({ fromdate, todate, stockType }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          // url:'/api/distributiondevelop/v1/stock-consumption-header/stock-between-date?fromDate=02-05-2024&toDate=02-05-2024'
          url:
            URL +
            "/stock-between-date?fromDate=" +
            fromdate +
            "&toDate=" +
            todate,
        }).then(({ data, success }) => {
          if (success) {
            const manageStockListObject = data.data;
            let manageStockListData = {
              id:
                manageStockListObject.id === null ? "" : manageStockListObject.id,
              fromDate:
                manageStockListObject.fromDate === null
                  ? ""
                  : manageStockListObject.fromDate,
              toDate:
                manageStockListObject.toDate === null
                  ? ""
                  : manageStockListObject.toDate,
              type:
                manageStockListObject.type === null
                  ? ""
                  : manageStockListObject.type,

              productStocks:
                manageStockListObject.productStocks === null
                  ? ""
                  : manageStockListObject.productStocks.map(
                    (orderHistoryObject, index) => {
                      let orderHistoryData = {
                        index: index + 1,

                        product:
                          orderHistoryObject.product !== null &&
                            orderHistoryObject.product.id
                            ? orderHistoryObject.product.id
                            : "",

                        name:
                          orderHistoryObject.product === null
                            ? ""
                            : orderHistoryObject.product.name,
                        stakeHolderUomName:
                          orderHistoryObject.product === null
                            ? ""
                            : orderHistoryObject.product.stakeHolderUomName != null
                              ? orderHistoryObject.product.stakeHolderUomName
                              : "",

                        outletUom:
                          orderHistoryObject.product !== null &&
                            orderHistoryObject.product.outletUom
                            ? orderHistoryObject.product.outletUom
                            : "",
                        outletUomName:
                          orderHistoryObject.product !== null &&
                            orderHistoryObject.product.outletUomName !== null

                            ? orderHistoryObject.product.outletUomName
                            : "",
                        mrp:
                          orderHistoryObject.product !== null &&
                            orderHistoryObject.product.mrp
                            ? orderHistoryObject.product.mrp
                            : "",
                        openingQty:
                          stockType == "0"
                            ? (
                              orderHistoryObject.secondaryOpeningQty /
                              orderHistoryObject.product.uomConversionValue
                            ).toFixed(2)
                            : orderHistoryObject.secondaryOpeningQty.toFixed(2),
                        purchaseQty:
                          stockType == "0"
                            ? orderHistoryObject.primaryPurchaseQty.toFixed(2)
                            : orderHistoryObject.secondaryPurchaseQty.toFixed(
                              2
                            ),
                        distributorSellQty:
                          stockType == "0"
                            ? (
                              orderHistoryObject.secondaryDistributorSellQty /
                              orderHistoryObject.product.uomConversionValue
                            ).toFixed(2)
                            : orderHistoryObject.secondaryDistributorSellQty.toFixed(
                              2
                            ),
                        outletSellQty:
                          stockType == "0"
                            ? (
                              orderHistoryObject.secondaryOutletSellQty /
                              orderHistoryObject.product.uomConversionValue
                            ).toFixed(2)
                            : orderHistoryObject.secondaryOutletSellQty.toFixed(
                              2
                            ),
                        grnQty:
                          stockType == "0"
                            ? (
                              orderHistoryObject.secondaryGrnQty /
                              orderHistoryObject.product.uomConversionValue
                            ).toFixed(2)
                            : orderHistoryObject.secondaryGrnQty.toFixed(2),
                        crnQty:
                          stockType == "0"
                            ? (
                              orderHistoryObject.secondaryCrnStock /
                              orderHistoryObject.product.uomConversionValue
                            ).toFixed(2)
                            : orderHistoryObject.secondaryCrnStock.toFixed(2),
                        inStockQty:
                          stockType == "0"
                            ? (
                              orderHistoryObject.secondaryInStockQty /
                              orderHistoryObject.product.uomConversionValue
                            ).toFixed(2)
                            : orderHistoryObject.secondaryInStockQty.toFixed(2),
                        outStockQty:
                          stockType == "0"
                            ? (
                              orderHistoryObject.secondaryOutStockQty /
                              orderHistoryObject.product.uomConversionValue
                            ).toFixed(2)
                            : orderHistoryObject.secondaryOutStockQty.toFixed(
                              2
                            ),
                        closingQty:
                          stockType == "0"
                            ? (
                              orderHistoryObject.secondaryClosingQty /
                              orderHistoryObject.product.uomConversionValue
                            ).toFixed(2)
                            : orderHistoryObject.secondaryClosingQty.toFixed(2),
                        primaryOpeningQty:
                          orderHistoryObject.primaryOpeningQty === null
                            ? ""
                            : orderHistoryObject.primaryOpeningQty.toFixed(2),
                        secondaryOpeningQty:
                          orderHistoryObject.secondaryOpeningQty === null
                            ? ""
                            : orderHistoryObject.secondaryOpeningQty.toFixed(2),
                        primaryPurchaseQty:
                          orderHistoryObject.primaryPurchaseQty === null
                            ? ""
                            : orderHistoryObject.primaryPurchaseQty.toFixed(2),
                        secondaryPurchaseQty:
                          orderHistoryObject.secondaryPurchaseQty === null
                            ? ""
                            : orderHistoryObject.secondaryPurchaseQty.toFixed(
                              2
                            ),
                        primaryDistributorSellQty:
                          orderHistoryObject.primaryDistributorSellQty === null
                            ? ""
                            : orderHistoryObject.primaryDistributorSellQty.toFixed(
                              2
                            ),
                        secondaryDistributorSellQty:
                          orderHistoryObject.secondaryDistributorSellQty ===
                            null
                            ? ""
                            : orderHistoryObject.secondaryDistributorSellQty.toFixed(
                              2
                            ),
                        primaryOutletSellQty:
                          orderHistoryObject.primaryOutletSellQty === null
                            ? ""
                            : orderHistoryObject.primaryOutletSellQty.toFixed(
                              2
                            ),
                        secondaryOutletSellQty:
                          orderHistoryObject.secondaryOutletSellQty === null
                            ? ""
                            : orderHistoryObject.secondaryOutletSellQty.toFixed(
                              2
                            ),
                        primaryGrnQty:
                          orderHistoryObject.primaryGrnQty === null
                            ? ""
                            : orderHistoryObject.primaryGrnQty.toFixed(2),
                        secondaryGrnQty:
                          orderHistoryObject.secondaryGrnQty === null
                            ? ""
                            : orderHistoryObject.secondaryGrnQty.toFixed(2),
                        primaryInStockQty:
                          orderHistoryObject.primaryInStockQty === null
                            ? ""
                            : orderHistoryObject.primaryInStockQty.toFixed(2),
                        secondaryInStockQty:
                          orderHistoryObject.secondaryInStockQty === null
                            ? ""
                            : orderHistoryObject.secondaryInStockQty.toFixed(2),
                        primaryOutStockQty:
                          orderHistoryObject.primaryOutStockQty === null
                            ? ""
                            : orderHistoryObject.primaryOutStockQty.toFixed(2),
                        secondaryOutStockQty:
                          orderHistoryObject.secondaryOutStockQty === null
                            ? ""
                            : orderHistoryObject.secondaryOutStockQty.toFixed(
                              2
                            ),
                        primaryClosingQty:
                          orderHistoryObject.primaryClosingQty === null
                            ? ""
                            : orderHistoryObject.primaryClosingQty.toFixed(2),
                        secondaryClosingQty:
                          orderHistoryObject.secondaryClosingQty === null
                            ? ""
                            : orderHistoryObject.secondaryClosingQty.toFixed(2),
                        stockInHand:
                          orderHistoryObject.secondaryClosingQty === null &&
                            orderHistoryObject.product.outletRate === null
                            ? ""
                            : (
                              orderHistoryObject.secondaryClosingQty *
                              orderHistoryObject.product.outletRate
                            ).toFixed(2),
                        totalValue:
                          stockType == "0"
                            ? orderHistoryObject.primaryOpeningQty +
                            orderHistoryObject.primaryPurchaseQty -
                            orderHistoryObject.primaryDistributorSellQty -
                            orderHistoryObject.primaryOutletSellQty -
                            orderHistoryObject.primaryGrnQty +
                            orderHistoryObject.primaryInStockQty -
                            orderHistoryObject.primaryOutStockQty
                            : orderHistoryObject.secondaryOpeningQty +
                            orderHistoryObject.secondaryPurchaseQty -
                            orderHistoryObject.secondaryDistributorSellQty -
                            orderHistoryObject.secondaryOutletSellQty +
                            -orderHistoryObject.secondaryGrnQty +
                            orderHistoryObject.secondaryInStockQty -
                            orderHistoryObject.secondaryOutStockQty,
                        total:
                          Math.floor(
                            orderHistoryObject.secondaryClosingQty /
                            orderHistoryObject.product.uomConversionValue
                          ) +
                          " " +
                          orderHistoryObject.product.stakeHolderUomName +
                          " " +
                          (orderHistoryObject.secondaryClosingQty %
                            orderHistoryObject.product.uomConversionValue) +
                          " " +
                          orderHistoryObject.product.outletUomName +
                          " (" +
                          orderHistoryObject.product.uomConversionValue +
                          ")",
                      };
                      return orderHistoryData;
                    }
                  ),
            };

            dispatch(stockBetweenDateSuccess({ row: manageStockListData }));
            return { response: manageStockListData, success };
          }
          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getStock =
  ({ stockType }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/get-stock",
        }).then(({ data, success }) => {
          if (success) {
            const stockDataObject = data.data;
            let stockData = {
              ...stockDataObject,
              fromDate:
                stockDataObject.fromDate === null ? "" : stockDataObject.fromDate,
              toDate:
                stockDataObject.toDate === null ? "" : stockDataObject.toDate,
              type: stockDataObject.type === null ? "" : stockDataObject.type,
              yearId:
                stockDataObject.yearId === null ? "" : stockDataObject.yearId,
              productStocks:
                stockDataObject.productStocks === null
                  ? ""
                  : stockDataObject.productStocks.map((stockObject, index) => {
                    let stockObjectData = {
                      index: index + 1,
                      product:
                        stockObject.product === null ? "" : stockObject.product,
                      productName:
                        stockObject.product === null
                          ? ""
                          : stockObject.product.name,
                      openingQty:
                        stockType == "0"
                          ? stockObject.primaryOpeningQty.toFixed(2)
                          : stockObject.secondaryOpeningQty.toFixed(2),
                      purchaseQty:
                        stockType == "0"
                          ? stockObject.primaryPurchaseQty.toFixed(2)
                          : stockObject.secondaryPurchaseQty.toFixed(2),
                      distributorSellQty:
                        stockType == "0"
                          ? stockObject.primaryDistributorSellQty.toFixed(2)
                          : stockObject.secondaryDistributorSellQty.toFixed(2),
                      outletSellQty:
                        stockType == "0"
                          ? stockObject.primaryOutletSellQty.toFixed(2)
                          : stockObject.secondaryOutletSellQty.toFixed(2),
                      grnQty:
                        stockType == "0"
                          ? stockObject.primaryGrnQty.toFixed(2)
                          : stockObject.secondaryGrnQty.toFixed(2),
                      crnQty:
                        stockType == "0"
                          ? stockObject.primaryCrnStock.toFixed(2)
                          : stockObject.secondaryCrnStock.toFixed(2),
                      inStockQty:
                        stockType == "0"
                          ? stockObject.primaryInStockQty.toFixed(2)
                          : stockObject.secondaryInStockQty.toFixed(2),
                      outStockQty:
                        stockType == "0"
                          ? stockObject.primaryOutStockQty.toFixed(2)
                          : stockObject.secondaryOutStockQty.toFixed(2),
                      closingQty:
                        stockType == "0"
                          ? stockObject.primaryClosingQty.toFixed(2)
                          : stockObject.secondaryClosingQty.toFixed(2),
                      taxPercentage:
                        stockObject.product === null
                          ? ""
                          : stockObject.product.taxPercentage.name,
                      stakeHolderUom:
                        stockObject.product === null
                          ? ""
                          : stockObject.product.stakeHolderUom.name,
                      outletUom:
                        stockObject.product === null
                          ? ""
                          : stockObject.product.outletUom.name,
                      uomConversionValue:
                        stockObject.product === null
                          ? ""
                          : stockObject.product.uomConversionValue,
                      code:
                        stockObject.product === null
                          ? ""
                          : stockObject.product.code,
                      shortName:
                        stockObject.product === null
                          ? ""
                          : stockObject.product.shortName,
                      weight:
                        stockObject.product === null
                          ? ""
                          : stockObject.product.weight,
                      description:
                        stockObject.product === null
                          ? ""
                          : stockObject.product.description,
                      selfLife:
                        stockObject.product === null
                          ? ""
                          : stockObject.product.selfLife,
                      image:
                        stockObject.product === null
                          ? ""
                          : stockObject.product.image,
                      sortOrder:
                        stockObject.product === null
                          ? ""
                          : stockObject.product.sortOrder,
                      isActive:
                        stockObject.product === null
                          ? ""
                          : stockObject.product.isActive,
                      mrp:
                        stockObject.product === null
                          ? ""
                          : stockObject.product.mrp,

                      secondaryOpeningQty:
                        stockObject.secondaryOpeningQty === null
                          ? ""
                          : stockObject.secondaryOpeningQty,

                      secondaryPurchaseQty:
                        stockObject.secondaryPurchaseQty === null
                          ? ""
                          : stockObject.secondaryPurchaseQty,

                      secondaryDistributorSellQty:
                        stockObject.secondaryDistributorSellQty === null
                          ? ""
                          : stockObject.secondaryDistributorSellQty,

                      secondaryOutletSellQty:
                        stockObject.secondaryOutletSellQty === null
                          ? ""
                          : stockObject.secondaryOutletSellQty,

                      secondaryGrnQty:
                        stockObject.secondaryGrnQty === null
                          ? ""
                          : stockObject.secondaryGrnQty,

                      secondaryInStockQty:
                        stockObject.secondaryInStockQty === null
                          ? ""
                          : stockObject.secondaryInStockQty,

                      secondaryOutStockQty:
                        stockObject.secondaryOutStockQty === null
                          ? ""
                          : stockObject.secondaryOutStockQty,

                      secondaryClosingQty:
                        stockObject.secondaryClosingQty === null
                          ? ""
                          : stockObject.secondaryClosingQty,
                      totalValue:
                        stockType == "0"
                          ? stockObject.primaryOpeningQty +
                          stockObject.primaryPurchaseQty -
                          stockObject.primaryDistributorSellQty -
                          stockObject.primaryOutletSellQty -
                          stockObject.primaryGrnQty +
                          stockObject.primaryInStockQty -
                          stockObject.primaryOutStockQty
                          : stockObject.secondaryOpeningQty +
                          stockObject.secondaryPurchaseQty -
                          stockObject.secondaryDistributorSellQty -
                          stockObject.secondaryOutletSellQty +
                          -stockObject.secondaryGrnQty +
                          stockObject.secondaryInStockQty -
                          stockObject.secondaryOutStockQty,
                      total:
                        Math.floor(
                          stockObject.secondaryClosingQty /
                          stockObject.product.uomConversionValue
                        ) +
                        " " +
                        stockObject.product.stakeHolderUom.name +
                        " " +
                        (stockObject.secondaryClosingQty %
                          stockObject.product.uomConversionValue) +
                        " " +
                        stockObject.product.outletUom.name +
                        " (" +
                        stockObject.product.uomConversionValue +
                        ")",
                    };
                    return stockObjectData;
                  }),
            };
            dispatch(stockConsumptionHeaderSuccess({ row: stockData }));
            dispatch(
              stockConsumptionDetailSuccess({
                row: stockData.productStocks,
              })
            );
            return { response: stockData, success };
          }
          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getItemwiseBatch =
  ({ productId, orderDate }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/get-batch-wise-stock?productId=" +
            productId +
            "&date=" +
            orderDate,
        }).then(({ data, success }) => {
          if (success) {
            const content = data.data;
            const row = content.map((rowData, index) => {
              let batchData = {
                index: index + 1,
                batch: rowData.batch != null ? rowData.batch : "",
                name: rowData.batch != null ? rowData.batch : "",
                id: rowData.batch != null ? rowData.batch : "",
                primaryClosingQty:
                  rowData.primaryClosingQty != null
                    ? rowData.primaryClosingQty.toString()
                    : "",
                primaryDistributorSellQty:
                  rowData.primaryDistributorSellQty != null
                    ? rowData.primaryDistributorSellQty
                    : "",
                primaryGrnQty:
                  rowData.primaryGrnQty != null ? rowData.primaryGrnQty : "",
                primaryInStockQty:
                  rowData.primaryInStockQty != null
                    ? rowData.primaryInStockQty
                    : "",
                primaryOpeningQty:
                  rowData.primaryOpeningQty != null
                    ? rowData.primaryOpeningQty
                    : "",
                primaryOutStockQty:
                  rowData.primaryOutStockQty != null
                    ? rowData.primaryOutStockQty
                    : "",
                primaryOutletSellQty:
                  rowData.primaryOutletSellQty != null
                    ? rowData.primaryOutletSellQty
                    : "",
                primaryPurchaseQty:
                  rowData.primaryPurchaseQty != null
                    ? rowData.primaryPurchaseQty
                    : "",
                secondaryClosingQty:
                  rowData.secondaryClosingQty != null
                    ? rowData.secondaryClosingQty.toString()
                    : "",
                secondaryDistributorSellQty:
                  rowData.secondaryDistributorSellQty != null
                    ? rowData.secondaryDistributorSellQty
                    : "",
                secondaryGrnQty:
                  rowData.secondaryGrnQty != null ? rowData.secondaryGrnQty : "",
                secondaryInStockQty:
                  rowData.secondaryInStockQty != null
                    ? rowData.secondaryInStockQty
                    : "",
                secondaryOpeningQty:
                  rowData.secondaryOpeningQty != null
                    ? rowData.secondaryOpeningQty
                    : "",
                secondaryOutStockQty:
                  rowData.secondaryOutStockQty != null
                    ? rowData.secondaryOutStockQty
                    : "",
                secondaryOutletSellQty:
                  rowData.secondaryOutletSellQty != null
                    ? rowData.secondaryOutletSellQty
                    : "",
                secondaryPurchaseQty:
                  rowData.secondaryPurchaseQty != null
                    ? rowData.secondaryPurchaseQty
                    : "",
              };
              return batchData;
            });
            dispatch(itemwiseBatchDataSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getItemwiseBatchByProductAndStockHolderId =
  ({ productId, orderDate, stockStakeHolderId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/get-batch-wise-stock?productId=" +
            productId +
            "&date=" +
            orderDate +
            "&stockStakeHolderId=" +
            stockStakeHolderId,
        }).then(({ data, success }) => {
          if (success) {
            const content = data.data;
            const row = content.map((rowData, index) => {
              let batchData = {
                index: index + 1,
                batch: rowData.batch != null ? rowData.batch : "",
                name: rowData.batch != null ? rowData.batch : "",
                id: rowData.batch != null ? rowData.batch : "",
                primaryClosingQty:
                  rowData.primaryClosingQty != null
                    ? rowData.primaryClosingQty.toString()
                    : "",
                primaryDistributorSellQty:
                  rowData.primaryDistributorSellQty != null
                    ? rowData.primaryDistributorSellQty
                    : "",
                primaryGrnQty:
                  rowData.primaryGrnQty != null ? rowData.primaryGrnQty : "",
                primaryInStockQty:
                  rowData.primaryInStockQty != null
                    ? rowData.primaryInStockQty
                    : "",
                primaryOpeningQty:
                  rowData.primaryOpeningQty != null
                    ? rowData.primaryOpeningQty
                    : "",
                primaryOutStockQty:
                  rowData.primaryOutStockQty != null
                    ? rowData.primaryOutStockQty
                    : "",
                primaryOutletSellQty:
                  rowData.primaryOutletSellQty != null
                    ? rowData.primaryOutletSellQty
                    : "",
                primaryPurchaseQty:
                  rowData.primaryPurchaseQty != null
                    ? rowData.primaryPurchaseQty
                    : "",
                secondaryClosingQty:
                  rowData.secondaryClosingQty != null
                    ? rowData.secondaryClosingQty.toString()
                    : "",
                secondaryDistributorSellQty:
                  rowData.secondaryDistributorSellQty != null
                    ? rowData.secondaryDistributorSellQty
                    : "",
                secondaryGrnQty:
                  rowData.secondaryGrnQty != null ? rowData.secondaryGrnQty : "",
                secondaryInStockQty:
                  rowData.secondaryInStockQty != null
                    ? rowData.secondaryInStockQty
                    : "",
                secondaryOpeningQty:
                  rowData.secondaryOpeningQty != null
                    ? rowData.secondaryOpeningQty
                    : "",
                secondaryOutStockQty:
                  rowData.secondaryOutStockQty != null
                    ? rowData.secondaryOutStockQty
                    : "",
                secondaryOutletSellQty:
                  rowData.secondaryOutletSellQty != null
                    ? rowData.secondaryOutletSellQty
                    : "",
                secondaryPurchaseQty:
                  rowData.secondaryPurchaseQty != null
                    ? rowData.secondaryPurchaseQty
                    : "",
              };
              return batchData;
            });
            dispatch(itemwiseBatchDataSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getItemwiseBatchByProductAndStockHolderIdNew =
  ({ productId, orderDate, stockStakeHolderId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL1 +
            "/get-detail-by-product-and-stock-holder-and-date?productId=" +
            productId +
            "&date=" +
            orderDate +
            "&stockStakeHolderId=" +
            stockStakeHolderId,
        }).then(({ data, success }) => {
          if (success) {
            const content = data.data;
            const row = content.map((rowData, index) => {
              let batchData = {
                index: index + 1,
                batch: rowData.batchCode != null ? rowData.batchCode : "",
                name: rowData.batchCode != null ? rowData.batchCode : "",
                id: rowData.id != null ? rowData.id : "",
                qty:
                  rowData.qty != null
                    ? rowData.qty.toString()
                    : "",
                billNo:
                  rowData.billNo != null
                    ? rowData.billNo
                    : "",
                billDate:
                  rowData.billDate != null ? rowData.billDate : "",
                hsnCode:
                  rowData.hsnCode != null
                    ? rowData.hsnCode
                    : "",
                rate:
                  rowData.rate != null
                    ? rowData.rate
                    : "",
                secondaryFreeQty:
                  rowData.secondaryFreeQty != null
                    ? rowData.secondaryFreeQty
                    : "",
                secondaryMrp:
                  rowData.secondaryMrp != null
                    ? rowData.secondaryMrp
                    : "",
                secondaryQty:
                  rowData.secondaryQty != null
                    ? rowData.secondaryQty
                    : "",
                sgstAmt:
                  rowData.sgstAmt != null
                    ? rowData.sgstAmt.toString()
                    : "",
                stakeHolderUomName:
                  rowData.stakeHolderUomName != null
                    ? rowData.stakeHolderUomName
                    : "",
                cgstAmt:
                  rowData.cgstAmt != null ? rowData.cgstAmt : "",
                taxableAmt:
                  rowData.taxableAmt != null
                    ? rowData.taxableAmt
                    : "",
                totalAmt:
                  rowData.totalAmt != null
                    ? rowData.totalAmt
                    : "",
                secondaryOutStockQty:
                  rowData.secondaryOutStockQty != null
                    ? rowData.secondaryOutStockQty
                    : "",
                uomConversionValue:
                  rowData.uomConversionValue != null
                    ? rowData.uomConversionValue
                    : "",
                uomName:
                  rowData.uomName != null
                    ? rowData.uomName
                    : "",
                taxPercentageId: rowData.taxPercentageId != null
                  ? rowData.taxPercentageId
                  : "",
                productId: rowData.productId != null
                  ? rowData.productId
                  : "",
                productName: rowData.productName != null
                  ? rowData.productName
                  : "",
                mrp: rowData.mrp != null
                  ? rowData.mrp
                  : "",
                igstAmt: rowData.igstAmt != null
                  ? rowData.igstAmt
                  : "",
                code: rowData.code != null
                  ? rowData.code
                  : "",
                billDetailId: rowData.billDetailId != null
                  ? rowData.billDetailId
                  : "",
              };
              return batchData;
            });
            dispatch(itemwiseBatchDataSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const setQuantityData =
  ({ row }) =>
    async (dispatch) => {
      dispatch(stockConsumptionDetailSuccess({ row }));
    };
export const getMultipleStock =
  ({ dataForPost }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/get-stock-stake-holders-stock",
          postBody: dataForPost,
        }).then(({ data, success }) => {
          if (success) {
            dispatch(multipleStockSuccess({ row: data.data }));
            return { response: data.data, success };
          }
          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getMultipleStockPost =
  ({ multipleStockData }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/get-stock-holders-stock",
          postBody: multipleStockData,
        }).then(({ data, success }) => {
          if (success) {
            dispatch(multipleStockSuccess({ row: data.data }));
            return { response: data.data, success };
          }
          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getDetail =
  ({ fromdate, todate, productId, flag }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/get-detail?fromDate=" +
            fromdate +
            "&toDate=" +
            todate +
            "&productId=" +
            productId +
            "&flag=" +
            flag,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((detailObject, index) => {
              let detailData = {
                index: index + 1,
                id: detailObject.id === null ? "" : detailObject.id,
                date: detailObject.date === null ? "" : detailObject.date,
                outLetName:
                  detailObject.outLet === null
                    ? ""
                    : detailObject.outLet.firmName,
                outletOwnerName:
                  detailObject.outLet === null
                    ? ""
                    : detailObject.outLet.ownerName,

                transactionNo:
                  detailObject.transactionNo === null
                    ? ""
                    : detailObject.transactionNo,
                consumptionNo:
                  detailObject.consumptionNo === null
                    ? ""
                    : detailObject.consumptionNo,
                billNo: detailObject.billNo === null ? "" : detailObject.billNo,
                productId:
                  detailObject.product === null ? "" : detailObject.product.id,
                productName:
                  detailObject.product === null ? "" : detailObject.product.name,
                groupName:
                  detailObject.product === null
                    ? ""
                    : detailObject.product.group.name,
                packagingTypeName:
                  detailObject.product === null
                    ? ""
                    : detailObject.product.packagingType.name,
                subCategoryName:
                  detailObject.product === null
                    ? ""
                    : detailObject.product.group.subCategory.name,
                categoryName:
                  detailObject.product === null
                    ? ""
                    : detailObject.product.group.subCategory.category.name,
                brandName:
                  detailObject.product === null
                    ? ""
                    : detailObject.product.group.brand.name,
                productShortName:
                  detailObject.product === null
                    ? ""
                    : detailObject.product.shortName,
                productCode:
                  detailObject.product === null ? "" : detailObject.product.code,
                tagIds:
                  detailObject.product === null
                    ? ""
                    : detailObject.product.tagIds,
                outLetType:
                  detailObject.outLet === null
                    ? ""
                    : detailObject.outLet.outLetType.name,
                primaryStock:
                  detailObject.primaryStock === null
                    ? ""
                    : detailObject.primaryStock,
                secondaryStock:
                  detailObject.secondaryStock === null
                    ? ""
                    : detailObject.secondaryStock,
                fromBill:
                  detailObject.fromBill === null ? "" : detailObject.fromBill,
                fromBillFirmName:
                  detailObject.fromBillFirmName === null
                    ? ""
                    : detailObject.fromBillFirmName,
                fromBillOwnerName:
                  detailObject.fromBillOwnerName === null
                    ? ""
                    : detailObject.fromBillOwnerName,
                fromBillAreaNames:
                  detailObject.fromBillAreaNames === null
                    ? ""
                    : detailObject.fromBillAreaNames,
                fromBillStateName:
                  detailObject.fromBillStateName === null
                    ? ""
                    : detailObject.fromBillStateName,
                stockStakeHolder:
                  detailObject.stockStakeHolder === null
                    ? ""
                    : detailObject.stockStakeHolder,
                stockStakeHolderOwnerName:
                  detailObject.stockStakeHolderOwnerName === null
                    ? ""
                    : detailObject.stockStakeHolderOwnerName,
                stockStakeHolderFirmName:
                  detailObject.stockStakeHolderFirmName === null
                    ? ""
                    : detailObject.stockStakeHolderFirmName,

                stockStakeHolderAreaNames:
                  detailObject.stockStakeHolderAreaNames === null
                    ? ""
                    : detailObject.stockStakeHolderAreaNames,
                stockStakeHolderStateName:
                  detailObject.stockStakeHolderStateName === null
                    ? ""
                    : detailObject.stockStakeHolderStateName,
                grnGvnNo:
                  detailObject.grnGvnNo === null ? "" : detailObject.grnGvnNo,
                fromStockStakeHolder:
                  detailObject.fromStockStakeHolder === null
                    ? ""
                    : detailObject.fromStockStakeHolder,
                toStockStakeHolder:
                  detailObject.toStockStakeHolder === null
                    ? ""
                    : detailObject.toStockStakeHolder,
                toStockStakeHolderFirmName:
                  detailObject.toStockStakeHolderFirmName === null
                    ? ""
                    : detailObject.toStockStakeHolderFirmName,
                toStockStakeHolderOwnerName:
                  detailObject.toStockStakeHolderOwnerName === null
                    ? ""
                    : detailObject.toStockStakeHolderOwnerName,
                toStockStakeHolderAreaNames:
                  detailObject.toStockStakeHolderAreaNames === null
                    ? ""
                    : detailObject.toStockStakeHolderAreaNames,
                toStockStakeHolderStateName:
                  detailObject.toStockStakeHolderStateName === null
                    ? ""
                    : detailObject.toStockStakeHolderStateName,
                toBill:
                  detailObject.toBill === null ? "" : detailObject.toBill.id,
                ownerName:
                  detailObject.toBill === null
                    ? ""
                    : detailObject.toBill.ownerName,
                firmName:
                  detailObject.toBill === null
                    ? ""
                    : detailObject.toBill.firmName,
                contactPersonName:
                  detailObject.toBill === null
                    ? ""
                    : detailObject.toBill.contactPersonName,
                address:
                  detailObject.toBill === null ? "" : detailObject.toBill.address,
                billName:
                  detailObject.toBill === null
                    ? ""
                    : detailObject.toBill.billName,
                upi: detailObject.toBill === null ? "" : detailObject.toBill.upi,
                gstNo:
                  detailObject.toBill === null ? "" : detailObject.toBill.gstNo,
                panNo:
                  detailObject.toBill === null ? "" : detailObject.toBill.panNo,
                creditLimit:
                  detailObject.toBill === null
                    ? ""
                    : detailObject.toBill.creditLimit,
                gstType:
                  detailObject.toBill === null ? "" : detailObject.toBill.gstType,
                fssiFdiNo:
                  detailObject.toBill === null
                    ? ""
                    : detailObject.toBill.fssiFdiNo,
                orderTo:
                  detailObject.toBill === null ? "" : detailObject.toBill.orderTo,
                accessRoleJson:
                  detailObject.toBill === null
                    ? ""
                    : detailObject.toBill.accessRoleJson,
                isActive:
                  detailObject.toBill === null
                    ? ""
                    : detailObject.toBill.isActive,
                fromDate:
                  detailObject.toBill === null
                    ? ""
                    : detailObject.toBill.fromDate,
                toDate:
                  detailObject.toBill === null ? "" : detailObject.toBill.toDate,
                expiryDate:
                  detailObject.toBill === null
                    ? ""
                    : detailObject.toBill.expiryDate,
                appointedDate:
                  detailObject.toBill === null
                    ? ""
                    : detailObject.toBill.appointedDate,
                uomConversionValue:
                  detailObject.product === null
                    ? ""
                    : detailObject.product.uomConversionValue,
                stakeHolderUom:
                  detailObject.product === null
                    ? ""
                    : detailObject.product.stakeHolderUom.name,
                outletUom:
                  detailObject.product === null
                    ? ""
                    : detailObject.product.outletUom.name,
                batchCodeClosingQty:
                  detailObject.batchCode === null ? "" : detailObject.batchCode,

                boxQty:
                  detailObject.secondaryStock === null ||
                    detailObject.product === null ||
                    detailObject.product.uomConversionValue === null
                    ? "-"
                    : detailObject.secondaryStock /
                    detailObject.product.uomConversionValue,

                pcsQty:
                  detailObject.secondaryStock === null ||
                    detailObject.product === null ||
                    detailObject.product.uomConversionValue === null
                    ? "-"
                    : detailObject.secondaryStock %
                    detailObject.product.uomConversionValue,

                qty:
                  Math.floor(
                    detailObject.secondaryStock === null ||
                      detailObject.product === null ||
                      detailObject.product.uomConversionValue === null
                      ? "-"
                      : detailObject.secondaryStock /
                      detailObject.product.uomConversionValue
                  ) +
                  " " +
                  (detailObject.product === null ||
                    detailObject.product.stakeHolderUom === null
                    ? "-"
                    : detailObject.product.stakeHolderUom.name) +
                  " " +
                  (detailObject.secondaryStock === null ||
                    detailObject.product === null ||
                    detailObject.product.uomConversionValue === null
                    ? "-"
                    : detailObject.secondaryStock %
                    detailObject.product.uomConversionValue) +
                  " " +
                  (detailObject.product === null ||
                    detailObject.product.outletUom === null ||
                    detailObject.product.uomConversionValue === null
                    ? "-"
                    : detailObject.product.outletUom.name +
                    " (" +
                    detailObject.product.uomConversionValue +
                    ")"),

                batchCode:
                  detailObject.batchCode === null ? "" : detailObject.batchCode,
                ...detailObject,
              };

              return detailData;
            });
            dispatch(detailSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getDetailUpdated =
  ({ fromdate, todate, productId, flag }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/get-stock-detail?fromDate=" +
            fromdate +
            "&toDate=" +
            todate +
            "&productId=" +
            productId +
            "&flag=" +
            flag,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((detailObject, index) => {
              let detailData = {
                index: index + 1,
                id: detailObject.id === null ? "" : detailObject.id,
                date: detailObject.date === null ? "" : detailObject.date,
                outLetName:
                  detailObject.outletFirmName === null ? "" : detailObject.outletFirmName,
                outletOwnerName:
                  detailObject.outletOwnerName === null ? "" : detailObject.outletOwnerName,

                transactionNo:
                  detailObject.transactionNo === null
                    ? ""
                    : detailObject.transactionNo,
                consumptionNo:
                  detailObject.consumptionNo === null
                    ? ""
                    : detailObject.consumptionNo,
                billNo: detailObject.billNo === null ? "" : detailObject.billNo,
                productId:
                  detailObject.productId === null ? "" : detailObject.productId,
                productName:
                  detailObject.productName === null
                    ? ""
                    : detailObject.productName,
                groupName:
                  detailObject.groupName === null ? "" : detailObject.groupName,
                packagingTypeName:
                  detailObject.packagingTypeName === null
                    ? ""
                    : detailObject.packagingTypeName,
                subCategoryName:
                  detailObject.subCategoryName === null
                    ? ""
                    : detailObject.subCategoryName,
                categoryName:
                  detailObject.categoryName === null
                    ? ""
                    : detailObject.categoryName,
                // brandName:
                //   detailObject.product === null
                //     ? ""
                //     : detailObject.product.group.brand.name,
                // productShortName:
                //   detailObject.product === null
                //     ? ""
                //     : detailObject.product.shortName,
                productCode:
                  detailObject.productCode === null
                    ? ""
                    : detailObject.productCode,
                tagIds: detailObject.tagIds === null ? "" : detailObject.tagIds,
                outLetType:
                  detailObject.outLetType === null ? "" : detailObject.outLetType,
                primaryStock:
                  detailObject.primaryStock === null
                    ? ""
                    : detailObject.primaryStock,
                secondaryStock:
                  detailObject.secondaryStock === null
                    ? ""
                    : detailObject.secondaryStock,
                fromBill:
                  detailObject.fromBill === null ? "" : detailObject.fromBill,
                fromBillFirmName:
                  detailObject.fromBillFirmName === null
                    ? ""
                    : detailObject.fromBillFirmName,
                fromBillOwnerName:
                  detailObject.fromBillOwnerName === null
                    ? ""
                    : detailObject.fromBillOwnerName,
                fromBillAreaNames:
                  detailObject.fromBillAreaNames === null
                    ? ""
                    : detailObject.fromBillAreaNames,
                fromBillStateName:
                  detailObject.fromBillStateName === null
                    ? ""
                    : detailObject.fromBillStateName,
                stockStakeHolder:
                  detailObject.stockStakeHolder === null
                    ? ""
                    : detailObject.stockStakeHolder,
                stockStakeHolderOwnerName:
                  detailObject.stockStakeHolderOwnerName === null
                    ? ""
                    : detailObject.stockStakeHolderOwnerName,
                stockStakeHolderFirmName:
                  detailObject.stockStakeHolderFirmName === null
                    ? ""
                    : detailObject.stockStakeHolderFirmName,

                stockStakeHolderAreaNames:
                  detailObject.stockStakeHolderAreaNames === null
                    ? ""
                    : detailObject.stockStakeHolderAreaNames,
                stockStakeHolderStateName:
                  detailObject.stockStakeHolderStateName === null
                    ? ""
                    : detailObject.stockStakeHolderStateName,
                grnGvnNo:
                  detailObject.grnGvnNo === null ? "" : detailObject.grnGvnNo,
                fromStockStakeHolder:
                  detailObject.fromStockStakeHolder === null
                    ? ""
                    : detailObject.fromStockStakeHolder,
                toStockStakeHolder:
                  detailObject.toStockStakeHolder === null
                    ? ""
                    : detailObject.toStockStakeHolder,
                toStockStakeHolderFirmName:
                  detailObject.toStockStakeHolderFirmName === null
                    ? ""
                    : detailObject.toStockStakeHolderFirmName,
                toStockStakeHolderOwnerName:
                  detailObject.toStockStakeHolderOwnerName === null
                    ? ""
                    : detailObject.toStockStakeHolderOwnerName,
                toStockStakeHolderAreaNames:
                  detailObject.toStockStakeHolderAreaNames === null
                    ? ""
                    : detailObject.toStockStakeHolderAreaNames,
                toStockStakeHolderStateName:
                  detailObject.toStockStakeHolderStateName === null
                    ? ""
                    : detailObject.toStockStakeHolderStateName,
                toBill:
                  detailObject.toBill === null ? "" : detailObject.toBill.id,
                ownerName:
                  detailObject.toBillOwnerName === null
                    ? ""
                    : detailObject.toBillOwnerName,
                firmName:
                  detailObject.toBillFirmName === null
                    ? ""
                    : detailObject.toBillFirmName,
                contactPersonName:
                  detailObject.toBill === null
                    ? ""
                    : detailObject.toBill.contactPersonName,
                address:
                  detailObject.toBill === null ? "" : detailObject.toBill.address,
                billName:
                  detailObject.toBill === null
                    ? ""
                    : detailObject.toBill.billName,
                upi: detailObject.toBill === null ? "" : detailObject.toBill.upi,
                gstNo:
                  detailObject.toBill === null ? "" : detailObject.toBill.gstNo,
                panNo:
                  detailObject.toBill === null ? "" : detailObject.toBill.panNo,
                creditLimit:
                  detailObject.toBill === null
                    ? ""
                    : detailObject.toBill.creditLimit,
                gstType:
                  detailObject.toBill === null ? "" : detailObject.toBill.gstType,
                fssiFdiNo:
                  detailObject.toBill === null
                    ? ""
                    : detailObject.toBill.fssiFdiNo,
                orderTo:
                  detailObject.toBill === null ? "" : detailObject.toBill.orderTo,
                accessRoleJson:
                  detailObject.toBill === null
                    ? ""
                    : detailObject.toBill.accessRoleJson,
                isActive:
                  detailObject.toBill === null
                    ? ""
                    : detailObject.toBill.isActive,
                fromDate:
                  detailObject.toBill === null
                    ? ""
                    : detailObject.toBill.fromDate,
                toDate:
                  detailObject.toBill === null ? "" : detailObject.toBill.toDate,
                expiryDate:
                  detailObject.toBill === null
                    ? ""
                    : detailObject.toBill.expiryDate,
                appointedDate:
                  detailObject.toBill === null
                    ? ""
                    : detailObject.toBill.appointedDate,
                uomConversionValue:
                  detailObject.uomConversionValue === null
                    ? ""
                    : detailObject.uomConversionValue,
                stakeHolderUom:
                  detailObject.stakeHolderUomName === null
                    ? ""
                    : detailObject.stakeHolderUomName,
                outletUom:
                  detailObject.outletUomName === null
                    ? ""
                    : detailObject.outletUomName,
                batchCodeClosingQty:
                  detailObject.batchCode === null ? "" : detailObject.batchCode,

                boxQty:
                  detailObject.secondaryStock === null ||
                    detailObject.product === null ||
                    detailObject.uomConversionValue === null
                    ? "-"
                    : detailObject.secondaryStock /
                    detailObject.uomConversionValue,

                pcsQty:
                  detailObject.secondaryStock === null ||
                    detailObject.uomConversionValue === null
                    ? "-"
                    : detailObject.secondaryStock %
                    detailObject.uomConversionValue,

                qty:
                  Math.floor(
                    detailObject.secondaryStock === null ||
                      detailObject.uomConversionValue === null
                      ? "-"
                      : detailObject.secondaryStock /
                      detailObject.uomConversionValue
                  ) +
                  " " +
                  (detailObject.stakeHolderUomName === null
                    ? "-"
                    : detailObject.stakeHolderUomName) +
                  " " +
                  (detailObject.secondaryStock === null ||
                    detailObject.uomConversionValue === null
                    ? "-"
                    : detailObject.secondaryStock %
                    detailObject.uomConversionValue) +
                  " " +
                  (detailObject.outletUomName === null ||
                    detailObject.uomConversionValue === null
                    ? "-"
                    : detailObject.outletUomName +
                    " (" +
                    detailObject.uomConversionValue +
                    ")"),

                batchCode:
                  detailObject.batchCode === null ? "" : detailObject.batchCode,
                ...detailObject,
              };

              return detailData;
            });
            dispatch(detailSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
