import { withStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { DimGrayColor, white } from "../../config/ColorObj";
import {
    noInternetMsg,
    serverMsg
} from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { apiGet } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
const styles = (theme) => ({
    root: {
        height: "100vh",
    },
    top: {
        position: "absolute",
        right: 0,
        top: 0,
    },
    bottom: {
        position: "absolute",
        left: 0,
        bottom: 0,
    },
    ellipse: {
        // position: "static",
        // width: "517px",
        // height: "350px",
        // left: "10px",
        // top: "10px",

        // background: "rgba(41, 151, 171, 0.2)",
        // flex: "none",
        // order: "0",
        // flexgrow: "0",
        justifyContent: "center",
    },
    loginBox: {
        display: "flex",
        border: `1px solid ${theme.palette.grey[400]}`,
        margin: "50px",
        width: "auto",
        zIndex: "1",
        backgroundColor: "white",
    },
    text: {
        fontfamily: "Roboto",
        fontstyle: "normal",
        fontweight: "bold",
        fontsize: "32px",
        textAlign: "center",

        color: white,
    },
    bmn: {
        padding: "30px",
        justifyContent: "center",

        // alignSelf:'center',
    },
    left: {
        //  width: "50%",
    },
    hello: {
        textAlign: "center",
        top: "60px",
        left: "50px",
    },
    helper: {
        textAlign: "center",
        height: "56px",
        fontfamily: "Roboto",
        fontstyle: "normal",
        fontweight: "normal",
        fontsize: "18px",
        lineheight: "28px",
        color: DimGrayColor,
    },
    field: {
        padding: "10px 50px",
        margin: "50px",
    },
    stay: {
        fontfamily: "Roboto",
        fontstyle: "normal",
        fontweight: "normal",
        fontsize: "14px",
        lineheight: "28px",
        paddingLeft: "20px",

        color: DimGrayColor,
    },
    ellipse2: {
        width: "504px",
        height: "504px",
        left: "-139px",
        top: "500px",

        background: white,
        border: "80px solid #2997AB",
        boxsizing: "border-box",
    },
    fogot: {
        left: "500px",
    },
    body: { backgroundColor: "#f5f5f5" },
});


const Maintainance = ({

    showLoador,
    showNotification,

}) => {

    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        if (navigator.onLine) {
            setShowLoading(true);
            apiGet({
                url: endpoint.setting + "/by-key?key=is_maintainance",
            }).then(({ data, success }) => {
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                } else {
                    if (data !== null && data.data !== null && Object.keys(data.data).length !== 0) {
                        if (data.data.settingValue == 0) {
                            window.location.replace("/login");
                        }
                    } else {
                        setShowLoading(false);
                        showNotification({
                            msg: serverMsg,
                            severity: "error",
                        });
                    }
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }, []);





    return (
        <>
            <div className="maintainance_logo"></div>
        </>
    );
};
const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {
    showNotification,
    showLoador,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Maintainance));


