import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { CountryColumns } from "../../../tableColumns/table-columns";
import { CountryJson } from "../../../DynamicFormsJson/MastersJSON/country";
import { getCountry } from "../../../Slice/country.slice";
import endpoint from "../../../config/endpoints";

class Country extends React.Component {
    constructor(props) {
        super(props);
    }




    render() {
        const { country, getCountry } = this.props;
        return (
            <>
                <DynamicMainScreen
                    formPath={CountryJson.formPath}
                    screenTitle={CountryJson.screenTitle}
                    fieldMeta={CountryJson.fieldMeta}
                    showPdfDownload={CountryJson.showPdfDownload}
                    showExcelDownload={true}
                    changeExcelURL={"contry-data" + '?sort={"sortOrder": "ASC"}'}
                    showExtension={false}
                    pdfFileName={CountryJson.pdfFileName}
                    excelFileName={CountryJson.excelFileName}
                    showAddButton={CountryJson.showAddButton}
                    tableColumnsToFilter={CountryJson.tableColumnsToFilter}
                    tableColumns={CountryColumns}
                    tableData={country?.country}
                    getTableData={getCountry}
                    apiBaseURL={CountryJson.apiBaseURL}
                    baseIdColumn={CountryJson.baseIdColumn}
                    isNote={true}
                    isNoteValue={"Country"}
                    showDeleteIcon={false}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    country: state.country,
});
const mapDispatchToProps = {
    getCountry,
};
export default connect(mapStateToProps, mapDispatchToProps)(Country);
