import { Checkbox, Paper } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { dayWiseBeatAssignJson } from "../../DynamicFormsJson/Transaction/DayWiseBeatAssign";
import { getAreaByUserType } from "../../Slice/area.slice";
import { getBeatBySalesTeamId } from "../../Slice/beat.slice";
import { getSalesExecutiveByAreaId } from "../../Slice/salesExecutive.slice";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { blackColor, checkboxColor } from "../../config/ColorObj";
class DayWiseBeatAssignView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
      beatAssignList: [],
      salesTeamId: "",
      dynamicMasterData: {
        areaDetail: this.props.areaList.area,
        salesExecutiveDetail: this.props.salesExecutive.salesExecutive,
      },
      matrixData: [],
      dayList: [
        {
          dayId: 0,
          name: "Monday",
        },
        {
          dayId: 1,
          name: "Tuesday",
        },
        {
          dayId: 2,
          name: "Wednesday",
        },
        {
          dayId: 3,
          name: "Thursday",
        },
        {
          dayId: 4,
          name: "Friday",
        },
        {
          dayId: 5,
          name: "Saturday",
        },
        {
          dayId: 6,
          name: "Sunday",
        },
      ],
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.beatList !== nextProps.beatList) {
      if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
        this.setMatrix(nextProps.beatList?.beat);
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.area !== nextProps.areaList?.area) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.area,
          },
        });
      }
    }
    if (this.props.salesExecutive !== nextProps.salesExecutive) {
      if (
        this.props.salesExecutive?.salesExecutive !==
        nextProps.salesExecutive?.salesExecutive
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            salesExecutiveDetail: nextProps.salesExecutive?.salesExecutive,
          },
        });
      }
    }
  }
  setMatrix = (beatList) => {
    const { dayList, beatAssignList } = this.state;
    const matList = dayList.map((dayObj) => {
      let matObj = {
        ...dayObj,
      };
      let filterDay = [];
      if (beatAssignList.length != 0) {
        filterDay = beatAssignList.filter(
          (rowData) => dayObj.dayId == rowData.day
        );
      }
      const beatListData = beatList.map((beatObj) => {
        let filterBeat = [];
        if (filterDay.length != 0) {
          filterBeat = filterDay[0].beatAssignDaywiseDetailBeat.filter(
            (rowData) => beatObj.beatId == rowData.beat.id
          );
        }
        let beatDataObj = {
          ...beatObj,
          beatName: beatObj.name,
          checked: filterBeat.length != 0 ? true : false,
          disabled: true,
        };
        if (filterBeat.length != 0) {
          beatDataObj = {
            ...beatDataObj,
            id: filterBeat[0].id,
          };
        }
        return beatDataObj;
      });
      matObj = {
        ...matObj,
        beatListData: beatListData,
      };
      if (filterDay.length != 0) {
        matObj = {
          ...matObj,
          id: filterDay[0].id,
        };
      }
      return matObj;
    });
    this.setState({
      matrixData: matList,
    });
  };
  async componentDidMount() {
    if (this.props.params.id) {
      this.getDataById(this.props.params.id);
    }
  }
  getDataById = (dayWiseBitAssignId) => {
    const { showLoador } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      const response = apiGet({
        url: endpoint.dayWiseBitAssign + "/" + dayWiseBitAssignId,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          if (!data.error) {
            let rowData = data.data;
            this.setState({
              DataToSet: [
                {
                  xs: 12,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  label: "Sales Executive",
                  value:
                    data.data.salesTeam !== null &&
                    data.data.salesTeam.name !== null
                      ? data.data.salesTeam.name
                      : "",
                },
              ],
              beatAssignList: rowData.beatAssignDaywiseDetail,
            });
            this.onSearch(rowData);

            return { data: rowData, success };
          }
          return { data: {}, success: false };
        }

        return { data: {}, success: false };
      });
      return response;
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onSearch = (data) => {
    const { getBeatBySalesTeamId, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      this.setState({
        salesTeamId: data.salesTeam.id,
      });
      showLoador({ loador: true });
      getBeatBySalesTeamId({
        salesTeamId: data.salesTeam.id,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  render() {
    const { matrixData, dynamicMasterData, DataToSet, dayList } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            DataToSet={DataToSet}
            dynamicMasterData={dynamicMasterData}
            showSaveBtn={dayWiseBeatAssignJson.showSaveBtn}
            showTitle={dayWiseBeatAssignJson.showTitle}
            screenTitle={dayWiseBeatAssignJson.screenTitle}
            fieldMeta={
              this.props.params.id ? [] : dayWiseBeatAssignJson.fieldMeta
            }
            showCancel={dayWiseBeatAssignJson.showCancel}
            apiBaseURL={dayWiseBeatAssignJson.apiBaseURL}
            showSaveNextBtn={dayWiseBeatAssignJson.showSaveNextBtn}
            saveBtnText={"Search"}
            showSaveBtnMain={this.props.params.id ? false : true}
          />
          {matrixData.length != 0 && (
            <>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" sx={{ width: "5%" }}>
                        Days
                      </TableCell>

                      <TableCell align="center" sx={{ width: "95%" }}>
                        Beats
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {matrixData.map((matrixDataRow, indexRow) => {
                      return (
                        <TableRow>
                          <TableCell>{matrixDataRow.name}</TableCell>
                          <TableCell>
                            {matrixDataRow.beatListData.map(
                              (columnName, index) => {
                                return (
                                  <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                      <FormControlLabel
                                        value="end"
                                        control={
                                          <Checkbox
                                            sx={{
                                              color: blackColor,
                                              "&.Mui-checked": {
                                                color: checkboxColor,
                                              },
                                            }}
                                            disabled={columnName.disabled}
                                            checked={columnName.checked}
                                          />
                                        }
                                        label={columnName.beatName}
                                        labelPlacement="end"
                                      />
                                    </FormGroup>
                                  </FormControl>
                                );
                              }
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  areaList: state.area,
  beatList: state.beat,
  salesExecutive: state.salesExecutive,
});
const mapDispatchToProps = {
  getAreaByUserType,
  showLoador,
  showNotification,
  getSalesExecutiveByAreaId,
  getBeatBySalesTeamId,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DayWiseBeatAssignView);
