import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import EditIcon from "@mui/icons-material/Edit";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { compose } from "@reduxjs/toolkit";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { saveReport } from '../../Slice/report.slice';
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import { withRouter } from "../../components/withRouter";
import * as msgConstant from "../../config/messageconstant";
import * as myConstClass from "../../config/messageconstant";
import { noInternetMsg } from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
let rowList = [

];
class FilterDependantOnParameterTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            stackHolderType: "",
            stackHolderTypeName: "",
            accessRoleName: "",
            shortName: "",
            formError: {},
            error: false,
            openDialog: true,
        };
    }

    async componentDidMount() {
        const { getModuleAndSubModule, showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            //   showLoador({ loador: true });
            //   const moduleResp = await getModuleAndSubModule().then(
            //     ({ response, success }) => {
            //       showLoador({ loador: false });
            //       if (!success) {
            //         showNotification({ msg: serverMsg, severity: "error" });
            //       }
            //       return { response, success };
            //     }
            //   );
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }
    checkValidation = () => {
        const { accessRoleName, stackHolderType, shortName } = this.state;
        let formErrors = {};
        let formIsValid = true;

        if (accessRoleName === "" || accessRoleName === null) {
            formIsValid = false;
            formErrors["nameError"] = myConstClass.accessRoleMsg;
        }

        if (shortName === "" || shortName === null) {
            formIsValid = false;
            formErrors["shortNameError"] = myConstClass.shortNameMsg;
        }
        if (stackHolderType === "" || stackHolderType === null) {
            formIsValid = false;
            formErrors["stackHolderTypeError"] = myConstClass.stackHolderTypeMsg;
        }
        this.setState({
            formError: formErrors,
        });
        return formIsValid;
    };

    goBack = () => {
        this.props.navigate(-1);
    };
    changeHandler = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });
    };
    onClickDelete = (e, graphId) => {
        e.stopPropagation();
        this.rowDelete(graphId);
    };
    onClickEdit = (e, rowData, index) => {
        e.stopPropagation();
        this.props.rowEdit({ ...rowData, index });
    };

    rowDelete = (graphId) => {
        const { showNotification, showLoador } = this.props;
        swal({
            title: "Are you sure?",
            text: msgConstant.deleteWarningMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                if (navigator.onLine) {
                    let filterData = this.props.filterListParameter.filter((row) => row.graphId != graphId)
                    this.onSave({
                        ...this.props.report.reportData,
                        filterListParameters: JSON.stringify(filterData)
                    }
                    )
                } else {
                    showNotification({ msg: msgConstant.noInternetMsg, severity: "error" });
                }
            }
        });
    };
    onSave = (dataToSave) => {

        if (navigator.onLine) {
            const {
                saveReport,
                showLoador,
                showNotification,
            } = this.props;
            showLoador({ loador: true });
            saveReport({ dataToSave }).then(({ success }) => {
                showLoador({ loador: false });
                if (success) {
                    showNotification({
                        msg: dataToSave.id ? msgConstant.deletemsg : msgConstant.savemsg,
                    });

                } else {
                    showNotification({
                        msg: msgConstant.saveFailedMsg,
                        severity: "error",
                    });
                }
            });
        } else {
            showNotification({
                msg: msgConstant.noInternetMsg,
                severity: "error",
            });
        }
    };
    onTextFieldDataChange = (row, index) => (e) => {
        console.log(row)
        console.log(e.target.name)
        console.log(e.target.value)

        this.props.setFilterListParameter(this.props.filterListParameter.map((rowData, indexData) => {
            return { ...rowData, [e.target.name]: index == indexData ? e.target.value : rowData[e.target.name] }
        }))
    }
    render() {
        const { filterListParameter } = this.props;
        return (
            <>
                <Box sx={{ width: "100%" }}>
                    <TableContainer sx={{ maxHeight: 500 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width="10%" >Sr. No.</TableCell>
                                    <TableCell>Dependant Parameter Name</TableCell>
                                    <TableCell>Backend parameter</TableCell>
                                    <TableCell>FE parameter</TableCell>

                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {filterListParameter.map((row, index) => (
                                    <>
                                        <TableRow key={row.id}>
                                            <TableCell align="center">{index + 1}</TableCell>
                                            <TableCell scope="row">{row.label}</TableCell>
                                            <TableCell scope="row">
                                                <TextFieldCompo
                                                    placeHolder={"Key"}
                                                    size="small"
                                                    color="primary"
                                                    name={"key"}
                                                    id={"1"}
                                                    value={row.key}
                                                    onChange={this.onTextFieldDataChange(row, index)}
                                                    fullWidth={false}
                                                    errorText={""}
                                                />
                                            </TableCell>
                                            <TableCell scope="row">
                                                <TextFieldCompo
                                                    placeHolder={"Key"}
                                                    size="small"
                                                    color="primary"
                                                    name={"value"}
                                                    id={"1"}
                                                    value={row.value}
                                                    onChange={this.onTextFieldDataChange(row, index)}
                                                    fullWidth={false}
                                                    errorText={""}
                                                />
                                            </TableCell>

                                        </TableRow>

                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box >

            </>
        );
    }
}

const mapStateToProps = (state) => ({ report: state.report });
const mapDispatchToProps = { showLoador, showNotification, saveReport };

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(FilterDependantOnParameterTable);