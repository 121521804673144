import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";

const initialState = {
  stockStakeHolderType: [],
  salesPersonListToConfigList: [],
  stockStakeholderListToConfigList: [],
  stockStakeHolderWithoutType: [],
  stockStakeHolderTypeList: [],
  stockStakeHolderTypeAll: [],
};

let URL = endpoints.stockStakeHolderType;

const stockStakeHolderTypeSlice = createSlice({
  name: "stockStakeHolderType",
  initialState,
  reducers: {
    stockStakeHolderTypeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        stockStakeHolderType: row,
      };
    },
    salesPersonListToConfigSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        salesPersonListToConfigList: row,
      };
    },
    stockStakeholderListToConfigSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        stockStakeholderListToConfigList: row,
      };
    },
    stockStakeHolderTypeListSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        stockStakeHolderTypeList: row,
      };
    },

    stockStakeHolderTypeAllSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        stockStakeHolderTypeAll: row,
      };
    },

    stockStakeHolderWithoutTypeSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        stockStakeHolderWithoutType: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  stockStakeHolderTypeSuccess,
  stockStakeHolderWithoutTypeSuccess,
  stockStakeHolderTypeListSuccess,
  salesPersonListToConfigSuccess,
  stockStakeholderListToConfigSuccess,
  stockStakeHolderTypeAllSuccess,
  resetState,
} = stockStakeHolderTypeSlice.actions;

export default stockStakeHolderTypeSlice.reducer;

export const getStockStakeHolderType =
  ({ userTypeIds, reverseTrue = false }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-user-type-ids?userTypeIds=" +
          userTypeIds +
          '?sort={"sortOrderNo": "ASC"}',
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((stockStakeHolderTypeObject, index) => {
            let stockStakeHolderTypeData = {
              index: index + 1,
              id:
                stockStakeHolderTypeObject.id === null
                  ? ""
                  : stockStakeHolderTypeObject.id,
              name:
                stockStakeHolderTypeObject.name === null
                  ? ""
                  : stockStakeHolderTypeObject.name,
              sortOrder:
                stockStakeHolderTypeObject.sortOrder === null
                  ? ""
                  : stockStakeHolderTypeObject.sortOrder,
              isActive:
                stockStakeHolderTypeObject.isActive === null
                  ? ""
                  : stockStakeHolderTypeObject.isActive,
              userType:
                stockStakeHolderTypeObject.userType == null
                  ? ""
                  : stockStakeHolderTypeObject.userType,
            };
            return stockStakeHolderTypeData;
          });
          if (reverseTrue) {
            dispatch(stockStakeHolderTypeSuccess({ row: row.reverse() }));
          } else {
            dispatch(stockStakeHolderTypeSuccess({ row }));
          }
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
  export const getStockStakeHolderTypeNew =
  ({ userTypeIds, reverseTrue = false }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-user-type-ids?userTypeIds=" +
          userTypeIds +
          '?sort={"sortOrderNo": "ASC"}',
      }).then(({ data, success }) => {
        if (success) {
        
          if (reverseTrue) {
            dispatch(stockStakeHolderTypeSuccess({ row: data.data.reverse() }));
          } else {
            dispatch(stockStakeHolderTypeSuccess({ row :data.data}));
          }
          return { response: data.data, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getSalesPersonListToConfig =
  ({ stockStakeHolderTypeId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-not-configured-stock-stake-holder-type?stockStakeHolderTypeId=" +
          stockStakeHolderTypeId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((stockStakeHolderTypeObject, index) => {
            let stockStakeHolderTypeData = {
              index: index + 1,
              id:
                stockStakeHolderTypeObject.id === null
                  ? ""
                  : stockStakeHolderTypeObject.id,
              name:
                stockStakeHolderTypeObject.name === null
                  ? ""
                  : stockStakeHolderTypeObject.name,
              sortOrder:
                stockStakeHolderTypeObject.sortOrder === null
                  ? ""
                  : stockStakeHolderTypeObject.sortOrder,
              isActive:
                stockStakeHolderTypeObject.isActive === null
                  ? ""
                  : stockStakeHolderTypeObject.isActive,
            };
            return stockStakeHolderTypeData;
          });
          dispatch(salesPersonListToConfigSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStockStakeholderListToConfig =
  ({ orderToIds }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/get-not-configured-order-to-stake-holder-type?orderToIds=" +
          orderToIds,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((stockStakeHolderTypeObject, index) => {
            let stockStakeHolderTypeData = {
              index: index + 1,
              id:
                stockStakeHolderTypeObject.id === null
                  ? ""
                  : stockStakeHolderTypeObject.id,
              name:
                stockStakeHolderTypeObject.name === null
                  ? ""
                  : stockStakeHolderTypeObject.name,
              sortOrder:
                stockStakeHolderTypeObject.sortOrder === null
                  ? ""
                  : stockStakeHolderTypeObject.sortOrder,
              isActive:
                stockStakeHolderTypeObject.isActive === null
                  ? ""
                  : stockStakeHolderTypeObject.isActive,
            };
            return stockStakeHolderTypeData;
          });
          dispatch(stockStakeholderListToConfigSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStockStakeHolderTypeWithoutType = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((stockStakeHolderTypeObject, index) => {
          let stockStakeHolderTypeData = {
            index: index + 1,
            id:
              stockStakeHolderTypeObject.id === null
                ? ""
                : stockStakeHolderTypeObject.id,
            name:
              stockStakeHolderTypeObject.name === null
                ? ""
                : stockStakeHolderTypeObject.name,
            sortOrder:
              stockStakeHolderTypeObject.sortOrder === null
                ? ""
                : stockStakeHolderTypeObject.sortOrder,
            isActive:
              stockStakeHolderTypeObject.isActive === null
                ? ""
                : stockStakeHolderTypeObject.isActive,
          };
          return stockStakeHolderTypeData;
        });
        dispatch(stockStakeHolderWithoutTypeSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getStockStakeHolderTypeAll = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-all",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((stockStakeHolderTypeObject, index) => {
          let stockStakeHolderTypeData = {
            index: index + 1,
            id:
              stockStakeHolderTypeObject.id === null
                ? ""
                : stockStakeHolderTypeObject.id,
            name:
              stockStakeHolderTypeObject.name === null
                ? ""
                : stockStakeHolderTypeObject.name,
            sortOrder:
              stockStakeHolderTypeObject.sortOrder === null
                ? ""
                : stockStakeHolderTypeObject.sortOrder,
            isActive:
              stockStakeHolderTypeObject.isActive === null
                ? ""
                : stockStakeHolderTypeObject.isActive,
          };
          return stockStakeHolderTypeData;
        });
        dispatch(stockStakeHolderTypeAllSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getStockStakeHolderTypeForAccessRole = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/not-in-access-role",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((stockStakeHolderTypeObject, index) => {
          let stockStakeHolderTypeData = {
            index: index + 1,
            id:
              stockStakeHolderTypeObject.id === null
                ? ""
                : stockStakeHolderTypeObject.id,
            name:
              stockStakeHolderTypeObject.name === null
                ? ""
                : stockStakeHolderTypeObject.name,
            sortOrder:
              stockStakeHolderTypeObject.sortOrder === null
                ? ""
                : stockStakeHolderTypeObject.sortOrder,
            isActive:
              stockStakeHolderTypeObject.isActive === null
                ? ""
                : stockStakeHolderTypeObject.isActive,
          };
          return stockStakeHolderTypeData;
        });
        dispatch(stockStakeHolderTypeSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAccessRoleForLeft =
  ({ user }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/sales-team-and-stock-stake-holder-by-user-type",
      }).then(({ data, success }) => {
        if (success) {
          const moduleList =
            data.data.accessRole != null && data.data.accessRole.modules != null
              ? data.data.accessRole.modules
              : [];
          const row = moduleList.map((module) => {
            const iconDiv = module.iconDiv.split("@");
            module = {
              ...module,
              checked: false,
              baseClassName: iconDiv.length == 3 ? iconDiv[1] : "",
              className: iconDiv.length == 3 ? iconDiv[0] : "",
              color: iconDiv.length == 3 ? iconDiv[2] : "",
              moduleSubs: module.moduleSubs.map((subModule) => {
                subModule = {
                  ...subModule,
                  checked: false,
                };
                return subModule;
              }),
            };
            return module;
          });
          localStorage.setItem(
            "userName",
            data.data.name != null ? data.data.name : user
          );
          localStorage.setItem("moduleSubModule", JSON.stringify(row));
          return { response: data.data, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getStockStakeHolderTypeForList = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/by-order-level",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((stockStakeHolderTypeListObject, index) => {
          let stockStakeHolderTypeListData = {
            index: index + 1,
            id:
              stockStakeHolderTypeListObject.id === null
                ? ""
                : stockStakeHolderTypeListObject.id,
            name:
              stockStakeHolderTypeListObject.name === null
                ? ""
                : stockStakeHolderTypeListObject.name,
            userType:
              stockStakeHolderTypeListObject.userType === null
                ? ""
                : stockStakeHolderTypeListObject.userType,
            sortOrder:
              stockStakeHolderTypeListObject.sortOrder === null
                ? ""
                : stockStakeHolderTypeListObject.sortOrder,
            isActive:
              stockStakeHolderTypeListObject.isActive === null
                ? ""
                : stockStakeHolderTypeListObject.isActive,
          };
          return stockStakeHolderTypeListData;
        });
        dispatch(stockStakeHolderTypeListSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
