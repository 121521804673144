import { Checkbox, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import endpoint from "../../config/endpoints";
import { getStateByRegion } from "../../Slice/state.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getAreaByTalukaPostBody } from "../../Slice/area.slice";
import { getStockStakeHolderTypeForList, } from "../../Slice/stockStakeHolderType.slice";
import { getActiveLeaveStructure } from "../../Slice/leaveStructure.slice"
import {
    beatAssignedMsg,
    checkedListEmptyMsg,
    dateValidationMsg,
    noInternetMsg,
    saveFailedMsg,
    savemsg,
    saveWarningMsg,
    serverMsg,
} from "../../config/messageconstant";
import { leaveAllotmentJson } from "../../DynamicFormsJson/Transaction/leaveAllotmentJson";
import { getAreaByUserType } from "../../Slice/area.slice";
import { getSalesExecutiveTypeByAreaId } from "../../Slice/salesExecutive.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { getRegionUpdated } from "../../Slice/region.slice";
import { getSalesTeamWithLeaveStructure, setSalesTeamWithLeaveStructure } from "../../Slice/leaveStructure.slice";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import dayjs from "dayjs";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";

class LeaveAllotmentTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selection: [],
            salesTeamId: "",
            uncheckedBeatIdsFromSelectedList: "",
            isError: false,
            fromDate: dayjs(),
            toDate: dayjs(),
            structureFromDate: "",
            searchValue: "",
            structureToDate: "",
            allChecked: false,
            adminFlag: false,
            leaveStructureHeaderId: "",
            dynamicMasterData: {
                areaDetail: this.props.areaList.area,
                salesExecutiveDetail: this.props.salesExecutive.salesExecutive,
                beatDetail: this.props.beatList.beat,
            },
            openOutletDialog: false,
        };
    }
    componentWillReceiveProps(nextProps) {

        if (this.props.stockStakeHolderTypeList !== nextProps.stockStakeHolderTypeList) {
            if (this.props.stockStakeHolderTypeList?.stockStakeHolderTypeList !== nextProps.stockStakeHolderTypeList?.stockStakeHolderTypeList) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        stockStakeHolderTypeDetail: nextProps.stockStakeHolderTypeList?.stockStakeHolderTypeList,
                    },
                });
            }
        }
        if (this.props.leaveStructureList !== nextProps.leaveStructureList) {
            if (this.props.leaveStructureList?.activeleaveStructure !== nextProps.leaveStructureList?.activeleaveStructure) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        leaveStructureDetail: nextProps.leaveStructureList?.activeleaveStructure,
                    },
                });
            }
        }

        if (this.props.regionList !== nextProps.regionList) {
            if (this.props.regionList?.region !== nextProps.regionList?.region) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        regionDetail: nextProps.regionList?.region,
                    },
                });
            }
        }
        if (this.props.stateList !== nextProps.stateList) {
            if (
                this.props.stateList?.stateByRegion !==
                nextProps.stateList?.stateByRegion
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        stateDetail: nextProps.stateList?.stateByRegion,
                    },
                });
            }
        }
        if (this.props.zoneList !== nextProps.zoneList) {
            if (
                this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        zoneDetail: nextProps.zoneList?.zoneByState,
                    },
                });
            }
        }
        if (this.props.districtList !== nextProps.districtList) {
            if (
                this.props.districtList?.districtByZone !==
                nextProps.districtList?.districtByZone
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        districtDetail: nextProps.districtList?.districtByZone,
                    },
                });
            }
        }
        if (this.props.talukaList !== nextProps.talukaList) {
            if (
                this.props.talukaList?.talukaByDistrict !==
                nextProps.talukaList?.talukaByDistrict
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        talukaDetail: nextProps.talukaList?.talukaByDistrict,
                    },
                });
            }
        }
        if (this.props.areaList !== nextProps.areaList) {
            if (
                this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        areaDetail: nextProps.areaList?.areaByTaluka,
                    },
                });
            }
        }
        if (this.props.areaList !== nextProps.areaList) {
            if (this.props.areaList?.area !== nextProps.areaList?.area) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        areaDetail: nextProps.areaList?.area,
                    },
                });
            }
        }
        if (this.props.salesExecutive !== nextProps.salesExecutive) {
            if (
                this.props.salesExecutive?.salesExecutive !==
                nextProps.salesExecutive?.salesExecutive
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        salesExecutiveDetail: nextProps.salesExecutive?.salesExecutive,
                    },
                });
            }
        }

    }

    async componentDidMount() {
        const { getStockStakeHolderTypeForList, setSalesTeamWithLeaveStructure, getActiveLeaveStructure, getAreaByUserType, getRegionUpdated, showLoador, showNotification } =
            this.props;
        if (navigator.onLine) {
            const userTypeId = localStorage.getItem("userTypeId");
            if (userTypeId == 1 || userTypeId == 5 || userTypeId == 6) {

                this.setState({
                    adminFlag: true,
                });
            }


            setSalesTeamWithLeaveStructure({ row: [] });

            showLoador({ loador: true });
            await getStockStakeHolderTypeForList().then(({ success }) => {
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
            showLoador({ loador: true });
            await getActiveLeaveStructure().then(({ success }) => {
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });


            showLoador({ loador: false });
            if (userTypeId == 1 || userTypeId == 5 || userTypeId == 6) {

                showLoador({ loador: true });
                getRegionUpdated().then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }


            if (userTypeId == 3 || userTypeId == 4) {
                showLoador({ loador: true });
                getAreaByUserType().then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }

        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }
    getListById = async (data) => {
        const {
            getStateByRegion,
            getZoneByState,
            getDistrictByZone,
            getTalukaByDistrict,
            getAreaByTalukaPostBody,
            showLoador,
            showNotification,
        } = this.props;
        if (navigator.onLine) {
            if (data.region && data.region != "") {
                showLoador({ loador: true });
                await getStateByRegion({ regionIds: data.region }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
            if (data.state && data.state != "") {
                showLoador({ loador: true });
                await getZoneByState({ stateIds: data.state }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            if (data.zone && data.zone != "") {
                showLoador({ loador: true });
                await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            if (data.district && data.district != "") {
                showLoador({ loador: true });
                await getTalukaByDistrict({ districtIds: data.district }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
            if (data.taluka && data.taluka != "") {
                let talukaJson = {
                    talukaIds: data.taluka,
                };
                showLoador({ loador: true });
                await getAreaByTalukaPostBody({ talukaIds: talukaJson }).then(
                    ({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    }
                );
            }
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    onSearch = (data) => {
        console.log(data);
        const {
            getSalesTeamWithLeaveStructure,
            showLoador,
            showNotification,
        } = this.props;
        if (navigator.onLine) {

            this.setState({ leaveStructureHeaderId: data.leaveStructure });

            let dataObj = {
                stockStakeHolderTypeId: data.stockStakeHolderType,
                areaIds: data.area.split(","),
                leaveStructureHeaderId: data.leaveStructure
            }

            showLoador({ loador: true });
            getSalesTeamWithLeaveStructure({ dataJson: dataObj }).then(({ response, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };



    onSubmit = () => {
        const { leaveStructureList, showLoador, showNotification } = this.props;
        const { leaveStructureHeaderId, fromDate } = this.state;

        let saveList = [];

        let validFromDate = "";
        let validToDate = "";


        leaveStructureList.leaveStructureAllotment.map((obj) => {
            if (obj.isChecked) {

                validFromDate = new Date(obj.fromDate);
                validToDate = new Date(obj.toDate);

                if (obj.leaveStructureAllotmentId !== "") {
                    saveList.push({
                        id: obj.leaveStructureAllotmentId,
                        salesTeam: { id: obj.id },
                        leaveStructureHeader: { id: leaveStructureHeaderId },
                        fromDate: obj.fromDate ? dayjs(obj.fromDate).format("DD-MM-YYYY") : dayjs(fromDate).format("DD-MM-YYYY"),
                        toDate: dayjs(obj.toDate).format("DD-MM-YYYY"),
                    });
                } else {
                    saveList.push({
                        salesTeam: { id: obj.id },
                        leaveStructureHeader: { id: leaveStructureHeaderId },
                        fromDate: obj.fromDate ? dayjs(obj.fromDate).format("DD-MM-YYYY") : dayjs(fromDate).format("DD-MM-YYYY"),
                        toDate: dayjs(obj.toDate).format("DD-MM-YYYY"),
                    });
                }

            }
            console.log(saveList);
        });




        if (saveList.length !== 0) {
            if (validFromDate >= validToDate) {
                showNotification({ msg: dateValidationMsg, severity: "error" });
            } else {
                swal({
                    title: "Are you sure?",
                    text: saveWarningMsg,
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((willDelete) => {
                    if (willDelete) {
                        showLoador({ loador: true });
                        apiPost({
                            url: endpoint.leaveStructureAllotment + "/save-list",
                            postBody: saveList,
                        }).then(({ data, success }) => {
                            showLoador({ loador: false });
                            if (success) {
                                if (data && data.data && data.data.message && data.data.leaveStructureAllotments && data.data.leaveStructureAllotments.length !== 0) {
                                    showNotification({ msg: data.data.message, severity: "error" });
                                } else {
                                    showNotification({ msg: savemsg });
                                    window.location.reload();
                                }
                            } else {
                                showNotification({ msg: saveFailedMsg, severity: "error" });
                            }
                            return success;
                        });
                    }
                });
            }

        } else {
            showNotification({ msg: checkedListEmptyMsg, severity: "error" });
        }


    };

    handleClick = (e, id) => {
        const { leaveStructureList, setSalesTeamWithLeaveStructure } = this.props;

        this.setState({ selection: e });

        let data = leaveStructureList.leaveStructureAllotment.map((dataObj) => {
            if (dataObj.id == id) {
                return {
                    ...dataObj,
                    isChecked: e.target.checked,
                };
            } else {
                return dataObj;
            }
        });
        setSalesTeamWithLeaveStructure({ row: data });
    };


    handleAllCheckedClick = (e) => {
        const { leaveStructureList, setSalesTeamWithLeaveStructure } = this.props;

        this.setState({ allChecked: e.target.checked });

        let data = leaveStructureList.leaveStructureAllotment.map((dataObj) => {
            return {
                ...dataObj,
                isChecked: e.target.checked,
            };
        });
        setSalesTeamWithLeaveStructure({ row: data });
    };


    onDateChange = (row, name) => (date) => {
        const { leaveStructureList, setSalesTeamWithLeaveStructure } = this.props;
        let updatedAllotment = leaveStructureList.leaveStructureAllotment.map((dataObj) => {
            if (dataObj.id === row.id) {
                return {
                    ...dataObj,
                    [name]: date,
                    ...(name === "fromDate" && { fromDateFlag: true }),
                };
            } else {
                return dataObj;
            }
        });
        setSalesTeamWithLeaveStructure({ row: updatedAllotment });
    };




    onDateChangeAll = (e, name) => {

        const { leaveStructureList, setSalesTeamWithLeaveStructure } =
            this.props;


        if (name === "fromDate") {
            this.setState({ structureFromDate: e })
        } else {
            this.setState({ structureToDate: e })
        }

        let list = leaveStructureList?.leaveStructureAllotment.map(
            (rowData) => {
                return {
                    ...rowData,
                    [name]: e,
                };
            }
        );
        setSalesTeamWithLeaveStructure({ row: list });

    };


    onSearchBox = (searchValue) => {
        this.setState({
            searchValue: searchValue,
        });
    };

    getFilteredTableData = (searchData) => {
        const { searchValue } = this.state;
        return searchData.filter((currentRow) => {
            let isValid = false;
            if (
                (currentRow["name"] &&
                    currentRow["name"]
                        .toString()
                        .toLowerCase()
                        .includes(searchValue.trim().toLowerCase()))
            ) {
                isValid = true;
                return true;
            }
        });
    };


    render() {
        const { leaveStructureList } = this.props;
        const {
            dynamicMasterData,
            toDate,
            fromDate,
            adminFlag,
            structureFromDate,
            structureToDate,
            allChecked,
        } = this.state;
        return (
            <>
                <Paper
                    sx={{
                        padding: 2,
                    }}
                    elevation={0}
                >

                    <DynamicFormWithoutSave
                        dynamicMasterData={dynamicMasterData}
                        getListById={this.getListById}
                        searchList={leaveAllotmentJson.searchList}
                        showSaveBtn={true}
                        showSaveBtnMain={false}
                        showTitle={leaveAllotmentJson.showTitle}
                        screenTitle={leaveAllotmentJson.screenTitle}
                        fieldMeta={
                            adminFlag
                                ? leaveAllotmentJson.fieldMetaAdmin
                                : leaveAllotmentJson.fieldMeta
                        }
                        showCancel={leaveAllotmentJson.showCancel}
                        apiBaseURL={leaveAllotmentJson.apiBaseURL}
                        showSaveNextBtn={leaveAllotmentJson.showSaveNextBtn}
                        saveBtnText={"Search"}
                        onSave={this.onSearch}
                    />
                    <br />

                    {leaveStructureList?.leaveStructureAllotment?.length !== 0 && (
                        <>

                            <LandingScreenHeader
                                screenTitle={""}
                                showSearchBox={true}
                                showTable={true}
                                showFilter={false}
                                showPdfDownload={false}
                                showExcelDownload={false}
                                onSearch={this.onSearchBox}
                            />
                            <TableContainer style={{ maxHeight: "550px", overflow: "auto" }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="error"
                                                    onClick={(event) => this.handleAllCheckedClick(event)}
                                                    checked={allChecked}
                                                    disabled={false}
                                                    inputProps={{
                                                        "aria-label": "select all desserts",
                                                    }}
                                                /></TableCell>
                                            <TableCell align="center">Sr. No.</TableCell>
                                            <TableCell align="center">Name</TableCell>
                                            <TableCell align="center">Area</TableCell>
                                            <TableCell align="center" width="20%">
                                                <div>From Date</div>
                                                <div>
                                                    <div>
                                                        <BasicDatePicker
                                                            color="primary"
                                                            name="fromDate"
                                                            size="small"
                                                            value={structureFromDate}
                                                            minDate={dayjs()}
                                                            disabled={false}
                                                            onChange={(e) => { this.onDateChangeAll(e, "fromDate") }}
                                                        />
                                                    </div>

                                                </div>
                                            </TableCell>
                                            <TableCell align="center" width="20%">
                                                <div>To Date</div>
                                                <div>
                                                    <div>
                                                        <BasicDatePicker
                                                            color="primary"
                                                            name="toDate"
                                                            size="small"
                                                            value={structureToDate}
                                                            minDate={dayjs()}
                                                            disabled={false}
                                                            onChange={(e) => { this.onDateChangeAll(e, "toDate") }}
                                                        />
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.getFilteredTableData(leaveStructureList?.leaveStructureAllotment)?.map((row, index) => (
                                            <TableRow>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        onClick={(event) => this.handleClick(event, row.id)}
                                                        checked={row.isChecked}
                                                        disabled={false}
                                                        inputProps={{
                                                            "aria-labelledby": `enhanced-table-checkbox-${index}`,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">{index + 1}</TableCell>
                                                <TableCell align="left">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="left">{row.areaNames}</TableCell>
                                                <TableCell>
                                                    {row.fromDate ? (
                                                        <BasicDatePicker
                                                            color="primary"
                                                            name="fromDate"
                                                            size="small"
                                                            value={row.fromDate}
                                                            minDate={dayjs()}
                                                            // disabled={row.fromDateFlag ? false : true}
                                                            disabled={false}
                                                            onChange={this.onDateChange(row, "fromDate")}
                                                        />
                                                    ) : (
                                                        <BasicDatePicker
                                                            color="primary"
                                                            name="fromDate"
                                                            size="small"
                                                            value={fromDate}
                                                            disabled={false}
                                                            minDate={dayjs()}
                                                            onChange={this.onDateChange(row, "fromDate")}
                                                        />
                                                    )}

                                                </TableCell>
                                                <TableCell>
                                                    <BasicDatePicker
                                                        color="primary"
                                                        name="toDate"
                                                        size="small"
                                                        value={row.toDate ? row.toDate : toDate}
                                                        disabled={false}
                                                        minDate={dayjs()}
                                                        onChange={this.onDateChange(row, "toDate")}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <>
                                <br />
                                <Grid
                                    item
                                    sm={12}
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    rowSpacing={2}
                                    columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                                    container
                                    justifyContent="right"
                                >
                                    <ButtonCompo
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        name="Submit"
                                        onClick={this.onSubmit}
                                    />
                                </Grid>
                            </>
                        </>
                    )
                    }
                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    stockStakeHolderTypeList: state.stockStakeHolderType,
    leaveStructureList: state.leaveStructure,
    managerList: state.manager,
    areaList: state.area,
    salesExecutive: state.salesExecutive,
    beatList: state.beat,
    outletList: state.outlet,
    regionList: state.region,
    stateList: state.states,
    zoneList: state.zone,
    districtList: state.district,
    talukaList: state.taluka,
});
const mapDispatchToProps = {
    getAreaByUserType,
    getRegionUpdated,
    getSalesExecutiveTypeByAreaId,
    getStateByRegion,
    getZoneByState,
    getDistrictByZone,
    getTalukaByDistrict,
    getAreaByTalukaPostBody,
    getStateByRegion,
    showLoador,
    showNotification,
    getStockStakeHolderTypeForList,
    getActiveLeaveStructure,
    getSalesTeamWithLeaveStructure,
    setSalesTeamWithLeaveStructure,
};
export default connect(mapStateToProps, mapDispatchToProps)(LeaveAllotmentTable);
