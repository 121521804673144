import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg } from "../../config/messageconstant";
import { getCreditNoteById } from "../../Slice/creditNote.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
class CreditNoteOutletView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
      crnData: [],
    };
    this.columns = [
      {
        title: "Sr.No.",
        name: "index",
        align: "center",
      },
      {
        title: "Product Name",
        name: "productName",
      },
      {
        title: "UOM",
        name: "outletUom",
        align: "center",
      },
      {
        title: "Rate",
        name: "rate",
        align: "right",
      },
      {
        title: "Quantity",
        name: "qty",
        align: "right",
      },
      {
        title: "CGST",
        name: "cgstAmt",
        align: "right",
      },
      {
        title: "SGST",
        name: "sgstAmt",
        align: "right",
      },
      {
        title: "Total Amount",
        name: "totalAmt",
        align: "right",
      },
    ];
  }

  async componentDidMount() {
    const { getCreditNoteById, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      if (this.props.params.id) {
        showLoador({ loador: true });
        await getCreditNoteById({
          crnHeaderId: this.props.params.id,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
          } else {
            this.setState({
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Credit Note Number",
                  value:
                    response.transactionNo !== null &&
                    response.transactionNo !== null
                      ? response.transactionNo
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Outlet Name",
                  value:
                    response.outLet !== null &&
                    response.outLet.firmName !== null
                      ? response.outLet.firmName
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Taxable Amount",
                  value:
                    response.taxableAmt !== null && response.taxableAmt !== null
                      ? response.taxableAmt
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Total Tax",
                  value:
                    response.totalAmt !== null && response.taxableAmt !== null
                      ? (+response.totalAmt - +response.taxableAmt).toFixed(2)
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Total Amount",
                  value: Math.round(response.totalAmt).toFixed(2),
                },
              ],
              crnData:
                response.creditNoteDetail.length !== 0
                  ? response.creditNoteDetail
                  : [],
            });
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  render() {
    const { dynamicMasterData, crnData, DataToSet } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            DataToSet={DataToSet}
            dynamicMasterData={dynamicMasterData}
            showTitle={true}
            screenTitle={"Credit Note View"}
            saveBtnText={"Search"}
            showSaveBtnMain={false}
          />

          <RTTable
            columns={this.columns}
            tableData={crnData}
            isActionColActive={false}
            showPegination={false}
          />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getCreditNoteById,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CreditNoteOutletView);
