import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../../components/Comman/Button";
import MaterialUITableWithTextField from "../../../components/Comman/RT/MaterialUITableWithTextField";
import DynamicFormWithoutSave from "../../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../../components/withRouter";
import endpoint from "../../../config/endpoints";
import {
  imageMsg,
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../../config/messageconstant";
import { salesExecutiveDocumentJson } from "../../../DynamicFormsJson/MastersJSON/salesExecutiveDocument";
import { setStakeDocList } from "../../../Slice/StackHolder.slice";
import { apiGet, apiPost, apiPostForImage } from "../../../utils/api_service";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
class SalesExecutiveDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productList: [],
      showDate: false,
      DataToSet: [],
      salesTeamId: "",
    };
  }

  async componentDidMount() {
    const { showNotification, setStakeDocList } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      const rowData = await apiGet({
        url:
          endpoint.salesTeamDocumentTable +
          "/with-all-document?salesTeamId=" +
          this.props.params.id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
          return [];
        }

        return data.data.map((rowInfo, index) => {
          rowInfo = {
            index: index + 1,
            mainId: rowInfo.id === null ? "" : rowInfo.id,
            documentName:
              rowInfo.documentTable.docName === null
                ? ""
                : rowInfo.documentTable.docName,
            id:
              rowInfo.documentTable.id === null ? "" : rowInfo.documentTable.id,
            imagePath: rowInfo.imagePath === null ? "" : rowInfo.imagePath,
            imagePathToSave: "",
          };
          return rowInfo;
        });
      });
      if (this.props.params.id) {
        await this.getSalesExecutiveById(this.props.params.id);
        setStakeDocList({
          row: rowData,
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getSalesExecutiveById = async (id) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await apiGet({
        url: endpoint.salesExecutive + "/" + id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          if (!data.error) {
            this.setState({
              salesTeamId: data.data.id !== null ? data.data.id : "",
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "User Name",
                  value: data.data.userName !== null ? data.data.userName : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Person Name",
                  value: data.data.name !== null ? data.data.name : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Contact Number",
                  value:
                    data.data.contactNo !== null ? data.data.contactNo : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Whatsapp Number",
                  value:
                    data.data.whatsAppNo !== null ? data.data.whatsAppNo : "",
                },
                {
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  labelSM: 2,
                  labelMD: 2,
                  labelLG: 2,
                  valueSM: 10,
                  valueMD: 10,
                  valueLG: 10,
                  label: "Address",
                  value: data.data.address !== null ? data.data.address : "",
                },
              ],
            });
          }
        } else {
          showNotification({ msg: serverMsg, severity: "error" });
        }

        return success;
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onFormSave = async () => {
    const { salesTeamId } = this.state;
    const { stakeHolderTypeList, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      const documentForStakeHolderList =
        stakeHolderTypeList?.documentForStakeHolder.filter(
          (row) => row.imagePathToSave != ""
        );
      if (documentForStakeHolderList.length != 0) {
        await documentForStakeHolderList.map(async (imageData) => {
          const formData = new FormData();
          formData.append("file", imageData.imagePathToSave);
          formData.append("imageName", imageData.imagePath);
          showLoador({ loador: true });
          await apiPostForImage({
            url: endpoint.uploadImage,
            postBody: formData,
          }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: imageMsg, severity: "error" });
            }
          });

          return null;
        });
        const dataToSave = await documentForStakeHolderList.map(
          (imageData, data) => {
            let documentForStakeHolderObj = {
              documentTable: {
                id: imageData.id,
              },
              salesTeam: {
                id: salesTeamId,
              },
              imagePath: imageData.imagePath,
            };
            if (imageData.mainId != 0) {
              documentForStakeHolderObj = {
                ...documentForStakeHolderObj,
                id: imageData.mainId,
              };
            }
            return documentForStakeHolderObj;
          }
        );
        this.submitHandler(dataToSave);
      } else {
        this.props.navigate(-1);
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  submitHandler = async (dataToSave) => {
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onSubmit(dataToSave);
      }
    });
  };

  onSubmit = async (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await apiPost({
        url: endpoint.salesTeamDocumentTable + "/save-list",
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg });
          this.props.navigate(-1);
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const { DataToSet } = this.state;
    const { setStakeDocList, stakeHolderTypeList } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            DataToSet={DataToSet}
            formPath={salesExecutiveDocumentJson.formPath}
            showSaveBtn={salesExecutiveDocumentJson.showSaveBtn}
            showTitle={salesExecutiveDocumentJson.showTitle}
            screenTitle={salesExecutiveDocumentJson.screenTitle}
            fieldMeta={salesExecutiveDocumentJson.fieldMeta}
            showCancel={salesExecutiveDocumentJson.showCancel}
            apiBaseURL={salesExecutiveDocumentJson.apiBaseURL}
            showSaveNextBtn={salesExecutiveDocumentJson.showSaveNextBtn}
            isActionColActive={false}
            showSaveBtnMain={salesExecutiveDocumentJson.showSaveBtn}
          />

          <MaterialUITableWithTextField
            tableHead={[
              {
                title: "#",
                name: "index",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Document Name",
                name: "documentName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },

              {
                title: "Image",
                name: "imagePath",
                nameToSave: "imagePathToSave",
                selectCompo: true,
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
                placeHolder: "Enter Remark",
              },
            ]}
            data={stakeHolderTypeList?.documentForStakeHolder}
            setList={setStakeDocList}
            isActionColActive={false}
          />
          <br />
          {stakeHolderTypeList?.documentForStakeHolder.length != 0 && (
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              rowSpacing={2}
              columnSpacing={{ xs: 12, sm: 6, md: 1 }}
              container
              justifyContent="right"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name={"Submit"}
                onClick={this.onFormSave}
              />
            </Grid>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stakeHolderTypeList: state.stackholder,
});
const mapDispatchToProps = {
  showLoador,
  setStakeDocList,
  showNotification,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SalesExecutiveDocument);
