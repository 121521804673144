// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customTextfield{
    color: rgba(0,0,0,.87);
border-bottom-color:  rgba(127, 127, 127, 0.25);
align-self: flex-end;
box-sizing: border-box;
width: 100%;
padding: 20px 16px 6px;
transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
border: none;
border-bottom: 1px solid;
border-radius: 4px 4px 0 0;
height: 44px;

}

.customTextfield:hover{
    border-bottom-color: rgba(0,0,0,.27);
    background: #ececec;
}

.customTextfield:focus{
    border-bottom-color: rgba(0,0,0,.87);
    
  }`, "",{"version":3,"sources":["webpack://./src/Pages/Product Rate Structure/product.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B,+CAA+C;AAC/C,oBAAoB;AACpB,sBAAsB;AACtB,WAAW;AACX,sBAAsB;AACtB,sDAAsD;AACtD,YAAY;AACZ,wBAAwB;AACxB,0BAA0B;AAC1B,YAAY;;AAEZ;;AAEA;IACI,oCAAoC;IACpC,mBAAmB;AACvB;;AAEA;IACI,oCAAoC;;EAEtC","sourcesContent":[".customTextfield{\n    color: rgba(0,0,0,.87);\nborder-bottom-color:  rgba(127, 127, 127, 0.25);\nalign-self: flex-end;\nbox-sizing: border-box;\nwidth: 100%;\npadding: 20px 16px 6px;\ntransition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);\nborder: none;\nborder-bottom: 1px solid;\nborder-radius: 4px 4px 0 0;\nheight: 44px;\n\n}\n\n.customTextfield:hover{\n    border-bottom-color: rgba(0,0,0,.27);\n    background: #ececec;\n}\n\n.customTextfield:focus{\n    border-bottom-color: rgba(0,0,0,.87);\n    \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
