import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
    otherTask: [],
};
let URL = endpoints.otherTask;
const otherTaskSlice = createSlice({
    name: "otherTask",
    initialState,
    reducers: {
        otherTaskSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                otherTask: row,

            };
        },

        resetState: (state = cloneDeep(initialState), action) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const { otherTaskSuccess, resetState } = otherTaskSlice.actions;

export default otherTaskSlice.reducer;

export const getOtherTask = () => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL + '?sort={"insertDateTime": "DESC"}',
        }).then(({ data, success }) => {
            if (success) {
                const { content } = data.data;
                const row = content.map((otherTaskObject, index) => {
                    let otherTaskData = {
                        index: index + 1,
                        id: otherTaskObject.id === null ? "" : otherTaskObject.id,
                        name: otherTaskObject.name === null ? "" : otherTaskObject.name,
                        isActive: otherTaskObject.isActive === null ? "" : otherTaskObject.isActive,
                        type: otherTaskObject.type === null ? "" : otherTaskObject.type,
                        typeForTable: otherTaskObject.type === null ? "" : otherTaskObject.type === 0 ? "Outlet" : "Distributor",
                    };
                    return otherTaskData;
                });
                dispatch(otherTaskSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};
