import { Grid, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { compose } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { orderHistoryEditJSON } from "../../DynamicFormsJson/MastersJSON/orderHistoryEdit";
import { placedOrderJson } from "../../DynamicFormsJson/Transaction/placedOrder";
import { getBeatByArea } from "../../Slice/beat.slice";
import { setCategoryAndItem } from "../../Slice/category.slice";
import { getReceivedBillHeaderById } from "../../Slice/receivedOrderList.slice";
import { getActiveOutletByBeatId } from "../../Slice/outlet.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import { withRouter } from "../../components/withRouter";
import { ValidationCheck, mandatoryCheck } from "../../config/ValidationCheck";
import endpoint from "../../config/endpoints";
import {
  atleastOne,
  noInternetMsg,
  saveFailedMsg,
  saveWarningMsg,
  savemsg,
  serverMsg,
  updateData,
  updateMsg,
} from "../../config/messageconstant";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import ManualOrderTable from "./RecivedOrderEditTable";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

class RecivedOrderEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      searchValue: "",
      dynamicMasterData: {
        orderDetail: this.props.stackHolderList.stackholder,
      },
      formErrors: {},
      fieldData: {},
      DataToSet: [],
      value: 0,
      total: 0,
      totalAmt: 0,
      open: false,
      orderList: [],
      qty: 0,
      flag: false,
      headerData: {},
    };
  }

  setCatList = ({ row }) => {
    const { setCategoryAndItem } = this.props;
    let dataTotal = 0;
    let qtyTotal = 0;
    row.map((rowData) => {
      if (rowData.flag == 3) {
        rowData.productWithMarginAndMrps.map((productData) => {
          dataTotal = dataTotal + +productData.total;
          qtyTotal = qtyTotal + +productData.qty;
        });
      }
    });
    const { fieldData } = this.state;
    const discount = fieldData.DiscountPercentage
      ? fieldData.DiscountPercentage
      : 0;
    let discountAmt = (dataTotal * +discount) / 100;
    this.setState({
      totalAmt: dataTotal - +discountAmt,
      total: dataTotal,
      qty: qtyTotal,
    });
    setCategoryAndItem({ row });
  };
  async componentDidMount() {
    const { getReceivedBillHeaderById, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getReceivedBillHeaderById({
        billHeaderId: this.props.params.id,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            headerData: response,
            DataToSet: [
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Order Number",
                value: response.orderNo !== null ? response.orderNo : "",
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Order Date",
                value: response.orderDate !== null ? response.orderDate : "",
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Taxable Amount",
                value: response.taxableAmt !== null ? response.taxableAmt : "",
              },
              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Total Amount",
                value: response.totalAmt !== null ? response.totalAmt : "",
              },

              {
                xs: 12,
                sm: 6,
                md: 6,
                lg: 6,
                label: "Total Tax",
                value:
                  response.cgstAmt !== null && response.sgstAmt !== null
                    ? (+response.cgstAmt + +response.sgstAmt).toFixed(2)
                    : "",
              },
            ],
          });
          showLoador({ loador: true });
          apiGet({
            url:
              endpoint.category +
              "/with-items-and-mrp-and-rate-and-stock-by-stake-holder?stockStakeHolderId=" +
              response.fromOrder,
          }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success && data.data != null) {
              const rowData = data.data;
              if (rowData.errorMessage.error) {
                showNotification({
                  msg: rowData.errorMessage.message,
                  severity: "error",
                });
              } else {
                const row = rowData.categoryAndItems.map((rowObj) => {
                  console.log(rowObj);
                  return {
                    ...rowObj,
                    total: 0,
                    qty: 0,
                    productWithMarginAndMrps:
                      rowObj.productWithMarginAndMrps.map((product) => {
                        let editQty =
                          response.stockStakeHolderOrderDetails.filter(
                            (row) => row.product.id == product.products.id
                          );
                        return {
                          ...product,
                          uomConversionValue:
                            product.products.uomConversionValue,
                          qty: editQty.length !== 0 ? editQty[0].qty : "",
                          freeQty: 0,
                          itemDiscPer: 0,
                          productStock: product.rate,
                          total:
                            editQty.length !== 0
                              ? editQty[0].qty * product.rate
                              : 0,
                          stockError: 0,
                        };
                      }),
                  };
                });
                this.setCatList({ row });
              }
            } else {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.stackHolderList !== nextProps.stackHolderList) {
      if (
        this.props.stackHolderList?.stackholder !==
        nextProps.stackHolderList?.stackholder
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            orderDetail: nextProps.stackHolderList?.stackholder,
          },
        });
      }
    }
  }
  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };
  subHandler = () => {
    if (this.checkValidationOnSubmit()) {
      const { category } = this.props;
      let rowList = [];
      category.category.map((catData) => {
        if (catData.flag == 3) {
          const filterData = catData.productWithMarginAndMrps.filter(
            (row) => row.qty != 0
          );
          rowList = [...rowList, ...filterData];
          return catData;
        }
      });
      if (rowList.length != 0) {
        this.setState({
          orderList: rowList.map((rowData, index) => {
            return {
              index: index + 1,
            };
          }),
        });
        this.toggle();
      } else {
        this.props.showNotification({ msg: atleastOne, severity: "error" });
      }
    }
  };

  checkValidationOnSubmit = () => {
    const { fieldData } = this.state;
    const mandatoryCheckErrors = mandatoryCheck({
      fieldData,
      fieldMeta: placedOrderJson.fieldMeta,
    });
    this.setState({
      formErrors: mandatoryCheckErrors.formErrors,
    });
    if (mandatoryCheckErrors.formValid) {
      const ValidationCheckErrors = ValidationCheck({
        fieldData,
        fieldMeta: placedOrderJson.fieldMeta,
      });
      this.setState({
        formErrors: ValidationCheckErrors.formErrors,
      });
      return ValidationCheckErrors.formValid;
    } else {
      return false;
    }
  };
  getOrder = () => {
    const { orderList, fieldData } = this.state;
    const outletBillDetails = orderList.map((orderData) => {
      return {
        product: orderData.product,
        qty: orderData.qty,
      };
    });
    const dataToSave = {
      orderDate: dayjs().format("DD-MM-YYYY"),
      discountOnBillPer: fieldData.DiscountPercentage
        ? fieldData.DiscountPercentage
        : 0,
      stockStakeHolderOrderDetails: outletBillDetails,
    };
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onSave(dataToSave);
      }
    });
  };
  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      this.toggle();
      showLoador({ loador: true });
      apiPost({
        url: endpoint.stockStakeholderOrderHeader,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        if (success) {
          showLoador({ loador: false });
          this.props.navigate("/received-order-list");
          showNotification({ msg: savemsg });
        } else {
          showLoador({ loador: false });

          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onDataChange = (fieldName, newValue) => {
    if (fieldName == "DiscountPercentage") {
      let discountAmt = (this.state.total * +newValue) / 100;
      this.setState({
        totalAmt: +this.state.total - +discountAmt,
      });
    }
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    placedOrderJson.fieldMeta.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };
  toggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  getFilteredTableData = (tableData) => {
    const { searchValue } = this.state;
    return tableData.filter((currentRow) => {
      let isValid = false;
      let productName =
        currentRow.products != null ? currentRow.products.name : "-";
      let stakeHolderUom =
        currentRow.products.stakeHolderUom != null
          ? currentRow.products.stakeHolderUom.name
          : "-";
      let rate = currentRow.rate != null ? currentRow.rate : "-";
      let qty = currentRow.qty != null ? currentRow.qty : "-";
      if (
        productName &&
        productName.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        stakeHolderUom &&
        stakeHolderUom.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (rate && rate.toLowerCase().includes(searchValue.toLowerCase())) {
        isValid = true;
        return true;
      }

      return isValid;
    });
  };
  changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "comment") {
      this.setState({ comment: value });
    }
  };
  onCancel = () => {
    this.props.navigate(-1);
  };

  submitHandler = () => {
    const { historyData, headerData } = this.state;
    const { showNotification, category, showLoador } = this.props;
    let rowList = [];
    category.category.map((catData) => {
      if (catData.flag == 3) {
        const filterData = catData.productWithMarginAndMrps.filter(
          (row) => row.qty != 0
        );
        rowList = [...rowList, ...filterData];
        return catData;
      }
    });
    let historySaveData = {
      ...headerData,
      stockStakeHolderOrderDetails: rowList.map((productObject) => {
        return {
          id: productObject.id,
          product:
            productObject.products !== null ? productObject.products : "",
          subCategory:
            productObject.products !== null &&
            productObject.products.group !== null &&
            productObject.products.group.subCategory !== null &&
            productObject.products.group.subCategory.id
              ? productObject.products.group.subCategory.id
              : "",
          category:
            productObject.products !== null &&
            productObject.products.group !== null &&
            productObject.products.group.subCategory !== null &&
            productObject.products.group.subCategory.category !== null &&
            productObject.products.group.subCategory.category.id
              ? productObject.products.group.subCategory.category.id
              : "",
          categoryName:
            productObject.products !== null &&
            productObject.products.group !== null &&
            productObject.products.group.subCategory !== null &&
            productObject.products.group.subCategory.category !== null &&
            productObject.products.group.subCategory.category.name
              ? productObject.products.group.subCategory.category.name
              : "",
          categoryShortName:
            productObject.products !== null &&
            productObject.products.group !== null &&
            productObject.products.group.subCategory !== null &&
            productObject.products.group.subCategory.category !== null &&
            productObject.products.group.subCategory.category.shortName
              ? productObject.products.group.subCategory.category.shortName
              : "",
          categorySortOrder:
            productObject.products !== null &&
            productObject.products.group !== null &&
            productObject.products.group.subCategory !== null &&
            productObject.products.group.subCategory.category !== null &&
            productObject.products.group.subCategory.category.sortOrder
              ? productObject.products.group.subCategory.category.sortOrder
              : "",
          isActive:
            productObject.products !== null &&
            productObject.products.group !== null &&
            productObject.products.group.subCategory !== null &&
            productObject.products.group.subCategory.category !== null &&
            productObject.products.group.subCategory.category.isActive
              ? productObject.products.group.subCategory.category.isActive
              : "",
          insertDateTime:
            productObject.products !== null &&
            productObject.products.group !== null &&
            productObject.products.group.subCategory !== null &&
            productObject.products.group.subCategory.category !== null &&
            productObject.products.group.subCategory.category.insertDateTime
              ? productObject.products.group.subCategory.category.insertDateTime
              : "",
          taxPercentage:
            productObject.products !== null &&
            productObject.products.taxPercentage !== null &&
            productObject.products.taxPercentage.id
              ? productObject.products.taxPercentage.id
              : "",
          taxName:
            productObject.products !== null &&
            productObject.products.taxPercentage !== null &&
            productObject.products.taxPercentage.name
              ? productObject.products.taxPercentage.name
              : "",
          taxSgst:
            productObject.products !== null &&
            productObject.products.taxPercentage !== null &&
            productObject.products.taxPercentage.sgst
              ? productObject.products.taxPercentage.sgst
              : "",
          taxCgst:
            productObject.products !== null &&
            productObject.products.taxPercentage !== null &&
            productObject.products.taxPercentage.cgst
              ? productObject.products.taxPercentage.cgst
              : "",
          taxIgst:
            productObject.products !== null &&
            productObject.products.taxPercentage !== null &&
            productObject.products.taxPercentage.igst
              ? productObject.products.taxPercentage.igst
              : "",
          taxCess:
            productObject.products !== null &&
            productObject.products.taxPercentage !== null &&
            productObject.products.taxPercentage.cess
              ? productObject.products.taxPercentage.cess
              : "",
          hsnCode:
            productObject.products !== null &&
            productObject.products.taxPercentage !== null &&
            productObject.products.taxPercentage.hsnCode
              ? productObject.products.taxPercentage.hsnCode
              : "",
          taxInsertDateTime:
            productObject.products !== null &&
            productObject.products.taxPercentage !== null &&
            productObject.products.taxPercentage.insertDateTime
              ? productObject.products.taxPercentage.insertDateTime
              : "",
          stakeHolderUom:
            productObject.products !== null &&
            productObject.products.stakeHolderUom !== null &&
            productObject.products.stakeHolderUom.id
              ? productObject.products.stakeHolderUom.id
              : "",
          stakeHolderUom:
            productObject.products !== null &&
            productObject.products.stakeHolderUom !== null &&
            productObject.products.stakeHolderUom.id
              ? productObject.products.stakeHolderUom.id
              : "",
          productName:
            productObject.products !== null && productObject.products.name
              ? productObject.products.name
              : "",
          outletName:
            productObject.products !== null &&
            productObject.products.stakeHolderUom !== null &&
            productObject.products.stakeHolderUom.name
              ? productObject.products.stakeHolderUom.name
              : "",
          outletisActive:
            productObject.products !== null &&
            productObject.products.stakeHolderUom !== null &&
            productObject.products.stakeHolderUom.isActive
              ? productObject.products.stakeHolderUom.isActive
              : "",
          outletInsertDateTime:
            productObject.products !== null &&
            productObject.products.stakeHolderUom !== null &&
            productObject.products.stakeHolderUom.insertDateTime
              ? productObject.products.stakeHolderUom.insertDateTime
              : "",
          outletUom:
            productObject.products !== null &&
            productObject.products.outletUom !== null &&
            productObject.products.outletUom.id
              ? productObject.products.outletUom.id
              : "",
          uom: {
            id: productObject.products.outletUom.id,
          },
          outletUomName:
            productObject.products !== null &&
            productObject.products.outletUom !== null &&
            productObject.products.outletUom.name
              ? productObject.products.outletUom.name
              : "",
          outletUomIsActive:
            productObject.products !== null &&
            productObject.products.outletUom !== null &&
            productObject.products.outletUom.isActive
              ? productObject.products.outletUom.isActive
              : "",
          outletUomInsertDateTime:
            productObject.products !== null &&
            productObject.products.outletUom !== null &&
            productObject.products.outletUom.insertDateTime
              ? productObject.products.outletUom.insertDateTime
              : "",
          uomConversionValue:
            productObject.products !== null &&
            productObject.products.uomConversionValue
              ? productObject.products.uomConversionValue
              : 0,
          productCode:
            productObject.products !== null && productObject.products.code
              ? productObject.products.code
              : "",

          shortName:
            productObject.products !== null && productObject.products.shortName
              ? productObject.products.shortName
              : "",
          weight:
            productObject.products !== null && productObject.products.weight
              ? productObject.products.weight
              : "",
          description:
            productObject.products !== null &&
            productObject.products.description
              ? productObject.products.description
              : "",
          selfLife:
            productObject.products !== null && productObject.products.selfLife
              ? productObject.products.selfLife
              : "",
          image:
            productObject.products !== null && productObject.products.image
              ? productObject.products.image
              : "",
          sortOrder:
            productObject.products !== null && productObject.products.sortOrder
              ? productObject.products.sortOrder
              : "",
          isActive:
            productObject.products !== null && productObject.products.isActive
              ? productObject.products.isActive
              : "",
          insertDateTime:
            productObject.products !== null &&
            productObject.products.insertDateTime
              ? productObject.products.insertDateTime
              : "",
          inserteUserId:
            productObject.products !== null &&
            productObject.products.inserteUserId
              ? productObject.products.inserteUserId
              : "",
          mrp:
            productObject.products !== null && productObject.products.mrp
              ? productObject.products.mrp
              : "",
          marginPercentage:
            productObject.marginPercentage !== null
              ? (+productObject.marginPercentage).toFixed(2)
              : 0,
          mrp: productObject.mrp !== null ? (+productObject.mrp).toFixed(2) : 0,

          qty: productObject.qty !== null ? productObject.qty : 0,
          rate:
            productObject.rate !== null ? (+productObject.rate).toFixed(2) : 0,
          total: productObject.total.toFixed(2),
        };
      }),
    };
    console.log(historySaveData);
    swal({
      title: "Are you sure?",
      text: updateData,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        if (navigator.onLine) {
          showLoador({ loador: true });
          await apiPost({
            url: endpoint.stockStakeholderOrderHeader + "/manual-order-edit",
            postBody: historySaveData,
          }).then(({ success }) => {
            if (success) {
              showLoador({ loador: false });
              showNotification({
                msg: updateMsg,
              });
              this.props.navigate(-1);
            } else {
              showNotification({
                msg: saveFailedMsg,
                severity: "error",
              });
              showLoador({ loador: false });
            }
          });
        } else {
          showNotification({ msg: noInternetMsg, severity: "error" });
        }
      }
    });
  };
  render() {
    const {
      dynamicMasterData,
      DataToSet,
      value,
      total,
      totalAmt,
      open,
      orderList,
      formErrors,
      fieldData,
      qty,
      comment,
      flag,
    } = this.state;
    const { category } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            showTitle={orderHistoryEditJSON.showTitle}
            screenTitle={orderHistoryEditJSON.screenTitle}
            showSaveBtnMain={false}
            showAddButton={false}
            showBackButton={true}
            onCancel={this.onCancel}
          />
          <br />
          <DynamicDetails DataToSet={DataToSet} />

          {category.category.length != 0 && (
            <>
              <Tabs
                value={value}
                onChange={this.handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
              >
                {category.category.map((catData) => {
                  return (
                    <Tab
                      label={
                        catData.category.name +
                        " ( " +
                        catData.productWithMarginAndMrps.length +
                        " )"
                      }
                    />
                  );
                })}
              </Tabs>
              {category.category.map((catData, index) => {
                return (
                  <TabPanel value={value} index={index}>
                    <LandingScreenHeader
                      screenTitle={""}
                      showSearchBox={true}
                      showTable={true}
                      showFilter={false}
                      showPdfDownload={false}
                      showExcelDownload={false}
                      onSearch={this.onSearch}
                    />
                    <ManualOrderTable
                      total={total}
                      qty={qty}
                      tableHead={[
                        {
                          title: "Sr.No.",
                          name: "index",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "center",
                        },
                        {
                          title: "Product Name",
                          name: "name",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                        },
                        {
                          title: "UOM",
                          name: "outletName",
                          positionCenter: true,
                          showInscreen: true,
                        },
                        {
                          title: "Rate",
                          name: "name",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                        },

                        {
                          title: "Product Stock",
                          name: "name",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                        },
                        {
                          title: "Quantity",
                          name: "target",
                          placeHolder: "Enter Target",
                          textFieldError: "targetErr",
                          positionCenter: false,
                          showInscreen: true,
                        },
                        {
                          title: "Total",
                          name: "name",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                        },
                      ]}
                      data={this.getFilteredTableData(
                        catData.productWithMarginAndMrps
                      )}
                      // data={catData.productWithMarginAndMrps}
                      rowList={category.category}
                      setList={this.setCatList}
                      flag={flag}
                    />
                  </TabPanel>
                );
              })}
              <br />
              <DynamicDetails
                DataToSet={[
                  {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    label: "Total Amount",
                    value: total.toFixed(2),
                  },
                  {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    label: "Total Quantity",
                    value: qty,
                  },
                ]}
              />

              <Grid container spacing={1}>
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ justifyContent: "flex-end" }}
                >
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name={"Update"}
                    onClick={this.submitHandler}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stackHolderList: state.stackholder,
  areaList: state.area,
  outletList: state.outlet,
  beatList: state.beat,
  outletOrderDetail: state.outletOrderDetail,
  salesExecutive: state.salesExecutive,
  category: state.category,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getBeatByArea,
  getActiveOutletByBeatId,
  setCategoryAndItem,
  getReceivedBillHeaderById,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RecivedOrderEdit);
