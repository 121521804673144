import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import endpoint from "../../config/endpoints";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { batchwiseStakeholderBillJson } from "../../DynamicFormsJson/Transaction/batchwiseStakeholderBill";
import { getGenratedBillList } from "../../Slice/orderBillList.slice";
import { getStockStackHolders } from "../../Slice/StackHolder.slice";
import { BatchwiseStakeholderListColumns } from "../../tableColumns/table-columns";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";
class BatchwiseStakeholderBillTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        stakeholdersDetail: this.props.stakeholdersList.stockStakeHolders,
      },
    };
  }

  async componentDidMount() {
    const { getStockStackHolders, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getStockStackHolders().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.stakeholdersList !== nextProps.stakeholdersList) {
      if (
        this.props.stakeholdersList?.stockStakeHolders !==
        nextProps.stakeholdersList?.stockStakeHolders
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeholdersDetail: nextProps.stakeholdersList?.stockStakeHolders,
          },
        });
      }
    }
  }
  onSearchList = async (data) => {
    const { getGenratedBillList, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getGenratedBillList({
        billHolderIds: data.stackholder,
        fromDate: data.fromdate,
        toDate: data.todate,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  rowPDF = (rowData) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      downloadF({
        url:
          endpoint.stockStakeholderBillHeader +
          "/report-by-id?documentType=1" +
          "&id=" +
          rowData.id,
        ext: "pdf",
        openNewTab: true,
      }).then((response) => {
        showLoador({ loador: false });
        if (!response) {
          this.props.showNotification({
            msg: serverMsg,
            severity: "error",
          });
        } else {
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const { generatedBillList, getGenratedBillList } = this.props;
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          dynamicMasterData={dynamicMasterData}
          searchList={batchwiseStakeholderBillJson.searchList}
          onSearchData={this.onSearchList}
          formPathView={batchwiseStakeholderBillJson.formPathView}
          formPath={batchwiseStakeholderBillJson.formPath}
          screenTitle={batchwiseStakeholderBillJson.screenTitle}
          fieldMeta={batchwiseStakeholderBillJson.fieldMeta}
          showPdfDownload={false}
          showExcelDownload={false}
          isActiveURL={batchwiseStakeholderBillJson.isActiveURL}
          showAddButton={true}
          tableColumnsToFilter={
            batchwiseStakeholderBillJson.tableColumnsToFilter
          }
          tableColumns={BatchwiseStakeholderListColumns}
          tableData={generatedBillList.genratedBillNew}
          getTableData={getGenratedBillList}
          apiBaseURL={batchwiseStakeholderBillJson.apiBaseURL}
          callApi={false}
          getTableDataById={true}
          showSearchBox={false}
          rowPDF={this.rowPDF}
          showPdf={true}
          deleteURL={batchwiseStakeholderBillJson.deleteURL}
          deleteURLStatic={true}
          showPegination={true}
          showSearch={false}
          showEditIcon={false}
          showDeleteIcon={false}
          showPegination={false}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stakeholdersList: state.stackholder,
  generatedBillList: state.billHeaderList,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getStockStackHolders,
  getGenratedBillList,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BatchwiseStakeholderBillTable);
