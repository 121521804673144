import { Divider, Dialog, DialogContent, Grid, Paper, styled, IconButton, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { DialogTitle, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { getAuditTransactionData } from "../../Slice/audit.slice";
import NoDataFound from "../DashbourdChanges/NoDataFound";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { apiPost } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
import CloseIcon from "@mui/icons-material/Close";
import { blackColor } from "../../config/ColorObj";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { classes, children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


class ComparePopUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DataToSet: [],
            response: [],
            matrixDataList: [],
        };

    }
    async componentDidMount() {
        const { showNotification } = this.props;
        if (navigator.onLine) {
            this.matrixData();
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }


    matrixData = () => {
        const { compareData } = this.props;

        let questions = compareData?.auditHeadDTO?.auditQuestions?.map((obj, index) => ({
            index: index + 1,
            question: obj.question
        })) || [];

        let answersByInquiry = compareData?.distributorInquiries.map((dataObj) => {
            let answers = questions.map((questionObj) => {
                const filterData = compareData?.auditHeadDTO?.questionaryTransactions?.filter(
                    (row) => row.distributorInquiry && row.distributorInquiry.id === dataObj.id
                );

                let answer = '';
                if (filterData.length !== 0) {
                    filterData.map((filterObj) => {
                        filterObj.questionaryTransactrionDetails.map((txnDetailsObj) => {
                            if (txnDetailsObj.auditQuestion.question === questionObj.question) {
                                let answerForMultiSelect = txnDetailsObj.auditQuestion.auditQuestionOption?.map((object) => {
                                    const auditQuestionOptionValueList = txnDetailsObj.auditQuestionOptionValue.split(',');
                                    return auditQuestionOptionValueList.includes(object.id) ? object.optionTitle : '';
                                }).filter(Boolean) || [];

                                answer = txnDetailsObj.auditQuestion?.optionType == 2
                                    ? answerForMultiSelect.join(' , ')
                                    : txnDetailsObj.auditQuestion?.auditQuestionOption?.find((row) => row.id === txnDetailsObj.auditQuestionOptionValue)?.optionTitle || txnDetailsObj.auditQuestionOptionValue || '-';
                            }
                        });
                    });
                }
                return answer;
            });

            return {
                id: dataObj.id,
                fullName: dataObj.fullName,
                answers
            };
        });

        this.setState({
            matrixDataList: questions.map((questionObj, index) => ({
                index: questionObj.index,
                question: questionObj.question,
                answersByInquiry: answersByInquiry.map((inquiry) => ({
                    fullName: inquiry.fullName,
                    answer: inquiry.answers[index]
                }))
            }))
        });

    };


    handleClose = () => {
        const { handleCloses } = this.props;
        handleCloses();
    }


    render() {
        const { open, compareData } = this.props;
        const { matrixDataList } = this.state;
        return (
            <>
                <BootstrapDialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    fullWidth
                    maxWidth="xl"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={this.handleClose}
                    >
                        Comparison
                    </BootstrapDialogTitle>
                    <DialogContent dividers>

                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" style={{ minWidth: "70px" }}>
                                            #
                                        </TableCell>
                                        <TableCell align="center" style={{ minWidth: "150px" }}>
                                            Questions
                                        </TableCell>
                                        {compareData?.distributorInquiries?.map((distributorInquiriesObj, index) => (
                                            <TableCell key={index} align="center" style={{ minWidth: "150px" }}>
                                                {distributorInquiriesObj.fullName}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {matrixDataList?.map((matrixDataObj, indexRow) => (
                                        <TableRow key={indexRow}>
                                            <TableCell align="center" component="td" scope="row">
                                                {indexRow + 1}
                                            </TableCell>
                                            <TableCell component="td" scope="row">
                                                {matrixDataObj.question}
                                            </TableCell>
                                            {matrixDataObj.answersByInquiry.map((answerObj, index) => (
                                                answerObj.answer ?
                                                    (
                                                        <TableCell key={index} component="td" scope="row">
                                                            {answerObj.answer}
                                                        </TableCell>
                                                    ) : (
                                                        <TableCell align="center" key={index} component="td" scope="row">
                                                            {"-"}
                                                        </TableCell>
                                                    )

                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>


                    </DialogContent>
                </BootstrapDialog >
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    auditList: state.audit,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getAuditTransactionData,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ComparePopUp);
