import VisibilityIcon from "@mui/icons-material/Visibility";
import { FormHelperText, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import {
    imageMsg,
    noInternetMsg,
    saveFailedMsg,
    savemsg,
    saveWarningMsg,
    serverMsg,
} from "../../config/messageconstant";
import { apiPost, apiPostForImage } from "../../utils/api_service";
import AttachFile from "../../components/Comman/AttachFile";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { getDistributorInquiryDocumentDetails } from "../../Slice/distributorInquiryDocumentDetails.slice";
import { imageUrl } from "../../config/Url";
class Attachments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileName: "",
            imageSelect: false,
            formErrors: {},
            documentId: "",
        };
    }

    async componentDidMount() {
        const { getDistributorInquiryDocumentDetails, showNotification, showLoador } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            await getDistributorInquiryDocumentDetails().then(
                ({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                }
            );

        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }



    onFormSave = () => {
        const { fileName, rows } = this.state;
        const { showLoador, showNotification, hideTable } = this.props;
        if (this.checkValidation()) {

            let fileNameData = dayjs(fileName.lastModifiedDate).format("hh:mm:ss") + fileName.name

            const formData = new FormData();
            formData.append("file", fileName);
            formData.append("documentName", fileNameData);


            showLoador({ loador: true });
            apiPostForImage({
                url: endpoint.uploadImage,
                postBody: formData,
            }).then(({ data, success }) => {
                if (success) {
                    showLoador({ loador: false });
                    const newRow = {
                        index: rows.length + 1,
                        fileName: data,
                        docName: fileName.name,
                    };

                    if (hideTable) {
                        this.setState({
                            rows: [newRow],
                        });
                    } else {
                        this.setState({
                            rows: [...rows, newRow],
                        });
                    }
                    this.submitHandler();
                } else {
                    showLoador({ loador: false });
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });


        }
    };
    submitHandler = async () => {
        const { documentId, fileName } = this.state;
        let fileNameData = dayjs(fileName.lastModifiedDate).format("hh:mm:ss") + fileName.name

        let dataJson = {
            distributorInquiry: {
                id: this.props.params.id,
            },
            documentId: documentId,
            documentImage: fileNameData,
        }


        console.log(dataJson);
        swal({
            title: "Are you sure?",
            text: saveWarningMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.onSubmit(dataJson);
            }
        });
    };

    onSubmit = async (dataToSave) => {
        const { showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            await apiPost({
                url: endpoint.distributorInquiryDocumentDetails,
                postBody: dataToSave,
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (success) {
                    showNotification({ msg: savemsg });
                } else {
                    showNotification({ msg: saveFailedMsg, severity: "error" });
                }
            });
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };



    checkValidation = () => {
        const {
            fileName,
        } = this.state;

        let formErrors = {};
        let formIsValid = true;

        console.log(fileName)


        if (fileName === "" || fileName === null) {
            formIsValid = false;
            formErrors["documentError"] = imageMsg;
        }

        this.setState({ formErrors: formErrors });
        return formIsValid;
    };


    changeH = (fileName, event, id) => {
        console.log(event);
        console.log(id);
        this.setState({
            ...this.state,
            fileName: fileName,
        });

        this.setState({ imageSelect: true });
    };

    onClickViewPreview = (e, row) => {
        console.log(row);
        e.stopPropagation();
        const url = URL.createObjectURL(row);
        window.open(url);
    };

    render() {
        const { fileName, formErrors, imageSelect } = this.state;
        const { documentList, stakeHolderTypeList } = this.props;
        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 2,
                    }}
                    elevation={0}
                >
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" style={{ minWidth: "70px" }}>
                                        #
                                    </TableCell>
                                    <TableCell align="center" style={{ minWidth: "150px" }}>
                                        Document
                                    </TableCell>
                                    <TableCell align="center" style={{ minWidth: "150px" }}>
                                        Attachment
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {documentList?.distributorInquiryDocumentDetails.map((docsObj, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center" component="td" scope="row">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell component="td" scope="row">
                                            {docsObj.documentName}
                                        </TableCell>
                                        <TableCell key={index} component="td" scope="row">
                                            <AttachFile
                                                name={"file"}
                                                fileName={(e) => (this.changeH, e, docsObj.id)}
                                            />
                                            &nbsp; {fileName !== "" ? fileName.name : ""}
                                            &nbsp;{" "}
                                            {fileName !== "" ? (
                                                <MyComponentWithIconProps
                                                    statusImage={VisibilityIcon}
                                                    color="primary"
                                                    fontSize="medium"
                                                    title="View"
                                                    onClick={
                                                        this.props.params.id && imageSelect ? (e) => this.onClickViewPreview(e, fileName, docsObj.id) :
                                                            (e) => { window.open(imageUrl + fileName.name, '_blank') }
                                                    }
                                                />
                                            ) : (
                                                <FormHelperText error>
                                                    {formErrors["documentError"] ? formErrors["documentError"] : ""}
                                                </FormHelperText>
                                            )}
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>


                    <br />
                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        rowSpacing={2}
                        columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                        container
                        justifyContent="right"
                    >
                        <ButtonCompo
                            size="medium"
                            type="submit"
                            variant="contained"
                            name={"Submit"}
                            onClick={this.onFormSave}
                        />
                    </Grid>
                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    documentList: state.distributorInquiryDocumentDetails,
});
const mapDispatchToProps = {
    showLoador,
    getDistributorInquiryDocumentDetails,
    showNotification,

};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Attachments);
