import { Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import MaterialUITableWithTextField from "../../components/Comman/RT/MaterialUITableWithTextField";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { getRegionNew } from "../../Slice/region.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getAreaByTalukaPostBody, getAreaByUserType } from "../../Slice/area.slice";
import { compose } from "@reduxjs/toolkit";
import { targetJson } from "../../DynamicFormsJson/Transaction/Targrt";
import { getMonthsFromYearId } from "../../Slice/regionAndTarget.slice";
import { getStockStakeHolderTypeForList } from "../../Slice/stockStakeHolderType.slice";
import {
  getNotInsertedTargetByStakeHolderTypeIdAndYearIdPostNew,
  setNotInsertedTarget,
} from "../../Slice/target.slice";
import { getActiveYear } from "../../Slice/year.slice";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";

import CalculateTarget from "./CalculateTarget";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
class TargetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openCal: false,
      stockStakeHolderTypeId: "",
      fieldData: {},
      stockStakeHolderTypeName: "",
      year: "",
      type: "",
      totaloftargetDenomination: 0,
      monthList: [],
      fieldDataForModule: {},
      dynamicMasterData: {
        stockStakeHolderTypelistDetail:
          this.props.stockStakeHolderTypeList?.stockStakeHolderTypeList,
        yearDetail: this.props.yearList?.activeYear,
      },
      notInsertedTargetList: [],
      adminFlag: false,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.area !== nextProps.areaList?.area
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.area,
          },
        });
      }
    }
    if (
      this.props.stockStakeHolderTypeList?.stockStakeHolderTypeList !==
      nextProps.stockStakeHolderTypeList?.stockStakeHolderTypeList
    ) {
      this.setState({
        dynamicMasterData: {
          ...this.state.dynamicMasterData,
          stockStakeHolderTypelistDetail:
            nextProps.stockStakeHolderTypeList?.stockStakeHolderTypeList,
        },
      });
    }
    if (this.props.yearList !== nextProps.yearList) {
      if (this.props.yearList?.activeYear !== nextProps.yearList?.activeYear) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            yearDetail: nextProps.yearList?.activeYear,
          },
        });
      }
    }

    if (
      this.props.regionAndTargetDataList !== nextProps.regionAndTargetDataList
    ) {
      if (
        this.props.regionAndTargetDataList?.monthsFromYearId !==
        nextProps.regionAndTargetDataList?.monthsFromYearId
      ) {
        this.setState({
          ...this.state.monthList,
          monthList: nextProps.regionAndTargetDataList?.monthsFromYearId,
        });
      }
    }
  }

  async componentDidMount() {
    const {
      getStockStakeHolderTypeForList,
      getActiveYear,
      getAreaByUserType,
      getRegionNew,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      const userTypeId = localStorage.getItem("userTypeId");

      if (userTypeId == 1 || userTypeId == 6) {

        this.setState({
          adminFlag: true,
        })

        showLoador({ loador: true });
        getRegionNew().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });

      } else {

        showLoador({ loador: true });
        getAreaByUserType().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });

      }




      const moduleObjData = localStorage.getItem("moduleObj");
      this.setState({
        fieldDataForModule:
          moduleObjData != null ? JSON.parse(moduleObjData) : {},
      });




      showLoador({ loador: true });
      await getStockStakeHolderTypeForList().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getActiveYear().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  onSubmit = async () => {
    const {
      notInsertedTargetList,
      setNotInsertedTarget,
      regionAndTargetDataList,
      showLoador,
    } = this.props;
    const { type } = this.state;
    let totaloftarget = 0;
    let conversionFactor = 1;
    if (type === "0") {
      conversionFactor = 1000;
    } else if (type === "1") {
      conversionFactor = 100000;
    } else if (type === "2") {
      conversionFactor = 10000000;
    }

    let conversionFactorForTotalTarget = "";
    if (type === "0") {
      conversionFactorForTotalTarget = "K";
    } else if (type === "1") {
      conversionFactorForTotalTarget = "L";
    } else if (type === "2") {
      conversionFactorForTotalTarget = "CR";
    }

    const convertedTargets = notInsertedTargetList.notInsertedTargetNew.map(
      (item) => ({
        ...item,
        target: Number(item.target) * conversionFactor,
      })
    );

    showLoador({ loador: true });
    const rowList = notInsertedTargetList.notInsertedTargetNew.map(
      (targetData) => {

        totaloftarget = totaloftarget + +targetData.totalTarget;
        let totaloftargetDenomination = totaloftarget + " " + conversionFactorForTotalTarget;
        this.setState({
          totaloftargetDenomination: totaloftargetDenomination,
        });

        let jan = 0;
        let feb = 0;
        let march = 0;
        let april = 0;
        let may = 0;
        let jun = 0;
        let july = 0;
        let aug = 0;
        let sep = 0;
        let oct = 0;
        let nov = 0;
        let dec = 0;
        let totalTarget = 0;
        if (targetData.id == 0) {
          if (targetData.regionTargetPercentageId == 0) {
            let totalTargetCal =
              (+targetData.totalTarget * conversionFactor) / 12;
            regionAndTargetDataList?.monthsFromYearId.map((yearObject) => {
              if (yearObject.month == "January") {
                jan = totalTargetCal;
              } else if (yearObject.month == "February") {
                feb = totalTargetCal;
              } else if (yearObject.month == "March") {
                march = totalTargetCal;
              } else if (yearObject.month == "April") {
                april = totalTargetCal;
              } else if (yearObject.month == "May") {
                may = totalTargetCal;
              } else if (yearObject.month == "June") {
                jun = totalTargetCal;
              } else if (yearObject.month == "July") {
                july = totalTargetCal;
              } else if (yearObject.month == "August") {
                aug = totalTargetCal;
              } else if (yearObject.month == "September") {
                sep = totalTargetCal;
              } else if (yearObject.month == "October") {
                oct = totalTargetCal;
              } else if (yearObject.month == "November") {
                nov = totalTargetCal;
              } else {
                dec = totalTargetCal;
              }
            });
            totalTarget = Number(targetData.totalTarget) * conversionFactor;
          } else {
            const totalTargetData =
              Number(targetData.totalTarget) * conversionFactor;
            jan =
              targetData.id != 0
                ? targetData.jan
                : +((+targetData.janPer / 100) * totalTargetData).toFixed(2);
            feb =
              targetData.id != 0
                ? targetData.feb
                : +((+targetData.febPer / 100) * totalTargetData).toFixed(2);
            march =
              targetData.id != 0
                ? targetData.march
                : +((+targetData.marchPer / 100) * totalTargetData).toFixed(2);
            april =
              targetData.id != 0
                ? targetData.april
                : +((+targetData.aprilPer / 100) * totalTargetData).toFixed(2);
            may =
              targetData.id != 0
                ? targetData.may
                : +((+targetData.mayPer / 100) * totalTargetData).toFixed(2);
            jun =
              targetData.id != 0
                ? targetData.jun
                : +((+targetData.junPer / 100) * totalTargetData).toFixed(2);
            july =
              targetData.id != 0
                ? targetData.july
                : +((+targetData.julyPer / 100) * totalTargetData).toFixed(2);
            aug =
              targetData.id != 0
                ? targetData.aug
                : +((+targetData.augPer / 100) * totalTargetData).toFixed(2);
            sep =
              targetData.id != 0
                ? targetData.sep
                : +((+targetData.sepPer / 100) * totalTargetData).toFixed(2);
            oct =
              targetData.id != 0
                ? targetData.oct
                : +((+targetData.octPer / 100) * totalTargetData).toFixed(2);
            nov =
              targetData.id != 0
                ? targetData.nov
                : +((+targetData.novPer / 100) * totalTargetData).toFixed(2);
            dec =
              targetData.id != 0
                ? targetData.dec
                : +((+targetData.decPer / 100) * totalTargetData).toFixed(2);

            totalTarget =
              jan +
              feb +
              march +
              april +
              may +
              jun +
              july +
              aug +
              sep +
              oct +
              nov +
              dec;
          }
        } else if (
          targetData.id != 0 &&
          Number(targetData.totalTarget) * conversionFactor !=
          targetData.totalOldTarget
        ) {
          if (targetData.regionTargetPercentageId == 0) {
            let totalTargetCal =
              (+targetData.totalTarget * conversionFactor) / 12;
            regionAndTargetDataList?.monthsFromYearId.map((yearObject) => {
              if (yearObject.month == "January") {
                jan = totalTargetCal;
              } else if (yearObject.month == "February") {
                feb = totalTargetCal;
              } else if (yearObject.month == "March") {
                march = totalTargetCal;
              } else if (yearObject.month == "April") {
                april = totalTargetCal;
              } else if (yearObject.month == "May") {
                may = totalTargetCal;
              } else if (yearObject.month == "June") {
                jun = totalTargetCal;
              } else if (yearObject.month == "July") {
                july = totalTargetCal;
              } else if (yearObject.month == "August") {
                aug = totalTargetCal;
              } else if (yearObject.month == "September") {
                sep = totalTargetCal;
              } else if (yearObject.month == "October") {
                oct = totalTargetCal;
              } else if (yearObject.month == "November") {
                nov = totalTargetCal;
              } else {
                dec = totalTargetCal;
              }
            });
            totalTarget = Number(targetData.totalTarget) * conversionFactor;
          } else {
            const totalTargetData =
              Number(targetData.totalTarget) * conversionFactor;
            jan = +((+targetData.janPer / 100) * totalTargetData).toFixed(2);
            feb = +((+targetData.febPer / 100) * totalTargetData).toFixed(2);
            march = +((+targetData.marchPer / 100) * totalTargetData).toFixed(
              2
            );
            april = +((+targetData.aprilPer / 100) * totalTargetData).toFixed(
              2
            );
            may = +((+targetData.mayPer / 100) * totalTargetData).toFixed(2);
            jun = +((+targetData.junPer / 100) * totalTargetData).toFixed(2);
            july = +((+targetData.julyPer / 100) * totalTargetData).toFixed(2);
            aug = +((+targetData.augPer / 100) * totalTargetData).toFixed(2);
            sep = +((+targetData.sepPer / 100) * totalTargetData).toFixed(2);
            oct = +((+targetData.octPer / 100) * totalTargetData).toFixed(2);
            nov = +((+targetData.novPer / 100) * totalTargetData).toFixed(2);
            dec = +((+targetData.decPer / 100) * totalTargetData).toFixed(2);

            totalTarget =
              jan +
              feb +
              march +
              april +
              may +
              jun +
              july +
              aug +
              sep +
              oct +
              nov +
              dec;
          }
        } else {
          const totalTargetData =
            Number(targetData.totalTarget) * conversionFactor;
          jan = targetData.jan;
          feb = targetData.feb;
          march = targetData.march;
          april = targetData.april;
          may = targetData.may;
          jun = targetData.jun;
          july = targetData.july;
          aug = targetData.aug;
          sep = targetData.sep;
          oct = targetData.oct;
          nov = targetData.nov;
          dec = targetData.dec;

          totalTarget =
            jan +
            feb +
            march +
            april +
            may +
            jun +
            july +
            aug +
            sep +
            oct +
            nov +
            dec;
        }
        //end else

        targetData = {
          ...targetData,
          jan: jan,
          feb: feb,
          march: march,
          april: april,
          may: may,
          jun: jun,
          july: july,
          aug: aug,
          sep: sep,
          oct: oct,
          nov: nov,
          dec: dec,
          totalTarget: totalTarget,
        };
        return targetData;
      }
    );
    setNotInsertedTarget({ row: rowList });

    this.setState({
      openCal: true,
    });
  };

  onSearch = async (data) => {
    const {
      getNotInsertedTargetByStakeHolderTypeIdAndYearIdPostNew,
      showLoador,
      showNotification,
      stockStakeHolderTypeList,
      yearList,
      getMonthsFromYearId,
      regionAndTargetDataList,
      setNotInsertedTarget,
    } = this.props;
    this.setState({
      type: data.type,
    });
    setNotInsertedTarget({ row: [] });
    if (navigator.onLine) {
      const filterstockStakeHolderTypeName =
        stockStakeHolderTypeList?.stockStakeHolderTypeList.filter(
          (rowData) => rowData.id == data.stockStakeHolderTypeId.id
        );
      if (filterstockStakeHolderTypeName.length != 0) {
        this.setState({
          stockStakeHolderTypeName: filterstockStakeHolderTypeName[0].name,
        });
      }
      const filterYearName = yearList?.activeYear.filter(
        (rowData) => rowData.id == data.name
      );
      if (filterYearName.length != 0) {
        this.setState({
          year: filterYearName[0],
        });
      }

      let jsonDataByPost = {
        stockStakeHolderTypeId: data.stockStakeHolderTypeId.id,
        yearId: data.name,
        areaIds: data.area.split(","),
      }
      showLoador({ loador: true });
      console.log(jsonDataByPost)
      await getNotInsertedTargetByStakeHolderTypeIdAndYearIdPostNew({ jsonDataByPost }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
        }
      });

      await getMonthsFromYearId({ yearId: data.name }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            monthList: regionAndTargetDataList.monthsFromYearId,
          });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onCancel = () => {
    this.setState({
      openCal: false,
    });
  };
  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTalukaPostBody,
      showLoador,
      showNotification,
    } = this.props;
    this.setState({
      type: data.type,
    });

    if (data.region && data.region != "") {
      showLoador({ loador: true });
      await getStateByRegion({ regionIds: data.region }).then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        }
      );
    }
    if (data.state && data.state != "") {
      showLoador({ loador: true });
      await getZoneByState({ stateIds: data.state }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    }
    if (data.zone && data.zone != "") {
      showLoador({ loador: true });
      await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    }
    if (data.district && data.district != "") {
      showLoador({ loador: true });
      await getTalukaByDistrict({ districtIds: data.district }).then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        }
      );
    }
    if (data.taluka && data.taluka != "") {
      let talukaJson = {
        talukaIds: data.taluka,
      };
      showLoador({ loador: true });
      await getAreaByTalukaPostBody({ talukaIds: talukaJson }).then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        }
      );
    }
  };

  render() {
    const {
      DataToSet,
      dynamicMasterData,
      openCal,
      stockStakeHolderTypeName,
      year,
      monthList,
      totaloftargetDenomination,
      fieldDataForModule,
      adminFlag,
    } = this.state;
    const {
      notInsertedTargetList,
      setNotInsertedTarget,
      regionAndTargetDataList,
    } = this.props;
    const totalCurrentTarget = localStorage.getItem("totalTarget");
    // alert(totalCurrentTarget ? totalCurrentTarget : "Null");

    return (
      <>
        {!openCal ? (
          <Paper
            sx={{
              p: 2,
              borderRadius: 2,
            }}
            elevation={0}
          >

            <DynamicFormWithoutSave
              resetStore={false}
              showBackToList={false}
              DataToSet={DataToSet}
              dynamicMasterData={dynamicMasterData}
              getByIdApi={true}
              callApi={true}
              getListById={this.getListById}
              showTitle={targetJson.showTitle}
              screenTitle={"Target Assignation"}
              fieldMeta={adminFlag ? targetJson.fieldMeta : targetJson.fieldMetaStockHolder}
              showCancel={targetJson.showCancel}
              apiBaseURL={targetJson.apiBaseURL}
              showSaveNextBtn={targetJson.showSaveNextBtn}
              showSaveBtnMain={true}
              tableData={notInsertedTargetList.notInsertedTargetNew}
              saveBtnText={"Search"}
              onSave={this.onSearch}
            />
            <DynamicDetails
              DataToSet={[
                {
                  xs: 12,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  label: "Total Target",
                  value: totalCurrentTarget != "" ? totalCurrentTarget : "-",
                },
              ]}
            />
            <>
              <MaterialUITableWithTextField
                resetStore={false}
                tableHead={[
                  {
                    title: "Sr. No.",
                    name: "index",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                    width: "80px",
                  },
                  {
                    title: "Name",
                    name: "name",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                    width: "120px",
                  },
                  {
                    title: "Region",
                    name: "regionName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                    width: "100px",
                  },
                  {
                    title: "State",
                    name: "stateName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                    minWidth: "10%",
                  },
                  {
                    title: "Zone",
                    name: "zoneNames",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                    minWidth: "10px",
                  },
                  {
                    title: "Area",
                    name: "areaName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                    width: "100px",
                  },
                  {
                    title: "Distributor",
                    name: "distributorCount",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                    width: "100px",
                  },
                  {
                    title: "Outlet",
                    name: "outletCount",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                    width: "100px",
                  },
                  {
                    title: "Sales",
                    name: "salesTeamCount",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                    width: "100px",
                  },
                  {
                    title: "Last Year Target",
                    name: "lastYearTotalTarget",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                    width: "130px",
                  },
                  {
                    title: "Last Year Achieved",
                    name: "lastYearTotalAcheived",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                    width: "150px",
                  },
                  {
                    title: "Last Year Percentage",
                    name: "lastYearPercentage",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                    width: "150px",
                  },
                  {
                    title: "Target",
                    name: "totalTarget",
                    placeHolder: "Enter Target",
                    textFieldError: "targetErr",
                    error: "Target",
                    textField: true,
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                    width: "100px",
                  },
                ]}

                data={notInsertedTargetList.notInsertedTargetNew}
                setList={setNotInsertedTarget}
                isActionColActive={false}
              />
              <br />
              {notInsertedTargetList.notInsertedTargetNew.length !== 0 && fieldDataForModule.editReject == 1 ? (
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  rowSpacing={2}
                  columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                  container
                  justifyContent="right"
                >
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name="Calculate"
                    onClick={this.onSubmit}
                  />
                </Grid>
              ) : ("")}
            </>
          </Paper>
        ) : (
          <CalculateTarget
            stockStakeHolderTypeName={stockStakeHolderTypeName}
            year={year}
            monthList={regionAndTargetDataList?.monthsFromYearId}
            onCancel={this.onCancel}
            setList={setNotInsertedTarget}
            notInsertedTargetList={notInsertedTargetList.notInsertedTargetNew}
            totaloftargetDenomination={totaloftargetDenomination}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stockStakeHolderTypeList: state.stockStakeHolderType,
  yearList: state.year,
  notInsertedTargetList: state.target,
  regionAndTargetDataList: state.regionAndTarget,
  areaList: state.area,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getStockStakeHolderTypeForList,
  getActiveYear,
  getNotInsertedTargetByStakeHolderTypeIdAndYearIdPostNew,
  setNotInsertedTarget,
  getMonthsFromYearId,
  getRegionNew,
  getStateByRegion,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTalukaPostBody,
  getAreaByUserType,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TargetForm);
