import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  language: [],
  isFetch: false,
  languageJson: [],
  isFetchLanguageJson: false,
};
let multilingualURL = endpoints.multilingual;

const multilingualSlice = createSlice({
  name: "multilingual",
  initialState,
  reducers: {
    languageSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        language: row,
        isFetch: true,
      };
    },

    languageJsonSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        languageJson: row,
        isFetchLanguageJson: true,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { languageSuccess, languageJsonSuccess, resetState } =
  multilingualSlice.actions;

export default multilingualSlice.reducer;

export const getActiveLanguage = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: multilingualURL + "/get-active",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((languageObject, index) => {
          let changedValue = 0;

          let categoryData = {
            index: index + 1,
            id: languageObject.id === null ? "" : languageObject.id,
            name: languageObject.name === null ? "" : languageObject.name,
            isActive:
              languageObject.isActive !== null ? languageObject.isActive : "",
            insertedUserId:
              languageObject.insertedUserId !== null
                ? languageObject.insertedUserId
                : "",
            insertDateTime:
              languageObject.insertDateTime !== null
                ? languageObject.insertDateTime
                : "",
          };
          return categoryData;
        });
        dispatch(languageSuccess({ row }));
        return { response: row, success };
      }
      return { response: data, success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getLanguageJson =
  ({ id }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: multilingualURL + "-json/by-type?areaBeatId=" + id,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((languageObject, index) => {
            let languageData = {
              index: index + 1,
              id: languageObject.id === null ? "" : languageObject.id,
              type: languageObject.type === null ? "" : languageObject.type,
              areaBeatId:
                languageObject.areaBeatId === null
                  ? ""
                  : languageObject.areaBeatId,
              json: languageObject.json !== null ? languageObject.json : "",
              insertedUserId:
                languageObject.insertedUserId !== null
                  ? languageObject.insertedUserId
                  : "",
              insertDateTime:
                languageObject.insertDateTime !== null
                  ? languageObject.insertDateTime
                  : "",
              jsonList:
                languageObject.jsonList !== null ? languageObject.jsonList : [],
            };
            return languageData;
          });
          dispatch(languageJsonSuccess({ row }));
          return { responseData: row, success };
        }
        return { responseData: data, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
