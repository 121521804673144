import endpoint from "../../config/endpoints";

export const publishRateConfigurationJson = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.checkPublicationDate,
  formPath: "/publish-rate-configuration-form",
  formPathView: "/publish-rate-configuration-view",
  screenTitle: "Publish Rate Configuration",
  showTitle: true,
  showCancel: false,
  showSaveBtn: false,
  fieldMeta: [
    {
      label: "Stock Holder Type",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder",
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeHolderTypeDetail",
      dataKey: "stockStakeHolderType",
      getListId: "stockStakeHolderType,category",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Category",
      controlType: "multiSelect",
      placeHolder: "Select Category",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "categoryDetail",
      dataKey: "category",
      getListId: "stockStakeHolderType,category",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Rate Configuration",
      controlType: "multiSelect",
      placeHolder: "Select Rate Configuration",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "rateConfigurationDetail",
      getListFrom: "stockStakeHolderType,category",
      dataKey: "productRateConfiguration",

      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      isMinDate: true,
      minDate: new Date(),
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "date",
      isMandatory: true,
    },
  ],
  fieldMetaForSearch: [
    {
      label: "Stock Holder Type",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeHolderTypeDetail",
      dataKey: "stockStakeHolderType",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Category",
      controlType: "multiSelect",
      placeHolder: "Select Category",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      masterName: "categoryDetail",
      dataKey: "category",
      isRootLevelKey: false,
      isMandatory: true,
    },
  ],
};
