import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import TouchAppRoundedIcon from "@mui/icons-material/TouchAppRounded";
import ReceiptIcon from '@mui/icons-material/Receipt';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import {
  ChipCompo,
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import DownloadIcon from "@mui/icons-material/Download";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { LabelCompo } from "../Label";
import ViewListIcon from "@mui/icons-material/ViewList";
import ReportIcon from "@mui/icons-material/Report";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import { cyanBlue } from "../../../config/ColorObj";
import {
  makeStyles,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  withStyles,
} from "@material-ui/core";
import "../../../CSS/NewLanding.css";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const StickyTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    // color: theme.palette.common.white,
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 2,
  },
  body: {
    backgroundColor: "#fff",
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 1,
  },
}))(TableCell);

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const breakpoint = 600;
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    hiddenColumnNames,
    tableHead,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {tableHead.filter((row) => row.isFrize).length != 0 && (
          <StickyTableCell>
            {tableHead
              .filter((row) => row.isFrize)
              .map((headCell, index) => {
                const hiddenColumn = hiddenColumnNames.filter(
                  (hiddenCol) => hiddenCol === headCell.name
                );
                if (hiddenColumn.length == 0) {
                  return (
                    <TableCell
                      key={headCell.name}
                      align={"center"}
                      sx={{ display: hiddenColumn.length !== 0 && "none" }}
                      style={{
                        minWidth:
                          headCell.name === "index"
                            ? 100
                            : headCell.width
                              ? headCell.width
                              : 100,
                      }}
                    // sortDirection={orderBy === headCell.name ? order : false}
                    >
                      <TableSortLabel
                        active={true}
                        direction={orderBy === headCell.name ? order : "asc"}
                        onClick={createSortHandler(headCell.name)}
                        className="tableCellHeaderColor"
                      >
                        {headCell.name == "index" ? "#" : headCell.title}
                        {orderBy === headCell.name ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  );
                }
              })}
          </StickyTableCell>
        )}
        {tableHead
          .filter((row) => row.isFrize == undefined)
          .map((headCell, index) => {
            const hiddenColumn = hiddenColumnNames.filter(
              (hiddenCol) => hiddenCol === headCell.name
            );
            if (hiddenColumn.length == 0) {
              return (
                <TableCell
                  key={headCell.name}
                  align={"center"}
                  sx={{ display: hiddenColumn.length !== 0 && "none" }}
                  style={{
                    minWidth:
                      headCell.name === "index"
                        ? 10
                        : headCell.width
                          ? headCell.width
                          : 100,
                  }}
                // sortDirection={orderBy === headCell.name ? order : false}
                >
                  <TableSortLabel
                    active={true}
                    direction={orderBy === headCell.name ? order : "asc"}
                    onClick={createSortHandler(headCell.name)}
                    className="tableCellHeaderColor"
                  >
                    {headCell.name == "index" ? "#" : headCell.title}
                    {orderBy === headCell.name ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              );
            }
          })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableHeadForMob(props) {
  const {
    onSelectAllClick,
    hiddenColumnNames,
    tableHead,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {tableHead.map((headCell, index) => {
          const hiddenColumn = hiddenColumnNames.filter(
            (hiddenCol) => hiddenCol === headCell.name
          );
          if (hiddenColumn.length == 0) {
            return (
              <TableCell
                key={headCell.name}
                align={"center"}
                sx={{ display: hiddenColumn.length !== 0 && "none" }}
                style={{
                  minWidth:
                    headCell.name === "index"
                      ? 10
                      : headCell.width
                        ? headCell.width
                        : 100,
                }}
              // sortDirection={orderBy === headCell.name ? order : false}
              >
                <TableSortLabel
                  active={true}
                  direction={orderBy === headCell.name ? order : "asc"}
                  onClick={createSortHandler(headCell.name)}
                  className="tableCellHeaderColor"
                >
                  {headCell.name == "index" ? "#" : headCell.title}
                  {orderBy === headCell.name ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHeadForMob.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  columns,
  onChangeStatus,
  rowDelete,
  rowEdit,
  rowCopy,
  showEditIcon,
  showSendRequestIcon,
  rowSendRequest,
  sendRequestIconName = "",
  sendRequestIconValue = "",
  showDeleteIcon,
  showCopyIcon,
  copyConditionName = "",
  copyConditionValue = "",
  transferConditionName = "",
  transferConditionValue = "",
  tableData = [],
  showAddButtonInTable,
  showTransferButtonInTable,
  showAddTitle,
  showViewButtonInTable,
  hiddenColumnNames = [],
  showPegination = true,
  rowAdd,
  rowTransfer,
  rowView,
  isActionColActive = true,
  showDownload,
  showOutlet,
  rowOutlet,
  rowDownload,
  setData,
  showSend,
  rowSend,
  showApply = false,
  showEWay = false,
  showCancel = false,
  showApplyTitle = "",
  showTransferTitle = "",
  showEWayTitle = "",
  showCancelTitle = "",
  rowApply,
  rowEWay,
  rowCancel,
  showPdf = false,
  showPdfLeger = false,
  onChangeStatusStatic,
  rowPDF,
  rowPDFLedger,
  deleteConditionName = "",
  deleteConditionValue = "",
  editConditionName = "",
  editConditionValue = "",
  credentialConditionName = "",
  credentialConditionValue = "",
  cancelBillConditionName = "",
  cancelBillConditionValue = "",
  chipCompoColor = false,
  totalList = [],
  showReportIcon,
  rowReport,
  rowLinkToShowData,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("zoneName");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = tableData.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      showPegination
        ? stableSort(tableData, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
        : stableSort(tableData, getComparator(order, orderBy)),
    [order, tableData, orderBy, page, rowsPerPage]
  );

  const tableStyle = {
    maxHeight: 500, // Set your desired height here
    overflowY: "scroll",
  };
  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer style={tableStyle}>
        <Table stickyHeader aria-label="sticky table">
          {width > breakpoint ? (
            <EnhancedTableHead
              hiddenColumnNames={hiddenColumnNames}
              tableHead={columns}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={tableData.length}
            />
          ) : (
            <EnhancedTableHeadForMob
              hiddenColumnNames={hiddenColumnNames}
              tableHead={columns}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={tableData.length}
            />
          )}
          <TableBody>
            {visibleRows.map((row, index) => {
              return (
                <>
                  {width > breakpoint ? (
                    <Row
                      rowLinkToShowData={rowLinkToShowData}
                      index={index}
                      key={row.id}
                      row={row}
                      tableHead={columns}
                      rowEdit={rowEdit}
                      rowCopy={rowCopy}
                      showEditIcon={showEditIcon}
                      showDeleteIcon={showDeleteIcon}
                      showCopyIcon={showCopyIcon}
                      copyConditionName={copyConditionName}
                      copyConditionValue={copyConditionValue}
                      transferConditionName={transferConditionName}
                      transferConditionValue={transferConditionValue}
                      onChangeStatus={onChangeStatus}
                      rowDelete={rowDelete}
                      rowAdd={rowAdd}
                      rowTransfer={rowTransfer}
                      rowView={rowView}
                      showViewButtonInTable={showViewButtonInTable}
                      showAddButtonInTable={showAddButtonInTable}
                      showTransferButtonInTable={showTransferButtonInTable}
                      showAddTitle={showAddTitle}
                      isActionColActive={isActionColActive}
                      hiddenColumnNames={hiddenColumnNames}
                      showDownload={showDownload}
                      rowOutlet={rowOutlet}
                      showOutlet={showOutlet}
                      showApplyTitle={showApplyTitle}
                      showTransferTitle={showTransferTitle}
                      showEWayTitle={showEWayTitle}
                      showCancelTitle={showCancelTitle}
                      showApply={showApply}
                      showEWay={showEWay}
                      rowDownload={rowDownload}
                      rowApply={rowApply}
                      rowEWay={rowEWay}
                      rowCancel={rowCancel}
                      tableData={tableData}
                      setData={setData}
                      showSend={showSend}
                      rowSend={rowSend}
                      rowPDF={rowPDF}
                      rowPDFLedger={rowPDFLedger}
                      showPdfLeger={showPdfLeger}
                      showPdf={showPdf}
                      showCancel={showCancel}
                      deleteConditionName={deleteConditionName}
                      deleteConditionValue={deleteConditionValue}
                      editConditionName={editConditionName}
                      editConditionValue={editConditionValue}
                      credentialConditionName={credentialConditionName}
                      credentialConditionValue={credentialConditionValue}
                      cancelBillConditionName={cancelBillConditionName}
                      cancelBillConditionValue={cancelBillConditionValue}
                      onChangeStatusStatic={onChangeStatusStatic}
                      showSendRequestIcon={showSendRequestIcon}
                      rowSendRequest={rowSendRequest}
                      sendRequestIconName={sendRequestIconName}
                      sendRequestIconValue={sendRequestIconValue}
                      chipCompoColor={chipCompoColor}
                    />
                  ) : (
                    <RowForMob
                      rowLinkToShowData={rowLinkToShowData}
                      index={index}
                      key={row.id}
                      row={row}
                      tableHead={columns}
                      rowEdit={rowEdit}
                      rowCopy={rowCopy}
                      showEditIcon={showEditIcon}
                      showDeleteIcon={showDeleteIcon}
                      showCopyIcon={showCopyIcon}
                      copyConditionName={copyConditionName}
                      copyConditionValue={copyConditionValue}
                      transferConditionName={transferConditionName}
                      transferConditionValue={transferConditionValue}
                      onChangeStatus={onChangeStatus}
                      rowDelete={rowDelete}
                      rowAdd={rowAdd}
                      rowTransfer={rowTransfer}
                      rowView={rowView}
                      showViewButtonInTable={showViewButtonInTable}
                      showAddButtonInTable={showAddButtonInTable}
                      showTransferButtonInTable={showTransferButtonInTable}
                      isActionColActive={isActionColActive}
                      hiddenColumnNames={hiddenColumnNames}
                      showDownload={showDownload}
                      rowOutlet={rowOutlet}
                      showOutlet={showOutlet}
                      showApplyTitle={showApplyTitle}
                      showTransferTitle={showTransferTitle}
                      showEWayTitle={showEWayTitle}
                      showCancelTitle={showCancelTitle}
                      showAddTitle={showAddTitle}
                      showApply={showApply}
                      showEWay={showEWay}
                      rowDownload={rowDownload}
                      rowApply={rowApply}
                      rowEWay={rowEWay}
                      rowCancel={rowCancel}
                      tableData={tableData}
                      setData={setData}
                      showSend={showSend}
                      rowSend={rowSend}
                      rowPDF={rowPDF}
                      rowPDFLedger={rowPDFLedger}
                      showPdf={showPdf}
                      showCancel={showCancel}
                      showPdfLeger={showPdfLeger}
                      deleteConditionName={deleteConditionName}
                      deleteConditionValue={deleteConditionValue}
                      editConditionName={editConditionName}
                      editConditionValue={editConditionValue}
                      credentialConditionName={credentialConditionName}
                      credentialConditionValue={credentialConditionValue}
                      cancelBillConditionName={cancelBillConditionName}
                      cancelBillConditionValue={cancelBillConditionValue}
                      onChangeStatusStatic={onChangeStatusStatic}
                      showSendRequestIcon={showSendRequestIcon}
                      rowSendRequest={rowSendRequest}
                      sendRequestIconName={sendRequestIconName}
                      sendRequestIconValue={sendRequestIconValue}
                      chipCompoColor={chipCompoColor}
                    />
                  )}
                </>
              );
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
            {/* Add more rows here */}
          </TableBody>
        </Table>
      </TableContainer>

      {showPegination && (
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
}

function RowForMob({
  index,
  row,
  tableData,
  tableHead,
  onChangeStatus,
  rowDelete,
  rowEdit,
  rowCopy,
  showSendRequestIcon,
  rowSendRequest,
  sendRequestIconName = "",
  sendRequestIconValue = "",
  showEditIcon = true,
  showDeleteIcon = true,
  showCopyIcon,
  copyConditionName = "",
  copyConditionValue = "",
  transferConditionName = "",
  transferConditionValue = "",
  showApply = false,
  showEWay = false,
  showApplyTitle = "",
  showTransferTitle = "",
  showEWayTitle = "",
  showCancelTitle = "",
  rowAdd,
  rowTransfer,
  rowView,
  showAddButtonInTable,
  showTransferButtonInTable,
  showViewButtonInTable,
  isActionColActive,
  hiddenColumnNames,
  showDownload,
  rowOutlet,
  showOutlet,
  rowDownload,
  setData,
  showSend,
  rowSend,
  rowApply,
  rowEWay,
  rowCancel,
  rowPDF,
  rowPDFLedger,
  showPdf,
  showCancel = false,
  showPdfLeger,
  deleteConditionName = "",
  deleteConditionValue = "",
  editConditionName = "",
  editConditionValue = "",
  onChangeStatusStatic,
  credentialConditionName = "",
  credentialConditionValue = "",
  cancelBillConditionName = "",
  cancelBillConditionValue = "",
  chipCompoColor,
  showReportIcon,
  rowReport,
  rowLinkToShowData,
}) {
  const [role, setRole] = React.useState("");

  const changeStatus = (e, row) => {
    e.stopPropagation();
    onChangeStatus(row);
  };
  const changeStatusUnique = (e, row) => {
    e.stopPropagation();
    onChangeStatusStatic(row);
  };

  const onClickEdit = (e, row) => {
    e.stopPropagation();
    rowEdit(row);
  };

  const onClickCopy = (e, row) => {
    e.stopPropagation();
    rowCopy(row);
  };

  const onClickSend = (e, row) => {
    e.stopPropagation();
    rowSend(row);
  };
  const onClickSendRequest = (e, row) => {
    e.stopPropagation();
    rowSendRequest(row);
  };
  const generatePDF = (e, row) => {
    e.stopPropagation();
    rowPDF(row);
  };
  const generatePDFLedger = (e, row) => {
    e.stopPropagation();
    rowPDFLedger(row);
  };
  const onClickDelete = (e, row) => {
    e.stopPropagation();
    rowDelete(row);
  };
  const onClickAdd = (e, row) => {
    e.stopPropagation();
    rowAdd(row);
  };

  const onClickTransfer = (e, row) => {
    e.stopPropagation();
    rowTransfer(row);
  };
  const onClickView = (e, row) => {
    e.stopPropagation();
    rowView(row);
  };

  const onClickDownload = (e, row) => {
    e.stopPropagation();
    rowDownload(row);
  };
  const onClickOutlet = (e, row) => {
    e.stopPropagation();
    rowOutlet(row);
  };
  const onClickApply = (e, row) => {
    e.stopPropagation();
    rowApply(row);
  };
  const onClickEWay = (e, row) => {
    e.stopPropagation();
    rowEWay(row);
  };

  const onClickCancel = (e, row) => {
    e.stopPropagation();
    rowCancel(row);
  };


  React.useEffect(() => {
    let role = localStorage.getItem("role");
    if (role) {
      setRole(role);
    }
  }, []);

  const onClickReport = (e, row) => {
    e.stopPropagation();
    rowReport(row);
  };
  const onClickLinkToShowData = (e, row, tableRow) => {
    e.stopPropagation();
    rowLinkToShowData(row, tableRow);
  };
  const onClickRead = (id) => {
    const rows = tableData.map((rowData) => {
      if (rowData.id === id) {
        rowData = {
          ...rowData,
          open: !rowData.open,
        };
        return rowData;
      }
      return rowData;
    });
    setData(rows);
  };
  return (
    <TableRow key={row.name}>
      {Object.keys(row).map(function (key, index) {
        const filteredList = tableHead.filter((row) => row.name === key);
        const hiddenColumn = hiddenColumnNames.filter(
          (hiddenCol) => hiddenCol === key
        );
        if (filteredList.length !== 0 && hiddenColumn.length == 0) {
          if (filteredList[0].positionCenter && key !== "isActive")
            return (
              <TableCell style={{ width: "10%" }}>
                <center>{row[key]}</center>
              </TableCell>
            );
          else if (key === "isActive") {
            return (
              <TableCell
                sx={{ display: hiddenColumn.length !== 0 && "none" }}
                style={{ width: "15%" }}
              >
                {row[key] == 1 ? (
                  <center>
                    <ChipCompo
                      label="Active"
                      variant="outlined"
                      size="small"
                      color="success"
                      onClick={(e) => changeStatus(e, row)}
                    />
                  </center>
                ) : (
                  <center>
                    <ChipCompo
                      label="Inactive"
                      variant="outlined"
                      size="small"
                      color="error"
                      onClick={(e) => changeStatus(e, row)}
                    />
                  </center>
                )}
              </TableCell>
            );
          } else if (filteredList[0].chipCompo) {
            return (
              <TableCell
                sx={{ display: hiddenColumn.length !== 0 && "none" }}
                style={{ width: "15%" }}
              >
                {row[filteredList[0].formDataKey] ==
                  filteredList[0].chipClick ? (
                  <center>
                    <ChipCompo
                      label={row[key]}
                      variant="outlined"
                      size="small"
                      color={chipCompoColor ? row["color"] : "error"}
                      onClick={(e) => changeStatusUnique(e, row)}
                    />
                  </center>
                ) : (
                  <center>
                    <ChipCompo
                      label={row[key]}
                      variant="outlined"
                      size="small"
                      color={chipCompoColor ? row["color"] : "success"}
                    />
                  </center>
                )}
              </TableCell>
            );
          } else if (filteredList[0].readMore) {
            return (
              <TableCell
                width={filteredList[0].name === "index" ? "10%" : ""}
                align={filteredList[0].align}
                sx={{ display: hiddenColumn.length !== 0 && "none" }}
              >
                {!row.open &&
                  row[filteredList[0].readMoreData].length >
                  row[filteredList[0].readMoreData].slice(0, 70).length
                  ? row[filteredList[0].readMoreData].slice(0, 70) + " ... "
                  : row[filteredList[0].readMoreData].slice(0, 70)}{" "}
                {!row.open &&
                  row[filteredList[0].readMoreData] !==
                  row[filteredList[0].readMoreData].slice(0, 70) && (
                    <LabelCompo
                      className="text-black"
                      style={{
                        fontSize: 16,
                        textDecoration: "underline",
                        color: "blue",
                        cursor: "pointer",
                      }}
                      label={" Read more"}
                      onClick={() => onClickRead(row.id)}
                    />
                  )}
                {row.open && row[filteredList[0].readMoreData]}
                {row.open &&
                  row[filteredList[0].readMoreData].length >
                  row[filteredList[0].readMoreData].slice(0, 70).length && (
                    <LabelCompo
                      className="text-black"
                      style={{
                        fontSize: 16,
                        textDecoration: "underline",
                        color: "blue",
                        cursor: "pointer",
                      }}
                      label={"Read less"}
                      onClick={() => onClickRead(row.id)}
                    />
                  )}
              </TableCell>
            );
          }
          if (filteredList[0].linkOpen)
            return (
              <TableCell
                align={filteredList[0].align}
                sx={{ display: hiddenColumn.length !== 0 && "none" }}
              >
                <div
                  onClick={(e) =>
                    onClickLinkToShowData(e, row, filteredList[0])
                  }
                  style={{
                    color: cyanBlue,
                    cursor: "pointer",
                  }}
                >
                  {row[key]}
                </div>
              </TableCell>
            );
          else
            return (
              <TableCell
                width={filteredList[0].name === "index" ? "10%" : ""}
                align={filteredList[0].align}
                sx={{ display: hiddenColumn.length !== 0 && "none" }}
              >
                {row[key]}
              </TableCell>
            );
        }
        return null;
      })}

      {isActionColActive && (
        <>
          <TableCell align={"center"} style={{ width: "13%" }}>
            {showEditIcon && editConditionName == "" && (
              <>
                <MyComponentWithIconProps
                  statusImage={EditIcon}
                  color="primary"
                  fontSize="medium"
                  title="Edit"
                  onClick={(e) => onClickEdit(e, row)}
                />
              </>
            )}
            {showEditIcon &&
              editConditionName != "" &&
              row[editConditionName] == editConditionValue && (
                <>
                  <MyComponentWithIconProps
                    statusImage={EditIcon}
                    color="primary"
                    fontSize="medium"
                    title="Edit"
                    onClick={(e) => onClickEdit(e, row)}
                  />
                </>
              )}
            {showEditIcon &&
              editConditionName != "" &&
              row[editConditionName] != editConditionValue && <>&emsp;&emsp;</>}

            {showDeleteIcon && deleteConditionName == "" && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={DeleteIcon}
                  color="error"
                  fontSize="medium"
                  title="Delete"
                  onClick={(e) => onClickDelete(e, row)}
                />
              </>
            )}
            {showDeleteIcon &&
              deleteConditionName != "" &&
              row[deleteConditionName] == deleteConditionValue && (
                <>
                  &nbsp;&nbsp;
                  <MyComponentWithIconProps
                    statusImage={DeleteIcon}
                    color="error"
                    fontSize="medium"
                    title="Delete"
                    onClick={(e) => onClickDelete(e, row)}
                  />
                </>
              )}
            {showCopyIcon && copyConditionName == "" && (
              <>
                <MyComponentWithIconProps
                  statusImage={CopyAllIcon}
                  color="primary"
                  fontSize="medium"
                  title="Copy To Save"
                  onClick={(e) => onClickCopy(e, row)}
                />
              </>
            )}
            {showCopyIcon &&
              copyConditionName != "" &&
              row[copyConditionName] == copyConditionValue && (
                <>
                  <MyComponentWithIconProps
                    statusImage={CopyAllIcon}
                    color="primary"
                    fontSize="medium"
                    title="Copy To Save"
                    onClick={(e) => onClickCopy(e, row)}
                  />
                </>
              )}



            {showTransferButtonInTable && transferConditionName == "" && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={MoveUpIcon}
                  color="primary"
                  fontSize="medium"
                  title={showTransferTitle ? showTransferTitle : "Assign Structure"}
                  onClick={(e) => onClickTransfer(e, row)}
                />
              </>
            )}

            {showTransferButtonInTable &&
              transferConditionName != "" &&
              row[transferConditionName] == transferConditionValue && (
                <>
                  <MyComponentWithIconProps
                    statusImage={MoveUpIcon}
                    color="primary"
                    fontSize="medium"
                    title={showTransferTitle ? showTransferTitle : "Assign Structure"}
                    onClick={(e) => onClickTransfer(e, row)}
                  />
                </>
              )}


            {showSendRequestIcon &&
              sendRequestIconName != "" &&
              row[sendRequestIconName] == sendRequestIconValue && (
                <>
                  &nbsp;&nbsp;
                  <MyComponentWithIconProps
                    statusImage={SendIcon}
                    color="primary"
                    fontSize="medium"
                    title="Send Request"
                    onClick={(e) => onClickSendRequest(e, row)}
                  />
                </>
              )}
            {showDeleteIcon &&
              deleteConditionName != "" &&
              row[deleteConditionName] != deleteConditionValue && (
                <>&emsp;&emsp;</>
              )}
            {showReportIcon && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={ReportIcon}
                  color="error"
                  fontSize="medium"
                  title="Sub Report"
                  onClick={(e) => onClickReport(e, row)}
                />
              </>
            )}
            {showSend && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={RateReviewIcon}
                  color="primary"
                  fontSize="medium"
                  title="Rate Configuration"
                  onClick={(e) => onClickSend(e, row)}
                />
              </>
            )}

            {/* {showApply && credentialConditionName != "" &&
 row[credentialConditionName] == credentialConditionValue && (
 <>
 &nbsp;&nbsp;
 <MyComponentWithIconProps
 statusImage={TouchAppRoundedIcon}
 color="primary"
 fontSize="medium"
 title={"Send Credential"}
 onClick={(e) => onClickApply(e, row)}
 />
 </>
 )} */}

            {showApply && credentialConditionName == "" && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={TouchAppRoundedIcon}
                  color="primary"
                  fontSize="medium"
                  title={showApplyTitle ? showApplyTitle : "Send Credential"}
                  onClick={(e) => onClickApply(e, row)}
                />
              </>
            )}
            {showApply &&
              credentialConditionName != "" &&
              row[credentialConditionName] == credentialConditionValue && (
                <>
                  &nbsp;&nbsp;
                  <MyComponentWithIconProps
                    statusImage={TouchAppRoundedIcon}
                    color="primary"
                    fontSize="medium"
                    title={showApplyTitle ? showApplyTitle : "Send Credential"}
                    onClick={(e) => onClickApply(e, row)}
                  />
                </>
              )}
            {showApply &&
              credentialConditionName != "" &&
              row[credentialConditionName] != credentialConditionValue && (
                <>&emsp;&emsp;</>
              )}

            {showCancel && cancelBillConditionName == "" && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={DisabledByDefaultIcon}
                  color="primary"
                  fontSize="medium"
                  title={showCancelTitle ? showCancelTitle : "Cancel Bill"}
                  onClick={(e) => onClickCancel(e, row)}
                />
              </>
            )}

            {showCancel &&
              cancelBillConditionName != "" &&
              row[cancelBillConditionName] == cancelBillConditionValue && (
                <>
                  &nbsp;&nbsp;
                  <MyComponentWithIconProps
                    statusImage={DisabledByDefaultIcon}
                    color="primary"
                    fontSize="medium"
                    title={showCancelTitle ? showCancelTitle : "Cancel Bill"}
                    onClick={(e) => onClickCancel(e, row)}
                  />
                </>
              )}

            {showCancel &&
              cancelBillConditionName != "" &&
              row[cancelBillConditionName] != cancelBillConditionValue && (
                <>&emsp;&emsp;</>
              )}

            {showEWay && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={ReceiptIcon}
                  color="primary"
                  fontSize="medium"
                  title={showEWayTitle ? showEWayTitle : "E-Way Bill"}
                  onClick={(e) => onClickEWay(e, row)}
                />
              </>
            )}

            {showPdf && (
              <>
                &nbsp;&nbsp;
                <FontAwesomeIconCompo
                  color="error"
                  fontSize="small"
                  title="PDF"
                  baseClassName="fas"
                  className="fa-file-pdf"
                  onClick={(e) => generatePDF(e, row)}
                />
              </>
            )}


            {showPdfLeger && (
              <>
                &nbsp;&nbsp;
                <FontAwesomeIconCompo
                  color="error"
                  fontSize="small"
                  title="Bill PDF"
                  baseClassName="fas"
                  className="fa-file-pdf"
                  onClick={(e) => generatePDFLedger(e, row)}
                />
              </>
            )}
            {showAddButtonInTable && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={AddCircleIcon}
                  color="primary"
                  fontSize="medium"
                  title="Add Document"
                  onClick={(e) => onClickAdd(e, row)}
                />
              </>
            )}


            {showViewButtonInTable && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={VisibilityIcon}
                  color="primary"
                  fontSize="medium"
                  title="View"
                  onClick={(e) => onClickView(e, row)}
                />
              </>
            )}
            {showDownload && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={DownloadIcon}
                  color="primary"
                  fontSize="medium"
                  title="View"
                  onClick={(e) => onClickDownload(e, row)}
                />
              </>
            )}
            {showOutlet && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={ViewListIcon}
                  color="primary"
                  fontSize="medium"
                  title="Show Details"
                  onClick={(e) => onClickOutlet(e, row)}
                />
              </>
            )}
          </TableCell>
        </>
      )}
    </TableRow>
  );
}

function Row({
  index,
  row,
  tableData,
  tableHead,
  onChangeStatus,
  rowDelete,
  rowEdit,
  rowCopy,
  showSendRequestIcon,
  rowSendRequest,
  sendRequestIconName = "",
  sendRequestIconValue = "",
  showEditIcon = true,
  showDeleteIcon = true,
  showCopyIcon,
  copyConditionName = "",
  copyConditionValue = "",
  transferConditionName = "",
  transferConditionValue = "",
  showApply = false,
  showEWay = false,
  showApplyTitle = "",
  showTransferTitle = "",
  showEWayTitle = "",
  showCancelTitle = "",
  rowAdd,
  rowTransfer,
  rowView,
  showAddButtonInTable,
  showTransferButtonInTable,
  showAddTitle,
  showViewButtonInTable,
  isActionColActive,
  hiddenColumnNames,
  showDownload,
  rowOutlet,
  showOutlet,
  rowDownload,
  setData,
  showSend,
  rowSend,
  rowApply,
  rowEWay,
  rowCancel,
  rowPDF,
  rowPDFLedger,
  showPdfLeger,
  showPdf,
  showCancel = false,
  deleteConditionName = "",
  deleteConditionValue = "",
  editConditionName = "",
  editConditionValue = "",
  onChangeStatusStatic,
  credentialConditionName = "",
  credentialConditionValue = "",
  cancelBillConditionName = "",
  cancelBillConditionValue = "",
  chipCompoColor,
  showReportIcon,
  rowReport,
  rowLinkToShowData,
}) {
  const [role, setRole] = React.useState("");

  const changeStatus = (e, row) => {
    e.stopPropagation();
    onChangeStatus(row);
  };
  const changeStatusUnique = (e, row) => {
    e.stopPropagation();
    onChangeStatusStatic(row);
  };

  const onClickEdit = (e, row) => {
    e.stopPropagation();
    rowEdit(row);
  };

  const onClickCopy = (e, row) => {
    e.stopPropagation();
    rowCopy(row);
  };

  const onClickSend = (e, row) => {
    e.stopPropagation();
    rowSend(row);
  };
  const onClickSendRequest = (e, row) => {
    e.stopPropagation();
    rowSendRequest(row);
  };
  const generatePDF = (e, row) => {
    e.stopPropagation();
    rowPDF(row);
  };
  const generatePDFLedger = (e, row) => {
    e.stopPropagation();
    rowPDFLedger(row);
  };

  const onClickDelete = (e, row) => {
    e.stopPropagation();
    rowDelete(row);
  };
  const onClickAdd = (e, row) => {
    e.stopPropagation();
    rowAdd(row);
  };

  const onClickTransfer = (e, row) => {
    e.stopPropagation();
    rowTransfer(row);
  };
  const onClickView = (e, row) => {
    e.stopPropagation();
    rowView(row);
  };

  const onClickDownload = (e, row) => {
    e.stopPropagation();
    rowDownload(row);
  };
  const onClickOutlet = (e, row) => {
    e.stopPropagation();
    rowOutlet(row);
  };
  const onClickApply = (e, row) => {
    e.stopPropagation();
    rowApply(row);
  };

  const onClickEWay = (e, row) => {
    e.stopPropagation();
    rowEWay(row);
  };

  const onClickCancel = (e, row) => {
    e.stopPropagation();
    rowCancel(row);
  };

  React.useEffect(() => {
    let role = localStorage.getItem("role");
    if (role) {
      setRole(role);
    }
  }, []);

  const onClickReport = (e, row) => {
    e.stopPropagation();
    rowReport(row);
  };
  const onClickLinkToShowData = (e, row, tableRow) => {
    e.stopPropagation();
    rowLinkToShowData(row, tableRow);
  };
  const onClickRead = (id) => {
    const rows = tableData.map((rowData) => {
      if (rowData.id === id) {
        rowData = {
          ...rowData,
          open: !rowData.open,
        };
        return rowData;
      }
      return rowData;
    });
    setData(rows);
  };
  return (
    <TableRow key={row.name}>
      {tableHead.filter((row) => row.isFrize).length != 0 && (
        <StickyTableCell>
          {tableHead
            .filter((row) => row.isFrize)
            .map((rowData) => {
              const hiddenColumn = hiddenColumnNames.filter(
                (hiddenCol) => hiddenCol === rowData.name
              );
              if (hiddenColumn.length == 0) {
                return (
                  <TableCell
                    width={rowData.name === "index" ? 100 : rowData.width}
                    align={rowData.align}
                    sx={{ display: hiddenColumn.length !== 0 && "none" }}
                  >
                    {row[rowData.name]}
                  </TableCell>
                );
              }
            })}
        </StickyTableCell>
      )}
      {Object.keys(row).map(function (key, index) {
        const filteredList = tableHead.filter(
          (row) => row.name === key && row.isFrize == undefined
        );
        const hiddenColumn = hiddenColumnNames.filter(
          (hiddenCol) => hiddenCol === key
        );
        if (filteredList.length !== 0 && hiddenColumn.length == 0) {
          if (filteredList[0].positionCenter && key !== "isActive")
            return (
              <TableCell style={{ width: "10%" }}>
                <center>{row[key]}</center>
              </TableCell>
            );
          else if (key === "isActive") {
            return (
              <TableCell
                sx={{ display: hiddenColumn.length !== 0 && "none" }}
                style={{ width: "15%" }}
              >
                {row[key] == 1 ? (
                  <center>
                    <ChipCompo
                      label="Active"
                      variant="outlined"
                      size="small"
                      color="success"
                      onClick={(e) => changeStatus(e, row)}
                    />
                  </center>
                ) : (
                  <center>
                    <ChipCompo
                      label="Inactive"
                      variant="outlined"
                      size="small"
                      color="error"
                      onClick={(e) => changeStatus(e, row)}
                    />
                  </center>
                )}
              </TableCell>
            );
          } else if (filteredList[0].chipCompo) {
            return (
              <TableCell
                sx={{ display: hiddenColumn.length !== 0 && "none" }}
                style={{ width: "15%" }}
              >
                {row[filteredList[0].formDataKey] ==
                  filteredList[0].chipClick ? (
                  <center>
                    <ChipCompo
                      label={row[key]}
                      variant="outlined"
                      size="small"
                      color={chipCompoColor ? row["color"] : "error"}
                      onClick={(e) => changeStatusUnique(e, row)}
                    />
                  </center>
                ) : (
                  <center>
                    <ChipCompo
                      label={row[key]}
                      variant="outlined"
                      size="small"
                      color={chipCompoColor ? row["color"] : "success"}
                    />
                  </center>
                )}
              </TableCell>
            );
          } else if (filteredList[0].readMore) {
            return (
              <TableCell
                width={filteredList[0].name === "index" ? "10%" : ""}
                align={filteredList[0].align}
                sx={{ display: hiddenColumn.length !== 0 && "none" }}
              >
                {!row.open &&
                  row[filteredList[0].readMoreData].length >
                  row[filteredList[0].readMoreData].slice(0, 70).length
                  ? row[filteredList[0].readMoreData].slice(0, 70) + " ... "
                  : row[filteredList[0].readMoreData].slice(0, 70)}{" "}
                {!row.open &&
                  row[filteredList[0].readMoreData] !==
                  row[filteredList[0].readMoreData].slice(0, 70) && (
                    <LabelCompo
                      className="text-black"
                      style={{
                        fontSize: 16,
                        textDecoration: "underline",
                        color: "blue",
                        cursor: "pointer",
                      }}
                      label={" Read more"}
                      onClick={() => onClickRead(row.id)}
                    />
                  )}
                {row.open && row[filteredList[0].readMoreData]}
                {row.open &&
                  row[filteredList[0].readMoreData].length >
                  row[filteredList[0].readMoreData].slice(0, 70).length && (
                    <LabelCompo
                      className="text-black"
                      style={{
                        fontSize: 16,
                        textDecoration: "underline",
                        color: "blue",
                        cursor: "pointer",
                      }}
                      label={"Read less"}
                      onClick={() => onClickRead(row.id)}
                    />
                  )}
              </TableCell>
            );
          }
          if (filteredList[0].linkOpen)
            return (
              <TableCell
                align={filteredList[0].align}
                sx={{ display: hiddenColumn.length !== 0 && "none" }}
              >
                <div
                  onClick={(e) =>
                    onClickLinkToShowData(e, row, filteredList[0])
                  }
                  style={{
                    color: cyanBlue,
                    cursor: "pointer",
                  }}
                >
                  {row[key]}
                </div>
              </TableCell>
            );
          else
            return (
              <TableCell
                width={filteredList[0].name === "index" ? "10%" : ""}
                align={filteredList[0].align}
                sx={{ display: hiddenColumn.length !== 0 && "none" }}
              >
                {row[key]}
              </TableCell>
            );
        }
        return null;
      })}

      {isActionColActive && (
        <>
          <TableCell align={"center"} style={{ width: "13%" }}>
            {showEditIcon && editConditionName == "" && (
              <>
                <MyComponentWithIconProps
                  statusImage={EditIcon}
                  color="primary"
                  fontSize="medium"
                  title="Edit"
                  onClick={(e) => onClickEdit(e, row)}
                />
              </>
            )}
            {showEditIcon &&
              editConditionName != "" &&
              row[editConditionName] == editConditionValue && (
                <>
                  <MyComponentWithIconProps
                    statusImage={EditIcon}
                    color="primary"
                    fontSize="medium"
                    title="Edit"
                    onClick={(e) => onClickEdit(e, row)}
                  />
                </>
              )}
            {showEditIcon &&
              editConditionName != "" &&
              row[editConditionName] != editConditionValue && <>&emsp;&emsp;</>}

            {showDeleteIcon && deleteConditionName == "" && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={DeleteIcon}
                  color="error"
                  fontSize="medium"
                  title="Delete"
                  onClick={(e) => onClickDelete(e, row)}
                />
              </>
            )}
            {showDeleteIcon &&
              deleteConditionName != "" &&
              row[deleteConditionName] == deleteConditionValue && (
                <>
                  &nbsp;&nbsp;
                  <MyComponentWithIconProps
                    statusImage={DeleteIcon}
                    color="error"
                    fontSize="medium"
                    title="Delete"
                    onClick={(e) => onClickDelete(e, row)}
                  />
                </>
              )}
            {showCopyIcon && copyConditionName == "" && (
              <>
                <MyComponentWithIconProps
                  statusImage={CopyAllIcon}
                  color="primary"
                  fontSize="medium"
                  title="Copy To Save"
                  onClick={(e) => onClickCopy(e, row)}
                />
              </>
            )}
            {showCopyIcon &&
              copyConditionName != "" &&
              row[copyConditionName] == copyConditionValue && (
                <>
                  <MyComponentWithIconProps
                    statusImage={CopyAllIcon}
                    color="primary"
                    fontSize="medium"
                    title="Copy To Save"
                    onClick={(e) => onClickCopy(e, row)}
                  />
                </>
              )}


            {showTransferButtonInTable && transferConditionName == "" && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={MoveUpIcon}
                  color="primary"
                  fontSize="medium"
                  title={showTransferTitle ? showTransferTitle : "Assign Structure"}
                  onClick={(e) => onClickTransfer(e, row)}
                />
              </>
            )}

            {showTransferButtonInTable &&
              transferConditionName != "" &&
              row[transferConditionName] == transferConditionValue && (
                <>
                  <MyComponentWithIconProps
                    statusImage={MoveUpIcon}
                    color="primary"
                    fontSize="medium"
                    title={showTransferTitle ? showTransferTitle : "Assign Structure"}
                    onClick={(e) => onClickTransfer(e, row)}
                  />
                </>
              )}

            {showSendRequestIcon &&
              sendRequestIconName != "" &&
              row[sendRequestIconName] == sendRequestIconValue && (
                <>
                  &nbsp;&nbsp;
                  <MyComponentWithIconProps
                    statusImage={SendIcon}
                    color="primary"
                    fontSize="medium"
                    title="Send Request"
                    onClick={(e) => onClickSendRequest(e, row)}
                  />
                </>
              )}
            {showDeleteIcon &&
              deleteConditionName != "" &&
              row[deleteConditionName] != deleteConditionValue && (
                <>&emsp;&emsp;</>
              )}
            {showReportIcon && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={ReportIcon}
                  color="error"
                  fontSize="medium"
                  title="Sub Report"
                  onClick={(e) => onClickReport(e, row)}
                />
              </>
            )}
            {showSend && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={RateReviewIcon}
                  color="primary"
                  fontSize="medium"
                  title="Rate Configuration"
                  onClick={(e) => onClickSend(e, row)}
                />
              </>
            )}

            {/* {showApply && credentialConditionName != "" &&
 row[credentialConditionName] == credentialConditionValue && (
 <>
 &nbsp;&nbsp;
 <MyComponentWithIconProps
 statusImage={TouchAppRoundedIcon}
 color="primary"
 fontSize="medium"
 title={"Send Credential"}
 onClick={(e) => onClickApply(e, row)}
 />
 </>
 )} */}

            {showApply && credentialConditionName == "" && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={TouchAppRoundedIcon}
                  color="primary"
                  fontSize="medium"
                  title={showApplyTitle ? showApplyTitle : "Send Credential"}
                  onClick={(e) => onClickApply(e, row)}
                />
              </>
            )}
            {showApply &&
              credentialConditionName != "" &&
              row[credentialConditionName] == credentialConditionValue && (
                <>
                  &nbsp;&nbsp;
                  <MyComponentWithIconProps
                    statusImage={TouchAppRoundedIcon}
                    color="primary"
                    fontSize="medium"
                    title={showApplyTitle ? showApplyTitle : "Send Credential"}
                    onClick={(e) => onClickApply(e, row)}
                  />
                </>
              )}
            {showApply &&
              credentialConditionName != "" &&
              row[credentialConditionName] != credentialConditionValue && (
                <>&emsp;&emsp;</>
              )}

            {showCancel && cancelBillConditionName == "" && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={DisabledByDefaultIcon}
                  color="primary"
                  fontSize="medium"
                  title={showCancelTitle ? showCancelTitle : "Cancel Bill"}
                  onClick={(e) => onClickCancel(e, row)}
                />
              </>
            )}

            {showCancel &&
              cancelBillConditionName != "" &&
              row[cancelBillConditionName] == cancelBillConditionValue && (
                <>
                  &nbsp;&nbsp;
                  <MyComponentWithIconProps
                    statusImage={DisabledByDefaultIcon}
                    color="primary"
                    fontSize="medium"
                    title={showCancelTitle ? showCancelTitle : "Cancel Bill"}
                    onClick={(e) => onClickCancel(e, row)}
                  />
                </>
              )}

            {showCancel &&
              cancelBillConditionName != "" &&
              row[cancelBillConditionName] != cancelBillConditionValue && (
                <>&emsp;&emsp;</>
              )}

            {showEWay && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={ReceiptIcon}
                  color="primary"
                  fontSize="medium"
                  title={showEWayTitle ? showEWayTitle : "E-Way Bill"}
                  onClick={(e) => onClickEWay(e, row)}
                />
              </>
            )}

            {showPdf && (
              <>
                &nbsp;&nbsp;
                <FontAwesomeIconCompo
                  color="error"
                  fontSize="small"
                  title="PDF"
                  baseClassName="fas"
                  className="fa-file-pdf"
                  onClick={(e) => generatePDF(e, row)}
                />
              </>
            )}



            {showPdfLeger && (
              <>
                &nbsp;&nbsp;
                <FontAwesomeIconCompo
                  color="error"
                  fontSize="small"
                  title="Bill PDF"
                  baseClassName="fas"
                  className="fa-file-pdf"
                  onClick={(e) => generatePDFLedger(e, row)}
                />
              </>
            )}
            {showAddButtonInTable && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={AddCircleIcon}
                  color="primary"
                  fontSize="medium"
                  title={showAddTitle ? showAddTitle : "Assign Access"}
                  onClick={(e) => onClickAdd(e, row)}
                />
              </>
            )}

            {showViewButtonInTable && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={VisibilityIcon}
                  color="primary"
                  fontSize="medium"
                  title="View"
                  onClick={(e) => onClickView(e, row)}
                />
              </>
            )}
            {showDownload && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={DownloadIcon}
                  color="primary"
                  fontSize="medium"
                  title="View"
                  onClick={(e) => onClickDownload(e, row)}
                />
              </>
            )}
            {showOutlet && (
              <>
                &nbsp;&nbsp;
                <MyComponentWithIconProps
                  statusImage={ViewListIcon}
                  color="primary"
                  fontSize="medium"
                  title="Show Details"
                  onClick={(e) => onClickOutlet(e, row)}
                />
              </>
            )}
          </TableCell>
        </>
      )
      }
    </TableRow >
  );
}
