import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg } from "../../config/messageconstant";
import { getPaymentRecieptDetail } from "../../Slice/paymentReciept.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
class PaymentDetailsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.columns = [
            {
                title: "Sr.No.",
                name: "index",
                align: "center",
            },
            {
                title: "Bill Number",
                name: "billNo",
                align: "center",
            },
            {
                title: "Bill Date",
                name: "billDate",
                align: "center",
            },
            {
                title: "Total Amount",
                name: "totalAmt",
                align: "right",
            },
        ];
    }

    async componentDidMount() {
        const { getPaymentRecieptDetail, showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            if (this.props.params.id) {
                showLoador({ loador: true });
                await getPaymentRecieptDetail({
                    paymentRecieptId: this.props.params.id,
                }).then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                    } else {
                        this.setState({});
                    }
                });
            }
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }
    render() {
        const { dynamicMasterData, DataToSet } = this.state;
        const { paymentDetailsList } = this.props;

        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 0,
                    }}
                    elevation={0}
                >
                    <DynamicFormWithoutSave
                        showBackToList={true}
                        DataToSet={DataToSet}
                        dynamicMasterData={dynamicMasterData}
                        showTitle={true}
                        screenTitle={"Payment Details View"}
                        saveBtnText={"Search"}
                        showSaveBtnMain={false}
                    />

                    <RTTable
                        columns={this.columns}
                        tableData={paymentDetailsList.paymentRecieptDetail}
                        isActionColActive={false}
                        showPegination={false}
                    />
                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    paymentDetailsList: state.paymentReciept,

});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getPaymentRecieptDetail,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(PaymentDetailsView);
