import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { ClaimStructureJson } from "../../../DynamicFormsJson/MastersJSON/claimStructure";
import { getClaimType, setClaimType } from "../../../Slice/claim.slice";
import { ButtonCompo } from "../../../components/Comman/Button";
import { LabelCompo } from "../../../components/Comman/Label";
import { TextFieldCompo } from "../../../components/Comman/TextFieldCompo";
import DynamicFormWithoutValidation from "../../../components/dynamicscreens/DynamicFormWithoutValidation";
import { withRouter } from "../../../components/withRouter";
import { DarkBlue, blackColor, redColor, titleColor } from "../../../config/ColorObj";
import endpoint from "../../../config/endpoints";
import { noInternetMsg, saveFailedMsg, saveWarningMsg, savemsg, serverMsg, updateData, updateMsg } from "../../../config/messageconstant";
import { apiGet, apiPost } from "../../../utils/api_service";
import { showLoador, showNotification } from "../../Landing/Landing.slice";

class ClaimStructureForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formErrors: {},
            fieldData: {},
            claimAmt: 0,
            claimStructureNameChangeFlag: false,
        };
    }

    async componentDidMount() {
        const {
            showLoador,
            getClaimType,
            showNotification,
            setClaimType
        } = this.props;

        setClaimType({ row: [] });


        showLoador({ loador: true });
        await getClaimType().then(({ response, success }) => {
            showLoador({ loador: false });
            if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
            } else {
                if (this.props.params.id) {
                    showLoador({ loador: true });
                    apiGet({
                        url: ClaimStructureJson.apiBaseURL + "/" + this.props.params.id,
                    }).then(({ data, success }) => {
                        showLoador({ loador: false });
                        if (success) {
                            if (data && data.data && Object.keys(data.data).length !== 0) {
                                this.editData(data.data);
                            }
                        } else {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    });
                }
            }
        });

    }



    editData = (data) => {
        console.log(data);
        const { claimTypeList, setClaimType } = this.props;
        const { fieldData } = this.state;

        let dataObject = {};

        let fieldDataName = {
            ...fieldData,
            name: data.name
        }

        let newDataToSet = claimTypeList?.claimType.map((dataObj) => {
            let filterData = data.claimStructureDetails.filter(
                (row) => row.claimType != null && row.claimType.id === dataObj.id
            );


            if (filterData.length !== 0) {

                filterData.map((filterObj) => {

                    dataObject = {
                        ...filterObj,
                        claimTypeId: dataObj.id,
                        structureId: filterObj.id,
                        name: dataObj.name,
                        type: dataObj.type,
                        uomName: dataObj.uomName !== null ? dataObj.uomName : "",
                        limitVal: dataObj.limitVal !== null ? dataObj.limitVal : "",
                        limitValue: dataObj.limitVal !== null ? dataObj.limitVal === 1 ? "Yes" : "No" : "",
                        claimAmt: filterObj.claimAmt !== null ? filterObj.claimAmt : 0,
                    }
                });

            } else {
                dataObject = {
                    ...dataObj,
                    claimTypeId: dataObj.id,
                    name: dataObj.name,
                    type: dataObj.type,
                    uomName: dataObj.uomName !== null ? dataObj.uomName : "",
                    limitVal: dataObj.limitVal !== null ? dataObj.limitVal : "",
                    limitValue: dataObj.limitVal !== null ? dataObj.limitVal === 1 ? "Yes" : "No" : "",
                    claimAmt: dataObj.claimAmt !== null ? dataObj.claimAmt : 0,
                };

            }
            return dataObject;
        });

        setClaimType({ row: newDataToSet });

        this.setState({
            fieldData: fieldDataName,
        });
    };





    onDataChange = (fieldName, newValue) => {
        const { fieldData } = this.state;
        const dataToSearch = {};
        const dataToReset = { ...this.state.fieldData };
        let listError = false;

        if (fieldName === "name") {
            this.setState({ nameChangeFlag: true })
        }

        ClaimStructureJson.fieldMetaHeader.map((currentField) => {
            if (currentField.getDate && currentField.dataKey === fieldName) {
                dataToReset[currentField.getDate] = newValue.add(365, "day");
            }
            if (currentField.dataKey === fieldName && currentField.getListId) {
                const masterKeyList = currentField.getListId.split(",");
                masterKeyList.map((key) => {
                    if (fieldName === key) {
                        dataToSearch[key] = newValue;
                    } else if (fieldData[key]) {
                        dataToSearch[key] = fieldData[key];
                    }
                    return null;
                });
            }
            if (currentField.getListFrom) {
                const masterKeyList = currentField.getListFrom.split(",");
                masterKeyList.map((key) => {
                    if (key == fieldName && dataToReset[key]) {
                        delete dataToReset[currentField.dataKey];
                    }
                });
            }

            return null;
        });

        dataToReset[fieldName] = newValue;
        this.setState({
            fieldData: dataToReset,
        });
    };


    changeHandlerTextField = (id) => (event) => {
        const { claimTypeList, setClaimType } = this.props;
        const { name, value } = event.target;

        const updatedList = claimTypeList?.claimType.map((dataObj) => {
            if (id === dataObj.id) {
                return {
                    ...dataObj,
                    [name]: value,
                };
            }
            return dataObj;
        });

        setClaimType({ row: updatedList })
    };





    mandatoryCheck = () => {
        let formValid = true;
        const { fieldData } = this.state;

        let formErrors = {};
        ClaimStructureJson.fieldMetaHeader.forEach((currentField) => {
            if (currentField.isMandatory) {
                if (
                    (currentField.controlType === "datepicker" &&
                        (fieldData[currentField.dataKey] === undefined ||
                            fieldData[currentField.dataKey] === "")) ||
                    (currentField.controlType !== "datepicker" &&
                        currentField.controlType !== "autocomplete" &&
                        (fieldData[currentField.dataKey] === undefined ||
                            fieldData[currentField.dataKey] === null ||
                            fieldData[currentField.dataKey].toString().trim() === ""))
                ) {
                    formValid = false;

                    formErrors = {
                        ...formErrors,
                        [currentField.dataKey]: {
                            isError: true,
                            errorText: `*${currentField.label} is required`,
                        },
                    };
                } else if (
                    currentField.controlType === "autocomplete" &&
                    (fieldData[currentField.dataKey] === undefined ||
                        fieldData[currentField.dataKey] === null ||
                        (!currentField.isRootLevelKey &&
                            fieldData[currentField.dataKey] != undefined &&
                            fieldData[currentField.dataKey] != null &&
                            fieldData[currentField.dataKey].id == null))
                ) {
                    formValid = false;

                    formErrors = {
                        ...formErrors,
                        [currentField.dataKey]: {
                            isError: true,
                            errorText: `*${currentField.label} is required`,
                        },
                    };
                }
            } else if (currentField.controlType === "hideTextfield") {
                if (
                    fieldData[currentField.tableDataKey] === currentField.open &&
                    (fieldData[currentField.dataKey] === undefined ||
                        fieldData[currentField.dataKey] === "")
                ) {
                    formValid = false;

                    formErrors = {
                        ...formErrors,
                        [currentField.dataKey]: {
                            isError: true,
                            errorText: `*${currentField.error} is required`,
                        },
                    };
                }
            } else if (currentField.controlType === "hideDatepicker") {
                if (
                    fieldData[currentField.tableDataKey] === currentField.open &&
                    (fieldData[currentField.dataKey] === undefined ||
                        fieldData[currentField.dataKey] === null ||
                        fieldData[currentField.dataKey].toString().trim() === "")
                ) {
                    formValid = false;

                    formErrors = {
                        ...formErrors,
                        [currentField.dataKey]: {
                            isError: true,
                            errorText: `*${currentField.label} is required`,
                        },
                    };
                }
            } else if (currentField.controlType === "hideAutocomplete") {
                if (
                    fieldData[currentField.tableDataKey] === currentField.open &&
                    (fieldData[currentField.dataKey] === undefined ||
                        fieldData[currentField.dataKey] === null ||
                        fieldData[currentField.dataKey].toString().trim() === "")
                ) {
                    formValid = false;

                    formErrors = {
                        ...formErrors,
                        [currentField.dataKey]: {
                            isError: true,
                            errorText: `*${currentField.label} is required`,
                        },
                    };
                }
            } else if (currentField.controlType === "hideMultiSelect") {
                if (
                    fieldData[currentField.tableDataKey] === currentField.open &&
                    (fieldData[currentField.dataKey] === undefined ||
                        fieldData[currentField.dataKey] === null ||
                        (fieldData[currentField.dataKey] &&
                            fieldData[currentField.dataKey].length == 0))
                ) {
                    formValid = false;

                    formErrors = {
                        ...formErrors,
                        [currentField.dataKey]: {
                            isError: true,
                            errorText: `*${currentField.label} is required`,
                        },
                    };
                }
            }
        });
        this.setState({
            formErrors: formErrors,
        });
        return formValid;
    };



    checkValidationOnSubmit = () => {
        if (this.mandatoryCheck()) {
            return this.checkValidation();
        } else {
            return false;
        }
    };

    checkValidation = () => {
        const { claimTypeList } = this.props;
        let formErrors = {};
        let formIsValid = true;

        claimTypeList?.claimType.map((dataObj) => {
            if (dataObj.claimAmt === "") {
                formIsValid = false;
                formErrors["claimAmountError", (dataObj.id)] = (dataObj.name) + " claim amount required";
            }
        });

        this.setState({ formErrors: formErrors });
        return formIsValid;
    };


    onSubmitData = () => {
        const { fieldData, nameChangeFlag } = this.state;
        const { showLoador, showNotification } = this.props;


        if (nameChangeFlag) {
            showLoador({ loador: true });
            apiGet({
                url: endpoint.claimStructure + "/claim-structure-name-exist-or-not?claimStructureName=" + fieldData.name,
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({
                        msg: serverMsg,
                        severity: "error",
                    });
                } else {
                    if (data && data.data && data.data !== null && Object.keys(data.data).length !== 0) {
                        if (data.data.statusCode == 500) {
                            showNotification({ msg: data.data.message, severity: "error" });
                        } else {
                            this.onSave();
                        }
                    }
                }
            });
        } else {
            this.onSave();
        }

    }

    onSave = () => {
        const { fieldData } = this.state;
        const { showLoador, showNotification, claimTypeList } = this.props;
        let saveDataJson = {};
        let listObj = {};
        if (this.checkValidationOnSubmit()) {

            if (this.props.params.id) {
                const editList = claimTypeList?.claimType.map((dataObj) => {
                    console.log(dataObj);
                    listObj = {
                        id: dataObj.structureId ? dataObj.structureId : null,
                        claimType: { id: dataObj.claimTypeId },
                        claimAmt: parseFloat(dataObj.claimAmt),
                        isActive: 1,
                    }
                    return listObj;
                });

                saveDataJson = {
                    id: this.props.params.id,
                    name: fieldData.name,
                    isActive: 1,
                    claimStructureDetails: editList,
                }
            } else {
                const saveList = claimTypeList?.claimType.map((dataObj) => {
                    listObj = {
                        claimType: { id: dataObj.id },
                        claimAmt: parseFloat(dataObj.claimAmt),
                        isActive: 1,
                    }
                    return listObj;
                });

                saveDataJson = {
                    name: fieldData.name,
                    isActive: 1,
                    claimStructureDetails: saveList,
                }
            }


            swal({
                title: "Are you sure?",
                text: this.props.params.id ? updateData : saveWarningMsg,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    showLoador({ loador: true });
                    apiPost({
                        url: endpoint.claimStructure,
                        postBody: saveDataJson,
                    }).then(({ data, success }) => {
                        showLoador({ loador: false });
                        if (success) {
                            showNotification({ msg: this.props.params.id ? updateMsg : savemsg });
                            window.location.replace("/claim-structure-list");
                        } else {
                            showNotification({ msg: saveFailedMsg, severity: "error" });
                        }
                        return success;
                    });
                }
            });
        }
    }


    render() {
        const { fieldData, formErrors } = this.state;
        const { claimTypeList } = this.props;

        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 2,
                    }}
                    elevation={0}
                >

                    <DynamicFormWithoutValidation
                        screenTitle={ClaimStructureJson.screenTitle}
                        showTitle={ClaimStructureJson.showTitle}
                        fieldMeta={ClaimStructureJson.fieldMetaHeader}
                        apiBaseURL={ClaimStructureJson.apiBaseURL}
                        formErrors={formErrors}
                        showSaveBtn={false}
                        showSaveBtnMain={false}
                        getByIdApi={true}
                        callApi={true}
                        fieldData={fieldData}
                        onDataChange={this.onDataChange}
                        showBackToList={true}
                    />


                    <br />
                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="space-between"
                    >
                        <Grid item xs={12} md={5} sm={5}>
                            <LabelCompo
                                className="text-black"
                                style={{ fontSize: 18, color: DarkBlue }}
                                label="Claim Types (Per Day) :"
                            />
                        </Grid>
                    </Grid>
                    <br />

                    {claimTypeList?.claimType.length !== 0 &&
                        claimTypeList.claimType.map((claimTypeObj) =>
                        (
                            <Grid
                                item
                                sm={11}
                                xs={12}
                                md={11}
                                lg={11}
                                container
                                justifyContent="space-between"
                            >
                                <Grid
                                    item
                                    sm={5}
                                    xs={12}
                                    md={5}
                                    lg={5}
                                    container
                                    justifyContent="space-between"
                                    key={claimTypeObj.id}
                                >
                                    <Grid item xs={12} md={4} sm={4}>
                                        <LabelCompo
                                            className="text-black"
                                            style={{ fontSize: 16, color: redColor }}
                                            label={"*"}
                                        />
                                        <LabelCompo
                                            className="text-black"
                                            style={{ fontSize: 16, color: titleColor }}
                                            label={claimTypeObj.name + " : "}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} sm={6}>
                                        <TextFieldCompo
                                            placeHolder={"Enter Claim Amount"}
                                            color="primary"
                                            name="claimAmt"
                                            type="number"
                                            value={claimTypeObj.claimAmt}
                                            fullWidth
                                            onChange={this.changeHandlerTextField(claimTypeObj.id)}
                                            errorText={
                                                formErrors["claimAmountError", (claimTypeObj.id)]
                                                    ? formErrors["claimAmountError", (claimTypeObj.id)]
                                                    : ""
                                            }
                                        />
                                    </Grid>

                                </Grid>

                                <Grid
                                    item
                                    sm={6}
                                    xs={12}
                                    md={6}
                                    lg={6}
                                    container
                                    justifyContent="space-between"
                                >
                                    {claimTypeObj.type === 0 ? (
                                        <>
                                            <Grid item xs={12} md={3} sm={3}>
                                                <LabelCompo
                                                    className="text-black"
                                                    style={{ fontSize: 16, color: titleColor }}
                                                    label={"UOM :"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={9} sm={9}>
                                                <LabelCompo
                                                    className="text-black"
                                                    style={{ fontSize: 16, color: blackColor }}
                                                    label={claimTypeObj.uomName !== null ? claimTypeObj.uomName : ""}
                                                />
                                            </Grid>
                                        </>
                                    ) : claimTypeObj.type === 2 ? (
                                        <>
                                            <Grid item xs={12} md={3} sm={3}>
                                                <LabelCompo
                                                    className="text-black"
                                                    style={{ fontSize: 16, color: titleColor }}
                                                    label={"Limit Value :"}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={9} sm={9}>
                                                <LabelCompo
                                                    className="text-black"
                                                    style={{ fontSize: 16, color: blackColor }}
                                                    label={claimTypeObj.limitValue !== null ? claimTypeObj.limitValue : ""}
                                                />
                                            </Grid>
                                        </>
                                    ) : (
                                        <Grid item xs={12} md={2} sm={2}>
                                            <LabelCompo
                                                className="text-black"
                                                style={{ fontSize: 16, color: blackColor }}
                                                label={""}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        )
                        )
                    }

                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        justifyContent="right"
                    >
                        <ButtonCompo
                            size="medium"
                            type="Submit"
                            variant="contained"
                            name="Submit"
                            onClick={this.onSubmitData}
                        />
                    </Grid>
                </Paper >
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    claimTypeList: state.claim,
});
const mapDispatchToProps = {
    getClaimType,
    setClaimType,
    showLoador,
    showNotification,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ClaimStructureForm);

