import endpoint from "../../config/endpoints";

export const creditNoteHolderJson = {
  showSaveNextBtn: true,
  showAddButton: true,
  apiBaseURL: endpoint.area,
  screenTitle: " Credit Note List",
  formPathView: "/credit-note-view",
  searchList: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "fromdate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "todate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "todate",
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
    },
  ],
};
