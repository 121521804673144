import { Grid, Paper } from "@mui/material";
import React from "react";
import { ButtonCompo } from "../../components/Comman/Button";
import { resetReducList } from "../../CommonActions/User.action";
import { connect } from "react-redux";

class ErrorPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    submitHandler = () => {
        const {resetReducList} =this.props
        localStorage.clear();
        resetReducList();
        window.location.replace("/login");
    };
  
    render() {

        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 2,
                    }}
                    elevation={0}
                >

                    <Grid container spacing={1}>
                        <Grid
                            container
                            xs={12}
                            sm={12}
                            md={12}
                            sx={{ justifyContent: "center" }}
                        >
                            <ButtonCompo
                                size="medium"
                                type="submit"
                                variant="contained"
                                name={"Go To Login"}
                                onClick={this.submitHandler}
                            />
                        </Grid>
                    </Grid>
                </Paper>

            </>
        );
    }
}

const mapStateToProps = (state) => ({
   
  });
  
  const mapDispatchToProps = { resetReducList };
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ErrorPage);
  