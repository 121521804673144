import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { compose } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import RTTable from "../../components/Comman/RT/RTTable";
import { withRouter } from "../../components/withRouter";
import { DarkBlue, redColor, titleColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import * as myConstClass from "../../config/messageconstant";
import { deleteWarningMsg, saveWarningMsg } from "../../config/messageconstant";
import { apiPost } from "../../utils/api_service";
import { showNotification } from "../Landing/Landing.slice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

class BillingPopUpForOffer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      rowList: [],
      offerBillNewData: {},
      billOfferId: "",
      outletHeader: "",
      openFlag3: false,
      productForFlag3: [],
      productForFlag3New: [],
      productIdForFlag3: "",
      batchIdForFlag3: "",
      offerNameForFlag3: "",
      rowListForFlag3: [],
      productStockForFlag3: [],

      openType0Flag5: false,
      productForType0Flag5: [],
      productIdForType0Flag5: "",
      batchIdForType0Flag5: "",
      offerNameForType0Flag5: "",
      rowListForType0Flag5: [],
      productForType0Flag5New: [],
      productStockForType0Flag5: [],

      openType1Flag5: false,
      productForType1Flag5: [],
      productIdForType1Flag5: "",
      batchIdForType1Flag5: "",
      offerNameForType1Flag5: "",
      rowListForType1Flag5: [],
      productForType1Flag5New: [],
      productStockForType1Flag5: [],

      openFlag6: false,
      productForFlag6: [],
      productIdForFlag6: "",
      batchIdForFlag6: "",
      offerNameForFlag6: "",
      rowListForFlag6: [],
      productForFlag6New: [],
      productStockForFlag6: [],

      openType0Flag7: false,
      productForType0Flag7: [],
      productForType0Flag7New: [],
      productIdForType0Flag7: "",
      batchIdForType0Flag7: "",
      offerNameForType0Flag7: "",
      productStockForType0Flag7: [],
      rowListForType0Flag7: [],

      openType1Flag7: false,
      productForType1Flag7: [],
      productForType1Flag7New: [],
      productIdForType1Flag7: "",
      batchIdForType1Flag7: "",
      offerNameForType1Flag7: "",
      productStockForType1Flag7: [],
      rowListForType1Flag7: [],

      productStock: [],
      formErrors: {},
    };
  }
  componentDidMount() {
    const { offerBillData } = this.props;
    if (
      Object.keys(offerBillData).length !== 0 &&
      Object.keys(offerBillData.outletBillsAfterApplyingOffers).length !== 0
    ) {
      if (
        offerBillData.outletBillsAfterApplyingOffers.appliedOffers.length !== 0
      ) {
        offerBillData.outletBillsAfterApplyingOffers.appliedOffers.map(
          (offerData) => {
            if (
              offerData.levelWiseType == 3 &&
              offerData.offerDetails.length !== 0 &&
              offerData.isApplied === 1 &&
              offerData.isShow === 1
            ) {
              let newOfferData = offerData.offerDetails.map(
                (offerDetailsObject) => {
                  offerDetailsObject = {
                    ...offerDetailsObject,
                    name:
                      offerDetailsObject.product !== null
                        ? offerDetailsObject.product.name +
                        " - " +
                        offerDetailsObject.qty
                        : "",
                    productId:
                      offerDetailsObject.product !== null
                        ? offerDetailsObject.product.id
                        : "",
                    levelWiseType: offerData.levelWiseType,
                  };
                  return offerDetailsObject;
                }
              );
              this.setState({
                openFlag3: true,
                productForFlag3: newOfferData,
                productForFlag3New: newOfferData,
                offerNameForFlag3: offerData,
              });
            } else if (
              offerData.levelWiseType == 5 &&
              offerData.offerDetails.length !== 0 &&
              offerData.isApplied === 1 &&
              offerData.isShow === 1 &&
              offerData.type == 0
            ) {
              let newOfferData = offerData.offerDetails.map(
                (offerDetailsObject) => {
                  offerDetailsObject = {
                    ...offerDetailsObject,
                    name:
                      offerDetailsObject.product !== null
                        ? offerDetailsObject.product.name +
                        " - " +
                        offerDetailsObject.qty
                        : "",
                    productId:
                      offerDetailsObject.product !== null
                        ? offerDetailsObject.product.id
                        : "",
                    levelWiseType: offerData.levelWiseType,
                  };
                  return offerDetailsObject;
                }
              );
              this.setState({
                openType0Flag5: true,
                productForType0Flag5: newOfferData,
                productForType0Flag5New: newOfferData,
                offerNameForType0Flag5: offerData,
              });
            } else if (
              offerData.levelWiseType == 5 &&
              offerData.offerDetails.length !== 0 &&
              offerData.isApplied === 1 &&
              offerData.isShow === 1 &&
              offerData.type == 1
            ) {
              let newOfferData = offerData.offerDetails.map(
                (offerDetailsObject) => {
                  offerDetailsObject = {
                    ...offerDetailsObject,
                    name:
                      offerDetailsObject.product !== null
                        ? offerDetailsObject.product.name +
                        " - " +
                        offerDetailsObject.qty
                        : "",
                    productId:
                      offerDetailsObject.product !== null
                        ? offerDetailsObject.product.id
                        : "",
                    levelWiseType: offerData.levelWiseType,
                  };
                  return offerDetailsObject;
                }
              );
              this.setState({
                openType1Flag5: true,
                productForType1Flag5: newOfferData,
                productForType1Flag5New: newOfferData,
                offerNameForType1Flag5: offerData,
              });
            } else if (
              offerData.levelWiseType == 6 &&
              offerData.offerDetails.length !== 0 &&
              offerData.isApplied === 1 &&
              offerData.isShow === 1
            ) {
              let newOfferData = offerData.offerDetails.map(
                (offerDetailsObject) => {
                  offerDetailsObject = {
                    ...offerDetailsObject,
                    name:
                      offerDetailsObject.product !== null
                        ? offerDetailsObject.product.name +
                        " - " +
                        offerDetailsObject.qty
                        : "",
                    productId:
                      offerDetailsObject.product !== null
                        ? offerDetailsObject.product.id
                        : "",
                    levelWiseType: offerData.levelWiseType,
                  };
                  return offerDetailsObject;
                }
              );
              this.setState({
                openFlag6: true,
                productForFlag6: newOfferData,
                productForFlag6New: newOfferData,
                offerNameForFlag6: offerData,
              });
            } else if (
              offerData.levelWiseType == 7 &&
              offerData.offerDetails.length !== 0 &&
              offerData.isApplied === 1 &&
              offerData.isShow === 1
            ) {
              let newOfferData = offerData.offerDetails.map(
                (offerDetailsObject) => {
                  offerDetailsObject = {
                    ...offerDetailsObject,
                    name:
                      offerDetailsObject.product !== null
                        ? offerDetailsObject.product.name +
                        " - " +
                        offerDetailsObject.qty
                        : "",
                    productId:
                      offerDetailsObject.product !== null
                        ? offerDetailsObject.product.id
                        : "",
                    levelWiseType: offerData.levelWiseType,
                  };
                  return offerDetailsObject;
                }
              );
              this.setState({
                openType0Flag7: true,
                productForType0Flag7: newOfferData,
                productForType0Flag7New: newOfferData,
                offerNameForType0Flag7: offerData,
              });
            } else if (
              offerData.levelWiseType == 7 &&
              offerData.offerDetails.length !== 0 &&
              offerData.isApplied === 1 &&
              offerData.isShow === 1
            ) {
              let newOfferData = offerData.offerDetails.map(
                (offerDetailsObject) => {
                  offerDetailsObject = {
                    ...offerDetailsObject,
                    name:
                      offerDetailsObject.product !== null
                        ? offerDetailsObject.product.name +
                        " - " +
                        offerDetailsObject.qty
                        : "",
                    productId:
                      offerDetailsObject.product !== null
                        ? offerDetailsObject.product.id
                        : "",
                    levelWiseType: offerData.levelWiseType,
                  };
                  return offerDetailsObject;
                }
              );
              this.setState({
                openType1Flag7: true,
                productForType1Flag7: newOfferData,
                productForType1Flag7New: newOfferData,
                offerNameForType1Flag7: offerData,
              });
            }
          }
        );
        this.setState({
          offerBillNewData: offerBillData,
          productStock: offerBillData.productAndStocks,
        });
      }
    }
  }
  clickSubmitOfferHandler = () => {
    const { offerBillNewData } = this.state;
    const { showNotification } = this.props;
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        apiPost({
          url:
            endpoint.outletBillHeader + "/batch-wise-save-after-appying-offers",
          postBody: offerBillNewData.outletBillsAfterApplyingOffers,
        }).then(({ data, success }) => {
          if (success) {
            window.location.replace("/batchwise-outlet-billing-list");
          } else {
            showNotification({
              msg: myConstClass.saveFailedMsg,
              severity: "error",
            });
          }
        });
      }
    });
  };

  checkValidationSubmit = () => {
    const {
      productForFlag3,
      productForType0Flag5,
      productForType1Flag5,
      productForFlag6,
      productForType0Flag7,
      productForType1Flag7,
    } = this.state;
    let formIsValid = true;
    let formErrors = {};

    if (productForFlag3.length !== 0) {
      formIsValid = false;
      formErrors["productForFlag3Error"] = myConstClass.productRemainingMsg;
    }

    if (productForType0Flag5.length !== 0) {
      formIsValid = false;
      formErrors["productForFlag5Error"] = myConstClass.productRemainingMsg;
    }

    if (productForType1Flag5.length !== 0) {
      formIsValid = false;
      formErrors["productForFlag5Error"] = myConstClass.productRemainingMsg;
    }

    if (productForFlag6.length !== 0) {
      formIsValid = false;
      formErrors["productForFlag6Error"] = myConstClass.productRemainingMsg;
    }

    if (productForType0Flag7.length !== 0) {
      formIsValid = false;
      formErrors["productForFlag7Error"] = myConstClass.productRemainingMsg;
    }

    if (productForType1Flag7.length !== 0) {
      formIsValid = false;
      formErrors["productForFlag7Error"] = myConstClass.productRemainingMsg;
    }


    this.setState({ formErrors: formErrors });
    return formIsValid;
  };

  clickSubmitHandler = () => {
    if (this.checkValidationSubmit()) {
      const {
        rowListForFlag3,
        rowListForType0Flag5,
        rowListForType1Flag5,
        rowListForFlag6,
        rowListForType0Flag7,
        rowListForType1Flag7,
      } = this.state;
      const { onDataSave } = this.props;
      let newList = [
        ...rowListForFlag3,
        ...rowListForType0Flag5,
        ...rowListForType1Flag5,
        ...rowListForFlag6,
        ...rowListForType0Flag7,
        ...rowListForType1Flag7,
      ];
      if (newList.length !== 0) {
        let newLisrForSave = newList.map((productObject) => {
          let rowData = {
            batchCode: productObject.batch,
            billDetailId: "0",
            isChecked: 1,
            cessAmt: 0,
            cessPer: 0,
            cgstAmt: 0,
            cgstPer: 0,
            conversionValue: 0,
            discountOnBillAmt: 0,
            freeQty: productObject.qty,
            hsnCode: "0",
            igstAmt: 0,
            igstPer: 0,
            insertDateTime: null,
            insertedUserId: "",
            itemDiscAmt: 0,
            itemDiscPer: 0,
            mrp: 0,
            primaryFreeQty: 0,
            primaryQty: 0,
            taxableAmtWithoutOffer: 0,
            totalAmtWithoutOffer: 0,
            product: {
              id: productObject.productId,
              name: productObject.product.product.name,
            },
            qty: 0,
            rate: 0,
            secondaryMrp: 0,
            sgstAmt: 0,
            sgstPer: 0,
            taxableAmt: 0,
            totalAmt: 0,
            uom: {
              id: productObject.product.product.outletUom.id,
            },
            levelWiseType: productObject.levelWiseType,
            type: productObject.type,
            level: productObject.level,
          };
          return rowData;
        });
        onDataSave(newLisrForSave);
      }
    }
  };

  checkValidationAdd = (value, type) => {
    const {
      productIdForFlag3,
      batchIdForFlag3,
      productIdForType0Flag5,
      productIdForType1Flag5,
      batchIdForType0Flag5,
      batchIdForType1Flag5,
      productIdForFlag6,
      batchIdForFlag6,
      productIdForType0Flag7,
      productIdForType1Flag7,
      batchIdForType0Flag7,
      batchIdForType1Flag7
    } = this.state;
    let formIsValid = true;
    let formErrors = {};

    if (value == 3) {
      if (productIdForFlag3 == "") {
        formIsValid = false;
        formErrors["productIdForFlag3Error"] = myConstClass.productMsg;
      }

      if (batchIdForFlag3.length == 0) {
        formIsValid = false;
        formErrors["batchIdForFlag3Error"] = myConstClass.batchMsg;
      }
    } else if (value == 5) {
      if (type == 0) {
        if (productIdForType0Flag5 == "") {
          formIsValid = false;
          formErrors["productIdForFlag5Error"] = myConstClass.productMsg;
        }
        if (batchIdForType0Flag5.length == 0) {
          formIsValid = false;
          formErrors["batchIdForFlag5Error"] = myConstClass.batchMsg;
        }
      } else if (type == 1) {
        if (productIdForType1Flag5 == "") {
          formIsValid = false;
          formErrors["productIdForFlag5Error"] = myConstClass.productMsg;
        }

        if (batchIdForType1Flag5.length == 0) {
          formIsValid = false;
          formErrors["batchIdForFlag5Error"] = myConstClass.batchMsg;
        }
      }
    } else if (value == 6) {
      if (productIdForFlag6 == "") {
        formIsValid = false;
        formErrors["productIdForFlag6Error"] = myConstClass.productMsg;
      }

      if (batchIdForFlag6.length == 0) {
        formIsValid = false;
        formErrors["batchIdForFlag6Error"] = myConstClass.batchMsg;
      }
    } else if (value == 7) {
      if (productIdForType0Flag7 == "") {
        formIsValid = false;
        formErrors["productIdForFlag7Error"] = myConstClass.productMsg;
      }

      if (batchIdForType0Flag7.length == 0) {
        formIsValid = false;
        formErrors["batchIdForFlag7Error"] = myConstClass.batchMsg;
      } else if (type == 1) {
        if (productIdForType1Flag7 == "") {
          formIsValid = false;
          formErrors["productIdForFlag7Error"] = myConstClass.productMsg;
        }

        if (batchIdForType1Flag7.length == 0) {
          formIsValid = false;
          formErrors["batchIdForFlag7Error"] = myConstClass.batchMsg;

        }

      }
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  };

  clickHandler = () => {
    const { cancelHandler } = this.props;
    cancelHandler();
  };

  onFormSaveHandler = (e, value, level, type) => {
    e.stopPropagation();
    if (this.checkValidationAdd(value, type)) {
      const {
        rowListForFlag3,
        rowListForType0Flag5,
        rowListForType1Flag5,
        rowListForFlag6,
        rowListForType0Flag7,
        rowListForType1Flag7,
        productStock,
        productForFlag3,
        productForType0Flag5,
        productForType1Flag5,
        productForFlag6,
        productForType0Flag7,
        productForType1Flag7,
        productIdForFlag3,
        productIdForType0Flag5,
        productIdForType1Flag5,
        productIdForFlag6,
        productIdForType0Flag7,
        productIdForType1Flag7,
        productStockForFlag3,
        productStockForType0Flag5,
        productStockForType1Flag5,
        productStockForFlag6,
        productStockForType0Flag7,
        productStockForType1Flag7,
        batchIdForFlag3,
        batchIdForType0Flag5,
        batchIdForType1Flag5,
        batchIdForFlag6,
        batchIdForType0Flag7,
        batchIdForType1Flag7,
      } = this.state;
      const { offerBillData } = this.props;
      let prodName = [];
      let batchName = [];
      if (value == 3) {
        let prodList = [...rowListForFlag3];
        prodName = productForFlag3.filter((row) => row.id == productIdForFlag3);
        batchName = productStockForFlag3.filter(
          (row) => row.id == batchIdForFlag3
        );
        if (prodName.length !== 0 && batchName.length !== 0) {
          let rowData = {
            index: rowListForFlag3.length + 1,
            productName: prodName[0].name,
            batch: batchName[0].batchCode,
            productId: prodName[0].productId,
            product: prodName[0],
            flag: value,
            qty: prodName[0].qty,
            levelWiseType: prodName[0].levelWiseType,
            type: type,
            level: level,
          };
          prodList.push(rowData);
          let productNewList = productForFlag3.filter(
            (row) => row.id !== productIdForFlag3
          );
          this.setState({
            rowListForFlag3: prodList,
            productIdForFlag3: "",
            batchIdForFlag3: "",
            productForFlag3: productNewList,
          });
        }
      } else if (value == 5) {
        if (type == 0) {
          let prodList = [...rowListForType0Flag5];
          prodName = productForType0Flag5.filter(
            (row) => row.id == productIdForType0Flag5
          );
          batchName = productStockForType0Flag5.filter(
            (row) => row.id == batchIdForType0Flag5
          );
          if (prodName.length !== 0 && batchName.length !== 0) {
            let rowData = {
              index: rowListForType0Flag5.length + 1,
              productName: prodName[0].name,
              batch: batchName[0].batchCode,
              productId: prodName[0].productId,
              product: prodName[0],
              flag: value,
              qty: prodName[0].qty,
              levelWiseType: prodName[0].levelWiseType,
              type: type,
              level: level,
            };
            prodList.push(rowData);
            let productNewList = productForType0Flag5.filter(
              (row) => row.id !== productIdForType0Flag5
            );
            this.setState({
              rowListForType0Flag5: prodList,
              productIdForType0Flag5: "",
              batchIdForType0Flag5: "",
              productForType0Flag5: productNewList,
            });
          }
        } else if (type == 1) {
          let prodList = [...rowListForType1Flag5];
          prodName = productForType1Flag5.filter(
            (row) => row.id == productIdForType1Flag5
          );
          batchName = productStockForType1Flag5.filter(
            (row) => row.id == batchIdForType1Flag5
          );
          if (prodName.length !== 0 && batchName.length !== 0) {
            let rowData = {
              index: rowListForType1Flag5.length + 1,
              productName: prodName[0].name,
              batch: batchName[0].batchCode,
              productId: prodName[0].productId,
              product: prodName[0],
              flag: value,
              qty: prodName[0].qty,
              levelWiseType: prodName[0].levelWiseType,
              type: type,
              level: level,
            };
            prodList.push(rowData);
            let productNewList = productForType1Flag5.filter(
              (row) => row.id !== productIdForType1Flag5
            );
            this.setState({
              rowListForType1Flag5: prodList,
              productIdForType1Flag5: "",
              batchIdForType1Flag5: "",
              productForType1Flag5: productNewList,
            });
          }
        }
      } else if (value == 6) {
        let prodList = [...rowListForFlag6];
        prodName = productForFlag6.filter((row) => row.id == productIdForFlag6);
        batchName = productStockForFlag6.filter(
          (row) => row.id == batchIdForFlag6
        );
        if (prodName.length !== 0 && batchName.length !== 0) {
          let rowData = {
            index: rowListForFlag6.length + 1,
            productName: prodName[0].name,
            batch: batchName[0].batchCode,
            productId: prodName[0].productId,
            product: prodName[0],
            flag: value,
            qty: prodName[0].qty,
            levelWiseType: prodName[0].levelWiseType,
            type: type,
            level: level,
          };
          prodList.push(rowData);
          let productNewList = productForFlag6.filter(
            (row) => row.id !== productIdForFlag6
          );
          this.setState({
            rowListForFlag6: prodList,
            productIdForFlag6: "",
            batchIdForFlag6: "",
            productForFlag6: productNewList,
          });
        }
      } else if (value == 7) {
        let prodList = [...rowListForType0Flag7];
        prodName = productForType0Flag7.filter((row) => row.id == productIdForType0Flag7);
        batchName = productStockForType0Flag7.filter(
          (row) => row.id == batchIdForType0Flag7
        );
        if (prodName.length !== 0 && batchName.length !== 0) {
          let rowData = {
            index: rowListForType0Flag7.length + 1,
            productName: prodName[0].name,
            batch: batchName[0].batchCode,
            productId: prodName[0].productId,
            product: prodName[0],
            flag: value,
            qty: prodName[0].qty,
            levelWiseType: prodName[0].levelWiseType,
            type: type,
            level: level,
          };
          prodList.push(rowData);
          let productNewList = productForType0Flag7.filter(
            (row) => row.id !== productIdForType0Flag7
          );
          this.setState({
            rowListForType0Flag7: prodList,
            productIdForType0Flag7: "",
            batchIdForType0Flag7: "",
            productForType0Flag7: productNewList,
          });
        }
      } else if (type == 1) {
        let prodList = [...rowListForType1Flag7];
        prodName = productForType1Flag7.filter((row) => row.id == productIdForType1Flag7);
        batchName = productStockForType1Flag7.filter(
          (row) => row.id == batchIdForType1Flag7
        );
        if (prodName.length !== 0 && batchName.length !== 0) {
          let rowData = {
            index: rowListForType1Flag7.length + 1,
            productName: prodName[0].name,
            batch: batchName[0].batchCode,
            productId: prodName[0].productId,
            product: prodName[0],
            flag: value,
            qty: prodName[0].qty,
            levelWiseType: prodName[0].levelWiseType,
          };
          prodList.push(rowData);
          let productNewList = productForType1Flag7.filter(
            (row) => row.id !== productIdForType1Flag7
          );
          this.setState({
            rowListForType1Flag7: prodList,
            productIdForType1Flag7: "",
            batchIdForType1Flag7: "",
            productForType1Flag7: productNewList,
          });
        }
      }

      if (offerBillData.productAndStocks.length !== 0) {
        for (let i = 0; i < offerBillData.productAndStocks.length; i++) {
          if (
            offerBillData.productAndStocks[i].productId ==
            prodName[0].productId &&
            offerBillData.productAndStocks[i].batchAndStocks.length !== 0
          ) {
            for (
              let j = 0;
              j < offerBillData.productAndStocks[i].batchAndStocks.length;
              j++
            ) {
              if (
                offerBillData.productAndStocks[i].batchAndStocks[j].batchCode ==
                batchName[0].batchCode
              ) {
                offerBillData.productAndStocks[i].batchAndStocks[j].stock =
                  offerBillData.productAndStocks[i].batchAndStocks[j].stock -
                  prodName[0].qty;
              }
              break;
            }
          }
        }
        this.setState({
          productStock: offerBillData.productAndStocks,
        });
      }
    }
  };

  rowDelete = (rowData) => {
    const { offerBillData } = this.props;
    const {
      rowListForFlag3,
      rowListForType0Flag5,
      rowListForType1Flag5,
      rowListForFlag6,
      rowListForType0Flag7,
      rowListForType1Flag7,
      productForFlag3New,
      productForType0Flag5New,
      productForType1Flag5New,
      productForFlag6New,
      productForType0Flag7New,
      productForType1Flag7New,
      productForFlag3,
      productForType0Flag5,
      productForType1Flag5,
      productForFlag6,
      productForType0Flag7,
      productForType1Flag7,
  } = this.state;
swal({
  title: "Are you sure?",
  text: deleteWarningMsg,
  icon: "warning",
  buttons: true,
  dangerMode: true,
}).then((willDelete) => {
  if (willDelete) {
    let prodName = [];
    if (rowData.flag == 3) {
      prodName = productForFlag3New.filter(
        (row) => row.productId == rowData.productId
      );
      if (prodName.length !== 0) {
        productForFlag3.push(prodName[0]);
        const newList = rowListForFlag3.filter(
          (row) => row.index !== rowData.index
        );
        const rowList = newList.map((rowListDataObj, index) => {
          return { ...rowListDataObj, index: index + 1 };
        });
        this.setState({
          rowListForFlag3: rowList,
        });
      }
    } else if (rowData.flag == 5) {
      if (rowData.type == 0) {
        prodName = productForType0Flag5New.filter(
          (row) => row.productId == rowData.productId
        );
        if (prodName.length !== 0) {
          productForType0Flag5.push(prodName[0]);
          const newList = rowListForType0Flag5.filter(
            (row) => row.index !== rowData.index
          );
          const rowList = newList.map((rowListDataObj, index) => {
            return { ...rowListDataObj, index: index + 1 };
          });
          this.setState({
            rowListForType0Flag5: rowList,
          });
        }
      } else if (rowData.type == 1) {
        prodName = productForType1Flag5New.filter(
          (row) => row.productId == rowData.productId
        );
        if (prodName.length !== 0) {
          productForType1Flag5.push(prodName[0]);
          const newList = rowListForType1Flag5.filter(
            (row) => row.index !== rowData.index
          );
          const rowList = newList.map((rowListDataObj, index) => {
            return { ...rowListDataObj, index: index + 1 };
          });
          this.setState({
            rowListForType1Flag5: rowList,
          });
        }
      }
    } else if (rowData.flag == 6) {
      prodName = productForFlag6New.filter(
        (row) => row.productId == rowData.productId
      );
      if (prodName.length !== 0) {
        productForFlag6.push(prodName[0]);
        const newList = rowListForFlag6.filter(
          (row) => row.index !== rowData.index
        );
        const rowList = newList.map((rowListDataObj, index) => {
          return { ...rowListDataObj, index: index + 1 };
        });
        this.setState({
          rowListForFlag6: rowList,
        });
      }
    } else if (rowData.flag == 7) {
      prodName = productForType0Flag7New.filter(
        (row) => row.productId == rowData.productId
      );
      if (prodName.length !== 0) {
        productForType0Flag7.push(prodName[0]);
        const newList = rowListForType0Flag7.filter(
          (row) => row.index !== rowData.index
        );
        const rowList = newList.map((rowListDataObj, index) => {
          return { ...rowListDataObj, index: index + 1 };
        });
        this.setState({
          rowListForType0Flag7: rowList,
        });
      } else if (rowData.type == 1) {
        prodName = productForType1Flag7New.filter(
          (row) => row.productId == rowData.productId
        );
        if (prodName.length !== 0) {
          productForType1Flag7.push(prodName[0]);
          const newList = rowListForType1Flag7.filter(
            (row) => row.index !== rowData.index
          );
          const rowList = newList.map((rowListDataObj, index) => {
            return { ...rowListDataObj, index: index + 1 };
          });
          this.setState({
            rowListForType1Flag7: rowList,
          });
        }
      }
    }

    if (offerBillData.productAndStocks.length !== 0) {
      for (let i = 0; i < offerBillData.productAndStocks.length; i++) {
        if (
          offerBillData.productAndStocks[i].productId ==
          prodName[0].productId &&
          offerBillData.productAndStocks[i].batchAndStocks.length !== 0
        ) {
          for (
            let j = 0;
            j < offerBillData.productAndStocks[i].batchAndStocks.length;
            j++
          ) {
            if (
              offerBillData.productAndStocks[i].batchAndStocks[j]
                .batchCode == rowData.batch
            ) {
              offerBillData.productAndStocks[i].batchAndStocks[j].stock =
                offerBillData.productAndStocks[i].batchAndStocks[j].stock +
                prodName[0].qty;
            }
            break;
          }
        }
      }
    }
  }
});
  };

getDataForDisabled = (value) => { };

changeAutocompleteHandler = (name, newValue) => {
  const {
    productStock,
    productForFlag3,
    productForType0Flag5,
    productForType1Flag5,
    productForFlag6,
    productForType0Flag7,
    productForType1Flag7,
  } = this.state;
  if (newValue !== null) {
    if (name == "productIdForFlag3") {
      let productObject = productForFlag3.filter((row) => row.id == newValue);
      let newStockList = [];
      if (productStock.length !== 0 && productObject.length !== 0) {
        productStock.map((stockObject) => {
          if (stockObject.productId == productObject[0].productId) {
            stockObject.batchAndStocks.map((stockBatchObject, index) => {
              stockBatchObject = {
                ...stockBatchObject,
                name:
                  stockBatchObject.batchCode + " - " + stockBatchObject.stock,
                id: index,
              };
              newStockList.push(stockBatchObject);
            });
          }
        });
        this.setState({ productStockForFlag3: newStockList });
      }
    } else if (name == "productIdForType0Flag5") {
      let productObject = productForType0Flag5.filter(
        (row) => row.id == newValue
      );
      let newStockList = [];
      if (productStock.length !== 0 && productObject.length !== 0) {
        productStock.map((stockObject) => {
          if (stockObject.productId == productObject[0].productId) {
            stockObject.batchAndStocks.map((stockBatchObject, index) => {
              stockBatchObject = {
                ...stockBatchObject,
                name:
                  stockBatchObject.batchCode + " - " + stockBatchObject.stock,
                id: index,
              };
              newStockList.push(stockBatchObject);
            });
          }
        });
        this.setState({ productStockForType0Flag5: newStockList });
      }
    } else if (name == "productIdForType1Flag5") {
      let productObject = productForType1Flag5.filter(
        (row) => row.id == newValue
      );
      let newStockList = [];
      if (productStock.length !== 0 && productObject.length !== 0) {
        productStock.map((stockObject) => {
          if (stockObject.productId == productObject[0].productId) {
            stockObject.batchAndStocks.map((stockBatchObject, index) => {
              stockBatchObject = {
                ...stockBatchObject,
                name:
                  stockBatchObject.batchCode + " - " + stockBatchObject.stock,
                id: index,
              };
              newStockList.push(stockBatchObject);
            });
          }
        });
        this.setState({ productStockForType1Flag5: newStockList });
      }
    } else if (name == "productIdForFlag6") {
      let productObject = productForFlag6.filter((row) => row.id == newValue);
      let newStockList = [];
      if (productStock.length !== 0 && productObject.length !== 0) {
        productStock.map((stockObject) => {
          if (stockObject.productId == productObject[0].productId) {
            stockObject.batchAndStocks.map((stockBatchObject, index) => {
              stockBatchObject = {
                ...stockBatchObject,
                name:
                  stockBatchObject.batchCode + " - " + stockBatchObject.stock,
                id: index,
              };
              newStockList.push(stockBatchObject);
            });
          }
        });
        this.setState({ productStockForFlag6: newStockList });
      }
    } else if (name == "productIdForType0Flag7") {
      let productObject = productForType0Flag7.filter((row) => row.id == newValue);
      let newStockList = [];
      if (productStock.length !== 0 && productObject.length !== 0) {
        productStock.map((stockObject) => {
          if (stockObject.productId == productObject[0].productId) {
            stockObject.batchAndStocks.map((stockBatchObject, index) => {
              stockBatchObject = {
                ...stockBatchObject,
                name:
                  stockBatchObject.batchCode + " - " + stockBatchObject.stock,
                id: index,
              };
              newStockList.push(stockBatchObject);
            });
          }
        });
        this.setState({ productStockForType0Flag7: newStockList });
      }
    } else if (name == "productIdForType1Flag7") {
      let productObject = productForType1Flag7.filter((row) => row.id == newValue);
      let newStockList = [];
      if (productStock.length !== 0 && productObject.length !== 0) {
        productStock.map((stockObject) => {
          if (stockObject.productId == productObject[0].productId) {
            stockObject.batchAndStocks.map((stockBatchObject, index) => {
              stockBatchObject = {
                ...stockBatchObject,
                name:
                  stockBatchObject.batchCode + " - " + stockBatchObject.stock,
                id: index,
              };
              newStockList.push(stockBatchObject);
            });
          }
        });
        this.setState({ productStockForType1Flag7: newStockList });
      }
    }
    this.setState({ [name]: newValue });
  } else {
    this.setState({ [name]: "" });
  }
};

render() {
  const {
    openDialog,
    offerBillNewData,
    openFlag3,
    productForFlag3,
    openType0Flag5,
    openType1Flag5,
    productForType0Flag5,
    productForType1Flag5,
    openFlag6,
    productForFlag6,
    openType0Flag7,
    openType1Flag7,
    productForType0Flag7,
    productForType1Flag7,
    productStock,
    productIdForFlag3,
    productIdForType0Flag5,
    productIdForType1Flag5,
    productIdForFlag6,
    productIdForType0Flag7,
    productIdForType1Flag7,
    batchIdForFlag3,
    batchIdForType0Flag5,
    batchIdForType1Flag5,
    batchIdForFlag6,
    batchIdForType0Flag7,
    batchIdForType1Flag7,
    rowListForFlag3,
    rowListForType0Flag5,
    rowListForType1Flag5,
    rowListForFlag6,
    rowListForType0Flag7,
    rowListForType1Flag7,
    formErrors,
    offerNameForFlag3,
    offerNameForType0Flag5,
    offerNameForType1Flag5,
    offerNameForFlag6,
    offerNameForType0Flag7,
    offerNameForType1Flag7,
    productStockForFlag3,
    productStockForType0Flag5,
    productStockForType1Flag5,
    productStockForFlag6,
    productStockForType0Flag7,
    productStockForType1Flag7,
  } = this.state;
  const { offerBillOutletData, onCancel } = this.props;

  return (
    <>
      <Paper
        sx={{
          p: 2,
          borderRadius: 2,
        }}
        elevation={0}
      >
        <LandingScreenHeader
          screenTitle={"Billing Preview"}
          showTable={false}
          showBackButton={true}
          showAddButton={false}
          onCancel={onCancel}
        />

        <br />
        <BootstrapDialog
          aria-labelledby="customized-dialog-title"
          open={true}
          fullWidth
          maxWidth="lg"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={this.clickHandler}
          >
            Product With Batches
            {openFlag3 && (
              <>
                <br />
                <Grid item sm={12} xs={12} md={12} lg={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: "20px", color: DarkBlue }}
                    label={offerNameForFlag3.name}
                  />
                  <br />
                  <Grid container spacing={1}>
                    <Grid
                      container
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      sx={{
                        marginTop: 1,
                      }}
                    >
                      <Grid item xs={12} sm={4} md={4}>
                        &nbsp;&nbsp;
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: redColor }}
                          label={"*"}
                        />
                        <LabelCompo
                          className="text-black"
                          style={{
                            fontSize: 16,
                            color: titleColor,
                          }}
                          label={"Product" + " :"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <AutoComplete
                          label={"Product"}
                          placeHolder={"Select product"}
                          keyColName={"id"}
                          value={productIdForFlag3}
                          name={"productIdForFlag3"}
                          options={productForFlag3}
                          disabled={false}
                          onChange={this.changeAutocompleteHandler}
                          isError={
                            formErrors.productIdForFlag3Error
                              ? true
                              : formErrors.productForFlag3Error
                                ? true
                                : false
                          }
                          errorText={
                            formErrors.productIdForFlag3Error
                              ? formErrors.productIdForFlag3Error
                              : formErrors.productForFlag3Error
                                ? formErrors.productForFlag3Error
                                : ""
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      sx={{
                        marginTop: 1,
                      }}
                    >
                      <Grid item xs={12} sm={4} md={4}>
                        &nbsp;&nbsp;
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: redColor }}
                          label={"*"}
                        />
                        <LabelCompo
                          className="text-black"
                          style={{
                            fontSize: 16,
                            color: titleColor,
                          }}
                          label={"Batch" + " :"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <AutoComplete
                          label={"Batch"}
                          placeHolder={"Select Batch"}
                          keyColName={"id"}
                          name={"batchIdForFlag3"}
                          value={batchIdForFlag3}
                          options={productStockForFlag3}
                          disabled={false}
                          onChange={this.changeAutocompleteHandler}
                          isError={
                            formErrors.batchIdForFlag3Error ? true : false
                          }
                          errorText={
                            formErrors.batchIdForFlag3Error
                              ? formErrors.batchIdForFlag3Error
                              : " "
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container sm={1} md={1} lg={1} xs={12}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{ paddingLeft: 2 }}
                      >
                        <ButtonCompo
                          size="medium"
                          type="submit"
                          variant="contained"
                          name={"ADD"}
                          onClick={(e) =>
                            this.onFormSaveHandler(
                              e,
                              3,
                              offerNameForFlag3.level,
                              offerNameForFlag3.type
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      container
                      row
                      sx={{ paddingTop: 2 }}
                    >
                      <RTTable
                        showPegination={false}
                        columns={[
                          {
                            name: "index",
                            title: "Sr. No.",
                            align: "center",
                          },
                          { name: "productName", title: "Product" },
                          { name: "batch", title: "Batch" },
                          { name: "qty", title: "Quantity" },
                          { name: "action", title: "Action" },
                        ]}
                        tableData={rowListForFlag3}
                        isActionColActive={true}
                        showEditIcon={false}
                        showDeleteIcon={true}
                        rowDelete={this.rowDelete}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <br />
              </>
            )}
            {openType0Flag5 && (
              <>
                <br />
                <Grid item sm={12} xs={12} md={12} lg={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: "20px", color: DarkBlue }}
                    label={offerNameForType0Flag5.name}
                  />
                  <br />
                  <Grid container spacing={1}>
                    <Grid
                      container
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      sx={{
                        marginTop: 1,
                      }}
                    >
                      <Grid item xs={12} sm={4} md={4}>
                        &nbsp;&nbsp;
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: redColor }}
                          label={"*"}
                        />
                        <LabelCompo
                          className="text-black"
                          style={{
                            fontSize: 16,
                            color: titleColor,
                          }}
                          label={"Product" + " :"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <AutoComplete
                          label={"Product"}
                          placeHolder={"Select product"}
                          keyColName={"id"}
                          name={"productIdForType0Flag5"}
                          value={productIdForType0Flag5}
                          options={productForType0Flag5}
                          disabled={false}
                          onChange={this.changeAutocompleteHandler}
                          isError={
                            formErrors.productIdForFlag5Error
                              ? true
                              : formErrors.productForFlag5Error
                                ? true
                                : false
                          }
                          errorText={
                            formErrors.productIdForFlag5Error
                              ? formErrors.productIdForFlag5Error
                              : formErrors.productForFlag5Error
                                ? formErrors.productForFlag5Error
                                : " "
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      sx={{
                        marginTop: 1,
                      }}
                    >
                      <Grid item xs={12} sm={4} md={4}>
                        &nbsp;&nbsp;
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: redColor }}
                          label={"*"}
                        />
                        <LabelCompo
                          className="text-black"
                          style={{
                            fontSize: 16,
                            color: titleColor,
                          }}
                          label={"Batch" + " :"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <AutoComplete
                          label={"Batch"}
                          placeHolder={"Select Batch"}
                          keyColName={"id"}
                          name={"batchIdForType0Flag5"}
                          value={batchIdForType0Flag5}
                          options={productStockForType0Flag5}
                          disabled={false}
                          onChange={this.changeAutocompleteHandler}
                          isError={
                            formErrors.batchIdForFlag5Error ? true : false
                          }
                          errorText={
                            formErrors.batchIdForFlag5Error
                              ? formErrors.batchIdForFlag5Error
                              : " "
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container sm={1} md={1} lg={1} xs={12}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{ paddingLeft: 2 }}
                      >
                        <ButtonCompo
                          size="medium"
                          type="submit"
                          variant="contained"
                          name={"ADD"}
                          // fullWidth={true}
                          onClick={(e) =>
                            this.onFormSaveHandler(
                              e,
                              5,
                              offerNameForType0Flag5.level,
                              offerNameForType0Flag5.type
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      container
                      row
                      sx={{ paddingTop: 2 }}
                    >
                      <RTTable
                        showPegination={false}
                        columns={[
                          {
                            name: "index",
                            title: "Sr. No.",
                            align: "center",
                          },
                          { name: "productName", title: "Product" },
                          { name: "batch", title: "Batch" },
                          { name: "qty", title: "Quantity" },
                          { name: "action", title: "Action" },
                        ]}
                        tableData={rowListForType0Flag5}
                        isActionColActive={true}
                        showEditIcon={false}
                        showDeleteIcon={true}
                        rowDelete={this.rowDelete}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <br />
              </>
            )}
            {openType1Flag5 && (
              <>
                <br />
                <Grid item sm={12} xs={12} md={12} lg={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: "20px", color: DarkBlue }}
                    label={offerNameForType1Flag5.name}
                  />
                  <br />
                  <Grid container spacing={1}>
                    <Grid
                      container
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      sx={{
                        marginTop: 1,
                      }}
                    >
                      <Grid item xs={12} sm={4} md={4}>
                        &nbsp;&nbsp;
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: redColor }}
                          label={"*"}
                        />
                        <LabelCompo
                          className="text-black"
                          style={{
                            fontSize: 16,
                            color: titleColor,
                          }}
                          label={"Product" + " :"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <AutoComplete
                          label={"Product"}
                          placeHolder={"Select product"}
                          keyColName={"id"}
                          name={"productIdForType1Flag5"}
                          value={productIdForType1Flag5}
                          options={productForType1Flag5}
                          disabled={false}
                          onChange={this.changeAutocompleteHandler}
                          isError={
                            formErrors.productIdForFlag5Error
                              ? true
                              : formErrors.productForFlag5Error
                                ? true
                                : false
                          }
                          errorText={
                            formErrors.productIdForFlag5Error
                              ? formErrors.productIdForFlag5Error
                              : formErrors.productForFlag5Error
                                ? formErrors.productForFlag5Error
                                : " "
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      sx={{
                        marginTop: 1,
                      }}
                    >
                      <Grid item xs={12} sm={4} md={4}>
                        &nbsp;&nbsp;
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: redColor }}
                          label={"*"}
                        />
                        <LabelCompo
                          className="text-black"
                          style={{
                            fontSize: 16,
                            color: titleColor,
                          }}
                          label={"Batch" + " :"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <AutoComplete
                          label={"Batch"}
                          placeHolder={"Select Batch"}
                          keyColName={"id"}
                          name={"batchIdForType1Flag5"}
                          value={batchIdForType1Flag5}
                          options={productStockForType1Flag5}
                          disabled={false}
                          onChange={this.changeAutocompleteHandler}
                          isError={
                            formErrors.batchIdForFlag5Error ? true : false
                          }
                          errorText={
                            formErrors.batchIdForFlag5Error
                              ? formErrors.batchIdForFlag5Error
                              : " "
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container sm={1} md={1} lg={1} xs={12}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{ paddingLeft: 2 }}
                      >
                        <ButtonCompo
                          size="medium"
                          type="submit"
                          variant="contained"
                          name={"ADD"}
                          // fullWidth={true}
                          onClick={(e) =>
                            this.onFormSaveHandler(
                              e,
                              5,
                              offerNameForType1Flag5.level,
                              offerNameForType1Flag5.type
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      container
                      row
                      sx={{ paddingTop: 2 }}
                    >
                      <RTTable
                        showPegination={false}
                        columns={[
                          {
                            name: "index",
                            title: "Sr. No.",
                            align: "center",
                          },
                          { name: "productName", title: "Product" },
                          { name: "batch", title: "Batch" },
                          { name: "qty", title: "Quantity" },
                          { name: "action", title: "Action" },
                        ]}
                        tableData={rowListForType1Flag5}
                        isActionColActive={true}
                        showEditIcon={false}
                        showDeleteIcon={true}
                        rowDelete={this.rowDelete}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <br />
              </>
            )}
            {openFlag6 && (
              <>
                <br />
                <Grid item sm={12} xs={12} md={12} lg={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: "20px", color: DarkBlue }}
                    label={offerNameForFlag6.name}
                  />
                  <br />
                  <Grid container spacing={1}>
                    <Grid
                      container
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      sx={{
                        marginTop: 1,
                      }}
                    //  rowSpacing={16}
                    >
                      <Grid item xs={12} sm={4} md={4}>
                        &nbsp;&nbsp;
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: redColor }}
                          label={"*"}
                        />
                        <LabelCompo
                          className="text-black"
                          style={{
                            fontSize: 16,
                            color: titleColor,
                          }}
                          label={"Product" + " :"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <AutoComplete
                          label={"Product"}
                          placeHolder={"Select product"}
                          keyColName={"id"}
                          value={productIdForFlag6}
                          name={"productIdForFlag6"}
                          options={productForFlag6}
                          disabled={false}
                          onChange={this.changeAutocompleteHandler}
                          isError={
                            formErrors.productIdForFlag6Error
                              ? true
                              : formErrors.productForFlag6Error
                                ? true
                                : false
                          }
                          errorText={
                            formErrors.productIdForFlag6Error
                              ? formErrors.productIdForFlag6Error
                              : formErrors.productForFlag6Error
                                ? formErrors.productForFlag6Error
                                : " "
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      sx={{
                        marginTop: 1,
                      }}
                    //  rowSpacing={16}
                    >
                      <Grid item xs={12} sm={4} md={4}>
                        &nbsp;&nbsp;
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: redColor }}
                          label={"*"}
                        />
                        <LabelCompo
                          className="text-black"
                          style={{
                            fontSize: 16,
                            color: titleColor,
                          }}
                          label={"Batch" + " :"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <AutoComplete
                          label={"Batch"}
                          placeHolder={"Select Batch"}
                          keyColName={"id"}
                          name={"batchIdForFlag6"}
                          value={batchIdForFlag6}
                          options={productStockForFlag6}
                          disabled={false}
                          onChange={this.changeAutocompleteHandler}
                          isError={
                            formErrors.batchIdForFlag6Error ? true : false
                          }
                          errorText={
                            formErrors.batchIdForFlag6Error
                              ? formErrors.batchIdForFlag6Error
                              : " "
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container sm={1} md={1} lg={1} xs={12}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{ paddingLeft: 2 }}
                      >
                        <ButtonCompo
                          size="medium"
                          type="submit"
                          variant="contained"
                          name={"ADD"}
                          onClick={(e) =>
                            this.onFormSaveHandler(
                              e,
                              6,
                              offerNameForFlag6.level,
                              offerNameForFlag6.type
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      container
                      row
                      sx={{ paddingTop: 2 }}
                    >
                      <RTTable
                        showPegination={false}
                        columns={[
                          {
                            name: "index",
                            title: "Sr. No.",
                            align: "center",
                          },
                          { name: "productName", title: "Product" },
                          { name: "batch", title: "Batch" },
                          { name: "qty", title: "Quantity" },
                          { name: "action", title: "Action" },
                        ]}
                        tableData={rowListForFlag6}
                        isActionColActive={true}
                        showEditIcon={false}
                        showDeleteIcon={true}
                        rowDelete={this.rowDelete}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <br />
              </>
            )}
            {openType0Flag7 && (
              <>
                <br />
                <Grid item sm={12} xs={12} md={12} lg={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: "20px", color: DarkBlue }}
                    label={offerNameForType0Flag7.name}
                  />
                  <br />
                  <Grid container spacing={1}>
                    <Grid
                      container
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      sx={{
                        marginTop: 1,
                      }}
                    >
                      <Grid item xs={12} sm={4} md={4}>
                        &nbsp;&nbsp;
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: redColor }}
                          label={"*"}
                        />
                        <LabelCompo
                          className="text-black"
                          style={{
                            fontSize: 16,
                            color: titleColor,
                          }}
                          label={"Product" + " :"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <AutoComplete
                          label={"Product"}
                          placeHolder={"Select product"}
                          keyColName={"id"}
                          name={"productIdForType0Flag7"}
                          value={productIdForType0Flag7}
                          options={productForType0Flag7}
                          disabled={false}
                          onChange={this.changeAutocompleteHandler}
                          isError={
                            formErrors.productIdForFlag7Error
                              ? true
                              : formErrors.productForFlag7Error
                                ? true
                                : false
                          }
                          errorText={
                            formErrors.productIdForFlag7Error
                              ? formErrors.productIdForFlag7Error
                              : formErrors.productForFlag7Error
                                ? formErrors.productForFlag7Error
                                : " "
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      sx={{
                        marginTop: 1,
                      }}
                    >
                      <Grid item xs={12} sm={4} md={4}>
                        &nbsp;&nbsp;
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: redColor }}
                          label={"*"}
                        />
                        <LabelCompo
                          className="text-black"
                          style={{
                            fontSize: 16,
                            color: titleColor,
                          }}
                          label={"Batch" + " :"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <AutoComplete
                          label={"Batch"}
                          placeHolder={"Select Batch"}
                          keyColName={"id"}
                          name={"batchIdForType0Flag7"}
                          value={batchIdForType0Flag7}
                          options={productStockForType0Flag7}
                          disabled={false}
                          onChange={this.changeAutocompleteHandler}
                          isError={
                            formErrors.batchIdForFlag7Error ? true : false
                          }
                          errorText={
                            formErrors.batchIdForFlag7Error
                              ? formErrors.batchIdForFlag7Error
                              : " "
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container sm={1} md={1} lg={1} xs={12}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{ paddingLeft: 2 }}
                      >
                        <ButtonCompo
                          size="medium"
                          type="submit"
                          variant="contained"
                          name={"ADD"}
                          onClick={(e) =>
                            this.onFormSaveHandler(
                              e,
                              7,
                              offerNameForType0Flag7.level,
                              offerNameForType0Flag7.type
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      container
                      row
                      sx={{ paddingTop: 2 }}
                    >
                      <RTTable
                        showPegination={false}
                        columns={[
                          {
                            name: "index",
                            title: "Sr. No.",
                            align: "center",
                          },
                          { name: "productName", title: "Product" },
                          { name: "batch", title: "Batch" },
                          { name: "qty", title: "Quantity" },
                          { name: "action", title: "Action" },
                        ]}
                        tableData={rowListForType0Flag7}
                        isActionColActive={true}
                        showEditIcon={false}
                        showDeleteIcon={true}
                        rowDelete={this.rowDelete}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <br />
              </>
            )}
            {openType1Flag7 && (
              <>
                <br />
                <Grid item sm={12} xs={12} md={12} lg={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: "20px", color: DarkBlue }}
                    label={offerNameForType1Flag7}
                  />
                  <br />
                  <Grid container spacing={1}>
                    <Grid
                      container
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      sx={{
                        marginTop: 1,
                      }}
                    >
                      <Grid item xs={12} sm={4} md={4}>
                        &nbsp;&nbsp;
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: redColor }}
                          label={"*"}
                        />
                        <LabelCompo
                          className="text-black"
                          style={{
                            fontSize: 16,
                            color: titleColor,
                          }}
                          label={"Product" + " :"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <AutoComplete
                          label={"Product"}
                          placeHolder={"Select product"}
                          keyColName={"id"}
                          name={"productIdForType1Flag7"}
                          value={productIdForType1Flag7}
                          options={productForType1Flag7}
                          disabled={false}
                          onChange={this.changeAutocompleteHandler}
                          isError={
                            formErrors.productIdForFlag7Error ? true : formErrors.productForFlag7Error ? true : false
                          }
                          errorText={
                            formErrors.productIdForFlag7Error
                              ? formErrors.productIdForFlag7Error
                              : formErrors.productForFlag7Error
                                ? formErrors.productForFlag7Error
                                : " "
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      sx={{
                        marginTop: 1,
                      }}
                    >
                      <Grid item xs={12} sm={4} md={4}>
                        &nbsp;&nbsp;
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: redColor }}
                          label={"*"}
                        />
                        <LabelCompo
                          className="text-black"
                          style={{
                            fontSize: 16,
                            color: titleColor,
                          }}
                          label={"Batch" + " :"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <AutoComplete
                          label={"Batch"}
                          placeHolder={"Select Batch"}
                          keyColName={"id"}
                          name={"batchIdForType1Flag7"}
                          value={batchIdForType1Flag7}
                          options={productStockForType1Flag7}
                          disabled={false}
                          onChange={this.changeAutocompleteHandler}
                          isError={
                            formErrors.batchIdForFlag7Error ? true : false
                          }
                          errorText={
                            formErrors.batchIdForFlag7Error
                              ? formErrors.batchIdForFlag7Error
                              : " "
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      sm={1}
                      md={1}
                      lg={1}
                      xs={12}

                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{ paddingLeft: 2 }}
                      >
                        <ButtonCompo
                          size="medium"
                          type="submit"
                          variant="contained"
                          name={"ADD"}
                          onClick={(e) => this.onFormSaveHandler(e, 7, offerNameForType1Flag7.level,
                            offerNameForType1Flag7.type)}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={12}
                      lg={12}
                      container
                      row
                      sx={{ paddingTop: 2 }}
                    >
                      <RTTable
                        showPegination={false}
                        columns={[
                          {
                            name: "index",
                            title: "Sr. No.",
                            align: "center",
                          },
                          { name: "productName", title: "Product" },
                          { name: "batch", title: "Batch" },
                          { name: "qty", title: "Quantity" },
                          { name: "action", title: "Action" },
                        ]}
                        tableData={rowListForType1Flag7}
                        isActionColActive={true}
                        showEditIcon={false}
                        showDeleteIcon={true}
                        rowDelete={this.rowDelete}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <br />
              </>
            )}
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              justifyContent="flex-end"
            >
              <ButtonCompo
                sx={{
                  bgcolor: "dodgerblue",
                  color: "#fbff00",
                }}
                size="medium"
                type="button"
                variant="contained"
                name={"Submit"}
                onClick={this.clickSubmitHandler}
                fullWidth={false}
              />
            </Grid>
          </BootstrapDialogTitle>
        </BootstrapDialog>
      </Paper>
    </>
  );
}
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BillingPopUpForOffer);
