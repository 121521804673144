import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  district: [],
  districtByZone: [],
  districtNew: [],
};
let URL = endpoints.district;
const districtSlice = createSlice({
  name: "district",
  initialState,
  reducers: {
    districtSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        district: row,
      };
    },

    districtByZoneSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        districtByZone: row,
      };
    },

    districtNewSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        districtNew: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  districtSuccess,
  districtByZoneSuccess,
  districtNewSuccess,
  resetState,
} = districtSlice.actions;

export default districtSlice.reducer;

export const getDistrict = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"sortOrder": "ASC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((districtObject, index) => {
          let districtData = {
            index: index + 1,
            id: districtObject.id === null ? "" : districtObject.id,
            name:
              districtObject.name === null
                ? ""
                : districtObject.name + " (" + districtObject.shortName + ")",
            districtName:
              districtObject.name === null ? "" : districtObject.name,
            zoneName:
              districtObject.zone !== null && districtObject.zone.name
                ? districtObject.zone.name
                : "",
            zone:
              districtObject.zone !== null && districtObject.zone.id
                ? districtObject.zone.id
                : "",
            shortName:
              districtObject.shortName === null ? "" : districtObject.shortName,
            talukaCount:
              districtObject.talukaCount === null
                ? ""
                : districtObject.talukaCount,
            areaCount:
              districtObject.areaCount === null ? "" : districtObject.areaCount,
            beatCount:
              districtObject.beatCount === null ? "" : districtObject.beatCount,
            outletCount:
              districtObject.outletCount === null
                ? ""
                : districtObject.outletCount,
            sortOrder:
              districtObject.sortOrder === null ? "" : districtObject.sortOrder,
          };
          return districtData;
        });
        dispatch(districtSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getDistrictUpdated = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-all" + '?sort={"sortOrder": "ASC"}',
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((districtObject, index) => {
          let districtData = {
            index: index + 1,
            id: districtObject.id === null ? "" : districtObject.id,
            name:
              districtObject.name === null
                ? ""
                : districtObject.name + " (" + districtObject.shortName + ")",
            districtName:
              districtObject.name === null ? "" : districtObject.name,
            zoneName:
              districtObject.zoneName !== null ? districtObject.zoneName : "",
            zone: districtObject.zoneId !== null ? districtObject.zoneId : "",
            shortName:
              districtObject.shortName === null ? "" : districtObject.shortName,
            talukaCount:
              districtObject.talukaCount === null
                ? ""
                : districtObject.talukaCount,
            areaCount:
              districtObject.areaCount === null ? "" : districtObject.areaCount,
            beatCount:
              districtObject.beatCount === null ? "" : districtObject.beatCount,
            outletCount:
              districtObject.outletCount === null
                ? ""
                : districtObject.outletCount,
            sortOrder:
              districtObject.sortOrder === null ? "" : districtObject.sortOrder,
          };
          return districtData;
        });
        dispatch(districtSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const setDistrictByZone =
  ({ row }) =>
  async (dispatch) => {
    dispatch(districtNewSuccess({ row }));
  };

export const getDistrictByZone =
  ({ zoneIds }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url: URL + "/by-zones?zoneIds=" + zoneIds,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((districtByZone, index) => {
            let districtByZoneData = {
              index: index + 1,
              ...districtByZone,
              id: districtByZone.id === null ? "" : districtByZone.id,
              name: districtByZone.name === null ? "" : districtByZone.name,
              shortName:
                districtByZone.shortName === null
                  ? ""
                  : districtByZone.shortName,
              zone:
                districtByZone.name === null ? "" : districtByZone.zone.name,
              sortOrder:
                districtByZone.sortOrder === null
                  ? ""
                  : districtByZone.sortOrder,
              zoneId:
                districtByZone.name === null ? "" : districtByZone.zone.id,
            };
            return districtByZoneData;
          });
          dispatch(districtByZoneSuccess({ row }));
          dispatch(districtNewSuccess({ row: data.data }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return { response: [], success: false };
    }
  };
