// material-ui
import {
  Box,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { cyanBlue } from "../../config/ColorObj";
import { serverMsg } from "../../config/messageconstant";
import { getBeatFromSalesTeamId } from "../../Slice/beat.slice";
import BeatPopUp from "./BeatPopUp";

// third-party
// import {NumericFormat} from 'react-number-format';

// project import
// import Dot from 'components/@extended/Dot';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// ==============================|| ORDER TABLE - HEADER CELL ||============================== //

const headCells = [
  {
    id: "index",
    align: "center",
    disablePadding: false,
    label: "Sr. No",
  },
  {
    id: "salesTeam",
    align: "center",
    disablePadding: false,
    label: "Sales Team Name",
  },
  {
    id: "district_names",
    align: "left",
    disablePadding: true,
    label: "District",
  },
  {
    id: "area_names",
    align: "left",
    disablePadding: true,
    label: "Area",
  },
  {
    id: "beatCount",
    align: "left",
    disablePadding: true,
    label: "Beat Count",
  },

  {
    id: "totalOutletCount",
    align: "right",
    disablePadding: false,
    label: "Total Outlet",
  },
  {
    id: "visitedCount",
    align: "right",
    disablePadding: false,
    label: "Visited Outlet",
  },
  {
    id: "unvisitedOutletCount",
    align: "right",
    disablePadding: false,
    label: "Unvisited Outlet",
  },
  {
    id: "zeroOrderCount",
    align: "right",
    disablePadding: false,
    label: "Zero Order",
  },
  {
    id: "totalAmount",
    align: "right",
    disablePadding: false,
    label: "Total Amount",
  },
];

// ==============================|| ORDER TABLE - HEADER ||============================== //

function OrderTableHead({ order, orderBy }) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

OrderTableHead.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
};

// ==============================|| ORDER TABLE - STATUS ||============================== //

const OrderStatus = ({ status }) => {
  let color;
  let title;

  switch (status) {
    case 0:
      color = "warning";
      title = "Pending";
      break;
    case 1:
      color = "success";
      title = "Approved";
      break;
    case 2:
      color = "error";
      title = "Rejected";
      break;
    default:
      color = "primary";
      title = "None";
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {/* <Dot color={color} /> */}
      <Typography>{title}</Typography>
    </Stack>
  );
};

OrderStatus.propTypes = {
  status: PropTypes.number,
};

// ==============================|| ORDER TABLE ||============================== //

// export default function OrderTable({
const OrderTable = ({
  rows = [],
  extraData = [],
  getBeatFromSalesTeamId,
  showLoador,
  showNotification,
  salesTeamData,
}) => {
  const [order] = useState("asc");
  const [orderBy] = useState("district_names");
  const [selected] = useState([]);
  const [popUpFlag, setPopUpFlag] = useState(false);

  const isSelected = (district_names) => selected.indexOf(district_names) !== -1;
  const options1 = {
    chart: {
      type: "line",
      width: 100,
      height: 35,
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      curve: "straight",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function (seriesName) {
            return "";
          },
        },
      },
      marker: {
        show: false,
      },
    },
  };
  const options = {
    chart: {
      type: "line",
      width: 100,
      height: 35,
      sparkline: {
        enabled: true,
      },
    },

    stroke: {
      curve: "straight",
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box">' +
          "<span>" +
          series1[0].label[dataPointIndex] +
          ": " +
          series[seriesIndex][dataPointIndex] +
          "</span>" +
          "</div>"
        );
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
      ],
    },
  };

  const handleCloses = () => {
    setPopUpFlag(false);
  };
  const onClickHandler = (e, row) => {
    console.log(row);
    if (navigator.onLine) {
      showLoador({ loador: true });
      getBeatFromSalesTeamId({ salesTeamIds: row.salesTeamId }).then(
        ({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            setPopUpFlag(true);
          }
        }
      );
    } else {
      showNotification({
        msg: myConstClass.noInternetMsg,
        severity: "error",
      });
    }
  };

  const series = [
    {
      name: "Desktops",
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
    },
  ];
  const series1 = [
    {
      label: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
      name: "High - 2013",
      data: [25, 66, 41, 89, 63, 25],
    },
  ];
  return (
    <Box>
      <TableContainer
        sx={{
          width: "auto",
          overflowX: "auto",
          position: "relative",
          // display: "block",
          // maxWidth: "100%",
          "& td, & th": { whiteSpace: "nowrap" },
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          sx={{
            "& .MuiTableCell-root:first-of-type": {
              pl: 2,
            },
            "& .MuiTableCell-root:last-of-type": {
              pr: 3,
            },
          }}
        >
          <OrderTableHead order={order} orderBy={orderBy} />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy)).map(
              (row, index) => {
                const isItemSelected = isSelected(row.district_names);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.district_names}
                    selected={isItemSelected}
                  >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      align="left"
                    >
                      <Link
                        color="secondary"
                        component={RouterLink}
                        to={"/sales/" + row.salesTeamId}
                      >
                        {row.salesTeam}
                      </Link>
                    </TableCell>
                    <TableCell align="left">{row.district_names}</TableCell>
                    <TableCell align="left">{row.area_names}</TableCell>
                    <TableCell align="right">
                      <div
                        onClick={(e) => onClickHandler(e, row)}
                        style={{
                          color: cyanBlue,
                          cursor: "pointer",
                        }}
                      >
                        {row.beatCount}
                      </div>
                    </TableCell>
                    <TableCell align="right">{row.totalOutletCount}</TableCell>
                    <TableCell align="right">
                      {row.totalOutletCount -
                        row.unvisitedOutletCount -
                        row.zeroOrderCount}
                    </TableCell>
                    <TableCell align="right">
                      {row.unvisitedOutletCount}
                    </TableCell>
                    <TableCell align="right">{row.zeroOrderCount}</TableCell>
                    <TableCell align="right">
                      {(+row.totalAmount).toFixed(2)}
                    </TableCell>
                  </TableRow>
                );
              }
            )}
            <TableRow>
              <TableCell align="right" colSpan={5}>
                Total
              </TableCell>
              {extraData.map((columnData) => {
                return <TableCell align="right">{columnData}</TableCell>;
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {popUpFlag && (
        <BeatPopUp
          open={popUpFlag}
          handleCloses={handleCloses}
          columns={[
            {
              title: "Sr. No.",
              name: "index",
              positionCenter: false,
              showInscreen: true,
              alignCenter: "center",
              canSearch: true,
            },
            {
              title: "Beat",
              name: "beatName",
              positionCenter: false,
              showInscreen: true,
              alignCenter: "left",
              canSearch: true,
            },
          ]}
          rows={salesTeamData}
        />
      )}
    </Box>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  getBeatFromSalesTeamId,
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderTable);
