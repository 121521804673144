import endpoint from "../../config/endpoints";

export const openingTransactionForOutletJson = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.transactionOutlet,
  screenTitle: "Opening For Outlet",
  fieldMeta: [
    {
      label: "Outlet ",
      controlType: "autocomplete",
      placeHolder: "Select Outlet",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "outletDetail",
      dataKey: "outlet",
      isMandatory: true,
      isRootLevelKey: false,
    },
    {
      label: "Opening Type",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "transactionType",
      tableDataKey: "transactionType",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Advance",
          value: "0",
        },
        {
          label: "Outstanding",
          value: "1",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Amount",
      controlType: "textfield",
      placeHolder: "Enter Amount",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "amount",
      isMandatory: true,
      autoFocus: true,
    },
    {
      label: "Remark",
      controlType: "textfield",
      placeHolder: "Enter Remark",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "remark",
      isMandatory: false,
    },
  ],
};
