import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { GroupProductColumns } from "../../../tableColumns/table-columns";
import { groupProductJson } from "../../../DynamicFormsJson/MastersJSON/groupProduct";
import { getGroupProductList } from "../../../Slice/groupProduct.slice";

class GroupProductTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { groupProductList, getGroupProductList } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={groupProductJson.formPath}
          screenTitle={groupProductJson.screenTitle}
          showViewButtonInTable={groupProductJson.formPathView}
          fieldMeta={groupProductJson.fieldMeta}
          showAddButton={groupProductJson.showAddButton}
          tableColumns={GroupProductColumns}
          tableData={groupProductList?.groupProduct}
          getTableData={getGroupProductList}
          apiBaseURL={groupProductJson.apiBaseURL}
          baseIdColumn={groupProductJson.baseIdColumn}
          showEditIcon={true}
          showPdfDownload={false}
          showExcelDownload={true}
          changeExcelURL={groupProductJson.excelUrl}
          showExtension={false}
          formPathView={groupProductJson.formPathView}
          showDeleteIcon={false}
          isNote={true}
          isNoteValue={"Add Group & Products"}
          isActiveURL={groupProductJson.isActiveURL}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  groupProductList: state.groupProduct,
});
const mapDispatchToProps = {
  getGroupProductList,
};
export default connect(mapStateToProps, mapDispatchToProps)(GroupProductTable);
