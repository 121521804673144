import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  pendingOrder: [],
  parkedOrder: [],
  orderCounts: {},
  paymentData: [],
  paymentReceiptData: [],
  secondaryBill:[],
  secondaryPaymentDateWise:[]
};
let URL = endpoints.outletOrderHeader;
let URL1 = endpoints.outletBillHeader;
let paymentReceiptOutlet = endpoints.paymentReceiptOutlet;


const functionalDashboardDistributorSlice = createSlice({
  name: "functionalDashboardDistributor",
  initialState,
  reducers: {
    pendingOrderSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        pendingOrder: row,
      };
    },

    parkedOrderSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        parkedOrder: row,
      };
    },

    orderCountsSuccess: (state = cloneDeep(initialState), action) => {
      const { orderCounts } = action.payload;
      return {
        ...state,
        orderCounts: orderCounts,
      };
    },

    outletPaymentSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        paymentData: row,
      };
    },
    outletPaymentReceiptSuccess: (state = cloneDeep(initialState), action) => {
      const { row1 } = action.payload;
      return {
        ...state,
        paymentReceiptData: row1,
      };
    },
    secondaryBillHistorySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        secondaryBill: row,
      };
    },
    secondaryPaymentDateWiseSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        secondaryPaymentDateWise: row,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  pendingOrderSuccess,
  parkedOrderSuccess,
  orderCountsSuccess,
  outletPaymentSuccess,
  outletPaymentReceiptSuccess,
  resetState,
  secondaryBillHistorySuccess,
  secondaryPaymentDateWiseSuccess
} = functionalDashboardDistributorSlice.actions;

export default functionalDashboardDistributorSlice.reducer;

export const getOrderCountsForDistributor = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/secondary-order",
    }).then(({ data, success }) => {
      if (success) {
        dispatch(orderCountsSuccess({ orderCounts: data.data }));
        return { response: data.data, success };
      }
      return { response: {}, success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getPendingOrderForDistributor = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/pending-order",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((pendingOrderObject, index) => {
          let pendingOrderData = {
            index: index + 1,
            id: pendingOrderObject.id === null ? "" : pendingOrderObject.id,
            outLet:
              pendingOrderObject.outLet === null
                ? ""
                : pendingOrderObject.outLet,
            outletName:
              pendingOrderObject.outLet === null
                ? ""
                : pendingOrderObject.outLet.firmName,
            orderNo:
              pendingOrderObject.orderNo === null
                ? ""
                : pendingOrderObject.orderNo,
            orderDate:
              pendingOrderObject.orderDate === null
                ? ""
                : pendingOrderObject.orderDate,
            taxableAmt:
              pendingOrderObject.taxableAmt === null
                ? ""
                : pendingOrderObject.taxableAmt.toFixed(2),
            cgstAmt:
              pendingOrderObject.cgstAmt === null
                ? ""
                : pendingOrderObject.cgstAmt.toFixed(2),
            sgstAmt:
              pendingOrderObject.sgstAmt === null
                ? ""
                : pendingOrderObject.sgstAmt.toFixed(2),
            totalAmt:
              pendingOrderObject.totalAmt === null
                ? ""
                : pendingOrderObject.totalAmt.toFixed(2),
            distcountAmt:
              pendingOrderObject.distcountAmt === null
                ? ""
                : pendingOrderObject.distcountAmt.toFixed(2),

            igstAmt:
              pendingOrderObject.igstAmt === null
                ? ""
                : pendingOrderObject.igstAmt.toFixed(2),

            stockStakeHolder:
              pendingOrderObject.stockStakeHolder === null
                ? ""
                : pendingOrderObject.stockStakeHolder,
            type:
              pendingOrderObject.type === null ? "" : pendingOrderObject.type,
            insertedUserId:
              pendingOrderObject.insertedUserId === null
                ? ""
                : pendingOrderObject.insertedUserId,
            insertDateTime:
              pendingOrderObject.insertDateTime === null
                ? ""
                : pendingOrderObject.insertDateTime,
          };
          return pendingOrderData;
        });
        dispatch(pendingOrderSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getPendingOrderForDistributorByFilter = ({salesTeamIds,outletIds,flag}) => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/pending-order-by-sales_teams-or-outlets?salesTeamIds="+salesTeamIds+
      "&outletIds="+outletIds+"&flag="+flag,
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((pendingOrderObject, index) => {
          let pendingOrderData = {
            index: index + 1,
            id: pendingOrderObject.id === null ? "" : pendingOrderObject.id,
            outLet:
              pendingOrderObject.outLet === null
                ? ""
                : pendingOrderObject.outLet,
            outletName:
              pendingOrderObject.outLet === null
                ? ""
                : pendingOrderObject.outLet.firmName,
            orderNo:
              pendingOrderObject.orderNo === null
                ? ""
                : pendingOrderObject.orderNo,
            orderDate:
              pendingOrderObject.orderDate === null
                ? ""
                : pendingOrderObject.orderDate,
            taxableAmt:
              pendingOrderObject.taxableAmt === null
                ? ""
                : pendingOrderObject.taxableAmt.toFixed(2),
            cgstAmt:
              pendingOrderObject.cgstAmt === null
                ? ""
                : pendingOrderObject.cgstAmt.toFixed(2),
            sgstAmt:
              pendingOrderObject.sgstAmt === null
                ? ""
                : pendingOrderObject.sgstAmt.toFixed(2),
            totalAmt:
              pendingOrderObject.totalAmt === null
                ? ""
                : pendingOrderObject.totalAmt.toFixed(2),
            distcountAmt:
              pendingOrderObject.distcountAmt === null
                ? ""
                : pendingOrderObject.distcountAmt.toFixed(2),

            igstAmt:
              pendingOrderObject.igstAmt === null
                ? ""
                : pendingOrderObject.igstAmt.toFixed(2),

            stockStakeHolder:
              pendingOrderObject.stockStakeHolder === null
                ? ""
                : pendingOrderObject.stockStakeHolder,
            type:
              pendingOrderObject.type === null ? "" : pendingOrderObject.type,
            insertedUserId:
              pendingOrderObject.insertedUserId === null
                ? ""
                : pendingOrderObject.insertedUserId,
            insertDateTime:
              pendingOrderObject.insertDateTime === null
                ? ""
                : pendingOrderObject.insertDateTime,
          };
          return pendingOrderData;
        });
        dispatch(pendingOrderSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getParkOrderForDistributor = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/parked-order",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((parkedOrderObject, index) => {
          let parkedOrderData = {
            index: index + 1,
            id: parkedOrderObject.id === null ? "" : parkedOrderObject.id,
            outLet:
              parkedOrderObject.outLet === null ? "" : parkedOrderObject.outLet,
            outletName:
              parkedOrderObject.outLet === null
                ? ""
                : parkedOrderObject.outLet.firmName,
            orderNo:
              parkedOrderObject.orderNo === null
                ? ""
                : parkedOrderObject.orderNo,
            orderDate:
              parkedOrderObject.orderDate === null
                ? ""
                : parkedOrderObject.orderDate,
            taxableAmt:
              parkedOrderObject.taxableAmt === null
                ? ""
                : parkedOrderObject.taxableAmt.toFixed(2),
            cgstAmt:
              parkedOrderObject.cgstAmt === null
                ? ""
                : parkedOrderObject.cgstAmt.toFixed(2),
            sgstAmt:
              parkedOrderObject.sgstAmt === null
                ? ""
                : parkedOrderObject.sgstAmt.toFixed(2),
            totalAmt:
              parkedOrderObject.totalAmt === null
                ? ""
                : parkedOrderObject.totalAmt.toFixed(2),
            distcountAmt:
              parkedOrderObject.distcountAmt === null
                ? ""
                : parkedOrderObject.distcountAmt.toFixed(2),

            igstAmt:
              parkedOrderObject.igstAmt === null
                ? ""
                : parkedOrderObject.igstAmt.toFixed(2),

            stockStakeHolder:
              parkedOrderObject.stockStakeHolder === null
                ? ""
                : parkedOrderObject.stockStakeHolder,
            type: parkedOrderObject.type === null ? "" : parkedOrderObject.type,
            insertedUserId:
              parkedOrderObject.insertedUserId === null
                ? ""
                : parkedOrderObject.insertedUserId,
            insertDateTime:
              parkedOrderObject.insertDateTime === null
                ? ""
                : parkedOrderObject.insertDateTime,
          };
          return parkedOrderData;
        });
        dispatch(parkedOrderSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getOutletOutstandingForDistributor = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL1 + "/and-pament-reciept",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.outletBillHeaders.map((billDataObject, index) => {
          let billData = {
            index: index + 1,
            acknoledgeNo:
              billDataObject.acknoledgeNo == null
                ? ""
                : billDataObject.acknoledgeNo,
            billDate:
              billDataObject.billDate == null ? "" : billDataObject.billDate,
            billNo: billDataObject.billNo == null ? 0 : billDataObject.billNo,
            cessAmt:
              billDataObject.cessAmt == null ? "" : billDataObject.cessAmt,
            cgstAmt:
              billDataObject.cgstAmt == null
                ? ""
                : billDataObject.cgstAmt.toFixed(2),
            discountOnBillAmt:
              billDataObject.discountOnBillAmt == null
                ? ""
                : billDataObject.discountOnBillAmt.toFixed(2),
            discountOnBillPer:
              billDataObject.discountOnBillPer == null
                ? ""
                : billDataObject.discountOnBillPer.toFixed(2),
            id: billDataObject.id == null ? "" : billDataObject.id,
            igstAmt:
              billDataObject.igstAmt == null
                ? ""
                : billDataObject.igstAmt.toFixed(2),
            insertedUserId:
              billDataObject.insertedUserId == null
                ? ""
                : billDataObject.insertedUserId,
            isSettle:
              billDataObject.isSettle == null ? "" : billDataObject.isSettle,
            itemDistcountAmt:
              billDataObject.itemDistcountAmt == null
                ? ""
                : billDataObject.itemDistcountAmt.toFixed(2),
            outletId:
              billDataObject.outLet == null && billDataObject.outLet.id == null
                ? ""
                : billDataObject.outLet.id,
            outletFirmName:
              billDataObject.outLet == null &&
              billDataObject.outLet.firmName == null
                ? ""
                : billDataObject.outLet.firmName,
            outletOwnerName:
              billDataObject.outLet == null &&
              billDataObject.outLet.ownerName == null
                ? ""
                : billDataObject.outLet.ownerName,
            payoutAmt:
              billDataObject.payoutAmt == null
                ? ""
                : billDataObject.payoutAmt.toFixed(2),
            payoutIsSettle:
              billDataObject.payoutIsSettle == null
                ? ""
                : billDataObject.payoutIsSettle.toFixed(2),
            remainingAmt:
              billDataObject.remainingAmt == null
                ? ""
                : billDataObject.remainingAmt.toFixed(2),
            sgstAmt:
              billDataObject.sgstAmt == null
                ? ""
                : billDataObject.sgstAmt.toFixed(2),
            status: billDataObject.status == null ? "" : billDataObject.status,
            stockStakeHolderId:
              billDataObject.stockStakeHolderId == null
                ? ""
                : billDataObject.stockStakeHolderId,
            taxableAmt:
              billDataObject.taxableAmt == null
                ? ""
                : billDataObject.taxableAmt.toFixed(2),
            totalAmt:
              billDataObject.totalAmt == null
                ? ""
                : billDataObject.totalAmt.toFixed(2),
          };

          return billData;
        });
        dispatch(outletPaymentSuccess({ row }));

        return { response: data.data, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getPaymentReceiptDataByFilter = ({fromDate,toDate}) => async (dispatch) => {
  try {
    const response = apiGet({
      url: paymentReceiptOutlet + "/by-date?fromDate="+fromDate+"&toDate="+toDate,
    }).then(({ data, success }) => {
      if (success) {
       
        const row1 = data.data.map((paymentDataObject, index) => {
            let paymentData = {
              index: index + 1,
              amt:
                paymentDataObject.amt == null
                  ? ""
                  : +paymentDataObject.amt.toFixed(2),
              name:
                (paymentDataObject.amt == null
                  ? ""
                  : paymentDataObject.amt.toFixed(2) + " ₹ - ") +
                (paymentDataObject.settleRemainingAmt == null
                  ? ""
                  : paymentDataObject.settleRemainingAmt.toFixed(2) + " ₹"),
              date:
                paymentDataObject.date == null ? "" : paymentDataObject.date,
              id: paymentDataObject.id == null ? "" : paymentDataObject.id,
              insertedUserId:
                paymentDataObject.insertedUserId == null
                  ? ""
                  : paymentDataObject.insertedUserId,
              outLet:
                paymentDataObject.outLet == null
                  ? ""
                  : paymentDataObject.outLet.id,
              outletName:
                paymentDataObject.outLet == null
                  ? ""
                  : paymentDataObject.outLet.firmName,
              payBank:
                paymentDataObject.payBank == null
                  ? ""
                  : paymentDataObject.payBank,
              payRefNo:
                paymentDataObject.payRefNo == null
                  ? ""
                  : paymentDataObject.payRefNo,
              payType:
                paymentDataObject.payType == null
                  ? ""
                  : paymentDataObject.payType,
              remark:
                paymentDataObject.remark == null
                  ? ""
                  : paymentDataObject.remark,
              remark2:
                paymentDataObject.remark2 == null
                  ? ""
                  : paymentDataObject.remark2,
              settleRemainingAmt:
                paymentDataObject.settleRemainingAmt == null
                  ? ""
                  : paymentDataObject.settleRemainingAmt.toFixed(2), // removed + because toFixed was not working
              remainingAmt:
                paymentDataObject.remainingAmt == null
                  ? ""
                  : +paymentDataObject.remainingAmt.toFixed(2),
              stockStakeHolderId:
                paymentDataObject.stockStakeHolderId == null
                  ? ""
                  : paymentDataObject.stockStakeHolderId,
              transactionNo:
                paymentDataObject.transactionNo == null
                  ? ""
                  : paymentDataObject.transactionNo,
            };
            return paymentData;
          }
        );

        dispatch(outletPaymentReceiptSuccess({ row1 }));

        return { response: data.data, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getPaymentReceiptData = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: paymentReceiptOutlet + "/latest",
    }).then(({ data, success }) => {
      if (success) {
       
        const row1 = data.data.map((paymentDataObject, index) => {
            let paymentData = {
              index: index + 1,
              amt:
                paymentDataObject.amt == null
                  ? ""
                  : +paymentDataObject.amt.toFixed(2),
              name:
                (paymentDataObject.amt == null
                  ? ""
                  : paymentDataObject.amt.toFixed(2) + " ₹ - ") +
                (paymentDataObject.settleRemainingAmt == null
                  ? ""
                  : paymentDataObject.settleRemainingAmt.toFixed(2) + " ₹"),
              date:
                paymentDataObject.date == null ? "" : paymentDataObject.date,
              id: paymentDataObject.id == null ? "" : paymentDataObject.id,
              insertedUserId:
                paymentDataObject.insertedUserId == null
                  ? ""
                  : paymentDataObject.insertedUserId,
              outLet:
                paymentDataObject.outLet == null
                  ? ""
                  : paymentDataObject.outLet.id,
              outletName:
                paymentDataObject.outLet == null
                  ? ""
                  : paymentDataObject.outLet.firmName,
              payBank:
                paymentDataObject.payBank == null
                  ? ""
                  : paymentDataObject.payBank,
              payRefNo:
                paymentDataObject.payRefNo == null
                  ? ""
                  : paymentDataObject.payRefNo,
              payType:
                paymentDataObject.payType == null
                  ? ""
                  : paymentDataObject.payType,
              remark:
                paymentDataObject.remark == null
                  ? ""
                  : paymentDataObject.remark,
              remark2:
                paymentDataObject.remark2 == null
                  ? ""
                  : paymentDataObject.remark2,
              settleRemainingAmt:
                paymentDataObject.settleRemainingAmt == null
                  ? ""
                  : paymentDataObject.settleRemainingAmt.toFixed(2), // removed + because toFixed was not working
              remainingAmt:
                paymentDataObject.remainingAmt == null
                  ? ""
                  : +paymentDataObject.remainingAmt.toFixed(2),
              stockStakeHolderId:
                paymentDataObject.stockStakeHolderId == null
                  ? ""
                  : paymentDataObject.stockStakeHolderId,
              transactionNo:
                paymentDataObject.transactionNo == null
                  ? ""
                  : paymentDataObject.transactionNo,
            };
            return paymentData;
          }
        );

        dispatch(outletPaymentReceiptSuccess({ row1 }));

        return { response: data.data, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getSecondaryBillHistory = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL1 + "/todays-generated-bill",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((billDataObject, index) => {
         
          return {index:index+1,...billDataObject};
        });
       

        dispatch(secondaryBillHistorySuccess({ row }));
        
        return { response: data.data, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getSecondaryLatestBillByFilter = ({ fromDate, toDate, outletIds, status, billNo, flag }) => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL1 + "/by-date-and-status-and-outlets-or-bill-no?fromDate=" + fromDate +
        "&toDate=" + toDate + "&outletIds=" + outletIds + "&status=" + status + "&billNo=" + billNo + "&flag=" + flag,
    }).then(({ data, success }) => {
      if (success) {

        const row = data.data.map((rowData, index) => {
          return { index: index + 1,billNo:rowData.billNo,billDate:rowData.billDate,totalAmt:rowData.totalAmt, ...rowData }
        })
        dispatch(secondaryBillHistorySuccess({ row }));
        return { response: data.data, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getSecondaryPaymentDateWise = ({fromDate,toDate}) => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL1 + "/outstanding-bill-list-by-date?fromDate="+fromDate+"&toDate="+toDate,
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((billDataObject, index) => {
         
          return {index:index+1,...billDataObject};
        });
       

        dispatch(secondaryPaymentDateWiseSuccess({ row }));
        
        return { response: data.data, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};