import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { talukaJson } from "../../../DynamicFormsJson/MastersJSON/taluka";
import { getDistrictUpdated } from "../../../Slice/district.slice";
import {
    showLoador,
    showNotification,
} from "../../Landing/Landing.slice";
import { serverMsg, noInternetMsg, } from "../../../config/messageconstant";

class StateForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicMasterData: {
                districtDetail: this.props.districtList?.district,
            },
        };
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.districtList !== nextProps.districtList) {
            if (this.props.districtList?.district !== nextProps.districtList?.district) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        districtDetail: nextProps.districtList?.district,
                    },
                });
            }
        }
    }
    async componentDidMount() {
        const { getDistrictUpdated, showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            await getDistrictUpdated().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
        }
        else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    render() {
        const { dynamicMasterData } = this.state;
        return (
            <>
                <DynamicForm
                    formPath={talukaJson.formPath}
                    screenTitle={talukaJson.screenTitle}
                    fieldMeta={talukaJson.fieldMeta}
                    tableColumnsToFilter={talukaJson.tableColumnsToFilter}
                    apiBaseURL={talukaJson.apiBaseURL}
                    baseIdColumn={talukaJson.baseIdColumn}
                    dynamicMasterData={dynamicMasterData}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    districtList: state.district,
});
const mapDispatchToProps = {
    getDistrictUpdated,
    showLoador,
    showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(StateForm);
