import endpoint from "../../config/endpoints";

export const talukaJson = {
  formPath: "/taluka-form",
  apiBaseURL: endpoint.taluka,
  changeExcel: endpoint.exportData,
  screenTitle: "Taluka",
  showAddButton: true,
  showPdfDownload: false,
  pdfFileName: "Taluka",
  showExcelDownload: false,
  excelFileName: "Taluka",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "name",
      isChecked: true,
    },

    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "District",
      controlType: "autocomplete",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "districtDetail",
      dataKey: "district",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "name",
      isMandatory: true,
      uniqueValidation: true,
    },
    {
      label: "Short Name",
      controlType: "textfield",
      placeHolder: "Short Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "shortName",
      isMandatory: true,
    },
    {
      label: "Sort Order No",
      controlType: "textfield",
      placeHolder: "Sort Order Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sortOrder",
      isMandatory: true,
    },
  ],
};
