import endpoint from "../../config/endpoints";

export const receivedOrderListJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.stockStakeholderOrderHeader,
  formPath: "/received-order-bill",
  formPathEdit: "/received-order-bill-edit",
  screenTitle: "Received Order",
  showTitle: true,
  showCancel: false,
  showSaveBtn: false,
};
