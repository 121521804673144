import endpoint from "../../config/endpoints";

export const addOutletRateConfigurationJson = {
    //showSaveNextBtn: true,
    apiBaseURL: endpoint.room,
    formPath: "/product",
    screenTitle: "Add  Outlet Cofiguration By Item List",
    showTitle: true,
    showCancel:false,
    showSaveBtn:false,
    fieldMeta: [
    ]
};