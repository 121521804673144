import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { outletTypeJson } from "../../../DynamicFormsJson/MastersJSON/outletType";


class OutletTypeForm extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <>
                <DynamicForm
                    formPath={outletTypeJson.formPath}
                    screenTitle={outletTypeJson.screenTitle}
                    fieldMeta={outletTypeJson.fieldMeta}
                    apiBaseURL={outletTypeJson.apiBaseURL}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    
});
const mapDispatchToProps = {
   
};
export default connect(mapStateToProps, mapDispatchToProps)(OutletTypeForm);
