import { useEffect, useState } from "react";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Paper,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import TablePopUp from "./TablePopUp";
import OrdersTable from "./OrdersTable";
import IncomeAreaChart from "./IncomeAreaChart";
import MonthlyBarChart from "./MonthlyBarChart";
import ReportAreaChart from "./ReportAreaChart";
import SalesColumnChart from "./SalesColumnChart";
import MainCard from "./MainCard";
import AnalyticEcommerce from "./AnalyticEcommerce";
import PieChart from "./PieChart";

// assets
import {
  GiftOutlined,
  MessageOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import avatar1 from "../../assets/users/avatar-1.png";
import avatar2 from "../../assets/users/avatar-2.png";
import avatar3 from "../../assets/users/avatar-3.png";
import avatar4 from "../../assets/users/avatar-4.png";
import { serverMsg } from "../../config/messageconstant";
import endpoint from "../../config/endpoints";
import { apiGet } from "../../utils/api_service";
import { connect } from "react-redux";
import { getDashBourdMain } from "../../Slice/distributorDashbourd.slice";
// avatar style
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: "1rem",
};

// action style
const actionSX = {
  mt: 0.75,
  ml: 1,
  top: "auto",
  right: "auto",
  alignSelf: "flex-start",
  transform: "none",
};

// sales report status
const status = [
  {
    value: "today",
    label: "Today",
  },
  {
    value: "month",
    label: "This Month",
  },
  {
    value: "year",
    label: "This Year",
  },
];

// ==============================|| DASHBOARD - DEFAULT ||============================== //

const DashboardDefault = ({
  showLoador,
  getDashBourdMain,
  distributorDashbourd,
  showNotification,
}) => {
  const [openTable, setOpenTable] = useState(false);
  const [title, setTitle] = useState("");
  const [flagData, setFlagData] = useState("");
  const [rows, setRows] = useState([]);
  const theme = useTheme();

  const { primary, secondary } = theme.palette.text;
  const info = theme.palette.info.light;

  const [value, setValue] = useState("today");
  const [slot, setSlot] = useState("week");
  const toggle = (name, urlData, flag) => () => {
    setFlagData(flag);
    setTitle(name);
    if (name != "") {
      if (navigator.onLine) {
        showLoador({ loador: true });
        apiGet({
          url: urlData,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            setRows(
              data.data.map((rowObj, index) => {
                if (flag == 0) {
                  return {
                    index: index + 1,
                    beatName: rowObj.beatName,
                    firmName: rowObj.firmName,
                    ownerName: rowObj.ownerName,
                  };
                }
                if (flag == 2) {
                  return {
                    index: index + 1,
                    salesTeamName:
                      rowObj.salesTeam != null ? rowObj.salesTeam.name : "-",
                    checkInTime:
                      rowObj.checkInTime == null ? "-" : rowObj.checkInTime,
                    checkOutTime:
                      rowObj.checkOutTime == null ? "-" : rowObj.checkOutTime,
                  };
                }
                if (flag == 3) {
                  return {
                    index: index + 1,
                    salesTeamName: rowObj.name != null ? rowObj.name : "-",
                    contactNo:
                      rowObj.contactNo == null ? "-" : rowObj.contactNo,
                    emailId: rowObj.emailId == null ? "-" : rowObj.emailId,
                  };
                }
                if (flag == 4) {
                  return {
                    index: index + 1,
                    ownerName:
                      rowObj.outLet != null ? rowObj.outLet.ownerName : "-",
                    firmName:
                      rowObj.outLet != null ? rowObj.outLet.firmName : "-",
                    totalAmt:
                      rowObj.totalAmt != null ? rowObj.totalAmt.toFixed(2) : 0,
                  };
                }
                if (flag == 5) {
                  return {
                    index: index + 1,
                    ownerName:
                      rowObj.toStockStakeHolder != null
                        ? rowObj.toStockStakeHolder.ownerName
                        : "-",
                    firmName:
                      rowObj.toStockStakeHolder != null
                        ? rowObj.toStockStakeHolder.firmName
                        : "-",
                    totalAmt:
                      rowObj.totalAmt != null ? rowObj.totalAmt.toFixed(2) : 0,
                  };
                }
                return {
                  index: index + 1,
                  orderDate: rowObj.orderDate,
                  firmName:
                    rowObj.outLet != null ? rowObj.outLet.firmName : "-",
                  totalAmt: rowObj.totalAmt,
                };
              })
            );

            setOpenTable(!openTable);
          }
        });
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    } else {
      setOpenTable(!openTable);
    }
  };
  useEffect(() => {
    showLoador({ loador: true });
    getDashBourdMain().then(({ success }) => {
      if (!success) {
        showNotification({
          msg: serverMsg,
          severity: "error",
        });
      }
      showLoador({ loador: false });
    });
  }, []);
  const getPercentage = (curretnAmt, pervAmt) => {
    if (curretnAmt != 0 && pervAmt != 0) {
      let amt = curretnAmt / pervAmt;
      console.log(amt);
      return (amt * 100).toFixed(2);
    }
    return 0;
  };
  const onCLickPie = (index) => {
    console.log(
      distributorDashbourd.dashbourdMain.dtoOutletBillCategoryWiseTotalAmounts[
        index
      ]
    );
  };
  return (
    <>
      <Paper
        sx={{
          p: 2,
          borderRadius: 2,
        }}
        elevation={0}
      >
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
          <Grid item xs={12} sx={{ mb: -2.25 }}>
            <Typography variant="h5">Current Month</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Sell amount"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain
                      .currentMonthOutletTotalAmt
                  : 0
              }
              percentage={getPercentage(
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain
                      .currentMonthOutletTotalAmt
                  : 0,
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain
                      .previousMonthOutletTotalAmt
                  : 0
              )}
              onClickFlag={false}
              isLoss={
                getPercentage(
                  Object.keys(distributorDashbourd.dashbourdMain).length != 0
                    ? distributorDashbourd.dashbourdMain
                        .currentMonthOutletTotalAmt
                    : 0,
                  Object.keys(distributorDashbourd.dashbourdMain).length != 0
                    ? distributorDashbourd.dashbourdMain
                        .previousMonthOutletTotalAmt
                    : 0
                ) < 0
                  ? true
                  : false
              }
              color="warning"
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain
                      .currentMonthOutletTotalAmt
                  : 0
              }
              msgRight=""
              msgLeft="Total outlet bill amount is = "
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Sell amount"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain
                      .currentMonthStakeHolderTotalAmt
                  : 0
              }
              percentage={getPercentage(
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain
                      .currentMonthStakeHolderTotalAmt
                  : 0,
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain
                      .previousMonthStakeHolderTotalAmt
                  : 0
              )}
              onClickFlag={false}
              isLoss
              color="warning"
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain
                      .currentMonthStakeHolderTotalAmt
                  : 0
              }
              msgRight=""
              msgLeft="Total stake holder bill amount is = "
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Unvisited Count"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.unvisitedOutletCount
                  : 0
              }
              // percentage={70.5}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.unvisitedOutletCount
                  : 0
              }
              onClick={toggle(
                "Unvisited outlets",
                endpoint.adminDashboard + "/get-unvisited-list",
                0
              )}
              msgRight=""
              msgLeft="Unvisited outlet count is = "
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Zero Order"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.zeroOrderOutletCount
                  : 0
              }
              onClick={toggle(
                "Zero Order",
                endpoint.adminDashboard + "/get-zero-order-list",
                0
              )}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.zeroOrderOutletCount
                  : 0
              }
              msgRight=""
              msgLeft="Zero order outlet count is = "
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Bill not generated"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.countOfOutletOrder
                  : 0
              }
              onClick={toggle(
                "Bill not generated",
                endpoint.outletOrderHeader +
                  "/get-bill-not-generated-outlet-order-list",
                1
              )}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.countOfOutletOrder
                  : 0
              }
              msgRight=""
              msgLeft="Zero order outlet count is = "
            />
          </Grid>

          <Grid item xs={12} sx={{ mb: -2.25 }}>
            <Typography variant="h5">Sales Executive Counts</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Total"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.countOfSalesExecutive
                  : 0
              }
              onClickFlag={false}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.countOfSalesExecutive
                  : 0
              }
              msgRight=""
              msgLeft="Sales executive count is = "
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Checked-in Count"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.checkedInCount
                  : 0
              }
              // percentage={70.5}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.checkedInCount
                  : 0
              }
              onClick={toggle(
                "Sales executive Checked-in List",
                endpoint.checkin + "/checked-in-list-by-distributor",
                2
              )}
              msgRight=""
              msgLeft="Checked-in count is = "
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title=" Beat not assign count"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.notAssignSalesTeamCount
                  : 0
              }
              // percentage={70.5}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.notAssignSalesTeamCount
                  : 0
              }
              onClick={toggle(
                "Sales executive Beat not assign List",
                endpoint.salesExecutive + "/not-assigned-sales-team",
                3
              )}
              msgRight=""
              msgLeft="Beat not assign count is = "
            />
          </Grid>

          <Grid item xs={12} sx={{ mb: -2.25 }}>
            <Typography variant="h5">GRN Count</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Remain to approved"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.approvedCount
                  : 0
              }
              // percentage={70.5}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.approvedCount
                  : 0
              }
              msgRight=""
              msgLeft="Approved count is = "
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Remain to credit note"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.checkedInCount
                  : 0
              }
              // percentage={70.5}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.checkedInCount
                  : 0
              }
              msgRight="----"
              msgLeft="Remain to credit note is = "
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: -2.25 }}>
            <Typography variant="h5">Order Count</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Pending"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.pendingCount
                  : 0
              }
              // percentage={70.5}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.pendingCount
                  : 0
              }
              msgRight=""
              msgLeft="Pending order count is = "
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Accepted"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.acceptOrderCount
                  : 0
              }
              // percentage={70.5}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.acceptOrderCount
                  : 0
              }
              msgRight=""
              msgLeft="Accepted order count is = "
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: -2.25 }}>
            <Typography variant="h5">Credit Note Amount</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Outlet Total"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.creditNoteTotalAmt
                  : 0
              }
              // percentage={70.5}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain.creditNoteTotalAmt
                  : 0
              }
              msgRight=""
              msgLeft="Total amount is = "
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Total Amount ( From me )"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain
                      .holderCreditNoteTotalAmtByFromStock
                  : 0
              }
              // percentage={70.5}
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain
                      .holderCreditNoteTotalAmtByFromStock
                  : 0
              }
              msgRight=""
              msgLeft="Total amount is = "
              onClick={toggle(
                "Outlet List",
                endpoint.creditNote +
                  "/by-date-and-outlet-wise?fromDate=&toDate=",
                4
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AnalyticEcommerce
              title="Total Amount ( To me )"
              count={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain
                      .holderCreditNoteTotalAmtByToStock
                  : 0
              }
              extra={
                Object.keys(distributorDashbourd.dashbourdMain).length != 0
                  ? distributorDashbourd.dashbourdMain
                      .holderCreditNoteTotalAmtByToStock
                  : 0
              }
              onClick={toggle(
                "Outlet List",
                endpoint.creaditNoteHeader +
                  "/by-date-and-outlet-wise?fromDate=&toDate=",
                5
              )}
              msgRight=""
              msgLeft="Total amount is = "
            />
          </Grid>
          <Grid
            item
            md={8}
            sx={{ display: { sm: "none", md: "block", lg: "none" } }}
          />

          {/* row 2 */}
          <Grid item xs={12} md={8} lg={8}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Unique Visitor</Typography>
              </Grid>
              <Grid item>
                {/* <Stack direction="row" alignItems="center" spacing={0}>
                  <Button
                    size="small"
                    onClick={() => setSlot("month")}
                    color={slot === "month" ? "primary" : "secondary"}
                    variant={slot === "month" ? "outlined" : "text"}
                  >
                    Month
                  </Button>
                  <Button
                    size="small"
                    onClick={() => setSlot("week")}
                    color={slot === "week" ? "primary" : "secondary"}
                    variant={slot === "week" ? "outlined" : "text"}
                  >
                    Week
                  </Button>
                </Stack> */}
              </Grid>
            </Grid>
            <MainCard content={false} sx={{ mt: 1.5 }}>
              <Box sx={{ pt: 1, pr: 2 }}>
                {Object.keys(distributorDashbourd.dashbourdMain).length != 0 &&
                  distributorDashbourd.dashbourdMain.dtoBeatWiseTotalAmounts &&
                  distributorDashbourd.dashbourdMain.dtoBeatWiseTotalAmounts
                    .length != 0 && (
                    <MonthlyBarChart
                      series={[
                        {
                          data: distributorDashbourd.dashbourdMain.dtoBeatWiseTotalAmounts.map(
                            (beatObj) => {
                              return beatObj.beat != null
                                ? +beatObj.totalBillAmt.toFixed(2)
                                : "";
                            }
                          ),
                        },
                      ]}
                      optionsObj={{
                        chart: {
                          type: "bar",
                          height: 365,
                          toolbar: {
                            show: false,
                          },
                        },
                        plotOptions: {
                          bar: {
                            columnWidth: "45%",
                            borderRadius: 4,
                          },
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                          categories:
                            distributorDashbourd.dashbourdMain.dtoBeatWiseTotalAmounts.map(
                              (beatObj) => {
                                return beatObj.beat != null
                                  ? beatObj.beat.beatName
                                  : "";
                              }
                            ),
                          axisBorder: {
                            show: false,
                          },
                          axisTicks: {
                            show: false,
                          },
                          labels: {
                            style: {
                              colors: [
                                secondary,
                                secondary,
                                secondary,
                                secondary,
                                secondary,
                                secondary,
                                secondary,
                              ],
                            },
                          },
                        },
                        yaxis: {
                          show: false,
                        },
                        grid: {
                          show: false,
                        },
                        colors: [info],

                        tooltip: {
                          theme: "light",
                        },
                      }}
                    />
                  )}
              </Box>
            </MainCard>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">category wise outlet count</Typography>
              </Grid>
            </Grid>

            <MainCard content={false} sx={{ mt: 1.5 }}>
              <Box sx={{ pt: 1, pr: 2 }}>
                {Object.keys(distributorDashbourd.dashbourdMain).length != 0 &&
                  distributorDashbourd.dashbourdMain
                    .dtoOutletBillCategoryWiseTotalAmounts &&
                  distributorDashbourd.dashbourdMain
                    .dtoOutletBillCategoryWiseTotalAmounts.length != 0 && (
                    <PieChart
                      series={distributorDashbourd.dashbourdMain.dtoOutletBillCategoryWiseTotalAmounts.map(
                        (category) => {
                          return category.category != null
                            ? category.totalAmt
                            : "";
                        }
                      )}
                      options={{
                        chart: {
                          width: 380,
                          type: "pie",
                          events: {
                            dataPointSelection: (
                              event,
                              chartContext,
                              config
                            ) => {
                              onCLickPie(config.dataPointIndex);
                            },
                          },
                        },
                        labels:
                          distributorDashbourd.dashbourdMain.dtoOutletBillCategoryWiseTotalAmounts.map(
                            (category) => {
                              return category.category != null
                                ? category.category.name
                                : "";
                            }
                          ),
                        responsive: [
                          {
                            breakpoint: 480,
                            options: {
                              chart: {
                                width: 300,
                              },
                              legend: {
                                position: "bottom",
                              },
                            },
                          },
                        ],
                      }}
                    />
                  )}
              </Box>
            </MainCard>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">
                  category wise Stock holder count
                </Typography>
              </Grid>
            </Grid>

            <MainCard content={false} sx={{ mt: 1.5 }}>
              <Box sx={{ pt: 1, pr: 2 }}>
                {Object.keys(distributorDashbourd.dashbourdMain).length != 0 &&
                  distributorDashbourd.dashbourdMain
                    .dtoStockStakeHolderBillCategoryWiseTotalAmounts &&
                  distributorDashbourd.dashbourdMain
                    .dtoStockStakeHolderBillCategoryWiseTotalAmounts.length !=
                    0 && (
                    <PieChart
                      series={distributorDashbourd.dashbourdMain.dtoStockStakeHolderBillCategoryWiseTotalAmounts.map(
                        (category) => {
                          return category.category != null
                            ? category.totalAmt
                            : "";
                        }
                      )}
                      options={{
                        chart: {
                          width: 380,
                          type: "pie",
                        },
                        labels:
                          distributorDashbourd.dashbourdMain.dtoStockStakeHolderBillCategoryWiseTotalAmounts.map(
                            (category) => {
                              return category.category != null
                                ? category.category.name
                                : "";
                            }
                          ),
                        responsive: [
                          {
                            breakpoint: 480,
                            options: {
                              chart: {
                                width: 300,
                              },
                              legend: {
                                position: "bottom",
                              },
                            },
                          },
                        ],
                      }}
                    />
                  )}
              </Box>
            </MainCard>
          </Grid>

          <Grid item xs={12} md={7} lg={8}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Recent Orders</Typography>
              </Grid>
              <Grid item />
            </Grid>
            <MainCard sx={{ mt: 2 }} content={false}>
              <OrdersTable />
            </MainCard>
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Analytics Report</Typography>
              </Grid>
              <Grid item />
            </Grid>
            <MainCard sx={{ mt: 2 }} content={false}>
              <List sx={{ p: 0, "& .MuiListItemButton-root": { py: 2 } }}>
                <ListItemButton divider>
                  <ListItemText primary="Company Finance Growth" />
                  <Typography variant="h5">+45.14%</Typography>
                </ListItemButton>
                <ListItemButton divider>
                  <ListItemText primary="Company Expenses Ratio" />
                  <Typography variant="h5">0.58%</Typography>
                </ListItemButton>
                <ListItemButton>
                  <ListItemText primary="Business Risk Cases" />
                  <Typography variant="h5">Low</Typography>
                </ListItemButton>
              </List>
              <ReportAreaChart />
            </MainCard>
          </Grid>

          {/* row 4 */}
          <Grid item xs={12} md={7} lg={8}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Sales Report</Typography>
              </Grid>
              <Grid item>
                <TextField
                  id="standard-select-currency"
                  size="small"
                  select
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  sx={{
                    "& .MuiInputBase-input": { py: 0.5, fontSize: "0.875rem" },
                  }}
                >
                  {status.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <MainCard sx={{ mt: 1.75 }}>
              <Stack spacing={1.5} sx={{ mb: -12 }}>
                <Typography variant="h6" color="secondary">
                  Net Profit
                </Typography>
                <Typography variant="h4">$1560</Typography>
              </Stack>
              <SalesColumnChart />
            </MainCard>
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Transaction History</Typography>
              </Grid>
              <Grid item />
            </Grid>
            <MainCard sx={{ mt: 2 }} content={false}>
              <List
                component="nav"
                sx={{
                  px: 0,
                  py: 0,
                  "& .MuiListItemButton-root": {
                    py: 1.5,
                    "& .MuiAvatar-root": avatarSX,
                    "& .MuiListItemSecondaryAction-root": {
                      ...actionSX,
                      position: "relative",
                    },
                  },
                }}
              >
                <ListItemButton divider>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        color: "success.main",
                        bgcolor: "success.lighter",
                      }}
                    >
                      <GiftOutlined />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1">Order #002434</Typography>
                    }
                    secondary="Today, 2:00 AM"
                  />
                  <ListItemSecondaryAction>
                    <Stack alignItems="flex-end">
                      <Typography variant="subtitle1" noWrap>
                        + $1,430
                      </Typography>
                      <Typography variant="h6" color="secondary" noWrap>
                        78%
                      </Typography>
                    </Stack>
                  </ListItemSecondaryAction>
                </ListItemButton>
                <ListItemButton divider>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        color: "primary.main",
                        bgcolor: "primary.lighter",
                      }}
                    >
                      <MessageOutlined />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1">Order #984947</Typography>
                    }
                    secondary="5 August, 1:45 PM"
                  />
                  <ListItemSecondaryAction>
                    <Stack alignItems="flex-end">
                      <Typography variant="subtitle1" noWrap>
                        + $302
                      </Typography>
                      <Typography variant="h6" color="secondary" noWrap>
                        8%
                      </Typography>
                    </Stack>
                  </ListItemSecondaryAction>
                </ListItemButton>
                <ListItemButton>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        color: "error.main",
                        bgcolor: "error.lighter",
                      }}
                    >
                      <SettingOutlined />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1">Order #988784</Typography>
                    }
                    secondary="7 hours ago"
                  />
                  <ListItemSecondaryAction>
                    <Stack alignItems="flex-end">
                      <Typography variant="subtitle1" noWrap>
                        + $682
                      </Typography>
                      <Typography variant="h6" color="secondary" noWrap>
                        16%
                      </Typography>
                    </Stack>
                  </ListItemSecondaryAction>
                </ListItemButton>
              </List>
            </MainCard>
            <MainCard sx={{ mt: 2 }}>
              <Stack spacing={3}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Stack>
                      <Typography variant="h5" noWrap>
                        Help & Support Chat
                      </Typography>
                      <Typography variant="caption" color="secondary" noWrap>
                        Typical replay within 5 min
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item>
                    <AvatarGroup
                      sx={{ "& .MuiAvatar-root": { width: 32, height: 32 } }}
                    >
                      <Avatar alt="Remy Sharp" src={avatar1} />
                      <Avatar alt="Travis Howard" src={avatar2} />
                      <Avatar alt="Cindy Baker" src={avatar3} />
                      <Avatar alt="Agnes Walker" src={avatar4} />
                    </AvatarGroup>
                  </Grid>
                </Grid>
                <Button
                  size="small"
                  variant="contained"
                  sx={{ textTransform: "capitalize" }}
                >
                  Need Help?
                </Button>
              </Stack>
            </MainCard>
          </Grid>
        </Grid>
      </Paper>
      {openTable && (
        <TablePopUp
          columns={
            flagData == 1
              ? [
                  {
                    title: "Sr. No.",
                    name: "index",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Order Date",
                    name: "orderDate",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },

                  {
                    title: "Outlet Name",
                    name: "firmName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                  {
                    title: "Total Amount",
                    name: "totalAmt",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                  },
                ]
              : flagData == 2
              ? [
                  {
                    title: "Sr. No.",
                    name: "index",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Sales Person Name",
                    name: "salesTeamName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },

                  {
                    title: "Check In Time",
                    name: "checkInTime",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Check Out Time",
                    name: "checkOutTime",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                ]
              : flagData == 3
              ? [
                  {
                    title: "Sr. No.",
                    name: "index",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Sales Person Name",
                    name: "salesTeamName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },

                  {
                    title: "Contact Number",
                    name: "contactNo",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Email Id",
                    name: "emailId",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                ]
              : flagData == 4 || flagData == 5
              ? [
                  {
                    title: "Sr. No.",
                    name: "index",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },

                  {
                    title: "Outlet Name",
                    name: "firmName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                  {
                    title: "Owner Name",
                    name: "ownerName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                  {
                    title: "Amount",
                    name: "totalAmt",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                ]
              : [
                  {
                    title: "Sr. No.",
                    name: "index",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Beat Name",
                    name: "beatName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },

                  {
                    title: "Outlet Name",
                    name: "firmName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                  {
                    title: "Owner Name",
                    name: "ownerName",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                ]
          }
          open={openTable}
          handleCloses={toggle("", "", "")}
          rows={rows}
          title={title}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  distributorDashbourd: state.distributorDashbourd,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getDashBourdMain,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDefault);
