import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { AnnualTurnoverJson } from "../../../DynamicFormsJson/MastersJSON/annualTurnover";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
class AnnualTurnoverForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
      },
    };
  }

  render() {
    return (
      <>
        <DynamicForm
          formPath={AnnualTurnoverJson.formPath}
          screenTitle={AnnualTurnoverJson.screenTitle}
          fieldMeta={AnnualTurnoverJson.fieldMeta}
          apiBaseURL={AnnualTurnoverJson.apiBaseURL}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({

});
const mapDispatchToProps = {
  showLoador,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(AnnualTurnoverForm);
