import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  orderConfiguration: [],
  isFetch: false,
};
let URL = endpoints.orderConfiguration;
const orderConfigurationSlice = createSlice({
  name: "orderConfiguration",
  initialState,
  reducers: {
    orderConfigurationSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        orderConfiguration: row,
        isFetch: true,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { orderConfigurationSuccess, resetState } = orderConfigurationSlice.actions;

export default orderConfigurationSlice.reducer;

export const getOrderConfiguration = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertDateTime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((orderConfigurationObject, index) => {
          let orderConfigurationData = {
            index: index + 1,
            id: orderConfigurationObject.id === null ? "" : orderConfigurationObject.id,
            name:orderConfigurationObject.stockStakeHolderType !== null && orderConfigurationObject.stockStakeHolderType.name !== null ? orderConfigurationObject.stockStakeHolderType.name:"",
            orderToName:
            orderConfigurationObject.orderToName === null ? "" : orderConfigurationObject.orderToName,
            
          };
          return orderConfigurationData;
        });
        dispatch(orderConfigurationSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

