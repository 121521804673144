import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
    typeOfBusiness: [],
};
let URL = endpoints.typeOfBusiness;
const typeOfBusinessSlice = createSlice({
    name: "typeOfBusiness",
    initialState,
    reducers: {
        typeOfBusinessSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                typeOfBusiness: row,

            };
        },

        resetState: (state = cloneDeep(initialState), action) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const { typeOfBusinessSuccess, resetState } = typeOfBusinessSlice.actions;

export default typeOfBusinessSlice.reducer;

export const getTypeOfBusiness = () => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL,
        }).then(({ data, success }) => {
            if (success) {
                let index = 0;
                const { content } = data.data;
                const row = content.map((typeOfBusinessObject, index) => {
                    let typeOfBusinessData = {
                        index: index + 1,
                        id: typeOfBusinessObject.id === null ? "" : typeOfBusinessObject.id,
                        name: typeOfBusinessObject.name === null ? "" : typeOfBusinessObject.name,
                        isActive: typeOfBusinessObject.isActive === null ? "" : typeOfBusinessObject.isActive,
                    };
                    return typeOfBusinessData;
                });
                dispatch(typeOfBusinessSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};
