import endpoint from "../../config/endpoints";

export const showMyBillListJson = {
  showSaveNextBtn: true,
  showAddButton: true,
  apiBaseURL: endpoint.area,
  screenTitle: " Purchase Bill",
  formPathView: "/show-Bill-List-view",

  searchList: [
    {
      label: "Type",
      controlType: "radiogroup",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelXS: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueXS: 12,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "flag",
      tableDataKey: "showBill",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Show Bill",
          value: "0",
        },
        {
          label: "Sync Bill",
          value: "1",
        },
      ],
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromdate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "todate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "todate",
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
    },
  ],

  searchListSimple: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromdate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "todate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "todate",
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
    },
  ],
};
