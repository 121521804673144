import endpoint from "../../config/endpoints";

export const DistributionReportsJson = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.area,
  screenTitle: "Reports",
  fieldMeta: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      parameterValue:1
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      isMandatory: true,
      parameterValue:2
    },
    {
      label: "Area (Multiple)",
      controlType: "multiSelect",
      placeHolder: "Select Area (Multiple)",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "areaIds",
      isRootLevelKey: true,
      isMandatory: true,
      parameterValue:3
    } 
  ]
};
