import { Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { openingTransactionJson } from "../../DynamicFormsJson/Transaction/openingTransaction";
import { getAreaByUserType } from "../../Slice/area.slice";
import { getStakeHolderNameByStakeholderType } from "../../Slice/rateConfigurationEditingForStockholder.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
class OpeningForStockHolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        areaDetail: this.props.areaList.area,
        stakeHolderTypeDetail:
          this.props.stakeHolderTypeList?.stockStakeHolderType,
        stakeHolderName: this.props.stakeholderList?.stackholderName,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.area !== nextProps.areaList?.area) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.area,
          },
        });
      }
    }
    if (this.props.stakeHolderTypeList !== nextProps.stakeHolderTypeList) {
      if (
        this.props.stakeHolderTypeList?.stockStakeHolderType !==
        nextProps.stakeHolderTypeList?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeHolderTypeDetail:
              nextProps.stakeHolderTypeList?.stockStakeHolderType,
          },
        });
      }
    }
    if (this.props.stakeholderList !== nextProps.stakeholderList) {
      if (
        this.props.stakeholderList?.stackholderName !==
        nextProps.stakeholderList?.stackholderName
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeHolderName: nextProps.stakeholderList?.stackholderName,
          },
        });
      }
    }
  }
  async componentDidMount() {
    const {
      showLoador,
      showNotification,
      getAreaByUserType,
      getStockStakeHolderType,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getAreaByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getStockStakeHolderType({ userTypeIds: 2 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  }
  getListById = async (data) => {
    const {
      showNotification,
      showLoador,
      getStakeHolderNameByStakeholderType,
    } = this.props;
    if (navigator.onLine) {
      if (data.stockStakeHolderType && data.stockStakeHolderType.id) {
        showLoador({ loador: true });
        await getStakeHolderNameByStakeholderType({
          stockStakeHolderTypeIds: data.stockStakeHolderType.id,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      } else if (data.stockStakeHolder && data.stockStakeHolder.id) {
        this.setState({ stakeHolderId: data.stockStakeHolder.id });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onSearch = (data) => {
    let dataToSave;
    dataToSave = {
      transactionId: "0",
      toStockStakeHolder: data.stockStakeHolder.id,
      totalAmt: data.amount,
      remark: "NA",
    };
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onSave(dataToSave);
      }
    });
  };

  onSave = (dataToSave) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.transaction + "/opening",
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg });
          window.location.reload();
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            screenTitle={openingTransactionJson.screenTitle}
            fieldMeta={openingTransactionJson.fieldMeta}
            apiBaseURL={openingTransactionJson.apiBaseURL}
            getListById={this.getListById}
            dynamicMasterData={dynamicMasterData}
            showSaveBtnMain={false}
            showSaveBtn={true}
            saveBtnText={"Submit"}
            onSave={this.onSearch}
          />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  areaList: state.area,
  stakeHolderTypeList: state.stockStakeHolderType,
  stakeholderList: state.stockHolderAndProductConfiguration,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  getAreaByUserType,
  getStockStakeHolderType,
  getStakeHolderNameByStakeholderType,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpeningForStockHolder);
