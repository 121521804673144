import { compose } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import {
    getOrderCountsForDistributor,
} from "../../Slice/functionalDashboardDistributor.slice";
import {
    getPrimaryOrderForDistributor
} from "../../Slice/OrderHeaderList.slice";
import "../../CSS/app.min.css";
import "../../CSS/bootstrap.min.css";
import "../../CSS/custom.min.css";
import "../../CSS/mermaid.min.css";
import "../../CSS/slickDashboard.css";
import image4 from "../../Images/image4.png";
import image5 from "../../Images/image5.png";
import image6 from "../../Images/image6.png";
import image7BgClr from "../../Images/image7BgClr.png";
import {
    showLoador,
    showNotification,
} from "../../Pages/Landing/Landing.slice";

import { dashboardJson } from "../../DynamicFormsJson/Transaction/Dashboard";
import {
    getPrimaryLatestOrderByFilter,
    getPrimaryLatestOrder
} from "../../Slice/OrderHeaderList.slice";
import {
    getPrimaryLatestBillByFilter,
     
    getPrimaryLatestBill
} from "../../Slice/orderBillList.slice";
import { getSecondaryLatestBillByFilter ,getPaymentReceiptData,getSecondaryBillHistory,getPendingOrderForDistributor,getPendingOrderForDistributorByFilter,getSecondaryPaymentDateWise,getPaymentReceiptDataByFilter} from "../../Slice/functionalDashboardDistributor.slice"
import { ConvertAmount } from "../../components/Comman/ConvertValueWithDenominator";
import SearchPopUp from "../../components/dynamicscreens/SearchPopUp";
import PrimaryOrder from "./PrimaryOrder";
import SecondaryOrder from "./SecondaryOrder";
import { getOutletByUserType,getOutletByBeatId } from "../../Slice/outlet.slice";
import { getBeatByUserType } from "../../Slice/beat.slice";
import { getSalesExecutiveByArea } from "../../Slice/salesExecutive.slice";
class FunctionalDashboardDistributor extends Component {

    state = {
        dynamicMasterData: {
            SalesExecutiveDetails: this.props.salesExecutiveList.salesExecutive,
            outletDetail: this.props.outletList.outletByUserType,
            outletDetailByBeat: this.props.outletList.outlets,
            beatDetail: this.props.beatList?.beatByUserType,
            statusList: [
                {
                    id: 0,
                    name: "Pending"
                },
                {
                    id: 1,
                    name: "Sent"
                },
                {
                    id: 2,
                    name: "Accept Order"
                },
                {
                    id: 3,
                    name: "Generate Bill"
                },
                {
                    id: 4,
                    name: "Reject Order"
                }
            ],
            statusListForBill: [
                {
                    id: 0,
                    name: "Pending"
                },

                {
                    id: 4,
                    name: "Reject Order"
                }
            ],
        },
        fieldDataForSearch: {},
        openTab: 1,
        showDashboard: true,
        openFilter: false,
        flag: ""
    };
    componentWillReceiveProps(nextProps) {

        if (this.props.outletList !== nextProps.outletList) {
            if (this.props.outletList?.outletByUserType !== nextProps.outletList?.outletByUserType) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        outletDetail: nextProps.outletList?.outletByUserType,
                    },
                });
            }
            if (this.props.outletList?.outlets !== nextProps.outletList?.outlets) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        outletDetailByBeat: nextProps.outletList?.outlets,
                    },
                });
            }
        }
        if (this.props.salesExecutiveList !== nextProps.salesExecutiveList) {
            if (
              this.props.salesExecutiveList?.salesExecutive !==
              nextProps.salesExecutiveList?.salesExecutive
            ) {
              this.setState({
                dynamicMasterData: {
                  ...this.state.dynamicMasterData,
                  SalesExecutiveDetails: nextProps.salesExecutiveList?.salesExecutive,
                },
              });
            }
          }
        if (this.props.beatList !== nextProps.beatList) {
            if (
              this.props.beatList?.beatByUserType !==
              nextProps.beatList?.beatByUserType
            ) {
              this.setState({
                dynamicMasterData: {
                  ...this.state.dynamicMasterData,
                  beatDetail: nextProps.beatList?.beatByUserType,
                },
              });
            }
          }
    }
    async componentDidMount() {
        const {
            showNotification,
            getOrderCountsForDistributor,
            getPrimaryOrderForDistributor,
            showLoador,
        } = this.props;
        if (navigator.onLine) {

            showLoador({ loador: true });
            await getOrderCountsForDistributor().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
            showLoador({ loador: true });
            await getPrimaryOrderForDistributor().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }
    setShowDashbourd = (value) => {
        this.setState({
            showDashboard: value
        })
    }
    onClickTab = (value) => {
        this.setState({
            openTab: value
        })
    }
    getListById=async(data)=>{
        const {getOutletByBeatId,showLoador,showNotification}=this.props
if (data.beat) {
    showLoador({ loador: true });
    await getOutletByBeatId({
      beatId: data.beat.id,
    }).then(({ response, success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      } else {
      }
    });
  }
    }
    onDataChangeForSearch = (fieldName, newValue) => {
        const { fieldDataForSearch, flag } = this.state;
        const dataToSearch = {};
        const dataToReset = { ...this.state.fieldDataForSearch };
        let listError = false;
        let searchList =flag == 1 ? dashboardJson.primaryOrderLatestList :
        flag == 2 && fieldDataForSearch.flag == 0 ? dashboardJson.secondaryOrderBillListOutletWise :
            flag == 2 && fieldDataForSearch.flag != 0 ? dashboardJson.secondaryOrderBillList :
                flag == 3 || flag == 5||flag == 6? dashboardJson.primaryOrderBillList :
                   flag == 4&&fieldDataForSearch.flag == 0? dashboardJson.secondaryOrderOutletWiseList
                   :flag == 4&&fieldDataForSearch.flag != 0? dashboardJson.secondaryOrderSalesExecutiveWiseList:[]
      
        searchList.map((currentField) => {
            if (currentField.getDate && currentField.dataKey === fieldName) {
                dataToReset[currentField.getDate] = newValue.add(365, "day");
            }
            if (currentField.dataKey === fieldName && currentField.getListId) {
                // dataToSearch[fieldName] = newValue;
                const masterKeyList = currentField.getListId.split(",");
                masterKeyList.map((key) => {
                    if (fieldName === key) {
                        dataToSearch[key] = newValue;
                    } else if (fieldDataForSearch[key]) {
                        dataToSearch[key] = fieldDataForSearch[key];
                    }
                    return null;
                });

                this.getListById(dataToSearch);
            }
            if (currentField.getListFrom) {
                const masterKeyList = currentField.getListFrom.split(",");
                masterKeyList.map((key) => {
                    if (key == fieldName && dataToReset[key]) {
                        delete dataToReset[currentField.dataKey];
                    }
                });
            }
            if (
                !listError &&
                fieldDataForSearch[currentField.dataKey] &&
                currentField.controlType != "datepicker" &&
                currentField.getListFrom == undefined
            ) {
                dataToReset[currentField.dataKey] =
                    fieldDataForSearch[currentField.dataKey];
            }
            return null;
        });

        dataToReset[fieldName] = newValue;
        this.setState({
            fieldDataForSearch: dataToReset,
        });
    };
    setFromDataForSearch = () => {
        const { fieldDataForSearch, flag } = this.state;
        const { today } = this.props;
        const dataToSave = {};
        let searchList = flag == 1 ? dashboardJson.primaryOrderLatestList :
        flag == 2 && fieldDataForSearch.flag == 0 ? dashboardJson.secondaryOrderBillListOutletWise :
            flag == 2 && fieldDataForSearch.flag != 0 ? dashboardJson.secondaryOrderBillList :
                flag == 3 || flag == 5||flag == 6? dashboardJson.primaryOrderBillList :
                   flag == 4&&fieldDataForSearch.flag == 0? dashboardJson.secondaryOrderOutletWiseList
                   :flag == 4&&fieldDataForSearch.flag != 0? dashboardJson.secondaryOrderSalesExecutiveWiseList:[]
        searchList.forEach((currentField) => {
            if (currentField.controlType === "datepicker") {
                if (fieldDataForSearch[currentField.dataKey]) {
                    if (!today) {
                        dataToSave[currentField.dataKey] = fieldDataForSearch[
                            currentField.dataKey
                        ]
                            ? fieldDataForSearch[currentField.dataKey].format("DD-MM-YYYY")
                            : "";
                    } else {
                        dataToSave[currentField.dataKey] =
                            fieldDataForSearch[currentField.dataKey] !== ""
                                ? fieldDataForSearch[currentField.dataKey].format("DD-MM-YYYY")
                                : dayjs().format("DD-MM-YYYY");
                    }
                } else {
                    if (!today) {
                        dataToSave[currentField.dataKey] = "";
                    } else {
                        dataToSave[currentField.dataKey] = dayjs().format("DD-MM-YYYY");
                    }
                }
            } else {
                dataToSave[currentField.dataKey] = fieldDataForSearch[
                    currentField.dataKey
                ] != undefined
                    ? fieldDataForSearch[currentField.dataKey]
                    : "";
            }
        });
        return dataToSave;
    };
    onOpenFilter = async (flag) => {
        const {
            showNotification,
            showLoador,
            getOutletByUserType,
            getBeatByUserType,
            getSalesExecutiveByArea
        } = this.props;
        this.setState({
            flag: flag ? flag : "",
            openFilter: !this.state.openFilter
        })
        if (flag == 2) {
            this.setState({
                fieldDataForSearch: {
                    flag: 1
                }
            })
            showLoador({ loador: true });
            await getOutletByUserType().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
          
        }
        if (flag == 4) {
            this.setState({
                fieldDataForSearch: {
                    flag: 1
                }
            })   
            showLoador({ loador: true });
      await getSalesExecutiveByArea({
        userTypeId: 4,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
            showLoador({ loador: true });
            await getBeatByUserType().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });
        }
        
        
    };
    onFormSearch = async () => {
        const dataToSave = await this.setFromDataForSearch();
        // localStorage.setItem("formData", JSON.stringify(dataToSave));

        const {
            showNotification,
            showLoador,getPaymentReceiptDataByFilter,
            getSecondaryPaymentDateWise,
            getPrimaryLatestOrderByFilter,getPendingOrderForDistributorByFilter, getSecondaryLatestBillByFilter, getPrimaryLatestBillByFilter
        } = this.props;
        const { flag } = this.state
        this.onOpenFilter(flag)
        if (navigator.onLine) {
            if (flag == 1) {
                showLoador({ loador: true });
                await getPrimaryLatestOrderByFilter({ fromDate: dataToSave.fromdate, toDate: dataToSave.todate, status: dataToSave.status }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            } else if (flag == 2) {
                showLoador({ loador: true });
                await getSecondaryLatestBillByFilter({ fromDate: dataToSave.flag == 0 ? dataToSave.fromdate : "", toDate: dataToSave.flag == 0 ? dataToSave.todate : "", status: dataToSave.flag == 0 ? dataToSave.status : "", outletIds: dataToSave.flag == 0 ? dataToSave.outletIds : "", billNo: dataToSave.flag != 0 ? dataToSave.billNo : "", flag: dataToSave.flag }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            else if (flag == 3) {
                showLoador({ loador: true });
                await getPrimaryLatestBillByFilter({ fromDate: dataToSave.fromdate, toDate: dataToSave.todate }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            else if (flag == 4) {
                showLoador({ loador: true });
                await getPendingOrderForDistributorByFilter({ outletIds:dataToSave.flag==0? dataToSave.outletIds:"", salesTeamIds:dataToSave.flag!=0? dataToSave.salesExecutiveIds:"" ,flag:dataToSave.flag}).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            else if (flag == 5) {
                showLoador({ loador: true });
                await getPaymentReceiptDataByFilter({  fromDate: dataToSave.fromdate, toDate: dataToSave.todate}).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            else if (flag == 6) {
                showLoador({ loador: true });
                await getSecondaryPaymentDateWise({  fromDate: dataToSave.fromdate, toDate: dataToSave.todate}).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };
    
    onClear = async () => {
      
        const {
            showNotification,
            showLoador,
            getPaymentReceiptData,
            
            getSecondaryPaymentDateWise,
            getPrimaryLatestOrder,
            getPendingOrderForDistributor,
             getSecondaryBillHistory, 
             getPrimaryLatestBill
        } = this.props;
        const { flag } = this.state
        this.setState({
            fieldDataForSearch:{}
        })
        this.onOpenFilter()
        if (navigator.onLine) {
            if (flag == 1) {
                showLoador({ loador: true });
                await getPrimaryLatestOrder().then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            } else if (flag == 2) {
                showLoador({ loador: true });
                await getSecondaryBillHistory().then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            else if (flag == 3) {
                showLoador({ loador: true });
                await getPrimaryLatestBill().then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            else if (flag == 4) {
                showLoador({ loador: true });
                await getPendingOrderForDistributor().then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            else if (flag == 5) {
                showLoador({ loador: true });
                await getPaymentReceiptData().then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            else if (flag == 6) {
                showLoador({ loador: true });
                await getSecondaryPaymentDateWise({  fromDate:"", toDate:""}).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
      

    };
    render() {
        const {
            dashboardData,
            orderHeaderList,
            functionalDashboardDistributorData,
        } = this.props;
        const {
            dynamicMasterData,
            fieldDataForSearch,
            openFilter,
            flag,
            openTab,
            showDashboard,

        } = this.state;

        return (
            <>

                <>
                    <div
                        id="layout-wrapper"
                        style={{
                            backgroundColor: "#fff",
                        }}
                    >
                        {/* ========== App Menu ========== */}

                        {/* Left Sidebar End */}
                        {/* Vertical Overlay*/}
                        <div className="vertical-overlay" />
                        <div className="main-content">
                            <div className="page-content">
                                <div className="container-fluid">
                                    {showDashboard && (<div
                                        className="row"
                                        style={{
                                            gap: "27px",
                                            paddingBottom: 10,
                                            padding: "12px",
                                        }}
                                    >
                                        {/* Card 1 */}
                                        <div
                                            onClick={() => {
                                                this.onClickTab(1)
                                            }}
                                            className="Frame427319159"
                                            style={{
                                                width: "23.5%",
                                                height: "auto",
                                                paddingTop: 22,
                                                paddingBottom: 22,
                                                background:
                                                    openTab == 1
                                                        ? "#9dffffd1"
                                                        : "rgba(253, 126, 20, 0.10)",
                                                borderRadius: 8,
                                                overflow: "hidden",
                                                border:
                                                    openTab == 1 ? "1px solid" : "",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "inline-flex",
                                            }}
                                        >
                                            <div
                                                className="Frame11262"
                                                style={{
                                                    flex: "1 1 0",
                                                    alignSelf: "stretch",
                                                    paddingLeft: 16,
                                                    paddingRight: 16,
                                                    justifyContent: "space-between",
                                                    alignItems: "flex-start",
                                                    display: "inline-flex",
                                                }}
                                            >
                                                <div
                                                    className="Frame427319153"
                                                    style={{
                                                        width: "60%",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "flex-start",
                                                        gap: 8,
                                                        display: "inline-flex",
                                                    }}
                                                >
                                                    <img
                                                        className="Image4"
                                                        style={{ width: 32, height: 32 }}
                                                        src={image4}
                                                    />

                                                    <div
                                                        className="TotalSales"
                                                        style={{
                                                            color: "black",
                                                            fontSize: 22,
                                                            fontFamily: "Poppins",
                                                            fontWeight: "400",
                                                            wordWrap: "break-word",
                                                            top: 5,
                                                        }}
                                                    >
                                                        Secondory Order{" "}
                                                    </div>
                                                </div>
                                                <div
                                                    className="Frame427319116"
                                                    style={{
                                                        // alignSelf: "stretch",
                                                        flexDirection: "column",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        display: "inline-flex",
                                                    }}
                                                >
                                                    <div
                                                        className="Cr"
                                                        style={{
                                                            color: "black",
                                                            fontSize: 28,
                                                            fontFamily: "Poppins",
                                                            fontWeight: "500",
                                                            textTransform: "capitalize",
                                                            lineHeight: 1.8,
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        {ConvertAmount({
                                                            amount:
                                                                functionalDashboardDistributorData
                                                                    ?.orderCounts.closedOrderAmt,
                                                        })}
                                                    </div>
                                                    <div
                                                        className="Frame427319115"
                                                        style={{
                                                            justifyContent: "flex-end",
                                                            alignItems: "center",
                                                            gap: 2,
                                                            display: "inline-flex",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                color: "#43C622",
                                                                fontSize: 16,
                                                                fontFamily: "Poppins",
                                                                fontWeight: "400",
                                                                textTransform: "capitalize",
                                                                wordWrap: "break-word",
                                                            }}
                                                        >
                                                            LOST ORDER
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Card 1  end*/}

                                        {/* Card 2 */}

                                        <div
                                            onClick={() => {
                                                this.onClickTab(2)
                                            }}
                                            className="Frame427319162"
                                            style={{
                                                width: "23.5%",
                                                height: "auto",
                                                paddingTop: 22,
                                                paddingBottom: 22,
                                                background:
                                                    openTab == 2
                                                        ? "#9dffffd1"
                                                        : "rgba(253, 126, 20, 0.10)",
                                                borderRadius: 8,
                                                border:
                                                    openTab == 2 ? "1px solid" : "",
                                                overflow: "hidden",
                                                // border: "1px solid",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "inline-flex",
                                            }}
                                        >
                                            <div

                                                className="Frame11262"
                                                style={{
                                                    flex: "1 1 0",
                                                    alignSelf: "stretch",
                                                    paddingLeft: 16,
                                                    paddingRight: 16,
                                                    justifyContent: "space-between",
                                                    alignItems: "flex-start",
                                                    display: "inline-flex",
                                                }}
                                            >
                                                <div
                                                    className="Frame427319153"
                                                    style={{
                                                        width: "60%",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "flex-start",
                                                        gap: 8,
                                                        display: "inline-flex",
                                                    }}
                                                >
                                                    <img
                                                        className="Image5"
                                                        style={{ width: 32, height: 32 }}
                                                        src={image5}
                                                    />
                                                    <div
                                                        className="TotalSales"
                                                        style={{
                                                            color: "black",
                                                            fontSize: 22,
                                                            fontFamily: "Poppins",
                                                            fontWeight: "400",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        Primary Order
                                                    </div>
                                                </div>
                                                <div
                                                    className="Frame427319116"
                                                    style={{
                                                        // alignSelf: "stretch",
                                                        flexDirection: "column",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        display: "inline-flex",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            color: "black",
                                                            fontSize: 28,
                                                            fontFamily: "Poppins",
                                                            fontWeight: "500",
                                                            textTransform: "capitalize",
                                                            lineHeight: 1.8,
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        {ConvertAmount({
                                                            amount: Object.keys(orderHeaderList?.primaryOrder).length != 0 ?
                                                                orderHeaderList.primaryOrder.orderAmt : 0
                                                        })} /  {ConvertAmount({
                                                            amount: Object.keys(orderHeaderList?.primaryOrder).length != 0 ?
                                                                orderHeaderList.primaryOrder.notRecievedBillAmt : 0
                                                        })}
                                                    </div>
                                                    <div
                                                        className="Frame427319115"
                                                        style={{
                                                            justifyContent: "flex-end",
                                                            alignItems: "center",
                                                            gap: 2,
                                                            display: "inline-flex",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                color: "#43C622",
                                                                fontSize: 16,
                                                                fontFamily: "Poppins",
                                                                fontWeight: "400",
                                                                textTransform: "capitalize",
                                                                wordWrap: "break-word",
                                                            }}
                                                        >
                                                            ORD AMT BILL NOT GENERATED AMT / BILL NOT RECEIVED AMT
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Card 2 end */}

                                        {/* Card 3 */}

                                        <div
                                            onClick={() => {
                                                this.onClickTab(3)
                                            }}
                                            className="Frame427319160"
                                            style={{
                                                width: "23.5%",
                                                height: "auto",
                                                paddingTop: 22,
                                                paddingBottom: 22,
                                                background: openTab == 3
                                                    ? "#9dffffd1"
                                                    : "rgba(253, 126, 20, 0.10)",
                                                borderRadius: 8,
                                                border:
                                                    openTab == 3 ? "1px solid" : "",
                                                overflow: "hidden",
                                                // border: "1px solid",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "inline-flex",
                                            }}
                                        >
                                            <div
                                                className="Frame11262"
                                                style={{
                                                    flex: "1 1 0",
                                                    alignSelf: "stretch",
                                                    paddingLeft: 16,
                                                    paddingRight: 16,
                                                    justifyContent: "space-between",
                                                    alignItems: "flex-start",
                                                    display: "inline-flex",
                                                }}
                                            >
                                                <div
                                                    className="Frame427319153"
                                                    style={{
                                                        width: "60%",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "flex-start",
                                                        gap: 8,
                                                        display: "inline-flex",
                                                    }}
                                                >
                                                    <img
                                                        className="Image5"
                                                        style={{ width: 32, height: 32 }}
                                                        src={image6}
                                                    />
                                                    <div
                                                        className="TotalSales"
                                                        style={{
                                                            color: "black",
                                                            fontSize: 22,
                                                            fontFamily: "Poppins",
                                                            fontWeight: "400",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        Stock Management
                                                    </div>
                                                </div>
                                                <div
                                                    className="Frame427319116"
                                                    style={{
                                                        // alignSelf: "stretch",
                                                        flexDirection: "column",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        display: "inline-flex",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            color: "black",
                                                            fontSize: 28,
                                                            fontFamily: "Poppins",
                                                            fontWeight: "500",
                                                            textTransform: "capitalize",
                                                            lineHeight: 1.8,
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        {
                                                            +dashboardData?.currentYearTarget
                                                                .totalStakeHolderCount
                                                        }
                                                    </div>
                                                    <div
                                                        className="Frame427319115"
                                                        style={{
                                                            justifyContent: "flex-end",
                                                            alignItems: "center",
                                                            gap: 2,
                                                            display: "inline-flex",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                color: "#43C622",
                                                                fontSize: 16,
                                                                fontFamily: "Poppins",
                                                                fontWeight: "400",
                                                                textTransform: "capitalize",
                                                                wordWrap: "break-word",
                                                            }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Card 3 end */}

                                        {/* Card 4 */}
                                        <div
                                            onClick={() => {
                                                this.onClickTab(4)
                                            }}
                                            className="Frame427319161"
                                            style={{
                                                width: "23.5%",
                                                height: "auto",
                                                paddingTop: 22,
                                                paddingBottom: 22,
                                                background: openTab == 4
                                                    ? "#9dffffd1"
                                                    : "rgba(253, 126, 20, 0.10)",
                                                borderRadius: 8,
                                                border:
                                                    openTab == 4 ? "1px solid" : "",
                                                overflow: "hidden",
                                                // border: "1px solid",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "inline-flex",
                                            }}
                                        >
                                            <div
                                                className="Frame11262"
                                                style={{
                                                    flex: "1 1 0",
                                                    alignSelf: "stretch",
                                                    paddingLeft: 16,
                                                    paddingRight: 16,
                                                    justifyContent: "space-between",
                                                    alignItems: "flex-start",
                                                    display: "inline-flex",
                                                }}
                                            >
                                                <div
                                                    className="Frame427319153"
                                                    style={{
                                                        width: "60%",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "flex-start",
                                                        gap: 8,
                                                        display: "inline-flex",
                                                    }}
                                                >
                                                    <img
                                                        className="Image7"
                                                        style={{ width: 32, height: 32 }}
                                                        src={image7BgClr}
                                                    />
                                                    <div
                                                        className="TotalSales"
                                                        style={{
                                                            color: "black",
                                                            fontSize: 22,
                                                            fontFamily: "Poppins",
                                                            fontWeight: "400",
                                                            wordWrap: "break-word",
                                                        }}
                                                    >
                                                        Notice And Messages
                                                    </div>
                                                </div>
                                                <div
                                                    className="Frame427319116"
                                                    style={{
                                                        // alignSelf: "stretch",
                                                        flexDirection: "column",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        display: "inline-flex",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            color: "black",
                                                            fontSize: 28,
                                                            fontFamily: "Poppins",
                                                            fontWeight: "500",
                                                            textTransform: "capitalize",
                                                            lineHeight: 1.8,
                                                            wordWrap: "break-word",
                                                        }}
                                                    >

                                                        {
                                                            +dashboardData?.currentYearTarget
                                                                .totalSalesTeamCount
                                                        }
                                                    </div>
                                                    <div
                                                        className="Frame427319115"
                                                        style={{
                                                            justifyContent: "flex-end",
                                                            alignItems: "center",
                                                            gap: 2,
                                                            display: "inline-flex",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                color: "#43C622",
                                                                fontSize: 16,
                                                                fontFamily: "Poppins",
                                                                fontWeight: "400",
                                                                textTransform: "capitalize",
                                                                wordWrap: "break-word",
                                                            }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Card 4 end */}
                                    </div>

                                    )}
                                    {openTab == 1 ?
                                        <SecondaryOrder onOpenFilter={this.onOpenFilter} setShowDashbourd={this.setShowDashbourd} /> :
                                        openTab == 2 ?
                                            <PrimaryOrder onOpenFilter={this.onOpenFilter} /> :
                                            openTab == 3 ?
                                                <SecondaryOrder setShowDashbourd={this.setShowDashbourd} /> :
                                                <SecondaryOrder setShowDashbourd={this.setShowDashbourd} />
                                    }



                                </div>
                            </div>
                        </div>
                    </div>
                </>
                {
                    openFilter &&
                    <SearchPopUp
                        open={openFilter}
                        handleCloses={this.onOpenFilter}
                        title={"Filter"}
                        searchList={flag == 1 ? dashboardJson.primaryOrderLatestList :
                            flag == 2 && fieldDataForSearch.flag == 0 ? dashboardJson.secondaryOrderBillListOutletWise :
                                flag == 2 && fieldDataForSearch.flag != 0 ? dashboardJson.secondaryOrderBillList :
                                    flag == 3|| flag == 5||flag == 6 ? dashboardJson.primaryOrderBillList :
                                       flag == 4&&fieldDataForSearch.flag == 0? dashboardJson.secondaryOrderOutletWiseList
                                       :flag == 4&&fieldDataForSearch.flag != 0? dashboardJson.secondaryOrderSalesExecutiveWiseList:[]}
                        dynamicMasterData={dynamicMasterData}
                        fieldDataForSearch={fieldDataForSearch}
                        onDataChangeForSearch={this.onDataChangeForSearch}
                        onFormSearch={this.onFormSearch}
                        searchButton={true}
                        searchButtonGrid={true}
                        onClear={this.onClear}
                    />
                }

            </>
        );
    }
}

const mapStateToProps = (state) => ({
    dashboardData: state.adminDashboard,
    outletList: state.outlet,
    orderHeaderList: state.orderHeaderList,
    beatList: state.beat,
    functionalDashboardDistributorData: state.functionalDashboardDistributor,
    salesExecutiveList: state.salesExecutive,
});

const mapDispatchToProps = {
    showLoador,
    showNotification,
    getPrimaryLatestOrderByFilter,
    getPrimaryLatestOrder,
    getOrderCountsForDistributor,
    getPrimaryOrderForDistributor,
    getOutletByUserType,
    getOutletByBeatId,
    getPrimaryLatestBillByFilter,
    getSecondaryLatestBillByFilter,
    getPendingOrderForDistributorByFilter,
    getPendingOrderForDistributor,
    getBeatByUserType,
    getSalesExecutiveByArea,
    getPaymentReceiptDataByFilter,getSecondaryPaymentDateWise,
    getSecondaryBillHistory, 
    getPrimaryLatestBill,
    getPaymentReceiptData
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(FunctionalDashboardDistributor);