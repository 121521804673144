import endpoint from "../../config/endpoints";

export const historyOfCreditNoteJson = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.area,
  screenTitle: "Credit Note History",
  formPathView: "/history-of-credit-note-view",
  searchList: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      onSubmit: "compare",
      onSubmitParameter: "fromDate-ge",
      isMandatory: true,
      onSubmit: "compare",
    },
    // {
    //   label: "Region",
    //   controlType: "autocomplete",
    //   placeHolder: "Select Region",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   masterName: "regionDetail",
    //   dataKey: "region",
    //   getListId: "region",
    //   isRootLevelKey: true,
    //   isMandatory: true,
    // },
    // {
    //   label: "State",
    //   controlType: "autocomplete",
    //   placeHolder: "Select State",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   masterName: "stateDetail",
    //   dataKey: "state",
    //   getListFrom: "region",
    //   getListId: "state",
    //   isRootLevelKey: true,
    //   isMandatory: true,
    // },
    // {
    //   label: "Zone",
    //   controlType: "multiSelect",
    //   placeHolder: "Select Zone",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   masterName: "zoneDetail",
    //   dataKey: "zone",
    //   getListFrom: "state",
    //   getListId: "zone",
    //   isRootLevelKey: true,
    //   isMandatory: true,
    // },
    // {
    //   label: "District",
    //   controlType: "multiSelect",
    //   placeHolder: "Select District",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   masterName: "districtDetail",
    //   dataKey: "district",
    //   getListFrom: "zone",
    //   getListId: "district",
    //   isRootLevelKey: true,
    //   isMandatory: true,
    // },
    // {
    //   label: "Taluka",
    //   controlType: "multiSelect",
    //   placeHolder: "Select Taluka",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   masterName: "talukaDetail",
    //   dataKey: "taluka",
    //   getListFrom: "district",
    //   getListId: "taluka",
    //   isRootLevelKey: true,
    //   isMandatory: true,
    // },
    // {
    //   label: "Area",
    //   controlType: "multiSelect",
    //   placeHolder: "Select Area",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   masterName: "areaDetail",
    //   dataKey: "area",
    //   getListFrom: "taluka",
    //   getListId: "area",
    //   isRootLevelKey: true,
    //   isMandatory: true,
    // },
    // {
    //   label: "Stock Holder",
    //   controlType: "multiSelect",
    //   placeHolder: "Select Stock Holder",
    //   md: 6,
    //   lg: 6,
    //   sm: 6,
    //   xs: 12,
    //   masterName: "stakeholdersDetail",
    //   dataKey: "stackholder",
    //   getListFrom: "area",
    //   isRootLevelKey: true,
    //   isMandatory: true,
    // },
  ],
};
