import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import { regionAndTargetJson } from "../../DynamicFormsJson/Transaction/RegionAndTarget";
import { getYearForRegionAndTarget } from "../../Slice/regionAndTarget.slice";
import { getYear } from "../../Slice/year.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { titleColor, redColor } from "../../config/ColorObj";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import EditIcon from "@mui/icons-material/Edit";
class RegionAndTargetTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      year: "",
      formError: {},
      error: false,
      fieldDataForModule: {},
    };
  }

  async componentDidMount() {
    const { getYear, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getYear().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      const moduleObjData = localStorage.getItem("moduleObj");
      this.setState({
        fieldDataForModule:
          moduleObjData != null ? JSON.parse(moduleObjData) : {},
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {}

  checkValidation = () => {
    const { year } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (year === "" || year === null) {
      formIsValid = false;
      formErrors["yearError"] = "*Year required";
    }

    this.setState({
      formError: formErrors,
    });
    return formIsValid;
  };
  onSearchData = async () => {
    if (this.checkValidation()) {
      const { getYearForRegionAndTarget, showLoador, showNotification } =
        this.props;
      const { year } = this.state;
      if (year) {
        if (navigator.onLine) {
          showLoador({ loador: true });
          getYearForRegionAndTarget({
            yearId: year,
          }).then(({ response, success }) => {
            this.setState({
              targetId: response.id,
            });
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          });
        } else {
          showNotification({ msg: noInternetMsg, severity: "error" });
        }
      }
    }
  };
  onClickEdit = (e, row) => {
    this.props.navigate(regionAndTargetJson.formEditPath + "/" + row.id);
  };

  onAddButtonClick = () => {
    this.props.navigate(regionAndTargetJson.formPath);
  };
  onAutocompleteChange = (name, newValue) => {
    if (newValue != null) {
      this.setState({
        [name]: newValue,
      });
    } else {
      this.setState({
        [name]: "",
      });
    }
  };

  render() {
    const { regionAndTarget, yearList } = this.props;
    const { year, formError, fieldDataForModule } = this.state;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            screenTitle={regionAndTargetJson.screenTitle}
            showTable={false}
            showBackButton={false}
            showAddButton={
              fieldDataForModule.addApproveConfig == 1 ? true : false
            }
            onAddButtonClick={this.onAddButtonClick}
            // onClickEdit={this.onClickEdit}
          />
          <br />
          <Grid container spacing={1}>
            <Grid item xs={12} sm={1.8} md={1.8} lg={1.8}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label={"*"}
              />
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: titleColor }}
                label="Year : "
              />
            </Grid>

            <Grid item xs={12} sm={3.7} md={3.7} lg={3.7}>
              <AutoComplete
                keyColName={"id"}
                name={"year"}
                value={year}
                options={yearList?.year}
                placeHolder="Select Year"
                onChange={this.onAutocompleteChange}
                isError={formError.yearError ? true : false}
                errorText={formError.yearError ? formError.yearError : " "}
              />
            </Grid>
            <Grid container sm={1} xs={12} md={1} lg={1}>
              <Grid item xs={12} sm={12} md={12} sx={{ paddingLeft: 2 }}>
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name={"Search"}
                  onClick={this.onSearchData}
                />
              </Grid>
            </Grid>
            <Grid
              item
              sm={4}
              xs={12}
              md={4}
              lg={4}
              container
              justifyContent="left"
            ></Grid>
          </Grid>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ width: "3%" }}>
                    Sr. No
                  </TableCell>
                  <TableCell align="center" sx={{ width: "8%" }}>
                    Region
                  </TableCell>
                  {regionAndTarget?.headerListForRegionAndTarget.map(
                    (headerObject, indexData) => {
                      return (
                        <TableCell align="center" sx={{ width: "6%" }}>
                          {headerObject.monthYear}
                        </TableCell>
                      );
                    }
                  )}
                  <TableCell align="center" sx={{ width: "8%" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {regionAndTarget?.yearForRegionAndTarget.map(
                  (yearObjectDataRow, indexRow) => {
                    return (
                      <TableRow>
                        <TableCell width="2%" align="center">
                          {indexRow + 1}
                        </TableCell>
                        <TableCell>{yearObjectDataRow.name}</TableCell>
                        {regionAndTarget?.headerListForRegionAndTarget.map(
                          (headerObject, indexData) => {
                            if (
                              headerObject.month == "January" &&
                              yearObjectDataRow.jan
                            ) {
                              return (
                                <TableCell align="center">
                                  {yearObjectDataRow.jan}
                                </TableCell>
                              );
                            } else if (
                              headerObject.month == "February" &&
                              yearObjectDataRow.feb
                            ) {
                              return (
                                <TableCell align="center">
                                  {yearObjectDataRow.feb}
                                </TableCell>
                              );
                            } else if (
                              headerObject.month == "March" &&
                              yearObjectDataRow.march
                            ) {
                              return (
                                <TableCell align="center">
                                  {yearObjectDataRow.march}
                                </TableCell>
                              );
                            } else if (
                              headerObject.month == "April" &&
                              yearObjectDataRow.april
                            ) {
                              return (
                                <TableCell align="center">
                                  {yearObjectDataRow.april}
                                </TableCell>
                              );
                            } else if (
                              headerObject.month == "May" &&
                              yearObjectDataRow.may
                            ) {
                              return (
                                <TableCell align="center">
                                  {yearObjectDataRow.may}
                                </TableCell>
                              );
                            } else if (
                              headerObject.month == "June" &&
                              yearObjectDataRow.jun
                            ) {
                              return (
                                <TableCell align="center">
                                  {yearObjectDataRow.jun}
                                </TableCell>
                              );
                            } else if (
                              headerObject.month == "July" &&
                              yearObjectDataRow.july
                            ) {
                              return (
                                <TableCell align="center">
                                  {yearObjectDataRow.july}
                                </TableCell>
                              );
                            } else if (
                              headerObject.month == "August" &&
                              yearObjectDataRow.aug
                            ) {
                              return (
                                <TableCell align="center">
                                  {yearObjectDataRow.aug}
                                </TableCell>
                              );
                            } else if (
                              headerObject.month == "September" &&
                              yearObjectDataRow.sep
                            ) {
                              return (
                                <TableCell align="center">
                                  {yearObjectDataRow.sep}
                                </TableCell>
                              );
                            } else if (
                              headerObject.month == "October" &&
                              yearObjectDataRow.oct
                            ) {
                              return (
                                <TableCell align="center">
                                  {yearObjectDataRow.oct}
                                </TableCell>
                              );
                            } else if (
                              headerObject.month == "November" &&
                              yearObjectDataRow.nov
                            ) {
                              return (
                                <TableCell align="center">
                                  {yearObjectDataRow.nov}
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell align="center">
                                  {yearObjectDataRow.dec}
                                </TableCell>
                              );
                            }
                          }
                        )}
                        <TableCell align="center">
                          {fieldDataForModule.editReject == 1 ? (
                            <MyComponentWithIconProps
                              statusImage={EditIcon}
                              color="primary"
                              fontSize="medium"
                              title="Edit"
                              onClick={(e) =>
                                this.onClickEdit(e, yearObjectDataRow)
                              }
                            />
                          ) : (
                            ""
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  yearList: state.year,
  regionAndTarget: state.regionAndTarget,
});

const mapDispatchToProps = {
  getYearForRegionAndTarget,
  getYear,
  showLoador,
  showNotification,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RegionAndTargetTable);
