import endpoint from "../../config/endpoints";

export const SingleMrpConfigurationByItemListJson = {
    showSaveNextBtn: true,
    apiBaseURL: endpoint.room,
    formPath: "/mrp-configuration-form",
    screenTitle: "Single MRP Configuration By Item List",
    showTitle: true,
    showCancel:false,
    showSaveBtn:false,
    fieldMeta: [
        {
            label: "MRP",
            controlType: "textfield",
            placeHolder: "MRP",
            inputType:"number",
            md:5.5,
            lg:5.5,
            sm:5.5,
            xs: 12,
            dataKey: "mrp",
            isMandatory: true,
        },
        {
            label: "Date",
            controlType: "datepicker",
            placeHolder: "",
            md:5.5,
            lg:5.5,
            sm:5.5,
            xs: 12,
            dataKey: "publishDate",
            isMandatory: true,
        }
    ],
    fieldMetaForSearch: [
        {
            label: "MRP",
            controlType: "textfield",
            placeHolder: "Name",
            md:5.5,
            lg:5.5,
            sm:5.5,
            xs: 12,
            dataKey: "name",
            isMandatory: true,
        },
        {
            label: "Date",
            controlType: "datepicker",
            placeHolder: "",
            md:5.5,
            lg:5.5,
            sm:5.5,
            xs: 12,
            dataKey: "date",
            isMandatory: true,
        }
    ],
};
