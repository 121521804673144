import endpoint from "../../config/endpoints";

export const AnnualTurnoverJson = {
    formPath: "/annual-turnover-form",
    apiBaseURL: endpoint.annualTurnover,
    screenTitle: "Annual Turnover",
    showAddButton: true,
    showPdfDownload: false,
    showExcelDownload: false,
    tableColumnsToFilter: [
        {
            columnDisplayName: "Name",
            columnKeyName: "name",
            isChecked: true,
        },
        {
            columnDisplayName: "Status",
            columnKeyName: "isActive",
            isChecked: true,
        },
    ],

    fieldMeta: [
        {
            label: "Name",
            controlType: "textfield",
            placeHolder: "Name",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            inputType: "numberText",
            dataKey: "name",
            isMandatory: true,
        },

    ]
};
