import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { titleColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  checkedListEmptyMsg,
  debitAmountNotValidMsg,
  noInternetMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { paymentFormStaticJson } from "../../DynamicFormsJson/Transaction/paymentFormStaticJson";
import { getAreaByUserType } from "../../Slice/area.slice";
import { getBeatByArea } from "../../Slice/beat.slice";
import {
  getOutletBillDetailFromOutletAndDateUpdated,
  setOutletBillDetailFromBeatAndDate,
} from "../../Slice/orderBillList.slice";
import {
  getOutletByBeatId,
  getOutletByBeatIdsUpdated,
} from "../../Slice/outlet.slice";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";

class PaymentFormStatic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      dynamicMasterData: {
        areaDetail: this.props.areaList.area,
        beatDetail: this.props.beatList.beat,
        outletDetail: this.props.outletList.outlets,
      },
      total: 0,
      isCheckedAll: false,
    };
  }
  async componentDidMount() {
    const { getAreaByUserType, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getAreaByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.area !== nextProps.areaList?.area) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.area,
          },
        });
      }
    }
    if (this.props.beatList !== nextProps.beatList) {
      if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            beatDetail: nextProps.beatList?.beat,
          },
        });
      }
    }
    if (this.props.outletList !== nextProps.outletList) {
      if (this.props.outletList?.outlets !== nextProps.outletList?.outlets) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            outletDetail: nextProps.outletList?.outlets,
          },
        });
      }
    }
  }
  onSearchData = async (data) => {
    const {
      getOutletBillDetailFromOutletAndDateUpdated,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (data.outlet && data.outlet != "" && data.fromDate && data.toDate) {
        let outletBillHeaderJson = {
          outletIds: data.outlet.split(","),
          fromDate: data.fromDate,
          toDate: data.toDate,
        };
        showLoador({ loador: true });
        await getOutletBillDetailFromOutletAndDateUpdated({
          outletBillHeaderJson: outletBillHeaderJson,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            if (response.length !== 0) {
              let paymentTotal = 0;
              response.map((object) => {
                paymentTotal = paymentTotal + +object.amount;
              });
              this.setState({
                total: paymentTotal,
              });
            }
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  getListById = async (data) => {
    const { getBeatByArea, getOutletByBeatId, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      if (data.area && data.area != "") {
        showLoador({ loador: true });
        await getBeatByArea({ areaId: data.area }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.beat) {
        showLoador({ loador: true });
        await getOutletByBeatId({
          beatId: data.beat,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onTextFieldDataChange = (event, row) => {
    const { billHeaderList, setOutletBillDetailFromBeatAndDate } = this.props;
    let outletRow = billHeaderList?.getOutletBillDetailFromBeatAndDate.map(
      (object) => {
        if (object.id == row.id) {
          object = {
            ...object,
            remark: event.target.value,
          };
          return object;
        } else {
          return object;
        }
      }
    );
    setOutletBillDetailFromBeatAndDate({ row: outletRow });
  };

  onTextFieldDataChangeForNumber = (event, row) => {
    const { billHeaderList, setOutletBillDetailFromBeatAndDate } = this.props;
    let outletRow = billHeaderList?.getOutletBillDetailFromBeatAndDate.map(
      (object) => {
        if (object.id == row.id) {
          if (+row.remainingAmt < +event.target.value) {
            object = {
              ...object,
              amount: event.target.value,
              amountError: true,
              amountErrorMsg: "Amount should be less than pending bill amount",
            };
          } else if (+event.target.value == 0) {
            object = {
              ...object,
              amount: event.target.value,
              amountError: true,
              amountErrorMsg: "Amount should be greater than 0",
            };
          } else {
            object = {
              ...object,
              amount: event.target.value,
              amountError: false,
              amountErrorMsg: "",
            };
          }
          return object;
        } else {
          return object;
        }
      }
    );

    let paymentTotal = 0;
    outletRow.map((object) => {
      paymentTotal = paymentTotal + +object.amount;
    });
    this.setState({
      total: paymentTotal,
    });
    setOutletBillDetailFromBeatAndDate({ row: outletRow });
  };

  handleChange = (event, row) => {
    const { billHeaderList, setOutletBillDetailFromBeatAndDate } = this.props;
    let outletRow = billHeaderList?.getOutletBillDetailFromBeatAndDate.map(
      (object) => {
        if (object.id == row.id) {
          object = {
            ...object,
            payType: event.target.value,
          };
          return object;
        } else {
          return object;
        }
      }
    );
    setOutletBillDetailFromBeatAndDate({ row: outletRow });
  };

  savePaymentOutlet = () => {
    const { showNotification, showLoador, billHeaderList } = this.props;
    let paymentOutletSave = [];
    let flag = 0;
    billHeaderList?.getOutletBillDetailFromBeatAndDate.map(
      (paymentOutletObject) => {
        if (paymentOutletObject.amount !== 0 && paymentOutletObject.isChecked) {
          flag = paymentOutletObject.amountError ? flag + 1 : flag;
          let paymentOutletData = {
            paymentRecieptOutlet: {
              outLet: {
                id: paymentOutletObject.outlet.id,
              },
              payType: paymentOutletObject.payType,
              payRefNo: "",
              payBank: "",
              remark: paymentOutletObject.remark,
            },
            outletBillHeaderId: paymentOutletObject.id,
            outletAmt: paymentOutletObject.amount,
          };
          paymentOutletSave.push(paymentOutletData);
        }
      }
    );

    if (paymentOutletSave.length !== 0) {
      if (flag <= 0) {
        swal({
          title: "Are you sure?",
          text: saveWarningMsg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            if (navigator.onLine) {
              showLoador({ loador: true });
              apiPost({
                url: endpoint.outletBillHeader + "/payment-with-settle-bill",
                postBody: paymentOutletSave,
              }).then(({ success }) => {
                if (success) {
                  showLoador({ loador: false });
                  showNotification({
                    msg: savemsg,
                  });
                  window.location.reload(
                    "/outlet-and-vehiclewise-payment-for-outlet"
                  );
                } else {
                  showNotification({
                    msg: serverMsg,
                    severity: "error",
                  });
                  showLoador({ loador: false });
                }
              });
            } else {
              showNotification({ msg: noInternetMsg, severity: "error" });
            }
          }
        });
      } else {
        showNotification({ msg: debitAmountNotValidMsg, severity: "error" });
      }
    } else {
      showNotification({ msg: checkedListEmptyMsg, severity: "error" });
    }
  };

  handleClick = (event, row) => {
    const { billHeaderList, setOutletBillDetailFromBeatAndDate } = this.props;
    const { checked } = event.target;

    let outletPaymentUpdatedList =
      billHeaderList?.getOutletBillDetailFromBeatAndDate.map(
        (outletPaymentObject) => {
          if (outletPaymentObject.id == row.id) {
            outletPaymentObject = {
              ...outletPaymentObject,
              isChecked: checked,
            };
            return outletPaymentObject;
          } else {
            return outletPaymentObject;
          }
        }
      );

    setOutletBillDetailFromBeatAndDate({ row: outletPaymentUpdatedList });
  };

  handleClickAll = (event) => {
    const { billHeaderList, setOutletBillDetailFromBeatAndDate } = this.props;
    const { checked } = event.target;

    let outletPaymentUpdatedList =
      billHeaderList?.getOutletBillDetailFromBeatAndDate.map(
        (outletPaymentObject) => {
          outletPaymentObject = {
            ...outletPaymentObject,
            isChecked: checked,
          };
          return outletPaymentObject;
        }
      );
    this.setState({
      isCheckedAll: checked,
    });
    setOutletBillDetailFromBeatAndDate({ row: outletPaymentUpdatedList });
  };

  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };
  getFilteredTableData = (tableData) => {
    const { searchValue } = this.state;

    return tableData.filter((currentRow) => {
      let isValid = false;
      if (
        currentRow.beatName
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        currentRow.firmName
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        currentRow.billNo
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        currentRow.billDate
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        currentRow.remainingAmt
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  render() {
    const { billHeaderList } = this.props;
    const { dynamicMasterData, total, isCheckedAll } = this.state;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showSaveBtn={false}
            showSaveBtnMain={true}
            dynamicMasterData={dynamicMasterData}
            getListById={this.getListById}
            showTitle={false}
            screenTitle={paymentFormStaticJson.screenTitle}
            fieldMeta={paymentFormStaticJson.fieldMeta}
            showCancel={false}
            showBackToList={false}
            showSaveNextBtn={paymentFormStaticJson.showSaveNextBtn}
            saveBtnText={"Search"}
            onSave={this.onSearchData}
          />
          <br />
          <LandingScreenHeader
            screenTitle={""}
            showSearchBox={true}
            showTable={true}
            showFilter={false}
            showPdfDownload={false}
            showExcelDownload={false}
            onSearch={this.onSearch}
          />
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="error"
                      onClick={(event) => this.handleClickAll(event)}
                      checked={isCheckedAll}
                      disabled={false}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </TableCell>
                  <TableCell>Sr. No.</TableCell>
                  <TableCell align="center">Beat</TableCell>
                  <TableCell align="center">Outlet</TableCell>
                  <TableCell align="center">Bill No.</TableCell>
                  <TableCell
                    align="center"
                    style={{
                      width: 100,
                    }}
                  >
                    Bill Date
                  </TableCell>
                  <TableCell align="center">Outstanding Amount</TableCell>
                  <TableCell
                    align="center"
                    style={{
                      width: 150,
                    }}
                  >
                    Amount
                  </TableCell>
                  <TableCell align="center">Pay Type</TableCell>
                  <TableCell
                    align="center"
                    style={{
                      width: 150,
                    }}
                  >
                    Remark
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {billHeaderList?.getOutletBillDetailFromBeatAndDate.length !==
                0 ? (
                  this.getFilteredTableData(
                    billHeaderList?.getOutletBillDetailFromBeatAndDate
                  ).map((row, index) => (
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          onClick={(event) => this.handleClick(event, row)}
                          checked={row.isChecked}
                          disabled={false}
                          inputProps={{
                            "aria-labelledby": `enhanced-table-checkbox-${index}`,
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="left">{row.beatName}</TableCell>
                      <TableCell align="left">{row.firmName}</TableCell>
                      <TableCell align="center">{row.billNo}</TableCell>
                      <TableCell align="center">{row.billDate}</TableCell>
                      <TableCell align="right">{row.remainingAmt}</TableCell>
                      <TableCell align="right">
                        <TextFieldCompo
                          placeHolder={"Amount"}
                          size="small"
                          color="primary"
                          type={"number"}
                          name={"amount"}
                          id={"amount"}
                          value={row.amount}
                          onChange={(e) =>
                            this.onTextFieldDataChangeForNumber(e, row)
                          }
                          fullWidth
                          error={row.amountError ? true : false}
                          errorText={
                            row.amountErrorMsg !== "" ? row.amountErrorMsg : ""
                          }
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Grid item xs={8} md={4} sm={4}>
                          <FormControl>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={row.payType}
                              disabled={false}
                              onChange={(e) => this.handleChange(e, row)}
                              row
                              defaultValue={"0"}
                            >
                              <FormControlLabel
                                disabled={false}
                                value="0"
                                control={<Radio />}
                                label="Cash"
                              />
                              <FormControlLabel
                                disabled={false}
                                value="1"
                                control={<Radio />}
                                label="Cheque"
                              />
                              <FormControlLabel
                                disabled={false}
                                value="2"
                                control={<Radio />}
                                label="Online"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </TableCell>
                      <TableCell align="right">
                        <Grid item xs={8} md={4} sm={4}>
                          <TextFieldCompo
                            placeHolder={"Remark"}
                            size="small"
                            color="primary"
                            name={"remark"}
                            id={"remark"}
                            value={row.remark}
                            onChange={(e) => this.onTextFieldDataChange(e, row)}
                            fullWidth
                          />
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={9}>
                      No Record Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <Grid item sm={12} xs={12} md={12} lg={12} container>
            <Grid
              item
              sm={6}
              xs={6}
              md={6}
              lg={6}
              container
              justifyContent="left"
            >
              <LabelCompo
                className="text-black"
                style={{ fontSize: 20, color: titleColor }}
                label={"Total : "}
              />{" "}
              &nbsp;
              <LabelCompo
                className="text-black"
                style={{ fontSize: 20 }}
                label={total.toFixed(2)}
              />
            </Grid>
            <Grid
              item
              sm={6}
              xs={6}
              md={6}
              lg={6}
              container
              justifyContent="right"
            >
              <ButtonCompo
                size="medium"
                type="submit"
                variant="contained"
                name="Submit"
                onClick={this.savePaymentOutlet}
              />
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  beatList: state.beat,
  areaList: state.area,
  outletList: state.outlet,
  billHeaderList: state.billHeaderList,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getAreaByUserType,
  getBeatByArea,
  getOutletByBeatId,
  getOutletByBeatIdsUpdated,
  setOutletBillDetailFromBeatAndDate,
  getOutletBillDetailFromOutletAndDateUpdated,
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentFormStatic);
