import endpoint from "../../config/endpoints";

export const grievanceCategoryJson = {
  showSaveNextBtn: true,
  formPath: "/grievance-category-form",
  apiBaseURL: endpoint.grievanceCategory,
  screenTitle: "Grievance Category",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Grievance-Category",
  showExcelDownload: true,
  excelFileName: "Grievance-Category",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "categoryName",
      isChecked: true,
    },
    {
      columnDisplayName: "Type",
      columnKeyName: "type",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "Enter Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "categoryName",
      isMandatory: true,
      uniqueValidation:true,
    },

    {
      label: "Type",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "type",
      tableDataKey: "type",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Regular",
          value: "0",
        },
        {
          label: "Outlet",
          value: "1",
        },
      ],
      isMandatory: true,
    },
  ],
};
