import { Grid, Box, Paper, Tab, Tabs, Typography } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAltOutlined";
import SwipeableViews from "react-swipeable-views";
import { withRouter } from "../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { onToggle } from "../../CommonActions/common.slice";
import AutoComplete from "../../components/Comman/AutoComplete";
import MonthCalender from "../../components/MonthCalender";
import { serverMsg } from "../../config/messageconstant";
import * as myConstClass from "../../config/messageconstant";
import { apiGet, apiPost } from "../../utils/api_service";
import CheckBoxSelectionTable from "../../components/Comman/RT/CheckBoxSelectionTable";
import CheckBoxSelectionTable1 from "../OrderList/CheckBoxSelectionTable";

import {
  getOutletOrderDetailFromHeaderIds,
  setOutletOrderDetail,
} from "../../Slice/outletOrderDetail.slice";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import "../../CSS/app.min.css";
import "../../CSS/bootstrap.min.css";
import "../../CSS/custom.min.css";
import "../../CSS/mermaid.min.css";
import "../../CSS/slickDashboard.css";
import MainCard from "../DashbourdChanges/MainCard";
import NoDataFound from "../DashbourdChanges/NoDataFound";
import OrdersTable from "../DashbourdChanges/OrdersTable";
import { getMonthYearTargetForSales } from "../../Slice/adminDashboard.slice";
import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import PropTypes from "prop-types";
import {
  getPendingOrderForDistributor,
  getParkOrderForDistributor,
  getOrderCountsForDistributor,
  getOutletOutstandingForDistributor,
} from "../../Slice/functionalDashboardDistributor.slice";
import {
  getAreaListFromYearAndMonth,
  getCurrentYearTarget,
  getOrderListFromYearAndMonth,
  getProductListFromYearAndMonth,
  getRegionListFromYearAndMonth,
  getSalesListFromYearAndMonth,
  getTillMonthYearTarget,
  getZoneListFromYearAndMonth,
  getMonthYearTarget,
  getSalesTeamInfo,
  setRegionListFromYearAndMonth,
  getSalesGraph,
  getZoneRegionwiseListFromYearAndMonth,
} from "../../Slice/adminDashboard.slice";
import { getAreaByUserType } from "../../Slice/area.slice";
import { getZoneByUserType } from "../../Slice/zone.slice";
import { getStockStakeHolderTypeForList } from "../../Slice/stockStakeHolderType.slice";
import LineChart from "../../components/Line Chart";
import { cyanBlue, yellowishBrownColor } from "../../config/ColorObj";
import MonthlyBarChart from "../DashbourdChanges/MonthlyBarChart";
import image4 from "../../Images/image4.png";
import image5 from "../../Images/image5.png";
import image6 from "../../Images/image6.png";
import image7BgClr from "../../Images/image7BgClr.png";
import target1 from "../../Images/target1.png";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { ConvertAmount } from "../../components/Comman/ConvertValueWithDenominator";
import SearchBar from "../../components/Search";
import endpoint from "../../config/endpoints";
import { OrderListFooterJsons } from "../../DynamicFormsJson/Transaction/orderListFooter";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import BillingWithOffer from "../OrderList/BillingWithOffer";
import { onlyNumber } from "../../components/Comman/Util/Validations";
import {
  FontAwesomeIconCompo,
  MyComponentWithIconProps,
} from "../../components/Comman/IconButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
let orderColumns = [
  {
    title: "Sr.No.",
    name: "index",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "Outlet Name",
    name: "firmName",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    title: "Order No",
    name: "orderNo",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
  },
  {
    title: "Order Date",
    name: "orderDate",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "Order Placed Time",
    name: "orderTime",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "Product Name",
    name: "productName",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "left",
  },
  {
    title: "UOM",
    name: "outletUom",
    positionCenter: false,
    showInscreen: true,
    alignCenter: "center",
  },
  {
    title: "Rate",
    name: "rate",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
  },
  {
    title: "Order Quantity",
    name: "billQty",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
  },
  {
    title: "Bill Quantity",
    name: "orderQty",
    placeHolder: "Enter Bill Quantity",
    textFieldError: "percentageErr",
    error: "Percentage",
    textField: true,
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
  },
  {
    title: "Free Quantity",
    name: "freeQty",
    placeHolder: "Enter Free Quantity",
    textFieldError: "percentageErr",
    error: "Percentage",
    textField: true,
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
  },
  {
    title: "Discount Percentage",
    name: "distcountAmt",
    placeHolder: "Enter Discount Percentage",
    textFieldError: "discountError",
    error: "Discount  Percentage",
    textField: true,
    positionCenter: false,
    showInscreen: true,
    alignCenter: "right",
  },
  {
    title: "UOM Conversion Value",
    name: "uomConversionRate",
    placeHolder: "Enter UOM Conversion Value",
    textFieldError: "conversionValueErr",
    error: "ConversionValue",
    textField: true,
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
  },
  {
    title: "Total",
    name: "total",
    positionCenter: true,
    showInscreen: true,
    alignCenter: "right",
  },
];
let tabList1 = [
  {
    label: "Outlet Outstanding",
    allyProps: 0,
    columns: [
      {
        name: "index",
        title: "Sr. No.",
        width: "10%",
        alignCenter: "center",
        showInscreen: true,
      },
      {
        title: "Bill No",
        name: "billNo",
        alignCenter: "center",
        showInscreen: true,
      },
      {
        title: "Bill Date",
        name: "billDate",
        alignCenter: "center",
        showInscreen: true,
      },
      {
        title: "Remaining Amt",
        name: "remainingAmt",
        alignCenter: "right",
        showInscreen: true,
      },
      {
        title: "Action",
        name: "action",
        alignCenter: "center",
        showInscreen: true,
      },
    ],
    isActionColActive: true,
    value: "currentOfferList",
  },
  {
    label: "Date-Wise Outstanding",
    allyProps: 1,

    columns: [
      {
        name: "index",
        title: "Sr. No.",
        width: "10%",
        alignCenter: "center",
        showInscreen: true,
      },
      {
        title: "Bill No",
        name: "billNo",
        alignCenter: "center",
        showInscreen: true,
      },
      {
        title: "Bill Date",
        name: "billDate",
        alignCenter: "center",
        showInscreen: true,
      },
      {
        title: "Remaining Amt",
        name: "remainingAmt",
        alignCenter: "right",
        showInscreen: true,
      },
      {
        title: "Action",
        name: "action",
        alignCenter: "center",
        showInscreen: true,
      },
    ],
    isActionColActive: true,
    value: "futureOfferList",
  },
  {
    label: "Payment Receipt",
    allyProps: 2,

    columns: [
      {
        name: "index",
        title: "Sr. No.",
        width: "10%",
        alignCenter: "center",
        showInscreen: true,
      },
      {
        title: "Amount",
        name: "amt",
        alignCenter: "center",
        showInscreen: true,
      },
      {
        title: "Date",
        name: "date",
        alignCenter: "center",
        showInscreen: true,
      },
      {
        title: "Outlet Name",
        name: "outletName",
        alignCenter: "right",
        showInscreen: true,
      },
      {
        title: "Transaction No",
        name: "transactionNo",
        alignCenter: "center",
        showInscreen: true,
      },
      {
        title: "Action",
        name: "action",
        alignCenter: "center",
        showInscreen: true,
      },
    ],
    isActionColActive: true,
    value: "futureOfferList",
  },
];

let tabColumns = [
  {
    name: "index",
    title: "Sr. No.",
    width: "10%",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    align: "center",
  },
  {
    title: "Outlet Name",
    name: "outletName",
    positionCenter: false,
    showInscreen: true,
    alignCell: "center",
    alignCenter: "left",
  },
  {
    title: "Order No",
    name: "orderNo",
    width: "15%",
    positionCenter: true,
    showInscreen: true,
    alignCell: "center",
    align: "center",
  },
  {
    title: "Order Date",
    name: "orderDate",
    width: "15%",
    positionCenter: true,
    showInscreen: true,
    alignCell: "center",
    align: "center",
  },
  {
    title: "Action",
    name: "action",
    positionCenter: true,
    showInscreen: true,
    alignCell: "center",
    align: "center",
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

class FunctionalDashboardDistributor extends Component {
  toggleDrawer = () => {
    this.props.onToggle();
  };
  state = {
    showFeedback: false,
    showPreview: false,

    curDate: new Date(),
    dob: "",
    dobDay: "",
    dobMonth: "",
    currenDate: "",
    currentMonth: "",
    timeTableList: [],
    subject: "",
    batch: "",
    yearDetail: "",
    semester: "",
    division: "",
    pieChartData: [],
    isLoading: false,
    showLoader: false,
    profile: "",
    nameFirstLetter: "",
    showZone: false,
    showArea: false,
    date: "",
    saleDate: "",
    productArea: "",
    productZone: "",
    outletArea: "",
    outletZone: "",
    outletDate: "",
    monthDate: "",
    yearDate: "",
    type: "",
    saleMonth: dayjs(),
    tabValue: 0,
    tabValuePayment: 0,
    showOrderGeneration: true,
    showBillGeneration: false,
    showPaymentGeneration: false,
    setList: [],
    selection: [],
    showDashboard: true,
    offerBillData: [],
    showBilling: false,
    showOrderTable: false,
    billDate: "",
    billDiscountPercentage: "",

    saleYear: "",
    month: "",
    year: "",
    searchValue: "",
    searchValueForZone: "",
    searchValueForSalesRegion: "",
    searchValueForZoneDetails: "",
    searchValueForAreaDetails: "",
    searchValuePersonWiseSales: "",
    searchValueForProduct: "",
    searchValueForOutlets: "",
    regionListFromYearAndMonthData: [],
    regionListFromYearAndMonthDataForZone: [],
    regionListFromYearAndMonthDataForSalesRegion: [],
    zoneDetailsSalesData: [],
    zoneDetailsRegionwiseData: [],
    salesGraphData: [],
    salesMonthData: [],
    areaDetailsData: [],
    personWiseSalesData: [],
    topPerformingProductData: [],
    topPerformingOutletData: [],
    salesTeamData: [],
    formErrors: {},
    graphList: [],
    radioValue: [],
    lineGraphData: [],
    salesGraphList: [],
    salesRegionName: "",
    monthList: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    columns: [
      { id: "name", label: "Name", minWidth: 170 },
      { id: "code", label: "ISO\u00a0Code", minWidth: 100 },
      {
        id: "population",
        label: "Population",
        minWidth: 170,
        align: "right",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "size",
        label: "Size\u00a0(km\u00b2)",
        minWidth: 170,
        align: "right",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "density",
        label: "Density",
        minWidth: 170,
        align: "right",
        format: (value) => value.toFixed(2),
      },
    ],
    rows: [
      this.createData("India", "IN", 1324171354, 3287263),
      this.createData("China", "CN", 1403500365, 9596961),
      this.createData("Italy", "IT", 60483973, 301340),
      this.createData("United States", "US", 327167434, 9833520),
      this.createData("Canada", "CA", 37602103, 9984670),
      this.createData("Australia", "AU", 25475400, 7692024),
      this.createData("Germany", "DE", 83019200, 357578),
      this.createData("Ireland", "IE", 4857000, 70273),
      this.createData("Mexico", "MX", 126577691, 1972570),
      this.createData("Japan", "JP", 126317000, 377973),
      this.createData("France", "FR", 67022000, 640679),
      this.createData("United Kingdom", "GB", 67545757, 242495),
      this.createData("Russia", "RU", 146793744, 17098246),
      this.createData("Nigeria", "NG", 200962417, 923768),
      this.createData("Brazil", "BR", 210147125, 8515767),
    ],
    rowsPerPage: 10,
    page: 0,
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0,
    });
  };

  orderGenerationHandler = (e, index) => {
    console.log(index);
    if (index == 0) {
      this.setState({
        showOrderGeneration: true,
        showBillGeneration: false,
        showPaymentGeneration: false,
      });
    } else if (index == 1) {
      this.setState({
        showOrderGeneration: false,
        showBillGeneration: true,
        showPaymentGeneration: false,
      });
    } else {
      this.setState({
        showOrderGeneration: false,
        showBillGeneration: false,
        showPaymentGeneration: true,
      });
    }
  };
  rowViewData = (row) => {
    this.props.navigate("/order-history-view/" + row.id);
  };

  handledChange = (e, newValue) => {
    this.setState({
      tabValue: newValue,
      setList: [],
    });
  };

  clickHandler = () => {
    this.setState({
      showOrderTable: false,
    });
  };

  onCancelBilling = () => {
    this.setState({
      showBilling: false,
      showDashboard: true,
    });
  };

  handledChangePayment = (e, newValue) => {
    this.setState({
      tabValuePayment: newValue,
    });
  };
  a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  getRow = (e) => {
    this.setState({ setList: e });
  };

  getSelectedList = (e) => {
    const { outletOrderDetail, setOutletOrderDetail } = this.props;
    this.setState({ selection: e });
    const rowList = outletOrderDetail.outletOrderDetail.map((rowData) => {
      const filterData = e.filter((id) => rowData.id == id);
      return {
        ...rowData,
        checked: filterData.length != 0 ? true : false,
      };
    });
    setOutletOrderDetail({ row: rowList });
  };

  orderHeaderHandler = () => {
    const { setList } = this.state;
    const { getOutletOrderDetailFromHeaderIds, showLoador } = this.props;
    showLoador({ loador: true });
    getOutletOrderDetailFromHeaderIds({ outletOrderHeaderIds: setList }).then(
      ({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({ showOrderTable: true });
        }
      }
    );
  };

  outletOrderDetails = ({ row }) => {
    const { setOutletOrderDetail } = this.props;
    setOutletOrderDetail({ row: row });
  };

  checkListValidation = (data) => {
    const { outletOrderDetail, showNotification } = this.props;
    const { productStockData } = this.state;
    const filterCheck = outletOrderDetail.outletOrderDetail.filter(
      (row) => row.checked
    );
    if (filterCheck.length != 0) {
      let error = false;
      const rowList = outletOrderDetail.outletOrderDetail.map((rowObj) => {
        if (rowObj.checked) {
          if (
            rowObj.orderQty.toString() == "" ||
            rowObj.freeQty.toString() == "" ||
            rowObj.distcountAmt.toString() == "" ||
            (+rowObj.distcountAmt >= 100 && rowObj.distcountAmt != 100) ||
            rowObj.uomConversionRate.toString() == ""
          ) {
            error = true;
          }
          if (rowObj.productStock > 0) {
            if (
              (rowObj.orderQty.toString() != "" &&
                (rowObj.orderQty.toString() == "0" ||
                  !onlyNumber.test(rowObj.orderQty.toString()))) ||
              (rowObj.uomConversionRate.toString() != "" &&
                (rowObj.uomConversionRate.toString() == "0" ||
                  !onlyNumber.test(rowObj.uomConversionRate.toString())))
            ) {
              error = true;
            }
          }

          rowObj = {
            ...rowObj,
            qtyError:
              rowObj.orderQty.toString() == ""
                ? 1
                : rowObj.orderQty.toString() != "" &&
                  !onlyNumber.test(rowObj.orderQty.toString())
                ? 3
                : 0,
            freeQtyError: rowObj.freeQty.toString() == "" ? 1 : 0,
            discountError:
              rowObj.distcountAmt.toString() == ""
                ? 1
                : rowObj.distcountAmt != 100 && +rowObj.distcountAmt >= 100
                ? 2
                : 0,
            conversionValueErr:
              rowObj.uomConversionRate.toString() == ""
                ? 1
                : rowObj.uomConversionRate.toString() != "" &&
                  rowObj.uomConversionRate.toString() == "0"
                ? 2
                : rowObj.uomConversionRate.toString() != "" &&
                  !onlyNumber.test(rowObj.uomConversionRate.toString())
                ? 3
                : 0,
          };
        }

        return rowObj;
      });
      this.props.setOutletOrderDetail({ row: rowList });
      if (!error) {
        this.saveBillConfigtation(data);
      }
    } else {
      showNotification({
        msg: myConstClass.checkedListEmptyMsg,
        severity: "error",
      });
    }
  };
  saveBillConfigtation = (data) => {
    const { showNotification, showLoador } = this.props;
    const { flag, productStockData } = this.state;
    this.setState({
      showCheckRecord: false,
      billDate: data.date,
      billDiscountPercentage: data.discount,
    });
    let billingCheckedList = [];
    let stockList = [];
    let dataToSave = {};
    if (flag) {
      billingCheckedList =
        this.props.outletOrderDetail.outletOrderDetail.filter(
          (row) => row.checked && +row.orderQty > 0
        );
      stockList = productStockData.productStocks.map((stockObject) => {
        let dataToReturn = {
          productId: stockObject.product.id,
          stock: stockObject.secondaryClosingQty,
        };
        return dataToReturn;
      });
      localStorage.setItem("stockList", JSON.stringify(stockList));
    } else {
      billingCheckedList =
        this.props.outletOrderDetail.outletOrderDetail.filter(
          (row) => row.checked
        );
    }

    let billingDataToSave = billingCheckedList.map((billingObject) => {
      let billData = {
        outletOrderDetail: {
          id: billingObject.id,
        },
        outLet: {
          id: billingObject.outlet.id,
        },
        product: {
          id: billingObject.product.id,
        },
        qty: +billingObject.orderQty,
        freeQty: +billingObject.freeQty,
        itemDiscPer: +billingObject.distcountAmt,
        conversionValue: +billingObject.uomConversionRate,
      };
      return billData;
    });

    dataToSave = {
      dtoOutletBillDetails: billingDataToSave,
      productAndStocks: stockList,
    };
    showLoador({ loador: true });
    apiPost({
      url:
        endpoint.outletBillHeader +
        "/generate-with-applied-offer?billDate=" +
        data.date +
        "&billDiscountPercentage=" +
        data.discount,
      postBody: dataToSave,
    }).then(({ data, success }) => {
      showLoador({ loador: false });
      if (success) {
        if (Object.keys(data).length !== 0 && data.data.length !== 0) {
          // this.handleCloses();
          let newObjectToSave = data.data.map((billObject) => {
            let newBillObject = {
              ...billObject.outletBillsAfterApplyingOffers,
              outletBillDetails:
                billObject.outletBillsAfterApplyingOffers.outletBillDetails.map(
                  (orderObject) => {
                    let productWithoutBillObject =
                      billObject.outletBillHeader.outletBillDetails.filter(
                        (row) => row.id == orderObject.id
                      );
                    if (productWithoutBillObject.length !== 0) {
                      orderObject = {
                        ...orderObject,
                        taxableAmtWithoutOffer:
                          productWithoutBillObject[0].taxableAmt,
                        totalAmtWithoutOffer:
                          productWithoutBillObject[0].totalAmt,
                      };
                      return orderObject;
                    } else {
                      orderObject = {
                        ...orderObject,
                        taxableAmtWithoutOffer: 0.0,
                        totalAmtWithoutOffer: 0.0,
                      };
                      return orderObject;
                    }
                  }
                ),
            };
            billObject = {
              ...billObject,
              outletBillsAfterApplyingOffers: newBillObject,
            };
            return billObject;
          });

          this.setState({
            showBilling: true,
            showDashboard: false,
            offerBillData: newObjectToSave,
          });
        }
      } else {
        showNotification({
          msg: saveFailedMsg,
          severity: "error",
        });
      }
    });
  };

  createData(name, code, population, size) {}

  async componentDidMount() {
    const {
      getPendingOrderForDistributor,
      getParkOrderForDistributor,
      getOrderCountsForDistributor,
      getOutletOutstandingForDistributor,
      showLoador,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getOrderCountsForDistributor().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getOutletOutstandingForDistributor().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      await getPendingOrderForDistributor().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getParkOrderForDistributor().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  regionWiseSalesHandler = (e, regionId) => {};

  regionHandler = (e, regionId) => {};

  viewProfile = () => {
    window.location.replace("/empPreview");
  };
  GoBack = () => {
    this.setState({
      showPreview: false,
    });
  };
  openFeedBack = (e) => {
    e.preventDefault();
    this.setState({
      showFeedback: true,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };
  topProductHandler = (e, productId) => {};

  salesHandler = (e, id) => {};

  salesTeamGraphHandler = (e, regionId) => {};

  zoneHandler = (e, zoneId) => {};

  onChangeRadioValue = (value) => {};

  onDateChange = (date, name) => {};

  onDateChangeSale = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }

    this.setState({
      saleDate: date,
    });
  };

  onDateChangeOutlet = async (date, name) => {};
  handleFormValidation() {
    const { type, saleMonth } = this.state;
    let formErrors = {};
    let formIsValid = true;
    if (type === "" || type === null) {
      formIsValid = false;
      formErrors["typeError"] = myConstClass.stackHolderTypeMsg;
    }
    if (saleMonth === "" || saleMonth === null) {
      formIsValid = false;
      formErrors["dateError"] = myConstClass.dateMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  searchSalesList = () => {};

  productColumns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "name",
      title: "Product Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },

    {
      name: "code",
      title: "Product Code",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "actualSale",
      title: "Acheived ",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "contribution",
      title: "% contribution",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
  ];

  outletColumns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "firmName",
      title: "Outlet Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },

    {
      name: "area",
      title: "Area Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "actualSale",
      title: "Acheived ",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "contribution",
      title: "% contribution",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "salePersonName",
      title: "Sales Excutive Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
  ];

  columns = [
    {
      name: "index",
      title: "Sr.No",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
    },

    {
      name: "name",
      title: " Product Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "code",
      title: "Product Code",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "actualSale",
      title: "Acheived",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "contribution",
      title: "% Contribution",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
  ];
  graphList = [{ x: 1, y: 64 }];
  onChangeMonthOrYear = (data, name) => {
    this.setState({
      [name]: data,
    });
  };
  getExtraData = (dtoSalesTeamInformations) => {
    let totalOutletCount = 0,
      zero = 0,
      unvisited = 0,
      totalAmount = 0;
    dtoSalesTeamInformations.map((salesTeam) => {
      totalOutletCount = totalOutletCount + +salesTeam.totalOutletCount;
      zero = zero + +salesTeam.zeroOrderCount;
      unvisited = unvisited + +salesTeam.unvisitedOutletCount;
      totalAmount = totalAmount + +salesTeam.totalAmount;
    });
    return [totalOutletCount, unvisited, zero, totalAmount];
  };

  onSearch = (searchData) => {
    const { dashboardData, setRegionListFromYearAndMonth } = this.props;
    const { regionListFromYearAndMonthData } = this.state;
    if (searchData != "" || searchData != undefined) {
      this.setState({
        searchValue: searchData,
      });
      const list = dashboardData.regionListFromYearAndMonth.filter(
        (currentRow) => {
          let isValid = false;
          if (
            currentRow["name"] &&
            currentRow["name"]
              .toString()
              .toLowerCase()
              .includes(searchData.toLowerCase())
          ) {
            isValid = true;
            return true;
          }
          return isValid;
        }
      );
      this.setState({
        regionListFromYearAndMonthData: list,
      });
    } else {
      this.setState({
        searchValue: "",
      });
      this.setState({
        regionListFromYearAndMonthData:
          dashboardData.regionListFromYearAndMonth,
      });
    }
  };

  onSearchForZone = (searchData) => {
    const { dashboardData, setRegionListFromYearAndMonth } = this.props;
    const { regionListFromYearAndMonthDataForZone } = this.state;
    // console.log(searchData);
    if (searchData != "" || searchData != undefined) {
      this.setState({
        searchValueForZone: searchData,
      });
      const list = dashboardData.regionListFromYearAndMonth.filter(
        (currentRow) => {
          let isValid = false;
          if (
            currentRow["name"] &&
            currentRow["name"]
              .toString()
              .toLowerCase()
              .includes(searchData.toLowerCase())
          ) {
            isValid = true;
            return true;
          }
          return isValid;
        }
      );
      this.setState({
        regionListFromYearAndMonthDataForZone: list,
      });
    } else {
      this.setState({
        searchValueForZone: "",
      });
      this.setState({
        regionListFromYearAndMonthDataForZone:
          dashboardData.regionListFromYearAndMonth,
      });
    }
  };

  onSearchForSalesRegion = (searchData) => {
    const { dashboardData, setRegionListFromYearAndMonth } = this.props;
    const { regionListFromYearAndMonthDataForSalesRegion } = this.state;
    // console.log(searchData);
    if (searchData != "" || searchData != undefined) {
      this.setState({
        searchValueForSalesRegion: searchData,
      });
      const list = dashboardData.regionListFromYearAndMonth.filter(
        (currentRow) => {
          let isValid = false;
          if (
            currentRow["name"] &&
            currentRow["name"]
              .toString()
              .toLowerCase()
              .includes(searchData.toLowerCase())
          ) {
            isValid = true;
            return true;
          }
          return isValid;
        }
      );
      this.setState({
        regionListFromYearAndMonthDataForSalesRegion: list,
      });
    } else {
      this.setState({
        searchValueForSalesRegion: "",
      });
      this.setState({
        regionListFromYearAndMonthDataForSalesRegion:
          dashboardData.regionListFromYearAndMonth,
      });
    }
  };

  onSearchForZoneDetails = (searchData) => {
    const { dashboardData } = this.props;
    const { zoneDetailsSalesData } = this.state;
    // console.log(searchData);
    if (searchData != "" || searchData != undefined) {
      this.setState({
        searchValueForZoneDetails: searchData,
      });
      const list = dashboardData.zoneListFromYearAndMonth.filter(
        (currentRow) => {
          let isValid = false;
          if (
            currentRow["name"] &&
            currentRow["name"]
              .toString()
              .toLowerCase()
              .includes(searchData.toLowerCase())
          ) {
            isValid = true;
            return true;
          }
          return isValid;
        }
      );
      this.setState({
        zoneDetailsSalesData: list,
      });
    } else {
      this.setState({
        searchValueForZoneDetails: "",
      });
      this.setState({
        zoneDetailsSalesData: dashboardData.zoneListFromYearAndMonth,
      });
    }
  };

  onSearchForAreaDetails = (searchData) => {
    const { dashboardData } = this.props;
    const { areaDetailsData } = this.state;
    // console.log(searchData);
    if (searchData != "" || searchData != undefined) {
      this.setState({
        searchValueForAreaDetails: searchData,
      });
      const list = dashboardData.areaListFromYearAndMonth.filter(
        (currentRow) => {
          let isValid = false;
          if (
            currentRow["name"] &&
            currentRow["name"]
              .toString()
              .toLowerCase()
              .includes(searchData.toLowerCase())
          ) {
            isValid = true;
            return true;
          }
          return isValid;
        }
      );
      this.setState({
        areaDetailsData: list,
      });
    } else {
      this.setState({
        searchValueForAreaDetails: "",
      });
      this.setState({
        areaDetailsData: dashboardData.areaListFromYearAndMonth,
      });
    }
  };

  // onSearchPersonWiseSales = (searchData) => {
  //   const { dashboardData } = this.props;
  //   const { personWiseSalesData } = this.state;
  //   console.log(searchData);
  //   if (searchData != "" || searchData != undefined) {
  //     this.setState({
  //       searchValuePersonWiseSales: searchData,
  //     });
  //     const list = dashboardData.salesListFromYearAndMonth.filter(
  //       (currentRow) => {
  //         let isValid = false;
  //         if (
  //           currentRow["name"] &&
  //           currentRow["name"]
  //             .toString()
  //             .toLowerCase()
  //             .includes(searchData.toLowerCase())
  //         ) {
  //           isValid = true;
  //           return true;
  //         }
  //         return isValid;
  //       }
  //     );
  //     this.setState({
  //       personWiseSalesData: list,
  //     });
  //   } else {
  //     this.setState({
  //       searchValuePersonWiseSales: "",
  //     });
  //     this.setState({
  //       personWiseSalesData:
  //         dashboardData.salesListFromYearAndMonth,
  //     });
  //   }
  // };

  onSearchForTopPerformingProducts = (searchData) => {
    const { dashboardData } = this.props;
    const { topPerformingProductData } = this.state;
    console.log(searchData);
    if (searchData != "" || searchData != undefined) {
      this.setState({
        searchValueForProduct: searchData,
      });
      const list = dashboardData.productListFromYearAndMonth.filter(
        (currentRow) => {
          let isValid = false;
          if (
            (currentRow["name"] &&
              currentRow["name"]
                .toString()
                .toLowerCase()
                .includes(searchData.toLowerCase())) ||
            (currentRow["code"] &&
              currentRow["code"]
                .toString()
                .toLowerCase()
                .includes(searchData.toLowerCase()))
          ) {
            isValid = true;
            return true;
          }
          return isValid;
        }
      );
      this.setState({
        topPerformingProductData: list,
      });
    } else {
      this.setState({
        searchValueForProduct: "",
      });
      this.setState({
        topPerformingProductData: dashboardData.productListFromYearAndMonth,
      });
    }
  };

  onSearchForTopPerformingOutlets = (searchData) => {
    const { dashboardData } = this.props;
    const { topPerformingOutletData } = this.state;
    console.log(searchData);
    if (searchData != "" || searchData != undefined) {
      this.setState({
        searchValueForOutlets: searchData,
      });
      const list = dashboardData.outletListFromYearAndMonth.filter(
        (currentRow) => {
          let isValid = false;
          if (
            currentRow["firmName"] &&
            currentRow["firmName"]
              .toString()
              .toLowerCase()
              .includes(searchData.toLowerCase())
          ) {
            isValid = true;
            return true;
          }
          return isValid;
        }
      );
      this.setState({
        topPerformingOutletData: list,
      });
    } else {
      this.setState({
        searchValueForOutlets: "",
      });
      this.setState({
        topPerformingOutletData: dashboardData.outletListFromYearAndMonth,
      });
    }
  };

  render() {
    const {
      dashboardData,
      dashboardData2,
      stockStakeHolderTypeList,
      zoneList,
      areaList,
      functionalDashboardDistributorData,
      outletOrderDetail,
    } = this.props;
    const {
      type,
      showZone,
      showArea,
      saleMonth,
      monthList,
      columns,
      rows,
      rowsPerPage,
      page,
      radioValue,
      productArea,
      productZone,
      outletArea,
      outletZone,
      regionListFromYearAndMonthData,
      regionListFromYearAndMonthDataForZone,
      regionListFromYearAndMonthDataForSalesRegion,
      zoneDetailsSalesData,
      zoneDetailsRegionwiseData,
      salesGraphData,
      salesMonthData,
      areaDetailsData,
      topPerformingProductData,
      topPerformingOutletData,
      salesTeamData,
      personWiseSalesData,
      searchValue,
      searchValueForZone,
      searchValueForSalesRegion,
      searchValueForZoneDetails,
      searchValueForAreaDetails,
      searchValuePersonWiseSales,
      searchValueForProduct,
      searchValueForOutlets,
      lineGraphData,
      salesGraphList,
      salesRegionName,
      tabValue,
      tabValuePayment,
      showOrderGeneration,
      showBillGeneration,
      showPaymentGeneration,
      setList,
      selection,
      showDashboard,
      showBilling,
      showOrderTable,
      offerBillData,
      billDate,
      billDiscountPercentage,
    } = this.state;
    const { typeError, dateError } = this.state.formErrors;
    console.log(functionalDashboardDistributorData);
    return (
      <>
        {showDashboard && (
          <>
            <div
              id="layout-wrapper"
              style={{
                backgroundColor: "#fff",
              }}
            >
              {/* ========== App Menu ========== */}

              {/* Left Sidebar End */}
              {/* Vertical Overlay*/}
              <div className="vertical-overlay" />
              {/* ============================================================== */}
              {/* Start right Content here
            {/* ============================================================== */}
              <div className="main-content">
                <div className="page-content">
                  <div className="container-fluid">
                    <div
                      className="row"
                      style={{
                        gap: "27px",
                        paddingBottom: 10,
                        padding: "12px",
                      }}
                    >
                      {/* Card 1 */}
                      <div
                        className="Frame427319159"
                        style={{
                          width: "23.5%",
                          height: "auto",
                          paddingTop: 22,
                          paddingBottom: 22,
                          background:
                            showOrderGeneration == true
                              ? "#9dffffd1"
                              : "rgba(253, 126, 20, 0.10)",
                          borderRadius: 8,
                          overflow: "hidden",
                          border:
                            showOrderGeneration == true ? "1px solid" : "",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          className="Frame11262"
                          style={{
                            flex: "1 1 0",
                            alignSelf: "stretch",
                            paddingLeft: 16,
                            paddingRight: 16,
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            display: "inline-flex",
                          }}
                        >
                          <div
                            className="Frame427319153"
                            style={{
                              width: "60%",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "flex-start",
                              gap: 8,
                              display: "inline-flex",
                            }}
                          >
                            <img
                              className="Image4"
                              style={{ width: 32, height: 32 }}
                              src={image4}
                            />

                            <div
                              className="TotalSales"
                              style={{
                                color: "black",
                                fontSize: 22,
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                wordWrap: "break-word",
                                top: 5,
                              }}
                            >
                              Secondory Order{" "}
                            </div>
                          </div>
                          <div
                            className="Frame427319116"
                            style={{
                              // alignSelf: "stretch",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              alignItems: "center",
                              display: "inline-flex",
                            }}
                          >
                            <div
                              className="Cr"
                              style={{
                                color: "black",
                                fontSize: 28,
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                textTransform: "capitalize",
                                lineHeight: 1.8,
                                wordWrap: "break-word",
                              }}
                            >
                              {ConvertAmount({
                                amount:
                                  functionalDashboardDistributorData
                                    ?.orderCounts.closedOrderAmt,
                              })}
                            </div>
                            <div
                              className="Frame427319115"
                              style={{
                                justifyContent: "flex-end",
                                alignItems: "center",
                                gap: 2,
                                display: "inline-flex",
                              }}
                            >
                              <div
                                style={{
                                  color: "#43C622",
                                  fontSize: 16,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  textTransform: "capitalize",
                                  wordWrap: "break-word",
                                }}
                              >
                                LOST ORDER
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Card 1  end*/}

                      {/* Card 2 */}

                      <div
                        className="Frame427319162"
                        style={{
                          width: "23.5%",
                          height: "auto",
                          paddingTop: 22,
                          paddingBottom: 22,
                          background: "rgba(253, 126, 20, 0.10)",
                          borderRadius: 8,
                          overflow: "hidden",
                          // border: "1px solid",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          className="Frame11262"
                          style={{
                            flex: "1 1 0",
                            alignSelf: "stretch",
                            paddingLeft: 16,
                            paddingRight: 16,
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            display: "inline-flex",
                          }}
                        >
                          <div
                            className="Frame427319153"
                            style={{
                              width: "60%",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "flex-start",
                              gap: 8,
                              display: "inline-flex",
                            }}
                          >
                            <img
                              className="Image5"
                              style={{ width: 32, height: 32 }}
                              src={image5}
                            />
                            <div
                              className="TotalSales"
                              style={{
                                color: "black",
                                fontSize: 22,
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                wordWrap: "break-word",
                              }}
                            >
                              Primary Order
                            </div>
                          </div>
                          <div
                            className="Frame427319116"
                            style={{
                              // alignSelf: "stretch",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              alignItems: "center",
                              display: "inline-flex",
                            }}
                          >
                            <div
                              style={{
                                color: "black",
                                fontSize: 28,
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                textTransform: "capitalize",
                                lineHeight: 1.8,
                                wordWrap: "break-word",
                              }}
                            >
                              {
                                +dashboardData?.currentYearTarget
                                  .totalOutletCount
                              }
                            </div>
                            <div
                              className="Frame427319115"
                              style={{
                                justifyContent: "flex-end",
                                alignItems: "center",
                                gap: 2,
                                display: "inline-flex",
                              }}
                            >
                              <div
                                style={{
                                  color: "#43C622",
                                  fontSize: 16,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  textTransform: "capitalize",
                                  wordWrap: "break-word",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Card 2 end */}

                      {/* Card 3 */}

                      <div
                        className="Frame427319160"
                        style={{
                          width: "23.5%",
                          height: "auto",
                          paddingTop: 22,
                          paddingBottom: 22,
                          background: "rgba(39, 197, 231, 0.10)",
                          borderRadius: 8,
                          overflow: "hidden",
                          // border: "1px solid",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          className="Frame11262"
                          style={{
                            flex: "1 1 0",
                            alignSelf: "stretch",
                            paddingLeft: 16,
                            paddingRight: 16,
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            display: "inline-flex",
                          }}
                        >
                          <div
                            className="Frame427319153"
                            style={{
                              width: "60%",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "flex-start",
                              gap: 8,
                              display: "inline-flex",
                            }}
                          >
                            <img
                              className="Image5"
                              style={{ width: 32, height: 32 }}
                              src={image6}
                            />
                            <div
                              className="TotalSales"
                              style={{
                                color: "black",
                                fontSize: 22,
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                wordWrap: "break-word",
                              }}
                            >
                              Stock Management
                            </div>
                          </div>
                          <div
                            className="Frame427319116"
                            style={{
                              // alignSelf: "stretch",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              alignItems: "center",
                              display: "inline-flex",
                            }}
                          >
                            <div
                              style={{
                                color: "black",
                                fontSize: 28,
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                textTransform: "capitalize",
                                lineHeight: 1.8,
                                wordWrap: "break-word",
                              }}
                            >
                              {
                                +dashboardData?.currentYearTarget
                                  .totalStakeHolderCount
                              }
                            </div>
                            <div
                              className="Frame427319115"
                              style={{
                                justifyContent: "flex-end",
                                alignItems: "center",
                                gap: 2,
                                display: "inline-flex",
                              }}
                            >
                              <div
                                style={{
                                  color: "#43C622",
                                  fontSize: 16,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  textTransform: "capitalize",
                                  wordWrap: "break-word",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Card 3 end */}

                      {/* Card 4 */}
                      <div
                        className="Frame427319161"
                        style={{
                          width: "23.5%",
                          height: "auto",
                          paddingTop: 22,
                          paddingBottom: 22,
                          background: "rgba(243, 91, 138, 0.10)",
                          borderRadius: 8,
                          overflow: "hidden",
                          // border: "1px solid",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "inline-flex",
                        }}
                      >
                        <div
                          className="Frame11262"
                          style={{
                            flex: "1 1 0",
                            alignSelf: "stretch",
                            paddingLeft: 16,
                            paddingRight: 16,
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            display: "inline-flex",
                          }}
                        >
                          <div
                            className="Frame427319153"
                            style={{
                              width: "60%",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "flex-start",
                              gap: 8,
                              display: "inline-flex",
                            }}
                          >
                            <img
                              className="Image7"
                              style={{ width: 32, height: 32 }}
                              src={image7BgClr}
                            />
                            <div
                              className="TotalSales"
                              style={{
                                color: "black",
                                fontSize: 22,
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                wordWrap: "break-word",
                              }}
                            >
                              Notice And Messages
                            </div>
                          </div>
                          <div
                            className="Frame427319116"
                            style={{
                              // alignSelf: "stretch",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              alignItems: "center",
                              display: "inline-flex",
                            }}
                          >
                            <div
                              style={{
                                color: "black",
                                fontSize: 28,
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                textTransform: "capitalize",
                                lineHeight: 1.8,
                                wordWrap: "break-word",
                              }}
                            >
                              {
                                +dashboardData?.currentYearTarget
                                  .totalSalesTeamCount
                              }
                            </div>
                            <div
                              className="Frame427319115"
                              style={{
                                justifyContent: "flex-end",
                                alignItems: "center",
                                gap: 2,
                                display: "inline-flex",
                              }}
                            >
                              <div
                                style={{
                                  color: "#43C622",
                                  fontSize: 16,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  textTransform: "capitalize",
                                  wordWrap: "break-word",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Card 4 end */}
                    </div>

                    {/* card 3 start */}

                    <div
                      className="row"
                      style={{
                        gap: "26px",
                        paddingBottom: 10,
                        padding: "12px",
                      }}
                    >
                      {/* Card 1 */}
                      <div
                        className="Frame427319159"
                        style={{
                          width: "32%",
                          height: "auto",
                          paddingTop: 22,
                          paddingBottom: 22,
                          background:
                            showOrderGeneration == true
                              ? "#ffe19cd1"
                              : "rgba(253, 126, 20, 0.10)",
                          borderRadius: 8,
                          overflow: "hidden",
                          border:
                            showOrderGeneration == true ? "1px solid" : "",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "inline-flex",
                        }}
                        onClick={(e) => this.orderGenerationHandler(e, 0)}
                      >
                        <div
                          className="Frame11262"
                          style={{
                            flex: "1 1 0",
                            alignSelf: "stretch",
                            paddingLeft: 16,
                            paddingRight: 16,
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            display: "inline-flex",
                          }}
                        >
                          <div
                            className="Frame427319153"
                            style={{
                              width: "60%",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "flex-start",
                              gap: 8,
                              display: "inline-flex",
                            }}
                          >
                            <img
                              className="Image4"
                              style={{ width: 32, height: 32 }}
                              src={image4}
                            />

                            <div
                              className="TotalSales"
                              style={{
                                color: "black",
                                fontSize: 22,
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                wordWrap: "break-word",
                                top: 5,
                              }}
                            >
                              Order Generation
                            </div>
                          </div>
                          <div
                            className="Frame427319116"
                            style={{
                              // alignSelf: "stretch",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              alignItems: "center",
                              display: "inline-flex",
                            }}
                          >
                            <div
                              className="Cr"
                              style={{
                                color: "black",
                                fontSize: 28,
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                textTransform: "capitalize",
                                lineHeight: 1.8,
                                wordWrap: "break-word",
                              }}
                            >
                              {ConvertAmount({
                                amount:
                                  functionalDashboardDistributorData
                                    ?.orderCounts.orderBillNotGenerateAmt,
                              })}
                            </div>
                            <div
                              className="Frame427319115"
                              style={{
                                justifyContent: "flex-end",
                                alignItems: "center",
                                gap: 2,
                                display: "inline-flex",
                              }}
                            >
                              <div
                                style={{
                                  color: "#43C622",
                                  fontSize: 16,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  textTransform: "capitalize",
                                  wordWrap: "break-word",
                                }}
                              >
                                BILL NOT GENERATED
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Card 1  end*/}

                      {/* Card 2 */}

                      <div
                        className="Frame427319162"
                        style={{
                          width: "32%",
                          height: "auto",
                          paddingTop: 22,
                          paddingBottom: 22,
                          background:
                            showBillGeneration == true
                              ? "#ffe19cd1"
                              : "rgba(253, 126, 20, 0.10)",
                          borderRadius: 8,
                          overflow: "hidden",
                          border: showBillGeneration == true ? "1px solid" : "",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "inline-flex",
                        }}
                        onClick={(e) => this.orderGenerationHandler(e, 1)}
                      >
                        <div
                          className="Frame11262"
                          style={{
                            flex: "1 1 0",
                            alignSelf: "stretch",
                            paddingLeft: 16,
                            paddingRight: 16,
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            display: "inline-flex",
                          }}
                        >
                          <div
                            className="Frame427319153"
                            style={{
                              width: "60%",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "flex-start",
                              gap: 8,
                              display: "inline-flex",
                            }}
                          >
                            <img
                              className="Image5"
                              style={{ width: 32, height: 32 }}
                              src={image5}
                            />
                            <div
                              className="TotalSales"
                              style={{
                                color: "black",
                                fontSize: 22,
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                wordWrap: "break-word",
                              }}
                            >
                              Bill Generation{" "}
                            </div>
                          </div>
                          <div
                            className="Frame427319116"
                            style={{
                              // alignSelf: "stretch",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              alignItems: "center",
                              display: "inline-flex",
                            }}
                          >
                            <div
                              style={{
                                color: "black",
                                fontSize: 28,
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                textTransform: "capitalize",
                                lineHeight: 1.8,
                                wordWrap: "break-word",
                              }}
                            >
                              {ConvertAmount({
                                amount:
                                  functionalDashboardDistributorData
                                    ?.orderCounts.currentMonthBillAmt,
                              })}
                            </div>
                            <div
                              className="Frame427319115"
                              style={{
                                justifyContent: "flex-end",
                                alignItems: "center",
                                gap: 2,
                                display: "inline-flex",
                              }}
                            >
                              <div
                                style={{
                                  color: "#43C622",
                                  fontSize: 16,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  textTransform: "capitalize",
                                  wordWrap: "break-word",
                                }}
                              >
                                CURRENT MONTH BILL AMT
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Card 2 end */}

                      {/* Card 3 */}

                      <div
                        className="Frame427319160"
                        style={{
                          width: "32%",
                          height: "auto",
                          paddingTop: 22,
                          paddingBottom: 22,
                          background:
                            showPaymentGeneration == true
                              ? "#ffe19cd1"
                              : "rgba(253, 126, 20, 0.10)",
                          borderRadius: 8,
                          overflow: "hidden",
                          border:
                            showPaymentGeneration == true ? "1px solid" : "",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "inline-flex",
                        }}
                        onClick={(e) => this.orderGenerationHandler(e, 2)}
                      >
                        <div
                          className="Frame11262"
                          style={{
                            flex: "1 1 0",
                            alignSelf: "stretch",
                            paddingLeft: 16,
                            paddingRight: 16,
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            display: "inline-flex",
                          }}
                        >
                          <div
                            className="Frame427319153"
                            style={{
                              width: "60%",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "flex-start",
                              gap: 8,
                              display: "inline-flex",
                            }}
                          >
                            <img
                              className="Image5"
                              style={{ width: 32, height: 32 }}
                              src={image6}
                            />
                            <div
                              className="TotalSales"
                              style={{
                                color: "black",
                                fontSize: 22,
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                wordWrap: "break-word",
                              }}
                            >
                              Payment
                            </div>
                          </div>
                          <div
                            className="Frame427319116"
                            style={{
                              // alignSelf: "stretch",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              alignItems: "center",
                              display: "inline-flex",
                            }}
                          >
                            <div
                              style={{
                                color: "black",
                                fontSize: 28,
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                textTransform: "capitalize",
                                lineHeight: 1.8,
                                wordWrap: "break-word",
                              }}
                            >
                              {ConvertAmount({
                                amount:
                                  functionalDashboardDistributorData
                                    ?.orderCounts.totalOutStandingAmt,
                              })}{" "}
                              /{" "}
                              {ConvertAmount({
                                amount:
                                  functionalDashboardDistributorData
                                    ?.orderCounts.todaysCollectionAmt,
                              })}
                            </div>
                            <div
                              className="Frame427319115"
                              style={{
                                justifyContent: "flex-end",
                                alignItems: "center",
                                gap: 2,
                                display: "inline-flex",
                              }}
                            >
                              <div
                                style={{
                                  color: "#43C622",
                                  fontSize: 16,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  textTransform: "capitalize",
                                  wordWrap: "break-word",
                                }}
                              >
                                TOTAL OUTSTANDING/ TODAY'S COLLECTION
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Card 3 end */}
                    </div>

                    {/* Card 3 end */}
                    {/* <Grid
                      container
                      justifyContent="flex-end"
                      item
                      xs={12}
                      md={12}
                      lg={12}
                    >
                      <MyComponentWithIconProps
                        statusImage={FilterAltIcon}
                        color="primary"
                        fontSize="large"
                        title="Filter"
                        // onClick={(e) => onClickViewPreview(e, row)}
                      />
                    </Grid> */}
                    <Grid
                      container
                      justifyContent="flex-end"
                      item
                      xs={12}
                      md={12}
                      lg={12}
                    >
                      <FontAwesomeIconCompo
                        color="primary"
                        fontSize="large"
                        title="Filter"
                        baseClassName="fas"
                        className="fa-sharp fa-filter"
                        // onClick={(e) => generatePDF(e, row)}
                      />
                    </Grid>

                    {showOrderGeneration && (
                      <>
                        <br />
                        <Box sx={{ width: "99.5%" }}>
                          <Tabs
                            onChange={this.handledChange}
                            value={tabValue}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                            sx={{
                              border: "2px solid black",
                              borderColor: "#eded9b",
                              borderRadius: "8px",
                              backgroundColor: "#ffe19cd1",
                            }}
                          >
                            <Tab
                              label={
                                "Pending outlet order (" +
                                functionalDashboardDistributorData?.pendingOrder
                                  .length +
                                ")"
                              }
                              {...this.a11yProps(0)}
                            />
                            <Tab
                              label={
                                "Park order (" +
                                functionalDashboardDistributorData?.parkedOrder
                                  .length +
                                ")"
                              }
                              {...this.a11yProps(1)}
                            />
                          </Tabs>
                          <SwipeableViews
                            index={tabValue}
                            onChangeIndex={this.handleChange}
                          >
                            <CheckBoxSelectionTable
                              rows={
                                functionalDashboardDistributorData.pendingOrder
                                  .length !== 0
                                  ? functionalDashboardDistributorData.pendingOrder
                                  : []
                              }
                              columns={tabColumns}
                              selection={setList}
                              showView={true}
                              rowViewData={this.rowViewData}
                              isActionColActive={true}
                              onSelectionChange={this.getRow}
                            />
                            <CheckBoxSelectionTable
                              rows={
                                functionalDashboardDistributorData.parkedOrder
                                  .length !== 0
                                  ? functionalDashboardDistributorData.parkedOrder
                                  : []
                              }
                              columns={tabColumns}
                              selection={setList}
                              showView={true}
                              rowViewData={this.rowViewData}
                              isActionColActive={true}
                              onSelectionChange={this.getRow}
                            />
                          </SwipeableViews>
                        </Box>
                        <br />
                        <Grid
                          container
                          justifyContent="flex-end"
                          item
                          xs={12}
                          md={12}
                          lg={12}
                        >
                          <button
                            onClick={this.orderHeaderHandler}
                            type="button"
                            className="btn btn-primary waves-effect waves-light btn-lg"
                          >
                            Proceed
                          </button>
                        </Grid>
                        {showOrderTable && (
                          <>
                            <Paper
                              sx={{
                                p: 2,
                                borderRadius: 2,
                              }}
                              elevation={0}
                            >
                              <BootstrapDialog
                                aria-labelledby="customized-dialog-title"
                                open={true}
                                fullWidth
                                maxWidth="200% !important"
                              >
                                <BootstrapDialogTitle
                                  id="customized-dialog-title"
                                  onClose={this.clickHandler}
                                >
                                  <br />
                                  <CheckBoxSelectionTable1
                                    selection={selection}
                                    onSelectionChange={this.getSelectedList}
                                    columns={orderColumns}
                                    rows={outletOrderDetail.outletOrderDetail}
                                    setList={this.outletOrderDetails}
                                    isActionColActive={false}
                                    // flag={flag}
                                  />
                                  <br />
                                  <DynamicFormWithoutSave
                                    showBackToList={false}
                                    // dynamicMasterData={dynamicMasterData}
                                    showSaveBtn={
                                      OrderListFooterJsons.showSaveBtn
                                    }
                                    showTitle={OrderListFooterJsons.showTitle}
                                    screenTitle={
                                      OrderListFooterJsons.screenTitle
                                    }
                                    fieldMeta={OrderListFooterJsons.fieldMeta}
                                    showCancel={OrderListFooterJsons.showCancel}
                                    apiBaseURL={OrderListFooterJsons.apiBaseURL}
                                    showSaveNextBtn={
                                      OrderListFooterJsons.showSaveNextBtn
                                    }
                                    saveBtnText={"Proceed"}
                                    onSave={this.checkListValidation}
                                  />
                                </BootstrapDialogTitle>
                              </BootstrapDialog>
                            </Paper>
                          </>
                        )}
                      </>
                    )}
                    {showBillGeneration && (
                      <>
                        <Box sx={{ width: "100%" }}>
                          <SwipeableViews
                            index={tabValue}
                            onChangeIndex={this.handleChange}
                          >
                            <DynamicTable
                              data={
                                functionalDashboardDistributorData?.pendingOrder
                              }
                              tableHead={tabColumns}
                              isActionColActive={true}
                              showPegination={false}
                              showView={true}
                              rowViewData={this.rowViewData}
                            />
                          </SwipeableViews>
                        </Box>
                      </>
                    )}

                    {showPaymentGeneration && (
                      <>
                        <Box sx={{ width: "100%" }}>
                          <Tabs
                            onChange={this.handledChangePayment}
                            value={tabValuePayment}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                            sx={{
                              border: "2px solid black",
                              borderColor: "#eded9b",
                              borderRadius: "8px",
                              backgroundColor: "#dae9e9",
                            }}
                          >
                            <Tab
                              label={
                                tabList1[0].label +
                                " (" +
                                functionalDashboardDistributorData?.paymentData
                                  .length +
                                ")"
                              }
                              {...this.a11yProps(tabList1[0].a11yProps)}
                            />
                            <Tab
                              label={
                                tabList1[1].label +
                                " (" +
                                functionalDashboardDistributorData?.paymentData
                                  .length +
                                ")"
                              }
                              {...this.a11yProps(tabList1[1].a11yProps)}
                            />
                            <Tab
                              label={
                                tabList1[2].label +
                                " (" +
                                functionalDashboardDistributorData?.paymentData
                                  .length +
                                ")"
                              }
                              {...this.a11yProps(tabList1[2].a11yProps)}
                            />
                          </Tabs>
                          <SwipeableViews
                            index={tabValuePayment}
                            onChangeIndex={this.handleChange}
                          >
                            {tabList1.map((data) => {
                              return (
                                <>
                                  <TabPanel
                                    value={tabValuePayment}
                                    index={data.allyProps}
                                  >
                                    <DynamicTable
                                      data={
                                        tabValuePayment == 0
                                          ? functionalDashboardDistributorData?.paymentData
                                          : tabValuePayment == 1
                                          ? []
                                          : functionalDashboardDistributorData?.paymentReceiptData
                                      }
                                      tableHead={data.columns}
                                      isActionColActive={true}
                                      showPegination={false}
                                      showView={true}
                                      rowViewData={this.rowViewData}
                                    />
                                  </TabPanel>
                                </>
                              );
                            })}
                          </SwipeableViews>
                        </Box>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {showBilling && (
          <>
            <BillingWithOffer
              offerBillData={offerBillData}
              onCancel={this.onCancelBilling}
              billDate={billDate}
              // flag={flag}
              redirect={true}
            />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
  dashboardData: state.adminDashboard,
  dashboardData2: state.adminDashboard,
  stockStakeHolderTypeList: state.stockStakeHolderType,
  zoneList: state.zone,
  areaList: state.area,

  functionalDashboardDistributorData: state.functionalDashboardDistributor,
  outletOrderDetail: state.outletOrderDetail,
});

const mapDispatchToProps = {
  onToggle,
  showLoador,
  getCurrentYearTarget,
  getTillMonthYearTarget,
  showNotification,
  getRegionListFromYearAndMonth,
  getZoneListFromYearAndMonth,
  getAreaListFromYearAndMonth,
  getStockStakeHolderTypeForList,
  getSalesListFromYearAndMonth,
  getOrderListFromYearAndMonth,
  getProductListFromYearAndMonth,
  getMonthYearTarget,
  getSalesTeamInfo,
  getMonthYearTargetForSales,
  getAreaByUserType,
  getZoneByUserType,
  setRegionListFromYearAndMonth,
  getSalesGraph,
  getZoneRegionwiseListFromYearAndMonth,

  getPendingOrderForDistributor,
  getParkOrderForDistributor,
  getOrderCountsForDistributor,
  getOutletOutstandingForDistributor,
  setOutletOrderDetail,
  getOutletOrderDetailFromHeaderIds,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(FunctionalDashboardDistributor);