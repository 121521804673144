import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { leaveTypeJson } from "../../../DynamicFormsJson/MastersJSON/leaveTypeJson";
import {getLeaveSummary} from "../../../Slice/LeaveType.slice"
import {
    showLoador,
    showNotification,
} from "../../Landing/Landing.slice";
import { serverMsg,noInternetMsg, } from "../../../config/messageconstant";

class LeaveTypeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicMasterData: {
                leaveSummaryDetail: this.props.leaveTypeList?.leaveSummary,
            },
        };
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.leaveTypeList !== nextProps.leaveTypeList) {
            if (this.props.leaveTypeList?.leaveSummary !== nextProps.leaveTypeList?.leaveSummary) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        leaveSummaryDetail: nextProps.leaveTypeList?.leaveSummary,
                    },
                });
            }
        }
    }

   async componentDidMount() {
        const { getLeaveSummary, showLoador, showNotification } = this.props;
        if (navigator.onLine) {
        showLoador({ loador: true });
       await getLeaveSummary().then(({ success }) => {
            showLoador({ loador: false });
            if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
            }
        });
    }
    else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
    }
    render() {
        const { dynamicMasterData } = this.state;
        return (
            <>
                <DynamicForm
                    formPath={leaveTypeJson.formPath}
                    screenTitle={leaveTypeJson.screenTitle}
                    fieldMeta={leaveTypeJson.fieldMeta}
                    apiBaseURL={leaveTypeJson.apiBaseURL}
                    dynamicMasterData={dynamicMasterData}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    leaveTypeList: state.leaveType,
});
const mapDispatchToProps = {
    getLeaveSummary,
    showLoador,
    showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(LeaveTypeForm);
