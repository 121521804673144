import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import { RateConfigurationColumns } from "../../tableColumns/table-columns";
import { rateConfigurationJson } from "../../DynamicFormsJson/Transaction/RateConfiguration";
import { getCategory } from "../../Slice/category.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { getProductRateConfigurationByStakeHolderTypeIdsAndCategoryIds, getProductRateConfigurationByAllStakeHolderTypeIdsAndAllCategoryIds } from "../../Slice/productRateConfiguration.slice";
import { serverMsg, noInternetMsg } from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
class RateConfigurationTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        stockStakeHolderTypeDetail:
          this.props.stockStakeHolderType?.stockStakeHolderType,
        categoryDetail: this.props.categoryList?.category,
      },
    };
  }
  async componentDidMount() {
    const {
      getStockStakeHolderType,
      getCategory,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      await this.getTableData()

      showLoador({ loador: true });
      await getStockStakeHolderType({ userTypeIds: 2 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getCategory().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.stockStakeHolderType !== nextProps.stockStakeHolderType) {
      if (
        this.props.stockStakeHolderType?.stockStakeHolderType !==
        nextProps.stockStakeHolderType?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stockStakeHolderTypeDetail:
              nextProps.stockStakeHolderType?.stockStakeHolderType,
          },
        });
      }
    }
    if (this.props.categoryList !== nextProps.categoryList) {
      if (
        this.props.categoryList?.category !== nextProps.categoryList?.category
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            categoryDetail: nextProps.categoryList?.category,
          },
        });
      }
    }
  }

  onSearchData = async (data) => {
    const {
      getProductRateConfigurationByStakeHolderTypeIdsAndCategoryIds,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getProductRateConfigurationByStakeHolderTypeIdsAndCategoryIds({
        stockStakeHolderTypeIds: data.stockStakeHolderType,
        categoryIds: data.category,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        } else {
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  getTableData = async () => {
    const { getProductRateConfigurationByAllStakeHolderTypeIdsAndAllCategoryIds, showLoador, showNotification } =
      this.props;
    showLoador({ loador: true });
    await getProductRateConfigurationByAllStakeHolderTypeIdsAndAllCategoryIds().then(({ success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      }
    });

  }

  render() {
    const { productRateConfigurationList } = this.props;
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          dynamicMasterData={dynamicMasterData}
          searchList={rateConfigurationJson.searchList}
          onSearchData={this.onSearchData}
          getTableDataById={true}
          formPath={rateConfigurationJson.formPath}
          screenTitle={rateConfigurationJson.screenTitle}
          fieldMeta={rateConfigurationJson.fieldMeta}
          showPdfDownload={rateConfigurationJson.showPdfDownload}
          showExcelDownload={rateConfigurationJson.showExcelDownload}
          pdfFileName={rateConfigurationJson.pdfFileName}
          deleteURL={rateConfigurationJson.deleteURL}
          excelFileName={rateConfigurationJson.excelFileName}
          showAddButton={rateConfigurationJson.showAddButton}
          tableColumnsToFilter={rateConfigurationJson.tableColumnsToFilter}
          tableColumns={RateConfigurationColumns}
          tableData={productRateConfigurationList?.productRateConfigurationNew}
          apiBaseURL={rateConfigurationJson.apiBaseURL}
          callApi={false}
          showPegination={false}
          getTableData={this.getTableData}

        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  productRateConfigurationList: state.productRateConfiguration,
  stockStakeHolderType: state.stockStakeHolderType,
  categoryList: state.category,
});
const mapDispatchToProps = {
  getStockStakeHolderType,
  getCategory,
  getProductRateConfigurationByStakeHolderTypeIdsAndCategoryIds,
  getProductRateConfigurationByAllStakeHolderTypeIdsAndAllCategoryIds,
  showLoador,
  showNotification,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RateConfigurationTable);
