
import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
    publishRateConfiguration: [],
  isFetch: false,
};
let URL = endpoints.publishRateConfiguration;
const publishRateConfigurationSlice = createSlice({
  name: "publishRateConfiguration",
  initialState,
  reducers: {
    publishRateConfigurationSaveSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        checkPublicationDate: row,
        isFetch: true,
      };
    },
    

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { publishRateConfigurationSaveSuccess, resetState } =
publishRateConfigurationSlice.actions;

export default publishRateConfigurationSlice.reducer;

export const setMRPConfList =
  ({ row }) =>
  async (dispatch) => {
    dispatch(publishRateConfigurationSaveSuccess({ row }));
  };

export const  getPublishRateConfiguration = () => async (dispatch) => {
    try {
      const response = apiGet({
        url: URL,
      }).then(({ data, success }) =>{
        if (success) {
          const{ content } =data.data
          const row = content.map((publishRateConfigurationObject, index) => {
            let publishRateConfigurationData = {
              index: index + 1,
              id:
              publishRateConfigurationObject.id === null
                  ? ""
                  : publishRateConfigurationObject.id,
              name:
              publishRateConfigurationObject.name === null
                  ? ""
                  : publishRateConfigurationObject.name,
              stockStakeHolderTypeName:
              publishRateConfigurationObject.stockStakeHolderType !== null &&
              publishRateConfigurationObject.stockStakeHolderType.name !== null
                  ?publishRateConfigurationObject.stockStakeHolderType.name 
                  : "",

              stockStakeHolderType:
              publishRateConfigurationObject.stockStakeHolderType !== null
                  ?publishRateConfigurationObject.stockStakeHolderType.id 
                  :"" ,
              categoryName:
              publishRateConfigurationObject.category !== null &&
              publishRateConfigurationObject.category.name
                  ? publishRateConfigurationObject.category.name
                  : "",
              category:
              publishRateConfigurationObject.category !== null &&
              publishRateConfigurationObject.category.id
                  ? publishRateConfigurationObject.category.id
                  : "",

              publicationDate:
              publishRateConfigurationObject.publicationDate === null
                  ? ""
                  :publishRateConfigurationObject.publicationDate,
            };
            return publishRateConfigurationData;
          });
          dispatch(publishRateConfigurationSaveSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

  