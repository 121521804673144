import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { InvestedAmountJson } from "../../../DynamicFormsJson/MastersJSON/investedAmount";
import { getInvestedAmount } from "../../../Slice/investedAmount.slice";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { withRouter } from "../../../components/withRouter";
import { investedAmountColumns } from "../../../tableColumns/table-columns";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { apiPost } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import { statusmsg } from "../../../config/messageconstant";
class InvestedAmountList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicMasterData: {},
        };
    }


    onChangeStatus = (rowData) => {
        const { showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            let status = "";
            if (rowData.isActive == 0) {
                status = 1;
            } else {
                status = 0;
            }
            showLoador({ loador: true });
            apiPost({
                url: endpoint.investedAmount + "/update-is-active?id=" + rowData.id +
                    "&status=" + status,
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (success) {
                    showNotification({ msg: statusmsg });
                    window.location.reload();
                }
            });
        } else {
            this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }

    };


    render() {
        const { investedAmountList, getInvestedAmount } = this.props;
        const { dynamicMasterData } = this.state;
        return (
            <>
                <DynamicMainScreen
                    dynamicMasterData={dynamicMasterData}
                    formPath={InvestedAmountJson.formPath}
                    fieldMeta={InvestedAmountJson.fieldMeta}
                    screenTitle={InvestedAmountJson.screenTitle}
                    showPdfDownload={false}
                    showExcelDownload={false}
                    showExtension={false}
                    pdfFileName={false}
                    excelFileName={InvestedAmountJson.excelFileName}
                    showAddButton={InvestedAmountJson.showAddButton}
                    tableColumnsToFilter={InvestedAmountJson.tableColumnsToFilter}
                    tableColumns={investedAmountColumns}
                    tableData={investedAmountList?.investedAmount}
                    getTableData={getInvestedAmount}
                    apiBaseURL={InvestedAmountJson.apiBaseURL}
                    baseIdColumn={InvestedAmountJson.baseIdColumn}
                    showDeleteIcon={true}
                    isActiveURLFalse={true}
                    isActiveURLFalseMethod={this.onChangeStatus}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    investedAmountList: state.investedAmount,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getInvestedAmount,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(InvestedAmountList);
