import endpoint from "../../config/endpoints";

export const ManagerJson = {
  formPath: "/manager-form",
  apiBaseURL: endpoint.manager,
  changeExcel: endpoint.exportData,
  isEditURL: endpoint.manager + "/update",
  isActiveURL: endpoint.manager + "/update-status",
  searchButtonGrid: 1,
  screenTitle: "Sales Manager",
  showAddButton: true,
  showPdfDownload: false,
  pdfFileName: "Manager",
  showExcelDownload: true,
  excelFileName: "Manager",
  excelUrl: "sales-manager-data",

  tableColumnsToFilter: [
    {
      columnDisplayName: "Person Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Address",
      columnKeyName: "address",
      isChecked: false,
    },
    {
      columnDisplayName: "Region",
      columnKeyName: "regionName",
      isChecked: true,
    },
    {
      columnDisplayName: "Zone",
      columnKeyName: "zoneNames",
      isChecked: true,
    },
    {
      columnDisplayName: "Area",
      columnKeyName: "areaNames",
      isChecked: true,
    },
    {
      columnDisplayName: "Email ID",
      columnKeyName: "emailId",
      isChecked: true,
    },
    {
      columnDisplayName: "Contact Number",
      columnKeyName: "contactNo",
      isChecked: true,
    },
    {
      columnDisplayName: "Whatsapp Number",
      columnKeyName: "whatsAppNo",
      isChecked: true,
    },
    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],
  searchList: [
    {
      label: "Type",
      controlType: "autocomplete",
      placeHolder: "Select Type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "stockStakeHolderTypeDetail",
      dataKey: "stockStakeHolderType",
      isRootLevelKey: true,
      isMandatory: true,
      disable: true,
    },

    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "regionDetail",
      dataKey: "region",
      getListId: "region",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "State",
      controlType: "autocomplete",
      placeHolder: "Select State",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "stateDetail",
      dataKey: "state",
      getListFrom: "region",
      getListId: "state",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "multiSelect",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "zoneDetail",
      dataKey: "zone",
      getListFrom: "state",
      getListId: "zone",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "District",
      controlType: "multiSelect",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "districtDetail",
      dataKey: "district",
      getListFrom: "zone",
      getListId: "district",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Taluka",
      controlType: "multiSelect",
      placeHolder: "Select Taluka",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "talukaDetail",
      dataKey: "taluka",
      getListFrom: "district",
      getListId: "taluka",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "areaDetail",
      dataKey: "area",
      getListFrom: "taluka",
      getListId: "area",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],

  fieldMeta: [
    {
      label: "Type",
      controlType: "autocomplete",
      placeHolder: "Select Type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stockStakeHolderTypeDetail",
      dataKey: "stockStakeHolderType",
      getListId: "stockStakeHolderType,zoneIds",
      isRootLevelKey: false,
      isMandatory: true,
      disable: true,
    },

    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "regionDetail",
      getListId: "region",
      dataKey: "region",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "multiSelect",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "zoneDetail",
      getListFrom: "region",
      getListId: "stockStakeHolderType,zoneIds",
      dataKey: "zoneIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      getListFrom: "zoneIds",
      dataKey: "areaIds",
      isRootLevelKey: true,
      isMandatory: true,
    },

    {
      label: "Person Name",
      controlType: "textfield",
      placeHolder: "Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "name",
      isMandatory: true,
    },
    {
      label: "Employee Code",
      controlType: "textfield",
      placeHolder: "Employee Code",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "employeeCode",
      isMandatory: true,
    },
    {
      label: "Address",
      controlType: "textfield",
      placeHolder: "Address",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "address",
      isMandatory: true,
    },
    {
      label: "Email Id",
      controlType: "textfield",
      placeHolder: "Email Id",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "emailId",
      valid: true,
      validName: "email",
      isMandatory: true,
    },
    {
      label: "Contact Number",
      controlType: "textfield",
      placeHolder: "Contact Number",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "contactNo",
      valid: true,
      validName: "phoneNo",
      isMandatory: true,
      sameAs: "whatsAppNo",
      sameAsKey: "whatsAppNoSame",
      sameAsLabel: "Same as Contact",
    },
    {
      label: "Watsapp Number",
      controlType: "textfield",
      placeHolder: "Whatsapp Number",
      maxLength: 10,
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "whatsAppNo",
      valid: true,
      validName: "phoneNo",
      isMandatory: true,
    },
  ],
};
