import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { otherTaskColumns } from "../../../tableColumns/table-columns";
import { OtherTaskJson } from "../../../DynamicFormsJson/MastersJSON/otherTaskJson";
import { getOtherTask } from "../../../Slice/otherTask.slice";

class OtherTaskTable extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { getOtherTask, otherTaskList } = this.props;
        return (
            <>
                <DynamicMainScreen
                    formPath={OtherTaskJson.formPath}
                    screenTitle={OtherTaskJson.screenTitle}
                    fieldMeta={OtherTaskJson.fieldMeta}
                    showPdfDownload={false}
                    showExcelDownload={false}
                    showExtension={false}
                    pdfFileName={OtherTaskJson.pdfFileName}
                    excelFileName={OtherTaskJson.excelFileName}
                    showAddButton={OtherTaskJson.showAddButton}
                    tableColumnsToFilter={OtherTaskJson.tableColumnsToFilter}
                    tableColumns={otherTaskColumns}
                    tableData={otherTaskList?.otherTask}
                    getTableData={getOtherTask}
                    apiBaseURL={OtherTaskJson.apiBaseURL}
                    baseIdColumn={OtherTaskJson.baseIdColumn}

                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    otherTaskList: state.otherTask,
});
const mapDispatchToProps = {
    getOtherTask,
};
export default connect(mapStateToProps, mapDispatchToProps)(OtherTaskTable);
