import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { ButtonCompo } from "../../components/Comman/Button";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { imageUrl } from "../../config/Url";
import {
  saveWarningMsg,
  saveAndCloseWarningMsg,
} from "../../config/messageconstant";
import endpoint from "../../config/endpoints";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { apiGet, apiPost } from "../../utils/api_service";
import {
  savemsg,
  saveFailedMsg,
  noInternetMsg,
} from "../../config/messageconstant";
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const GrievanceDialog = ({
  handleCloses,
  onSubmitRefreshList,
  open,
  showLoador,
  showNotification,
  fieldData = {},
  remark = "",
  responseRemark,
  onTextFieldDataChange,
}) => {
  const handleClose = () => {
    handleCloses();
  };

  const onSubmitRefresh = () => {
    onSubmitRefreshList();
  };

  const onSubmit = async () => {
    if (navigator.onLine) {
      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          onSave(fieldData.status);
        }
      });
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };
  const onSubmitAndClose = async () => {
    if (navigator.onLine) {
      swal({
        title: "Are you sure?",
        text: saveAndCloseWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          onSave(1);
        }
      });
    } else {
      showNotification({
        msg: noInternetMsg,
        severity: "error",
      });
    }
  };
  const onSave = async (status) => {
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiGet({
        url:
          endpoint.grievance +
          "/update?id=" +
          fieldData.id +
          "&responseRemark=" +
          responseRemark +
          "&status=" +
          status,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg });
          handleCloses();
          onSubmitRefresh();
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  const onClickViewPreview = (e, row) => {
    e.stopPropagation();
    window.open(imageUrl + row, "_blank");
  };

  return (
    <>
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="md"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Grievance Box
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid
              container
              justifyContent="space-evenly"
              direction="row"
              spacing={2}
              padding={4}
            >
              <div style={{ width: "100%", display: "flex" }}>
                <div
                  style={{
                    width: "70%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={2}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={3} md={3}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Complaint By : "
                      />
                    </Grid>

                    <Grid item xs={12} sm={9} md={9}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={
                          Object.keys(fieldData).length != 0
                            ? fieldData.userName
                            : "-"
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={2}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={3} md={3}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Date : "
                      />
                    </Grid>

                    <Grid item xs={12} sm={9} md={9}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={
                          Object.keys(fieldData).length != 0
                            ? fieldData.insertDateTime
                            : "-"
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={2}
                    columnSpacing={1}
                    container
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={3} md={3}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label="Complaint : "
                      />
                    </Grid>

                    <Grid item xs={12} sm={9} md={9}>
                      <LabelCompo
                        className="text-black"
                        style={{ fontSize: 16 }}
                        label={
                          Object.keys(fieldData).length != 0
                            ? fieldData.remark
                            : "-"
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
                <div style={{ width: "30%" }}>
                  {fieldData.docPic !== null && fieldData.docPic !== "" ? (
                    <img
                      className="Image10"
                      style={{ width: "100%", height: "150px" }}
                      src={imageUrl + fieldData.docPic}
                      onClick={(e) => onClickViewPreview(e, fieldData.docPic)}
                    />
                  ) : (
                    "No Image"
                  )}
                </div>
              </div>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              />

              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={1}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} sm={2} md={2}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, padding: 0 }}
                    label="Remark : "
                    controlType="textfield"
                  />
                </Grid>

                <Grid item xs={12} sm={10} md={10}>
                  <TextFieldCompo
                    placeHolder={"Remark"}
                    size="small"
                    color="primary"
                    name={"remark"}
                    id={"remark"}
                    value={responseRemark}
                    type={"text"}
                    onChange={onTextFieldDataChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                rowSpacing={2}
                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                container
                justifyContent="right"
              >
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Save"
                  onClick={onSubmit}
                />
                &nbsp;&nbsp;
                {fieldData.status == 0 && (
                  <ButtonCompo
                    size="medium"
                    type="submit"
                    variant="contained"
                    name="Save & Close"
                    onClick={onSubmitAndClose}
                  />
                )}
              </Grid>
            </Grid>

            <br />
          </DialogContent>
        </BootstrapDialog>
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  showLoador,
  showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(GrievanceDialog);
