import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import { OutletRateStructureColumns } from "../../tableColumns/table-columns";
import { outletRateStructureJson } from "../../DynamicFormsJson/Transaction/OutletRateStructure";
import {
  getCategoryForRateConfig,
  getOutletConfigurationList,
} from "../../Slice/outletRateStructure.slice";
import { serverMsg, noInternetMsg } from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
class OutletRateStructureTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        categoryDetail: this.props.categoryList?.category,
      },
    };
  }
  
  async componentDidMount() {
    const { getOutletConfigurationList, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getOutletConfigurationList().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.categoryList !== nextProps.categoryList) {
      if (
        this.props.categoryList?.category !== nextProps.categoryList?.category
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            categoryDetail: nextProps.categoryList?.category,
          },
        });
      }
    }
  }

  onSearchData = async (data) => {
    const { getCategoryForRateConfig, showLoador, showNotification } =
      this.props;
    if (data.category) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        getCategoryForRateConfig({
          categoryIds: data.category.join(),
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };
  render() {
    const { outletRateStructure } = this.props;
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          showDeleteIcon={false}
          dynamicMasterData={dynamicMasterData}
          searchList={outletRateStructureJson.searchList}
          onSearchData={this.onSearchData}
          formPath={outletRateStructureJson.formPath}
          screenTitle={outletRateStructureJson.screenTitle}
          fieldMeta={outletRateStructureJson.fieldMeta}
          showPdfDownload={outletRateStructureJson.showPdfDownload}
          showExcelDownload={outletRateStructureJson.showExcelDownload}
          pdfFileName={outletRateStructureJson.pdfFileName}
          excelFileName={outletRateStructureJson.excelFileName}
          showAddButton={outletRateStructureJson.showAddButton}
          tableColumnsToFilter={outletRateStructureJson.tableColumnsToFilter}
          tableColumns={OutletRateStructureColumns}
          tableData={outletRateStructure?.rateConfigForOutlet}
          apiBaseURL={outletRateStructureJson.apiBaseURL}
          callApi={false}
          showPegination={false}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stockStakeHolderType: state.stockStakeHolderType,
  categoryList: state.category,
  outletRateStructure: state.outletRateStructure,
});
const mapDispatchToProps = {
  getCategoryForRateConfig,
  getOutletConfigurationList,
  showLoador,
  showNotification,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutletRateStructureTable);
