import endpoint from "../../config/endpoints";
import dayjs from "dayjs";

export const AssetsImplementationJson = {
    showSaveNextBtn: true,
    apiBaseURL: endpoint.area,
    screenTitle: "Assets Implementation",

    searchListByAssets: [
        {
            label: "Search By",
            controlType: "radiogroup",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            getListId: "flag",
            dataKey: "flag",
            tableDataKey: "Assets Wise",
            defaultValue: "0",
            radioGroupItems: [
                {
                    label: "Assets Wise",
                    value: "0",
                },
                {
                    label: "Area Wise",
                    value: "1",
                },
            ],
            isMandatory: true,
        },

        {
            label: "Assets",
            controlType: "multiSelect",
            placeHolder: "Select Assets",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            dataKey: "assets",
            masterName: "assetsDetail",
            isRootLevelKey: true,
            isMandatory: true,
        },
    ],
    searchListByArea: [
        {
            label: "Search By",
            controlType: "radiogroup",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            getListId: "flag",
            dataKey: "flag",
            tableDataKey: "Assets wise",
            defaultValue: "0",
            radioGroupItems: [
                {
                    label: "Assets wise",
                    value: "0",
                },
                {
                    label: "Area wise",
                    value: "1",
                },
            ],
            isMandatory: true,
        },

        {
            label: "Region",
            controlType: "autocomplete",
            placeHolder: "Select Region",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "regionDetail",
            dataKey: "region",
            getListId: "region",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "State",
            controlType: "autocomplete",
            placeHolder: "Select State",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "stateDetail",
            dataKey: "state",
            getListFrom: "region",
            getListId: "state",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Zone",
            controlType: "multiSelect",
            placeHolder: "Select Zone",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "zoneDetail",
            dataKey: "zoneIds",
            getListFrom: "state",
            getListId: "zoneIds",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "District",
            controlType: "multiSelect",
            placeHolder: "Select District",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "districtDetail",
            dataKey: "districtIds",
            getListFrom: "zoneIds",
            getListId: "districtIds",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Taluka",
            controlType: "multiSelect",
            placeHolder: "Select Taluka",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "talukaDetail",
            dataKey: "talukaIds",
            getListFrom: "districtIds",
            getListId: "talukaIds",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Area",
            controlType: "autocomplete",
            placeHolder: "Select Area",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "areaDetail",
            dataKey: "area",
            getListFrom: "talukaIds",
            getListId: "area",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Beat",
            controlType: "multiSelect",
            placeHolder: "Select Beat",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "beatDetail",
            dataKey: "beatIds",
            getListFrom: "area",
            getListId: "beatIds",
            isRootLevelKey: true,
            isMandatory: true,
        },
        {
            label: "Outlet",
            controlType: "multiSelect",
            placeHolder: "Select Outlet",
            md: 6,
            lg: 6,
            sm: 6,
            xs: 12,
            labelSM: 2,
            labelMD: 2,
            labelLG: 2,
            valueSM: 10,
            valueMD: 10,
            valueLG: 10,
            masterName: "outletDetail",
            dataKey: "outletIds",
            getListFrom: "beatIds",
            getListId: "outletIds",
            isRootLevelKey: true,
            isMandatory: true,
        },
    ],
};
