import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import * as React from "react";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { onlyNumber } from "../../components/Comman/Util/Validations";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, tableHead, orderBy } = props;

  return (
    <TableHead>
      <TableRow>
        {tableHead.map((headCell) => {
          if (headCell.showInscreen && headCell.title !== "Action") {
            return (
              <TableCell
                key={headCell.name}
                align={"center"}
                style={{ minWidth: headCell.width }}
              >
                {headCell.title}
                {orderBy === headCell.name ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableCell>
            );
          }
          return null;
        })}
      </TableRow>
    </TableHead>
  );
}
function Row({ row, index, rowList, setList }) {
  const onTextFieldDataChange = (dataKey, productId) => (event) => {
    const { value } = event.target;
    console.log(rowList);
    console.log(value);
    if (
      value === "" ||
      onlyNumber.test(value) ||
      (dataKey == "itemDiscPer" && value !== "e" && +value >= 0)
    ) {
      const ListToSet = rowList.map((rowD) => {
        if (rowD.product.id == productId) {
          rowD = {
            ...rowD,
            [dataKey]: value,
          };
          return rowD;
        } else {
          return rowD;
        }
      });
      console.log(ListToSet);
      setList({ row: ListToSet });
    }
  };
  console.log(row);
  return (
    <TableRow key={row.name}>
      <TableCell style={{ width: "8%" }}>
        <center>{index + 1}</center>
      </TableCell>
      <TableCell style={{ width: "20%" }}>
        {row.productName != null ? row.productName : "-"}
      </TableCell>
      <TableCell style={{ width: "10%" }}>
        <center>
          {row.product != null && row.product.stakeHolderUom != null
            ? row.product.stakeHolderUom.name
            : "-"}
        </center>
      </TableCell>
      <TableCell align="right" style={{ width: "10%" }}>
        {row.rate}
      </TableCell>
      <TableCell align="right" style={{ width: "10%" }}>
        {row.qty}
      </TableCell>
      <TableCell align="right" style={{ width: "10%" }}>
        {row.secQty}
      </TableCell>
      <TableCell style={{ width: "10%" }}>
        <TextFieldCompo
          placeHolder={"primaryQty"}
          size="small"
          color="primary"
          type="number"
          id={"1"}
          value={row.primaryQty}
          onChange={onTextFieldDataChange("primaryQty", row.product.id)}
          fullWidth={false}
          error={row.qtyError ? true : false}
          errorText={
            row.qtyError ? "Quantity Should be less than Bill Quantity" : ""
          }
        />
      </TableCell>
      <TableCell style={{ width: "10%" }}>
        <TextFieldCompo
          placeHolder={"secondaryQty"}
          size="small"
          color="primary"
          type="number"
          id={"1"}
          value={row.secondaryQty}
          onChange={onTextFieldDataChange("secondaryQty", row.product.id)}
          fullWidth={false}
          error={row.qtyError ? true : false}
          errorText={
            row.qtyError ? "Quantity Should be less than Bill Quantity" : ""
          }
        />
      </TableCell>
      <TableCell align="right" style={{ width: "10%" }}>
        {row.totalQty}
      </TableCell>
      <TableCell align="right" style={{ width: "10%" }}>
        {row.totalAmtForPendingGrn}
      </TableCell>
    </TableRow>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function MaterialUITableWithTextField({
  tableHead = [],
  stockHolderAndProductConfiguration,
  setList,
  rowList = [],
  data = [],
  flag,
  freeQtyFlag = 1,
  manualOrderAdminPrimary,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            tableHead={tableHead}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            rowCount={data.length}
          />
          <TableBody>
            {stableSort(data, getComparator(order, orderBy)).map(
              (row, index) => {
                return (
                  <Row
                    freeQtyFlag={freeQtyFlag}
                    manualOrderAdminPrimary={manualOrderAdminPrimary}
                    rowList={rowList}
                    row={row}
                    setList={setList}
                    index={index}
                    flag={flag}
                    stockHolderAndProductConfiguration={
                      stockHolderAndProductConfiguration
                    }
                  />
                );
              }
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={tableHead.length} />
              </TableRow>
            )}
            {data.length === 0 && (
              <TableRow
                style={{
                  height: 53 * 1,
                }}
              >
                <TableCell align="center" colSpan={tableHead.length}>
                  No Record Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
