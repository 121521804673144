import {
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ButtonCompo } from "../../components/Comman/Button";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import CheckBoxTable from "./PendingGrnDetailsTextfeild";
import { onlyNumber } from "../../components/Comman/Util/Validations";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
  serverMsg,
} from "../../config/messageconstant";
import { pendingGrnListJSON } from "../../DynamicFormsJson/Transaction/pendingGrnList";
import {
  getGRNHeaderById,
  getGRNHeaderDetailsById,
  setRequestGRNDetails,
} from "../../Slice/requestGRN.slice";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { LabelCompo } from "../../components/Comman/Label";
import { redColor, titleColor } from "../../config/ColorObj";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";

class RequestGRNEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      billProductList: [],
      headerData: {},
      DataToSet: [],
      calStock: 1,
      remark: "",
      companyFlagOfc: false,
    };
  }

  async componentDidMount() {
    const {
      getGRNHeaderDetailsById,
      getGRNHeaderById,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      if (this.props.params.id) {
        showLoador({ loador: true });
        await getGRNHeaderById({
          requestGRNHeaderId: this.props.params.id,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
          } else {
            this.setState({
              headerData: response,
              remark: response.remark !== null ? response.remark : "",
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Firm Name",
                  value:
                    response.fromStockStakeHolderFirmName !== null
                      ? response.fromStockStakeHolderFirmName
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Owner Name",
                  value:
                    response.fromStockStakeHolderOwnerName !== null
                      ? response.fromStockStakeHolderOwnerName
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "GRN Number",
                  value: response.grnGvnNo !== null ? response.grnGvnNo : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Date",
                  value: response.date !== null ? response.date : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Taxable Amount",
                  value:
                    response.taxableAmt !== null ? response.taxableAmt : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Total Amount",
                  value: response.totalAmt !== null ? response.totalAmt : "",
                },

                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Total Tax",
                  value:
                    response.totalAmt !== null && response.taxableAmt !== null
                      ? (+response.totalAmt - +response.taxableAmt).toFixed(2)
                      : "",
                },

                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Remark",
                  value: response.remark !== null ? response.remark : "",
                },
              ],
            });
          }
        });
        showLoador({ loador: true });
        await getGRNHeaderDetailsById({
          requestGRNHeaderId: this.props.params.id,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
          } else {
            const showCompanyFlag = localStorage.getItem("showCompanyFlag");

            if (showCompanyFlag == 0) {
              this.setState({
                companyFlagOfc: true,
              });
            }
          }

        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.requestGRNList !== nextProps.requestGRNList) {
      if (
        this.props.requestGRNList.requestGRNHeaderDetails !==
        nextProps.requestGRNList.requestGRNHeaderDetails
      ) {
        this.setState({
          billProductList: nextProps.requestGRNList.requestGRNHeaderDetails,
        });
      }
    }
  }
  onSubmit = (status) => () => {
    const { billProductList, headerData, calStock, remark } = this.state;
    const { showNotification, showLoador, requestGRNList } = this.props;
    let error = false;
    const rowList = requestGRNList.requestGRNHeaderDetails.map((rowObj) => {
      console.log(rowObj);
      if (status == 2) {
        if (!rowObj.qtyError) {
          rowObj = {
            ...rowObj,
            secondaryApprovedQty: +rowObj.secondaryQty,
          };
          return rowObj;
        }
      } else {
        if (!rowObj.qtyError) {
          rowObj = {
            id: rowObj.id,
            product: {
              id: rowObj.product.id,
              taxPercentage: {
                id: rowObj.product.taxPercentage.id,
              },
            },
            uom: {
              id: rowObj.product.stakeHolderUom.id,
            },
            qty: rowObj.qty,
            primaryQty: rowObj.qty, 
            approvedQty: rowObj.qty,
            secondaryApprovedQty: rowObj.secondaryQty,
            uomConversionValue: rowObj.uomConversionValue,
            hsnCode: rowObj.hsnCode,
            mrp: rowObj.mrp,
            rate: rowObj.rate,
            batchCode: rowObj.batchCode,
            billDetailId: "0",
            stockStakeHolderBillDetailId: "0",
          };
          return rowObj;
        }
      }
    });
    // this.props.setRequestGRNDetails({
    //   row: rowList,
    // });
    console.log(rowList);
    const data = {
      ...headerData,
      instockOutstock: calStock,
      grnGvnDetails: rowList,
      remark: remark,
    };
    console.log(data);
    if (!error) {
      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          if (navigator.onLine) {
            showLoador({ loador: true });
            await apiPost({
              url:
                status == 2
                  ? endpoint.grnGVNHeader + "/approved-grn-gvn"
                  : endpoint.grnGVNHeader +
                  "/approved-grn-gvn-and-generate-credit-note-new",
              postBody: data,
            }).then(({ success }) => {
              if (success) {
                showLoador({ loador: false });
                showNotification({
                  msg: savemsg,
                });
                this.props.navigate(-1);
              } else {
                showNotification({
                  msg: saveFailedMsg,
                  severity: "error",
                });
                showLoador({ loador: false });
              }
            });
          } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
          }
        }
      });
    }
  };
  handleChange = (event) => {
    this.setState({
      calStock: event.target.value,
    });
  };

  changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "remark") {
      this.setState({ remark: value });
    }
  };

  render() {
    const { DataToSet, calStock, remark, companyFlagOfc } = this.state;
    const { requestGRNList, setRequestGRNDetails } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            showTitle={pendingGrnListJSON.showTitle}
            screenTitle={pendingGrnListJSON.screenTitle}
            showSaveBtnMain={false}
            showAddButton={false}
            showBackButton={true}
            onCancel={() => this.props.navigate(-1)}
          />
          <br />
          <DynamicDetails DataToSet={DataToSet} />
          <br />

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-between"
          >
            <Grid item xs={12} md={2.5} sm={2.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: redColor }}
                label="* "
              />
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: titleColor }}
                label="Is calculate in stock :"
              />
            </Grid>
            <Grid item xs={12} sm={9.5} md={9.5}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="calStock"
                value={calStock}
                onChange={this.handleChange}
                defaultValue={calStock}
              >
                <FormControlLabel value={1} control={<Radio />} label="Yes" />
                <FormControlLabel value={0} control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
          </Grid>

          <br />
          <CheckBoxTable
            tableHead={[
              {
                title: "Sr.No.",
                name: "index",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Product Name",
                name: "productName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "UOM",
                name: "outletUomName",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "center",
              },
              {
                title: "Rate",
                name: "rate",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
              },
              {
                title: "Primary Quantity",
                name: "qty",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
              },
              {
                title: "Secondary Quantity",
                name: "qty",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
              },
              {
                title: "Approve Primary Qty",
                name: "primaryQty",
                placeHolder: "Enter Approved Quantity",
                textFieldError: "approvedQtyError",
                error: "Approved Quantity",
                textField1: true,
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "Approve Secondary Qty",
                name: "secondaryQty",
                placeHolder: "Enter Approved Quantity",
                textFieldError: "approvedQtyError",
                error: "Approved Quantity",
                textField: true,
                positionCenter: false,
                showInscreen: true,
                alignCenter: "left",
              },
              {
                title: "Total Approved Quantity",
                name: "totalQty",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
              },
              {
                title: "Total Amount",
                name: "totalAmtForPendingGrn",
                positionCenter: false,
                showInscreen: true,
                alignCenter: "right",
              },
            ]}
            data={requestGRNList.requestGRNHeaderDetails}
            rowList={requestGRNList.requestGRNHeaderDetails}
            setList={setRequestGRNDetails}
          />
          <br />

          <Grid container alignItems="center">
            <Grid item xs={12} md={2.5} sm={2.5}>
              <LabelCompo
                className="text-black"
                style={{ fontSize: 16, color: titleColor, marginLeft: 15 }}
                label=" Add Comment :"
              />
            </Grid>
            <Grid item xs={12} md={9.5} sm={9.5}>
              <TextFieldCompo
                placeholder="Add Comment"
                color="primary"
                name="remark"
                size="small"
                value={remark}
                fullWidth
                onChange={this.changeHandler}
              />
            </Grid>
          </Grid>
          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            rowSpacing={2}
            columnSpacing={{ xs: 12, sm: 6, md: 1 }}
            container
            justifyContent="right"
          >
            {!companyFlagOfc && (
              <>
                <ButtonCompo
                  size="medium"
                  type="submit"
                  variant="contained"
                  name="Approved"
                  onClick={this.onSubmit(2)}
                />
              </>
            )}
            &nbsp;&nbsp;
            <ButtonCompo
              size="medium"
              type="submit"
              variant="contained"
              name="Approved & Generate CRN"
              onClick={this.onSubmit(3)}
            />
          </Grid>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  requestGRNList: state.requestGRN,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getGRNHeaderDetailsById,
  getGRNHeaderById,
  setRequestGRNDetails,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RequestGRNEdit);
