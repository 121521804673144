import React from "react";
import { connect } from "react-redux";
import { bankJson } from "../../../DynamicFormsJson/MastersJSON/bank";
import { getBankDetails } from "../../../Slice/bankDetails.slice";
import { getArea, getAreaByTalukaPostBody } from "../../../Slice/area.slice";
import { getDistrictByZone } from "../../../Slice/district.slice";
import { getRegion } from "../../../Slice/region.slice";
import { getStateByRegion } from "../../../Slice/state.slice";
import { getTalukaByDistrict } from "../../../Slice/taluka.slice";
import { getZoneByState } from "../../../Slice/zone.slice";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import {
  noInternetMsg,
  serverMsg,
  statusmsg,
} from "../../../config/messageconstant";
import { bankDetailsColums } from "../../../tableColumns/table-columns";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { apiGet } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import { withRouter } from "../../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
class BankTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {},
    };
  }
  async componentDidMount() {}
  onChangeStatus = (rowData) => {
    console.log(rowData);
    if (rowData.isActive == 0) {
      const { showLoador, showNotification } = this.props;
      if (navigator.onLine) {
        showLoador({ loador: true });
        apiGet({
          url:
            endpoint.bankDetails +
            "/update-is-active?id=" +
            rowData.id +
            "&status=1",
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            showNotification({ msg: statusmsg });
            window.location.reload();
          }
        });
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    } else {
      showNotification({ msg: serverMsg, severity: "error" });
    }
  };
  render() {
    const { bankDetailsList, getBankDetails } = this.props;
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicMainScreen
          dynamicMasterData={dynamicMasterData}
          formPath={bankJson.formPath}
          screenTitle={bankJson.screenTitle}
          showPdfDownload={false}
          showExcelDownload={false}
          showExtension={false}
          pdfFileName={false}
          excelFileName={bankJson.excelFileName}
          showAddButton={bankJson.showAddButton}
          tableColumnsToFilter={bankJson.tableColumnsToFilter}
          tableColumns={bankDetailsColums}
          tableData={bankDetailsList?.bankDetails}
          getTableData={getBankDetails}
          getTableDataById={true}
          apiBaseURL={bankJson.apiBaseURL}
          baseIdColumn={bankJson.baseIdColumn}
          isNote={false}
          showDeleteIcon={true}
          isActionColActive={true}
          isActiveURLFalse={true}
          isActiveURLFalseMethod={this.onChangeStatus}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  bankDetailsList: state.bankDetails,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getBankDetails,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BankTable);
