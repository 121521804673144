import endpoint from "../../config/endpoints";

export const settleBillFormJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.assignRateConfigurationToStakeholder,
  formPath: "/settle-bill",
  formPathView: "/payment-list",
  screenTitle: "Settle Bill",
  showTitle: true,
  showCancel: false,
  showBackToList: false,
  showSaveBtn: false,
  fieldMeta: [
    // {
    //   label: "Area",
    //   controlType: "autocomplete",
    //   placeHolder: "Select Area ",
    //   md: 4,
    //   lg: 4,
    //   sm: 4,
    //   xs: 12,
    //   masterName: "areaDetail",
    //   dataKey: "area",
    //   isRootLevelKey: true,
    //   isMandatory: true,
    //   getListId: "area",
    // },
    // {
    //   label: "Beat ",
    //   controlType: "autocomplete",
    //   placeHolder: "Select Beat",
    //   md: 4,
    //   lg: 4,
    //   sm: 4,
    //   xs: 12,
    //   masterName: "beatDetail",
    //   dataKey: "beat",
    //   getListFrom: "area",
    //   isRootLevelKey: false,
    //   isMandatory: true,
    //   getListId: "beat",
    // },
    {
      label: "Outlet ",
      controlType: "autocomplete",
      placeHolder: "Select Outlet",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "outletDetail",
      dataKey: "outlet",
      getListId: "outlet",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Payment Receipt",
      controlType: "autocomplete",
      placeHolder: "Select Payment Receipt",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      masterName: "paymentRecTranDetail",
      dataKey: "paymentRecTran",
      getListFrom: "outlet",
      isRootLevelKey: false,
      isMandatory: true,
      getListId: "paymentRecTran",
      disableWithCondition: true,
    },
  ],
  fieldMetaForPayment: [
    // {
    //   label: "Area",
    //   controlType: "autocomplete",
    //   placeHolder: "Select Area ",
    //   md: 4,
    //   lg: 4,
    //   sm: 4,
    //   xs: 12,
    //   masterName: "areaDetail",
    //   dataKey: "area",
    //   isRootLevelKey: true,
    //   isMandatory: true,
    //   getListId: "area",
    // },
    // {
    //   label: "Beat ",
    //   controlType: "autocomplete",
    //   placeHolder: "Select Beat",
    //   md: 4,
    //   lg: 4,
    //   sm: 4,
    //   xs: 12,
    //   masterName: "beatDetail",
    //   dataKey: "beat",
    //   getListFrom: "area",
    //   isRootLevelKey: false,
    //   isMandatory: true,
    //   getListId: "beat",
    // },
    {
      label: "Outlet ",
      controlType: "autocomplete",
      placeHolder: "Select Outlet",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      masterName: "outletDetail",
      dataKey: "outlet",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Amount",
      controlType: "textfield",
      placeHolder: "Amount",
      inputType: "number",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "amount",
      isMandatory: true,
      disableWithCondition: true,
    },
  ],
  
  searchList: [
    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "area",
      getListId: "area",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Beat",
      controlType: "autocomplete",
      placeHolder: "Select Beat",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "beat",
      masterName: "beatDetail",
      getListFrom: "area",
      getListId: "beat",
      isMandatory: true,
      isRootLevelKey: false,
    },
    {
      label: "Outlet",
      controlType: "autocomplete",
      placeHolder: "Select Outlet",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "outlet",
      getListFrom: "beat",
      masterName: "outletDetail",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      isMandatory: true,
    },
  ],
};
