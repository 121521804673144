import React from "react";
import { connect } from "react-redux";
import { DistributionReportsJson } from "../../DynamicFormsJson/Transaction/DynamicReport";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicReportMainWithQuery";

class DistributionReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  


  render() {
    return (
      <>
        <DynamicMainScreen
          screenTitle={DistributionReportsJson.screenTitle}
          fieldMeta={DistributionReportsJson.fieldMeta}
          buttonCenter={DistributionReportsJson.buttonCenter}
          searchButton={DistributionReportsJson.searchButton}
          PDFButton={DistributionReportsJson.PDFButton}
          EXCELButton={DistributionReportsJson.EXCELButton}
          baseIdColumn={DistributionReportsJson.baseIdColumn}
          apiBaseURL={DistributionReportsJson.apiBaseURL}
          marginTop={DistributionReportsJson.marginTop}
          openNewTab={true}
          rowData={this.rowData}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
 
});
const mapDispatchToProps = {

};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributionReports);
