import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { beatJson } from "../../../DynamicFormsJson/MastersJSON/beat";
import { getAreaByUserType } from "../../../Slice/area.slice";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { serverMsg, noInternetMsg } from "../../../config/messageconstant";
import { apiGet } from "../../../utils/api_service";
import { withRouter } from "../../../components/withRouter";
import { compose } from "@reduxjs/toolkit";

class BeatForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        areaForBeatDetail: this.props.areaForBeatList?.area,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.areaForBeatList !== nextProps.areaForBeatList) {
      if (
        this.props.areaForBeatList?.area !== nextProps.areaForBeatList?.area
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaForBeatDetail: nextProps.areaForBeatList?.area,
          },
        });
      }
    }
  }

  async componentDidMount() {
    const { getAreaByUserType, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getAreaByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }


  callBeforeSaveMethod = async (dataToSave, editData) => {

    if (
      Object.keys(editData).length == 0 ||
      (Object.keys(editData).length != 0 &&
        editData.beatName != dataToSave.beatName)
    ) {
      const { showLoador, showNotification } = this.props;
      if (navigator.onLine) {
        showLoador({ loador: true });
        const response = await apiGet({
          url:
            beatJson.apiBaseURL +
            "/beat-name-exist-or-not-by-area?areaId=" +
            dataToSave.area.id +
            "&beatName=" +
            dataToSave.beatName,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            if (!data.data.error) {
              showNotification({
                msg: data.data.message,
                severity: "error",
              });
            }
            return data.data.error;
          }
          return false;
        });
        return response;
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
    return true;
  };


  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicForm
          callBeforeSave={true}
          screenTitle={beatJson.screenTitle}
          fieldMeta={beatJson.fieldMeta}
          callBeforeSaveMethod={this.callBeforeSaveMethod}
          formPath={beatJson.formPath}
          dynamicMasterData={dynamicMasterData}
          apiBaseURL={beatJson.apiBaseURL}
          showSaveNextBtn={beatJson.showSaveNextBtn}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  areaForBeatList: state.area,
});
const mapDispatchToProps = {
  getAreaByUserType,
  showLoador,
  showNotification,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BeatForm);
