import PieChart from "../DashbourdChanges/PieChart";
import {
  Box, Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import dayjs from "dayjs";
import React, { Component } from "react";
import { connect } from "react-redux";
import "../../CSS/app.min.css";
import "../../CSS/bootstrap.min.css";
import "../../CSS/custom.min.css";
import "../../CSS/mermaid.min.css";
import "../../CSS/slickDashboard.css";
import { onToggle } from "../../CommonActions/common.slice";
import image4 from "../../Images/image4.png";
import target1 from "../../Images/target1.png";
import { ConvertAmount } from "../../components/Comman/ConvertValueWithDenominator";
import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import { getManagerDashboardApi } from "../../Slice/managerDashboard.slice";
import AutoComplete from "../../components/Comman/AutoComplete";
import MonthCalender from "../../components/MonthCalender";
import * as myConstClass from "../../config/messageconstant";
import MainCard from "../DashbourdChanges/MainCard";
import MonthlyBarChart from "../DashbourdChanges/MonthlyBarChart";
import NoDataFound from "../DashbourdChanges/NoDataFound";
import OrdersTable from "../DashbourdChanges/OrdersTable";
import DonutChart from "../DashbourdChanges/DonutChart";
let salesGraphList = [
  {
    id: "31a22011-61bb-11ee-9b93-000c29c9f32f",
    month: "4",
    monthName: "April",
    monthYear: "4-2023",
    totalAmount: "5000",
  },
  {
    id: "31a22011-61bb-11ee-9b93-000c29c9f32f",
    month: "5",
    monthName: "May",
    monthYear: "5-2023",
    totalAmount: "4000",
  },
  {
    id: "31a22011-61bb-11ee-9b93-000c29c9f32f",
    month: "6",
    monthName: "June",
    monthYear: "6-2023",
    totalAmount: "7000",
  },
  {
    id: "31a22011-61bb-11ee-9b93-000c29c9f32f",
    month: "7",
    monthName: "July",
    monthYear: "7-2023",
    totalAmount: "3000",
  },
  {
    id: "31a22011-61bb-11ee-9b93-000c29c9f32f",
    month: "8",
    monthName: "August",
    monthYear: "8-2023",
    totalAmount: "28977",
  },
  {
    id: "31a22011-61bb-11ee-9b93-000c29c9f32f",
    month: "9",
    monthName: "September",
    monthYear: "8-2023",
    totalAmount: "4000",
  },
  {
    id: "31a22011-61bb-11ee-9b93-000c29c9f32f",
    month: "10",
    monthName: "October",
    monthYear: "8-2023",
    totalAmount: "10000",
  },
];
// let timeSlots = [
//   {
//     "id": "205e964c-2e55-4a28-b93d-8570a2ceac41",
//     "fromTime": "02:00 PM",
//     "toTime": "04:00 PM",
//     "isActive": 1,
//     "insertDateTime": "04-06-2024 04:16:39",
//     "managerLogsCount": {
//       "id": "1aa4c154-cac4-4d9a-8904-1b1965bf2b87",
//       "retailingCount": 2,
//       "distributorCount": 0,
//       "officeWorkCount": 0,
//       "jointWorkCount": 0,
//       "workNotStarted": 0,
//       "totalCount": 0
//     }
//   },
//   {
//     "id": "8df616a8-25dd-4c5c-a77f-173681c62e64",
//     "fromTime": "12:00 PM",
//     "toTime": "10:00 PM",
//     "isActive": 1,
//     "insertDateTime": "04-06-2024 12:48:18",
//     "managerLogsCount": {
//       "id": "01c324cc-0233-44d5-9825-e0b7f789e384",
//       "retailingCount": 4,
//       "distributorCount": 0,
//       "officeWorkCount": 0,
//       "jointWorkCount": 0,
//       "workNotStarted": 0,
//       "totalCount": 0
//     }
//   }
// ];

class RMDashboard extends Component {
  toggleDrawer = () => {
    this.props.onToggle();
  };
  state = {
    showFeedback: false,
    showPreview: false,
    date1: new Date(),
    newDate: "",
    currentDate: new Date(),
    curDate: new Date(),
    dob: "",
    dobDay: "",
    dobMonth: "",
    currenDate: "",
    currentMonth: "",
    timeTableList: [],
    subject: "",
    batch: "",
    yearDetail: "",
    semester: "",
    division: "",
    pieChartData: [],
    isLoading: false,
    showLoader: false,
    profile: "",
    nameFirstLetter: "",
    showZone: false,
    showArea: false,
    date: "",
    saleDate: "",
    outletDate: "",
    monthDate: "",
    yearDate: "",
    type: "",
    saleMonth: dayjs(),
    saleYear: "",
    month: "",
    year: "",
    productArea: "",
    productZone: "",
    outletArea: "",
    outletZone: "",
    formErrors: {},
    graphList: [],
    monthList: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    radioValue: [
      {
        name: "Target",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
      },
      {
        name: "Achieved",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      },
    ],
    columns: [
      { id: "name", label: "Name", minWidth: 170 },
      { id: "code", label: "ISO\u00a0Code", minWidth: 100 },
      {
        id: "population",
        label: "Population",
        minWidth: 170,
        align: "right",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "size",
        label: "Size\u00a0(km\u00b2)",
        minWidth: 170,
        align: "right",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "density",
        label: "Density",
        minWidth: 170,
        align: "right",
        format: (value) => value.toFixed(2),
      },
    ],
    rows: [
      this.createData("India", "IN", 1324171354, 3287263),
      this.createData("China", "CN", 1403500365, 9596961),
      this.createData("Italy", "IT", 60483973, 301340),
      this.createData("United States", "US", 327167434, 9833520),
      this.createData("Canada", "CA", 37602103, 9984670),
      this.createData("Australia", "AU", 25475400, 7692024),
      this.createData("Germany", "DE", 83019200, 357578),
      this.createData("Ireland", "IE", 4857000, 70273),
      this.createData("Mexico", "MX", 126577691, 1972570),
      this.createData("Japan", "JP", 126317000, 377973),
      this.createData("France", "FR", 67022000, 640679),
      this.createData("United Kingdom", "GB", 67545757, 242495),
      this.createData("Russia", "RU", 146793744, 17098246),
      this.createData("Nigeria", "NG", 200962417, 923768),
      this.createData("Brazil", "BR", 210147125, 8515767),
    ],
    rowsPerPage: 10,
    page: 0,
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0,
    });
  };

  createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }

  async componentDidMount() {
    const {
      showLoador,
      showNotification,
      getManagerDashboardApi,
    } = this.props;
    if (navigator.onLine) {

      // showLoador({ loador: true });
      // await getManagerDashboardApi({ date: dayjs().format("DD-MM-YYYY") }).then(({ response, success }) => {
      //   showLoador({ loador: false });
      //   if (!success) {
      //     showNotification({
      //       msg: myConstClass.serverMsg,
      //       severity: "error",
      //     });
      //   } else {
      //     console.log(response);
      //     if (response !== null && Object.keys(response).length !== 0) {
      //       // let totalOfRemainingAmt = 0;
      //       // response.dtoOutletPendingBill.map((dtoOutletPendingBillObj) => {
      //       //   totalOfRemainingAmt =
      //       //     totalOfRemainingAmt + +dtoOutletPendingBillObj.remainingAmt;
      //       // });
      //     }
      //   }
      // });

      // await getAreaByUserType().then(({ success }) => {
      //   showLoador({ loador: false });
      //   if (!success) {
      //     // showNotification({ msg: serverMsg, severity: "error" });
      //   }
      // });

      // showLoador({ loador: true });
      // await getZoneByUserType().then(({ success }) => {
      //   showLoador({ loador: false });
      //   if (!success) {
      //     // showNotification({ msg: serverMsg, severity: "error" });
      //   }
      // });

      // showLoador({ loador: true });
      // await getCurrentYearTargetForSales().then(({ success }) => {
      //   showLoador({ loador: false });
      //   if (!success) {
      //     // showNotification({ msg: serverMsg, severity: "error" });
      //   }
      // });

      // showLoador({ loador: true });
      // await getStockStakeHolderTypeForList().then(({ response, success }) => {
      //   showLoador({ loador: false });
      //   if (!success) {
      //     // showNotification({ msg: serverMsg, severity: "error" });
      //   } else {
      //     if (response.length !== 0) {
      //       this.setState({
      //         type: response[0].id,
      //       });
      //       showLoador({ loador: true });
      //       getSalesListFromYearAndMonth({
      //         month: dayjs().format("MM"),
      //         year: dayjs().format("YYYY"),
      //         stockStakeHolderTypeId: response[0].id,
      //       }).then(({ success }) => {
      //         showLoador({ loador: false });
      //         if (!success) {
      //           // showNotification({ msg: serverMsg, severity: "error" });
      //         }
      //       });
      //     }
      //   }
      // });

      // showLoador({ loador: true });
      // await getCount().then(({ success }) => {
      //   showLoador({ loador: false });
      //   if (!success) {
      //     // showNotification({ msg: serverMsg, severity: "error" });
      //   }
      // });

      // showLoador({ loador: true });
      // await getOutletListFromYearAndMonth({
      //   month: dayjs().format("MM"),
      //   year: dayjs().format("YYYY"),
      // }).then(({ success }) => {
      //   showLoador({ loador: false });
      //   if (!success) {
      //     // showNotification({ msg: serverMsg, severity: "error" });
      //   }
      // });

      // showLoador({ loador: true });
      // await getProductListFromYearAndMonth({
      //   month: dayjs().format("MM"),
      //   year: dayjs().format("YYYY"),
      // }).then(({ success }) => {
      //   showLoador({ loador: false });
      //   if (!success) {
      //     // showNotification({ msg: serverMsg, severity: "error" });
      //   }
      // });

      // showLoador({ loador: true });
      // await getMonthYearTargetForSales({ salesTeamId: "" }).then(
      //   ({ response, success }) => {
      //     showLoador({ loador: false });
      //     if (!success) {
      //       // showNotification({ msg: serverMsg, severity: "error" });
      //     } else {
      //       this.setState({
      //         selfSalesLineChart: response,
      //       });
      //     }
      //   }
      // );

      // showLoador({ loador: true });
      // await getSalesTeamInfo().then(({ success }) => {
      //   showLoador({ loador: false });
      //   if (!success) {
      //     // showNotification({ msg: serverMsg, severity: "error" });
      //   }
      // });
    } else {
      showNotification({ msg: myConstClass.noInternetMsg, severity: "error" });
    }
  }

  viewProfile = () => {
    window.location.replace("/empPreview");
  };
  GoBack = () => {
    this.setState({
      showPreview: false,
    });
  };
  openFeedBack = (e) => {
    e.preventDefault();
    this.setState({
      showFeedback: true,
    });
  };

  ChangeHandlerSearch = (name, newValue) => {
    if (newValue !== null) {
      this.setState({ [name]: newValue });
    } else {
      this.setState({ [name]: "" });
    }
  };

  regionHandler = (e, regionId) => {
    const { getZoneListFromYearAndMonth, showLoador, showNotification } =
      this.props;
    const { month, year } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getZoneListFromYearAndMonth({
        month: month,
        year: year,
        regionId: regionId,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          // showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            showZone: true,
            showArea: false,
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  salesHandler = (e, id) => {
    const { getMonthYearTargetForSales, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getMonthYearTargetForSales({ salesTeamId: id }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          // showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  salesTeamGraphHandler = (e, regionId) => {
    const { getZoneListFromYearAndMonth, showLoador, showNotification } =
      this.props;
    const { month, year } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getZoneListFromYearAndMonth({
        month: month,
        year: year,
        regionId: regionId,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          // showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            showZone: true,
            showArea: false,
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  zoneHandler = (e, zoneId) => {
    const { getAreaListFromYearAndMonth, showLoador, showNotification } =
      this.props;
    const { month, year } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getAreaListFromYearAndMonth({
        month: month,
        year: year,
        zoneId: zoneId,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          // showNotification({ msg: serverMsg, severity: "error" });
        } else {
          this.setState({
            showArea: true,
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onDateChange = (date, name) => {
    this.setState({
      month: date.format("MM"),
      year: date.format("YYYY"),
      saleMonth: date,
      showZone: false,
      showArea: false,
    });
    const { getRegionListFromYearAndMonth, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getRegionListFromYearAndMonth({
        month: date.format("MM"),
        year: date.format("YYYY"),
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          // showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onDateChangeSale = (dataKey) => (date) => {
    if (date !== null) {
      this.setState({
        [dataKey]: date,
      });
    }

    this.setState({
      saleDate: date,
    });
  };

  onDateChangeOutlet = async (date, name) => {
    this.setState({
      [name]: date,
    });
    const { getOrderListFromYearAndMonth, getProductListFromYearAndMonth } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getOrderListFromYearAndMonth({
        month: date.format("MM"),
        year: date.format("YYYY"),
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          // showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getProductListFromYearAndMonth({
        month: date.format("MM"),
        year: date.format("YYYY"),
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          // showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  handleFormValidation() {
    const { type, saleMonth } = this.state;
    let formErrors = {};
    let formIsValid = true;
    if (type === "" || type === null) {
      formIsValid = false;
      formErrors["typeError"] = myConstClass.stackHolderTypeMsg;
    }
    if (saleMonth === "" || saleMonth === null) {
      formIsValid = false;
      formErrors["dateError"] = myConstClass.dateMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  searchSalesList = () => {
    const { getSalesListFromYearAndMonth } = this.props;
    const { saleMonth, type } = this.state;

    if (this.handleFormValidation()) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        getSalesListFromYearAndMonth({
          month: saleMonth.format("MM"),
          year: saleMonth.format("YYYY"),
          stockStakeHolderTypeId: type,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            // showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };

  productColumns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "name",
      title: "Product Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },

    {
      name: "code",
      title: "Product Code",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "actualSale",
      title: "Acheived ",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "contribution",
      title: "% contribution",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
  ];

  outletColumns = [
    {
      name: "index",
      title: "Sr. No.",
      positionCenter: true,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "firmName",
      title: "Outlet Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },

    {
      name: "area",
      title: "Area Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "actualSale",
      title: "Acheived ",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "contribution",
      title: "% contribution",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "salePersonName",
      title: "Sales Excutive Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
  ];

  columns = [
    {
      name: "index",
      title: "Sr.No",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "center",
    },
    {
      name: "name",
      title: "Name",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "left",
    },
    {
      name: "targetAmt",
      title: "Target Amount",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "actualAmt",
      title: "Acheived",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "contribution",
      title: "% Contribution",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
    {
      name: "divisional",
      title: "%(-/+)",
      positionCenter: false,
      showInscreen: true,
      alignCenter: "right",
    },
  ];
  graphList = [{ x: 1, y: 64 }];
  onChangeMonthOrYear = (data, name) => {
    this.setState({
      [name]: data,
    });
  };

  onChangeRadioValue = (value) => {
    if (value == "0") {
      this.setState({
        radioValue: [
          {
            name: "Target",
            data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
          },
          {
            name: "Achieved",
            data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
          },
        ],
      });
    } else if (value == "1") {
      this.setState({
        radioValue: [
          {
            name: "Target",
            data: [50, 60, 57, 56, 61, 58, 63, 60, 66],
          },
          {
            name: "Achieved",
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
          },
        ],
      });
    } else if (value == "2") {
      this.setState({
        radioValue: [
          {
            name: "Target",
            data: [80, 70, 57, 56, 61, 58, 63, 60, 66],
          },
          {
            name: "Achieved",
            data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
          },
        ],
      });
    } else if (value == "3") {
      this.setState({
        radioValue: [
          {
            name: "Target",
            data: [10, 20, 57, 56, 61, 58, 63, 60, 66],
          },
          {
            name: "Achieved",
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
          },
        ],
      });
    }
  };

  getExtraData = (dtoSalesTeamInformations) => {
    let totalOutletCount = 0,
      zero = 0,
      unvisited = 0,
      totalAmount = 0;
    dtoSalesTeamInformations.map((salesTeam) => {
      totalOutletCount = totalOutletCount + +salesTeam.totalOutletCount;
      zero = zero + +salesTeam.zeroOrderCount;
      unvisited = unvisited + +salesTeam.unvisitedOutletCount;
      totalAmount = totalAmount + +salesTeam.totalAmount;
    });
    return [totalOutletCount, unvisited, zero, totalAmount];
  };


  getValue = (value, total) => {
    if (value == 0 || total == 0) {
      if (value == 0) {
        return 0;
      } else {
        return 100;
      }
    } else {
      return +(+(value / total) * 100).toFixed(2);
    }
  };

  render() {
    const {
      managerDashboardData,
      stockStakeHolderTypeList,
      zoneList,
      areaList,
      distributorDashbourd,
    } = this.props;
    const {
      type,
      showZone,
      showArea,
      saleMonth,
      monthList,
      columns,
      rows,
      rowsPerPage,
      page,
      radioValue,
      productArea,
      productZone,
      outletArea,
      outletZone,
    } = this.state;
    const { typeError, dateError } = this.state.formErrors;

    console.log(managerDashboardData.dashboardApi.dtoCategoryWiseTotalAmounts);
    return (
      <>
        <div
          id="layout-wrapper"
          style={{
            backgroundColor: "#fff",
          }}
        >
          {/* ========== App Menu ========== */}

          {/* Left Sidebar End */}
          {/* Vertical Overlay*/}
          <div className="vertical-overlay" />
          {/* ============================================================== */}
          {/* Start right Content here 
              {/* ============================================================== */}
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div

                  className="row"
                  style={{

                    gap: 8,
                    display: 'flex',
                    flexWrap: 'nowrap',
                    justifyContent: 'space-between',
                    paddingBottom: 10,
                    width: "100%",
                    height: "auto",

                  }}
                >

                  {/* Card 1 */}
                  <div
                    className="Frame1000002996"
                    style={{
                      flex: '2',
                      height: "auto",
                      padding: 12,
                      background: "#EEF6FF",
                      borderRadius: 8,
                      justifyContent: "space-between",
                      alignItems: "center",
                      display: "inline-flex",
                      flexDirection: 'column',
                    }}
                  >
                    <div
                      className="Frame427319166"
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        display: "inline-flex",

                      }}
                    >

                      <div
                        className="Frame427319174"
                        style={{
                          fontSize: 14,
                        }}
                      >
                        Target
                      </div>

                      <div
                        className="Frame427319174"
                        style={{
                          fontSize: 14,
                          backgroundColor: '#F6FCFF',
                          borderRadius: '15%',
                          padding: '2px 6px',
                          fontWeight: '450',
                        }}
                      >
                        MTD
                      </div>



                    </div>

                    <div
                      className="Frame1000002994"
                      style={{
                        // alignSelf: "stretch",
                        width: '100%',
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >

                      <div
                        style={{
                          color: "#43C622",
                          fontSize: 16,
                          fontFamily: "Poppins",
                          fontWeight: "500",
                          textTransform: "capitalize",
                          wordWrap: "break-word",
                        }}
                      >
                        {"₹ "}
                        {ConvertAmount({
                          amount: Object.keys(managerDashboardData).length !== 0 &&
                            managerDashboardData.dashboardApi &&
                            managerDashboardData.dashboardApi.dtoSalesPersonYearMonthTargetAndActualAmt &&
                            managerDashboardData.dashboardApi.dtoSalesPersonYearMonthTargetAndActualAmt.actualAmt
                        })}
                      </div>



                      <div
                        style={{
                          color: "#ff0000",
                          fontSize: 16,
                          fontFamily: "Poppins",
                          fontWeight: "500",
                          textTransform: "capitalize",
                          wordWrap: "break-word",
                        }}
                      >
                        {"₹ "}
                        {ConvertAmount({
                          amount: Object.keys(managerDashboardData).length !== 0 &&
                            managerDashboardData.dashboardApi &&
                            managerDashboardData.dashboardApi.dtoSalesPersonYearMonthTargetAndActualAmt &&
                            managerDashboardData.dashboardApi.dtoSalesPersonYearMonthTargetAndActualAmt.monthTragetAmt
                        })}
                      </div>

                    </div>
                  </div>

                  {/* Card 1  end*/}


                  {/* Card 2 */}

                  <div
                    className="Frame1000002996"
                    style={{
                      flex: '1.5',
                      height: "auto",
                      padding: 12,
                      background: "#FFFDE7",
                      borderRadius: 8,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "inline-flex",
                    }}
                  >
                    <div
                      className="Frame11262"
                      style={{

                        padding: 12,

                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        display: "inline-flex",
                      }}
                    >
                      <div
                        className="Frame427319153"
                        style={{
                          width: "100%",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 8,
                          display: "inline-flex",
                        }}
                      >
                        <img
                          className="Image4"
                          style={{ width: 50, height: 50 }}
                          src={image4}
                        />
                        <div
                          className="TotalSales"
                          style={{
                            color: "black",
                            fontSize: 18,
                            fontFamily: "Poppins",
                            fontWeight: "500",
                            marginTop: 10,
                          }}
                        >
                          {"₹ "}
                          {ConvertAmount({
                            amount: Object.keys(managerDashboardData).length !== 0 &&
                              managerDashboardData.dashboardApi &&
                              managerDashboardData.dashboardApi.dtoSalesPersonYearMonthTargetAndActualAmt &&
                              managerDashboardData.dashboardApi.dtoSalesPersonYearMonthTargetAndActualAmt.actualAmt
                          })}

                        </div>
                      </div>
                    </div>
                    <br />
                  </div>

                  {/* Card 2 end */}

                  {/* Card 3 */}

                  <div
                    className="Frame427319167"
                    style={{
                      flex: '3.5',
                      height: "auto",
                      padding: 12,
                      background: "#E2FBFC",
                      borderRadius: 8,
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      display: "flex",
                      gap: '5px',
                      flexDirection: "column",
                    }}
                  >


                    <div
                      className="Frame427319166"
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        // gap: 10,
                        display: "inline-flex",

                      }}
                    >

                      <div
                        className="Frame427319174"
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          // alignItems: "left",
                          fontSize: 14,
                          display: "flex",
                        }}
                      >
                        User Summary
                      </div>

                      <div
                        className="Frame427319174"
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          // alignItems: "left",
                          fontSize: 14,
                          display: "flex",
                          backgroundColor: '#F6FCFF',
                          borderRadius: '15%',
                          padding: '2px 6px',
                          fontWeight: '450',
                        }}
                      >
                        Todays
                      </div>



                    </div>

                    <div
                      className="Frame427319166"
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: 10,
                        display: "flex",
                      }}
                    >


                      <div
                        className="Frame427319166"
                        style={{
                          width: "100%",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "left",
                          display: "inline-flex",
                        }}
                      >

                        <div
                          className="Frame427319174"
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >



                          <div
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "flex-end",
                              display: "flex",
                              width: '55%',
                            }}
                          >

                            <div
                              className="Frame427319174"
                              style={{
                                flexDirection: "column",
                                justifyContent: "space-between",
                                alignItems: "center",
                                display: "flex",
                                gap: '4px',
                              }}
                            >
                              <div
                                className="Frame427319174"
                                style={{
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <div
                                  style={{
                                    color: "black",
                                    fontSize: 16,
                                    color: "#43C622",
                                    fontFamily: "Poppins",
                                    fontWeight: "500",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {Object.keys(managerDashboardData).length !== 0 &&
                                    managerDashboardData.dashboardApi &&
                                    managerDashboardData.dashboardApi.userSummary &&
                                    managerDashboardData.dashboardApi.userSummary.checkInSalesTeamCount &&
                                    managerDashboardData.dashboardApi.userSummary.checkInSalesTeamCount}
                                </div>
                                <div
                                  className="PendingForCheckIn"
                                  style={{
                                    color: "black",
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                  }}
                                >
                                  Check In
                                </div>
                              </div>



                              <div
                                className="Frame427319174"
                                style={{
                                  flexDirection: "column",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <div
                                  style={{
                                    color: "black",
                                    fontSize: 16,
                                    color: "#FF0000",
                                    fontFamily: "Poppins",
                                    fontWeight: "500",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {Object.keys(managerDashboardData).length !== 0 &&
                                    managerDashboardData.dashboardApi &&
                                    managerDashboardData.dashboardApi.userSummary &&
                                    managerDashboardData.dashboardApi.userSummary.totalSalesTeamCount &&
                                    managerDashboardData.dashboardApi.userSummary.checkInSalesTeamCount &&
                                    (managerDashboardData.dashboardApi.userSummary.totalSalesTeamCount - managerDashboardData.dashboardApi.userSummary.checkInSalesTeamCount)}
                                </div>
                                <div
                                  className="PendingForCheckIn"
                                  style={{
                                    color: "black",
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  Absence
                                </div>
                              </div>
                            </div>

                            <div
                              className="Frame427319174"
                              style={{
                                flexDirection: "column",
                                justifyContent: "space-between",
                                alignItems: "center",
                                display: "flex",
                                gap: '4px',
                              }}
                            >
                              <div
                                className="Frame427319174"
                                style={{
                                  flexDirection: "column",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <div
                                  style={{
                                    color: "black",
                                    fontSize: 16,
                                    color: "#FFA500",
                                    fontFamily: "Poppins",
                                    fontWeight: "500",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {Object.keys(managerDashboardData).length !== 0 &&
                                    managerDashboardData.dashboardApi &&
                                    managerDashboardData.dashboardApi.userSummary &&
                                    managerDashboardData.dashboardApi.userSummary.checkInLateSalesTeamCount &&
                                    managerDashboardData.dashboardApi.userSummary.checkInLateSalesTeamCount}
                                </div>
                                <div
                                  className="PendingForCheckIn"
                                  style={{
                                    color: "black",
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  Late
                                </div>
                              </div>
                              <div
                                className="Frame427319174"
                                style={{
                                  flexDirection: "column",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <div
                                  style={{
                                    color: "black",
                                    fontSize: 16,
                                    color: "#43C622",
                                    fontFamily: "Poppins",
                                    fontWeight: "500",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {Object.keys(managerDashboardData).length !== 0 &&
                                    managerDashboardData.dashboardApi &&
                                    managerDashboardData.dashboardApi.userSummary &&
                                    managerDashboardData.dashboardApi.userSummary.checkInTimeSalesTeamCount &&
                                    managerDashboardData.dashboardApi.userSummary.checkInTimeSalesTeamCount}
                                </div>
                                <div
                                  className="PendingForCheckIn"
                                  style={{
                                    color: "black",
                                    fontSize: 14,
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  In-Time
                                </div>
                              </div>
                            </div>
                          </div>


                          <div
                            className="Frame427319174"
                            style={{
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                color: "black",
                                fontSize: 22,
                                fontFamily: "Poppins",
                                fontWeight: "500",
                                wordWrap: "break-word",
                              }}
                            >
                              {Object.keys(managerDashboardData).length !== 0 &&
                                managerDashboardData.dashboardApi &&
                                managerDashboardData.dashboardApi.userSummary &&
                                managerDashboardData.dashboardApi.userSummary.totalSalesTeamCount &&
                                managerDashboardData.dashboardApi.userSummary.totalSalesTeamCount}
                            </div>
                            <div
                              className="PendingForCheckIn"
                              style={{
                                color: "black",
                                fontSize: 16,
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                wordWrap: "break-word",
                              }}
                            >
                              Total
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Card 3 end */}

                  {/* Card 4 starts */}
                  <div
                    className="Frame427319167"
                    style={{
                      flex: '2',
                      height: "auto",
                      padding: 12,
                      background: "rgba(253, 126, 20, 0.10)",
                      borderRadius: 8,
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: '5px',
                      display: "flex",

                      flexDirection: "column",
                    }}
                  >


                    <div
                      className="Frame427319166"
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        display: "inline-flex",

                      }}
                    >

                      <div
                        className="Frame427319174"
                        style={{
                          fontSize: 14,
                        }}
                      >
                        Poductivity
                      </div>

                      <div
                        className="Frame427319174"
                        style={{
                          fontSize: 14,
                          backgroundColor: '#F6FCFF',
                          borderRadius: '15%',
                          padding: '2px 6px',
                          fontWeight: '450',
                        }}
                      >
                        MTD
                      </div>



                    </div>

                    <div
                      className="Frame427319166"
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: 10,
                        display: "flex",
                      }}
                    >


                      <div
                        className="Frame427319166"
                        style={{
                          width: "100%",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "left",
                          gap: 3,
                          display: "inline-flex",
                        }}
                      >

                        <div
                          className="Frame427319174"
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >



                          <div
                            style={{
                              flexDirection: "column",
                              width: '100%',
                              justifyContent: "space-between",
                              alignItems: "center",
                              display: "flex",
                              gap: '4px',
                            }}
                          >

                            <div
                              className="Frame427319166"
                              style={{
                                width: "100%",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                // gap: 10,
                                display: "flex",

                              }}
                            >

                              <div
                                className="Frame427319174"
                                style={{
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >

                                <div
                                  style={{
                                    color: "black",
                                    fontSize: 16,
                                    color: "#43C622",
                                    fontFamily: "Poppins",
                                    fontWeight: "500",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {
                                    Object.keys(managerDashboardData).length !== 0 &&
                                    managerDashboardData.dashboardApi &&
                                    managerDashboardData.dashboardApi.currentMonthProductivity &&
                                    managerDashboardData.dashboardApi.currentMonthProductivity.scheduleCall
                                  }
                                </div>
                                <div
                                  className="PendingForCheckIn"
                                  style={{
                                    color: "black",
                                    fontSize: 16,
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                  }}
                                >
                                  SC
                                </div>
                              </div>



                              <div
                                className="Frame427319174"
                                style={{
                                  flexDirection: "column",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >

                                <div
                                  style={{
                                    color: "black",
                                    fontSize: 16,
                                    color: "#FF0000",
                                    fontFamily: "Poppins",
                                    fontWeight: "500",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {
                                    Object.keys(managerDashboardData).length !== 0 &&
                                    managerDashboardData.dashboardApi &&
                                    managerDashboardData.dashboardApi.currentMonthProductivity &&
                                    managerDashboardData.dashboardApi.currentMonthProductivity.productiveCall
                                  }
                                </div>
                                <div
                                  className="PendingForCheckIn"
                                  style={{
                                    color: "black",
                                    fontSize: 16,
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  PC
                                </div>
                              </div>
                            </div>

                            <div
                              className="Frame427319174"
                              style={{
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                display: "flex",
                              }}
                            >

                              <div
                                style={{
                                  color: "black",
                                  fontSize: 16,
                                  color: "#FFA500",
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  wordWrap: "break-word",
                                }}
                              >
                                {
                                  Object.keys(managerDashboardData).length !== 0 &&
                                  managerDashboardData.dashboardApi &&
                                  managerDashboardData.dashboardApi.currentMonthProductivity &&
                                  managerDashboardData.dashboardApi.currentMonthProductivity.scheduleProductivity
                                }
                              </div>
                              <div
                                className="PendingForCheckIn"
                                style={{
                                  color: "black",
                                  fontSize: 16,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  wordWrap: "break-word",
                                }}
                              >
                                SP
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Card 4 ends */}

                  {/* Card 5 Starts */}
                  <div
                    className="Frame427319167"
                    style={{
                      flex: '2',
                      height: "auto",
                      padding: 12,
                      background: "#ECFAF2",
                      borderRadius: 8,
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: '5px',
                      display: "flex",

                      flexDirection: "column",
                    }}
                  >


                    <div
                      className="Frame427319166"
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        display: "inline-flex",

                      }}
                    >

                      <div
                        className="Frame427319174"
                        style={{
                          fontSize: 14,
                        }}
                      >
                        Poductivity
                      </div>

                      <div
                        className="Frame427319174"
                        style={{
                          fontSize: 14,
                          backgroundColor: '#F6FCFF',
                          borderRadius: '15%',
                          padding: '2px 6px',
                          fontWeight: '450',
                        }}
                      >
                        Todays
                      </div>
                    </div>

                    <div
                      className="Frame427319166"
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: 10,
                        display: "flex",
                      }}
                    >


                      <div
                        className="Frame427319166"
                        style={{
                          width: "100%",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "left",
                          gap: 3,
                          display: "inline-flex",
                        }}
                      >

                        <div
                          className="Frame427319174"
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >



                          <div
                            style={{
                              flexDirection: "column",
                              width: '100%',
                              justifyContent: "space-between",
                              alignItems: "center",
                              display: "flex",
                              gap: '4px',
                            }}
                          >

                            <div
                              className="Frame427319166"
                              style={{
                                width: "100%",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                // gap: 10,
                                display: "flex",

                              }}
                            >

                              <div
                                className="Frame427319174"
                                style={{
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >

                                <div
                                  style={{
                                    color: "black",
                                    fontSize: 16,
                                    color: "#43C622",
                                    fontFamily: "Poppins",
                                    fontWeight: "500",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {
                                    Object.keys(managerDashboardData).length !== 0 &&
                                    managerDashboardData.dashboardApi &&
                                    managerDashboardData.dashboardApi.currentDateProductivity &&
                                    managerDashboardData.dashboardApi.currentDateProductivity.scheduleCall
                                  }
                                </div>
                                <div
                                  className="PendingForCheckIn"
                                  style={{
                                    color: "black",
                                    fontSize: 16,
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                  }}
                                >
                                  SC
                                </div>
                              </div>



                              <div
                                className="Frame427319174"
                                style={{
                                  flexDirection: "column",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >

                                <div
                                  style={{
                                    color: "black",
                                    fontSize: 16,
                                    color: "#FF0000",
                                    fontFamily: "Poppins",
                                    fontWeight: "500",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {
                                    Object.keys(managerDashboardData).length !== 0 &&
                                    managerDashboardData.dashboardApi &&
                                    managerDashboardData.dashboardApi.currentDateProductivity &&
                                    managerDashboardData.dashboardApi.currentDateProductivity.productiveCall
                                  }
                                </div>
                                <div
                                  className="PendingForCheckIn"
                                  style={{
                                    color: "black",
                                    fontSize: 16,
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  PC
                                </div>
                              </div>
                            </div>

                            <div
                              className="Frame427319174"
                              style={{
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                display: "flex",
                              }}
                            >

                              <div
                                style={{
                                  color: "black",
                                  fontSize: 16,
                                  color: "#FFA500",
                                  fontFamily: "Poppins",
                                  fontWeight: "500",
                                  wordWrap: "break-word",
                                }}
                              >
                                {
                                  Object.keys(managerDashboardData).length !== 0 &&
                                  managerDashboardData.dashboardApi &&
                                  managerDashboardData.dashboardApi.currentDateProductivity &&
                                  managerDashboardData.dashboardApi.currentDateProductivity.scheduleProductivity
                                }
                              </div>
                              <div
                                className="PendingForCheckIn"
                                style={{
                                  color: "black",
                                  fontSize: 16,
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  wordWrap: "break-word",
                                }}
                              >
                                SP
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  {/* Card 5 ends */}


                </div>
                <br />
                {/* Sales & Manpower Div starts */}

                <div
                  className="row"
                  style={{
                    gap: 8,
                    marginBottom: 10,
                    flexDirection: "row",
                    width: "100%",
                  }}
                >

                  {/* User Summary Work Chart Start */}
                  <div
                    style={{
                      width: "45%",
                      background: "white",
                      // padding: 5,
                      borderRadius: 8,
                      border: "1px #E6E6E6 solid",
                    }}
                  >
                    {/* Sales & Manpower Table 2 starts */}
                    <div
                      // className="card"
                      style={{
                        marginBottom: -2,
                        background: "white",
                        borderRadius: 8,
                        border: "0 #E6E6E6 solid",
                      }}
                    >
                      <div
                        className="card-header align-items-center d-flex"
                        style={{
                          background: "white",
                          borderRadius: 8,
                          fontSize: 14,
                          border: "0 #E6E6E6 solid",
                          // marginTop: 20,
                        }}
                      >
                        User Work Summary
                        {/* Replace table with graph */}

                      </div>
                    </div>
                    <MonthlyBarChart
                      // radioValue={onChangeRadioValue}
                      height={400}
                      series={[
                        {
                          data: managerDashboardData &&
                            managerDashboardData.dashboardApi &&
                            managerDashboardData.dashboardApi.dataList
                        },
                      ]}
                      optionsObj={{
                        // chart: {
                        //   type: "bar",
                        //   height: 365,
                        //   events: {
                        //     dataPointSelection: (event, chartContext, config) => {
                        //       onClickLineChart(config.dataPointIndex);
                        //     },
                        //   },
                        // },

                        plotOptions: {
                          bar: {
                            columnWidth: "20%",
                            distributed: true,
                          },
                        },
                        stroke: {
                          show: true,
                          width: 2,
                          // colors: ["transparent"],
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                          categories: [
                            "Retailing",
                            "Distributor",
                            "Office Work",
                            "Joint Working",
                            "Other Work",
                            "Work Not Start",
                            "Total",
                          ],
                        },
                        // yaxis: {
                        //   title: {
                        //     text: "$ (thousands)",
                        //   },
                        // },
                        fill: {
                          opacity: 1,
                        },
                        colors: ["#FD9600", "#00FFFF", "#008000", "#DE00FD", "#FF0000", "#000FFF", "#61EE9B"],

                        tooltip: {
                          theme: "light",
                        },
                      }}
                    />
                  </div>

                  {/* User Summary Work Chart end */}



                  {/* Time Slot Wise start */}

                  <div
                    style={{
                      width: "53%",
                      background: "white",
                      padding: 10,
                      borderRadius: 8,
                      border: "1px #E6E6E6 solid",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      className="Frame427319166"
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        // gap: 10,
                        display: "inline-flex",
                        marginBottom: 25,
                      }}
                    >

                      <div
                        className="Frame427319174"
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          // alignItems: "left",
                          fontSize: 14,
                          display: "flex",
                        }}
                      >
                        Time Slot Wise Working
                      </div>


                      <div
                        className="Frame427319174"
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          alignItems: "right",
                          fontSize: 14,
                          color: "#004969",
                          display: "flex",
                        }}
                      >
                        Todays
                      </div>
                    </div>

                    {Object.keys(managerDashboardData).length !== 0 &&
                      managerDashboardData.dashboardApi &&
                      managerDashboardData.dashboardApi.timeSlots &&
                      managerDashboardData.dashboardApi.timeSlots.map((obj, index) => {
                        return (
                          <>
                            <div
                              className="Frame427319166"
                              style={{
                                width: "100%",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                color: "#004969",
                                // gap: 10,
                                fontSize: 18,
                                display: "inline-flex",
                                marginBottom: 5,
                              }}
                            >
                              {obj.fromTime + ' To ' + obj.toTime}
                            </div>

                            <div
                              className="Frame427319166"
                              style={{
                                width: "100%",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                // gap: 10,
                                display: "inline-flex",
                                marginBottom: 15,
                              }}
                            >


                              <div
                                className="Frame427319166"
                                style={{
                                  justifyContent: "space-between",
                                  // gap: 10,
                                  width: "18%",
                                  fontSize: 16,
                                  display: "inline-flex",
                                }}
                              >
                                {"Retailing : " + obj.managerLogsCount.retailingCount}

                              </div>


                              <div
                                className="Frame427319166"
                                style={{
                                  justifyContent: "space-between",
                                  // gap: 10,
                                  width: "18%",
                                  fontSize: 16,
                                  display: "inline-flex",
                                }}
                              >
                                {"Distributor : " + obj.managerLogsCount.distributorCount}

                              </div>


                              <div
                                className="Frame427319166"
                                style={{
                                  justifyContent: "space-between",
                                  // gap: 10,
                                  width: "18%",
                                  fontSize: 16,
                                  display: "inline-flex",
                                }}
                              >
                                {"Office Work : " + obj.managerLogsCount.officeWorkCount}

                              </div>


                              <div
                                className="Frame427319166"
                                style={{
                                  justifyContent: "space-between",
                                  // gap: 10,
                                  width: "18%",
                                  fontSize: 16,
                                  display: "inline-flex",
                                }}
                              >
                                {"Joint Work : " + obj.managerLogsCount.jointWorkCount}

                              </div>
                            </div>
                          </>
                        )


                      })}



                  </div>
                  {/* Time Slot Wise end */}

                </div>




                <div
                  className="row"
                  style={{
                    gap: 8,
                    marginBottom: 10,
                    padding: 2,
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  {/* Category wise chart start */}
                  <div
                    style={{
                      width: "48%",
                      background: "white",
                      padding: 10,
                      borderRadius: 8,
                      border: "1px #E6E6E6 solid",
                    }}
                  >

                    <Grid item xs={12} md={4} lg={4} background="yellow">
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <Typography variant="h5">
                            Category-wise Summary
                          </Typography>
                        </Grid>
                      </Grid>

                      <Box sx={{ pt: 10, pr: 2, width: '750px', height: '600px' }}>
                        {managerDashboardData &&
                          Object.keys(managerDashboardData).length !=
                          0 &&
                          managerDashboardData.dashboardApi && managerDashboardData.dashboardApi.dtoCategoryWiseTotalAmounts &&
                          managerDashboardData.dashboardApi.dtoCategoryWiseTotalAmounts.length != 0 ? (
                          <DonutChart
                            series={managerDashboardData.dashboardApi.dtoCategoryWiseTotalAmounts.map(
                              (category) => {
                                return category.totalAmt != null
                                  ? category.totalAmt
                                  : "";
                              }
                            )}
                            width={"700"}
                            options={{
                              chart: {
                                height: "300%",
                                width: "500%",
                                type: "donut",
                                events: {
                                  // dataPointSelection: (
                                  //   event,
                                  //   chartContext,
                                  //   config
                                  // ) => {
                                  //   this.onCLickPie(config.dataPointIndex);
                                  // },
                                },
                              },
                              animations: {
                                enabled: true,
                                easing: "easeinout",
                                speed: 1000,
                                animateGradually: {
                                  enabled: true,
                                  delay: 150,
                                },
                                dynamicAnimation: {
                                  enabled: true,
                                  speed: 100,
                                },
                              },
                              labels:
                                managerDashboardData.dashboardApi.dtoCategoryWiseTotalAmounts.map(
                                  (category) => {
                                    return category.category.name != null
                                      ? category.category.name
                                      : "";
                                  }
                                ),
                              responsive: [
                                {
                                  breakpoint: 480,
                                  options: {
                                    chart: {
                                      width: 600,
                                    },
                                    legend: {
                                      position: "bottom",
                                    },
                                  },
                                },
                              ],
                            }}
                          />
                        ) : (
                          <NoDataFound />
                        )}
                      </Box>
                    </Grid>
                  </div>

                  {/* <div
                    style={{
                      width: "50%",
                      background: "white",
                      padding: 5,
                      borderRadius: 8,
                      border: "1px #E6E6E6 solid",
                    }}
                  > */}
                  {/* Sales & Manpower Table 2 starts */}
                  {/* <Grid item xs={12} md={4} lg={4} background="yellow">
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <Typography variant="h5">
                            Productivity
                          </Typography>
                        </Grid>
                      </Grid>

                      <Box sx={{ pt: 5, pr: 2, width: '750px', height: '600px' }}>
                        {managerDashboardData && Object.keys(managerDashboardData).length !== 0 &&
                          managerDashboardData.dashboardApi &&
                          managerDashboardData.dashboardApi.dtoCategoryWiseTotalAmounts &&
                          managerDashboardData.dashboardApi.dtoCategoryWiseTotalAmounts.length !== 0
                          ?
                          (
                            <PieChart
                              series={managerDashboardData.dashboardApi.dtoCategoryWiseTotalAmounts.map(
                                (category) => {
                                  return category.totalAmt != null
                                    ? category.totalAmt
                                    : "";
                                }
                              )}
                              options={{
                                chart: {
                                  height: "100%",
                                  width: "100%",
                                  type: "donut",
                                  events: {
                                    // dataPointSelection: (
                                    //   event,
                                    //   chartContext,
                                    //   config
                                    // ) => {
                                    //   this.onCLickPie(config.dataPointIndex);
                                    // },
                                  },
                                },
                                animations: {
                                  enabled: true,
                                  easing: "easeinout",
                                  speed: 1000,
                                  animateGradually: {
                                    enabled: true,
                                    delay: 150,
                                  },
                                  dynamicAnimation: {
                                    enabled: true,
                                    speed: 100,
                                  },
                                },
                                labels:
                                  managerDashboardData.dashboardApi.dtoCategoryWiseTotalAmounts.map(
                                    (category) => {
                                      return category.category.name != null
                                        ? category.category.name
                                        : "";
                                    }
                                  ),
                                responsive: [
                                  {
                                    breakpoint: 480,
                                    options: {
                                      chart: {
                                        width: 600,
                                      },
                                      legend: {
                                        position: "bottom",
                                      },
                                    },
                                  },
                                ],
                              }}

                            />
                          ) : (
                            <NoDataFound />
                          )}
                      </Box>
                    </Grid> */}
                  {/* </div> */}
                </div>
              </div>

              <footer className="footer">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-6">2022 © Byte Elephants. </div>
                    <div className="col-sm-6">
                      <div className="text-sm-end d-none d-sm-block">
                        {" "}
                        Designed &amp; Developed by Byte Elephants Technologies
                        PVT LTD{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  managerDashboardData: state.managerDashboard,

});

const mapDispatchToProps = {
  onToggle,
  showLoador,
  showNotification,
  getManagerDashboardApi,
};

export default connect(mapStateToProps, mapDispatchToProps)(RMDashboard);
