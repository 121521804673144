import endpoint from "../../config/endpoints";

export const regionAndTargetJson = {
  formPath: "/region-and-target-form",
  formEditPath: "/region-and-target-edit-form",
  apiBaseURL: endpoint.regionAndTarget,
  screenTitle: "Region-Month Wise Target %",
  showAddButton: true,
  showPdfDownload: false,
  pdfFileName: "Region-Month Wise Target %",
  showExcelDownload: false,
  excelFileName: "Region-Month Wise Target %",

  searchList: [
    {
      label: "Year",
      controlType: "autocomplete",
      placeHolder: "Select Year",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      masterName: "yearDetail",
      dataKey: "name",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],

  fieldMeta: [
    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      masterName: "regionDetail",
      dataKey: "region",
      getListId: "region",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Year",
      controlType: "autocomplete",
      placeHolder: "Year",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      masterName: "yearRegionDetail",
      getListFrom: "region",
      dataKey: "yearName",
      getListId: "yearName",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],
};
