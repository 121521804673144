import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  productRateConfOutlet: [],
  publishRateConfigOutletCurrent: [],
  publishRateConfigOutletFuture: [],
  publishRateConfigOutletPrevious: [],
  publishRateConfigurationView: {},
  isFetch: false,
};
let URL = endpoints.productRateConfOutlet;
const publishRateConfigOultletSlice = createSlice({
  name: "productRateConfOutlet",
  initialState,
  reducers: {
    publishRateConfigurationOutletCurrentSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        publishRateConfigOutletCurrent: row,
        isFetch: true,
      };
    },
    publishRateConfigurationOutletFutureSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        publishRateConfigOutletFuture: row,
        isFetch: true,
      };
    },

    publishRateConfigurationOutletSearchSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        publishRateConfigOutletPrevious: row,
        isFetch: true,
      };
    },
    publishRateConfigurationHeaderByIdSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        publishRateConfigurationView: row,
        isFetch: true,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  publishRateConfigurationOutletSuccess,
  publishRateConfigurationOutletCurrentSuccess,
  publishRateConfigurationOutletFutureSuccess,
  publishRateConfigurationOutletSearchSuccess,
  publishRateConfigurationHeaderByIdSuccess,
  resetState,
} = publishRateConfigOultletSlice.actions;

export default publishRateConfigOultletSlice.reducer;
export const getpublishRateConfigOutletCurrent = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-current",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map(
          (publishRateConfigurationOutletCurrentObject, index) => {
            let publishRateConfigurationOutletCurrentData = {
              index: index + 1,
              id:
                publishRateConfigurationOutletCurrentObject.id === null
                  ? ""
                  : publishRateConfigurationOutletCurrentObject.id,
              name:
                publishRateConfigurationOutletCurrentObject
                  .productRateConfigurationOutlet.name !== null
                  ? publishRateConfigurationOutletCurrentObject
                      .productRateConfigurationOutlet.name
                  : "",
              publishDate:
                publishRateConfigurationOutletCurrentObject.publishDate === null
                  ? ""
                  : publishRateConfigurationOutletCurrentObject.publishDate,
              days:
                publishRateConfigurationOutletCurrentObject.days === null
                  ? ""
                  : publishRateConfigurationOutletCurrentObject.days +
                    " Day's",
            };
            return publishRateConfigurationOutletCurrentData;
          }
        );
        dispatch(publishRateConfigurationOutletCurrentSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getpublishRateConfigOutletFuture = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-future",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map(
          (publishRateConfigurationOutletFutureObject, index) => {
            let publishRateConfigurationOutletFutureData = {
              index: index + 1,
              id:
                publishRateConfigurationOutletFutureObject.id === null
                  ? ""
                  : publishRateConfigurationOutletFutureObject.id,
              name:
                publishRateConfigurationOutletFutureObject
                  .productRateConfigurationOutlet.name !== null
                  ? publishRateConfigurationOutletFutureObject
                      .productRateConfigurationOutlet.name
                  : "",
              publishDate:
                publishRateConfigurationOutletFutureObject.publishDate === null
                  ? ""
                  : publishRateConfigurationOutletFutureObject.publishDate,
              days:
                publishRateConfigurationOutletFutureObject.days === null
                  ? ""
                  : publishRateConfigurationOutletFutureObject.days +
                    " Day's",
            };
            return publishRateConfigurationOutletFutureData;
          }
        );
        dispatch(publishRateConfigurationOutletFutureSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getpublishRateConfigOutletSearch =
  ({ categoryIds }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/get-previous-by-categories?categoryIds=" + categoryIds,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map(
            (publishRateConfigurationOutletPreviousObject, index) => {
              let publishRateConfigurationOutletPreviousData = {
                index: index + 1,
                id:
                  publishRateConfigurationOutletPreviousObject.id === null
                    ? ""
                    : publishRateConfigurationOutletPreviousObject.id,
                name:
                  publishRateConfigurationOutletPreviousObject
                    .productRateConfigurationOutlet.name !== null
                    ? publishRateConfigurationOutletPreviousObject
                        .productRateConfigurationOutlet.name
                    : "",
                publishDate:
                  publishRateConfigurationOutletPreviousObject.publishDate ===
                  null
                    ? ""
                    : publishRateConfigurationOutletPreviousObject.publishDate,
              };

              return publishRateConfigurationOutletPreviousData;
            }
          );
          dispatch(publishRateConfigurationOutletSearchSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getPublishRateConfigurationById =
  ({ publishRateConfigurationHeaderId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/" + publishRateConfigurationHeaderId,
      }).then(({ data, success }) => {
        let row;
        if (success) {
          const publishRateConfigurationHeaderObject = data.data;
          let publishRateConfigurationHeaderObjectData = {
            // id:
            //   publishRateConfigurationHeaderObject.id === null ? "" : publishRateConfigurationHeaderObject.id,
            // stockStakeHolderTypeIds:
            //   publishRateConfigurationHeaderObject.stockStakeHolderTypeIds === null
            //     ? ""
            //     : publishRateConfigurationHeaderObject.stockStakeHolderTypeIds,
            // stockStakeHolderTypeName:
            //   publishRateConfigurationHeaderObject.stockStakeHolderTypeName === null
            //     ? ""
            //     : publishRateConfigurationHeaderObject.stockStakeHolderTypeName,
            // type:
            //   publishRateConfigurationHeaderObject.type === null
            //     ? ""
            //     : publishRateConfigurationHeaderObject.type,
            // typeName:
            //   publishRateConfigurationHeaderObject.type === null
            //     ? ""
            //     : publishRateConfigurationHeaderObject.type == 0
            //     ? "Notice"
            //     : "Message",
            // regionIds:
            //   publishRateConfigurationHeaderObject.regionIds === null
            //     ? ""
            //     : publishRateConfigurationHeaderObject.regionIds,
            // regionName:
            //   publishRateConfigurationHeaderObject.regionName === null
            //     ? ""
            //     : publishRateConfigurationHeaderObject.regionName,
            // zoneIds:
            //   publishRateConfigurationHeaderObject.zoneIds === null
            //     ? ""
            //     : publishRateConfigurationHeaderObject.zoneIds,
            // zoneName:
            //   publishRateConfigurationHeaderObject.zoneName === null
            //     ? ""
            //     : publishRateConfigurationHeaderObject.zoneName,
            // areaIds:
            //   publishRateConfigurationHeaderObject.areaIds === null
            //     ? ""
            //     : publishRateConfigurationHeaderObject.areaIds,
            // areaName:
            //   publishRateConfigurationHeaderObject.areaName === null
            //     ? ""
            //     : publishRateConfigurationHeaderObject.areaName,
            // noticeHeadline:
            //   publishRateConfigurationHeaderObject.noticeHeadline === null
            //     ? ""
            //     : publishRateConfigurationHeaderObject.noticeHeadline,
            // description:
            //   publishRateConfigurationHeaderObject.description === null
            //     ? ""
            //     : publishRateConfigurationHeaderObject.description,
            // flash:
            //   publishRateConfigurationHeaderObject.flash === null
            //     ? ""
            //     : publishRateConfigurationHeaderObject.flash,
            // flashType:
            //   publishRateConfigurationHeaderObject.flash === null
            //     ? ""
            //     : publishRateConfigurationHeaderObject.flash == 0
            //     ? "No"
            //     : "Yes",
            // fromdate:
            //   publishRateConfigurationHeaderObject.fromdate === null
            //     ? ""
            //     : publishRateConfigurationHeaderObject.fromdate,
            // todate:
            //   publishRateConfigurationHeaderObject.todate === null
            //     ? ""
            //     : publishRateConfigurationHeaderObject.todate,
            // isActive:
            //   publishRateConfigurationHeaderObject.isActive === null
            //     ? ""
            //     : publishRateConfigurationHeaderObject.isActive,
            // flashName:
            //   publishRateConfigurationHeaderObject.flashName === null
            //     ? "-"
            //     : publishRateConfigurationHeaderObject.flashName,
            // image:
            //   publishRateConfigurationHeaderObject.image === null
            //     ? ""
            //     : publishRateConfigurationHeaderObject.image,
            ...publishRateConfigurationHeaderObject,
          };
          dispatch(
            publishRateConfigurationHeaderByIdSuccess({
              row: publishRateConfigurationHeaderObjectData,
            })
          );
          return {
            response: publishRateConfigurationHeaderObjectData,
            success,
          };
        }
        return { response: {}, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
