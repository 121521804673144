import React from "react";
import { connect } from "react-redux";
import endpoint from "../../config/endpoints";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import { creditNoteHolderJson } from "../../DynamicFormsJson/Transaction/CreditNoteHolder";
import {
  getTodaysCreditNote,
  getCreditNoteByDateSearchList,
} from "../../Slice/creditNote.slice";
import { creditNoteHeaderListColumns } from "../../tableColumns/table-columns";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { serverMsg, noInternetMsg } from "../../config/messageconstant";
import { downloadF } from "../PDF/PDFDownload";
class CreditNoteTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
    };
  }
  async componentDidMount() {
    await this.getData();
  }
  getData = async () => {
    const { getTodaysCreditNote, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getTodaysCreditNote().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  getCreditNoteListData = ({ fromdate, todate }) => {
    const { getCreditNoteByDateSearchList, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      getCreditNoteByDateSearchList({
        fromdate: fromdate,
        todate: todate,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  onSearchList = (data) => {
    this.setState({
      formData: data,
    });
    this.getCreditNoteListData({
      fromdate: data.fromdate,
      todate: data.todate,
    });
  };

  rowPDF = (rowData) => {
    localStorage.setItem("creditNoteBillID", rowData.id);
    window.open('./credit-note-bill-pdf', '_blank');
  };


  render() {
    const { creditNoteList } = this.props;

    return (
      <>
        <DynamicMainScreen
          deleteMsgAuto={true}
          showViewButtonInTable={true}
          showDeleteIcon={false}
          searchList={creditNoteHolderJson.searchList}
          onSearchData={this.onSearchList}
          formPath={creditNoteHolderJson.formPath}
          formPathView={creditNoteHolderJson.formPathView}
          screenTitle={creditNoteHolderJson.screenTitle}
          showPdfDownload={false}
          showExcelDownload={false}
          isActiveURL={creditNoteHolderJson.isActiveURL}
          showAddButton={false}
          tableColumns={creditNoteHeaderListColumns}
          tableData={creditNoteList.creditNoteByDate}
          apiBaseURL={creditNoteHolderJson.apiBaseURL}
          callApi={false}
          getTableDataById={true}
          showSearchBox={true}
          rowPDF={this.rowPDF}
          showPdf={true}
          showEditIcon={false}
          showPegination={false}
          getTableData={this.getData}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  creditNoteList: state.creditNoteHeader,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getCreditNoteByDateSearchList,
  getTodaysCreditNote,
};
export default connect(mapStateToProps, mapDispatchToProps)(CreditNoteTable);
