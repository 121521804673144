export const langugae = {
  0: {
    finalTotal: "अंतिम एकूण",
    billDispatch: "बिल डिस्पॅच सारांश",
    orderDispatch: "ऑर्डर डिस्पॅच सारांश",
    paymentSumm: "पेमेंट सारांश अहवाल",
    distName: "वितरकाचे नाव",
    srNO: "अनु.क्र",
    billNO: "बिल क्रमांक",
    outletName: "आउटलेटचे नाव",
    ownerName: "मालकाचे नाव",
    beatName: "बीट नाव",
    mobileNo: "मोबाईल नंबर",
    amount: "रक्कम",
    amountNew: "रक्कम",
    recAmt: "प्राप्त रकम",
    total: "एकूण",
    address: "पत्ता",
    village: "गाव",
    gSTNo: "जीएसटी क्र",
    billTo: "बिल ते",
    phoneNo: "दूरध्वनी क्रमांक",
    FSSAINo: "एफएसएसएआय क्र.",
    date: "तारीख",
    time: "वेळ",
    HSN: "एचएसएन",
    itemName: "आयटमचे नाव",
    uom: "यूओएम",
    qty: "प्रमाण",
    freeQty: "मोफत प्रमाण",
    freeQtyNew: "मोफत प्रमाण",
    rate: "दर",
    CGST: "सीजीएसटी",
    SGST: "एसजीएसटी",
    fillingType: "भरण्याचा प्रकार",
    namkeen: "नमकीन",
    note: "नोंद",
    orderBy: "च्या आदेशाने",
    subjectToJurisdiction: "अधिकारक्षेत्राच्या अधीन.",
    aurthorisedSign: "अधिकृत चिन्ह",
    productName: "उत्पादनाचे नांव",
    all: "सर्व",
    strip: "पट्टी",
    cartoon: "व्यंगचित्र",
    goodsCheckBeforeReceived: "सामानमिळण्यापूर्वी तपासा",
    taxInvoice: "कर चालान",
    purchaseReturn: "खरेदी परतावा",
    IGST: "आईजीएसटी",
    bill: "बिल",
    creditNote: "क्रेडिट नोट",
  },
  1: {
    finalTotal: "अंतिम कुल",
    billDispatch: "बिल प्रेषण सारांश",
    orderDispatch: "ऑर्डर प्रेषण सारांश",
    paymentSumm: "भुगतान सारांश रिपोर्ट",
    distName: "वितरक का नाम",
    srNO: "क्रम न.",
    billNO: "बिल नंबर",
    outletName: "आउटलेट का नाम",
    ownerName: "मालिक का नाम",
    beatName: "बीट का नाम",
    mobileNo: "मोबाइल नंबर",
    amount: "रकम",
    amountNew: "रकम",
    recAmt: "प्राप्त रकम",
    total: "कुल",
    address: "पता",
    village: "गाव",
    gSTNo: "जीएसटी नंबर",
    billTo: "बिल प्राप्तकर्ता",
    phoneNo: "फोन नंबर",
    FSSAINo: "एफएसएसएआई नंबर",
    date: "तारीख",
    time: "समय",
    HSN: "एचएसएन",
    itemName: "आइटम नाम",
    uom: "यूओएम",
    qty: "मात्रा",
    freeQty: "मुफ़्त मात्रा",
    freeQtyNew: "मोफत प्रमाण",
    rate: "दर",
    CGST: "सीजीएसटी",
    SGST: "एसजीएसटी",
    fillingType: "भरने का प्रकार",
    namkeen: "नमकीन",
    note: "टिप्पणी",
    orderBy: "द्वारा आदेश",
    subjectToJurisdiction: "क्षेत्राधिकार के अधीन.",
    aurthorisedSign: "अधिकृत संकेत",
    productName: "प्रोडक्ट का नाम",
    all: "सभी",
    strip: "पट्टी",
    cartoon: "कार्टून",
    goodsCheckBeforeReceived: "मालप्राप्त होने से पहले जांचें",
    taxInvoice: "कर चालान",
    IGST: "आईजीएसटी",
    bill: "बिल",
    purchaseReturn: "खरीद वापसी",
    creditNote: "क्रेडिट नोट",
  },
  2: {
    finalTotal: "Final Total",
    billDispatch: "Bill Dispatch Summary",
    orderDispatch: "Order Dispatch Summary",
    paymentSumm: "Payment Summary Report",
    distName: "Distributor Name",
    srNO: "Sr No",
    billNO: "Bill No.",
    outletName: "Outlet Name",
    ownerName: "Owner Name",
    beatName: "Beat Name",
    mobileNo: "Mobile No.",
    amount: "Amount",
    amountNew: "Amt.",
    recAmt: "Rec.Amt",
    total: "Total",
    address: "Address",
    village: "Village",
    gSTNo: "GST No",
    billTo: "Bill To",
    phoneNo: "Phone No",
    FSSAINo: "FSSAI No.",
    date: "Date",
    time: "Time",
    HSN: "HSN",
    itemName: "Item Name",
    uom: "UOM",
    qty: "Qty",
    freeQty: "Free Qty",
    freeQtyNew: "Fr. Qty",
    rate: "Rate",
    CGST: "CGST",
    SGST: "SGST",
    fillingType: "Filling Type",
    namkeen: "Namkeen",
    note: "Note",
    orderBy: "Order By",
    subjectToJurisdiction: "Subject to Jurisdiction",
    aurthorisedSign: "Aurthorised Sign",
    productName: "Product Name",
    all: "All",
    strip: "Strip",
    cartoon: "Cart",
    goodsCheckBeforeReceived: "Goods Check Before Received",
    taxInvoice: "Tax Invoice",
    IGST: "IGST",
    bill: "Bill",
    purchaseReturn: "Credit Note",
    creditNote: "Credit Note",
  },
  3: {
    finalTotal: "અંતિમ કુલ",
    paymentSumm: "ચુકવણી સારાંશ રિપોર્ટ",
    distName: "વિતરક નામ",
    srNO: "ક્રમ નં",
    billNO: "બિલ નંબર",
    outletName: "આઉટલેટનું નામ",
    ownerName: "માલીકનું નામ",
    beatName: "બીટ નામ",
    mobileNo: "મોબાઈલ નંબર",
    amountNew: "રકમ.",
    amount: "રકમ",
    recAmt: "પ્રાપ્ત રકમ",
    total: "કુલ",
    address: "સરનામું",
    village:"ગામ",
    gSTNo: "જીએસટી નં",
    billTo: "માટે બિલ",
    phoneNo: "ફોન નં",
    FSSAINo: "એફએસએસએઆઈ નં",
    date: "તારીખ",
    time: "સમય",
    HSN: "એચએસએન",
    itemName: "વસ્તુનુ નામ",
    uom: "યુઓએમ",
    qty: "જથ્થો",
    freeQty: "મફત જથ્થો",
    freeQtyNew: "મફત જથ્થો",
    rate: "દર",
    CGST: "સીજીએસટી",
    SGST: "એસજીએસટી",
    fillingType: "ભરવાનો પ્રકાર",
    namkeen: "નમકીન",
    note: "નૉૅધ",
    orderBy: "દ્વારા ઓર્ડર",
    subjectToJurisdiction: "અધિકારક્ષેત્રને આધીન.",
    aurthorisedSign: "અધિકૃત સાઇન",
    productName: "ઉત્પાદન નામ",
    all: "બધા",
    strip: "પટ્ટી",
    cartoon: "કાર્ટૂન",
    billDispatch: "બિલ ડિસ્પેચ સારાંશ",
    orderDispatch: "ઓર્ડર ડિસ્પેચ સારાંશ",
    goodsCheckBeforeReceived: "પ્રાપ્ત થયા પહેલા માલની તપાસ કરો",
    taxInvoice: "કર ભરતિયું",
    IGST: "આઈજીએસટી",
    bill: "બિલ",
    purchaseReturn: "ખરીદી રીટર્ન",
    creditNote: "ઉધાર નોધ",
  },
};
