import endpoint from "../../config/endpoints";

export const taxJson = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.tax,
  changeExcel: endpoint.exportData,
  formPath: "/tax-form",
  screenTitle: "Tax",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Tax",
  showExcelDownload: true,
  excelFileName: "Tax",
  excelUrl: "tax-data" + '?sort={"insertDateTime": "DESC"}',

  tableColumnsToFilter: [
    {
      columnDisplayName: "Tax Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "SGST",
      columnKeyName: "sgst",
      isChecked: true,
    },
    {
      columnDisplayName: "CGST",
      columnKeyName: "cgst",
      isChecked: true,
    },
    {
      columnDisplayName: "IGST",
      columnKeyName: "igst",
      isChecked: true,
    },
    {
      columnDisplayName: "CESS",
      columnKeyName: "cess",
      isChecked: true,
    },
    {
      columnDisplayName: "HSN Code",
      columnKeyName: "hsnCode",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "Tax Name",
      controlType: "textfield",
      placeHolder: " Tax Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "name",
      isMandatory: true,
      uniqueValidation: true,
      inputType: "numberText",
    },
    {
      label: "SGST",
      controlType: "textfield",
      placeHolder: "SGST",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "sgst",
      inputType: "number",
      spValid: "float",
      isMandatory: true,
    },
    {
      label: "CGST",
      controlType: "textfield",
      placeHolder: " CGST",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "cgst",
      inputType: "number",
      spValid: "float",
      isMandatory: true,
    },
    {
      label: "IGST",
      controlType: "textfield",
      placeHolder: " IGST",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      spValid: "float",
      dataKey: "igst",
      inputType: "number",
      isMandatory: true,
    },
    {
      label: "CESS",
      controlType: "textfield",
      placeHolder: " CESS",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "cess",
      inputType: "number",
      spValid: "float",
      isMandatory: true,
    },
    {
      label: "HSN Code",
      controlType: "textfield",
      placeHolder: " HSN Code",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "hsnCode",
      inputType: "number",
      isMandatory: true,
    },
    {
      label: "Search By",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "type",
      tableDataKey: "type",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "Goods",
          value: "0",
        },
        {
          label: "Service",
          value: "1",
        },
      ],
      isMandatory: true,
    },
  ],
};
