import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { cloneDeep } from "lodash";
import { logout } from "../../CommonActions/User.action";
import endpoints from "../../config/endpoints";
import { Buffer } from "buffer";

let URL = endpoints.auth;
// const checkLocalStorage = () => {
//     if (localStorage.getItem('applicationState') !== null) {
//         const { user } = JSON.parse(localStorage.getItem('applicationState'))
//         return user?.isAuthenticated
//     }
//     return false
// }\\\\

const initialState = {
  isAuthenticated: false,
  user: {},
  token: false,
};

const loginSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    },
    loginFailure: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        isAuthenticated: false,
        user: {},
        token: false,
      };
    },
    tokenExpSuccess: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        token: true,
      };
    },
  },
});

export const { loginSuccess, tokenExpSuccess, loginFailure } =
  loginSlice.actions;

export default loginSlice.reducer;

//Actions

export const login =
  ({ username, password }) =>
    async (dispatch) => {
      try {
        // dispatch(loginSuccess({ name }));
        // localStorage.setItem('token', name);
        localStorage.setItem("trustId", "1");
        const headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          // Authorization: name,
        };
        
        const success = await axios
          .post(
            URL + "?username=" + username + "&password=" + password,
            {},
            { headers }
          )
          .then((response) => {
            if (response) {
              console.log("Success ========>", response.data);
              const { externalToken, error, id, username } = response.data;
              if (!error) {

                let name = "";

                if (externalToken != null) {
                  name = Buffer.from(externalToken).toString("base64");
                  localStorage.setItem("token", name);
                }
                if (id != null) {
                  localStorage.setItem("id", id);
                }
                if (username != null) {
                  localStorage.setItem("username", username);
                }

                dispatch(loginSuccess({ name }));
                return { success: true };
              }
              return { success: false };
            }
            return { success: false };
          })
          .catch((error) => {
            console.log("Error ========>", error);
            return { success: false };
          });
        return success;
      } catch (e) {
        dispatch(loginFailure());

        return console.error(e.message);
      }
    };

export const logOut = () => async (dispatch) => {
  try {
    dispatch(loginFailure());
    dispatch(logout());

    //localStorage.setItem('trustId', '1');
  } catch (e) {
    dispatch(loginFailure());

    return console.error(e.message);
  }
};

export const tokenExp = () => async (dispatch) => {
  try {
    dispatch(tokenExpSuccess());
    //localStorage.setItem('trustId', '1');
  } catch (e) { }
};
