import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import RTTable from "../../components/Comman/RT/RTTable";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import { noInternetMsg } from "../../config/messageconstant";
import { getPlaceOrderHeaderById } from "../../Slice/OrderHeaderList.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { PlaceOrderTableJson } from "../../DynamicFormsJson/MastersJSON/PlaceOrderTable";
class PlaceOrderView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
      placeOrder: [],
    };
    this.columns = [
      {
        title: "Sr.No.",
        name: "index",
        align: "center",
      },
      {
        title: "Product Name",
        name: "productName",
      },
      {
        title: "UOM",
        name: "stakeHolderUom",
        align: "center",
      },

      {
        title: "Rate",
        name: "rate",
        align: "right",
      },
      {
        title: "Order Quantity",
        name: "qty",
        align: "right",
      },
      {
        title: "Taxable Amount",
        name: "taxableAmt",
        align: "right",
      },
      {
        title: "Total Amount",
        name: "totalAmt",
        align: "right",
      },
    ];
  }
  async componentDidMount() {
    const { getPlaceOrderHeaderById, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      if (this.props.params.id) {
        showLoador({ loador: true });
        await getPlaceOrderHeaderById({
          orderHeaderId: this.props.params.id,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
          } else {
            let totalQty = 0;
            response.stockStakeHolderOrderDetails.length !== 0 &&
              response.stockStakeHolderOrderDetails.map(
                (orderDetailsObject) => {
                  totalQty = totalQty + orderDetailsObject.qty;
                }
              );
            this.setState({
              headerData: response,
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Order Number",
                  value: response.orderNo !== null ? response.orderNo : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Order Date",
                  value: response.orderDate !== null ? response.orderDate : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Taxable Amount",
                  value:
                    response.taxableAmt !== null ? response.taxableAmt : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Total Amount",
                  value: response.totalAmt !== null ? response.totalAmt : "",
                },

                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Total Quantity",
                  value: totalQty,
                },
              ],
              placeOrder:
                response.stockStakeHolderOrderDetails.length !== 0
                  ? response.stockStakeHolderOrderDetails
                  : [],
            });
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  render() {
    const { dynamicMasterData, placeOrder, DataToSet } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 0,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            DataToSet={DataToSet}
            dynamicMasterData={dynamicMasterData}
            showSaveBtn={PlaceOrderTableJson.showSaveBtn}
            showTitle={true}
            screenTitle={" Place Order  View"}
            fieldMeta={[]}
            showCancel={PlaceOrderTableJson.showCancel}
            apiBaseURL={PlaceOrderTableJson.apiBaseURL}
            showSaveNextBtn={PlaceOrderTableJson.showSaveNextBtn}
            saveBtnText={"Search"}
            showSaveBtnMain={false}
            padding={true}
            paddingTop={true}
          />
          <RTTable
            columns={this.columns}
            tableData={placeOrder}
            isActionColActive={false}
            showPegination={false}
          />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getPlaceOrderHeaderById,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PlaceOrderView);
