import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import DynamicTabwiseTable from "../../components/dynamicscreens/DynamicTabwiseTable";
import { withRouter } from "../../components/withRouter";
import { publishRateConfigurationJson } from "../../DynamicFormsJson/Transaction/PublishRateConfiguration";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import {
  serverMsg,
  deletemsg,
  noInternetMsg,
  deleteFailedMsg,
} from "../../config/messageconstant";
import { getCategory } from "../../Slice/category.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import {
  getpublishRateConfigCurrent,
  getpublishRateConfigFuture,
  getpublishRateConfigSearch,
} from "../../Slice/publishDateCheck.slice";
import { apiDelete } from "../../utils/api_service";
import endpoint from "../../config/endpoints";
class PublishRateConfigurationTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldData: {},
      dynamicMasterData: {
        currentList:
          this.props.checkPublicationDateByRateList?.publishRateConfigCurrent,
        futureList:
          this.props.checkPublicationDateByRateList?.publishRateConfigFuture,
        priviousList:
          this.props.checkPublicationDateByRateList?.publishRateConfigPrevious,
      },
      dynamicMasterDataToSearch: {
        stakeHolderTypeDetail:
          this.props.stakeHolderTypeList?.stockStakeHolderType,
        categoryDetail: this.props.categoryList?.category,
      },
      tabList: [
        {
          label: "Current Configuration",
          allyProps: 0,
          columns: [
            {
              name: "index",
              title: "Sr. No.",
              width: "10%",
              alignCenter: "center",
              showInscreen: true,
            },
            {
              title: "Configuration Name",
              name: "structureName",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Stock Holder Type",
              name: "name",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Publish Date",
              name: "publishDate",
              alignCenter: "center",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Action",
              name: "action",
              alignCenter: "center",
              showInscreen: true,
            },
          ],
          isActionColActive: true,
          value: "currentList",
        },
        {
          label: "Future Configuration",
          allyProps: 1,
          columns: [
            {
              name: "index",
              title: "Sr. No.",
              width: "10%",
              alignCenter: "center",
              showInscreen: true,
            },
            {
              title: "Configuration Name",
              name: "structureName",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Stock Holder Type",
              name: "name",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Publish Date",
              name: "publishDate",
              alignCenter: "center",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Action",
              name: "action",
              alignCenter: "center",
              showInscreen: true,
            },
          ],
          isActionColActive: true,
          value: "futureList",
        },
        {
          label: "Previous Configuration",
          allyProps: 2,

          columns: [
            {
              name: "index",
              title: "Sr. No.",
              width: "10%",
              alignCenter: "center",
              showInscreen: true,
            },
            {
              title: "Configuration Name",
              name: "structureName",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Published Date",
              name: "publishDate",
              alignCenter: "center",
              showInscreen: true,
              canSearch: true,
            },
            {
              title: "Action",
              name: "action",
              alignCenter: "center",
              showInscreen: true,
            },
          ],
          isActionColActive: true,
          value: "priviousList",
          fieldMeta: publishRateConfigurationJson.fieldMetaForSearch,
        },
      ],
    };
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.checkPublicationDateByRateList !==
      nextProps.checkPublicationDateByRateList
    ) {
      if (
        this.props.checkPublicationDateByRateList?.publishRateConfigCurrent !==
        nextProps.checkPublicationDateByRateList?.publishRateConfigCurrent
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            currentList:
              nextProps.checkPublicationDateByRateList
                ?.publishRateConfigCurrent,
          },
        });
      }
    }

    if (
      this.props.checkPublicationDateByRateList !==
      nextProps.checkPublicationDateByRateList
    ) {
      if (
        this.props.checkPublicationDateByRateList?.publishRateConfigFuture !==
        nextProps.checkPublicationDateByRateList?.publishRateConfigFuture
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            futureList:
              nextProps.checkPublicationDateByRateList?.publishRateConfigFuture,
          },
        });
      }
    }

    if (this.props.stakeHolderTypeList !== nextProps.stakeHolderTypeList) {
      if (
        this.props.stakeHolderTypeList?.stockStakeHolderType !==
        nextProps.stakeHolderTypeList?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterDataToSearch: {
            ...this.state.dynamicMasterDataToSearch,
            stakeHolderTypeDetail:
              nextProps.stakeHolderTypeList?.stockStakeHolderType,
          },
        });
      }
    }
    if (this.props.categoryList !== nextProps.categoryList) {
      if (
        this.props.categoryList?.category !== nextProps.categoryList?.category
      ) {
        this.setState({
          dynamicMasterDataToSearch: {
            ...this.state.dynamicMasterDataToSearch,
            categoryDetail: nextProps.categoryList?.category,
          },
        });
      }
    }
    if (
      this.props.checkPublicationDateByRateList !==
      nextProps.checkPublicationDateByRateList
    ) {
      if (
        this.props.checkPublicationDateByRateList?.publishRateConfigPrevious !==
        nextProps.checkPublicationDateByRateList?.publishRateConfigPrevious
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            priviousList:
              nextProps.checkPublicationDateByRateList
                ?.publishRateConfigPrevious,
          },
        });
      }
    }
  }
  getData = async () => {
    const {
      getpublishRateConfigCurrent,
      getpublishRateConfigFuture,
      getStockStakeHolderType,
      getCategory,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getpublishRateConfigCurrent().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      await getpublishRateConfigFuture().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getStockStakeHolderType({ userTypeIds: 2 }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      await getCategory().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  async componentDidMount() {
    this.getData();
  }

  onSave = (rowData) => {
    if (
      rowData.stockStakeHolderType &&
      rowData.stockStakeHolderType.id &&
      rowData.category
    ) {
      this.setState({
        fieldData: rowData,
      });
      this.getSearchList(rowData);
    }
  };
  getSearchList = (rowData) => {
    if (navigator.onLine) {
      const { getpublishRateConfigSearch, showLoador, showNotification } =
        this.props;

      showLoador({ loador: true });
      getpublishRateConfigSearch({
        stockStakeHolderTypeIds: rowData.stockStakeHolderType.id,
        categoryIds: rowData.category,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  rowDelete = (rowData) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiDelete({
        url: endpoint.checkPublicationDate + "/" + rowData.id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: deletemsg });
          this.getData();
          if (Object.keys(this.state.fieldData).length != 0) {
            this.getSearchList(this.state.fieldData);
          }
        } else {
          showNotification({ msg: deleteFailedMsg });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  rowViewData = (row) => {
    this.props.navigate("/publish-rate-configuration-view/" + row.id);
  };
  onAddButtonClick = () => {
    this.props.navigate(publishRateConfigurationJson.formPath);
  };
  render() {
    const { tabList, dynamicMasterData, dynamicMasterDataToSearch } =
      this.state;

    return (
      <>
        <DynamicTabwiseTable
          dynamicMasterData={dynamicMasterDataToSearch}
          getListById={this.getListById}
          rowList={dynamicMasterData}
          showHeadEdit={false}
          formPath={publishRateConfigurationJson.formPath}
          screenTitle={publishRateConfigurationJson.screenTitle}
          rowDelete={this.rowDelete}
          showHeadDelete={true}
          showPdfDownload={publishRateConfigurationJson.showPdfDownload}
          showExcelDownload={publishRateConfigurationJson.showExcelDownload}
          pdfFileName={publishRateConfigurationJson.pdfFileName}
          excelFileName={publishRateConfigurationJson.excelFileName}
          showAddButton={publishRateConfigurationJson.showAddButton}
          tableColumnsToFilter={
            publishRateConfigurationJson.tableColumnsToFilter
          }
          onSave={this.onSave}
          apiBaseURL={publishRateConfigurationJson.apiBaseURL}
          baseIdColumn={publishRateConfigurationJson.baseIdColumn}
          tabList={tabList}
          onAddButtonClick={this.onAddButtonClick}
          showView={true}
          formPathView={publishRateConfigurationJson.formPathView}
          rowViewData={this.rowViewData}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stakeHolderTypeList: state.stockStakeHolderType,
  categoryList: state.category,
  checkPublicationDateByRateList: state.publishRateConfigCurrent,
});
const mapDispatchToProps = {
  getpublishRateConfigCurrent,
  getpublishRateConfigFuture,
  getStockStakeHolderType,
  getCategory,
  getpublishRateConfigSearch,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PublishRateConfigurationTable);
