import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { ClaimStructureColumns } from "../../../tableColumns/table-columns";
import { getClaimStructure } from "../../../Slice/claim.slice";
import { ClaimStructureJson } from "../../../DynamicFormsJson/MastersJSON/claimStructure";

class ClaimStructureList extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { ClaimStructureList, getClaimStructure } = this.props;
        return (
            <>
                <DynamicMainScreen
                    formPath={ClaimStructureJson.formPath}
                    screenTitle={ClaimStructureJson.screenTitle}
                    fieldMeta={ClaimStructureJson.fieldMeta}
                    showPdfDownload={false}
                    showExcelDownload={false}
                    pdfFileName={ClaimStructureJson.pdfFileName}
                    excelFileName={ClaimStructureJson.excelFileName}
                    showAddButton={ClaimStructureJson.showAddButton}
                    tableColumnsToFilter={ClaimStructureJson.tableColumnsToFilter}
                    tableColumns={ClaimStructureColumns}
                    tableData={ClaimStructureList?.claimStructure}
                    getTableData={getClaimStructure}
                    apiBaseURL={ClaimStructureJson.apiBaseURL}
                    baseIdColumn={ClaimStructureJson.baseIdColumn}
                    showDeleteIcon={true}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    ClaimStructureList: state.claim,
});
const mapDispatchToProps = {
    getClaimStructure,
};
export default connect(mapStateToProps, mapDispatchToProps)(ClaimStructureList);
