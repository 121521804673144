import { Grid, Paper } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { redColor, titleColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  addressMsg,
  cinNumberMsg,
  contactNoMsg,
  emailIdMsg,
  fdiFssiNumberMsg,
  gstNumberMsg,
  invoicePrefixMsg,
  nameMsg,
  noInternetMsg,
  panNumberMsg,
  saveFailedMsg,
  serverMsg,
  updateData,
  updateMsg,
} from "../../config/messageconstant";
import { getDistrictByZone } from "../../Slice/district.slice";
import { getRegion } from "../../Slice/region.slice";
import { getStateByRegion } from "../../Slice/state.slice";
import { getTalukaByDistrict } from "../../Slice/taluka.slice";
import { getZoneByState } from "../../Slice/zone.slice";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
const CompanyForm = ({
  regionList,
  stateList,
  zoneList,
  districtList,
  talukaList,
  getRegion,
  getStateByRegion,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrict,
  showLoador,
  showNotification,
}) => {
  const [formData, setFormData] = useState({});
  const [name, setName] = useState("");
  const [contactNo, setcontactNo] = useState("");
  const [emailId, setEmailId] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [gstNo, setgstNo] = useState("");
  const [panNo, setpanNo] = useState("");
  const [contactPersonNo, setcontactPersonNo] = useState("");
  const [contactPersonAddress, setContactPersonAddress] = useState("");
  const [cinNo, setcinNo] = useState("");
  const [fdiNoFssiNo, setfdiNoFssiNo] = useState("");
  const [formErrors, setFormErrors] = React.useState({});
  const [region, setRegion] = useState("");
  const [state, setStates] = useState("");
  const [zone, setZone] = useState([]);
  const [district, setDistrict] = useState([]);
  const [taluka, setTaluka] = useState([]);
  const [area, setArea] = useState([]);
  const [invoicePrefix, setInvoicePrefix] = useState("");
  const [logo, setLogo] = useState("");
  const changeHandler = (e) => {
    const { name, value } = e.target;
    console.log(e);
    if (name === "name") {
      setName(value);
    } else if (name === "contactNo") {
      if (!/^\d{10}$/.test(value)) {
        setFormErrors({
          ...formErrors,
          contactNumberError: "Please enter a valid 10-digit mobile number.",
        });
      } else {
        setFormErrors({
          ...formErrors,
          contactNumberError: "",
        });
      }
      setcontactNo(value);
    } else if (name === "emailId") {
      const emailValidation = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailValidation.test(value)) {
        setFormErrors({
          ...formErrors,
          emailIdError: "Please enter a valid email address.",
        });
      } else {
        setFormErrors({
          ...formErrors,
          emailIdError: "",
        });
      }
      setEmailId(value);
    } else if (name === "address1") {
      setAddress1(value);
    } else if (name === "address2") {
      setAddress2(value);
    } else if (name === "gstNo") {
      const gstNoValidation =
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$/;
      if (!gstNoValidation.test(value)) {
        setFormErrors({
          ...formErrors,
          gstNumberError:
            "Please enter a valid GST number (e.g., 22AAAAA1234A1Z5).",
        });
      } else {
        setFormErrors({
          ...formErrors,
          gstNumberError: "",
        });
      }
      setgstNo(value);
    } else if (name === "panNo") {
      const panNoValidation = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
      if (!panNoValidation.test(value)) {
        setFormErrors({
          ...formErrors,
          panNumberError:
            "Please enter a valid PAN card number (e.g., AAAAB1111A).",
        });
      } else {
        setFormErrors({
          ...formErrors,
          panNumberError: "",
        });
      }
      setpanNo(value);
    } else if (name === "contactPersonNo") {
      if (!/^\d{10}$/.test(value)) {
        setFormErrors({
          ...formErrors,
          contactPersonNumberError:
            "Please enter a valid 10-digit mobile number.",
        });
      } else {
        setFormErrors({
          ...formErrors,
          contactPersonNumberError: "",
        });
      }
      setcontactPersonNo(value);
    } else if (name === "contactPersonAddress") {
      setContactPersonAddress(value);
    } else if (name === "cinNo") {
      setcinNo(value);
    } else if (name === "invoicePrefix") {
      setInvoicePrefix(value);
    } else {
      setfdiNoFssiNo(value);
    }
  };
  const changeHandlerForDropdown = (name, dropdown) => {
    console.log(dropdown);
    if (name === "region") {
      setRegion(dropdown);
      showLoador({ loador: true });
      getStateByRegion({ regionIds: dropdown }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else if (name === "state") {
      setStates(dropdown);
      showLoador({ loador: true });
      getZoneByState({ stateIds: dropdown }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else if (name === "zone") {
      setZone(dropdown);
      showLoador({ loador: true });
      getDistrictByZone({ zoneIds: dropdown }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else if (name === "district") {
      setDistrict(dropdown);
      showLoador({ loador: true });
      getTalukaByDistrict({ districtIds: dropdown }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else if (name === "taluka") {
      setTaluka(dropdown);
    }
  };
  useEffect(() => {
    (async () => {
      if (navigator.onLine) {
        showLoador({ loador: true });
        await getRegion().then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
        showLoador({ loador: true });
        await apiGet({
          url: endpoint.company + "/exist-or-not",
        }).then((data, success) => {
          console.log(data);
          showLoador({ loador: false });
          if (data.data.error) {
            showNotification({
              msg: "data not found",
            });
          } else {
            showNotification({ msg: "data found" });
            if (
              Object.keys(data.data.data).length != 0 &&
              Object.keys(data.data.data.company).length != 0
            ) {
              let companyData =
                data.data.data.company === null ? {} : data.data.data.company;
              setName(companyData.name === null ? "" : companyData.name);
              setcontactNo(
                companyData.name === null ? "" : companyData.contactNo
              );
              setEmailId(
                companyData.emailId === null ? "" : companyData.emailId
              );
              setAddress1(
                companyData.address1 === null ? "" : companyData.address1
              );
              setAddress2(
                companyData.address2 === null ? "" : companyData.address2
              );
              setgstNo(companyData.gstNo === null ? "" : companyData.gstNo);
              setpanNo(companyData.panNo === null ? "" : companyData.panNo);
              setcontactPersonNo(
                companyData.contactPersonNo === null
                  ? ""
                  : companyData.contactPersonNo
              );
              setContactPersonAddress(
                companyData.contactPersonAddress === null
                  ? ""
                  : companyData.contactPersonAddress
              );
              setcinNo(companyData.cinNo === null ? "" : companyData.cinNo);
              setfdiNoFssiNo(
                companyData.fdiNoFssiNo === null ? "" : companyData.fdiNoFssiNo
              );
              setInvoicePrefix(
                companyData.invoicePrefix === null
                  ? ""
                  : companyData.invoicePrefix
              );
              setLogo(companyData.logo === null ? "" : companyData.logo);
              let stateInfo =
                companyData.state === null ? {} : companyData.state;
              setStates(stateInfo.id);
              let regionId =
                stateInfo.region && stateInfo.region === null
                  ? ""
                  : stateInfo.region.id;
              setRegion(regionId);

              let districtInfo =
                companyData.district === null ? "" : companyData.district;
              setDistrict(districtInfo.id);
              let zoneId =
                districtInfo.zone === null ? "" : districtInfo.zone.id;
              setZone(zoneId);
              setTaluka(
                companyData.taluka === null ? "" : companyData.taluka.id
              );
              setFormData(companyData);
              getListData({
                zoneId: zoneId,
                regionId: regionId,
                stateId: stateInfo.id,
                districtId: districtInfo.id,
              });
            }
          }
        });
      } else {
        showNotification({ msg: noInternetMsg, severity: "error" });
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);
  const getListData = (editIds) => {
    showLoador({ loador: true });
    getStateByRegion({
      regionIds: editIds.regionId,
    }).then(({ success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      }
    });
    showLoador({ loador: true });
    getZoneByState({ stateIds: editIds.stateId }).then(({ success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      }
    });
    showLoador({ loador: true });
    getDistrictByZone({
      zoneIds: editIds.zoneId,
    }).then(({ success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      }
    });
    showLoador({ loador: true });
    getTalukaByDistrict({
      districtIds: editIds.districtId,
    }).then(({ success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      }
    });
  };
  const checkValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (name === "") {
      formIsValid = false;
      formErrors["nameError"] = nameMsg;
    }
    if (contactNo === "") {
      formIsValid = false;
      formErrors["contactNumberError"] = contactNoMsg;
    }
    if (emailId === "") {
      formIsValid = false;
      formErrors["emailIdError"] = emailIdMsg;
    }
    if (address1 === "") {
      formIsValid = false;
      formErrors["address1Error"] = addressMsg;
    }
    if (address2 === "") {
      formIsValid = false;
      formErrors["address2Error"] = addressMsg;
    }
    if (gstNo === "") {
      formIsValid = false;
      formErrors["gstNumberError"] = gstNumberMsg;
    }
    if (panNo === "") {
      formIsValid = false;
      formErrors["panNumberError"] = panNumberMsg;
    }
    if (contactPersonNo === "") {
      formIsValid = false;
      formErrors["contactPersonNumberError"] = contactNoMsg;
    }
    if (contactPersonAddress === "") {
      formIsValid = false;
      formErrors["contactPersonAddressError"] = addressMsg;
    }
    if (cinNo === "") {
      formIsValid = false;
      formErrors["cinNumberError"] = cinNumberMsg;
    }
    if (invoicePrefix === "") {
      formIsValid = false;
      formErrors["invoicePrefixError"] = invoicePrefixMsg;
    }
    if (fdiNoFssiNo === "") {
      formIsValid = false;
      formErrors["fdiFssiNumberError"] = fdiFssiNumberMsg;
    }
    setFormErrors(formErrors);
    return formIsValid;
  };

  const saveCompanyData = () => {
    if (navigator.onLine) {
      if (checkValidation()) {
        swal({
          title: "Are you sure?",
          text: updateData,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            let companyDataObject = {
              ...formData,
              name: name,
              contactNo: contactNo,
              emailId: emailId,
              address1: address1,
              address2: address2,
              gstNo: gstNo,
              panNo: panNo,
              contactPersonNo: contactPersonNo,
              contactPersonAddress: contactPersonAddress,
              cinNo: cinNo,
              fdiNoFssiNo: fdiNoFssiNo,
              invoicePrefix: invoicePrefix,
              logo: logo,
              state:
                state === ""
                  ? null
                  : {
                      id: state,
                    },
              district:
                district === ""
                  ? null
                  : {
                      id: district,
                    },
              taluka:
                taluka === ""
                  ? null
                  : {
                      id: taluka,
                    },
            };
            onSave(companyDataObject);
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  const onSave = (companyDataObject) => {
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url: endpoint.company,
        postBody: companyDataObject,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: updateMsg });
          window.location.reload();
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  return (
    <>
      <Paper
        sx={{
          p: 2,
          borderRadius: 2,
        }}
        elevation={0}
      >
        <LandingScreenHeader
          screenTitle={"Compnay Profile"}
          showTable={false}
          showBackButton={false}
          showAddButton={false}
        />
        <div>
          <br></br>
          <>
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              lg={12}
              container
              justifyContent="space-between"
            >
              <br></br>
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-between"
              >
                <Grid
                  item
                  sm={6}
                  xs={8}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} md={5} sm={5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: redColor }}
                      label="* "
                    />
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: titleColor }}
                      label="Region:"
                    />
                  </Grid>
                  <Grid item xs={12} md={7} sm={7}>
                    <AutoComplete
                      keyColName={"id"}
                      placeHolder="Select Region"
                      value={region}
                      name={"region"}
                      options={regionList?.region}
                      errorText={
                        formErrors["regionError"]
                          ? formErrors["regionError"]
                          : ""
                      }
                      isError={
                        formErrors["regionError"]
                          ? formErrors["regionError"]
                          : ""
                      }
                      onChange={changeHandlerForDropdown}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={8}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} md={5} sm={5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: redColor }}
                      label="* "
                    />
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: titleColor }}
                      label="State:"
                    />
                  </Grid>
                  <Grid item xs={12} md={7} sm={7}>
                    <AutoComplete
                      keyColName={"id"}
                      placeHolder="Select State"
                      value={state}
                      name={"state"}
                      options={stateList?.stateByRegion}
                      errorText={
                        formErrors["stateError"] ? formErrors["stateError"] : ""
                      }
                      isError={
                        formErrors["stateError"] ? formErrors["stateError"] : ""
                      }
                      onChange={changeHandlerForDropdown}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <br /> <br />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-between"
              >
                <Grid
                  item
                  sm={6}
                  xs={8}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} md={5} sm={5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: redColor }}
                      label="* "
                    />
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: titleColor }}
                      label="Zone:"
                    />
                  </Grid>
                  <Grid item xs={12} md={7} sm={7}>
                    <AutoComplete
                      keyColName={"id"}
                      placeHolder="Select Zone"
                      value={zone}
                      name={"zone"}
                      options={zoneList?.zoneByState}
                      errorText={
                        formErrors["zoneError"] ? formErrors["zoneError"] : ""
                      }
                      isError={
                        formErrors["zoneError"] ? formErrors["zoneError"] : ""
                      }
                      onChange={changeHandlerForDropdown}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={8}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} md={5} sm={5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: redColor }}
                      label="* "
                    />
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: titleColor }}
                      label="District:"
                    />
                  </Grid>
                  <Grid item xs={12} md={7} sm={7}>
                    <AutoComplete
                      keyColName={"id"}
                      placeHolder="Select District"
                      value={district}
                      name={"district"}
                      options={districtList?.districtByZone}
                      errorText={
                        formErrors["districtError"]
                          ? formErrors["districtError"]
                          : ""
                      }
                      isError={
                        formErrors["districtError"]
                          ? formErrors["districtError"]
                          : ""
                      }
                      onChange={changeHandlerForDropdown}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <br /> <br />
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                container
                justifyContent="space-between"
              >
                <Grid
                  item
                  sm={6}
                  xs={8}
                  md={6}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} md={5} sm={5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: redColor }}
                      label="* "
                    />
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: titleColor }}
                      label="Taluka:"
                    />
                  </Grid>
                  <Grid item xs={12} md={7} sm={7}>
                    <AutoComplete
                      keyColName={"id"}
                      placeHolder="Select Taluka"
                      value={taluka}
                      name={"taluka"}
                      options={talukaList?.talukaByDistrict}
                      errorText={
                        formErrors["talukaError"]
                          ? formErrors["talukaError"]
                          : ""
                      }
                      isError={
                        formErrors["talukaError"]
                          ? formErrors["talukaError"]
                          : ""
                      }
                      onChange={changeHandlerForDropdown}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={7}
                  md={8}
                  lg={6}
                  container
                  justifyContent="space-evenly"
                >
                  <Grid item xs={12} md={5} sm={5}>
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: redColor }}
                      label="* "
                    />
                    <LabelCompo
                      className="text-black"
                      style={{ fontSize: 16, color: titleColor }}
                      label="Name :"
                    />
                  </Grid>
                  <Grid item xs={12} md={7} sm={7}>
                    <TextFieldCompo
                      placeHolder={"Name"}
                      color="primary"
                      name="name"
                      size="small"
                      value={name}
                      fullWidth
                      onChange={changeHandler}
                      errorText={
                        formErrors["nameError"] ? formErrors["nameError"] : ""
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <br /> <br />
              <Grid
                item
                sm={6}
                xs={8}
                md={6}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} md={5} sm={5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label="* "
                  />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="Contact Number :"
                  />
                </Grid>
                <Grid item xs={12} md={7} sm={7}>
                  <TextFieldCompo
                    placeHolder={"Contact Number"}
                    color="primary"
                    name="contactNo"
                    size="small"
                    value={contactNo}
                    fullWidth
                    onChange={changeHandler}
                    type="number"
                    errorText={
                      formErrors["contactNumberError"]
                        ? formErrors["contactNumberError"]
                        : ""
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sm={6}
                xs={7}
                md={8}
                lg={6}
                container
                justifyContent="space-evenly"
              >
                <Grid item xs={12} md={5} sm={5}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: redColor }}
                    label="* "
                  />
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 16, color: titleColor }}
                    label="Email Id :"
                  />
                </Grid>
                <Grid item xs={12} md={7} sm={7}>
                  <TextFieldCompo
                    placeHolder={"Email Id "}
                    color="primary"
                    name="emailId"
                    size="small"
                    value={emailId}
                    fullWidth
                    onChange={changeHandler}
                    errorText={
                      formErrors["emailIdError"]
                        ? formErrors["emailIdError"]
                        : ""
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-between"
          >
            <Grid
              item
              sm={6}
              xs={8}
              md={6}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} md={5} sm={5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="* "
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="Address 1 :"
                />
              </Grid>
              <Grid item xs={12} md={7} sm={7}>
                <TextFieldCompo
                  placeHolder={"Address 1"}
                  color="primary"
                  name="address1"
                  size="small"
                  value={address1}
                  fullWidth
                  onChange={changeHandler}
                  errorText={
                    formErrors["address1Error"]
                      ? formErrors["address1Error"]
                      : ""
                  }
                />
              </Grid>
            </Grid>
            <Grid
              item
              sm={6}
              xs={8}
              md={6}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} md={5} sm={5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="* "
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="Address 2 :"
                />
              </Grid>
              <Grid item xs={12} md={7} sm={7}>
                <TextFieldCompo
                  placeHolder={"Address 2"}
                  color="primary"
                  name="address2"
                  size="small"
                  value={address2}
                  fullWidth
                  onChange={changeHandler}
                  multiline={true}
                  errorText={
                    formErrors["address2Error"]
                      ? formErrors["address2Error"]
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-between"
          >
            <Grid
              item
              sm={6}
              xs={8}
              md={6}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} md={5} sm={5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="* "
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="Contact Person Number :"
                />
              </Grid>
              <Grid item xs={12} md={7} sm={7}>
                <TextFieldCompo
                  placeHolder={"Contact Person number"}
                  color="primary"
                  name="contactPersonNo"
                  size="small"
                  value={contactPersonNo}
                  fullWidth
                  onChange={changeHandler}
                  multiline={true}
                  type="number"
                  errorText={
                    formErrors["contactPersonNumberError"]
                      ? formErrors["contactPersonNumberError"]
                      : ""
                  }
                />
              </Grid>
            </Grid>
            <Grid
              item
              sm={6}
              xs={8}
              md={6}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} md={5} sm={5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="* "
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="Contact Person Address :"
                />
              </Grid>
              <Grid item xs={12} md={7} sm={7}>
                <TextFieldCompo
                  placeHolder={"Contact Person Address"}
                  color="primary"
                  name="contactPersonAddress"
                  size="small"
                  value={contactPersonAddress}
                  fullWidth
                  onChange={changeHandler}
                  multiline={true}
                  errorText={
                    formErrors["contactPersonAddressError"]
                      ? formErrors["contactPersonAddressError"]
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-between"
          >
            <Grid
              item
              sm={6}
              xs={8}
              md={6}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} md={5} sm={5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="* "
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="CIN Number :"
                />
              </Grid>
              <Grid item xs={12} md={7} sm={7}>
                <TextFieldCompo
                  placeHolder={"CIN Number"}
                  color="primary"
                  name="cinNo"
                  size="small"
                  value={cinNo}
                  fullWidth
                  onChange={changeHandler}
                  multiline={true}
                  errorText={
                    formErrors["cinNumberError"]
                      ? formErrors["cinNumberError"]
                      : ""
                  }
                />
              </Grid>
            </Grid>
            <Grid
              item
              sm={6}
              xs={8}
              md={6}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} md={5} sm={5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="* "
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="FDI FSSI Number :"
                />
              </Grid>
              <Grid item xs={12} md={7} sm={7}>
                <TextFieldCompo
                  placeHolder={"FdiFssi Number"}
                  color="primary"
                  name="fdiNoFssiNo"
                  size="small"
                  value={fdiNoFssiNo}
                  fullWidth
                  onChange={changeHandler}
                  multiline={true}
                  errorText={
                    formErrors["fdiFssiNumberError"]
                      ? formErrors["fdiFssiNumberError"]
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-between"
          >
            <Grid
              item
              sm={6}
              xs={8}
              md={6}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} md={5} sm={5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="* "
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="GST Number :"
                />
              </Grid>
              <Grid item xs={12} md={7} sm={7}>
                <TextFieldCompo
                  placeHolder={"GST Number"}
                  color="primary"
                  name="gstNo"
                  size="small"
                  value={gstNo}
                  fullWidth
                  onChange={changeHandler}
                  multiline={true}
                  errorText={
                    formErrors["gstNumberError"]
                      ? formErrors["gstNumberError"]
                      : ""
                  }
                />
              </Grid>
            </Grid>
            <Grid
              item
              sm={6}
              xs={8}
              md={6}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} md={5} sm={5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="* "
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="PAN Number :"
                />
              </Grid>
              <Grid item xs={12} md={7} sm={7}>
                <TextFieldCompo
                  placeHolder={"PAN Number"}
                  color="primary"
                  name="panNo"
                  size="small"
                  value={panNo}
                  fullWidth
                  onChange={changeHandler}
                  multiline={true}
                  errorText={
                    formErrors["panNumberError"]
                      ? formErrors["panNumberError"]
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="space-between"
          >
            <Grid
              item
              sm={6}
              xs={8}
              md={6}
              lg={6}
              container
              justifyContent="space-evenly"
            >
              <Grid item xs={12} md={5} sm={5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="* "
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="Invoice Prefix :"
                />
              </Grid>
              <Grid item xs={12} md={7} sm={7}>
                <TextFieldCompo
                  placeHolder={"Invoice Prefix"}
                  color="primary"
                  name="invoicePrefix"
                  size="small"
                  value={invoicePrefix}
                  fullWidth
                  onChange={changeHandler}
                  errorText={
                    formErrors["invoicePrefixError"]
                      ? formErrors["invoicePrefixError"]
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </Grid>

          <DialogActions>
            <Grid item sx={{ mr: 3 }} xs={12} sm={12} md={1}>
              <ButtonCompo
                size="medium"
                onClick={saveCompanyData}
                variant="contained"
                name="Submit"
              />
            </Grid>
          </DialogActions>
        </div>
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getRegion,
  getStateByRegion,
  getZoneByState,
  getDistrictByZone,
  getTalukaByDistrict,
};
export default connect(mapStateToProps, mapDispatchToProps)(CompanyForm);
