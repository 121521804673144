export const downloadJson = (jsonData) => {
  // Replace with your JSON data

  // Convert JSON to string
  const jsonString = JSON.stringify(jsonData, null, 2);

  // Create a Blob from the string
  const blob = new Blob([jsonString], { type: "text/plain" });

  // Create a URL for the Blob
  const url = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");

  // Set the link's href to the URL created for the Blob
  link.href = url;

  // Set the download attribute to specify the file name
  link.download = "json_data.json";

  // Append the link to the document
  document.body.appendChild(link);

  // Trigger a click on the link to start the download
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
};
