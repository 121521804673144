import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { ConvertAmount } from "../components/Comman/ConvertValueWithDenominator";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  targetNew: [],
  notInsertedTargetNew: [],
  headerListForTarget: [],
};
let URL = endpoints.target;
const targetSlice = createSlice({
  name: "target",
  initialState,
  reducers: {
    targetSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        targetNew: row,
      };
    },
    notInsertedTargetSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        notInsertedTargetNew: row,
      };
    },

    headerListForTargetSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        headerListForTarget: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  targetSuccess,
  notInsertedTargetSuccess,
  headerListForTargetSuccess,
  resetState,
} = targetSlice.actions;

export default targetSlice.reducer;

export const getTargetByStakeHolderTypeIdAndYearId =
  ({ stockStakeHolderTypeId, yearId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-stock-stake-holder-type-and-year-and-freez?stockStakeHolderTypeId=" +
          stockStakeHolderTypeId +
          "&yearId=" +
          yearId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.targets.map((targetObject, index) => {
            let targetData = {
              index: index + 1,
              id: targetObject.id === null ? "" : targetObject.id,
              name:
                targetObject.salesTeam !== null && targetObject.salesTeam.name
                  ? targetObject.salesTeam.name
                  : "",

              yearName:
                targetObject.yearMaster !== null && targetObject.yearMaster.name
                  ? targetObject.yearMaster.name
                  : "",
              year:
                targetObject.yearMaster !== null && targetObject.yearMaster.id
                  ? targetObject.yearMaster.id
                  : "",

              jan:
                targetObject.jan === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.jan }),
              feb:
                targetObject.feb === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.feb }),
              march:
                targetObject.march === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.march }),
              april:
                targetObject.april === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.april }),
              may:
                targetObject.may === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.may }),
              jun:
                targetObject.jun === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.jun }),
              july:
                targetObject.july === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.july }),
              aug:
                targetObject.aug === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.aug }),
              sep:
                targetObject.sep === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.sep }),
              oct:
                targetObject.oct === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.oct }),
              nov:
                targetObject.nov === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.nov }),
              dec:
                targetObject.dec === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.dec }),
              totalTarget:
                targetObject.totalTarget === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.totalTarget }),
              isChecked: false,
            };
            return targetData;
          });
          dispatch(targetSuccess({ row }));
          dispatch(
            headerListForTargetSuccess({
              row: data.data.dateSequences,
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getTargetByStakeHolderTypeIdAndYearIdWithFilter =
  ({ stockStakeHolderTypeId, yearId, isFreez }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-stock-stake-holder-type-and-year-and-is-freez?stockStakeHolderTypeId=" +
          stockStakeHolderTypeId +
          "&yearId=" +
          yearId +
          "&isFreez=" +
          isFreez,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.targets.map((targetObject, index) => {
            let targetData = {
              index: index + 1,
              id: targetObject.id === null ? "" : targetObject.id,
              name:
                targetObject.salesTeam !== null && targetObject.salesTeam.name
                  ? targetObject.salesTeam.name
                  : "",
              regionName:
                targetObject.regionName !== null ? targetObject.regionName : "",
              stateName:
                targetObject.stateNames !== null ? targetObject.stateNames : "",
              zoneName:
                targetObject.zoneNames !== null ? targetObject.zoneNames : "",
              areaName:
                targetObject.areaNames !== null ? targetObject.areaNames : "",
              yearName:
                targetObject.yearMaster !== null && targetObject.yearMaster.name
                  ? targetObject.yearMaster.name
                  : "",
              year:
                targetObject.yearMaster !== null && targetObject.yearMaster.id
                  ? targetObject.yearMaster.id
                  : "",

              jan:
                targetObject.jan === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.jan }),
              feb:
                targetObject.feb === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.feb }),
              march:
                targetObject.march === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.march }),
              april:
                targetObject.april === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.april }),
              may:
                targetObject.may === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.may }),
              jun:
                targetObject.jun === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.jun }),
              july:
                targetObject.july === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.july }),
              aug:
                targetObject.aug === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.aug }),
              sep:
                targetObject.sep === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.sep }),
              oct:
                targetObject.oct === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.oct }),
              nov:
                targetObject.nov === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.nov }),
              dec:
                targetObject.dec === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.dec }),
              totalTarget:
                targetObject.totalTarget === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.totalTarget }),
              isChecked: false,
            };
            return targetData;
          });
          dispatch(targetSuccess({ row }));
          dispatch(
            headerListForTargetSuccess({
              row: data.data.dateSequences,
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getTargetByStakeHolderTypeIdAndYearIdWithFilterPost =
  ({ jsonDataByPost }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url:
          URL + "/by-stock-stake-holder-type-and-year-and-is-freez-and-areas",
        postBody: jsonDataByPost,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.targets.map((targetObject, index) => {
            let targetData = {
              index: index + 1,
              id: targetObject.id === null ? "" : targetObject.id,
              name:
                targetObject.salesTeam !== null && targetObject.salesTeam.name
                  ? targetObject.salesTeam.name
                  : "",
              regionName:
                targetObject.regionName !== null ? targetObject.regionName : "",
              stateName:
                targetObject.stateNames !== null ? targetObject.stateNames : "",
              zoneName:
                targetObject.zoneNames !== null ? targetObject.zoneNames : "",
              areaName:
                targetObject.areaNames !== null ? targetObject.areaNames : "",
              yearName:
                targetObject.yearMaster !== null && targetObject.yearMaster.name
                  ? targetObject.yearMaster.name
                  : "",
              year:
                targetObject.yearMaster !== null && targetObject.yearMaster.id
                  ? targetObject.yearMaster.id
                  : "",

              jan:
                targetObject.jan === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.jan }),
              feb:
                targetObject.feb === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.feb }),
              march:
                targetObject.march === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.march }),
              april:
                targetObject.april === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.april }),
              may:
                targetObject.may === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.may }),
              jun:
                targetObject.jun === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.jun }),
              july:
                targetObject.july === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.july }),
              aug:
                targetObject.aug === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.aug }),
              sep:
                targetObject.sep === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.sep }),
              oct:
                targetObject.oct === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.oct }),
              nov:
                targetObject.nov === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.nov }),
              dec:
                targetObject.dec === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.dec }),
              totalTarget:
                targetObject.totalTarget === null
                  ? ""
                  : ConvertAmount({ amount: targetObject.totalTarget }),
              isChecked: false,
            };
            return targetData;
          });
          dispatch(targetSuccess({ row }));
          dispatch(
            headerListForTargetSuccess({
              row: data.data.dateSequences,
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getNotInsertedTargetByStakeHolderTypeIdAndYearId =
  ({ stockStakeHolderTypeId, yearId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/with-not-inserted-by-stock-stake-holder-type-and-year?stockStakeHolderTypeId=" +
          stockStakeHolderTypeId +
          "&yearId=" +
          yearId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((notInsertedTargetObject, index) => {
            let notInsertedTargetData = {
              index: index + 1,
              primaryId:
                notInsertedTargetObject.primaryId === null
                  ? ""
                  : notInsertedTargetObject.primaryId,
              regionTargetPercentageId:
                notInsertedTargetObject.regionTargetPercentageId === null
                  ? ""
                  : notInsertedTargetObject.regionTargetPercentageId,
              id:
                notInsertedTargetObject.id === null
                  ? ""
                  : notInsertedTargetObject.id,
              salesTeamId:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.id
                  ? notInsertedTargetObject.salesTeam.id
                  : "",

              name:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.name
                  ? notInsertedTargetObject.salesTeam.name
                  : "",

              whatsAppNo:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.whatsAppNo
                  ? notInsertedTargetObject.salesTeam.whatsAppNo
                  : "",
              contactNo:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.contactNo
                  ? notInsertedTargetObject.salesTeam.contactNo
                  : "",
              address:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.address
                  ? notInsertedTargetObject.salesTeam.address
                  : "",

              employeeCode:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.employeeCode
                  ? notInsertedTargetObject.salesTeam.employeeCode
                  : "",

              emailId:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.emailId
                  ? notInsertedTargetObject.salesTeam.emailId
                  : "",

              region:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.region
                  ? notInsertedTargetObject.salesTeam.region.name
                  : "",

              zoneIds:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.zoneIds
                  ? notInsertedTargetObject.salesTeam.zoneIds
                  : "",

              areaIds:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.areaIds
                  ? notInsertedTargetObject.salesTeam.areaIds
                  : "",

              accessRoleId:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.accessRoleId
                  ? notInsertedTargetObject.salesTeam.accessRoleId
                  : "",

              isActive:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.isActive
                  ? notInsertedTargetObject.salesTeam.isActive
                  : "",

              insertDateTime:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.insertDateTime
                  ? notInsertedTargetObject.salesTeam.insertDateTime
                  : "",

              yearName:
                notInsertedTargetObject.yearMaster !== null &&
                notInsertedTargetObject.yearMaster.name
                  ? notInsertedTargetObject.yearMaster.name
                  : "",
              year:
                notInsertedTargetObject.yearMaster !== null &&
                notInsertedTargetObject.yearMaster.id
                  ? notInsertedTargetObject.yearMaster.id
                  : "",
              isFreez:
                notInsertedTargetObject.isFreez === null
                  ? ""
                  : notInsertedTargetObject.isFreez,

              jan:
                notInsertedTargetObject.jan === null
                  ? ""
                  : notInsertedTargetObject.jan,
              feb:
                notInsertedTargetObject.feb === null
                  ? ""
                  : notInsertedTargetObject.feb,
              march:
                notInsertedTargetObject.march === null
                  ? ""
                  : notInsertedTargetObject.march,
              april:
                notInsertedTargetObject.april === null
                  ? ""
                  : notInsertedTargetObject.april,
              may:
                notInsertedTargetObject.may === null
                  ? ""
                  : notInsertedTargetObject.may,
              jun:
                notInsertedTargetObject.jun === null
                  ? ""
                  : notInsertedTargetObject.jun,
              july:
                notInsertedTargetObject.july === null
                  ? ""
                  : notInsertedTargetObject.july,
              aug:
                notInsertedTargetObject.aug === null
                  ? ""
                  : notInsertedTargetObject.aug,
              sep:
                notInsertedTargetObject.sep === null
                  ? ""
                  : notInsertedTargetObject.sep,
              oct:
                notInsertedTargetObject.oct === null
                  ? ""
                  : notInsertedTargetObject.oct,
              nov:
                notInsertedTargetObject.nov === null
                  ? ""
                  : notInsertedTargetObject.nov,
              dec:
                notInsertedTargetObject.dec === null
                  ? ""
                  : notInsertedTargetObject.dec,

              janPer:
                notInsertedTargetObject.janPer === null
                  ? ""
                  : notInsertedTargetObject.janPer,
              febPer:
                notInsertedTargetObject.febPer === null
                  ? ""
                  : notInsertedTargetObject.febPer,
              marchPer:
                notInsertedTargetObject.marchPer === null
                  ? ""
                  : notInsertedTargetObject.marchPer,
              aprilPer:
                notInsertedTargetObject.aprilPer === null
                  ? ""
                  : notInsertedTargetObject.aprilPer,
              mayPer:
                notInsertedTargetObject.mayPer === null
                  ? ""
                  : notInsertedTargetObject.mayPer,
              junPer:
                notInsertedTargetObject.junPer === null
                  ? ""
                  : notInsertedTargetObject.junPer,
              julyPer:
                notInsertedTargetObject.julyPer === null
                  ? ""
                  : notInsertedTargetObject.julyPer,
              augPer:
                notInsertedTargetObject.augPer === null
                  ? ""
                  : notInsertedTargetObject.augPer,
              sepPer:
                notInsertedTargetObject.sepPer === null
                  ? ""
                  : notInsertedTargetObject.sepPer,
              octPer:
                notInsertedTargetObject.octPer === null
                  ? ""
                  : notInsertedTargetObject.octPer,
              novPer:
                notInsertedTargetObject.novPer === null
                  ? ""
                  : notInsertedTargetObject.novPer,
              decPer:
                notInsertedTargetObject.decPer === null
                  ? ""
                  : notInsertedTargetObject.decPer,

              regionName:
                notInsertedTargetObject.regionName === null
                  ? ""
                  : notInsertedTargetObject.regionName,
              stateName:
                notInsertedTargetObject.stateNames !== null
                  ? notInsertedTargetObject.stateNames
                  : "",

              zoneNames:
                notInsertedTargetObject.zoneNames === null
                  ? ""
                  : notInsertedTargetObject.zoneNames,
              areaName:
                notInsertedTargetObject.areaNames === null
                  ? ""
                  : notInsertedTargetObject.areaNames,
              areaCount:
                notInsertedTargetObject.areaCount === null
                  ? ""
                  : notInsertedTargetObject.areaCount,
              distributorCount:
                notInsertedTargetObject.distributorCount === null
                  ? ""
                  : notInsertedTargetObject.distributorCount,
              outletCount:
                notInsertedTargetObject.outletCount === null
                  ? ""
                  : notInsertedTargetObject.outletCount,

              salesTeamCount:
                notInsertedTargetObject.salesTeamCount === null
                  ? ""
                  : notInsertedTargetObject.salesTeamCount,
              lastYearTotalAcheived:
                notInsertedTargetObject.lastYearTotalAcheived === null
                  ? ""
                  : notInsertedTargetObject.lastYearTotalAcheived,
              lastYearTotalTarget:
                notInsertedTargetObject.lastYearTotalTarget === null
                  ? ""
                  : notInsertedTargetObject.lastYearTotalTarget,
              lastYearPercentage:
                notInsertedTargetObject.lastYearPercentage === null
                  ? ""
                  : notInsertedTargetObject.lastYearPercentage,
              totalTarget:
                notInsertedTargetObject.totalTarget === null
                  ? ""
                  : notInsertedTargetObject.totalTarget,
              totalOldTarget:
                notInsertedTargetObject.totalTarget === null
                  ? ""
                  : notInsertedTargetObject.totalTarget,
            };
            return notInsertedTargetData;
          });
          dispatch(notInsertedTargetSuccess({ row }));

          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getNotInsertedTargetByStakeHolderTypeIdAndYearIdPost =
  ({ jsonDataByPost }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url:
          URL +
          "/with-not-inserted-by-stock-stake-holder-type-and-year-and-areas",
        postBody: jsonDataByPost,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((notInsertedTargetObject, index) => {
            let notInsertedTargetData = {
              index: index + 1,
              primaryId:
                notInsertedTargetObject.primaryId === null
                  ? ""
                  : notInsertedTargetObject.primaryId,
              regionTargetPercentageId:
                notInsertedTargetObject.regionTargetPercentageId === null
                  ? ""
                  : notInsertedTargetObject.regionTargetPercentageId,
              id:
                notInsertedTargetObject.id === null
                  ? ""
                  : notInsertedTargetObject.id,
              salesTeamId:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.id
                  ? notInsertedTargetObject.salesTeam.id
                  : "",

              name:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.name
                  ? notInsertedTargetObject.salesTeam.name
                  : "",

              whatsAppNo:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.whatsAppNo
                  ? notInsertedTargetObject.salesTeam.whatsAppNo
                  : "",
              contactNo:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.contactNo
                  ? notInsertedTargetObject.salesTeam.contactNo
                  : "",
              address:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.address
                  ? notInsertedTargetObject.salesTeam.address
                  : "",

              employeeCode:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.employeeCode
                  ? notInsertedTargetObject.salesTeam.employeeCode
                  : "",

              emailId:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.emailId
                  ? notInsertedTargetObject.salesTeam.emailId
                  : "",

              region:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.region
                  ? notInsertedTargetObject.salesTeam.region.name
                  : "",

              zoneIds:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.zoneIds
                  ? notInsertedTargetObject.salesTeam.zoneIds
                  : "",

              areaIds:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.areaIds
                  ? notInsertedTargetObject.salesTeam.areaIds
                  : "",

              accessRoleId:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.accessRoleId
                  ? notInsertedTargetObject.salesTeam.accessRoleId
                  : "",

              isActive:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.isActive
                  ? notInsertedTargetObject.salesTeam.isActive
                  : "",

              insertDateTime:
                notInsertedTargetObject.salesTeam !== null &&
                notInsertedTargetObject.salesTeam.insertDateTime
                  ? notInsertedTargetObject.salesTeam.insertDateTime
                  : "",

              yearName:
                notInsertedTargetObject.yearMaster !== null &&
                notInsertedTargetObject.yearMaster.name
                  ? notInsertedTargetObject.yearMaster.name
                  : "",
              year:
                notInsertedTargetObject.yearMaster !== null &&
                notInsertedTargetObject.yearMaster.id
                  ? notInsertedTargetObject.yearMaster.id
                  : "",
              isFreez:
                notInsertedTargetObject.isFreez === null
                  ? ""
                  : notInsertedTargetObject.isFreez,

              jan:
                notInsertedTargetObject.jan === null
                  ? ""
                  : notInsertedTargetObject.jan,
              feb:
                notInsertedTargetObject.feb === null
                  ? ""
                  : notInsertedTargetObject.feb,
              march:
                notInsertedTargetObject.march === null
                  ? ""
                  : notInsertedTargetObject.march,
              april:
                notInsertedTargetObject.april === null
                  ? ""
                  : notInsertedTargetObject.april,
              may:
                notInsertedTargetObject.may === null
                  ? ""
                  : notInsertedTargetObject.may,
              jun:
                notInsertedTargetObject.jun === null
                  ? ""
                  : notInsertedTargetObject.jun,
              july:
                notInsertedTargetObject.july === null
                  ? ""
                  : notInsertedTargetObject.july,
              aug:
                notInsertedTargetObject.aug === null
                  ? ""
                  : notInsertedTargetObject.aug,
              sep:
                notInsertedTargetObject.sep === null
                  ? ""
                  : notInsertedTargetObject.sep,
              oct:
                notInsertedTargetObject.oct === null
                  ? ""
                  : notInsertedTargetObject.oct,
              nov:
                notInsertedTargetObject.nov === null
                  ? ""
                  : notInsertedTargetObject.nov,
              dec:
                notInsertedTargetObject.dec === null
                  ? ""
                  : notInsertedTargetObject.dec,

              janPer:
                notInsertedTargetObject.janPer === null
                  ? ""
                  : notInsertedTargetObject.janPer,
              febPer:
                notInsertedTargetObject.febPer === null
                  ? ""
                  : notInsertedTargetObject.febPer,
              marchPer:
                notInsertedTargetObject.marchPer === null
                  ? ""
                  : notInsertedTargetObject.marchPer,
              aprilPer:
                notInsertedTargetObject.aprilPer === null
                  ? ""
                  : notInsertedTargetObject.aprilPer,
              mayPer:
                notInsertedTargetObject.mayPer === null
                  ? ""
                  : notInsertedTargetObject.mayPer,
              junPer:
                notInsertedTargetObject.junPer === null
                  ? ""
                  : notInsertedTargetObject.junPer,
              julyPer:
                notInsertedTargetObject.julyPer === null
                  ? ""
                  : notInsertedTargetObject.julyPer,
              augPer:
                notInsertedTargetObject.augPer === null
                  ? ""
                  : notInsertedTargetObject.augPer,
              sepPer:
                notInsertedTargetObject.sepPer === null
                  ? ""
                  : notInsertedTargetObject.sepPer,
              octPer:
                notInsertedTargetObject.octPer === null
                  ? ""
                  : notInsertedTargetObject.octPer,
              novPer:
                notInsertedTargetObject.novPer === null
                  ? ""
                  : notInsertedTargetObject.novPer,
              decPer:
                notInsertedTargetObject.decPer === null
                  ? ""
                  : notInsertedTargetObject.decPer,

              regionName:
                notInsertedTargetObject.regionName === null
                  ? ""
                  : notInsertedTargetObject.regionName,
              stateName:
                notInsertedTargetObject.stateNames !== null
                  ? notInsertedTargetObject.stateNames
                  : "",

              zoneNames:
                notInsertedTargetObject.zoneNames === null
                  ? ""
                  : notInsertedTargetObject.zoneNames,
              areaName:
                notInsertedTargetObject.areaNames === null
                  ? ""
                  : notInsertedTargetObject.areaNames,
              areaCount:
                notInsertedTargetObject.areaCount === null
                  ? ""
                  : notInsertedTargetObject.areaCount,
              distributorCount:
                notInsertedTargetObject.distributorCount === null
                  ? ""
                  : notInsertedTargetObject.distributorCount,
              outletCount:
                notInsertedTargetObject.outletCount === null
                  ? ""
                  : notInsertedTargetObject.outletCount,

              salesTeamCount:
                notInsertedTargetObject.salesTeamCount === null
                  ? ""
                  : notInsertedTargetObject.salesTeamCount,
              lastYearTotalAcheived:
                notInsertedTargetObject.lastYearTotalAcheived === null
                  ? ""
                  : notInsertedTargetObject.lastYearTotalAcheived,
              lastYearTotalTarget:
                notInsertedTargetObject.lastYearTotalTarget === null
                  ? ""
                  : notInsertedTargetObject.lastYearTotalTarget,
              lastYearPercentage:
                notInsertedTargetObject.lastYearPercentage === null
                  ? ""
                  : notInsertedTargetObject.lastYearPercentage,
              totalTarget:
                notInsertedTargetObject.totalTarget === null
                  ? ""
                  : notInsertedTargetObject.totalTarget,
              totalOldTarget:
                notInsertedTargetObject.totalTarget === null
                  ? ""
                  : notInsertedTargetObject.totalTarget,
            };
            return notInsertedTargetData;
          });
          dispatch(notInsertedTargetSuccess({ row }));

          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getNotInsertedTargetByStakeHolderTypeIdAndYearIdPostNew =
  ({ jsonDataByPost }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url:
          URL +
          "/get-with-not-inserted-by-stock-stake-holder-type-and-year-and-areas",
        postBody: jsonDataByPost,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((notInsertedTargetObject, index) => {
            let notInsertedTargetData = {
              index: index + 1,
              primaryId:
                notInsertedTargetObject.primaryId === null
                  ? ""
                  : notInsertedTargetObject.primaryId,
              regionTargetPercentageId:
                notInsertedTargetObject.regionTargetPercentageId === null
                  ? ""
                  : notInsertedTargetObject.regionTargetPercentageId,
              id:
                notInsertedTargetObject.id === null
                  ? ""
                  : notInsertedTargetObject.id,
              salesTeamId:
                notInsertedTargetObject.salesTeamId !== null
                  ? notInsertedTargetObject.salesTeamId
                  : "",
              name:
                notInsertedTargetObject.salesTeamName !== null
                  ? notInsertedTargetObject.salesTeamName
                  : "",
              region:
                notInsertedTargetObject.regionName !== null
                  ? notInsertedTargetObject.regionName
                  : "",
              year:
                notInsertedTargetObject.yearId !== null
                  ? notInsertedTargetObject.yearId
                  : "",
              isFreez:
                notInsertedTargetObject.isFreez === null
                  ? ""
                  : notInsertedTargetObject.isFreez,
              jan:
                notInsertedTargetObject.jan === null
                  ? ""
                  : notInsertedTargetObject.jan,
              feb:
                notInsertedTargetObject.feb === null
                  ? ""
                  : notInsertedTargetObject.feb,
              march:
                notInsertedTargetObject.march === null
                  ? ""
                  : notInsertedTargetObject.march,
              april:
                notInsertedTargetObject.april === null
                  ? ""
                  : notInsertedTargetObject.april,
              may:
                notInsertedTargetObject.may === null
                  ? ""
                  : notInsertedTargetObject.may,
              jun:
                notInsertedTargetObject.jun === null
                  ? ""
                  : notInsertedTargetObject.jun,
              july:
                notInsertedTargetObject.july === null
                  ? ""
                  : notInsertedTargetObject.july,
              aug:
                notInsertedTargetObject.aug === null
                  ? ""
                  : notInsertedTargetObject.aug,
              sep:
                notInsertedTargetObject.sep === null
                  ? ""
                  : notInsertedTargetObject.sep,
              oct:
                notInsertedTargetObject.oct === null
                  ? ""
                  : notInsertedTargetObject.oct,
              nov:
                notInsertedTargetObject.nov === null
                  ? ""
                  : notInsertedTargetObject.nov,
              dec:
                notInsertedTargetObject.dec === null
                  ? ""
                  : notInsertedTargetObject.dec,
              janPer:
                notInsertedTargetObject.janPer === null
                  ? ""
                  : notInsertedTargetObject.janPer,
              febPer:
                notInsertedTargetObject.febPer === null
                  ? ""
                  : notInsertedTargetObject.febPer,
              marchPer:
                notInsertedTargetObject.marchPer === null
                  ? ""
                  : notInsertedTargetObject.marchPer,
              aprilPer:
                notInsertedTargetObject.aprilPer === null
                  ? ""
                  : notInsertedTargetObject.aprilPer,
              mayPer:
                notInsertedTargetObject.mayPer === null
                  ? ""
                  : notInsertedTargetObject.mayPer,
              junPer:
                notInsertedTargetObject.junPer === null
                  ? ""
                  : notInsertedTargetObject.junPer,
              julyPer:
                notInsertedTargetObject.julyPer === null
                  ? ""
                  : notInsertedTargetObject.julyPer,
              augPer:
                notInsertedTargetObject.augPer === null
                  ? ""
                  : notInsertedTargetObject.augPer,
              sepPer:
                notInsertedTargetObject.sepPer === null
                  ? ""
                  : notInsertedTargetObject.sepPer,
              octPer:
                notInsertedTargetObject.octPer === null
                  ? ""
                  : notInsertedTargetObject.octPer,
              novPer:
                notInsertedTargetObject.novPer === null
                  ? ""
                  : notInsertedTargetObject.novPer,
              decPer:
                notInsertedTargetObject.decPer === null
                  ? ""
                  : notInsertedTargetObject.decPer,
              regionName:
                notInsertedTargetObject.regionName === null
                  ? ""
                  : notInsertedTargetObject.regionName,
              stateName:
                notInsertedTargetObject.stateNames !== null
                  ? notInsertedTargetObject.stateNames
                  : "",
              zoneNames:
                notInsertedTargetObject.zoneNames === null
                  ? ""
                  : notInsertedTargetObject.zoneNames,
              areaName:
                notInsertedTargetObject.areaNames === null
                  ? ""
                  : notInsertedTargetObject.areaNames,
              areaCount:
                notInsertedTargetObject.areaCount === null
                  ? ""
                  : notInsertedTargetObject.areaCount,
              distributorCount:
                notInsertedTargetObject.distributorCount === null
                  ? ""
                  : notInsertedTargetObject.distributorCount,
              outletCount:
                notInsertedTargetObject.outletCount === null
                  ? ""
                  : notInsertedTargetObject.outletCount,
              salesTeamCount:
                notInsertedTargetObject.salesTeamCount === null
                  ? ""
                  : notInsertedTargetObject.salesTeamCount,
              lastYearTotalTarget:
                notInsertedTargetObject.lastYearTotalTarget === null
                  ? ""
                  : notInsertedTargetObject.lastYearTotalTarget,
              lastYearTotalAcheived:
                notInsertedTargetObject.lastYearTotalAcheived === null
                  ? ""
                  : notInsertedTargetObject.lastYearTotalAcheived,
              lastYearPercentage:
                notInsertedTargetObject.lastYearPercentage === null
                  ? ""
                  : notInsertedTargetObject.lastYearPercentage,
              totalTarget:
                notInsertedTargetObject.totalTarget === null
                  ? ""
                  : notInsertedTargetObject.totalTarget,
              totalOldTarget:
                notInsertedTargetObject.totalTarget === null
                  ? ""
                  : notInsertedTargetObject.totalTarget,
            };
            return notInsertedTargetData;
          });
          dispatch(notInsertedTargetSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const setNotInsertedTarget =
  ({ row }) =>
  async (dispatch) => {
    dispatch(notInsertedTargetSuccess({ row }));
  };
