import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  setting: [],
};
let URL = endpoints.setting;
const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    settingSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        setting: row,
      };
    },
  },
});

export const { settingSuccess } = settingSlice.actions;

export default settingSlice.reducer;

export const getSetting = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        localStorage.setItem("setting", JSON.stringify(data.data));
        dispatch(settingSuccess({ row: data.data }));
        return { response: data.data, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
