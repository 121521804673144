
// import React, { Component } from "react";

// import GoogleMapReact from "google-map-react";

// import styled from "styled-components";
// import "../Master/Outlet/maincss.css";
// import { connect } from "react-redux";
// import AutoComplete from "../Master/Outlet/Autocomplete";
// import Marker from "../Master/Outlet/Marker";
// import { ButtonCompo } from "../../components/Comman/Button";
// import { withRouter } from "../../components/withRouter";
// import { compose } from "@reduxjs/toolkit";
// import { Polyline } from "react-google-maps";

// const Wrapper = styled.main`
//   width: 100%;
//   height: 100%;
// `;

// class MyGoogleMap extends Component {
//   state = {
//     mapApiLoaded: false,
//     mapInstance: null,
//     mapApi: null,
//     geoCoder: null,
//     places: [],
//     center: [],
//     zoom: 9,
//     address: "",
//     draggable: true,
//     lat: null,
//     lng: null,
//     linePoint: [
//       {
//         latitude: 20.0011423,
//         longitude: 73.78327,
//         latitudeDelta: 0.0922,
//         longitudeDelta: 0.0421,
//       },
//       {
//         latitude: 19.9232789,
//         longitude: 73.7172605,
//         latitudeDelta: 0.0922,
//         longitudeDelta: 0.0421,
//       },
//       {
//         latitude: 19.7720211,
//         longitude: 73.6631865,
//         latitudeDelta: 0.0922,
//         longitudeDelta: 0.0421,
//       },
//       {
//         latitude: 19.6963259,
//         longitude: 73.5611065,
//         latitudeDelta: 0.0922,
//         longitudeDelta: 0.0421,
//       },
//     ],
//   };

//   componentWillMount() {
 
//       this.setCurrentLocation();
    
//   }

//   onMarkerInteraction = (childKey, childProps, mouse) => {
//     this.setState({
//       draggable: false,
//       lat: mouse.lat,
//       lng: mouse.lng,
//     });
//   };
//   onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
//     this.setState({ draggable: true });
//     this._generateAddress();
//   };

//   _onChange = ({ center, zoom }) => {
//     this.setState({
//       center: center,
//       zoom: zoom,
//     });
//   };

//   _onClick = (value) => {
//     this.setState({
//       lat: value.lat,
//       lng: value.lng,
//     });
//     this._generateAddress();
//   };

//   apiHasLoaded = (map, maps) => {
//     this.setState({
//       mapApiLoaded: true,
//       mapInstance: map,
//       mapApi: maps,
//     });

//     this._generateAddress();
//   };

//   addPlace = (place) => {
//     this.setState({
//       places: [place],
//       lat: place.geometry.location.lat(),
//       lng: place.geometry.location.lng(),
//     });
//     this._generateAddress();
//   };

//   _generateAddress() {
//     const { mapApi } = this.state;

//     const geocoder = new mapApi.Geocoder();

//     geocoder.geocode(
//       { location: { lat: this.state.lat, lng: this.state.lng } },
//       (results, status) => {
//         console.log(results);
//         console.log(status);
//         if (status === "OK") {
//           if (results[0]) {
//             this.zoom = 12;
//             this.setState({ address: results[0].formatted_address });
//           } else {
//             window.alert("No results found");
//           }
//         } else {
//           window.alert("Geocoder failed due to: " + status);
//         }
//       }
//     );
//   }

//   // Get Current Location Coordinates
//   setCurrentLocation() {
//     if ("geolocation" in navigator) {
//       navigator.geolocation.getCurrentPosition((position) => {
//         this.setState({
//           center: [position.coords.latitude, position.coords.longitude],
//           lat: position.coords.latitude,
//           lng: position.coords.longitude,
//         });
//       });
//     }
//   }
//   getLocationData = () => {
//     this.props.getLocation(this.state.lat, this.state.lng);
//   };
//   render() {
//     const { linePoint, mapApiLoaded, mapInstance, mapApi } = this.state;

//     return (
//       <div className="main-wrapper">
//         <Wrapper>
//           {mapApiLoaded && (
//             <div>
//               <AutoComplete
//                 map={mapInstance}
//                 mapApi={mapApi}
//                 addplace={this.addPlace}
//               />
//             </div>
//           )}
//           <GoogleMapReact
//             center={this.state.center}
//             zoom={this.state.zoom}
//             draggable={this.state.draggable}
//             onChange={this._onChange}
//             onChildMouseDown={this.onMarkerInteraction}
//             onChildMouseUp={this.onMarkerInteractionMouseUp}
//             onChildMouseMove={this.onMarkerInteraction}
//             onChildClick={() => console.log("child click")}
//             onClick={this._onClick}
//             bootstrapURLKeys={{
//               key: "AIzaSyBahlnISPYhetj3q50ADqVE6SECypRGe4A",
//               libraries: ["places", "geometry"],
//             }}
//             yesIWantToUseGoogleMapApiInternals
//             onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
//           >
//             <Marker
//               text={this.state.address}
//               lat={this.state.lat}
//               lng={this.state.lng}
//             />
//              <Polyline
//               path={linePoint}
//               strokeColor="#0000FF"
//               strokeOpacity={0.8}
//               strokeWeight={2} />
//           </GoogleMapReact>

//           <div className="info-wrapper">
//             <div className="map-details">
//               Latitude: <span>{this.state.lat}</span>, Longitude:{" "}
//               <span>{this.state.lng}</span>
//             </div>
//             <div className="map-details">
//               Zoom: <span>{this.state.zoom}</span>
//             </div>
//             <div className="map-details">
//               Address: <span>{this.state.address}</span>
//             </div>
//           </div>

//           <ButtonCompo
//             size="medium"
//             type="button"
//             variant="contained"
//             name="Get Location"
//             fullWidth={true}
//             onClick={this.getLocationData}
//           />
//         </Wrapper>
//       </div>
//     );
//   }
// }
// const mapStateToProps = (state) => ({});
// const mapDispatchToProps = {};
// export default compose(
//   withRouter,
//   connect(mapStateToProps, mapDispatchToProps)
// )(MyGoogleMap);


import React, { useState, useEffect } from 'react';
import GMap from './GMap';

const App = () => {
  const [loadMap, setLoadMap] = useState(false);

  // useEffect(() => {
  //   loadGoogleMapScript(() => {
  //     setLoadMap(true)
  //   });
  // }, []);

  return (
    <GMap  />
    // <div className="App">
    //   {!loadMap ? <div>Loading...</div> : <GMap />}
  
    // </div>
  );
}

export default App;
