import React from "react";
import { Grid, Paper } from "@mui/material";
import { connect } from "react-redux";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import {
  serverMsg,
  savemsg,
  saveFailedMsg,noInternetMsg,saveWarningMsg,dateExistMsg
} from "../../config/messageconstant";
import { publishRateConfigurationJson } from "../../DynamicFormsJson/Transaction/PublishRateConfiguration";
import { getCategory } from "../../Slice/category.slice";
import { getProductRateConfigurationByStakeHolderTypeIdsAndCategoryIds } from "../../Slice/productRateConfiguration.slice";
import { getPublishRateConfiguration } from "../../Slice/publishRateConfiguration.slice";
import { getStockStakeHolderType } from "../../Slice/stockStakeHolderType.slice";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import endpoint from "../../config/endpoints";
import { withRouter } from "../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import swal from "sweetalert";
class PublishRateConfigurationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        stakeHolderTypeDetail:
          this.props.stakeHolderTypeList?.stockStakeHolderType,
        categoryDetail: this.props.categoryList?.category,
        rateConfigurationDetail:
          this.props.productRateConfiguration?.productRateConfigurationNew,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.stakeHolderTypeList !== nextProps.stakeHolderTypeList) {
      if (
        this.props.stakeHolderTypeList?.stockStakeHolderType !==
        nextProps.stakeHolderTypeList?.stockStakeHolderType
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeHolderTypeDetail:
              nextProps.stakeHolderTypeList?.stockStakeHolderType,
          },
        });
      }
    }
    if (this.props.categoryList !== nextProps.categoryList) {
      if (
        this.props.categoryList?.category !== nextProps.categoryList?.category
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            categoryDetail: nextProps.categoryList?.category,
          },
        });
      }
    }
    if (
      this.props.productRateConfiguration !== nextProps.productRateConfiguration
    ) {
      if (
        this.props.productRateConfiguration?.productRateConfigurationNew !==
        nextProps.productRateConfiguration?.productRateConfigurationNew
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            rateConfigurationDetail:
              nextProps.productRateConfiguration?.productRateConfigurationNew,
          },
        });
      }
    }
  }
  async componentDidMount() {
    const {
      getStockStakeHolderType,
      getCategory,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
    showLoador({ loador: true });
   await getStockStakeHolderType({ userTypeIds: 2 }).then(({ success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      }
    });

    showLoador({ loador: true });
   await getCategory().then(({ success }) => {
      showLoador({ loador: false });
      if (!success) {
        showNotification({ msg: serverMsg, severity: "error" });
      }
    });
  }
  else {
    this.props.showNotification({ msg: noInternetMsg, severity: "error" });
  }
  }
  getListById = async (data) => {
    const { getProductRateConfigurationByStakeHolderTypeIdsAndCategoryIds,showLoador,showNotification } =
      this.props;
    if (
      data.stockStakeHolderType &&
      data.stockStakeHolderType.id &&
      data.category
    ) {
      if (navigator.onLine) {
      showLoador({ loador: true });
     await getProductRateConfigurationByStakeHolderTypeIdsAndCategoryIds({
        stockStakeHolderTypeIds: data.stockStakeHolderType.id,
        categoryIds: data.category,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    }
    else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  
  };

  onSearch = (searchData) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
    showLoador({ loador: true });
    apiGet({
      url:
        endpoint.checkPublicationDate +
        "/by-product-rate-configurations-and-publish-date?productRateConfigurationIds=" +
        searchData.productRateConfiguration +
        "&publishDate=" +
        searchData.date,
    }).then(({ data, success }) => {
      showLoador({ loador: false });
      if (success) {
        if (data.data.length == 0) {
          const rateconfigurationList =
            searchData.productRateConfiguration.split(",");
          const dataToSave = rateconfigurationList.map(
            (rateconfigurationListData) => {
              const rateconfigurationListObj = {
                productRateConfiguration: {
                  id: rateconfigurationListData,
                },
                publishDate: searchData.date,
              };
              return rateconfigurationListObj;
            }
          );
          swal({
            title: "Are you sure?",
            text: saveWarningMsg,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              this.onSave(dataToSave);
            }
          });
          
        } else {
          showNotification({
            msg: dateExistMsg,
            severity: "error",
          });
        }
      }
    });
  }
  else {
    this.props.showNotification({ msg: noInternetMsg, severity: "error" });
  }
  };
  onSave = (dataToSave) =>{
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
    showLoador({ loador: true });
          apiPost({
            url: endpoint.checkPublicationDate + "/save-list",
            postBody: dataToSave,
          }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success) {
              this.props.navigate(-1);
              showNotification({ msg: savemsg });
            } else {
              showNotification({ msg: saveFailedMsg, severity: "error" });
            }
          });
        }
        else {
          this.props.showNotification({ msg: noInternetMsg, severity: "error" });
        }
  }
  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
       <Paper
          sx={{
            padding: 2,
            borderRadius: 2,

          }}
          elevation={0}
        >
        <DynamicFormWithoutSave
          showBackToList={true}
          getListById={this.getListById}
          dynamicMasterData={dynamicMasterData}
          showTitle={publishRateConfigurationJson.showTitle}
          screenTitle={publishRateConfigurationJson.screenTitle}
          fieldMeta={publishRateConfigurationJson.fieldMeta}
          showCancel={publishRateConfigurationJson.showCancel}
          apiBaseURL={publishRateConfigurationJson.apiBaseURL}
          showSaveNextBtn={publishRateConfigurationJson.showSaveNextBtn}
          onSave={this.onSearch}
          showSaveBtnMain={false}
          showSaveBtn={true}
        />
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stakeHolderTypeList: state.stockStakeHolderType,
  categoryList: state.category,
  productRateConfiguration: state.productRateConfiguration,
});
const mapDispatchToProps = {
  getStockStakeHolderType,
  getCategory,
  getProductRateConfigurationByStakeHolderTypeIdsAndCategoryIds,
  getPublishRateConfiguration,
  showLoador,
  showNotification,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PublishRateConfigurationForm);
