import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import EditIcon from "@mui/icons-material/Edit";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { compose } from "@reduxjs/toolkit";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { saveReport } from '../../Slice/matrixReport.slice';
import { MyComponentWithIconProps } from "../../components/Comman/IconButton";
import { withRouter } from "../../components/withRouter";
import * as msgConstant from "../../config/messageconstant";
import * as myConstClass from "../../config/messageconstant";
import { noInternetMsg } from "../../config/messageconstant";
import { showLoador, showNotification } from "../Landing/Landing.slice";
let rowList = [
  {
    graphName: "Pie chart",
    xAxis: {
      label: "Category",
      name: "Category"
    },
    yAxis: [
      {
        label: "Total",
        name: "total"
      },
      {
        label: "Amount",
        name: "Amount"
      }
    ]
  },
  {
    graphName: "Bar chart",
    xAxis: {
      label: "Product",
      name: "Product"
    },
    yAxis: [
      {
        label: "Amount",
        name: "Amount"
      }
    ]
  },
];
class UserAccessForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      stackHolderType: "",
      stackHolderTypeName: "",
      accessRoleName: "",
      shortName: "",
      formError: {},
      error: false,
      openDialog: true,
    };
  }

  async componentDidMount() {
    const { getModuleAndSubModule, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      //   showLoador({ loador: true });
      //   const moduleResp = await getModuleAndSubModule().then(
      //     ({ response, success }) => {
      //       showLoador({ loador: false });
      //       if (!success) {
      //         showNotification({ msg: serverMsg, severity: "error" });
      //       }
      //       return { response, success };
      //     }
      //   );
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  checkValidation = () => {
    const { accessRoleName, stackHolderType, shortName } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (accessRoleName === "" || accessRoleName === null) {
      formIsValid = false;
      formErrors["nameError"] = myConstClass.accessRoleMsg;
    }

    if (shortName === "" || shortName === null) {
      formIsValid = false;
      formErrors["shortNameError"] = myConstClass.shortNameMsg;
    }
    if (stackHolderType === "" || stackHolderType === null) {
      formIsValid = false;
      formErrors["stackHolderTypeError"] = myConstClass.stackHolderTypeMsg;
    }
    this.setState({
      formError: formErrors,
    });
    return formIsValid;
  };

  goBack = () => {
    this.props.navigate(-1);
  };
  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };
   onClickDelete = (e, graphId) => {
    e.stopPropagation();
    this.rowDelete(graphId);
  };
  onClickEdit = (e, rowData,index) => {
    e.stopPropagation();
    this.props.rowEdit({...rowData,index});
  };
  
  rowDelete = (graphId) => {
    const { showNotification, showLoador } = this.props;
    swal({
        title: "Are you sure?",
        text: msgConstant.deleteWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
    }).then(async (willDelete) => {
        if (willDelete) {
            if (navigator.onLine) {
                let filterData = this.props.graphColumns.filter((row) => row.graphId != graphId)
                 this.onSave({
                    ...this.props.report.reportData,
                    graphColumns: JSON.stringify(filterData)
                }
                )
            } else {
                showNotification({ msg: msgConstant.noInternetMsg, severity: "error" });
            }
        }
    });
};
onSave = (dataToSave) => {

  if (navigator.onLine) {
      const {
          saveReport,
          showLoador,
          showNotification,
      } = this.props;
      showLoador({ loador: true });
      saveReport({ dataToSave }).then(({ success }) => {
          showLoador({ loador: false });
          if (success) {
              showNotification({
                  msg: dataToSave.id ? msgConstant.deletemsg : msgConstant.savemsg,
              });

          } else {
              showNotification({
                  msg: msgConstant.saveFailedMsg,
                  severity: "error",
              });
          }
      });
  } else {
      showNotification({
          msg: msgConstant.noInternetMsg,
          severity: "error",
      });
  }
};
  render() {
    const { accessRole, graphColumns } = this.props;
    const {
      accessRoleName,
      shortName,
      formError,
      stackHolderType,
      stackHolderTypeName,
      error,
      id,
      openDialog,
    } = this.state;
    console.log(graphColumns)
    return (
      <>
        <Box sx={{ width: "100%" }}>
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell width="10%" >Sr. No.</TableCell>
                <TableCell>Graph Name</TableCell>

                <TableCell width="10%" align="center" >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {graphColumns.map((row, index) => (
                <>
                  <TableRow key={row.id}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell scope="row">{row.graphName}</TableCell>

                    <TableCell scope="row">
                    <MyComponentWithIconProps
                        statusImage={EditIcon}
                        color="primary"
                        fontSize="medium"
                        title="edit"
                       onClick={(e) => this.onClickEdit(e, row,index)}
                      />
                      
                      &nbsp;&nbsp;
                      <MyComponentWithIconProps
                        statusImage={DeleteIcon}
                        color="error"
                        fontSize="medium"
                        title="Delete"
                       onClick={(e) => this.onClickDelete(e, row.graphId)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableCell
                    style={{ paddingBottom: 5, paddingTop: 0 }}
                    colSpan={10}
                  >
                    <Box sx={{ marginLeft: 2, marginRight: 2 }}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <>
                            <TableHead>
                              <TableRow>
                                <TableCell align="center" >
                                  X-axis(Label)
                                </TableCell>
                                <TableCell align="center" >
                                  X-axis(Field Name)
                                </TableCell>
                                <TableCell>YAxis(Label)</TableCell>
                                <TableCell align="center" >
                                  YAxis(Field Name)
                                </TableCell>

                              </TableRow>
                            </TableHead>
                            <TableBody>

                              <Fragment>
                                <TableRow>
                                  <TableCell rowSpan={row.yAxis.length + 1}>
                                    {row.xAxis.label}
                                  </TableCell>
                                  <TableCell rowSpan={row.yAxis.length + 1}>
                                    {row.xAxis.name}
                                  </TableCell>
                                </TableRow>
                                {row.yAxis.map((detail) => (
                                  <TableRow>
                                    <TableCell> {detail.label}</TableCell>
                                    <TableCell> {detail.name}</TableCell>
                                  </TableRow>
                                ))}

                              </Fragment>
                            </TableBody>
                          </>
                        </Table>
                      </TableContainer>
                    </Box>
                  </TableCell>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Box >

      </>
    );
  }
}

const mapStateToProps = (state) => ({ report: state.matrixReport });
const mapDispatchToProps = { showLoador, showNotification,saveReport };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(UserAccessForm);