import { Grid, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { ManualOrderJson } from "../../DynamicFormsJson/Transaction/ManualOrder";
import { getAreaByUserType } from "../../Slice/area.slice";

import { getRateEditOrNot } from "../../Slice/rateConfigurationEditingForStockholder.slice";

import dayjs from "dayjs";
import { getActiveBeatByArea } from "../../Slice/beat.slice";
import { setCategoryAndItem } from "../../Slice/category.slice";
import { getActiveOutletByBeatId } from "../../Slice/outlet.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import ManualForm from "../../components/dynamicscreens/DynamicFormWithoutValidation";
import { titleColor } from "../../config/ColorObj";
import { ValidationCheck, mandatoryCheck } from "../../config/ValidationCheck";
import endpoint from "../../config/endpoints";
import {
  atleastOne,
  cdMsg,
  discountPercentageErrorMsg,
  noInternetMsg,
  saveFailedMsg,
  saveWarningMsg,
  savemsg,
  serverMsg
} from "../../config/messageconstant";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import ManualBillingWithOffer from "./ManualBillingWithOffer";
import ManualOrderTable from "./ManualOrderTable";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

class ManualOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        areaDetail: this.props.areaList.area,
        beatDetail: this.props.beatList.beat,
        outletDetail: this.props.outletList.outlets,
        salesExecutiveDetail: this.props.salesExecutive,
      },
      searchValue: "",
      formErrors: {},
      fieldDataForModule: {},
      fieldData: { flag: "0" },
      DataToSet: [],
      value: 0,
      total: 0,
      totalAmt: 25.5,
      open: false,
      companyFlagTwo: false,
      totalQty: 0,
      orderList: [],
      salesPersonId: "",
      flag: false,
      offerBillData: {},
      discountOnBillAmt: 0,
    };
  }

  setCatList = ({ row }) => {
    const { setCategoryAndItem } = this.props;
    const { flag } = this.state;
    let dataTotal = 0;
    let totalQty = 0;
    row.map((rowData) => {
      if (rowData.flag == 3) {
        rowData.productWithMarginAndMrps.map((productData) => {
          dataTotal = dataTotal + +productData.total;
          totalQty = totalQty + +productData.qty;
        });
      }
    });
    const { fieldData } = this.state;
    const discount = fieldData.DiscountPercentage
      ? fieldData.DiscountPercentage
      : 0;
    let discountAmt = (dataTotal * +discount) / 100;
    this.setState({
      totalAmt: dataTotal - +discountAmt,
      total: dataTotal,
      totalQty: totalQty,
    });
    if (flag) {
      let newList = row.map((rowData) => {
        let rowList = rowData.productWithMarginAndMrps.map((productData) => {
          if (
            productData.productStock > 0 &&
            productData.productStock < +productData.qty
          ) {
            let productNewData = {
              ...productData,
              stockError: 1,
            };
            return productNewData;
          } else if (
            productData.productStock > 0 &&
            productData.productStock < +productData.freeQty
          ) {
            let productNewData = {
              ...productData,
              stockError: 2,
            };
            return productNewData;
          } else if (
            productData.productStock > 0 &&
            productData.productStock < +productData.qty + +productData.freeQty
          ) {
            let productNewData = {
              ...productData,
              stockError: 3,
            };
            return productNewData;
          } else if (
            productData.productStock > 0 &&
            productData.productStock >= +productData.qty
          ) {
            let productNewData = {
              ...productData,
              stockError: 0,
            };
            return productNewData;
          } else {
            return productData;
          }
        });
        let rowNewList = {
          ...rowData,
          productWithMarginAndMrps: rowList,
        };
        return rowNewList;
      });
      setCategoryAndItem({ row: newList });
    } else {
      setCategoryAndItem({ row });
    }
  };
  async componentDidMount() {
    const {
      getAreaByUserType,
      getRateEditOrNot,
      showLoador,
      setCategoryAndItem,
      showNotification,
    } = this.props;
    if (navigator.onLine) {


      const showCompanyFlag = localStorage.getItem("showCompanyFlag");
      if (showCompanyFlag == 2) {
        this.setState({ companyFlagTwo: true });
      }



      const moduleObjData = localStorage.getItem("moduleObj");
      this.setState({
        fieldDataForModule:
          moduleObjData != null ? JSON.parse(moduleObjData) : {},
      });
      setCategoryAndItem({ row: [] });
      showLoador({ loador: true });
      await getAreaByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getRateEditOrNot().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      apiGet({
        url: endpoint.manageStockHeader + "/stock-by-current-date",
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          let stockList = data.data.productStocks.map((productStockObject) => {
            let stockObject = {
              productId: productStockObject.product.id,
              stock: productStockObject.secondaryClosingQty,
            };
            return stockObject;
          });
          localStorage.setItem("stockList", JSON.stringify(stockList));
        }
      });
      const stockCalAtBill = localStorage.getItem("stockCalAtBill");
      this.setState({
        flag: stockCalAtBill === "1" ? true : false,
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.area !== nextProps.areaList?.area) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.area,
          },
        });
      }
    }

    if (this.props.beatList !== nextProps.beatList) {
      if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            beatDetail: nextProps.beatList?.beat,
          },
        });
      }
    }

    if (this.props.outletList !== nextProps.outletList) {
      if (this.props.outletList?.outlets !== nextProps.outletList?.outlets) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            outletDetail: nextProps.outletList?.outlets,
          },
        });
      }
    }
    if (this.props.salesExecutive !== nextProps.salesExecutive) {
      if (
        this.props.salesExecutive?.salesExecutive !==
        nextProps.salesExecutive?.salesExecutive
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            salesExecutiveDetail: nextProps.salesExecutive?.salesExecutive,
          },
        });
      }
    }
  }
  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
      searchValue: "",
    });
  };


  addOrder = () => {
    if (this.checkValidation()) {
      const { category } = this.props;
      const { flag } = this.state;
      let rowList = [];
      let filterData = [];
      category.category.map((catData) => {
        if (catData.flag == 3) {
          if (flag) {
            filterData = catData.productWithMarginAndMrps.filter(
              (row) => row.qty != 0 && row.stockError == 0
            );
          } else {
            filterData = catData.productWithMarginAndMrps.filter(
              (row) => row.qty != 0
            );
          }
          rowList = [...rowList, ...filterData];
          return catData;
        }
      });
      if (rowList.length != 0) {
        if (flag) {
          this.setState({
            orderList: rowList.map((rowData, index) => {
              console.log(rowData);
              return {
                index: index + 1,
                productName:
                  rowData.products !== null && rowData.products.name
                    ? rowData.products.name
                    : "",
                outletName:
                  rowData.products !== null &&
                    rowData.products.outletUom !== null &&
                    rowData.products.outletUom.name
                    ? rowData.products.outletUom.name
                    : "",
                primaryQty: rowData.primaryQty !== null ? rowData.primaryQty : 0,
                secondaryQty: rowData.secondaryQty !== null ? rowData.secondaryQty : 0,
                qty: rowData.qty !== null ? rowData.qty : 0,
                rate: rowData.rate !== null ? rowData.rate : 0,

                ...rowData,
                uomConversionValue:
                  rowData.uomConversionValue !== ""
                    ? rowData.uomConversionValue
                    : 0,
                total: rowData.total.toFixed(2),
              };
            }),
          });
          this.getOrderDetails(rowList);
        } else {
          this.getOrderDetails(rowList);
        }
      } else {
        this.props.showNotification({ msg: atleastOne, severity: "error" });
      }
    }

  }


  getOrderDetails = (rowList) => {
    const { flag, fieldData, discountOnBillAmt } = this.state;
    const { category, showLoador, showNotification } = this.props;
    let stockList = [];
    const outletBillDetails = rowList.map((orderData) => {
      return {
        product: orderData.products,
        primaryQty: orderData.primaryQty ? +orderData.primaryQty : 0,
        secondaryQty: orderData.secondaryQty ? +orderData.secondaryQty : 0,
        qty: +orderData.qty,
        freeQty: +orderData.freeQty,
        itemDiscPer: +orderData.itemDiscPer,
        batchCode: "0",
        conversionValue: orderData.uomConversionValue,
        rate: orderData.rate,
      };
    });
    if (flag) {
      category.category.map((categoryObject) => {
        categoryObject.productWithMarginAndMrps.map((productObject) => {
          let productNewObject = {
            productId: productObject.products.id,
            stock:
              productObject.productStock -
              productObject.qty -
              productObject.freeQty,
          };
          stockList.push(productNewObject);
        });
      });
      localStorage.setItem("stockList", JSON.stringify(stockList));
    }

    const dataToSave = {
      outLet: {
        id: fieldData.outletIds,
      },
      billDate: dayjs().format("DD-MM-YYYY"),
      discountOnBillPer: discountOnBillAmt,
      outletBillDetails: outletBillDetails,
    };

    const dataToSaveWithStock = {
      outletBillHeader: dataToSave,
      productAndStocks: stockList,
    };

    console.log(dataToSaveWithStock);


    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        showLoador({ loador: true });
        apiPost({
          url: endpoint.manualOrder +
            "/save-outlet-order?salesPersonId=" +
            this.state.salesPersonId,
          postBody: dataToSaveWithStock,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            showNotification({ msg: savemsg });
            window.location.reload();
          } else {
            showNotification({ msg: saveFailedMsg, severity: "error" });
          }
        });
      }
    });
  };


  submitHandler = () => {
    if (this.checkValidationOnSubmit()) {
      const { category } = this.props;
      const { flag } = this.state;
      let rowList = [];
      let filterData = [];
      category.category.map((catData) => {
        if (catData.flag == 3) {
          if (flag) {
            filterData = catData.productWithMarginAndMrps.filter(
              (row) => row.qty != 0 && row.stockError == 0
            );
          } else {
            filterData = catData.productWithMarginAndMrps.filter(
              (row) => row.qty != 0
            );
          }
          rowList = [...rowList, ...filterData];
          return catData;
        }
      });
      if (rowList.length != 0) {
        if (flag) {
          this.setState({
            orderList: rowList.map((rowData, index) => {
              console.log(rowData);
              return {
                index: index + 1,
                productName:
                  rowData.products !== null && rowData.products.name
                    ? rowData.products.name
                    : "",
                outletName:
                  rowData.products !== null &&
                    rowData.products.outletUom !== null &&
                    rowData.products.outletUom.name
                    ? rowData.products.outletUom.name
                    : "",
                primaryQty: rowData.primaryQty !== null ? rowData.primaryQty : 0,
                secondaryQty: rowData.secondaryQty !== null ? rowData.secondaryQty : 0,
                qty: rowData.qty !== null ? rowData.qty : 0,
                rate: rowData.rate !== null ? rowData.rate : 0,

                ...rowData,
                uomConversionValue:
                  rowData.uomConversionValue !== ""
                    ? rowData.uomConversionValue
                    : 0,
                total: rowData.total.toFixed(2),
              };
            }),
          });
          this.getOrder(rowList);
        } else {
          this.getOrder(rowList);
        }
      } else {
        this.props.showNotification({ msg: atleastOne, severity: "error" });
      }
    }
  };
  onDataChange = (fieldName, newValue) => {
    if (fieldName == "DiscountPercentage") {
      let discountAmt = (this.state.total * +newValue) / 100;
      this.setState({
        totalAmt: +this.state.total - +discountAmt,
      });
    }
    const { fieldData } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldData };
    let listError = false;
    ManualOrderJson.fieldMeta.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        // dataToSearch[fieldName] = newValue;
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldData[key]) {
            dataToSearch[key] = fieldData[key];
          }
          return null;
        });

        this.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldData[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] = fieldData[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldData: dataToReset,
    });
  };
  getListById = async (data) => {
    const {
      getActiveBeatByArea,
      getActiveOutletByBeatId,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      this.setState({
        fieldData: data,
      });
      if (data.area) {
        showLoador({ loador: true });
        await getActiveBeatByArea({ areaId: data.area ? data.area.id : 0 }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.beat) {
        showLoador({ loador: true });
        let beatId = data.beat ? data.beat.id : 0;
        await apiGet({
          url:
            endpoint.salesExecutive +
            "/check-assigned-sales-team-by-beat?beatId=" +
            beatId,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success && data.data.salesTeam != null) {
            const rowData = data.data;
            this.setState({
              salesPersonId: rowData.salesTeam.id,
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Sales Person Name",
                  value:
                    rowData.salesTeam !== null &&
                      rowData.salesTeam.name !== null
                      ? rowData.salesTeam.name
                      : "",
                },
              ],
            });
            showLoador({ loador: true });
            getActiveOutletByBeatId({
              beatIds: beatId,
            }).then(({ success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              }
            });
          } else {
            this.setState({
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Sales Person Name",
                  value: " - ",
                },
              ],
            });
            getActiveOutletByBeatId({
              beatIds: 0,
            }).then(({ success }) => {
              showLoador({ loador: false });
              if (!success) {
                showNotification({ msg: serverMsg, severity: "error" });
              }
            });

            showNotification({
              msg: data.errorMessage,
              severity: "error",
            });
          }
        });
      }
      if (data.beat && data.outletIds) {
        let beatId = data.beat ? data.beat.id : 0;
        showLoador({ loador: true });
        await apiGet({
          url:
            endpoint.category +
            "/items-with-margin-and-mrp-by-beat-new?beatId=" +
            beatId,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success && data.data != null) {
            const rowData = data.data;
            if (rowData.errorMessage.error) {
              showNotification({
                msg: rowData.errorMessage.message,
                severity: "error",
              });
            } else {
              const row = rowData.categoryAndItems.map((rowObj) => {
                return {
                  ...rowObj,
                  total: 0,
                  qty: 0,
                  productWithMarginAndMrps: rowObj.productWithMarginAndMrps.map(
                    (productObject) => {
                      return {
                        ...productObject,
                        uomConversionValue:
                          productObject.products.uomConversionValue,
                        qty: "",
                        freeQty: 0,
                        itemDiscPer: 0,
                        productStock:
                          productObject.productStockForMobile
                            .secondaryClosingQty,
                        total: 0,
                        stockError: 0,
                      };
                    }
                  ),
                };
              });
              this.setCatList({ row });
            }
          } else {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  checkValidationOnSubmit = () => {
    const { fieldData, flag } = this.state;
    const { category } = this.props;
    const mandatoryCheckErrors = mandatoryCheck({
      fieldData,
      fieldMeta: ManualOrderJson.fieldMeta,
    });
    this.setState({
      formErrors: mandatoryCheckErrors.formErrors,
    });
    if (mandatoryCheckErrors.formValid) {
      const ValidationCheckErrors = ValidationCheck({
        fieldData,
        fieldMeta: ManualOrderJson.fieldMeta,
      });
      this.setState({
        formErrors: ValidationCheckErrors.formErrors,
      });
      return ValidationCheckErrors.formValid;
    } else {
      return false;
    }
  };
  getOrder = (rowList) => {
    const { flag, fieldData } = this.state;
    const { category } = this.props;
    let stockList = [];
    const outletBillDetails = rowList.map((orderData) => {
      return {
        product: orderData.products,
        primaryQty: orderData.primaryQty ? +orderData.primaryQty : 0,
        secondaryQty: orderData.secondaryQty ? +orderData.secondaryQty : 0,
        qty: +orderData.qty,
        freeQty: +orderData.freeQty,
        itemDiscPer: +orderData.itemDiscPer,
        batchCode: "0",
        conversionValue: orderData.uomConversionValue,
        rate: orderData.rate,
      };
    });
    if (flag) {
      category.category.map((categoryObject) => {
        categoryObject.productWithMarginAndMrps.map((productObject) => {
          let productNewObject = {
            productId: productObject.products.id,
            stock:
              productObject.productStock -
              productObject.qty -
              productObject.freeQty,
          };
          stockList.push(productNewObject);
        });
      });
      localStorage.setItem("stockList", JSON.stringify(stockList));
    }

    const dataToSave = {
      outLet: {
        id: fieldData.outletIds,
      },
      billDate: dayjs().format("DD-MM-YYYY"),
      discountOnBillPer: fieldData.DiscountPercentage
        ? fieldData.DiscountPercentage
        : 0,
      outletBillDetails: outletBillDetails,
    };


    console.log(dataToSave);

    const dataToSaveWithStock = {
      outletBillHeader: dataToSave,
      productAndStocks: stockList,
    };

    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onSave(dataToSaveWithStock);
      }
    });
  };

  checkValidation = () => {
    const { discountOnBillAmt } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (discountOnBillAmt === "") {
      formIsValid = false;
      formErrors["discountOnBillAmtError"] = cdMsg;
    } else if (discountOnBillAmt > 100) {
      formIsValid = false;
      formErrors["discountOnBillAmtError"] = discountPercentageErrorMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  };



  onSave = (dataToSaveWithStock) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      // this.toggle();
      showLoador({ loador: true });
      apiPost({
        url:
          endpoint.manualOrder +
          "/generate-with-applied-offer?salesPersonId=" +
          this.state.salesPersonId,
        postBody: dataToSaveWithStock,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          let newObjectToSave = {
            ...data.data.outletBillsAfterApplyingOffers,
            outletBillDetails:
              data.data.outletBillsAfterApplyingOffers.outletBillDetails.map(
                (orderObject) => {
                  let productWithoutBillObject =
                    data.data.outletBillHeader.outletBillDetails.filter(
                      (row) => row.id == orderObject.id
                    );
                  if (productWithoutBillObject.length !== 0) {
                    orderObject = {
                      ...orderObject,
                      taxableAmtWithoutOffer:
                        productWithoutBillObject[0].taxableAmt,
                      totalAmtWithoutOffer:
                        productWithoutBillObject[0].totalAmt,
                    };
                    return orderObject;
                  } else {
                    orderObject = {
                      ...orderObject,
                      taxableAmtWithoutOffer: 0.0,
                      totalAmtWithoutOffer: 0.0,
                    };
                    return orderObject;
                  }
                }
              ),
          };
          let newData = {
            ...data.data,
            outletBillsAfterApplyingOffers: newObjectToSave,
          };
          this.setState({ open: true, offerBillData: newData });
          // window.location.replace("/manual-order");
          showNotification({ msg: savemsg });
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  toggle = () => {
    this.setState({
      open: !this.state.open,
    });
    localStorage.removeItem("stockList");
  };
  getFilteredTableData = (tableData) => {
    const { searchValue } = this.state;

    return tableData.filter((currentRow) => {
      let isValid = false;
      let productName =
        currentRow.products != null ? currentRow.products.name : "-";
      let outletUom =
        currentRow.products.outletUom != null
          ? currentRow.products.outletUom.name
          : "-";
      let rate = currentRow.rate != null ? currentRow.rate : "-";
      let productStock =
        currentRow.productStock != null ? currentRow.productStock : "-";
      if (
        productName &&
        productName.toString().toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        outletUom &&
        outletUom.toString().toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        rate &&
        rate.toString().toLowerCase().includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      if (
        productStock &&
        productStock
          .toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      ) {
        isValid = true;
        return true;
      }
      return isValid;
    });
  };
  onSearch = (searchValue) => {
    this.setState({
      searchValue: searchValue,
    });
  };

  changeHandlerTextField = () => (event) => {
    const { name, value } = event.target;
    console.log(name)
    console.log(value)
    this.setState({
      discountOnBillAmt: value,
    });
  }



  render() {
    const {
      dynamicMasterData,
      DataToSet,
      value,
      total,
      totalAmt,
      open,
      orderList,
      formErrors,
      fieldData,
      offerBillData,
      flag,
      salesPersonId,
      totalQty,
      fieldDataForModule,
      companyFlagTwo,
      discountOnBillAmt,
    } = this.state;
    const { category, stockHolderAndProductConfiguration } = this.props;

    return (
      <>
        {!open && (
          <>
            <Paper
              sx={{
                p: 2,
                borderRadius: 2,
              }}
              elevation={0}
            >
              <ManualForm
                getListById={this.getListById}
                dynamicMasterData={dynamicMasterData}
                showTitle={ManualOrderJson.showTitle}
                screenTitle={ManualOrderJson.screenTitle}
                fieldMeta={ManualOrderJson.fieldMeta}
                apiBaseURL={ManualOrderJson.apiBaseURL}
                formErrors={formErrors}
                showSaveBtn={ManualOrderJson.showSaveBtn}
                showSaveBtnMain={false}
                fieldData={fieldData}
                onDataChange={this.onDataChange}
                showBackToList={ManualOrderJson.showBackToList}
              />
              <DynamicDetails DataToSet={DataToSet} />
              {category.category.length != 0 && (
                <>
                  <Tabs
                    value={value}
                    onChange={this.handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                  >
                    {category.category.map((catData) => {
                      return (
                        <Tab
                          label={
                            catData.category.name +
                            " ( " +
                            catData.productWithMarginAndMrps.length +
                            " )"
                          }
                        />
                      );
                    })}
                  </Tabs>
                  {category.category.map((catData, index) => {
                    return (
                      <TabPanel value={value} index={index}>
                        <LandingScreenHeader
                          screenTitle={""}
                          showBackButton={false}
                          showAddButton={false}
                          showTable={true}
                          showPdfDownload={false}
                          showExcelDownload={false}
                          onSearch={this.onSearch}
                        />
                        <br />
                        <ManualOrderTable
                          stockHolderAndProductConfiguration={
                            stockHolderAndProductConfiguration
                          }
                          tableHead={
                            companyFlagTwo ? (

                              flag && fieldData.flag == 1
                                ? [
                                  {
                                    title: "Sr.No.",
                                    name: "index",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "center",
                                  },
                                  {
                                    title: "Product Name",
                                    name: "name",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "left",
                                  },
                                  {
                                    title: "UOM",
                                    name: "name",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "center",
                                  },
                                  {
                                    title: "Rate",
                                    name: "productStock",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "right",
                                  },
                                  {
                                    title: "Product Stock",
                                    name: "name",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "left",
                                  },
                                  {
                                    title: "Primary Quantity",
                                    name: "target",
                                    placeHolder: "Enter Target",
                                    textFieldError: "targetErr",
                                    positionCenter: false,
                                    showInscreen: true,
                                  },

                                  {
                                    title: "Secondary Quantity",
                                    name: "target",
                                    placeHolder: "Enter Target",
                                    textFieldError: "targetErr",
                                    positionCenter: false,
                                    showInscreen: true,
                                  },
                                  {
                                    title: "Discount",
                                    name: "discount",
                                    placeHolder: "Enter Discount",
                                    textFieldError: "disocutErr",
                                    positionCenter: false,
                                    showInscreen: true,
                                  },
                                  {
                                    title: " Free Quantity",
                                    name: "name",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "left",
                                  },
                                  {
                                    title: "Discount %",
                                    name: "name",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "left",
                                  },
                                  {
                                    title: "Total",
                                    name: "name",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "left",
                                  },
                                ]
                                : flag && fieldData.flag != 1
                                  ? [
                                    {
                                      title: "Sr.No.",
                                      name: "index",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "center",
                                    },
                                    {
                                      title: "Product Name",
                                      name: "name",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "left",
                                    },
                                    {
                                      title: "UOM",
                                      name: "name",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "center",
                                    },
                                    {
                                      title: "Rate",
                                      name: "productStock",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "right",
                                    },
                                    {
                                      title: "Product Stock",
                                      name: "name",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "left",
                                    },

                                    {
                                      title: "Primary Quantity",
                                      name: "target",
                                      placeHolder: "Enter Target",
                                      textFieldError: "targetErr",
                                      positionCenter: false,
                                      showInscreen: true,
                                    },

                                    {
                                      title: "Secondary Quantity",
                                      name: "target",
                                      placeHolder: "Enter Target",
                                      textFieldError: "targetErr",
                                      positionCenter: false,
                                      showInscreen: true,
                                    },
                                    {
                                      title: "Discount",
                                      name: "discount",
                                      placeHolder: "Enter Discount",
                                      textFieldError: "disocutErr",
                                      positionCenter: false,
                                      showInscreen: true,
                                    },
                                    {
                                      title: "Total",
                                      name: "name",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "left",
                                    },
                                  ]
                                  : !flag && fieldData.flag == 1
                                    ? [
                                      {
                                        title: "Sr.No.",
                                        name: "index",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "center",
                                      },
                                      {
                                        title: "Product Name",
                                        name: "name",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "left",
                                      },
                                      {
                                        title: "UOM",
                                        name: "name",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "center",
                                      },
                                      {
                                        title: "Rate",
                                        name: "productStock",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "left",
                                      },
                                      {
                                        title: "Primary Quantity",
                                        name: "target",
                                        placeHolder: "Enter Target",
                                        textFieldError: "targetErr",
                                        positionCenter: false,
                                        showInscreen: true,
                                      },

                                      {
                                        title: "Secondary Quantity",
                                        name: "target",
                                        placeHolder: "Enter Target",
                                        textFieldError: "targetErr",
                                        positionCenter: false,
                                        showInscreen: true,
                                      },
                                      {
                                        title: "Discount",
                                        name: "discount",
                                        placeHolder: "Enter Discount",
                                        textFieldError: "disocutErr",
                                        positionCenter: false,
                                        showInscreen: true,
                                      },
                                      {
                                        title: " Free Quantity",
                                        name: "name",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "left",
                                      },
                                      {
                                        title: "Discount %",
                                        name: "name",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "left",
                                      },
                                      {
                                        title: "Total",
                                        name: "name",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "left",
                                      },
                                    ]
                                    : [
                                      {
                                        title: "Sr.No.",
                                        name: "index",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "center",
                                      },
                                      {
                                        title: "Product Name",
                                        name: "name",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "left",
                                      },
                                      {
                                        title: "UOM",
                                        name: "name",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "center",
                                      },
                                      {
                                        title: "Rate",
                                        name: "productStock",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "left",
                                      },
                                      {
                                        title: "Primary Quantity",
                                        name: "target",
                                        placeHolder: "Enter Target",
                                        textFieldError: "targetErr",
                                        positionCenter: false,
                                        showInscreen: true,
                                      },

                                      {
                                        title: "Secondary Quantity",
                                        name: "target",
                                        placeHolder: "Enter Target",
                                        textFieldError: "targetErr",
                                        positionCenter: false,
                                        showInscreen: true,
                                      },
                                      {
                                        title: "Discount",
                                        name: "discount",
                                        placeHolder: "Enter Discount",
                                        textFieldError: "disocutErr",
                                        positionCenter: false,
                                        showInscreen: true,
                                      },
                                      {
                                        title: "Total",
                                        name: "name",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "left",
                                      },
                                    ]
                            ) : (

                              flag && fieldData.flag == 1
                                ? [
                                  {
                                    title: "Sr.No.",
                                    name: "index",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "center",
                                  },
                                  {
                                    title: "Product Name",
                                    name: "name",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "left",
                                  },
                                  {
                                    title: "UOM",
                                    name: "name",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "center",
                                  },
                                  {
                                    title: "Rate",
                                    name: "productStock",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "right",
                                  },
                                  {
                                    title: "Product Stock",
                                    name: "name",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "left",
                                  },
                                  {
                                    title: "Quantity",
                                    name: "target",
                                    placeHolder: "Enter Target",
                                    textFieldError: "targetErr",
                                    positionCenter: false,
                                    showInscreen: true,
                                  },
                                  {
                                    title: " Free Quantity",
                                    name: "name",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "left",
                                  },
                                  {
                                    title: "Discount %",
                                    name: "name",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "left",
                                  },
                                  {
                                    title: "Total",
                                    name: "name",
                                    positionCenter: false,
                                    showInscreen: true,
                                    alignCenter: "left",
                                  },
                                ]
                                : flag && fieldData.flag != 1
                                  ? [
                                    {
                                      title: "Sr.No.",
                                      name: "index",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "center",
                                    },
                                    {
                                      title: "Product Name",
                                      name: "name",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "left",
                                    },
                                    {
                                      title: "UOM",
                                      name: "name",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "center",
                                    },
                                    {
                                      title: "Rate",
                                      name: "productStock",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "right",
                                    },
                                    {
                                      title: "Product Stock",
                                      name: "name",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "left",
                                    },

                                    {
                                      title: "Quantity",
                                      name: "target",
                                      placeHolder: "Enter Target",
                                      textFieldError: "targetErr",
                                      positionCenter: false,
                                      showInscreen: true,
                                    },
                                    // {
                                    // title: " Free Quantity",
                                    // name: "name",
                                    // positionCenter: false,
                                    // showInscreen: true,
                                    // alignCenter: "left",
                                    // },
                                    // {
                                    // title: "Discount %",
                                    // name: "name",
                                    // positionCenter: false,
                                    // showInscreen: true,
                                    // alignCenter: "left",
                                    // },
                                    {
                                      title: "Total",
                                      name: "name",
                                      positionCenter: false,
                                      showInscreen: true,
                                      alignCenter: "left",
                                    },
                                  ]
                                  : !flag && fieldData.flag == 1
                                    ? [
                                      {
                                        title: "Sr.No.",
                                        name: "index",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "center",
                                      },
                                      {
                                        title: "Product Name",
                                        name: "name",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "left",
                                      },
                                      {
                                        title: "UOM",
                                        name: "name",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "center",
                                      },
                                      {
                                        title: "Rate",
                                        name: "productStock",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "left",
                                      },
                                      {
                                        title: "Quantity",
                                        name: "target",
                                        placeHolder: "Enter Target",
                                        textFieldError: "targetErr",
                                        positionCenter: false,
                                        showInscreen: true,
                                      },
                                      {
                                        title: " Free Quantity",
                                        name: "name",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "left",
                                      },
                                      {
                                        title: "Discount %",
                                        name: "name",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "left",
                                      },
                                      {
                                        title: "Total",
                                        name: "name",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "left",
                                      },
                                    ]
                                    : [
                                      {
                                        title: "Sr.No.",
                                        name: "index",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "center",
                                      },
                                      {
                                        title: "Product Name",
                                        name: "name",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "left",
                                      },
                                      {
                                        title: "UOM",
                                        name: "name",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "center",
                                      },
                                      {
                                        title: "Rate",
                                        name: "productStock",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "left",
                                      },
                                      {
                                        title: "Quantity",
                                        name: "target",
                                        placeHolder: "Enter Target",
                                        textFieldError: "targetErr",
                                        positionCenter: false,
                                        showInscreen: true,
                                      },
                                      // {
                                      // title: " Free Quantity",
                                      // name: "name",
                                      // positionCenter: false,
                                      // showInscreen: true,
                                      // alignCenter: "left",
                                      // },
                                      // {
                                      // title: "Discount %",
                                      // name: "name",
                                      // positionCenter: false,
                                      // showInscreen: true,
                                      // alignCenter: "left",
                                      // },
                                      {
                                        title: "Total",
                                        name: "name",
                                        positionCenter: false,
                                        showInscreen: true,
                                        alignCenter: "left",
                                      },
                                    ]
                            )
                          }
                          // data={catData.productWithMarginAndMrps}
                          data={this.getFilteredTableData(
                            catData.productWithMarginAndMrps
                          )}
                          rowList={category.category}
                          setList={this.setCatList}
                          flag={flag}
                          freeQtyFlag={fieldData.flag}
                          companyFlagTwo={companyFlagTwo}
                        />
                      </TabPanel>
                    );
                  })}
                  <br />

                  {companyFlagTwo ? (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      container
                      justifyContent="space-between"
                    >
                      <Grid
                        item
                        xs={8}
                        sm={6}
                        md={6}
                        lg={6}
                        container
                        justifyContent="flex-start"
                      >
                        <Grid item xs={12} sm={2.5} md={2.5}>
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16, color: titleColor }}
                            label="CD :"
                          />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                          <TextFieldCompo
                            placeHolder="Enter CD"
                            color="primary"
                            name="discountOnBillAmt"
                            value={discountOnBillAmt}
                            fullWidth
                            onChange={this.changeHandlerTextField("discountOnBillAmt")}
                            errorText={formErrors["discountOnBillAmtError"] ? formErrors["discountOnBillAmtError"] : ""}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={8}
                        sm={6}
                        md={6}
                        lg={6}
                        container
                        justifyContent="space-between"
                      >
                        <DynamicDetails
                          DataToSet={[
                            {
                              xs: 12,
                              sm: 6,
                              md: 6,
                              lg: 6,
                              label: "Total Amount",
                              value: total.toFixed(2),
                            },
                            {
                              xs: 12,
                              sm: 6,
                              md: 6,
                              lg: 6,
                              label: "Total Quantity",
                              value: totalQty,
                            },
                          ]}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <DynamicDetails
                      DataToSet={[
                        {
                          xs: 12,
                          sm: 4,
                          md: 4,
                          lg: 4,
                          label: "Total Amount",
                          value: total.toFixed(2),
                        },
                        {
                          xs: 12,
                          sm: 4,
                          md: 4,
                          lg: 4,
                          label: "Total Quantity",
                          value: totalQty,
                        },
                      ]}
                    />
                  )}

                  <Grid container spacing={1}>
                    {fieldDataForModule.editReject == 1 ? (
                      <Grid
                        container
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{ justifyContent: "flex-end" }}
                      >

                        {companyFlagTwo && (
                          <ButtonCompo
                            size="medium"
                            type="submit"
                            variant="contained"
                            name={"Add Order"}
                            onClick={this.addOrder}
                          />
                        )}
                        &nbsp;&nbsp;
                        <ButtonCompo
                          size="medium"
                          type="submit"
                          variant="contained"
                          name={"Proceed"}
                          onClick={this.submitHandler}
                        />
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </>
              )}
            </Paper>
          </>
        )
        }
        {
          open && (
            <>
              <ManualBillingWithOffer
                offerBillOutletData={offerBillData}
                onCancel={this.toggle}
                salesPersonId={salesPersonId}
                flag={flag}
                total={total}
                totalQty={totalQty}
              />
            </>
          )
        }
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  areaList: state.area,
  outletList: state.outlet,
  beatList: state.beat,
  outletOrderDetail: state.outletOrderDetail,
  salesExecutive: state.salesExecutive,
  category: state.category,
  stockHolderAndProductConfiguration: state.stockHolderAndProductConfiguration,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getAreaByUserType,
  getActiveBeatByArea,
  getActiveOutletByBeatId,
  setCategoryAndItem,
  getRateEditOrNot,
};
export default connect(mapStateToProps, mapDispatchToProps)(ManualOrder);
