import { Grid, Paper } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import MaterialUITableWithTextField from "../../components/Comman/RT/MaterialUITableWithTextField";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import {
  serverMsg,
  saveFailedMsg,
  savemsg,
  noInternetMsg,
  saveWarningMsg,
} from "../../config/messageconstant";
import { outletRateStructureJson } from "../../DynamicFormsJson/Transaction/OutletRateStructure";
import {
  getProductByCategoryId,
  setProductList,
  getProductByOutletId,
} from "../../Slice/category.slice";
import endpoint from "../../config/endpoints";

import { apiPost, apiGet } from "../../utils/api_service";
import { getCategoryForOutlet } from "../../Slice/outletRateStructure.slice";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { withRouter } from "../../components/withRouter";
import { compose } from "@reduxjs/toolkit";
import swal from "sweetalert";
class OutletRateStructureForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldData: {},
      dynamicMasterData: {
        categoryForOutletDetail:
          this.props.categoryForOutletList?.categoryForOutlet,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.categoryForOutletList !== nextProps.categoryForOutletList) {
      if (
        this.props.categoryForOutletList?.categoryForOutlet !==
        nextProps.categoryForOutletList?.categoryForOutlet
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            categoryForOutletDetail:
              nextProps.categoryForOutletList?.categoryForOutlet,
          },
        });
      }
    }
  }

  async componentDidMount() {
    const {
      getProductByOutletId,
      getCategoryForOutlet,
      setProductList,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      setProductList({row:[]})
      showLoador({ loador: true });
      await getCategoryForOutlet().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      if (this.props.params.id) {
        showLoador({ loador: true });
        await getProductByOutletId({ outletId: this.props.params.id }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            } else {
            }
          }
        );
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  onSubmit = async () => {
    const { setProductList, category } = this.props;
    const { fieldData } = this.state;

    let productList = [];
    let error = false;
    const productRateConfigurationDetailsOutlets = category.product.map(
      (productListObj) => {
        const productObj = {
          product: {
            id: productListObj.productId,
          },
          marginPercentage: productListObj.marginPercentage,
          name: productListObj.name,
        };
        if (
          productListObj.marginPercentage == 0 ||
          productListObj.marginPercentage == ""
        ) {
          error = true;
          productListObj = {
            ...productListObj,
            marginPercentageErr: productListObj.marginPercentage == "" ? 1 : 2,
          };
        } else {
          productListObj = {
            ...productListObj,
            marginPercentageErr: 0,
          };
        }
        productList.push(productListObj);
        return productObj;
      }
    );
    setProductList({ row: productList });
    const dataToSave = {
      category: fieldData.category,
      name: fieldData.name,
      rateType: fieldData.rateType,
      productRateConfigurationDetailsOutlets:
        productRateConfigurationDetailsOutlets,
    };
    if (!error) {
      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.onSave(dataToSave);
        }
      });
    }
  };

  onSave = async (dataToSave) => {
    const { showLoador, showNotification, category } = this.props;

    if (navigator.onLine) {
      showLoador({ loador: true });
      await apiPost({
        url: endpoint.outletRateStructure,
        postBody: dataToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          showNotification({ msg: savemsg });
          this.props.navigate(-1);
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  onSearch = (data) => {
    const { getProductByCategoryId, showLoador, showNotification } = this.props;
    this.setState({
      fieldData: data,
    });
    if (navigator.onLine) {
      if (data.category && data.category.id) {
        showLoador({ loador: true });
        getProductByCategoryId({
          catId: data.category.id,
          marginPercentage: data.marginPercentage,
        }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  getDataById = (id) => {
    const { showLoador } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      const response = apiGet({
        url: endpoint.outletRateStructure + "/" + id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          if (!data.error) {
            let rowData = data.data;

            this.setState({
              fieldData: rowData,
            });
            return { data: rowData, success };
          }
          return { data: {}, success: false };
        }

        return { data: {}, success: false };
      });
      return response;
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  render() {
    const { DataToSet } = this.state;
    const { dynamicMasterData } = this.state;
    const { setProductList, category } = this.props;
    return (
      <>
        <Paper
          sx={{
            padding: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <DynamicFormWithoutSave
            showBackToList={true}
            DataToSet={DataToSet}
            getByIdApi={true}
            callApi={true}
            getDataById={this.getDataById}
            dynamicMasterData={dynamicMasterData}
            showSaveBtn={outletRateStructureJson.showSaveBtn}
            showTitle={outletRateStructureJson.showTitle}
            screenTitle={outletRateStructureJson.screenTitle}
            fieldMeta={outletRateStructureJson.fieldMeta}
            showCancel={outletRateStructureJson.showCancel}
            apiBaseURL={outletRateStructureJson.apiBaseURL}
            showSaveNextBtn={outletRateStructureJson.showSaveNextBtn}
            saveBtnText={"Search"}
            onSave={this.onSearch}
            showSaveBtnMain={false}
          />
          {category.product.length != 0 && (
            <>
            <br/>
              <MaterialUITableWithTextField
                tableHead={[
                  {
                    title: "Sr. No.",
                    name: "index",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "center",
                  },
                  {
                    title: "Product Name",
                    name: "name",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "left",
                  },
                  {
                    title: "MRP",
                    name: "mrp",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                  },
                  {
                    title: "Margin (%)",
                    textField: true,
                    name: "marginPercentage",
                    textFieldError: "marginPercentageErr",
                    error: "Margin (%)",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                    placeHolder: "Enter Remark",
                  },
                  {
                    title: "Projected Rate",
                    name: "rate",
                    positionCenter: false,
                    showInscreen: true,
                    alignCenter: "right",
                  },
                ]}
                data={category.product}
                setList={setProductList}
                isActionColActive={false}
              />
              <br />
              {category.product.length != 0 && (
                <>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    rowSpacing={2}
                    columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                    container
                    justifyContent="right"
                  >
                    <ButtonCompo
                      size="medium"
                      type="submit"
                      variant="contained"
                      name="Submit"
                      onClick={this.onSubmit}
                    />
                  </Grid>
                </>
              )}
            </>
          )}
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  mrpConfigrationList: state.mrpConfigration,
  categoryForOutletList: state.outletRateStructure,
  category: state.category,
});
const mapDispatchToProps = {
  showLoador,
  showNotification,
  setProductList,
  getCategoryForOutlet,
  getProductByCategoryId,
  getProductByOutletId,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OutletRateStructureForm);
