import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  taluka: [],
  talukaByDistrict: [],
  talukaNew: [],
};
let URL = endpoints.taluka;
const talukaSlice = createSlice({
  name: "taluka",
  initialState,
  reducers: {
    talukaSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        taluka: row,
      };
    },

    talukaByDistrictSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        talukaByDistrict: row,
      };
    },

    talukaNewSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        talukaNew: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  talukaSuccess,
  talukaByDistrictSuccess,
  talukaNewSuccess,
  resetState,
} = talukaSlice.actions;

export default talukaSlice.reducer;

export const getTaluka = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((talukaObject, index) => {
          let talukaData = {
            index: index + 1,
            id: talukaObject.id === null ? "" : talukaObject.id,
            name: talukaObject.name === null ? "" : talukaObject.name,
            districtName:
              talukaObject.district.name === null
                ? ""
                : talukaObject.district.name,
            shortName:
              talukaObject.shortName === null ? "" : talukaObject.shortName,
            areaCount:
              talukaObject.areaCount === null ? "" : talukaObject.areaCount,
            beatCount:
              talukaObject.beatCount === null ? "" : talukaObject.beatCount,
            outletCount:
              talukaObject.outletCount === null ? "" : talukaObject.outletCount,
            sortOrder:
              talukaObject.sortOrder === null ? "" : talukaObject.sortOrder,
          };
          return talukaData;
        });
        dispatch(talukaSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getTalukaUpdated = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/get-all",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((talukaObject, index) => {
          let talukaData = {
            index: index + 1,
            id: talukaObject.id === null ? "" : talukaObject.id,
            name: talukaObject.name === null ? "" : talukaObject.name,
            districtName:
              talukaObject.districtName === null
                ? ""
                : talukaObject.districtName,
            shortName:
              talukaObject.shortName === null ? "" : talukaObject.shortName,
            areaCount:
              talukaObject.areaCount === null ? "" : talukaObject.areaCount,
            beatCount:
              talukaObject.beatCount === null ? "" : talukaObject.beatCount,
            outletCount:
              talukaObject.outletCount === null ? "" : talukaObject.outletCount,
            sortOrder:
              talukaObject.sortOrder === null ? "" : talukaObject.sortOrder,
          };
          return talukaData;
        });
        dispatch(talukaSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getTalukaByDistrict =
  ({ districtIds }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/by-districts?districtIds=" + districtIds,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((talukaByDistrict, index) => {
              let talukaByDistrictData = {
                ...talukaByDistrict,
                index: index + 1,
                id: talukaByDistrict.id === null ? "" : talukaByDistrict.id,
                name: talukaByDistrict.name === null ? "" : talukaByDistrict.name,
                shortName:
                  talukaByDistrict.shortName === null
                    ? ""
                    : talukaByDistrict.shortName,
                district:
                  talukaByDistrict.name === null
                    ? ""
                    : talukaByDistrict.district.name,
                sortOrder:
                  talukaByDistrict.sortOrder === null
                    ? ""
                    : talukaByDistrict.sortOrder,
                districtId:
                  talukaByDistrict.name === null
                    ? ""
                    : talukaByDistrict.district.id,
              };
              return talukaByDistrictData;
            });
            dispatch(talukaByDistrictSuccess({ row }));
            dispatch(talukaNewSuccess({ row: data.data }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return { response: [], success: false };
      }
    };


export const getTalukaByDistrictPost =
  ({ districtIds }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/list-by-districts",
          postBody: districtIds
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((talukaByDistrict, index) => {
              let talukaByDistrictData = {
                ...talukaByDistrict,
                index: index + 1,
                id: talukaByDistrict.id === null ? "" : talukaByDistrict.id,
                name: talukaByDistrict.name === null ? "" : talukaByDistrict.name,
                shortName:
                  talukaByDistrict.shortName === null
                    ? ""
                    : talukaByDistrict.shortName,
                district:
                  talukaByDistrict.name === null
                    ? ""
                    : talukaByDistrict.district.name,
                sortOrder:
                  talukaByDistrict.sortOrder === null
                    ? ""
                    : talukaByDistrict.sortOrder,
                districtId:
                  talukaByDistrict.name === null
                    ? ""
                    : talukaByDistrict.district.id,
              };
              return talukaByDistrictData;
            });
            dispatch(talukaByDistrictSuccess({ row }));
            dispatch(talukaNewSuccess({ row: data.data }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return { response: [], success: false };
      }
    };

export const setTalukabyDistrict =
  ({ row }) =>
    async (dispatch) => {
      dispatch(talukaNewSuccess({ row }));
    };
