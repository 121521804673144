import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import moment from "moment";
import dayjs from "dayjs";
import { apiGet } from "../utils/api_service";
const initialState = {
  audit: [],
  auditQuestion: [],
  auditHeadById: [],
  auditHeadTransaction: [],
};
let URL = endpoints.audit;
let URL2 = endpoints.auditQuestion;
let URL3 = endpoints.auditTransaction;
const auditSlice = createSlice({
  name: "audit",
  initialState,
  reducers: {
    auditSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        audit: row,
      };
    },

    auditQestionSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        auditQuestion: row,
      };
    },

    auditHeadByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        auditHeadById: row,
      };
    },

    auditHeadTransactionSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        auditHeadTransaction: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
    resetStateQuestion: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        auditQuestion: [],
      };
    },
  },
});

export const {
  auditSuccess,
  auditQestionSuccess,
  auditHeadByIdSuccess,
  auditHeadTransactionSuccess,
  resetState,
  resetStateQuestion,
} = auditSlice.actions;

export default auditSlice.reducer;

export const getAudit = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const dateArr = moment(new Date()).format("DD-MM-YYYY");
        let changedFromDate = "";
        const toDateArr = "";
        let changedToDate = "";
        const { content } = data.data;
        const row = content.map((auditObject, index) => {
          let auditdata = {
            fromDateArr:
              auditObject.fromDate === null
                ? ""
                : auditObject.fromDate?.split("-"),
            toDateArr:
              auditObject.toDate === null ? "" : auditObject.toDate?.split("-"),
            index: index + 1,
            id: auditObject.id === null ? "" : auditObject.id,
            name: auditObject.name === null ? "" : auditObject.name,
            isAuditSurvey:
              auditObject.isAuditSurvey === null
                ? ""
                : auditObject.isAuditSurvey == 1
                  ? "Survey" : auditObject.isAuditSurvey == 2 ? "Area Exploration Questionary"
                    : "Audit",
            isAuditSurveyCond:
              auditObject.isAuditSurvey == 0
                ? 0
                : dayjs(auditObject.toDate, "DD-MM-YYYY").isAfter(dayjs(), "day")
                  ? 0
                  : 1,
            remark: auditObject.remark === null ? "" : auditObject.remark,
            whoCanTake:
              auditObject.whoCanTake === null ? "" : auditObject.whoCanTake,
            type:
              auditObject.type === null
                ? ""
                : auditObject.type == 1
                  ? "StakeHolder"
                  : "Outlet",
            stockStakeHolderTypeId:
              auditObject.stockStakeHolderTypeId === null
                ? ""
                : auditObject.stockStakeHolderTypeId,
            isActive: auditObject.isActive === null ? "" : auditObject.isActive,
            areaIds: auditObject.areaIds === null ? "" : auditObject.areaIds,
            beatIds: auditObject.beatIds === null ? "" : auditObject.beatIds,
            delStatus:
              auditObject.delStatus === null ? "" : auditObject.delStatus,
            editDelConditionStatus:
              auditObject.isAuditSurvey === 0
                ? 1
                : dayjs(auditObject.toDate, "DD-MM-YYYY").isAfter(dayjs(), "day")
                  ? 1
                  : 0,


            fromDate: auditObject.fromDate === null ? "" : auditObject.fromDate,
            toDate: auditObject.toDate === null ? "" : auditObject.toDate,
            outletIds:
              auditObject.outletIds === null ? "" : auditObject.outletIds,
            insertDateTime:
              auditObject.insertDateTime === null
                ? ""
                : auditObject.insertDateTime,
          };
          return auditdata;
        });
        dispatch(auditSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAuditQestion =
  ({ id }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL2 + "/by-audit-head?auditHeadId=" + id,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((auditQuestionObject, index) => {
              let auditQuestiondata = {
                index: index + 1,
                id:
                  auditQuestionObject.auditHead !== null &&
                    auditQuestionObject.auditHead.id
                    ? auditQuestionObject.auditHead.id
                    : "",
                questionId:
                  auditQuestionObject.id === null ? "" : auditQuestionObject.id,
                question:
                  auditQuestionObject.question === null
                    ? ""
                    : auditQuestionObject.question,
                optionTypeName:
                  auditQuestionObject.optionType === null
                    ? ""
                    : auditQuestionObject.optionType == "0"
                      ? "Description"
                      : auditQuestionObject.optionType == "1"
                        ? "Optional"
                        : "Multiselect",
                optionType:
                  auditQuestionObject.optionType === null
                    ? ""
                    : auditQuestionObject.optionType,
                points:
                  auditQuestionObject.points === null
                    ? ""
                    : auditQuestionObject.points == 0 ? "-" : auditQuestionObject.points,
                orderNo:
                  auditQuestionObject.orderNo === null
                    ? ""
                    : auditQuestionObject.orderNo,
                remark:
                  auditQuestionObject.remark === null
                    ? ""
                    : auditQuestionObject.remark,
                remarkMandatory:
                  auditQuestionObject.remarkMandatory === null
                    ? ""
                    : auditQuestionObject.remarkMandatory,
                imageUpload:
                  auditQuestionObject.imageUpload === null
                    ? ""
                    : auditQuestionObject.imageUpload,
                auditQuestionOption:
                  auditQuestionObject.auditQuestionOption === null
                    ? ""
                    : auditQuestionObject.auditQuestionOption.map(
                      (optionData, indexData) => {
                        return { index: indexData + 1, ...optionData };
                      }
                    ),
              };
              return auditQuestiondata;
            });
            dispatch(auditQestionSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };


export const getAuditHeadById =
  ({ auditHeadId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL + "/survey-done-remain-count?auditHeadId=" + auditHeadId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((auditHeadByIdObj, index) => {
              let auditQuestiondata = {
                index: index + 1,
                id:
                  auditHeadByIdObj.auditHead !== null
                    ? auditHeadByIdObj.id
                    : "",
                areaId:
                  auditHeadByIdObj.areaId === null ? "" : auditHeadByIdObj.areaId,
                areaName: auditHeadByIdObj.areaName === null ? "" : auditHeadByIdObj.areaName,
                auditHeadId:
                  auditHeadByIdObj.auditHeadId === null
                    ? ""
                    : auditHeadByIdObj.auditHeadId,
                surveyDoneCount:
                  auditHeadByIdObj.surveyDoneCount === null
                    ? ""
                    : auditHeadByIdObj.surveyDoneCount,
                surveyRemainCount:
                  auditHeadByIdObj.surveyRemainCount === null
                    ? ""
                    : auditHeadByIdObj.surveyRemainCount,


              };
              return auditQuestiondata;
            });
            dispatch(auditHeadByIdSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };


export const setAuditQuestion =
  ({ row }) =>
    async (dispatch) => {
      dispatch(auditQestionSuccess({ row }));
    };

export const resetDataAddQuestion = () => async (dispatch) => {
  try {
    dispatch(resetStateQuestion());
  } catch (e) {
    return console.error(e.message);
  }
};


export const getAuditTransactionData = ({ auditHeadId, areaId }) => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL3 + "/by-audit-head-and-area?auditHeadId=" + auditHeadId + "&areaId=" + areaId,
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((auditTransactionObj, index) => {
          let auditTransactionData = {
            index: index + 1,
            id:
              auditTransactionObj.id === null
                ? ""
                : auditTransactionObj.id,
            auditHeadId:
              auditTransactionObj.auditHead === null ? "" : auditTransactionObj.auditHead.id,
            auditHeadName: auditTransactionObj.auditHead === null ? "" : auditTransactionObj.auditHead.name,
            auditHeadRemark: auditTransactionObj.auditHead === null ? "" : auditTransactionObj.auditHead.remark,
            auditHeadIsAuditSurvey: auditTransactionObj.auditHead === null ? "" : auditTransactionObj.auditHead.isAuditSurvey,
            auditHeadFromDate: auditTransactionObj.auditHead === null ? "" : auditTransactionObj.auditHead.auditHead,
            auditHeadToDate: auditTransactionObj.auditHead === null ? "" : auditTransactionObj.auditHead.toDate,
            type: auditTransactionObj.type === null ? "" : auditTransactionObj.type,
            date: auditTransactionObj.date === null ? "" : auditTransactionObj.date,
            totalPoint: auditTransactionObj.totalPoint === null ? "" : auditTransactionObj.totalPoint,
            totalScore: auditTransactionObj.totalScore === null ? "" : auditTransactionObj.totalScore,
            auditHeadStockholderIdOutletId: auditTransactionObj.stockholderIdOutletId === null ? ""
              : auditTransactionObj.stockholderIdOutletId,
            outletFirmName: auditTransactionObj.outletFirmName === null ? "" : auditTransactionObj.outletFirmName,
            outletOwnerName: auditTransactionObj.outletOwnerName === null ? "" : auditTransactionObj.outletOwnerName,
            status: auditTransactionObj.status === null ? "" : auditTransactionObj.status,
            isAuditSurvey: auditTransactionObj.isAuditSurvey === null ? "" : auditTransactionObj.isAuditSurvey,
            auditTransactionDetails: auditTransactionObj.dtoAuditTransactionDetails === null ? "" : auditTransactionObj.dtoAuditTransactionDetails.map((obj, index) => {
              let auditTransactionDetailsData = {
                index: index + 1,
                auditTransactionDetailsId: obj.id === null ? "" : obj.id,
                auditQuestionId: obj.auditQuestion === null ? "" : obj.auditQuestion.id,
                question: obj.auditQuestion === null ? "" : obj.auditQuestion.question,
                auditQuestionPoint: obj.auditQuestion === null ? "" : obj.auditQuestion.points,
                auditQuestionOptionType: obj.auditQuestion === null ? "" : obj.auditQuestion.optionType,
                auditQuestionRmark: obj.auditQuestion === null ? "" : obj.auditQuestion.remark,
                auditQuestionOrderNo: obj.auditQuestion === null ? "" : obj.auditQuestion.orderNo,
                auditTransactionTotalPoints: obj.totalPoint === null ? "" : obj.totalPoint,
                auditTransactionQueScore: obj.queScore === null ? "" : obj.queScore,
                auditQuestionOptionValue: obj.auditQuestionOptionValue === null ? "" : obj.auditQuestionOptionValue,
                answer: obj.auditQuestion && obj.auditQuestion.optionType === null ? ""
                  : obj.auditQuestion.optionType == 0 ? obj.auditQuestionOptionValue
                    : obj.auditQuestion.auditQuestionOption.find((row) => row.id === obj.auditQuestionOptionValue)?.optionTitle || "",
                answerForMultiSelect: obj.auditQuestion && obj.auditQuestion.auditQuestionOption === null ? ""
                  : obj.auditQuestion.auditQuestionOption.map((object) => {
                    const auditQuestionOptionValueList = obj.auditQuestionOptionValue.split(",")
                    const matchedValue = auditQuestionOptionValueList.filter((row) => row === object.id);
                    return matchedValue.length !== 0 ? object.optionTitle : '';
                  }),
                auditTransactionDetailsRemark: obj.remark === null ? "" : obj.remark,
                auditQuestionOption: obj.auditQuestion && obj.auditQuestion.auditQuestionOption === null ? "" :
                  obj.auditQuestion.auditQuestionOption.map((optionData, indexData) => {
                    return { index: indexData + 1, ...optionData };
                  }
                  ),
              };
              return auditTransactionDetailsData;
            }),
          };
          return auditTransactionData;
        });
        dispatch(auditHeadTransactionSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
