import endpoint from "../../config/endpoints";

export const noticeMessageJSON = {
  showSaveNextBtn: false,
  showAddButtonInTable: false,
  formPath: "/notice-message-form",
  formPathView: "/notice-message-view",
  isActiveURL: endpoint.noticeMsg + "/update-status",
  apiBaseURL: endpoint.noticeMsg,
  changeExcel: endpoint.exportData,
  screenTitle: "Notice / Message",
  showAddButton: true,
  showPdfDownload: false,
  pdfFileName: "Product",
  showExcelDownload: false,
  excelFileName: "Product",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Notice Headline",
      columnKeyName: "noticeHeadline",
      isChecked: true,
    },
    {
      columnDisplayName: "Description",
      columnKeyName: "description",
      isChecked: true,
    },
    {
      columnDisplayName: "Type",
      columnKeyName: "typeName",
      isChecked: true,
    },
    {
      columnDisplayName: "From Date",
      columnKeyName: "fromdate",
      isChecked: true,
    },
    {
      columnDisplayName: "To Date",
      columnKeyName: "todate",
      isChecked: true,
    },
  ],
  searchList: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "toDate",
      onSubmit: "compare",
      onSubmitParameter: "fromDate-ge",
      isMandatory: true,
      onSubmit: "compare",
    },
  ],
  fieldMeta: [
    {
      label: "Stock Holder Type",
      controlType: "multiSelect",
      placeHolder: "Select Stock Holder Type",
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeHolderTypeDetail",
      dataKey: "stockStakeHolderTypeIds",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "regionDetail",
      dataKey: "regionIds",
      getListId: "regionIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "State",
      controlType: "autocomplete",
      placeHolder: "Select State",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stateDetail",
      dataKey: "state",
      getListFrom: "regionIds",
      getListId: "state",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "multiSelect",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "zoneDetail",
      dataKey: "zoneIds",
      getListFrom: "state",
      getListId: "zoneIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "District",
      controlType: "multiSelect",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "districtDetail",
      dataKey: "district",
      getListFrom: "zoneIds",
      getListId: "district",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Taluka",
      controlType: "multiSelect",
      placeHolder: "Select Taluka",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "talukaDetail",
      dataKey: "taluka",
      getListFrom: "district",
      getListId: "taluka",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "areaIds",
      getListFrom: "taluka",
      getListId: "areaIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromdate",
      isMandatory: true,
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "todate",
      isMinDate: false,
      minDate: "fromdate",
      addOne: false,
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
    },
    {
      label: "Notice Headline",
      controlType: "textfield",
      placeHolder: "Notice Headline",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "noticeHeadline",
      isMandatory: true,
    },
    {
      label: "Type",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "type",
      tableDataKey: "type",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Message",
          value: "1",
        },
        {
          label: "Notice",
          value: "0",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Description",
      controlType: "textfield",
      placeHolder: "Description",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "description",
      isMandatory: true,
    },
    {
      label: "Flash",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "flash",
      tableDataKey: "flash",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: true,
    },
    {
      label: " ",
      controlType: "hideTextfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "flashName",
      tableDataKey: "flashName",
      openDataKey: "flash",
      open: "1",
      error: "flash",
      saveDataKey: true,
    },
    {
      label: "Select Image",
      controlType: "selectImage",
      placeHolder: "Select Image",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKeyToSave: "image",
      dataKey: "productImageShow",
      isMandatory: true,
    },
  ],

  fieldMetaForEdit: [
    {
      label: "Stock Holder Type",
      controlType: "multiSelect",
      placeHolder: "Select Stock Holder Type",
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeHolderTypeDetail",
      dataKey: "stockStakeHolderTypeIds",
      isRootLevelKey: false,
      isMandatory: true,
      disableTrue: true,
    },
    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "regionDetail",
      dataKey: "regionIds",
      getListId: "regionIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "multiSelect",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "zoneDetail",
      dataKey: "zoneIds",
      getListFrom: "regionIds",
      getListId: "zoneIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "areaIds",
      getListFrom: "zoneIds",
      getListId: "areaIds",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromdate",
      isMandatory: true,
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "todate",
      isMinDate: false,
      minDate: "fromdate",
      addOne: false,
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "fromdate-ge",
    },
    {
      label: "Notice Headline",
      controlType: "textfield",
      placeHolder: "Notice Headline",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "noticeHeadline",
      isMandatory: true,
    },
    {
      label: "Type",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "type",
      tableDataKey: "type",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Message",
          value: "1",
        },
        {
          label: "Notice",
          value: "0",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Description",
      controlType: "textfield",
      placeHolder: "Description",
      md: 12,
      lg: 12,
      sm: 12,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      dataKey: "description",
      isMandatory: true,
    },
    {
      label: "Flash",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "flash",
      tableDataKey: "flash",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Yes",
          value: "1",
        },
        {
          label: "No",
          value: "0",
        },
      ],
      isMandatory: true,
    },
    {
      label: " ",
      controlType: "hideTextfield",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "flashName",
      tableDataKey: "flashName",
      openDataKey: "flash",
      open: "1",
      error: "flash",
      saveDataKey: true,
    },
    {
      label: "Select Image",
      controlType: "selectImage",
      placeHolder: "Select Image",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKeyToSave: "image",
      dataKey: "productImageShow",
      isMandatory: true,
    },
  ],
};
