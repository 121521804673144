import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { TimeSlotJson } from "../../../DynamicFormsJson/MastersJSON/timeSlot";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
class TimeSlotForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        return (
            <>
                <DynamicForm
                    formPath={TimeSlotJson.formPath}
                    screenTitle={TimeSlotJson.screenTitle}
                    fieldMeta={TimeSlotJson.fieldMeta}
                    apiBaseURL={TimeSlotJson.apiBaseURL}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({

});
const mapDispatchToProps = {
    showLoador,
    showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(TimeSlotForm);
