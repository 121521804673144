import { Checkbox, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import React from "react";
import { connect } from "react-redux";
import { AssetsImplementationJson } from "../../../DynamicFormsJson/MastersJSON/assetsImplementationJson";
import { getAreaByTalukaPostBodyUpdated } from "../../../Slice/area.slice";
import { getDistrictByZone } from "../../../Slice/district.slice";
import { getOutletByAreaNew } from "../../../Slice/outlet.slice";
import { getRegionNew } from "../../../Slice/region.slice";
import { getStateByRegion } from "../../../Slice/state.slice";
import { getTalukaByDistrict } from "../../../Slice/taluka.slice";
import { getZoneByState } from "../../../Slice/zone.slice";
import { getAssets } from "../../../Slice/assets.slice";
import { getVender } from "../../../Slice/vender.slice";
import { getAssetsRequestDetailsByAssetsAndRequestStatus, setAssetsRequestDetails, getAssetsRequestDetailsByAreaOutletsAndRequestStatus } from "../../../Slice/assets.slice";
import DynamicFormWithoutSave from "../../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../../components/withRouter";
import {
    costGreaterThanZeroMsg,
    costRequiredMsg,
    noInternetMsg,
    saveFailedMsg,
    savemsg,
    saveWarningMsg,
    serverMsg,
    venderRequiredMsg
} from "../../../config/messageconstant";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import LandingScreenHeader from "../../../components/Comman/LandingScreenHeader";
import AutoComplete from "../../../components/Comman/AutoComplete";
import { TextFieldCompo } from "../../../components/Comman/TextFieldCompo";
import { ButtonCompo } from "../../../components/Comman/Button";
import { apiPost } from "../../../utils/api_service";
import endpoint from "../../../config/endpoints";
import { getActiveBeatByArea } from "../../../Slice/beat.slice";
import { getOutletByBeatIdsPost } from "../../../Slice/outlet.slice";
import { cyanBlue } from "../../../config/ColorObj";
import AssetsCheckListDetailsPopUp from "./AssetsCheckListDetailsPopUp";

class AssetsImplementationForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assetsCheckListDetailsPopUpFlag: false,
            dataRow: "",
            vender: "",
            autoCompleteSelectedId: "",
            flag: "0",
            searchBy: "0",
            searchValue: "",
            listClearFlag: false,
            allChecked: false,
            fieldData: {},
            formData: {},
            formErrors: {},
            dynamicMasterData: {
                regionDetail: this.props.regionList.region,
            },
            selection: [],
            fromDate: dayjs().format("DD-MM-YYYY"),
            toDate: dayjs().format("DD-MM-YYYY"),
        };
    }
    async componentDidMount() {
        const {
            getRegionNew,
            getAssets,
            setAssetsRequestDetails,
            showLoador,
            showNotification,
        } = this.props;
        if (navigator.onLine) {

            setAssetsRequestDetails({ row: [] })


            showLoador({ loador: true });
            await getRegionNew().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });

            showLoador({ loador: true });
            await getAssets().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });



        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    componentWillReceiveProps(nextProps) {


        if (this.props.assetsList !== nextProps.assetsList) {
            if (this.props.assetsList?.assets !== nextProps.assetsList?.assets) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        assetsDetail: nextProps.assetsList?.assets,
                    },
                });
            }
        }


        if (this.props.regionList !== nextProps.regionList) {
            if (this.props.regionList?.region !== nextProps.regionList?.region) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        regionDetail: nextProps.regionList?.region,
                    },
                });
            }
        }
        if (this.props.stateList !== nextProps.stateList) {
            if (this.props.stateList?.stateByRegion !== nextProps.stateList?.stateByRegion) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        stateDetail: nextProps.stateList?.stateByRegion,
                    },
                });
            }
        }
        if (this.props.zoneList !== nextProps.zoneList) {
            if (this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        zoneDetail: nextProps.zoneList?.zoneByState,
                    },
                });
            }
        }
        if (this.props.districtList !== nextProps.districtList) {
            if (this.props.districtList?.districtByZone !== nextProps.districtList?.districtByZone) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        districtDetail: nextProps.districtList?.districtByZone,
                    },
                });
            }
        }
        if (this.props.talukaList !== nextProps.talukaList) {
            if (this.props.talukaList?.talukaByDistrict !== nextProps.talukaList?.talukaByDistrict) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        talukaDetail: nextProps.talukaList?.talukaByDistrict,
                    },
                });
            }
        }
        if (this.props.areaList !== nextProps.areaList) {
            if (this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        areaDetail: nextProps.areaList?.areaByTaluka,
                    },
                });
            }
        }

        if (this.props.beatList !== nextProps.beatList) {
            if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        beatDetail: nextProps.beatList?.beat,
                    },
                });
            }
        }
        if (this.props.outletList !== nextProps.outletList) {
            if (this.props.outletList?.outlets !== nextProps.outletList?.outlets) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        outletDetail: nextProps.outletList?.outlets,
                    },
                });
            }
        }
    }

    getListById = async (data) => {
        const {
            getStateByRegion,
            getZoneByState,
            getTalukaByDistrict,
            getDistrictByZone,
            getAreaByTalukaPostBodyUpdated,
            getActiveBeatByArea,
            showLoador,
            showNotification,
            getOutletByBeatIdsPost,
            setAssetsRequestDetails
        } = this.props;


        if (data.flag == 1) {
            setAssetsRequestDetails({ row: [] })
        } else {
            setAssetsRequestDetails({ row: [] })
        }


        if (data.flag) {
            this.setState({
                searchBy: data.flag,
                formData: {
                    flag: data.flag,
                },
            });
        }

        if (navigator.onLine) {
            if (this.state.searchBy == 1) {
                if (data.region && data.region != "") {
                    showLoador({ loador: true });
                    await getStateByRegion({ regionIds: data.region }).then(
                        ({ success }) => {
                            showLoador({ loador: false });
                            if (!success) {
                                showNotification({ msg: serverMsg, severity: "error" });
                            }
                        }
                    );

                }


                if (data.state && data.state != "") {
                    showLoador({ loador: true });
                    await getZoneByState({ stateIds: data.state }).then(
                        ({ success }) => {
                            showLoador({ loador: false });
                            if (!success) {
                                showNotification({ msg: serverMsg, severity: "error" });
                            }
                        }
                    );
                }

                if (data.zoneIds && data.zoneIds != "") {
                    showLoador({ loador: true });
                    await getDistrictByZone({ zoneIds: data.zoneIds }).then(
                        ({ success }) => {
                            showLoador({ loador: false });
                            if (!success) {
                                showNotification({ msg: serverMsg, severity: "error" });
                            }
                        }
                    );
                }
                if (data.districtIds && data.districtIds != "") {
                    showLoador({ loador: true });
                    await getTalukaByDistrict({ districtIds: data.districtIds }).then(
                        ({ success }) => {
                            showLoador({ loador: false });
                            if (!success) {
                                showNotification({ msg: serverMsg, severity: "error" });
                            }
                        }
                    );
                }
                if (data.talukaIds && data.talukaIds != "") {
                    let talukaIdsByPost = {
                        talukaIds: data.talukaIds,
                    };
                    showLoador({ loador: true });
                    await getAreaByTalukaPostBodyUpdated({ talukaIds: talukaIdsByPost }).then(
                        ({ success }) => {
                            showLoador({ loador: false });
                            if (!success) {
                                showNotification({ msg: serverMsg, severity: "error" });
                            }
                        }
                    );
                }


                if (data.area && data.area != "") {

                    showLoador({ loador: true });
                    await getActiveBeatByArea({ areaId: data.area }).then(
                        ({ success }) => {
                            showLoador({ loador: false });
                            if (!success) {
                                showNotification({ msg: serverMsg, severity: "error" });
                            }
                        }
                    );
                }

                if (data.beatIds && data.beatIds != "") {
                    let jsonData = {
                        beatIds: data.beatIds,
                    }

                    showLoador({ loador: true });
                    await getOutletByBeatIdsPost({ beatIds: jsonData }).then(
                        ({ success }) => {
                            showLoador({ loador: false });
                            if (!success) {
                                showNotification({ msg: serverMsg, severity: "error" });
                            }
                        }
                    );
                }



            }
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    onSearchList = (data) => {
        const {
            getAssetsRequestDetailsByAssetsAndRequestStatus,
            getAssetsRequestDetailsByAreaOutletsAndRequestStatus,
            getVender,
            setAssetsRequestDetails,
            showLoador,
            showNotification,
        } = this.props;
        if (navigator.onLine) {

            this.setState({
                formData: data,
                fieldData: data,
            });



            if (data.flag == 0) {
                setAssetsRequestDetails({ row: [] })
                showLoador({ loador: true });
                getAssetsRequestDetailsByAssetsAndRequestStatus({ assetIds: data.assets, requestStatus: "0" }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            } else {
                if (data.area && data.outletIds) {
                    let JsonData = {
                        areaId: data.area,
                        outletIds: data.outletIds.split(","),
                        status: "0",
                    };
                    showLoador({ loador: true });
                    getAssetsRequestDetailsByAreaOutletsAndRequestStatus({ JsonData }).then(({ success }) => {
                        showLoador({ loador: false });
                        if (!success) {
                            showNotification({ msg: serverMsg, severity: "error" });
                        }
                    });
                }
            }


            showLoador({ loador: true });
            getVender().then(({ success }) => {
                showLoador({ loador: false });
                if (!success) {
                    showNotification({ msg: serverMsg, severity: "error" });
                }
            });

        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };




    checkValidationNew = () => {
        const { assetsList } = this.props;
        let formErrors = {};
        let formIsValid = true;

        assetsList?.assetsRequestDetails.map((dataObj) => {
            if (dataObj.isChecked) {
                if (dataObj.vender === "") {
                    formIsValid = false;
                    formErrors["venderError"] = venderRequiredMsg;
                }

                if (dataObj.cost === "0") {
                    formIsValid = false;
                    formErrors["costError"] = costGreaterThanZeroMsg;
                } else if (dataObj.cost.trim() === "") {
                    formIsValid = false;
                    formErrors["costError"] = costRequiredMsg
                }
            }
        });

        this.setState({ formErrors });
        return formIsValid;
    };


    onAutocompleteChange = (row) => (name, newValue) => {
        const { assetsList, setAssetsRequestDetails } = this.props;

        let updatedVenderList = assetsList.assetsRequestDetails.map((dataObj) => {
            if (dataObj.id === row.id) {
                return {
                    ...dataObj,
                    [name]: newValue,
                };

            }
            return dataObj;
        });

        setAssetsRequestDetails({ row: updatedVenderList });
    };


    handleClick = (e, id) => {
        const { assetsList, setAssetsRequestDetails } = this.props;

        this.setState({ selection: e });

        let data = assetsList.assetsRequestDetails.map((dataObj) => {
            if (dataObj.id == id) {
                return {
                    ...dataObj,
                    isChecked: e.target.checked,
                };
            } else {
                return dataObj;
            }
        });
        setAssetsRequestDetails({ row: data });
    };

    handleAllCheckedClick = (e) => {
        const { assetsList, setAssetsRequestDetails } = this.props;

        this.setState({ selection: e });

        this.setState({ allChecked: e.target.checked });

        let dataList = assetsList.assetsRequestDetails.map((dataObj) => {
            return {
                ...dataObj,
                isChecked: e.target.checked,
            };
        });
        console.log(dataList);
        setAssetsRequestDetails({ row: dataList });
    };


    changeHandler = (row, name) => (e) => {
        const { name, value } = e.target;
        const { assetsList, setAssetsRequestDetails } = this.props;

        let updatedList = assetsList.assetsRequestDetails.map((dataObj) => {
            if (dataObj.id === row.id) {
                return {
                    ...dataObj,
                    [name]: value,
                };
            } else {
                return dataObj;
            }
        });

        setAssetsRequestDetails({ row: updatedList })
    };

    onSearchBox = (searchValue) => {
        this.setState({
            searchValue: searchValue,
        });
    };

    getFilteredTableData = (searchData) => {
        const { searchValue } = this.state;
        return searchData.filter((currentRow) => {
            let isValid = false;
            if (
                (currentRow["firmName"] &&
                    currentRow["firmName"]
                        .toString()
                        .toLowerCase()
                        .includes(searchValue.trim().toLowerCase()))
            ) {
                isValid = true;
                return true;
            }
        });
    };




    onSave = (dataToSave) => {
        const { showLoador, showNotification } = this.props;
        if (navigator.onLine) {
            showLoador({ loador: true });
            apiPost({
                url: endpoint.assetsImplementationDetails + "/save",
                postBody: dataToSave,
            }).then(({ data, success }) => {
                showLoador({ loador: false });
                if (success) {
                    window.location.reload();
                    showNotification({ msg: savemsg });
                } else {
                    showNotification({ msg: saveFailedMsg, severity: "error" });
                }
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };


    onApprove = () => {
        const { assetsList, showNotification } = this.props;
        const { selection } = this.state;

        if (selection.length !== 0) {
            if (this.checkValidationNew()) {
                const dataToSaveList = assetsList.assetsRequestDetails
                    .filter((rowData) => rowData.isChecked).map((rowData) => {
                        return {
                            outlet: {
                                id: rowData.outletId,
                            },
                            vender: {
                                id: rowData.vender,
                            },
                            assetsRequestDetails: {
                                id: rowData.id,
                            },
                            implementaionCost: rowData.cost,
                            remark: "NA",
                            implementaionDate: dayjs().format("DD-MM-YYYY"),
                        };
                    });

                swal({
                    title: "Are you sure?",
                    text: saveWarningMsg,
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((willDelete) => {
                    if (willDelete) {
                        this.onSave(dataToSaveList);
                    }
                });
            }
        } else {
            showNotification({
                msg: "Please add at least one product",
                severity: "error",
            });
        }
    };


    assetsChecklistDetais = (data) => {
        this.setState({ assetsCheckListDetailsPopUpFlag: true, dataRow: data })
    }

    toggle = () => {
        this.setState({ assetsCheckListDetailsPopUpFlag: false })
    }


    render() {
        const { assetsList, venderList } = this.props;
        const {
            dynamicMasterData,
            assetsCheckListDetailsPopUpFlag,
            searchBy,
            allChecked,
            formErrors,
            dataRow,
        } = this.state;
        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 0,
                    }}
                    elevation={0}
                >

                    <DynamicFormWithoutSave
                        getListById={this.getListById}
                        dynamicMasterData={dynamicMasterData}
                        showTitle={true}
                        screenTitle={AssetsImplementationJson.screenTitle}
                        searchList={
                            searchBy == 0
                                ? AssetsImplementationJson.searchListByAssets
                                : AssetsImplementationJson.searchListByArea
                        }
                        fieldMeta={searchBy == 0
                            ? AssetsImplementationJson.searchListByAssets
                            : AssetsImplementationJson.searchListByArea}
                        showBackToList={AssetsImplementationJson.showBackToList}
                        apiBaseURL={AssetsImplementationJson.apiBaseURL}
                        saveBtnText={"Search"}
                        showSaveBtn={true}
                        showSaveBtnMain={false}
                        onSave={this.onSearchList}
                        padding={true}
                        paddingTop={true}
                    />
                    <br />


                    {assetsList?.assetsRequestDetails?.length !== 0 && (
                        <>

                            <LandingScreenHeader
                                screenTitle={""}
                                showSearchBox={true}
                                showTable={true}
                                showFilter={false}
                                showPdfDownload={false}
                                showExcelDownload={false}
                                onSearch={this.onSearchBox}
                            />
                            <TableContainer style={{ maxHeight: "550px", overflow: "auto" }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="error"
                                                    onClick={(event) => this.handleAllCheckedClick(event)}
                                                    checked={allChecked}
                                                    disabled={false}
                                                    inputProps={{
                                                        "aria-label": "select all desserts",
                                                    }}
                                                /></TableCell>
                                            <TableCell align="center">Sr. No.</TableCell>
                                            <TableCell align="center" width="25%">Firm Name</TableCell>
                                            <TableCell align="center" width="25%">Assets</TableCell>
                                            <TableCell align="center" width="25%">Vender</TableCell>
                                            <TableCell align="center">Cost</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.getFilteredTableData(assetsList?.assetsRequestDetails)?.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        onClick={(event) => this.handleClick(event, row.id)}
                                                        checked={row.isChecked}
                                                        disabled={false}
                                                        inputProps={{
                                                            "aria-labelledby": `enhanced-table-checkbox-${index}`,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">{index + 1}</TableCell>
                                                <TableCell align="left">
                                                    <div
                                                        onClick={(e) =>
                                                            this.assetsChecklistDetais(row)
                                                        }
                                                        style={{
                                                            color: cyanBlue,
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        {row.firmName}
                                                    </div>
                                                </TableCell>
                                                <TableCell align="left">{row.assetsName}</TableCell>
                                                <TableCell>
                                                    <AutoComplete
                                                        name="vender"
                                                        label="Vender"
                                                        placeHolder="Select Vender"
                                                        keyColName="id" value={row.vender}
                                                        options={venderList.vender}
                                                        onChange={this.onAutocompleteChange(row)}
                                                        errorText={row.isChecked && formErrors["venderError"]
                                                            ? formErrors["venderError"] : ""
                                                        }
                                                        isError={row.isChecked && formErrors["venderError"]
                                                            ? formErrors["venderError"] : ""
                                                        }
                                                    />

                                                </TableCell>

                                                <TableCell>
                                                    <TextFieldCompo
                                                        placeHolder="Cost"
                                                        color="primary"
                                                        type="number"
                                                        name="cost"
                                                        id={"id"}
                                                        size="small"
                                                        value={row.cost}
                                                        fullWidth
                                                        onChange={this.changeHandler(row, "cost")}
                                                        errorText={row.isChecked && formErrors["costError"]
                                                            ? formErrors["costError"] : ""
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <>
                                <br />
                                <Grid
                                    item
                                    sm={12}
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    rowSpacing={2}
                                    columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                                    container
                                    justifyContent="right"
                                >
                                    <ButtonCompo
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        name="Approve"
                                        onClick={this.onApprove}
                                    />
                                </Grid>
                            </>


                            {assetsCheckListDetailsPopUpFlag && (
                                <AssetsCheckListDetailsPopUp
                                    open={assetsCheckListDetailsPopUpFlag}
                                    handleCloses={this.toggle}
                                    dataRow={dataRow}
                                />
                            )}
                        </>
                    )
                    }
                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    assetsList: state.assets,
    areaList: state.area,
    outletList: state.outlet,
    regionList: state.region,
    stateList: state.states,
    zoneList: state.zone,
    districtList: state.district,
    talukaList: state.taluka,
    venderList: state.vender,
    beatList: state.beat,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getRegionNew,
    getStateByRegion,
    getZoneByState,
    getDistrictByZone,
    getTalukaByDistrict,
    getAreaByTalukaPostBodyUpdated,
    getOutletByAreaNew,
    getAssets,
    getAssetsRequestDetailsByAssetsAndRequestStatus,
    getAssetsRequestDetailsByAreaOutletsAndRequestStatus,
    getVender,
    setAssetsRequestDetails,
    getActiveBeatByArea,
    getOutletByBeatIdsPost,
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AssetsImplementationForm);

