import CloseIcon from "@mui/icons-material/Close";
import { Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import DynamicTable from "../../../components/Comman/RT/MaterialUIRTTable";
import DynamicDetails from "../../../components/dynamicscreens/DynamicDetails";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const OutletListTable = ({
  handleCloses,
  open,
  columns,
  rows,
 
  areaName = "",
  salesTeamName = "",
  beatName = "",
}) => {
  const handleClose = () => {
    handleCloses();
  };

  return (
    <>
      <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="lg"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Outlet List
          </BootstrapDialogTitle>
          <DialogContent dividers>
          <DynamicDetails DataToSet={[
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    labelXS: 12,
                    labelSM: 1.5,
                    labelMD: 1.5,
                    labelLG: 1.5,
                    valueXS: 12,
                    valueSM: 10.5,
                    valueMD: 10.5,
                    valueLG: 10.5,
                    label: "Area",
                    value: areaName,
                  },
                  {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    labelXS: 12,
                    labelSM: 6,
                    labelMD: 6,
                    labelLG: 6,
                    valueXS: 12,
                    valueSM: 6,
                    valueMD: 6,
                    valueLG: 6,
                    label: "Sales Executive Name ",
                    value: salesTeamName,
                  }, {
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    labelXS: 12,
                    labelSM: 4,
                    labelMD: 4,
                    labelLG: 4,
                    valueXS: 12,
                    valueSM: 8,
                    valueMD: 8,
                    valueLG: 8,
                    label: "Beat Name",
                    value: beatName,
                  }
                ]} />
        

            <DynamicTable
              data={rows}
              tableHead={columns}
              showPegination={false}
              isActionColActive={false}
            />
            <br />
          </DialogContent>
        </BootstrapDialog>
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OutletListTable);
