import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
    timeSlot: [],
};
let URL = endpoints.timeSlot;
const timeSlotSlice = createSlice({
    name: "timeSlot",
    initialState,
    reducers: {
        timeSlotSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                timeSlot: row,

            };
        },

        resetState: (state = cloneDeep(initialState), action) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const { timeSlotSuccess, resetState } = timeSlotSlice.actions;

export default timeSlotSlice.reducer;

export const getTimeSlot = () => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL + '?sort={"insertDateTime": "DESC"}',
        }).then(({ data, success }) => {
            if (success) {
                const { content } = data.data;
                const row = content.map((timeSlotObject, index) => {
                    let timeSlotData = {
                        index: index + 1,
                        id: timeSlotObject.id === null ? "" : timeSlotObject.id,
                        fromTime: timeSlotObject.fromTime === null ? "" : timeSlotObject.fromTime,
                        toTime: timeSlotObject.toTime === null ? "" : timeSlotObject.toTime,
                        isActive: timeSlotObject.isActive === null ? "" : timeSlotObject.isActive,
                    };
                    return timeSlotData;
                });
                dispatch(timeSlotSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};
