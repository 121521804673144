import { Grid, TextField } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import endpoint from "../../../config/endpoints";
import * as myConstClass from "../../../config/messageconstant";
import { apiGetWithoutHeader } from "../../../utils/api_service";
import { showLoador1, showNotification } from "../../Landing/Landing.slice";
import * as msgConstant from "../../../config/messageconstant";

class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    enterOtpFlag: false,
    externalToken: false,
    userName: "",
    enterPassWord: false,
    enterOtp: "",
    confirmPassword: "",
    newPassword: "",
    formErrors: [],
    token: "",
  };

  changedHandler = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  generateOtp = () => {
    const { userName } = this.state;
    const { showLoador1, showNotification } = this.props;
    if (this.handleUserNameValidation()) {
      if (navigator.onLine) {
        showLoador1({ loador: true });
        apiGetWithoutHeader({
          url: endpoint.forgotPassword + "/generate-otp?userName=" + userName,
        }).then(({ data, success }) => {
          showLoador1({ loador: false });
          if (success) {
            if (!data.error) {
              this.setState({
                enterOtpFlag: true,
                enterPassWord: false,
              });
            } else {
              let formErrors = {};
              let formIsValid = true;
              formIsValid = false;
              formErrors["userNameErr"] = data.message;
              this.setState({ formErrors: formErrors });
            }
          } else {
            showNotification({
              msg: myConstClass.serverMsg,
              severity: "error",
            });
          }

          return success;
        });
      } else {
        this.props.showNotification({
          msg: msgConstant.noInternetMsg,
          severity: "error",
        });
      }
    }
  };
  validateOtp = () => {
    const { enterOtp, userName } = this.state;
    const { showLoador1, showNotification } = this.props;
    if (this.handleOTPValidation()) {
      if (navigator.onLine) {
        showLoador1({ loador: true });
        apiGetWithoutHeader({
          url:
            endpoint.forgotPassword +
            "/validate-otp?userName=" +
            userName +
            "&" +
            "otp=" +
            enterOtp,
        }).then(({ data, success }) => {
          showLoador1({ loador: false });
          if (success) {
            if (!data.error) {
              this.setState({
                token: data.externalToken,
                enterPassWord: true,
              });
            } else {
              if (data.error) {
                showNotification({ msg: data.errorMessage, severity: "error" });
              }
            }
          } else {
          }

          return success;
        });
      } else {
        this.props.showNotification({
          msg: msgConstant.noInternetMsg,
          severity: "error",
        });
      }
    }
  };

  handleUserNameValidation() {
    const { userName, enterOtp } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (userName === "") {
      formIsValid = false;
      formErrors["userNameErr"] = myConstClass.userNameEmptyMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  handleOTPValidation() {
    const { enterOtp } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (enterOtp === "") {
      formIsValid = false;
      formErrors["otpEmptyErr"] = myConstClass.otpEmptyMsg;
    } else if (!/^\d{6}$/.test(enterOtp)) {
      formIsValid = false;
      formErrors["otpFormatErr"] = myConstClass.otpFormatMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  passwordFormValidation(name) {
    const { newPassword, confirmPassword } = this.state;

    let formErrors = {};
    let formIsValid = true;

    if (newPassword === "") {
      formIsValid = false;
      formErrors["enterPasswordErr"] = myConstClass.enterPasswordMsg;
    }

    if (confirmPassword === "") {
      formIsValid = false;
      formErrors["confirmPasswordErr"] = myConstClass.confirmPasswordMsg;
    }
    if (
      confirmPassword !== "" &&
      newPassword !== "" &&
      confirmPassword != newPassword
    ) {
      formIsValid = false;
      formErrors["confirmPasswordErr"] =
        myConstClass.confirmPasswordIncorrectMsg;
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  submitHandler = () => {
    const { token, confirmPassword } = this.state;
    const { showLoador1, showNotification } = this.props;
    if (navigator.onLine) {
      if (this.passwordFormValidation()) {
        showLoador1({ loador: true });
        apiGetWithoutHeader({
          url:
            endpoint.forgotPassword +
            "/update-password?token=" +
            token +
            "&password=" +
            confirmPassword,
        }).then(({ data, success }) => {
          showLoador1({ loador: false });
          if (success) {
            if (!data.error) {
              showNotification({ msg: "Password changed successfully" });
              window.location.replace("/login");
            }
          } else {
            showNotification({
              msg: myConstClass.serverMsg,
              severity: "error",
            });
          }

          return success;
        });
      } else {
        this.props.showNotification({
          msg: msgConstant.noInternetMsg,
          severity: "error",
        });
      }
    }
  };

  onCancel = () => {
    window.location.replace("/login");
  };
  changeUserName = () => {
    this.setState({
      enterOtpFlag: false,
      enterOtp: "",
    });
  };
  render() {
    const {
      userName,
      enterOtp,
      newPassword,
      confirmPassword,
      enterOtpFlag,
      enterPassWord,
    } = this.state;

    const {
      otpEmptyErr,
      otpFormatErr,
      userNameErr,
      enterPasswordErr,
      confirmPasswordErr,
      incorrectPasswordErr,
    } = this.state.formErrors;

    return (
      <>
        <div align="center">
          <div className="new_content">
            <div className="dash_login">
              <Grid item container justifyContent="space-between">
                <Grid item md={4}></Grid>
                <Grid item md={4}>
                  <div className="lgn_bx">
                    <h3 className="lgn_title">Forgot Password</h3>

                    <div className="lgn_container">
                      {!enterPassWord && (
                        <div className="user_lgn">
                          <div className="user_label">
                            Enter Your Username :
                          </div>
                          <div className="user_field">
                            <TextField
                              placeholder=""
                              color="primary"
                              name="userName"
                              size="small"
                              value={userName}
                              onChange={this.changedHandler}
                              fullWidth
                              disabled={enterOtpFlag ? true : false}
                              error={userNameErr ? true : false}
                              helperText={userNameErr ? userNameErr : " "}
                            />
                          </div>
                        </div>
                      )}

                      {!enterOtpFlag && (
                        <div className="lgn_btn">
                          <button
                            type="button"
                            className="register_btn sendOtp"
                            onClick={this.generateOtp}
                          >
                            Send OTP
                          </button>

                          <button
                            type="button"
                            className="register_btn sendOtp"
                            onClick={this.onCancel}
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                      {enterOtpFlag && (
                        <>
                          {!enterPassWord && (
                            <>
                              <div className="user_lgn">
                                <div className="user_label">*Enter OTP :</div>
                                <div className="user_field">
                                  <TextField
                                    color="primary"
                                    name="enterOtp"
                                    size="small"
                                    value={enterOtp}
                                    onChange={this.changedHandler}
                                    fullWidth
                                    error={
                                      otpEmptyErr || otpFormatErr ? true : false
                                    }
                                    helperText={
                                      otpEmptyErr
                                        ? otpEmptyErr
                                        : otpFormatErr
                                        ? otpFormatErr
                                        : " "
                                    }
                                  />
                                </div>
                              </div>

                              <button
                                type="button"
                                className="register_btn sendOtp"
                                onClick={this.validateOtp}
                              >
                                Submit
                              </button>
                              <button
                                type="button"
                                className="register_btn sendOtp"
                                onClick={this.generateOtp}
                              >
                                Resend OTP
                              </button>
                              <button
                                type="button"
                                className="register_btn sendOtp"
                                onClick={this.changeUserName}
                              >
                                Change Username
                              </button>
                              <button
                                type="button"
                                className="register_btn sendOtp"
                                onClick={this.onCancel}
                              >
                                Cancel
                              </button>
                            </>
                          )}
                          {enterPassWord && (
                            <>
                              <div className="user_lgn">
                                <div className="user_label">
                                  *Enter Password :
                                </div>
                                <div className="user_field">
                                  <TextField
                                    color="primary"
                                    name="newPassword"
                                    type="password"
                                    size="small"
                                    value={newPassword}
                                    onChange={this.changedHandler}
                                    onPaste={(e) => {
                                      e.preventDefault();
                                      return false;
                                    }}
                                    onCopy={(e) => {
                                      e.preventDefault();
                                      return false;
                                    }}
                                    fullWidth
                                    error={enterPasswordErr ? true : false}
                                    helperText={
                                      enterPasswordErr ? enterPasswordErr : " "
                                    }
                                  />
                                </div>
                              </div>
                              <div className="user_lgn">
                                <div className="user_label">
                                  *Confirm Password :
                                </div>
                                <div className="user_field">
                                  <TextField
                                    color="primary"
                                    name="confirmPassword"
                                    size="small"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={this.changedHandler}
                                    fullWidth
                                    onPaste={(e) => {
                                      e.preventDefault();
                                      return false;
                                    }}
                                    onCopy={(e) => {
                                      e.preventDefault();
                                      return false;
                                    }}
                                    error={
                                      confirmPasswordErr || incorrectPasswordErr
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      confirmPasswordErr
                                        ? confirmPasswordErr
                                        : incorrectPasswordErr
                                        ? incorrectPasswordErr
                                        : " "
                                    }
                                  />
                                </div>
                              </div>

                              <button
                                type="button"
                                className="register_btn sendOtp"
                                onClick={this.submitHandler}
                              >
                                Save
                              </button>
                              <button
                                type="button"
                                className="register_btn sendOtp"
                                onClick={this.onCancel}
                              >
                                Cancel
                              </button>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item md={4}></Grid>
              </Grid>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  passwordData: state.forgotPassword,
});

const mapDispatchToProps = {
  showLoador1,
  showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);
