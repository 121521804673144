// import React, { useEffect, useRef } from "react";

// const GMap = () => {
//   const googleMapRef = useRef(null);
//   let googleMap = null;

//   // list of icons
//   const iconList = {
//     icon1:
//       "https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Flag--Right-Chartreuse.png",
//     icon2:
//       "https://cdn2.iconfinder.com/data/icons/IconsLandVistaMapMarkersIconsDemo/256/MapMarker_Marker_Outside_Chartreuse.png",
//     icon3:
//       "https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Ball-Right-Azure.png",
//     icon4:
//       "https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Marker-Outside-Pink.png",
//   };

//   // list of the marker object along with icon
//   const markerList = [
//     {
//       lat: 20.007357,
//       lng: 73.792992,
//       icon: iconList.icon2,
//     },
//     {
//       lat: 20.001468,
//       lng: 73.783508,
//       icon: iconList.icon2,
//     },
//     {
//       lat: 20.007376,
//       lng: 73.767365,
//       icon: iconList.icon2,
//     },
//   ];

//   useEffect(() => {
//     googleMap = initGoogleMap();
//     var bounds = new window.google.maps.LatLngBounds();
//     markerList.map((x) => {
//       const marker = createMarker(x);
//       bounds.extend(marker.position);
//     });
//     googleMap.fitBounds(bounds); // the map to contain all markers
//     const flightPlanCoordinates = [
//       {
//         lat: 20.007357,
//         lng: 73.792992,
//       },
//       {
//         lat: 20.001468,
//         lng: 73.783508,
//       },
//       {
//         lat: 20.007376,
//         lng: 73.767365,
//       },
//     ];
//     const flightPath = new google.maps.Polyline({
//       path: flightPlanCoordinates,
//       geodesic: true,
//       strokeColor: "#0000FF",
//       strokeOpacity: 1.0,
//       strokeWeight: 2,
//     });
//     flightPath.setMap(googleMap);
//   }, []);

//   // initialize the google map
//   const initGoogleMap = () => {
//     return new window.google.maps.Map(googleMapRef.current, {
//       center: { lat: -34.397, lng: 150.644 },
//       zoom: 8,
//       onClick:onMarkerClick,
//     });
//   };
// const onMarkerClick=()=>{
//   alert("hiu")
// }
//   // create marker on google map
//   const createMarker = (markerObj) =>
//     new window.google.maps.Marker({
//       position: { lat: markerObj.lat, lng: markerObj.lng },
//       map: googleMap,
//       label: {
//         text: "5409 Madison St",
//         color: "#C70E20",
//         fontWeight: "bold",
//       },

//       icon: {
//         url: markerObj.icon,
//         // set marker width and height
//         scaledSize: new window.google.maps.Size(30, 30),
//       },
//     });

//   return <div ref={googleMapRef} style={{ width: 600, height: 500 }} />;
// };

// export default GMap;
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
  Polyline,
} from "@react-google-maps/api";
import { Box, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";

import { useEffect, useState } from "react";
import "./styles.css";
import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import { connect } from "react-redux";
import moment from "moment";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import {
  getMapData,
  getTimelineForSalesExecutive,
} from "../../Slice/location.slice";
import { noInternetMsg, serverMsg } from "../../config/messageconstant";
import dayjs from "dayjs";
import Timeline from "./Timeline";
const GMap = ({
  showNotification,
  getMapData,

  location,
  showLoador,
  getTimelineForSalesExecutive,
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDvKXkZ-S03qxR-8b8HLnCOKr36eYRxC2k",
  });
  const params = useParams();
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();
  const [markers, setMarkers] = useState([]);
  const [timelineData, setTimelineData] = useState([]);

  useEffect(() => {
    // async () => {
    getMapList(moment().format("DD-MM-YYYY"));

    // };

    // return () => {
    //   // this now gets called when the component unmounts
    // };
  }, []);
  const getMapList = (dataObj) => {
    if (navigator.onLine) {
      showLoador({ loador: true });
      getMapData({
        salesTeamId: params.id,
        date: dataObj,
      }).then(({ response, success }) => {
        showLoador({ loador: false });
        if (!success) {
          setMarkers([]);
          showNotification({ msg: serverMsg, severity: "error" });
        } else {

          setDate(dataObj);

          if (
            response &&
            response.outletList &&
            response.outletList.length != 0
          ) {
            console.log(response.outletList);
            setMarkers(response.outletList);
          }
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  const onMapLoad = (map) => {
    setMapRef(map);
    const bounds = new google.maps.LatLngBounds();
    markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
  };

  const handleMarkerClick = (index, id, lat, lng, address, mainId, amount) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ index, id, address, amount });
    setIsOpen(true);
  };
  const onDateChange = (dateObj) => {
    setDate(dateObj);
    getMapList(dateObj.format("DD-MM-YYYY"));
  };
  const tabValueHandler = (value) => {
    let filterDaylist = location.salesTeamTimeline.filter(
      (row) => row.index == value
    );
    if (filterDaylist.length !== 0) {
      getMapList(filterDaylist[0].date);
    }
  };

  return (
    <Paper
      sx={{
        p: 2,
        borderRadius: 2,
      }}
      elevation={0}
    >
      {/* <BasicDatePicker
        color="primary"
        name="dob"
        size="small"
        value={date}
        maxDate={dayjs()}
        fullWidth
        onChange={onDateChange}
      /> */}
      <Grid
        style={{ height: "90vh" }}
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        <Grid container xs={12} sm={8} md={8} lg={8}>
          <Grid container xs={12} sm={12} md={12} lg={12}>
            {!isLoaded ? (
              <h1>Loading...</h1>
            ) : (
              <GoogleMap
                mapContainerClassName="map-container"
                onLoad={onMapLoad}
                onClick={() => setIsOpen(false)}
              >
                {markers.map(
                  ({ index, address, lat, lng, id, icon, amount }, ind) => (
                    <Marker
                      key={ind}
                      position={{ lat, lng }}
                      onClick={() => {
                        handleMarkerClick(
                          index,
                          ind,
                          lat,
                          lng,
                          address,
                          id,
                          amount
                        );
                      }}
                      icon={{
                        url: icon,
                        scaledSize: new google.maps.Size(50, 50),
                      }}
                    >
                      {console.log(infoWindowData)}
                      {isOpen && infoWindowData?.id === ind && (
                        <InfoWindow
                          onCloseClick={() => {
                            setIsOpen(false);
                          }}
                        >
                          <h3>
                            {infoWindowData.index +
                              "-" +
                              infoWindowData.address +
                              " - " +
                              infoWindowData.amount}
                          </h3>
                        </InfoWindow>
                      )}
                      <Polyline
                        path={
                          location.location.firebaseList
                            ? location.location.firebaseList
                            : []
                        }
                        geodesic={true}
                        options={{
                          strokeColor: "#00008B",
                          strokeOpacity: 0.75,
                          strokeWeight: 2,
                          icons: [
                            {
                              // icon:"https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Marker-Outside-Pink.png",
                              offset: "0",
                              repeat: "20px",
                            },
                          ],
                        }}
                      />
                    </Marker>
                  )
                )}
              </GoogleMap>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          style={{ overflow: "auto", width: "100%" }}
          xs={12}
          sm={4}
          md={4}
          lg={4}
        >
          <Timeline
            saveData={tabValueHandler}
            dateFlag={date}
          />

        </Grid>
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  location: state.location,
});

const mapDispatchToProps = {
  showLoador,
  showNotification,
  getMapData,
  getTimelineForSalesExecutive,
};

export default connect(mapStateToProps, mapDispatchToProps)(GMap);
