import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  vehicle: [],
};
let URL = endpoints.vehicle;
const vehicleSlice = createSlice({
  name: "vehicle",
  initialState,
  reducers: {
    vehicleSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        vehicle: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { vehicleSuccess, resetState } = vehicleSlice.actions;

export default vehicleSlice.reducer;

export const getVehicleNoList = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"insertDateTime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((vehicleObject, index) => {
          let vehicleData = {
            index: index + 1,
            id: vehicleObject.id === null ? "" : vehicleObject.id,
            transporter:
              vehicleObject.transporter === null
                ? ""
                : vehicleObject.transporter.id,
            transporterName:
              vehicleObject.transporter === null
                ? ""
                : vehicleObject.transporter.name,
            name:
              vehicleObject.vehicleNo === null ? "" : vehicleObject.vehicleNo,
            vehicleNo:
              vehicleObject.vehicleNo === null ? "" : vehicleObject.vehicleNo,
            isActive:
              vehicleObject.isActive === null ? "" : vehicleObject.isActive,
          };
          return vehicleData;
        });
        dispatch(vehicleSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const setVehicle =
  ({ row }) =>
  async (dispatch) => {
    dispatch(vehicleSuccess({ row }));
  };
export const getVehicleNoListByStockholder = () => async (dispatch) => {
  try {
    const response = apiGet({
      url:
        URL + "/by-stock-stake-holder-id" + '?sort={"insertDateTime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((vehicleObject, index) => {
          let vehicleData = {
            index: index + 1,
            id: vehicleObject.id === null ? "" : vehicleObject.id,
            transporter:
              vehicleObject.transporter === null
                ? ""
                : vehicleObject.transporter.id,
            transporterName:
              vehicleObject.transporter === null
                ? ""
                : vehicleObject.transporter.name,
            name:
              vehicleObject.vehicleNo === null ? "" : vehicleObject.vehicleNo,
            vehicleNo:
              vehicleObject.vehicleNo === null ? "" : vehicleObject.vehicleNo,
            isActive:
              vehicleObject.isActive === null ? "" : vehicleObject.isActive,
          };
          return vehicleData;
        });
        dispatch(vehicleSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};


export const getUpdatedVehicleNoListByStockholder = () => async (dispatch) => {
  try {
    const response = apiGet({
      // http://localhost:8088/api/distribution/v1/vehicle/by-stock-stake-holder-id-new?sort=%7B%22insertDateTime%22:%20%22DESC%22%7D
      url:
        URL + "/by-stock-stake-holder-id-new" + '?sort={"insertDateTime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((vehicleObject, index) => {
          let vehicleData = {
            index: index + 1,
            id: vehicleObject.id === null ? "" : vehicleObject.id,
            transporter:
              vehicleObject.transporterId === null
                ? ""
                : vehicleObject.transporterId,
            transporterName:
              vehicleObject.transporterName === null
                ? ""
                : vehicleObject.transporterName,
            name:
              vehicleObject.vehicleNo === null ? "" : vehicleObject.vehicleNo,
            vehicleNo:
              vehicleObject.vehicleNo === null ? "" : vehicleObject.vehicleNo,
            isActive:
              vehicleObject.isActive === null ? "" : vehicleObject.isActive,
          };
          return vehicleData;
        });
        dispatch(vehicleSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getActiveVehicleNoListByStockholder = () => async (dispatch) => {
  try {
    const response = apiGet({
      url:
        URL +
        "/by-stock-stake-holder-id-active" +
        '?sort={"insertDateTime": "DESC"}',
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((vehicleObject, index) => {
          let vehicleData = {
            index: index + 1,
            id: vehicleObject.id === null ? "" : vehicleObject.id,
            transporter:
              vehicleObject.transporter === null
                ? ""
                : vehicleObject.transporter.id,
            transporterName:
              vehicleObject.transporter === null
                ? ""
                : vehicleObject.transporter.name,
            name:
              vehicleObject.vehicleNo === null ? "" : vehicleObject.vehicleNo,
            vehicleNo:
              vehicleObject.vehicleNo === null ? "" : vehicleObject.vehicleNo,
            isActive:
              vehicleObject.isActive === null ? "" : vehicleObject.isActive,
          };
          return vehicleData;
        });
        dispatch(vehicleSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
export const getActiveVehicleNoListByStockholderForBilling =
  () => async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/for-billing",
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((vehicleObject, index) => {
            let vehicleData = {
              index: index + 1,
              id: vehicleObject.id === null ? "" : vehicleObject.id,
              transporter:
                vehicleObject.transporter === null
                  ? ""
                  : vehicleObject.transporter.id,
              transporterName:
                vehicleObject.transporter === null
                  ? ""
                  : vehicleObject.transporter.name,
              name:
                vehicleObject.vehicleNo === null ? "" : vehicleObject.vehicleNo,
              vehicleNo:
                vehicleObject.vehicleNo === null ? "" : vehicleObject.vehicleNo,
              isActive:
                vehicleObject.isActive === null ? "" : vehicleObject.isActive,
            };
            return vehicleData;
          });
          dispatch(vehicleSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getActiveVehicleNoListByStockholderNew =
  () => async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-stock-stake-holder-id-active" +
          '?sort={"insertDateTime": "DESC"}',
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((vehicleObject, index) => {
            let vehicleData = {
              index: index + 1,
              id:
                vehicleObject.vehicleNo === null ? "" : vehicleObject.vehicleNo,
              transporter:
                vehicleObject.transporter === null
                  ? ""
                  : vehicleObject.transporter.id,
              transporterName:
                vehicleObject.transporter === null
                  ? ""
                  : vehicleObject.transporter.name,
              name:
                vehicleObject.vehicleNo === null ? "" : vehicleObject.vehicleNo,
              vehicleNo:
                vehicleObject.vehicleNo === null ? "" : vehicleObject.vehicleNo,
              isActive:
                vehicleObject.isActive === null ? "" : vehicleObject.isActive,
            };
            return vehicleData;
          });
          dispatch(vehicleSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
