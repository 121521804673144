// import Box from "@mui/material/Box";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import { visuallyHidden } from "@mui/utils";
// import PropTypes from "prop-types";
// import * as React from "react";
// import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
// import { onlyNumber } from "../../components/Comman/Util/Validations";
// import BasicDatePicker from "../../components/Comman/DatePickerEx";
// import { batchEmptyMsg, dateMsg } from "../../config/messageconstant";

// import dayjs from "dayjs";

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function stableSort(array, comparator) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

// function EnhancedTableHead(props) {
//   const { order, tableHead, orderBy } = props;

//   return (
//     <TableHead>
//       <TableRow>
//         {tableHead.map((headCell) => {
//           if (headCell.showInscreen && headCell.title !== "Action") {
//             if (headCell.name !== "target") {
//               return (
//                 <TableCell
//                   key={headCell.name}
//                   align={"center"}
//                   style={{ minWidth: headCell.width }}
//                 // padding={headCell.disablePadding ? 'none' : 'normal'}
//                 // sortDirection={orderBy === headCell.name ? order : false}
//                 >
//                   {headCell.title}
//                   {orderBy === headCell.name ? (
//                     <Box component="span" sx={visuallyHidden}>
//                       {order === "desc"
//                         ? "sorted descending"
//                         : "sorted ascending"}
//                     </Box>
//                   ) : null}
//                 </TableCell>
//               );
//             } else {
//               return (
//                 <TableCell
//                   key={headCell.name}
//                   align={"center"}
//                   style={{ minWidth: headCell.width }}
//                 >
//                   <TableRow>
//                     <TableCell style={{ width: "1.8%" }}>Primary UOM</TableCell>
//                     {headCell.secondaryQty && (
//                       <>
//                         <TableCell style={{ width: "1%" }}>
//                           Secondary UOM
//                         </TableCell>
//                       </>
//                     )}
//                   </TableRow>
//                 </TableCell>
//               );
//             }
//           }
//           return null;
//         })}
//       </TableRow>
//     </TableHead>
//   );
// }
// function Row({
//   row,
//   index,
//   rowList = [],
//   setList,
//   flag,
//   fieldData,
//   batchFlag,
//   secondaryQtyFlag,
// }) {
//   const onTextFieldDataChange = (dataKey, productId) => (event) => {
//     const { value } = event.target;
//     if (
//       value !== "" ||
//       (value !== "e" && +value >= 0)
//     ) {
//       const ListToSet = rowList.map((rowD) => {
//         let qty = 0;
//         let total = 0;
//         const subListData = rowD.productWithMarginAndMrps.map((productData) => {
//           if (productData.products.id == productId) {
//             productData = {
//               ...productData,
//               [dataKey]: value,
//               batchError:
//                 value > 0 && batchFlag && productData.batchCode == ""
//                   ? true
//                   : false,
//               stockError:
//                 fieldData == "0" && value > 0 && +row.productStock < value
//                   ? 1
//                   : 0,
//               stockAdjust:
//                 fieldData == "2" && +row.productStock > value
//                   ? +row.productStock -
//                   (dataKey == "primaryQty"
//                     ? +productData.qty +
//                     +productData.products.uomConversionValue * +value
//                     : +productData.products.uomConversionValue *
//                     +productData.primaryQty +
//                     +value)
//                   : (dataKey == "primaryQty"
//                     ? +productData.qty +
//                     +productData.products.uomConversionValue * +value
//                     : +productData.products.uomConversionValue *
//                     +productData.primaryQty +
//                     +value) - +row.productStock,
//               stockInOut:
//                 fieldData == "2" &&
//                   +row.productStock <
//                   (dataKey == "primaryQty"
//                     ? +productData.qty +
//                     +productData.products.uomConversionValue * +value
//                     : +productData.products.uomConversionValue *
//                     +productData.primaryQty +
//                     +value)
//                   ? 0
//                   : 1,
//               total: secondaryQtyFlag
//                 ? dataKey == "primaryQty"
//                   ? +productData.qty +
//                   +productData.products.uomConversionValue * +value
//                   : +productData.products.uomConversionValue *
//                   +productData.primaryQty +
//                   +value
//                 : +value,
//             };
//           }
//           qty =
//             qty + fieldData == "0" && +row.productStock < value
//               ? +productData.qty
//               : fieldData == "2" && +row.productStock > value
//                 ? +row.productStock - value
//                 : fieldData == "2" && +row.productStock > value
//                   ? value - +row.productStock
//                   : 0;

//           total = total + +productData.total;
//           return productData;
//         });
//         return {
//           ...rowD,
//           qty: qty,
//           total: total,
//           productWithMarginAndMrps: subListData,
//         };
//       });
//       setList({ row: ListToSet });
//     }
//   };

//   const onTextFieldChange = (dataKey, productId) => (event) => {
//     const { value } = event.target;
//     const ListToSet = rowList.map((rowD) => {
//       const subListData = rowD.productWithMarginAndMrps.map((productData) => {
//         if (productData.products.id == productId) {
//           productData = {
//             ...productData,
//             [dataKey]: value,
//             expDateError: productData.expDate == "" ? true : false,
//             batchError: false,
//           };
//         }

//         return productData;
//       });
//       return {
//         ...rowD,
//         productWithMarginAndMrps: subListData,
//       };
//     });
//     setList({ row: ListToSet });
//   };
//   const changeFromDate = (dataKey, productId) => (date) => {
//     const ListToSet = rowList.map((rowD) => {
//       const subListData = rowD.productWithMarginAndMrps.map((productData) => {
//         if (productData.products.id == productId) {
//           productData = {
//             ...productData,
//             [dataKey]: date,
//             expDateError: false,
//           };
//         }

//         return productData;
//       });
//       return {
//         ...rowD,
//         productWithMarginAndMrps: subListData,
//       };
//     });
//     setList({ row: ListToSet });
//   };

//   return (
//     <TableRow key={row.name}>
//       <TableCell style={{ width: "5%" }}>
//         <center>{index + 1}</center>
//       </TableCell>
//       <TableCell style={{ width: batchFlag ? "25%" : "40%" }}>
//         {row.products != null ? row.products.name : "-"}
//       </TableCell>
//       {flag && (
//         <TableCell align="right" style={{ width: "10%" }}>
//           {Math.floor(row.productStock / row.products.uomConversionValue) +
//             " " +
//             row.products.stakeHolderUom.name +
//             " " +
//             (row.productStock % row.products.uomConversionValue).toFixed(2) +
//             " " +
//             row.products.outletUom.name}
//         </TableCell>
//       )}
//       <TableCell style={{ width: "20%" }}>
//         <TableCell>
//           {" "}
//           <TextFieldCompo
//             placeHolder={"Quantity"}
//             size="small"
//             color="primary"
//             // type="number"
//             id={"1"}
//             value={row.primaryQty}
//             onChange={onTextFieldDataChange("primaryQty", row.products.id)}
//             fullWidth={false}
//             errorText={
//               row.stockError == 1 ? "Quantity should be less than stock" : ""
//             }
//           />
//         </TableCell>
//         {secondaryQtyFlag && (
//           <>
//             <TableCell>
//               {" "}
//               <TextFieldCompo
//                 placeHolder={"Quantity"}
//                 size="small"
//                 color="primary"
//                 type="number"
//                 id={"1"}
//                 value={row.qty}
//                 onChange={onTextFieldDataChange("qty", row.products.id)}
//                 fullWidth={false}
//                 errorText={
//                   row.stockError == 1
//                     ? "Quantity should be less than stock"
//                     : ""
//                 }
//               />
//             </TableCell>
//           </>
//         )}
//       </TableCell>
//       {batchFlag && (
//         <>
//           <TableCell>
//             {" "}
//             <TextFieldCompo
//               placeHolder={"Batch"}
//               size="small"
//               color="primary"
//               id={"1"}
//               value={row.batchCode}
//               onChange={onTextFieldChange("batchCode", row.products.id)}
//               fullWidth={false}
//               errorText={row.batchError ? batchEmptyMsg : ""}
//             />
//           </TableCell>
//           <TableCell>
//             {" "}
//             <BasicDatePicker
//               color="primary"
//               name="mfgDate"
//               size="small"
//               value={row.mfgDate}
//               disabled={false}
//               fullWidth
//               onChange={changeFromDate("mfgDate", row.products.id)}
//             // isError={formErrors.transactionDateError ? true : false}
//             // errorText={
//             //   formErrors.transactionDateError
//             //     ? formErrors.transactionDateError
//             //     : " "
//             // }
//             />
//           </TableCell>
//           <TableCell>
//             {" "}
//             <BasicDatePicker
//               color="primary"
//               name="expDate"
//               size="small"
//               value={row.expDate}
//               disabled={false}
//               fullWidth
//               onChange={changeFromDate("expDate", row.products.id)}
//               isError={row.expDateError ? true : false}
//               errorText={row.expDateError ? dateMsg : " "}
//             />
//           </TableCell>
//         </>
//       )}

//       {fieldData == "2" && (
//         <TableCell align="center" style={{ width: "5%" }}>
//           {row.stockInOut == 0 ? "IN" : "OUT"}
//         </TableCell>
//       )}
//       <TableCell align="right">
//         {secondaryQtyFlag ? row.total : row.primaryQty}
//       </TableCell>
//     </TableRow>
//   );
// }
// EnhancedTableHead.propTypes = {
//   numSelected: PropTypes.number.isRequired,
//   onRequestSort: PropTypes.func.isRequired,
//   onSelectAllClick: PropTypes.func.isRequired,
//   order: PropTypes.oneOf(["asc", "desc"]).isRequired,
//   orderBy: PropTypes.string.isRequired,
//   rowCount: PropTypes.number.isRequired,
// };

// export default function MaterialUITableWithTextField({
//   tableHead = [],
//   setList,
//   rowList = [],
//   data = [],
//   total = 0,
//   flag,
//   fieldData,
//   batchFlag,
//   secondaryQtyFlag,
// }) {
//   const [order, setOrder] = React.useState("asc");
//   const [orderBy, setOrderBy] = React.useState("calories");
//   const [selected, setSelected] = React.useState([]);
//   const [page, setPage] = React.useState(0);
//   const [rowsPerPage, setRowsPerPage] = React.useState(10);

//   const emptyRows =
//     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

//   return (
//     <Box sx={{ width: "100%" }}>
//       <TableContainer sx={{ maxHeight: 500 }}>
//         <Table stickyHeader aria-label="sticky table">
//           <EnhancedTableHead
//             tableHead={tableHead}
//             numSelected={selected.length}
//             order={order}
//             orderBy={orderBy}
//             rowCount={data.length}
//           />
//           <TableBody>
//             {stableSort(data, getComparator(order, orderBy)).map(
//               (row, index) => {
//                 return (
//                   <Row
//                     rowList={rowList}
//                     row={row}
//                     setList={setList}
//                     index={index}
//                     flag={flag}
//                     fieldData={fieldData}
//                     batchFlag={batchFlag}
//                     secondaryQtyFlag={secondaryQtyFlag}
//                   />
//                 );
//               }
//             )}
//             {/* <TableRow >
//             <TableCell colSpan={5}>Total</TableCell>
//               <TableCell>{total}</TableCell>
//             </TableRow> */}
//             {emptyRows > 0 && (
//               <TableRow
//                 style={{
//                   height: 53 * emptyRows,
//                 }}
//               >
//                 <TableCell colSpan={tableHead.length} />
//               </TableRow>
//             )}
//             {data.length === 0 && (
//               <TableRow
//                 style={{
//                   height: 53 * 1,
//                 }}
//               >
//                 <TableCell align="center" colSpan={tableHead.length}>
//                   No Record Found
//                 </TableCell>
//               </TableRow>
//             )}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Box>
//   );
// }

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import * as React from "react";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import { onlyNumber } from "../../components/Comman/Util/Validations";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { batchEmptyMsg, dateMsg } from "../../config/messageconstant";

import dayjs from "dayjs";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, tableHead, orderBy } = props;

  return (
    <TableHead>
      <TableRow>
        {tableHead.map((headCell) => {
          if (headCell.showInscreen && headCell.title !== "Action") {
            if (headCell.name !== "target") {
              return (
                <TableCell
                  key={headCell.name}
                  align={"center"}
                  style={{ minWidth: headCell.width }}
                  // padding={headCell.disablePadding ? 'none' : 'normal'}
                  // sortDirection={orderBy === headCell.name ? order : false}
                >
                  {headCell.title}
                  {orderBy === headCell.name ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableCell>
              );
            } else {
              return (
                <TableCell
                  key={headCell.name}
                  align={"center"}
                  style={{ minWidth: headCell.width }}
                >
                  <TableRow>
                    <TableCell style={{ width: "1.8%" }}>Primary UOM</TableCell>
                    {headCell.secondaryQty && (
                      <>
                        <TableCell style={{ width: "1%" }}>
                          Secondary UOM
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                </TableCell>
              );
            }
          }
          return null;
        })}
      </TableRow>
    </TableHead>
  );
}
function Row({
  row,
  index,
  rowList = [],
  setList,
  flag,
  fieldData,
  batchFlag,
  secondaryQtyFlag,
}) {
  const onTextFieldDataChange = (dataKey, productId) => (event) => {
    const { value } = event.target;
    if (value !== "" || (value !== "e" && +value >= 0)) {
      const ListToSet = rowList.map((rowD) => {
        let qty = 0;
        let total = 0;
        const subListData = rowD.productWithMarginAndMrps.map((productData) => {
          if (productData.products.id == productId) {
            productData = {
              ...productData,
              [dataKey]: value,
              batchError:
                value > 0 && batchFlag && productData.batchCode == ""
                  ? true
                  : false,
              stockError:
                fieldData == "0" && value > 0 && +row.productStock < value
                  ? 1
                  : 0,
              stockAdjust:
                fieldData == "2" && +row.productStock > value
                  ? +row.productStock -
                    (dataKey == "primaryQty"
                      ? +productData.qty +
                        +productData.products.uomConversionValue * +value
                      : +productData.products.uomConversionValue *
                          +productData.primaryQty +
                        +value)
                  : (dataKey == "primaryQty"
                      ? +productData.qty +
                        +productData.products.uomConversionValue * +value
                      : +productData.products.uomConversionValue *
                          +productData.primaryQty +
                        +value) - +row.productStock,
              stockInOut:
                fieldData == "2" &&
                +row.productStock <
                  (dataKey == "primaryQty"
                    ? +productData.qty +
                      +productData.products.uomConversionValue * +value
                    : +productData.products.uomConversionValue *
                        +productData.primaryQty +
                      +value)
                  ? 0
                  : 1,
              total: secondaryQtyFlag
                ? dataKey == "primaryQty"
                  ? +productData.qty +
                    +productData.products.uomConversionValue * +value
                  : +productData.products.uomConversionValue *
                      +productData.primaryQty +
                    +value
                : +value,
            };
          }
          qty =
            qty + fieldData == "0" && +row.productStock < value
              ? +productData.qty
              : fieldData == "2" && +row.productStock > value
              ? +row.productStock - value
              : fieldData == "2" && +row.productStock > value
              ? value - +row.productStock
              : 0;

          total = total + +productData.total;
          return productData;
        });
        return {
          ...rowD,
          qty: qty,
          total: total,
          productWithMarginAndMrps: subListData,
        };
      });
      setList({ row: ListToSet });
    }
  };

  const onTextFieldChange = (dataKey, productId) => (event) => {
    const { value } = event.target;
    const ListToSet = rowList.map((rowD) => {
      const subListData = rowD.productWithMarginAndMrps.map((productData) => {
        if (productData.products.id == productId) {
          productData = {
            ...productData,
            [dataKey]: value,
            expDateError: productData.expDate == "" ? true : false,
            batchError: false,
          };
        }
        return productData;
      });
      return {
        ...rowD,
        productWithMarginAndMrps: subListData,
      };
    });

    setList({ row: ListToSet });
  };
  const changeFromDate = (dataKey, productId) => (event) => {
    const ListToSet = rowList.map((rowD) => {
      const subListData = rowD.productWithMarginAndMrps.map((productData) => {
        if (productData.products.id == productId) {
          productData = {
            ...productData,
            [dataKey]: event.target.value,
            expDateError: false,
          };
        }

        return productData;
      });
      return {
        ...rowD,
        productWithMarginAndMrps: subListData,
      };
    });
    setList({ row: ListToSet });
  };

  return (
    <TableRow key={row.name}>
      <TableCell style={{ width: "5%" }}>
        <center>{index + 1}</center>
      </TableCell>
      <TableCell style={{ width: batchFlag ? "25%" : "40%" }}>
        {row.products != null ? row.products.name : "-"}
      </TableCell>
      {flag && (
        <TableCell align="right" style={{ width: "10%" }}>
          {Math.floor(row.productStock / row.products.uomConversionValue) +
            " " +
            row.products.stakeHolderUom.name +
            " " +
            (row.productStock % row.products.uomConversionValue).toFixed(2) +
            " " +
            row.products.outletUom.name}
        </TableCell>
      )}
      <TableCell style={{ width: "20%" }}>
        <TableCell>
          {" "}
          <input
            className={"customTextfield"}
            placeHolder={"Outlet Rate"}
            value={row.primaryQty}
            name="outletRate"
            onChange={onTextFieldDataChange("primaryQty", row.products.id)}
            type="text"
            id="outletRate"
          ></input>
          {/* <TextFieldCompo
            placeHolder={"Quantity"}
            size="small"
            color="primary"
            // type="number"
            id={"1"}
            value={row.primaryQty}
            onChange={onTextFieldDataChange("primaryQty", row.products.id)}
            fullWidth={false}
            errorText={
              row.stockError == 1 ? "Quantity should be less than stock" : ""
            }
          /> */}
        </TableCell>
        {secondaryQtyFlag && (
          <>
            <TableCell>
              {" "}
              <input
                className={"customTextfield"}
                placeHolder={"Outlet Rate"}
                value={row.qty}
                name="outletRate"
                onChange={onTextFieldDataChange("qty", row.products.id)}
                type="text"
                id="outletRate"
              ></input>
              {/* <TextFieldCompo
                placeHolder={"Quantity"}
                size="small"
                color="primary"
                type="number"
                id={"1"}
                value={row.qty}
                onChange={onTextFieldDataChange("qty", row.products.id)}
                fullWidth={false}
                errorText={
                  row.stockError == 1
                    ? "Quantity should be less than stock"
                    : ""
                }
              /> */}
            </TableCell>
          </>
        )}
      </TableCell>
      {batchFlag && (
        <>
          <TableCell>
            {" "}
            <input
                className={"customTextfield"}
                placeHolder={"Outlet Rate"}
                value={row.batchCode}
                name="outletRate"
                onChange={onTextFieldChange("batchCode", row.products.id)}
                type="text"
                id="outletRate"
              ></input>
            {/* <TextFieldCompo
              placeHolder={"Batch"}
              size="small"
              color="primary"
              id={"1"}
              value={row.batchCode}
              onChange={onTextFieldChange("batchCode", row.products.id)}
              fullWidth={false}
              errorText={row.batchError ? batchEmptyMsg : ""}
            /> */}
          </TableCell>
          <TableCell>
            {" "}
            <input
                className={"customTextfield"}
                placeHolder={"Outlet Rate"}
                value={row.mfgDate}
                name="mfgDate"
                onChange={changeFromDate("mfgDate", row.products.id)}
                type="date"
                id="mfgDate"
              ></input>
            {/* <BasicDatePicker
              color="primary"
              name="mfgDate"
              size="small"
              value={row.mfgDate}
              disabled={false}
              fullWidth
              onChange={changeFromDate("mfgDate", row.products.id)}
              // isError={formErrors.transactionDateError ? true : false}
              // errorText={
              //   formErrors.transactionDateError
              //     ? formErrors.transactionDateError
              //     : " "
              // }
            /> */}
          </TableCell>
          
          <TableCell>
            {" "}
            <input
                className={"customTextfield"}
                placeHolder={"Outlet Rate"}
                value={row.expDate}
                name="expDate"
                onChange={changeFromDate("expDate", row.products.id)}
                type="date"
                id="expDate"
              ></input>
            {/* <BasicDatePicker
              color="primary"
              name="expDate"
              size="small"
              value={row.expDate}
              disabled={false}
              fullWidth
              onChange={changeFromDate("expDate", row.products.id)}
              isError={row.expDateError ? true : false}
              errorText={row.expDateError ? dateMsg : " "}
            /> */}
          </TableCell>
        </>
      )}

      {fieldData == "2" && (
        <TableCell align="center" style={{ width: "5%" }}>
          {row.stockInOut == 0 ? "IN" : "OUT"}
        </TableCell>
      )}
      <TableCell align="right">
        {secondaryQtyFlag ? row.total : row.primaryQty}
      </TableCell>
    </TableRow>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function MaterialUITableWithTextField({
  tableHead = [],
  setList,
  rowList = [],
  data = [],
  dataNew,
  total = 0,
  flag,
  fieldData,
  batchFlag,
  secondaryQtyFlag,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataNew.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            tableHead={tableHead}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            rowCount={dataNew.length}
          />
          <TableBody>
            {stableSort(dataNew, getComparator(order, orderBy)).map(
              (row, index) => {
                return (
                  <Row
                    rowList={rowList}
                    row={row}
                    setList={setList}
                    index={index}
                    flag={flag}
                    fieldData={fieldData}
                    batchFlag={batchFlag}
                    secondaryQtyFlag={secondaryQtyFlag}
                  />
                );
              }
            )}
            {/* <TableRow >
            <TableCell colSpan={5}>Total</TableCell>
              <TableCell>{total}</TableCell>
            </TableRow> */}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={tableHead.length} />
              </TableRow>
            )}
            {data.length === 0 && (
              <TableRow
                style={{
                  height: 53 * 1,
                }}
              >
                <TableCell align="center" colSpan={tableHead.length}>
                  No Record Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
