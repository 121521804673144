import endpoint from "../../config/endpoints";

export const outletTypeJson = {
  showSaveNextBtn: true,
  formPath: "/outlet-type-form",
  apiBaseURL: endpoint.outletType,
  changeExcel: endpoint.exportData,
  screenTitle: "Outlet Type",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Outlet-Type",
  showExcelDownload: true,
  excelFileName: "Outlet-Type",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Outlet Count",
      columnKeyName: "outletCount",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "name",
      isMandatory: true,
      uniqueValidation: true,
    },
    {
      label: "Short Name",
      controlType: "textfield",
      placeHolder: "Short Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "shortName",
      isMandatory: true,
    },
  ],
};
