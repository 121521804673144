import { Grid, Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { batchWiseGRNJSON } from "../../DynamicFormsJson/Transaction/batchWiseGRN";
import { getCategoryForStakeholder } from "../../Slice/category.slice";
import { getItemwiseBatch } from "../../Slice/manageStock.slice";
import { getProductByCategoryForStakeHolder } from "../../Slice/product.slice";
import {
    getGRNHeaderById,
    getGRNHeaderDetailsById,
    setRequestGRNDetails,
} from "../../Slice/requestGRN.slice";
import { ButtonCompo } from "../../components/Comman/Button";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import CheckBoxTable from "../../components/Comman/RT/MaterialUITableWithTextField";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import {
    deleteFailedMsg,
    deleteWarningMsg,
    deletemsg,
    noInternetMsg,
    saveFailedMsg,
    savemsg,
    serverMsg,
    updateData,
} from "../../config/messageconstant";
import { apiDelete, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";

class BatchWiseGRNEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dynamicMasterData: {
                categoryDetail: this.props.categoryList.categoryForStakeholder,
                productDetail: this.props.productList.productByCategoryForStakeholder,
                batchDetail: this.props.batchList.itemwiseBatchData,
            },
            headerData: {},
            DataToSet: [],
        };
    }

    async componentDidMount() {
        const {
            getGRNHeaderDetailsById,
            getGRNHeaderById,
            showLoador,
            showNotification,
            getCategoryForStakeholder,
        } = this.props;
        if (navigator.onLine) {
            if (this.props.params.id) {
                showLoador({ loador: true });
                await getGRNHeaderById({
                    requestGRNHeaderId: this.props.params.id,
                }).then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                    } else {
                        this.setState({
                            headerData: response,
                            DataToSet: [
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "GRN Number",
                                    value: response.grnGvnNo !== null ? response.grnGvnNo : "",
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Date",
                                    value: response.date !== null ? response.date : "",
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Taxable Amount",
                                    value:
                                        response.taxableAmt !== null ? response.taxableAmt : "",
                                },
                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Total Amount",
                                    value: response.totalAmt !== null ? response.totalAmt : "",
                                },

                                {
                                    xs: 12,
                                    sm: 6,
                                    md: 6,
                                    lg: 6,
                                    label: "Total Tax",
                                    value:
                                        response.totalAmt !== null && response.taxableAmt !== null
                                            ? (+response.totalAmt - +response.taxableAmt).toFixed(2)
                                            : "",
                                },
                            ],
                        });
                    }
                });

                showLoador({ loador: true });
                await getCategoryForStakeholder().then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    } else {
                    }
                });

                showLoador({ loador: true });
                await getGRNHeaderDetailsById({
                    requestGRNHeaderId: this.props.params.id,
                }).then(({ response, success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                    } else {
                    }
                });
            }
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (
            this.props.categoryList.categoryForStakeholder !==
            nextProps.categoryList.categoryForStakeholder
        ) {
            this.setState({
                dynamicMasterData: {
                    ...this.state.dynamicMasterData,
                    categoryDetail: nextProps.categoryList.categoryForStakeholder,
                },
            });
        }
        if (this.props.productList !== nextProps.productList) {
            if (
                this.props.productList?.productByCategoryForStakeholder !==
                nextProps.productList?.productByCategoryForStakeholder
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        productDetail:
                            nextProps.productList?.productByCategoryForStakeholder,
                    },
                });
            }
        }
        if (this.props.batchList !== nextProps.batchList) {
            if (
                this.props.batchList.itemwiseBatchData !==
                nextProps.batchList.itemwiseBatchData
            ) {
                this.setState({
                    dynamicMasterData: {
                        ...this.state.dynamicMasterData,
                        batchDetail: nextProps.batchList.itemwiseBatchData,
                    },
                });
            }
        }
    }

    getListById = async (data) => {
        const {
            getProductByCategoryForStakeHolder,
            getItemwiseBatch,
            showLoador,
            showNotification,
        } = this.props;
        if (navigator.onLine) {
            if (data.category) {
                showLoador({ loador: true });
                await getProductByCategoryForStakeHolder({
                    categoryId: data.category,
                }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    }
                });
            }
            if (data.product && this.state.headerData) {
                showLoador({ loador: true });
                await getItemwiseBatch({
                    orderDate: this.state.headerData.date,
                    productId: data.product,
                }).then(({ success }) => {
                    showLoador({ loador: false });
                    if (!success) {
                        showNotification({ msg: serverMsg, severity: "error" });
                    } else {
                    }
                });
            }
        } else {
            showNotification({
                msg: noInternetMsg,
                severity: "error",
            });
        }
    };

    getDataForDisabled = (value) => {
        const { dynamicMasterData } = this.state;
        const { requestGRNList } = this.props;
        let productList = dynamicMasterData["productDetail"]
            ? dynamicMasterData["productDetail"].filter((row) => row.id == value)
            : [];
        let batchList = dynamicMasterData["batchDetail"]
            ? dynamicMasterData["batchDetail"].filter((row) => row.id == value)
            : [];
        if (batchList.length != 0) {
            let newBatchList = requestGRNList.requestGRNHeaderDetails.filter(
                (row) => row.batchCode == batchList[0].id
            );
            if (newBatchList.length != 0) {
                let newBatchQty = 0;
                newBatchList.map((row) => {
                    newBatchQty = newBatchQty + +row.qty;
                });
                return {
                    secondaryClosingQty: (
                        +batchList[0].secondaryClosingQty - newBatchQty
                    ).toString(),
                    batchQty: batchList.length != 0 && batchList[0].secondaryClosingQty,
                };
            }
            return {
                secondaryClosingQty:
                    batchList.length != 0 && batchList[0].secondaryClosingQty,
                batchQty: batchList.length != 0 && batchList[0].secondaryClosingQty,
            };
        }
        if (productList.length != 0) {
            return {
                rate: productList.length != 0 && productList[0].rate,
                uom: productList.length != 0 && productList[0].outletUomName,
                uomConversionValue:
                    productList.length != 0 && productList[0].uomConversionValue,
            };
        }
    };

    getDataForCalculate = (value, fieldData) => {
        const { dynamicMasterData } = this.state;
        const { requestGRNList } = this.props;
        let batchList = dynamicMasterData["batchDetail"]
            ? dynamicMasterData["batchDetail"].filter(
                (row) => row.id == fieldData.batch
            )
            : [];
        if (requestGRNList.requestGRNHeaderDetails.length != 0) {
            let newQty = fieldData.batchQty;
            requestGRNList.requestGRNHeaderDetails.map((row) => {
                if (row.batchCode == fieldData.batch) {
                    if (batchList.length != 0 && fieldData.batch) {
                        let newBatchList = batchList.filter(
                            (rowData) => rowData.id == row.batchCode
                        );
                        if (newBatchList.length != 0) {
                            newQty = newQty - +row.qty;
                        }
                    }
                }
            });
            return {
                secondaryClosingQty: (+newQty - +value).toString(),
                secondaryQty: newQty,
            };
        } else if (fieldData.batch) {
            if (batchList.length != 0) {
                return {
                    secondaryClosingQty: (
                        +batchList[0].secondaryClosingQty - value
                    ).toString(),
                    secondaryQty: +batchList[0].secondaryClosingQty,
                };
            }
        }
    };

    onSearchList = (data) => {
        const {
            productList,
            setRequestGRNDetails,
            showNotification,
            requestGRNList,
        } = this.props;
        if (navigator.onLine) {
            let row = [...requestGRNList.requestGRNHeaderDetails];
            let productName = "";
            let rate = 0;
            let mrp = 0;
            let outLetUom = "";
            let stakeHolderUomName = "";
            let uom = "";
            let uomConversionValue = "";
            let cgst = "";
            let cess = "";
            let igst = "";
            let sgst = "";
            let hsnCode = "";
            productList.productByCategoryForStakeholder.filter((rowData) => {
                if (rowData.id == data.product) {
                    productName = rowData.name;
                    rate = rowData.rate;
                    mrp = rowData.mrp;
                    outLetUom = rowData.outletUomName;
                    uom = rowData.outletUom;
                    stakeHolderUomName = rowData.stakeHolderUomName;
                    uomConversionValue = rowData.uomConversionValue;
                    cgst = rowData.cgst;
                    cess = rowData.cess;
                    igst = rowData.igst;
                    sgst = rowData.sgst;
                    hsnCode = rowData.hsnCode;
                }
            });
            let dataToSave = {
                index: row.length + 1,
                productName: productName,
                stakeHolderUomName: stakeHolderUomName,
                product: { id: data.product },
                uom: uom,
                approvedQty: "",
                batchCode: data.batch,
                billDetailId: 0,
                cessAmt: "",
                cessPer: cess,
                cgstAmt: "",
                cgstPer: cgst,
                grnPer: "",
                grnRate: "",
                hsnCode: hsnCode,
                igstAmt: "",
                igstPer: igst,
                sgstPer: sgst,
                mrp: mrp,
                primaryApprovedQty: "",
                secondaryQty: "",
                orderDate: "",
                stockStakeHolder: "",
                type: "",
                rate: rate,
                qty: data.qty,
                taxableAmt: "",
                totalAmt: (+rate * +data.qty).toFixed(2),
                insertedUserId: localStorage.getItem("userId"),
                insertDateTime: "",
                uomConversionValue: uomConversionValue,
            };
            row.push(dataToSave);
            setRequestGRNDetails({
                row: row,
            });
        } else {
            showNotification({ msg: noInternetMsg, severity: "error" });
        }
    };

    onSubmit = () => {
        const { headerData } = this.state;
        const { showNotification, showLoador, requestGRNList } = this.props;
        const data = {
            ...headerData,
            grnGvnDetails: requestGRNList.requestGRNHeaderDetails,
        };
        swal({
            title: "Are you sure?",
            text: updateData,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                if (navigator.onLine) {
                    showLoador({ loador: true });
                    await apiPost({
                        url: endpoint.grnGVNHeader + "/update",
                        postBody: data,
                    }).then(({ success }) => {
                        if (success) {
                            showLoador({ loador: false });
                            showNotification({
                                msg: savemsg,
                            });
                            window.location.replace("/batchwise-grn-list");
                        } else {
                            showNotification({
                                msg: saveFailedMsg,
                                severity: "error",
                            });
                            showLoador({ loador: false });
                        }
                    });
                } else {
                    showNotification({ msg: noInternetMsg, severity: "error" });
                }
            }
        });
    };

    rowDelete = (rowData) => {
        const {
            showNotification,
            showLoador,
            requestGRNList,
            setRequestGRNDetails,
        } = this.props;
        if (rowData.id) {
            swal({
                title: "Are you sure?",
                text: deleteWarningMsg,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(async (willDelete) => {
                if (willDelete) {
                    if (navigator.onLine) {
                        showLoador({ loador: true });
                        await apiDelete({
                            url: endpoint.grnGVNDetail + "/" + rowData.id,
                        }).then(({ data, success }) => {
                            showLoador({ loador: false });
                            if (data.data.error == false) {
                                showNotification({ msg: deletemsg, severity: "success" });
                                const rowList = requestGRNList.requestGRNHeaderDetails.filter(
                                    (row) => row.index != rowData.index
                                );
                                const row = rowList.map((rowListData, index) => {
                                    return { ...rowListData, index: index + 1 };
                                });
                                setRequestGRNDetails({
                                    row: row,
                                });
                            } else {
                                showNotification({ msg: deleteFailedMsg, severity: "error" });
                            }
                        });
                    } else {
                        showNotification({ msg: noInternetMsg, severity: "error" });
                    }
                }
            });
        } else {
            swal({
                title: "Are you sure?",
                text: deleteWarningMsg,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(async (willDelete) => {
                if (willDelete) {
                    const rowList = requestGRNList.requestGRNHeaderDetails.filter(
                        (row) => row.index != rowData.index
                    );
                    const row = rowList.map((rowListData, index) => {
                        return { ...rowListData, index: index + 1 };
                    });
                    setRequestGRNDetails({
                        row: row,
                    });
                }
            });
        }
    };

    render() {
        const { dynamicMasterData, DataToSet } = this.state;
        const { requestGRNList, setRequestGRNDetails } = this.props;
        return (
            <>
                <Paper
                    sx={{
                        p: 2,
                        borderRadius: 0,
                    }}
                    elevation={0}
                >
                    <LandingScreenHeader
                        showTitle={batchWiseGRNJSON.showTitle}
                        screenTitle={"Edit Request Batchwise GRN"}
                        showSaveBtnMain={false}
                        showAddButton={false}
                        showBackButton={true}
                        onCancel={() =>
                            window.location.replace("/batchwise-grn-list")
                        }
                    />
                    <br />
                    <DynamicDetails DataToSet={DataToSet} />

                    <DynamicFormWithoutSave
                        getListById={this.getListById}
                        dynamicMasterData={dynamicMasterData}
                        showTitle={false}
                        screenTitle={batchWiseGRNJSON.screenTitle}
                        searchList={batchWiseGRNJSON.searchList}
                        fieldMeta={batchWiseGRNJSON.fieldMetaDetails}
                        showCancel={batchWiseGRNJSON.showCancel}
                        showBackToList={batchWiseGRNJSON.showBackToList}
                        apiBaseURL={batchWiseGRNJSON.apiBaseURL}
                        saveBtnText={"ADD"}
                        showSaveBtn={true}
                        showSaveBtnMain={false}
                        onSave={this.onSearchList}
                        padding={true}
                        paddingTop={true}
                        resetAfterSave={true}
                        getDataForDisabled={this.getDataForDisabled}
                        getDataForCalculate={this.getDataForCalculate}
                    />

                    <CheckBoxTable
                        tableHead={[
                            {
                                title: "Sr.No.",
                                name: "index",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "center",
                            },
                            {
                                title: "Product Name",
                                name: "productName",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "left",
                            },
                            {
                                title: "UOM",
                                name: "stakeHolderUomName",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "center",
                            },
                            {
                                title: "Batch",
                                name: "batchCode",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "left",
                            },
                            {
                                title: "Rate",
                                name: "rate",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "right",
                            },
                            {
                                title: "Quantity",
                                name: "qty",
                                placeHolder: "Enter Order Quantity",
                                textFieldError: "qtyError",
                                error: "Order Quantity",
                                textField: true,
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "left",
                            },
                            {
                                title: "Total Amount",
                                name: "totalAmt",
                                positionCenter: false,
                                showInscreen: true,
                                alignCenter: "right",
                            },
                            {
                                title: "Action",
                                name: "total",
                                positionCenter: true,
                                showInscreen: true,
                                alignCenter: "center",
                            },
                        ]}
                        data={requestGRNList.requestGRNHeaderDetails}
                        setList={setRequestGRNDetails}
                        isActionColActive={true}
                        isCheckBox={false}
                        showEdit={false}
                        showHeadDelete={true}
                        rowDelete={this.rowDelete}
                    />
                    <br />
                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        rowSpacing={2}
                        columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                        container
                        justifyContent="right"
                    >
                        <ButtonCompo
                            size="medium"
                            type="submit"
                            variant="contained"
                            name="Cancel"
                            onClick={() =>
                                window.location.replace("/batchwise-grn-list")
                            }
                        />
                        &nbsp;&nbsp;
                        <ButtonCompo
                            size="medium"
                            type="submit"
                            variant="contained"
                            name="Update"
                            onClick={this.onSubmit}
                        />
                    </Grid>
                </Paper>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    categoryList: state.category,
    productList: state.product,
    requestGRNList: state.requestGRN,
    batchList: state.manageStockByDate,
});
const mapDispatchToProps = {
    showNotification,
    showLoador,
    getCategoryForStakeholder,
    getProductByCategoryForStakeHolder,
    getItemwiseBatch,
    getGRNHeaderDetailsById,
    getGRNHeaderById,
    setRequestGRNDetails,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(BatchWiseGRNEdit);
