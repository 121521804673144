import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";
import { resetReducList } from "../../CommonActions/User.action";
import { billHistoryEditJSON } from "../../DynamicFormsJson/MastersJSON/billHistoryJson";
import { OrderListFooterJsons } from "../../DynamicFormsJson/Transaction/orderListFooter";
import {
  getReceivedBillHeaderByIdForGenerateBills,
  getReceivedOrderHeader,
  setReceivedOrderBillData,
} from "../../Slice/receivedOrderList.slice";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import CheckBoxTable from "../../components/Comman/RT/MaterialUITableWithTextField";
import { onlyNumber } from "../../components/Comman/Util/Validations";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import endpoint from "../../config/endpoints";
import {
  exportmsg,
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  serverMsg,
  stockMsg,
} from "../../config/messageconstant";
import { apiGet, apiPost, apiPostForDownload } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import StakeholderBillingWithOffer from "./StakeholderBillingWithOffer";
import dayjs from "dayjs";
class ReceivedOrderBillForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataToSet: [],
      billDate: "",
      billDiscountPercentage: "",
      fromOrder: "",
      total: 0,
      taxableAmt: 0,
      totalBillQty: 0,
      totalAmt: 0,
      discountPer: 0,
      flag: false,
      open: false,
      companyFlagOfc: false,
      offerBillData: "",
      stockCurrentList: [],
    };
  }
  async componentDidMount() {
    const { flag } = this.state;
    if (navigator.onLine) {
      if (this.props.params.id) {
        const {
          showLoador,
          showNotification,
          getReceivedBillHeaderByIdForGenerateBills,
          getReceivedOrderHeader,
          setReceivedOrderBillData,
        } = this.props;

        setReceivedOrderBillData({ row: [] });

        const showCompanyFlag = localStorage.getItem("showCompanyFlag");
        if (showCompanyFlag == 0) {
          this.setState({
            companyFlagOfc: true,
          });
        }

        showLoador({ loador: true });
        await getReceivedOrderHeader({
          billHeaderId: this.props.params.id,
        }).then(({ response, success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            this.setState({
              fromOrder: response.fromOrder,
              DataToSet: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Firm Name",
                  value:
                    response.fromOrderFirmName !== null
                      ? response.fromOrderFirmName
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Stock Holder Name",
                  value:
                    response.fromOrderOwnerName !== null
                      ? response.fromOrderOwnerName
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Order Number",
                  value:
                    response.orderNo !== null && response.orderNo !== null
                      ? response.orderNo
                      : "",
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  label: "Order Date",
                  value:
                    response.orderDate !== null && response.orderDate !== null
                      ? response.orderDate
                      : "",
                },
              ],
            });
          }
        });

        let stockData = [];
        let listToAppend = [];

        const stockCalAtBill = localStorage.getItem("stockCalAtBill");

        this.setState({
          flag: stockCalAtBill === "1" ? true : false,
        });

        showLoador({ loador: true });
        getReceivedBillHeaderByIdForGenerateBills({
          billHeaderId: this.props.params.id,
        }).then(({ response, success, finalTotal, changedTotal }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          } else {
            if (response.stockStakeHolderOrderDetails.length > 0) {
              if (stockCalAtBill === "1") {
                showLoador({ loador: true });
                apiGet({
                  url:
                    endpoint.manageStockHeader + "/get-stock-by-current-date",
                }).then(({ data, success }) => {
                  showLoador({ loador: false });
                  if (success) {
                    let i;
                    let j;
                    let listLength =
                      response.stockStakeHolderOrderDetails.length;
                    let productStockLength = data.data.productStocks.length;
                    for (i = 0; i < listLength; i++) {
                      for (j = 0; j < productStockLength; j++) {
                        if (
                          response.stockStakeHolderOrderDetails[i].product.id ==
                          data.data.productStocks[j].product.id
                        ) {
                          let newObject = {
                            ...response.stockStakeHolderOrderDetails[i],
                            stockError: 0,
                            productStock:
                              data.data.productStocks[
                                j
                              ].primaryClosingQty.toFixed(2),
                            isDisable:
                              response.stockStakeHolderOrderDetails[i].billQty >
                              data.data.productStocks[j].primaryClosingQty
                                ? true
                                : false,
                            qty:
                              data.data.productStocks[j].primaryClosingQty < 0
                                ? 0
                                : data.data.productStocks[j].primaryClosingQty <
                                  response.stockStakeHolderOrderDetails[i]
                                    .billQty
                                ? data.data.productStocks[j].primaryClosingQty
                                : response.stockStakeHolderOrderDetails[i]
                                    .billQty,

                            total: (
                              response.stockStakeHolderOrderDetails[i].rate *
                              (data.data.productStocks[j].primaryClosingQty < 0
                                ? 0
                                : data.data.productStocks[j].primaryClosingQty <
                                  response.stockStakeHolderOrderDetails[i]
                                    .billQty
                                ? data.data.productStocks[j].primaryClosingQty
                                : response.stockStakeHolderOrderDetails[i]
                                    .billQty)
                            ).toFixed(2),
                            remainingStock:
                              data.data.productStocks[j].primaryClosingQty < 0
                                ? data.data.productStocks[j].primaryClosingQty
                                : // : data.data.productStocks[j]
                                  //   .primaryClosingQty == 0
                                  //   ? 0
                                  //   :
                                  (
                                    +data.data.productStocks[j]
                                      .primaryClosingQty -
                                    +response.stockStakeHolderOrderDetails[i]
                                      .qty
                                  ).toFixed(2),
                          };
                          data.data.productStocks[j].primaryClosingQty =
                            data.data.productStocks[j].primaryClosingQty -
                            (data.data.productStocks[j].primaryClosingQty < 0
                              ? 0
                              : data.data.productStocks[j].primaryClosingQty <
                                response.stockStakeHolderOrderDetails[i].billQty
                              ? data.data.productStocks[j].primaryClosingQty
                              : response.stockStakeHolderOrderDetails[i]
                                  .billQty);
                          listToAppend.push(newObject);
                          break;
                        }
                      }
                    }
                    stockData = data.data.productStocks.map((stockObject) => {
                      let dataToReturn = {
                        productId: stockObject.product.id,
                        stock: stockObject.primaryClosingQty,
                      };
                      return dataToReturn;
                    });
                    localStorage.setItem(
                      "stockList",
                      JSON.stringify(stockData)
                    );
                    this.setState({ stockCurrentList: stockData });
                    setReceivedOrderBillData({ row: listToAppend });
                  } else {
                    showNotification({
                      msg: serverMsg,
                      severity: "error",
                    });
                  }
                });
              }
            }

            let total = 0;
            let totalAmt = 0;
            let totaltaxableAmt = 0;
            let totalBillQty = 0;
            response.stockStakeHolderOrderDetails.map((rowData) => {
              totalBillQty = totalBillQty + +rowData.qty;
              totaltaxableAmt = totaltaxableAmt + +rowData.taxableAmt;
              total = total + +rowData.totalAmt;
              totalAmt = totalAmt + +rowData.totalAmt;
            });
            this.setState({
              totalBillQty: totalBillQty,
              taxableAmt: totaltaxableAmt,
              totalAmt: totalAmt,
              total: total,
            });
          }
        });
      }
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  getDataForDisabled = (value) => {
    const discount = +value;
    let discountAmt = (this.state.total * +discount) / 100;
    this.setState({
      totalAmt: this.state.total - +discountAmt,
      total: this.state.total,
      discountPer: value,
    });

    return {};
  };

  setReceivedOrderBillList = ({ row }) => {
    const { setReceivedOrderBillData } = this.props;
    const { discountPer } = this.state;
    let dataTotal = 0;
    const rowlist = row.map((rowData) => {
      const objTotal = +rowData.qty * +rowData.rate;

      rowData = {
        ...rowData,
        total:
          rowData.itemDiscPer > 0
            ? (
                objTotal -
                (+rowData.qty * +rowData.rate * +rowData.itemDiscPer) / 100
              ).toFixed(2)
            : (+rowData.qty * +rowData.rate).toFixed(2),
      };
      dataTotal = dataTotal + +rowData.total;
      return rowData;
    });
    const discount = discountPer ? discountPer : 0;
    let discountAmt = (dataTotal * +discount) / 100;
    this.setState({
      totalAmt: dataTotal - +discountAmt,
      total: dataTotal,
    });
    setReceivedOrderBillData({ row: rowlist });
  };

  generateExcel = (data) => {
    const { showLoador, showNotification } = this.props;
    console.log(this.props.params.id);
    showLoador({ loador: true });
    apiPostForDownload({
      url:
        endpoint.stockStakeholderOrderHeader +
        "/pending-order-and-bill-by-id-excel",
      postBody: {
        id: this.props.params.id,
      },
    }).then(({ data, success }) => {
      showLoador({ loador: false });
      const blb = new Blob([data], { type: "text/plain" });
      const reader = new FileReader();
      reader.addEventListener("loadend", (e) => {
        if (success) {
          var blob = new Blob([data], {
            type: "application/xls",
          });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Report_" + new Date() + ".xls";
          link.click();
          showNotification({
            msg: exportmsg,
          });
        }
      });
      reader.readAsText(blb);
    });
  };

  onSubmit = (data) => {
    const { receivedOrderBillList, showNotification } = this.props;
    const { flag, stockCurrentList } = this.state;
    console.log(receivedOrderBillList);
    let billFlag = 0;
    receivedOrderBillList.billDetailsById.map((billObject) => {
      if (billObject.qty > 0) {
        billFlag++;
      }
    });
    if (billFlag > 0) {
      let error = false;
      const rowList = receivedOrderBillList.billDetailsById.map(
        (rowObj, index) => {
          if (
            rowObj.qty.toString() == "" ||
            rowObj.freeQty.toString() == "" ||
            rowObj.itemDiscPer.toString() == ""
          ) {
            error = true;
          }
          if (
            // (rowObj.qty.toString() != "" &&
            //   ((rowObj.qty.toString() == "0" && !flag) ||
            //     !onlyNumber.test(rowObj.qty.toString()))) ||
            +rowObj.qty.toString() > +rowObj.billQty.toString() ||
            (+rowObj.productStock.toString() > 0 &&
              +rowObj.qty.toString() > +rowObj.productStock.toString() &&
              flag) ||
            (rowObj.freeQty.toString() != "" &&
              !onlyNumber.test(rowObj.freeQty.toString())) ||
            (rowObj.itemDiscPer.toString() != "" &&
              +rowObj.itemDiscPer.toString() > 100)
          ) {
            error = true;
          }
          rowObj = {
            ...rowObj,
            qtyError:
              rowObj.qty.toString() == ""
                ? 1
                : rowObj.qty.toString() != "" &&
                  !onlyNumber.test(rowObj.qty.toString())
                ? 2
                : rowObj.qty.toString() != "" &&
                  +rowObj.qty.toString() > +rowObj.billQty.toString()
                ? 3
                : rowObj.qty.toString() != "" && +rowObj.qty.toString() <= 0
                ? 4
                : rowObj.qty.toString() != "" &&
                  flag &&
                  +rowObj.productStock.toString() > 0 &&
                  +rowObj.qty.toString() > +rowObj.productStock.toString()
                ? 5
                : 0,
            // freeQtyError:
            //   rowObj.freeQty.toString() == ""
            //     ? 1
            //     : rowObj.freeQty.toString() != "" &&
            //       !onlyNumber.test(rowObj.freeQty.toString())
            //     ? 2
            //     : 0,
            // discountError:
            //   rowObj.itemDiscPer.toString() == ""
            //     ? 1
            //     : rowObj.itemDiscPer.toString() != "" &&
            //       +rowObj.itemDiscPer.toString() > 100
            //     ? 2
            //     : 0,
            qty: flag && +rowObj.productStock.toString() < 0 ? 0 : +rowObj.qty,
            total:
              flag && +rowObj.productStock.toString() < 0
                ? 0
                : (+rowObj.total).toFixed(2),
            taxableAmt:
              flag && +rowObj.productStock.toString() < 0
                ? 0
                : (+rowObj.taxableAmt).toFixed(2),
            totalAmt:
              flag && +rowObj.productStock.toString() < 0
                ? 0
                : (+rowObj.totalAmt).toFixed(2),
          };
          return rowObj;
        }
      );
      this.props.setReceivedOrderBillData({
        row: rowList,
      });
      this.setState({
        billDate: data.date,
        billDiscountPercentage: data.discount,
      });
      console.log(error);
      if (!error) {
        let billDataToSave = {
          ...receivedOrderBillList.billHeaderById,
        };
        let billObjectToSave = receivedOrderBillList.billDetailsById.map(
          (billObject) => {
            billObject = {
              ...billObject,
              product: { id: billObject.product.id },
            };
            return billObject;
          }
        );
        billDataToSave = {
          ...billDataToSave,
          stockStakeHolderOrderDetails: billObjectToSave,
        };
        let row = [];
        row.push(billDataToSave);

        let dataToSave = {
          dtoStockStakeHolderOrderDetails: billObjectToSave,
          productAndStocks: stockCurrentList,
        };
        this.onSave(dataToSave, data.date, data.discount);
      }
    } else {
      showNotification({ msg: stockMsg, severity: "error" });
    }
  };
  onSave = (beatNewObjectToSave, billDate, billDiscountPercentage) => {
    const { showLoador, showNotification } = this.props;
    const { fromOrder } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiPost({
        url:
          endpoint.stockStakeholderBillHeader +
          "/generate-with-applied-offer?billDate=" +
          dayjs().format("DD-MM-YYYY") +
          "&billDiscountPercentage=0" +
          "&fromOrder=" +
          fromOrder,
        postBody: beatNewObjectToSave,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          let newObjectToSave = {
            ...data.data.stockStakeHolderBillsAfterApplyingOffers,
            stockStakeHolderBillDetails:
              data.data.stockStakeHolderBillsAfterApplyingOffers.stockStakeHolderBillDetails.map(
                (orderObject) => {
                  let productWithoutBillObject =
                    data.data.stockStakeHolderBillHeader.stockStakeHolderBillDetails.filter(
                      (row) => row.id == orderObject.id
                    );
                  if (productWithoutBillObject.length !== 0) {
                    orderObject = {
                      ...orderObject,
                      taxableAmtWithoutOffer:
                        productWithoutBillObject[0].taxableAmt,
                      totalAmtWithoutOffer:
                        productWithoutBillObject[0].totalAmt,
                    };
                    return orderObject;
                  } else {
                    orderObject = {
                      ...orderObject,
                      taxableAmtWithoutOffer: 0.0,
                      totalAmtWithoutOffer: 0.0,
                    };
                    return orderObject;
                  }
                }
              ),
          };
          let newData = {
            ...data.data,
            stockStakeHolderBillsAfterApplyingOffers: newObjectToSave,
          };
          this.setState({ offerBillData: newData });
          this.toggle();
          showNotification({ msg: savemsg });
          // this.props.navigate(-1);
        } else {
          showNotification({ msg: saveFailedMsg, severity: "error" });
        }
        return success;
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  toggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  cancelHandler = () => {
    this.props.navigate(-1);
    localStorage.removeItem("stockList");
  };
  render() {
    const {
      DataToSet,
      dynamicMasterData,
      total,
      totalAmt,
      open,
      flag,
      offerBillData,
      companyFlagOfc,
      taxableAmt,
      totalBillQty,
    } = this.state;
    const { setReceivedOrderBillData, receivedOrderBillList } = this.props;
    return (
      <>
        {!open && (
          <>
            <Paper
              sx={{
                p: 2,
                borderRadius: 2,
              }}
              elevation={0}
            >
              <LandingScreenHeader
                showTitle={billHistoryEditJSON.showTitle}
                screenTitle={"Generate Bills"}
                showSaveBtnMain={false}
                showPdfDownload={false}
                showAddButton={false}
                showBackButton={true}
                onCancel={this.cancelHandler}
              />
              <br />
              <DynamicDetails DataToSet={DataToSet} />
              <DynamicFormWithoutSave
                showBackToList={false}
                dynamicMasterData={dynamicMasterData}
                showSaveBtn={OrderListFooterJsons.showSaveBtn}
                showTitle={OrderListFooterJsons.showTitle}
                screenTitle={OrderListFooterJsons.screenTitle}
                fieldMeta={OrderListFooterJsons.fieldMeta}
                showCancel={OrderListFooterJsons.showCancel}
                apiBaseURL={OrderListFooterJsons.apiBaseURL}
                showSaveBtnMain={true}
                showSaveNextBtn={OrderListFooterJsons.showSaveNextBtn}
                saveBtnText={companyFlagOfc ? "Excel" : "Proceed"}
                onSave={companyFlagOfc ? this.generateExcel : this.onSubmit}
                // getDataForCalculate={this.getDataForCalculate}
                getDataForDisabled={this.getDataForDisabled}
              />
              <br />
              <CheckBoxTable
                tableHead={
                  flag
                    ? [
                        {
                          title: "Sr.No.",
                          name: "index",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "center",
                        },
                        {
                          title: "Product Name",
                          name: "name",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                        },
                        {
                          title: "UOM",
                          name: "stakeHolderUom",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "center",
                        },
                        {
                          title: "Rate",
                          name: "rate",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "right",
                        },
                        {
                          title: "Product Stock",
                          name: "productStock",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "right",
                        },

                        {
                          title: "Order Quantity",
                          name: "billQty",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "center",
                        },
                        {
                          title: "Bill Quantity",
                          name: "qty",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "center",
                        },
                        {
                          title: "Remaining Stock",
                          name: "remainingStock",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "center",
                        },
                        // {
                        //   title: "Free Quantity",
                        //   name: "freeQty",
                        //   positionCenter: false,
                        //   showInscreen: true,
                        //   alignCenter: "center",
                        // },

                        // {
                        //   title: "Discount % On Item",
                        //   name: "itemDiscPer",
                        //   positionCenter: false,
                        //   showInscreen: true,
                        //   alignCenter: "center",
                        // },
                        {
                          title: "Total Amount",
                          name: "total",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "right",
                        },
                      ]
                    : [
                        {
                          title: "Sr.No.",
                          name: "index",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "center",
                        },
                        {
                          title: "Product Name",
                          name: "name",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                        },
                        {
                          title: "UOM",
                          name: "stakeHolderUom",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "center",
                        },
                        {
                          title: "Rate",
                          name: "rate",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "right",
                        },
                        {
                          title: "Order Quantity",
                          name: "billQty",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "right",
                        },
                        {
                          title: "Bill Quantity",
                          name: "qty",
                          placeHolder: "Enter Order Quantity",
                          textFieldError: "qtyError",
                          error: "Order Quantity",
                          textField: true,
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "left",
                        },
                        // {
                        //   title: "Free Quantity",
                        //   name: "freeQty",
                        //   placeHolder: "Enter Free Quantity",
                        //   textFieldError: "freeQtyError",
                        //   error: "Free Quantity",
                        //   textField: true,
                        //   positionCenter: false,
                        //   showInscreen: true,
                        //   alignCenter: "left",
                        // },
                        // {
                        //   title: "Discount % On Item",
                        //   name: "itemDiscPer",
                        //   placeHolder: "Enter Discount Percentage",
                        //   textFieldError: "discountError",
                        //   error: "Discount Percentage",
                        //   textField: true,
                        //   positionCenter: false,
                        //   showInscreen: true,
                        //   alignCenter: "left",
                        // },
                        {
                          title: "Total Amount",
                          name: "total",
                          positionCenter: false,
                          showInscreen: true,
                          alignCenter: "right",
                        },
                      ]
                }
                data={receivedOrderBillList.billDetailsById}
                setList={this.setReceivedOrderBillList}
                isActionColActive={false}
                isCheckBox={false}
                showEdit={false}
                showDelete={false}
              />
              <br />
              <DynamicDetails
                DataToSet={[
                  {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    labelXS: 12,
                    labelSM: 3,
                    labelMD: 3,
                    labelLG: 3,
                    valueXS: 12,
                    valueSM: 9,
                    valueMD: 9,
                    valueLG: 9,
                    label: "Total Quantity",
                    value: totalBillQty,
                  },
                  {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    labelXS: 12,
                    labelSM: 3,
                    labelMD: 3,
                    labelLG: 3,
                    valueXS: 12,
                    valueSM: 9,
                    valueMD: 9,
                    valueLG: 9,
                    label: "Taxable Amt",
                    value: taxableAmt.toFixed(2),
                  },

                  {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    labelXS: 12,
                    labelSM: 3,
                    labelMD: 3,
                    labelLG: 3,
                    valueXS: 12,
                    valueSM: 9,
                    valueMD: 9,
                    valueLG: 9,
                    label: "Total Tax",
                    value: (totalAmt - taxableAmt).toFixed(2),
                  },
                  {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    labelXS: 12,
                    labelSM: 3,
                    labelMD: 3,
                    labelLG: 3,
                    valueXS: 12,
                    valueSM: 9,
                    valueMD: 9,
                    valueLG: 9,
                    label: "Total Amt",
                    value: Math.round(totalAmt).toFixed(2),
                  },
                ]}
              />
            </Paper>
          </>
        )}
        {open && (
          <>
            <StakeholderBillingWithOffer
              offerBillOutletData={offerBillData}
              onCancel={this.toggle}
              flag={flag}
            />
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  receivedOrderBillList: state.receivedOrderList,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  setReceivedOrderBillData,
  resetReducList,
  getReceivedBillHeaderByIdForGenerateBills,
  getReceivedOrderHeader,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ReceivedOrderBillForm);
