import React from "react";
import { connect } from "react-redux";
import DynamicFormForDocumentUpload from "../../../components/dynamicscreens/DynamicFormForDocumentUpload";
import { productJson } from "../../../DynamicFormsJson/MastersJSON/product";
import {
  getCategory,
  getSubCategoryByCategoryId,
} from "../../../Slice/category.slice";
import { getUom } from "../../../Slice/uom.slice";
import { getTax } from "../../../Slice/Tax.slice";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { serverMsg, noInternetMsg } from "../../../config/messageconstant";
import { apiGet } from "../../../utils/api_service";

class ProductForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        categoryDetail: this.props.categoryList?.category,
        subCategoryDetail: this.props.categoryList.subCategory,
        stakeHolderUomDetail: this.props.uomList.uom,
        productDetails: this.props.productList?.product,
        taxDetail: this.props.taxList?.tax,
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.categoryList !== nextProps.categoryList) {
      if (
        this.props.categoryList?.category !== nextProps.categoryList?.category
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            categoryDetail: nextProps.categoryList?.category,
          },
        });
      }
    }
    if (this.props.categoryList !== nextProps.categoryList) {
      if (
        this.props.categoryList?.subCategory !==
        nextProps.categoryList?.subCategory
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            subCategoryDetail: nextProps.categoryList.subCategory,
          },
        });
      }
    }
    if (this.props.uomList !== nextProps.uomList) {
      if (this.props.uomList?.uom !== nextProps.uomList?.uom) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stakeHolderUomDetail: nextProps.uomList.uom,
          },
        });
      }
    }

    if (this.props.taxList !== nextProps.taxList) {
      if (this.props.taxList?.tax !== nextProps.taxList?.tax) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            taxDetail: nextProps.taxList.tax,
          },
        });
      }
    }
  }

  async componentDidMount() {
    const { getCategory, showLoador, showNotification, getUom, getTax } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getCategory().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: true });
      await getUom().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });

      showLoador({ loador: true });
      await getTax().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  getListById = async (data) => {
    const { getSubCategoryByCategoryId, showLoador, showNotification } =
      this.props;
    if (data.category) {
      if (navigator.onLine) {
        showLoador({ loador: true });
        await getSubCategoryByCategoryId({ categoryId: data.category.id }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      } else {
        this.props.showNotification({ msg: noInternetMsg, severity: "error" });
      }
    }
  };

  getDataById = ({ id }) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      const response = apiGet({
        url: productJson.apiBaseURL + "/" + id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {
          if (!data.error) {
            let rowData = data.data;
            let dataToSet = {
              ...rowData,
              category:
                rowData.group.subCategory !== null
                  ? rowData.group.subCategory.category
                  : "",
            };
            return { response: dataToSet, success };
          } else {
            showNotification({ msg: serverMsg, severity: "error" });
          }
          return { response: {}, success: false };
        }
        return { response: {}, success: false };
      });
      return response;
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicFormForDocumentUpload
          formPath={productJson.formPath}
          getListById={this.getListById}
          screenTitle={productJson.screenTitle}
          fieldMeta={productJson.fieldMeta}
          dynamicMasterData={dynamicMasterData}
          apiBaseURL={productJson.apiBaseURL}
          showSaveNextBtn={productJson.showSaveNextBtn}
          callGetByIdApi={false}
          getByIdApi={this.getDataById}
          isEditURL={productJson.isEditURL}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  categoryList: state.category,
  uomList: state.uom,
  taxList: state.tax,
});
const mapDispatchToProps = {
  getCategory,
  getUom,
  getTax,
  showLoador,
  getSubCategoryByCategoryId,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductForm);
