import { Box, Grid } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import { LabelCompo } from "./Label";
import {cyanBlue} from "../../config/ColorObj";
export const TitleAndBreadCrum = ({ title }) => {
  return (
    <Box display="flex" justifyContent="flex-start">
      <Box style={{ fontSize: 16 }}>
        <Grid container direction="row" alignItems="center">
          <ListIcon sx={{ fontSize: 30 }} />
          <LabelCompo
            className="text-black"
            style={{ margin: 5, fontSize: "24px", color:cyanBlue }}
            label={title}
          />
        </Grid>
      </Box>
    </Box>
  );
};
