import endpoint from "../../config/endpoints";

export const openingTransactionJson = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.transaction,
  screenTitle: "Opening For Stock-Holder",
  fieldMeta: [
    {
      label: "Area",
      controlType: "autocomplete",
      placeHolder: "Select Area",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "area",
      isMandatory: true,
      isRootLevelKey: false,
    },
    {
      label: "Stock Holder Type",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeHolderTypeDetail",
      dataKey: "stockStakeHolderType",
      getListId: "stockStakeHolderType",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Stock Holder Name",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stakeHolderName",
      dataKey: "stockStakeHolder",
      getListFrom: "stockStakeHolderType",
      getListId: "stockStakeHolder",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Amount",
      controlType: "textfield",
      placeHolder: "Enter Amount",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "amount",
      isMandatory: true,
      autoFocus: true,
    },
    {
      label: "Remark",
      controlType: "textfield",
      placeHolder: "Enter Remark",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "remark",
      isMandatory: false,
    },
  ],
};
