import endpoint from "../../config/endpoints";

export const generatedBillJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.area,
  screenTitle: "Invoice List",
  formPathView: "/generated-bill-list-view",
  formPath: "/genrated-bill-edit",
  searchList: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "toDate",
      onSubmit: "compare",
      onSubmitParameter: "fromDate-ge",
      isMandatory: true,
      onSubmit: "compare",
    },
    // {
    //   label: "Stock Holder",
    //   controlType: "multiSelect",
    //   placeHolder: "Select Stock Holder",
    //   md: 12,
    //   lg: 12,
    //   sm: 12,
    //   xs: 12,
    //   labelSM: 2,
    //   labelMD: 2,
    //   labelLG: 2,
    //   valueSM: 10,
    //   valueMD: 10,
    //   valueLG: 10,
    //   masterName: "stakeholdersDetail",
    //   dataKey: "stackholder",
    //   isRootLevelKey: true,
    //   isMandatory: true,
    // },
  ],
  fieldMetaForBilling: [
    {
      label: "LR/RR/GR No",
      inputType: "number",
      controlType: "textfield",
      placeHolder: "LR/RR/GR No",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "lrRrGrNo",
      isMandatory: false,
    },

    {
      label: "E-Way Bill No",
      inputType: "number",
      controlType: "textfield",
      placeHolder: "E-Way Bill No",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "eWayBillNo",
      isMandatory: true,
    },
  ],
};
