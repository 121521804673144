import endpoint from "../../config/endpoints";

export const bankJson = {
  formPath: "/bank-form",
  apiBaseURL: endpoint.bankDetails,
  screenTitle: "Bank",
  showAddButton: true,
  showPdfDownload: false,
  pdfFileName: "Taluka",
  showExcelDownload: false,
  excelFileName: "Taluka",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Firm Name",
      columnKeyName: "firmName",
      isChecked: true,
    },
    {
      columnDisplayName: "Owner Name",
      columnKeyName: "ownerName",
      isChecked: true,
    },
    {
      columnDisplayName: "Bank Name",
      columnKeyName: "bankName",
      isChecked: true,
    },
    {
      columnDisplayName: "Account Type",
      columnKeyName: "accType",
      isChecked: true,
    },
    {
      columnDisplayName: "Account Number",
      columnKeyName: "accNo",
      isChecked: true,
    },
    {
      columnDisplayName: "IFSC Code",
      columnKeyName: "ifscCode",
      isChecked: true,
    },
    {
      columnDisplayName: "State",
      columnKeyName: "state",
      isChecked: true,
    },
    {
      columnDisplayName: "City",
      columnKeyName: "city",
      isChecked: true,
    },
    {
      columnDisplayName: "Status",
      columnKeyName: "isActive",
      isChecked: true,
    },
  ],
  // fieldMeta: [
  //   {
  //     label: "Stock Holder",
  //     controlType: "autocomplete",
  //     placeHolder: "Select Stock Holder",
  //     md: 6,
  //     lg: 6,
  //     sm: 6,
  //     xs: 12,
  //     masterName: "stakeholdersDetail",
  //     dataKey: "stockHo",
  //     isRootLevelKey: false,
  //     isMandatory: true,
  //   },
  //   {
  //     label: "IFSC Code",
  //     controlType: "textfield",
  //     placeHolder: "IFSC Code",
  //     md: 6,
  //     lg: 6,
  //     sm: 6,
  //     xs: 12,
  //     autoFocus: true,
  //     dataKey: "ifscCode",
  //     isMandatory: true,
  //   },
  //   {
  //     label: "Bank Name",
  //     controlType: "textfield",
  //     placeHolder: "Bank Name",
  //     md: 6,
  //     lg: 6,
  //     sm: 6,
  //     xs: 12,
  //     autoFocus: true,
  //     dataKey: "bankName",
  //     isMandatory: true,
  //   },
  //   {
  //     label: "Account No",
  //     controlType: "textfield",
  //     placeHolder: "Account Number",
  //     inputType: "number",
  //     md: 6,
  //     lg: 6,
  //     sm: 6,
  //     xs: 12,
  //     dataKey: "accNo",
  //     isMandatory: true,
  //   },

  //   {
  //     label: "Account Type",
  //     controlType: "radiogroup",
  //     md: 6,
  //     lg: 6,
  //     sm: 6,
  //     xs: 12,
  //     dataKey: "accType",
  //     defaultValue: "0",
  //     radioGroupItems: [
  //       {
  //         label: "Saving",
  //         value: "0",
  //       },
  //       {
  //         label: "Current",
  //         value: "1",
  //       },
  //     ],
  //     isMandatory: true,
  //   },
  //   {
  //     label: "State",
  //     controlType: "textfield",
  //     placeHolder: "State",
  //     md: 6,
  //     lg: 6,
  //     sm: 6,
  //     xs: 12,
  //     dataKey: "state",
  //     isMandatory: true,
  //   },
  //   {
  //     label: "District",
  //     controlType: "textfield",
  //     placeHolder: "District",
  //     md: 6,
  //     lg: 6,
  //     sm: 6,
  //     xs: 12,
  //     dataKey: "district",
  //     isMandatory: true,
  //   },
  //   {
  //     label: "City/Village",
  //     controlType: "textfield",
  //     placeHolder: "City",
  //     md: 6,
  //     lg: 6,
  //     sm: 6,
  //     xs: 12,
  //     dataKey: "city",
  //     isMandatory: true,
  //   },
  //   {
  //     label: "Address",
  //     controlType: "textfield",
  //     placeHolder: "Address",
  //     md: 12,
  //     lg: 12,
  //     sm: 12,
  //     xs: 12,
  //     dataKey: "address",
  //     isMandatory: true,
  //   },
  // ],

  // fieldMetaForStockHolder: [
  //   {
  //     label: "Bank Name",
  //     controlType: "textfield",
  //     placeHolder: "Bank Name",
  //     md: 6,
  //     lg: 6,
  //     sm: 6,
  //     xs: 12,
  //     autoFocus: true,
  //     dataKey: "bankName",
  //     isMandatory: true,
  //   },
  //   {
  //     label: "Account No",
  //     controlType: "textfield",
  //     placeHolder: "Account Number",
  //     inputType: "number",
  //     md: 6,
  //     lg: 6,
  //     sm: 6,
  //     xs: 12,
  //     dataKey: "accNo",
  //     isMandatory: true,
  //   },
  //   {
  //     label: "IFSC Code",
  //     controlType: "textfield",
  //     placeHolder: "IFSC Code",
  //     md: 6,
  //     lg: 6,
  //     sm: 6,
  //     xs: 12,
  //     autoFocus: true,
  //     dataKey: "ifscCode",
  //     isMandatory: true,
  //   },
  //   {
  //     label: "Account Type",
  //     controlType: "radiogroup",
  //     md: 6,
  //     lg: 6,
  //     sm: 6,
  //     xs: 12,
  //     dataKey: "accType",
  //     defaultValue: "0",
  //     radioGroupItems: [
  //       {
  //         label: "Saving",
  //         value: "0",
  //       },
  //       {
  //         label: "Current",
  //         value: "1",
  //       },
  //     ],
  //     isMandatory: true,
  //   },
  //   {
  //     label: "State",
  //     controlType: "textfield",
  //     placeHolder: "State",
  //     md: 6,
  //     lg: 6,
  //     sm: 6,
  //     xs: 12,
  //     dataKey: "state",
  //     isMandatory: true,
  //   },
  //   {
  //     label: "City",
  //     controlType: "textfield",
  //     placeHolder: "City",
  //     md: 6,
  //     lg: 6,
  //     sm: 6,
  //     xs: 12,
  //     dataKey: "city",
  //     isMandatory: true,
  //   },
  //   {
  //     label: "Address",
  //     controlType: "textfield",
  //     placeHolder: "Address",
  //     md: 6,
  //     lg: 6,
  //     sm: 6,
  //     xs: 12,
  //     dataKey: "address",
  //     isMandatory: true,
  //   },
  // ],
};
