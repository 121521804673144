import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { serverMsg, noInternetMsg } from "../../../config/messageconstant";
import { DistrictJson } from "../../../DynamicFormsJson/MastersJSON/district";
import { getZoneNew } from "../../../Slice/zone.slice";
import { showLoador, showNotification } from "../../Landing/Landing.slice";

class DistrictForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        zoneDetail: this.props.zoneList?.zone,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.zoneList !== nextProps.zoneList) {
      if (this.props.zoneList?.zone !== nextProps.zoneList?.zone) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zone,
          },
        });
      }
    }
  }

  async componentDidMount() {
    const { getZoneNew, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getZoneNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  render() {
    const { dynamicMasterData } = this.state;
    return (
      <>
        <DynamicForm
          screenTitle={DistrictJson.screenTitle}
          fieldMeta={DistrictJson.fieldMeta}
          formPath={DistrictJson.formPath}
          tableColumnsToFilter={DistrictJson.tableColumnsToFilter}
          apiBaseURL={DistrictJson.apiBaseURL}
          dynamicMasterData={dynamicMasterData}
          baseIdColumn={DistrictJson.baseIdColumn}
          showSaveNextBtn={DistrictJson.showSaveNextBtn}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  zoneList: state.zone,

});
const mapDispatchToProps = {
  getZoneNew,
  showLoador,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(DistrictForm);
