import React from "react";
import { connect } from "react-redux";
import { ClaimTypeJson } from "../../../DynamicFormsJson/MastersJSON/claimType";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { ClaimTypeColumns } from "../../../tableColumns/table-columns";
import { getClaimType } from "../../../Slice/claim.slice";

class ClaimTypeList extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { claimTypeList, getClaimType } = this.props;
        return (
            <>
                <DynamicMainScreen
                    formPath={ClaimTypeJson.formPath}
                    screenTitle={ClaimTypeJson.screenTitle}
                    fieldMeta={ClaimTypeJson.fieldMeta}
                    showPdfDownload={false}
                    showExcelDownload={false}
                    pdfFileName={ClaimTypeJson.pdfFileName}
                    excelFileName={ClaimTypeJson.excelFileName}
                    showAddButton={ClaimTypeJson.showAddButton}
                    tableColumnsToFilter={ClaimTypeJson.tableColumnsToFilter}
                    tableColumns={ClaimTypeColumns}
                    tableData={claimTypeList?.claimType}
                    getTableData={getClaimType}
                    apiBaseURL={ClaimTypeJson.apiBaseURL}
                    baseIdColumn={ClaimTypeJson.baseIdColumn}
                    showDeleteIcon={true}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    claimTypeList: state.claim,
});
const mapDispatchToProps = {
    getClaimType,
};
export default connect(mapStateToProps, mapDispatchToProps)(ClaimTypeList);
