import endpoint from "../../config/endpoints";

export const requestGRNJSON = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.grnGVNHeader,
  screenTitle: "Purchase Return",
  formPath: "/purchase-return",
  formPathEdit: "/request-grn-edit",
  formPathView: "/request-grn-view",
  showBackToList: true,
  showTitle: false,
  searchList: [
    {
      label: "From Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "fromDate",
      isMandatory: true,
      onSubmit: "compare",
      onSubmitParameter: "toDate-le",
    },
    {
      label: "To Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 4,
      lg: 4,
      sm: 4,
      xs: 12,
      dataKey: "toDate",
      onSubmit: "compare",
      onSubmitParameter: "fromDate-ge",
      isMandatory: true,
      onSubmit: "compare",
    },
  ],

  fieldMeta: [
    {
      label: "Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "date",
      isMandatory: true,
    },
  ],
  fieldMetaDetails: [
    {
      label: "Category",
      controlType: "autocomplete",
      placeHolder: "Select Category ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "categoryDetail",
      dataKey: "category",
      isRootLevelKey: true,
      isMandatory: true,
      getListFrom: "outlet",
      getListId: "category",
    },
    {
      label: "Product ",
      controlType: "autocomplete",
      placeHolder: "Select Product",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "productDetail",
      dataKey: "product",
      getListFrom: "category",
      isRootLevelKey: true,
      isMandatory: true,
      getData: true,
    },
    {
      label: "Batch",
      controlType: "textfield",
      placeHolder: "Enter Batch",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "batch",
      isMandatory: true,
    },
    {
      label: "Quantity",
      controlType: "textfield",
      placeHolder: "Enter Quantity",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "qty",
      isMandatory: true,
    },
    {
      label: "Rate",
      controlType: "textfield",
      placeHolder: "Enter Rate",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      inputType: "number",
      dataKey: "rate",
      isMandatory: true,
      spValid: "float",
      disableTrue: true,
    },
    {
      label: "UOM",
      controlType: "textfield",
      placeHolder: "Enter Rate",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "uom",
      isMandatory: true,
      disableTrue: true,
    },
    {
      label: "Conversion Value",
      controlType: "textfield",
      placeHolder: "Enter Conversion Value",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "uomConversionValue",
      isMandatory: true,
      disableTrue: true,
    },
    {
      label: "Add To Scrap",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "calStock",
      tableDataKey: "calStock",
      defaultValue: "Yes",
      radioGroupItems: [
        {
          label: "Yes",
          value: "Yes",
        },
        {
          label: "No",
          value: "No",
        },
      ],
      isMandatory: true,
    },
  ],
};
