import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import { compose } from "@reduxjs/toolkit";
import { OutletColumns } from "../../../tableColumns/table-columns";
import { outletJson } from "../../../DynamicFormsJson/MastersJSON/outlet";
import {
  getOutletByUserType,
  getUpdatedOutletListByBeatIds,
} from "../../../Slice/outlet.slice";
import { getRegionNew } from "../../../Slice/region.slice";
import { getStateByRegion } from "../../../Slice/state.slice";
import { getZoneByState } from "../../../Slice/zone.slice";
import { getDistrictByZone } from "../../../Slice/district.slice";
import { getTalukaByDistrict } from "../../../Slice/taluka.slice";
import {
  getAreaByTalukaPostBody,
  getAreaByUserType,
} from "../../../Slice/area.slice";
import { getUpdatedBeatsByAreaIds } from "../../../Slice/beat.slice";
import { showLoador, showNotification } from "../../Landing/Landing.slice";
import { noInternetMsg, serverMsg } from "../../../config/messageconstant";
import endpoint from "../../../config/endpoints";
import { apiGet } from "../../../utils/api_service";
import { withRouter } from "../../../components/withRouter";
import OutletPopupForActiveInactive from "./OutletPopupForActiveInactive";
class outletTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicMasterData: {
        regionDetail: this.props.regionList?.region,
        stateDetail: this.props.stateList?.stateByRegion,
        zoneDetail: this.props.zoneList?.zoneByState,
        districtDetail: this.props.districtList?.districtByZone,
        talukaDetail: this.props.talukaList?.talukaByDistrict,
        areaDetail: this.props.areaList?.areaByTaluka,
        areaByUserTypeDetail: this.props.areaList?.area,
        beatDetail: this.props.beatList?.beat,
      },
      adminFlag: false,
      popupFlag: false,
      outletId: "",
      isActive: "",
    };
  }
  async componentDidMount() {
    const { showNotification } = this.props;
    if (navigator.onLine) {
      const userTypeId = localStorage.getItem("userTypeId");
      if (userTypeId == 1 || userTypeId == 6) {
        this.setState({
          adminFlag: true,
        });
      }

      await this.getList();

    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }
  getList = async () => {
    const { getRegionNew, getAreaByUserType, showLoador, showNotification } = this.props;

    const userTypeId = localStorage.getItem("userTypeId");
    if (userTypeId == 1 || userTypeId == 6) {
      showLoador({ loador: true });
      await getRegionNew().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showLoador({ loador: true });
      await getAreaByUserType().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    }
  };
  componentWillReceiveProps(nextProps) {
    if (this.props.regionList !== nextProps.regionList) {
      if (this.props.regionList?.region !== nextProps.regionList?.region) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            regionDetail: nextProps.regionList?.region,
          },
        });
      }
    }
    if (this.props.stateList !== nextProps.stateList) {
      if (
        this.props.stateList?.stateByRegion !==
        nextProps.stateList?.stateByRegion
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            stateDetail: nextProps.stateList?.stateByRegion,
          },
        });
      }
    }
    if (this.props.zoneList !== nextProps.zoneList) {
      if (
        this.props.zoneList?.zoneByState !== nextProps.zoneList?.zoneByState
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            zoneDetail: nextProps.zoneList?.zoneByState,
          },
        });
      }
    }
    if (this.props.districtList !== nextProps.districtList) {
      if (
        this.props.districtList?.districtByZone !==
        nextProps.districtList?.districtByZone
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            districtDetail: nextProps.districtList?.districtByZone,
          },
        });
      }
    }
    if (this.props.talukaList !== nextProps.talukaList) {
      if (
        this.props.talukaList?.talukaByDistrict !==
        nextProps.talukaList?.talukaByDistrict
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            talukaDetail: nextProps.talukaList?.talukaByDistrict,
          },
        });
      }
    }
    if (this.props.areaList !== nextProps.areaList) {
      if (
        this.props.areaList?.areaByTaluka !== nextProps.areaList?.areaByTaluka
      ) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaDetail: nextProps.areaList?.areaByTaluka,
          },
        });
      }
    }

    if (this.props.areaList !== nextProps.areaList) {
      if (this.props.areaList?.area !== nextProps.areaList?.area) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            areaByUserTypeDetail: nextProps.areaList?.area,
          },
        });
      }
    }

    if (this.props.beatList !== nextProps.beatList) {
      if (this.props.beatList?.beat !== nextProps.beatList?.beat) {
        this.setState({
          dynamicMasterData: {
            ...this.state.dynamicMasterData,
            beatDetail: nextProps.beatList?.beat,
          },
        });
      }
    }
  }
  onSearchData = async (data) => {
    const { getUpdatedOutletListByBeatIds, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      if (data.beat && data.beat != "") {
        let beatJson = {
          beatIds: data.beat,
        };
        showLoador({ loador: true });
        await getUpdatedOutletListByBeatIds({ beatIds: beatJson }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      } else {
        await this.getList();
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  getListById = async (data) => {
    const {
      getStateByRegion,
      getZoneByState,
      getDistrictByZone,
      getTalukaByDistrict,
      getAreaByTalukaPostBody,
      getUpdatedBeatsByAreaIds,
      showLoador,
      showNotification,
    } = this.props;
    if (navigator.onLine) {
      console.log(data);
      if (data.region && data.region != "") {
        showLoador({ loador: true });
        await getStateByRegion({ regionIds: data.region }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.state && data.state != "") {
        showLoador({ loador: true });
        await getZoneByState({ stateIds: data.state }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.zone && data.zone != "") {
        showLoador({ loador: true });
        await getDistrictByZone({ zoneIds: data.zone }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
      if (data.district && data.district != "") {
        showLoador({ loador: true });
        await getTalukaByDistrict({ districtIds: data.district }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.taluka && data.taluka != "") {
        let talukaJson = {
          talukaIds: data.taluka,
        };
        showLoador({ loador: true });
        await getAreaByTalukaPostBody({ talukaIds: talukaJson }).then(
          ({ success }) => {
            showLoador({ loador: false });
            if (!success) {
              showNotification({ msg: serverMsg, severity: "error" });
            }
          }
        );
      }
      if (data.area && data.area != "") {
        let outletJson = {
          areaIds: data.area,
        }
        showLoador({ loador: true });
        await getUpdatedBeatsByAreaIds({ areaIds: outletJson }).then(({ success }) => {
          showLoador({ loador: false });
          if (!success) {
            showNotification({ msg: serverMsg, severity: "error" });
          }
        });
      }
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  rowApply = (row) => {
    console.log(row);
    this.props.navigate("/outlet-language/" + row.id);
  };
  rowStaticUrlMethod = (row) => {
    console.log(row);
    this.setState({
      popupFlag: true,
      outletId: row.id,
      isActive: row.isActive === 1 ? 0 : row.isActive === 0 ? 1 : "",
    });
  };
  handleCloses = () => {
    this.setState({
      popupFlag: false,
    });
  };
  render() {
    const { outletList } = this.props;
    const { dynamicMasterData, adminFlag, popupFlag, outletId, isActive } =
      this.state;
    return (
      <>
        <DynamicMainScreen
          isResetReduceList={false}
          dynamicMasterData={dynamicMasterData}
          onSearchData={this.onSearchData}
          getListById={this.getListById}
          searchList={
            adminFlag
              ? outletJson.searchList
              : outletJson.searchListForDistributor
          }
          formPath={outletJson.formPath}
          screenTitle={outletJson.screenTitle}
          fieldMeta={outletJson.fieldMeta}
          showDeleteIcon={adminFlag ? true : false}
          showPdfDownload={false}
          changeExcelURL={adminFlag ? outletJson.excelUrl : false}
          showExtension={false}
          showExcelDownload={adminFlag ? true : false}
          pdfFileName={adminFlag ? outletJson.pdfFileName : false}
          excelFileName={adminFlag ? outletJson.excelFileName : false}
          showAddButton={outletJson.showAddButton}
          tableColumnsToFilter={outletJson.tableColumnsToFilter}
          tableColumns={OutletColumns}
          tableData={outletList?.outletByUserType}
          getTableDataById={true}
          callApi={false}
          searchButtonGrid={outletJson.searchButtonGrid}
          apiBaseURL={outletJson.apiBaseURL}
          isNote={true}
          isNoteValue={"Add Retail Outlets"}
          getTableData={this.getList}
          showApply={true}
          rowApply={this.rowApply}
          showApplyTitle={"Change Langauge"}
          isActiveURLFalse={true}
          isActiveURLFalseMethod={this.rowStaticUrlMethod}
        />
        {popupFlag && (
          <>
            <OutletPopupForActiveInactive
              open={popupFlag}
              handleCloses={this.handleCloses}
              onSave={this.saveHandler}
              outletId={outletId}
              isActive={isActive}
            />
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  outletList: state.outlet,
  areaList: state.area,
  regionList: state.region,
  stateList: state.states,
  zoneList: state.zone,
  districtList: state.district,
  talukaList: state.taluka,
  beatList: state.beat,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getZoneByState,
  getRegionNew,
  getDistrictByZone,
  getTalukaByDistrict,
  getAreaByTalukaPostBody,
  getStateByRegion,
  getOutletByUserType,
  getUpdatedOutletListByBeatIds,
  getUpdatedBeatsByAreaIds,
  getAreaByUserType,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(outletTable);
