import endpoint from "../../config/endpoints";

export const TagJson = {
  showSaveNextBtn: true,
  formPath: "/tag-form",
  apiBaseURL: endpoint.tag,
  changeExcel: endpoint.exportData,
  screenTitle: "Tag",
  showAddButton: true,
  showPdfDownload: false,
  pdfFileName: "tag",
  showExcelDownload: false,
  excelFileName: "tag",
  excelUrl: "tag-data" + '?sort={"insertDateTime": "DESC"}',
  tableColumnsToFilter: [
    {
      columnDisplayName: "Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Sort Order Number",
      columnKeyName: "sortOrder",
      isChecked: true,
    },
  ],
  fieldMeta: [
    {
      label: "Name",
      controlType: "textfield",
      placeHolder: "Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      autoFocus: true,
      dataKey: "name",
      isMandatory: true,
      uniqueValidation: true,
    },
    {
      label: "Short Name",
      controlType: "textfield",
      placeHolder: "Short Name",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "shortName",
      isMandatory: true,
    },
  ],
};
