import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  currentYearTargetForSales: {},
  salesListFromYearAndMonth: [],
  salesListFromYearAndMonthTotal: 0,
  outletListFromYearAndMonth: [],
  outletListFromYearAndMonthTotal: 0,
  productListFromYearAndMonth: [],
  productListFromYearAndMonthTotal: 0,
  count: {},
};
let URL = endpoints.manager;
let URL1 = endpoints.adminDashboard;
const SalesDashboardSlice = createSlice({
  name: "SalesDashboard",
  initialState,
  reducers: {
    currentYearTargetSuccess: (state = cloneDeep(initialState), action) => {
      const { currentTargetData } = action.payload;
      return {
        ...state,
        currentYearTargetForSales: currentTargetData,
      };
    },
    salesListFromYearAndMonthSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row, total } = action.payload;
      return {
        ...state,
        salesListFromYearAndMonth: row,
        salesListFromYearAndMonthTotal: total,
      };
    },
    outletListFromYearAndMonthSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row, total } = action.payload;
      return {
        ...state,
        outletListFromYearAndMonth: row,
        outletListFromYearAndMonthTotal: total,
      };
    },
    productListFromYearAndMonthSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row, total } = action.payload;
      return {
        ...state,
        productListFromYearAndMonth: row,
        productListFromYearAndMonthTotal: total,
      };
    },
    countSuccess: (state = cloneDeep(initialState), action) => {
      const { countData } = action.payload;
      return {
        ...state,
        count: countData,
      };
    },
  },
});

export const {
  currentYearTargetSuccess,
  outletListFromYearAndMonthSuccess,
  salesListFromYearAndMonthSuccess,
  productListFromYearAndMonthSuccess,
  countSuccess,
} = SalesDashboardSlice.actions;

export default SalesDashboardSlice.reducer;

export const getCurrentYearTargetForSales = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/person-dashboad",
    }).then(({ data, success }) => {
      if (success) {
        const currentTarget = data.data;
        const currentTargetData = {
          yearTargetAmt:
            currentTarget.yearTargetAmt === null
              ? ""
              : currentTarget.yearTargetAmt.toFixed(2),
          monthTragetAmt:
            currentTarget.monthTragetAmt === null
              ? ""
              : currentTarget.monthTragetAmt.toFixed(2),
          salesTeamWithOrderAndTargetAmt:
            currentTarget.salesTeamWithOrderAndTargetAmt === null
              ? ""
              : currentTarget.salesTeamWithOrderAndTargetAmt.actualAmt.toFixed(2),
        };
        dispatch(currentYearTargetSuccess({ currentTargetData }));
        return { response: currentTargetData, success };
      }
      return { response: {}, success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getSalesListFromYearAndMonth =
  ({ month, year, stockStakeHolderTypeId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/with-target-and-actual-amt-by-month-year-stock-stake-holder-type-and-assign-area?month=" +
          month +
          "&year=" +
          year +
          "&stockStakeHolderTypeId=" +
          stockStakeHolderTypeId,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((salesObject) => {
            finalTotal = finalTotal + salesObject.actualAmt;
          });
          const row = data.data.map((salesObject, index) => {
            let salesListData = {
              index: index + 1,
              id: salesObject.id === null ? "" : salesObject.id,
              name: salesObject.name === null ? "" : salesObject.name,
              targetAmt:
                salesObject.targetAmt === null
                  ? ""
                  : salesObject.targetAmt.toFixed(2),
              actualAmt:
                salesObject.actualAmt === null
                  ? ""
                  : salesObject.actualAmt.toFixed(2),
              contribution: (
                (salesObject.actualAmt / finalTotal) *
                100
              ).toFixed(2),
              divisional:
                salesObject.targetAmt == 0
                  ? "-"
                  : (
                      (salesObject.actualAmt / salesObject.targetAmt) *
                      100
                    ).toFixed(2),
            };
            return salesListData;
          });
          dispatch(
            salesListFromYearAndMonthSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getOutletListFromYearAndMonth =
  ({ month, year }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/top-outlet-order-value-by-month-year-assign-area?month=" +
          month +
          "&year=" +
          year,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((outletObject) => {
            finalTotal = finalTotal + outletObject.orderValue;
          });
          const row = data.data.map((outletObject, index) => {
            let outletListData = {
              index: index + 1,
              id: outletObject.id === null ? "" : outletObject.id,
              firmName:
                outletObject.firmName === null ? "" : outletObject.firmName,
              ownerName:
                outletObject.ownerName === null ? "" : outletObject.ownerName,
              beat: outletObject.beat === null ? "" : outletObject.beat,
              area:
                outletObject.beat !== null && outletObject.beat.area !==null &&  outletObject.beat.area.name? outletObject.beat.area.name :"" ,
              salesExecutiveName:
                outletObject.salesTeam !== null &&
                outletObject.salesTeam.name
                  ?outletObject.salesTeam.name
                  : "",
              actualSale:
                outletObject.orderValue === null
                  ? ""
                  : outletObject.orderValue.toFixed(2),
              percentage:
                outletObject.percentage === null ? "" : outletObject.percentage,
              contribution: (
                (outletObject.orderValue / finalTotal) *
                100
              ).toFixed(2),
            };
            return outletListData;
          });
          dispatch(
            outletListFromYearAndMonthSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getProductListFromYearAndMonth =
  ({ month, year }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL1 +
          "/top-product-order-value-by-month-year-assign-area?month=" +
          month +
          "&year=" +
          year,
      }).then(({ data, success }) => {
        if (success) {
          let finalTotal = 0;
          data.data.map((productObject) => {
            finalTotal = finalTotal + productObject.orderVale;
          });
          const row = data.data.map((productObject, index) => {
            let productListData = {
              index: index + 1,
              id: productObject.id === null ? "" : productObject.id,
              name: productObject.name === null ? "" : productObject.name,
              code: productObject.code === null ? "" : productObject.code,
              actualSale:
                productObject.orderVale === null
                  ? ""
                  : productObject.orderVale.toFixed(2),
              contribution: (
                (productObject.orderVale / finalTotal) *
                100
              ).toFixed(2),
            };
            return productListData;
          });
          dispatch(
            productListFromYearAndMonthSuccess({
              row,
              total: finalTotal.toFixed(2),
            })
          );
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getCount = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL1 + "/sales-excutive-and-check-in-count-of-assign-area",
    }).then(({ data, success }) => {
      if (success) {
        const count = data.data;
        const countData = {
          id:count.id === null ? "" :count.id,
          salesExcutiveCount:
           count.salesExcutiveCount === null
              ? ""
              :count.salesExcutiveCount,
          checkInCount:
           count.checkInCount === null ? "" :count.checkInCount,
        };
        dispatch(countSuccess({ countData }));
        return { response: countData, success };
      }
      return { response: {}, success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};
