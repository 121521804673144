import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicTable from "../../components/Comman/RT/MaterialUIRTTable";
import { ButtonCompo } from "../../components/Comman/Button";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { classes, children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const SubmitPopUp = ({
    onCancel,
    open,
    columns,
    tableData,
    onSubmit
}) => {

    const [totalOfQty, setTotalOfQty] = React.useState("");

    const handleClose = () => {
        onCancel();
    };
    React.useEffect(() => {
        console.log(tableData);
        if (tableData.length !== 0) {
            let totalQty = 0;
            tableData.map((obj) => {
                totalQty = totalQty + +obj.qty
            })
            setTotalOfQty(totalQty);
        }

    }, []);


    return (
        <>
            <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    fullWidth
                    maxWidth="lg"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleClose}
                    >
                        Manual Order For Admin
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <DynamicTable
                            data={tableData}
                            tableHead={columns}
                            showPegination={false}
                            isActionColActive={false}
                        />
                        <br />
                        <>
                            <DynamicDetails
                                DataToSet={[
                                    {
                                        xs: 12,
                                        sm: 4,
                                        md: 4,
                                        lg: 4,
                                        label: "Total Quantity",
                                        value: totalOfQty,
                                    },
                                ]}
                            />
                            <Grid container spacing={1}>
                                <Grid
                                    container
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    sx={{ justifyContent: "flex-end" }}
                                >
                                    <ButtonCompo
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        name={"Submit"}
                                        onClick={onSubmit}
                                    />
                                </Grid>
                            </Grid>
                        </>

                    </DialogContent>
                </BootstrapDialog>
            </Paper>
        </>
    );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SubmitPopUp);
