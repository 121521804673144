import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { ButtonCompo } from "../../components/Comman/Button";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import endpoint from "../../config/endpoints";
import {
  jsonMsg,
  noInternetMsg,
  saveFailedMsg,
  savemsg,
  saveWarningMsg,
} from "../../config/messageconstant";
import { apiGet, apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { generatedBillJSON } from "../../DynamicFormsJson/MastersJSON/generatedBill";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const EWayPopUp = ({
  handleCloses,
  open,
  popUpData,
  showLoador,
  showNotification,
}) => {
  const [name, setName] = React.useState("");
  const [formErrors, setFormErrors] = React.useState({});

  React.useEffect(() => { }, []);
  const handleClose = () => {
    handleCloses();
  };

  const onSubmit = (data) => {
    let vehicleData = {
      ...JSON.parse(popUpData.vehicleNo),
      lrRrGrNo: data.lrRrGrNo ? data.lrRrGrNo : "",
      eWayBillNo: data.eWayBillNo ? data.eWayBillNo : "",
    };

    let dataToSave = {
      ...popUpData,
      vehicleNo: JSON.stringify(vehicleData),
    };
    console.log(dataToSave.id);
    if (navigator.onLine) {
      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          showLoador({ loador: true });
          apiGet({
            url:
              endpoint.stockStakeholderBillHeader +
              "/update-vehicle-no?id=" + dataToSave.id + "&vehicleNo=" + JSON.stringify(vehicleData),
          }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success) {
              showNotification({
                msg: savemsg,
              });
              window.location.reload();
            } else {
              showNotification({
                msg: saveFailedMsg,
                severity: "error",
              });
            }
          });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  return (
    <>
      <Paper sx={{ p: 12, pt: 8, borderRadius: 8 }} elevation={0}>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="xl"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            <DynamicFormWithoutSave
              showBackToList={false}
              showSaveBtn={true}
              showSaveBtnMain={false}
              showTitle={generatedBillJSON.showTitle}
              screenTitle={"E-Way Bill"}
              fieldMeta={generatedBillJSON.fieldMetaForBilling}
              showCancel={generatedBillJSON.showCancel}
              apiBaseURL={generatedBillJSON.apiBaseURL}
              showSaveNextBtn={generatedBillJSON.showSaveNextBtn}
              saveBtnText={"Submit"}
              onSave={onSubmit}
            />
          </BootstrapDialogTitle>
        </BootstrapDialog>
      </Paper>
    </>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = { showNotification, showLoador };

export default connect(mapStateToProps, mapDispatchToProps)(EWayPopUp);
