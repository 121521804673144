import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  orderHistory: [],
  orderHistoryById: [],
};
let URL = endpoints.outletOrderHeader;
let URL1 = endpoints.outletOrderDetail;
let URL2 = endpoints.adminDashboard;
const orderHistorySlice = createSlice({
  name: "orderHistory",
  initialState,
  reducers: {
    orderHistorySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        orderHistory: row,
        isFetch: true,
      };
    },

    orderHistoryByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        orderHistoryById: row,
        isFetch: true,
      };
    },
    orderHeaderByIdSuccess: (state = cloneDeep(initialState), action) => {
      const { orderHeaderData } = action.payload;
      return {
        ...state,
        orderHeaderById: orderHeaderData,
        isFetch: true,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});
export const {
  orderHistorySuccess,
  resetState,
  orderHistoryByIdSuccess,
  orderHeaderByIdSuccess,
  billHeaderIdSuccess,
} = orderHistorySlice.actions;
export default orderHistorySlice.reducer;
export const getTodaysOrder = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/todays-order",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((orderHistoryObject, index) => {
          let billCount = 0;
          orderHistoryObject.outletOrderDetails.map((orderDetailObject) => {
            if (
              orderDetailObject.isBillGenerated == 1 ||
              orderDetailObject.isBillGenerated == 2
            ) {
              billCount++;
            }
          });
          let orderHistoryData = {
            index: index + 1,
            id: orderHistoryObject.id === null ? "" : orderHistoryObject.id,
            outLet:
              orderHistoryObject.outLet === null
                ? ""
                : orderHistoryObject.outLet,
            outletName:
              orderHistoryObject.outLet === null
                ? ""
                : orderHistoryObject.outLet.firmName,
            beatName:
              orderHistoryObject.outLet === null
                ? ""
                : orderHistoryObject.outLet.beat.beatName,
            orderNo:
              orderHistoryObject.orderNo === null
                ? ""
                : orderHistoryObject.orderNo,
            orderDate:
              orderHistoryObject.orderDate === null
                ? ""
                : orderHistoryObject.orderDate,
            type:
              orderHistoryObject.type === null
                ? ""
                : orderHistoryObject.type === 0
                  ? orderHistoryObject.personName === null
                    ? ""
                    : orderHistoryObject.personName
                  : "Manual Order",
            taxableAmt:
              orderHistoryObject.taxableAmt === null
                ? ""
                : orderHistoryObject.taxableAmt.toFixed(2),
            cgstAmt:
              orderHistoryObject.cgstAmt === null
                ? ""
                : orderHistoryObject.cgstAmt.toFixed(2),
            sgstAmt:
              orderHistoryObject.sgstAmt === null
                ? ""
                : orderHistoryObject.sgstAmt.toFixed(2),
            totalAmt:
              orderHistoryObject.totalAmt === null
                ? ""
                : orderHistoryObject.totalAmt.toFixed(2),
            distcountAmt:
              orderHistoryObject.distcountAmt === null
                ? ""
                : orderHistoryObject.distcountAmt.toFixed(2),

            igstAmt:
              orderHistoryObject.igstAmt === null
                ? ""
                : orderHistoryObject.igstAmt.toFixed(2),

            stockStakeHolder:
              orderHistoryObject.stockStakeHolder === null
                ? ""
                : orderHistoryObject.stockStakeHolder,
            insertedUserId:
              orderHistoryObject.insertedUserId === null
                ? ""
                : orderHistoryObject.insertedUserId,
            insertDateTime:
              orderHistoryObject.insertDateTime === null
                ? ""
                : orderHistoryObject.insertDateTime,
            isGenerated: billCount == 0 ? "0" : billCount,
          };

          return orderHistoryData;
        });
        dispatch(orderHistorySuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getTodaysOrderUpdated = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/todays-order-new",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((orderHistoryObject, index) => {
          let orderHistoryData = {
            index: index + 1,
            id: orderHistoryObject.id === null ? "" : orderHistoryObject.id,
            billCount: orderHistoryObject.billCount === null ? "" : orderHistoryObject.billCount,
            outletId:
              orderHistoryObject.outletId === null
                ? ""
                : orderHistoryObject.outletId,
            outletName:
              orderHistoryObject.firmName === null
                ? ""
                : orderHistoryObject.firmName,
            ownerName:
              orderHistoryObject.ownerName === null
                ? ""
                : orderHistoryObject.ownerName,

            stateId: orderHistoryObject.stateId === null
              ? ""
              : orderHistoryObject.stateId,

            stateName: orderHistoryObject.stateName === null
              ? ""
              : orderHistoryObject.stateName,

            beatName:
              orderHistoryObject.beatName === null
                ? ""
                : orderHistoryObject.beatName,

            stateName:
              orderHistoryObject.stateName === null
                ? ""
                : orderHistoryObject.stateName,
            orderNo:
              orderHistoryObject.orderNo === null
                ? ""
                : orderHistoryObject.orderNo,
            orderDate:
              orderHistoryObject.orderDate === null
                ? ""
                : orderHistoryObject.orderDate,
            stockStakeHolderId:
              orderHistoryObject.stockStakeHolderId === null
                ? ""
                : orderHistoryObject.stockStakeHolderId,
            typeForJson: orderHistoryObject.type === null
              ? ""
              : orderHistoryObject.type,
            type:
              orderHistoryObject.type === null
                ? ""
                : orderHistoryObject.type === 0
                  ? orderHistoryObject.personName === null
                    ? ""
                    : orderHistoryObject.personName
                  : "Manual Order",
            taxableAmt:
              orderHistoryObject.taxableAmt === null
                ? ""
                : orderHistoryObject.taxableAmt.toFixed(2),
            cgstAmt:
              orderHistoryObject.cgstAmt === null
                ? ""
                : orderHistoryObject.cgstAmt.toFixed(2),
            sgstAmt:
              orderHistoryObject.sgstAmt === null
                ? ""
                : orderHistoryObject.sgstAmt.toFixed(2),
            totalAmt:
              orderHistoryObject.totalAmt === null
                ? ""
                : orderHistoryObject.totalAmt.toFixed(2),
            distcountAmt:
              orderHistoryObject.distcountAmt === null
                ? ""
                : orderHistoryObject.distcountAmt.toFixed(2),

            igstAmt:
              orderHistoryObject.igstAmt === null
                ? ""
                : orderHistoryObject.igstAmt.toFixed(2),

            remark: orderHistoryObject.remark === null
              ? ""
              : orderHistoryObject.remark,

            saleManagerId: orderHistoryObject.saleManagerId === null
              ? ""
              : orderHistoryObject.saleManagerId,
            insertedUserId:
              orderHistoryObject.insertedUserId === null
                ? ""
                : orderHistoryObject.insertedUserId,
            insertDateTime:
              orderHistoryObject.insertDateTime === null
                ? ""
                : orderHistoryObject.insertDateTime,
            isGenerated:
              orderHistoryObject.billCount == 0
                ? "0"
                : orderHistoryObject.billCount,
          };

          return orderHistoryData;
        });
        dispatch(orderHistorySuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getOrderHistory =
  ({ orderHistoryListData }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/by-outlets-and-date-new",
          postBody: orderHistoryListData,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((orderHistoryObject, index) => {
              let orderHistoryData = {
                index: index + 1,
                id: orderHistoryObject.id === null ? "" : orderHistoryObject.id,
                outLet:
                  orderHistoryObject.outLet === null
                    ? ""
                    : orderHistoryObject.outLet,
                outletName:
                  orderHistoryObject.outLet === null
                    ? ""
                    : orderHistoryObject.outLet.firmName,
                beatName:
                  orderHistoryObject.outLet === null
                    ? ""
                    : orderHistoryObject.outLet.beat.beatName,
                orderNo:
                  orderHistoryObject.orderNo === null
                    ? ""
                    : orderHistoryObject.orderNo,
                orderDate:
                  orderHistoryObject.orderDate === null
                    ? ""
                    : orderHistoryObject.orderDate,
                type:
                  orderHistoryObject.type === null
                    ? ""
                    : orderHistoryObject.type === 0
                      ? orderHistoryObject.personName === null
                        ? ""
                        : orderHistoryObject.personName
                      : "Manual Order",
                taxableAmt:
                  orderHistoryObject.taxableAmt === null
                    ? ""
                    : orderHistoryObject.taxableAmt.toFixed(2),
                cgstAmt:
                  orderHistoryObject.cgstAmt === null
                    ? ""
                    : orderHistoryObject.cgstAmt.toFixed(2),
                sgstAmt:
                  orderHistoryObject.sgstAmt === null
                    ? ""
                    : orderHistoryObject.sgstAmt.toFixed(2),
                totalAmt:
                  orderHistoryObject.totalAmt === null
                    ? ""
                    : orderHistoryObject.totalAmt.toFixed(2),
                distcountAmt:
                  orderHistoryObject.distcountAmt === null
                    ? ""
                    : orderHistoryObject.distcountAmt.toFixed(2),

                igstAmt:
                  orderHistoryObject.igstAmt === null
                    ? ""
                    : orderHistoryObject.igstAmt.toFixed(2),
                insertedUserId:
                  orderHistoryObject.insertedUserId === null
                    ? ""
                    : orderHistoryObject.insertedUserId,
                insertDateTime:
                  orderHistoryObject.insertDateTime === null
                    ? ""
                    : orderHistoryObject.insertDateTime,
                isGenerated:
                  orderHistoryObject.billCount == 0
                    ? "0"
                    : orderHistoryObject.billCount,
              };

              return orderHistoryData;
            });
            dispatch(orderHistorySuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getOrderHistoryUpdated =
  ({ orderHistoryListData }) =>
    async (dispatch) => {
      try {
        const response = apiPost({
          url: URL + "/by-outlets-and-date-new",
          postBody: orderHistoryListData,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((orderHistoryObject, index) => {
              let orderHistoryData = {
                index: index + 1,
                id: orderHistoryObject.id === null ? "" : orderHistoryObject.id,
                billCount: orderHistoryObject.billCount === null ? "" : orderHistoryObject.billCount,
                outletId:
                  orderHistoryObject.outletId === null
                    ? ""
                    : orderHistoryObject.outletId,
                outletName:
                  orderHistoryObject.firmName === null
                    ? ""
                    : orderHistoryObject.firmName,
                ownerName:
                  orderHistoryObject.ownerName === null
                    ? ""
                    : orderHistoryObject.ownerName,

                stateId: orderHistoryObject.stateId === null
                  ? ""
                  : orderHistoryObject.stateId,

                stateName: orderHistoryObject.stateName === null
                  ? ""
                  : orderHistoryObject.stateName,

                beatName:
                  orderHistoryObject.beatName === null
                    ? ""
                    : orderHistoryObject.beatName,
                orderNo:
                  orderHistoryObject.orderNo === null
                    ? ""
                    : orderHistoryObject.orderNo,
                orderDate:
                  orderHistoryObject.orderDate === null
                    ? ""
                    : orderHistoryObject.orderDate,
                stockStakeHolderId:
                  orderHistoryObject.stockStakeHolderId === null
                    ? ""
                    : orderHistoryObject.stockStakeHolderId,
                typeForJson: orderHistoryObject.type === null
                  ? ""
                  : orderHistoryObject.type,
                type:
                  orderHistoryObject.type === null
                    ? ""
                    : orderHistoryObject.type === 0
                      ? orderHistoryObject.personName === null
                        ? ""
                        : orderHistoryObject.personName
                      : "Manual Order",
                taxableAmt:
                  orderHistoryObject.taxableAmt === null
                    ? ""
                    : orderHistoryObject.taxableAmt.toFixed(2),
                cgstAmt:
                  orderHistoryObject.cgstAmt === null
                    ? ""
                    : orderHistoryObject.cgstAmt.toFixed(2),
                sgstAmt:
                  orderHistoryObject.sgstAmt === null
                    ? ""
                    : orderHistoryObject.sgstAmt.toFixed(2),
                totalAmt:
                  orderHistoryObject.totalAmt === null
                    ? ""
                    : orderHistoryObject.totalAmt.toFixed(2),
                distcountAmt:
                  orderHistoryObject.distcountAmt === null
                    ? ""
                    : orderHistoryObject.distcountAmt.toFixed(2),

                igstAmt:
                  orderHistoryObject.igstAmt === null
                    ? ""
                    : orderHistoryObject.igstAmt.toFixed(2),
                insertedUserId:
                  orderHistoryObject.insertedUserId === null
                    ? ""
                    : orderHistoryObject.insertedUserId,
                insertDateTime:
                  orderHistoryObject.insertDateTime === null
                    ? ""
                    : orderHistoryObject.insertDateTime,
                isGenerated:
                  orderHistoryObject.billCount == 0
                    ? "0"
                    : orderHistoryObject.billCount,
              };

              return orderHistoryData;
            });
            dispatch(orderHistorySuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
export const getOrderHistoryBySalesPerson =
  ({ salesTeamIds, fromdate, todate }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/by-sales-teams-and-date?salesTeamIds=" +
            salesTeamIds +
            "&fromDate=" +
            fromdate +
            "&toDate=" +
            todate,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((orderHistoryObject, index) => {
              let billCount = 0;
              orderHistoryObject.outletOrderDetails.map((orderDetailObject) => {
                if (
                  orderDetailObject.isBillGenerated == 1 ||
                  orderDetailObject.isBillGenerated == 2
                ) {
                  billCount++;
                }
              });
              let orderHistoryData = {
                index: index + 1,
                id: orderHistoryObject.id === null ? "" : orderHistoryObject.id,
                outLet:
                  orderHistoryObject.outLet === null
                    ? ""
                    : orderHistoryObject.outLet,
                outletName:
                  orderHistoryObject.outLet === null
                    ? ""
                    : orderHistoryObject.outLet.firmName,
                beatName:
                  orderHistoryObject.outLet === null
                    ? ""
                    : orderHistoryObject.outLet.beat.beatName,
                orderNo:
                  orderHistoryObject.orderNo === null
                    ? ""
                    : orderHistoryObject.orderNo,
                orderDate:
                  orderHistoryObject.orderDate === null
                    ? ""
                    : orderHistoryObject.orderDate,
                type:
                  orderHistoryObject.type === null
                    ? ""
                    : orderHistoryObject.type === 0
                      ? orderHistoryObject.personName === null
                        ? ""
                        : orderHistoryObject.personName
                      : "Manual Order",
                taxableAmt:
                  orderHistoryObject.taxableAmt === null
                    ? ""
                    : orderHistoryObject.taxableAmt.toFixed(2),
                cgstAmt:
                  orderHistoryObject.cgstAmt === null
                    ? ""
                    : orderHistoryObject.cgstAmt.toFixed(2),
                sgstAmt:
                  orderHistoryObject.sgstAmt === null
                    ? ""
                    : orderHistoryObject.sgstAmt.toFixed(2),
                totalAmt:
                  orderHistoryObject.totalAmt === null
                    ? ""
                    : orderHistoryObject.totalAmt.toFixed(2),
                distcountAmt:
                  orderHistoryObject.distcountAmt === null
                    ? ""
                    : orderHistoryObject.distcountAmt.toFixed(2),

                igstAmt:
                  orderHistoryObject.igstAmt === null
                    ? ""
                    : orderHistoryObject.igstAmt.toFixed(2),

                stockStakeHolder:
                  orderHistoryObject.stockStakeHolder === null
                    ? ""
                    : orderHistoryObject.stockStakeHolder,
                insertedUserId:
                  orderHistoryObject.insertedUserId === null
                    ? ""
                    : orderHistoryObject.insertedUserId,
                insertDateTime:
                  orderHistoryObject.insertDateTime === null
                    ? ""
                    : orderHistoryObject.insertDateTime,
                isGenerated: billCount == 0 ? "0" : billCount,
              };

              return orderHistoryData;
            });
            dispatch(orderHistorySuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const getOrderHistoryBySalesPersonUpdated =
  ({ salesTeamIds, fromdate, todate }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL +
            "/by-sales-teams-and-date-new?salesTeamIds=" +
            salesTeamIds +
            "&fromDate=" +
            fromdate +
            "&toDate=" +
            todate,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((orderHistoryObject, index) => {
              let orderHistoryData = {
                index: index + 1,
                id: orderHistoryObject.id === null ? "" : orderHistoryObject.id,
                outLet:
                  orderHistoryObject.outletId === null
                    ? ""
                    : orderHistoryObject.outletId,
                outletName:
                  orderHistoryObject.firmName === null
                    ? ""
                    : orderHistoryObject.firmName,
                billCount: orderHistoryObject.billCount === null ? "" : orderHistoryObject.billCount,


                ownerName:
                  orderHistoryObject.ownerName === null
                    ? ""
                    : orderHistoryObject.ownerName,

                stateId: orderHistoryObject.stateId === null
                  ? ""
                  : orderHistoryObject.stateId,

                stateName: orderHistoryObject.stateName === null
                  ? ""
                  : orderHistoryObject.stateName,

                beatName:
                  orderHistoryObject.beatName === null
                    ? ""
                    : orderHistoryObject.beatName,
                orderNo:
                  orderHistoryObject.orderNo === null
                    ? ""
                    : orderHistoryObject.orderNo,
                orderDate:
                  orderHistoryObject.orderDate === null
                    ? ""
                    : orderHistoryObject.orderDate,
                stockStakeHolderId:
                  orderHistoryObject.stockStakeHolderId === null
                    ? ""
                    : orderHistoryObject.stockStakeHolderId,
                typeForJson: orderHistoryObject.type === null
                  ? ""
                  : orderHistoryObject.type,
                type:
                  orderHistoryObject.type === null
                    ? ""
                    : orderHistoryObject.type === 0
                      ? orderHistoryObject.personName === null
                        ? ""
                        : orderHistoryObject.personName
                      : "Manual Order",
                taxableAmt:
                  orderHistoryObject.taxableAmt === null
                    ? ""
                    : orderHistoryObject.taxableAmt.toFixed(2),
                cgstAmt:
                  orderHistoryObject.cgstAmt === null
                    ? ""
                    : orderHistoryObject.cgstAmt.toFixed(2),
                sgstAmt:
                  orderHistoryObject.sgstAmt === null
                    ? ""
                    : orderHistoryObject.sgstAmt.toFixed(2),
                totalAmt:
                  orderHistoryObject.totalAmt === null
                    ? ""
                    : orderHistoryObject.totalAmt.toFixed(2),
                distcountAmt:
                  orderHistoryObject.distcountAmt === null
                    ? ""
                    : orderHistoryObject.distcountAmt.toFixed(2),

                igstAmt:
                  orderHistoryObject.igstAmt === null
                    ? ""
                    : orderHistoryObject.igstAmt.toFixed(2),
                insertedUserId:
                  orderHistoryObject.insertedUserId === null
                    ? ""
                    : orderHistoryObject.insertedUserId,
                insertDateTime:
                  orderHistoryObject.insertDateTime === null
                    ? ""
                    : orderHistoryObject.insertDateTime,
                isGenerated:
                  orderHistoryObject.billCount == 0
                    ? "0"
                    : orderHistoryObject.billCount,
              };

              return orderHistoryData;
            });
            dispatch(orderHistorySuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const setOrderHistory = () => async (dispatch) => {
  dispatch(orderHistorySuccess({ row: [] }));
};

export const getOrderHistoryById =
  ({ outletOrderHeaderId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url:
            URL1 +
            "/by-outlet-order-header?outletOrderHeaderId=" +
            outletOrderHeaderId,
        }).then(({ data, success }) => {
          if (success) {
            const row = data.data.map((orderHistoryObject, index) => {
              let orderHistoryData = {
                index: index + 1,
                id: orderHistoryObject.id === null ? "" : orderHistoryObject.id,
                productName:
                  orderHistoryObject.product === null
                    ? ""
                    : orderHistoryObject.product.name,
                outletUom:
                  orderHistoryObject.product === null
                    ? ""
                    : orderHistoryObject.product.outletUom,
                uom:
                  orderHistoryObject.uom === null ? "" : orderHistoryObject.uom,
                outLetUom:
                  orderHistoryObject.product === null
                    ? ""
                    : orderHistoryObject.product.outletUom.name,
                rate:
                  orderHistoryObject.rate === null ? "" : orderHistoryObject.rate,
                qty:
                  orderHistoryObject.qty === null ? "" : +orderHistoryObject.qty,

                totalAmt:
                  orderHistoryObject.totalAmt === null
                    ? ""
                    : orderHistoryObject.totalAmt.toFixed(2),
                taxableAmt:
                  orderHistoryObject.taxableAmt === null
                    ? ""
                    : orderHistoryObject.taxableAmt.toFixed(2),
                product:
                  orderHistoryObject.product === null
                    ? ""
                    : orderHistoryObject.product,
                cgstAmt:
                  orderHistoryObject.cgstAmt === null
                    ? ""
                    : orderHistoryObject.cgstAmt,
                sgstAmt:
                  orderHistoryObject.sgstAmt === null
                    ? ""
                    : orderHistoryObject.sgstAmt.toFixed(2),

                igstAmt:
                  orderHistoryObject.igstAmt === null
                    ? ""
                    : orderHistoryObject.igstAmt.toFixed(2),

                freeQty: 0,
                discountAmt: 0,
                total:
                  orderHistoryObject.totalAmt === null
                    ? ""
                    : orderHistoryObject.totalAmt.toFixed(2),

                insertedUserId:
                  orderHistoryObject.insertedUserId === null
                    ? ""
                    : orderHistoryObject.insertedUserId,
                insertDateTime:
                  orderHistoryObject.insertDateTime === null
                    ? ""
                    : orderHistoryObject.insertDateTime,

                isBillGenerated:
                  orderHistoryObject.isBillGenerated === null
                    ? ""
                    : orderHistoryObject.isBillGenerated,
                isBillGeneratedStatus:
                  orderHistoryObject.isBillGenerated === null
                    ? ""
                    : orderHistoryObject.isBillGenerated === 0
                      ? "Pending"
                      : orderHistoryObject.isBillGenerated === 1
                        ? "Generated"
                        : "Canceled",
              };

              return orderHistoryData;
            });
            dispatch(orderHistoryByIdSuccess({ row }));
            return { response: row, success };
          }
          return { response: [], success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };

export const setOrderHistoryEditDetail =
  ({ row }) =>
    async (dispatch) => {
      const rowList = row.map((rowObject, index) => {
        const addObj = {
          ...rowObject,
          total: (+rowObject.qty * +rowObject.rate).toFixed(2),
          qty: rowObject.qty,
          outLetUom: rowObject.outLetUom,
        };
        return addObj;
      });
      dispatch(orderHistoryByIdSuccess({ row: rowList }));
    };

export const setOrderHistoryDetail =
  ({ row }) =>
    async (dispatch) => {
      dispatch(orderHistoryByIdSuccess({ row }));
    };

export const getOrderHeaderById =
  ({ orderHeaderId }) =>
    async (dispatch) => {
      try {
        const response = apiGet({
          url: URL2 + "/" + orderHeaderId,
        }).then(({ data, success }) => {
          if (success) {
            const orderHeaderObject = data.data;
            let orderHeaderData = {
              id: orderHeaderObject.id === null ? "" : orderHeaderObject.id,
              outLet:
                orderHeaderObject.outLet === null ? "" : orderHeaderObject.outLet,
              ownerName:
                orderHeaderObject.ownerName === null
                  ? ""
                  : orderHeaderObject.ownerName,
              orderNo:
                orderHeaderObject.orderNo === null
                  ? ""
                  : orderHeaderObject.orderNo,
              orderDate:
                orderHeaderObject.orderDate === null
                  ? ""
                  : orderHeaderObject.orderDate,
              distcountAmt:
                orderHeaderObject.distcountAmt === null
                  ? ""
                  : orderHeaderObject.distcountAmt,
              taxableAmt:
                orderHeaderObject.taxableAmt === null
                  ? ""
                  : orderHeaderObject.taxableAmt.toFixed(2),
              cgstAmt:
                orderHeaderObject.cgstAmt === null
                  ? ""
                  : orderHeaderObject.cgstAmt.toFixed(2),
              sgstAmt:
                orderHeaderObject.sgstAmt === null
                  ? ""
                  : orderHeaderObject.sgstAmt.toFixed(2),
              igstAmt:
                orderHeaderObject.igstAmt === null
                  ? ""
                  : orderHeaderObject.igstAmt.toFixed(2),
              totalAmt:
                orderHeaderObject.totalAmt === null
                  ? ""
                  : orderHeaderObject.totalAmt.toFixed(2),
              stockStakeHolder:
                orderHeaderObject.stockStakeHolder === null
                  ? ""
                  : orderHeaderObject.stockStakeHolder,
              type: orderHeaderObject.type === null ? "" : orderHeaderObject.type,
              insertedUserId:
                orderHeaderObject.insertedUserId === null
                  ? ""
                  : orderHeaderObject.insertedUserId,
              insertDateTime:
                orderHeaderObject.insertDateTime === null
                  ? ""
                  : orderHeaderObject.insertDateTime,
              outletOrderDetails:
                orderHeaderObject.outletOrderDetails === null
                  ? ""
                  : orderHeaderObject.outletOrderDetails.map(
                    (orderHistoryObject, index) => {
                      let orderHistoryData = {
                        index: index + 1,
                        id:
                          orderHistoryObject.id === null
                            ? ""
                            : orderHistoryObject.id,
                        productName:
                          orderHistoryObject.product === null
                            ? ""
                            : orderHistoryObject.product.name,
                        productId:
                          orderHistoryObject.product === null
                            ? ""
                            : orderHistoryObject.product.id,
                        outletUom:
                          orderHistoryObject.product === null
                            ? ""
                            : orderHistoryObject.product.outletUom.name,
                        outLet:
                          orderHistoryObject.outLet === null
                            ? ""
                            : orderHistoryObject.outLet,
                        orderNo:
                          orderHistoryObject.orderNo === null
                            ? ""
                            : orderHistoryObject.orderNo,
                        orderDate:
                          orderHistoryObject.orderDate === null
                            ? ""
                            : orderHistoryObject.orderDate,
                        cgstAmt:
                          orderHistoryObject.cgstAmt === null
                            ? ""
                            : orderHistoryObject.cgstAmt,
                        sgstAmt:
                          orderHistoryObject.sgstAmt === null
                            ? ""
                            : orderHistoryObject.sgstAmt.toFixed(2),

                        totalAmt:
                          orderHistoryObject.totalAmt === null
                            ? ""
                            : orderHistoryObject.totalAmt.toFixed(2),
                        distcountAmt:
                          orderHistoryObject.distcountAmt === null
                            ? ""
                            : orderHistoryObject.distcountAmt,

                        igstAmt:
                          orderHistoryObject.igstAmt === null
                            ? ""
                            : orderHistoryObject.igstAmt.toFixed(2),

                        stockStakeHolder:
                          orderHistoryObject.stockStakeHolder === null
                            ? ""
                            : orderHistoryObject.stockStakeHolder,
                        type:
                          orderHistoryObject.type === null
                            ? ""
                            : orderHistoryObject.type,
                        rate:
                          orderHistoryObject.rate === null
                            ? ""
                            : orderHistoryObject.rate.toFixed(2),
                        qty:
                          orderHistoryObject.qty === null
                            ? ""
                            : +orderHistoryObject.qty,

                        freeQty: 0,
                        discountAmt: 0,
                        discountPer: orderHistoryObject.discountPer === null
                          ? ""
                          : orderHistoryObject.discountPer,
                        taxableAmt:
                          orderHistoryObject.taxableAmt === null
                            ? ""
                            : orderHistoryObject.taxableAmt.toFixed(2),
                        total:
                          orderHistoryObject.totalAmt === null
                            ? ""
                            : orderHistoryObject.totalAmt.toFixed(2),

                        insertedUserId:
                          orderHistoryObject.insertedUserId === null
                            ? ""
                            : orderHistoryObject.insertedUserId,
                        insertDateTime:
                          orderHistoryObject.insertDateTime === null
                            ? ""
                            : orderHistoryObject.insertDateTime,
                        isBillGenerated:
                          orderHistoryObject.isBillGenerated === null
                            ? ""
                            : orderHistoryObject.isBillGenerated,
                      };
                      return orderHistoryData;
                    }
                  ),
            };
            dispatch(orderHeaderByIdSuccess({ orderHeaderData }));
            return { response: orderHeaderData, success };
          }
          return { response: {}, success };
        });
        return response;
      } catch (e) {
        return console.error(e.message);
      }
    };
