import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../../components/dynamicscreens/DynamicMainScreen";
import {
  leaveStructureColumns,

} from "../../../tableColumns/table-columns";
import { leaveStructureJson } from "../../../DynamicFormsJson/MastersJSON/leaveStructure";
import { getleaveStructureList } from "../../../Slice/leaveStructure.slice";
import swal from "sweetalert";
import { apiDelete } from "../../../utils/api_service";
import {

  deletemsg,
  deleteFailedMsg,

  deleteConfirmMsg,

  noInternetMsg,

} from "../../../config/messageconstant";
import endpoint from "../../../config/endpoints";

class LeaveStructureTable extends React.Component {
  constructor(props) {
    super(props);
  }
  rowEdit = (rowData) => {
    
    window.location.replace(leaveStructureJson.formPath+"/"+rowData.id);
  };
  rowDelete = (rowData) => {
    swal({
      title: "Are you sure?",
      text: deleteConfirmMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.onDelete(rowData);
      }
    });
  };
  onDelete = (row) => {
    const { showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      apiDelete({
        url: endpoint.leaveStructure + "/" + row.id,
      }).then(({ data, success }) => {
        showLoador({ loador: false });
        if (success) {

          showNotification({ msg: deletemsg });
        } else {
          showNotification({ msg: deleteFailedMsg, severity: "error" });
        }
      });
    } else {
      this.props.showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }; 
  render() {
    const { leaveStructureList, getleaveStructureList } = this.props;
    return (
      <>
        <DynamicMainScreen
          formPath={leaveStructureJson.formPath}
          screenTitle={leaveStructureJson.screenTitle}
          fieldMeta={leaveStructureJson.fieldMeta}
          showPdfDownload={false}
          showExcelDownload={false}
          changeExcelURL={leaveStructureJson.excelUrl}
          showExtension={false}
          pdfFileName={leaveStructureJson.pdfFileName}
          excelFileName={leaveStructureJson.excelFileName}
          showAddButton={leaveStructureJson.showAddButton}
          tableColumnsToFilter={leaveStructureJson.tableColumnsToFilter}
          tableColumns={leaveStructureColumns}
          tableData={leaveStructureList?.leaveStructure}
          getTableData={getleaveStructureList}
          apiBaseURL={leaveStructureJson.apiBaseURL}
          baseIdColumn={leaveStructureJson.baseIdColumn}
          isNote={true}
          isNoteValue={"Add Leave Structure"}
          showDeleteIcon={true}
          rowDelete={this.rowDelete}
          rowEdit={this.rowEdit}
          
          showPopUpDataList={[


          ]}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  leaveStructureList: state.leaveStructure,
});
const mapDispatchToProps = {
  getleaveStructureList,
};
export default connect(mapStateToProps, mapDispatchToProps)(LeaveStructureTable);
