import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet, apiPost } from "../utils/api_service";
const initialState = {
  area: [],
  areaByTaluka: [],
  areaByDistrict: [],
  areaByTalukaNew: [],
};
let URL = endpoints.area;
const areaSlice = createSlice({
  name: "area",
  initialState,
  reducers: {
    areaSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        area: row,
      };
    },
    areaByDistrictSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        areaByDistrict: row,
      };
    },
    areaByTalukaSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        areaByTaluka: row,
      };
    },

    areaBytalukaNewSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        areaByTalukaNew: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  areaSuccess,
  areaByTalukaSuccess,
  areaBytalukaNewSuccess,
  areaByDistrictSuccess,
  resetState,
} = areaSlice.actions;

export default areaSlice.reducer;

export const getArea = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + '?sort={"sortOrder": "ASC"}',
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((areaObject, index) => {
          let areaData = {
            index: index + 1,
            id: areaObject.id === null ? "" : areaObject.id,
            name:
              areaObject.name === null
                ? ""
                : areaObject.name + " (" + areaObject.shortName + ")",
            talukaName:
              areaObject.taluka !== null && areaObject.taluka.name
                ? areaObject.taluka.name
                : "",
            taluka:
              areaObject.taluka !== null && areaObject.taluka.id
                ? areaObject.taluka.id
                : "",
            shortName:
              areaObject.shortName === null ? "" : areaObject.shortName,
            beatCount:
              areaObject.beatCount === null ? "" : areaObject.beatCount,
            outletCount:
              areaObject.outletCount === null ? "" : areaObject.outletCount,
            sortOrder:
              areaObject.sortOrder === null ? "" : areaObject.sortOrder,
          };
          return areaData;
        });
        dispatch(areaSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAreaByUserType = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL + "/by-user-type",
    }).then(({ data, success }) => {
      if (success) {
        const row = data.data.map((area, index) => {
          let areaForBeatData = {
            index: index + 1,
            id: area.id === null ? "" : area.id,
            name: area.name === null ? "" : area.name,
            shortName: area.shortName === null ? "" : area.shortName,
            taluka: area.taluka === null ? "" : area.talukaName,
            sortOrder: area.sortOrder === null ? "" : area.sortOrder,
          };
          return areaForBeatData;
        });
        dispatch(areaSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const setAreaByTaluka =
  ({ row }) =>
  async (dispatch) => {
    dispatch(areaBytalukaNewSuccess({ row }));
  };

export const setArea =
  ({ row }) =>
  async (dispatch) => {
    dispatch(areaSuccess({ row }));
  };

export const getAreaByZoneIds =
  ({ zoneIds }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-zones?zoneIds=" + zoneIds,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((area, index) => {
            let areaForBeatData = {
              index: index + 1,
              id: area.id === null ? "" : area.id,
              name: area.name === null ? "" : area.name,
              shortName: area.shortName === null ? "" : area.shortName,
              sortOrder: area.sortOrder === null ? "" : area.sortOrder,
            };
            return areaForBeatData;
          });
          dispatch(areaSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getAreaByTalukaPostBody =
  ({ talukaIds }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url: URL + "/list-by-talukas",
        postBody: talukaIds,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((areaByTaluka, index) => {
            let areaFromTalukaData = {
              index: index + 1,
              // ...areaByTaluka,
              id: areaByTaluka.id === null ? "" : areaByTaluka.id,
              name: areaByTaluka.name === null ? "" : areaByTaluka.name,
              shortName:
                areaByTaluka.shortName === null ? "" : areaByTaluka.shortName,
              taluka:
                areaByTaluka.name === null ? "" : areaByTaluka.taluka.name,
              beatCount:
                areaByTaluka.beatCount === null ? "" : areaByTaluka.beatCount,
              outletCount:
                areaByTaluka.outletCount === null
                  ? ""
                  : areaByTaluka.outletCount,
              sortOrder:
                areaByTaluka.sortOrder === null ? "" : areaByTaluka.sortOrder,
              talukaId:
                areaByTaluka.taluka === null ? "" : areaByTaluka.taluka.id,
            };
            return areaFromTalukaData;
          });
          dispatch(areaByTalukaSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return { response: [], success: false };
    }
  };

  export const getAreaByTalukaPostBodyUpdated =
  ({ talukaIds }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url: URL + "/list-by-talukas-new",
        postBody: talukaIds,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((areaByTaluka, index) => {
            let areaFromTalukaData = {
              index: index + 1,
              // ...areaByTaluka,
              id: areaByTaluka.id === null ? "" : areaByTaluka.id,
              name: areaByTaluka.name === null ? "" : areaByTaluka.name,
              shortName:
                areaByTaluka.shortName === null ? "" : areaByTaluka.shortName,
              taluka:
                areaByTaluka.talukaName === null ? "" : areaByTaluka.talukaName,
              beatCount:
                areaByTaluka.beatCount === null ? "" : areaByTaluka.beatCount,
              outletCount:
                areaByTaluka.outletCount === null
                  ? ""
                  : areaByTaluka.outletCount,
              sortOrder:
                areaByTaluka.sortOrder === null ? "" : areaByTaluka.sortOrder,
              talukaId:
                areaByTaluka.talukaId === null ? "" : areaByTaluka.talukaId,
            };
            return areaFromTalukaData;
          });
          dispatch(areaByTalukaSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return { response: [], success: false };
    }
  };

export const getAreaByTaluka =
  ({ talukaIds }) =>
  async (dispatch) => {
    try {
      const response = apiPost({
        url: URL + "/by-talukas?talukaIds=" + talukaIds,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((areaByTaluka, index) => {
            let areaFromTalukaData = {
              index: index + 1,
              ...areaByTaluka,
              id: areaByTaluka.id === null ? "" : areaByTaluka.id,
              name: areaByTaluka.name === null ? "" : areaByTaluka.name,
              shortName:
                areaByTaluka.shortName === null ? "" : areaByTaluka.shortName,
              taluka:
                areaByTaluka.name === null ? "" : areaByTaluka.taluka.name,
              sortOrder:
                areaByTaluka.sortOrder === null ? "" : areaByTaluka.sortOrder,
              talukaId:
                areaByTaluka.name === null ? "" : areaByTaluka.taluka.id,
            };
            return areaFromTalukaData;
          });
          dispatch(areaByTalukaSuccess({ row }));
          dispatch(areaBytalukaNewSuccess({ row: data.data }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return { response: [], success: false };
    }
  };
export const getAreaByDistrict =
  ({ districtId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-district?districtId=" + districtId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((districtObject, index) => {
            let districtData = {
              index: index + 1,
              id: districtObject.id === null ? "" : districtObject.id,
              name: districtObject.name === null ? "" : districtObject.name,
              shortName:
                districtObject.shortName === null
                  ? ""
                  : districtObject.shortName,
              sortOrder:
                districtObject.sortOrder === null
                  ? ""
                  : districtObject.sortOrder,
            };
            return districtData;
          });
          dispatch(areaByDistrictSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
