import React from "react";
import { connect } from "react-redux";
import DynamicForm from "../../../components/dynamicscreens/DynamicForm";
import { PaymentTermJson } from "../../../DynamicFormsJson/MastersJSON/paymentTerm"; 
import {
  showLoador,
  showNotification,
} from "../../Landing/Landing.slice";

class PaymentTermForm extends React.Component {
  constructor(props) {
    super(props);
 
  }

  render() {
    return (
      <>
        <DynamicForm
          formPath={PaymentTermJson.formPath}
          screenTitle={PaymentTermJson.screenTitle}
          fieldMeta={PaymentTermJson.fieldMeta}
          apiBaseURL={PaymentTermJson.apiBaseURL}

        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({

});
const mapDispatchToProps = {
  showLoador,
  showNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentTermForm);
