import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  product: [],
  productBySubCategory: [],
  isFetch: false,
};
let categoryURL = endpoints.category;
let URL = endpoints.product;

const mrpConfigurationSlice = createSlice({
  name: "mrpConfiguration",
  initialState,
  reducers: {
    productSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        product: row,
        isFetch: true,
      };
    },

    productBySubCategorySuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        productBySubCategory: row,
        isFetch: true,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { productSuccess, productBySubCategorySuccess, resetState } =
  mrpConfigurationSlice.actions;

export default mrpConfigurationSlice.reducer;

export const setMRPConfList =
  ({ row }) =>
  async (dispatch) => {
    dispatch(productBySubCategorySuccess({ row }));
  };
export const getProductByCategoryId =
  ({ categoryId, type, value, addSub }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: categoryURL + "/" + categoryId + "/get-product-with-current-mrp",
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((productObject, index) => {
            let changedValue = 0;
            if (value != 0 && type === "1") {
              changedValue =
                addSub == 1
                  ? productObject.mrp + +value
                  : productObject.mrp - +value;
            } else if (value != 0) {
              changedValue =
                addSub == 1
                  ? productObject.mrp + (productObject.mrp * +value) / 100
                  : productObject.mrp - (productObject.mrp * +value) / 100;
            }
            let categoryData = {
              index: index + 1,
              id: productObject.id === null ? "" : productObject.id,
              code: productObject.code === null ? "" : productObject.code,
              name: productObject.name === null ? "" : productObject.name,
              mrp: productObject.mrp !== null ? productObject.mrp : "",
              changedValue: changedValue,
              changedValueErr: changedValue < 0 ? 1 : 0,
            };
            return categoryData;
          });
          dispatch(productSuccess({ row }));
          return { response: row, success };
        }
        return { response: data, success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getProductBySubCategory =
  ({ subCategoryIds, type, value, addSub }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/with-current-mrp-by-sub-categories?subCategoryIds=" +
          subCategoryIds,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((subCategoryProductObject, index) => {
            let changedValue = 0;
            if (value != 0 && type === "1") {
              changedValue =
                addSub == 1
                  ? subCategoryProductObject.mrp + +value
                  : subCategoryProductObject.mrp - +value;
            } else if (value != 0) {
              changedValue =
                addSub == 1
                  ? subCategoryProductObject.mrp +
                    (subCategoryProductObject.mrp * +value) / 100
                  : subCategoryProductObject.mrp -
                    (subCategoryProductObject.mrp * +value) / 100;
            }
            let subCategoryProductData = {
              index: index + 1,
              id:
                subCategoryProductObject.id === null
                  ? ""
                  : subCategoryProductObject.id,
              code:
                subCategoryProductObject.code === null
                  ? ""
                  : subCategoryProductObject.code,
              itemName:
                subCategoryProductObject.name === null
                  ? ""
                  : subCategoryProductObject.name,
              group:
                subCategoryProductObject.group === null
                  ? ""
                  : subCategoryProductObject.group,
              name:
                subCategoryProductObject.group === null &&
                subCategoryProductObject.group.subCategory === null
                  ? ""
                  : subCategoryProductObject.group.subCategory.name,
              productShortName:
                subCategoryProductObject.shortName === null
                  ? ""
                  : subCategoryProductObject.shortName,
              uomConversionValue:
                subCategoryProductObject.uomConversionValue === null
                  ? ""
                  : subCategoryProductObject.uomConversionValue,
              weight:
                subCategoryProductObject.weight === null
                  ? ""
                  : subCategoryProductObject.weight,
              description:
                subCategoryProductObject.description === null
                  ? ""
                  : subCategoryProductObject.description,
              selfLife:
                subCategoryProductObject.selfLife === null
                  ? ""
                  : subCategoryProductObject.selfLife,
              image:
                subCategoryProductObject.image === null
                  ? ""
                  : subCategoryProductObject.image,
              sortOrder:
                subCategoryProductObject.sortOrder === null
                  ? ""
                  : subCategoryProductObject.sortOrder,
              isActive:
                subCategoryProductObject.isActive === null
                  ? ""
                  : subCategoryProductObject.isActive,
              mrp:
                subCategoryProductObject.mrp === null
                  ? ""
                  : subCategoryProductObject.mrp,
              changedValue: changedValue,
              changedValueErr: changedValue < 0 ? 1 : 0,
              subCatShortName:
                subCategoryProductObject.group === null &&
                subCategoryProductObject.group.subCategory === null
                  ? ""
                  : subCategoryProductObject.group.subCategory.shortName,
              subCatSortOrder:
                subCategoryProductObject.group === null &&
                subCategoryProductObject.group.subCategory === null
                  ? ""
                  : subCategoryProductObject.group.subCategory.sortOrder,
              productCount:
                subCategoryProductObject.group === null &&
                subCategoryProductObject.group.subCategory === null
                  ? ""
                  : subCategoryProductObject.group.subCategory.productCount,
              subCatIsActive:
                subCategoryProductObject.group === null &&
                subCategoryProductObject.group.subCategory === null
                  ? ""
                  : subCategoryProductObject.group.subCategory.isActive,
              categoryName:
                subCategoryProductObject.group === null &&
                subCategoryProductObject.group.subCategory.category === null
                  ? ""
                  : subCategoryProductObject.group.subCategory.category.name,
              subCategoryCount:
                subCategoryProductObject.group === null &&
                subCategoryProductObject.group.subCategory.category === null
                  ? ""
                  : subCategoryProductObject.group.subCategory.category
                      .subCategoryCount,
              taxPercentage:
                subCategoryProductObject.taxPercentage === null
                  ? ""
                  : subCategoryProductObject.taxPercentage.name,
              stakeHolderUom:
                subCategoryProductObject.stakeHolderUom === null
                  ? ""
                  : subCategoryProductObject.stakeHolderUom.name,
              outletUom:
                subCategoryProductObject.outletUom === null
                  ? ""
                  : subCategoryProductObject.outletUom.name,
            };
            return subCategoryProductData;
          });
          dispatch(productBySubCategorySuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
