import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { compose } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { getActiveVehicleNoListByStockholder } from "../../Slice/veichleSlice.slice";
import AutoComplete from "../../components/Comman/AutoComplete";
import { ButtonCompo } from "../../components/Comman/Button";
import { LabelCompo } from "../../components/Comman/Label";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { TextFieldCompo } from "../../components/Comman/TextFieldCompo";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import { withRouter } from "../../components/withRouter";
import { redColor, titleColor, yellowColor } from "../../config/ColorObj";
import endpoint from "../../config/endpoints";
import {
  cdMsg,
  discountPercentageErrorMsg,
  noInternetMsg,
  saveFailedMsg,
  saveWarningMsg,
  savemsg,
  serverMsg,
  vehicleNoMsg,
} from "../../config/messageconstant";
import { apiPost } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

class OrderBilling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataList: [],
      vehicleNo: "",
      vehicleInfo: "",
      name: "",
      formErrors: {},
    };
  }
  componentDidMount() {
    const { getActiveVehicleNoListByStockholder, offerBillOutletData } =
      this.props;

    if (navigator.onLine) {
      showLoador({ loador: true });

      getActiveVehicleNoListByStockholder().then(({ success }) => {
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
      showLoador({ loador: false });

      this.setState({
        dataList: offerBillOutletData,
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  }

  checkValidation = () => {
    const { vehicleNo } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (vehicleNo === "") {
      formIsValid = false;
      formErrors["vehicleNoError"] = vehicleNoMsg;
    }


    this.setState({ formErrors: formErrors });
    return formIsValid;
  };

  onCancelAfterSubmit = () => {
    const { onCancel } = this.props;

    onCancel();
  };

  changeHandlerTextField = (dataKey, id) => (event) => {
    const { dataList } = this.state;
    const { name, value } = event.target;

    const onChangeList = dataList.map((dataObj) => {
      if (Object.keys(dataObj.outletBillHeader).length !== 0) {
        if (id === dataObj.outletBillHeader.id && value <= 100) {
          const updatedDetails = dataObj.outletBillHeader.outletBillDetails.map(
            (detailsObj) => {
              let newRate =
                (detailsObj.rate * 100) /
                (100 +
                  detailsObj.sgstPer +
                  detailsObj.cgstPer +
                  detailsObj.igstPer);
              let newTaxableAmt = newRate * detailsObj.qty;
              let newItemWiseDiscount =
                (newTaxableAmt / 100) * detailsObj.itemDiscPer;
              let newTaxableWithItemWiseDiscount =
                newTaxableAmt - newItemWiseDiscount;
              let newBillWiseDiscount =
                (newTaxableWithItemWiseDiscount / 100) * value;
              let newTaxableWithBillWiseDiscount =
                newTaxableWithItemWiseDiscount - newBillWiseDiscount;
              let newSgstAmt =
                (newTaxableWithItemWiseDiscount / 100) * detailsObj.sgstPer;
              let newCgstAmt =
                (newTaxableWithItemWiseDiscount / 100) * detailsObj.cgstPer;
              let newIgstAmt =
                (newTaxableWithItemWiseDiscount / 100) * detailsObj.igstPer;
              return {
                ...detailsObj,
                cgstAmt: newSgstAmt,
                sgstAmt: newCgstAmt,
                igstAmt: newIgstAmt,
                itemDiscAmt:
                  (newTaxableWithBillWiseDiscount / 100) *
                  detailsObj.itemDiscPer,
                taxableAmt: newTaxableWithBillWiseDiscount,
                totalAmt:
                  newTaxableWithBillWiseDiscount +
                  newSgstAmt +
                  newCgstAmt +
                  newIgstAmt,
              };
            }
          );
          return {
            ...dataObj,
            outletBillHeader: {
              ...dataObj.outletBillHeader,
              [name]: value,
              taxableAmt: updatedDetails.reduce(
                (sum, row) => sum + row.taxableAmt,
                0
              ),
              totalAmt: updatedDetails.reduce(
                (sum, row) => sum + row.totalAmt,
                0
              ),
              cgstAmt: updatedDetails.reduce(
                (sum, row) => sum + row.cgstAmt,
                0
              ),
              sgstAmt: updatedDetails.reduce(
                (sum, row) => sum + row.sgstAmt,
                0
              ),
              igstAmt: updatedDetails.reduce(
                (sum, row) => sum + row.igstAmt,
                0
              ),
              itemDistcountAmt: updatedDetails.reduce(
                (sum, row) => sum + row.itemDiscAmt,
                0
              ),
              discountOnBillAmt:
                (updatedDetails.reduce((sum, row) => sum + row.taxableAmt, 0) /
                  100) *
                value,
              outletBillDetails: updatedDetails,
            },
          };
        }
      }
      return dataObj;
    });

    this.setState({
      dataList: onChangeList,
    });
  };

  changeHandler = (e, value) => {
    const selectedVehicles = this.props.vehicleList?.vehicle.filter(
      (row) => row.id === value
    );
    if (selectedVehicles && selectedVehicles.length > 0) {
      const selectedVehicle = selectedVehicles[0];
      const { name } = selectedVehicle;
      this.setState({
        vehicleNo: value,
        vehicleInfo: name,
      });
    }
  };

  onChangeTextField = (dataKey, id) => (event) => {
    console.log(id);
    const { dataList } = this.state;
    const { name, value } = event.target;
    console.log("value");
    console.log(value);
    const onChangeList = dataList.map((dataObj) => {
      if (
        Object.keys(dataObj.outletBillHeader).length !== 0 &&
        dataObj.outletBillHeader.outletBillDetails.length !== 0
      ) {
        const updatedDetails = dataObj.outletBillHeader.outletBillDetails.map(
          (detailsObj) => {
            if (id === detailsObj.id && value <= 100) {
              let newRate =
                (detailsObj.rate * 100) /
                (100 +
                  detailsObj.sgstPer +
                  detailsObj.cgstPer +
                  detailsObj.igstPer);
              let newTaxableAmt = newRate * detailsObj.qty;
              let newItemWiseDiscount = (newTaxableAmt / 100) * value;
              let newTaxableWithItemWiseDiscount =
                newTaxableAmt - newItemWiseDiscount;
              let newBillWiseDiscount =
                (newTaxableWithItemWiseDiscount / 100) *
                dataObj.outletBillHeader.discountOnBillPer;
              let newTaxableWithBillWiseDiscount =
                newTaxableWithItemWiseDiscount - newBillWiseDiscount;
              let newSgstAmt =
                (newTaxableWithItemWiseDiscount / 100) * detailsObj.sgstPer;
              let newCgstAmt =
                (newTaxableWithItemWiseDiscount / 100) * detailsObj.cgstPer;
              let newIgstAmt =
                (newTaxableWithItemWiseDiscount / 100) * detailsObj.igstPer;
              return {
                ...detailsObj,
                cgstAmt: newSgstAmt,
                sgstAmt: newCgstAmt,
                igstAmt: newIgstAmt,
                itemDiscAmt: (newTaxableWithBillWiseDiscount / 100) * value,
                taxableAmt: newTaxableWithBillWiseDiscount,
                totalAmt:
                  newTaxableWithBillWiseDiscount +
                  newSgstAmt +
                  newCgstAmt +
                  newIgstAmt,
                [name]: value,
              };
            }
            return detailsObj;
          }
        );
        return {
          ...dataObj,
          outletBillHeader: {
            ...dataObj.outletBillHeader,
            taxableAmt: updatedDetails.reduce(
              (sum, row) => sum + row.taxableAmt,
              0
            ),
            totalAmt: updatedDetails.reduce(
              (sum, row) => sum + row.totalAmt,
              0
            ),
            cgstAmt: updatedDetails.reduce((sum, row) => sum + row.cgstAmt, 0),
            sgstAmt: updatedDetails.reduce((sum, row) => sum + row.sgstAmt, 0),
            igstAmt: updatedDetails.reduce((sum, row) => sum + row.igstAmt, 0),
            itemDistcountAmt: updatedDetails.reduce(
              (sum, row) => sum + row.itemDiscAmt,
              0
            ),
            outletBillDetails: updatedDetails,
          },
        };
      }
      return dataObj;
    });
    this.setState({
      dataList: onChangeList,
    });
  };

  clickSubmitOfferHandler = () => {
    const { showLoador, showNotification, onCancel } = this.props;
    const { dataList, vehicleInfo } = this.state;

    if (this.checkValidation()) {
      let billHeaderListToSave = [];
      let outletBillHeader = {};

      const dataListForSave = dataList.map((dataObj) => {
        (outletBillHeader = {
          ...dataObj.outletBillHeader,
          discountOnBillPer: parseFloat(
            dataObj.outletBillHeader.discountOnBillPer
          ),
          outletBillDetails:
            dataObj.outletBillHeader.outletBillDetails.length != 0 &&
            dataObj.outletBillHeader.outletBillDetails.map(
              (billDetailsObject) => {
                billDetailsObject = {
                  ...billDetailsObject,
                  itemDiscPer: parseFloat(billDetailsObject.itemDiscPer),
                };
                return billDetailsObject;
              }
            ),
          vehicleNo: vehicleInfo,
        }),
          billHeaderListToSave.push(outletBillHeader);
      });

      console.log(billHeaderListToSave);

      swal({
        title: "Are you sure?",
        text: saveWarningMsg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          showLoador({ loador: true });
          apiPost({
            url:
              endpoint.outletBillHeader +
              "/save-list-after-generate-with-order",
            postBody: billHeaderListToSave,
          }).then(({ data, success }) => {
            if (success) {
              showNotification({ msg: savemsg });
              this.onCancelAfterSubmit();
              window.location.reload();
            } else {
              showNotification({ msg: saveFailedMsg, severity: "error" });
            }
            showLoador({ loador: false });
          });
        }
      });
    }
  };

  render() {
    const { vehicleNo, formErrors, dataList, proceedForBillingFlag } =
      this.state;
    const { vehicleList, onCancel } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 1.5,
            borderRadius: 1.5,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            screenTitle={"Billing Preview"}
            showTable={false}
            showBackButton={true}
            showAddButton={false}
            onCancel={onCancel}
          />
          <br />

          {dataList.length !== 0 &&
            dataList.map((dataObj, index) => {
              return (
                <>
                  <DynamicDetails
                    DataToSet={[
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "No",
                        value: index + 1,
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "Outlet Name",
                        value:
                          dataObj.outletBillHeader &&
                            Object.keys(dataObj.outletBillHeader).length !== 0 &&
                            dataObj.outletBillHeader.outLet &&
                            dataObj.outletBillHeader.outLet.firmName !== null
                            ? dataObj.outletBillHeader.outLet.firmName
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "Owner Name",
                        value:
                          dataObj.outletBillHeader &&
                            Object.keys(dataObj.outletBillHeader).length !== 0 &&
                            dataObj.outletBillHeader.outLet &&
                            dataObj.outletBillHeader.outLet.ownerName !== null
                            ? dataObj.outletBillHeader.outLet.ownerName
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "Bill No",
                        value:
                          dataObj.outletBillHeader &&
                            Object.keys(dataObj.outletBillHeader).length !== 0 &&
                            dataObj.outletBillHeader.billNo !== null
                            ? dataObj.outletBillHeader.billNo
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "Taxable Amount",
                        value:
                          dataObj.outletBillHeader &&
                            Object.keys(dataObj.outletBillHeader).length !== 0 &&
                            dataObj.outletBillHeader.taxableAmt !== null
                            ? dataObj.outletBillHeader.taxableAmt.toFixed(2)
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "Total Tax",
                        value:
                          dataObj.outletBillHeader &&
                            Object.keys(dataObj.outletBillHeader).length !== 0 &&
                            dataObj.outletBillHeader.sgstAmt !== null &&
                            dataObj.outletBillHeader.cgstAmt !== null &&
                            dataObj.outletBillHeader.igstAmt !== null
                            ? (
                              dataObj.outletBillHeader.sgstAmt +
                              dataObj.outletBillHeader.cgstAmt +
                              dataObj.outletBillHeader.igstAmt
                            ).toFixed(2)
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "Bill Discount Amt",
                        value:
                          dataObj.outletBillHeader &&
                            Object.keys(dataObj.outletBillHeader).length !== 0 &&
                            dataObj.outletBillHeader.discountOnBillAmt !== null
                            ? dataObj.outletBillHeader.discountOnBillAmt.toFixed(
                              2
                            )
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "Item Discount Amt",
                        value:
                          dataObj.outletBillHeader &&
                            Object.keys(dataObj.outletBillHeader).length !== 0 &&
                            dataObj.outletBillHeader.itemDistcountAmt !== null
                            ? dataObj.outletBillHeader.itemDistcountAmt.toFixed(
                              2
                            )
                            : "",
                      },
                      {
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 6,
                        labelXS: 12,
                        labelSM: 4,
                        labelMD: 4,
                        labelLG: 4,
                        valueXS: 12,
                        valueSM: 8,
                        valueMD: 8,
                        valueLG: 8,
                        label: "Bill Amount",
                        value:
                          dataObj.outletBillHeader &&
                            Object.keys(dataObj.outletBillHeader).length !== 0 &&
                            dataObj.outletBillHeader.taxableAmt !== null &&
                            dataObj.outletBillHeader.sgstAmt !== null &&
                            dataObj.outletBillHeader.cgstAmt !== null &&
                            dataObj.outletBillHeader.igstAmt !== null &&
                            dataObj.outletBillHeader.discountOnBillAmt !== null &&
                            dataObj.outletBillHeader.itemDistcountAmt !== null
                            ? (
                              dataObj.outletBillHeader.taxableAmt +
                              dataObj.outletBillHeader.sgstAmt +
                              dataObj.outletBillHeader.cgstAmt +
                              dataObj.outletBillHeader.igstAmt -
                              dataObj.outletBillHeader.discountOnBillAmt -
                              dataObj.outletBillHeader.itemDistcountAmt
                            ).toFixed(2)
                            : "",
                      },
                    ]}
                  />

                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" sx={{ width: "5%" }}>
                            Sr. No
                          </TableCell>
                          <TableCell align="center" sx={{ width: "15%" }}>
                            Product Name
                          </TableCell>
                          <TableCell align="center" sx={{ width: "10%" }}>
                            Quantity
                          </TableCell>
                          <TableCell align="center" sx={{ width: "10%" }}>
                            Tax (%)
                          </TableCell>
                          <TableCell align="center" sx={{ width: "10%" }}>
                            Tax Amount
                          </TableCell>
                          <TableCell align="center" sx={{ width: "10%" }}>
                            Discount (%)
                          </TableCell>
                          <TableCell align="center" sx={{ width: "10%" }}>
                            Discount Amount
                          </TableCell>
                          <TableCell align="center" sx={{ width: "10%" }}>
                            Taxable Amount
                          </TableCell>
                          <TableCell align="center" sx={{ width: "10%" }}>
                            Total Amount
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <>
                        {Object.keys(dataObj.outletBillHeader).length !== 0 &&
                          dataObj.outletBillHeader.outletBillDetails.length !==
                          0 &&
                          dataObj.outletBillHeader.outletBillDetails.map(
                            (detailObj, index) => (
                              <TableRow key={index}>
                                <TableCell align="center" sx={{ width: "5%" }}>
                                  {index + 1}
                                </TableCell>
                                <TableCell align="left" sx={{ width: "15%" }}>
                                  {detailObj.product.name}
                                </TableCell>
                                <TableCell align="center" sx={{ width: "10%" }}>
                                  {detailObj.qty}
                                </TableCell>
                                <TableCell align="right" sx={{ width: "10%" }}>
                                  {(
                                    detailObj.cgstPer +
                                    detailObj.sgstPer +
                                    detailObj.igstPer
                                  ).toFixed(2)}
                                </TableCell>
                                <TableCell align="right" sx={{ width: "10%" }}>
                                  {(
                                    detailObj.cgstAmt +
                                    detailObj.sgstAmt +
                                    detailObj.igstAmt
                                  ).toFixed(2)}
                                </TableCell>
                                <TableCell align="right" sx={{ width: "10%" }}>
                                  <TextFieldCompo
                                    placeHolder={"Discount %"}
                                    size="small"
                                    color="primary"
                                    name="itemDiscPer"
                                    id={"1"}
                                    value={detailObj.itemDiscPer}
                                    onChange={this.onChangeTextField(
                                      "itemDiscPer",
                                      detailObj.id
                                    )}
                                    fullWidth={false}
                                    errorText={detailObj.itemDiscPer > 100 ? discountPercentageErrorMsg : ""}
                                  />
                                </TableCell>
                                <TableCell align="right" sx={{ width: "10%" }}>
                                  {detailObj.itemDiscAmt.toFixed(2)}
                                </TableCell>
                                <TableCell align="right" sx={{ width: "10%" }}>
                                  {detailObj.taxableAmt.toFixed(2)}
                                </TableCell>
                                <TableCell align="right" sx={{ width: "10%" }}>
                                  {detailObj.totalAmt.toFixed(2)}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                      </>
                    </Table>
                  </TableContainer>
                  <br />
                  <Grid
                    item
                    sm={6}
                    xs={12}
                    md={6}
                    lg={6}
                    container
                    justifyContent="space-between"
                  >
                    <Grid
                      item
                      sm={6}
                      xs={8}
                      md={6}
                      lg={6}
                      container
                      justifyContent="space-between"
                    >
                      <Grid item xs={12} md={5} sm={5}>
                        <LabelCompo
                          className="text-black"
                          style={{ fontSize: 16, color: titleColor }}
                          label="CD :"
                        />
                      </Grid>
                      <Grid item xs={12} md={7} sm={7}>
                        <TextFieldCompo
                          placeHolder={"Enter CD"}
                          color="primary"
                          name="discountOnBillPer"
                          value={dataObj.outletBillHeader.discountOnBillPer}
                          fullWidth
                          onChange={this.changeHandlerTextField(
                            "discountOnBillPer",
                            dataObj.outletBillHeader.id
                          )}
                          errorText={dataObj.outletBillHeader.discountOnBillPer > 100 ? discountPercentageErrorMsg : dataObj.outletBillHeader.discountOnBillPer === "" ? cdMsg : ""}

                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              );
            })}
          <br />
          <br />
          <Grid
            item
            sm={6}
            xs={12}
            md={6}
            lg={6}
            container
            justifyContent="space-between"
          >
            <Grid
              item
              sm={6}
              xs={8}
              md={6}
              lg={6}
              container
              justifyContent="space-between"
            >
              <Grid item xs={12} md={5} sm={5}>
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: redColor }}
                  label="* "
                />
                <LabelCompo
                  className="text-black"
                  style={{ fontSize: 16, color: titleColor }}
                  label="Vehicle No :"
                />
              </Grid>
              <Grid item xs={12} md={7} sm={7}>
                <AutoComplete
                  keyColName={"id"}
                  placeHolder="Select Vehicle No"
                  value={vehicleNo}
                  name={"vehicleNo"}
                  options={vehicleList?.vehicle}
                  errorText={
                    formErrors["vehicleNoError"]
                      ? formErrors["vehicleNoError"]
                      : ""
                  }
                  isError={
                    formErrors["vehicleNoError"]
                      ? formErrors["vehicleNoError"]
                      : ""
                  }
                  onChange={this.changeHandler}
                />
              </Grid>
            </Grid>
          </Grid>

          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="flex-end"
          >
            <ButtonCompo
              sx={{
                bgcolor: "dodgerblue",
                color: yellowColor,
              }}
              size="medium"
              type="button"
              variant="contained"
              name={"Save Bill"}
              onClick={this.clickSubmitOfferHandler}
              fullWidth={false}
            />
          </Grid>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  vehicleList: state.vehicle,
});
const mapDispatchToProps = {
  getActiveVehicleNoListByStockholder,
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OrderBilling);
