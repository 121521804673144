import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import InfoIcon from "@mui/icons-material/Info";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { compose } from "@reduxjs/toolkit";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import DynamicFormWithoutSave from "../../components/dynamicscreens/DynamicFormWithoutSave";
import { withRouter } from "../../components/withRouter";
import MainCard from "../DashbourdChanges/MainCard";
import { styled } from "@mui/material/styles";
import endpoint from "../../config/endpoints";
import { apiPost } from "../../utils/api_service";
import { FormControl } from "@mui/base";
import {
  blackColor,
  checkboxColor,
  checkboxShadowColor,
  redColor,
  titleColor,
} from "../../config/ColorObj";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { pink } from "@mui/material/colors";
import { LabelCompo } from "../../components/Comman/Label";
import DynamicDetails from "../../components/dynamicscreens/DynamicDetails";
import { ButtonCompo } from "../../components/Comman/Button";
import { saveFailedMsg, saveWarningMsg } from "../../config/messageconstant";
import swal from "sweetalert";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { classes, children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

class BillingWithOffer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      checkboxAllData: [],
      offerBillNewData: {},
      billOfferId: "",
      outletHeader: "",
      stockChangedList: [],
    };
  }
  componentDidMount() {
    const { offerBillOutletData, flag } = this.props;
    const stockCurrentList = localStorage.getItem("stockList");
    const valuesArray = JSON.parse(stockCurrentList);
    if (flag) {
      let i;
      let j;
      let stockDummyNewList = valuesArray;
      for (i = 0; i < stockDummyNewList.length; i++) {
        for (
          j = 0;
          j <
          offerBillOutletData.stockStakeHolderBillHeader
            .stockStakeHolderBillDetails.length;
          j++
        ) {
          if (
            stockDummyNewList[i].productId ==
            offerBillOutletData.stockStakeHolderBillHeader
              .stockStakeHolderBillDetails[j].product.id
          ) {
            stockDummyNewList[i].stock =
              stockDummyNewList[i].stock -
              offerBillOutletData.stockStakeHolderBillHeader
                .stockStakeHolderBillDetails[j].freeQty;
          }
        }
      }
      this.setState({ stockChangedList: stockDummyNewList });
    }

    this.setState({
      offerBillNewData: offerBillOutletData,
      outletHeader: offerBillOutletData.stockStakeHolderBillHeader,
    });
  }
  clickSubmitOfferHandler = () => {
    const { offerBillNewData, outletHeader } = this.state;
    const { salesPersonId, showLoador, showNotification } = this.props;
    let dataToSave = {
      stockStakeHolderBillsAfterApplyingOffers:
        offerBillNewData.stockStakeHolderBillsAfterApplyingOffers,
    };
    swal({
      title: "Are you sure?",
      text: saveWarningMsg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        showLoador({ loador: true });
        apiPost({
          url:
            endpoint.stockStakeholderBillHeader + "/save-after-appying-offers",
          postBody: offerBillNewData.stockStakeHolderBillsAfterApplyingOffers,
        }).then(({ data, success }) => {
          showLoador({ loador: false });
          if (success) {
            window.location.replace("/received-order-list");
          } else {
            showNotification({ msg: saveFailedMsg, severity: "error" });
          }
        });
      }
    });
  };
  clickSubmitHandler = () => {
    const { checkboxAllData, offerBillNewData, billOfferId, stockChangedList } =
      this.state;
    const { offerBillOutletData } = this.props;
    if (offerBillNewData.length !== 0) {
      let dataToSave = [];
      if (
        Object.keys(offerBillNewData.stockStakeHolderBillHeader).length !== 0 &&
        offerBillNewData.stockStakeHolderBillHeader.id == billOfferId
      ) {
        let billCheckedOfferData = [];
        if (offerBillNewData.offerHeaders.length !== 0) {
          offerBillNewData.offerHeaders.map((offerData) => {
            if (checkboxAllData.length !== 0) {
              checkboxAllData.map((checkedData) => {
                if (checkedData.id == offerData.id) {
                  billCheckedOfferData.push(checkedData);
                }
              });
            }
          });
        }
        let billObjectData = {
          stockStakeHolderBillHeader:
            offerBillNewData.stockStakeHolderBillHeader,
          offerHeaders: billCheckedOfferData,
          productAndStocks: stockChangedList,
        };
        dataToSave.push(billObjectData);
      }
      this.onSave(dataToSave[0]);
    }
  };

  onSave = (dataToSave) => {
    const { offerBillNewData, openDialog } = this.state;
    const { flag, showLoador } = this.props;
    const stockCurrentList = localStorage.getItem("stockList");
    const valuesArray = JSON.parse(stockCurrentList);
    showLoador({ loador: true });
    apiPost({
      url: endpoint.stockStakeholderBillHeader + "/apply-offer",
      postBody: dataToSave,
    }).then(({ data, success }) => {
      showLoador({ loador: false });
      if (success) {
        if (flag) {
          let i;
          let j;
          let stockDummyNewList = valuesArray;
          for (i = 0; i < stockDummyNewList.length; i++) {
            for (
              j = 0;
              j <
              data.data.stockStakeHolderBillHeader.stockStakeHolderBillDetails
                .length;
              j++
            ) {
              if (
                stockDummyNewList[i].productId ==
                data.data.stockStakeHolderBillHeader
                  .stockStakeHolderBillDetails[j].product.id
              ) {
                stockDummyNewList[i].stock =
                  stockDummyNewList[i].stock -
                  data.data.stockStakeHolderBillHeader
                    .stockStakeHolderBillDetails[j].freeQty;
              }
            }
          }
          this.setState({ stockChangedList: stockDummyNewList });
        }
        let newObjectToSave = {
          ...data.data.stockStakeHolderBillsAfterApplyingOffers,
          stockStakeHolderBillDetails:
            data.data.stockStakeHolderBillsAfterApplyingOffers.stockStakeHolderBillDetails.map(
              (stockStakeHolderBillObject) => {
                let productWithoutBillObject =
                  data.data.stockStakeHolderBillHeader.stockStakeHolderBillDetails.filter(
                    (row) => row.id == stockStakeHolderBillObject.id
                  );
                if (productWithoutBillObject.length !== 0) {
                  stockStakeHolderBillObject = {
                    ...stockStakeHolderBillObject,

                    taxableAmtWithoutOffer:
                      productWithoutBillObject[0].taxableAmt,
                    totalAmtWithoutOffer: productWithoutBillObject[0].totalAmt,
                  };
                  return stockStakeHolderBillObject;
                } else {
                  stockStakeHolderBillObject = {
                    ...stockStakeHolderBillObject,
                    taxableAmtWithoutOffer:
                      0.00,
                    totalAmtWithoutOffer: 0.00,
                  };
                  return stockStakeHolderBillObject;
                }
              }
            ),
        };
        let newData = {
          ...data.data,
          stockStakeHolderBillsAfterApplyingOffers: newObjectToSave,
        };
        this.setState({
          offerBillNewData: newData,
          openDialog: !openDialog,
        });
      } else {
        showNotification({
          msg: saveFailedMsg,
          severity: "error",
        });
      }
    });
  };

  onCheckBoxClick = (checkedValue, headerId) => {
    const { checkboxAllData, offerBillNewData } = this.state;
    const { offerBillOutletData } = this.props;

    let checkboxData = [];
    if (offerBillNewData.length !== 0) {
      if (
        Object.keys(offerBillNewData.stockStakeHolderBillsAfterApplyingOffers)
          .length !== 0 &&
        offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.id ===
        headerId
      ) {
        checkboxAllData.map((allOffer) => {
          if (
            allOffer.level == 0 &&
            allOffer.type == 0 &&
            checkedValue.level == 0 &&
            checkedValue.type == 0
          ) {
            if (allOffer.id == checkedValue.id) {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied == 1 ? 0 : 1,
                appliedOfferId:
                  offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.id,
              };
              checkboxData.push(allOfferData);
            } else {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied == 1 ? 0 : 0,
              };
              checkboxData.push(allOfferData);
            }
          } else if (
            allOffer.level == 0 &&
            allOffer.type == 1 &&
            checkedValue.level == 0 &&
            checkedValue.type == 1
          ) {
            if (allOffer.id == checkedValue.id) {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied == 1 ? 0 : 1,
                appliedOfferId:
                  offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.id,
              };
              checkboxData.push(allOfferData);
            } else {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied == 1 ? 0 : 0,
              };
              checkboxData.push(allOfferData);
            }
          } else if (
            allOffer.level == 1 &&
            allOffer.type == 0 &&
            checkedValue.level == 1 &&
            checkedValue.type == 0
          ) {
            if (allOffer.id == checkedValue.id) {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied == 1 ? 0 : 1,
                appliedOfferId:
                  offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.id,
              };
              checkboxData.push(allOfferData);
            } else {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied,
              };
              checkboxData.push(allOfferData);
            }
          } else if (
            allOffer.level == 1 &&
            allOffer.type == 1 &&
            checkedValue.level == 1 &&
            checkedValue.type == 1
          ) {
            if (allOffer.id == checkedValue.id) {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied == 1 ? 0 : 1,
                appliedOfferId:
                  offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.id,
              };
              checkboxData.push(allOfferData);
            } else {
              let allOfferData = {
                ...allOffer,
                isApplied: allOffer.isApplied,
              };
              checkboxData.push(allOfferData);
            }
          } else {
            checkboxData.push(allOffer);
          }
        });
      }
    }
    this.setState({
      checkboxAllData: checkboxData,
    });
  };

  clickHandler = (billId) => {
    const { openDialog, offerBillNewData } = this.state;

    const { offerBillOutletData } = this.props;
    let checkboxData = [];
    this.setState({
      openDialog: !openDialog,
      checkboxAllData: [],
      billOfferId: billId,
    });
    if (offerBillNewData.length !== 0) {
      if (
        Object.keys(offerBillNewData.stockStakeHolderBillsAfterApplyingOffers)
          .length !== 0 &&
        offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.id ==
        billId &&
        offerBillNewData.offerHeaders.length !== 0
      ) {
        offerBillNewData.offerHeaders.map((allOffer) => {
          let allOfferData = {
            ...allOffer,
            appliedOfferId:
              offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.id,
          };
          checkboxData.push(allOfferData);
        });
      }
    }
    this.setState({
      checkboxAllData: checkboxData,
    });
  };

  render() {
    const { openDialog, checkboxAllData, offerBillNewData } = this.state;
    const { offerBillOutletData, onCancel } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            screenTitle={"Billing Preview"}
            showTable={false}
            showBackButton={true}
            showAddButton={false}
            onCancel={onCancel}
          />
          <br />
          {offerBillNewData !== null &&
            Object.keys(offerBillNewData).length !== 0 &&
            Object.keys(
              offerBillNewData.stockStakeHolderBillsAfterApplyingOffers
            ).length !== 0 && (
              <>
                <DynamicDetails
                  DataToSet={[
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Stock Holder No.",
                      value: 1,
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Stock Holder Name",
                      value:
                        offerBillNewData.stockStakeHolderBillsAfterApplyingOffers !==
                          null &&
                          offerBillNewData
                            .stockStakeHolderBillsAfterApplyingOffers
                            .toBillStakeHolder !== null
                          ? offerBillNewData
                            .stockStakeHolderBillsAfterApplyingOffers
                            .toBillStakeHolder.ownerName
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Firm Name",
                      value:
                        offerBillNewData.stockStakeHolderBillsAfterApplyingOffers !==
                          null &&
                          offerBillNewData
                            .stockStakeHolderBillsAfterApplyingOffers
                            .toBillStakeHolder !== null
                          ? offerBillNewData
                            .stockStakeHolderBillsAfterApplyingOffers
                            .toBillStakeHolder.firmName
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "State Name",
                      value:
                        offerBillNewData.stockStakeHolderBillsAfterApplyingOffers !==
                          null &&
                          offerBillNewData
                            .stockStakeHolderBillsAfterApplyingOffers
                            .toBillStakeHolder !== null &&
                          offerBillNewData
                            .stockStakeHolderBillsAfterApplyingOffers
                            .toBillStakeHolder.state !== null
                          ? offerBillNewData
                            .stockStakeHolderBillsAfterApplyingOffers
                            .toBillStakeHolder.state.name
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Bill No",
                      value:
                        offerBillNewData.stockStakeHolderBillsAfterApplyingOffers !==
                          null
                          ? offerBillNewData
                            .stockStakeHolderBillsAfterApplyingOffers.billNo
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Bill Date",
                      value:
                        offerBillNewData.stockStakeHolderBillsAfterApplyingOffers !==
                          null
                          ? offerBillNewData
                            .stockStakeHolderBillsAfterApplyingOffers.billDate
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Taxable Amount",
                      value:
                        offerBillNewData.stockStakeHolderBillsAfterApplyingOffers !==
                          null
                          ? offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.taxableAmt.toFixed(
                            2
                          )
                          : "",
                    },
                    {
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      labelXS: 12,
                      labelSM: 4,
                      labelMD: 4,
                      labelLG: 4,
                      valueXS: 12,
                      valueSM: 8,
                      valueMD: 8,
                      valueLG: 8,
                      label: "Total Amount",
                      value:
                        offerBillNewData.stockStakeHolderBillsAfterApplyingOffers !==
                          null
                          ? offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.totalAmt.toFixed(
                            2
                          )
                          : "",
                    },
                  ]}
                />
              </>
            )}

          {offerBillNewData !== null &&
            Object.keys(offerBillNewData).length !== 0 &&
            offerBillNewData.offerHeaders.length !== 0 && (
              <>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  container
                  justifyContent="flex-end"
                >
                  <ButtonCompo
                    sx={{
                      bgcolor: "dodgerblue", // theme.palette.primary.main
                      color: "#fbff00",
                      opacity: "0.9",
                      transform: "scale(0.9)",
                      ":hover": {
                        display: "inline-block",
                        bgcolor: "#fbff00", // theme.palette.primary.main
                        color: "dodgerblue",
                        opacity: "0.9",
                        transform: "scale(1.5)",
                        transitionDuration: "3s",
                        backfaceVisibility: "hidden",
                        transform: "translate(2px, 2px) rotate(0deg)  scale(2)",
                        config: {
                          tension: "300",
                          friction: "10",
                        },
                      },
                    }}
                    size="medium"
                    type="button"
                    variant="contained"
                    name={"Offer"}
                    onClick={(e) =>
                      this.clickHandler(
                        offerBillNewData
                          .stockStakeHolderBillsAfterApplyingOffers.id
                      )
                    }
                    fullWidth={false}
                  />
                </Grid>
              </>
            )}
          {Object.keys(offerBillNewData).length !== 0 && (
            <>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" sx={{ width: "5%" }}>
                        Sr. No
                      </TableCell>
                      <TableCell align="center" sx={{ width: "13%" }}>
                        Product Name
                      </TableCell>
                      <TableCell align="center" sx={{ width: "13%" }}>
                        Quantity
                      </TableCell>
                      <TableCell align="center" sx={{ width: "13%" }}>
                        Free Quantity
                      </TableCell>
                      <TableCell align="center" sx={{ width: "13%" }}>
                        Taxable Amount
                      </TableCell>
                      <TableCell align="center" sx={{ width: "13%" }}>
                        Total Amount
                      </TableCell>
                      <TableCell align="center" sx={{ width: "15%" }}>
                        Taxable Amount with offer
                      </TableCell>
                      <TableCell align="center" sx={{ width: "15%" }}>
                        Total Amount with offer
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {Object.keys(
                    offerBillNewData.stockStakeHolderBillsAfterApplyingOffers
                  ).length !== 0 &&
                    offerBillNewData.stockStakeHolderBillsAfterApplyingOffers
                      .stockStakeHolderBillDetails.length !== 0 &&
                    offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.stockStakeHolderBillDetails.map(
                      (billDetails, index) => {
                        return (
                          <>
                            <TableRow>
                              <TableCell align="center" sx={{ width: "5%" }}>
                                {index + 1}
                              </TableCell>
                              <TableCell align="left" sx={{ width: "13%" }}>
                                {billDetails !== null &&
                                  billDetails.product !== null
                                  ? billDetails.product.name
                                  : ""}
                              </TableCell>
                              <TableCell align="center" sx={{ width: "13%" }}>
                                {billDetails !== null
                                  ? billDetails.qty.toFixed(2)
                                  : ""}
                              </TableCell>
                              <TableCell align="center" sx={{ width: "13%" }}>
                                {billDetails !== null
                                  ? billDetails.freeQty.toFixed(2)
                                  : ""}
                              </TableCell>
                              <TableCell align="right" sx={{ width: "13%" }}>
                                {billDetails !== null
                                  ? billDetails.taxableAmtWithoutOffer.toFixed(2)
                                  : ""}
                              </TableCell>
                              <TableCell align="right" sx={{ width: "13%" }}>
                                {billDetails !== null
                                  ? billDetails.totalAmtWithoutOffer.toFixed(2)
                                  : ""}
                              </TableCell>
                              <TableCell align="right" sx={{ width: "15%" }}>
                                {billDetails !== null
                                  ? billDetails.taxableAmt.toFixed(2)
                                  : ""}
                              </TableCell>
                              <TableCell align="right" sx={{ width: "15%" }}>
                                {billDetails !== null
                                  ? billDetails.totalAmt.toFixed(2)
                                  : ""}
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      }
                    )}
                </Table>
                {Object.keys(offerBillNewData).length !== 0 &&
                  Object.keys(
                    offerBillNewData.stockStakeHolderBillsAfterApplyingOffers
                  ).length !== 0 &&
                  offerBillNewData.stockStakeHolderBillsAfterApplyingOffers
                    .appliedOffers.length !== 0 && (
                    <>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" sx={{ width: "5.5%" }}>
                              Sr. No
                            </TableCell>
                            <TableCell align="center" sx={{ width: "80%" }}>
                              Offer Name
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {offerBillNewData
                          .stockStakeHolderBillsAfterApplyingOffers
                          .appliedOffers.length !== 0 &&
                          offerBillNewData.stockStakeHolderBillsAfterApplyingOffers.appliedOffers.map(
                            (offerAppliedData, index) => {
                              return (
                                <>
                                  <TableRow>
                                    <TableCell align="center">
                                      {index + 1}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      sx={{ width: "80%" }}
                                    >
                                      {offerAppliedData !== null
                                        ? offerAppliedData.name
                                        : ""}
                                    </TableCell>
                                  </TableRow>
                                </>
                              );
                            }
                          )}
                      </Table>
                    </>
                  )}
              </TableContainer>
            </>
          )}
          <br />
          {openDialog && (
            <>
              <BootstrapDialog
                onClose={this.clickHandler}
                aria-labelledby="customized-dialog-title"
                open={true}
                fullWidth
                maxWidth="500px"
              >
                <BootstrapDialogTitle
                  id="customized-dialog-title"
                  onClose={this.clickHandler}
                >
                  Applicable Offers
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="flex-between"
                  >
                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="flex-start"
                    >
                      {checkboxAllData.length !== 0 &&
                        checkboxAllData.map((applicableOfferData) => {
                          if (
                            applicableOfferData.type == 0 &&
                            applicableOfferData.level == 0
                          ) {
                            return (
                              <>
                                <Typography
                                  sx={{ p: 0, marginLeft: 2 }}
                                  variant="h6"
                                  color="textSecondary"
                                >
                                  <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                      <FormControlLabel
                                        value="end"
                                        control={
                                          <Checkbox
                                            sx={{
                                              color: checkboxShadowColor,
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 30,
                                              },
                                              "&.Mui-checked": {
                                                color: pink[600],
                                              },
                                            }}
                                            color="secondary"
                                          />
                                        }
                                        sx={{
                                          ".css-19g4vwf-MuiTypography-root": {
                                            fontSize: "1.3rem",
                                            color: "#000000",
                                          },
                                        }}
                                        label={applicableOfferData.name}
                                        onChange={(e) => {
                                          this.onCheckBoxClick(
                                            applicableOfferData,
                                            applicableOfferData.appliedOfferId
                                          );
                                        }}
                                        labelPlacement="end"
                                        checked={
                                          applicableOfferData.isApplied == 1
                                            ? true
                                            : false
                                        }
                                      />
                                    </FormGroup>{" "}
                                    {applicableOfferData.isApplied !== 2 ? (
                                      <div
                                        style={{
                                          marginLeft: "40px",
                                          fontSize: "1.1rem",
                                        }}
                                      >
                                        {applicableOfferData.discription}
                                      </div>
                                    ) : (
                                      <FormHelperText
                                        style={{
                                          marginLeft: "40px",
                                        }}
                                        error
                                      >
                                        {
                                          "Due to a shortage of stock, the offer is not applicable"
                                        }
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Typography>
                              </>
                            );
                          }
                        })}
                    </Grid>

                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="flex-start"
                    >
                      {checkboxAllData.length !== 0 &&
                        checkboxAllData.map((applicableOfferData) => {
                          if (
                            applicableOfferData.type == 1 &&
                            applicableOfferData.level == 0
                          ) {
                            return (
                              <>
                                <Typography
                                  sx={{ p: 0, marginLeft: 2 }}
                                  variant="h6"
                                  color="textSecondary"
                                >
                                  <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                      <FormControlLabel
                                        value="end"
                                        control={
                                          <Checkbox
                                            sx={{
                                              color: checkboxShadowColor,
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 30,
                                              },
                                              "&.Mui-checked": {
                                                color: pink[600],
                                              },
                                            }}
                                            color="secondary"
                                          />
                                        }
                                        sx={{
                                          ".css-19g4vwf-MuiTypography-root": {
                                            fontSize: "1.3rem",
                                            color: "#000000",
                                          },
                                        }}
                                        label={applicableOfferData.name}
                                        onChange={(e) => {
                                          this.onCheckBoxClick(
                                            applicableOfferData,
                                            applicableOfferData.appliedOfferId
                                          );
                                        }}
                                        labelPlacement="end"
                                        checked={
                                          applicableOfferData.isApplied == 1
                                            ? true
                                            : false
                                        }
                                      />
                                      {applicableOfferData.isApplied !== 2 ? (
                                        <div
                                          style={{
                                            marginLeft: "40px",
                                            fontSize: "1.1rem",
                                          }}
                                        >
                                          {applicableOfferData.discription}
                                        </div>
                                      ) : (
                                        <FormHelperText
                                          style={{
                                            marginLeft: "40px",
                                          }}
                                          error
                                        >
                                          {
                                            "Due to a shortage of stock, the offer is not applicable"
                                          }
                                        </FormHelperText>
                                      )}
                                    </FormGroup>
                                  </FormControl>
                                </Typography>
                              </>
                            );
                          }
                        })}
                    </Grid>
                  </Grid>
                  <br />
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="flex-between"
                  >
                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="flex-start"
                    >
                      {checkboxAllData.length !== 0 &&
                        checkboxAllData.map((applicableOfferData) => {
                          if (
                            applicableOfferData.type == 0 &&
                            applicableOfferData.level == 1
                          ) {
                            return (
                              <>
                                <Typography
                                  sx={{ p: 0, marginLeft: 2 }}
                                  variant="h6"
                                  color="textSecondary"
                                >
                                  <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                      <FormControlLabel
                                        value="end"
                                        control={
                                          <Checkbox
                                            sx={{
                                              color: checkboxShadowColor,
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 30,
                                              },
                                              "&.Mui-checked": {
                                                color: pink[600],
                                              },
                                            }}
                                            color="secondary"
                                          />
                                        }
                                        sx={{
                                          ".css-19g4vwf-MuiTypography-root": {
                                            fontSize: "1.3rem",
                                            color: "#000000",
                                          },
                                        }}
                                        label={applicableOfferData.name}
                                        onChange={(e) => {
                                          this.onCheckBoxClick(
                                            applicableOfferData,
                                            applicableOfferData.appliedOfferId
                                          );
                                        }}
                                        labelPlacement="end"
                                        checked={
                                          applicableOfferData.isApplied == 1
                                            ? true
                                            : false
                                        }
                                      />
                                    </FormGroup>{" "}
                                    {applicableOfferData.isApplied !== 2 ? (
                                      <div
                                        style={{
                                          marginLeft: "40px",
                                          fontSize: "1.1rem",
                                        }}
                                      >
                                        {applicableOfferData.discription}
                                      </div>
                                    ) : (
                                      <FormHelperText
                                        style={{
                                          marginLeft: "40px",
                                        }}
                                        error
                                      >
                                        {
                                          "Due to a shortage of stock, the offer is not applicable"
                                        }
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Typography>
                              </>
                            );
                          }
                        })}
                    </Grid>

                    <Grid
                      item
                      sm={6}
                      xs={6}
                      md={6}
                      lg={6}
                      container
                      justifyContent="flex-start"
                    >
                      {checkboxAllData.length !== 0 &&
                        checkboxAllData.map((applicableOfferData) => {
                          if (
                            applicableOfferData.type == 1 &&
                            applicableOfferData.level == 1
                          ) {
                            return (
                              <>
                                <Typography
                                  sx={{ p: 0, marginLeft: 2 }}
                                  variant="h6"
                                  color="textSecondary"
                                >
                                  <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                      <FormControlLabel
                                        value="end"
                                        control={
                                          <Checkbox
                                            sx={{
                                              color: checkboxShadowColor,
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 30,
                                              },
                                              "&.Mui-checked": {
                                                color: pink[600],
                                              },
                                            }}
                                            color="secondary"
                                          />
                                        }
                                        sx={{
                                          ".css-19g4vwf-MuiTypography-root": {
                                            fontSize: "1.5rem",
                                            color: "#000000",
                                          },
                                        }}
                                        label={applicableOfferData.name}
                                        onChange={(e) => {
                                          this.onCheckBoxClick(
                                            applicableOfferData,
                                            applicableOfferData.appliedOfferId
                                          );
                                        }}
                                        labelPlacement="end"
                                        checked={
                                          applicableOfferData.isApplied == 1
                                            ? true
                                            : false
                                        }
                                      />
                                      {applicableOfferData.isApplied !== 2 ? (
                                        <div
                                          style={{
                                            marginLeft: "40px",
                                            fontSize: "1.1rem",
                                          }}
                                        >
                                          {applicableOfferData.discription}
                                        </div>
                                      ) : (
                                        <FormHelperText
                                          style={{
                                            marginLeft: "40px",
                                          }}
                                          error
                                        >
                                          {
                                            "Due to a shortage of stock, the offer is not applicable"
                                          }
                                        </FormHelperText>
                                      )}
                                    </FormGroup>
                                  </FormControl>
                                </Typography>
                              </>
                            );
                          }
                        })}
                    </Grid>
                  </Grid>
                  <br />
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    container
                    justifyContent="flex-end"
                  >
                    <ButtonCompo
                      sx={{
                        bgcolor: "dodgerblue",
                        color: "#fbff00",
                      }}
                      size="medium"
                      type="button"
                      variant="contained"
                      name={"Submit"}
                      onClick={this.clickSubmitHandler}
                      fullWidth={false}
                    />
                  </Grid>
                </BootstrapDialogTitle>
              </BootstrapDialog>
            </>
          )}
          <br />
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            lg={12}
            container
            justifyContent="flex-end"
          >
            <ButtonCompo
              sx={{
                bgcolor: "dodgerblue",
                color: "#fbff00",
              }}
              size="medium"
              type="button"
              variant="contained"
              name={"Save Bill"}
              onClick={this.clickSubmitOfferHandler}
              fullWidth={false}
            />
          </Grid>
        </Paper>
      </>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  showLoador,
  showNotification,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BillingWithOffer);

// <Table stickyHeader aria-label="sticky table">
//                     <TableHead>
//                       <TableRow>
//                         <TableCell align="center" sx={{ width: "25%" }}>
//                           Firm Name
//                         </TableCell>
//                         <TableCell align="center" sx={{ width: "25%" }}>
//                           Owner Name
//                         </TableCell>
//                         <TableCell align="center" sx={{ width: "25%" }}>
//                           State Name
//                         </TableCell>
//                         <TableCell align="center" sx={{ width: "25%" }}>
//                           Address
//                         </TableCell>
//                       </TableRow>
//                     </TableHead>
//                     <TableRow>
//                       <TableCell align="center" sx={{ width: "25%" }}>
//                         {
//                           billData.stockStakeHolderBillsAfterApplyingOffers.outLet
//                             .firmName
//                         }
//                       </TableCell>
//                       <TableCell align="center" sx={{ width: "25%" }}>
//                         {
//                           billData.stockStakeHolderBillsAfterApplyingOffers.outLet
//                             .ownerName
//                         }
//                       </TableCell>
//                       <TableCell align="center" sx={{ width: "25%" }}>
//                         {
//                           billData.stockStakeHolderBillsAfterApplyingOffers.outLet.state
//                             .name
//                         }
//                       </TableCell>
//                       <TableCell align="center" sx={{ width: "25%" }}>
//                         {billData.stockStakeHolderBillsAfterApplyingOffers.outLet.address}
//                       </TableCell>
//                     </TableRow>
//                   </Table>
