import React from "react";
import { connect } from "react-redux";

import { Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { compose } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import { matrixReportInfoJson } from "../../DynamicFormsJson/Transaction/matrixReportGenerate";
import LandingScreenHeader from "../../components/Comman/LandingScreenHeader";
import { withRouter } from "../../components/withRouter";
import AddFilterForm from './AddFilterForm';
import GraphDetails from './GraphDetails';
import AddQueryWithParameter from './QueryWithParameterForm';
import ReportForm from './ReportForm';
import TableColumnsDetails from './TableColumns';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

class ReportGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
      fieldData: {},
      DataToSet: [],
      value: 0,
      total: 0,
      totalAmt: 25.5,
      open: false,
      orderList: [],
      salesPersonId: "",
    };
  }

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  toggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  changeTab = (value) => {
    this.setState({
      value: value
    })
  }
  onCancel = () => {
    this.props.navigate(-1)
  }
  render() {
    const {
      value
    } = this.state;
    const { reportList } = this.props;
    return (
      <>
        <Paper
          sx={{
            p: 2,
            borderRadius: 2,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            tableColumnsToFilter={[]}
            screenTitle={matrixReportInfoJson.screenTitle}
            showPdfDownload={false}
            showExcelDownload={false}
            showAddButton={false}
            showTable={false}
            showBackButton={true}
            onCancel={this.onCancel}
          />
          <br />
          <>
            <Tabs
              value={value}
              onChange={this.handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
            >
              <Tab
                label={"Add Basic Info"}
              />
               <Tab
                disabled={Object.keys(reportList.reportData).length == 0 ? true : false}
                label={"Add Filter"}
              />
              <Tab
                disabled={Object.keys(reportList.reportData).length == 0 ? true : false}
                label={"Add Query And Parameter 1"}
              />
             
              <Tab
                disabled={Object.keys(reportList.reportData).length == 0 ? true : false}
                label={"Add Table Colums 1"}
              />

              <Tab
                disabled={Object.keys(reportList.reportData).length == 0 ? true : false}
                label={"Add Query And Parameter 2"}
              />
             
              <Tab
                disabled={Object.keys(reportList.reportData).length == 0 ? true : false}
                label={"Add Table Colums 2"}
              />
              <Tab
                disabled={Object.keys(reportList.reportData).length == 0 ? true : false}
                label={"Add Query And Parameter 3"}
              />
             
              <Tab
                disabled={Object.keys(reportList.reportData).length == 0 ? true : false}
                label={"Add Table Colums 3"}
              />
              <Tab
                disabled={Object.keys(reportList.reportData).length == 0 ? true : false}
                label={"Add Graph Details"}
              />
            </Tabs>
            <TabPanel index={0} value={value} >
              <ReportForm changeTab={this.changeTab} />
            </TabPanel>
            <TabPanel index={1} value={value} >
              <AddFilterForm changeTab={this.changeTab} />
            </TabPanel>
            <TabPanel index={2} value={value} >
              <AddQueryWithParameter value={value}changeTab={this.changeTab} />
            </TabPanel>
            <TabPanel index={3} value={value} >
              <TableColumnsDetails  value={value} changeTab={this.changeTab} />
            </TabPanel>
            <TabPanel index={4} value={value} >
              <AddQueryWithParameter  value={value}changeTab={this.changeTab} />
            </TabPanel>
            <TabPanel index={5} value={value} >
              <TableColumnsDetails value={value} changeTab={this.changeTab} />
            </TabPanel>
            <TabPanel index={6} value={value} >
              <AddQueryWithParameter value={value}changeTab={this.changeTab} />
            </TabPanel>
            <TabPanel index={7} value={value} >
              <TableColumnsDetails value={value}changeTab={this.changeTab} />
            </TabPanel>
            <TabPanel index={8} value={value} >
              <GraphDetails />
            </TabPanel>
          </>
        </Paper>

      </>
    );
  }
}
const mapStateToProps = (state) => ({
  reportList: state.report,

});
const mapDispatchToProps = {

};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ReportGenerator);