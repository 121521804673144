import endpoint from "../../config/endpoints";

export const rateConfigurationJson = {
  formPath: "/rate-configuration-form",
  apiBaseURL: endpoint.productRateConfiguration+"/header-by",
  deleteURL: endpoint.productRateConfiguration,
  screenTitle: "Rate Configuration",
  showAddButton: true,
  showPdfDownload: true,
  pdfFileName: "Rate Configuration",
  showExcelDownload: true,
  excelFileName: "Rate Configuration",
  tableColumnsToFilter: [
    {
      columnDisplayName: "Stock Holder Type",
      columnKeyName: "stockStakeHolderTypeName",
      isChecked: true,
    },
    {
      columnDisplayName: "Category",
      columnKeyName: "categoryName",
      isChecked: true,
    },
    {
      columnDisplayName: "Configuration Name",
      columnKeyName: "name",
      isChecked: true,
    },
    {
      columnDisplayName: "Reference Date",
      columnKeyName: "dateForMrpRate",
      isChecked: true,
    },
  ],
  searchList: [
    {
      label: "Stock Holder Type",
      controlType: "multiSelect",
      placeHolder: "Select Stock Holder Type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stockStakeHolderTypeDetail",
      dataKey: "stockStakeHolderType",
      isRootLevelKey: true,
      isMandatory: true,
    },

    {
      label: "Category",
      controlType: "multiSelect",
      placeHolder: "Select Category",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "categoryDetail",
      dataKey: "category",
      isRootLevelKey: true,
      isMandatory: true,
    },
  ],

  fieldMeta: [
    {
      label: "Stock Holder Type",
      controlType: "autocomplete",
      placeHolder: "Select Stock Holder Type",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "stockStakeHolderTypeDetail",
      dataKey: "stockStakeHolderType",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Category",
      controlType: "autocomplete",
      placeHolder: "Select Category",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,

      masterName: "categoryDetail",
      dataKey: "category",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Reference Date",
      controlType: "datepicker",
      placeHolder: "",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      disable: true,
      dataKey: "dateForMrpRate",
      isMandatory: true,
    },
    {
      label: "Configuration Name",
      controlType: "textfield",
      placeHolder: "Enter Name of Configuration",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "name",
      isMandatory: true,
    },
    {
      label: "Type",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      disable: true,
      dataKey: "rateType",
      tableDataKey: "rateType",
      defaultValue: "1",
      radioGroupItems: [
        {
          label: "Rate tax Inclusive",
          value: "1",
        },
        {
          label: "Tax Extra ",
          value: "0",
        },
      ],
      isMandatory: true,
    },
    {
      label: "Percentage",
      controlType: "textfield",
      placeHolder: "Enter Percentage",
      inputType: "number",
      md: 6,
      lg: 6,
      sm: 6,
      disable: true,
      xs: 12,
      dataKey: "marginPercentage",
      isMandatory: true,
      validName: "zero",
      valid: true,
    },
  ],
};
