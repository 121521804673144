import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  tag: [],
};
let URL = endpoints.tag;
const tagSlice = createSlice({
  name: "tag",
  initialState,
  reducers: {
    tagSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        tag: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { tagSuccess, resetState } = tagSlice.actions;

export default tagSlice.reducer;

export const getTag = () => async (dispatch) => {
  try {
    const response = apiGet({
      url: URL,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((tagObject, index) => {
          let tagData = {
            index: index + 1,
            id: tagObject.id === null ? "" : tagObject.id,
            name: tagObject.name === null ? "" : tagObject.name,
            shortName: tagObject.shortName === null ? "" : tagObject.shortName,
            isActive: tagObject.isActive === null ? "" : tagObject.isActive,
          };
          return tagData;
        });
        dispatch(tagSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};

export const setTag =
  ({ row }) =>
  async (dispatch) => {
    dispatch(tagSuccess({ row }));
  };
