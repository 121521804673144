import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  paymentRecieptData: [],
  paymentByDate: [],
  paymentRecieptDetail: [],
};
let URL = endpoints.paymentReceipt;
let URL2 = endpoints.paymentReceiptDetail;

const paymentRecieptSlice = createSlice({
  name: "Payment Reciept",
  initialState,
  reducers: {
    paymentRecieptSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        paymentRecieptData: row,
        isFetch: true,
      };
    },

    paymentByDateSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        paymentByDate: row,
      };
    },

    paymentRecieptDetailSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        paymentRecieptDetail: row,
      };
    },
    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  paymentRecieptSuccess,
  paymentByDateSuccess,
  paymentRecieptDetailSuccess,
  resetState,
} = paymentRecieptSlice.actions;

export default paymentRecieptSlice.reducer;

export const getPaymentReciept =
  ({ holderId, fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/by-date-and-stock-stake-holder?holderId=" +
          holderId +
          "&fromDate=" +
          fromDate +
          "&toDate=" +
          toDate,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((paymentRecieptObject, index) => {
            let paymentRecieptData = {
              index: index + 1,
              id:
                paymentRecieptObject.id === null ? "" : paymentRecieptObject.id,
              fromStockStakeHolder:
                paymentRecieptObject.fromStockStakeHolder === null
                  ? ""
                  : paymentRecieptObject.fromStockStakeHolder,
              fromStockStakeHolderFirmName:
                paymentRecieptObject.fromStockStakeHolderFirmName === null
                  ? ""
                  : paymentRecieptObject.fromStockStakeHolderFirmName,
              transactionNo:
                paymentRecieptObject.transactionNo === null
                  ? ""
                  : paymentRecieptObject.transactionNo,
              date:
                paymentRecieptObject.date === null
                  ? ""
                  : paymentRecieptObject.date,
              amt:
                paymentRecieptObject.amt === null
                  ? ""
                  : paymentRecieptObject.amt.toFixed(2),
              settleRemainingAmt:
                paymentRecieptObject.settleRemainingAmt === null
                  ? ""
                  : paymentRecieptObject.settleRemainingAmt.toFixed(2),
              payType:
                paymentRecieptObject.payType === null
                  ? ""
                  : paymentRecieptObject.payType === 0
                  ? "Cash"
                  : paymentRecieptObject.payType === 1
                  ? "Online"
                  : "Cheque",
              toStockStakeHolder:
                paymentRecieptObject.toStockStakeHolder === null
                  ? ""
                  : paymentRecieptObject.toStockStakeHolder,

              payRefNo:
                paymentRecieptObject.payRefNo === null
                  ? ""
                  : paymentRecieptObject.payRefNo,
              payBank:
                paymentRecieptObject.payBank === null
                  ? ""
                  : paymentRecieptObject.payBank,

              remark:
                paymentRecieptObject.remark === null
                  ? ""
                  : paymentRecieptObject.remark,
              remark2:
                paymentRecieptObject.remark2 === null
                  ? ""
                  : paymentRecieptObject.remark2,
              insertedUserId:
                paymentRecieptObject.insertedUserId === null
                  ? ""
                  : paymentRecieptObject.insertedUserId,
              insertDateTime:
                paymentRecieptObject.insertDateTime === null
                  ? ""
                  : paymentRecieptObject.insertDateTime,
              deleteStatus:
                paymentRecieptObject.amt ===
                paymentRecieptObject.settleRemainingAmt
                  ? 0
                  : 1,
            };

            return paymentRecieptData;
          });
          dispatch(paymentRecieptSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getPaymentByDate =
  ({ fromDate, toDate }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-date?fromDate=" + fromDate + "&toDate=" + toDate,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((paymentByDateObj, index) => {
            let paymentByDateData = {
              index: index + 1,
              id: paymentByDateObj.id === null ? "" : paymentByDateObj.id,
              amt: paymentByDateObj.amt === null ? "" : paymentByDateObj.amt,
              date: paymentByDateObj.date === null ? "" : paymentByDateObj.date,
              payType:
                paymentByDateObj.payType === null
                  ? ""
                  : paymentByDateObj.payType === 0
                  ? "Cash"
                  : paymentByDateObj.payType === 1
                  ? "Online"
                  : "Cheque",
              toStakeHolderNameAndFirmName:
                paymentByDateObj.toStockStakeHolderOwnerName === null
                  ? ""
                  : (paymentByDateObj.toStockStakeHolderFirmName +" ("+
                    paymentByDateObj.toStockStakeHolderOwnerName) +")",
              toStockStakeHolderOwnerName:
                paymentByDateObj.toStockStakeHolderOwnerName === null
                  ? ""
                  : paymentByDateObj.toStockStakeHolderOwnerName,
              fromStockStakeHolder:
                paymentByDateObj.fromStockStakeHolder === null
                  ? ""
                  : paymentByDateObj.fromStockStakeHolder,
              toStockStakeHolder:
                paymentByDateObj.toStockStakeHolder === null
                  ? ""
                  : paymentByDateObj.toStockStakeHolder,
              transactionNo:
                paymentByDateObj.transactionNo === null
                  ? ""
                  : paymentByDateObj.transactionNo,
              payRefNo:
                paymentByDateObj.payRefNo === null
                  ? ""
                  : paymentByDateObj.payRefNo,
              payBank:
                paymentByDateObj.payBank === null
                  ? ""
                  : paymentByDateObj.payBank,
              date: paymentByDateObj.date === null ? "" : paymentByDateObj.date,
              remark:
                paymentByDateObj.remark === null ? "" : paymentByDateObj.remark,
              amt: paymentByDateObj.amt === null ? "" : paymentByDateObj.amt,
              settleRemainingAmt:
                paymentByDateObj.settleRemainingAmt === null
                  ? ""
                  : paymentByDateObj.settleRemainingAmt,
              remark2:
                paymentByDateObj.remark2 === null
                  ? ""
                  : paymentByDateObj.remark2,
              fromStockStakeHolderFirmName:
                paymentByDateObj.fromStockStakeHolderFirmName === null
                  ? ""
                  : paymentByDateObj.fromStockStakeHolderFirmName,
              fromStockStakeHolderOwnerName:
                paymentByDateObj.fromStockStakeHolderOwnerName === null
                  ? ""
                  : paymentByDateObj.fromStockStakeHolderOwnerName,
              toStockStakeHolderFirmName:
                paymentByDateObj.toStockStakeHolderFirmName === null
                  ? ""
                  : paymentByDateObj.toStockStakeHolderFirmName,
              toStockStakeHolderOwnerName:
                paymentByDateObj.toStockStakeHolderOwnerName === null
                  ? ""
                  : paymentByDateObj.toStockStakeHolderOwnerName,
            };

            return paymentByDateData;
          });
          dispatch(paymentByDateSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getPaymentRecieptDetail =
  ({ paymentRecieptId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL2 + "/by-payment-reciept?paymentRecieptId=" + paymentRecieptId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((paymentRecieptDetailObject, index) => {
            let paymentReceiptDetailData = {
              index: index + 1,
              id:
                paymentRecieptDetailObject.id === null
                  ? ""
                  : paymentRecieptDetailObject.id,
              paymentReciept:
                paymentRecieptDetailObject.paymentReciept === null
                  ? ""
                  : paymentRecieptDetailObject.paymentReciept,
              stockStakeHolderBillHeader:
                paymentRecieptDetailObject.stockStakeHolderBillHeader === null
                  ? ""
                  : paymentRecieptDetailObject.stockStakeHolderBillHeader,
              id:
                paymentRecieptDetailObject.stockStakeHolderBillHeader === null
                  ? ""
                  : paymentRecieptDetailObject.stockStakeHolderBillHeader.id,
              billNo:
                paymentRecieptDetailObject.stockStakeHolderBillHeader === null
                  ? ""
                  : paymentRecieptDetailObject.stockStakeHolderBillHeader
                      .billNo,
              fromBill:
                paymentRecieptDetailObject.stockStakeHolderBillHeader === null
                  ? ""
                  : paymentRecieptDetailObject.stockStakeHolderBillHeader
                      .fromBill,
              toBill:
                paymentRecieptDetailObject.stockStakeHolderBillHeader === null
                  ? ""
                  : paymentRecieptDetailObject.stockStakeHolderBillHeader
                      .toBill,
              billDate:
                paymentRecieptDetailObject.stockStakeHolderBillHeader === null
                  ? ""
                  : paymentRecieptDetailObject.stockStakeHolderBillHeader
                      .billDate,
              itemDiscountAmt:
                paymentRecieptDetailObject.stockStakeHolderBillHeader === null
                  ? ""
                  : paymentRecieptDetailObject.stockStakeHolderBillHeader
                      .itemDiscountAmt,
              discountOnbillPer:
                paymentRecieptDetailObject.stockStakeHolderBillHeader === null
                  ? ""
                  : paymentRecieptDetailObject.stockStakeHolderBillHeader
                      .discountOnbillPer,
              discountOnbillPerAmt:
                paymentRecieptDetailObject.stockStakeHolderBillHeader === null
                  ? ""
                  : paymentRecieptDetailObject.stockStakeHolderBillHeader
                      .discountOnbillPerAmt,
              discountOnbillAmt:
                paymentRecieptDetailObject.stockStakeHolderBillHeader === null
                  ? ""
                  : paymentRecieptDetailObject.stockStakeHolderBillHeader
                      .discountOnbillAmt,
              cessAmt:
                paymentRecieptDetailObject.stockStakeHolderBillHeader === null
                  ? ""
                  : paymentRecieptDetailObject.stockStakeHolderBillHeader
                      .cessAmt,
              payoutAmt:
                paymentRecieptDetailObject.stockStakeHolderBillHeader === null
                  ? ""
                  : paymentRecieptDetailObject.stockStakeHolderBillHeader
                      .payoutAmt,
              taxableAmt:
                paymentRecieptDetailObject.stockStakeHolderBillHeader === null
                  ? ""
                  : paymentRecieptDetailObject.stockStakeHolderBillHeader.taxableAmt.toFixed(
                      2
                    ),
              totalAmt:
                paymentRecieptDetailObject.stockStakeHolderBillHeader === null
                  ? ""
                  : paymentRecieptDetailObject.stockStakeHolderBillHeader.totalAmt.toFixed(
                      2
                    ),
              cgstAmt:
                paymentRecieptDetailObject.stockStakeHolderBillHeader === null
                  ? ""
                  : paymentRecieptDetailObject.stockStakeHolderBillHeader
                      .cgstAmt,
              sgstAmt:
                paymentRecieptDetailObject.stockStakeHolderBillHeader === null
                  ? ""
                  : paymentRecieptDetailObject.stockStakeHolderBillHeader
                      .sgstAmt,
              igstAmt:
                paymentRecieptDetailObject.stockStakeHolderBillHeader === null
                  ? ""
                  : paymentRecieptDetailObject.stockStakeHolderBillHeader
                      .igstAmt,
              isRecieved:
                paymentRecieptDetailObject.stockStakeHolderBillHeader === null
                  ? ""
                  : paymentRecieptDetailObject.stockStakeHolderBillHeader
                      .isRecieved,
              status:
                paymentRecieptDetailObject.stockStakeHolderBillHeader === null
                  ? ""
                  : paymentRecieptDetailObject.stockStakeHolderBillHeader
                      .status,
              acknoledgeNo:
                paymentRecieptDetailObject.stockStakeHolderBillHeader === null
                  ? ""
                  : paymentRecieptDetailObject.stockStakeHolderBillHeader
                      .acknoledgeNo,
              payoutIsSettle:
                paymentRecieptDetailObject.stockStakeHolderBillHeader === null
                  ? ""
                  : paymentRecieptDetailObject.stockStakeHolderBillHeader
                      .payoutIsSettle,
              remainingAmt:
                paymentRecieptDetailObject.stockStakeHolderBillHeader === null
                  ? ""
                  : paymentRecieptDetailObject.stockStakeHolderBillHeader
                      .remainingAmt,
              isSettle:
                paymentRecieptDetailObject.stockStakeHolderBillHeader === null
                  ? ""
                  : paymentRecieptDetailObject.stockStakeHolderBillHeader
                      .isSettle,
              remainingAmt:
                paymentRecieptDetailObject.stockStakeHolderBillHeader === null
                  ? ""
                  : paymentRecieptDetailObject.stockStakeHolderBillHeader
                      .remainingAmt,
              roundOff:
                paymentRecieptDetailObject.stockStakeHolderBillHeader === null
                  ? ""
                  : paymentRecieptDetailObject.stockStakeHolderBillHeader
                      .roundOff,
              date:
                paymentRecieptDetailObject.amt === null
                  ? ""
                  : paymentRecieptDetailObject.date,
              amt:
                paymentRecieptDetailObject.amt === null
                  ? ""
                  : paymentRecieptDetailObject.amt,
            };

            return paymentReceiptDetailData;
          });
          dispatch(paymentRecieptDetailSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
