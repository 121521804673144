import endpoint from "../../config/endpoints";

export const TimeSlotJson = {
    showSaveNextBtn: true,
    screenTitle: "Time Slot",
    formPath: "/time-slot-form",
    apiBaseURL: endpoint.timeSlot,
    showTitle: true,
    showCancel: false,
    showSaveBtn: true,
    showAddButton: true,

    fieldMeta: [

        {
            label: "From Time",
            controlType: "timePicker",
            placeHolder: "",
            md: 4,
            lg: 4,
            sm: 4,
            xs: 12,
            dataKey: "fromTime",
            isMandatory: true,
        },

        {
            label: "To Time",
            controlType: "timePicker",
            placeHolder: "",
            md: 4,
            lg: 4,
            sm: 4,
            xs: 12,
            dataKey: "toTime",
            isMandatory: true,
        },
    ],

};
