import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  productRateConfigurationNew: [],
  product: [],
  productRate: [],
};
let URL = endpoints.productRateConfiguration;
let mrpURL = endpoints.mrpConfiguration;
let productRateConfigurationDetailsUrl =
  endpoints.productRateConfigurationDetails;
const productRateConfigurationSlice = createSlice({
  name: "productRateConfiguration",
  initialState,
  reducers: {
    productRateConfigurationSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        productRateConfigurationNew: row,
      };
    },
    productRateConfigurationByCategoryIdAndDateSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        product: row,
      };
    },
    productRateConfigurationByProductIdSuccess: (
      state = cloneDeep(initialState),
      action
    ) => {
      const { row } = action.payload;
      return {
        ...state,
        productRate: row,
      };
    },

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const {
  productRateConfigurationSuccess,
  productRateConfigurationByCategoryIdAndDateSuccess,
  resetState,
  productRateConfigurationByProductIdSuccess,
} = productRateConfigurationSlice.actions;

export default productRateConfigurationSlice.reducer;

export const setRateConfList =
  ({ row }) =>
  async (dispatch) => {
    const productRateConfigurationByCategoryIdAndDate = row.map(
      (productRateConfigurationByCategoryIdAndDateObject) => {
        return {
          ...productRateConfigurationByCategoryIdAndDateObject,
          rate:
            productRateConfigurationByCategoryIdAndDateObject.mrp !== null
              ? +productRateConfigurationByCategoryIdAndDateObject.mrp -
                (+productRateConfigurationByCategoryIdAndDateObject.mrp *
                  +productRateConfigurationByCategoryIdAndDateObject.marginPercentage) /
                  100
              : "",
        };
      }
    );
    dispatch(
      productRateConfigurationByCategoryIdAndDateSuccess({
        row: productRateConfigurationByCategoryIdAndDate,
      })
    );
  };

export const getProductRateConfigurationByStakeHolderTypeIdsAndCategoryIds =
  ({ stockStakeHolderTypeIds, categoryIds }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          URL +
          "/header-by-categories-and-stock-stake-holder-types?categoryIds=" +
          categoryIds +
          "&stockStakeHolderTypeIds=" +
          stockStakeHolderTypeIds,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((productRateConfigurationObject, index) => {
            let rateConfigurationData = {
              index: index + 1,
              id:
                productRateConfigurationObject.id === null
                  ? ""
                  : productRateConfigurationObject.id,
              name:
                productRateConfigurationObject.name === null
                  ? ""
                  : productRateConfigurationObject.name,
              stockStakeHolderTypeName:
                productRateConfigurationObject.stockStakeHolderType === null &&
                productRateConfigurationObject.stockStakeHolderType.name ===
                  null
                  ? ""
                  : productRateConfigurationObject.stockStakeHolderType.name,

              stockStakeHolderType:
                productRateConfigurationObject.stockStakeHolderType !== null &&
                productRateConfigurationObject.stockStakeHolderType.id
                  ? productRateConfigurationObject.stockStakeHolderType.id
                  : "",
              categoryName:
                productRateConfigurationObject.category != null &&
                productRateConfigurationObject.category.name
                  ? productRateConfigurationObject.category.name
                  : "",
              category:
                productRateConfigurationObject.category != null &&
                productRateConfigurationObject.category.id
                  ? productRateConfigurationObject.category.id
                  : "",

              dateForMrpRate:
                productRateConfigurationObject.dateForMrpRate === null
                  ? ""
                  : productRateConfigurationObject.dateForMrpRate,
            };
            return rateConfigurationData;
          });
          dispatch(productRateConfigurationSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getProductRateConfigurationByCategoryIdAndDate =
  ({ categoryId, selectedDate, marginPercentage }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          mrpURL +
          "/by-category-and-date?categoryId=" +
          categoryId +
          "&selectedDate=" +
          selectedDate,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map(
            (productRateConfigurationByCategoryIdAndDateObject, index) => {
              let rateConfigurationByCategoryIdAndDateData = {
                index: index + 1,
                id:
                  productRateConfigurationByCategoryIdAndDateObject.id === null
                    ? ""
                    : productRateConfigurationByCategoryIdAndDateObject.id,
                name:
                  productRateConfigurationByCategoryIdAndDateObject !== null &&
                  productRateConfigurationByCategoryIdAndDateObject.product
                    .name !== null
                    ? productRateConfigurationByCategoryIdAndDateObject.product
                        .name
                    : "",
                productId:
                  productRateConfigurationByCategoryIdAndDateObject !== null &&
                  productRateConfigurationByCategoryIdAndDateObject.product
                    .id !== null
                    ? productRateConfigurationByCategoryIdAndDateObject.product
                        .id
                    : "",
                mrp:
                  productRateConfigurationByCategoryIdAndDateObject.mrp !== null
                    ? productRateConfigurationByCategoryIdAndDateObject.mrp.toFixed(
                        2
                      )
                    : "",
                publishDate:
                  productRateConfigurationByCategoryIdAndDateObject.publishDate ===
                  null
                    ? ""
                    : productRateConfigurationByCategoryIdAndDateObject.publishDate,

                marginPercentage: marginPercentage,
                marginPercentageErr: 0,
                rate:
                  productRateConfigurationByCategoryIdAndDateObject.mrp !== null
                    ? (
                        productRateConfigurationByCategoryIdAndDateObject.mrp -
                        (productRateConfigurationByCategoryIdAndDateObject.mrp *
                          marginPercentage) /
                          100
                      ).toFixed(2)
                    : "",
              };
              return rateConfigurationByCategoryIdAndDateData;
            }
          );
          dispatch(productRateConfigurationByCategoryIdAndDateSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getProductRateConfigurationByCategoryIdAndDateForEdit =
  ({ productRateConfigurationHeaderId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url:
          productRateConfigurationDetailsUrl +
          "/get-with-not-inserted?productRateConfigurationHeaderId=" +
          productRateConfigurationHeaderId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map(
            (productRateConfigurationByCategoryIdAndDateObject, index) => {
              let mrp =
                productRateConfigurationByCategoryIdAndDateObject.mrp !== null
                  ? productRateConfigurationByCategoryIdAndDateObject.mrp
                  : "";
              let rateConfigurationByCategoryIdAndDateData = {
                index: index + 1,
                id:
                  productRateConfigurationByCategoryIdAndDateObject.id === null
                    ? ""
                    : productRateConfigurationByCategoryIdAndDateObject.id,
                primaryId:
                  productRateConfigurationByCategoryIdAndDateObject.primaryId ===
                  null
                    ? ""
                    : productRateConfigurationByCategoryIdAndDateObject.primaryId,
                name:
                  productRateConfigurationByCategoryIdAndDateObject !== null &&
                  productRateConfigurationByCategoryIdAndDateObject.product
                    .name !== null
                    ? productRateConfigurationByCategoryIdAndDateObject.product
                        .name
                    : "",
                productId:
                  productRateConfigurationByCategoryIdAndDateObject !== null &&
                  productRateConfigurationByCategoryIdAndDateObject.product
                    .id !== null
                    ? productRateConfigurationByCategoryIdAndDateObject.product
                        .id
                    : "",

                mrp:
                  productRateConfigurationByCategoryIdAndDateObject.mrp !== null
                    ? productRateConfigurationByCategoryIdAndDateObject.mrp.toFixed(
                        2
                      )
                    : "",
                publishDate:
                  productRateConfigurationByCategoryIdAndDateObject.publishDate ===
                  null
                    ? ""
                    : productRateConfigurationByCategoryIdAndDateObject.publishDate,

                marginPercentage:
                  productRateConfigurationByCategoryIdAndDateObject.marginPercentage ===
                  null
                    ? 0
                    : productRateConfigurationByCategoryIdAndDateObject.marginPercentage,
                marginPercentageErr: 0,
                rate:
                  productRateConfigurationByCategoryIdAndDateObject.marginPercentage ===
                  null
                    ? 0
                    : (
                        mrp -
                        (mrp *
                          productRateConfigurationByCategoryIdAndDateObject.marginPercentage) /
                          100
                      ).toFixed(2),
              };
              return rateConfigurationByCategoryIdAndDateData;
            }
          );
          dispatch(productRateConfigurationByCategoryIdAndDateSuccess({ row }));
          return { response: [], success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getProductRateConfigurationByProductId =
  ({ productId }) =>
  async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/by-product?productId=" + productId,
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((productRateConfigurationObject, index) => {
            let rateConfigurationData = {
              index: index + 1,
              id:
                productRateConfigurationObject.id === null
                  ? ""
                  : productRateConfigurationObject.id,
              name:
                productRateConfigurationObject.name === null
                  ? ""
                  : productRateConfigurationObject.name,
              stockStakeHolderTypeName:
                productRateConfigurationObject.stockStakeHolderType === null &&
                productRateConfigurationObject.stockStakeHolderType.name ===
                  null
                  ? ""
                  : productRateConfigurationObject.stockStakeHolderType.name,

              stockStakeHolderType:
                productRateConfigurationObject.stockStakeHolderType === null
                  ? ""
                  : productRateConfigurationObject.stockStakeHolderType.id,
              categoryName:
                productRateConfigurationObject.category !== null &&
                productRateConfigurationObject.category.name
                  ? productRateConfigurationObject.category.name
                  : "",
              category:
                productRateConfigurationObject.category !== null &&
                productRateConfigurationObject.category.id
                  ? productRateConfigurationObject.category.id
                  : "",

              dateForMrpRate:
                productRateConfigurationObject.dateForMrpRate === null
                  ? ""
                  : productRateConfigurationObject.dateForMrpRate,
              marginPercentage: 0,
            };
            return rateConfigurationData;
          });
          dispatch(productRateConfigurationByProductIdSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getRateConfigurationList =
  ({ row }) =>
  async (dispatch) => {
    dispatch(productRateConfigurationByProductIdSuccess({ row }));
  };

// Added by akash 20-10-23
export const getProductRateConfigurationByAllStakeHolderTypeIdsAndAllCategoryIds =
  () => async (dispatch) => {
    try {
      const response = apiGet({
        url: URL + "/for-stake-holder",
      }).then(({ data, success }) => {
        if (success) {
          const row = data.data.map((productRateConfigurationObject, index) => {
            let rateConfigurationData = {
              index: index + 1,
              id:
                productRateConfigurationObject.id === null
                  ? ""
                  : productRateConfigurationObject.id,
              name:
                productRateConfigurationObject.name === null
                  ? ""
                  : productRateConfigurationObject.name,
              stockStakeHolderTypeName:
                productRateConfigurationObject.stockStakeHolderType === null &&
                productRateConfigurationObject.stockStakeHolderType.name ===
                  null
                  ? ""
                  : productRateConfigurationObject.stockStakeHolderType.name,

              stockStakeHolderType:
                productRateConfigurationObject.stockStakeHolderType !== null &&
                productRateConfigurationObject.stockStakeHolderType.id
                  ? productRateConfigurationObject.stockStakeHolderType.id
                  : "",
              categoryName:
                productRateConfigurationObject.category != null &&
                productRateConfigurationObject.category.name
                  ? productRateConfigurationObject.category.name
                  : "",
              category:
                productRateConfigurationObject.category != null &&
                productRateConfigurationObject.category.id
                  ? productRateConfigurationObject.category.id
                  : "",

              dateForMrpRate:
                productRateConfigurationObject.dateForMrpRate === null
                  ? ""
                  : productRateConfigurationObject.dateForMrpRate,
            };
            return rateConfigurationData;
          });
          dispatch(productRateConfigurationSuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };
