import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
  leaveType: [],
  leaveSummary:[]
};
let URL1 = endpoints.leaveSummary;
let URL = endpoints.leaveType;

const leaveTypeSlice = createSlice({
  name: "leaveType",
  initialState,
  reducers: {
    leaveTypeSuccess: (state = cloneDeep(initialState), action) => {
      const { row } = action.payload;
      return {
        ...state,
        leaveType: row,
      };
    },
    leaveSummarySuccess: (state = cloneDeep(initialState), action) => {
        const { row } = action.payload;
        return {
          ...state,
          leaveSummary: row,
        };
      },
    

    resetState: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const { leaveTypeSuccess, leaveSummarySuccess,resetState } = leaveTypeSlice.actions;

export default leaveTypeSlice.reducer;

export const getLeaveType = () => async (dispatch) => {
  try {
    const response = apiGet({
      
      url: URL ,
    }).then(({ data, success }) => {
      if (success) {
        const { content } = data.data;
        const row = content.map((leaveTypeObject, index) => {
          let leaveTypeData = {
            index: index + 1,
            id: leaveTypeObject.id === null ? "" : leaveTypeObject.id,
            name:
              leaveTypeObject.name === null
                ? ""
                : leaveTypeObject.name ,
               
                leaveSummaryName: leaveTypeObject.leaveSummary!=null && leaveTypeObject.leaveSummary.name != null
                ? ""
                : leaveTypeObject.leaveSummary.name ,
            shortName:
              leaveTypeObject.shortName === null
                ? ""
                : leaveTypeObject.shortName,
                isActive: leaveTypeObject.isActive === null
                ? ""
                : leaveTypeObject.isActive,
                extra1:leaveTypeObject.extra1 === null
                ? ""
                : leaveTypeObject.extra1,
                leaveSummary:  leaveTypeObject.leaveSummary === null
                ? ""
                : leaveTypeObject.leaveSummary,
                remark:leaveTypeObject.remark === null
                ? ""
                : leaveTypeObject.remark,
                company:leaveTypeObject.company === null
                ? ""
                : leaveTypeObject.company,
               
          };
          return leaveTypeData;
        });
        dispatch(leaveTypeSuccess({ row }));
        return { response: row, success };
      }
      return { response: [], success };
    });
    return response;
  } catch (e) {
    return console.error(e.message);
  }
};


export const getLeaveSummary = () => async (dispatch) => {
    try {
      const response = apiGet({
        
        url: URL1+"/get-active-list" ,
      }).then(({ data, success }) => {
        if (success) {
        
          const row =  data.data.map((leaveTypeObject, index) => {
            let leaveTypeData = {
              index: index + 1,
              id: leaveTypeObject.id === null ? "" : leaveTypeObject.id,
              name:
                leaveTypeObject.name === null
                  ? ""
                  : leaveTypeObject.name ,
                  leaveSummaryName: leaveTypeObject.name === null
                  ? ""
                  : leaveTypeObject.name ,
             
              shortName:
                leaveTypeObject.shortName === null
                  ? ""
                  : leaveTypeObject.shortName,
                  isActive: leaveTypeObject.isActive === null
                  ? ""
                  : leaveTypeObject.isActive,
                  extra1:leaveTypeObject.extra1 === null
                  ? ""
                  : leaveTypeObject.extra1,
            };
            return leaveTypeData;
          });
          dispatch(leaveSummarySuccess({ row }));
          return { response: row, success };
        }
        return { response: [], success };
      });
      return response;
    } catch (e) {
      return console.error(e.message);
    }
  };

  export const setLeaveType =
  ({ row }) =>
  async (dispatch) => {
    dispatch(leaveTypeSuccess({ row }));
  };
