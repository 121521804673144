import CloseIcon from "@mui/icons-material/Close";
import { Grid, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { LabelCompo } from "../../components/Comman/Label";
import { ButtonCompo } from "../../components/Comman/Button";
import BasicDatePicker from "../../components/Comman/DatePickerEx";
import { fromdateMsg, todateMsg } from "../../config/messageconstant";
import { redColor, titleColor } from "../../config/ColorObj";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { classes, children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const FilterPopUp = ({
    handleCloses,
    open,
    onSearch,
}) => {
    const [fromDate, setFromdate] = React.useState("");
    const [toDate, setTodate] = React.useState("");
    const [formErrors, setFormErrors] = React.useState({});

    React.useEffect(() => {

    }, []);

    const changeDate = (name) => (date) => {
        if (name === "fromDate") {
            setFromdate(date);
        } else if (name === "toDate") {
            setTodate(date);
        }
    };

    const onSearchPopUp = () => {
        if (checkValidation()) {
            onSearch(fromDate, toDate);
        }
    };

    const checkValidation = () => {
        let formErrors = {};
        let formIsValid = true;

        if (fromDate === "") {
            formIsValid = false;
            formErrors["fromDateError"] = fromdateMsg;
        }
        if (toDate === "") {
            formIsValid = false;
            formErrors["toDateError"] = todateMsg;
        }

        setFormErrors(formErrors);
        return formIsValid;
    };

    return (
        <>
            <Paper sx={{ p: 3, pt: 2, borderRadius: 2 }} elevation={0}>
                <BootstrapDialog
                    onClose={handleCloses}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    fullWidth
                    maxWidth="md"
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleCloses}
                    >
                        Received Order
                    </BootstrapDialogTitle>

                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        container
                        sx={{ p: 2 }}
                        justifyContent="space-evenly"
                    >

                        <DialogContent>
                            <Grid
                                item
                                sm={12}
                                xs={12}
                                md={12}
                                lg={12}
                                container
                                justifyContent="space-between"
                            >
                                <Grid
                                    item
                                    sm={5.5}
                                    xs={5.5}
                                    md={5.5}
                                    lg={5.5}
                                    container
                                    justifyContent="space-evenly"
                                >
                                    <Grid item xs={12} md={5} sm={5}>
                                        <LabelCompo
                                            className="text-black"
                                            style={{ fontSize: 16, color: redColor }}
                                            label="* "
                                        />
                                        <LabelCompo
                                            className="text-black"
                                            style={{ fontSize: 16, color: titleColor }}
                                            label="From Date :"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={7} sm={7}>
                                        <BasicDatePicker
                                            color="primary"
                                            name="fromDate"
                                            size="small"
                                            value={fromDate}
                                            disabled={false}
                                            fullWidth
                                            onChange={changeDate("fromDate")}
                                            errorText={
                                                formErrors["fromDateError"]
                                                    ? formErrors["fromDateError"]
                                                    : ""
                                            }
                                            isError={
                                                formErrors["fromDateError"]
                                                    ? formErrors["fromDateError"]
                                                    : ""
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    sm={5.5}
                                    xs={5.5}
                                    md={5.5}
                                    lg={5.5}
                                    container
                                    justifyContent="space-evenly"
                                >
                                    <Grid item xs={12} md={5} sm={5}>
                                        <LabelCompo
                                            className="text-black"
                                            style={{ fontSize: 16, color: redColor }}
                                            label="* "
                                        />
                                        <LabelCompo
                                            className="text-black"
                                            style={{ fontSize: 16, color: titleColor }}
                                            label="To Date :"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={7} sm={7}>
                                        <BasicDatePicker
                                            color="primary"
                                            name="toDate"
                                            size="small"
                                            value={toDate}
                                            minDate={fromDate}
                                            disabled={false}
                                            fullWidth
                                            onChange={changeDate("toDate")}
                                            errorText={
                                                formErrors["toDateError"] ? formErrors["toDateError"] : ""
                                            }
                                            isError={
                                                formErrors["toDateError"] ? formErrors["toDateError"] : ""
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <br />
                            <br />
                            <Grid
                                item
                                sm={12}
                                xs={12}
                                md={12}
                                lg={12}
                                rowSpacing={2}
                                columnSpacing={{ xs: 12, sm: 6, md: 1 }}
                                container
                                justifyContent="right"
                            >
                                <ButtonCompo
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    name="Search"
                                    onClick={onSearchPopUp}
                                />
                            </Grid>
                        </DialogContent>
                    </Grid>
                </BootstrapDialog >
            </Paper >
        </>
    );
};
const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FilterPopUp);
