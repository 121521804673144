import endpoint from "../../config/endpoints";

export const leaveAllotmentJson = {
  showSaveNextBtn: true,
  apiBaseURL: endpoint.assignRateConfigurationToStakeholder,
  formPath: "/assign-rate-configuration-to-stakeholder-form",
  screenTitle: " Leave Structure Assigation",
  showTitle: true,
  showCancel: false,
  showSaveBtn: false,
  fieldMetaAdmin:[
    {
        label: " Leave Structure",
        controlType: "autocomplete",
        placeHolder: "Select  Leave Structure",
        md: 6,
        lg: 6,
        sm: 6,
        xs: 12,
        masterName: "leaveStructureDetail",
        dataKey: "leaveStructure",
        isRootLevelKey: true,
        isMandatory: true,
      },
      {
        label: "Stock Stake Holder Type",
        controlType: "autocomplete",
        placeHolder: "Select Stock Stake Holder Type",
        md: 6,
        lg: 6,
        sm: 6,
        xs: 12,
        masterName: "stockStakeHolderTypeDetail",
        dataKey: "stockStakeHolderType",
        isRootLevelKey: true,
        isMandatory: true,
      },

    {
      label: "Region",
      controlType: "autocomplete",
      placeHolder: "Select Region",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "regionDetail",
      dataKey: "region",
      getListId: "region",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "State",
      controlType: "autocomplete",
      placeHolder: "Select State",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "stateDetail",
      dataKey: "state",
      getListFrom: "region",
      getListId: "state",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Zone",
      controlType: "multiSelect",
      placeHolder: "Select Zone",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "zoneDetail",
      dataKey: "zone",
      getListFrom: "state",
      getListId: "zone",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "District",
      controlType: "multiSelect",
      placeHolder: "Select District",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "districtDetail",
      dataKey: "district",
      getListFrom: "zone",
      getListId: "district",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Taluka",
      controlType: "multiSelect",
      placeHolder: "Select Taluka",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "talukaDetail",
      dataKey: "taluka",
      getListFrom: "district",
      getListId: "taluka",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area ",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      labelSM: 2,
      labelMD: 2,
      labelLG: 2,
      valueSM: 10,
      valueMD: 10,
      valueLG: 10,
      masterName: "areaDetail",
      dataKey: "area",
      getListFrom: "taluka",
      isRootLevelKey: false,
      isMandatory: true,
      getListId: "area",
    },
   
  ],

  fieldMeta: [
    {
        label: " Leave Structure",
        controlType: "autocomplete",
        placeHolder: "Select  Leave Structure",
        md: 6,
        lg: 6,
        sm: 6,
        xs: 12,
        masterName: "leaveStructureDetail",
        dataKey: "leaveStructure",
        isRootLevelKey: true,
        isMandatory: true,
      },
      {
        label: "Stock Stake Holder Type",
        controlType: "autocomplete",
        placeHolder: "Select Stock Stake Holder Type",
        md: 6,
        lg: 6,
        sm: 6,
        xs: 12,
        masterName: "stockStakeHolderTypeDetail",
        dataKey: "stockStakeHolderType",
        isRootLevelKey: true,
        isMandatory: true,
      },
    {
      label: "Area",
      controlType: "multiSelect",
      placeHolder: "Select Area ",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      masterName: "areaDetail",
      dataKey: "area",
      isRootLevelKey: false,
      isMandatory: true,
      getListId: "area",
    },
   
  ],
  fieldMetaForSearch: [
    {
      label: "Stock Holder Type",
      controlType: "multiSelect",
      placeHolder: "Select Stock Holder Type",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      getListId: "stockStakeHolderType",
      masterName: "stakeHolderTypeDetail",
      dataKey: "stockStakeHolderType",
      isRootLevelKey: false,
      isMandatory: true,
    },
    {
      label: "Stock Holder Name",
      controlType: "multiSelect",
      placeHolder: "Select Stock Holder",
      md: 5,
      lg: 5,
      sm: 5,
      xs: 12,
      masterName: "stakeHolderName",
      dataKey: "stackholder",
      getListFrom: "stockStakeHolderType",
      isRootLevelKey: false,
      isMandatory: true,
    },
  ],
};