import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import endpoints from "../config/endpoints";
import { apiGet } from "../utils/api_service";
const initialState = {
    investedAmount: [],
};
let URL = endpoints.investedAmount;
const investedAmountSlice = createSlice({
    name: "investedAmount",
    initialState,
    reducers: {
        investedAmountSuccess: (state = cloneDeep(initialState), action) => {
            const { row } = action.payload;
            return {
                ...state,
                investedAmount: row,

            };
        },

        resetState: (state = cloneDeep(initialState), action) => {
            return {
                ...state,
                ...initialState,
            };
        },
    },
});

export const { investedAmountSuccess, resetState } = investedAmountSlice.actions;

export default investedAmountSlice.reducer;

export const getInvestedAmount = () => async (dispatch) => {
    try {
        const response = apiGet({
            url: URL,
        }).then(({ data, success }) => {
            if (success) {
                let index = 0;
                const { content } = data.data;
                const row = content.map((investedAmountObject, index) => {
                    let investedAmountData = {
                        index: index + 1,
                        id: investedAmountObject.id === null ? "" : investedAmountObject.id,
                        name: investedAmountObject.name === null ? "" : investedAmountObject.name,
                        isActive: investedAmountObject.isActive === null ? "" : investedAmountObject.isActive,
                    };
                    return investedAmountData;
                });
                dispatch(investedAmountSuccess({ row }));
                return { response: row, success };
            }
            return { response: [], success };
        });
        return response;
    } catch (e) {
        return console.error(e.message);
    }
};
