import React from "react";
import { connect } from "react-redux";
import DynamicMainScreen from "../../components/dynamicscreens/DynamicMainScreen";
import endpoint from "../../config/endpoints";
import {
  noInternetMsg,
  serverMsg,
  statusmsg,
} from "../../config/messageconstant";
import { requestGRNJSON } from "../../DynamicFormsJson/Transaction/requestGRN";
import {
  getRequestGRNList,
  getTodaysRequestGRNList,
} from "../../Slice/requestGRN.slice";
import { requestGRNColumns } from "../../tableColumns/table-columns";
import { apiGet } from "../../utils/api_service";
import { showLoador, showNotification } from "../Landing/Landing.slice";
import { downloadF } from "../PDF/PDFDownload";
class RequestGRNList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: "",
      toDate: "",
    };
  }

  onSearchData = async (data) => {
    const { getRequestGRNList, showLoador, showNotification } = this.props;
    this.setState({
      fromDate: data.fromDate,
      toDate: data.toDate,
    });
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getRequestGRNList({
        fromDate: data.fromDate,
        toDate: data.toDate,
      }).then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };
  async componentDidMount() {
    await this.getData();
  }
  getData = async () => {
    const { getTodaysRequestGRNList, showLoador, showNotification } =
      this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getTodaysRequestGRNList().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  rowSendRequest = (rowData) => {
    const { showLoador, showNotification } = this.props;

    swal({
      text: "Do you want to send the request?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        if (navigator.onLine) {
          showLoador({ loador: true });
          await apiGet({
            url:
              endpoint.grnGVNHeader +
              "/" +
              rowData.id +
              "/update-status?status=1",
          }).then(({ data, success }) => {
            showLoador({ loador: false });
            if (success) {
              showNotification({ msg: statusmsg });
              this.getData();
            }
          });
        } else {
          showNotification({ msg: noInternetMsg, severity: "error" });
        }
      }
    });
  };

  rowPDF = (rowData) => {
    downloadF({
      url:
        endpoint.creditNote +
        "/report-by-id?id=" +
        rowData.id +
        "&documentType=1",
      ext: "pdf",
      openNewTab: true,
    }).then((response) => {
      if (!response) {
        this.props.showNotification({
          msg: serverMsg,
          severity: "error",
        });
      } else {
      }
    });
  };

  rowEdit = (rowData) => {
    window.location.replace("/request-grn-edit/" + rowData.id);
  };

  generateExcelStatic = (rowData) => {
    console.log(rowData);
    const { showLoador, showNotification } = this.props;
    const { fromDate, toDate } = this.state;
    if (navigator.onLine) {
      showLoador({ loador: true });
      downloadF({
        url:
          endpoint.grnGVNHeader +
          "/excel-by-date?fromDate=" + fromDate + "&toDate=" + toDate,
        ext: "xlsx",
        openNewTab: true,
      }).then((response) => {
        showLoador({ loador: false });
        if (!response) {
          this.props.showNotification({
            msg: serverMsg,
            severity: "error",
          });
        } else {
        }
      });
    } else {
      showNotification({ msg: noInternetMsg, severity: "error" });
    }
  };

  render() {
    const { dynamicMasterData } = this.state;
    const { requestGRNList } = this.props;
    return (
      <>
        <DynamicMainScreen
          dynamicMasterData={dynamicMasterData}
          searchList={requestGRNJSON.searchList}
          onSearchData={this.onSearchData}
          formPathView={requestGRNJSON.formPathView}
          formPath={requestGRNJSON.formPath}
          screenTitle={requestGRNJSON.screenTitle}
          fieldMeta={requestGRNJSON.fieldMeta}
          showPdfDownload={false}
          showExcelDownload={true}
          isActiveURL={requestGRNJSON.isActiveURL}
          showAddButton={requestGRNJSON.showAddButton}
          tableColumnsToFilter={requestGRNJSON.tableColumnsToFilter}
          tableColumns={requestGRNColumns}
          tableData={requestGRNList.requestGRNListData}
          apiBaseURL={requestGRNJSON.apiBaseURL}
          callApi={false}
          getTableDataById={true}
          showSearchBox={true}
          rowPDF={this.rowPDF}
          showPdf={false}
          showEditIcon={true}
          rowEdit={this.rowEdit}
          deleteConditionName="status"
          deleteConditionValue="0"
          editConditionName="status"
          editConditionValue="0"
          showHeadView={true}
          showViewButtonInTable={true}
          showSendRequestIcon={true}
          rowSendRequest={this.rowSendRequest}
          sendRequestIconName="status"
          sendRequestIconValue="0"
          showPegination={false}
          getTableData={this.getData}
          showDeleteIcon={false}
          staticExcel={true}
          generateExcelStatic={this.generateExcelStatic}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  requestGRNList: state.requestGRN,
});
const mapDispatchToProps = {
  showNotification,
  showLoador,
  getRequestGRNList, 
  getTodaysRequestGRNList,
};
export default connect(mapStateToProps, mapDispatchToProps)(RequestGRNList);
