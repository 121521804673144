import { Paper } from "@mui/material";
import { compose } from "@reduxjs/toolkit";
import React, { Component } from "react";
import { connect } from "react-redux";
import { downloadF } from "../../Pages/PDF/PDFDownload";
import swal from "sweetalert";
import {
  showLoador,
  showNotification,
} from "../../Pages/Landing/Landing.slice";
import { resetReducList } from "../../CommonActions/User.action";

import * as msgConstant from "../../config/messageconstant";
import { apiDelete, apiGet, apiPost } from "../../utils/api_service";
import LandingScreenHeader from "../Comman/LandingScreenHeader";
import RTTable from "../Comman/RT/RTTable";
import { withRouter } from "../withRouter";
import DynamicFormWithTable from "./DynamicFormWithTable";
import dayjs from "dayjs";
import { LabelCompo } from "../Comman/Label";
import { redColor, titleColor } from "../../config/ColorObj";
import { Grid } from "@mui/material";
import ShowDynamicTableWithData from "./ShowDynamicTableWithData";
import SearchPopUp from "./SearchPopUp";

export const OperationMode = {
  Add: "Add",
  Edit: "Edit",
  Status: "Status",
};

class DynamicMainScreen extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      popUpData: {},
      tableColumnsToFilter: this.props.tableColumnsToFilter,
      fieldData: {},
      fieldDataForSearch: {},
      showTable: true,
      showPopUp: false,
      rowList: [],
      showForm: false,
      currentOperationMode: "",
      searchValue: "",
      fromDate: "",
      toDate: "",
      title: "",
      tableRow: [],
      rowData: {},
      openFilter: false,
      fieldDataForModule: {},
    };
  }

  componentDidMount() {
    const { callApi = true } = this.props;

    if (callApi) {
      this.getData();
    }
    const formData = localStorage.getItem("formData");
    if (formData != null) {
      const dataToSet = this.setFormData(JSON.parse(formData));
      this.props.onSearchData(JSON.parse(formData));

      this.setState({
        fieldDataForSearch: dataToSet,
      });
    } else {
      const { searchList = [] } = this.props;
      const dataToReset = {};
      searchList.forEach((currentField) => {
        if (currentField.defaultValue) {
          dataToReset[currentField.dataKey] = currentField.defaultValue;
        }
      });
      this.setState({
        fieldDataForSearch: dataToReset,
      });
    }
    const moduleObjData = localStorage.getItem("moduleObj");
    this.setState({
      fieldDataForModule:
        moduleObjData != null ? JSON.parse(moduleObjData) : {},
    });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.formData !== nextProps.formData) {
      this.setState({
        fieldDataForSearch: {
          ...this.state.fieldDataForSearch,
          ...nextProps.formData,
        },
      });
    }
    if (this.props.rowListData !== nextProps.rowListData) {
      this.setState({
        rowList: nextProps.rowListData,
      });
    }
  }
  getData = async () => {
    const { getTableData, showLoador, showNotification } = this.props;
    if (navigator.onLine) {
      showLoador({ loador: true });
      await getTableData().then(({ success }) => {
        showLoador({ loador: false });
        if (!success) {
          showNotification({ msg: msgConstant.serverMsg, severity: "error" });
        }
      });
    } else {
      showNotification({ msg: msgConstant.noInternetMsg, severity: "error" });
    }
  };
  getDataStatic = async () => {
    const { getTableData } = this.props;
    await getTableData();
  };
  onRTSelectMultipleChange = (selectedOption) => {
    const { tableColumnsToFilter } = this.state;
    const selectedItem = tableColumnsToFilter.filter(
      (item) => item.columnKeyName === selectedOption.columnKeyName
    )[0];
    selectedItem.isChecked = !selectedItem.isChecked;

    this.setState({
      tableColumnsToFilter: tableColumnsToFilter,
    });
  };

  onDataChangeForSearch = (fieldName, newValue) => {
    const { searchList, popUpClose } = this.props;
    const { fieldDataForSearch } = this.state;
    const dataToSearch = {};
    const dataToReset = { ...this.state.fieldDataForSearch };
    let listError = false;
    searchList.map((currentField) => {
      if (currentField.getDate && currentField.dataKey === fieldName) {
        dataToReset[currentField.getDate] = newValue.add(365, "day");
      }
      if (currentField.dataKey === fieldName && currentField.getListId) {
        // dataToSearch[fieldName] = newValue;
        const masterKeyList = currentField.getListId.split(",");
        masterKeyList.map((key) => {
          if (fieldName === key) {
            dataToSearch[key] = newValue;
          } else if (fieldDataForSearch[key]) {
            dataToSearch[key] = fieldDataForSearch[key];
          }
          return null;
        });
        if (popUpClose) {
          this.onOpenFilter();
        }
        this.props.getListById(dataToSearch);
      }
      if (currentField.getListFrom) {
        const masterKeyList = currentField.getListFrom.split(",");
        masterKeyList.map((key) => {
          if (key == fieldName && dataToReset[key]) {
            delete dataToReset[currentField.dataKey];
          }
        });
      }
      if (
        !listError &&
        fieldDataForSearch[currentField.dataKey] &&
        currentField.controlType != "datepicker" &&
        currentField.getListFrom == undefined
      ) {
        dataToReset[currentField.dataKey] =
          fieldDataForSearch[currentField.dataKey];
      }
      return null;
    });

    dataToReset[fieldName] = newValue;
    this.setState({
      fieldDataForSearch: dataToReset,
    });
  };
  onSearch = (searchValue) => {
    const { onFilterData, searchTrue = true } = this.props;
    if (searchTrue) {
      this.setState({
        searchValue: searchValue,
      });
    } else {
      onFilterData(searchValue);
    }
  };

  onAddButtonClick = () => {
    const { formPath, isResetReduceList = true, resetReducList } = this.props;
    if (isResetReduceList) {
      resetReducList();
    }

    this.props.navigate(formPath);
  };
  onOpenFilter = () => {
    this.setState({
      openFilter: !this.state.openFilter,
    });
  };
  rowAdd = (rowData) => {
    const { openFormPath } = this.props;
    this.props.navigate(openFormPath + "/" + rowData.id);
  };
  rowTransfer = (rowData) => {
    const { openFormPathStatus } = this.props;
    this.props.navigate(openFormPathStatus + "/" + rowData.id);
  };
  rowEdit = (rowData) => {
    const { formPath, rowEdit, editAuto = true } = this.props;
    if (editAuto) {
      this.props.navigate(formPath + "/" + rowData.id);
    } else {
      rowEdit(rowData);
    }
  };
  rowCopy = (rowData) => {
    const { formPath, rowCopy, copyAuto = true } = this.props;
    if (copyAuto) {
      this.props.navigate(formPath + "/" + rowData.id + "/1");
    } else {
      rowCopy(rowData);
    }
  };

  rowView = (rowData) => {
    const { formPathView, rowView, staticView = false } = this.props;
    if (staticView) {
      rowView(rowData);
    } else {
      this.props.navigate(formPathView + "/" + rowData.id);
    }
  };

  rowSend = (rowData) => {
    const { formPathForsend } = this.props;
    this.props.navigate(formPathForsend + "/" + rowData.id);
  };

  rowOutlet = (rowData) => {
    const { formPathForOutlet } = this.props;
    this.props.navigate(formPathForOutlet + "/" + rowData.id);
  };
  onSave = async (dataToSave, isToggle = true) => {
    const { getTableDataById = false } = this.props;
    let dataToSearchList = "";
    if (
      getTableDataById &&
      Object.keys(this.state.fieldDataForSearch).length != 0
    ) {
      dataToSearchList = await this.setFromDataForSearch();
    }
    const { apiBaseURL } = this.props;
    if (navigator.onLine) {
      this.props.showLoador({ loador: true });
      apiPost({
        url: apiBaseURL,
        postBody: dataToSave,
      }).then(({ success }) => {
        if (success) {
          this.props.showLoador({ loador: false });
          const { currentOperationMode } = this.state;
          this.props.showNotification({
            msg:
              currentOperationMode === OperationMode.Add
                ? msgConstant.savemsg
                : currentOperationMode === OperationMode.Status
                  ? msgConstant.statusmsg
                  : msgConstant.updateMsg,
          });
          const { getTableDataById = false, callApi = true } = this.props;
          if (
            getTableDataById &&
            Object.keys(this.state.fieldDataForSearch).length != 0
          ) {
            this.props.onSearchData(dataToSearchList);
          } else if (callApi) {
            this.getData();
          } else {
            this.getDataStatic();
          }

          if (isToggle) {
            this.setState({
              fieldData: {},
              currentOperationMode: "",
            });
          }
          if (!isToggle) {
            let fieldData = {};
            const { fieldMeta } = this.props;
            fieldMeta.map((currentField) => {
              if (currentField.defaultValue) {
                fieldData[currentField.dataKey] = currentField.defaultValue;
              }
              if (
                currentField.controlType === "autocomplete" &&
                !currentField.isRootLevelKey
              ) {
                fieldData[currentField.dataKey] = dataToSave[
                  currentField.dataKey
                ]["id"]
                  ? dataToSave[currentField.dataKey]["id"]
                  : "";
              } else if (
                currentField.controlType === "autocomplete" &&
                !currentField.isRootLevelKey
              ) {
                fieldData[currentField.dataKey] = dataToSave[
                  currentField.dataKey
                ]
                  ? dataToSave[currentField.dataKey]
                  : "";
              }
              return null;
            });

            this.setState({
              fieldData: fieldData,
              currentOperationMode: OperationMode.Add,
            });
          }
        } else {
          this.props.showNotification({
            msg: msgConstant.saveFailedMsg,
            severity: "error",
          });
          this.props.showLoador({ loador: false });
        }
      });
    } else {
      this.props.showNotification({
        msg: msgConstant.noInternetMsg,
        severity: "error",
      });
    }
  };
  onStatusChange = async (dataToSave) => {
    const { isActiveURL = "", getTableDataById = false } = this.props;
    let dataToSearchList = "";
    if (
      getTableDataById &&
      Object.keys(this.state.fieldDataForSearch).length != 0
    ) {
      dataToSearchList = await this.setFromDataForSearch();
    }
    if (navigator.onLine) {
      this.props.showLoador({ loador: true });
      apiPost({
        url:
          isActiveURL +
          "?id=" +
          dataToSave.id +
          "&status=" +
          dataToSave.isActive,
      }).then(({ success }) => {
        if (success) {
          this.props.showLoador({ loador: false });
          this.props.showNotification({
            msg: "Status updated",
          });
          const { getTableDataById = false, callApi = true } = this.props;
          if (
            getTableDataById &&
            Object.keys(this.state.fieldDataForSearch).length != 0
          ) {
            this.props.onSearchData(dataToSearchList);
          } else if (callApi) {
            this.getData();
          } else {
            this.getDataStatic();
          }
          this.setState({
            fieldData: {},
            currentOperationMode: "",
          });
        } else {
          this.props.showNotification({
            msg: msgConstant.statusFailedMsg,
            severity: "error",
          });

          this.props.showLoador({ loador: false });
        }
      });
    } else {
      this.props.showNotification({
        msg: msgConstant.noInternetMsg,
        severity: "error",
      });
    }
  };
  rowDelete = (rowData) => {
    const { deleteURLStatic = false, onDeleteStatic } = this.props;

    swal({
      title: "Are you sure?",
      text: "Once deleted, Information can not be Retrieved. Would you like to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (deleteURLStatic) {
          onDeleteStatic(rowData);
        } else {
          this.onDelete(rowData);
        }
      }
    });
  };

  onDelete = async (rowdata) => {
    const { getTableDataById = false, callApi = true } = this.props;
    let dataToSearchList = "";
    if (
      getTableDataById &&
      Object.keys(this.state.fieldDataForSearch).length != 0
    ) {
      dataToSearchList = await this.setFromDataForSearch();
    }
    const {
      apiBaseURL,
      deleteURL = "",
      deleteMsgAuto = false,
      deleteMapping = true,
    } = this.props;
    if (navigator.onLine) {
      if (deleteMapping) {
        this.props.showLoador({ loador: true });
        apiDelete({
          url:
            deleteURL != ""
              ? deleteURL + "/" + rowdata.id
              : apiBaseURL + "/" + rowdata.id,
        }).then(({ data, success }) => {
          if (success) {
            this.props.showLoador({ loador: false });
            this.props.showNotification({
              msg: deleteMsgAuto ? data.data.message : msgConstant.deletemsg,
              severity: data.data.error ? "error" : "success",
            });

            if (
              getTableDataById &&
              Object.keys(this.state.fieldDataForSearch).length != 0
            ) {
              this.props.onSearchData(dataToSearchList);
            } else if (callApi) {
              this.getData();
            } else {
              this.getDataStatic();
            }
            this.setState({
              currentOperationMode: "",
            });
          } else {
            this.props.showNotification({
              msg: msgConstant.deleteFailedMsg,
              severity: "error",
            });

            this.props.showLoador({ loador: false });
          }
        });
      } else {
        this.props.showLoador({ loador: true });
        apiGet({
          url: apiBaseURL + "/" + rowdata.id + "/delete-by-id",
        }).then(({ success }) => {
          if (success) {
            this.props.showLoador({ loador: false });
            this.props.showNotification({
              msg: deleteMsgAuto ? data.data.message : msgConstant.deletemsg,
              severity: data.data.error ? "error" : "success",
            });
            const { getTableDataById = false, callApi = true } = this.props;
            if (
              getTableDataById &&
              Object.keys(this.state.fieldDataForSearch).length != 0
            ) {
              this.props.onSearchData(dataToSearchList);
            } else if (callApi) {
              this.getData();
            } else {
              this.getDataStatic();
            }
            this.setState({
              currentOperationMode: "",
            });
          } else {
            this.props.showNotification({
              msg: msgConstant.deleteFailedMsg,
              severity: "error",
            });
            this.props.showLoador({ loador: false });
          }
        });
      }
    } else {
      this.props.showNotification({
        msg: msgConstant.noInternetMsg,
        severity: "error",
      });
    }
  };

  getFilteredTableData = () => {
    const { tableData, tableColumns } = this.props;
    const { searchValue } = this.state;
    const columsFil = tableColumns.filter((item) => {
      return item.canSearch;
    });
    return tableData.filter((currentRow) => {
      let isValid = false;
      columsFil.some((col) => {
        if (
          currentRow[col.name] &&
          currentRow[col.name]
            .toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          isValid = true;
          return true;
        }
        return null;
      });
      return isValid;
    });
  };

  onChangeStatus = (rowData) => {
    const {
      fieldMeta = [],
      isActiveURL = "",
      isActiveURLFalse,
      isActiveURLFalseMethod,
    } = this.props;
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        if (!isActiveURLFalse) {
          if (isActiveURL == "") {
            const dataToSave = {
              ...rowData,
              id: rowData["id"],
            };

            fieldMeta.forEach((currentField) => {
              if (
                currentField.controlType === "autocomplete" &&
                !currentField.isRootLevelKey
              ) {
                dataToSave[currentField.dataKey] = {};
                dataToSave[currentField.dataKey]["id"] = rowData[
                  currentField.dataKey
                ]
                  ? rowData[currentField.dataKey]
                  : "";
              } else {
                dataToSave[currentField.dataKey] = rowData[currentField.dataKey]
                  ? rowData[currentField.dataKey]
                  : "";
              }
            });

            dataToSave["isActive"] = rowData.isActive == 1 ? "0" : "1";
            this.setState({
              currentOperationMode: OperationMode.Status,
            });
            this.onSave(dataToSave, false);
          } else {
            const dataToSave = {
              id: rowData["id"],
            };
            dataToSave["isActive"] = rowData.isActive == 1 ? "0" : "1";
            this.setState({
              currentOperationMode: OperationMode.Status,
            });
            this.onStatusChange(dataToSave, false);
          }
        } else {
          isActiveURLFalseMethod(rowData);
        }
      }
    });
  };

  generateExcel = () => {
    const {
      changeExcelURL = "",
      showExtension = true,
      staticExcel,
      generateExcelStatic,
    } = this.props;
    if (staticExcel) {
      generateExcelStatic();
    } else {
      this.downloadPDF(
        "xls",
        changeExcelURL != "" ? changeExcelURL : "",
        showExtension
      );
    }
  };

  generatePDF = () => {
    const {
      changePDFURL = "",
      generatePDFStatic,
      staticPDF = false,
    } = this.props;
    if (staticPDF) {
      generatePDFStatic();
    } else {
      this.downloadPDF("pdf", changePDFURL != "" ? changePDFURL : "report");
    }
  };

  downloadPDF = (ext, addURL, showExtension) => {
    const { apiBaseURL, changeExcel = "" } = this.props;
    downloadF({
      url: showExtension
        ? apiBaseURL + "/" + addURL + "?documentType=1"
        : changeExcel != ""
          ? changeExcel
          : "/api/distributiondevelop/v1/export" + changeExcel + "/" + addURL,
      ext: ext,
      openNewTab: true,
    }).then((response) => {
      if (!response) {
        this.props.showNotification({
          msg: msgConstant.serverMsg,
          severity: "error",
        });
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    });
  };
  setFormData = (rowData) => {
    const { searchList = [], popUpClose = false } = this.props;

    let fieldData = {};

    searchList.forEach((currentField) => {
      let dataToSearch = {};
      const tableDataKey = currentField.tableDataKey
        ? currentField.tableDataKey
        : currentField.dataKey;
      if (currentField.controlType === "multiSelect") {
        fieldData[currentField.dataKey] = rowData[tableDataKey];
        if (currentField.getListId) {
          const masterKeyList = currentField.getListId.split(",");
          masterKeyList.map((key) => {
            if (currentField.dataKey === key) {
              dataToSearch[key] = rowData[tableDataKey];
            } else if (fieldData[key]) {
              dataToSearch[key] = fieldData[key];
            }
            return null;
          });
          if (popUpClose) {
            this.onOpenFilter();
          }
          this.props.getListById(dataToSearch);
        }
      } else if (currentField.controlType === "autocomplete/textfield") {
        if (!currentField.isRootLevelKey) {
          fieldData = {
            ...fieldData,
            [currentField.dataKey]: {
              id: rowData[tableDataKey].id,
            },
          };
        } else {
          fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
        }
        fieldData = {
          ...fieldData,
          [currentField.dataKeyName]: rowData[tableDataKey].name,
        };
      } else if (
        currentField.controlType === "autocomplete" &&
        !currentField.isRootLevelKey
      ) {
        fieldData = {
          ...fieldData,
          [currentField.dataKey]: {
            id: rowData[tableDataKey].id,
          },
        };
        if (currentField.getListId) {
          const masterKeyList = currentField.getListId.split(",");
          masterKeyList.map((key) => {
            if (currentField.dataKey === key) {
              dataToSearch[key] = rowData[tableDataKey];
            } else if (fieldData[key]) {
              dataToSearch[key] = fieldData[key];
            }
            return null;
          });
          if (popUpClose) {
            this.onOpenFilter();
          }
          this.props.getListById(dataToSearch);
        }
      } else if (
        currentField.controlType === "autocomplete" &&
        currentField.isRootLevelKey
      ) {
        fieldData = {
          ...fieldData,
          [currentField.dataKey]: rowData[tableDataKey],
        };
        if (currentField.getListId) {
          const masterKeyList = currentField.getListId.split(",");
          masterKeyList.map((key) => {
            if (currentField.dataKey === key) {
              dataToSearch[key] = rowData[tableDataKey];
            } else if (fieldData[key]) {
              dataToSearch[key] = fieldData[key];
            }
            return null;
          });
          if (popUpClose) {
            this.onOpenFilter();
          }
          this.props.getListById(dataToSearch);
        }
      } else if (currentField.controlType === "timePicker") {
        const timeDate = moment(rowData[tableDataKey], ["h:mm A"]).format(
          "HH:mm"
        );
        const timeArr = timeDate?.split(":", -1);

        fieldData[currentField.dataKey] = new Date(
          null,
          null,
          null,
          timeArr[0],
          timeArr[1]
        );
      } else if (currentField.controlType === "datepicker") {
        if (rowData[tableDataKey]) {
          fieldData[currentField.dataKey] = dayjs(
            rowData[tableDataKey],
            "DD-MM-YYYY"
          );
        }
      } else if (
        currentField.controlType === "hideTextfield" &&
        !currentField.rootValue
      ) {
        let list = dynamicMasterData[currentField["masterName"]].filter(
          (item) => {
            return item.id === rowData[tableDataKey];
          }
        );
        if (list.length !== 0) {
          fieldData[tableDataKey] = rowData[tableDataKey]?.toString();
          return;
        } else {
          fieldData[tableDataKey] = currentField.open;
          fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
        }
      } else if (
        currentField.controlType === "hideTextfield" &&
        currentField.setTextHide
      ) {
        if (rowData[currentField.tableDataKey] == currentField.open) {
          fieldData[currentField.dataKey] =
            rowData[currentField.dataKey]?.toString();
        }
      } else if (currentField.controlType === "hideDatepicker") {
        if (rowData[currentField.tableDataKey] == currentField.open) {
          const dateArr = rowData[currentField.dataKey]?.split("-");

          fieldData[currentField.dataKey] = new Date(
            dateArr[2],
            dateArr[1] - 1,
            dateArr[0]
          );
        }
      } else {
        fieldData[currentField.dataKey] = rowData[tableDataKey]?.toString();
      }
    });

    fieldData["id"] = rowData.id;
    fieldData["isActive"] = rowData.isActive;

    return fieldData;
  };
  // setFormData = (fieldDataForSearch) => {
  // const { searchList } = this.props;
  // const dataToSave = {};
  // searchList.forEach((currentField) => {
  // if (currentField.controlType === "datepicker") {
  // if (fieldDataForSearch[currentField.dataKey]) {
  // dataToSave[currentField.dataKey] = fieldDataForSearch[
  // currentField.dataKey
  // ]
  // ? dayjs(fieldDataForSearch[currentField.dataKey])
  // : "";
  // } else {
  // dataToSave[currentField.dataKey] = "";
  // }
  // } else {
  // dataToSave[currentField.dataKey] = fieldDataForSearch[
  // currentField.dataKey
  // ]
  // ? fieldDataForSearch[currentField.dataKey]
  // : "";
  // }
  // });
  // return dataToSave;
  // };
  handleToggleClosed = (flag) => {
    this.setState({
      showPopUp: flag ? this.state.showPopUp : !this.state.showPopUp,
    });
  };
  setFromDataForSearch = () => {
    const { fieldDataForSearch } = this.state;
    const { searchList = [], today } = this.props;
    const dataToSave = {};
    searchList.forEach((currentField) => {
      if (currentField.controlType === "datepicker") {
        if (fieldDataForSearch[currentField.dataKey]) {
          if (!today) {
            dataToSave[currentField.dataKey] = fieldDataForSearch[
              currentField.dataKey
            ]
              ? fieldDataForSearch[currentField.dataKey].format("DD-MM-YYYY")
              : "";
          } else {
            dataToSave[currentField.dataKey] =
              fieldDataForSearch[currentField.dataKey] !== ""
                ? fieldDataForSearch[currentField.dataKey].format("DD-MM-YYYY")
                : dayjs().format("DD-MM-YYYY");
          }
        } else {
          if (!today) {
            dataToSave[currentField.dataKey] = "";
          } else {
            dataToSave[currentField.dataKey] = dayjs().format("DD-MM-YYYY");
          }
        }
      } else {
        dataToSave[currentField.dataKey] = fieldDataForSearch[
          currentField.dataKey
        ]
          ? fieldDataForSearch[currentField.dataKey]
          : "";
      }
    });
    return dataToSave;
  };
  onFormSearch = async () => {
    const dataToSave = await this.setFromDataForSearch();
    // localStorage.setItem("formData", JSON.stringify(dataToSave));
    this.onOpenFilter();
    this.props.onSearchData(dataToSave);
  };
  rowLinkToShowData = async (row, tableRow) => {
    const { linkStatic = true, rowLinkToShowData } = this.props;
    if (linkStatic) {
      this.setState({
        popUpData: {
          row: row,
          tableRow: tableRow,
        },
      });
      this.showPopUpData(row, tableRow, false);
    } else {
      rowLinkToShowData(row, tableRow);
    }
  };
  showPopUpData = (row, tableRow, flag) => {
    const { showLoador, showNotification, showPopUpDataList = [] } = this.props;
    const filterTableCol = showPopUpDataList.filter(
      (row) => row.dataKey === tableRow.name
    );
    showLoador({ loador: true });
    apiGet({
      url: tableRow.url + row[tableRow.passId],
    }).then(({ data, success }) => {
      if (success) {
        let tableRowData = filterTableCol.length != 0 ? filterTableCol[0] : {};
        let col =
          filterTableCol.length != 0 && filterTableCol[0].columns
            ? filterTableCol[0].columns
            : [];
        let rowList = data.data.map((rowData, index) => {
          rowData = {
            ...rowData,
            index: index + 1,
          };
          let filterDataObj = tableRowData.filterData
            ? tableRowData.filterData
            : {};
          let rowInfo = { id: rowData.id };
          if (Object.keys(filterDataObj).length != 0) {
            let strData = filterDataObj
              ? filterDataObj.tableDataKey.split(".")
              : [filterDataObj.name];
            let strD = "";

            strData.map((objData, index) => {
              if (index == 0) {
                strD = rowData[objData] != null ? rowData[objData] : "";
              } else {
                strD = strD[objData] ? strD[objData] : "";
              }
            });
            rowInfo = { ...rowInfo, [filterDataObj.name]: strD };
          }

          col.map((calData) => {
            let strData = calData.tableDataKey
              ? calData.tableDataKey.split(".")
              : [calData.name];
            let strD = "";

            strData.map((objData, index) => {
              if (index == 0) {
                strD = rowData[objData] != null ? rowData[objData] : "";
              } else {
                strD = strD[objData] ? strD[objData] : "";
              }
            });
            rowInfo = {
              ...rowInfo,
              [calData.name]: strD,
            };
          });
          return { ...rowInfo, isActive: rowData.isActive };
        });
        this.setState({
          rowList: rowList,
          tableRow: tableRowData,
          rowData: row,
        });
        this.handleToggleClosed(flag);
        showLoador({ loador: false });
      } else {
        showLoador({ loador: false });
        showNotification({ msg: msgConstant.serverMsg, severity: "error" });
      }
      return { data: data.data, success };
    });
  };
  onClearData = async () => {
    this.onOpenFilter();
    this.setState({
      fieldDataForSearch: {},
    });
    this.getDataStatic();
  };
  onChangeStatusPopUp = (rowData) => {
    swal(msgConstant.changeActive, {
      buttons: ["Cancel", true],
    }).then((value) => {
      if (value) {
        const dataToSave = {
          id: rowData["id"],
        };
        dataToSave["isActive"] = rowData.isActive == 1 ? "0" : "1";
        this.setState({
          currentOperationMode: OperationMode.Status,
        });
        const { popUpData } = this.state;
        if (popUpData.tableRow.isActiveStatic) {
          this.props.onStatusChangePopUpStatic(
            dataToSave,
            this.state.popUpData.row
          );
        } else {
          this.onStatusChangePopUp(dataToSave, false);
        }
      }
    });
  };
  onStatusChangePopUp = async (dataToSave) => {
    const { popUpData } = this.state;
    if (navigator.onLine) {
      this.props.showLoador({ loador: true });
      apiPost({
        url:
          popUpData.tableRow.isActiveURL +
          "?id=" +
          dataToSave.id +
          "&isActive=" +
          dataToSave.isActive,
      }).then(({ success }) => {
        if (success) {
          this.props.showLoador({ loador: false });
          this.props.showNotification({
            msg: "Status updated",
          });
          this.showPopUpData(
            this.state.popUpData.row,
            this.state.popUpData.tableRow,
            true
          );
        } else {
          this.props.showNotification({
            msg: msgConstant.statusFailedMsg,
            severity: "error",
          });

          this.props.showLoador({ loador: false });
        }
      });
    } else {
      this.props.showNotification({
        msg: msgConstant.noInternetMsg,
        severity: "error",
      });
    }
  };

  render() {
    const {
      screenTitle = "",
      showAddButtonInTable = false,
      showPdfDownload = true,
      showExcelDownload = true,
      showAddButton = true,
      dynamicMasterData = {},
      tableColumns = [],
      showSearchBox = true,
      showInPaper = true,
      searchList = [],
      isActionColActive = true,
      showDeleteIcon = true,
      showCopyIcon,
      copyConditionValue,
      copyConditionName,
      showTransferTitle = "",
      transferConditionName,
      transferConditionValue,
      setData,
      condition,
      showSend = false,
      conditionDelete = false,
      isActiveURLFalse = false,
      showOutlet = false,
      showViewButtonInTable = false,
      showApply = false,
      showEWay = false,
      showCancel = false,
      showTransferButtonInTable = false,
      showApplyTitle = "",
      showEWayTitle = "",
      showCancelTitle = "",
      showAddTitle,
      rowApply,
      rowEWay,
      rowCancel,
      rowPDF,
      showPdf = false,
      showEditIcon = true,
      showSearch = true,
      showPegination = true,
      deleteConditionName = "",
      deleteConditionValue = "",
      editConditionName = "",
      editConditionValue = "",
      onChangeStatusStatic,
      credentialConditionName = "",
      credentialConditionValue = "",
      cancelBillConditionName = "",
      cancelBillConditionValue = "",
      showSendRequestIcon,
      rowSendRequest,
      sendRequestIconName = "",
      sendRequestIconValue = "",
      rowEdit,
      rowCopy,
      searchButton = true,
      chipCompoColor,
      today = false,
      isNote = false,
      isNoteValue = "",
      searchButtonGrid,
      onClear,
      showClear,
      showSearchData,
      rowPDFLedger,
      showPdfLeger,
      isActiveURLFalseMethod,
      DataToSet = [],
    } = this.props;
    const {
      tableColumnsToFilter = [],
      fieldDataForSearch,
      fieldDataForModule,
      showTable,
      showPopUp,
      rowList,
      tableRow,
      title,
      rowData,
      openFilter,
    } = this.state;
    return (
      <>
        <Paper
          sx={{
            p: showInPaper ? 2 : 0,
            pt: showInPaper ? 2 : 0,
            borderRadius: showInPaper ? 2 : 0,
          }}
          elevation={0}
        >
          <LandingScreenHeader
            screenTitle={screenTitle}
            showSearchBox={showSearchBox}
            showAddButton={
              showAddButton && fieldDataForModule.addApproveConfig == 1
                ? true
                : false
            }
            onAddButtonClick={this.onAddButtonClick}
            onOpenFilter={this.onOpenFilter}
            showFilterIcon={searchList.length !== 0 ? true : false}
          />
          <br />
          {showTable && (
            <>
              {searchList.length !== 0 && openFilter && (
                <>
                  <SearchPopUp
                    open={openFilter}
                    showClear={showClear}
                    showSearch={showSearchData}
                    onClear={this.onClearData}
                    handleCloses={this.onOpenFilter}
                    title={screenTitle}
                    searchList={searchList}
                    dynamicMasterData={dynamicMasterData}
                    fieldDataForSearch={fieldDataForSearch}
                    onDataChangeForSearch={this.onDataChangeForSearch}
                    onFormSearch={this.onFormSearch}
                    searchButton={searchButton}
                    today={today}
                    searchButtonGrid={searchButtonGrid}
                  />
                </>
              )}

              <LandingScreenHeader
                tableColumnsToFilter={tableColumnsToFilter}
                onRTSelectMultipleChange={this.onRTSelectMultipleChange}
                screenTitle={""}
                showSearchBox={showSearchBox}
                showPdfDownload={showPdfDownload}
                generatePDF={this.generatePDF}
                showExcelDownload={showExcelDownload}
                generateExcel={this.generateExcel}
                showAddButton={false}
                onAddButtonClick={this.onAddButtonClick}
                onSearch={this.onSearch}
                showTable={showSearch ? showTable : false}
              />
              {isNote && (
                <Grid item xs={12} sm={12} md={12}>
                  <LabelCompo
                    className="text-black"
                    style={{ fontSize: 14, color: redColor, paddingLeft: 5 }}
                    label={"Note : " + isNoteValue}
                  />
                </Grid>
              )}
              {DataToSet.length !== 0 && (
                <>
                  <Grid container sx={{ margin: 1 }} spacing={1}>
                    {DataToSet.map((dropDownData) => (
                      <Grid
                        container
                        xs={dropDownData.xs}
                        sm={dropDownData.sm}
                        md={dropDownData.md}
                        lg={dropDownData.lg}
                      >
                        <Grid
                          item
                          xs={dropDownData.labelXS ? dropDownData.labelXS : 4}
                          sm={dropDownData.labelSM ? dropDownData.labelSM : 4}
                          md={dropDownData.labelMD ? dropDownData.labelMD : 4}
                          lg={dropDownData.labelLG ? dropDownData.labelLG : 4}
                        >
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16, color: titleColor }}
                            label={dropDownData.label + " :"}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={dropDownData.valueXS ? dropDownData.valueXS : 8}
                          sm={dropDownData.valueSM ? dropDownData.valueSM : 8}
                          md={dropDownData.valueMD ? dropDownData.valueMD : 8}
                          lg={dropDownData.valueLG ? dropDownData.valueLG : 8}
                        >
                          <LabelCompo
                            className="text-black"
                            style={{ fontSize: 16 }}
                            label={dropDownData.value}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
              {tableColumns.length != 0 && (
                <RTTable
                  columns={tableColumns}
                  hiddenColumnNames={tableColumnsToFilter.map((item) => {
                    return !item.isChecked ? item.columnKeyName : "";
                  })}
                  rowPDFLedger={rowPDFLedger}
                  showPdfLeger={showPdfLeger}
                  chipCompoColor={chipCompoColor}
                  showViewButtonInTable={
                    showViewButtonInTable && fieldDataForModule.view == 1
                      ? true
                      : false
                  }
                  onChangeStatusStatic={onChangeStatusStatic}
                  showOutlet={
                    showOutlet && fieldDataForModule.editReject == 1
                      ? true
                      : false
                  }
                  showSend={showSend}
                  showEditIcon={
                    showEditIcon && fieldDataForModule.editReject == 1
                      ? true
                      : false
                  }
                  rowSend={this.rowSend}
                  showDeleteIcon={
                    showDeleteIcon &&
                      fieldDataForModule.deleteRejectApprove == 1
                      ? true
                      : false
                  }
                  showCopyIcon={
                    showCopyIcon && fieldDataForModule.editReject == 1
                      ? true
                      : false
                  }
                  copyConditionName={copyConditionName}
                  copyConditionValue={copyConditionValue}
                  transferConditionName={transferConditionName}
                  transferConditionValue={transferConditionValue}
                  showAddButtonInTable={
                    showAddButtonInTable && fieldDataForModule.editReject == 1
                      ? true
                      : false
                  }
                  tableData={this.getFilteredTableData()}
                  rowEdit={rowEdit ? rowEdit : this.rowEdit}
                  rowCopy={rowCopy ? rowCopy : this.rowCopy}
                  rowView={this.rowView}
                  rowOutlet={this.rowOutlet}
                  rowDelete={this.rowDelete}
                  onChangeStatus={this.onChangeStatus}
                  rowAdd={this.rowAdd}
                  rowTransfer={this.rowTransfer}
                  showTransferTitle={showTransferTitle}
                  isActionColActive={isActionColActive}
                  setData={setData}
                  condition={condition}
                  conditionDelete={conditionDelete}
                  showApplyTitle={showApplyTitle}
                  showEWayTitle={showEWayTitle}
                  showCancelTitle={showCancelTitle}
                  showAddTitle={showAddTitle}
                  showApply={
                    showApply && fieldDataForModule.editReject == 1
                      ? true
                      : false
                  }
                  showEWay={
                    showEWay && fieldDataForModule.editReject == 1
                      ? true
                      : false
                  }
                  showCancel={
                    showCancel && fieldDataForModule.editReject == 1
                      ? true
                      : false
                  }
                  showTransferButtonInTable={
                    showTransferButtonInTable &&
                      fieldDataForModule.editReject == 1
                      ? true
                      : false
                  }
                  rowApply={rowApply}
                  rowEWay={rowEWay}
                  rowCancel={rowCancel}
                  rowPDF={rowPDF}
                  showPdf={
                    showPdf && fieldDataForModule.view == 1 ? true : false
                  }
                  showPegination={showPegination}
                  deleteConditionName={deleteConditionName}
                  deleteConditionValue={deleteConditionValue}
                  editConditionName={editConditionName}
                  credentialConditionName={credentialConditionName}
                  credentialConditionValue={credentialConditionValue}
                  cancelBillConditionName={cancelBillConditionName}
                  cancelBillConditionValue={cancelBillConditionValue}
                  editConditionValue={editConditionValue}
                  showSendRequestIcon={
                    showSendRequestIcon && fieldDataForModule.editReject == 1
                      ? true
                      : false
                  }
                  rowSendRequest={rowSendRequest}
                  sendRequestIconName={sendRequestIconName}
                  sendRequestIconValue={sendRequestIconValue}
                  rowLinkToShowData={this.rowLinkToShowData}
                  isActiveURLFalse={isActiveURLFalse}
                  isActiveURLFalseMethod={isActiveURLFalseMethod}
                />
              )}
            </>
          )}



        </Paper>
        {showPopUp && (
          <ShowDynamicTableWithData
            open={showPopUp}
            rowList={rowList}
            tableRow={tableRow}
            handleCloses={this.handleToggleClosed}
            title={title}
            rowData={rowData}
            onChangeStatusPopUp={this.onChangeStatusPopUp}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = () => ({});
const mapDispatchToProps = { resetReducList, showNotification, showLoador };
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DynamicMainScreen);
