import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

const initialState = {
  toggleNav: true,
  nav: [
    {
      name: "Master",
      path: "/master",
      open: false,
      roles: ["trust"],
      subMenu: [
        {
          name: "Floor",
          path: "/floor",
        },
        {
          name: "Room",
          path: "/room",
        },
        {
          name: "Time Slot",
          path: "/time-slot",
        },
        {
          name: "Internship Type",
          path: "/internshipType",
        },
        {
          name: "Company Master",
          path: "/companymaster",
        },

        {
          name: "Division",
          path: "/division",
        },
        {
          name: "Scholarship Type",
          path: "/scholarship-type",
        },
        {
          name: "Pattern",
          path: "/pattern",
        },
        {
          name: "Designation",
          path: "/designation",
        },
        {
          name: "Department",
          path: "/department",
        },
        {
          name: "Scheme",
          path: "/scheme",
        },
        {
          name: "Scheme Head",
          path: "/scheme-head",
        },
        {
          name: "Fees Title",
          path: "/fees-title",
        },
        {
          name: "Semester Info",
          path: "/semister-info",
        },
        {
          name: "Subject Info",
          path: "/subject-info",
        },
        {
          name: "Admission Type",
          path: "/admission-type",
        },

        // {
        //   name: "From fees",
        //   path: "/from-fees",
        // },
        {
          name: "Financial Year",
          path: "/financial-year",
        },
        {
          name: "Admission Status",
          path: "/admission-status",
        },
        {
          name: "Subject Type Detail",
          path: "/subject-Type-Detail",
        },

        {
          name: "Fees Structure Header",
          path: "/fees-Structure-Header",
        },
        {
          name: "Fees Head Details",
          path: "/fees-head-details",
        },

        {
          name: "Employee",
          path: "/employeeList",
        },
      ],
      permission: [],
    },
    {
      name: "Forgot Password Link",
      path: "/forgot-password-link",
      roles: ["trust"],
      open: true,
    },

    {
      name: "Academic Details ",
      path: "/academic-details",
      roles: ["trust"],
      open: false,
      subMenu: [
        {
          name: "Co-curricular ",
          path: "/cocurricular",
        },
        {
          name: "Innovative Teaching Learning Strategies ",
          path: "/innovative-teaching",
        },
        {
          name: "Paper Poster Presented ",
          path: "/paper-poster-presented",
        },
        {
          name: "Attended Seminar ",
          path: "/attendedseminar",
        },
        {
          name: "Activity Master",
          path: "/activity-master",
        },

        {
          name: "Resource Person",
          path: "/research-person",
        },
        {
          name: "Award",
          path: "/awards",
        },
        {
          name: "Participation Details",
          path: "/participation-details",
        },
        {
          name: "Contributions",
          path: "/contributions",
        },
        {
          name: "Research Journal Publication",
          path: "/reserch-journal-publication",
        },
        {
          name: "Research Guidance",
          path: "/research-guidance",
        },
        {
          name: "Research Project ",
          path: "/research-project",
        },
        {
          name: "Course Attended ",
          path: "/course-attended",
        },
        {
          name: "Adding Qualification",
          path: "/adding-qualification",
        },
        {
          name: "Seminar Webinar Activity",
          path: "/sem-web",
        },

        {
          name: "Committee Handle",
          path: "/committee-handle",
        },
        {
          name: "Consultancy",
          path: "/consultancy",
        },
        {
          name: "Extended Activity",
          path: "/extended-activity",
        },
        {
          name: "Complaint Admin",
          path: "/complaint-admin",
        },
        {
          name: "Complaint",
          path: "/complaint",
        },
        {
          name: "Notice for Staff",
          path: "/view-notice",
        },
        {
          name: "Notice For Student",
          path: "/view-student-notice",
        },
        {
          name: "Notice For Teacher",
          path: "/view-teacher-notice",
        },
        {
          name: "Student View Notice",
          path: "/student-view-notice",
        },
        {
          name: "PRN Number ",
          path: "/prn-number",
        },
      ],
      permission: [],
    },
    {
      name: "Batches ",
      path: "/batches",
      roles: ["trust"],
      open: false,
      subMenu: [
        {
          name: "List of Batches",
          path: "/batch-list",
        },
      ],
      permission: [],
    },
    {
      name: "Deemed ",
      path: "/deemed",
      roles: ["trust"],
      open: false,
      subMenu: [
        {
          name: "Deemed Committee",
          path: "/deemed-committee",
        },
      ],
      permission: [],
    },

    {
      name: "User Credential ",
      path: "/user-credentials",
      roles: ["trust"],
      open: false,
      subMenu: [
        {
          name: "User Credentials Form",
          path: "/user-credentials-form",
        },
      ],
      permission: [],
    },
    {
      name: "Suggestions ",
      path: "/suggestions",
      roles: ["trust"],
      open: false,
      subMenu: [
        {
          name: " Add Suggestions",
          path: "/suggestions-form",
        },
        {
          name: "View Suggestions",
          path: "/suggestion",
        },
      ],
      permission: [],
    },
    {
      name: "Notice ",
      path: "/notice",
      roles: ["trust"],
      open: false,
      subMenu: [
        {
          name: "Notice for Staff",
          path: "/view-notice",
        },
        {
          name: "Notice For Student",
          path: "/view-student-notice",
        },
        {
          name: "Notice For Teacher",
          path: "/view-teacher-notice",
        },
        {
          name: "Student View Notice",
          path: "/student-view-notice",
        },
      ],
      permission: [],
    },

    {
      name: "Feedback ",
      path: "/feedback",
      roles: ["trust"],
      open: false,
      subMenu: [
        {
          name: " Employee Feedback",
          path: "/view-declared-feedback",
        },
        {
          name: "Student Feedback",
          path: "/view-declared-student-feedback",
        },
        {
          name: "Student Feedback Details",
          path: "/student-feedback-details",
        },
        {
          name: "Employee Feedback Details",
          path: "/employee-feedback-details",
        },
        {
          name: "Add Feedback",
          path: "/add-Feedback",
        },
        {
          name: "Your Feedback ( Employee )",
          path: "/employee-view-applied-feedback",
        },
        {
          name: "Your Feedback ( Student )",
          path: "/student-view-applied-feedback",
        },
      ],
      permission: [],
    },
    {
      name: "Roll No ",
      path: "/rollNo",
      roles: ["trust"],
      open: false,
      subMenu: [
        {
          name: "view of Roll list",
          path: "/roll-list",
        },
      ],
      permission: [],
    },
    {
      name: "Student Admission ",
      path: "/admission",
      open: false,
      subMenu: [
        {
          name: "Allocated Student List",
          path: "/document-verification",
        },
        {
          name: "Verification Document",
          path: "/verification-document",
        },

        {
          name: "New Admission ",
          path: "/admissionForm",
        },
        {
          name: "Admited Student List  ",
          path: "/applicant-admission",
        },

        {
          name: "Student Fees Collection",
          path: "/student-fees-collection",
        },
        {
          name: "Admission Form",
          path: "/admission-form",
        },
        {
          name: "Admin edit student details",
          path: "/admin-edit-details",
        },
      ],
      roles: ["trust"],
      permission: [],
    },
    {
      name: "Internship",
      path: "/internship",
      open: false,
      subMenu: [
        {
          name: "List of Internship",
          path: "/list-of-internship-declaration",
        },
        {
          name: "Viva Declaration List",
          path: "/viva-declaration",
        },
        {
          name: "Teacher Viva",
          path: "/teacherViva",
        },
        {
          name: "Student Add Internship",
          path: "/student-add-internship",
        },

        {
          name: "External Students",
          path: "/external-students",
        },
      ],
      roles: ["trust"],
      permission: [],
    },
    {
      name: "Placement",
      path: "/placement",
      open: false,
      subMenu: [
        {
          name: "List of Placement",
          path: "/list-of-placement",
        },

        {
          name: "Student Placement List",
          path: "/student-placement",
        },

        {
          name: "External Placed Students",
          path: "/external-placed-students",
        },
      ],
      roles: ["trust"],
      permission: [],
    },

    {
      name: "Workload Allocation",
      path: "/workload",
      open: false,
      subMenu: [
        {
          name: "Admin Workload Allocation",
          path: "/workload-allocation",
        },
        {
          name: "teacher workload allocation",
          path: "/teacher-workload-allocation",
        },
      ],
      roles: ["trust"],
      permission: [],
    },
    {
      name: "Event",
      path: "/event",
      open: false,
      subMenu: [
        {
          name: "View Events",
          path: "/view-event",
        },
        {
          name: "Students Apply For Event",
          path: "/student-apply-event",
        },
        {
          name: "Students Add Event",
          path: "/student-view-event",
        },
      ],
      roles: ["trust"],
      permission: [],
    },
    {
      name: "Certificate",
      path: "/certificate",
      open: false,
      subMenu: [
        {
          name: "Admin Certificate Status",
          path: "/admin-certificate",
        },
        // {
        //   name: "Students Apply For Certificate",
        //   path: "/student-apply-event",
        // },
        {
          name: "Students Certificate History",
          path: "/view-history",
        },
        {
          name: "List Of Certificate ",
          path: "/list-of-certificate",
        },
      ],
      roles: ["trust"],
      permission: [],
    },
    {
      name: "Time Table",
      path: "/timeTable",
      open: false,
      subMenu: [
        {
          name: "View Time Table",
          path: "/view-time-table",
        },
        {
          name: "View Time Table To Teacher",
          path: "/view-time-table-to-teacher",
        },
        {
          name: "View Time Table To Student",
          path: "/view-time-table-to-student",
        },
      ],
      roles: ["trust"],
      permission: [],
    },

    {
      name: "Student Attendance",
      path: "/attendance",
      open: false,
      subMenu: [
        {
          name: "Viewing Own Attendance",
          path: "/own-attendance",
        },
        {
          name: "Viewing Class Attendance",
          path: "/view-attendance-class",
        },
        {
          name: "Present Student Details",
          path: "/present-student-details",
        },
        {
          name: "View Present Student Details by Head ",
          path: "/view-present-by-head",
        },
      ],
      roles: ["trust"],
      permission: [],
    },
    {
      name: "Teaching Plan",
      path: "/teaching-plan",
      open: false,
      subMenu: [
        {
          name: "Admin Teaching Plan",
          path: "/admin-add-teaching-plan",
        },
        {
          name: "Principal View",
          path: "/teaching-plan-principle-view",
        },
        {
          name: "Class Co-Ordinator View",
          path: "/teaching-plan-class-co-ordinator-view",
        },
      ],
    },
    {
      name: "Unit Test",
      path: "/unit-test-master",
      open: false,
      subMenu: [
        {
          name: "Unit Test",
          path: "/unit-test",
        },
        {
          name: "Unit Test Declaration",
          path: "/unit-test-declare",
        },
      ],
      roles: ["trust"],
      permission: [],
    },

    {
      name: "Out Side feedback",
      path: "/outside-feedback",
      open: false,
      subMenu: [
        {
          name: "out side feedback",
          path: "/outside-feedback-student",
        },
        {
          name: "out side feedback Employee",
          path: "/outside-feedback-employee",
        },
      ],
      roles: ["trust"],
      permission: [],
    },

    {
      name: "Dynamic",
      path: "/dynamicReport",
      open: false,
      subMenu: [
        {
          name: "Dynamic report",
          path: "/dynamicReport",
        },
      ],
      roles: ["trust"],
      permission: [],
    },

    {
      name: "E-material",
      path: "/ematerial-form",
      open: false,
      subMenu: [
        {
          name: "E-material",
          path: "/ematerial-form",
        },
        {
          name: "E-material View Uploded",
          path: "/ematerial-view-table",
        },
      ],
      roles: ["trust"],
      permission: [],
    },
    {
      name: "User Access Role ",
      path: "/accessRole",
      roles: ["trust"],
      open: false,
      subMenu: [
        {
          name: "User Access Role",
          path: "/accessRole",
        },
      ],
      permission: [],
    },
  ],
};
const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    toggleNav: (state = cloneDeep(initialState), action) => {
      return {
        ...state,
        toggleNav: !state.toggleNav,
      };
    },
    changeNav: (state = cloneDeep(initialState), action) => {
      const { list } = action.payload;
      return {
        ...state,
        nav: list,
      };
    },
  },
});

export const { toggleNav, changeNav } = commonSlice.actions;

export default commonSlice.reducer;

//Actions
export const onToggle = () => async (dispatch) => {
  dispatch(toggleNav());
};
export const resetNavBar =
  ({ list }) =>
  async (dispatch) => {
    dispatch(changeNav({ list }));
  };
