import endpoint from "../../config/endpoints";

export const companyTargetJson = {
  formPath: "/company-and-target-form",
  apiBaseURL: endpoint.companyTarget,
  screenTitle: "Company Target",
  showAddButton: true,
  showPdfDownload: false,
  pdfFileName: "Company Target",
  showExcelDownload: false,
  excelFileName: "Company Target",

  fieldMeta: [
    {
      label: "Year",
      controlType: "autocomplete",
      placeHolder: "Year",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      masterName: "yearDetail",
      dataKey: "yearName",
      getListId: "yearName",
      isRootLevelKey: true,
      isMandatory: true,
    },
    {
      label: "Denomination",
      controlType: "radiogroup",
      md: 6,
      lg: 6,
      sm: 6,
      xs: 12,
      dataKey: "type",
      tableDataKey: "type",
      defaultValue: "0",
      radioGroupItems: [
        {
          label: "K",
          value: "0",
        },
        {
          label: "L",
          value: "1",
        },
        {
          label: "CR",
          value: "2",
        },
      ],
      isMandatory: true,
    },
  ],
};
